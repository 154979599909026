/**
 * @name parentNgClick
 * @desc Prevents parent element from firing its click event handler when its child element with a click event handler of its own is clicked.
 */
import angular from 'angular';
import $ from 'jquery';

angular.module('app').directive('parentNgClick', ['$timeout', '$parse', function parentNgClick($timeout, $parse) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const clickListener = function (e) {
        let targetHasClick = false;
        let target = e.target;
        while (!target.parentNode.isEqualNode(element[0]) && !target.isEqualNode(element[0]) && !targetHasClick) {
          const jqueryEvents = $._data(target, 'events');
          targetHasClick = typeof target.onclick === 'function' ||
            (jqueryEvents && jqueryEvents.click !== null && jqueryEvents.click !== undefined) ||
            target.getAttribute('data-prevent-parent-click') === 'true';
          target = target.parentNode;
        }
        if (!targetHasClick) {
          $timeout(() => {
            const func = $parse(attrs.parentNgClick);
            func(scope);
          });
        }
      };
      element.on('click', clickListener);
      scope.$on('$destroy', () => {
        element.off('click', clickListener);
      });
    },
  };
}]);
