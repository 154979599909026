import { toastError, toastSuccess, showActionConfirmation } from 'Common/utilities/alert';
import { isClientFilterValid } from 'Common/utilities/insurancePipeline';
import { findIndex } from 'lodash';
import { DEFAULT_PAGE_NUMBER } from 'Common/default/pipeline';
import { INSURANCE_PIPELINE_STATUS } from 'Common/constants/insuranceOptions';

export default class InsurancePipelineTableCtrl {
  constructor(
    $sce,
    $element,
    $timeout,
    insurancePipelineService,
    modalRenderService,
    utilitiesService
  ) {
    'ngInject';

    this.$sce = $sce;
    this.$element = $element;
    this.$timeout = $timeout;
    this.insurancePipelineService = insurancePipelineService;
    this.modalRenderService = modalRenderService;
    this.utilitiesService = utilitiesService;
    this.pageNumber = DEFAULT_PAGE_NUMBER;
    this.pageSize = 10;
  }

  $onInit() {
    this.loadPipelineItems();
    this.onInit && this.onInit({
      api: {
        refresh: () => this.loadPipelineItems(1, true),
        silentlyRefresh: () => this.loadPipelineItems(1, false),
      },
    });
    this.statusInForce = { id: INSURANCE_PIPELINE_STATUS.IN_FORCE, name: 'In Force' };
    this.statusNotProceeded = { id: INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED, name: 'Not Proceeded With' };
  }

  $onDestroy() {
    this.setRowHeightsTimeout && this.$timeout.cancel(this.setRowHeightsTimeout);
    this.onLabelsDropdownToggled && this.$timeout.cancel(this.onLabelsDropdownToggled);
    this.filterByClientTimeout && this.$timeout.cancel(this.filterByClientTimeout);
  }

  $onChanges(changes) {
    const { labelsSelection, clientFilter, statuses } = changes;
    if (labelsSelection) {
      this.updateLabelsColor();
    }
    if (clientFilter && !clientFilter.isFirstChange()) {
      this.filterByClient(clientFilter.currentValue);
    }
    if (statuses && statuses.currentValue) {
      this.statuses = statuses.currentValue.filter((status) => {
        return status.id !== INSURANCE_PIPELINE_STATUS.IN_FORCE && status.id !== INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED;
      });
    }
  }

  filterByClient(filter) {
    if (!isClientFilterValid(filter)) return;
    this.$timeout.cancel(this.filterByClientTimeout);
    this.filterByClientTimeout = this.$timeout(() => {
      this.loadPipelineItems(1, true);
    }, 1000);
  }

  updateLabelsColor() {
    if (!this.labelsSelection || !this.pipelineItems) return;
    this.pipelineItems = this.pipelineItems.map((item) => {
      return { ...item, labels: this.getDisplayableLabels(item) };
    });
  }

  setRowHeights() {
    if (!this.pipelineItems || !this.pipelineItems.length) return;
    this.setRowHeightsTimeout = this.$timeout(() => {
      this.pipelineItems.forEach((item) => {
        if (!item.quoteId) return;
        const selector = `.row-${item.quoteId}`;
        const scrollable = this.$element[0].querySelectorAll(`.is-scrollable ${selector}`)[0];
        const nonScrollable = this.$element[0].querySelectorAll(`.is-static ${selector}`)[0];
        if (!scrollable || !nonScrollable) return;
        scrollable.style.height = 'auto';
        nonScrollable.style.height = 'auto';

        const maxHeight = scrollable.offsetHeight > nonScrollable.offsetHeight ? scrollable.offsetHeight : nonScrollable.offsetHeight;
        scrollable.style.height = `${maxHeight}px`;
        nonScrollable.style.height = `${maxHeight}px`;
      });
    });
  }

  loadPipelineItems(page, showLoading = true) {
    this.isLoading = showLoading;
    this.insurancePipelineService.getInsurancePipelineTableItems({
      pageNumber: page || 1,
      pageSize: this.pageSize,
      sortColumn: this.sortCol,
      sortDir: this.sortDir,
      searchClient: this.clientFilter,
    })
      .then((response) => {
        if (!response) return;
        this.pageNumber = response.pageNumber;
        this.totalRecords = response.totalRecords;
        this.pipelineItems = response.items && response.items.map((item) => {
          return {
            ...item,
            provider: {
              ...item.provider,
              logo: `assets/images/insurers/bordered/${item.provider.id}.png`,
            },
            adviser: {
              ...item.adviser,
              avatar: this.$sce.trustAsResourceUrl(item.adviser.avatar),
              initials: this.utilitiesService.filterInitialOneString(item.adviser.name),
            },
            selectedLabelIds: item.labels && item.labels.reduce((accum, label) => {
              return [...accum, label.id];
            }, []),
            labels: this.getDisplayableLabels(item),
          };
        });
        this.setRowHeights();
      })
      .catch(e => toastError(e))
      .finally(() => {
        this.isLoading = false;
      });
  }

  getDisplayableLabels(item) {
    if (!this.labelsSelection) return [];
    return item.labels && item.labels.reduce((accum, itemLabel) => {
      const labelInSelection = this.labelsSelection.find(label => label.id === itemLabel.id);
      if (!labelInSelection) return accum;

      return [...accum, {
        ...itemLabel,
        color: labelInSelection.color,
        name: labelInSelection.name,
      }];
    }, []);
  }

  convertApplication(itemId, isProceeding) {
    const item = this.pipelineItems.find(itemInList => itemInList.quoteId === itemId);
    const status = isProceeding ? this.statusInForce : this.statusNotProceeded;
    if (item.status.id === status.id) return;
    this.promptToContinueConversion(isProceeding)
      .result.then((response) => {
        if (!response.isOk) return;
        this.onSelectStatus(status, itemId, response.selectedReasonId);
      });
  }

  promptToContinueConversion(isProceeding) {
    return this.modalRenderService.renderPipelineStatusMoveConfirmationModal({
      content: `This application will now be moved to ${isProceeding ? 'In Force' : 'Not Proceeding'}`,
      reasonList: isProceeding ? null : this.notProceedingReasons,
      showNurtureCampaignsQuestion: false,
    });
  }

  onSelectStatus(status, itemId, reasonForNotProceedingID) {
    const item = this.pipelineItems.find(itemInList => itemInList.quoteId === itemId);
    if (item.status.id === status.id) return;
    this.insurancePipelineService.movePipelineCardToStatus({
      fromStatusId: item.status.id,
      toStatusId: status.id,
      quoteId: item.quoteId,
      reasonForNotProceedingID,
    })
      .then(() => {
        item.status = { ...status };
        const isConversion = status === this.statusInForce || status === this.statusNotProceeded;
        isConversion && this.onApplicationConverted && this.onApplicationConverted();
      })
      .catch(e => toastError(e));
  }

  onLabelsDropdownToggled(label, event, itemId) {
    const item = this.pipelineItems.find(itemInList => itemInList.quoteId === itemId);
    item.isLabelDropdownOpen = !!label && label.id !== item.labelDropdownTrigger;
    item.labelDropdownTrigger = item.isLabelDropdownOpen ? label && label.id : null;
    item.hasJustOpened = item.isLabelDropdownOpen;
    this.onLabelsToggleTimeout = this.$timeout(() => {
      item.hasJustOpened = false;
      this.$timeout.cancel(this.onLabelsDropdownToggled);
      this.onLabelsToggleTimeout = null;
    });
  }

  onAddLabelToItem(label, itemId) {
    if (!label || !itemId) return;
    this.insurancePipelineService.addPipelineItemLabel({ quoteId: itemId, labelIds: label.id })
      .then(() => {
        const item = this.pipelineItems.find(itemInList => itemInList.quoteId === itemId);
        item.labels = item.labels ? [...item.labels, label] : [label];
        item.labels = this.getDisplayableLabels(item);
      })
      .catch(e => toastError(e));
  }

  onDeleteLabelFromItem(label, itemId) {
    if (!label || !itemId) return;
    this.insurancePipelineService.deletePipelineItemLabel({ quoteId: itemId, labelIds: label.id })
      .then(() => {
        const item = this.pipelineItems.find(itemInList => itemInList.quoteId === itemId);
        const indexOfDeletedLabel = findIndex(item.labels, labelInlist => labelInlist.id === label.id);
        item.labels = [
          ...item.labels.slice(0, indexOfDeletedLabel),
          ...item.labels.slice(indexOfDeletedLabel + 1),
        ];
      })
      .catch(e => toastError(e));
  }

  onDeleteItem(itemId) {
    showActionConfirmation('Are you sure?', 'This application will be removed from your list.', () => {
      this.insurancePipelineService.deletePipelineCard(itemId)
        .then(() => {
          const pageToLoad = this.pipelineItems.length > 1 ? this.pageNumber : this.pageNumber - 1;
          this.loadPipelineItems(pageToLoad, false);
          toastSuccess('Application removed successfully!');
        })
        .catch(e => toastError(e));
    });
  }

  closeLabelsMenu(itemId) {
    const item = this.pipelineItems.find(itemInList => itemInList.quoteId === itemId);
    if (item.hasJustOpened) return;
    item.isLabelDropdownOpen = false;
    item.labelDropdownTrigger = null;
  }

  sort(column) {
    if (column !== this.sortCol) {
      this.sortDir = 'ASC';
    } else {
      this.sortDir = this.sortDir === 'ASC' ? 'DESC' : 'ASC';
    }
    this.sortCol = column;
    this.onTableSort && this.onTableSort({ column, direction: this.sortDir });
  }
}
