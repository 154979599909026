/**
 * HttpCacheService Factory
 * @namespace Factories
 */
app.factory('httpCacheService', httpCacheService);

httpCacheService.$inject = ['httpRequest', '$cacheFactory', '$timeout', '$interval'];

/**
 * @namespace httpCacheService
 * @desc Cache http request
 * @memberOf Factories
 */
function httpCacheService(httpRequest, $cacheFactory, $timeout, $interval) {
  var cache = $cacheFactory('httpCacheStorage');
  var service = {
    get: getRequest,
    post: postRequest,
  };

  return service;

  // //////////

  /**
     * @name cacheCallback
     * @desc Utility function for cache http service
     * @private
     * @param {String} type
     * @param {String} url
     * @param {Object} data
     * @param {successCallback} successCallback
     * @param {errorCallback} errorCallback
     *
     * @callback successCallback
     * @param {Object} response
     *
     * @callback errorCallback
     * @param {Object} errorResponse
     */
  function cacheCallback(type, url, data, successCallback, errorCallback) {
    var callCounter = cache.get(`${url}__counter`) || 0;
    var responseCache = cache.get(url);

    // if post request then will cache url with the data string to avoid caching post request with different data param
    if (type === 'post') {
      data = data || { value: 'no data' };
      var stringifyData = JSON.stringify(data);
      callCounter = cache.get(`${url + stringifyData}__counter`);
      responseCache = cache.get(url + stringifyData);
    }

    if (typeof responseCache !== 'undefined') {
      successCallback(responseCache);
    } else if (callCounter < 1) {
      // This is to validate post request with data to check if they have same data param
      if (type === 'post') {
        cache.put(`${url + stringifyData}__counter`, 1);
      } else {
        cache.put(`${url}__counter`, 1);
      }

      switch (type) {
      case 'get':
        httpRequest.get(url, (response) => {
          cache.put(url, response);
          cache.put(`${url}__counter`, 0);
          successCallback(_.assign({}, response));
          // will remove the data
          var timeoutResponse = $timeout(() => {
            cache.removeAll();
            $timeout.cancel(timeoutResponse);
          }, 5000);
        }, errorCallback);
        break;
      case 'post':
        httpRequest.post(url, data, (response) => {
          cache.put(url + stringifyData, response);
          cache.put(`${url + stringifyData}__counter`, 0);
          successCallback(_.assign({}, response));
          // will remove the data
          var timeoutResponse = $timeout(() => {
            cache.removeAll();
            $timeout.cancel(timeoutResponse);
          }, 5000);
        }, errorCallback);
      }
    } else {
      // if same request has already been made will wait for the response and return it
      var responseCacheInterval = $interval(() => {
        var intervalCacheResponse = cache.get(url);
        if (typeof intervalCacheResponse !== 'undefined') {
          successCallback(_.assign({}, intervalCacheResponse));
          $interval.cancel(responseCacheInterval);
        }
      }, 100);
    }
  }

  /**
     * @name getRequest
     * @desc Cache GET http request
     * @param {String} url
     * @param {successCallback} successCallback Callback function on success
     * @param {errorCallback} errorCallback Callback function on fail
     *
     * @callback successCallback
     * @param {Object} response
     *
     * @callback errorCallback
     * @param {Object} errorResponse
     * @memberOf Factories.httpCacheService
     */
  function getRequest(url, successCallback, errorCallback) {
    cacheCallback('get', url, null, successCallback, errorCallback);
  }

  /**
     * @name postRequest
     * @desc Cache POST http request
     * @param {String} url
     * @param {Object} data
     * @param {successCallback} successCallback Callback function on success
     * @param {errorCallback} errorCallback Callback function on fail
     *
     * @callback successCallback
     * @param {Object} response
     *
     * @callback errorCallback
     * @param {Object} errorResponse
     * @memberOf Factories.httpCacheService
     */
  function postRequest(url, data, successCallback, errorCallback) {
    cacheCallback('post', url, data, successCallback, errorCallback);
  }
}
