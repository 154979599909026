import angular from 'angular';

angular.module('app').factory('selectOptionsModel', ['httpRequest', function (httpRequest) {
  var apiBaseUrl = 'SelectOptions/';

  function selectOptionsModel() {
  }

  selectOptionsModel.prototype = {
    getLoanTerm(callback) {
      httpRequest.get(`${apiBaseUrl}LoanTerm`, (response) => {
        return callback(response);
      });
    },

    getLoanRepaymentFrequency(callback) {
      httpRequest.get(`${apiBaseUrl}LoanRepaymentFrequency`, (response) => {
        return callback(response);
      });
    },

    getPaymentFrequency(callback) {
      httpRequest.get(`${apiBaseUrl}PaymentFrequency`, (response) => {
        return callback(response);
      });
    },

    getCalculationPeriod(callback) {
      httpRequest.get(`${apiBaseUrl}CalculationPeriod`, (response) => {
        return callback(response);
      });
    },

    getIPCoverType(callback) {
      httpRequest.get(`${apiBaseUrl}IPCoverType`, (response) => {
        return callback(response);
      });
    },

    getWaitPeriod(callback) {
      httpRequest.get(`${apiBaseUrl}WaitPeriod`, (response) => {
        return callback(response);
      });
    },

    getBenefitPeriods(callback) {
      httpRequest.get(`${apiBaseUrl}BenefitPeriod`, (response) => {
        return callback(response);
      });
    },


    // Security Structure
    getSecurityType(callback) {
      httpRequest.get(`${apiBaseUrl}SecurityType`, (response) => {
        return callback(response);
      });
    },

    getPropertyOwnership(callback) {
      httpRequest.get(`${apiBaseUrl}PropertyOwnership`, (response) => {
        return callback(response);
      });
    },
    getPropertyType(callback) {
      httpRequest.get(`${apiBaseUrl}PropertyType`, (response) => {
        return callback(response);
      });
    },
    getPropertyDevelopmentStatus(callback) {
      httpRequest.get(`${apiBaseUrl}PropertyDevelopmentStatus`, (response) => {
        return callback(response);
      });
    },

    getPropertyIntention(callback) {
      httpRequest.get(`${apiBaseUrl}PropertyIntention`, (response) => {
        return callback(response);
      });
    },

    getPropertyZoning(callback) {
      httpRequest.get(`${apiBaseUrl}PropertyZoning`, (response) => {
        return callback(response);
      });
    },

    getPropertyTenure(callback) {
      httpRequest.get(`${apiBaseUrl}PropertyTenure`, (response) => {
        return callback(response);
      });
    },

    getPropertyValuationType(callback) {
      httpRequest.get(`${apiBaseUrl}PropertyValuationType`, (response) => {
        return callback(response);
      });
    },

    // Serviceability
    getIncomeTypeForRegion(brokerEventId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeTypeForRegion?brokerEventID=${brokerEventId}`, (response) => {
        return callback(response);
      });
    },
    getLiabiltyTypeForRegion(brokerEventId, callback) {
      httpRequest.get(`${apiBaseUrl}LiabiltyTypeForRegion?brokerEventID=${brokerEventId}`, (response) => {
        return callback(response);
      });
    },
    getExpenseType(callback) {
      httpRequest.get(`${apiBaseUrl}ExpenseType`, (response) => {
        return callback(response);
      });
    },
  };
  return selectOptionsModel;
}]);
