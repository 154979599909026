import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { displayError } from 'Common/utilities/alert';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { SHARING_VALUE } from 'Common/constants/documentSharing';

angular.module('app').controller('DocumentsDetailCtrl',
  function DocumentsDetailCtrl($scope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    contactService,
    NgTableParams,
    $filter,
    configService,
    toaster,
    generalService,
    uiService,
    $window,
    downloadDataService,
    $q,
    $state) {
    $scope.interface = {};
    $scope.uploadCount = 0;
    $scope.success = false;
    $scope.error = false;
    $scope.gdriveObject = [];
    $scope.familyId = $stateParams.familyId;
    $scope.adviserOrgId = $stateParams.adviserOrgId ? $stateParams.adviserOrgId : 0;
    $scope.hideDocumentProgress = false;
    $scope.isUploaded = false;
    $scope.documentTab = 'allDocuments';
    $scope.selectedFilterInfo = [];
    const {
      clientId,
      adviserOrgId,
    } = $state.params;
    $scope.isClientDocuments = (!clientId && clientId !== 0) && (!adviserOrgId && adviserOrgId !== 0);
    $scope.extensions = ['pdf', 'docx', 'doc', 'odt', 'xls', 'xlsx', 'ods', 'odp', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', 'rtf', 'odt', 'txt', 'tif', 'bmp', 'gif', 'html'];
    $scope.invalidFileInfo = { title: 'Invalid', description: 'Invalid file NOT added' };

    $scope.isShowGdriveFeature = () => {
      return configService.feature.googleDrive && !uiService.isCorporateUser;
    };

    $scope.isLoadingEmails = {
      documents: true,
      googleDrive: $scope.isShowGdriveFeature(),
      isTrue: () => $scope.isLoadingEmails.documents || $scope.isLoadingEmails.googleDrive,
    };
    $scope.documentType = [];
    $scope.gdriveObject.models = null;
    $scope.gdriveObject.models = {
      UserAuthUrl: null,
      isAuthenticated: false,
      rawFolderFiles: {},
      hasChosenAFolder: false,
      isShowNotAssignedFolder: false,
    };

    $scope.bannerObject = {
      typeShown: '',
      textsList: {
        documentsDragTip: `Quick Tip: Did you know that you can also add a file by dragging and dropping it to the window? Try it!`,
        noGdriveIntegrated: `We could not load Google Drive files because you haven't linked your account to MyCRM yet. Click here to link`,
        noFolderAssigned: `We could not load Google Drive files because you didn't select a folder for this family yet. Click here to select`,
      },
      textToShow: () => {
        const { isAuthenticated, hasChosenAFolder } = $scope.gdriveObject.models;
        const { noFolderAssigned, documentsDragTip, noGdriveIntegrated } = $scope.bannerObject.textsList;

        if (!isAuthenticated && $scope.isShowGdriveFeature()) {
          $scope.bannerObject.typeShown = 'notIntegrated';
          return noGdriveIntegrated;
        } else if (!hasChosenAFolder && $scope.isShowGdriveFeature()) {
          $scope.bannerObject.typeShown = 'noFolder';
          return noFolderAssigned;
        }

        $scope.bannerObject.typeShown = 'documentsDrag';
        return documentsDragTip;
      },
      clicked: () => {
        const { typeShown } = $scope.bannerObject;
        const { openNewWindowForAuth } = $scope.gdriveObject.methods;

        if (typeShown === 'noFolder') {
          $scope.showSelectGdriveFolder();
        } else if (typeShown === 'notIntegrated') {
          openNewWindowForAuth();
        }
      },
    };

    const populateDocumentType = () => {
      contactService.documentTypeGet().then((response) => {
        $scope.documentType = response.data;

        if (!$scope.isShowGdriveFeature()) {
          $scope.documentType = response.data.filter(object => object.type !== 'googleDrive');
        }
        const isClient = typeof $state.params.clientId === 'undefined' && typeof $state.params.adviserOrgId === 'undefined';
        if (!isClient && $scope.documentType) {
          $scope.documentType = $scope.documentType.filter(object => object.type !== 'loanApp');
        }
      });
    };
    populateDocumentType();

    /* start of gdrive methods */
    $scope.gdriveObject.methods = null;
    $scope.gdriveObject.methods = {
      init: () => {
        $scope.gdriveObject.methods.checkIfAuthenticated().then((response) => {
          $scope.gdriveObject.models.isAuthenticated = response.AuthSuccess;

          if ($scope.gdriveObject.models.isAuthenticated && $scope.gdriveObject.models.hasChosenAFolder) {
            if (response.GoogleDriveFolderFiles && response.GoogleDriveFolderFiles.length) {
              $scope.gdriveObject.models.rawFolderFiles = response.GoogleDriveFolderFiles.filter(object => object.IsFolder === false);
            }
          } else {
            $scope.gdriveObject.models.UserAuthUrl = response.UserAuthUrl;
          }
        });
      },

      openNewWindowForAuth: () => {
        $window.open($scope.gdriveObject.models.UserAuthUrl);
      },

      checkIfAuthenticated: () => {
        $scope.isLoadingEmails.googleDrive = true;
        const defer = $q.defer();
        contactService.getDriveUserMapping($state.params.familyId).then((response) => {
          if (response.data) {
            $scope.gdriveObject.models.hasChosenAFolder = true;
            $scope.selectedGdriveFolderId = response.data.GoogleDriveFolderID;
          } else {
            $scope.gdriveObject.models.isShowNotAssignedFolder = true;
          }

          contactService.getGoogleDriveFolderId(false, $scope.selectedGdriveFolderId ? $scope.selectedGdriveFolderId : 'root').then((getGdriveResponse) => {
            const data = getGdriveResponse.data;
            let toReturnData;

            if (data.AuthSuccess) {
              toReturnData = data;
            } else {
              toReturnData = {
                AuthSuccess: data.AuthSuccess,
                UserAuthUrl: data.UserAuthUrl,
              };
            }
            $scope.isLoadingEmails.googleDrive = false;

            return defer.resolve(toReturnData);
          });
        });

        return defer.promise;
      },
    };
    /* end of gdrive */
    $scope.hideDocumentBanner = (event) => {
      event.stopPropagation();
      $scope.hideDocumentNotification = true;
    };
    $scope.isGdriveOnlyChecked = () => {
      return $scope.filterModel.googleDrive && !$scope.filterModel.loanApp && !$scope.filterModel.others;
    };

    $scope.filterChanged = (filterInfo) => {
      $scope.filterModel = filterInfo;
    };

    $scope.isAllFilterChecked = () => {
      let toReturnValue = true;
      _.forOwn($scope.filterModel, (object) => {
        if (!object) {
          toReturnValue = false;
        }
      });
      return toReturnValue;
    };

    $scope.isShowLoanDocument = (doc) => {
      return $scope.filterModel.loanApp && doc.DocFromLoanApp;
    };

    $scope.isShowOthersDocument = (doc) => {
      return $scope.filterModel.others && doc.DocFromContact && !$scope.documentIsDuplicate(doc);
    };

    $scope.isShowBanner = () => {
      return !$scope.isLoadingEmails.isTrue() && !$scope.hideDocumentNotification && !$scope.isGdriveOnlyChecked();
    };

    $scope.isShowNoGdriveIntegratedPanel = () => {
      return !$scope.gdriveObject.models.isAuthenticated && $scope.isGdriveOnlyChecked() && !$scope.isLoadingEmails.isTrue();
    };

    const addTagsToDocumentList = (documentsList) => {
      documentsList.forEach((object) => {
        object.tag = object.DocFromLoanApp ? 'loanApp' : 'others';
      });
      return documentsList;
    };

    $scope.documentProgressVisibility = function (visibility) {
      $scope.hideDocumentProgress = parseInt(visibility, 0) < 1;
    };

    $scope.uploadViewSuccess = (files) => {
      angular.forEach(files, (o) => {
        $scope.processDocument(
          {
            Title: o.Name,
            DocumentID: o.DocumentId,
            FamilyID: $scope.familyId,
          }
        );
      });
      $scope.showDragBox = false;
    };

    $scope.openDocumentModal = function (obj, isUpdate) {
      const documentDataObject = obj;
      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/add_document_modal.html',
        controller: 'DocumentModalCtrl',
        scope: $scope,
        resolve: {
          documentDataObject() {
            return documentDataObject;
          },
          isUpdate() {
            return isUpdate;
          },
          userInfo() {
            return $scope.userInfo;
          },
          isClientDocuments() {
            return $scope.isClientDocuments;
          },
        },
      });
    };

    $scope.processDocument = function (obj) {
      const documentDataObject = obj;
      $scope.documentObject = {
        Title: documentDataObject.Title,
        Tags: '',
        Description: '',
        DoucmentID: documentDataObject.DocumentID,
        FamilyID: documentDataObject.FamilyID,
        OrganisationId: $scope.adviserOrgId,
      };
      contactService
        .documentPropertySet([$scope.documentObject])
        .then(() => {
          $scope.loadDocumentList();
          swal({
            title: 'Success',
            text: 'Document has been been successfully uploaded',
            type: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        })
        .catch(displayError);
    };

    $scope.editDocument = function (documentParam) {
      const documentObj = angular.copy(documentParam) || {};
      documentObj.DocumentID = documentParam.DocumentId || documentParam.DocumentID;
      documentObj.Title = documentParam.Name || documentParam.Title;
      if (!_.isUndefined(documentObj.Tags)) {
        documentObj.Tags = documentObj.Tags.join(',');
      }
      documentObj.FamilyID = $scope.familyId;

      $scope.openDocumentModal(documentObj, true);
    };

    // Douments List
    //-------------------------------------------------------------
    $scope.documentsList = [];
    $scope.documentListLoading = true;
    $scope.searchFilesText = '';
    $scope.documentsListLength = 0;
    $scope.searchDocumentFile = null;
    $scope.isDocumentAlreadyLoaded = false;
    let callSearch;
    $scope.searchDocumentCall = function () {
      $timeout.cancel(callSearch);
      callSearch = $timeout(() => {
        $scope.loadDocumentList();
      }, 800);
    };
    $scope.loadDocumentList = function () {
      $scope.isLoadingEmails.documents = true;
      if ($scope.adviserOrgId > 0) {
        $scope.familyId = 0;
      }
      $scope.documentListLoading = true;
      contactService.getDocumentList($scope.familyId, $scope.searchDocumentFile, $scope.adviserOrgId, true).then((result) => {
        $scope.isLoadingEmails.documents = false;
        $scope.documentsList = addTagsToDocumentList(result.data);
        $scope.documentListLoading = false;
        $scope.tableParams = new NgTableParams({
          count: 5,
          sorting: {},
        },
        {
          counts: [],
          total: $scope.documentsList.length,
          getData($defer, params) {
            let filterData = $scope.documentsList;

            if (!_.isEmpty($scope.searchFilesText)) {
              const regExp = new RegExp($scope.searchFilesText, 'i');
              filterData = _.filter($scope.documentsList, (obj) => {
                return (String(obj.Name).match(regExp) || String(obj.Description).match(regExp)
                  || String(obj.uploadUser.UserName).match(regExp) || String($filter('date')(obj.DateCreated, 'dd MMM yyyy')).match(regExp) || String(obj.Tags).match(regExp));
              });
            }
            params.total(filterData.length);
            $defer.resolve(filterData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
            $scope.documentsListLength = filterData.length;
          },
        });
      });
    };

    $scope.rmoveDocument = function (documentId) {
      swal({
        title: 'Please confirm action',
        text: 'Are you sure you want to delete this document? This action cannot be undone',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FA8E91',
        confirmButtonText: 'Delete Document',
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          contactService
            .removeDocument(
              $scope.familyId,
              documentId
            )
            .then(() => {
              $scope.loadDocumentList();
              toaster.pop('success', 'Deleted', 'File has been deleted');
            })
            .catch(displayError);
        }
      });
    };

    // on watch

    if ($stateParams.adviserOrgId) {
      $scope.loadDocumentList();
    }

    // Search Document
    //-------------------------------------------------------------
    $scope.searchDocument = function ($event) {
      $scope.searchFilesText = $event.target.innerText;
      $scope.tableParams.reload();
    };

    // Clear Search Document
    //-------------------------------------------------------------
    $scope.clearSearchDocument = function () {
      $scope.searchFilesText = '';
      angular.element('.search-label').text($scope.searchFilesText);
      $scope.tableParams.reload();
    };

    const loadAllDocumentsProcedure = () => {
      $scope.loadDocumentList();
      if ($scope.isShowGdriveFeature()) {
        $scope.gdriveObject.methods.init();
      }
    };
    $scope.$watch('selectedContacts', (newValue) => {
      if (newValue === 'documents' && !$scope.isDocumentAlreadyLoaded) {
        $scope.isDocumentAlreadyLoaded = true;
        loadAllDocumentsProcedure();
      }
    });

    // Download document using DocumentGet
    $scope.downloadDocument = function (documentID) {
      generalService.documentGet(documentID).then((response) => {
        if (response.data) {
          const data = response.data;
          downloadDataService.download(`data:${data.ContentType};base64,${data.DocumentContent}`, data.Name, data.ContentType);
        }
      });
    };
    // don't remove this!, this is for corporate adviser users
    if ($stateParams.familyId && $stateParams.clientId) {
      loadAllDocumentsProcedure();
    }

    $scope.documentIsDuplicate = function (documentParam) {
      const dupeDoc = _.find($scope.documentsList, (o) => {
        return o.DocumentId === documentParam.DocumentId && o.DocFromLoanApp !== documentParam.DocFromLoanApp;
      });

      if (!dupeDoc) {
        return false;
      }
      return documentParam.DocFromLoanApp;
    };

    $scope.showSelectGdriveFolder = () => {
      $scope.gdriveObject.models.isShowNotAssignedFolder = true;

      const modalInstance = $uibModal.open({
        controller: 'GdriveFolderModalCtrl',
        controllerAs: 'vm',
        templateUrl: '/assets/views/contacts/partials/gdriveFolderModal.html',
        size: 'lg',
        windowTopClass: 'gdriveModal-class',
        resolve: {
        },
      });

      modalInstance.result.then((object) => {
        $scope.gdriveObject.models.isShowNotAssignedFolder = false;
        $scope.selectedGdriveFolderId = object.gdriveFolderId;
        $scope.gdriveObject.models.hasChosenAFolder = object.isChosenAFolder;
        $scope.gdriveObject.methods.init();
      }, () => {
      });
    };

    contactService.getUserInfo().then((response) => {
      $scope.userInfo = response.data;
    });
    $window.authGdrive = function (code) {
      contactService.authGdrive(code).then(() => {
        $scope.showSelectGdriveFolder();
      });
    };

    $scope.accessType = ACCESS_TYPE;
    $scope.getDocumentInfo = (documentInfo) => {
      if (documentInfo.SharedWithCorporateTeamId) return `Just my team (${$scope.userInfo.CorporateTeam})`;
      return `All Corporate Users`;
    };
    $scope.setDocumentInfo = (documentObj) => {
      documentObj.SharedWithCorporateTeamId = documentObj.SharedWithCorporateTeamId ? 0 : $scope.userInfo.CorporateTeamId;
      const obj = {
        Title: documentObj.Name,
        Tags: documentObj.Tags.join(),
        Description: documentObj.Description || '',
        DoucmentID: documentObj.DocumentId,
        FamilyID: $scope.familyId || 0,
        OrganisationId: $scope.adviserOrgId || 0,
        SharedWithCorporateTeamId: documentObj.SharedWithCorporateTeamId,
        UploadedByCorporateTeamId: null,
      };
      contactService.documentPropertySet([obj]).then(() => {
        toaster.pop('success', 'Success', `Successfully set sharing for ${$scope.getSharing(documentObj)}`);
      }, () => {
        toaster.pop('error', 'Failed to Share', 'Unable to set sharing.');
        documentObj.SharedWithCorporateTeamId = documentObj.SharedWithCorporateTeamId ? 0 : $scope.userInfo.CorporateTeamId;
      });
    };

    $scope.overrideByTeam = () => {
      if (!$scope.userInfo || !$scope.userInfo.CorporateTeamId) return false;
      const teamList = [CORPORATE_TEAM.COMPLIANCE];
      return teamList.indexOf($scope.userInfo.CorporateTeamId) !== -1;
    };

    $scope.displaySharing = (obj) => {
      if ($scope.isClientDocuments) return true;
      if (!$scope.userInfo || !$scope.userInfo.CorporateTeamId) return false;
      const splittedTeam = obj.SharedWithCorporateTeamId && obj.SharedWithCorporateTeamId.split(',');
      if (!splittedTeam || !splittedTeam.length) return false;
      const sharedByTeamList = splittedTeam.reduce((accum, value) => {
        accum.push(parseInt(value, 10));
        return accum;
      }, []);
      if (sharedByTeamList.indexOf(SHARING_VALUE.ALL_CORPORATE) !== -1) return true;
      if (sharedByTeamList.indexOf($scope.userInfo.CorporateTeamId) !== -1) return true;
      return false;
    };
    $scope.displayDeleteButton = (obj) => {
      if ($scope.isClientDocuments) return true;
      if (!$scope.userInfo || !$scope.userInfo.FamilyId) return false;
      return obj.uploadUser && parseInt(obj.uploadUser.UserId, 10) === parseInt($scope.userInfo.FamilyId, 10);
    };
  });
