import angular from 'angular';

angular.module('app').directive('pipelineStatusTable', function pipelineStatusTable($timeout) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { myParam: '=' },
    templateUrl: 'assets/views/overviewDashboard/directiveTemplates/pipelineStatusTable.html',

    link(scope) {
      scope.interval = 'week';
      scope.slickConfig = { method: {} };

      scope.testDataContainer = [];
      function init() {
        const totalData = 10;
        const round = (Math.floor(totalData / 4) * 4) - 1;
        const modStart = Math.floor(totalData / 4) * 4;
        const mod = Math.floor(totalData % 4);
        for (let i = 0; i < round; i += 4) {
          const testData = [];
          testData.push({ amount: '$104.8' });
          testData.push({ amount: '$242.54' });
          testData.push({ amount: '$242.54' });
          testData.push({ amount: '$252.54' });


          scope.testDataContainer.push(testData);
        }

        if (parseInt(mod, 10) !== 0) {
          const lastItem = [];
          for (let i = modStart; i < totalData; i++) {
            lastItem.push({ amount: '$242.54' });
          }
          scope.testDataContainer.push(lastItem);
        }

        scope.dataLoaded = true;

        if (scope.testDataContainer.length > 0) {
          $timeout(() => {
            try {
              scope.slickConfig.method.slickGoTo(0);
            } catch (error) {
              // Continue regard less of error
            }
          }, 1000);
        }
      }

      scope.dynamicPopover = {
        content: ' ',
        templateUrl: 'commissionReceivedChartPopover.html',
        title: 'Title',
      };

      scope.doAction = function (msg) {
        if (msg === 'Customise')
          scope.customizeMode = true;
        else
          scope.customizeMode = false;
      };

      init();
    },
  };
});
