import { map, find, isFunction } from 'lodash';

class AnnouncementModalCtrl {
  constructor(
    announcementTryItOut,
    announcementService,
    configService,
    $state,
    $sce
  ) {
    'ngInject';

    this.announcementTryItOut = announcementTryItOut;
    this.announcementService = announcementService;
    this.configService = configService;
    this.$state = $state;
    this.$sce = $sce;
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  togglePreviousRelease() {
    if (this.isPreviousReleaseToggle && this.featuresList && this.featuresList.length) {
      const latestFeature = find(this.featuresList, feature => feature.isNew) || this.featuresList[0];

      if (latestFeature.announcementID !== this.selectedFeature.announcementID) {
        this.chooseFeature(latestFeature);
      }
    }

    this.isPreviousReleaseToggle = !this.isPreviousReleaseToggle;
  }

  toggleVideoMode() {
    this.isVideoMode = !this.isVideoMode;
    this.selectedFeature.isPlayed = true;
  }

  extractThumbnail() {
    if (!this.selectedFeature.attachments) return;

    const imageObj = find(this.selectedFeature.attachments, attachment => attachment.type === 'image') || null;
    if (!imageObj) return;

    this.selectedFeature.thumbnail = imageObj.source;
  }

  extractVideo() {
    if (!this.selectedFeature.attachments) return;

    const videoObj = find(this.selectedFeature.attachments, attachment => attachment.type === 'video') || null;
    if (!videoObj) return;

    const isValid = videoObj.source.indexOf('youtube.com/embed/') !== -1;
    const url = isValid ? this.$sce.trustAsResourceUrl(`${videoObj.source}?autoplay=1`) : null;
    this.selectedFeature.youtubeUrl = url;
  }

  chooseFeature(feature) {
    this.selectedFeature.isToggled = false;
    this.selectedFeature = feature;
    this.selectedFeature.isToggled = true;

    this.extractVideo();
    this.extractThumbnail();
  }

  tryItOut() {
    const findAnnouncementAction = find(this.announcementTryItOut.announcementActions, obj => obj.id === this.selectedFeature.announcementID) || null;
    if (!findAnnouncementAction) return;

    if (findAnnouncementAction.is_route) {
      this.$state.go(findAnnouncementAction.action.route, findAnnouncementAction.action.params);
      this.cancel();
    } else if (!findAnnouncementAction.is_route && isFunction(findAnnouncementAction.action)) {
      findAnnouncementAction.action();
      this.cancel();
    }
  }

  $onInit() {
    this.isPreviousReleaseToggle = false;
    this.isVideoMode = false;
    this.selectedFeature = {};

    if (this.featuresList && this.featuresList.length) {
      this.featuresList = map(this.featuresList, (feature) => {
        feature.isPlayed = false;
        feature.isToggled = false;
        feature.dateStart = feature.dateStart ? new Date(feature.dateStart) : null;

        return feature;
      });

      this.selectedFeature = this.featuresList.length && this.featuresList[0];
      this.selectedFeature.isToggled = true;

      this.extractVideo();
      this.extractThumbnail();
    }
  }
}

export default AnnouncementModalCtrl;
