import angular from 'angular';

angular.module('app').controller('OffsetCalculatorCtrl',
  function OffsetCalculatorCtrl($scope,
    $rootScope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanToolsService,
    $filter,
    $element,
    $window,
    SweetAlert,
    $localStorage,
    DEMO_VIDEO,
    toaster,
    loanCalculatorSharedData,
    formattingService,
    optionsService) {
    // Open Model For Demo Video
    const TOTAL_MONTH_COUNT = 12;
    $scope.openModalForDemoVideo = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId() {
            return DEMO_VIDEO.OFFSET_CALCULATOR.Url;
          },
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };
    optionsService.getLoanTerms().then((loanTermResponse) => {
      $scope.loanTermList = loanTermResponse;
    });
    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    const viewport = function () {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function () {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch($scope.getWindowWidth, (newValue) => {
      $scope.screenSize = newValue.w;
    }, true);

    $scope.series = ['Owning with Offset', 'Owning without Offset'];
    $scope.options = loanCalculatorSharedData.chartOptions;
    $scope.colors = [{
      fillColor: 'rgba(151,187,205,0)',
      strokeColor: '#abc5de',
      pointColor: '#fff',
      pointStrokeColor: '#6297cb',
      pointHighlightFill: '#6297cb',
      pointHighlightStroke: '#6297cb',
    },
    {
      fillColor: 'rgba(220,220,220,0)',
      strokeColor: '#f9b7b7',
      pointColor: '#fff',
      pointStrokeColor: '#fd7575',
      pointHighlightFill: '#fd7575',
      pointHighlightStroke: '#fd7575',
    }];
    $scope.offsetList = { offsetTable: [],
      offsetChart: [],
      offsetTit: [] };
    $scope.offset = { loan: '500,000',
      term: 30,
      rate: '5.00',
      frequency: 'Monthly',
      OffsetAccountBalance: '50,000',
      offsetStartFromYear: '0',
      offsetTopUpPeriod: '100',
      offsetTopUpStartFromYear: '1',
    };

    $scope.showBottom = true;
    $scope.changeShowBottom = function (val) {
      $scope.showBottom = val;
      if (val)
        $scope.constructDataForGraph();
    };

    function getParsed(amount) {
      const replaced = amount.replace(/,/g, '');
      const parsed = parseFloat(replaced);
      return parsed;
    }
    $scope.formatToCurrency = formattingService.calculatorFormatCurrency;
    $scope.formatInterestRate = (amount) => {
      return formattingService.calculatorFormatInterestRate(amount);
    };

    $scope.loadTable = function () {
      const setOffsetFilters = {
        LoanTerm: parseInt($scope.offset.term, 10),
        Frequency: $scope.offset.frequency,
        LoanAmount: parseFloat(getParsed($scope.offset.loan)),
        InterestRate: parseFloat($scope.offset.rate),
        OffsetAccount: {
          AccountBalance: parseFloat(getParsed($scope.offset.OffsetAccountBalance)),
          StartYear: parseInt($scope.offset.offsetStartFromYear, 10),
          TopupAmount: parseInt(getParsed($scope.offset.offsetTopUpPeriod), 10),
          TopupStartYear: parseInt($scope.offset.offsetTopUpStartFromYear, 10),
        },
      };

      loanToolsService.OffsetCalculationsSet(setOffsetFilters)
        .then((response) => {
          $scope.calculationObject = response;
          $scope.tableArray = $scope.calculationObject.repaymentHistory;
          $scope.interestSaved = $scope.calculationObject.interestDifference;

          $scope.constructDataForGraph();
          $scope.constructDataForXSTable();
          const year = $scope.calculationObject.periodDifference > (TOTAL_MONTH_COUNT - 1) ? parseInt($scope.calculationObject.periodDifference / TOTAL_MONTH_COUNT, 10) : '';
          const month = $scope.calculationObject.periodDifference > TOTAL_MONTH_COUNT ? ($scope.calculationObject.periodDifference % TOTAL_MONTH_COUNT) || '' : $scope.calculationObject.periodDifference;
          $scope.timeSaved = year && `${year}Years`;
          $scope.timeSaved += month && ` ${month}Months`;
        });
    };


    $scope.constructDataForGraph = function () {
      const arrWithOffset = [];
      const arrWithoutOffset = [];
      $scope.labels = [];
      $scope.graphData = [];
      $scope.data = $scope.calculationObject.offsetPaymentChart.reduce((list, item, index) => {
        arrWithOffset.push(parseInt(item.principalToPaywithOffset, 10).toFixed(2));
        arrWithoutOffset.push(parseInt(item.principalToPaywithoutOffset, 10).toFixed(2));
        $scope.labels.push(index);
        list[0] = arrWithOffset;
        list[1] = arrWithoutOffset;
        return list;
      }, []);
      $scope.graphData = $scope.data;
    };


    $scope.constructDataForXSTable = function () {
      let yrCount = 1;
      let prdCount = 1;

      $scope.offsetTableArrayXs = [];
      const splicedArray = Object.assign([], $scope.calculationObject.repaymentHistory);
      splicedArray.splice(0, 1);
      $scope.offsetTableArrayXs = splicedArray.reduce((list, item) => {
        const count = item.period;
        if (count > TOTAL_MONTH_COUNT) {
          const reminder = count % TOTAL_MONTH_COUNT;
          yrCount = reminder === 0 ? parseInt((count / TOTAL_MONTH_COUNT), 10) : parseInt((count / TOTAL_MONTH_COUNT), 10) + 1;
          prdCount = reminder === 0 ? TOTAL_MONTH_COUNT : reminder;
        } else {
          prdCount = count;
        }
        item.selectText = `Yearly ${yrCount} Period ${prdCount}`;
        item.yearText = `Yearly ${yrCount}`;
        item.periodText = `Period ${prdCount}`;
        list.push(item);
        return list;
      }, []);
      $scope.selectedOffsetXs = $scope.offsetTableArrayXs[0];
    };

    function getLendersList() {
      loanToolsService.getLendersList()
        .then((response) => {
          $scope.lenderList = response.data;
        });
    }
    $scope.openPdfPreviewModal = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/loanCalculator/partials/offset_pdf.html',
        resolve: {
          graphData() {
            return $scope.graphData;
          },
          options() {
            return $scope.options;
          },
          labels() {
            return $scope.labels;
          },
          colors() {
            return $scope.colors;
          },
          loanAmount() {
            return $scope.offset.loan;
          },
          years() {
            return $scope.offset.term;
          },
          interestRate() {
            return $scope.offset.rate;
          },
          frequency() {
            return $scope.offset.frequency;
          },
          interestSaved() {
            return $scope.interestSaved;
          },
          timeSaved() {
            return $scope.timeSaved;
          },
          series() {
            return $scope.series;
          },
        },
        controller: 'OffsetPdfPreviewModalCtrl',
        size,

      });
    };

    function init() {
      $scope.loadTable();
      getLendersList();
    }

    init();
  });
