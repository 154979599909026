import { ENTITY_PARTY_TYPES, PERSON_PARTY_TYPES } from 'Common/constants/partyTypes';

class PartyTypeService {
  getPartyType(typeId) {
    this.partyType = '';
    if (typeId) {
      const id = parseInt(typeId, 10);
      switch (id) {
      case ENTITY_PARTY_TYPES.COMPANY:
        this.partyType = 'Company';
        break;
      case ENTITY_PARTY_TYPES.PARTNERSHIP:
        this.partyType = 'Partnership';
        break;
      case PERSON_PARTY_TYPES.APPLICANT:
        this.partyType = 'Applicant';
        break;
      case PERSON_PARTY_TYPES.GUARANTOR:
        this.partyType = 'Guarantor';
        break;
      case PERSON_PARTY_TYPES.DEPENDENT:
        this.partyType = 'Dependant';
        break;
      case PERSON_PARTY_TYPES.SOLICITOR:
        this.partyType = 'Solicitor';
        break;
      case PERSON_PARTY_TYPES.ACCOUNTANT:
        this.partyType = 'Accountant';
        break;
      default:
        break;
      }
    }
    return this.partyType;
  }
}

export default PartyTypeService;
