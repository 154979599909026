import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import swal from 'sweetalert';
import { LOAN_APP_SUBMISSION_METHODS } from 'Common/constants/loanAppSubmissionMethods';
import { LOAN_APP_REFERRAL_QUESTION, LOAN_APP_ENQUIRY_SOURCE, REFERRAL_TYPE } from 'Common/constants/referralType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { LOAN_SUBMISSION_EMAIL_TYPE } from 'Common/constants/loanSubmissionEmailType';
import { SECTION_ID, NOTES_TO_SHOW_QUESTION_ID } from 'Common/constants/loanappSectionIds';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { CLIENT_REFERRAL, ADDITIONAL_REFERRAL } from 'Common/constants/enquirySource';
import { displayField } from 'Common/utilities/displayField';
import { showActionConfirmation, toastError } from 'Common/utilities/alert';

angular.module('app').controller('LoanAppLoanSubmissionCtrl',
  function LoanAppLoanSubmissionCtrl(
    $q,
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    $uibModal,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    optionsService,
    nextGenLoanAppService,
    $window,
    questionOptionService,
    dashboardService,
    regionalizationService,
    loanAppSharedData,
    $sce,
    generalService,
    simpologyLoanAppService,
    popupBlockerService,
    loanAppSubmissionValidationService,
    loanSubmissionEmailContentService,
    financialsSharedService,
    watcherService,
    uiService,
    $filter,
    SweetAlert,
    enquirySourceService,
    employmentService
  ) {
    let clickCounter = 0;
    $scope.uiService = uiService;
    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    $scope.assignedAdviserId = 0;
    const getBrokerId = () => {
      contactService.contactFamilyInfoGet($state.params.familyId).then((response) => {
        const { data } = response;
        if (!data) return;
        $scope.assignedAdviserId = data.BrokerID || 0;
      });
    };
    getBrokerId();
    $scope.clientEnquiryList = CLIENT_REFERRAL;
    $scope.additionalEnquiryList = ADDITIONAL_REFERRAL;
    /* end of listeners */
    const updateLenderData = () => {
      const { LenderName, LenderId, OtherLenderName, IsOnlineAvailable } = loanAppSharedData.loanAppSummary.LoanDetails;
      if (!$scope.loanAppLenderDetailObject) {
        $scope.loanAppLenderDetailObject = {};
      }
      $scope.loanAppLenderDetailObject.LenderName = LenderName || null;
      $scope.loanAppLenderDetailObject.LenderId = LenderId || null;
      $scope.loanAppLenderDetailObject.OtherLenderName = OtherLenderName;
      $scope.loanAppLenderDetailObject.IsOnlineAvailable = IsOnlineAvailable;

      const strLenderId = LenderId ? LenderId.toString().trim() : null;
      if (!$scope.loanAppLender) {
        $scope.loanAppLender = {};
      }
      $scope.loanAppLender.LenderId = strLenderId;
      loanAppSharedData.lenderId = strLenderId;

      if (uiService.isCountry('Australia') && $scope.LenderSubmissionSet) {
        $scope.LenderSubmissionSet.SubmissionMethod = $scope.loanAppLenderDetailObject.LenderId ? 'nextgen' : null;
      }
      $scope.checkSubmitOnlineDisabled();
      $scope.checkSubmitOnline();
      $scope.checkSubmissionEnabled();
    };

    const populateReferredBy = () => {
      $scope.referrerHelper = {};
      if (loanAppSharedData.loanAppSummary.EnquirySource.CategoryName !== 'Referral') {
        optionsService.getEnquirySourceList().then((response) => {
          let { data: referrerObject } = response;
          if (!referrerObject) {
            referrerObject = {};
          }
          referrerObject = referrerObject.find(object => object.ReferralCategoryId === loanAppSharedData.loanAppSummary.EnquirySource.ReferralCategoryId);
          if (referrerObject) {
            referrerObject.EnquirySourceItem.find(iteretee => iteretee.ReferralItemId === loanAppSharedData.loanAppSummary.EnquirySource.ReferralItemId);
            $scope.referrerHelper.ReferrerBy = (referrerObject && referrerObject.ReferralItemName) || '';
          }
        });
      } else {
        contactService.contactFamilyInfoGet($state.params.familyId).then((response) => {
          optionsService.getReferencedReferralList(response.data.BrokerID, '').then((responseReferral) => {
            let { data: referrerObject } = responseReferral;
            referrerObject = referrerObject.find(object => object.ReferralOrganizationId === loanAppSharedData.loanAppSummary.EnquirySource.ReferrerOrgId && object.ReferralMemberId === loanAppSharedData.loanAppSummary.EnquirySource.ReferrerId);
            if (referrerObject) {
              $scope.referrerHelper.ReferrerBy = `${referrerObject.ReferralMemberName} - ${referrerObject.ReferralOrganizationName}`;
            }
          });
        });
      }
    };

    $scope.checkSubmissionEnabled = () => {
      $scope.isSubmissionEnabled = !$scope.isLoadingLoanSubmission && (!uiService.isCountry('New Zealand') || ($scope.loanAppLenderDetailObject && $scope.loanAppLenderDetailObject.LenderId));
    };

    $scope.checkSimpologyEnabled = () => {
      const selectedLenderId = $scope.loanAppLenderDetailObject ? parseInt($scope.loanAppLenderDetailObject.LenderId, 10) : 0;
      const isLenderForSimpology = selectedLenderId === parseInt(loanAppSharedData.simpology.bnzId, 10);
      const isSimpologyUser = loanAppSharedData.LoanApplicationDetails && loanAppSharedData.LoanApplicationDetails.IsSimpologyUser;
      const isSimpologyEnabled = uiService.isCountry('New Zealand') && isLenderForSimpology && isSimpologyUser;
      return isSimpologyEnabled;
    };

    $scope.checkSubmitOnline = () => {
      const submissionMethod = $scope.LenderSubmissionSet && $scope.LenderSubmissionSet.SubmissionMethod;
      const isNextGen = submissionMethod === 'nextgen';
      const isSimpology = submissionMethod === 'simpology';
      $scope.isSubmitOnline = isNextGen || isSimpology;
    };

    $scope.checkSubmitOnlineDisabled = () => {
      const isNextGenAvailable = $scope.loanAppLenderDetailObject && $scope.loanAppLenderDetailObject.IsOnlineAvailable;
      const isNextGenDisabled = !isNextGenAvailable && uiService.isCountry('Australia');
      const isSimpologyDisabled = !$scope.checkSimpologyEnabled() && uiService.isCountry('New Zealand');
      $scope.isSubmitOnlineDisabled = isNextGenDisabled || isSimpologyDisabled;
    };

    $scope.CPADeclarationGet = () => {
      loanScenarioService.CPADeclarationsGet($scope.loanAppId).then((response) => {
        _.forEach(response.data, (obj) => {
          const answerKey = questionOptionService.getAnswerType(obj.QuestionId, $scope.loanSubmissionDeclarations);
          $scope.declarationsValueSet[obj.QuestionId] = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
        });

        $scope.validateDeclarations();
      });
    };

    $scope.initializeDeclarations = () => {
      optionsService.question('Loan Submission Declaration').then((response) => {
        $scope.declarationsValueSet = {};
        $scope.loanSubmissionDeclarations = response.data;
        $scope.loanSubmissionDeclarations && $scope.loanSubmissionDeclarations.forEach((obj) => {
          $scope.declarationsValueSet[obj.QuestionId] = null;
        });
        $scope.CPADeclarationGet();
      });
    };

    $scope.validateDeclarations = () => {
      let validDeclarationProp = false;
      let countDeclarationPop = 0;
      $scope.declarationsValueSet && Object.keys($scope.declarationsValueSet).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call($scope.declarationsValueSet, key)) {
          validDeclarationProp = $scope.declarationsValueSet[key] !== null;
          if (validDeclarationProp) {
            countDeclarationPop++;
          }
        }
      });

      $scope.isStartedDeclarations = countDeclarationPop >= 1;
      $scope.isValidDeclarations = validDeclarationProp && countDeclarationPop === ($rootScope.crmCountry === 'New Zealand' ? 1 : 3);

      const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.DECLARATIONS);
      loanAppSharedData.completionStatusSet(SECTION_ID.DECLARATIONS, sectionObject.IsCompleted, $scope.isValidDeclarations);

      $scope.countValids($scope.isValidDeclarations, 'declarations');
    };

    loanApplicationServices.loanSummaryGet($state.params.loanAppId).then((loanSummary) => {
      $scope.loanSummary = loanSummary.data;
      $scope.processLoanAppLoanSubmission = () => {
        updateLenderData();
        if (clickCounter) {
          $scope.loanSubmissionLoaded = true;
          $scope.loanSubmissionLoading = false;
          return;
        }
        clickCounter++;
        $scope.loanSubmissionLoading = true;

        /**
         * Models
         * */
        angular.extend($scope, {
          isEnquirySourceDropdownEnabled: true,
          hasAnswer: false,
          DeclarationsSet: {},
          BrokerNotesSet: {},
          ReferrerInfo: {},
          LenderSubmissionSet: {},
          LenderSubmission: {},

          // Referrer
          referrerValueSet: {},
          referrerOrgMemberList: [],

          // Broker Notes
          brokernotesValueSet: {},

          // Lender Submission
          lenderSubmissionValueSet: {},

          // Branches
          showFindBranch: false,
          selectedBranch: '',

          // Enquiry Source
          showReferralInfo: false,
          getClientList: [],
          referrerHelper: {
            showClientList: false,
            ReferrerName: '',
          },
          submitObj: {
            isProcessingView: false,
            displayGeneratedWorkSheet: false,
            isGeneratingWorkSheet: false,
          },
          loanAppSharedData,

        });
        const lenderPlace = 1;

        /**
         * Methods
         * */
        angular.extend($scope, {
          /**
           * Generate worksheet for NZ
           */
          listOfLenders: ['13', '14', '16', '1'],

          generateWorksheet: () => {
            if ($scope.listOfLenders.indexOf(loanAppSharedData.lenderId) !== -1) {
              $scope.submitObj.isGeneratingWorkSheet = true;
              loanScenarioService.LoanAppProviderWorksheetGet($scope.loanAppId).then((response) => {
                loanAppSharedData.worksheet = _.map(response.data, (obj) => {
                  obj.extension = 'xlsx';
                  return obj;
                });

                $scope.submitObj.displayGeneratedWorkSheet = true;
                $scope.submitObj.isGeneratingWorkSheet = false;
              }, () => {
                toaster.pop('error', 'Unable to Generate Worksheet', 'There seems to be an issue on generating the worksheet. Please try again');
                $scope.submitObj.isGeneratingWorkSheet = false;
              });
            }
          },

          isMainLender: (lenderId) => {
            return _.includes($scope.listOfLenders, lenderId);
          },

          removeGeneratedWorksheet: () => {
            loanAppSharedData.worksheet = null;
          },

          deleteAttachedWorksheet: (worksheet) => {
            showActionConfirmation('Are you sure?', 'This will remove the uploaded worksheet.', (confirm) => {
              if (!confirm) return;
              const { DocumentId: docId, LoanScenarioDocumentID: loanScenarioDocId } = worksheet;
              loanScenarioService.documentDelete($scope.loanAppId, docId, loanScenarioDocId)
                .then(() => {
                  $scope.getDocumentListLenderWorksheet();
                  swal('Success', 'Document has been successfully deleted.', 'success');
                })
                .catch(toastError);
            });
          },

          notGenerateWorksheetProceedure: () => {
            $scope.removeGeneratedWorksheet();
            $scope.isGenerateWorksheetUpdate('IsGenerateWorksheet', $scope.LenderSubmissionSet.isGenerateWorksheet);
          },

          isGenerateWorksheetUpdate: (type, value) => {
            // type for now is only 'IsGenerateWorksheet'
            $scope[`loanSubmission${type}`] = {
              QuestionDisplayTypeId: 1,
              QuestionId: 124,
            };
            $scope.updateLoanSubmission(type, value);
          },

          /**
           * Broker Notes
           * */
          validateBrokerNotes() {
            let validBrokerNotesProp = false;
            let countBrokerNotesPop = 0;
            $scope.brokernotesValueSet && Object.keys($scope.brokernotesValueSet).forEach((key) => {
              if (Object.prototype.hasOwnProperty.call($scope.brokernotesValueSet, key)) {
                // temp fix for the broker notes purpose
                if ($scope.brokernotesValueSet[key] === 'Owner Occupied' || $scope.brokernotesValueSet[key] === 'Investment') {
                  validBrokerNotesProp = false;
                } else {
                  validBrokerNotesProp = $scope.brokernotesValueSet[key] !== '' && $scope.brokernotesValueSet[key] !== null;
                }
                if (validBrokerNotesProp) {
                  countBrokerNotesPop++;
                }
              }
            });
            $scope.isStartedBrokerNotes = countBrokerNotesPop >= 1;
            $scope.isValidBrokerNotes = validBrokerNotesProp && countBrokerNotesPop === ($rootScope.crmCountry === 'Australia' ? 1 : 3);

            const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.BROKER_ADVISER_NOTES);
            loanAppSharedData.completionStatusSet(SECTION_ID.BROKER_ADVISER_NOTES, sectionObject.IsCompleted, $scope.isValidBrokerNotes);

            $scope.countValids($scope.isValidBrokerNotes, 'notes');
          },

          /**
           * Referrer Information
           * */
          validateReferrerInfo: () => {
            const isNZ = uiService.isCountry('New Zealand');
            if ($scope.loanSubmissionReferrer && !isNZ) {
              const isReferral = $scope.showReferralInfo;
              const hasReferredBy = $scope.referrerHelper.ReferrerBy && $scope.referrerHelper.ReferrerBy !== 'Referral';
              $scope.hasAnswer = _.size($scope.selectedEnquirySource) > 0;
              $scope.isStartedReferrer = $scope.hasAnswer;
              $scope.isValidReferrer = $scope.hasAnswer && isReferral === false ? true : hasReferredBy;
              $scope.countValids($scope.isValidReferrer, 'referrer');
            } else {
              $scope.isStartedReferrer = isNZ;
              $scope.isValidReferrer = isNZ;
            }

            if (uiService.isCountry('Australia')) {
              const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.REFERRER_INFORMATION);
              loanAppSharedData.completionStatusSet(SECTION_ID.REFERRER_INFORMATION, sectionObject.IsCompleted, $scope.isValidReferrer);
            }
          },

          /**
           * Lender submission
           * */
          validateLenderSubmission: () => {
            $scope.isValidLenderSubmission =
              ($scope.loanAppLenderDetailObject !== null) &&
              (($scope.loanAppLenderDetailObject && $scope.loanAppLenderDetailObject.LenderName === 'ASB' &&
                (
                  $scope.LenderSubmissionSet.BrokerCentreOrBranch &&
                  (($scope.LenderSubmissionSet.BusinessLoan && $scope.LenderSubmissionSet.TurnOver) || !$scope.LenderSubmissionSet.BusinessLoan)
                )
              ) ||
                ($scope.loanAppLenderDetailObject && $scope.loanAppLenderDetailObject.LenderName !== 'ASB')
              ) &&
              (
                (
                  $scope.LenderSubmissionSet.SubmissionMethod === 'nextgen' && $scope.isNextGenSubmitted
                ) ||
                loanAppSubmissionValidationService.canSubmitViaEmail($scope.LenderSubmissionSet, $scope.loanAppLenderDetailObject) ||
                (loanAppSubmissionValidationService.canSubmitViaSimpology($scope.LenderSubmissionSet, $scope.loanAppLenderDetailObject)) ||
                loanAppSubmissionValidationService.canSubmitViaPrintedApp($scope.LenderSubmissionSet, $scope.loanAppLenderDetailObject)
              ) &&
              (
                (!$scope.isMainLender($scope.loanAppSharedData.lenderId)) ||
                (($scope.LenderSubmissionSet.isGenerateWorksheet === true) || ($scope.LenderSubmissionSet.isGenerateWorksheet === false && $scope.loanAppSharedData.documentListLenderWorksheet.length > 0))
              );

            $scope.isStartedLenderSubmission =
              ($scope.LenderSubmissionSet.ChooseLender !== null) ||
              (($scope.loanAppLenderDetailObject && $scope.loanAppLenderDetailObject.LenderName === 'ASB' &&
                (
                  $scope.LenderSubmissionSet.BrokerCentreOrBranch &&
                  (($scope.LenderSubmissionSet.BusinessLoan && $scope.LenderSubmissionSet.TurnOver) || !$scope.LenderSubmissionSet.BusinessLoan)
                )
              ) ||
                ($scope.loanAppLenderDetailObject && $scope.loanAppLenderDetailObject.LenderName !== 'ASB')
              ) ||
              (($scope.LenderSubmissionSet.SubmissionMethod === 'nextgen' && $scope.isNextGenSubmitted) || ($scope.LenderSubmissionSet.SubmissionMethod === 'email' &&
                ($scope.LenderSubmissionSet.CustomEmailSubject !== '' && $scope.LenderSubmissionSet.CustomEmailSubject !== null) &&
                ($scope.LenderSubmissionSet.CustomEmailContent !== '' && $scope.LenderSubmissionSet.CustomEmailContent !== null)));

            if (uiService.isCountry('New Zealand')) {
              $scope.countValids($scope.isValidLenderSubmission, 'submit');
            }
            const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.LENDER_SUBMISSION);
            loanAppSharedData.completionStatusSet(SECTION_ID.LENDER_SUBMISSION, sectionObject.IsCompleted, $scope.isValidLenderSubmission);

            $scope.checkSubmissionEnabled();
          },

          getLenderId: () => {
            $scope.LenderSubmissionSet.ChooseLender = loanAppSharedData.lenderId;
            return loanAppSharedData.lenderId;
          },

          /**
           * Question Set
           *
           * @param type
           * @param displayOptionId - option id on question
           * @param questionid - question id on object
           * @param apiMethod
           * @param callback
           */
          updateQuestionSet: (type, apiMethod, displayOptionId, questionid, callback) => {
            const loanSubmissionObj = `loanSubmission${type}`;
            const questionObjValue = `question${type}Set`;
            const valueSet = `${type.toLowerCase()}ValueSet`;
            const answerTypeValue = questionOptionService.getAnswerType(questionid, $scope[loanSubmissionObj]);
            let displayOptionIdSet;
            if (typeof displayOptionId === 'undefined') {
              displayOptionIdSet = questionOptionService.getDisplayOptionId(questionid, $scope[loanSubmissionObj]);
            } else {
              displayOptionIdSet = displayOptionId;
            }

            $scope[questionObjValue] = {
              LoanScenarioId: $scope.loanAppId,
              FamilyId: $scope.familyId,
              QuestionId: questionid,
              DisplayOptionId: displayOptionIdSet,
            };
            if (answerTypeValue) {
              const answerValue = questionOptionService.setAnswerValue(answerTypeValue, $scope[valueSet][questionid]);
              $scope[questionObjValue][answerTypeValue] = [answerValue];
              loanScenarioService[apiMethod]($scope[questionObjValue]).then(() => {
                callback();
              });
            }
          },


          /**
           * Declaration GET & SET
           */
          updateQuestionDeclaration: (questionid, displayOptionId) => {
            $scope.updateQuestionSet('Declarations', 'CPADeclarationsSet', displayOptionId, questionid, $scope.CPADeclarationGet);
          },

          processReferrerAnswer: (answer) => {
            $scope.referrerHelper.resetReferralInfo();
            if (!answer) $scope.referrerHelper.ReferrerBy = $scope.referrerHelper.ReferrerByDuplicate;
          },

          /**
           * Referrer GET & SET
           */
          referrerInfoGet: () => {
            loanScenarioService.referrerInfoGet($scope.loanAppId).then((response) => {
              _.forEach(response.data, (obj) => {
                const answerKey = questionOptionService.getAnswerType(obj.QuestionId, $scope.loanSubmissionReferrer);

                if (obj.QuestionId === LOAN_APP_REFERRAL_QUESTION.IS_CUSTOMER_REFERRAL) { // main `yes or no`
                  $scope.isReferredByCompany = obj[answerKey][0];
                }
                if (answerKey === 'answerInt') {
                  $scope.referrerValueSet[obj.QuestionId] = obj[answerKey][0];
                } else {
                  $scope.referrerValueSet[obj.QuestionId] = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                }
              });
              $scope.validateReferrerInfo();
              if (!$scope.showReferralInfo) {
                $scope.referrerLoaded = true;
              }
            });
          },

          updateQuestionReferrer: (questionid, displayOptionId, touched) => {
            $scope.isReferredByCompany = $scope.referrerValueSet[questionid];
            if (touched) {
              $scope.processReferrerAnswer($scope.isReferredByCompany);
            }
            $scope.updateQuestionSet('Referrer', 'referrerInfoSet', displayOptionId, questionid, $scope.referrerInfoGet);
          },

          /**
           * Broker Notes GET & SET
           */
          getLoanAppPurpose: (questionId, displayTypeId) => {
            const purposeFiltered = _.find($scope.brokerNotes.LoanPurposeList, (obj) => {
              return obj.Value === loanAppSharedData.loanAppPurpose;
            });

            if (purposeFiltered) {
              if ($scope.brokernotesValueSet[questionId] !== purposeFiltered.Name && typeof purposeFiltered.Name !== 'undefined') {
                $scope.brokernotesValueSet[questionId] = purposeFiltered.Name;
                $scope.updateQuestionSet('BrokerNotes', 'loanSubmissionBrokerNotesSet', displayTypeId, questionId, $scope.loanSubmissionBrokerNotesGet);
              }
            }
            if (!$scope.brokernotesValueSet[questionId]) {
              $scope.brokernotesValueSet[questionId] = 'Owner Occupied';
            }
            return $scope.brokernotesValueSet[questionId];
          },

          loanSubmissionBrokerNotesGet: () => {
            loanScenarioService.loanSubmissionBrokerNotesGet($scope.loanAppId).then((response) => {
              _.forEach(response.data, (obj) => {
                const answerKey = questionOptionService.getAnswerType(obj.QuestionId, $scope.loanSubmissionBrokerNotes);
                const characterObj = _.find($scope.loanSubmissionBrokerNotes, (cObj) => {
                  if ($rootScope.crmCountry === 'Australia') {
                    return cObj.QuestionContent === 'Additional Info / Notes';
                  }
                  return cObj.QuestionContent === 'Client Background / Character';
                });
                $scope.brokernotesValueSet[obj.QuestionId] = (typeof obj[answerKey] !== 'undefined' && obj[answerKey][0] !== 'null' && obj[answerKey][0]) ? obj[answerKey][0] : '';
                // will set the summary for broker notes
                if (characterObj.QuestionId === obj.QuestionId) {
                  $scope.loanSubmissionBrokerNotesSummary = $scope.brokernotesValueSet[obj.QuestionId];
                }
              });
            });
          },

          brokerNotesLabel: uiService.brokerNotesLabel(),

          changeBrokerNotesSummary(note) {
            $scope.loanSubmissionBrokerNotesSummary = note;
          },

          updateQuestionBrokerNotes: (questionid, displayOptionId) => {
            $scope.updateQuestionSet('BrokerNotes', 'loanSubmissionBrokerNotesSet', displayOptionId, questionid, () => {
            });
          },

          /**
           * Lender Submission
           */
          lenderSubmissionGet: () => {
            loanScenarioService.lenderSubmissionGet($scope.loanAppId).then((response) => {
              $scope.LenderSubmissionSet.BusinessLoan = false;
              _.forEach(response.data, (obj) => {
                let answerKey;
                if (_.isNil(obj.QuestionId)) {
                  return;
                }
                const copyToAdviserQuestionId = $scope.loanSubmissionCopyToAdviser ? $scope.loanSubmissionCopyToAdviser.QuestionId : -1;
                const adviserEmailAddressQuestionId = $scope.loanSubmissionAdviserEmailAddress ? $scope.loanSubmissionAdviserEmailAddress.QuestionId : -1;
                const lenderEmailAddressQuestionId = $scope.loanSubmissionLenderEmailAddress ? $scope.loanSubmissionLenderEmailAddress.QuestionId : -1;

                switch (obj.QuestionId) {
                case $scope.loanSubmissionChooseLender.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionChooseLender]);
                  $scope.LenderSubmissionSet.ChooseLender = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case $scope.loanSubmissionChooseBranch.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionChooseBranch]);
                  const selectedBranchId = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  if (selectedBranchId !== null) {
                    loanScenarioService.lenderBranchDetailGet($scope.loanAppId, selectedBranchId).then((lenderResponse) => {
                      $scope.selectedBranch = lenderResponse.data;
                      $scope.selectedBranch.logo = '/assets/images/lenderLogos/default.png';
                      $scope.showFindBranch = true;
                    });
                  }
                  break;
                }
                case $scope.loanSubmissionMethod.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionMethod]);
                  $scope.LenderSubmissionSet.SubmissionMethod = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case $scope.loanSubmissionCopyToClient.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionCopyToClient]);
                  $scope.LenderSubmissionSet.CopyToClient = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case $scope.loanSubmissionClientEmailAddress.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionClientEmailAddress]);
                  $scope.LenderSubmissionSet.ClientEmailAddress = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case copyToAdviserQuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionCopyToAdviser]);
                  $scope.LenderSubmissionSet.CopyToAdviser = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case adviserEmailAddressQuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionAdviserEmailAddress]);
                  $scope.LenderSubmissionSet.AdviserEmailAddress = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case lenderEmailAddressQuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionLenderEmailAddress]);
                  $scope.LenderSubmissionSet.LenderEmailAddress = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case $scope.loanSubmissionCustomEmailSubject.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionCustomEmailSubject]);
                  $scope.LenderSubmissionSet.CustomEmailSubject = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case $scope.loanSubmissionCustomEmailContent.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionCustomEmailContent]);
                  $scope.LenderSubmissionSet.CustomEmailContent = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case $scope.loanSubmissionBrokerCentreOrBranch.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionBrokerCentreOrBranch]);
                  $scope.LenderSubmissionSet.BrokerCentreOrBranch = typeof obj[answerKey] !== 'undefined' ? String(obj[answerKey][0]) : null;
                  break;
                }
                case $scope.loanSubmissionBusinessLoan.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionBusinessLoan]);
                  $scope.LenderSubmissionSet.BusinessLoan = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  break;
                }
                case $scope.loanSubmissionTurnOver.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionTurnOver]);
                  $scope.LenderSubmissionSet.TurnOver = typeof obj[answerKey] !== 'undefined' ? String(obj[answerKey][0]) : null;
                  break;
                }
                case $scope.loanSubmissionGenerateWorksheetQuestion.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.loanSubmissionGenerateWorksheetQuestion]);
                  $scope.LenderSubmissionSet.isGenerateWorksheet = typeof obj[answerKey] !== 'undefined' ? obj[answerKey][0] : null;
                  if ($scope.LenderSubmissionSet.isGenerateWorksheet === true && $scope.isMainLender(loanAppSharedData.lenderId))
                    $scope.generateWorksheet();
                  else {
                    $scope.LenderSubmissionSet.isGenerateWorksheet = false;
                  }
                  break;
                }
                case $scope.applicantEmailSubjectQuestion.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.applicantEmailSubjectQuestion]);
                  const answer = obj[answerKey];
                  $scope.LenderSubmissionSet.ApplicantEmailSubject = answer ? answer[0] : null;
                  break;
                }
                case $scope.applicantEmailBodyQuestion.QuestionId: {
                  answerKey = questionOptionService.getAnswerType(obj.QuestionId, [$scope.applicantEmailBodyQuestion]);
                  const answer = obj[answerKey];
                  $scope.LenderSubmissionSet.ApplicantEmailBody = answer ? answer[0] : null;
                  break;
                }
                default: {
                  break;
                }
                }
              });

              // special case for Adviser Email
              if (!$scope.LenderSubmissionSet.AdviserEmailAddress && $rootScope.crmCountry === 'New Zealand') {
                $scope.updateLoanSubmission('AdviserEmailAddress', loanAppSharedData.LoanApplicationDetails.AdviserEmail);
              }

              if (uiService.isCountry('New Zealand')) {
                if (!$scope.isLenderSubmissionInitialized) {
                  $scope.checkLenderEmailAddressState();
                }
                $scope.loadDefaultClientEmailAddress();
                $scope.loadDefaultEmailContent();
              }
              // copy values to LenderSubmission
              $scope.LenderSubmission = _.clone($scope.LenderSubmissionSet);
              $scope.isLenderSubmissionInitialized = true;
            });
          },

          getDocumentListLenderWorksheet: () => {
            loanScenarioService.documentListGetLenderWorksheet($scope.loanAppId).then((response) => {
              loanAppSharedData.documentListLenderWorksheet = response.data || [];
            });
          },

          getDocumentType: (type) => {
            const contentType = type.toLowerCase();
            switch (contentType) {
            case 'application/pdf':
              return 'assets/images/icon/pdf.png';
            case 'application/msword':
              return 'assets/images/icon/word.png';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
              return 'assets/images/icon/xls.png';
            default:
              break;
            }
          },

          updateLoanSubmission: (type, dataValue) => {
            let value = '';
            if (typeof dataValue !== 'undefined') {
              value = dataValue;
            } else if (typeof $scope.LenderSubmissionSet[type] !== 'undefined') {
              value = $scope.LenderSubmissionSet[type];
            }
            const lenderSubmissionQuestion = $scope[`loanSubmission${type}`];
            if (typeof lenderSubmissionQuestion === 'undefined') {
              return false;
            }
            const lenderSubmissionObj = {
              LoanScenarioId: $scope.loanAppId,
              FamilyId: $scope.familyId,
              QuestionId: lenderSubmissionQuestion.QuestionId,
              DisplayOptionId: lenderSubmissionQuestion.QuestionDisplayTypeId,
            };
            switch (type) {
            case 'ChooseLender':
              value = parseInt(value, 10);
              loanAppSharedData.lenderId = String(value);
              lenderSubmissionObj.answerInt = [value];
              break;
            case 'CustomEmailSubject':
              value = String(value);
              lenderSubmissionObj.answerString = [value];
              break;
            case 'CustomEmailContent':
              value = String(value);
              lenderSubmissionObj.answerString = [value];
              break;
            case 'CopyToClient':
              value = String(value);
              lenderSubmissionObj.answerBool = [value];
              break;
            case 'ClientEmailAddress':
              value = String(value);
              lenderSubmissionObj.answerString = [value];
              $scope.lenderSubmissionEmailAddressFields.switchToViewMode('client');
              $scope.LenderSubmission.ClientEmailAddress = value;
              break;
            case 'CopyToAdviser':
              value = String(value);
              lenderSubmissionObj.answerBool = [value];
              break;
            case 'AdviserEmailAddress':
              value = String(value);
              lenderSubmissionObj.answerString = [value];
              $scope.lenderSubmissionEmailAddressFields.switchToViewMode('adviser');
              $scope.LenderSubmission.AdviserEmailAddress = value;
              break;
            case 'LenderEmailAddress':
              value = String(value);
              lenderSubmissionObj.answerString = [value];
              $scope.lenderSubmissionEmailAddressFields.switchToViewMode('lender');
              $scope.LenderSubmission.LenderEmailAddress = value;
              break;
            case 'BrokerCentreOrBranch':
              value = String(value);
              lenderSubmissionObj.answerInt = [value];
              break;
            case 'BusinessLoan':
              value = String(value);
              lenderSubmissionObj.answerBool = [value];
              break;
            case 'TurnOver':
              value = String(value);
              lenderSubmissionObj.answerInt = [value];
              break;
            case 'IsGenerateWorksheet':
              value = String(value);
              lenderSubmissionObj.answerBool = [value];
              break;
            default:
              break;
            }
            loanScenarioService.lenderSubmissionSet(lenderSubmissionObj).then(() => {
              $scope.lenderSubmissionGet();
            });
          },

          /**
           * Main Lender submission method
           */
          submitLenderSubmission: (dontCheckEmployment) => {
            $scope.isLoadingLoanSubmission = true;
            if (uiService.isCountry('New Zealand')) {
              $scope.formSubmissionLender(dontCheckEmployment);
            } else {
              const callback = () => {
                $scope.formSubmissionLender(dontCheckEmployment);
              };
              const errorcallback = () => {
                $scope.isLoadingLoanSubmission = false;
              };
              popupBlockerService.run(callback, errorcallback);
            }
          },

          formSubmissionLender: (dontCheckEmployment) => {
            if (!dontCheckEmployment) {
              if ($scope.loanAppSharedData.isOverrideValidation) {
                $scope.proceedSubmissionToLender();
              } else {
                $scope.countEmploymentYears(() => {
                  $scope.validateAddressHistory(() => {
                    $scope.proceedSubmissionToLender();
                  });
                }, () => {
                  $scope.countValids(false, 'applicants');
                  $scope.validateAddressHistory();
                });
              }
            } else if (uiService.isCountry('New Zealand')) {
              $scope.validateAddressHistory(() => {
                if ($scope.validSections && $scope.validSections.length > 0) {
                  $scope.showValidationMessage();
                } else {
                  switch ($scope.LenderSubmissionSet.SubmissionMethod) {
                  case 'email':
                    $scope.emailLoanAppToLenderNZ();
                    break;
                  case 'print':
                    $scope.submitPrintedLoanAppNZ();
                    break;
                  default:
                    break;
                  }
                }
              });
            }
          },

          proceedSubmissionToLender: () => {
            if ($scope.validSections && $scope.validSections.length > 0 && !$scope.loanAppSharedData.isOverrideValidation) {
              $scope.showValidationMessage();
            } else {
              $rootScope.LoanAppFormWarning = false;

              switch ($scope.LenderSubmissionSet.SubmissionMethod) {
              case 'nextgen':
                $scope.isNextGenSubmitted = true;
                $scope.submitNextGenLoanSubmission();
                break;
              case 'simpology':
                $scope.submitSimpologySubmission();
                break;
              case 'email':
                $scope.emailLoanAppToLenderNZ();
                break;
              case 'print':
                $scope.submitPrintedLoanAppNZ();
                break;
              default:
                break;
              }
            }
          },

          showValidationMessage: () => {
            $rootScope.LoanAppFormWarning = true;
            $scope.isLoadingLoanSubmission = false;
            $scope.submitObj.isProcessingView = false;
            angular.element('html,body').animate({ scrollTop: 0 }, 1000);
          },

          viewSubmission: () => {
            if (!uiService.isCountry('New Zealand')) return;

            simpologyLoanAppService.SimpologyLoanApplicationView($scope.loanAppId).then((response) => {
              $scope.openSimpologyLoanAppView(response.data);
            });
          },

          openSimpologyLoanAppView: (simpologyReturnData) => {
            if (!simpologyReturnData) return;

            const simpologyUrl = simpologyReturnData.replace(/\?\w+=(\w|.|-)+/ig, '');
            const token = $scope.extractSimpologyToken(simpologyReturnData);
            $scope.simpologyObj = {};
            $scope.simpologyObj.URL = $sce.trustAsResourceUrl(simpologyUrl);
            $scope.applicationViewURL = $sce.trustAsResourceUrl(simpologyReturnData);
            $scope.simpologyObj.Token = token;
            $scope.submitObj.isProcessingView = false;
            $timeout(() => {
              $window.document.getElementById('simpology-form').submit();
            }, 300);
          },

          extractSimpologyToken: (simpologyReturnData) => {
            const queryStrings = simpologyReturnData.split('?');
            const token = _.find(queryStrings, queryString => queryString.search('token=') !== -1);
            return token && token.trim() ? token.replace(/(token=|\?|#)/ig, '') : token;
          },

          submitSimpologySubmission: () => {
            simpologyLoanAppService.SimpologyLoanApplicationSubmission($scope.loanAppId).then((response) => {
              const simpologyReturnData = response.data;
              const simpologyUrl = simpologyReturnData.replace(/\?\w+=(\w|.|-)+/ig, '');
              const token = $scope.extractSimpologyToken(simpologyReturnData);
              $scope.isApplicationSubmitted = true;
              $scope.isLoadingLoanSubmission = false;
              $scope.submitObj.isProcessingView = false;
              $scope.simpologyObj = {};
              $scope.simpologyObj.URL = $sce.trustAsResourceUrl(simpologyUrl);
              $scope.applicationViewURL = $sce.trustAsResourceUrl(simpologyReturnData);
              $scope.simpologyObj.Token = token;
              $timeout(() => {
                $window.document.getElementById('simpology-form').submit();
              }, 300);
            }, () => {
              $scope.isLoadingLoanSubmission = false;
              $scope.submitObj.isProcessingView = false;
              toaster.pop('error', 'Submission Error', 'There was an issue submitting the application to Simpology');
            });
          },

          /**
           * Update NextGen
           */
          submitNextGenLoanSubmission: () => {
            nextGenLoanAppService.nextGenAOLSubmission($scope.loanAppId).then((response) => {
              $scope.nextGenObj = response.data;
              $scope.nextGenObj.https = $sce.trustAsResourceUrl($scope.nextGenObj.https);
              const startPage = $scope.nextGenObj.StartPage || '';
              const guid = $scope.nextGenObj.GUID || '';
              loanScenarioService.scenarioDetailsGet($scope.loanAppId).then((scenarioResponse) => {
                const nextgenStatus = scenarioResponse.data;
                $scope.isApplicationSubmitted = Object.prototype.hasOwnProperty.call(nextgenStatus, 'HasBeenSentToNextGen') ? nextgenStatus.HasBeenSentToNextGen : false;
                if ($scope.isApplicationSubmitted) {
                  $scope.isLoadingLoanSubmission = false;
                  toaster.pop('info', 'Redirection', 'will be redirecting shortly...');
                  $scope.applicationViewURL = `${$scope.nextGenObj.https}?GUID=${guid}&StartPage=${startPage}`;
                  const formElementNextgen = $window.document.getElementById('nextgen-form');
                  if (typeof (formElementNextgen) !== 'undefined' && formElementNextgen !== null) {
                    formElementNextgen.submit();
                  }
                } else {
                  $scope.isLoadingLoanSubmission = false;
                  $scope.submitObj.isProcessingView = false;
                  toaster.pop('error', 'Submission Error', 'There was an issue submitting the application to NextGen');
                }
              });
            }, (err) => {
              $scope.isLoadingLoanSubmission = false;
              $scope.submitObj.isProcessingView = false;
              const errorMessage = (err && err.data && err.data.Message) || '';
              toaster.pop('error', err.statusText, errorMessage);
            });
          },

          emailLoanAppToLenderNZ: () => {
            const RecipientLists = [{
              EmailAddress: $scope.LenderSubmissionSet.LenderEmailAddress,
            }];

            // decide whether to add adviser and client emails
            if ($scope.LenderSubmissionSet.CopyToAdviser && $scope.LenderSubmissionSet.AdviserEmailAddress) {
              RecipientLists.push({ EmailAddress: $scope.LenderSubmissionSet.AdviserEmailAddress });
            }

            loanScenarioService.emailLoanAppToLenderNZ({
              EmailSubject: $scope.LenderSubmissionSet.CustomEmailSubject,
              EmailBody: $scope.LenderSubmissionSet.CustomEmailContent,
              FamilyID: $scope.familyId,
              RecipientList: RecipientLists,
              loanAppId: $scope.loanAppId,
            }).then((response) => {
              const hasError = loanAppSubmissionValidationService.sendEmailHasError(response);
              if (!hasError) {
                $scope.setSubmissionDateToToday(() => {
                  $scope.onLoanSubmissionSuccess();
                });
              } else {
                const emailResult = response.data[0];
                if (emailResult && emailResult.EmailRemarks) {
                  const errorText = emailResult.EmailRemarks.replace('Error:', '');
                  swal({
                    text: `<div class='text-left padding-left-10'>${errorText ? errorText.trim() : 'Error'}</div>`,
                    title: 'Error submitting to lender',
                    html: true,
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay, got it!',
                    backdrop: false,
                    allowEscapeKey: false,
                    closeOnConfirm: true,
                  });
                } else {
                  toaster.pop('error', 'Unable to send loan application to Lender');
                }
                $scope.isLoadingLoanSubmission = false;
              }
            });
          },

          countEmploymentYears: (callback, onErrorCallback) => {
            employmentService.countEmploymentYears($scope.loanAppId, 0)
              .then((response) => {
                const invalidEmployments = response;
                if (invalidEmployments.length > 0) {
                  $scope.validation.EmploymentError = invalidEmployments;
                  angular.element('html,body').animate({ scrollTop: 0 }, 1000);
                  $rootScope.LoanAppFormWarning = true;
                  $scope.isLoadingLoanSubmission = false;
                  $scope.submitObj.isProcessingView = false;
                  if (onErrorCallback) {
                    onErrorCallback();
                  }
                } else {
                  $scope.validation.EmploymentError = [];
                  callback();
                }
              });
          },

          validateAddressHistory: (callback) => {
            $scope.validation.AddressHistoryError = loanAppSubmissionValidationService.isValidAddressHistory(loanAppSharedData.allAddressList);
            if ($scope.validation.AddressHistoryError && $scope.validation.AddressHistoryError.length) {
              $scope.countValids(false, 'applicants');
              $scope.showValidationMessage();
            } else if (callback) {
              $scope.countValids(true, 'applicants');
              callback();
            }
          },
          submitPrintedLoanAppNZ: () => {
            if (uiService.isCountry('New Zealand')) {
              $scope.moveLoanAppToLodged(() => {
                $scope.onLoanSubmissionSuccess();
              });
            }
          },
          onLoanSubmissionSuccess: () => {
            $scope.isLoadingLoanSubmission = false;
            SweetAlert.swal({
              title: 'Successfully submitted to Lenders!',
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Go back to lending tab',
              backdrop: false,
              allowEscapeKey: false,
            }, (confirm) => {
              if (confirm) {
                $state.go('app.contactsSingle', { familyId: $scope.familyId, activeTab: 'lending', loanId: loanAppSharedData.LoanApplicationDetails.LoanId, inprogress: 1 });
              }
            });
          },
          moveLoanAppToLodged: (callback) => {
            if (loanAppSharedData.LoanApplicationDetails.StatusId === 8) {
              contactService.LoanStatusSet(loanAppSharedData.LoanApplicationDetails.LoanId, 9).then(() => {
                $scope.setSubmissionDateToToday((response) => {
                  if (callback) {
                    callback(response);
                  }
                });
              });
            } else if (callback) {
              callback();
            }
          },
          setSubmissionDateToToday: (callback) => {
            const submissionDateType = 3;
            const submissionDate = moment().format('YYYY-MM-DD');
            loanApplicationServices.loanSummaryGet($state.params.loanAppId)
              .then((response) => {
                if (!response || !response.data) return;

                const paramLoanId = loanAppSharedData.LoanApplicationDetails.LoanId || response.data.LoanDetails.LoanID;
                return contactService.loanImportantDatesSet(paramLoanId, submissionDateType, submissionDate);
              })
              .then((response) => {
                if (callback) {
                  callback(response);
                }
              });
          },
          /**
           * Branch Location
           * */

          findBranch: () => {
            const lenderDetailObject = $scope.loanAppLenderDetailObject ? $scope.loanAppLenderDetailObject : {};
            const isLenderNameNotEmpty = lenderDetailObject && lenderDetailObject.LenderName && lenderDetailObject.LenderName !== '';
            if (isLenderNameNotEmpty) {
              $scope.selectedBranch = '';
              $scope.showFindBranch = ($scope.showFindBranch === false);
            } else {
              toaster.pop('warning', 'Important', 'Please select lender in "LOAN / FUNDING DETAILS" to find branch.');
            }
          },

          searchBranch: (query) => {
            if (query && query.length > 2) {
              optionsService.bankBranchSearch(query, $scope.getLenderId()).then((response) => {
                $scope.branches = response.data;

                angular.forEach($scope.branches, (val) => {
                  val.logo = '/assets/images/lenderLogos/default.png';
                });
              });
            }
          },

          addAsBranch: (branch) => {
            $scope.selectedBranch = branch;
            const lenderSubmissionQuestion = $scope.loanSubmissionChooseBranch;
            if (typeof lenderSubmissionQuestion === 'undefined') {
              return false;
            }
            const lenderSubmissionObj = {
              LoanScenarioId: $scope.loanAppId,
              FamilyId: $scope.familyId,
              QuestionId: lenderSubmissionQuestion.QuestionId,
              DisplayOptionId: lenderSubmissionQuestion.QuestionDisplayTypeId,
              answerInt: [branch.LenderBranchId],
            };
            loanScenarioService.lenderSubmissionSet(lenderSubmissionObj).then(() => {
              $scope.lenderSubmissionGet();
            });
          },

          /**
           * Referrer
           * */
          referrerHelper: {

            showReferralInfoInit: () => {

            },

            getReferralOrgList: (callback, search) => {
              contactService.contactFamilyInfoGet($scope.familyId).then(
                (response) => {
                  if (response.data) {
                    const brokerId = response.data.BrokerID || 0;
                    if (brokerId > 0) {
                      optionsService.getReferencedReferralList(brokerId, search).then((refResponse) => {
                        if (!refResponse || !refResponse.data) return;
                        const clientList = refResponse.data.filter(x => x.ReferralOrganizationName && x.ReferralMemberName);
                        angular.forEach(
                          clientList,
                          (val) => {
                            if (val.ReferralOrganizationName !== '' && val.ReferralMemberName !== '') {
                              val.Searchable = `${val.ReferralOrganizationName} ${val.ReferralMemberName}`;
                              $scope.getClientList.push(val);
                            }
                          }
                        );
                        $scope.ReferralOrgList = $filter('filter')(clientList, search);
                        if (typeof search === 'undefined') {
                          $scope.ReferralOrgList2 = clientList;
                        }
                        if (typeof callback !== 'undefined') {
                          callback();
                        }
                      });
                    }
                  }
                }
              );
            },

            getEnquirySourceList: () => {
              enquirySourceService.getEnquirySourceList($scope);
            },

            enquiryChanged: (selected) => {
              $scope.showReferralInfo = selected.ReferralItemId === LOAN_APP_ENQUIRY_SOURCE.REFERRAL && selected.ReferralCategoryId === LOAN_APP_ENQUIRY_SOURCE.REFERRAL;
              const hasBeenReferral = $scope.referrerHelper.data && $scope.referrerHelper.data.ReferrerOrgId;
              const isReferral = selected.ReferralItemId === LOAN_APP_ENQUIRY_SOURCE.REFERRAL;

              if (hasBeenReferral) {
                $scope.referrerHelper.backup = $scope.referrerHelper.data;
              }

              if (isReferral) {
                $scope.referrerHelper.data = { ...selected };
                if ($scope.referrerHelper.backup) {
                  $scope.referrerHelper.data = { ...$scope.referrerHelper.data, ...$scope.referrerHelper.backup };
                }
                $scope.referrerHelper.ReferrerBy = selected.ReferralItemName;
              } else {
                $scope.referrerHelper.data = selected;
                $scope.referrerHelper.data.ReferrerId = 0;
                $scope.referrerHelper.data.ReferrerOrgId = 0;
                $scope.referrerHelper.data.ReferrerName = '';
                $scope.referrerHelper.data.ReferrerOrgName = '';
                $scope.referrerHelper.data.ReferrerAgreementTypeId = 0;
                $scope.referrerHelper.ReferrerBy = '';
              }
              $scope.isReferrerRequired = isReferral && !$scope.referrerHelper.ReferrerName;
              $scope.referrerHelper.data.loanApplicationId = parseInt($scope.loanAppId, 10);
              $scope.referrerHelper.data.ClientReferrerFamilyId = 0;
              $scope.referrerHelper.data.OtherContactReferrerName = '';
              $scope.displayClient = $scope.displayField(selected.ReferralItemId, $scope.clientEnquiryList);
              $scope.displayAdditional = $scope.displayField(selected.ReferralItemId, $scope.additionalEnquiryList);
              $scope.referrerHelper.setLoanScenarioEnquiry();
              $scope.validateReferrerInfo();
              $scope.selectedEnquirySource = selected;
            },

            getLoanScenarioEnquiry: () => {
              loanScenarioService.getLoanScenarioEnquiry($scope.loanAppId).then((response) => {
                const option = response.data;
                $scope.referralCategoryId = LOAN_APP_ENQUIRY_SOURCE.REFERRAL;
                $scope.selectedEnquirySource = {
                  ReferralItemId: option.ReferralItemId,
                  ReferralCategoryId: option.ReferralCategoryId,
                };

                if (option && option.ReferrerId > 0 && option.ReferralCategoryId === LOAN_APP_ENQUIRY_SOURCE.REFERRAL) {
                  $scope.ReferrerId = option.ReferrerId;
                  $scope.ReferrerOrgId = option.ReferrerOrgId;
                  $scope.referrerHelper.data = {
                    ReferrerId: option.ReferrerId,
                    ReferrerOrgId: option.ReferrerOrgId,
                    ReferrerName: option.ReferrerName,
                    ReferrerOrgName: option.ReferrerOrgName,
                    ReferrerAgreementTypeId: option.ReferrerAgreementTypeId,
                  };
                  const orgName = option.ReferrerOrgName ? ` - ${option.ReferrerOrgName}` : ``;
                  $scope.referrerHelper.ReferrerName = `${option.ReferrerName}${orgName}`;
                  $scope.referrerHelper.ReferrerBy = $scope.referrerHelper.ReferrerName;
                } else if (option.ReferralCategoryId) {
                  $scope.ReferrerId = option.ReferralItemId;
                  const referralItem = _.find($scope.EnquirySourceList, o => o.ReferralItemId === option.ReferralItemId && o.ReferralCategoryId === option.ReferralCategoryId);
                  if (referralItem) {
                    $scope.referrerHelper.ReferrerBy = referralItem.ReferralItemName || referralItem.ReferralCategoryName;
                    $scope.referrerHelper.ReferrerByDuplicate = referralItem.ReferralCategoryName;
                  }
                }
                $scope.showReferralInfo = option.ReferralItemId === LOAN_APP_ENQUIRY_SOURCE.REFERRAL && option.ReferralCategoryId === LOAN_APP_ENQUIRY_SOURCE.REFERRAL;
                $scope.displayClient = $scope.displayField(option.ReferralItemId, $scope.clientEnquiryList);
                $scope.displayAdditional = $scope.displayField(option.ReferralItemId, $scope.additionalEnquiryList);
                if (option.ClientReferrerFamilyId) {
                  $scope.clientData = {
                    FamilyID: option.ClientReferrerFamilyId,
                    FamilyFullName: option.ClientReferrerName,
                  };
                }
                $scope.additionalDetails = option.OtherContactReferrerName || '';
                contactService.getUserInfo().then((userlevel) => {
                  const isValidReferral = parseInt(userlevel.data.AccessType, 10) !== ACCESS_TYPE.CORPORATE && (option.ReferralCategoryId === REFERRAL_TYPE.WEB_LOANMARKET || option.ReferralCategoryId === REFERRAL_TYPE.CONTACT_US);
                  if (isValidReferral) {
                    $scope.isEnquirySourceDropdownEnabled = false;
                  }
                });
              });
            },
            removeReferrer: () => {
              $scope.referrerHelper.ReferrerName = '';
              $scope.referrerHelper.ReferrerBy = '';
            },

            resetReferralInfo: () => {
              $scope.referrerHelper.removeReferrer();
              $scope.referrerHelper.data = {
                loanApplicationId: parseInt($scope.loanAppId, 10),
                ReferralCategoryId: $scope.selectedEnquirySource.ReferralCategoryId,
                ReferralItemId: $scope.selectedEnquirySource.ReferralItemId,
                ReferrerId: 0,
                ReferrerOrgId: 0,
                ReferrerAgreementTypeId: 0,
              };
              $scope.ReferrerId = 0;
              $scope.ReferrerOrgId = 0;
              $scope.referrerHelper.setLoanScenarioEnquiry();
            },

            setLoanScenarioEnquiry: () => {
              if ($scope.isReferrerRequired) return;
              loanScenarioService.setLoanScenarioEnquiry(
                $scope.referrerHelper.data
              );
            },

            searchReferrer: (query) => {
              if (query && query.length > 2) {
                /**
                 * Search the api
                 * */
                $scope.referrerHelper.showClientList = true;
                $scope.referrerHelper.getReferralOrgList(() => {
                }, query);
              }
            },

            selectReferrer: (client) => {
              if (!client) {
                $scope.referrerHelper.resetReferralInfo();
              } else {
                $scope.referrerHelper.ReferrerName = `${client.ReferralMemberName
                } - ${client.ReferralOrganizationName}`;

                $scope.referrerHelper.ReferrerBy = `${client.ReferralMemberName
                } - ${client.ReferralOrganizationName}`;

                $scope.referrerHelper.data = {
                  loanApplicationId: parseInt($scope.loanAppId, 10),
                  ReferralCategoryId: LOAN_APP_ENQUIRY_SOURCE.REFERRAL,
                  ReferralItemId: LOAN_APP_ENQUIRY_SOURCE.REFERRAL,
                  ReferrerId: client.ReferralMemberId,
                  ReferrerOrgId: client.ReferralOrganizationId,
                  ReferrerAgreementTypeId: client.AgreementTypeId,
                  ReferrerName: client.ReferralMemberName,
                  ReferrerOrgName: client.ReferralOrganizationName,
                };

                $scope.ReferrerId = client.ReferralMemberId;

                $scope.ReferrerOrgId = client.ReferralOrganizationId;
                $scope.isReferrerRequired = false;
                $scope.referrerHelper.setLoanScenarioEnquiry();
              }
              $scope.referrerHelper.showClientList = false;
            },

            loadSavedReferralList: () => {
              $scope.referrerHelper.getReferralOrgList(() => {
                /**
                 * Load the saved referral after fetching all org list
                 * */
                $scope.referrerHelper.getLoanScenarioEnquiry();
                $scope.validateReferrerInfo();
              });
            },

            listeners: () => {
              $rootScope.$on('EDIT_ADVISER_EMAIL', () => {
                $scope.updateLoanSubmission('AdviserEmailAddress', loanAppSharedData.LoanApplicationDetails.AdviserEmail);
              });
            },

            enquirySourceInit: () => {
              $scope.referrerHelper.listeners();
              $scope.referrerHelper.loadSavedReferralList();
              $scope.referrerHelper.getEnquirySourceList();
              $scope.referrerHelper.showReferralInfoInit();
            },
          },

          listeners: () => {
            const listenersList = [];
            /**
             *  Validates every update on object
             */
            listenersList.push($scope.$watch('declarationsValueSet', () => {
              $scope.validateDeclarations();
            }, true));

            listenersList.push($scope.$watch('brokernotesValueSet', () => {
              $scope.validateBrokerNotes();
            }, true));

            listenersList.push($scope.$watch('referrerValueSet', () => {
              $scope.validateReferrerInfo();
            }, true));

            listenersList.push($scope.$watch('ReferrerId', () => {
              $scope.validateReferrerInfo();
            }, true));

            listenersList.push($scope.$watch('LenderSubmissionSet', () => {
              $scope.validateLenderSubmission();
            }, true));

            listenersList.push($scope.$watch('loanAppLenderDetailObject', () => {
              if ($scope.loanAppLenderDetailObject && $scope.loanAppLenderDetailObject.LenderId !== loanAppSharedData.simpology.bnzId &&
                $scope.LenderSubmissionSet.SubmissionMethod === LOAN_APP_SUBMISSION_METHODS.SIMPOLOGY && uiService.isCountry('New Zealand')) {
                $scope.LenderSubmissionSet.SubmissionMethod = '';
              }
              if (uiService.isCountry('Australia') && $scope.loanAppLenderDetailObject.LenderId) {
                $scope.LenderSubmissionSet.SubmissionMethod = 'nextgen';
              }
              $scope.validateLenderSubmission();
            }, true));

            listenersList.push($scope.$watch('isNextGenSubmitted', () => {
              $scope.validateLenderSubmission();
            }));

            // track crm country
            listenersList.push($scope.$watch('crmCountry', (nv) => {
              if (nv === 'Australia' && $scope.loanAppLenderDetailObject.LenderId) {
                $scope.LenderSubmissionSet.SubmissionMethod = 'nextgen';
                $scope.checkSubmitOnlineDisabled();
                $scope.checkSubmissionEnabled();
                $scope.checkSubmitOnline();
              } else {
                $scope.LenderSubmissionSet.SubmissionMethod = '';
              }
            }));

            listenersList.push($scope.$watch(() => loanAppSharedData.lenderId, (nv) => {
              $scope.loanAppLender.LenderId = loanAppSharedData.lenderId ? loanAppSharedData.lenderId.toString().trim() : null;

              if (nv) {
                $scope.resetLenderEmailAddress();
                $scope.loadDefaultClientEmail();
              }
            }));

            listenersList.push($scope.$watch(() => loanAppSharedData.listApplicantsUpdated, (nv) => {
              if (nv && uiService.isCountry('New Zealand')) {
                $scope.resetClientEmailAddress();
                $scope.updateLenderEmailSubject();
              }
            }));

            listenersList.push($scope.$watch(() => loanAppSharedData.fundingSummary && loanAppSharedData.fundingSummary.lvr, (nv, ov) => {
              const isLenderSubmissionFetched = $scope.LenderSubmissionSet;
              if (!isLenderSubmissionFetched) return;

              const hasLenderEmailSubject = $scope.LenderSubmissionSet.CustomEmailSubject;
              const subjectHasNoLVR = hasLenderEmailSubject && $scope.LenderSubmissionSet.CustomEmailSubject.indexOf(' LVR') === -1;
              const shouldUpdateSubject = ov || !hasLenderEmailSubject || subjectHasNoLVR;
              if (!nv || !shouldUpdateSubject) return;
              $scope.updateLenderEmailSubject();
            }));

            listenersList.push($scope.$on('$destroy', () => {
              if ($scope.detailsSetTimeout) {
                $timeout.cancel($scope.detailsSetTimeout);
              }
            }));

            $scope.$on('$destroy', () => {
              if (listenersList && listenersList.length) {
                listenersList.forEach(listener => listener());
              }
            });
          },


          validationIfSubmitted: () => {
            const checkNextGen = () => {
              if ($scope.crmCountry === 'Australia') {
                loanScenarioService.scenarioDetailsGet(
                  $scope.loanAppId
                )
                  .then((response) => {
                    const nextgenStatus = response.data;
                    $scope.isApplicationSubmitted = Object.prototype.hasOwnProperty.call(nextgenStatus, 'HasBeenSentToNextGen') ? nextgenStatus.HasBeenSentToNextGen : false;
                    $scope.isNextGenSubmitted = $scope.isApplicationSubmitted;
                  });
              }
            };

            const checkCountry = (callback) => {
              if (typeof $scope.crmCountry === 'undefined') {
                // Get Broker Info
                dashboardService.getBrokerBasicInfo()
                  .then((response) => {
                    $scope.brokerInfo = response.data;
                    $scope.capitalize($scope.brokerInfo.FullName);

                    regionalizationService.getBrokerRegionalization($scope.brokerInfo.BrokerId)
                      .then((brokerResponse) => {
                        $scope.crmCountry = brokerResponse.data.Name;
                        callback();
                      });
                  }
                  );
              } else {
                callback();
              }
            };

            // check country info if exists and call api if not
            checkCountry(checkNextGen);

            // check if simpology submitted
            if ($scope.crmCountry === 'New Zealand') {
              simpologyLoanAppService.SimpologyLoanApplicationSubmissionStatus($scope.loanAppId).then((response) => {
                if (typeof response.data === 'boolean') {
                  $scope.isApplicationSubmitted = response.data;
                }
              });
            }
          },

          getOptions: {
            brokerNotes: () => {
              optionsService.question('Loan Submission BrokerNotes').then((response) => {
                $scope.loanSubmissionBrokerNotes = response.data;
                $scope.setDefaultValue(response.data, 'brokernotesValueSet');
                $scope.loanSubmissionBrokerNotesGet();
              });
            },
            lenderSubmission: () => {
              optionsService.question('Lender Submission').then((response) => {
                // Choose Lender
                const chooseLender = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Choose a Lender';
                });
                $scope.loanSubmissionChooseLender = chooseLender;

                // Choose Branch
                const chooseBranch = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'BSB of Branch to sign documents';
                });
                $scope.loanSubmissionChooseBranch = chooseBranch;

                // Submission Method
                const submissionMethod = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Submission Method';
                });
                $scope.loanSubmissionMethod = submissionMethod;

                // Copy to Client
                const copyToClient = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Copy to Client';
                });
                $scope.loanSubmissionCopyToClient = copyToClient;
                // Client Email Address
                const clientEmailAddress = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Client Email Address';
                });
                $scope.loanSubmissionClientEmailAddress = clientEmailAddress;

                // Copy to Adviser
                const copyToAdviser = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Copy to Adviser';
                });
                $scope.loanSubmissionCopyToAdviser = copyToAdviser;
                // Adviser Email Address
                const adviserEmailAddress = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Adviser Email Address';
                });
                $scope.loanSubmissionAdviserEmailAddress = adviserEmailAddress;

                // Lender Email Address
                const lenderEmailAddress = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Lender Email Address';
                });
                $scope.loanSubmissionLenderEmailAddress = lenderEmailAddress;

                // Custom Email Subject
                const customEmailSubject = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Custom Email Subject';
                });
                $scope.loanSubmissionCustomEmailSubject = customEmailSubject;

                // Custom Email Subject
                const customEmailContent = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Custom Email Content';
                });
                $scope.loanSubmissionCustomEmailContent = customEmailContent;

                $scope.applicantEmailSubjectQuestion = $scope.getQuestion(response.data, 'Applicant Email Subject');
                $scope.applicantEmailBodyQuestion = $scope.getQuestion(response.data, 'Applicant Email Body');

                // Broker Centre or Branch
                const BrokerCentreOrBranch = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Broker Centre or Branch';
                });
                $scope.loanSubmissionBrokerCentreOrBranch = BrokerCentreOrBranch;

                // Business Loan
                const BusinessLoan = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Business Loan';
                });
                $scope.loanSubmissionBusinessLoan = BusinessLoan;

                // TurnOver
                const TurnOver = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'TurnOver';
                });
                $scope.loanSubmissionTurnOver = TurnOver;

                // generate worksheet question
                const generateWorksheetQuestion = _.find(response.data, (obj) => {
                  return obj.QuestionContent === 'Would you like to generate a lender worksheet?';
                });
                $scope.loanSubmissionGenerateWorksheetQuestion = generateWorksheetQuestion;

                $scope.lenderSubmissionGet();
              });
            },
            referrerQuestion: () => {
              optionsService.question('Loan Submission Referrer').then((response) => {
                $scope.loanSubmissionReferrer = response.data;
                $scope.setDefaultValue(response.data, 'referrerValueSet');
                $scope.referrerInfoGet(); // Referrer
              });
            },
          },

          setDefaultValue: (object, valueSet) => {
            _.forEach(object, (obj) => {
              $scope[valueSet][obj.QuestionId] = null;
            });
          },

          optionLoadInit: () => {
            $scope.getOptions.brokerNotes();

            const brokerNotesHasInvestmentWatcher = $scope.$watch('brokerNotes.hasInvestment', () => {
              $scope.brokerNotes.getLoanObjectives();
            });

            watcherService.addWatcher(brokerNotesHasInvestmentWatcher, WATCHER_CONTEXT.LOAN_APP_LOAN_SUBMISSION_CTRL);

            $scope.getOptions.lenderSubmission();

            $scope.getOptions.referrerQuestion();
          },

          getQuestion: (questionList, questionContent) => {
            if (!questionList) return null;
            return questionList.find(question => question.QuestionContent === questionContent);
          },

          /**
           * Email Content Editor
           * */
          openEditEmailContentModal: (emailSubjectQuestion, emailContentQuestion, emailType, onSaved) => {
            if (emailSubjectQuestion && emailContentQuestion) {
              const modalInstance = $uibModal.open({
                templateUrl: 'assets/views/loanApplication/loanSubmission/submitLender/editCustomEmailContentModal.html',
                backdrop: 'static',
                size: 'lg',
                controller: 'EditEmailContentModalCtrl',
                resolve: {
                  emailType: () => emailType,
                  LenderSubmissionObj: () => {
                    return {
                      LenderSubmissionSet: $scope.LenderSubmissionSet,
                      familyId: $scope.familyId,
                      loanAppId: $scope.loanAppId,
                      emailSubjectQuestion,
                      emailContentQuestion,
                    };
                  },
                },
              });

              modalInstance.result.then((email) => {
                if (!onSaved) return;
                onSaved(email);
              });
            }
          },

          openEditLenderEmailModal: () => {
            const emailSubjectQuestion = $scope.loanSubmissionCustomEmailSubject;
            const emailContentQuestion = $scope.loanSubmissionCustomEmailContent;
            $scope.openEditEmailContentModal(emailSubjectQuestion, emailContentQuestion, LOAN_SUBMISSION_EMAIL_TYPE.LENDER, (email) => {
              const { CustomEmailSubject, CustomEmailContent } = email;
              $scope.LenderSubmissionSet.CustomEmailSubject = CustomEmailSubject;
              $scope.LenderSubmissionSet.CustomEmailContent = CustomEmailContent;
              $scope.loanSubmissionLenderEmail.email.Subject = CustomEmailSubject;
              $scope.loanSubmissionLenderEmail.email.Body = CustomEmailContent;
            });
          },

          openEditClientEmailModal: () => {
            const validClientEmail = $scope.loanSubmissionClientEmail;
            if (!validClientEmail) return;
            const { SubjectQuestion: emailSubjectQuestion, BodyQuestion: emailContentQuestion } = $scope.loanSubmissionClientEmail;
            $scope.openEditEmailContentModal(emailSubjectQuestion, emailContentQuestion, LOAN_SUBMISSION_EMAIL_TYPE.CLIENT, (email) => {
              const { CustomEmailSubject: subject, CustomEmailContent: body } = email;
              $scope.LenderSubmissionSet.ApplicantEmailSubject = subject;
              $scope.LenderSubmissionSet.ApplicantEmailBody = body;
              $scope.loanSubmissionClientEmail.Subject = subject;
              $scope.loanSubmissionClientEmail.Body = body;
            });
          },

          /**
           * Lender Email Address
           * */
          resetLenderEmailAddress: () => {
            $scope.loanAppLender.IsDefaultLenderEmailAddr = false;
            $scope.LenderSubmissionSet.LenderEmailAddress = null;
            $scope.checkLenderEmailAddressState();
          },

          checkLenderEmailAddressState: () => {
            if ($scope.isCheckingLenderEmailAddr) return;
            $scope.isCheckingLenderEmailAddr = true;
            loanScenarioService.LoanAppProviderEmailGet($scope.loanAppId, loanAppSharedData.lenderId).then((response) => {
              $scope.loanAppLender.DefaultLenderEmailAddress = response.data;
              $scope.isCheckingLenderEmailAddr = false;

              if ($scope.LenderSubmissionSet.LenderEmailAddress !== $scope.loanAppLender.DefaultLenderEmailAddress) {
                if ($scope.LenderSubmissionSet.LenderEmailAddress && $scope.LenderSubmissionSet.LenderEmailAddress.trim()) {
                  $scope.loanAppLender.IsDefaultLenderEmailAddr = $scope.loanAppLender.DefaultLenderEmailAddress === $scope.LenderSubmissionSet.LenderEmailAddress;
                  $scope.LenderSubmission.LenderEmailAddress = $scope.LenderSubmissionSet.LenderEmailAddress;
                } else {
                  $scope.updateLoanSubmission('LenderEmailAddress', $scope.loanAppLender.DefaultLenderEmailAddress);
                  $scope.LenderSubmissionSet.LenderEmailAddress = $scope.loanAppLender.DefaultLenderEmailAddress;
                  $scope.loanAppLender.IsDefaultLenderEmailAddr = true;
                }
              } else {
                $scope.loanAppLender.IsDefaultLenderEmailAddr = !_.isEmpty($scope.loanAppLender.DefaultLenderEmailAddress);
              }
            }, () => {
              $scope.isCheckingLenderEmailAddr = false;
            });
          },

          updateLenderEmailAddress: (email, isDefault) => {
            const emailAddress = email && email.trim();
            if (isDefault) {
              // set lender email address as default
              loanScenarioService.LoanAppProviderEmailSet($scope.loanAppId, loanAppSharedData.lenderId, emailAddress).then(() => {
                $scope.loanAppLender.DefaultLenderEmailAddress = emailAddress;
              });
            } else if (emailAddress === $scope.loanAppLender.DefaultLenderEmailAddress) {
              // clear custom default lender email address
              loanScenarioService.LoanAppProviderEmailSet($scope.loanAppId, loanAppSharedData.lenderId, '').then(() => {
                $scope.loanAppLender.DefaultLenderEmailAddress = '';
              });
            }

            $scope.updateLoanSubmission('LenderEmailAddress', emailAddress);

            $scope.lenderSubmissionEmailAddressFields.switchToViewMode('lender');
          },

          /**
           * Lender Selection
           * */
          updateLoanDetailsField: (lenderId, otherLenderName) => {
            if (lenderId) {
              loanScenarioService.loanDetailsGet($scope.loanAppId).then((details) => {
                if (!details || !details.data) return;

                const loanDetailsSet = { ...details.data, LenderId: lenderId, OtherLenderName: otherLenderName, LoanScenarioId: $scope.loanAppId };

                $timeout.cancel($scope.detailsSetTimeout);
                $scope.detailsSetTimeout = $timeout(() => {
                  if (!loanDetailsSet.ABSPurposeId) {
                    loanDetailsSet.ABSPurposeId = null;
                  }
                  const loanDetailsParam = _.omit(loanDetailsSet, 'LoanFacility');
                  const { totalProposedLendingUserDefined } = loanAppSharedData.fundingSummary;
                  loanDetailsParam.TotalProposedLendingUserDefined = totalProposedLendingUserDefined;
                  loanDetailsParam.LMI = loanDetailsParam.IsLMI ? loanDetailsParam.LMI : 0;
                  loanScenarioService.loanDetailsSet(loanDetailsParam).then(() => {
                    loanAppSharedData.lenderId = lenderId;
                    loanAppSharedData.otherLenderName = otherLenderName;
                    const hasLoanAppSummaryDetails = loanAppSharedData.loanAppSummary && loanAppSharedData.loanAppSummary.LoanDetails;
                    if (hasLoanAppSummaryDetails) {
                      loanAppSharedData.loanAppSummary.LoanDetails.LenderId = lenderId;
                    }

                    if (!loanAppSharedData.otherLenderName) {
                      const lender = $scope.lenderList.find((item) => {
                        const itemId = parseInt(item.ProviderId, 10);
                        return itemId === parseInt(lenderId, 10);
                      });
                      if (lender) {
                        $scope.loanAppLenderDetailObject.LenderId = lenderId;
                        $scope.loanAppLenderDetailObject.LenderName = lender.ProviderName;
                      }
                    }

                    const shouldRefreshSummaryDetails = !hasLoanAppSummaryDetails || !loanAppSharedData.loanAppSummary.LoanDetails.IsOnlineAvailable;
                    if (shouldRefreshSummaryDetails) {
                      $scope.refreshLoanAppSummaryDetails();
                    }
                  });
                }, 0);
              });
            }
          },

          refreshLoanAppSummaryDetails: () => {
            loanScenarioService.loanDetailsGet($scope.loanAppId).then((details) => {
              if (details && details.data) {
                loanAppSharedData.loanAppSummary.LoanDetails = { ...loanAppSharedData.loanAppSummary.LoanDetails, ...details.data };
                updateLenderData();
              }
            });
          },

          onOtherLenderSelected: (lender) => {
            if (!lender) return;

            $scope.updateLoanDetailsField(lender.ProviderId, lender.ProviderName);
            $scope.loanAppLenderDetailObject.LenderId = lender.ProviderId;
            $scope.loanAppLenderDetailObject.LenderName = lender.ProviderName;
          },

          onLenderSelected: (lender) => {
            if (!lender) return;

            loanAppSharedData.loanAppSummary.LoanDetails.LenderName = lender.ProviderName;
            loanAppSharedData.loanAppSummary.LoanDetails.LenderId = lender.ProviderId;
            $scope.loanAppLenderDetailObject.LenderId = lender.ProviderId;
            $scope.loanAppLenderDetailObject.LenderName = lender.ProviderName;

            $scope.updateLoanDetailsField(lender.ProviderId, '');
          },

          loadDefaultClientEmailAddress: (overwriteCurrentEmailAddr) => {
            if (overwriteCurrentEmailAddr) {
              $scope.LenderSubmissionSet.ClientEmailAddress = null;
            }
            loanAppSubmissionValidationService.checkClientEmailAddressState($scope.loanAppId, $scope.familyId, $scope.loanSubmissionClientEmailAddress, $scope.LenderSubmissionSet, overwriteCurrentEmailAddr).then(() => {
              $scope.LenderSubmission.ClientEmailAddress = $scope.LenderSubmissionSet.ClientEmailAddress;
            });
          },

          loadDefaultEmailContent: () => {
            $scope.initLenderEmailContent();
            loanAppSubmissionValidationService.checkLenderEmailState($scope.loanAppId, $scope.familyId, $scope.loanSubmissionCustomEmailSubject, $scope.loanSubmissionCustomEmailContent, $scope.LenderSubmissionSet).then((response) => {
              $scope.LenderSubmissionSet.CustomEmailSubject = response.CustomEmailSubject;
              $scope.LenderSubmissionSet.CustomEmailContent = response.CustomEmailContent;
              $scope.LenderSubmission.CustomEmailSubject = response.CustomEmailSubject;
              $scope.LenderSubmission.CustomEmailContent = response.CustomEmailContent;
              $scope.initLenderEmailContent();
            });
            $scope.loadDefaultClientEmail(true);
          },

          loadDefaultClientEmail: (isInstantiate) => {
            if (!$scope.clientEmailInstantiated && !isInstantiate) return;
            $scope.clientEmailInstantiated = true;

            $scope.loanSubmissionClientEmail = loanSubmissionEmailContentService.getClientEmail($scope.familyId, loanAppSharedData.LoanApplicationDetails.LoanId, $scope.LenderSubmissionSet);
            loanAppSubmissionValidationService.checkClientEmailState($scope.loanSubmissionClientEmail, $scope.loanAppId).then(() => {
              const { Subject: subject, Body: body } = $scope.loanSubmissionClientEmail;
              $scope.LenderSubmissionSet.ApplicantEmailSubject = subject;
              $scope.LenderSubmissionSet.ApplicantEmailBody = body;
            });
          },

          initLenderEmailContent: () => {
            const { CustomEmailSubject: Subject, CustomEmailContent: Body } = $scope.LenderSubmissionSet;
            $scope.loanSubmissionLenderEmail.email = { Subject, Body };
          },

          updateLenderEmailSubject: () => {
            loanSubmissionEmailContentService.initEmailSubject(financialsSharedService.involvedParties, $scope.familyId).then((subject) => {
              const isLenderEmailValid = $scope.loanSubmissionLenderEmail && $scope.loanSubmissionLenderEmail.email;
              if (!$scope.LenderSubmissionSet || !isLenderEmailValid) return;
              $scope.LenderSubmissionSet.CustomEmailSubject = subject;
              $scope.loanSubmissionLenderEmail.email.Subject = subject;
              loanAppSubmissionValidationService.saveLenderSubmissionQuestion($scope.loanAppId, $scope.familyId, $scope.loanSubmissionCustomEmailSubject, subject);
            });
          },

          resetClientEmailAddress: () => {
            $scope.loadDefaultClientEmailAddress(true);
          },

          selectSubmissionMethod: (method) => {
            let option = $scope.LenderSubmissionSet.SubmissionMethod;

            switch (method) {
            case 'email':
              if ($scope.crmCountry !== 'Australia') {
                option = 'email';
              }
              break;
            case 'print':
              if ($scope.crmCountry !== 'Australia') {
                option = 'print';
              }
              break;
            default:
              if ($scope.crmCountry === 'Australia') {
                option = 'nextgen';
              } else if ($scope.crmCountry === 'New Zealand') {
                option = 'simpology';
              }
              break;
            }
            $scope.LenderSubmissionSet.SubmissionMethod = option;
            $scope.checkSubmitOnline();
            $scope.checkSubmitOnlineDisabled();
          },

          isCountry: country => uiService.isCountry(country),

          getLenderList: () => {
            contactService.lenderListGet(undefined, lenderPlace).then((lenderResponse) => {
              $scope.lenderList = lenderResponse.data;
            }).finally(() => {
              $scope.loanSubmissionLoaded = true;
              $scope.loanSubmissionLoading = false;
            });
          },

          init: () => {
            $scope.listeners();
            $scope.LenderSubmissionSet = {};
            $scope.documentData = {};
            $scope.documentData.documentListLenderWorksheet = [];
            $scope.isNextGenSubmitted = false;
            $scope.loanSubmissionLenderEmail = { email: {} };
            $scope.emailContentOptions = {
              language: 'en',
              allowedContent: true,
              uiColor: '#DFD6E4',
              height: 300,
              resize_enabled: false,
              removePlugins: 'elementspath',
              toolbar: [
                { items: ['BGColor'] }, { items: ['JustifyLeft'] }, { items: ['BulletedList', '-'] }, { items: ['Link'] },
                { items: ['Image'] }, { items: ['Table'] }, { items: ['Paste', '-'] }, { items: ['Smiley'] }, { items: ['-'] },
                '/',
                { items: ['Format'] }, { items: ['Font'] }, { items: ['FontSize', '-'] }, { items: ['Bold'] }, { items: ['Italic'] }, { items: ['Underline'] },
                { items: ['Subscript'] }, { items: ['Superscript'] },
              ],

            };
            $scope.referrerHelper.enquirySourceInit();
            $scope.optionLoadInit();
            $scope.validationIfSubmitted();
            $scope.getDocumentListLenderWorksheet();
            $scope.asbBranchOptions = optionsService.getASBBranchOptions();

            if (uiService.isCountry('Australia')) {
              $scope.LenderSubmissionSet.SubmissionMethod = $scope.loanAppLenderDetailObject.LenderId ? 'nextgen' : null;
              $scope.checkSubmitOnlineDisabled();
              $scope.checkSubmitOnline();
            }
            $scope.validateLenderSubmission();

            $scope.$on('$destroy', () => {
              watcherService.destroyWatchers(WATCHER_CONTEXT.LOAN_APP_LOAN_SUBMISSION_CTRL);
            });
            $scope.getLenderList();
          },

          stringConvert: (str) => {
            return String(str);
          },
        });

        /**
         * Lender Submission Email Address Fields Helper
         * */
        angular.extend($scope, {
          lenderSubmissionEmailAddressFields: {
            lenderEmailAddrDisplayMode: 'view_mode',
            clientEmailAddrDisplayMode: 'view_mode',
            adviserEmailAddrDisplayMode: 'view_mode',

            switchToViewMode: (emailAddrType, revertChanges) => {
              $scope.lenderSubmissionEmailAddressFields.switchMode(emailAddrType, 'view_mode');
              if (revertChanges === true) {
                $scope.lenderSubmissionEmailAddressFields.revertChanges(emailAddrType);
              }
            },
            switchToEditMode: (emailAddrType) => {
              $scope.lenderSubmissionEmailAddressFields.switchMode(emailAddrType, 'edit_mode');
            },
            switchMode: (emailAddrType, mode) => {
              switch (emailAddrType) {
              case 'lender':
                $scope.lenderSubmissionEmailAddressFields.lenderEmailAddrDisplayMode = mode;
                break;
              case 'client':
                $scope.lenderSubmissionEmailAddressFields.clientEmailAddrDisplayMode = mode;
                break;
              case 'adviser':
                $scope.lenderSubmissionEmailAddressFields.adviserEmailAddrDisplayMode = mode;
                break;
              default:
                break;
              }
            },
            revertChanges: (emailAddrType) => {
              switch (emailAddrType) {
              case 'lender':
                $scope.LenderSubmissionSet.LenderEmailAddress = $scope.LenderSubmission.LenderEmailAddress;
                break;
              case 'client':
                $scope.LenderSubmissionSet.ClientEmailAddress = $scope.LenderSubmission.ClientEmailAddress;
                break;
              case 'adviser':
                $scope.LenderSubmissionSet.AdviserEmailAddress = $scope.LenderSubmission.AdviserEmailAddress;
                break;
              default:
                break;
              }
            },
            cancelEditMode: (emailAddrType) => {
              $scope.lenderSubmissionEmailAddressFields.switchToViewMode(emailAddrType, true);
            },
          },
        });

        $scope.init();
      };

      // TODO: Remove the usage of $watch once issue with uiService is resolved
      const initWatcher = $scope.$watch(() => uiService.getCountryCode(), (nv) => {
        const sectionObjectDeclarations = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.DECLARATIONS);
        const sectionObjectAdviserBrokerNotes = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.BROKER_ADVISER_NOTES);
        const sectionObjectLenderSubmission = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.LENDER_SUBMISSION);
        const sectionObjectReferrerInformation = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.REFERRER_INFORMATION);

        const baseIsCompletedSetThreeSections = (typeof sectionObjectDeclarations.IsCompleted !== 'undefined') && (typeof sectionObjectAdviserBrokerNotes.IsCompleted !== 'undefined') && (typeof sectionObjectLenderSubmission.IsCompleted !== 'undefined');
        const isCompletedSetThreeSections = uiService.isCountry('Australia') ? baseIsCompletedSetThreeSections && (typeof sectionObjectReferrerInformation.IsCompleted !== 'undefined') : baseIsCompletedSetThreeSections;

        if (!isCompletedSetThreeSections) {
          $scope.processLoanAppLoanSubmission();
        } else {
          updateLenderData();
          const { BrokerNotes, LenderSubmissions } = $scope.loanSummary;
          $scope.isValidDeclarations = sectionObjectDeclarations.IsCompleted;
          $scope.initializeDeclarations();

          $scope.isValidBrokerNotes = sectionObjectAdviserBrokerNotes.IsCompleted;
          $scope.isStartedBrokerNotes = BrokerNotes && BrokerNotes.length;

          $scope.isValidLenderSubmission = sectionObjectLenderSubmission.IsCompleted;
          $scope.isStartedLenderSubmission = ($scope.loanAppLenderDetailObject.LenderName && $scope.loanAppLenderDetailObject.LenderId > 0) || (LenderSubmissions && LenderSubmissions.length);

          if (uiService.isCountry('Australia')) {
            $scope.isValidReferrer = sectionObjectReferrerInformation.IsCompleted;
            $scope.isStartedReferrer = loanAppSharedData.loanAppSummary.EnquirySource && Object.keys(loanAppSharedData.loanAppSummary.EnquirySource).length;

            if ($scope.isStartedReferrer) {
              populateReferredBy();
            }
          }

          let notesObject = {};
          if (uiService.isCountry('New Zealand')) {
            notesObject = loanAppSharedData.loanAppSummary.BrokerNotes.find(notes => notes.QuestionId === NOTES_TO_SHOW_QUESTION_ID.NZ);
          } else {
            notesObject = loanAppSharedData.loanAppSummary.BrokerNotes.find(notes => notes.QuestionId === NOTES_TO_SHOW_QUESTION_ID.AU);
          }

          if (notesObject && notesObject.answerString && notesObject.answerString.length) {
            $scope.loanSubmissionBrokerNotesSummary = notesObject.answerString[0];
          }
        }

        if (initWatcher && nv) initWatcher();
      });

      $scope.$on('$destroy', () => {
        if (initWatcher) initWatcher();
      });
    });

    $scope.displayField = displayField;
    const resetData = () => {
      $scope.referrerHelper.data = {
        loanApplicationId: parseInt($scope.loanAppId, 10),
        ReferralCategoryId: $scope.selectedEnquirySource.ReferralCategoryId,
        ReferralItemId: $scope.selectedEnquirySource.ReferralItemId,
        ClientReferrerFamilyId: 0,
        OtherContactReferrerName: '',
        ReferrerId: 0,
        ReferrerOrgId: 0,
        ReferrerAgreementTypeId: 0,
      };
    };
    $scope.selectClient = (client) => {
      if (!client || !client.description || !client.originalObject) return;
      resetData();
      $scope.referrerHelper.data.ClientReferrerFamilyId = client.originalObject.FamilyID;
      $scope.referrerHelper.setLoanScenarioEnquiry();
      $scope.clientData = client.originalObject;
    };
    $scope.updateAdditionalDetails = (details) => {
      resetData();
      $scope.referrerHelper.data.OtherContactReferrerName = details || '';
      $scope.referrerHelper.setLoanScenarioEnquiry();
    };
    $scope.clientSearchApi = (userInputString) => {
      const adviserId = $scope.assignedAdviserId;
      const defer = $q.defer();
      defer.resolve([]);
      if (!userInputString || !adviserId) return defer.promise;
      return contactService.searchFamilyContactType(userInputString, 'Client', adviserId);
    };
  });
