export function featureListBuilderForUI(feature) {
  return {
    organizations: (feature.Organizations && feature.Organizations.map((organization) => {
      return {
        adviserOrganizationId: organization.AdviserOrganizationId,
        adviserOrganizatioName: organization.AdviserOrganizatioName,
        addressId: organization.AddressId,
        featureManagerId: organization.FeatureManagerId,
        phone: organization.Phone,
        fax: organization.Fax,
      };
    })) || [],
    featureId: feature.FeatureId,
    name: feature.Name,
    isEnabled: feature.IsEnabled,
    settingTypeId: feature.SettingTypeId,
    modifiedBy: feature.ModifiedBy,
    lastDateModified: feature.LastDateModified,
  };
}

export function preferredAdvisersBuilderForUI(preferred) {
  return {
    agreementId: preferred.AgreementId,
    adviserId: preferred.AdviserId,
    adviserName: preferred.AdviserName,
    adviserOrganizationName: preferred.AdviserOrganizationName,
    referrerOrgPreferredAdviserId: preferred.ReferrerOrgPreferredAdviserId,
    preferredByReferrerIds: preferred.PreferredByReferrerIds,
  };
}

export function adviserProfileBuilderForUI(profile) {
  return {
    profileId: profile.ProfileId,
    adviserId: profile.AdviserId,
    bio: profile.Bio,
    otherInfo: profile.OtherInfo,
  };
}

export function adviserProfileBuilderForMyCRM(profile) {
  return {
    ProfileId: profile.profileId,
    AdviserId: profile.adviserId,
    Bio: profile.bio,
    OtherInfo: profile.otherInfo,
  };
}

export function workingDaysBuilderForUI(days) {
  return {
    label: days.Label,
    isChecked: days.IsChecked,
    limit: days.Limit,
  };
}
