import { typeBuilderForUI, optionBuilderForUI, optionBuilderForMyCRM } from './sharedMappers';

export function propertyParametersBuilderForUI(parameter) {
  return {
    purposeParams: parameter.PurposeParams.map(typeBuilderForUI),
    purposeParamsStatus: parameter.PurposeParamsStatus.map(typeBuilderForUI),
  };
}

export function firstHomeBuyerBuilderForUI(result) {
  return {
    brokerEventId: result.BrokerEventID,
    isFirstHomeBuyer: result.IsFirstHomeBuyer,
  };
}

export function firstHomeBuyerBuilderForMyCRM(result) {
  return {
    brokerEventID: result.brokerEventId,
    isFirstBuyer: result.isFirstHomeBuyer,
  };
}

export function frequencyBuilderForUI(result) {
  return {
    frequencyId: result.frequencyID,
    frequencyFactor: result.FrequencyFactor,
    frequencyName: result.FrequencyName,
  };
}

export function proposedLendingSummaryBuilderForUI(result) {
  return {
    capitalizeLoanAmount: result.CapitalizeLoanAmount,
    isInterestOnly: result.IsInterestOnly,
    lmiAmount: result.LMIAmount,
    loanAmount: result.LoanAmount,
    loanTermInYears: result.LoanTermInYears,
    ongoingRate: result.OngoingRate,
    proposedLendingID: result.ProposedLendingID,
    rate: result.Rate,
    rateTerm: result.RateTerm,
    rateType: result.RateType,
    repaymentAmount: result.RepaymentAmount,
    productID: result.ProductID,
    repayment: result.Repayment,
    loanTermInYearsName: result.LoanTermInYearsName,
  };
}


export function fundingCalculatorParametersBuilderForUI(result) {
  const { address, purchaseInfo, clientInfo } = result;
  return {
    fundingCalParametersId: result.FundingCalParametersID,
    landValue: result.LandValue,
    address: {
      country: address.Country,
      state: address.state,
      formattedAddress: address.formatted_address,
    },
    purchaseInfo: {
      propertyValue: purchaseInfo.PropertyValue,
      refinanceAmount: purchaseInfo.RefinanceAmount,
      purpose: purchaseInfo.Purpose,
      purposeName: purchaseInfo.PurposeName,
      propertyStatus: purchaseInfo.PropertyStatus,
      propertyStatusName: purchaseInfo.PropertyStatusName,
      propertyType: purchaseInfo.PropertyType,
      existingLending: purchaseInfo.ExistingLending,
      isPreApproved: purchaseInfo.IsPreApproved,
    },
    clientInfo: {
      firstBuyer: clientInfo.firstBuyer,
      totalIncomeOfPurchasers: clientInfo.totalIncomeOfPurchasers,
      dependents: clientInfo.dependents,
      eligiblePensioner: clientInfo.eligiblePensioner,
      propertyLocation: clientInfo.propertyLocation,
      paymentMethod: clientInfo.paymentMethod,
      foreignPurchaser: clientInfo.foreignPurchaser,
      victoriaCities: clientInfo.VictoriaCities,
    },
    innerFundingRequired: result.InnerFundingRequired.map((obj) => {
      return {
        fundsId: obj.FundsID,
        fundingCalParametersId: obj.FundingCalParametersID,
        name: obj.Name,
        value: obj.Value,
        isOnlyValueEditable: obj.IsOnlyValueEditable,
      };
    }),
  };
}

export function fundingCalculatorParametersBuilderForMyCRM(result) {
  const { address, purchaseInfo, clientInfo } = result.selection;
  return {
    brokerEventID: result.brokerEventId,
    Selection: {
      FundingCalParametersID: result.selection.fundingCalParametersId,
      address: {
        Country: address.country,
        state: address.state,
        latitude: address.latitude,
        longitude: address.longitude,
        formatted_address: address.formattedAddress,
      },
      purchaseInfo: {
        propertyValue: purchaseInfo.propertyValue,
        RefinanceAmount: purchaseInfo.refinanceAmount,
        purpose: purchaseInfo.purpose,
        propertyStatus: purchaseInfo.propertyStatus,
        PropertyType: purchaseInfo.propertyType,
      },
      clientInfo: {
        firstBuyer: clientInfo.firstBuyer,
        totalIncomeOfPurchasers: clientInfo.totalIncomeOfPurchasers,
        dependents: clientInfo.dependents,
        eligiblePensioner: clientInfo.eligiblePensioner,
        propertyLocation: clientInfo.propertyLocation,
        paymentMethod: clientInfo.paymentMethod,
        foreignPurchaser: clientInfo.foreignPurchaser,
        VictoriaCities: clientInfo.victoriaCities,
      },
      LandValue: result.selection.landValue,
    },
  };
}

export function fundingCalculatorFundsBuilderForUI(result) {
  return {
    id: result.ID,
    fundingType: result.FundingType,
    fundingParticulars: result.FundingParticulars,
    fundingValue: result.FundingValue,
    isOnlyValueEditable: result.IsOnlyValueEditable,
    description: result.Description,
  };
}

export function fundingCalculatorFundsBuilderForMyCRM(result) {
  return {
    BrokerEventID: result.brokerEventId,
    FundsID: result.fundsId,
    FundingCalParametersID: result.fundingCalParametersId,
    FundingType: result.fundingType,
    fundingParticulars: result.fundingParticulars,
    FundingValue: result.fundingValue,
    IsOnlyValueEditable: result.isOnlyValueEditable,
    Description: result.description,
  };
}

export function proposedLendingBuilderForUI(result) {
  return {
    proposedLendingId: result.ProposedLendingID,
    productId: result.ProductID,
    loanAmount: result.LoanAmount,
    rate: result.Rate,
    rateType: result.RateType,
    isInterestOnly: result.IsInterestOnly,
    loanTermInYears: result.LoanTermInYears,
    repaymentAmount: result.RepaymentAmount,
    repayment: result.Repayment,
    capitalizeLoanAmount: result.CapitalizeLoanAmount,
    rateTerm: result.RateTerm,
    ongoingRate: result.ongoingRate,
    loanTermInYearsName: result.LoanTermInYearsName,
    lmiAmount: result.LMIAmount,
  };
}

export function proposedLendingBuilderForMyCRM(result) {
  return {
    proposedLendingID: result.proposedLendingId,
    brokerEventID: result.brokerEventId,
    loanAmount: result.loanAmount,
    rate: result.rate,
    rateType: result.rateType,
    loanTerm: result.loanTermInYears,
    isInterestOnly: result.isInterestOnly,
    RateTerm: result.rateTerm,
    LMIAmount: result.lmiAmount,
  };
}

export function securityBuilderForUI(result) {
  return {
    id: result.ID,
    stateOfSecurity: result.StateOfSercurity,
    occupancyType: result.OccupancyType,
    value: result.Value,
  };
}

export function securityBuilderForMyCRM(result) {
  return {
    ID: result.id,
    StateOfSercurity: result.stateOfSecurity,
    OccupancyType: result.occupancyType,
    Value: result.value,
  };
}

export function fundingCalculatorFundsLMIBuilderForUI(result) {
  return {
    fundsLmiId: result.FundsLMIID,
    brokerEventId: result.BrokerEventID,
    lenderId: result.LenderID,
    insurer: result.Insurer,
    isSelfEmployed: result.IsSelfEmployed,
    newLoanAmount: result.NewLoanAmount,
    newLoanTerm: result.NewLoanTerm,
    predominantLoanPurpose: result.PredominantLoanPurpose,
    loanOption: result.loanOption.map(optionBuilderForUI),
    security: result.security.map(securityBuilderForUI),
    capitalizeLmi: result.CapitalizeLMI,
    lvr: result.LVR,
  };
}

export function fundingCalculatorFundsLMIBuilderForMyCRM(result) {
  return {
    FundsLMIID: result.fundsLmiId,
    BrokerEventID: result.brokerEventId,
    LenderID: result.lenderId,
    Insurer: result.insurer,
    IsSelfEmployed: result.isSelfEmployed,
    NewLoanAmount: result.newLoanAmount,
    NewLoanTerm: result.newLoanTerm,
    PredominantLoanPurpose: result.predominantLoanPurpose,
    loanOption: result.loanOption.map(optionBuilderForMyCRM),
    security: result.security.map(securityBuilderForMyCRM),
    CapitalizeLMI: result.capitalizeLmi,
    LVR: result.lvr,
  };
}

export function proposedLendingSettingsBuilderForMyCRM(result) {
  return {
    brokerEventID: result.brokerEventId,
    isCalculatedProposedLending: result.isCalculatedProposedLending,
    totalProposedLending: result.totalProposedLending,
  };
}

export function repaymentFrequencyBuilderForMyCRM(result) {
  return {
    brokerEventID: result.brokerEventId,
    frequencyID: result.frequencyId,
  };
}

export function fundingCalculatorReportBuilderForUI(result) {
  return {
    contentType: result.ContentType,
    documentContent: result.DocumentContent,
    documentExists: result.DocumentExists,
    imageUploadType: result.ImageUploadType,
    isSvg: result.IsSvg,
  };
}

export function fundingCalculatorFundsSummaryBuilderForUI(result) {
  return {
    totalFundingRequired: result.TotalFundingRequired,
    totalFundingAvailable: result.TotalFundingAvailable,
    totalProposedLending: result.TotalProposedLending,
    totalSecurityValue: result.TotalSecurityValue,
    lvr: result.LVR,
    lmiAmount: result.LMIAmount,
    lmiLvr: result.LMI_LVR,
    surplus: result.Surplus,
    deficit: result.Deficit,
    isSurplus: result.IsSurplus,
    totalProposedLendingUserDefined: result.TotalProposedLendingUserDefined,
    isCalculatedProposedLending: result.IsCalculatedProposedLending,
    fundingValue: result.FundingValue,
  };
}
