import angular from 'angular';

angular.module('app').controller('LoanCommissionEnquiryCtrl',
  function LoanCommissionEnquiryCtrl(
    loanInformationService
  ) {
    const vm = this;

    vm.toggleCommissionEnquiry = (commissionType) => {
      loanInformationService.openCommissionEnquiry(commissionType);
    };
  });
