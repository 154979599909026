import angular from 'angular';

angular.module('app').filter('shortenAmount', function shortenAmount() {
  return function (pAmount, pAfterDotDigits) {
    let amount = pAmount;
    let afterDotDigits = pAfterDotDigits;
    // 2 decimal places => 100, 3 => 1000, etc
    amount = parseInt(amount, 10);
    afterDotDigits = 10 ** afterDotDigits;

    // Enumerate number abbreviations
    const abbrev = ['k', 'm', 'b', 't'];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
      // Convert array index to "1000", "1000000", etc
      const size = 10 ** ((i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= amount) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        amount = Math.round(amount * afterDotDigits / size) / afterDotDigits;

        // Handle special case where we round up to the next abbreviation
        if ((amount === 1000) && (i < abbrev.length - 1)) {
          amount = 1;
          i++;
        }

        // Add the letter for the abbreviation
        amount += abbrev[i];

        // We are done... stop
        break;
      }
    }

    return amount;
  };
});
