import { totalLoanAmountsBuilderForUI } from 'Common/mappers/loanOpportunity';

export function opportunityCardsBuilderForUI(data) {
  return {
    cards: data.Cards.map((card) => {
      return {
        contactNames: card.ContactNames,
        adviserFirstName: card.AdviserFirstName,
        adviserLastName: card.AdviserLastName,
        adviserFullName: card.AdviserFullName,
        title: card.Title,
        loanScenarioId: card.LoanScenarioId,
        pipelineStatusId: card.PipelineStatusId,
        proposedLoanAmount: card.ProposedLoanAmount,
        securityAmount: card.SecurityAmount,
        lastModifiedDate: card.LastModifiedDate,
        enquiry: card.Enquiry,
        loanId: card.LoanId,
      };
    }),
    totalLoanAmounts: data.TotalLoanAmounts.map(totalLoanAmountsBuilderForUI),
  };
}

export function opportunityTableBuilderForUI(rowData) {
  return {
    contactNames: rowData.ContactNames,
    adviserFullName: rowData.AdviserFullName,
    title: rowData.Title,
    loanScenarioId: rowData.LoanScenarioId,
    pipelineStatus: rowData.PipelineStatus,
    pipelineStatusId: rowData.PipelineStatusId,
    pipelineCardsId: rowData.PipelineCardsId,
    proposedLoanAmount: rowData.ProposedLoanAmount,
    referrerName: rowData.ReferrerName,
    securityAmount: rowData.SecurityAmount,
    enquiry: rowData.Enquiry,
    loanId: rowData.LoanId,
    totalRecords: rowData.TotalRecords,
    statusUpdated: rowData.StatusUpdated,
  };
}

export function pipelineSettingsBuilderForAPI(settings) {
  return {
    SortColumn: settings.sortColumn,
    SortType: settings.sortType,
    StatusId: settings.statusId,
    AdvisorId: settings.advisorId,
    LabelId: settings.labelId,
    Probability: settings.probability,
    ReferralItemID: settings.referralItemID,
    ReferralCategoryID: settings.referralCategoryID,
    Referral: settings.referral,
    IsCompact: settings.isCompact,
    SortingMode: settings.sortingMode,
    IsTableView: settings.isTableView,
    CustomerSort: settings.customerSort,
    TableViewExcludedColumns: settings.tableViewExcludedColumns,
    SubStatusId: settings.subStatusId,
    searchClientName: settings.searchClientName,
    PipelineCardsId: settings.pipelineCardsId,
    IsOpportunity: settings.isOpportunity,
  };
}

export function pipelineSettingsBuilderForUI(settings) {
  return {
    sortColumn: settings.SortColumn,
    sortType: settings.SortType,
    statusId: settings.StatusId,
    advisorId: settings.AdvisorId,
    labelId: settings.LabelId,
    probability: settings.Probability,
    referralItemID: settings.ReferralItemID,
    referralCategoryID: settings.ReferralCategoryID,
    referral: settings.Referral,
    isCompact: settings.IsCompact,
    sortingMode: settings.SortingMode,
    isTableView: settings.IsTableView,
    customerSort: settings.CustomerSort,
    tableViewExcludedColumns: settings.TableViewExcludedColumns,
    subStatusId: settings.SubStatusId,
    searchClientName: settings.searchClientName,
    pipelineCardsId: settings.PipelineCardsId,
    isOpportunity: settings.IsOpportunity,
  };
}

export function pipelineStatusBuilderForUI(status) {
  return {
    brokerBrandingId: status.BrokerBrandingID,
    categoryName: status.CategoryName,
    pipelineCategoryId: status.PipelineCategoryID,
    pipelineStatus: status.PipelineStatus,
    pipelineStatusId: status.PipelineStatusID,
    subStatus: status.SubStatus,
    typeName: status.TypeName,
    isConversionStatus: status.IsConversionStatus,
  };
}
