import _ from 'lodash';
import { FIXED_RATE_EXPIRES_ID, APPROVAL_EXPIRIES_ID, BIRTHDAYS_ID, ANNUAL_REVIEWS_ID, POST_SETTLEMENT_FOLLOW_UP_ID } from 'Common/constants/customerCareModules';
import CustomerCareModule from './customerCareModule';

class TemplateService {
  constructor($uibModal, $q, userService, uiService) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.userService = userService;
    this.uiService = uiService;
  }

  openDefaultTemplateSettings(template, methodType) {
    if (this.isValidTemplate(template)) {
      this.$uibModal.open({
        templateUrl: 'assets/views/customerCare/partials/modal/set_default_template_modal.html',
        size: 'md',
        controller: 'SetDefaultTemplateModalCtrl as vm',
        resolve: {
          template: () => template,
          methodType: () => methodType,
        },
      });
    }
  }

  openSMSTemplatesManagement(showDefaultTemplateSettings) {
    return this.$uibModal.open({
      templateUrl: 'assets/views/contacts/sms_templates_management.html',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      controller: 'SmsTemplatesManagementCtrl as vm',
      resolve: {
        showDefaultTemplateSettings: () => showDefaultTemplateSettings,
      },
    });
  }

  isValidTemplate(template) {
    this.validTemplate = template && template.UserTemplateName && template.UserTemplateID;
    return this.validTemplate;
  }

  isBlankFolderOrTemplate(template) {
    this.isBlank = template && template.UserTemplateName === 'Blank' && template.IsSystemTemplate;
    return this.isBlank;
  }

  getBlankTemplateIn(blankCategory) {
    if (blankCategory && this.isBlankFolderOrTemplate(blankCategory)) {
      let blankTemplate;
      if (blankCategory.DocRtfSMSTemplateList && blankCategory.DocRtfSMSTemplateList.length) {
        blankTemplate = blankCategory.DocRtfSMSTemplateList[0];
      } else if (blankCategory.EmailTemplates && blankCategory.EmailTemplates.length) {
        blankTemplate = blankCategory.EmailTemplates[0];
      } else {
        blankTemplate = { UserTemplateID: 0, UserTemplateFolderId: blankCategory.UserTemplateID, UserTemplateName: 'Blank', isSharedWithAdviser: true };
      }
      blankTemplate.IsSystemTemplate = true;
      return blankTemplate;
    }
  }

  getModuleDefaultTemplate(moduleId, methodType) {
    const defer = this.$q.defer();

    if (moduleId) {
      this.userService.UserDefaultTemplateGet(moduleId, methodType).then((response) => {
        if (response.data) {
          defer.resolve({ data: response.data[0] });
        }
      });
    }

    return defer.promise;
  }

  getCustomerCareModulesDefaultTemplates(methodType) {
    const defer = this.$q.defer();

    this.userService.UserDefaultTemplateGet(0, methodType).then((response) => {
      const modules = [];
      modules.push(new CustomerCareModule(FIXED_RATE_EXPIRES_ID));
      modules.push(new CustomerCareModule(APPROVAL_EXPIRIES_ID));
      modules.push(new CustomerCareModule(BIRTHDAYS_ID));
      modules.push(new CustomerCareModule(ANNUAL_REVIEWS_ID));
      modules.push(new CustomerCareModule(POST_SETTLEMENT_FOLLOW_UP_ID));
      if (response.data) {
        _.each(modules, (mod) => {
          const defaultTemplate = response.data.find(template => template.ModuleID === mod.ModuleID);
          if (defaultTemplate) {
            mod.UserTemplateID = defaultTemplate.UserTemplateID;
            mod.UserTemplateName = defaultTemplate.UserTemplateName;
          }
        });
      }
      defer.resolve({ data: modules });
    }, error => defer.reject(error));

    return defer.promise;
  }

  filterTemplatesByUserType(list) {
    if (!this.uiService.isCorporateUser && list) {
      return list.filter(folder => folder.isSharedWithAdviser || folder.IsSharedWithAdviser || !folder.IsSystemTemplate);
    }
    return list;
  }
}

export default TemplateService;
