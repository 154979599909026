/**
* This template reacts to textarea changes
* and applies dynamic height to the div and textarea
*/

import angular from 'angular';
import textAreaHeightAuto from './textAreaHeightAuto';
import './style.scss';

export default angular.module('components.textAreaHeightAuto', [])
  .component('textAreaHeightAuto', textAreaHeightAuto)
  .name;
