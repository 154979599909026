import angular from 'angular';

angular.module('app').directive('showOnScrollOf', function showOnScrollOf($document) {
  return {
    restrict: 'A',
    scope: {
      scrollableElem: '@showOnScrollOf',
    },
    link(scope, element, attrs) {
      const scrollableElem = $document.find(scope.scrollableElem);
      if (scrollableElem) {
        const scrollLeftOffset = attrs.showOnScrollBy ? Number(attrs.showOnScrollBy) : 150;

        const onScrollListener = () => {
          if (scrollableElem.scrollLeft() > scrollLeftOffset) {
            element.fadeIn();
          } else {
            element.fadeOut();
          }
        };
        scrollableElem.on('scroll', onScrollListener);

        const onElemClickListener = () => {
          scrollableElem.animate({ scrollLeft: 0 }, 600);
          return false;
        };
        element.on('click', onElemClickListener);

        scope.$on('$destroy', () => {
          element.off('click', onElemClickListener);
          scrollableElem.off('scroll', onScrollListener);
        });
      }
    },
  };
});
