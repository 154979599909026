import template from './sendEmail.html';

export default {
  template,
  bindings: {
    familyId: '<',
    isWorkbench: '<',
    loanId: '<',
    emailToEdit: '<',
    onBack: '&',
    modalInstance: '<',
  },
  controller: 'SendEmailComponentCtrl',
};
