import { sortBy } from 'lodash';
import { getParameterByName } from 'Common/utilities/api';

export default class MarryContactsModalCtrl {
  constructor(
    $q,
    $state,
    $window,
    toaster,
    contactService,
    mergeContactsService
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$window = $window;
    this.toaster = toaster;
    this.contactService = contactService;
    this.mergeContactsService = mergeContactsService;
  }

  $onInit() {
    this.mergeability = {};
    this.activate();
  }

  activate() {
    this.mergeContactsService.initializeClientsToMergeFamilyInfo(this.clientsToMerge)
      .then(() => this.getClientsLoanApps());
  }

  viewContact(client) {
    const url = this.$state.href('app.contactsSingle', { familyId: client.FamilyID });
    this.$window.open(url, '_blank');
  }

  getClientsLoanApps() {
    const promises = [];

    this.clientsToMerge.forEach((client) => {
      promises.push(this.contactService.loanListGet(client.FamilyID));
    });

    this.$q.all(promises)
      .then((responses) => {
        if (!responses) return;

        responses.forEach((response) => {
          const familyID = getParameterByName('familyID', response.config.url);
          const client = this.clientsToMerge.find((obj) => {
            return obj.FamilyID === familyID;
          });

          if (client) {
            const { data } = response;
            if (!data) return;

            client.loanApps = sortBy(data.filter((loanApp) => {
              return loanApp.Lender && loanApp.Lender.LenderName;
            }), (loanApp) => {
              return loanApp.CreatedDate;
            });
          }
        });
      });
  }

  cancel() {
    this.clientsToMerge.forEach((client) => {
      client.keepRecords = false;
    });
    this.modalInstance.dismiss('cancelled');
  }

  merge() {
    this.merging = true;

    this.mergeContactsService.mergeFamilyContacts(this.clientsToMerge)
      .then((response) => {
        if (response.data) {
          this.toaster.pop('success', 'Clients successfully merged');
          this.modalInstance.close();
          this.openMergedClient(response.data.familyId);
        } else {
          this.toaster.pop('error', 'Clients not merged successfullly');
        }

        this.merging = false;
      }, () => {
        this.merging = false;
        this.toaster.pop('error', 'Clients not merged successfullly');
      });
  }

  openMergedClient(mainFamilyId) {
    this.$state.go('app.contactsSingle', { familyId: mainFamilyId });
  }

  toggleClientKeepRecords(client) {
    this.clientsToMerge.forEach((obj) => {
      obj.keepRecords = obj.FamilyID === client.FamilyID;
    });

    this.mergeContactsService.checkClientsMergeability(this.clientsToMerge, client.FamilyID)
      .then((response) => {
        if (!response) return;

        this.mergeability.mergeable = response.mergeable;
        this.mergeability.warning = response.warning;
      });
  }
}
