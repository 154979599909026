/* CLEARFIELD FOR LOAN CALCULATOR REQUIREMENTS:
  INPUTS MUST HAVE A UNIQUE ¬ID¬ IN ITS OWN HTML,
  SAMPLE HTML CODE
  <input clear-field value-to-clear="goal.initialDeposit" ng-model="goal.initalDeposit" id="thisShouldBeUniquePerPage" />
  <button clear-field>BUTTON FOR THE TRIGGER TO RESET THE INPUT WHEN FOCUSED!</button>
  made by Peter Anthony Duot
*/
import angular from 'angular';
import _ from 'lodash';

angular.module('app').directive('clearField', function clearField($window, loanCalculatorSharedData) {
  return {
    restrict: 'A',
    scope: {
      valueToClear: '=',
    },
    link(scope, element) {
      angular.element($window.document).ready(() => {
        const theElementId = element.context.id;
        const clearFieldVals = {};
        let elementIndex = 0;
        let originalValue = 0;
        angular.extend(clearFieldVals, {
          isCalculated: false,
          isChanged: false,
          theElementId: '',
          originalValue: 0,
          newValue: 0,
        });
        /* setting the default values of clearFields */
        if (!_.isEmpty(element.context.id) && element.context.localName !== 'button') {
          clearFieldVals.isCalculated = false;
          clearFieldVals.isChanged = false;
          clearFieldVals.originalValue = scope.valueToClear;
          clearFieldVals.newValue = scope.valueToClear;
          clearFieldVals.theElementId = theElementId;
          loanCalculatorSharedData.valueSet.push(clearFieldVals);
        }
        /* checking if valueToClear is not undefined */
        if (!_.isUndefined(scope.valueToClear)) {
          element.context.onfocus = function () {
            elementIndex = scope.getIndex();
            if (loanCalculatorSharedData.valueSet[elementIndex].isCalculated === false) {
              originalValue = scope.valueToClear;
              scope.valueToClear = '';
              loanCalculatorSharedData.valueSet[elementIndex].newValue = '';
            }
          };
          element.context.onblur = function () {
            if (loanCalculatorSharedData.valueSet[elementIndex].newValue !== loanCalculatorSharedData.valueSet[elementIndex].originalValue) {
              loanCalculatorSharedData.valueSet[elementIndex].isChanged = true;
              loanCalculatorSharedData.valueSet[elementIndex].newValue = scope.valueToClear;
            }
            if ((_.isEmpty(loanCalculatorSharedData.valueSet[elementIndex].originalValue) || !loanCalculatorSharedData.valueSet[elementIndex].originalValue)) {
              loanCalculatorSharedData.valueSet[elementIndex].originalValue = originalValue;
            }
            loanCalculatorSharedData.valueSet[elementIndex].isCalculated = true;
            if ((_.isEmpty(loanCalculatorSharedData.valueSet[elementIndex].newValue) || !loanCalculatorSharedData.valueSet[elementIndex].newValue) && loanCalculatorSharedData.valueSet[elementIndex].originalValue) {
              scope.valueToClear = loanCalculatorSharedData.valueSet[elementIndex].originalValue;
              loanCalculatorSharedData.valueSet[elementIndex].isCalculated = false;
            }
            if (loanCalculatorSharedData.valueSet[elementIndex].isChanged === false) {
              loanCalculatorSharedData.valueSet[elementIndex].newValue = loanCalculatorSharedData.valueSet[elementIndex].originalValue;
            }
          };
        }
        angular.extend(scope, {
          setAllIsCalculatedToFalse() {
            _.forEach(loanCalculatorSharedData.valueSet, (object) => {
              object.isCalculated = false;
              object.isChanged = false;
              object.originalValue = object.newValue;
              if (object.newValue === '' || _.isEmpty(object.newValue)) {
                angular.element(`#${object.theElementId}`).context.value = object.originalValue;
              }
            });
          },
          getIndex() {
            return _.findIndex(loanCalculatorSharedData.valueSet, (object) => { return object.theElementId === theElementId; });
          },
        });
        if (element.context.localName === 'button')
          element.on('click', () => {
            scope.setAllIsCalculatedToFalse();
          });
      });
    },
  };
});
