import angular from 'angular';

angular.module('app').directive('textFormat', function textFormat($filter) {
  return {
    require: '?ngModel',
    link(scope, elem, attrs, ctrl) {
      if (!ctrl) return;

      if (attrs.currency === 'IDR') {
        ctrl.$formatters.unshift(() => {
          if (!ctrl.$modelValue) {
            if (attrs.needZero) {
              return $filter(attrs.textFormat)(ctrl.$modelValue).replace(/[,.]/g, (m) => { return m === ',' ? '.' : ','; });
            }
            return $filter(attrs.textFormat)(null);
          }
          return $filter(attrs.textFormat)(ctrl.$modelValue).replace(/[,.]/g, (m) => { return m === ',' ? '.' : ','; });
        });

        ctrl.$parsers.unshift((pViewValue) => {
          let viewValue = pViewValue;
          const checkForComma = viewValue.match(/,/g) || [];
          let tempData;
          let plainNumber;
          let splitData;

          if (checkForComma.length > 1) {
            viewValue = viewValue.substring(0, viewValue.length - 1);
            elem.val(viewValue);
            ctrl.$setViewValue(viewValue);
            return viewValue.replace(/,/g, '.');
          }
          if (viewValue.slice(-1) !== ',') {
            if (viewValue.slice(-1) === '0') {
              tempData = viewValue.toString();

              if (tempData.slice(-2, -1) === ',') {
                elem.val(viewValue);
                return viewValue.replace(/,/g, '.');
              }
              if (checkForComma.length && tempData.split(',')[1].length > 2) {
                viewValue = viewValue.substring(0, viewValue.length - 1);
                ctrl.$setViewValue(viewValue);
                elem.val(viewValue);
                return viewValue.replace(/,/g, '.');
              }
              plainNumber = viewValue.replace(/[^\d|\-+|,+]/g, '');
              elem.val(plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
              return plainNumber.replace(/,/g, '.');
            } else if (Number.isNaN(viewValue.slice(-1))) {
              tempData = viewValue.slice(0, -1);
              plainNumber = tempData.replace(/[^\d|\-+|,+]/g, '');

              if (plainNumber !== viewValue) {
                ctrl.$setViewValue(plainNumber);
              }

              elem.val(plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
              return plainNumber.replace(/,/g, '.');
            }

            tempData = viewValue.toString();

            if (tempData.split(',')[1]) {
              splitData = tempData.split(',')[1].length;
            }

            if (splitData > 2) {
              viewValue = viewValue.substring(0, viewValue.length - 1);
            }

            plainNumber = viewValue.replace(/[^\d|\-+|,+]/g, '');
            elem.val(plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
            ctrl.$setViewValue(plainNumber);
            return plainNumber.replace(/,/g, '.');
          }

          elem.val(viewValue);
          return viewValue.replace(/,/g, '.');
        });
      } else {
        ctrl.$formatters.unshift(() => {
          if (!ctrl.$modelValue) {
            if (attrs.needZero) {
              return $filter(attrs.textFormat)(ctrl.$modelValue);
            }
            return $filter(attrs.textFormat)(null);
          }
          return $filter(attrs.textFormat)(ctrl.$modelValue);
        });

        ctrl.$parsers.unshift((pViewValue) => {
          let viewValue = pViewValue;
          const checkForDots = viewValue.match(/\./g) || [];
          let tempData;
          let plainNumber;
          let splitData;

          if (checkForDots.length > 1) {
            viewValue = viewValue.substring(0, viewValue.length - 1);
            ctrl.$setViewValue(viewValue);
            elem.val(viewValue);
            return viewValue;
          }
          if (viewValue.slice(-1) !== '.') {
            if (viewValue.slice(-1) === '0') {
              tempData = viewValue.toString();

              if (tempData.slice(-2, -1) === '.') {
                elem.val(viewValue);
                return viewValue;
              }
              plainNumber = viewValue.replace(/[^\d|\-+|.+]/g, '');
              elem.val($filter(attrs.textFormat)(plainNumber));
              return plainNumber;
            } else if (Number.isNaN(viewValue.slice(-1))) {
              tempData = viewValue.slice(0, -1);
              plainNumber = tempData.replace(/[^\d|\-+|.+]/g, '');

              if (plainNumber !== viewValue) {
                ctrl.$setViewValue(plainNumber);
              }

              elem.val($filter(attrs.textFormat)(plainNumber));
              return plainNumber;
            }

            tempData = viewValue.toString();

            if (tempData.split('.')[1]) {
              splitData = tempData.split('.')[1].length;
            }

            if (splitData > 2) {
              viewValue = viewValue.substring(0, viewValue.length - 1);
            }

            plainNumber = viewValue.replace(/[^\d|\-+|.+]/g, '');
            elem.val($filter(attrs.textFormat)(plainNumber));
            ctrl.$setViewValue(plainNumber);
            return plainNumber;
          }

          elem.val(viewValue);
          return viewValue;
        });
      }
    },
  };
});
