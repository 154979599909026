import angular from 'angular';
import _ from 'lodash';
import { TRANSACTION_TYPE, DEFAULT_VALUES } from 'Common/constants/securityValues';
import { assetFinanceIgnore, checkIfInvestment } from 'Common/utilities/security';

angular.module('app').controller('ShowAddSecurityModalCtrl',
  function ShowAddSecurityModalCtrl(
    $scope,
    $uibModalInstance,
    toaster,
    optionsService,
    $timeout,
    generalService,
    loanApplicationServices,
    dashboardService,
    loanAppSharedData,
    uiService) {
    $scope.assetFinanceIgnore = assetFinanceIgnore;

    const lenderPlace = 1; /* Lender place for loanapp */
    $scope.uiService = uiService;
    optionsService.getLenderList(lenderPlace).then((response) => {
      if (response.data) {
        $scope.LenderListList = response.data;
        $scope.LenderListList.unshift({ ProviderName: 'Please select', ProviderId: '' });
      }
    });

    const setDefaultValues = () => {
      $scope.security.add.PropertyDetails.StatusId = DEFAULT_VALUES.ESTABLISHED_STATUSID;
      $scope.security.add.PropertyDetails.PropertyPrimaryPurposeId = DEFAULT_VALUES.OWNEROCCUPIED_PRIMARYPURPOSE;
      $scope.security.add.PropertyDetails.HoldingId = loanAppSharedData.applicantsLength(loanAppSharedData.listApplicants) === 1 ? DEFAULT_VALUES.SOLE_HOLDINGID : DEFAULT_VALUES.JOINT_HOLDINGID;
      $scope.security.add.PropertyDetails.ZoningId = DEFAULT_VALUES.RESIDENTIAL_ZONINGID;
    };

    if ($scope.TransactionTypeList) {
      /**
       * Start: https://trello.com/c/EqPlPK20
       * */
      _.forEach($scope.TransactionTypeList, (o) => {
        const value = parseInt(o.Value, 10);
        o.disabled = value !== TRANSACTION_TYPE.PURCHASING && value !== TRANSACTION_TYPE.CONSTRUCTION;
      });
    } else {
      optionsService.getTransactionType().then((response) => {
        $scope.TransactionTypeList = _.map(response.data, (o) => {
          const value = parseInt(o.Value, 10);
          o.disabled = value !== TRANSACTION_TYPE.PURCHASING && value !== TRANSACTION_TYPE.CONSTRUCTION;
          return o;
        });
      });
    }

    $scope.incomeSettings = {
      showAddIncome: false,
    };

    $scope.IncomeList = [];

    $scope.addNewIncome = LoanSecurityInfoID => $scope.IncomeList.push($scope.getNewIncomeModel(LoanSecurityInfoID));

    $scope.deleteIncome = (obj, index) => {
      $scope.IncomeList.splice(index, 1);
    };

    $scope.$watch('IncomeList', (nv) => {
      $scope.incomeSettings.showAddIncome = _.size(nv) > 0;
    }, true);

    _.map($scope.formAreas, (area) => {
      area.error = false;
      return area;
    });

    $scope.securityShowTabContent = (tab) => {
      $scope.showTab = tab;
    };
    $scope.showTab = 'info';

    $scope.getApplicants(true);
    $scope.getOwners();
    $scope.getEmptySecurityInfo();
    $scope.getSecurityOptions();

    $scope.$watch(
      'security.add.PropertyDetails.ZoningId',
      (nv) => {
        if (nv !== '') {
          optionsService.getPropertyType(nv).then((response) => {
            $scope.PropertyTypeList = response.data;
            $scope.PropertyTypeList.unshift({ AddressPropertyType: 'Please select', AddressPropertyTypeID: '' });
            if (nv === DEFAULT_VALUES.RESIDENTIAL_ZONINGID && !$scope.security.add.PropertyDetails.PropertyTypeId) {
              $scope.security.add.PropertyDetails.PropertyTypeId = DEFAULT_VALUES.FULLY_DETACHED_HOUSE;
            } else {
              $scope.security.add.PropertyDetails.PropertyTypeId = '';
            }
          });
        }
      }
    );

    $scope.$watch(
      'security.add.TransactionId',
      (nv) => {
        $scope.security.add.IsRefinance = parseInt(nv, 10) === 2;
      }
    );

    $scope.security.add.datePopUp = {
      ContractDate: false,
    };
    // Open Date Popup
    $scope.security.add.openDate = (dateField) => {
      $scope.security.add.datePopUp[dateField] = !$scope.security.add.datePopUp[dateField];
    };

    $scope.security.add.dateFormat = 'dd MMM yyyy';
    $scope.security.add.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
    $scope.security.add.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    $scope.security.add.SecurityTypeId = 1; // default to Registered
    $scope.security.add.TransactionId = 1; // default to Purchasing

    /**
    * End: https://trello.com/c/EqPlPK20
    * */

    $scope.security.add.getFormattedAddress = () => {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService.placeSearch($scope.security.add.Address.formatted_address).then((response) => {
          $scope.security.add.addressSuggestions = response.data;
          $scope.security.add.showSuggestionModal = true;
        }, () => {
        });
      }, 500);
    };

    $scope.security.add.closeAutoHomeAddress = () => {
      $scope.security.add.addressSuggestions = [];
    };

    $scope.security.add.pickAddressSuggestion = (homeAddress) => {
      $scope.security.add.Address = homeAddress;

      // Address to post
      $scope.security.add.ContactAddress = homeAddress;

      $scope.security.add.closeAutoHomeAddress();
      $scope.security.add.showSuggestionModal = false;
      $scope.security.saveAddress();
    };

    $scope.showSecurityList = () => {
      if ($scope.LoanSecurityList && _.isUndefined($scope.LoanSecurityList.LoanSecurityId)) {
        loanApplicationServices.setLoanSecurity({
          LoanApplicationId: $scope.loanAppId,
          LoanSecurityId: 0,
        }).then(() => {
          $scope.getLoanSecurity();

          $scope.securityList = $scope.LoanSecurityList.SecurityList;
        });
      }
    };

    $scope.showSecurityList();

    $scope.security.saveAddress = (cb) => {
      // trap due to sentry error
      if (!$scope.security.add.Address) {
        $scope.security.add.Address = {};
      }
      let callback = cb;
      if (_.isUndefined(cb)) {
        callback = () => {};
      }

      if ($scope.security.add.Address.isCustomAddress) {
        const { street_address: streetAddress, locality, postal_code: postalCode, stateShort_Name: state } = $scope.security.add.Address;
        const local = locality ? `, ${locality}` : '';
        const zip = postalCode ? `, ${postalCode}` : '';
        const stateShortName = state ? `, ${state}` : '';
        $scope.security.add.Address.formatted_address = `${streetAddress}${local}${stateShortName}${zip}`;
      }
      dashboardService.addressDetailsSet(
        {
          ...$scope.security.add.Address,
          IsMailing: false,
          Type: $scope.security.add.Address.Type,
          countryCode: $scope.security.add.Address.CountryCode,
          TypeId: $scope.security.add.Address.AddressType,
          AddressID: 0,
          AddressValuationExtendedDetailsId: 0,
        }
      ).then((response) => {
        $scope.security.add.PropertyDetails.AddressId = response.data;
        callback();
      });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.validateSecurityForm = (form) => {
      $scope.hasError = false;
      _.map($scope.formAreas, (area) => {
        area.error = false;
        return area;
      });

      _.each(form, (field, key) => {
        if (field && key[0] !== '$') {
          if (!field.$valid || field.$viewValue === '') {
            const exceptName = ['IdentityTypeId-0', 'IdentityValue-0', 'FaxNumber', 'FaxAreaCode', 'startDate']; // this is done because the $error.required property is not working properly
            if (exceptName.indexOf(field.$name) === -1) {
              field.$setValidity('required', false);
              $scope.hasError = true;

              // some names have indexes prepended by "-", let remove them here to properly check by it's original name
              const nameToSearch = field.$name ? field.$name.split('-')[0] : '';
              _.forEach($scope.formAreas, (area) => {
                if (!area.error && _.findIndex(area.data, (o) => { return o === nameToSearch; }) > -1) {
                  area.error = true;
                }
                return area;
              });
            }
          } else if (field.$name === 'owners') {
            // validate owners 100%
            let countPercentages = 0;
            let checkPercentage = true;
            $scope.ownersError = false;
            $scope.formAreas.info.error = false;
            $scope.security.add.Ownership.forEach((obj) => {
              countPercentages += parseFloat(obj.Percentage);
              if (checkPercentage && countPercentages > 100) {
                field.$setValidity('required', true);
                $scope.hasError = true;
                $scope.ownersError = true;
                checkPercentage = false;

                $scope.formAreas.info.error = true;
              }
            });
          } else {
            field.$setValidity('required', true);
          }

          if (field.$pristine) {
            field.$dirty = true;
          }
        }
      });

      if ($scope.hasError) {
        $scope.security.sending = false;
        return false;
      }
      form.$setValidity('required', true);

      return true;
    };

    $scope.$watch('security.add', () => {
      if ($scope.addSecurityForm.$submitted)
        $scope.validateSecurityForm($scope.addSecurityForm);
    }, true);

    $scope.$watch('IncomeList', () => {
      if ($scope.addSecurityForm.$submitted)
        $scope.validateSecurityForm($scope.addSecurityForm);
    }, true);

    const proceedAddSecurity = () => {
      if (assetFinanceIgnore($scope.security.add.SecurityTypeId)) {
        $scope.security.add.PropertyDetails.StatusId = 0;
        $scope.security.add.PropertyDetails.PropertyPrimaryPurposeId = 0;
        $scope.security.add.PropertyDetails.HoldingId = 0;
        $scope.security.add.PropertyDetails.ZoningId = 0;
        $scope.security.add.PropertyDetails.PropertyTypeId = 0;
      }
      const { add: addData } = $scope.security;
      const setData = {
        LoanApplicationId: $scope.loanAppId,
        SecurityInfoId: 0,
        FundingCalParametersId: 0,
        IsPrimary: addData.IsPrimary,
        SecurityTypeId: parseInt(addData.SecurityTypeId, 10),
        LoanSecurityId: parseInt($scope.LoanSecurityList.LoanSecurityId, 10),
        TransactionId: parseInt(addData.TransactionId, 10),
        MortgagePriority: addData.MortgagePriority,
        IsRefinance: addData.IsRefinance,
        PropertyDetails: {
          LoanSecurityId: 0,
          StatusId: parseInt(addData.PropertyDetails.StatusId, 10),
          PropertyPrimaryPurposeId: parseInt(addData.PropertyDetails.PropertyPrimaryPurposeId, 10),
          HoldingId: parseInt(addData.PropertyDetails.HoldingId, 10),
          ZoningId: parseInt(addData.PropertyDetails.ZoningId, 10),
          PropertyTypeId: parseInt(addData.PropertyDetails.PropertyTypeId, 10),
          Suburb: addData.PropertyDetails.IsPreApproved ? addData.PropertyDetails.Suburb : '',
          PropertyState: addData.PropertyDetails.IsPreApproved ? addData.PropertyDetails.PropertyState : '',
          CurrentValue: !addData.PropertyDetails.IsPreApproved ? addData.PropertyDetails.CurrentValue : '',
          LandValue: !addData.PropertyDetails.IsPreApproved ? addData.PropertyDetails.LandValue : '',
          EstimatedPrice: addData.PropertyDetails.IsPreApproved ? addData.PropertyDetails.EstimatedPrice : '',
          EstimatedBasesId: parseInt(addData.PropertyDetails.EstimatedBasesId, 10),
          ContratctPrice: !addData.PropertyDetails.IsPreApproved ? addData.PropertyDetails.ContratctPrice : '',
          ContractDate: addData.PropertyDetails.ContractDate,
          LicensedRealEstateAgentContract: addData.PropertyDetails.LicensedRealEstateAgentContract,
          IsPreApproved: addData.PropertyDetails.IsPreApproved,
          IdentifierList: addData.PropertyDetails.IdentifierList,
          AddressId: !addData.PropertyDetails.IsPreApproved ? addData.PropertyDetails.AddressId : 0,
          TitleTypeId: addData.PropertyDetails.TitleTypeId.toString(), // TitleTenureType
          TitleId: addData.PropertyDetails.TitleId.toString(), // TitleType
          ContactForAccess: {
            ...addData.PropertyDetails.ContactForAccess,
          },
        },
        MortgageList: addData.MortgageList,
        IncomeList: $scope.IncomeList,
        Ownership: addData.Ownership,
        AssetId: 0,
      };
      loanApplicationServices.setLoanSecurityInfo(setData).then((response) => {
        if (response.status === 200) {
          $scope.financialsSharedService.LiabilityInfoGet();
          $scope.financialsSharedService.IncomeInfoGet();
          $scope.loanAppSharedData.REFRESH_FUNDS_AVAILABLE_SECTION = { isNew: true };
          $scope.getLoanSecurity();
          $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
          $scope.cancel();
        } else if (response.status === -1) {
          toaster.pop('error', 'Please provide a valid state');
        }
      });
    };

    $scope.addSecurity = (formData) => {
      $scope.security.sending = true;

      if ($scope.validateSecurityForm(formData) === true) {
        if ($scope.mortgageSettings.showAddMortgage) {
          $scope.saveNewMortgage();
        }

        const validSecurity = (assetFinanceIgnore($scope.security.add.SecurityTypeId)) || $scope.security.add.PropertyDetails.IsPreApproved;
        if (validSecurity) {
          proceedAddSecurity();
        } else {
          $scope.security.saveAddress(proceedAddSecurity);
        }
      }
    };

    $scope.checkIfInvestment = () => {
      $scope.isInvestment = checkIfInvestment($scope.security.add.PropertyDetails.PropertyPrimaryPurposeId);
    };
    $scope.checkIfInvestment();
    setDefaultValues();
  });
