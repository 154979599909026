import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';
import { REPORT_TYPE } from 'Common/constants/reportType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { getMonthList } from 'Common/utilities/month';

angular.module('app').controller('ReportCtrl',
  function ReportCtrl($scope,
    $rootScope,
    $http,
    SweetAlert,
    configService,
    $filter,
    dashboardService,
    imageDataURI,
    $timeout,
    $templateCache,
    overviewDashboardService,
    reportService,
    communicateService,
    $ocLazyLoad,
    corporateService,
    $window,
    jasperService,
    pipelineStatusService,
    commissionService,
    optionsService
  ) {
    const restrictedReportList = [REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED, REPORT_TYPE.CUSTOMER_BIRTHDAYS_LIST];
    $scope.reportModel = {
      isLoaded: false,
      monthSelected: 0,
      fiscalYear: {},
      pipelineStatusList: [],
      isIncludedInMarketing: true,
      isAdviserOpportunityCreator: false,
      isOpportunityAllocatedToAdviser: true,

    };
    $scope.showIPInsurersGraph = true;
    $scope.pipelineReport = [];
    $scope.currentPage = 1;
    $scope.totalPages = 1;
    const test = {};
    test.isTestEnvironment = configService.allow_report_from_uat;
    test.data = {
      status: 'active',
      url: 'https://uat-reports.loanmarket.com',
      token: configService.jasper_token,
    };
    $scope.reportDate = {};
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    $scope.monthList = getMonthList();
    $scope.reportModel.monthSelected = month + 1;
    $scope.preloading = true;
    const getCountryFiscalYear = () => {
      reportService.getCountryFiscalYear().then((response) => {
        if (response.data.length > 0) {
          $scope.reportModel.fiscalYear = response.data[0];
        }
      });
    };
    getCountryFiscalYear();

    const getReportingState = () => {
      corporateService.reportingStatesGet().then((response) => {
        const { data } = response;
        const reportingList = data && data.map((row) => {
          row.ticked = false;
          return row;
        });
        const reportingForAllStates = {
          ReportingStateId: -1,
          ReportingStateName: 'All States',
          ticked: true,
        };
        $scope.reportModel.reportingStateRaw = [reportingForAllStates, ...reportingList];
        $scope.reportModel.reportingState = [reportingForAllStates, ...reportingList];
        $scope.reportModel.reportingStateSelected = reportingForAllStates;
      });
    };
    getReportingState();
    const getUnconditionalApprovedDate = () => {
      $scope.reportDate.start = new Date(year, month - 6, 1);
      $scope.reportDate.end = new Date(year, month, 0);
    };
    const getCurrentMonthDate = () => {
      $scope.reportDate.start = new Date(year, month, 1);
      $scope.reportDate.end = new Date(year, month + 1, 0);
    };
    getCurrentMonthDate();

    const reportTypeSettings = () => {
      const assistantAccessType = [ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT];
      const disabledAssistantReports = [REPORT_TYPE.ESTIMATED_SETTLEMENT_DATES];
      jasperService.reportTypeSettings().then((response) => {
        if (response) {
          $scope.pipelineReport = response.filter((data) => {
            const isEnabled = configService.feature.enableRestrictedReport ? true : restrictedReportList.indexOf(data.reportTypeId) === -1;
            const corp = $scope.currentUserInfo.AccessType === ACCESS_TYPE.CORPORATE && data.canCorpAccess;
            const broker = $scope.currentUserInfo.AccessType !== ACCESS_TYPE.CORPORATE && data.canAdviserAccess;
            const isDisabledAssistant = assistantAccessType.indexOf($scope.currentUserInfo.AccessType) > -1 && disabledAssistantReports.indexOf(data.reportTypeId) > -1;
            return isEnabled && (corp || broker) && data.isEnabled && !isDisabledAssistant;
          });
          if ($scope.pipelineReport && $scope.pipelineReport.length > 0) {
            $scope.pipelineReportData = $scope.pipelineReport[0].reportTypeId;
            $scope.getReportUrl($scope.pipelineReportData);
          }
        }
      });
    };

    const getPipelineStatus = () => {
      const pipelineList = pipelineStatusService.getPipelineStatus();
      if (!pipelineList || !pipelineList.length) return;
      $scope.reportModel.pipelineStatusRaw = [...pipelineList];
      $scope.reportModel.pipelineStatusList = [...pipelineList];
      $scope.reportModel.pipelineStatusSelected = pipelineList[0];
    };
    getPipelineStatus();

    $scope.showAdvisersForReport = () => {
      const disabledReports = [
        REPORT_TYPE.WIP_BY_LOAN_FRANCHISE,
        REPORT_TYPE.ESTIMATED_SETTLEMENT_DATES,
        REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED,
        REPORT_TYPE.CURRENT_OPPORTUNITIES,
        REPORT_TYPE.CUSTOMER_BIRTHDAYS_LIST,
        REPORT_TYPE.COMMISSION_REPORT,
      ];
      const notBroker = $scope.currentUserInfo.AccessType !== ACCESS_TYPE.ADVISER;
      return notBroker && disabledReports.indexOf($scope.pipelineReportData) === -1;
    };

    $scope.displayMultiselectAdviser = () => {
      const allowedReportList = [REPORT_TYPE.CUSTOMER_BIRTHDAYS_LIST, REPORT_TYPE.COMMISSION_REPORT, REPORT_TYPE.CURRENT_OPPORTUNITIES];
      return allowedReportList.indexOf($scope.pipelineReportData) !== -1;
    };

    $scope.adviserLocalLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: '<i class="fa fa-refresh"></i>',
      search: 'Search',
      nothingSelected: '<div class="buttonLabel padding-left-10">All Advisers</div>',
    };

    const loadAdvisers = () => {
      optionsService.getAdvisers().then((data) => {
        if (!data || !data.length) return;
        if (data.length > 1) {
          const allAdviserObj = {
            value: -1,
            name: 'All Advisers',
            ticked: true,
          };
          $scope.reportModel.adviserRaw = [allAdviserObj, ...data];
        } else {
          data[0].ticked = true;
          $scope.reportModel.adviserRaw = [...data];
        }
        $scope.reportModel.adviserList = [...$scope.reportModel.adviserRaw];
        $scope.reportModel.adviserSelected = [...$scope.reportModel.adviserRaw[0]];
      });
    };

    $scope.resetAdviserSelect = () => {
      const data = [...$scope.reportModel.adviserRaw];
      if (data.length === 1) {
        data[0].ticked = true;
        $scope.reportModel.adviserList = data;
        $scope.reportModel.adviserSelected = data[0];
        return;
      }
      const resetData = data.map((item) => {
        item.ticked = item.value === -1 || item.value === 0;
        return item;
      });
      $scope.reportModel.adviserList = resetData;
      $scope.reportModel.adviserSelected = resetData[0];
    };

    $scope.showLender = () => {
      const lenderAvailableList = [REPORT_TYPE.WIP_BY_LOAN, REPORT_TYPE.WIP_BY_LOAN_REFERRER, REPORT_TYPE.LOAN_DETAILS_REPORT];
      return lenderAvailableList.indexOf($scope.pipelineReportData) > -1;
    };

    $scope.getReportUrl = (reportTypeId) => {
      $scope.reportEndpointUrl = '';
      if ($scope.pipelineReport && $scope.pipelineReport.length) {
        const filteredData = $scope.pipelineReport.filter(data => parseInt(data.reportTypeId, 10) === reportTypeId);
        if ($scope.currentUserInfo.AccessType === ACCESS_TYPE.ADVISER) {
          $scope.reportEndpointUrl = filteredData && filteredData.length ? filteredData[0].adviserUserUrl : '';
        } else {
          $scope.reportEndpointUrl = filteredData && filteredData.length ? filteredData[0].corpUserUrl : '';
        }
      }

      if (reportTypeId === REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED) {
        getUnconditionalApprovedDate();
      } else {
        getCurrentMonthDate();
      }

      if (reportTypeId === REPORT_TYPE.COMMISSION_REPORT) {
        $scope.nonCommissionAdvisers = [...$scope.reportModel.adviserList];
        $scope.loadAdvisersForCommission();
      } else if ($scope.nonCommissionAdvisers) {
        $scope.reportModel.adviserList = [...$scope.nonCommissionAdvisers];
      }
    };

    $scope.loadAdvisersForCommission = () => {
      commissionService.getAllBrokers()
        .then((response) => {
          if (!response.data) return;
          const hasOnlyOneAdviser = response.data.length === 2;
          const data = hasOnlyOneAdviser ? response.data.slice(1) : response.data;
          $scope.reportModel.adviserRaw = data.map((adviser, index) => {
            const { BrokerID: value, BrokerFullName: name } = adviser;
            const ticked = index === 0;
            return { ticked, value, name };
          }).sort((left, right) => {
            return left.value < right.value ? -1 : 1;
          });
          $scope.reportModel.adviserList = [...$scope.reportModel.adviserRaw];
          $scope.reportModel.adviserSelected = [...$scope.reportModel.adviserList[0]];
        });
    };

    $scope.dateRange = [
      { Name: 'Previous Month', id: 1 },
      { Name: 'Current Month', id: 2 },
      { Name: 'Previous FY', id: 4 },
      { Name: 'Current  FY', id: 5 },
    ];

    $scope.dateRangeData = 2;
    $scope.adviserListSelected = {
      selectedID: '',
    };


    $scope.lenderList = {};
    const lastFilterLender = [];

    const lenderPlace = 0;
    communicateService.lenderListGet(lenderPlace).then((response) => {
      const temp = {
        ProviderId: -1,
        ProviderName: 'All Lender',
        ticked: false,
      };
      response.data = [temp, ...response.data];
      const tmp = [];

      response.data && Object.keys(response.data).forEach((x) => {
        const value = response.data[x];
        if (lastFilterLender.indexOf('All') > -1) {
          value.ticked = value.ProviderId === -1;
        } else if (lastFilterLender.indexOf(response.data[x].ProviderName) > -1) {
          value.ticked = true;
        }
        tmp.push(value);
      });

      $scope.lenderList = tmp;
    });

    $scope.lenderLocalLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel padding-left-10'>All Lender</div>",
    };

    $scope.onSelectAllLender = () => {
      for (let i = 0; i < $scope.lenderList.length; i++) {
        if ($scope.lenderList[i].ProviderId > -1) {
          $scope.lenderList[i].ticked = false;
        } else {
          $scope.lenderList[i].ticked = true;
        }
      }
    };

    const isOtherTickedLender = (data) => {
      let bool = false;
      data.forEach((val) => {
        if (val.ProviderId > -1 && val.ticked) {
          bool = true;
        }
      });
      return bool;
    };

    $scope.lenderListSelected = [];
    $scope.onItemSelectLender = (data) => {
      if (data.ProviderId === -1) {
        for (let i = 0; i < $scope.lenderList.length; i++) {
          if ($scope.lenderList[i].ProviderId > -1) {
            $scope.lenderList[i].ticked = false;
          } else {
            $scope.lenderList[i].ticked = true;
          }
        }
      } else {
        for (let i = 0; i < $scope.lenderList.length; i++) {
          if ($scope.lenderList[i].ProviderId === -1 && !isOtherTickedLender($scope.lenderList)) {
            $scope.lenderList[i].ticked = true;
            $scope.lenderListSelected.push($scope.lenderList[i]);
          } else if ($scope.lenderList[i].ProviderId === -1) {
            $scope.lenderList[i].ticked = false;
          }
        }
      }
    };

    $scope.pipelineStatusList = {};
    const lastFilterPipelineStatus = [];

    reportService.settingsStatusGet().then((response) => {
      const temp = {
        PipelineStatusID: -1,
        PipelineStatus: 'All Pipeline',
        ticked: false,
      };
      response.data.unshift(temp);
      const tmp = [];
      response.data && Object.keys(response.data).forEach((x) => {
        const value = response.data[x];
        if (lastFilterPipelineStatus.indexOf('All') > -1) {
          value.ticked = value.PipelineStatusID === -1;
        } else if (lastFilterPipelineStatus.indexOf(response.data[x].PipelineStatus) > -1) {
          value.ticked = true;
        }
        tmp.push(value);
      });

      $scope.pipelineStatusList = tmp;
    });

    // Filter Select all
    $scope.localLangPipeline = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel padding-left-10'>All Pipeline</div>",
    };

    $scope.onSelectAllPipeline = () => {
      for (let i = 0; i < $scope.pipelineStatusList.length; i++) {
        if ($scope.pipelineStatusList[i].PipelineStatusID > -1) {
          $scope.pipelineStatusList[i].ticked = false;
        } else {
          $scope.pipelineStatusList[i].ticked = true;
        }
      }
    };

    const isOtherTickedPipeline = (data) => {
      let bool = false;
      $scope.pipelineStatusListSelected = [];
      data.forEach((val) => {
        if (val.PipelineStatusID > -1 && val.ticked) {
          bool = true;
        }
      });

      return bool;
    };

    $scope.pipelineStatusListSelected = [];
    $scope.onItemSelectPipeline = (data) => {
      if (data.PipelineStatusID === -1) {
        for (let i = 0; i < $scope.pipelineStatusList.length; i++) {
          if ($scope.pipelineStatusList[i].PipelineStatusID > -1) {
            $scope.pipelineStatusList[i].ticked = false;
          } else {
            $scope.pipelineStatusList[i].ticked = true;
          }
        }
      } else {
        for (let i = 0; i < $scope.pipelineStatusList.length; i++) {
          if ($scope.pipelineStatusList[i].PipelineStatusID === -1 && !isOtherTickedPipeline($scope.pipelineStatusList)) {
            $scope.pipelineStatusList[i].ticked = true;
            $scope.pipelineStatusListSelected.push($scope.pipelineStatusList[i]);
          } else if ($scope.pipelineStatusList[i].PipelineStatusID === -1) {
            $scope.pipelineStatusList[i].ticked = false;
          }
        }
      }
    };

    const getAdviserList = (accessType, familyId) => {
      const sameOrg = accessType === ACCESS_TYPE.CORPORATE ? 0 : 1;
      return corporateService.getAdvisersDropdown(familyId, sameOrg);
    };

    const callVisualize = () => {
      $window.visualize(
        {
          auth: {
            token: $scope.report.token,
            preAuth: true,
            tokenName: 'pp',
          },
        },

        (v) => {
          if (!$scope.isCancelled) {
            const report = v.report({
              resource: $scope.report.resource,
              params: $scope.report.params,
              container: '#report',
              events: {
                reportCompleted() {
                  if (!$scope.isCancelled) {
                    const reportTimeout = $timeout(() => {
                      $scope.reportModel.isLoaded = true;

                      $scope.exportToPDF = () => {
                        report.export({
                          outputFormat: 'pdf',
                        })
                          .done((link) => {
                            $window.open(link.href); // open new window to download report
                          })
                          .fail((err) => {
                            swal('Report Error', err.message, 'error');
                          });
                      };
                      $scope.exportToExcel = () => {
                        report.export({
                          outputFormat: 'xlsx',
                        })
                          .done((link) => {
                            $window.open(link.href); // open new window to download report
                          })
                          .fail((err) => {
                            swal('Report Error', err.message, 'error');
                          });
                      };
                      $timeout.cancel(reportTimeout);
                    });
                  }
                },
                changeTotalPages(pages) {
                  $scope.totalPages = pages || 1;
                },
              },
              error() {
                if (!$scope.isCancelled) {
                  const reportLoadedTimeout = $timeout(() => {
                    SweetAlert.swal('Error Message', 'Report failed to load. Please try to generate again.');
                    $scope.reportModel.isLoaded = true;
                    $timeout.cancel(reportLoadedTimeout);
                  });
                }
              },
            });
            $scope.cancelReport = () => {
              report.cancel = () => { };
              $scope.isCancelled = true;
              $scope.reportModel.isLoaded = true;
            };

            $scope.pageChange = (currentPage) => {
              $scope.reportModel.isLoaded = false;
              report
                .pages(currentPage)
                .run()
                .done(() => {
                  $scope.reportModel.isLoaded = true;
                })
                .fail((err) => {
                  swal('Error Message', err);
                });
            };
          }
        },

        (err) => {
          if (!$scope.isCancelled) {
            SweetAlert.swal('Authentication Error', `Server response: ${err.message}`);
          }
        });
    };

    const callIsLoaded = () => {
      if (typeof visualize !== 'undefined' && !$scope.isCancelled) {
        callVisualize();
      } else if (!$scope.isCancelled) {
        $timeout(() => {
          callIsLoaded();
        }, 500);
      }
    };
    const loadReport = () => {
      $scope.isCancelled = false;
      callIsLoaded();
    };
    $scope.generateReport = () => {
      $scope.reportModel.isLoaded = false;
      $scope.exportToPDF = null;
      $scope.exportToExcel = null;

      const countryQ = $scope.currentUserInfo.CountryId || 2;

      let startDate = [''];
      let endDate = [''];

      switch ($scope.dateRangeData) {
      case 1:
        startDate = ['MONTH-1'];
        endDate = ['MONTH-1'];
        break;
      case 2:
        startDate = ['MONTH'];
        endDate = ['MONTH'];
        break;
      case 3:
        startDate = ['MONTH+1'];
        endDate = ['MONTH+1'];
        break;
      case 4:
        if (typeof $scope.reportModel.fiscalYear.FiscalYearStart !== 'undefined' && typeof $scope.reportModel.fiscalYear.FiscalYearEnd !== 'undefined') {
          const yearStart = new Date($scope.reportModel.fiscalYear.FiscalYearStart);
          yearStart.setFullYear(yearStart.getFullYear() - 1);

          const yearEnd = new Date($scope.reportModel.fiscalYear.FiscalYearEnd);
          yearEnd.setFullYear(yearEnd.getFullYear() - 1);
          startDate = [$filter('date')(yearStart, 'yyyy-MM-dd').toString()];
          endDate = [$filter('date')(yearEnd, 'yyyy-MM-dd').toString()];
        } else {
          const currentYear = new Date().getFullYear();
          startDate = [`${currentYear - 1}-07-01`];
          endDate = [`${currentYear}-06-30`];
        }
        break;
      case 5:
        if (typeof $scope.reportModel.fiscalYear.FiscalYearStart !== 'undefined' && typeof $scope.reportModel.fiscalYear.FiscalYearEnd !== 'undefined') {
          startDate = [$filter('date')($scope.reportModel.fiscalYear.FiscalYearStart, 'yyyy-MM-dd').toString()];
          endDate = [$filter('date')($scope.reportModel.fiscalYear.FiscalYearEnd, 'yyyy-MM-dd').toString()];
        } else {
          const currentYear = new Date().getFullYear();
          startDate = [`${currentYear}-07-01`];
          endDate = [`${currentYear + 1}-06-30`];
        }
        break;
      default:
      }

      let statusList = [];
      let lenderName = [];
      let businessUnit = [];

      angular.forEach($scope.lenderList, (item) => {
        if (item.ticked) {
          if (item.ProviderId === -1) {
            const temporaryData = angular.copy($scope.lenderList);
            lenderName = temporaryData.splice(0, 1);
          } else {
            lenderName.push(item.ProviderId);
          }
        }
      });

      angular.forEach($scope.pipelineStatusList, (item) => {
        if (item.ticked) {
          if (item.PipelineStatusID === -1) {
            const temporaryData = angular.copy($scope.pipelineStatusList);
            statusList = temporaryData.splice(0, 1);
          } else {
            statusList.push(item.PipelineStatusID);
          }
        }
      });

      businessUnit = _.map($scope.reportModel.businessUnitSelected, (data) => {
        return data.BusinessUnitID;
      });
      if (businessUnit.length > 0 && businessUnit.indexOf(-1) > -1) {
        businessUnit = [];
      }
      const reportingState = $scope.reportModel.reportingStateSelected
        .filter(data => data.ReportingStateId !== -1)
        .map(state => state.ReportingStateId);

      const selectedMonth = $scope.reportModel.monthSelected ? $scope.reportModel.monthSelected : '~NOTHING~';

      const pipelineStatus = $scope.reportModel.pipelineStatusSelected
        .reduce((accum, status) => {
          if (status.statusId !== -1) return [...accum, status.statusId];
          return accum;
        }, []);
      const adviserIdList = $scope.reportModel.adviserSelected && $scope.reportModel.adviserSelected
        .reduce((accum, adviser) => {
          if (adviser.value !== -1) return [...accum, adviser.value];
          return accum;
        }, []);
      const adviser = adviserIdList && adviserIdList.length ? adviserIdList : ['~NOTHING~'];
      const currentOpportunitiesCommonParam = {
        PipelineStatus: pipelineStatus,
        IsAdviserOpportunityCreator: [$scope.reportModel.isAdviserOpportunityCreator],
        IsOpportunityAllocatedToAdviser: [$scope.reportModel.isOpportunityAllocatedToAdviser],
        Adviser_ID: adviser,
      };
      const birthdayCommonParam = { Month_Of_Date: [selectedMonth], ShowOnlyClientsIncludedInMarketing: [$scope.reportModel.isIncludedInMarketing], Adviser_ID: adviser };
      $scope.paramsData = {};
      if ($scope.reportDate.start) {
        startDate = [$filter('date')($scope.reportDate.start, 'yyyy-MM-dd')];
      }
      if ($scope.reportDate.end) {
        endDate = [$filter('date')($scope.reportDate.end, 'yyyy-MM-dd')];
      }
      if ($scope.currentUserInfo.AccessType === ACCESS_TYPE.ADVISER) {
        switch ($scope.pipelineReportData) {
        case REPORT_TYPE.WIP_BY_LOAN:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, LenderName_InputControl: lenderName };
          break;
        case REPORT_TYPE.AVG_LOAN_SIZE:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, Busines_Unit: [] };
          break;
        case REPORT_TYPE.WIP_BY_LOAN_REFERRER:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, LenderName_InputControl: lenderName };
          break;
        case REPORT_TYPE.WIP_BY_LOAN_FRANCHISE:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList };
          break;
        case REPORT_TYPE.LEAD_STATUS_REPORT:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate };
          break;
        case REPORT_TYPE.COMMISSION_REPORT:
          $scope.paramsData = { Relative_Start_Date: startDate, Relative_End_Date: endDate, Adviser_ID: $scope.getCommissionBrokerNo() };
          break;
        case REPORT_TYPE.LOAN_DETAILS_REPORT:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, LenderName_InputControl: lenderName };
          break;
        case REPORT_TYPE.ESTIMATED_SETTLEMENT_DATES:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate };
          break;
        case REPORT_TYPE.CUSTOMER_BIRTHDAYS_LIST:
          $scope.paramsData = birthdayCommonParam;
          break;
        case REPORT_TYPE.CURRENT_OPPORTUNITIES:
          $scope.paramsData = currentOpportunitiesCommonParam;
          break;
        default:
          break;
        }
      } else {
        switch ($scope.pipelineReportData) {
        case REPORT_TYPE.WIP_BY_LOAN:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, LenderName_InputControl: lenderName, Broker_No: [$scope.adviserListSelected.selectedID] };
          break;
        case REPORT_TYPE.AVG_LOAN_SIZE:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, Busines_Unit: businessUnit, Broker_No: [$scope.adviserListSelected.selectedID] };
          break;
        case REPORT_TYPE.WIP_BY_LOAN_REFERRER:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, LenderName_InputControl: lenderName, Broker_No: [$scope.adviserListSelected.selectedID] };
          break;
        case REPORT_TYPE.WIP_BY_LOAN_FRANCHISE:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList };
          break;
        case REPORT_TYPE.LEAD_STATUS_REPORT:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Broker_No: [$scope.adviserListSelected.selectedID] };
          break;
        case REPORT_TYPE.COMMISSION_REPORT:
          $scope.paramsData = { Relative_Start_Date: startDate, Relative_End_Date: endDate, Adviser_ID: $scope.getCommissionBrokerNo() };
          break;
        case REPORT_TYPE.LOAN_DETAILS_REPORT:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, Status_List: statusList, LenderName_InputControl: lenderName, Broker_No: [$scope.adviserListSelected.selectedID] };
          break;
        case REPORT_TYPE.ESTIMATED_SETTLEMENT_DATES:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate };
          break;
        case REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED:
          $scope.paramsData = { Country_Q: [countryQ], Relative_Start_Date: startDate, Relative_End_Date: endDate, BusinessUnit: businessUnit, State_List: reportingState };
          break;
        case REPORT_TYPE.CUSTOMER_BIRTHDAYS_LIST:
          $scope.paramsData = birthdayCommonParam;
          break;
        case REPORT_TYPE.CURRENT_OPPORTUNITIES:
          $scope.paramsData = currentOpportunitiesCommonParam;
          break;
        default:
          break;
        }
      }

      const userToken = test.isTestEnvironment ? test.data.token : $scope.currentUserToken.token;
      $scope.currentUserToken.token = encodeURIComponent(userToken);
      $scope.isLoaded = true;
      $scope.report = {
        resource: $scope.reportEndpointUrl,
        params: $scope.paramsData,
        token: $scope.currentUserToken.token,
      };

      $scope.reportModel.isLoaded = false;
      loadReport();
    };

    $scope.currentUserInfo = '';
    $scope.currentUserToken = '';
    $scope.reportEndpointUrl = '';

    reportService.getUserInfo().then((response) => {
      $scope.currentUserInfo = response.data;
      loadAdvisers();
      reportTypeSettings();

      getAdviserList($scope.currentUserInfo.AccessType, $scope.currentUserInfo.FamilyId)
        .then((adviser) => {
          $scope.adviserList = adviser && adviser.data && adviser.data.length ? adviser.data.filter(data => data.FullName) : [];
          $scope.adviserListSelected.selectedID = $scope.adviserList && $scope.adviserList.length ? $scope.adviserList[0].FamilyId : $scope.currentUserInfo.FamilyId || 0;

          reportService.token().then((token) => {
            $scope.currentUserToken = token.data;
            $scope.currentUserToken.status = test.isTestEnvironment ? 'active' : $scope.currentUserToken.status;
            if ($scope.currentUserToken.status === 'active') {
              $scope.currentUserToken.url = test.isTestEnvironment ? test.data.url : $scope.currentUserToken.url;
              const visualizeUrl = `${$scope.currentUserToken.url}/jasperserver-pro/client/visualize.js`;
              $ocLazyLoad.load(visualizeUrl).then($scope.generateReport());
            } else {
              $scope.reportModel.isLoaded = true;
              $scope.isLoaded = true;
            }
          });
        });
    });

    $scope.getCommissionBrokerNo = () => {
      const [selectedID] = [...$scope.reportModel.adviserSelected];
      const isAllBrokers = !selectedID || !selectedID.value;
      const selectedAdvisers = isAllBrokers ? $scope.reportModel.adviserList : $scope.reportModel.adviserSelected;
      const selectedAdviserIds = selectedAdvisers.reduce((accum, adviser) => {
        return adviser.value ? [...accum, adviser.value] : accum;
      }, []);
      return selectedAdviserIds;
    };

    $scope.generateReportClicked = () => {
      $scope.currentPage = 1;
      reportService.token().then((response) => {
        $scope.currentUserToken = response.data;
        $scope.currentUserToken.status = test.isTestEnvironment ? 'active' : $scope.currentUserToken.status;
        if ($scope.currentUserToken.status === 'active') {
          $scope.generateReport();
        } else {
          $scope.reportModel.isLoaded = true;
          $scope.isLoaded = true;
        }
      });
    };

    $scope.$watch(
      () => {
        $scope.sidebarheight = $('#sidebar-1').height();
        return $scope.sidebarheight;
      },
      (value) => {
        $scope.sidebarheight = value + 135;
      },
      true
    );

    $scope.isCancelled = false;
    $scope.cancelReport = () => {
      $scope.isCancelled = true;
      $scope.reportModel.isLoaded = true;
    };

    const getBusinessUnit = () => {
      corporateService.businessUnitGet().then((response) => {
        let data = response.data;
        _.map(data, (row) => {
          row.ticked = false;
        });
        const all = {
          BusinessUnitID: -1,
          BusinessUnitName: 'All Business Unit',
          ticked: true,
        };
        data = [all, ...data];
        $scope.reportModel.businessUnitRaw = angular.copy(data);
        $scope.reportModel.businessUnitList = data;
        $scope.reportModel.businessUnitSelected = data[0];
      });
    };
    getBusinessUnit();

    $scope.resetBusinessUnit = () => {
      const data = angular.copy($scope.reportModel.businessUnitRaw);
      $scope.reportModel.businessUnitList = data;
      $scope.reportModel.businessUnitSelected = data[0];
    };

    $scope.onItemSelectCorp = (data, fieldName, source, selection) => {
      let selectionData = selection;
      if (!source || !source.length) return;
      source.map((row) => {
        if (data[fieldName] === -1 || data[fieldName] === 0) {
          row.ticked = row[fieldName] === -1 || row[fieldName] === 0;
          if (row.ticked) {
            selectionData = row;
          }
        } else {
          const isDefaultSelection = row[fieldName] === -1 || row[fieldName] === 0;
          if (isDefaultSelection) {
            row.ticked = false;
          }
          if (selectionData.length === 0 && isDefaultSelection) {
            row.ticked = true;
          }
        }
        return row;
      });
    };

    $scope.unitLocalLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel padding-left-10'>All Business Unit</div>",
    };

    $scope.stateLocalLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel padding-left-10'>All State</div>",
    };
    $scope.resetReportingState = () => {
      const data = [...$scope.reportModel.reportingStateRaw];
      $scope.reportModel.reportingState = data;
      $scope.reportModel.reportingStateSelected = data[0];
    };

    $scope.showState = () => {
      const allowedList = [REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED];
      return allowedList.indexOf($scope.pipelineReportData) !== -1;
    };

    $scope.showBusinessUnit = () => {
      const allowedList = [REPORT_TYPE.AVG_LOAN_SIZE, REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED];
      const corpUser = $scope.currentUserInfo.AccessType === ACCESS_TYPE.CORPORATE;
      return corpUser && allowedList.indexOf($scope.pipelineReportData) !== -1;
    };
    $scope.displayDateRange = () => {
      const restrictedTypeList = [REPORT_TYPE.CUSTOMER_BIRTHDAYS_LIST, REPORT_TYPE.CURRENT_OPPORTUNITIES];
      return restrictedTypeList.indexOf($scope.pipelineReportData) === -1;
    };
    $scope.displayPipelineStatus = () => {
      const showForTypeList = [REPORT_TYPE.CURRENT_OPPORTUNITIES];
      return showForTypeList.indexOf($scope.pipelineReportData) !== -1;
    };
    $scope.resetPipelineStatus = () => {
      const data = [...$scope.reportModel.pipelineStatusRaw];
      const resetData = data.map((item) => {
        item.ticked = item.statusId === -1;
        return item;
      });
      $scope.reportModel.pipelineStatusList = resetData;
      $scope.reportModel.pipelineStatusSelected = resetData[0];
    };
    $scope.statusLocalLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: '<i class="fa fa-refresh"></i>',
      search: 'Search',
      nothingSelected: '<div class="buttonLabel padding-left-10">All Pipeline Status</div>',
    };

    $scope.showMonth = () => {
      return $scope.pipelineReportData === REPORT_TYPE.CUSTOMER_BIRTHDAYS_LIST;
    };
    $scope.displayPagination = () => {
      return !$scope.isCancelled && $scope.totalPages > 1 && $scope.reportModel.isLoaded;
    };
    $scope.showCurrentOpportunityCheckbox = () => {
      return $scope.pipelineReportData === REPORT_TYPE.CURRENT_OPPORTUNITIES;
    };
  });
