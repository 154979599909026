import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import { onAcceptListener } from 'Common/utilities/templatesDragListener';

function EmailTemplatesCtrl($scope,
  $rootScope,
  $http,
  $filter,
  $timeout,
  corporateService,
  contactService,
  regionalizationService,
  queryService,
  $state,
  $stateParams,
  mapService,
  $uibModal,
  toaster,
  broadcastDataService,
  generalService,
  emailTemplatesSharedData,
  $window,
  commonFnService,
  templateService,
  uiService
) {
  'ngInject';

  $scope.emailTemplateListing = [];
  $scope.templateModel = {};
  $scope.emailTemplateList = [];
  $scope.emailTemplateFolderList = [];
  $scope.isAddCategory = false;
  $scope.isTemplateEditor = false;
  $scope.email = {};
  $scope.email = broadcastDataService;
  $scope.newEmailTemplate = {
    UserTemplateID: 0,
    UserTemplateName: 'New Email Template',
    UserTemplateContent: '',
    UserTemplateEmailSubject: '',
  };
  $scope.SEND_METHOD_TYPES = SEND_METHOD_TYPES;
  $scope.isCorporateUser = uiService.isCorporateUser;

  const populateAttachment = (fileIds) => {
    if (fileIds) {
      const attachedIds = fileIds.split(',');
      attachedIds.forEach((id) => {
        if (id) {
          generalService.documentGet(id).then((response) => {
            if (response.data) {
              response.data.DocumentId = id;
              $scope.templateModel.attachments = [...$scope.templateModel.attachments, response.data];
            }
          });
        }
      });
    }
  };
  const getEmailTemplates = () => {
    corporateService.getEmailTemplates(0).then((response) => {
      $scope.blankCategory = _.remove(response.data, category => templateService.isBlankFolderOrTemplate(category))[0];
      $scope.blankTemplate = templateService.getBlankTemplateIn($scope.blankCategory);
      $scope.emailTemplateListing = templateService.filterTemplatesByUserType(response.data);
    });
    $scope.email.emailSendingPage.isTriggered = true;
  };


  const saveTemplateFolder = () => {
    const data = {
      FolderTemplateID: 0,
      UserTemplateName: $scope.emailTemplateFolderList.newFolder || `NewTemplate${$scope.emailTemplateListing.length}`,
      SubBrokerBrandingID: 0,
    };
    corporateService.setEmailTemplateFolder(data).then((response) => {
      if (parseInt(response.data, 10) === -1) {
        swal('Oops', 'Template category name already in use! Try to use other name.', 'error');
      } else {
        toaster.pop('success', 'Template Folder', `${$scope.emailTemplateFolderList.newFolder || `NewTemplate${$scope.emailTemplateListing.length}`} has been added.`);
        getEmailTemplates();
        $scope.emailTemplateFolderList.newFolder = '';
        $scope.isAddCategory = false;
        $scope.email.emailTemplateView.isTriggered = true;
        $scope.onChangesMade();
      }
    });
  };
  const updateTemplateFolder = (templateId) => {
    const data = {
      FolderTemplateID: templateId,
      UserTemplateName: $scope.emailTemplateFolderList[templateId].folderName || `UpdatedTemplate${$scope.emailTemplateListing.length}`,
      SubBrokerBrandingID: 0,
    };
    corporateService.setEmailTemplateFolder(data).then(() => {
      toaster.pop('success', 'Template Folder', `${$scope.emailTemplateFolderList[templateId].folderName || `UpdatedTemplate${$scope.emailTemplateListing.length}`} has been updated.`);
      getEmailTemplates();
      $scope.isAddCategory = false;
      $scope.email.emailTemplateView.isTriggered = true;
      $scope.onChangesMade();
    });
  };

  const toggleTemplateEditor = () => {
    $scope.isTemplateEditor = !$scope.isTemplateEditor;

    if (emailTemplatesSharedData.getNewTemplateToSave() && !$scope.selectedTemplate) {
      $scope.email.subject = emailTemplatesSharedData.getNewTemplateToSave().subject;
      $scope.email.content = emailTemplatesSharedData.getNewTemplateToSave().content;
      emailTemplatesSharedData.setNewTemplateToSave(null, null);
    }
  };

  const saveEmailTemplate = () => {
    let attachmentIds = '';
    if ($scope.templateModel.attachments) {
      attachmentIds = $scope.templateModel.attachments.map((row) => {
        return row.DocumentId;
      }).join(',');
    }
    const data = {
      AttachmentDocumentIds: attachmentIds,
      UserTemplateID: $scope.currentTemplateSelection.templateId || 0,
      FolderTemplateID: $scope.currentTemplateSelection.folderId || 0,
      UserTemplateName: $scope.email.title || 'New Email Template',
      UserTemplateEmailSubject: $scope.email.subject || '',
      UserTemplateContent: $scope.email.content || '',
      OrderBy: 1,
      SubBrokerBrandingID: 0,
      IsExclusiveForCorpUser: $scope.selectedTemplate && $scope.selectedTemplate.IsExclusiveForCorpUser,
      IsSharedWithAdviser: $scope.selectedTemplate ? $scope.selectedTemplate.IsSharedWithAdviser : templateService.isBlankFolderOrTemplate($scope.selectedTemplate),
      IsActive: true,
      IsSystemTemplate: $scope.selectedTemplate && $scope.selectedTemplate.IsSystemTemplate,
    };
    corporateService.setEmailTemplates(data).then((response) => {
      if (parseInt(response.data, 10) === -1) {
        swal('Oops', 'Template Name already in use! Try to use other name.', 'error');
      } else if (parseInt(response.data, 10) > 0) {
        $scope.onChangesMade();
        toaster.pop('success', 'New Email Template', `${$scope.email.title || 'New Email Template'} has been saved.`);
        getEmailTemplates();
        $scope.email.emailTemplateView.isTriggered = true;
        if (parseInt($scope.currentTemplateSelection.templateId, 10) === 0) {
          $timeout(() => {
            $scope.cancelTemplateEditor();
          });
        }
      } else {
        toaster.pop('error', 'New Email Template', 'There is an error in saving template.Please try again.');
      }
    });
  };
  $scope.currentTemplateId = 0;
  $scope.editTemplateFolder = (templateId) => {
    $scope.emailTemplateFolderList[templateId].isEdit = true;
    $scope.currentTemplateId = templateId;
  };

  $scope.saveTemplateFolder = () => {
    saveTemplateFolder();
  };
  $scope.updateTemplateFolder = () => {
    updateTemplateFolder($scope.currentTemplateId);
    $scope.emailTemplateFolderList[$scope.currentTemplateId].isEdit = false;
    $scope.currentTemplateId = 0;
  };

  $scope.toggleAddCategory = () => {
    $scope.isAddCategory = !$scope.isAddCategory;
  };

  $scope.noProgation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  $scope.currentTemplateSelection = {};
  $scope.setTemplateEditor = (folder, template) => {
    if (!folder || !template) return;

    const folderId = folder.UserTemplateID;
    const folderName = folder.UserTemplateName;
    const templateId = template.UserTemplateID;
    const templateName = template.UserTemplateName;
    const content = template.UserTemplateContent;
    const subject = template.UserTemplateEmailSubject;
    $scope.templateModel.attachments = [];
    populateAttachment(template.AttachmentDocumentIds);
    $scope.currentTemplateSelection = {
      folderId,
      folderName,
      templateId,
      templateName,
    };
    $scope.email.subject = subject;
    $scope.email.title = templateName;
    $scope.email.content = content;
    $scope.selectedTemplate = template;

    toggleTemplateEditor();
  };
  $scope.cancelTemplateEditor = () => {
    toggleTemplateEditor();
    $scope.currentTemplateSelection = {};
  };
  $scope.saveEmailTemplate = () => {
    saveEmailTemplate();
  };

  $scope.deleteTemplate = (data, isFolder) => {
    let title = '';
    if (isFolder) {
      title = 'Are you sure you want to delete this Folder?';
    } else {
      title = 'Are you sure you want to delete this Template?';
    }
    swal({
      title,
      text: 'If you want to recover just contact the administrator!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, Delete it!',
      closeOnConfirm: true,
    },
    () => {
      data.IsActive = false;
      data.FolderTemplateID = data.UserTemplateFolderId;
      data.IsFolder = isFolder;
      corporateService.setEmailTemplates(data).then((response) => {
        if (response.data > 0) {
          toaster.pop('success', 'Delete Template', `${data.UserTemplateName} has been deleted.`);
          getEmailTemplates();
          $scope.email.emailTemplateView.isTriggered = true;
          $scope.onChangesMade();
        } else {
          toaster.pop('error', 'Delete Template', 'There is an error in deleting template.Please try again.');
        }
      });
    });
  };

  $scope.mergeFieldsList = $scope.contactList;

  $scope.emailMergeFieldList = [];
  const getMergeFields = () => {
    corporateService.getMergeFields().then((response) => {
      $scope.emailMergeFieldList = response.data;
      $scope.mergeFields = response.data[0];
      $scope.onMergeFieldSelected();
    });
  };

  $scope.onMergeFieldSelected = () => {
    if ($scope.mergeFields) {
      $scope.mergeValues = $scope.mergeFields.MergeFields;
    }
  };

  $scope.isSubject = false;
  $scope.insertValue = (value) => {
    if ($scope.isSubject) {
      const txt = $('#subject');
      const caretPos = txt[0].selectionStart;
      const textAreaTxt = txt.val();
      const txtToAdd = `{${value}}`;
      $scope.email.subject = textAreaTxt.substring(0, caretPos) + txtToAdd + textAreaTxt.substring(caretPos);
    } else {
      $window.CKEDITOR.instances.emailContent.insertText(`{${value}}`);
    }
  };

  $window.CKEDITOR.on('instanceReady', (evt) => {
    const editor = evt.editor;
    editor.on('focus', () => {
      $scope.isSubject = false;
    });
  });


  $scope.setSubjectFocus = (bool) => {
    $scope.isSubject = bool;
  };

  $scope.disableSaveButton = () => {
    const title = $scope.email.title ? $scope.email.title.trim() : '';
    const subject = $scope.email.subject ? $scope.email.subject.trim() : '';

    return title === '' || subject === '';
  };

  $scope.disableSaveCategoryButton = () => {
    const title = $scope.emailTemplateFolderList.newFolder ? $scope.emailTemplateFolderList.newFolder.trim() : '';
    return title === '';
  };
  // ============================================
  const saveChangedOrder = (data) => {
    for (let i = 0; i < data.length; i++) {
      data[i].OrderBy = i + 1;
    }
    $timeout(() => {
      corporateService.setTemplateSorting(data).then(() => {
        $scope.email.emailTemplateView.isTriggered = true;
      });
    });
  };

  $scope.dragControlListeners = {
    accept: onAcceptListener,
    itemMoved(event) {
      const parentId = parseInt(event.dest.sortableScope.$parent.$parent.heading, 10) || 0;
      const index = event.dest.index;
      const modelValue = event.dest.sortableScope.modelValue[index];
      modelValue.FolderTemplateID = parentId;
      corporateService.setEmailTemplates(modelValue).then((response) => {
        if (response.data > 0) {
          $scope.email.emailSendingPage.isTriggered = true;
          $scope.email.emailTemplateView.isTriggered = true;
          toaster.pop('success', 'Successfully Moved', 'Email template succefully moved to other category.');
          $scope.onChangesMade();
        } else {
          toaster.pop('error', 'Failed to Move', 'There is an error in moving template.Please try again.');
        }
      });
    },
    orderChanged(event) {
      saveChangedOrder(event.dest.sortableScope.modelValue);
    },
    containerPositioning: 'relative',
    allowDuplicates: true,
  };
  $scope.dragControlListenersFolder = {
    accept(sourceItemHandleScope) {
      return sourceItemHandleScope.itemScope.modelValue.IsTemplateFolder;
    },
    orderChanged(event) {
      saveChangedOrder(event.dest.sortableScope.modelValue);
    },
    containerPositioning: 'relative',
    allowDuplicates: true,
  };

  $scope.options = {
    language: 'en',
    allowedContent: true,
    uiColor: '#DFD6E4',
    height: 300,
    resize_enabled: false,
    removePlugins: 'elementspath',
    toolbar: [
      { items: ['BGColor'] }, { items: ['JustifyLeft'] }, { items: ['BulletedList', '-'] }, { items: ['Link'] },
      { items: ['Image'] }, { items: ['Table'] }, { items: ['Paste', '-'] }, { items: ['Smiley'] }, { items: ['-'] },
      '/',
      { items: ['Format'] }, { items: ['Font'] }, { items: ['FontSize', '-'] }, { items: ['Bold'] }, { items: ['Italic'] }, { items: ['Underline'] },
      { items: ['Subscript'] }, { items: ['Superscript'] },
    ],

  };

  $scope.attachmentFiles = [];
  $scope.tmpAttachmentFiles = [];
  $scope.templateModel.attachments = [];
  $scope.$on('ATTACHED_FILE_TEMPLATE', ($event, arr) => {
    $scope.attachmentFiles = arr;
    $scope.attachmentFiles && Object.keys($scope.attachmentFiles).forEach((x) => {
      const docuId = $scope.attachmentFiles[x].DocumentId || 0;
      const filtered = _.find($scope.templateModel.attachments, { DocumentId: docuId });
      if (!(filtered && filtered.length)) {
        $scope.templateModel.attachments = [...$scope.templateModel.attachments, $scope.attachmentFiles[x]];
      }
    });
  });
  $scope.defaultAttachment = () => {
    commonFnService.attachFileMgmt($scope.familyId, 'ATTACHED_FILE_TEMPLATE');
  };

  $scope.isShowDefaultTemplateSettings = () => {
    return emailTemplatesSharedData.isShowDefaultTemplateSettings();
  };

  $scope.removeTemplateAttachment = (item) => {
    _.remove($scope.templateModel.attachments, item);
  };

  $scope.onChangesMade = () => {
    if (this.onTemplatesUpdated) {
      this.onTemplatesUpdated();
    }
  };

  const init = () => {
    getEmailTemplates();
    getMergeFields();
  };
  init();

  this.$onDestroy = () => {
    emailTemplatesSharedData.setShowDefaultTemplateSettings(false);
  };
}

export default EmailTemplatesCtrl;
