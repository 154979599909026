export default class WidgetFieldCtrl {
  $onInit() {
    this.isVisible = this.shouldDisplayField();
    this.initVisibilityChecker();
  }

  initVisibilityChecker() {
    if (this.field.visibilityCondition) {
      const { visibilityCondition } = this.field;
      this.fieldDepencencyPrevValue = this.model[visibilityCondition.fieldDependency];
    }
  }

  $doCheck() {
    const { visibilityCondition } = this.field;
    if (!visibilityCondition) return;

    const fieldDepencencyIsChanged = this.fieldDepencencyPrevValue !== this.model[visibilityCondition.fieldDependency];
    if (!fieldDepencencyIsChanged) return;

    this.isVisible = this.shouldDisplayField();
    this.initVisibilityChecker();
  }

  shouldDisplayField() {
    if (!this.field.visibilityCondition) return true;

    const { visibilityCondition } = this.field;
    const conditionArg = this.model[visibilityCondition.fieldDependency];
    if (visibilityCondition.condition === 'equal') {
      return conditionArg === this.field.visibilityCondition.fieldDependencyValue;
    }
    return conditionArg !== this.field.visibilityCondition.fieldDependencyValue;
  }
}
