export function haversineInKM(lat1, lng1, lat2, lng2) {
  const radlat1 = Math.PI * lat1 / 180;
  const radlat2 = Math.PI * lat2 / 180;
  // const radlon1 = Math.PI * lng1 / 180;
  // const radlon2 = Math.PI * lng2 / 180;
  const theta = lng1 - lng2;
  const radtheta = Math.PI * theta / 180;
  let dist = (Math.sin(radlat1) * Math.sin(radlat2)) + (Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta));
  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;

  // Get in in kilometers
  dist *= 1.609344;

  return dist;
}
export function typelessEqual(a, b) {
  return a == b; // eslint-disable-line
}

export function getWholeNumber(n) {
  return Math.floor(n);
}

export function getRemainder(n) {
  const decimalValue = (n % 1).toFixed(2).substring(1);
  return decimalValue;
}

export function bitwiseXOR(a, b) {
  // algebraic expression (a * !b) + (!a * b)
  return (a && !b) || (!a && b);
}

export function roundOffToNearestHundreds(num) {
  return Math.ceil(num / 100) * 100;
}

export function getParsed(amount) {
  const replaced = amount.toString().replace(/,/g, '');
  const parsed = parseFloat(replaced);
  return parsed;
}
