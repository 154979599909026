import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import { isEmploymentIncome } from 'Common/utilities/income';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';

angular.module('app').controller('LoanAppFinancialIncomeCtrl',
  function LoanAppFinancialIncomeCtrl($rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    SweetAlert,
    optionsService,
    financialsSharedService,
    loanAppSharedData,
    insuranceFinancialsSharedService,
    incomeSharedService) {
    /* NOTICE: this module is also being used by Insurance Application - rolandbarro */
    $scope.callerApp = '';

    if ($stateParams.insAppId) {
      $scope.callerApp = 'insurance';
    }

    $scope.financialsSharedService = ($scope.callerApp !== 'insurance' ? financialsSharedService : insuranceFinancialsSharedService);
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.loanAppId = $stateParams.loanAppId;
    $scope.familyId = $stateParams.familyId;
    $scope.incomeLoading = false;
    $scope.tooltipDisplay = false;
    $scope.IncomeTotalAmount = 0;

    //* ************************************/
    //  owners multi Select                /
    //* ************************************/
    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: '', // default-label is deprecated and replaced with this.
      // nothingSelected: "<div class='buttonLabel'>All Clients</div>"         //default-label is deprecated and replaced with this.
    };
    $scope.ownersList = [];
    $scope.ownersListSelected = [];

    // from IncomeClientGet -
    const incomeClientProcessor = (data, borrowers) => {
      $scope.ownersListSelected = (borrowers || []);
      const tmp = [];
      const temp = {
        Percentage: 0,
        BorrowerID: 0,
        FirstName: 'All',
        LastName: 'Clients',
        PreferedName: 'Joint',
        ticked: true,
        disabled: true,
      };
      if ($scope.ownersListSelected.length < 1 || ($scope.ownersListSelected.length === data.length)) {
        data && Object.keys(data).forEach((x) => {
          const value = data[x];
          value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
          tmp.push(value);
        });
        if (tmp.length === 1) {
          tmp[0].ticked = true;
        } else {
          tmp.unshift(temp);
        }
        $scope.ownersList = tmp;
      } else {
        temp.ticked = false;
        // Set default selected from edit
        // @TODO : Edit should match a borrowers array
        if (_.size(borrowers) <= _.size(data)) {
          data && Object.keys(data).forEach((x) => {
            const value = data[x];
            value.ticked = (!!_.find(borrowers, (o) => { return o.BorrowerID === value.BorrowerID; }));
            value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
            tmp.push(value);
          });
        } else {
          data && Object.keys(data).forEach((x) => {
            const value = data[x];
            value.ticked = value.BorrowerID === -1;
            value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
            tmp.push(value);
          });
        }
        if (data.length > 1) {
          tmp.unshift(temp);
        }

        $scope.ownersList = tmp;
      }
    };

    $scope.changeIncome = () => {
      if (isEmploymentIncome($scope.IncomeSet.TypeId)) {
        $scope.IncomeSet.FrequencyID = parseInt($scope.IncomeSet.FrequencyID, 10);
        $scope.newIncomeModal($scope.IncomeSet.EmploymentId ? 'edit' : 'new', true);
      } else if (parseInt($scope.IncomeSet.TypeId, 10) === DEFAULT_INCOME_TYPES.RENTAL) {
        $scope.displayIncomeForm = false;
        $scope.IncomeSet.TypeId = $scope.prevValueHolder;
        toaster.pop('error', 'Error', 'Please add rental income to the Existing Real Estate Asset');
      }
    };

    $scope.IncomeClientGet = (incomeId, familyId, borrowers) => {
      if (!familyId) return;

      if ($scope.callerApp !== 'insurance') {
        loanScenarioService.IncomeClientGet(familyId, $scope.loanAppId, null).then((respond) => {
          incomeClientProcessor(respond.data, borrowers);
        });
      } else {
        contactService.IncomeClientGet(familyId).then((respond) => {
          incomeClientProcessor(respond.data, borrowers);
        });
      }
    };

    $scope._incomeInit = () => {
      $scope.prevValueHolder = '0';
      $scope.IncomeSet = {};
      $scope.frequencyTypes = [];
      optionsService.FinancialFrequency().then((response) => {
        response.data = _.map(response.data, (o) => {
          o.Desc = o.Name;
          o.Value = parseInt(o.Value, 10);

          return o;
        });

        $scope.frequencyTypes = response.data;
      });

      loanScenarioService.IncomeTypeGet().then((response) => {
        $scope.incomeTypes = response.data;

        // Remove Addback
        _.remove($scope.incomeTypes, (o) => {
          return o.GroupName === 'Addback';
        });

        angular.extend($scope.IncomeSet, {
          Type: response.data[0].Value,
          Frequency: 3,
        });
      });

      $scope.financialsSharedService.IncomeInfoGet();
      $scope.IncomeClientGet(undefined, $scope.financialsSharedService.selectedFamily.FamilyId);
    };
    // first caller
    $scope._incomeInit(true);
    $scope.$watch('loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS', (nv) => {
      if (nv && _.size(nv) > 0 && nv.RefreshIncome) {
        $scope._incomeInit(true);
        nv.RefreshIncome = false;
      }
    });

    $scope.IncomeInfoDelete = (incomeId) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your income list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          let serviceInOperation = loanScenarioService;
          if ($scope.callerApp === 'insurance') {
            serviceInOperation = contactService;
          }
          serviceInOperation.IncomeInfoDelete($scope.loanAppId, incomeId).then(() => {
            $timeout(() => {
              $scope._incomeInit(false);
              SweetAlert.swal({
                title: 'Success',
                text: 'Income has been been successfully deleted.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            }, 100);
          });
        }
      });
    };

    $scope.getNewIncomeModel = () => {
      return {
        IsEvidenceOfTenancy: true,
        Id: 0,
        LoanScenarioId: $scope.loanAppId,
        FamilyId: $scope.familyId,
        FinancialId: 0,
        TypeId: '',
        Description: '',
        Amount: null,
        CurrencyCode: 'USD',
        Borrowers: [
        ],
        FrequencyID: 4,
        Provider: '',
        IsGross: 'grossTemp',
      };
    };

    $scope.resetIncomeModel = () => {
      $scope.IncomeSet = $scope.getNewIncomeModel();
      $scope.chosenGrossNetWithFrequency = 'G/M';
    };
    const incomeValidation = () => {
      const filterIncome = _.find($scope.financialsSharedService.listIncome, (obj) => {
        return obj.FamilyId === parseInt($scope.financialsSharedService.selectedFamily.FamilyId, 10);
      }) || { overview: { FinancialInfo: [] } };
      $scope.hasIncome = filterIncome.overview.FinancialInfo.length > 0;
    };
    $scope.getRightIncomeType = (financialInfo) => {
      return financialInfo.TypeId === DEFAULT_INCOME_TYPES.RENTAL ? financialInfo.RentalTypeName || financialInfo.Type : financialInfo.Type;
    };
    $scope.$watch('financialsSharedService.listIncome', () => {
      incomeValidation();
    }, true);

    $scope.$watch('financialsSharedService.selectedFamily.FamilyId', () => {
      incomeValidation();
    });

    $scope.displayIncomeFormCallback = () => {
      $scope.incomeLoading = true;
      $timeout(() => {
        $scope.displayIncomeForm = true;
        $scope.incomeLoading = false;
        $scope.resetIncomeModel();
        $scope.tooltipDisplay = true;
        $timeout(() => {
          $scope.tooltipDisplay = false;
        }, 6500);
      }, 2000);
    };
    $scope.hideToolTip = () => {
      $scope.tooltipDisplay = false;
    };

    // Save liability inline
    $scope.addIncome = () => {
      // action taken : change the familyIds of the model
      angular.extend($scope.IncomeSet, {
        FamilyId: $scope.financialsSharedService.selectedFamily.FamilyId.toString(),
        TypeId: parseInt($scope.IncomeSet.TypeId, 10),
        IsGross: $scope.IncomeSet.TypeId === '19' ? incomeSharedService.incomeServices.convertNetGross($scope.IncomeSet.IsGross, true) : true,
      });
      const joinFamilyObj = _.find($scope.ownersList, (obj) => {
        return obj.BorrowerID === 0;
      });

      if (joinFamilyObj) {
        if (joinFamilyObj.ticked) {
          $scope.ownersListSelected = _.map($scope.ownersList, (obj) => {
            if (obj.BorrowerID !== 0) {
              obj.ticked = true;
              return obj;
            }
          });
        }
      } else {
        $scope.ownersListSelected = _.filter($scope.ownersList, (obj) => {
          return obj.ticked === true;
        });
      }
      $scope.IncomeSet.Borrowers = [];
      $scope.ownersList.forEach((data) => {
        if (data.ticked && data.BorrowerID !== 0) {
          $scope.IncomeSet.Borrowers.push({
            Percentage: 0,
            BorrowerID: data.BorrowerID,
            IsEntity: data.IsEntity,
          });
        }
      });

      loanScenarioService.IncomeDetailsSet($scope.IncomeSet).then(() => {
        $scope._incomeInit(false);
        $scope.resetIncomeModel();
      });
    };

    $scope.incomeEmploymentCallback = () => {
      $scope.financialsSharedService.IncomeInfoGet();
      $scope.loanAppSharedData.refreshEmploymentList = true;
    };

    // Add Income Modal
    $scope.newIncomeModal = (type, isLinkIncome) => {
      if (isLinkIncome && $scope.ownersList.length) {
        const isJoint = _.find($scope.ownersList, obj => !obj.BorrowerID) || {};
        const firstBorrower = _.find($scope.ownersList, obj => (isJoint.ticked ? obj.BorrowerID : obj.ticked));
        $scope.IncomeSet.ClientId = (firstBorrower && firstBorrower.BorrowerID) || 0;
      }

      const templateUrl = `/assets/views/loanApplication/clientAssess/modal/${isLinkIncome ? `incomeEmployment` : `income.add`}.html`;
      const size = isLinkIncome ? 'md' : 'sm';
      const modalInstance = $uibModal.open({
        templateUrl,
        controller: 'LoanappIncomeModalCtrl',
        size,
        scope: $scope,
        resolve: {
          modalType() {
            return type;
          },
        },
        backdrop: 'static',
        keyboard: false,
      });

      modalInstance.result.then((loanAppIncomeResponse) => {
        if (loanAppIncomeResponse && loanAppIncomeResponse.isOpenIncomeModal && loanAppIncomeResponse.obj) {
          $scope.changeIncome(loanAppIncomeResponse.obj);
        } else {
          $scope.resetIncomeModel();
        }
      }, () => {
        $scope.resetIncomeModel();
      });
    };

    $scope.IncomeDetailsGet = (familyId, income) => {
      $scope.resetIncomeModel();

      angular.extend($scope.IncomeSet, income);
      $scope.IncomeSet.FrequencyID = parseInt($scope.IncomeSet.FrequencyID, 10);
      $scope.IncomeSet.TypeId = ($scope.IncomeSet.TypeId).toString();
      $scope.IncomeSet.IsGross = incomeSharedService.incomeServices.convertNetGross($scope.IncomeSet.IsGross, false);
      $scope.IncomeClientGet($scope.IncomeSet.Id, familyId, angular.copy($scope.IncomeSet.Borrowers));
      $scope.prevValueHolder = $scope.IncomeSet.TypeId;
      $scope.newIncomeModal('edit');
    };

    $scope.selectIncome = (familyId, income) => {
      if (isEmploymentIncome(income.TypeId) && income.EmploymentId) {
        const clientId = income.Borrowers && income.Borrowers.length && income.Borrowers[0].BorrowerID && income.Borrowers[0].BorrowerID.toString();
        $scope.loanAppSharedData.changeEmploymentOwnership(clientId);
        const modalInstance = $uibModal.open({
          backdrop: 'static',
          keyboard: false,
          templateUrl: '/assets/views/modals/employment.html',
          controller: 'EmploymentModalCtrl',
          controllerAs: 'vm',
          size: 'lg',
          windowClass: 'modal-larger',
          resolve: {
            newIncomeDetails: () => null,
            isFromIncome: () => true,
            familyId: () => familyId,
            employmentId: () => income.EmploymentId,
            clientId: () => clientId,
            loanAppId: () => $scope.loanAppId,
            action: () => 'Edit',
          },
        });

        modalInstance.result.then((response) => {
          if (response) {
            $scope.financialsSharedService.IncomeInfoGet();
            $scope.loanAppSharedData.refreshEmploymentList = true;
          }
        });
      } else if (isEmploymentIncome(income.TypeId) && !income.EmploymentId) {
        $scope.IncomeSet = income;
        $scope.newIncomeModal('edit', true);
      } else {
        $scope.IncomeDetailsGet(familyId, income);
      }
    };

    // Watch for changes in selected family
    $scope.$watch('financialsSharedService.selectedFamily.FamilyId', (nv) => {
      $scope.IncomeClientGet(undefined, nv);
      financialsSharedService.updateIncome(nv);
    });

    $scope.onItemSelect = (data) => {
      const dataParam = data;
      let isJointFamily = true;
      for (let i = 0; i < $scope.ownersList.length; i++) {
        if ((typeof $scope.ownersList[i].ticked === 'undefined' || !$scope.ownersList[i].ticked) && $scope.ownersList[i].BorrowerID >= 1) {
          isJointFamily = false;
        }
      }

      // will be true if user clicks on joint
      if (dataParam.BorrowerID === 0) {
        isJointFamily = true;
      }
      if (!isJointFamily) {
        $scope.ownersList = _.map($scope.ownersList, (obj) => {
          if (obj.BorrowerID === 0) {
            obj.ticked = false;
          }
          return obj;
        });
      } else {
        const findJointIncomeChecker = incomeSharedService.incomeServices.findJoint($scope.ownersList);
        // need to check if single owner
        if (findJointIncomeChecker && Object.keys(findJointIncomeChecker).length) {
          $scope.ownersList = _.map($scope.ownersList, (obj) => {
            obj.ticked = obj.BorrowerID === 0;
            return obj;
          });
        }
      }
    };

    $scope.ownersListSelectedArray = [];

    $scope.onSelectAll = () => {
      const findJointOnSelect = incomeSharedService.incomeServices.findJoint($scope.ownersList);
      // need to check if single owner
      if (findJointOnSelect && Object.keys(findJointOnSelect).length) {
        $scope.ownersList = _.map($scope.ownersList, (obj) => {
          obj.ticked = obj.BorrowerID === 0;
          return obj;
        });
      } else {
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ($scope.ownersList[i].BorrowerID > -1) {
            $scope.ownersList[i].ticked = false;
          } else {
            $scope.ownersList[i].ticked = true;
          }
        }
      }
    };

    $scope.grossNetDropdownClicked = (grossNetString, freqValue, freqDesc) => {
      $scope.IncomeSet.IsGross = grossNetString;
      $scope.IncomeSet.FrequencyID = freqValue;
      $scope.chosenGrossNetWithFrequency = `${(grossNetString.charAt(0)).toUpperCase()}/${(freqDesc.charAt(0)).toUpperCase()}`;
    };
    $scope.$watch('ownersListSelected', () => {
      $('.hide-in-mobile .multiSelect').nextAll('div.buttonLabel').remove();
      if ($scope.ownersListSelected && $scope.ownersListSelected.length > 2) {
        $('.multiSelect .buttonLabel:last-child').html(
          `${$scope.ownersListSelected.length - 2} more ...` +
                    `<span class="caret"></span>`
        );
      }
      $('.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))').each((e) => {
        $(e.currentTarget).html(`${$(e.currentTarget).text()}<i class="fa fa-close"></i> <span class="caret"></span>`);
      });
    });
  });
