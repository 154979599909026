import angular from 'angular';
import { COLOR } from 'Common/constants/colors';
import { getGoogleMapInstance } from 'Common/utilities/map';

class GoogleMapShapesCtrl {
  constructor(
    mapService,
    $interval,
    corporateService
  ) {
    'ngInject';

    this.mapService = mapService;
    this.$interval = $interval;
    this.corporateService = corporateService;
  }

  addCircleRadius(lat, lng, mapInstance) {
    if (!mapInstance) return;
    if (!this.circle) {
      // Initialize listeners for initial animations
      // Use only on first run
      const googleMapWindow = getGoogleMapInstance();
      if (!googleMapWindow) return;
      const circleOptions = {
        strokeColor: COLOR.DARKER_BLUE,
        strokeOpacity: 0.37,
        strokeWeight: 0,
        fillColor: COLOR.DARKER_BLUE,
        fillOpacity: 0.37,
        map: mapInstance,
        center: new googleMapWindow.LatLng(lat, lng),
        radius: this.distanceValue * 1000,
      };
      this.circle = new googleMapWindow.Circle(circleOptions);
      const circleInstance = this.circle;
      const circleFitListener = googleMapWindow.event.addListener(mapInstance, 'bounds_changed', () => {
        mapInstance.fitBounds(circleInstance.getBounds());
      });
      googleMapWindow.event.addListener(mapInstance, 'tilesloaded', () => {
        googleMapWindow.event.removeListener(circleFitListener);
      });
    } else {
      this.circle.setRadius(this.distanceValue * 1000);
      mapInstance.fitBounds(this.circle.getBounds());
    }
  }

  getSelectedValue(value) {
    this.distanceValue = parseInt(value, 10);
    this.addCircleRadius(this.addressModelValue.latitude, this.addressModelValue.longitude, this.map);
    this.leadDetailsObj.Distance = parseInt(value, 10);
    this.corporateService.leadsProfileSet(this.mainFamilyId, 0, this.leadDetailsObj);
  }

  loadMap(mapId, address) {
    this.mapDetails = this.mapService.mapInitialization(
      mapId,
      this.addressModelValue.latitude,
      this.addressModelValue.longitude,
      10,
      this.addressModelValue.formatted_address,
      false);
    this.map = this.mapDetails && this.mapDetails.length ? this.mapDetails[0] : '';
    this.addCircleRadius(address.latitude, address.longitude, this.map);
  }

  checkMapEle() {
    const gmapEle = angular.element(`.gmap-shapes-component #${this.mapId}`);
    if (!gmapEle.length) return;
    this.loadMap(this.mapId, this.addressModelValue);
    if (this.mapEleInterval) this.$interval.cancel(this.mapEleInterval);
  }

  $onChanges(changes) {
    if (!changes) return;
    if (changes.addressModel && changes.addressModel.currentValue) {
      this.addressModelValue = changes.addressModel.currentValue;
    }
    if (changes.leadDetails && changes.leadDetails.currentValue) {
      this.leadDetailsObj = changes.leadDetails.currentValue;
      this.distanceValue = parseInt(changes.leadDetails.currentValue.Distance, 10);
    }
    this.checkMapEle();
  }

  $onInit() {
    this.options = {
      from: this.minDistance,
      to: this.maxDistance,
      step: 1,
      className: 'clip-slider',
      css: {
        background: {
          'background-color': 'silver',
        },
        range: {
          'background-color': '#579BCB',
        },
      },
    };
    this.mapEleInterval = this.$interval(this.checkMapEle.bind(this), 1000);
  }

  $onDestroy() {
    this.$interval.cancel(this.mapEleInterval);
  }
}

export default GoogleMapShapesCtrl;
