import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('InsAppFinancialLiabilitiesCtrl',
  function InsAppFinancialLiabilitiesCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal
  ) {
    $scope.loanAppId = $stateParams.loanAppId;

    /**
            Liabilities Code Below
        * */
    $scope.liabilitiesInit = function () {
      $scope.listLiabilities = [];

      $scope.liabilityTypes = [
        { Value: 'Commercial bill', Group: 'Commercial', Desc: 'Commercial bill' },
        { Value: 'Hire Purchase', Group: 'Commercial', Desc: 'Hire Purchase' },
        { Value: 'Lease', Group: 'Commercial', Desc: 'Lease' },
        { Value: 'Line Of Credit', Group: 'Commercial', Desc: 'Line Of Credit' },
        { Value: 'Other Loan', Group: 'Commercial', Desc: 'Other Loan' },
        { Value: 'Overdraft', Group: 'Commercial', Desc: 'Overdraft' },
        { Value: 'Term Loan', Group: 'Commercial', Desc: 'Term Loan' },

        { Value: 'Contingent Liability', Group: 'Contingent', Desc: 'Contingent Liability' },
        { Value: 'Credit Card', Group: 'Contingent', Desc: 'Credit Card' },
        { Value: 'Maintenance', Group: 'Contingent', Desc: 'Maintenance' },
        { Value: 'Outstanding Taxation', Group: 'Contingent', Desc: 'Outstanding Taxation' },
        { Value: 'Personal Loan', Group: 'Contingent', Desc: 'Personal Loan' },
        { Value: 'Store Card', Group: 'Contingent', Desc: 'Store Card' },
        { Value: 'Other', Group: 'Contingent', Desc: 'Other' },

        { Value: 'HECs', Group: 'Student Loan', Desc: 'HECs' },
      ];

      // Temporary / should call get api
      $scope.listLiabilities = [
        {
          Type: $scope.liabilityTypes[0],
          OwnershipInitials: utilitiesService.filterInitial('Test', 'Client'),
          Ownership: 'Test Client',
          Limit: 21212,
          Value: 300000,
        },
      ];

      $scope.displayLiabilitiesForm = false;
    };
    $scope.liabilitiesInit();

    $scope.delLiability = function (liability) {
      const idx = $scope.listLiabilities.indexOf(liability);
      if (idx > -1) {
        $scope.listLiabilities.splice(idx, 1);
      }
    };

    $scope.getNewLiabilityModel = function () {
      return {
        Type: '',
        OwnershipInitials: utilitiesService.filterInitial('Test', 'Client'),
        Ownership: 'Test Client',
        Limit: null,
        Value: null,
        AddToExpenses: true,
      };
    };

    $scope.resetLiabilityModel = function () {
      $scope.LiabilitiesSet = $scope.getNewLiabilityModel();
    };

    $scope.changeLiability = function () {
      if (_.size($scope.LiabilitiesSet.Type) > 0 && $scope.LiabilitiesSet.Type.Group !== 'Commercial')
        $scope.newLiabilitiesModal();
    };

    // Save liability inline
    $scope.addLiability = function () {
      $scope.listLiabilities.push($scope.LiabilitiesSet);
      $scope.resetLiabilityModel();
    };

    // Add Liabilities Modal
    $scope.newLiabilitiesModal = function (type) {
      $scope.modalType = type;
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/loanApplication/clientAssess/modal/liabilities.add.html',
        controller: 'FinPosLiabilitiesModalCtrl',
        size: 'lg',
        scope: $scope,
      });

      modalInstance.result.then(() => {
        $scope.addLiability();
        $scope.displayLiabilitiesForm = false;
      }, () => {
        $scope.addLiability();
        $scope.displayLiabilitiesForm = false;
      });
    };


    $scope.modalType = false;
    //* ************************************/
    //  owners multi Select                /
    //* ************************************/
    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel'>All Owners</div>", // default-label is deprecated and replaced with this.
    };
    $scope.ownersList = [];
    $scope.ownersListSelected = [];
    function isOtherTicked(data) {
      let bool = false;
      data.forEach((val) => {
        if (val.ClientEntityId > -1 && val.ticked) {
          bool = true;
        }
      });

      return bool;
    }
    $scope.onItemSelect = function (data) {
      if (parseInt(data.ClientEntityId, 10) === -1) {
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ($scope.ownersList[i].ClientEntityId > -1) {
            $scope.ownersList[i].ticked = false;
          } else {
            $scope.ownersList[i].ticked = true;
          }
        }
      } else {
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if (parseInt($scope.ownersList[i].ClientEntityId, 10) === -1 && !isOtherTicked($scope.ownersList)) {
            $scope.ownersList[i].ticked = true;
            $scope.ownersListSelected.push($scope.ownersList[i]);
            //
          } else if (parseInt($scope.ownersList[i].ClientEntityId, 10) === -1) {
            $scope.ownersList[i].ticked = false;
          }
        }
      }
    };

    $scope.ownersListSelectedArray = [];

    $scope.onSelectAll = function () {
      for (let i = 0; i < $scope.ownersList.length; i++) {
        if ($scope.ownersList[i].ClientEntityId > -1) {
          $scope.ownersList[i].ticked = false;
        } else {
          $scope.ownersList[i].ticked = true;
        }
      }
    };
  });
