import moment from 'moment';

export function insurancePipelineStatusForUI(status) {
  return {
    id: status.StatusID,
    name: status.StatusName,
  };
}

export function insurancePipelineLabelForUI(label) {
  return {
    id: parseInt(label.LabelID, 10),
    name: label.LabelName,
    color: label.ColorName,
  };
}

export function insurancePipelineLabelForMyCRM(label) {
  return {
    LabelID: parseInt(label.id, 10),
    LabelName: label.name,
    ColorName: label.color,
  };
}

export function insurancePipelineCardForUI(card) {
  return {
    id: card.PipelineCardID,
    quoteId: card.QuoteID,
    policyNumber: card.PolicyNumber,
    annualPremium: card.AnnualPremium,
    dateCreated: card.CreatedDate,
    lastDateUpdated: card.LastUpdatedDate,
    status: {
      id: card.PipelineStatusID,
      name: card.PipelineStatusName,
    },
    provider: {
      id: card.ProviderID,
      name: card.ProviderName,
    },
    client: {
      id: card.ClientFamilyID,
      name: card.ClientName,
    },
    adviser: {
      id: card.AdviserID,
      name: card.AdviserName,
      avatar: card.AdviserAvatarUrl,
    },
  };
}

export function insurancePipelineCardBenefitForUI(benefit) {
  return {
    type: benefit.BenefitID,
    name: benefit.BenefitName,
  };
}

export function insurancePipelineColumnForUI(column) {
  return {
    id: column.StatusID,
    name: column.StatusName,
    page: column.Page,
    pageSize: column.PageSize,
    totalCards: column.TotalCardCounts,
    totalAnnualPremium: column.TotalAnnualPremium,
    cards: column.Cards && column.Cards.map((card) => {
      const mappedCard = insurancePipelineCardForUI(card);
      mappedCard.labels = card.CardLabels && card.CardLabels.map(insurancePipelineLabelForUI);
      mappedCard.benefits = card.Benefits && card.Benefits.map(insurancePipelineCardBenefitForUI);
      return mappedCard;
    }),
  };
}

export function insuranceFamilyListForUI(info) {
  return {
    adviser: info.Adviser,
    city: info.City,
    clientContact: info.ClientContact,
    clientFirstName: info.ClientFirstName,
    clientLastName: info.ClientLastName,
    familyFullName: info.FamilyFullName,
    familyID: info.FamilyID,
    familyType: info.FamilyType,
    totalCount: info.TotalCount,
  };
}

export function insuranceQuoteForUI(quote) {
  return {
    dateCreated: moment(quote.DateCreated).format('MMMM Do YYYY, h:mm:ss a'),
    familyFullName: quote.FamilyFullName,
    familyID: quote.FamilyID,
    module: quote.Module,
    noOfClients: quote.NoOfClients,
    peopleEntity: quote.PeopleEntity,
    quoteId: quote.QuoteID,
    pipelineStatusId: quote.InsurancePipelineStatusID,
  };
}

export function insuranceProvidersQuoteForUI(provider) {
  return {
    id: provider.ProviderID,
    quoteId: provider.QuoteID,
    providerName: provider.ProviderName,
    providerLogoUrl: provider.ProviderLogoUrl,
  };
}

export function insuranceClientSearchResult(data) {
  return {
    familyFullName: data.ProviderName,
    familyID: data.ResultFamilyID,
  };
}

export function insurancePipelineConversionSummaryForUI(summary) {
  return {
    statusId: summary.StatusID,
    statusCount: summary.StatusCount,
    conversionPercentage: summary.ConversionPercentage,
  };
}

export function insuranceNotProceededReasonForUI(reason) {
  return {
    id: reason.InsurancePipelineReasonID,
    description: reason.Reason,
  };
}

export function insurancePipelineTableForUI(pipelineTable) {
  return {
    totalRecords: pipelineTable.TotalCardCounts,
    pageNumber: pipelineTable.Page,
    pageSize: pipelineTable.PageSize,
  };
}

export function insurancePipelineFilterForUI(filter) {
  return {
    isCompact: filter.IsCompact,
    viewMode: filter.ViewMode,
    sortColumn: filter.SortExpression,
    sortDirection: filter.SortDirection,
    labelFilter: filter.LabelID,
    adviserFilter: filter.AdviserID,
    statusFilters: filter.StatusIds,
  };
}

export function insurancePipelineStatusSortingForUI(sorting) {
  return {
    statusId: sorting.StatusID,
    field: sorting.SortExpression,
    direction: sorting.SortDirection,
  };
}

export function insurancePipelineFiltersForMyCRM(filters) {
  return {
    IsCompact: filters.isCompact,
    ViewMode: filters.viewMode,
    SortExpression: filters.sortColumn,
    SortDirection: filters.sortDirection,
    LabelID: filters.labelFilter || null,
    AdviserID: filters.adviserFilter || null,
    StatusIds: filters.statusFilters || null,
  };
}

export function insurancePipelineStatusSortingForMyCRM(sorting) {
  return {
    StatusID: sorting.statusId,
    SortExpression: sorting.field,
    SortDirection: sorting.direction,
  };
}
