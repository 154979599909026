import angular from 'angular';

class AddInsuranceTemplateNotesModalCtrl {
  constructor(
    $uibModalInstance,
    benefitId,
    actionType
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.benefitId = benefitId;
    this.actionType = actionType;
  }
}
angular.module('app').controller('AddInsuranceTemplateNotesModalCtrl', AddInsuranceTemplateNotesModalCtrl);
