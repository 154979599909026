class LoanTermService {
  getLoanTermMonthList() {
    this.loanTermMonthList = [
      { name: 'None', value: 0 },
      { name: '3 Months', value: 0.25 },
      { name: '6 Months', value: 0.5 },
      { name: '9 Months', value: 0.75 },
      { name: '18 Months', value: 1.5 }];
    return this.loanTermMonthList;
  }
}

export default LoanTermService;
