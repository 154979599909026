// Angular File Upload module does not include this directive
// Only for example

/**
 * The ng-thumb directive
 * @author: nerv
 * @version: 0.1.2, 2014-01-09
 */
import angular from 'angular';

angular.module('app').directive('ngThumb', function ngThumb($window) {
  const helper = {
    support: !!($window.FileReader && $window.CanvasRenderingContext2D),
    isFile(item) {
      return angular.isObject(item) && item instanceof $window.File;
    },
    isImage(file) {
      const type = `|${file.type.slice(file.type.lastIndexOf('/') + 1)}|`;
      return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
    },
  };

  return {
    restrict: 'A',
    template: '<canvas/>',
    link(scope, element, attributes) {
      if (!helper.support) return;

      const params = scope.$eval(attributes.ngThumb);

      if (!helper.isFile(params.file)) return;
      if (!helper.isImage(params.file)) return;

      const canvas = element.find('canvas');
      const reader = new $window.FileReader();

      function onLoadImage() {
        const width = params.width || this.width / this.height * params.height;
        const height = params.height || this.height / this.width * params.width;
        canvas.attr({ width, height });
        canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
      }

      function onLoadFile(event) {
        const img = new $window.Image();
        img.onload = onLoadImage;
        img.src = event.target.result;
      }

      reader.onload = onLoadFile;
      reader.readAsDataURL(params.file);
    },
  };
});
