import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import Blob from 'cf-blob.js';
import { AGREEMENT_TYPE } from 'Common/constants/agreementType';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ADVISER_TAB } from 'Common/constants/contactTabIndex';
import { CORPORATE_CONTACT_KEY } from 'Common/constants/corporateContactKeys';
import { ADVISER_STATUS } from 'Common/constants/adviserStatus';
import { removeSpacesOnString } from 'Common/utilities/string';

angular.module('app').controller('AdviserCtrl', function AdviserCtrl(
  referralAgreementService,
  $scope,
  $rootScope,
  $http,
  $filter,
  $timeout,
  corporateService,
  contactService,
  generalService,
  queryService,
  regionalizationService,
  $sce,
  $state,
  mapService,
  $uibModal,
  $compile,
  toaster,
  wmComplianceService,
  $q,
  referralsService,
  broadcastDataService,
  dataService,
  $window,
  $document,
  commonFnService,
  corporatePermissionService
) {
  // Filter
  $filter('lowercase')();
  // state params
  $scope.familyId = $state.params.familyId;
  $scope.clientId = $state.params.clientId;
  // array
  $scope.localLang = {
    reset: "<i class='fa fa-plus'></i>",
    search: 'Search',
  };
  // done
  $scope.genderList = [{
    id: 1,
    value: 'Male',
  }, {
    id: 2,
    value: 'Female',
  }, {
    id: 3,
    value: 'Unknown',
  }];
  $scope.nameTitles = ['Mr.', 'Ms.', 'Mrs.'];
  $scope.addressTypes = ['Business Address', 'Home Address', 'Mailing Address'];
  $scope.addressTypeDetails = [{
    id: 1,
    name: 'Business Address',
  }, {
    id: 2,
    name: 'Home Address',
  }, {
    id: 3,
    name: 'Mailing Address',
  }];
  $scope.serviceModelArr = ['Standard', 'Discount', 'Waivered'];
  $scope.allWeeks = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  $scope.loanType = ['Residential', 'Commercial', 'Reverse Mortgage', 'SMSF', 'Car', 'Personal', 'Low Doc', 'Investment', 'Refinance', 'First Home Buyer'];
  $scope.review_month = ['No Review Month', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  // broker
  $scope.contactMethodArr = ['Email', 'Phone', 'Any'];
  $scope.supportingDocArr = ['Email', 'Apply Online', 'Lender Portal'];
  $scope.feesPaidBy = [{
    name: 'Head Broker',
    id: 0,
  }, {
    name: 'Loan Writer',
    id: 1,
  }, {
    name: 'Others',
    id: 2,
  }];
  $scope.employmentTypes = ['Contractor', 'Employee'];
  $scope.auEdrArr = ['CIO', 'FOS'];
  $scope.nzEdrArr = ['FSCL', 'IFSO', 'FDR'];
  $scope.roleType = [{
    name: 'Franchisee',
    ticked: false,
  }, {
    name: 'Loan Writer',
    ticked: false,
  }, {
    name: 'Corporate',
    ticked: false,
  }, {
    name: 'Director',
    ticked: false,
  }, {
    name: 'Guarantor',
    ticked: false,
  }, {
    name: 'Principal',
    ticked: false,
  }];
  $scope.saveType = {
    all: 0,
    adviserDetails: 1,
    additionalDetails: 2,
    franchiseDetails: 3,
  };
  $scope.setterType = ['contactSet', 'familyComplianceSet', 'familyMarketingDetailSet', 'familyWebManagementSet', 'familyBrokerSupportSet', 'leadsProfileSet', 'familyRecruitmentDetailsSet', 'adviserPreviousNameSet', 'appointmentDetailSet', 'employmentDetailSet', 'educationDetailSet', 'qualificationDetailsSet', 'recentAuditResultSet', 'stdPreVetSet', 'specialistAdviceSRSet', 'generalSet', 'familyAchievementSet'];

  $scope.splits = [{
    id: 1,
    name: 'TBC 1',
  }, {
    id: 2,
    name: 'TBC 2',
  }];
  $scope.authLevels = [{
    id: 1,
    name: 'Level 1',
  }, {
    id: 2,
    name: 'Level 2',
  }, {
    id: 3,
    name: 'Level 3',
  }];
  $scope.preVetStatuses = [{
    id: 1,
    name: 'Completed',
  }, {
    id: 2,
    name: 'Incomplete',
  }, {
    id: 3,
    name: 'Extended',
  }];
  $scope.leadPriorities = [{
    id: 1,
    name: 'Level 1',
  }, {
    id: 2,
    name: 'Level 2',
  }, {
    id: 3,
    name: 'Level 3',
  }];
  $scope.industryMemberships = [{
    id: 1,
    name: 'AFA',
  }, {
    id: 2,
    name: 'FPA',
  }, {
    id: 3,
    name: 'SPAA',
  }];
  $scope.level_accessList = [{
    id: 1,
    value: 'Email Only',
  }, {
    id: 2,
    value: 'Full system access',
  }, {
    id: 3,
    value: 'MyCRM access with no charge',
  }];
  $scope.adviserDetailsTmp = {};
  // private  arrays
  const btnClass = ['btn-success', 'btn-light-blue', 'btn-default', 'btn-warning', 'btn-info', 'btn-danger'];
  const fontClass = ['fa-paper-plane text-white', 'fa-check text-white', 'violet-color fa-times', 'fa-search text-white', 'fa-hand-paper-o text-white', 'fa-ban text-white'];
  // Variables
  $scope.selectedCorporateContact = [];
  $scope.isEditMode = false;
  $scope.isMailingEditMode = false;
  // objects
  $scope.adviserDetails = {};
  $scope.editMode = {};
  $scope.adviserMarketing = {};
  $scope.wmComplianceDetails = {};
  // array for adviser info
  // recruitement
  $scope.recruitmentNotProceedingReasonLists = [];
  $scope.adviserOrganizationLists = [];
  $scope.prospectiveRatingLists = [];
  $scope.recruitmentStatusLists = [];
  $scope.marketingAdviserDetail = [];
  $scope.perceivedQualityLists = [];
  $scope.adviserAchievements = [];
  $scope.operatingCountries = [];
  $scope.inquirySourceLists = [];
  $scope.brokerStatusLists = [];
  $scope.contactTypeRoles = [];
  $scope.reportingRegions = [];
  $scope.reportingStates = [];
  $scope.commissionTiers = [];
  $scope.enquirySources = [];
  $scope.entityTypes = [];
  $scope.leadStatus = [];
  $scope.nps = [];
  $scope.adviser_compliance = [];
  $scope.homeAutoAddressDetails = [];
  $scope.webManagement = [];
  $scope.adviserAgreements = [];
  $scope.outputLoanType = [];
  $scope.outputRoleTypes = [];
  $scope.selectedQualification = [];
  $scope.selectedSpecialCourse = [];
  $scope.specialCourseName = [];
  $scope.selectedAdviceType = [];
  $scope.selectedIndustryMembership = [];
  $scope.mapDetails = [];
  $scope.corpLeadCampaignGet = [];
  $scope.userTypes = [];
  $scope.brokerFees = [];
  $scope.selectedLeadCampaign = [];
  $scope.selectedCategory = [];
  $scope.contactModel = {};
  $scope.isMapLoaded = false;
  $scope.googleAutoPlace = '';
  $rootScope.isCorpTeam = false;
  $scope.isSameinBussinessAddress = false;
  $scope.accessTypesArr = [];
  $scope.mortageToolsArr = [];
  $scope.commissionAccessArr = [];
  $scope.showAddField = {};
  $scope.commission = {};
  // variables
  $scope.subMenuTab = 'Adviser Info';
  $scope.marketingSubMenuTab = 'Marketing/Web Management';
  $scope.brokerSupportSubMenuTab = 'General';
  $scope.phoneMask = '';
  $scope.addressTypeValue = '';
  $scope.mycrmActive = false;
  $scope.hiddenTabCount = angular.element('.more li').length;
  $scope.hiddenTabs = false;
  $scope.narrowNav = true;
  $scope.curDate = new Date();
  $scope.currrentYear = $scope.curDate.getFullYear();
  $scope.previousYear = $scope.curDate.getFullYear() - 1;
  $scope.currentYearValue = (`${$scope.curDate.getFullYear()}`).slice(-2);
  $scope.previousYearValue = (`${$scope.curDate.getFullYear() - 1}`).slice(-2);
  $scope.altInputFormats = ['dd-MM-yyyy', 'dd/MM/yyyy'];
  $scope.dateFormat = 'dd-MM-yyyy';
  $scope.currrentDate = new Date();
  $scope.editBusinessAdd = false;
  $scope.editHomeAdd = false;
  $scope.editMailingAdd = false;
  // Broadcasts Events
  // $scope.$on('UPDATE_FIELD_DETAIL', _OnUpdateDetails);
  // mapService.loadAPI().then(function (response) {
  //     // google autocomplete place initialization
  //     mapService.mapAutoCompletePlace('map-address-bus-edit', _OnAddressChange);
  //     mapService.mapAutoCompletePlace('map-address-home-edit', _OnAddressChange);
  //     mapService.mapAutoCompletePlace('map-address-mailing-edit', _OnAddressChange);
  // });
  // this code already exists from other controllers (just copy and paste it here)
  //* *****
  // Public
  // Function : this will show and list the hidden tabs that
  // is not shown in tabs view.
  //* ****
  $scope.toggleOverflowTabs = function () {
    if ($scope.hiddenTabCount > 0) {
      $scope.narrowNav = false;
    } else {
      $scope.narrowNav = true;
    }
  };
  $scope.openHiddenTabs = function () {
    $scope.hiddenTabs = $scope.hiddenTabs === false;
  };
  $scope.closeHiddenTabs = function () {
    $scope.hiddenTabs = false;
  };
  //* *****
  // Private
  // Function : this will initialize all required
  // methods and properties to be loaded on page loads.
  //* ****
  const initializeTab = () => {
    if ($scope.familyId && $scope.clientId) {
      const currentTab = parseInt($window.sessionStorage.getItem('currentTab'), 10);
      if (currentTab === ADVISER_TAB.AGREEMENTS || currentTab === ADVISER_TAB.COMMUNICATIONS) {
        $scope.contactModel.selectedContacts = currentTab;
        $scope.getAllAdviserDetails($scope.tabs_advisers[0].name);
      } else {
        $window.sessionStorage.removeItem('currentTab');
        $scope.contactModel.selectedContacts = 0;
      }
      $scope.$watch('contactModel.selectedContacts', (newValue) => {
        const tabSelected = $scope.tabs_advisers.filter((tab) => {
          return parseInt(tab.index, 10) === parseInt(newValue, 10);
        });
        if (tabSelected && tabSelected.length) {
          $window.sessionStorage.setItem('currentTab', tabSelected[0].index);
          $scope.getAllAdviserDetails(tabSelected[0].name);
        } else {
          $scope.getAllAdviserDetails($scope.tabs_advisers[0].name);
        }
      });
    }
  };
  $scope.isToggleAdviser = false;
  $scope.systemInfoToggle = () => {
    $scope.isToggleAdviser = !$scope.isToggleAdviser;
  };

  const initialize = () => {
    dataService.getUserInfo(true).then((response) => {
      if (response && response.data) {
        $scope.familyIdLoggedUser = response.data.FamilyId;
        $scope.isCorpTeamValue = response.data.CorpAccessTypeID;
        $scope.userReportingCountryId = response.data.CountryId;
        $scope.tabs_advisers = dataService.getAdviserTabs($scope.userReportingCountryId);
        initializeTab();
      }
    });
    $scope.countryNewZealand = COUNTRY_TYPE.NEW_ZEALAND;
    $scope.countryAustralia = COUNTRY_TYPE.AUSTRALIA;
  };

  const removeSpaces = (selectedContacts) => {
    if (selectedContacts)
      return removeSpacesOnString(selectedContacts);
  };
  const getAccessMatrixes = (selectedContacts, subMenuTab) => {
    if (!selectedContacts) return;
    dataService.mapFieldAccessMatrixes('AdviserContact', selectedContacts, subMenuTab)
      .then((response) => {
        $scope.editMode = response;
      });
  };

  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  function _setBooleantoInt(value) {
    return value ? 1 : 0;
  }
  function _setInttoBoolean(value) {
    return value ? 'true' : 'false';
  }
  //* *****
  // Public
  // Function : this will set all the required class.
  // @ref Arrays btnClass and fontClass
  // @params
  //     status (string), statusId (interger), isbtn (boolean)
  //* ****
  $scope.setClasses = function (status, stausId, isbtn) {
    return isbtn ? btnClass[stausId] : fontClass[stausId];
  };

  $scope.getClassesName = (isbtn, statusName, isFontColor) => {
    return commonFnService.getButtonClassNamePerStatusName(isbtn, statusName, isFontColor);
  };
  //* *****
  // Private
  // Function :
  // @params
  // @return
  //* ****
  $scope.selectSubMenuTab = function (subMenuTab, type) {
    if ($scope.tabs_advisers[$scope.contactModel.selectedContacts] && $scope.tabs_advisers[$scope.contactModel.selectedContacts].name) {
      switch (type) {
      // initialize all variable and data to be parsed in field
      case 'Adviser Details':
        $scope.subMenuTab = subMenuTab;
        break;
      case 'Marketing':
        $scope.marketingSubMenuTab = subMenuTab;
        break;
      case 'Support Services':
        $scope.brokerSupportSubMenuTab = subMenuTab;
        break;
      default:
        break;
      }
      getAccessMatrixes(removeSpaces($scope.tabs_advisers[$scope.contactModel.selectedContacts].name), removeSpaces(subMenuTab));
    }
  };

  $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
    return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
  };
  //* *****
  // Private
  // Function :
  // @params
  // @return
  //* ****
  $scope.selectViewType = function (radioModel) {
    $scope.radioModel = radioModel;
  };
  //* *****
  // Private
  // Function :
  // @params
  // @return
  //* ****
  const _queryGet = (getter, ids, callback, ...args) => {
    const callbackFunctionQuery = callback;
    const argus = args[0] || [];

    const queryCallback = function (value) {
      $scope[getter] = [];
      $scope[getter] = value.data;
      if (callbackFunctionQuery) {
        callbackFunctionQuery(...argus);
      }
    };
    corporateService[getter].apply(null, ids).then(queryCallback);
  };
  $scope.formatDateString = function (date) {
    const tmpDate = new Date(date);
    let month;
    let day;
    month = tmpDate.getMonth() + 1;
    day = tmpDate.getDate();
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    return `${tmpDate.getFullYear()}-${month}-${day}`;
  };
  $scope.viewReferrer = function (data) {
    data.showReferrers = !data.showReferrers;
  };
  $scope.GoToAdviser = function (value) {
    // check if contact type is equal to assistant
    if (value.ContactType === 'Assistant') {
      $state.go('app.contactsAssistant', {
        clientId: 0,
        familyId: value.FamilyId,
      }, {
        notify: true,
        inherit: false,
      });
    } else {
      $state.go('app.contactsAdviser', {
        clientId: 0,
        familyId: value.FamilyId,
      }, {
        notify: true,
        inherit: false,
      });
    }
  };

  function getRecentViewed() {
    broadcastDataService.recentView.get().then((res) => {
      broadcastDataService.recentView.list = res;
    });
  }
  const getCommissionAccess = () => {
    contactService.commissionAccess($scope.familyId)
      .then((response) => {
        if (response) {
          $scope.commission.accessList = response.data;
        }
      });
  };
  //* *****
  // Private
  // Function :
  // @params
  // @return
  //* ****
  function _getArrValue(needleId, needledName, haystackId, haystackName, haystackArray) {
    let arrValue;
    angular.forEach(haystackArray, (array) => {
      if (needleId !== null) {
        if (needleId === array[haystackId]) {
          arrValue = array[haystackName];
        }
      }
      if (needledName !== null) {
        if (needledName === array[haystackName]) {
          arrValue = array[haystackId];
        }
      }
    });
    return arrValue;
  }

  function _getAllArrayDetails(service, getter, params, arrayContainer, objSetter, keySetName, keySetId, arrayKey, arrayName) {
    service[getter].apply(null, params).then((adviserResponse) => {
      $scope[arrayContainer] = [];
      if (adviserResponse && adviserResponse.data) {
        angular.forEach(adviserResponse.data, (value) => {
          $scope[arrayContainer].push(value);
        });
      }
      if (arrayKey && arrayKey) {
        objSetter.data[keySetName] = _getArrValue(objSetter.data[keySetId], null, arrayKey, arrayName, $scope[arrayContainer]);
      } else {
        objSetter.data[keySetName] = _getArrValue(objSetter.data[keySetId], null, keySetId, keySetName, $scope[arrayContainer]);
      }
    });
  }

  function _getContactTypeRoles(object) {
    // role
    corporateService.contactTypeRolesGet().then((adviserRolesTypeResponse) => {
      $scope.contactTypeRoles = [];
      if (adviserRolesTypeResponse && adviserRolesTypeResponse.data) {
        angular.forEach(adviserRolesTypeResponse.data, (value) => {
          if (value.ContactTypeRoleName === 'Adviser') {
            $scope.contactTypeRoles.push(value);
          }
        });
        // Business Details
        let contactRoleName = [];
        const contactRoleDesc = [];
        const contactRoleNameValue = [];
        contactRoleName = object.data.RoleId ? object.data.RoleId.split(',') : '';
        angular.forEach(contactRoleName, (value) => {
          angular.forEach($scope.contactTypeRoles, (role) => {
            if (parseInt(role.ContactTypeRoleId, 10) === parseInt(value, 10)) {
              contactRoleDesc.push(role);
              contactRoleNameValue.push(role.ContactTypeRoleDesc);
            }
          });
        });
        object.data.ContactTypeRoleNameValue = contactRoleNameValue.length > 0 ? contactRoleNameValue.join(', ') : 'Please Select Role';
        object.data.ContactTypeRoleName = contactRoleDesc;
      }
    });
  }

  function _getAdviserOrganizationEntityTypes() {
    corporateService.adviserOrganizationEntityTypesGet().then((response) => {
      $scope.entityTypes = [];
      if (response && response.data) {
        angular.forEach(response.data, (value) => {
          $scope.entityTypes.push(value);
        });
      }
    });
  }

  const initBrokerLists = () => {
    contactService.getAdminUser()
      .then((response) => {
        $scope.adminUsers = [];
        if (!response || !response.data) return;
        $scope.adminUsers = response.data;
      });
  };

  const initAdvisersLists = () => {
    corporateService.getAdvisersDropdown($scope.familyId, 0, 0)
      .then((advisers) => {
        $scope.adviserLists = [];
        if (!advisers || !advisers.data) return;
        dataService.getAssistantLists(advisers.data)
          .then((data) => {
            $scope.adviserLists = data;
          });
      });
  };
  //* *****
  // Private
  // Function :
  // @params
  // @return
  //* ****
  $scope.getAllAdviserDetails = function (selectedContacts) {
    // check corporate access

    contactService.getUserInfo().then((response) => {
      if (response.data.CorpAccessTypeID === 1) {
        $rootScope.isCorpTeam = true;
      }
    });
    dataService.getAccessType('Adviser')
      .then((response) => {
        $scope.accessTypesArr = response;
      });
    generalService.userTypes()
      .then((userTypes) => {
        if (userTypes && userTypes.data) {
          $scope.userTypes = [...userTypes.data];
        }
      });
    generalService.brokerFees()
      .then((brokerFees) => {
        if (brokerFees && brokerFees.data) {
          $scope.brokerFees = [...brokerFees.data];
        }
      });
    corporateService.corporateContactGet($scope.clientId, $scope.familyId).then((corporateAdviser) => {
      if (corporateAdviser.data.ReportingCountryId) {
        $scope.userReportingCountryId = corporateAdviser.data.ReportingCountryId;
      }
      getRecentViewed();
      if (corporateAdviser) {
        /* added this line so we can get the adviser organization's values */
        switch (selectedContacts) {
        // initialize all variable and data to be parsed in field
        case 'Adviser Details':
          getAccessMatrixes(removeSpaces(selectedContacts), removeSpaces($scope.subMenuTab));
          break;
        case 'Business Details':
          getAccessMatrixes(removeSpaces(selectedContacts));
          if (corporateAdviser.data) {
            $scope.getAdviserOrgLists(corporateAdviser.data.AdviserOrganizationId);
            $scope.getAppointmentLists(corporateAdviser.data.EntityOrgId);
            corporateAdviser.data.AppointmentEntityShareholder = corporateAdviser.data.AppointmentEntityShareholder ? corporateAdviser.data.AppointmentEntityShareholder : 'Please Enter IRD / GST Number';
            _getContactTypeRoles(corporateAdviser);
            _getAdviserOrganizationEntityTypes(corporateAdviser);
            $scope.loadContactUnderOrg(corporateAdviser.data);
            $scope.adviserObj = corporateAdviser.data;
          }
          break;
        case 'Marketing':
          getAccessMatrixes(removeSpaces(selectedContacts));
          break;
        case 'Support Services':
          getAccessMatrixes(removeSpaces(selectedContacts), removeSpaces($scope.brokerSupportSubMenuTab));
          $scope.subMenuAccessMgmtTab = 'Given To';
          if (corporateAdviser.data.ReportingCountryId) {
            $scope.userReportingCountryId = corporateAdviser.data.ReportingCountryId;
          }
          $scope.commissionAccessArr = [];
          initBrokerLists();
          initAdvisersLists();
          // broker support
          corporateService.familyBrokerSupportGet($scope.familyId, $scope.clientId).then((broker) => {
            if (broker && broker.data) {
              getCommissionAccess();
              if ($scope.accessTypesArr && $scope.accessTypesArr.length > 0) {
                broker.data.AccessTypeName = queryService.getArrValue(broker.data.AccessTypeID, null, 'AccessTypeId', 'AccessType', $scope.accessTypesArr);
              }
              if ($scope.userTypes && $scope.userTypes.length > 0) {
                broker.data.UserTypeName = queryService.getArrValue(broker.data.UserTypeId, null, 'UserTypeId', 'UserType', $scope.userTypes);
              }
              if ($scope.brokerFees && $scope.brokerFees.length > 0) {
                broker.data.BrokerFeeName = queryService.getArrValue(broker.data.BrokerFeeId, null, 'BrokerFeeId', 'BrokerFee', $scope.brokerFees);
              }
              $scope.mortageToolsArr = dataService.getMortageTools();
              if ($scope.mortageToolsArr && $scope.mortageToolsArr.length > 0) {
                broker.data.MortgageToolAccessName = $scope.mortageToolsArr[broker.data.MortgageToolAccess];
                broker.data.InsuranceToolsAccessName = $scope.mortageToolsArr[broker.data.InsuranceToolsAccess];
              }
              broker.data.FeesPaidByName = queryService.getArrValue(broker.data.FeesPaidById, null, 'id', 'name', $scope.feesPaidBy);
              broker.data.OfficerFullName = queryService.getArrValue(broker.data.OfficerFamilyID, null, 'FamilyId', 'FullName', $scope.adminUsers);
              broker.data.OfficerFullNameTmp = queryService.getArrValue(broker.data.OfficerFamilyID, null, 'FamilyId', 'FullName', $scope.adminUsers);
              _getAllArrayDetails(corporateService, 'confSponsorship2017Get', [], 'confSponsorship2017Gets', broker, 'CS2017Name', 'CS2017ID', 'ID', 'CSDesc');
              $scope.familyBrokerSupportGet = broker.data;
            }
          });

          // nps
          corporateService.familyNpsGet($scope.familyId, $scope.clientId).then((response) => {
            if (!response || !response.data) return;
            $scope.familyNpsGet = response.data;
          });
          // contact get
          corporateService.corporateContactGet($scope.clientId, $scope.familyId).then((response) => {
            if (response && response.data) {
              $scope.adviserDetailsTmp = response.data;
              $scope.adviserDetailsTmp.ReqdTeamAcessLevelName = queryService.getArrValue(response.data.ReqdTeamAcessLevel, null, 'id', 'value', $scope.level_accessList);
            }
          });
          break;
        case 'Agreements':
          referralAgreementService.getCreateAgreementAccess()
            .then((response) => {
              $scope.editMode.allReadOnly = !response;
            });
          corporateService.organizationAgreementGet(0, 0, 0, $scope.familyId, 0).then((response) => {
            if (response && response.data) {
              const tempAgreements = _.uniqBy(response.data, 'AgreementId');
              // elmer
              $scope.adviserAgreements = _.map(tempAgreements, (data) => {
                data.referrerLists = [];
                data.showReferrers = true;
                if (data.AgreementType === 'Loan Market') {
                  data.AgreementType = 'Loan Market / Wealth Market';
                }
                if (typeof data.Referrers_FullNames !== 'undefined') {
                  const referrerNameArray = data.Referrers_FullNames.split(',');
                  const referrerActiveArray = data.Referrers_IsActive.split(',');
                  // Display only active referrer
                  for (let index = 0; index < referrerActiveArray.length; index++) {
                    if (Number(referrerActiveArray[index]) > 0 && typeof referrerNameArray[index] !== 'undefined' && referrerNameArray[index] !== '') {
                      data.referrerLists.push(referrerNameArray[index]);
                    }
                  }
                }
                if (data.referrerLists.length > 1) {
                  data.showReferrers = false;
                }
                return data;
              });
            }
          });
          break;
        case 'Accreditation':
          getAccessMatrixes(removeSpaces(selectedContacts));
          break;
        default:
          break;
        }
        $scope.adviserDetails = corporateAdviser.data;
      }
    }).then(() => {});
  };

  $scope.showEditMode = (keyName) => {
    $scope.editMode[keyName] = true;
  };

  $scope.dateEditMode = function (obj, key) {
    if ($scope.formatDateString(obj[key]) === '1900-01-01') {
      obj[key] = new Date();
    } else if (!obj[key] || obj[key] === undefined) {
      obj[key] = new Date();
    } else {
      obj[key] = new Date(obj[key]);
    }
    $scope.editMode[key] = true;
    return $scope.editMode[key];
  };
  $scope.resetFailedLoginAttemps = () => {
    corporateService.resetLoginTotalFailedAttemptsSet($scope.familyId)
      .then((response) => {
        if (response) {
          $scope.getAllAdviserDetails('Support Services');
        }
      });
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  function _setEditMode(key) {
    $scope.editMode[key] = false;
    return $scope.editMode[key];
  }
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  const _querySet = (setter, key, field, arrays, isCorporate) => {
    $scope.editMode[key] = false;
    _setEditMode(field);
    if (isCorporate) {
      corporateService[setter].apply(null, arrays).then((response) => {
        // $scope.DateChangeValue(key, setter);
        if (response.status === 200) {
          toaster.pop('success', 'Update', 'successfully updated!');
        }
        if (key === 'ContactTypeRoleNameValue') {
          $scope.loadContactUnderOrg(arrays[1]);
        }
        // if (arrays.length > 0) {
        //     if (setter === 'contactSet') {
        //         //Always get the last array where data is placed. And if undefined it will be handled by the function.
        //         replaceMainDateFromTmp(arrays[arrays.length - 1], ['DOB', 'StartDate', 'EndDate']);
        //     } else if (setter === 'familyComplianceSet') {
        //         //Always get the last array where data is placed. And if undefined it will be handled by the function.
        //         replaceMainDateFromTmp(arrays[arrays.length - 1], ['EDRExpiryDate', 'DiplomaCompletedDate', 'EDRStartDate', 'PIExpiry', 'ProfessionalSchemeRenewalDate', 'DriversLicenseExpiry']);
        //     } else if (setter === 'familyRecruitmentDetailsSet') {
        //         replaceMainDateFromTmp(arrays[arrays.length - 1], ['ExpectedJoinDate']);
        //     } else if (setter === ' familyBrokerSupportSet') {
        //         replaceMainDateFromTmp(arrays[arrays.length - 1], ['CommencementDate', 'CommencementDate','AgreementSupportDate']);
        //     } else if (setter === 'employmentDetailSet') {
        //         replaceMainDateFromTmp(arrays[arrays.length - 1], ['EmploymentEndDate', 'ResignationDate']);
        //     } else if (setter === 'recentAuditResultSet') {
        //         replaceMainDateFromTmp(arrays[arrays.length - 1], ['ParaPlanningStartDate', 'ParaPlanningEndDate']);
        //     }
        // }
        // Refresh previous name when save by DatzMe101
        //= ===== Start of Code (Just in case of conflict) ========
        if (setter === 'adviserPreviousNameSet') {
          corporateService.adviserPreviousNameGet($scope.familyId, $scope.clientId).then(() => {
            $scope.adviserPreviousNameGet = response.data;
          });
        }
        //= ===== End of Code (Just in case of conflict) ========
        if (key === 'AdviserOrganizatioName') {
          if (arrays && arrays.length > 1) {
            $scope.loadContactUnderOrg(arrays[1]);
          }
        }

        if (key === CORPORATE_CONTACT_KEY.MYCRMACTIVE) {
          $scope.getAllAdviserDetails('Support Services');
        }
      });
    } else {
      wmComplianceService[setter].apply(null, arrays).then((response) => {
        if (response.status === 200) {
          toaster.pop('success', 'Update', 'successfully updated!');
        }
      });
    }
    toaster.clear();
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.updateAdviserDetails = function (setterType, corpAdviserDetailObj, key, field, saveType, adviserFormName, adviserInputName) {
    let dataArray = [];
    let obj = corpAdviserDetailObj;
    if (adviserFormName && adviserInputName) {
      if (adviserFormName[adviserInputName].$invalid) {
        toaster.pop('error', 'Error', 'Invalid date format, it must be dd-MM-yyyy or dd/MM/yyyy!');
        return false;
      }
      if (!obj[key]) {
        obj[key] = '1900-01-01';
      } else {
        obj[key] = obj[key].toDateString();
      }
    }
    if (setterType === 'contactSet') {
      dataArray = [$scope.saveType[saveType], obj];
      // set array
      obj.BrokerStatusId = _getArrValue(null, obj.BrokerStatusName, 'BrokerStatusId', 'BrokerStatusName', $scope.brokerStatusLists);
      // reporting country
      obj.ReportingCountryId = _getArrValue(null, obj.ReportingCountryCode, 'CountryId', 'CountryCode', $scope.operatingCountries);
      obj.ReportingCountryName = _getArrValue(obj.ReportingCountryId, null, 'CountryId', 'CountryName', $scope.operatingCountries);
      obj.ReportingCountryCode = _getArrValue(obj.ReportingCountryId, null, 'CountryId', 'CountryCode', $scope.operatingCountries);
      obj.ReportingCountryPhoneCode = _getArrValue(obj.ReportingCountryId, null, 'CountryId', 'CallingCode', $scope.operatingCountries);
      // commission
      obj.CommissionTierId = _getArrValue(null, obj.CommissionTierName, 'CommissionTierId', 'CommissionTierName', $scope.commissionTiers);
      obj.CommissionTierName = _getArrValue(obj.CommissionTierId, null, 'CommissionTierId', 'CommissionTierName', $scope.commissionTiers);
      // industrial achievement
      obj.IndividualAchievementId = _getArrValue(null, obj.IndividualAchievementName, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
      obj.IndividualAchievementName = _getArrValue(obj.IndividualAchievementId, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
      // enquiry source
      obj.EnquirySourceId = _getArrValue(null, obj.EnquirySourceName, 'EnquirySourceId', 'EnquirySourceName', $scope.enquirySources);
      obj.EnquirySourceName = _getArrValue(obj.EnquirySourceId, null, 'EnquirySourceId', 'EnquirySourceName', $scope.enquirySources);
      // reporting state
      obj.ReportingStateId = _getArrValue(null, obj.ReportingStateName, 'ReportingStateId', 'ReportingStateName', $scope.reportingStates);
      obj.ReportingStateName = _getArrValue(obj.ReportingStateId, null, 'ReportingStateId', 'ReportingStateName', $scope.reportingStates);
      // reporting regiton
      obj.ReportingRegionId = _getArrValue(null, obj.ReportingRegionName, 'ReportingRegionId', 'ReportingRegionName', $scope.reportingRegions);
      obj.ReportingRegionName = _getArrValue(obj.ReportingRegionId, null, 'ReportingRegionId', 'ReportingRegionName', $scope.reportingRegions);
      // business data
      obj.BusinessUnitID = _getArrValue(null, obj.BusinessUnitName, 'BusinessUnitID', 'BusinessUnitName', $scope.business_units);
      obj.BusinessUnitName = _getArrValue(obj.BusinessUnitID, null, 'BusinessUnitID', 'BusinessUnitName', $scope.business_units);
      if (key === 'ReqdTeamAcessLevelName') {
        obj.ReqdTeamAcessLevel = _getArrValue(null, obj.ReqdTeamAcessLevelName, 'id', 'value', $scope.level_accessList);
        obj.ReqdTeamAcessLevelName = _getArrValue(obj.ReqdTeamAcessLevel, null, 'id', 'value', $scope.level_accessList);
      }
      if (obj.AccountOwner_FullName) {
        if (typeof (obj.AccountOwner_FullName) === 'object') {
          obj.AccountOwner_FullName = obj.AccountOwner_FullName.originalObject.FullName;
          obj.AccountOwner_FamilyId = _getArrValue(null, obj.AccountOwner_FullName, 'FamilyId', 'FullName', $scope.adminUsers);
          obj.AccountOwner_FullNameTmp = _getArrValue(obj.AccountOwner_FamilyId, null, 'FamilyId', 'FullName', $scope.adminUsers);
        }
      } else {
        obj.AccountOwner_FullName = '';
        obj.AccountOwner_FamilyId = 0;
        obj.AccountOwner_FullNameTmp = '';
      }
      if (obj.BrokerReferredByTmp) {
        obj.BrokerReferredBy = obj.BrokerReferredByTmp.originalObject.FullName;
      }
      // gender
      obj.Gender = _getArrValue(null, obj.GenderName, 'id', 'value', $scope.genderList);
      obj.GenderName = _getArrValue(obj.Gender, null, 'id', 'value', $scope.genderList);
      // symmerty
      obj.SymmetryUserID = _setBooleantoInt(obj.SymmetryUserID);
      obj.BrandingCategoryId = _getArrValue(null, obj.BrandingCategory, 'BrandingCategoryId', 'BrandingCategory', $scope.brandingCategoryListGet);
      obj.BrandingCategory = _getArrValue(obj.BrandingCategoryId, null, 'BrandingCategoryId', 'BrandingCategory', $scope.brandingCategoryListGet);
      // Business Details
      const contactRoleName = [];
      const contactRoleId = [];
      angular.forEach(obj.ContactTypeRoleName, (value) => {
        contactRoleName.push(value.ContactTypeRoleDesc);
        contactRoleId.push(value.ContactTypeRoleId);
      });
      if (field === 'ContactTypeRoleNameValue') {
        obj.RoleId = contactRoleId.length > 0 ? contactRoleId.join(',') : '';
        obj.ContactTypeRoleNameValue = contactRoleName.length > 0 ? contactRoleName.join(', ') : '';
      }
      if (key === 'ReceiveRecruitNewsLetter') {
        obj.ReceiveRecruitNewsLetter = _setBooleantoInt(obj.ReceiveRecruitNewsLetter);
      }
      if (key === 'ClientTypeNames') {
        const ClientTypeName = [];
        const ClientTypeId = [];
        angular.forEach(obj.ClientTypeNameArr, (value) => {
          ClientTypeName.push(value.ClientTypeName);
          ClientTypeId.push(value.ClientTypeID);
        });
        obj.ClientTypeNames = ClientTypeName.length > 0 ? ClientTypeName.join(',  ') : '';
        obj.ClientTypeIDs = ClientTypeId.length > 0 ? ClientTypeId.join(',') : '';
      }
      if (key === 'MyCRMActive') {
        obj.myCRMActiveIsUpdated = (key === 'MyCRMActive');
      }
      _querySet(setterType, key, field, dataArray, true);
    } else if (setterType === 'familyBrokerSupportSet') {
      const brokerSupportObjKeyValue = commonFnService.getAllObjectFieldKey(setterType);
      obj.FamilyId = obj.FamilyId ? obj.FamilyId : $scope.familyId;
      if (key === brokerSupportObjKeyValue.mortgageToolAccessName) {
        obj.MortgageToolAccess = Number($scope.mortageToolsArr.indexOf(obj.MortgageToolAccessName));
      }
      if (key === brokerSupportObjKeyValue.insuranceToolsAccessName) {
        obj.InsuranceToolsAccess = $scope.mortageToolsArr.indexOf(obj.InsuranceToolsAccessName);
      }
      if (key === brokerSupportObjKeyValue.fnGInsurance) {
        obj.FnGInsurance = _setBooleantoInt(obj.FnGInsurance);
      }
      if (key === brokerSupportObjKeyValue.accessTypeName) {
        obj.AccessTypeID = queryService.getArrValue(null, obj.AccessTypeName, 'AccessTypeId', 'AccessType', $scope.accessTypesArr);
      }
      if (key === brokerSupportObjKeyValue.offshoring) {
        obj.Offshoring = _setBooleantoInt(obj.Offshoring);
      }
      if (key === brokerSupportObjKeyValue.micAccess) {
        obj.MICAccess = _setInttoBoolean(obj.MICAccess);
      }
      if (key === brokerSupportObjKeyValue.agreementSigned) {
        obj.AgreementSigned = _setBooleantoInt(obj.AgreementSigned);
      }
      if (key === brokerSupportObjKeyValue.customerContact) {
        obj.CustomerContact = _setBooleantoInt(obj.CustomerContact);
      }
      if (key === brokerSupportObjKeyValue.campaignBreezeEnabled) {
        if (brokerSupportObjKeyValue.campaignBreezeEnabled) {
          obj.CampaignBreezeEnabledDate = new Date();
        }
      }

      if (key === 'UserTypeName') {
        obj.UserTypeId = queryService.getArrValue(null, obj.UserTypeName, 'UserTypeId', 'UserType', $scope.userTypes);
      }
      if (key === 'BrokerFeeName') {
        obj.BrokerFeeId = queryService.getArrValue(null, obj.BrokerFeeName, 'BrokerFeeId', 'BrokerFee', $scope.brokerFees);
      }
      obj.FeesPaidById = _getArrValue(null, obj.FeesPaidByName, 'id', 'name', $scope.feesPaidBy);
      obj.CS2017ID = _getArrValue(null, obj.CS2017Name, 'ID', 'CSDesc', $scope.confSponsorship2017Gets);
      obj.CS2017Name = _getArrValue(obj.CS2017ID, null, 'ID', 'CSDesc', $scope.confSponsorship2017Gets);
      if (obj.OfficerFullName) {
        if (typeof (obj.OfficerFullName) === 'object') {
          obj.OfficerFullName = obj.OfficerFullName.originalObject.FullName;
          obj.OfficerFamilyID = _getArrValue(null, obj.OfficerFullName, 'FamilyId', 'FullName', $scope.adminUsers);
          obj.OfficerFullNameTmp = _getArrValue(obj.OfficerFamilyID, null, 'FamilyId', 'FullName', $scope.adminUsers);
        }
      } else {
        obj.OfficerFullName = '';
        obj.OfficerFamilyID = 0;
        obj.OfficerFullNameTmp = '';
      }
      dataArray = [$scope.familyId, $scope.clientId, obj];
      _querySet(setterType, key, field, dataArray, true);
    } else if (setterType === 'familyAchievementSet') {
      if (key === 'AdviserAchievementName') {
        $scope.currentYearAchievementID = _getArrValue(null, obj, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
        obj = _getArrValue($scope.currentYearAchievementID, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
        dataArray = [$scope.familyId, $scope.currentYearAchievementID, $scope.currrentYear];
      }
      if (key === 'AdviserAchievementNamePrevYear') {
        $scope.previousYearAchievementID = _getArrValue(null, obj, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsPreviousYear);
        obj = _getArrValue($scope.previousYearAchievementID, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsPreviousYear);
        dataArray = [$scope.familyId, $scope.previousYearAchievementID, $scope.previousYear];
      }
      _querySet(setterType, key, field, dataArray, true);
    }
  };
  $scope.SetAdviserAchievement = function (model, name) {
    if (name === 'AdviserAchievementName') {
      $scope.AdviserAchievementName = model;
    } else {
      $scope.AdviserAchievementNamePrevYear = model;
    }
  };
  $scope.setCommissionAccess = (commissionAccess, id) => {
    if (!id) {
      contactService.commissionAccessSet(commissionAccess.FamilyID, $scope.familyId, commissionAccess.HasCommissionAccess);
      return false;
    }
    if (commissionAccess && commissionAccess.BrokerFullNameTmp) {
      commissionAccess.BrokerFullName = commissionAccess.BrokerFullNameTmp.originalObject.FullName;
      contactService.commissionAccessSet(commissionAccess.BrokerFullNameTmp.originalObject.FamilyId, $scope.familyId, 0)
        .then((response) => {
          if (response) {
            $scope.showAddField.show = false;
            getCommissionAccess();
            $scope.commissionAccessArr = [];
            commissionAccess.BrokerFullNameTmp = {};
            commissionAccess.isActive = false;
            /* this is based on angucomplet doc
            * id - id of angucomplete
            * src: https://github.com/ghiden/angucomplete-alt
            */
            $scope.$broadcast('angucomplete-alt:clearInput', id);
          }
        });
    } else {
      toaster.pop('error', 'Field required!', 'Adviser field');
    }
  };
  $scope.cancelCommissionAccess = (commissionAccess) => {
    $scope.showAddField.show = false;
    if (commissionAccess) {
      commissionAccess.BrokerFullNameTmp = null;
      commissionAccess.isActive = false;
    }
  };
  $scope.deleteCommissionAccess = (commissionAccess) => {
    contactService.brokerAccessDelete(commissionAccess.FamilyID, $scope.familyId)
      .then((response) => {
        if (response) {
          $scope.commissionAccessArr = [];
          getCommissionAccess();
        }
      });
  };
  $scope.DateChangeValue = function (key, setterType) {
    if (setterType === 'employmentDetailSet') {
      if ($scope.wmEmploymentComplianceDetails[key] instanceof Date) {
        $scope.wmEmploymentComplianceDetails[key] = $scope.formatDate($scope.wmEmploymentComplianceDetails[key]);
      }
    }
  };
  $scope.pagination = {};
  $scope.pagination.page = 1;
  $scope.pagination.size = 10;
  $scope.pagination.total = 0;

  $scope.loadContactUnderOrg = function (adviserDetails) {
    $scope.contactsUnderOrganizationGet = [];
    if (adviserDetails.AdviserOrganizationId && parseInt(adviserDetails.AdviserOrganizationId, 10) > 0) {
      corporateService.contactsUnderOrganizationGet(adviserDetails.AdviserOrganizationId, $scope.pagination.page, $scope.pagination.size)
        .then((response) => {
          const contactUnderOrg = response.data;
          if (contactUnderOrg) {
            $scope.contactsUnderOrganizationGet = contactUnderOrg.Result;
            $scope.pagination.total = contactUnderOrg.Count;
          }
        });
    }
  };
  $scope.isMyCrmActive = function (setterType, obj, key, field, saveType) {
    if (key === 'MyCRMActive') {
      swal({
        title: 'Confirmation',
        text: 'Are you absolutely sure that this user has completed the verification process and is ready to move to MyCRM? This will impact all existing system integrations and this change cannot be reversed.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          $scope.updateAdviserDetails(setterType, obj, key, field, saveType, null);
          swal('Updated!', 'myCRM Active updated.', 'success');
        }
      });
    }
  };
  $scope.gotoContact = function (adviserMember) {
    const adviserGoToContactObj = {
      clientId: 0,
      familyId: adviserMember.FamilyId,
    };
    if (adviserMember.ContactType === 'Adviser' || adviserMember.ContactType === 'Principal Adviser') {
      $state.go('app.contactsAdviser', adviserGoToContactObj);
    } else if (adviserMember.ContactType === 'Assistant') {
      $state.go('app.contactsAssistant', adviserGoToContactObj);
    } else {
      $state.go('app.contactsSingle', {
        familyId: adviserMember.FamilyId,
      });
    }
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.EditAddressModal = function (type) {
    if (type === 'Business Address') {
      $scope.editBusinessAdd = !$scope.editBusinessAdd;
    } else if (type === 'Home Address') {
      $scope.editHomeAdd = !$scope.editHomeAdd;
    } else if (type === 'Mailing Address') {
      $scope.editMailingAdd = !$scope.editMailingAdd;
    }
  };
  $scope.SaveAddress = function (type) {
    if (type === 'Business Address') {
      corporateService.familyAddressSet($scope.familyId, 0, $scope.isBusiness[0].Formatted_address, false, true, false).then((response) => {
        if (response.status === 200) {
          // _loadAviserAddress();
          $scope.editBusinessAdd = false;
          toaster.pop('success', 'Successfully Updated', 'Address');
        }
      });
      // }
    } else if (type === 'Home Address') {
      //  comment for now for google api ref
      // if ($scope.googleAutoPlace) {
      //   corporateService.familyAddressSet($scope.familyId, 0, $scope.googleAutoPlace, false, false, false).then(function (response) {
      //       if (response.status === 200) {
      //         _loadAviserAddress();
      //         $scope.editHomeAdd = false;
      //         toaster.pop('success', 'Successfully Updated', 'Address');
      //       }
      //   });
      // } else {
      corporateService.familyAddressSet($scope.familyId, 0, $scope.isHomeAddress[0].Formatted_address, false, false, false).then((response) => {
        if (response.status === 200) {
          // _loadAviserAddress();
          $scope.editHomeAdd = false;
          toaster.pop('success', 'Successfully Updated', 'Address');
        }
      });
      // }
    } else if (type === 'Mailing Address') {
      //  comment for now for google api ref
      // if ($scope.googleAutoPlace) {
      //     corporateService.familyAddressSet($scope.familyId, 0, $scope.googleAutoPlace, true, false, false).then(function (response) {
      //         if (response.status === 200) {
      //            _loadAviserAddress();
      //           $scope.editMailingAdd = false;
      //           toaster.pop('success', 'Successfully Updated', 'Address');
      //         }
      //     });
      // } else {
      corporateService.familyAddressSet($scope.familyId, 0, $scope.isMailing[0].Formatted_address, true, false, false).then((response) => {
        if (response.status === 200) {
          // _loadAviserAddress();
          $scope.editMailingAdd = false;
          toaster.pop('success', 'Successfully Updated', 'Address');
        }
      });
      // }
    }
  };
  $scope.homeAutoAddresss = function (address) {
    generalService.placeSearch(address).then((respond) => {
      if (respond && respond.data) {
        $scope.homeAutoAddressDetails = respond.data;
      }
    });
  };
  $scope.selectHomeAddress = function (type, homeAddress) {
    if (type === 'Home Address') {
      $scope.isHomeAddress[0].Formatted_address = homeAddress;
    } else if (type === 'Mailing Address') {
      $scope.isMailing[0].Formatted_address = homeAddress;
    } else if (type === 'Business Address') {
      $scope.isBusiness[0].Formatted_address = homeAddress;
    }
    $scope.closeAutoHomeAddress();
  };
  $scope.closeAutoHomeAddress = function () {
    $scope.homeAutoAddressDetails = [];
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.hideAdviserAddress = function (type) {
    if (type === 'business') {
      $scope.isEditMode = false;
    } else if (type === 'home') {
      $scope.isMailingEditMode = false;
    }
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.setEditMode = function (type) {
    if (type === 'edit') {
      $scope.isEditMode = true;
    } else if (type === 'editHome') {
      $scope.isMailingEditMode = true;
    }
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.hideAdviserAddress = function (type) {
    if (type === 'business') {
      $scope.isEditMode = false;
    } else if (type === 'home') {
      $scope.isMailingEditMode = false;
    }
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.setStatus = function (name, setterType, obj, saveType) {
    let key;
    let field;
    let error = false;
    obj.IsActive = null;
    if (setterType === 'contactSet') {
      if (name === 'Terminated by LM' || name === 'Active' || name === 'Ceased by Adviser' || name === 'Suspended') {
        if ($scope.selected_adviser_org.length > 0 && typeof $scope.selected_adviser_org[0].AdviserOrganizationId !== 'undefined' && $scope.selected_adviser_org[0].AdviserOrganizationId > 0) {
          error = false;
          $scope.adviserDetails.BrokerStatusName = name;
          key = 'BrokerStatusName';
          field = key;
        } else {
          swal('Warning!', 'Please check that there is an Adviser Organization for this Adviser before changing status', 'warning');
          error = true;
        }
      } else if (name === 'Prospect' || name === 'Onboarding') {
        error = true;
        corporateService.checkIfFamilyHasValidEmailGet($scope.familyId, $scope.clientId).then((response) => {
          if (response.data !== 1) {
            error = true;
            toaster.pop('error', 'Error', 'Email is not valid!');
          } else {
            $scope.adviserDetails.BrokerStatusName = name;
            key = 'BrokerStatusName';
            field = key;
            $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
          }
        });
      } else {
        error = false;
        $scope.adviserDetails.BrokerStatusName = name;
        key = 'BrokerStatusName';
        field = key;
      }
    } else if (setterType === 'leadsProfileSet') {
      $scope.leadsProfileGet.ReceiveStatusName = name.LeadReceiveStatus;
      key = 'ReceiveStatusName';
      field = key;
      $scope.leadsProfileGet.ReceiveStatus = name.LeadReceiveStatusId;
      key = 'ReceiveStatus';
      field = key;
    } else if (setterType === 'appointmentDetailSet') {
      $scope.wmComplianceDetails.AuthLevel = name.name;
      key = 'AuthLevel';
      field = key;
      $scope.wmComplianceDetails.AuthLevelId = name.id;
      key = 'AuthLevelId';
      field = key;
    } else if (setterType === 'recentAuditResultSet') {
      $scope.wmRecentAudit.AuditRating = name.name;
      key = 'AuditRating';
      field = key;
    } else if (setterType === 'stdPreVetSet') {
      $scope.wmstdPreVetGet.PreVetStatus = name.name;
      key = 'PreVetStatus';
      field = key;
    }
    if (!error) {
      $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
    }
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.selectedAdviser = function (setterType, key, selectedAdviserOrg, obj, field, saveType) {
    const restrictedCountry = [COUNTRY_TYPE.AUSTRALIA];
    corporateService.corporateContactGet($scope.clientId, $scope.familyId).then((response) => {
      const isActiveAdviser = response && response.data && response.data.BrokerStatusId === ADVISER_STATUS.ACTIVE;
      const isRestrictedCountry = restrictedCountry.indexOf($scope.userReportingCountryId) !== -1;
      if (isActiveAdviser && isRestrictedCountry) {
        swal('Transfer Error', 'Transfers from one Adviser Org to another need to be managed manually by the data team. Please contact mycrmsupport@loanmarket.com.au', 'error');
        if (!$scope.adviserDetails || !$scope.adviserDetails.AdviserOrganizationId) return;
        $scope.getAdviserOrgLists($scope.adviserDetails.AdviserOrganizationId);
      } else {
        obj.AdviserOrganizationId = selectedAdviserOrg[0].AdviserOrganizationId;
        $scope.selectedAdviserOrg = selectedAdviserOrg;
        $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
        $scope.selected_adviser_org = selectedAdviserOrg;
      }
    });
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.selectedAppoinmentEntity = function (setterType, key, selectedAdviserOrg, obj, field, saveType) {
    obj.EntityName = selectedAdviserOrg[0].AdviserOrganizatioName;
    obj.EntityOrgId = selectedAdviserOrg[0].AdviserOrganizationId;
    $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.selectedRole = function (setterType, key, selectedObj, obj, field, saveType) {
    const tmp = [];
    angular.forEach(selectedObj, (selectedObjValue) => {
      tmp.push(selectedObjValue.ContactTypeRoleId);
    });
    obj.RoleId = tmp.join(',');
    $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.selectContacts = function (selectedContacts) {
    $timeout(() => {
      const el = $window.$document.querySelectorAll(`#${selectedContacts} .nav-link`);
      angular.element(el).triggerHandler('click');
    }, 0);
    $scope.selectedContacts = selectedContacts;
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.getAdviserOrgLists = function (id) {
    dataService.getAdviserOrgLists(id).then((response) => {
      if (response) {
        $scope.selected_adviser_org = response.selected;
        $scope.adviser_org = response.data;
        $scope.isEmptyOrg = response.isEmptyOrg;
      }
    });
  };
  $scope.ConvertPipeToCSV = function (str) {
    if (str) {
      return str.replace(/\|/g, ', ');
    }
    return str;
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  // Add Client
  //-------------------------------------------------------------
  $scope.getContactFamilyInfo = {};
  $scope.contactFamilyInfoGet = function () {
    contactService.contactFamilyInfoGet($scope.familyId).then((response) => {
      $scope.getContactFamilyInfo = response.data;
      $scope.contactInfoSetValue = response.data;
      $scope.contactInfoSetValue.FamilyId = $scope.familyId;
      $scope.optionReferenceCode = [];
      $scope.contactAddress = {};
      $scope.contactAddress.Home = '';
      $scope.contactAddress.HomeLatLng = undefined;
      if ($scope.getContactFamilyInfo.ReferenceCode) {
        $scope.optionReferenceCode = [{
          text: $scope.getContactFamilyInfo.ReferenceCode,
        }];
      }
      $scope.clientTypeTags = [];
      $scope.categoriesTags = [];
      angular.forEach($scope.getContactFamilyInfo.ClientTypes, (value) => {
        $scope.clientTypeTags.push({
          text: value,
        });
      });
      angular.forEach($scope.getContactFamilyInfo.Categories, (value) => {
        $scope.categoriesTags.push({
          text: value,
        });
      });
      angular.forEach($scope.getContactFamilyInfo.Rating, (value) => {
        $scope.ratingTags.push({
          text: value,
        });
      });
    });
  };
  $scope.contactFamilyInfoGet();
  $scope.clientTypeTags = [];
  $scope.categoriesTags = [];
  angular.forEach($scope.getContactFamilyInfo.ClientTypes, (value) => {
    $scope.clientTypeTags.push({
      text: value,
    });
  });
  angular.forEach($scope.getContactFamilyInfo.Categories, (value) => {
    $scope.categoriesTags.push({
      text: value,
    });
  });
  angular.forEach($scope.getContactFamilyInfo.Rating, (value) => {
    $scope.ratingTags.push({
      text: value,
    });
  });
  // Client Type
  $scope.openClientTypeModal = function (size) {
    $uibModal.open({
      templateUrl: '/assets/views/contacts/partials/client_type_modal.html',
      scope: $scope,
      controller($uibModalInstance) {
        $scope.clientTypeModalType = 'Select';
        $scope.isNewClientTypeVisible = false;
        $scope.newClientTypeList = [];
        $scope.existingClientTypeNameList = [];
        $scope.isClientTypePresent = function (clientTypeName) {
          return $scope.getContactFamilyInfo.ClientTypes.indexOf(clientTypeName) !== -1;
        };

        function getClientType() {
          contactService.getClientType().then((response) => {
            $scope.existingClientTypeNameList = [];
            const listOfClientType = [];
            angular.forEach(response.data, (value) => {
              if (listOfClientType.indexOf(value.ClientTypeName) === -1 && value.ClientTypeName !== '') {
                listOfClientType.push(value.ClientTypeName);
                $scope.existingClientTypeNameList.push(value);
              }
            });
          }, () => {});
        }
        $scope.setCorporate = function (clientTypeId, isExclusiveForCorpUser, positionIndex) {
          $scope.filteredItems[positionIndex].isExclusiveForCorpUser = !(typeof isExclusiveForCorpUser === 'undefined' ? false : isExclusiveForCorpUser);
          contactService.setClientTypeCorpUser(clientTypeId, !(typeof isExclusiveForCorpUser === 'undefined' ? false : isExclusiveForCorpUser)).then(() => {});
        };
        $scope.addClientType = function () {
          $scope.isNewClientTypeVisible = true;
          const newClientType = {
            ClientTypeID: Math.random(),
            ClientTypeName: '',
            IsChecked: true,
          };
          $scope.newClientTypeList.push(newClientType);
        };
        $scope.deleteClientType = function (clientTypeID) {
          swal({
            title: 'Are you sure?',
            text: 'This record will be removed from your client type list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          }, (confirm) => {
            if (confirm) {
              contactService.deleteClientType(clientTypeID).then(() => {
                getClientType();
                // swal("Deleted", "Client Type has been deleted.", "success");
                toaster.pop('success', 'Deleted', 'Client Type has been successfully deleted');
              }, (e) => {
                swal('Yikes! Something is wrong', e.data.Message, 'error');
              });
            }
          });
        };
        $scope.saveClientType = function (clientType) {
          if (clientType.ClientTypeName === '') {
            swal('Empty Name', 'Client Type Name cannot be empty.', 'error');
            return;
          }
          contactService.addClientType(clientType.ClientTypeName).then(() => {
            const index = $scope.newClientTypeList.indexOf(clientType);
            if (index > -1) $scope.newClientTypeList.splice(index, 1);
            getClientType();
            // swal("Added", "Client Type has been Added Successfully.", "success");
            toaster.pop('success', 'Added', 'Client Type has been successfully added');
          }, (e) => {
            swal('Yikes! Something is wrong', e.data.Message, 'error');
          });
          $scope.contactFamilyInfoGet();
        };
        $scope.clientTypeCheckedAdd = function (clientType, checked) {
          if (checked) {
            $scope.contactInfoSetValue.ClientTypes.push(clientType);
          } else {
            const clientTypeIndex = $scope.contactInfoSetValue.ClientTypes.indexOf(clientType);
            $scope.contactInfoSetValue.ClientTypes.splice(clientTypeIndex, 1);
          }
        };
        $scope.hideEditButton = function (clientType) {
          clientType.IsEditMode = true;
        };
        $scope.updateClientType = function (currentlyEditedClientType) {
          if (currentlyEditedClientType.ClientTypeName === '') {
            swal('Empty Name', 'Client Type Name cannot be empty.', 'error');
            return;
          }
          currentlyEditedClientType.IsEditMode = false;
          contactService.updateClientType(currentlyEditedClientType.ClientTypeID, currentlyEditedClientType.ClientTypeName).then(() => {
            // swal("Updated", "Client Type has been Updated Successfully.", "success");
            toaster.pop('success', 'Updated', 'Client Type has been successfully updated');
          }, (e) => {
            swal('Yikes! Something is wrong', e.data.Message, 'error');
          });
        };
        function reorder(clientTypeID, orderBy) {
          contactService.updateOrder(clientTypeID, orderBy).then(() => {
            getClientType();
          });
        }
        $scope.reorderUp = function (clientType, index) {
          if (index > 0) {
            const itemAbove = $scope.filteredItems[$scope.filteredItems.indexOf(clientType) - 1];
            reorder(clientType.ClientTypeID, itemAbove.OrderBy);
          }
        };
        $scope.reorderDown = function (clientType, index) {
          if (index + 1 < $scope.existingClientTypeNameList.length) {
            const itemBelow = $scope.filteredItems[$scope.filteredItems.indexOf(clientType) + 1];
            reorder(clientType.ClientTypeID, itemBelow.OrderBy);
          }
        };
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
        $scope.done = function () {
          contactService.contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue)).then(() => {
            $scope.contactFamilyInfoGet();
            toaster.pop('success', 'Updated', 'Client Type successfully updated');
          });
          // todo: checkedClientTypes contains client types which are checked
          $scope.existingClientTypeNameList.filter((item) => {
            return item.IsChecked ? item : '';
          });
          $uibModalInstance.dismiss('cancel');
        };

        function init() {
          getClientType();
        }
        init();
      },
      size,
    });
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.getAppointmentLists = function (id) {
    corporateService.adviserOrganizationListGet().then((adviserOrg) => {
      $scope.appointment_entity = [];
      $scope.selected_appointment = [];
      $scope.isEmptyOrg = true;
      angular.forEach(adviserOrg.data, (value) => {
        if (value.AdviserOrganizatioName !== '') {
          value.icon = '<i class="fa fa-cog violet-color"></i>';
          if (parseInt(id, 10) === parseInt(value.AdviserOrganizationId, 10)) {
            value.ticked = true;
            $scope.selected_appointment.push(value);
            $scope.isEmptyOrg = false;
          } else {
            value.ticked = false;
          }
          if (value.ticked) {
            $scope.appointment_entity.unshift(value);
          } else {
            $scope.appointment_entity.push(value);
          }
        }
      });
      if ($scope.isEmptyOrg) {
        const data = {
          AdviserOrganizationId: 0,
          AdviserOrganizatioName: 'Please Select Adviser Org',
          AddressId: 0,
          Phone: '',
          Fax: '',
          ticked: true,
        };
        data.icon = '<i class="fa fa-cog violet-color"></i>';
        $scope.appointment_entity.unshift(data);
        $scope.selected_appointment = $scope.appointment_entity[0];
      }
    });
  };
  //* *****
  // Private
  // Function : Update all adviser details
  // @params
  // @return
  //* ****
  $scope.formatDate = function (date) {
    return new Date(date);
  };
  // Function call
  initialize();
  const currentState = {
    state: 'app.contactsAdviser',
    params: {
      clientId: $scope.clientId,
      familyId: $scope.familyId,
    },
  };
  $scope.editAgreement = function (agreementId) {
    referralsService.getReferrerOrgSummary(agreementId).then((response) => {
      $window.sessionStorage.setItem('currentState', JSON.stringify(currentState));
      let orgTypeId;
      if (response.data.AgreementTYpeID === AGREEMENT_TYPE.THIRD_PARTY) {
        orgTypeId = response.data.OrganisationTypeId;
      } else {
        orgTypeId = response.data.AgreementTYpeID === AGREEMENT_TYPE.RAY_WHITE ? 4 : 0;
      }
      const agreementTypeId = response.data.AgreementTYpeID;
      $state.go('app.newReferral', {
        referralType: orgTypeId,
        agreementId,
        agreementTypeId,
        requestId: 1,
      });
    });
  };
  $scope.addAgreementAdviser = (referralType) => {
    referralAgreementService.launchNewReferralModal(referralType, currentState, $scope);
  };
  $scope.isAddDirector = false;
  $scope.director = {};
  $scope.saveDirector = function () {
    if (typeof $scope.director.name !== 'undefined' || $scope.director.name !== '') {
      const adviserDirectorData = {
        DirectorId: 0,
        Name: $scope.director.name || '',
        Contact: $scope.director.number || '',
        Email: $scope.director.email || '',
      };
      corporateService.referrerOrgDirectorSet($scope.referrerOrgId, adviserDirectorData).then((response) => {
        if (response.data > 0) {
          $scope.cancelDirector();
          _queryGet('referrerOrgGet', [$scope.referrerComplianceId, $scope.referrerOrgId]);
          swal('Success', 'Successfully added the director', 'success');
        }
      });
    }
  };
  $scope.addDirector = function () {
    $scope.isAddDirector = true;
  };
  $scope.cancelDirector = function () {
    $scope.director = {};
    $scope.isAddDirector = false;
  };
  $scope.isDownloading = false;
  $scope.generateAccreditationPack = function () {
    $scope.isDownloading = true;
    corporateService.getAccreditationFormPdf($scope.familyId).then((response) => {
      const eventTimeout = $timeout(() => {
        const blob = new Blob([response.data], {
          type: 'application/pdf',
        });
        const downloadLink = angular.element('<a></a>');
        downloadLink.attr('href', $window.URL.createObjectURL(blob));
        downloadLink.attr('download', 'accreditation.pdf');
        downloadLink[0].click();
        $scope.isDownloading = false;
        $timeout.cancel(eventTimeout);
      });
    });
  };
  $scope.downloadDocuSign = function (agreementId) {
    corporateService.getAgreementDocument(agreementId).then((getAgreementDocumentResponse) => {
      const pdfAsDataUri = `data:application/pdf;base64,${getAgreementDocumentResponse.data.DocumentContent}`;
      $window.open(pdfAsDataUri, '_blank');
    });
  };
  $scope.sendDocuSign = function (adviserAgreementId) {
    $scope.isAdviserDownloadingAgreement = true;
    corporateService.setSendDocuSignAgreementToRecipients(adviserAgreementId).then((docuSignAgreementResponse) => {
      if (docuSignAgreementResponse.data > 0) {
        $scope.isAdviserDownloadingAgreement = false;
        swal('Success', 'Docusign has been sent through email.', 'success');
      }
    });
  };
  $scope.isSendingAccreditation = false;
  $scope.sendAccreditationEmail = function () {
    $scope.isSendingAccreditation = true;
    corporateService.setSendAccreditationEmail($scope.familyId).then((response) => {
      $scope.isSendingAccreditation = false;
      if (response.data > 0) {
        swal('Success', 'Accreditation email has been sent.', 'success');
      }
    });
  };
  $scope.openEmailManagement = function () {
    $uibModal.open({
      templateUrl: 'assets/views/corporate/emailManagement/emailManagement.html',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      controller: 'EmailManagementCtrl',
    });
  };
  // Prototypes
  $scope.deleteContact = function () {
    swal({
      title: 'Are you sure?',
      text: 'This contact will be removed from your list',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, remove it!',
      closeOnConfirm: false,
    }, (isConfirm) => {
      if (isConfirm) {
        contactService.deleteContact($scope.familyId).then(() => {
          swal('Deleted', 'Contact has been deleted.', 'success');
          $state.go('app.contacts');
        }, (e) => {
          swal('Yikes! Something is wrong', e.data.Message, 'error');
        });
      }
    });
  };
  $scope.getNpsScore = (score) => {
    return score ? score.toFixed(0) : 0;
  };
  $scope.isDateValid = function (date) {
    const d = new Date(date);
    return parseInt(d.getFullYear(), 10) > 1;
  };
  $scope.converCSVToArray = function (data) {
    return data.split(',');
  };
  $scope.convertToDate = function (date) {
    return new Date(date);
  };

  $scope.stringToProperCase = function (adviserStringValue) {
    return adviserStringValue && adviserStringValue.charAt(0).toUpperCase() + adviserStringValue.substring(1, adviserStringValue.length).toLowerCase();
  };
  $scope.getDateFormatInView = (date) => {
    return dataService.setDateFormatInView(date);
  };
  const getAdviserSubscriptionsPermission = () => {
    $scope.subscriptionEnabled = false;
    const pageName = 'AdviserContact';
    const tabName = 'Subscriptions';
    corporatePermissionService.isPermissionReadAndWrite(pageName, tabName)
      .then((response) => {
        $scope.subscriptionEnabled = response;
      });
  };
  getAdviserSubscriptionsPermission();
});
