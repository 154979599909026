import swal from 'sweetalert';

class CreditCardService {
  constructor() {
    'ngInject';

    this.valiCardNumber = true;
    this.creditCardList = {
      americanCArd: {
        label: 'American Express',
        value: 1,
        regex: '^(?:3[47][0-9]{13})$',
        masked: '999 9999 9999 9999',
        svg: 'americanExpressCard',
      },
      bankCard: {
        label: 'Bank Card',
        value: 2,
        regex: '^(6541|6556)[0-9]{12}$',
        masked: '999 9999 9999 9999',
        svg: 'bankCard',
      },
      dinersClubCard: {
        label: 'Diners Club',
        value: 3,
        regex: '^3(?:0[0-5]|[68][0-9])[0-9]{11}$',
        masked: '9999 9999 9999 99',
        svg: 'dinersClubCard',
      },
      masterCard: {
        label: 'Master Card',
        value: 4,
        regex: '^(?:5[1-5][0-9]{14})$',
        masked: '9999 9999 9999 9999',
        svg: 'masterCard',
      },
      otherCard: {
        label: 'Other Card',
        value: 5,
        regex: false,
        masked: null,
        svg: 'otherCard',
      },
      visaCard: {
        label: 'Visa Card',
        value: 6,
        regex: '^(?:4[0-9]{12}(?:[0-9]{3})?)$',
        masked: '9999 9999 9999 9999',
        svg: 'visaCard',
      },
    };
  }
  creditCardType(cardType) {
    let findCreditCardObj = [];
    Object.keys(this.creditCardList).forEach((key) => {
      if (this.creditCardList[key].value === cardType) {
        findCreditCardObj = this.creditCardList[key];
      }
    });
    return findCreditCardObj;
  }
  checkCreditCardNumber(cardNUmber, Regex) {
    const regex = new RegExp(Regex);
    const creditCardValue = cardNUmber.replace(/\s/g, '').match(regex);
    return this.validateCreditCardNumber(creditCardValue);
  }
  validateCreditCardNumber(carNumber) {
    this.validCardNumber = Array.isArray(carNumber);
    if (!this.validCardNumber) {
      swal('Invalid credit card number', 'Please input valid credit card number.', 'error');
    }
    return this.validCardNumber;
  }
}

export default CreditCardService;
