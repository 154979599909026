import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

angular.module('app').controller('MyDocumentsCtrl', function MyDocumentsCtrl(
  $uibModal,
  configService,
  loanToolsService,
  contactService,
  toaster,
  $timeout
) {
  const vm = this;
  /*
  * @desc Sample Document Model
  */
  vm.documentsList = [];
  vm.uploadMyDocumentDroplet = [];
  vm.successUploadResponse = [];
  vm.userInfo = [];
  vm.searchDocumentFile = [];

  vm.isProgressBarVisible = false;
  vm.hideDocumentNotification = true;

  function closeNotif() {
    vm.hideDocumentNotification = false;
  }

  function openSearch() {
    vm.isActiveSearch = true;
  }

  function closeSearch() {
    vm.isActiveSearch = false;
  }

  function searchDocumentCall(searchStr) {
    $timeout.cancel(vm.searchTimeout);
    vm.searchTimeout = $timeout(() => {
      vm.documentsList = null;
      loanToolsService.resourceMyDocumentsSearch(searchStr)
        .then((response) => {
          vm.documentsList = response;
        });
    }, 2000);
  }

  /*
     @name openUploadedDocumentModal
     @desc addDocument, a modal, basically just upload the file, no setting of document
     @param size = string, isAdd = boolean, docFileIdForOpeningEdit = int
     @return none
 */
  function openUploadedDocumentModal(size, isAdd, docFileIdForOpeningEdit) {
    const modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: '/assets/views/modals/resource/uploadDocumentModal.html',
      controller: 'MyDocumentsModalCtrl',
      controllerAs: 'modal',
      windowClass: 'upload-mydocuments-modal-class',
      size,
      resolve: {
        successUploadResponse: () => {
          let toReturnVal;

          if (!isAdd) {
            toReturnVal = docFileIdForOpeningEdit;
          } else {
            toReturnVal = vm.successUploadResponse;
          }

          return toReturnVal;
        },
        userInfo: () => {
          return vm.userInfo;
        },
      },
    });

    modalInstance.result.then((newSetOfDocuments) => {
      vm.documentsList = null;
      vm.documentsList = newSetOfDocuments;
    }, () => {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  }
  /*
 * @name editDocument
 * @desc show edit modal
 * @param document object
 * @return
 */
  function editDocument(documentFileId) {
    openUploadedDocumentModal('lg', false, documentFileId);
  }

  function populateDocumentsList() {
    vm.documentsList = null;

    loanToolsService.resourceMyDocumentsGet(0)
      .then((response) => {
        if (!response) return;

        vm.documentsList = response;
      });
  }
  // DROPLET FUNCTIONS AREA
  /*
     @name DROPLET
     @desc FOR UPLOADING DOCUMENT
     @param VARIES
     @return none
 */
  function toUploadReady() {
    vm.uploadMyDocumentDroplet.allowedExtensions(['pdf', 'docx', 'doc', 'odt', 'xls', 'xlsx', 'ods', 'odp', 'ppt', 'pptx', 'jpg', 'jpeg', 'png']);
    vm.uploadMyDocumentDroplet.setRequestUrl(`${configService.resource}/contacts/DocumentUpload`);
    vm.uploadMyDocumentDroplet.setRequestHeaders({ Authorization: configService.token });
    vm.uploadMyDocumentDroplet.defineHTTPSuccess([/2.{2}/]);
    vm.uploadMyDocumentDroplet.useArray(false);
  }

  function toAddUpload(file) {
    if (file.type === vm.uploadMyDocumentDroplet.FILE_TYPES.INVALID) {
      toaster.pop('error', 'Invalid', 'Bad file type added');
    } else {
      vm.isProgressBarVisible = true;
      vm.uploadMyDocumentDroplet.uploadFiles();
    }
  }

  function successUpload(response) {
    vm.successUploadResponse = response;
    openUploadedDocumentModal('lg', true, null);
    vm.isProgressBarVisible = false;
  }

  function errorUpload() {
    toaster.pop('error', 'Something went wrong', 'Please try again');
  }
  // END OF DROPLET FUNCTIONS AREA

  /*
 * @name removeDocument
 * @desc show delete sweetalert
 * @param documentId
 * @return
 */
  function removeDocument(documentFileId) {
    swal({
      title: 'Please confirm action',
      text: 'Are you sure you want to delete this document? This action cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FA8E91',
      confirmButtonText: 'Delete Document',
      closeOnConfirm: true,
    },
    (isConfirm) => {
      if (isConfirm) {
        loanToolsService
          .resourceMyDocumentsDelete(documentFileId)
          .then(() => {
            populateDocumentsList();
            toaster.pop('success', 'Deleted', 'File has been deleted');
          })
          .catch(displayError);
      }
    });
  }

  // generic functions
  vm.editDocument = editDocument;
  vm.removeDocument = removeDocument;
  vm.searchDocumentCall = searchDocumentCall;
  vm.closeNotif = closeNotif;
  vm.openSearch = openSearch;
  vm.closeSearch = closeSearch;

  // for droplet functions declaration
  vm.toUploadReady = toUploadReady;
  vm.toAddUpload = toAddUpload;
  vm.successUpload = successUpload;
  vm.errorUpload = errorUpload;
  // //////////////////

  /*
   * @name init
   * @desc call required methods upon page load
   */
  function init() {
    contactService.getUserInfo().then((response) => {
      vm.userInfo = response.data;
    });
    populateDocumentsList();
  }
  /* calling init */
  init();
});
