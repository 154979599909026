import angular from 'angular';

angular.module('app').controller('ComplaintsCtrl', function ComplaintsCtrl($uibModal, loanToolsService, SweetAlert) {
  const vm = this;

  // models
  vm.complaintList = [];

  const openComplaintsModal = (isAdd, complaintIdForOpeningEdit) => {
    const modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: '/assets/views/modals/resource/complaintsModal.html',
      controller: 'ComplaintsModalCtrl',
      controllerAs: 'modal',
      windowClass: 'complaints-modal-class',
      size: 'lg',
      resolve: {
        successUploadResponse() {
          let toReturnVal;

          if (isAdd === false) {
            toReturnVal = complaintIdForOpeningEdit;
          } else {
            toReturnVal = null;
          }

          return toReturnVal;
        },
      },
    });

    modalInstance.result.then((newSetOfComplaints) => {
      vm.complaintList = null;
      vm.complaintList = newSetOfComplaints;
    }, () => {
    });
  };

  const populateComplaintsList = () => {
    vm.complaintList = null;

    loanToolsService.resourceComplaintGet(0).then((response) => {
      vm.complaintList = response.data;
    });
  };

  const deleteComplaint = (id) => {
    SweetAlert.swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, delete it!',
    }, (confirm) => {
      if (confirm) {
        loanToolsService.resourceComplaintDelete(id).then(() => {
          populateComplaintsList();
        });
      }
    });
  };

  // function expressions
  vm.openComplaintsModal = openComplaintsModal;
  vm.deleteComplaint = deleteComplaint;

  // //////////////////
  /* init call here */
  function init() {
    populateComplaintsList();
  }
  init();
});
