import template from './subscriptionAutoMarketingModal.html';
import controller from './subscriptionAutoMarketingModalCtrl';


export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
    subscriptionData: '<',
    orderedById: '<',
  },
};
