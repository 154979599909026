class NextGenLoanAppService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'NextGenLoanApp';
  }

  nextGenGUID(loanApplicationId) {
    return this.httpClient.post(`${this.apiBaseUrl}/NextGenGUID`, {}, { loanApplicationId });
  }

  nextGenAOLView(loan) {
    const { LoanScenarioID: loanApplicationId, LoanID: loanId } = loan;
    return this.httpClient.post(`${this.apiBaseUrl}/NextGenAOLView`, {}, { loanApplicationId, loanId });
  }

  nextGenLoanAppSubmission(loanApplicationId) {
    return this.httpClient.post(`${this.apiBaseUrl}/NextGenLoanAppSubmission`, {}, { loanApplicationId });
  }

  nextGenAOLSubmission(loanApplicationId) {
    return this.httpClient.post(`${this.apiBaseUrl}/NextGenAOLSubmission`, {}, { loanApplicationId });
  }

  nextGenDocHeaderIdGet(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/NextGenDocHeaderIdGet`, { loanApplicationId });
  }
}

export default NextGenLoanAppService;
