

app.factory('validateEmailService', [function () {
  var factory = this;

  angular.extend(factory, {
    validateEmail(emailToValidate) {
      var atpos = emailToValidate.indexOf('@');
      var dotpos = emailToValidate.lastIndexOf('.');
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailToValidate.length) {
        return false;
      }
      return true;
    },
  });

  return factory;
}]);
