
import { INSURANCE_DATA, INSURANCE_OPTIONS } from 'Common/constants/insuranceOptions';

export default class insuranceSaveQuotesCtrl {
  constructor(
    $state,
    toaster,
    insuranceQuoteService
  ) {
    'ngInject';

    this.$state = $state;
    this.toaster = toaster;
    this.insuranceQuoteService = insuranceQuoteService;
    this.familyId = parseInt(this.$state.params.familyId, 10);
    this.isQuickQuote = INSURANCE_DATA.QUOTE;
    this.savedQuotesList = [];
    this.tempBenefit = {};
    this.savedQuote = {};
    this.benefitType = { ...INSURANCE_OPTIONS.BENEFIT_TYPE };
  }

  $onInit() {
    this.initQuotes();
  }

  initQuotes() {
    this.insuranceQuoteService.insuranceQuoteGet(this.isQuickQuote, 0, this.familyId)
      .then((response) => {
        if (response.data && response.data.length && response.data[0].Module === this.isQuickQuote) {
          this.filterQuoteData(response.data);
        }
      });
  }

  filterQuoteData(qupteData = []) {
    const savedQuotesList = [];
    let savedQuote = {};
    qupteData.forEach((data) => {
      savedQuote = this.saveQuotes(data);

      let tempBenefit = {};
      data.PeopleEntity.forEach((value) => {
        value.BenefitList.forEach((benefit) => {
          const result = savedQuote.benefitList.filter((o) => {
            return o.benefitId === benefit.BenefitId;
          });
          if (result.length === 0) {
            tempBenefit = this.dataStripper(benefit);
            savedQuote.benefitList.push(tempBenefit);
          }
        });
      });
      savedQuotesList.push(savedQuote);
      savedQuote = {};
    });
    this.savedQuotesList = savedQuotesList;
  }

  saveQuotes(data) {
    this.savedQuote = {
      quoteId: data.QuoteID,
      dateCreated: data.DateCreated,
      familyId: data.FamilyID,
      noOfClients: data.NoOfClients,
      noOfDependents: data.NoOfDependents,
      benefitList: [],
    };
    return this.savedQuote;
  }

  openQuoteDetail(quoteObj) {
    this.$state.go('app.quickQuoteDetails', { insQuoteId: quoteObj.quoteId, familyId: quoteObj.familyId });
  }

  removeSavedQuote(quote) {
    this.insuranceQuoteService.insuranceQuoteDelete(quote.quoteId).then((response) => {
      if (response.data === 1) {
        this.toaster.pop('success', 'Removed', 'Insurance Quote successfully removed');
        this.initQuotes();
      } else {
        this.toaster.pop('error', 'Error', 'Insurance Quote was not removed');
      }
    });
  }

  dataStripper(obj) {
    const id = JSON.parse(JSON.stringify(obj.BenefitId));
    this.tempBenefit = {
      benefitId: id,
      benefit: {},
      displayName: '',
    };

    switch (id) {
    case this.benefitType.HEALTH_COVER:
      this.tempBenefit.displayName = 'Health Cover';
      this.tempBenefit.benefit = obj.HealthCover;
      break;

    case this.benefitType.LIFE_COVER:
      this.tempBenefit.displayName = 'Life Cover';
      this.tempBenefit.benefit = obj.LifeCover;
      break;

    case this.benefitType.FAMILY_PROTECTION:
      this.tempBenefit.displayName = 'Family Protection';
      this.tempBenefit.benefit = obj.FamilyProtection;
      break;

    case this.benefitType.TRAUMA_COVER:
      this.tempBenefit.displayName = 'Trauma Cover';
      this.tempBenefit.benefit = obj.TraumaCover;
      break;

    case this.benefitType.TPD:
      this.tempBenefit.displayName = 'Total and Permanent Disability';
      this.tempBenefit.benefit = obj.TotalAndPermanentDisability;
      break;

    case this.benefitType.INCOME_PROTECTION:
      this.tempBenefit.displayName = 'Income Protection';
      this.tempBenefit.benefit = obj.IncomeProtection;
      break;

    case this.benefitType.MORTAGE_REPAYMENT:
      this.tempBenefit.displayName = 'Mortgage Repayment Cover';
      this.tempBenefit.benefit = obj.MortgageRepaymentCover;
      break;

    case this.benefitType.REDUNDANCY:
      this.tempBenefit.displayName = 'Redundancy Cover';
      this.tempBenefit.benefit = obj.RedundancyCover;
      break;

    case this.benefitType.WAIVER_OF_PREMIUM:
      this.tempBenefit.displayName = 'Waiver Premium';
      this.tempBenefit.benefit = obj.WaiverOfPremium;
      break;

    default:
      this.tempBenefit.displayName = 'Unknown Premium';
      this.tempBenefit.benefit = {};
      break;
    }

    return this.tempBenefit;
  }
}

