// Angular-Loading-Bar configuration
function configLoadingBar(cfpLoadingBarProvider) {
  cfpLoadingBarProvider.includeBar = true;
  cfpLoadingBarProvider.includeSpinner = false;
}

configLoadingBar.$inject = [
  'cfpLoadingBarProvider',
];

export default configLoadingBar;
