import { CONTACT_STATUS, CONTACT_STATUS_COLOR } from 'Common/constants/contactStatus';

class ContactStatusService {
  populateStatusWithColor(data) {
    this.statusList = [];
    if (data && data.length) {
      this.statusList = data.map((status) => {
        switch (parseInt(status.Value, 10)) {
        case CONTACT_STATUS.LEAD:
          status.Color = CONTACT_STATUS_COLOR.LEAD;
          break;
        case CONTACT_STATUS.APPLICATION:
          status.Color = CONTACT_STATUS_COLOR.APPLICATION;
          break;
        case CONTACT_STATUS.EXISTING_CLIENT:
          status.Color = CONTACT_STATUS_COLOR.EXISTING_CLIENT;
          break;
        case CONTACT_STATUS.PROFESSIONAL_PARTNER:
          status.Color = CONTACT_STATUS_COLOR.PROFESSIONAL_PARTNER;
          break;
        case CONTACT_STATUS.PREVIOUS_CLIENT:
          status.Color = CONTACT_STATUS_COLOR.PREVIOUS_CLIENT;
          break;
        default:
          break;
        }
        return status;
      });
    }
    return this.statusList;
  }
}

export default ContactStatusService;
