// For Demo Video
const DEMO_VIDEO = ['DEMO_VIDEO', {
  SERVICEABILITY: {
    Url: '223399924',
    LocalStorage_Key: 'serviceabilityIsFeelingStuckPopOver',
  },
  PRODUCT_FINDER: {
    Url: '223400327',
    LocalStorage_Key: 'productFinderIsFeelingStuckPopOver',
  },
  DASHBOARD: {
    Header_Name: 'Dashboard',
    LocalStorage_Key: 'dashboardIsFeelingStuckPopOver',
    Video_List: [{
      Name: 'Overview/Introduction',
      Url: '226074710',
    },
    {
      Name: 'Dashboard Layouts',
      Url: '223400285',
    }],
  },
  LOAN_APPLICATION: {
    Header_Name: 'Loan Application',
    LocalStorage_Key: 'loanApplicationIsFeelingStuckPopOver',
    Video_List: [{
      Name: 'Loan Application Example FULL',
      Url: '225187114',
    },
    {
      Name: 'Loan Application (Applicants)',
      Url: '225186525',
    },
    {
      Name: 'Loan Application (Objectives)',
      Url: '225186595',
    },
    {
      Name: 'Loan Application (Financial Pos)',
      Url: '225186538',
    },
    {
      Name: 'Loan Application (Loan Funding)',
      Url: '225186565',
    },
    {
      Name: 'Loan Application (Insurance)',
      Url: '225186556',
    },
    {
      Name: 'Loan Application (Uploading Docs)',
      Url: '225186607',
    },
    {
      Name: 'Loan Application (Loan Submit)',
      Url: '225186578',
    }],
  },
  CONTACTS: {
    Url: '224879451',
    LocalStorage_Key: 'contactsIsFeelingStuckPopOver',
  },
  TASKS: {
    Url: '223400379',
    LocalStorage_Key: 'tasksIsFeelingStuckPopOver',
  },
  REPAYMENT_CALCULATOR: {
    Url: '223400346',
    LocalStorage_Key: 'repaymentIsFeelingStuckPopOver',
  },
  ADDITIONAL_REPAYMENT_CALCULATOR: {
    Url: '223400243',
    LocalStorage_Key: 'additionalRepaymentIsFeelingStuckPopOver',
  },
  COMPARE_LOAN_CALCULATOR: {
    Url: '223400244',
    LocalStorage_Key: 'compareLoanIsFeelingStuckPopOver',
  },
  SAVING_GOAL_CALCULATOR: {
    Url: '223400365',
    LocalStorage_Key: 'savingGoalIsFeelingStuckPopOver',
  },
  OFFSET_CALCULATOR: {
    Url: '223400317',
    LocalStorage_Key: 'offsetIsFeelingStuckPopOver',
  },
  FUNDING_CALCULATOR: {
    Url: '226666477',
    LocalStorage_Key: 'fundingIsFeelingStuckPopOver',
  },
  LENDER_INFORATION: {
    Url: '223400305',
    LocalStorage_Key: 'lenderInfoIsFeelingStuckPopOver',
  },
  SATISFACTION_SERVEY: {
    Url: '226666490',
    LocalStorage_Key: 'satisfactionServeyIsFeelingStuckPopOver',
  },
  LEAD_PIPELINE: {
    Url: '223400268',
    LocalStorage_Key: 'leadPipelineIsFeelingStuckPopOver',
  },
  APPLICATION_PIPELINE: {
    Url: '223400268',
    LocalStorage_Key: 'applicationPipelineIsFeelingStuckPopOver',
  },
  BROKER_SUPPORT: {
    Url: '224255007',
    LocalStorage_Key: 'brokerSupportIsFeelingStuckPopOver',
  },
  REFERRAL_AGREEMENT_REQUEST: {
    Url: '281904233',
    LocalStorage_Key: 'referralAgreementRequest',
  },
}];

export default DEMO_VIDEO;
