import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('preApprovalExpiriesSmall',
  function preApprovalExpiriesSmall(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl: 'assets/views/overviewDashboard/directiveTemplates/preApprovalExpiriesSmall.html',

      link(scope) {
        scope.fixedRateReviewsMultiAdviser = [];
        scope.fixedRateReviewsMultiAdviserObj = {};
        scope.fixedRateReviewSmall = {};
        scope.WidgetOptionGetData = [];
        scope.outp = [];

        // widgetOptioGet
        function PreApprovalExpiryCountGet() {
          overviewDashboardService.PreApprovalExpiryCountGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.fixedRateReviewSmallData = response.data;
              scope.fixedRateReviewSmall.WidgetName = scope.fixedRateReviewSmallData.WidgetType;
            }, () => {
            });
        }

        function WidgetOptionGet() {
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;
              scope.WidgetOptionGetYesNo = {};
              scope.WidgetOptionGetAdviser = {};
              scope.Advisers = [];
              for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 16) {
                  const widgetOptionValue = scope.WidgetOptionGetData[i].Widget_OptionValue;
                  scope.filteredFixedRateReviewsDropDownList = scope.fixedRateReviewsDropDownList.filter(item => parseInt(item.OptionValueId, 10) === parseInt(widgetOptionValue, 10));

                  scope.fixedRateReviewsDropDowns = scope.filteredFixedRateReviewsDropDownList[0];
                }
                if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 2) {
                  scope.WidgetOptionGetYesNo.DisplayValue = scope.WidgetOptionGetData[i].DisplayValue;
                }
                if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 3) {
                  scope.Advisers = scope.WidgetOptionGetData[i].SelectedUerValues;
                }
              }
            }, () => {
            });
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(() => {
              PreApprovalExpiryCountGet();
              WidgetOptionGet();
            })
            .catch(displayError);
        }

        scope.callServerWithUpdatedvalue = function () {
          overviewDashboardService.WidgetYesNoGet()
            .then((response) => {
              scope.YesNoData = response.data;
              for (let i = 0; i < scope.YesNoData.length; i++) {
                if (scope.WidgetOptionGetYesNo.DisplayValue === scope.YesNoData[i].OptionDisplayName) {
                  scope.WidgetOptionGetYesNo.Widget_OptionValue = scope.YesNoData[i].OptionValueId;
                  scope.WidgetOptionGetYesNo.WidgetOptionId = scope.YesNoData[i].OptionId;
                  scope.WidgetOptionGetYesNo.Widget_UserID = scope.myParam.Widget_UserID;
                  scope.WidgetOptionGetYesNo.SelectedUser = null;
                }
              }
              saveForTimePeriod(scope.WidgetOptionGetYesNo);
            }, () => {
            });
        };

        scope.updateMultiselectFilterData = function (clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.fixedRateReviewsMultiAdviser, ['ticked', true]);
          if (_.isUndefined(itemData)) {
            _.map(scope.fixedRateReviewsMultiAdviser, (item) => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.fixedRateReviewsMultiAdviser) !== 0) {
              scope.fixedRateReviewsMultiAdviser[0].ticked = false;
            }

            dataToSend = scope.fixedRateReviewsMultiAdviser.filter(item => item.FamilyName !== 'All' && item.ticked === true).map((obj) => {
              return { PersonId: obj.FamilyId };
            });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId = scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue = scope.WidgetOptionAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && scope.outp.length > 1) {
              scope.fixedRateReviewsMultiAdviser = scope.fixedRateReviewsMultiAdviser.map((obj) => {
                if (obj.FamilyName === 'All')
                  return obj;

                obj.ticked = false;
                return obj;
              });
            }
            dataToSend.push({ PersonId: scope.fixedRateReviewsMultiAdviser[0].FamilyId });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId = scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue = scope.WidgetOptionAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };

        function WidgetOptionAdviserListGet() {
          overviewDashboardService.WidgetOptionAdviserListGet()
            .then((response) => {
              scope.WidgetOptionAdviserList = response.data;
              for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
                scope.fixedRateReviewsMultiAdviserObj = { FamilyName: scope.WidgetOptionAdviserList[i].FamilyName, FamilyId: scope.WidgetOptionAdviserList[i].FamilyId, OptionValueId: scope.WidgetOptionAdviserList[i].OptionValueId, OptionDB_Value: scope.WidgetOptionAdviserList[i].OptionDB_Value, ticked: false, WidgetOptionId: scope.WidgetOptionAdviserList[i].OptionId };

                scope.fixedRateReviewsMultiAdviser.push(scope.fixedRateReviewsMultiAdviserObj);
              }

              overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
                .then((res) => {
                  scope.WidgetOptionGetData = res.data;
                  scope.WidgetOptionGetYesNo = {};
                  scope.WidgetOptionGetAdviser = {};
                  scope.Advisers = [];
                  for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                    if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 2) {
                      scope.WidgetOptionGetYesNo.DisplayValue = scope.WidgetOptionGetData[j].DisplayValue;
                    }
                    if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 3) {
                      scope.Advisers = scope.WidgetOptionGetData[j].SelectedUerValues;
                      const AdvisersArray = scope.Advisers.split(',');

                      scope.fixedRateReviewsMultiAdviser = scope.fixedRateReviewsMultiAdviser.map((obj) => {
                        if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                          obj.ticked = true;
                          return obj;
                        }
                        return obj;
                      });
                    }
                  }
                }, () => {
                });
            }, () => {
            });
        }

        scope.fixedRateReviewsDropDownList = [];
        scope.fixedRateReviewsDropDowns = {};
        scope.filteredFixedRateReviewsDropDownList = [];
        function WidgetOptionTimePeriodListv1Get() {
          overviewDashboardService.WidgetOptionTimePeriodList7Get()
            .then((response) => {
              scope.fixedRateReviewsDropDownList = response.data;
              scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[0];
              for (let i = 0; i < scope.fixedRateReviewsDropDownList.length; i++) {
                scope.fixedRateReviewsDropDownList[i].index = 0 + i;
              }
              overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
                .then((res) => {
                  scope.WidgetOptionGetData = res.data;
                  for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                    if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 16) {
                      const widgetOptionValue = scope.WidgetOptionGetData[j].Widget_OptionValue;
                      scope.filteredFixedRateReviewsDropDownList = scope.fixedRateReviewsDropDownList.filter(item => parseInt(item.OptionValueId, 10) === parseInt(widgetOptionValue, 10));

                      scope.fixedRateReviewsDropDowns = scope.filteredFixedRateReviewsDropDownList[0];
                    }
                    if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 1) {
                      const widgetOptionValue = scope.WidgetOptionGetData[j].Widget_OptionValue;
                      scope.filteredFixedRateReviewsDropDownList = scope.fixedRateReviewsDropDownList.filter(item => parseInt(item.OptionValueId, 10) === parseInt(widgetOptionValue, 10));

                      scope.fixedRateReviewsDropDowns = scope.filteredFixedRateReviewsDropDownList[0];
                    }
                  }
                }, () => {
                });
            }, () => {
            });
        }
        scope.currentObj = {};
        scope.objForWidgetOptionSet = {};


        scope.showNextVal = function (displayObj) {
          scope.currentObj = displayObj;


          const displayPos = scope.currentObj.index;
          const arrayPos = displayPos;
          if (arrayPos + 1 > scope.fixedRateReviewsDropDownList.length - 1) {
            scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[0];

            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
              SelectedUser: null };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            // save(scope.objForWidgetOptionSet);
            return;
          }
          scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[arrayPos + 1];

          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;
            }, () => {
            });
          scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
            SelectedUser: null };
          saveForTimePeriod(scope.objForWidgetOptionSet);
        };

        scope.showPrevVal = function (displayObj) {
          scope.currentObj = displayObj;
          let displayPos;
          if (!_.isNil(scope.currentObj.index)) {
            displayPos = scope.currentObj.index;
          } else {
            displayPos = 0;
          }
          const arrayPos = displayPos;

          if (arrayPos === 0) {
            const lastPos = scope.fixedRateReviewsDropDownList.length - 1;
            scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[lastPos];
            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
              SelectedUser: null };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            return;
          }
          scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[arrayPos - 1];
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;
            }, () => {
            });
          scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
            SelectedUser: null };

          saveForTimePeriod(scope.objForWidgetOptionSet);
        };

        scope.doAction = function (msg) {
          if (msg === 'edit')
            scope.customizeMode = true;
          else
            scope.customizeMode = false;
          if (msg === 'notEdit') {
            scope.customizeMode = false;
          } else
            scope.customizeMode = true;
        };
        function init() {
          PreApprovalExpiryCountGet();
          WidgetOptionTimePeriodListv1Get();
          WidgetOptionAdviserListGet();
        }

        init();
      },
    };
  });
