import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('LoanAppIdentificationModalCtrl', function LoanAppIdentificationModalCtrl(
  $scope, $uibModalInstance, utilitiesService, toaster, contactService, loanApplicationServices, loanScenarioService, optionsService, modalType, familyId, passportService, identificationService
) {
  $scope.Title = (modalType === 'new' ? 'Add' : 'Edit');

  $scope.datePopUp = {
    IssueDate: false,
    ExpiryDate: false,
  };
  // Open Date Popup
  $scope.openDate = (dateField) => {
    $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
  };

  $scope.format = 'dd MMM yyyy';
  $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  $scope.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };

  $scope.cancel = () => {
    let familyIdToSend = null;
    if (familyId) {
      familyIdToSend = familyId;
    } else {
      familyIdToSend = $scope.familyIdentification.FamilyId;
    }
    $uibModalInstance.close({ modalType, FamilyId: familyIdToSend });
  };

  $scope.addIdentification = (form) => {
    $scope.isSavingIdentification = true;
    if (identificationService.isIdentificationValid($scope.IdentificationSet) && form) {
      loanScenarioService.IdentificationSet($scope.IdentificationSet).then(() => {
        $scope.cancel();
        $scope.validateApplicants();
        $scope.isSavingIdentification = false;
      });
    } else {
      $scope.isSavingIdentification = false;
    }
  };

  // get document type
  optionsService.identificationDocumentsTypeGet().then((response) => {
    $scope.DocumentsType = passportService.mapPassportValue(response.data);
    $scope.checkIfDocumentIsPassport = passportService.checkIfDocumentIsPassport;
  });


  loanScenarioService.scenarioApplicantListGet($scope.loanAppId).then((response) => {
    const involvedPartyPerson = response.data.InvolvedPartyPerson;
    const involvedPartyEntity = response.data.InvolvedPartyEntity;

    const clientEntityList = [];

    // Clients
    _.forEach(involvedPartyPerson, (family) => {
      _.forEach(family, (person) => {
        if (person.FamilyId === $scope.familyIdentification.FamilyId) {
          // set the isClient property here since this will be called if
          // ONLY once if the above expression return truthy
          $scope.IdentificationSet.IsClient = true;
          person.ClientEntityId = person.PersonId;
          clientEntityList.push(person);
        }
      });
    });

    // Entities
    _.forEach(involvedPartyEntity, (entity) => {
      if (entity.FamilyId === $scope.familyIdentification.FamilyId) {
        // set the isClient property here since this will be called if
        // ONLY once if the above expression return truthy
        $scope.IdentificationSet.IsClient = false;
        entity.ClientEntityId = entity.OrganisationId;
        entity.Name = entity.FamilyFullName;
        clientEntityList.push(entity);
      }
    });

    $scope.ClientEntityList = clientEntityList;
    let firstClientEntity = {};
    if (modalType !== 'new') {
      firstClientEntity = { ClientEntityId: $scope.IdentificationSet.ClientEntityId };
    } else {
      firstClientEntity = _.first($scope.ClientEntityList) || { ClientEntityId: '' };
    }

    angular.extend($scope.IdentificationSet, {
      ClientEntityId: String(firstClientEntity.ClientEntityId),
    });

    if (modalType === 'new') {
      $scope.changeIdentificationClient($scope.ClientEntityList);
    }
  });
});
