import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('targetTracking',
  function targetTracking(
    $timeout,
    overviewDashboardService,
    corporateService,
    $filter,
    $window
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl: 'assets/views/overviewDashboard/directiveTemplates/targetTracking.html',

      link(scope) {
        scope.fixedRateReviewsMultiAdviser = [];
        scope.fixedRateReviewsMultiAdviserObj = {};
        scope.fixedRateReviewSmall = {};
        scope.WidgetOptionGetData = [];
        scope.WidgetOptionData = {};
        scope.outp = [];
        scope.targetManagement = {};
        scope.targetManagement.target = 'Please Select';

        function populateDropdown(value) {
          const val = parseInt(value, 10);
          for (let i = 0; i < scope.targetManagementList.length; i++) {
            if (scope.targetManagementList[i].TargetManagementID === val) {
              scope.targetManagement.target = scope.targetManagementList[i];
            }
          }
        }

        function getTargetTracking(value) {
          corporateService.getTargetTracking(parseInt(value, 10)).then((response) => {
            scope.targetTracking = response.data[0];
          });
        }

        function WidgetOptionGet() {
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionData = response.data[0];
              if (scope.WidgetOptionData.SelectedUerValues) {
                populateDropdown(scope.WidgetOptionData.SelectedUerValues);
                getTargetTracking(scope.WidgetOptionData.SelectedUerValues);
              } else {
                getTargetTracking(0);
              }
            }, () => {
            });
        }

        function getTargetManagement() {
          corporateService.getTargetManagement(0, 0, 0, 0, 0).then((response) => {
            scope.targetManagementList = response.data;
            WidgetOptionGet();
          });
        }

        getTargetManagement();
        scope.Math = $window.Math;

        function saveOption(widgetSetObj) {
          overviewDashboardService
            .WidgetOptionSet(widgetSetObj)
            .then(WidgetOptionGet)
            .catch(displayError);
        }

        scope.saveDropdownValue = function () {
          scope.WidgetOptionData.WidgetId = scope.myParam.WidgetId;
          scope.WidgetOptionData.Widget_UserID = scope.myParam.Widget_UserID;
          scope.WidgetOptionData.SelectedUerValues = scope.targetManagement.target.TargetManagementID;
          saveOption(scope.WidgetOptionData);
        };

        scope.fixedRateReviewsDropDownList = [];
        scope.fixedRateReviewsDropDowns = {};
        scope.filteredFixedRateReviewsDropDownList = [];
        function WidgetOptionTimePeriodListv1Get() {
          corporateService.getWidgetOptionTimePeriodList6()
            .then((response) => {
              scope.fixedRateReviewsDropDownList = response.data;
              scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[0];
              for (let i = 0; i < scope.fixedRateReviewsDropDownList.length; i++) {
                scope.fixedRateReviewsDropDownList[i].index = 0 + i;
              }
              overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
                .then((res) => {
                  scope.WidgetOptionGetData = res.data;
                  for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                    if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 15) {
                      const widgetOptionValue = scope.WidgetOptionGetData[j].Widget_OptionValue;
                      const filteredData = scope.fixedRateReviewsDropDownList.filter(item => item.Option_ValueID === widgetOptionValue);
                      scope.fixedRateReviewsDropDowns = filteredData[0];
                    }
                  }
                }, () => {
                });
            }, () => {
            });
        }
        scope.currentObj = {};
        scope.objForWidgetOptionSet = {};

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(WidgetOptionGet)
            .catch(displayError);
        }

        scope.showNextVal = function (displayObj) {
          scope.currentObj = displayObj;

          const displayPos = scope.currentObj.index;
          const arrayPos = displayPos;
          if (arrayPos + 1 > scope.fixedRateReviewsDropDownList.length - 1) {
            scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[0];

            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionID,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.fixedRateReviewsDropDowns.Option_ValueID,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
          } else {
            scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[arrayPos + 1];

            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionID,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.fixedRateReviewsDropDowns.Option_ValueID,
              SelectedUser: null,
            };
            saveForTimePeriod(scope.objForWidgetOptionSet);
          }
        };

        scope.showPrevVal = function (displayObj) {
          scope.currentObj = displayObj;
          let displayPos;
          if (!_.isNil(scope.currentObj.index)) {
            displayPos = scope.currentObj.index;
          } else {
            displayPos = 0;
          }
          const arrayPos = displayPos;

          if (arrayPos === 0) {
            const lastPos = scope.fixedRateReviewsDropDownList.length - 1;
            scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[lastPos];
            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionID,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.fixedRateReviewsDropDowns.Option_ValueID,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            return;
          }

          scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[arrayPos - 1];
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;
            }, () => {
            });
          scope.objForWidgetOptionSet = {
            WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionID,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.fixedRateReviewsDropDowns.Option_ValueID,
            SelectedUser: null,
          };

          saveForTimePeriod(scope.objForWidgetOptionSet);
        };

        scope.doAction = function (msg) {
          if (msg === 'edit')
            scope.customizeMode = true;
          else
            scope.customizeMode = false;
          if (msg === 'notEdit') {
            scope.customizeMode = false;
          } else
            scope.customizeMode = true;
        };
        function init() {
        // FixedRateReviewSmallGet();
          WidgetOptionTimePeriodListv1Get();
        // WidgetOptionAdviserListGet();
        }

        init();

        scope.getDate = function (month, year) {
          let retVal = year;
          if (month > 0) {
            retVal = $filter('date')(new Date(`${month}-1-${year}`), 'MMM yyyy');
          }
          return retVal;
        };

        scope.targetTracking = {};
      },
    };
  });
