import { forEach } from 'lodash';
import swal from 'sweetalert';
import { isMobileValid } from 'Common/utilities/mobile';

class OpenOnlineFactFindForm {
  constructor(
    $uibModalInstance,
    $state,
    loanScenarioService,
    $uibModal,
    loanId,
    optionsService
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.$state = $state;
    this.loanScenarioService = loanScenarioService;
    this.$uibModal = $uibModal;
    this.loanId = loanId;
    this.optionsService = optionsService;
  }

  $onInit() {
    this.involvedParty = [];
    this.applicant = {};
    this.applicantEmail = {};
    this.applicantMobileNumber = {};
    this.checkEmail = null;
    this.checkMobile = null;
    this.isUpdateApplicant = false;
    this.getInvolveParty();
    this.getCountryList();
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  getCountryList() {
    this.optionsService.countryListGet()
      .then((response) => {
        this.countryList = response.data || [];
      }, () => {
        this.countryList = [];
      });
  }

  submitApplyOnline() {
    const checkIfContentExist = this.applicant && this.applicant.data && this.applicantMobileNumber && this.applicantMobileNumber.Number && this.applicantEmail && this.applicantEmail.EmailAddress;
    if (checkIfContentExist) {
      const applicantNumber = this.applicantMobileNumber.Number;
      const loanId = parseInt(this.loanId, 10);
      const personId = parseInt(this.applicant.data.PersonId, 10);
      const familyId = parseInt(this.applicant.FamilyId, 10);
      this.loanScenarioService.share(loanId, personId, familyId, this.applicantEmail.EmailAddress, applicantNumber).then((response) => {
        this.cancel();
        if (response.data) {
          this.$uibModal.open({
            templateUrl: '/assets/views/loanApplication/sidebar/partial/applyOnlineSent.html',
            controller: 'ApplyOnlineSentCtrl',
            size: 'md',
          });
        } else {
          swal({
            title: 'Failed to send invite!',
            text: 'Please contact support',
            type: 'error',
            showCancelButton: false,
          });
        }
      }, () => {
        this.cancel();
      });
    }
  }

  openClientEditModal(isChild) {
    const countryList = this.countryList;
    if (this.applicant && this.applicant.data && this.applicant.data.PersonId) {
      const applicantClientId = this.applicant.data.PersonId;
      const appplicantFamilyId = this.applicant.FamilyId;
      const modalInstance = this.$uibModal.open({
        templateUrl: '/assets/views/contacts/partials/add_client_modal_v2.html',
        controller: 'AddClientModalCtrl',
        size: 'lg',
        resolve: {
          countryList() {
            return countryList;
          },
          isChild() {
            return isChild;
          },
          clientId() {
            return applicantClientId;
          },
          applicantFamilyId() {
            return appplicantFamilyId;
          },
        },
      });
      modalInstance.result.then((modalResponse) => {
        if (modalResponse.updateApplicant) {
          this.isUpdateApplicant = true;
          this.getInvolveParty();
        }
      });
    }
  }

  getInvolveParty() {
    if (this.loanId) {
      this.loanScenarioService.scenarioApplicantListGet(this.loanId).then((response) => {
        if (response.data && response.data.InvolvedPartyPerson) {
          this.involvedParty = [];
          forEach(response.data.InvolvedPartyPerson, (val, key) => {
            val.forEach((v) => {
              this.involvedParty.push({
                FamilyId: key,
                data: v,
              });
            });
          });
          const isApplicant = this.involvedParty && this.involvedParty.length && !this.isUpdateApplicant;


          if (isApplicant) {
            this.applicant = this.involvedParty[0];
            this.changeApplicant();
          }
          if (this.isUpdateApplicant) {
            this.applicant = this.involvedParty && this.involvedParty.length && this.involvedParty.find(obj => obj.data.PersonId === this.applicant.data.PersonId);
            this.changeApplicant();
            this.isUpdateApplicant = false;
          }
        }
      });
    }
  }
  disableSubmit() {
    const disableSubmit = (!this.applicant || !this.applicantMobileNumber || !this.applicantMobileNumber.Number || !this.applicantEmail || !this.applicantEmail.EmailAddress || !this.isValidMobile);
    return disableSubmit;
  }
  changeApplicant() {
    if (this.applicant) {
      this.checkEmail = null;
      this.checkMobile = null;
      this.applicantEmail = this.applicant.data && this.applicant.data.Email && this.applicant.data.Email.find(obj => obj.Type === 'Email');
      this.applicantMobileNumber = this.applicant.data && this.applicant.data.Phone && this.applicant.data.Phone.find(obj => obj.Type === 'Mobile');
      this.checkEmail = this.applicant && this.applicantEmail && this.applicantEmail.EmailAddress;
      this.checkMobile = this.applicant && this.applicantMobileNumber && this.applicantMobileNumber.Number;
      if (this.applicantMobileNumber && this.applicantMobileNumber.Number) {
        this.isValidMobile = isMobileValid(this.applicantMobileNumber.Number);
      }
    }
  }
}
export default OpenOnlineFactFindForm;
