import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('fixedRateReviewsTable',
  function fixedRateReviewsTable(
    $timeout,
    $state,
    overviewDashboardService,
    overviewDashboardSharedService,
    $localStorage,
    currentUserService,
    eventService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl: 'assets/views/overviewDashboard/directiveTemplates/fixedRateReviewsTable.html',

      link(scope) {
        scope.tableMode = true;
        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };
        scope.fixedRateReviewsDropDownList = [];
        scope.fixedRateReviewsDropDowns = {};
        scope.WidgetOptionAdviserList = [];
        scope.widgetOptionAdviser = {};
        scope.fixedRateReviewTableData = [];
        scope.fixedRateReviewsMultiAdviserObj = {};
        scope.fixedRateReviewsMultiAdviser = [];
        scope.WidgetOptionGetData = [];
        scope.filteredFixedRateReviewsDropDownList = [];
        scope.outp = [];
        scope.switchsetting2 = {};
        scope.iconLocation = currentUserService.isNZ ? `/assets/images/lenderLogos/NZ/` : `/assets/images/company/`;

        function FixedRateReviewTableGet() {
          scope.fixedRateReviewTableData = [];

          overviewDashboardService.FixedRateReviewTableGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.showSlick = false;
              scope.fixedRateReviewTableData = [];

              const totalData = response.data.length;
              const round = (Math.floor(totalData / 4) * 4) - 1;
              const modStart = Math.floor(totalData / 4) * 4;
              const mod = Math.floor(totalData % 4);
              for (let i = 0; i < round; i += 4) {
                const testData = [];
                testData.push(response.data[i]);
                testData.push(response.data[i + 1]);
                testData.push(response.data[i + 2]);
                testData.push(response.data[i + 3]);

                scope.fixedRateReviewTableData.push(testData);
              }

              if (parseInt(mod, 10) !== 0) {
                const lastItem = [];
                for (let i = modStart; i < totalData; i++) {
                  lastItem.push(response.data[i]);
                }
                scope.fixedRateReviewTableData.push(lastItem);
              }

              scope.dataLoaded = true;
              scope.showSlick = true;

              if (scope.fixedRateReviewTableData.length > 0) {
                $timeout(() => {
                  try {
                    scope.slickConfig.method.slickGoTo(0);
                  } catch (error) {
                    // Continue regardless of error
                  }
                }, 1000);
              }
            }, () => {
            });
        }

        function widgetOptionGet() {
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;

              scope.WidgetOptionGetYesNo = {};
              scope.WidgetOptionGetAdviser = {};
              scope.Advisers = [];
              scope.optionText = {};

              let Flag;
              for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 16) {
                  const widgetOptionValue = scope.WidgetOptionGetData[i].Widget_OptionValue;
                  scope.filteredFixedRateReviewsDropDownList = scope.fixedRateReviewsDropDownList.filter(item => parseInt(item.OptionValueId, 10) === parseInt(widgetOptionValue, 10));

                  scope.fixedRateReviewsDropDowns = scope.filteredFixedRateReviewsDropDownList[0];
                  if (scope.fixedRateReviewsDropDowns.OptionDisplayName === 'Next 90 Days') {
                    scope.optionText = 90;
                  }
                  if (scope.fixedRateReviewsDropDowns.OptionDisplayName === 'Next 7 Days') {
                    scope.optionText = 7;
                  }
                  if (scope.fixedRateReviewsDropDowns.OptionDisplayName === 'Next 30 Days') {
                    scope.optionText = 30;
                  }
                  if (scope.fixedRateReviewsDropDowns.OptionDisplayName === 'Next 60 Days') {
                    scope.optionText = 60;
                  }
                }
                if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 1) {
                  const widgetOptionValue = scope.WidgetOptionGetData[i].Widget_OptionValue;
                  scope.filteredFixedRateReviewsDropDownList = scope.fixedRateReviewsDropDownList.filter(item => item.OptionValueId === widgetOptionValue);

                  scope.fixedRateReviewsDropDowns = scope.filteredFixedRateReviewsDropDownList[0];
                }
                if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 2) {
                  scope.WidgetOptionGetYesNo.DisplayValue = scope.WidgetOptionGetData[i].DisplayValue;
                }
                if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 3) {
                  Flag = 1;
                  scope.switchsetting2 = 'Yes';
                  scope.Advisers = scope.WidgetOptionGetData[i].SelectedUerValues;
                  const AdvisersArray = scope.Advisers.split(',');

                  scope.fixedRateReviewsMultiAdviser = scope.fixedRateReviewsMultiAdviser.map((obj) => {
                    if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                      obj.ticked = true;
                      return obj;
                    }

                    return obj;
                  });
                } else
                if ((scope.WidgetOptionGetData[i].OptionName !== 'Adviser') && (scope.switchsetting2 !== 'Yes') && (Flag !== 1)) {
                  scope.switchsetting2 = 'No';
                  Flag = 0;
                }
              }
            }, () => {
            });
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;

          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(() => {
              FixedRateReviewTableGet();
              widgetOptionGet();
            })
            .catch(displayError);
        }

        scope.callServerWithUpdatedvalue = function () {
          overviewDashboardService.WidgetYesNoGet()
            .then((response) => {
              scope.YesNoData = response.data;
              for (let i = 0; i < scope.YesNoData.length; i++) {
                if (scope.WidgetOptionGetYesNo.DisplayValue === scope.YesNoData[i].OptionDisplayName) {
                  scope.WidgetOptionGetYesNo.Widget_OptionValue = scope.YesNoData[i].OptionValueId;
                  scope.WidgetOptionGetYesNo.WidgetOptionId = scope.YesNoData[i].OptionId;
                  scope.WidgetOptionGetYesNo.Widget_UserID = scope.myParam.Widget_UserID;
                  scope.WidgetOptionGetYesNo.SelectedUser = null;
                }
              }
              saveForTimePeriod(scope.WidgetOptionGetYesNo);
            }, () => {
            });
        };

        scope.makeSelectNone = function () {
          for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
            scope.WidgetOptionAdviserList[i].ticked = false;
            scope.fixedRateReviewsMultiAdviser.push(scope.WidgetOptionAdviserList[i]);
          }
          scope.setIsFiltered.Widget_OptionValue = '';
          scope.setIsFiltered.WidgetOptionId = 3;
          scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
          scope.setIsFiltered.SelectedUser = [];
          saveForTimePeriod(scope.setIsFiltered);
        };


        scope.isFiltered = function (filteredOption) {
          scope.val = filteredOption;
          scope.setIsFiltered = {};

          if (scope.val === 'No') {
            scope.makeSelectNone();
          } else {
            scope.switchsetting2 = 'Yes';
          }
        };


        scope.updateMultiselectFilterData = function (clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.fixedRateReviewsMultiAdviser, ['ticked', true]);
          if (_.isUndefined(itemData)) {
            _.map(scope.fixedRateReviewsMultiAdviser, (item) => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.fixedRateReviewsMultiAdviser) !== 0) {
              scope.fixedRateReviewsMultiAdviser[0].ticked = false;
            }

            dataToSend = scope.fixedRateReviewsMultiAdviser.filter(item => item.FamilyName !== 'All' && item.ticked === true).map((obj) => {
              return { PersonId: obj.FamilyId };
            });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId = scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue = scope.WidgetOptionAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && scope.outp.length > 1) {
              scope.fixedRateReviewsMultiAdviser = scope.fixedRateReviewsMultiAdviser.map((obj) => {
                if (obj.FamilyName === 'All')
                  return obj;

                obj.ticked = false;
                return obj;
              });
            }
            dataToSend.push({ PersonId: scope.fixedRateReviewsMultiAdviser[0].FamilyId });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId = scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue = scope.WidgetOptionAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };

        function WidgetOptionAdviserListGet() {
          overviewDashboardService.WidgetOptionAdviserListGet()
            .then((response) => {
              scope.WidgetOptionAdviserList = response.data;

              for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
                scope.WidgetOptionAdviserList[i].ticked = false;
                // scope.fixedRateReviewsMultiAdviserObj={"FamilyName":scope.WidgetOptionAdviserList[i].FamilyName,"OptionDB_Value":scope.WidgetOptionAdviserList[i].OptionDB_Value,"ticked":false};

                scope.fixedRateReviewsMultiAdviser.push(scope.WidgetOptionAdviserList[i]);
              }

              widgetOptionGet();
            }, () => {
            });
        }
        function WidgetOptionTimePeriodListv1Get() {
          overviewDashboardService.WidgetOptionTimePeriodList7Get()
            .then((response) => {
              scope.fixedRateReviewsDropDownList = response.data;
              scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[0];

              widgetOptionGet();
            }, () => {
            });
        }

        scope.updateTimePeriod = function (fixedRateReviewsDropDowns) {
          scope.fixedRateReviewsDropDownsObj = fixedRateReviewsDropDowns;
          scope.fixedRateReviewsDropDownsObjToSet = {};

          scope.fixedRateReviewsDropDownsObjToSet.Widget_UserID = scope.myParam.Widget_UserID;
          scope.fixedRateReviewsDropDownsObjToSet.Widget_OptionValue = scope.fixedRateReviewsDropDownsObj.OptionValueId;
          scope.fixedRateReviewsDropDownsObjToSet.WidgetOptionId = scope.fixedRateReviewsDropDownsObj.OptionId;
          scope.fixedRateReviewsDropDownsObjToSet.SelectedUser = null;
          saveForTimePeriod(scope.fixedRateReviewsDropDownsObjToSet);
        };

        function init() {
          FixedRateReviewTableGet();
          WidgetOptionTimePeriodListv1Get();
          WidgetOptionAdviserListGet();
        }

        scope.dynamicPopover = {
          content: '',
          templateUrl: 'fixedRateReviewsTablePopover.html',
          title: '',
        };


        scope.doAction = function (msg) {
          overviewDashboardSharedService.doActionShared(msg, scope);
        };


        scope.events = eventService.getEventsValue();


        scope.calendarView = 'month';
        scope.calendarDate = new Date();


        scope.startOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };

        scope.endOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };

        scope.navigateToCustomerCare = function () {
          $localStorage.dropdownVal = scope.fixedRateReviewsDropDowns;
          $state.go('app.customerCare', { activeTab: 0 });
        };
        init();
      },
    };
  });
