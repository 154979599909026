export const DEFAULT_VALUES = {
  EXISTING_STATUSID: 1,
  ESTABLISHED_STATUSID: 3,
  OWNEROCCUPIED_PRIMARYPURPOSE: 1,
  JOINT_HOLDINGID: 2,
  SOLE_HOLDINGID: 1,
  RESIDENTIAL_ZONINGID: 2,
  STDRESIDENTIAL_PROPERTYTYPE: 68,
  FULLY_DETACHED_HOUSE: 13,
  ASSET_FINANCE_IGNORE_SECURITY_TYPES: [3, 4, 5], // Term Deposit, Vehicle, Other
};

export const TRANSACTION_TYPE = {
  PURCHASING: 1,
  OWNS: 2,
  SOLD: 3,
  REFINANCE: 4,
  GUARANTEE: 5,
  CONSTRUCTION: 6,
};

export const PRIMARY_PURPOSE_VALUE = {
  OWNER_OCCUPIED: 1,
  INVESTMENT: 2,
};
