import { goToOpportunity } from 'Common/utilities/loanOpportunity';

export default class OpportunityNewModalService {
  constructor(modalRenderService, $state) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.$state = $state;
  }

  openNewOpportunityModal(familyId, clients, redirectParams = {}, pipelineItemId) {
    return this.modalRenderService.openNewOpportunityModal(familyId, clients, pipelineItemId)
      .result
      .then((result) => {
        if (!result) return;
        const {
          loanId,
          opportunityId,
        } = result;

        const {
          sourceFamilyId,
          sourceFamilyName,
          isContactOpener,
          tabName,
          isBusiness,
        } = redirectParams;

        const params = {
          loanId,
          opportunityId,
          sourceFamilyId: sourceFamilyId || 0,
          sourceFamilyName: sourceFamilyName || '',
          isContactOpener: isContactOpener || 0,
          tabName: tabName || 'details',
          isBusiness,
        };

        goToOpportunity(this.$state, params);

        return { isSuccess: true };
      }, () => {
        return { isSuccess: false };
      });
  }
}
