import './style.scss';
import template from './usersReferral.html';
import UsersReferralCtrl from './usersReferralCtrl';

export default {
  template,
  bindings: {
  },
  controller: UsersReferralCtrl,
  controllerAs: 'vm',
};
