class EmploymentDetailsCtrl {
  constructor(
    loanApplicationServices,
    employmentModalService,
    generalService,
    uiService,
    $timeout
  ) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.employmentModalService = employmentModalService;
    this.generalService = generalService;
    this.uiService = uiService;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.getEmployerType();
  }

  getEmployerType() {
    this.loanApplicationServices.getEmployerType().then((response) => {
      const { data } = response;
      if (!data) return;
      const newData = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        return obj;
      });
      this.employment.EmployerTypeList = [
        { Name: 'Please select', Value: '' },
        ...newData,
      ];
    });
  }

  getFormattedAddress() {
    this.$timeout.cancel(this.searchTimeout);
    const { Address } = this.employment;
    const isSearchQueryValid = Address && Address.formatted_address;
    this.searchTimeout = this.$timeout(() => {
      if (isSearchQueryValid) {
        this.generalService.placeSearch(this.employment.Address.formatted_address).then((response) => {
          this.addressSuggestions = response.data;
          this.showSuggestionModal = true;
        });
      }
    }, 500);
  }

  toggleSearch() {
    this.showSuggestionModal = !this.showSuggestionModal;
  }

  pickAddressSuggestion(homeAddress) {
    this.employment.Address = homeAddress;
    this.employment.Details.EmployerAddress = homeAddress;
    this.addressSuggestions = [];
    this.showSuggestionModal = false;
  }
}

export default EmploymentDetailsCtrl;
