import angular from 'angular';

angular.module('app').factory('overviewDashboardModel', ['httpRequest', function (httpRequest) {
  var apiBaseUrl = 'dashboard/';

  function overviewDashboardModel() {
  }

  overviewDashboardModel.prototype = {
    getLenderUpdates(days, callback) {
      httpRequest.get(`${apiBaseUrl}LenderUpdates?days=${days}`, (response) => {
        return callback(response);
      });
    },
    LayoutGet(callback) {
      httpRequest.get(`${apiBaseUrl}LayoutGet`, (response) => {
        return callback(response);
      });
    },
    LayoutListGet(position, callback) {
      httpRequest.get(`${apiBaseUrl}LayoutListGet?position=${position}`, (response) => {
        return callback(response);
      });
    },
    LayoutSet(layouts, callback) {
      httpRequest.post(`${apiBaseUrl}LayoutSet`, layouts, (response) => {
        return callback(response);
      });
    },

    UpComingEventTableGet(callback) {
      httpRequest.get(`${apiBaseUrl}UpComingEventTableGet`, (response) => {
        return callback(response);
      });
    },
    CommissionRecievedSmallGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}CommissionRecievedSmallGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    CommissionRecievedChartGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}CommissionRecievedChartGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    PipelineStatsGraphViewGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}PipelineStatsGraphViewGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    SystemUpdateTableGet(callback) {
      httpRequest.get(`${apiBaseUrl}SystemUpdateTableGet`, (response) => {
        return callback(response);
      });
    },
    InterestRateUpdateTableGet(callback) {
      httpRequest.get(`${apiBaseUrl}InterestRateUpdateTableGet`, (response) => {
        return callback(response);
      });
    },
    ClientReviewSmallGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}ClientReviewSmallGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    ProviderUpdateTableGet(callback) {
      httpRequest.get(`${apiBaseUrl}ProviderUpdateTableGet`, (response) => {
        return callback(response);
      });
    },
    FixedRateReviewSmallGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}FixedRateReviewSmallGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    WidgetPiplineLodgedGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}WidgetPiplineLodgedGet?userWidgetID=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    WidgetPiplineSettledGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}WidgetPiplineSettledGet?userWidgetID=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    WidgetPiplineApprovedGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}WidgetPiplineApprovedGet?userWidgetID=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    UpcomingBirthdaySmallGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}UpcomingBirthdaySmallGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    TaskSmallGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}TaskSmallGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    CommissionRecievedListGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}CommissionRecievedListGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    ClientReviewTableGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}ClientReviewTableGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    FixedRateReviewTableGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}FixedRateReviewTableGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    WidgetPipelineNewLeadsGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}WidgetPipelineNewLeadsGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    WidgetPipelineStatsTableViewGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}WidgetPipelineStatsTableViewGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    PreApprovalExpiryListGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}PreApprovalExpiryListGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    PreApprovalExpiryCountGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}PreApprovalExpiryCountGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    UpcomingBirthdayTableGet(userWidgetId, reportingStateId, callback) {
      httpRequest.get(`${apiBaseUrl}UpcomingBirthdayTableGet?userWidgetId=${userWidgetId}&reportingStateId=${reportingStateId}`, (response) => {
        return callback(response);
      });
    },
    TasksTableGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}TasksTableGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    CommissionExpectedListGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}CommissionExpectedListGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    CommissionExpectedSmallGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}CommissionExpectedSmallGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTimePeriodList7Get(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTimePeriodList7Get`, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTimePeriodList8Get(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTimePeriodList8Get`, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTimePeriodListv1Get(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTimePeriodListv1Get`, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTimePeriodListv2Get(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTimePeriodListv2Get`, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTimePeriodListv3Get(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTimePeriodListv3Get`, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTimePeriodListv4Get(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTimePeriodListv4Get`, (response) => {
        return callback(response);
      });
    },
    WidgetOptionAdviserListGet(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionAdviserListGet `, (response) => {
        return callback(response);
      });
    },
    WidgetOptionAdviserCommissionList(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionAdviserCommissionList `, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTimePeriodListv5Get(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTimePeriodListv5Get `, (response) => {
        return callback(response);
      });
    },
    WidgetOptionSet(OptionToSet, callback) {
      httpRequest.post(`${apiBaseUrl}WidgetOptionSet`, OptionToSet, (response) => {
        return callback(response);
      });
    },
    WidgetOptionGet(userWidgetId, callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionGet?userWidgetId=${userWidgetId}`, (response) => {
        return callback(response);
      });
    },
    WidgetYesNoGet(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetYesNoGet `, (response) => {
        return callback(response);
      });
    },
    WidgetOptionLenderListGet(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionLenderListGet `, (response) => {
        return callback(response);
      });
    },
    WidgetOptionTaskTypeListGet(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetOptionTaskTypeListGet `, (response) => {
        return callback(response);
      });
    },
    WidgetTaskTypeGet(callback) {
      httpRequest.get(`${apiBaseUrl}WidgetTaskTypeGet`, (response) => {
        return callback(response);
      });
    },
    WidgetTypeGet(widgetName, callback) {
      httpRequest.get(`${apiBaseUrl}WidgetTypeGet?widgetName=${widgetName}`, (response) => {
        return callback(response);
      });
    },
    CategorySet(categoryObj, callback) {
      httpRequest.post(`${apiBaseUrl}CategorySet`, categoryObj, (response) => {
        return callback(response);
      });
    },


  };

  return overviewDashboardModel;
}]);
