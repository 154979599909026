import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import {
  expectedCommissionsQueryBuilderForMyCRM,
  expectedCommissionsSummaryBuilderForUI,
  oneOffCommissionRatesBuilderForUI,
  loansOneOffCommissionsBuilderForUI,
  commissionLoanStructureBuilderForUI,
  loansExpectedCommissionsRecurringBuilderForUI,
} from 'Common/mappers/commission';
import {
  subscriptionInfoBuilderForUI,
  subscriptionBuilderForMyCRM,
  subscriptionPricingBuilderForUI
} from 'Common/mappers/subscription';
import {
  emailSignatureImageBuilderForUI,
  emailSignatureSettingsBuilderForUI,
  emailSignatureSettingsBuilderForMyCRM,
  emailSignaturePreviewDetailsBuilderForUI
} from 'Common/mappers/emailSignature';
import {
  convertContentHtmlToRtfBuilderForUI,
} from 'Common/mappers/contact';

angular.module('app').factory('contactService', ['$q', 'ContactModel', function ($q, ContactModel) {
  var factory = this;

  var contact = new ContactModel();


  factory.clientInformGet = function (familyID, clientId, isHttpRequest) {
    var defer = $q.defer();

    contact.clientInformGet(familyID, clientId, isHttpRequest, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.familyAddressInformGet = function (familyID) {
    var defer = $q.defer();

    contact.familyAddressInformGet(familyID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  /*
 //Non existing
  factory.transList = function (familyID) {
    var defer = $q.defer();

    contact.transList(familyID, function (response) {
      factory.data = response.data;
      return defer.resolve(response);
    }, function (error) {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
*/


  factory.organisationInfoGet = function (id) {
    var defer = $q.defer();

    contact.organisationInfoGet(id, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.organisationInfoSet = function (org) {
    var defer = $q.defer();

    contact.organisationInfoSet(org, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.categorySet = function (familyId, categoryId, categoryName) {
    var defer = $q.defer();

    contact.categorySet(familyId, categoryId, categoryName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.notesList = function (familyID, count, brokerbrandingId) {
    var defer = $q.defer();

    contact.notesList(familyID, count, brokerbrandingId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.noteGet = function (familyID, commId, brokerBrandingId, isOpportunity = false) {
    var defer = $q.defer();

    contact.noteGet(familyID, commId, brokerBrandingId, isOpportunity, (response) => {
      factory.data = response.data;

      _.remove(factory.data, (o) => {
        o.IsActive == false;
      });
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.notesListCount = function (familyID, brokerbrandingId) {
    var defer = $q.defer();
    contact.notesListCount(familyID, brokerbrandingId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.noteSet = function (obj) {
    var defer = $q.defer();

    contact.noteSet(obj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.noteDelete = function (id) {
    var defer = $q.defer();

    contact.noteDelete(id, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.taggedList = function (familyID) {
    var defer = $q.defer();

    contact.taggedList(familyID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.taggedSet = function (familyID, taggedReason) {
    var defer = $q.defer();

    contact.taggedSet(familyID, taggedReason, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.taggedDelete = function (familyID, taggedId) {
    var defer = $q.defer();

    contact.taggedDelete(familyID, taggedId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Active Or Inactive Tasks
  //-----------------------------------------------------------------------------------

  factory.tasksList = function (familyID, count) {
    var defer = $q.defer();

    contact.tasksList(familyID, count, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.adviserCommentGet = function (familyID) {
    var defer = $q.defer();

    contact.adviserCommentGet(familyID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };


  factory.adviserCommentSet = function (familyID, comment) {
    var defer = $q.defer();

    contact.adviserCommentSet(familyID, comment, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.taskAdviserListGet = function () {
    var defer = $q.defer();

    contact.taskAdviserListGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.taskSet = function ($postData) {
    var defer = $q.defer();

    contact.taskSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  // factory.TaskCompletionSet = function (taskCompletionObj) {
  //  var defer = $q.defer();
  //
  //  contact.setTaskCompletion(taskCompletionObj, function (response) {
  //    factory.data = response.data;
  //    return defer.resolve(response);
  //  }, function (error) {
  //    factory.data = [];
  //    return defer.reject(error);
  //  });
  //
  //  return defer.promise;
  // };

  factory.getTaskListCount = function (familyID) {
    var defer = $q.defer();

    contact.getTaskListCount(familyID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setTaskCompletion = function ($postData) {
    var defer = $q.defer();

    contact.setTaskCompletion($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];

      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAllByFilter = function (startWith, page, count, sortColumn, sortMode, searchCriteria, contactTypes, adviserFamilyId, clientContactStatusId, clientRecordType, ratings = '', categories = '') {
    var defer = $q.defer();

    contact.getAllByFilter(startWith, page, count, sortColumn, sortMode, searchCriteria, contactTypes, adviserFamilyId, clientContactStatusId, clientRecordType, ratings, categories, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAdviserClient = function (startWith) {
    var defer = $q.defer();
    contact.getAdviserClient(startWith, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.searchFamily = function (searchCriteria, adviserId, byPassFilter) {
    var defer = $q.defer();

    contact.searchFamily(searchCriteria, adviserId, byPassFilter, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.searchContacts = function (searchCriteria, adviserId) {
    var defer = $q.defer();

    contact.searchContacts(searchCriteria, adviserId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.searchFamilyContactType = function (searchCriteria, contactTypes, adviserFamilyId) {
    var defer = $q.defer();

    contact.searchFamilyContactType(searchCriteria, contactTypes, adviserFamilyId	, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.contactSummaryPDFGet = function (familyID) {
    var defer = $q.defer();

    contact.contactSummaryPDFGet(familyID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.deleteContact = function (familyId) {
    var defer = $q.defer();

    contact.deleteContact(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      if (!_.isNil(error.data.Message)) {
        swal('Delete Message', error.data.Message, 'error');
      } else {
        swal('Delete Message', 'Unable to delete contact', 'error');
      }

      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.contactClientDelete = function (familyId, clientId) {
    var defer = $q.defer();

    contact.contactClientDelete(familyId, clientId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.contactFamilyInfoGet = function (familyId) {
    var defer = $q.defer();
    contact.contactFamilyInfoGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.contactDetailsSMSGet = function (clientId, familyId) {
    var defer = $q.defer();
    contact.contactDetailsSMSGet(clientId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.GeoCodeSearch = function (address, latitude, longitude) {
    var defer = $q.defer();
    contact.GeoCodeSearch(address, latitude, longitude, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.contactFamilyInfoSet = function (obj) {
    var defer = $q.defer();
    contact.contactFamilyInfoSet(obj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.familyInfoGet = function (familyId, clientId) {
    var defer = $q.defer();
    contact.familyInfoGet(familyId, clientId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.familyInfoSet = function (obj) {
    var defer = $q.defer();
    contact.familyInfoSet(obj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };


  factory.relationshipGet = function (familyId, entityId) {
    var defer = $q.defer();
    contact.relationshipGet(familyId, entityId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.contactRelationshipGet = function (familyId, entityId) {
    var defer = $q.defer();
    contact.contactRelationshipGet(familyId, entityId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.relationshipSet = function (obj) {
    var defer = $q.defer();

    contact.relationshipSet(obj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.relationshipDelete = function (familyId, GroupId) {
    var defer = $q.defer();

    contact.relationshipDelete(familyId, GroupId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.roleType = function () {
    var defer = $q.defer();

    contact.roleType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.titleType = function () {
    var defer = $q.defer();

    contact.titleType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addContact = function ($postData) {
    var defer = $q.defer();

    contact.addContact($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  // Loan
  //-----------------------------------------------------------------------------------
  factory.loanList = function (familyId) {
    var defer = $q.defer();

    contact.loanList(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanListGet = function (familyId, includeOpportunity = false) {
    var defer = $q.defer();

    contact.loanListGet(familyId, includeOpportunity, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanImportantDatesSet = function (loanId, type, dateValue, refNo, isEstimated) {
    var defer = $q.defer();

    contact.loanImportantDatesSet(loanId, type, dateValue, refNo, isEstimated, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanImportantDatesDelete = function (loanId, type, isEstimated) {
    var defer = $q.defer();

    contact.loanImportantDatesDelete(loanId, type, isEstimated, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanListGetNewDesign = function (familyId, statusName) {
    var defer = $q.defer();

    contact.loanListGetNewDesign(familyId, statusName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanStatusListGet = function () {
    var defer = $q.defer();

    contact.loanStatusListGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLoanCalculations = function (familyId) {
    var defer = $q.defer();

    contact.getLoanCalculations(familyId, (response) => {
      factory.data = response.data;
        return defer.resolve(response);
      }, (error) => {
        factory.data = [];
        return defer.reject(error);
      });

    return defer.promise;
  };

  factory.savedCalculationsGet = function (familyId) {
    var defer = $q.defer();

    contact.savedCalculationsGet(familyId, (response) => {
      factory.data = response.data;
        return defer.resolve(response);
      }, (error) => {
        factory.data = [];
        return defer.reject(error);
      });

    return defer.promise;
  };


  factory.calculationsSet = function (id, postData) {
    var defer = $q.defer();

    contact.calculationsSet(id, postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // savedCalculationsDelete : function(type,loanScenarioId,brokerEventId, callback){

  factory.savedCalculationsDelete = function (type, loanScenarioId, brokerEventId) {
    var defer = $q.defer();

    contact.savedCalculationsDelete(type, loanScenarioId, brokerEventId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.loanDetailsGet = function (loanId) {
    var defer = $q.defer();

    contact.loanDetailsGet(loanId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanSet = function ($postData) {
    var defer = $q.defer();

    contact.loanSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanDelete = function (loanId) {
    var defer = $q.defer();

    contact.loanDelete(loanId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanStructureDetailsGet = function (loanId, loanStructureId) {
    var defer = $q.defer();

    contact.loanStructureDetailsGet(loanId, loanStructureId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanStructureSet = function ($postData) {
    var defer = $q.defer();

    contact.loanStructureSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanStructureDelete = function (loanId, loanStructureId) {
    var defer = $q.defer();

    contact.loanStructureDelete(loanId, loanStructureId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.lendingScenarioDelete = function (lendingScenarioId) {
    var defer = $q.defer();
    contact.lendingScenarioDelete(lendingScenarioId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.scenarioClientSet = function (postData) {
    var defer = $q.defer();
    contact.scenarioClientSet(postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  /*
  * Creating Loan Scenario
  * */

  factory.lendingScenarioSet = function (obj) {
    var defer = $q.defer();

    contact.lendingScenarioSet(obj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.FixedRateHistoryGet = function (loanStructureId) {
    var defer = $q.defer();

    contact.FixedRateHistoryGet(loanStructureId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.CurrentFixedRateGet = function (loanStructureId) {
    var defer = $q.defer();

    contact.CurrentFixedRateGet(loanStructureId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.FixedRatesSet = function (obj) {
    var defer = $q.defer();

    contact.FixedRatesSet(obj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.FixedRatesDelete = function (loanStructureId, rateId) {
    var defer = $q.defer();

    contact.FixedRatesDelete(loanStructureId, rateId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Borrower
  //-----------------------------------------------------------------------------------
  factory.borrowersDetailsGet = function (familyId, loanId, isIncludeGuarantor) {
    var defer = $q.defer();

    contact.borrowersDetailsGet(familyId, loanId, isIncludeGuarantor, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Insurance
  //-----------------------------------------------------------------------------------
  factory.insuranceList = function (familyId) {
    var defer = $q.defer();

    contact.insuranceList(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.deleteInsurance = function (familyId, benefitId) {
    var defer = $q.defer();

    contact.deleteInsurance(familyId, benefitId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.generalInsuranceListGet = function (familyId) {
    var defer = $q.defer();

    contact.generalInsuranceListGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.insuranceListGetNew = function (familyId, providerId, policyNumber) {
    var defer = $q.defer();

    contact.insuranceListGetNew(familyId, providerId, policyNumber, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.insuranceDetailsGet = function (benefitId, familyId) {
    var defer = $q.defer();

    contact.insuranceDetailsGet(benefitId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.insurersGet = function (benefitID) {
    var defer = $q.defer();

    contact.insurersGet(benefitID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.productsGet = function (benefitID, insurerID) {
    var defer = $q.defer();

    contact.productsGet(benefitID, insurerID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanProductListGet = function (providerID) {
    var defer = $q.defer();

    contact.loanProductListGet(providerID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.insuranceTypesGet = function () {
    var defer = $q.defer();

    contact.insuranceTypesGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.insuranceStatusesGet = function (type) {
    var defer = $q.defer();

    contact.insuranceStatusesGet(type, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.insuranceDetailsSet = function (data) {
    var defer = $q.defer();

    contact.insuranceDetailsSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Security Structure
  //-----------------------------------------------------------------------------------
  factory.securityDetailsGet = function (familyId, securityId) {
    var defer = $q.defer();
    contact.securityDetailsGet(familyId, securityId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.securityListGet = function (loanId) {
    var defer = $q.defer();
    contact.securityListGet(loanId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.securityClientsListGet = function (familyId, loanId) {
    var defer = $q.defer();
    contact.securityClientsListGet(familyId, loanId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.securitySet = function ($postData) {
    var defer = $q.defer();
    contact.securitySet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.securityDelete = function (securityId) {
    var defer = $q.defer();
    contact.securityDelete(securityId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.lenderListGet = function (isCommercial, lenderPlace) {
    var defer = $q.defer();

    contact.lenderListGet(isCommercial, lenderPlace, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Client Type , Rating , Category , Referral
  //-----------------------------------------------------------------------------------
  factory.addClientType = function (clientTypeName) {
    var defer = $q.defer();
    contact.addClientType(clientTypeName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.updateClientType = function (clientTypeID, clientTypeName) {
    var defer = $q.defer();
    contact.updateClientType(clientTypeID, clientTypeName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.updateOrder = function (clientTypeID, orderBy) {
    var defer = $q.defer();
    contact.updateOrder(clientTypeID, orderBy, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getClientType = function () {
    var defer = $q.defer();

    contact.getClientType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.deleteClientType = function (clientTypeID) {
    var defer = $q.defer();
    contact.deleteClientType(clientTypeID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addRating = function (ratingName) {
    var defer = $q.defer();
    contact.addRating(ratingName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.updateRating = function (ratingID, ratingName) {
    var defer = $q.defer();
    contact.updateRating(ratingID, ratingName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.updateRatingOrder = function (ratingID, orderBy) {
    var defer = $q.defer();
    contact.updateRatingOrder(ratingID, orderBy, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getRating = function () {
    var defer = $q.defer();

    contact.getRating((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.deleteRating = function (ratingID) {
    var defer = $q.defer();
    contact.deleteRating(ratingID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addCategory = function (categoryName) {
    var defer = $q.defer();
    contact.addCategory(categoryName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.updateCategory = function (categoryID, categoryName) {
    var defer = $q.defer();
    contact.updateCategory(categoryID, categoryName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.updateCategoryOrder = function (categoryID, orderBy) {
    var defer = $q.defer();
    contact.updateCategoryOrder(categoryID, orderBy, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.updateRefCategoryOrder = function (ReferralCategoryID, orderBy) {
    var defer = $q.defer();
    contact.updateRefCategoryOrder(ReferralCategoryID, orderBy, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.updateRefItemOrder = function (referralCategoryID, referralItemId, orderBy) {
    var defer = $q.defer();
    contact.updateRefItemOrder(referralCategoryID, referralItemId, orderBy, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getCategory = function () {
    var defer = $q.defer();

    contact.getCategory((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.categoryListsGet = function (familyId) {
    var defer = $q.defer();

    contact.categoryListsGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.deleteCategory = function (categoryID) {
    var defer = $q.defer();
    contact.deleteCategory(categoryID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getReferralCategory = function () {
    var defer = $q.defer();

    contact.getReferralCategory((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getReferralChildList = function (referralID) {
    var defer = $q.defer();

    contact.getReferralChildList(referralID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.updateRefCategory = function (referralCategoryID, referralCategoryName) {
    var defer = $q.defer();
    contact.updateRefCategory(referralCategoryID, referralCategoryName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.updateRefCategoryItem = function (referralItemID, itemName) {
    var defer = $q.defer();
    contact.updateRefCategoryItem(referralItemID, itemName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.deleteReferralCategory = function (referralCategoryID) {
    var defer = $q.defer();
    contact.deleteReferralCategory(referralCategoryID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addReferralCategory = function (referralCategoryName) {
    var defer = $q.defer();
    contact.addReferralCategory(referralCategoryName, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addReferralItem = function (referralCategoryID, itemName, ItemPhone) {
    var defer = $q.defer();
    contact.addReferralItem(referralCategoryID, itemName, ItemPhone, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.deleteReferralItem = function (referralItemId) {
    var defer = $q.defer();
    contact.deleteReferralItem(referralItemId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // financials Tab
  //-----------------------------------------------------------------------------------
  factory.assetInfoGet = function (familyId) {
    var defer = $q.defer();

    contact.assetInfoGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.liabilityInfoGet = function (familyId) {
    var defer = $q.defer();

    contact.liabilityInfoGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.incomeInfoGet = function (familyId) {
    var defer = $q.defer();

    contact.incomeInfoGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.incomeClientGet = function (familyId) {
    var defer = $q.defer();

    contact.incomeClientGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.expenseInfoGet = function (familyId) {
    var defer = $q.defer();

    contact.expenseInfoGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.assetDetailsSet = function (data) {
    var defer = $q.defer();
    contact.assetDetailsSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.liabilityDetailsSet = function (data) {
    var defer = $q.defer();
    contact.liabilityDetailsSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.incomeDetailsSet = function (data) {
    var defer = $q.defer();
    contact.incomeDetailsSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.expenseDetailsSet = function (data) {
    var defer = $q.defer();
    contact.expenseDetailsSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.assetDelete = function (familyId, assetId) {
    var defer = $q.defer();
    contact.assetDelete(familyId, assetId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.deleteAsset = function (assetId, familyId) {
    var defer = $q.defer();
    contact.deleteAsset(assetId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.liabilityDelete = function (familyId) {
    var defer = $q.defer();
    contact.liabilityDelete(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.deleteLiability = function (liabilityId, familyId) {
    var defer = $q.defer();
    contact.deleteLiability(liabilityId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.incomeDelete = function (familyId) {
    var defer = $q.defer();
    contact.incomeDelete(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.deleteIncome = function (incomeId, familyId) {
    var defer = $q.defer();
    contact.deleteIncome(incomeId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.expenseDelete = function (familyId) {
    var defer = $q.defer();
    contact.expenseDelete(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.deleteExpense = function (expenseId, familyId) {
    var defer = $q.defer();
    contact.deleteExpense(expenseId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  // #############################

  factory.getIncomeList = function (familyId) {
    var defer = $q.defer();

    contact.getIncomeList(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getExpenseList = function (familyId) {
    var defer = $q.defer();

    contact.getExpenseList(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAssetList = function (familyId) {
    var defer = $q.defer();

    contact.getAssetList(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLiabilityList = function (familyId) {
    var defer = $q.defer();

    contact.getLiabilityList(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getExpenseType = function () {
    var defer = $q.defer();

    contact.getExpenseType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLiabilityType = function () {
    var defer = $q.defer();

    contact.getLiabilityType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAssetType = function () {
    var defer = $q.defer();

    contact.getAssetType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getIncomeType = function () {
    var defer = $q.defer();

    contact.getIncomeType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLoanIncomeFrequency = function () {
    var defer = $q.defer();

    contact.getLoanIncomeFrequency((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLoanTerm = function () {
    var defer = $q.defer();

    contact.getLoanTerm((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getOrSetVlaue = function () {
    return {
      get() {
        return factory._dataObj;
      },
      set(value) {
        factory._dataObj = value;
      },
    };
  };

  factory.getExpenseClientList = function (familyId, expenseId) {
    var defer = $q.defer();

    contact.getExpenseClientList(familyId, expenseId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addExpense = function ($postdata) {
    var defer = $q.defer();

    contact.addExpense($postdata, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLiabilityClientList = function (familyId, liabilityId) {
    var defer = $q.defer();

    contact.getLiabilityClientList(familyId, liabilityId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addLiability = function ($postdata) {
    var defer = $q.defer();

    contact.addLiability($postdata, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLiabilityDetails = function (familyId, liabilityId) {
    var defer = $q.defer();

    contact.getLiabilityDetails(familyId, liabilityId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getExpenseDetails = function (familyId, expenseId) {
    var defer = $q.defer();

    contact.getExpenseDetails(familyId, expenseId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getIncomeClientList = function (familyId, incomeId) {
    var defer = $q.defer();

    contact.getIncomeClientList(familyId, incomeId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getIncomeAssetsList = function (familyId, incomeId) {
    var defer = $q.defer();

    contact.getIncomeAssetsList(familyId, incomeId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getIncomeDetails = function (familyId, incomeId) {
    var defer = $q.defer();

    contact.getIncomeDetails(familyId, incomeId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getIncomeAssetAddress = function (assetId) {
    var defer = $q.defer();

    contact.getIncomeAssetAddress(assetId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addIncome = function ($postdata) {
    var defer = $q.defer();

    contact.addIncome($postdata, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAssetClientList = function (familyId, assetId) {
    var defer = $q.defer();

    contact.getAssetClientList(familyId, assetId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addAsset = function ($postdata) {
    var defer = $q.defer();

    contact.addAsset($postdata, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAssetDetails = function (familyId, assetId) {
    var defer = $q.defer();

    contact.getAssetDetails(familyId, assetId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  // Documents Tab
  //-----------------------------------------------------------------------------------

  factory.getDocumentList = function (familyId, searchName, OrganisationId, forLoanApp) {
    var defer = $q.defer();

    contact.getDocumentList(familyId, searchName, OrganisationId, forLoanApp, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getDocument = function (documentId, familyId) {
    var defer = $q.defer();

    contact.getDocument(documentId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.removeDocument = function (familyId, documentId) {
    var defer = $q.defer();

    contact.removeDocument(familyId, documentId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.documentThumbnailGet = function (documentObj) {
    var defer = $q.defer();
    contact.documentThumbnailGet(documentObj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.documentPropertySet = function (documentObj) {
    var defer = $q.defer();
    contact.documentPropertySet(documentObj, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.documentImageDataURIGet = function (documentId, thumbWidth) {
    var defer = $q.defer();
    thumbWidth = thumbWidth || 0;
    contact.documentImageDataURIGet(documentId, thumbWidth, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  // Recent Activity
  //-----------------------------------------------------------------------------------

  factory.geRecentActivityList = function (familyId, count) {
    var defer = $q.defer();
    contact.geRecentActivityList(familyId, count, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Emails Tab
  //-----------------------------------------------------------------------------------

  factory.getEmailList = function (familyId, pageNumber, pageSize, includeGoogleMail, isIncludeMyCRMEmail, isIncludeSMS, searchCriteria) {
    var defer = $q.defer();

    contact.getEmailList(familyId, pageNumber, pageSize, includeGoogleMail, isIncludeMyCRMEmail, isIncludeSMS, searchCriteria, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.removeEmail = function (familyId, emailId) {
    var defer = $q.defer();

    contact.removeEmail(familyId, emailId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.sendEmail = function ($postData) {
    var defer = $q.defer();

    contact.sendEmail($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getRecipientAutoSuggestion = function (userInput) {
    var defer = $q.defer();

    contact.getRecipientAutoSuggestion(userInput, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getEmail = function (familyId, emailId) {
    var defer = $q.defer();

    contact.getEmail(familyId, emailId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getEmailTemplateCategory = function (familyId) {
    var defer = $q.defer();

    contact.getEmailTemplateCategory(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getEmailTemplateContent = function (userTemplateId, familyId) {
    var defer = $q.defer();

    contact.getEmailTemplateContent(userTemplateId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

   factory.convertContentHtmlToRTF = function (postData) {
    var defer = $q.defer();

    contact.convertContentHtmlToRTF(postData, (response) => {
    return defer.resolve(convertContentHtmlToRtfBuilderForUI(response.data));
  }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Address Tab
  //-----------------------------------------------------------------------------------
  factory.contactAddressGet = function (familyId) {
    var defer = $q.defer();

    contact.contactAddressGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.contactAddressDetailsGet = function (addressValuationExtendedDetailsId, familyId) {
    var defer = $q.defer();

    contact.contactAddressDetailsGet(addressValuationExtendedDetailsId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.contactAddressSet = function ($postData) {
    var defer = $q.defer();
    contact.contactAddressSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.contactPropertySet = function ($postData) {
    var defer = $q.defer();
    contact.contactPropertySet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.contactPropertyDelete = function (addressValuationExtendedDetailsId, familyId) {
    var defer = $q.defer();
    contact.contactPropertyDelete(addressValuationExtendedDetailsId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.contactAddressDelete = function (addressValuationExtendedDetailsId, familyId) {
    var defer = $q.defer();
    contact.contactAddressDelete(addressValuationExtendedDetailsId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.getContactType = function () {
    var defer = $q.defer();

    contact.getContactType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getEntityType = function () {
    var defer = $q.defer();

    contact.getEntityType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getAdminUser = function () {
    var defer = $q.defer();

    contact.getAdminUser((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getOperatingCountry = function () {
    var defer = $q.defer();

    contact.getOperatingCountry((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getOrganizationStatus = function () {
    var defer = $q.defer();

    contact.getOrganizationStatus((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getAdviserUnderOrganization = function (id) {
    var defer = $q.defer();

    contact.getAdviserUnderOrganization(id, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.setAdviserOrganization = function (saveType, data) {
    var defer = $q.defer();

    contact.setAdviserOrganization(saveType, data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.setFamilyAddress = function (id, clientId, addressId, isMailing, isBusiness) {
    var defer = $q.defer();

    contact.setFamilyAddress(id, clientId, addressId, isMailing, isBusiness, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getAdviserOrganizationList = function () {
    var defer = $q.defer();

    contact.getAdviserOrganizationList((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getBrokerStatusList = function () {
    var defer = $q.defer();

    contact.getBrokerStatusList((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getContactTypeRoles = function () {
    var defer = $q.defer();

    contact.getContactTypeRoles((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getReportingStates = function () {
    var defer = $q.defer();

    contact.getReportingStates((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getReportingRegion = function (countryId) {
    var defer = $q.defer();

    contact.getReportingRegion(countryId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getCommissionTiers = function (countryId) {
    var defer = $q.defer();

    contact.getCommissionTiers(countryId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getAdviserAchievements = function () {
    var defer = $q.defer();

    contact.getAdviserAchievements((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getEnquirySources = function () {
    var defer = $q.defer();

    contact.getEnquirySources((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getGeneralInterest = function () {
    var defer = $q.defer();

    contact.getGeneralInterest((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getSportsTeam = function () {
    var defer = $q.defer();

    contact.getSportsTeam((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getEmploymentType = function () {
    var defer = $q.defer();

    contact.getEmploymentType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.setAdviser = function (saveType, data) {
    var defer = $q.defer();

    contact.setAdviser(saveType, data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getAdviserOrgAddress = function (orgId) {
    var defer = $q.defer();

    contact.getAdviserOrgAddress(orgId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.familyListGet = function (searchString, byPassFilter) {
    var defer = $q.defer();

    contact.familyListGet(searchString, byPassFilter, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getUserInfo = function () {
    var defer = $q.defer();

    contact.getUserInfo((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setOrganizationAddress = function (organizationId, orgComplianceId, addressId, isMailing) {
    var defer = $q.defer();

    contact.setOrganizationAddress(organizationId, orgComplianceId, addressId, isMailing, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getOrganizationType = function () {
    var defer = $q.defer();

    contact.getOrganizationType((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setReferrerOrg = function (data) {
    var defer = $q.defer();

    contact.setReferrerOrg(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.addressIDGet = function (familyId) {
    var defer = $q.defer();

    contact.addressIDGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.getIfOnboardingStatus = function (familyId, clientId) {
    var defer = $q.defer();

    contact.getIfOnboardingStatus(familyId, clientId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAdviserContactSearchOptions = function (familyId) {
    var defer = $q.defer();

    contact.getAdviserContactSearchOptions(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  // Employment/ID
  factory.clientEntityIdentificationGet = function (familyId, identificationId) {
    var defer = $q.defer();

    contact.clientEntityIdentificationGet(familyId, identificationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  /* factory.clientEntityIdentificationSet = function (identificationDocId,clientId,entityId, nameOnDoc, documentName, documentTypeID, documentNumber, issueDate, expiryDate, placeOfissue, countryCode) { */
  factory.clientEntityIdentificationSet = function (data) {
    var defer = $q.defer();

    // contact.clientEntityIdentificationSet(identificationDocId,clientId,entityId, nameOnDoc, documentName, documentTypeID, documentNumber, issueDate, expiryDate, placeOfissue, countryCode, function (response) {
    contact.clientEntityIdentificationSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.clientEntityIdentificationDelete = function (identificationId) {
    var defer = $q.defer();

    contact.clientEntityIdentificationDelete(identificationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.clientEntityEmploymentGet = function (familyId, identificationId) {
    var defer = $q.defer();

    contact.clientEntityEmploymentGet(familyId, identificationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.clientEntityEmploymentSet = function (data) {
    var defer = $q.defer();

    contact.clientEntityEmploymentSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.clientEntityEmploymentDelete = function (employmentId) {
    var defer = $q.defer();

    contact.clientEntityEmploymentDelete(employmentId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.getSkipWelcomeScreen = function (familyId) {
    var defer = $q.defer();

    contact.getSkipWelcomeScreen(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.setSkipWelcomeScreen = function (familyId, skipWelcomeScreen) {
    var defer = $q.defer();

    contact.setSkipWelcomeScreen(familyId, skipWelcomeScreen, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  // Loan Application (Previously Loan Scenario)

  factory.loanApplicationCompletionStatusGet = function (loanApplicationId) {
    var defer = $q.defer();

    contact.loanApplicationCompletionStatusGet(loanApplicationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loanApplicationCompletionStatusSet = function (loanApplicationId, sectionId) {
    var defer = $q.defer();

    contact.loanApplicationCompletionStatusSet(loanApplicationId, sectionId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  factory.getLoanApplicationCQP = function (loanApplicationId) {
    var defer = $q.defer();

    contact.getLoanApplicationCQP(loanApplicationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setLoanApplicationCQP = function (loanApplicationId, lenderId) {
    var defer = $q.defer();

    contact.setLoanApplicationCQP(loanApplicationId, lenderId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLoanApplicationCQPFeeStructure = function (loanApplicationId) {
    var defer = $q.defer();

    contact.getLoanApplicationCQPFeeStructure(loanApplicationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.deleteLoanApplicationCQPFeeStructure = function (loanApplicationId, LoanApplicationCQPFeeId) {
    var defer = $q.defer();

    contact.deleteLoanApplicationCQPFeeStructure(loanApplicationId, LoanApplicationCQPFeeId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setLoanApplicationCQPFeeStructure = function (structure) {
    var defer = $q.defer();

    contact.setLoanApplicationCQPFeeStructure(structure, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getLoanApplicationCQPReferralFeeStructure = function (loanApplicationId) {
    var defer = $q.defer();

    contact.getLoanApplicationCQPReferralFeeStructure(loanApplicationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.deleteLoanApplicationCQPReferralFeeStructure = function (loanApplicationId, LoanApplicationCQPReferralFeeId) {
    var defer = $q.defer();

    contact.deleteLoanApplicationCQPReferralFeeStructure(loanApplicationId, LoanApplicationCQPReferralFeeId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setLoanApplicationCQPReferralFeeStructure = function (structure) {
    var defer = $q.defer();

    contact.setLoanApplicationCQPReferralFeeStructure(structure, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getCQPDocumentGet = function (loanApplicationId) {
    var defer = $q.defer();

    contact.getCQPDocumentGet(loanApplicationId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setClientTypeCorpUser = function (clientTypeId, isExclusiveForCorpUser) {
    var defer = $q.defer();

    contact.setClientTypeCorpUser(clientTypeId, isExclusiveForCorpUser, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setContactCategoryCorpUser = function (categoryId, isExclusiveForCorpUser) {
    var defer = $q.defer();

    contact.setContactCategoryCorpUser(categoryId, isExclusiveForCorpUser, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setReferralCategoryCorpUser = function (referralCategoryId, isExclusiveForCorpUser) {
    var defer = $q.defer();

    contact.setReferralCategoryCorpUser(referralCategoryId, isExclusiveForCorpUser, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.setReferralItemCorpUser = function (referralItemId, isExclusiveForCorpUser) {
    var defer = $q.defer();

    contact.setReferralItemCorpUser(referralItemId, isExclusiveForCorpUser, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getPropertyRelatedClient = function (familyId, addressId) {
    var defer = $q.defer();

    contact.getPropertyRelatedClient(familyId, addressId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.putAssignAdviser = function (familyId, assignedAdviserId) {
    var defer = $q.defer();

    contact.putAssignAdviser(familyId, assignedAdviserId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getContactAssignedAdviser = function (familyId) {
    var defer = $q.defer();

    contact.getContactAssignedAdviser(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.sendSms = function (data) {
    var defer = $q.defer();

    contact.sendSms(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.LoanDetailsSet = function (data) {
    var defer = $q.defer();

    contact.LoanDetailsSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.EnquiryCategoryReorder = function (familyId, referralCategoryID, orderBy) {
    var defer = $q.defer();

    contact.EnquiryCategoryReorder(familyId, referralCategoryID, orderBy, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.RecentActivityFeedListGet = function (familyId, brokerBrandingId = 0) {
    var defer = $q.defer();

    contact.RecentActivityFeedListGet(familyId, brokerBrandingId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.RecentActivityListFullDetailsGet = function (familyId, brokerBrandingId, adviserId, notificationTypeId) {
    var defer = $q.defer();

    contact.RecentActivityListFullDetailsGet(familyId, brokerBrandingId, adviserId, notificationTypeId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.adviserOrgCommentGet = function (adviserOrgId) {
    var defer = $q.defer();

    contact.AdviserOrgCommentGet(adviserOrgId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.adviserOrgCommentSet = function (adviserOrgId, comment) {
    var defer = $q.defer();

    contact.AdviserOrgCommentSet(adviserOrgId, comment, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.LoanStatusSet = function (loanId, statusId, shouldSendNurtureCampaigns, reasonSubstatusID) {
    var defer = $q.defer();

    contact.LoanStatusSet(loanId, statusId, shouldSendNurtureCampaigns, reasonSubstatusID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.clientEntityPrimarySet = function (clientId, entityId) {
    var defer = $q.defer();

    contact.clientEntityPrimarySet(clientId, entityId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.loansExpectedCommissionsGet = (loanId, expectedCommissionId) => {
    var defer = $q.defer();

    if (loanId) {
      contact.loansExpectedCommissionsGet(loanId, expectedCommissionId, (response) => {
        return defer.resolve(response);
      }, (error) => {
        return defer.reject(error);
      });
    }

    return defer.promise;
  };

  factory.loansOneOffCommissionsGet = (loanId) => {
    var defer = $q.defer();

    if (loanId) {
      contact.loansOneOffCommissionsGet(loanId, (response) => {
        return defer.resolve(response.data.map(loansOneOffCommissionsBuilderForUI));
    }, (error) => {
        return defer.reject(error);
      });
    }

    return defer.promise;
  };

  factory.loansExpectedCommissionsSet = (commission) => {
    var defer = $q.defer();

    if (commission) {
      contact.loansExpectedCommissionsSet(commission, (response) => {
        return defer.resolve(loansOneOffCommissionsBuilderForUI(response.data));
      }, (error) => {
        return defer.reject(error);
      });
    }

    return defer.promise;
  };

  factory.loansCommissionRatingsGet = (loanId) => {
    var defer = $q.defer();

    contact.loansCommissionRatingsGet(loanId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loansExpectedCommissionsDelete = (commission) => {
    var defer = $q.defer();

    if (commission) {
      contact.loansExpectedCommissionsDelete(commission, (response) => {
        return defer.resolve(response);
      }, (error) => {
        return defer.reject(error);
      });
    }

    return defer.promise;
  };

  factory.loanCommissionLoanStructuresGet = (loanId) => {
    var defer = $q.defer();

    contact.loanCommissionLoanStructuresGet(loanId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loansExpectedCommissionsRecurringGet = (loanId, recurringId) => {
    var defer = $q.defer();

    contact.loansExpectedCommissionsRecurringGet(loanId, recurringId, (response) => {
      return defer.resolve(response.data.map(loansExpectedCommissionsRecurringBuilderForUI));
    }, (error) => {
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loansExpectedCommissionsRecurringSet = (recurringCommission) => {
    var defer = $q.defer();

    if (recurringCommission) {
      contact.loansExpectedCommissionsRecurringSet(recurringCommission, (response) => {
        return defer.resolve(loansExpectedCommissionsRecurringBuilderForUI(response.data));
      }, (error) => {
        return defer.reject(error);
      });
    }

    return defer.promise;
  };

  factory.loansExpectedCommissionsRecurringDelete = (loanId, recurringId) => {
    var defer = $q.defer();

    if (loanId && recurringId) {
      contact.loansExpectedCommissionsRecurringDelete(loanId, recurringId, (response) => {
        return defer.resolve(response);
      }, (error) => {
        return defer.reject(error);
      });
    }

    return defer.promise;
  };

  factory.expectedCommissionSummaryTotalGet = () => {
    var defer = $q.defer();

    contact.expectedCommissionSummaryTotalGet((response) => {
      return defer.resolve(response.data.map(expectedCommissionsSummaryBuilderForUI));
    }, (error) => {
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.loansExpectedCommissionsQuerySet = (query) => {
    var defer = $q.defer();

    if (query) {
      contact.loansExpectedCommissionsQuerySet(expectedCommissionsQueryBuilderForMyCRM(query), (response) => {
        return defer.resolve(response);
      }, (error) => {
        return defer.reject(error);
      });
    }

    return defer.promise;
  };
  factory.loanUnmatchedComissionSummary = (adviserId = 0, typeId = 0, providerId = 0, pageNumber = 1, pageSize = 10) => {
    const defer = $q.defer();
    contact.loanUnmatchedComissionSummary(adviserId, typeId, providerId, pageNumber, pageSize, (response) => {
      return defer.resolve(response);
    }, error => defer.reject(error));

    return defer.promise;
  };
  factory.oneOffCommissionRateGet = (lenderId = 0) => {
    const defer = $q.defer();
    contact.oneOffCommissionRateGet(lenderId, (response) => {
      return defer.resolve(response.data.map(oneOffCommissionRatesBuilderForUI));
    }, error => defer.reject(error));

    return defer.promise;
  };
  factory.mergeClients = (mergeToFamilyId, mergeFromFamilyId) => {
    var defer = $q.defer();

    contact.mergeClients(mergeToFamilyId, mergeFromFamilyId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.mergeClientsWithSecondary = (data) => {
    var defer = $q.defer();
    if (data) {
      contact.mergeClientsWithSecondary(data, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.studentLoanRepaymentEstimate = (data) => {
    var defer = $q.defer();
    if (data) {
      contact.studentLoanRepaymentEstimate(data, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.convertHTMLtoRTF = (htmlString) => {
    const defer = $q.defer();
    if (htmlString) {
      contact.convertHTMLtoRTF(htmlString, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.mailTemplatesGet = (userTemplateID) => {
    const defer = $q.defer();
    contact.mailTemplatesGet(userTemplateID || 0, response => defer.resolve(response), error => defer.reject(error));
    return defer.promise;
  };

  factory.mailTemplatesSet = (template) => {
    const defer = $q.defer();
    if (template) {
      template.UserTemplateFolderID = template.UserTemplateFolderId;
      contact.mailTemplatesSet(template, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.mailTemplatesDelete = (userTemplateID) => {
    const defer = $q.defer();
    if (userTemplateID) {
      contact.mailTemplatesDelete(userTemplateID, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.mailTemplateFoldersSet = (templateFolder) => {
    const defer = $q.defer();
    if (templateFolder && templateFolder.UserTemplateName && templateFolder.UserTemplateName.trim()) {
      templateFolder.UserTemplateID = templateFolder.UserTemplateID || 0;
      contact.mailTemplateFoldersSet(templateFolder, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.mailTemplateFoldersDelete = (templateFolderId) => {
    const defer = $q.defer();
    if (templateFolderId) {
      contact.mailTemplateFoldersDelete(templateFolderId, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.mailTemplateSortingSet = (data) => {
    const defer = $q.defer();
    if (data) {
      contact.mailTemplateSortingSet(data, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };
  factory.loanStructureChangeLog = (loanId, loanStructureID) => {
    const defer = $q.defer();
    contact.loanStructureChangeLog(loanId, loanStructureID, response => defer.resolve(response), error => defer.reject(error));
    return defer.promise;
  };
  factory.unmatchedCommissionsLoanStructuresGet = (args) => {
    const defer = $q.defer();
    if (args && parseInt(args.familyId) && parseInt(args.providerId)) {
      contact.unmatchedCommissionsLoanStructuresGet(
        args,
        response => defer.resolve(response.data.map(commissionLoanStructureBuilderForUI)),
        error => defer.reject(error));
    } else {
      defer.reject({ data: 'Invalid arguments' });
    }
    return defer.promise;
  };

  factory.matchCommission = (args) => {
    const defer = $q.defer();
    if (args && parseInt(args.expectationID) && parseInt(args.loanID) && parseInt(args.loanStructureID) && parseInt(args.commissionSpreadSheetID)) {
      contact.matchCommission(args, response => defer.resolve(response), error => defer.reject(error));
    } else {
      defer.reject({ data: 'Invalid arguments' });
    }
    return defer.promise;
  };
  factory.commissionAccess = (familyId) => {
    var defer = $q.defer();
    contact.commissionAccess(familyId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.commissionAccessSet = (familyId, brokerFamilyId, isActive) => {
    var defer = $q.defer();
    contact.commissionAccessSet(familyId, brokerFamilyId, isActive, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.brokerAccessDelete = (familyId, brokerFamilyId) => {
    var defer = $q.defer();
    contact.brokerAccessDelete(familyId, brokerFamilyId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.getGoogleEmail = (familyId) => {
    var defer = $q.defer();
    contact.getGoogleEmail(familyId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.authGoogle = (code) => {
    var defer = $q.defer();
    contact.authGoogle(code, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.smsTemplatesSet = (template) => {
    const defer = $q.defer();
    if (template) {
      contact.smsTemplatesSet(template, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.smsTemplatesGet = (userTemplateID) => {
    const defer = $q.defer();
    contact.smsTemplatesGet(userTemplateID || 0, response => defer.resolve(response), error => defer.reject(error));
    return defer.promise;
  };

  factory.smsTemplatesDelete = (userTemplateID) => {
    const defer = $q.defer();
    if (userTemplateID) {
      contact.smsTemplatesDelete(userTemplateID, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.smsTemplateFolderSet = (templateFolder) => {
    const defer = $q.defer();
    if (templateFolder && templateFolder.UserTemplateName) {
      templateFolder.UserTemplateID = templateFolder.UserTemplateID || 0;
      contact.smsTemplateFolderSet(templateFolder, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.smsTemplateFolderDelete = (folderId) => {
    const defer = $q.defer();
    if (folderId) {
      contact.smsTemplateFolderDelete(folderId, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.smsTemplateSortingSet = (templateSorting) => {
    const defer = $q.defer();
    if (templateSorting) {
      contact.smsTemplateSortingSet(templateSorting, response => defer.resolve(response), error => defer.reject(error));
    }
  };

  factory.mailTemplateSet = (data) => {
    const defer = $q.defer();
    if (data) {
      contact.mailTemplateSet(data, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.subscriptionInfo = (familyId) => {
    const defer = $q.defer();
    if (familyId) {
      contact.subscriptionInfo(familyId, response => defer.resolve(response.data.map(subscriptionInfoBuilderForUI)), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.subscriptionPricing = (familyId, subscriptionTypeId) => {
    const defer = $q.defer();
    if (familyId && subscriptionTypeId) {
      contact.subscriptionPricing(familyId, subscriptionTypeId, response => defer.resolve(subscriptionPricingBuilderForUI(response.data)), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.getGoogleDriveRoot = () => {
    var defer = $q.defer();
    contact.getGoogleDriveRoot((response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.getGoogleDriveFolderId = (isFoldersOnly, folderId) => {
    var defer = $q.defer();
    contact.getGoogleDriveFolderId(isFoldersOnly, folderId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.authGdrive = (code) => {
    var defer = $q.defer();
    contact.authGdrive(code, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.getDriveUserMapping = (familyId) => {
    var defer = $q.defer();
    contact.getDriveUserMapping(familyId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.postDriveUserMapping = (postParams) => {
    const defer = $q.defer();
    if (postParams) {
      contact.postDriveUserMapping(postParams, response => defer.resolve(response), error => defer.reject(error));
     }
      return defer.promise;
  };

  factory.getGmailSync = (familyId) => {
    var defer = $q.defer();
    contact.getGmailSync(familyId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  }

  factory.postGmailSync = (postParams) => {
    const defer = $q.defer();
    if (postParams) {
      contact.postGmailSync(postParams, response => defer.resolve(response), error => defer.reject(error));
     }
      return defer.promise;
  }

  factory.getGmailSyncStatus = (familyId) => {
    var defer = $q.defer();
    contact.getGmailSyncStatus(familyId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  }

  factory.subscription = (data) => {
    const defer = $q.defer();
    if (data) {
      contact.subscription(subscriptionBuilderForMyCRM(data), response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.subscriptionCancel = (data, familyId) => {
    const defer = $q.defer();
    if (data && familyId) {
      contact.subscriptionCancel(subscriptionBuilderForMyCRM(data), familyId, response => defer.resolve(response), error => defer.reject(error));

    }
    return defer.promise;
  };

  factory.subscriptionAdviserDetailsSet = (data) => {
    const defer = $q.defer();
    if (data) {
      contact.subscriptionAdviserDetailsSet(data, response => defer.resolve(response), error => defer.reject(error));
    }
    return defer.promise;
  };

  factory.documentTypeGet = () => {
    // TODO: move this to api
    const defer = $q.defer();
      defer.resolve({
        data: [
        {
          id: 0,
          type: 'loanApp',
          value: 'Loan App',
        },
        {
          id: 1,
          type: 'others',
          value: 'Documents',
        },
        {
          id: 2,
          type: 'googleDrive',
          value: 'Google Drive',
        },
      ],
      });
      return defer.promise;
  }

  factory.getCompanyTypes = () => {
    var defer = $q.defer();
    contact.getCompanyTypes((response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  }

  factory.getEmailSignatureImage = (familyId, type) => {
    var defer = $q.defer();
    if (!familyId || !type) return;
    contact.getEmailSignatureImage(familyId, type, (response) => {
      return defer.resolve(response.data.map(emailSignatureImageBuilderForUI));
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  }

  factory.deleteSignatureImage = (familyId, documentId) => {
    var defer = $q.defer();
    if (!documentId || !familyId) return;
    contact.deleteSignatureImage(familyId, documentId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  }

  factory.getEmailSignatureSettings = (familyId) => {
    var defer = $q.defer();
    if (!familyId) return;
    contact.getEmailSignatureSettings(familyId, (response) => {
      return defer.resolve(emailSignatureSettingsBuilderForUI(response.data));
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  }

  factory.getEmailSignaturePreviewDetails = (familyId) => {
    var defer = $q.defer();
    if (!familyId) return;
    contact.getEmailSignaturePreviewDetails(familyId, (response) => {
      return defer.resolve(emailSignaturePreviewDetailsBuilderForUI(response.data));
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  }

  factory.postEmailSignatureSettings = (data, familyId) => {
    const defer = $q.defer();
    if (!data || !Object.keys(data).length || !familyId) return;
    contact.postEmailSignatureSettings(emailSignatureSettingsBuilderForMyCRM(data), familyId, response => defer.resolve(response), error => defer.reject(error));
    return defer.promise;
  };

  factory.getFamiliesPerLoan = (loanId) => {
    var defer = $q.defer();
    contact.getFamiliesPerLoan(loanId, (response) => {
      return defer.resolve(collectionCamelizeBuilderForUI(response));
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };

  return factory;
}]);
