class GenderService {
  changeGender(title, gender) {
    if (title) {
      const newGender = this.getGender(title, gender);
      return newGender || gender;
    }
  }
  getGender(title, gender) {
    if (title === 'Mr') {
      this.gender = 'Male';
    } else if (title === 'Mrs' || title === 'Ms' || title === 'Miss') {
      this.gender = 'Female';
    } else {
      this.gender = gender;
    }
    return this.gender;
  }
}

export default GenderService;

