import { uniqBy } from 'lodash';

class existingAddressBarCtrl {
  constructor(generalService,
    $timeout,
    contactService) {
    'ngInject';

    this.generalService = generalService;
    this.$timeout = $timeout;
    this.contactService = contactService;
  }

  getSuggestedAddresses() {
    if (!this.addressModel || !this.addressModel.formatted_address) return;
    this.addressModel.isEditSecurityInfo = true;
    this.$timeout.cancel(this.searchTimeOut);

    this.searchTimeOut = this.$timeout(() => {
      if (!this.addressModel) return;
      this.addressModel.isEditSecurityInfo = true;
      if (this.addressModel.formatted_address.length >= 3) {
        this.generalService.placeSearch(this.addressModel.formatted_address).then((response) => {
          this.addressSuggestions = response.data;
          this.showSuggestionMenu = true;
        });
      }
    }, 500);
  }

  setCustomAddressFlag() {
    this.addressModel.isCustomAddress = !this.addressModel.isCustomAddress;
  }

  clearManualFields() {
    if (this.addressModel) {
      this.addressModel.street_address = '';
      this.addressModel.locality = '';
      this.addressModel.postal_code = '';
      this.addressModel.stateShort_Name = '';
      this.addressModel.country = '';
      this.addressModel.formatted_address = '';
    }
  }

  showExistingAddressResult(status) {
    this.showSuggestionMenu = false;
    this.showExistingAddresses = !status;
  }

  selectFullAddress(address) {
    const excludeProp = [
      'AddressID',
      'AddressValuationExtendedDetailsId',
      'OwnershipType',
      'OwnershipTypeId',
      'StartDate',
      'Type',
      'TypeId',
      'ContactLivingIn',
    ];

    const newFullAddress = Object.keys(address).reduce((accum, prop) => {
      if (excludeProp.indexOf(prop) === -1) {
        accum[prop] = address[prop];
      }
      return accum;
    }, {});

    this.showSuggestionMenu = false;
    this.addressModel = newFullAddress;
    this.addressModel.isEditSecurityInfo = true;
  }

  closeAddressSuggestions() {
    this.showSuggestionMenu = false;
  }

  getExistingAddresses() {
    this.contactService.contactAddressGet(this.contactFamilyId).then((response) => {
      this.existingAddress = uniqBy(response.data, address => address.formatted_address);
    });
  }

  $onDestroy() {
    this.$timeout.cancel(this.searchTimeOut);
  }

  $onInit() {
    this.showExistingAddresses = false;
    this.showSuggestionMenu = false;
    this.getExistingAddresses();
  }
}

export default existingAddressBarCtrl;
