import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import moment from 'moment';

angular.module('app').controller('LoanStructureAddEditCtrl',
  function LoanStructureAddEditCtrl(
    $scope,
    contactService,
    imageDataURI,
    $timeout,
    toaster,
    $filter,
    $rootScope,
    broadcastDataService,
    contactSharedData,
    userService,
    commonFnService,
    optionsService,
    configService,
    loanScenarioService,
    loanTermService
  ) {
    $scope.productDiscountRate = configService.feature.productDiscountRate;
    $scope.setLoanStructureDetails = {
      LoanId: 'string',
      LoanStructureId: 'string',
      AccountNumber: 'string',
      ProductID: 'string',
      Product: 'string',
      InterestRateType: 'string',
      InterestRateTerm: 0,
      RateTerm: 0,
      FixedRateBegin: '2017-06-14T00:13:11.104Z',
      FixedRateExpiry: '2017-06-14T00:13:11.104Z',
      IsLinkRateExpiry: false,
      InterestOnlyTerm: 0,
      RepaymentAmount: 0,
      RepaymentFrequency: 'string',
      IsDefault: true,
      LiabilityId: 'string',
      Lender: 'string',
      LenderId: 'string',
      CardLimit: 0,
      CardPaidInFull: true,
      ToBeRefinanced: true,
      LoanStructureType: 'string',
      LoanTerm: 0,
      LoanInterestRate: 0,
      MonthlyExpense: 0,
      IsRental: true,
      OwnerFamily: 'string',
      Owners: [
        {
          FamilyId: 'string',
          FamilyFullName: 'string',
          EntityTypeID: 'string',
          EntityTypeName: 'string',
          ContactRelationshipTypeId: 0,
          Phone: [
            {
              Type: 'string',
              Number: 'string',
            },
          ],
          Address: [
            {
              street_address: 'string',
              route: 'string',
              intersection: 'string',
              political: 'string',
              country: 'string',
              administrative_area_level_1: 'string',
              administrative_area_level_2: 'string',
              administrative_area_level_3: 'string',
              administrative_area_level_4: 'string',
              administrative_area_level_5: 'string',
              colloquial_area: 'string',
              locality: 'string',
              ward: 'string',
              sublocality: 'string',
              sublocality_level_1: 'string',
              sublocality_level_2: 'string',
              sublocality_level_3: 'string',
              sublocality_level_4: 'string',
              sublocality_level_5: 'string',
              neighborhood: 'string',
              premise: 'string',
              subpremise: 'string',
              postal_code: 'string',
              natural_feature: 'string',
              airport: 'string',
              park: 'string',
              point_of_interest: 'string',
              floor: 'string',
              establishment: 'string',
              parking: 'string',
              post_box: 'string',
              postal_town: 'string',
              room: 'string',
              street_number: 'string',
              bus_station: 'string',
              train_station: 'string',
              transit_station: 'string',
              latitude: 0,
              longitude: 0,
              geoCoded: true,
              stateShort_Name: 'string',
              countryCodeInTwoLetter: 'string',
              errorMessage: 'string',
              IsMailing: true,
              FamilyId: 0,
              AddressID: 'string',
              Type: 'string',
              TypeId: 'string',
              formatted_address: 'string',
              StartDate: '2017-06-14T00:13:11.104Z',
              EndDate: '2017-06-14T00:13:11.104Z',
              OwnershipType: 'string',
              OwnershipTypeId: 0,
              AddressValuationExtendedDetailsId: 0,
            },
          ],
          Email: [
            {
              Type: 'string',
              EmailAddress: 'string',
            },
          ],
          Notes: 'string',
          GroupId: 'string',
          PersonId: 'string',
          OrganisationId: 'string',
          IsApplicant: true,
          IsGuarantor: true,
          IsSolicitor: true,
          IsAccountant: true,
        },
      ],
      Type: 'string',
      PropertyType: 'string',
      TypeOther: 'string',
      Description: 'string',
      Value: 0,
      Borrowers: [
        {
          BorrowerID: 0,
          FirstName: 'string',
          LastName: 'string',
          IsInclude: true,
          IsEntity: true,
        },
      ],
      OtherInformation: 'string',
      FinancialID: 'string',
    };
    // Services
    angular.extend($scope, {
      isRequiredComplete() {
        if (!$scope.setLoanStructureDetails.Value || !$scope.data.LoanInterestRate || !$scope.setLoanStructureDetails.LoanTerm)
          return false;
        return true;
      },

      calculateRepayment(dataInput, dataLegend) {
        /*
          dataLegend:
            2 = loanAmount
            3 = interestRate
            4 = loanTerms
            5 = optrepayment
            6 = optIsInterest
        */
        if (dataInput && dataLegend) {
          switch (dataLegend) {
          case 2:
            $scope.setLoanStructureDetails.Value = dataInput;
            break;
          case 3:
            $scope.data.LoanInterestRate = dataInput;
            break;
          case 4:
            $scope.setLoanStructureDetails.LoanTerm = dataInput;
            break;
          case 5:
            $scope.setLoanStructureDetails.RepaymentFrequency = dataInput;
            break;
          case 6:
            $scope.setLoanStructureDetails.LoanStructureType = dataInput;
            break;
          default:
            break;
          }

          if ($scope.isRequiredComplete()) {
            broadcastDataService.calRep.calculateRepayment($scope.setLoanStructureDetails.Value, $scope.data.LoanInterestRate, $scope.setLoanStructureDetails.LoanTerm, $scope.setLoanStructureDetails.RepaymentFrequency, $scope.setLoanStructureDetails.LoanStructureType)
              .then((response) => {
                $scope.setLoanStructureDetails.RepaymentAmount = response;
              });
          }
        }
      },
    });


    $scope.borrowingEntityListSelected = [];
    $scope.toggle = {
      isOpenDatePickerRateStart: false,
      isOpenDatePickerRateExpiry: false,
    };
    $scope.toggleStatus = (statusName, currentStatus) => {
      switch (statusName) {
      case 'rateStart':
        $scope.toggle.isOpenDatePickerRateStart = !currentStatus;
        break;
      case 'rateExpiry':
        $scope.toggle.isOpenDatePickerRateExpiry = !currentStatus;
        break;
      case 'linkButton':
        $scope.setLoanStructureDetails.IsLinkRateExpiry = !currentStatus;
        break;
      default:
        break;
      }
    };
    $scope.linkRateChanged = (changeName, newValue) => {
      let { FixedRateBegin } = $scope.setLoanStructureDetails;
      const { RateTerm, IsLinkRateExpiry } = $scope.setLoanStructureDetails;

      const baseDateFormat = 'YYYY-MM-DD';
      const uiDateFormat = 'DD MMM YYYY';

      if (!FixedRateBegin || !RateTerm || !IsLinkRateExpiry) return;

      FixedRateBegin = moment(FixedRateBegin).format();
      switch (changeName) {
      case 'rateStart':
        $scope.setLoanStructureDetails.FixedRateExpiry = moment(moment(newValue, baseDateFormat).add((RateTerm * 12), 'M').format(uiDateFormat))._d;
        break;
      case 'rateTerm':
        $scope.setLoanStructureDetails.FixedRateExpiry = moment(moment(FixedRateBegin, baseDateFormat).add((newValue * 12), 'M').format(uiDateFormat))._d;
        break;
      default:
        break;
      }
    };
    if (!$scope.params.loanStructureAction) {
      $scope.params.loanStructureAction = 'create';
    }

    // Borrowers
    // -------------------------------------------------------------------------
    $scope.borrowerDefault = function () {
      contactService.familyInfoGet($scope.params.familyId).then((response) => {
        const data = [];

        angular.forEach(response.data, (value) => {
          if (value.Role === 'Adult') {
            value.BorrowerID = value.PersonId;
            value.IsInclude = true;
            value.IsEntity = false;
            data.push(value);
          }
        });
        $scope.borrowerList = data;
      });
    };
    $scope.getBorrowersList = function () {
      contactService.borrowersDetailsGet($scope.params.familyId, $scope.params.loanId).then((respond) => {
        const entityList = [];
        const borrowerList = [];
        const borrowerIDList = [];
        $scope.listBorrowerValues = [];
        angular.forEach(respond.data, (value) => {
          if (borrowerIDList.indexOf(value.BorrowerID) === -1) {
            borrowerIDList.push(value.BorrowerID);
            $scope.listBorrowerValues.push(value);
          }
        });
        angular.forEach($scope.listBorrowerValues, (value) => {
          if (value.IsEntity) {
            entityList.push(value);
          } else {
            borrowerList.push(value);
          }
        });

        $scope.entityList = entityList;
        $scope.borrowerList = borrowerList;
      });
    };

    if ($scope.params.loanStructureAction === 'create') {
      $scope.borrowerDefault();
    } else {
      $scope.getBorrowersList();
    }

    // Rate Type select options
    angular.extend($scope, {
      userCountryId: 0,
      selectedRate: {},
      selectRateType: [],
      tempSelectedRate: {},
      rateTypeSelected(rateType) {
        $scope.setLoanStructureDetails.InterestRateType = rateType.type;
        if (rateType && rateType.type === 'Fixed') $scope.setLoanStructureDetails.IsLinkRateExpiry = true;
      },
    });

    function getRateTypeOption() {
      userService.GetUserInfo().then((response) => {
        if (response.data) {
          $scope.userCountryId = response.data.CountryId;
          switch ($scope.userCountryId) {
          case 2: // Australia
            $scope.selectRateType.push({ type: 'Variable', label: 'Variable' });
            break;
          default: // NZ
            $scope.selectRateType.push({ type: 'Floating', label: 'Floating' });
          }
          $scope.selectRateType.push({ type: 'Fixed', label: 'Fixed' });
        }
      });
    }

    function getSelectedRateValue(interestRateType) {
      let selectedRateType = {};
      switch (interestRateType) {
      case 'Floating':
        selectedRateType = {
          type: (parseInt($scope.userCountryId, 10) === 2 ? 'Variable' : 'Floating'),
          label: (parseInt($scope.userCountryId, 10) === 2 ? 'Variable' : 'Floating'),
        };
        break;
      default: // 'Fixed'
        selectedRateType = { type: 'Fixed', label: 'Fixed' };
      }
      return selectedRateType;
    }

    //----------------------
    $scope.loanStructureDataField = function () {
      $scope.setLoanStructureDetails = {};
      $scope.setLoanStructureDetails.FixedRateBegin = moment()._d;
      $scope.setLoanStructureDetails.FixedRateExpiry = moment()._d;
      $scope.setLoanStructureDetails.RepaymentAmount = 0;
      $scope.setLoanStructureDetails.ProductID = 0;
      $scope.setLoanStructureDetails.Product = '';

      $scope.structureId = $scope.params.structureId;

      // Loan Type select options
      $scope.selectLoanType = ['Principal and Interest', 'Interest Only', 'Revolving Credit'];

      // Terms select options
      $scope.selectTerms = [0, 0.25, 0.5, 0.75, 1.5];
      for (let i = 1; i < 31; i++) {
        $scope.selectTerms.push(i);
      }

      const loanTermMonth = loanTermService.getLoanTermMonthList();
      optionsService.getLoanTerms().then((loanTermResponse) => {
        $scope.loanTermsList = [...loanTermMonth, ...loanTermResponse];
      });

      // Repayment Frequency select option
      if ($scope.crmCountry === 'Indonesia') {
        $scope.selectRepaymentFreq = [{ Name: 'Monthly', id: 'Monthly' }];
      } else {
        $scope.selectRepaymentFreq = [
          { Name: 'Yearly', id: 'Yearly' },
          { Name: 'Half-Yearly', id: 'Half-Yearly' },
          { Name: 'Quarterly', id: 'Quarterly' },
          { Name: 'Monthly', id: 'Monthly' },
          { Name: 'Fortnightly', id: 'Fortnightly' },
          { Name: 'Weekly', id: 'Weekly' },
        ];
      }

      $scope.borrowerInputTag = [];

      $scope.data = {};
      $scope.data.LoanInterestRate = 0;

      $scope.FixedRateHistory = [];

      $scope.assignBorrowerTag = function (listBorrowerValues) {
        const borrowerIdList = [];
        angular.forEach(listBorrowerValues, (value) => {
          if (borrowerIdList.indexOf(value.BorrowerID) === -1) {
            borrowerIdList.push(value.BorrowerID);
            $scope.borrowerInputTag.push(
              {
                text: 'test',
                borrowerId: 123,
              }
            );
          }
        });
      };

      if ($scope.params.loanStructureAction !== 'create') {
        if (!$scope.params.structureId) {
          $scope.params.structureId = 0;
        }
        contactService.loanStructureDetailsGet($scope.params.loanId, $scope.params.structureId)
          .then((response) => {
            const loanStructureDetails = response.data;
            $scope.borrowerCurrentList = loanStructureDetails.Borrowers;
            $scope.assignBorrowerTag(loanStructureDetails.Borrowers);
            if ($scope.params.loanStructureAction !== 'view') {
              $scope.setLoanStructureDetails = loanStructureDetails;
              // select option
              $scope.setLoanStructureDetails.FixedRateBegin = loanStructureDetails.FixedRateBegin ? moment(moment(loanStructureDetails.FixedRateBegin, 'YYYY-MM-DD').format('DD MMM YYYY'))._d : null;
              $scope.setLoanStructureDetails.FixedRateExpiry = loanStructureDetails.FixedRateExpiry ? moment(moment(loanStructureDetails.FixedRateExpiry, 'YYYY-MM-DD').format('DD MMM YYYY'))._d : null;
              $scope.setLoanStructureDetails.RepaymentFrequency = loanStructureDetails.RepaymentFrequency.trim();
              $scope.setLoanStructureDetails.Value = loanStructureDetails.Value;
              $scope.setLoanStructureDetails.ProductDetails = {
                ProductID: $scope.setLoanStructureDetails.ProductID,
                Product: $scope.setLoanStructureDetails.Product,
              };
              $scope.setLoanStructureDetails.LenderDetails = $scope.params.Lender;
            }

            $scope.loanStructureDetails = loanStructureDetails;
            $scope.data.LoanInterestRate = loanStructureDetails.LoanInterestRate * 100;
            $scope.data.InterestRateTerm = $scope.params.InterestRateTerm;
            $scope.data.InterestRateTermTypeFull = $scope.params.InterestRateType;
            $scope.data.FixedRateExpiry = loanStructureDetails.FixedRateExpiry;

            $scope.selectedRate = getSelectedRateValue(loanStructureDetails.InterestRateType);
            $scope.tempSelectedRate = _.clone($scope.selectedRate);
          });
      } else {
        $scope.setLoanStructureDetails.Borrowers = $scope.listBorrowerValues;
        $scope.assignBorrowerTag($scope.listBorrowerValues);
      }
    };

    $scope.loanStructureDataField();

    // Get Product List
    if (typeof $scope.params.providerID !== 'undefined') {
      contactService.loanProductListGet($scope.params.providerID)
        .then((response) => {
          $scope.ProductList = response.data;
        });
    }

    $scope.dateOptions = {
      formatDay: 'dd',
      formatMonth: 'MMM',
      formatYear: 'yy',
      showWeeks: false,
      maxMode: 'day',
    };
    $scope.jumpedYear = 0;
    $scope.jumpToYear = (jumpedYear) => {
      if (jumpedYear) {
        $scope.setLoanStructureDetails.FixedRateExpiry = moment().add(jumpedYear, 'year').utc().format();
      }
    };

    $scope.setLoanStructureDetails = {};
    $scope.saveLoanStructureData = function () {
      $scope.setLoanStructureDetails.LoanId = $scope.params.loanId;

      if ($scope.borrowingEntityListSelected[0] && $scope.borrowingEntityListSelected[0].BorrowerID === 0) {
        $scope.setLoanStructureDetails.Borrowers = $scope.borrowerList;
      } else {
        $scope.setLoanStructureDetails.Borrowers = $scope.borrowingEntityListSelected;
      }

      const loanInterestRate = $scope.data.LoanInterestRate / 100;

      if (_.isNil($scope.setLoanStructureDetails.ProductDetails)) {
        $scope.setLoanStructureDetails.ProductDetails = {};
      }

      if (_.isNil($scope.setLoanStructureDetails.ProductDetails.ProductID)) {
        $scope.setLoanStructureDetails.ProductDetails.ProductID = 0;
      }

      if (_.isNil($scope.setLoanStructureDetails.ProductDetails.Product)) {
        $scope.setLoanStructureDetails.ProductDetails.Product = '';
      }

      $scope.setLoanStructureDetails.LoanID = $scope.params.loanId;
      if ($scope.params.structureId) {
        $scope.setLoanStructureDetails.LoanStructureId = parseInt($scope.params.structureId, 10);
      } else {
        $scope.setLoanStructureDetails.LoanStructureId = 0;
      }
      $scope.setLoanStructureDetails.LoanInterestRate = loanInterestRate.toString();

      if ($scope.setLoanStructureDetails.InterestRateType === 'Variable') {
        $scope.setLoanStructureDetails.RateTerm = 0;
      }

      $scope.setLoanStructureDetails.ProductID = $scope.setLoanStructureDetails.ProductDetails.ProductID;
      $scope.setLoanStructureDetails.Product = $scope.setLoanStructureDetails.ProductDetails.Product;
      $scope.setLoanStructureDetails.FixedRateBegin = moment($scope.setLoanStructureDetails.FixedRateBegin, 'YYYY-MM-DD ').format('YYYY-MM-DD') || '';
      $scope.setLoanStructureDetails.FixedRateExpiry = moment($scope.setLoanStructureDetails.FixedRateExpiry, 'YYYY-MM-DD ').format('YYYY-MM-DD') || '';
      const setLoanStructureDetailsTemporary = angular.copy($scope.setLoanStructureDetails);

      if (!$scope.setLoanDetails.Lender.LenderId) {
        swal({
          title: 'No Lender',
          text: 'Select lender first at Loan Details tab.',
          type: 'warning',
          showConfirmButton: true,
          showCancelButton: false,
        });
        contactSharedData.showLoanSplit = false;
      } else {
        contactService.loanStructureSet(setLoanStructureDetailsTemporary).then((data) => {
          if (data.statusText === 'OK') {
            if ($scope.params.loanStructureAction === 'edit') {
              swal({
                title: 'Success',
                text: 'Loan structure has been successfully updated.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            } else if ($scope.params.loanStructureAction === 'create') {
              swal({
                title: 'Success',
                text: 'Loan structure has been successfully created.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            }

            $scope.addLoanVar = !$scope.addLoanVar;
          }

          $scope.loanDataField();
          $scope.LoanDetails = [];
          $scope.LoanStructureListGet = [];
          $scope.loanStructureDataField();

          $rootScope.$emit('reloadLoanSplitToLoanStructure');

          $timeout(() => {
            // $scope.close();
          }, 1000);
        });

        if ($scope.tempSelectedRate.type === 'Fixed' && $scope.selectedRate.type === 'Floating') {
          contactService.FixedRatesSet($scope.params.CurrentFixedRateGet).then(() => {
            toaster.pop('success', 'Updated', 'Successfully updated interest rate');
          });
        }

        $scope.clearAddLoanSplitTempData();
      }
    };

    if ($scope.params.loanStructureAction === 'view') {
      $scope.isLoanStructureSetFieldValue = false;
    } else {
      $scope.isLoanStructureSetFieldValue = true;
    }

    $scope.$watch('setLoanStructureDetails.InterestRateType', (newValue) => {
      if ($scope.selectRateType.length > 0) {
        if (newValue === $scope.selectRateType[1].type) {
          $scope.isInterestRateType = 0;
        } else {
          $scope.isInterestRateType = 1;
        }
      }
    });

    // /*******************************************************************/
    $scope.addSplitLoanStructure = () => {
      if ($scope.$parent.addLoanStructure) {
        $scope.$parent.addLoanStructure();
      }
      $scope.saveLoanStructureData();
      $scope.clearAddLoanSplitTempData();
    };

    $scope.editSplitLoanStructure = () => {
      if ($scope.$parent.editLoanStructure) {
        $scope.$parent.editLoanStructure();
      }
      $scope.saveLoanStructureData();
      $scope.clearAddLoanSplitTempData();
    };

    $scope.clearAddLoanSplitTempData = function () {
      $scope.setLoanStructureDetails.InterestRateType = undefined;
      $scope.setLoanStructureDetails.ProductDetails = undefined;
      $scope.borrowerInputTag = $scope.borrowerList;
      $scope.setLoanStructureDetails.InterestOnlyTerm = undefined;
      $scope.setLoanStructureDetails.AccountNumber = undefined;
      $scope.setLoanStructureDetails.RepaymentFrequency = undefined;
      $scope.setLoanStructureDetails.RepaymentAmount = undefined;
      $scope.setLoanStructureDetails.LoanStructureType = undefined;
      $scope.setLoanStructureDetails.LoanTerm = undefined;
    };

    /** ************************************************* */
    $scope.borrowingEntity = {};

    contactService.borrowersDetailsGet($scope.params.familyId, $scope.params.loanId).then((response) => {
      const temp = {
        Percentage: 0,
        BorrowerID: 0,
        FirstName: 'All',
        LastName: 'Joint',
        PreferedName: 'Joint',
        ticked: true,
      };
      angular.forEach($scope.params.loanstructureDataBorrowersList, (value) => {
        if (value.IsInclude) {
          value.ticked = value.IsInclude;
          $scope.borrowingEntityListSelected.push(value);
        }
      });

      if ($scope.borrowingEntityListSelected.length < 1 || ($scope.borrowingEntityListSelected.length === response.data.length)) {
        const tmp = [];
        response.data && Object.keys(response.data).forEach((x) => {
          const value = response.data[x];
          value.PreferedName = `${value.FirstName} ${value.LastName}`;
          tmp.push(value);
        });
        if (tmp.length === 1) {
          tmp[0].ticked = true;
        } else {
          tmp.unshift(temp);
        }
        $scope.borrowingEntity = tmp;
      } else {
        temp.ticked = false;
        const tmp = [];
        // Set default selected from edit
        // @TODO : Edit should match a $scope.borrowingEntityListSelected array

        tmp.push(commonFnService.processTickedBorrower(response, $scope.borrowingEntityListSelected, temp));
        $scope.borrowingEntity = commonFnService.processTickedBorrower(response, $scope.borrowingEntityListSelected, temp);
      }
    });

    // Filter Select all
    $scope.filterRefreshData = [];

    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: '',
    };

    $scope.onSelectAll = function () {
      if ($scope.filterRefreshData[0] && $scope.filterRefreshData[0].ownership) {
        $scope.filterRefreshData[0].ownership = [];
      }
      for (let i = 0; i < $scope.borrowingEntity.length; i++) {
        if ($scope.borrowingEntity[i].BorrowerID > -1) {
          $scope.borrowingEntity[i].ticked = false;
        } else {
          $scope.borrowingEntity[i].ticked = true;
        }
      }
    };

    $scope.onItemSelect = function (data) {
      let isJointFamily = true;
      for (let i = 0; i < $scope.borrowingEntity.length; i++) {
        if ((typeof $scope.borrowingEntity[i].ticked === 'undefined' || !$scope.borrowingEntity[i].ticked) && $scope.borrowingEntity[i].BorrowerID >= 1) {
          isJointFamily = false;
        }
      }

      // will be true if user clicks on joint
      if (data.BorrowerID === 0) {
        isJointFamily = true;
      }
      if (!isJointFamily) {
        $scope.borrowingEntity = _.map($scope.borrowingEntity, (obj) => {
          if (obj.BorrowerID === 0) {
            obj.ticked = false;
          }
          return obj;
        });
      } else {
        const findJoint = _.find($scope.borrowingEntity, (obj) => {
          return obj.BorrowerID === 0;
        });
        // need to check if single owner
        if (!_.isEmpty(findJoint)) {
          $scope.borrowingEntity = _.map($scope.borrowingEntity, (obj) => {
            obj.ticked = obj.BorrowerID === 0;
            return obj;
          });
        }
      }
    };

    $scope.onProductSelected = (product) => {
      if (!product) return;

      $scope.setLoanStructureDetails.LoanStructureType = product.LoanType;
      $scope.setLoanStructureDetails.LoanTerm = product.LoanTerm;
      $scope.setLoanStructureDetails.RepaymentFrequency = 'Monthly';
      $scope.data.LoanInterestRate = product.InterestRate;
      $scope.selectedRate = product.RateType;

      const selectedRate = _.find($scope.selectRateType, type => type.label === product.RateType);
      if (selectedRate) {
        $scope.selectedRate = selectedRate;
        $scope.rateTypeSelected(selectedRate);
      }

      $scope.calculateRepayment(product.InterestRate, 3);
    };

    const getloanDetails = () => {
      const loanAppId = typeof $scope.params.loanScenarioID === 'object' ? $scope.params.loanScenarioID.LoanScenarioID : $scope.params.loanScenarioID;
      if (!loanAppId) return;
      loanScenarioService.loanDetailsGet(loanAppId).then((response) => {
        const isLoanFacilityNotEmpty = response && response.data && response.data.LoanFacility && response.data.LoanFacility.length;
        if (isLoanFacilityNotEmpty) {
          $scope.loanBrokerEventId = response.data.LoanFacility[0].BrokerEventId;
        }
      });
    };

    $scope.getInitialActualRate = (actualRate) => {
      if (!$scope.data) return;
      $scope.data.LoanInterestRate = actualRate;
    };

    function init() {
      getRateTypeOption();
      getloanDetails();
    }

    init();
  });
