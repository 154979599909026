import template from './workbench.html';
import WorkbenchCtrl from './workbenchCtrl';

export default {
  template,
  bindings: {
    loanId: '<',
    loanStatusId: '=',
    loanPipelineCardsId: '<',
    loanScenarioId: '<',
    familyId: '<',
    modalInstance: '<',
    enquirySource: '<',
  },
  controller: WorkbenchCtrl,
  controllerAs: 'vm',
};
