import { dailyDigestBuilderForUI, userSurveyBuilderForUI } from 'Common/mappers/user';
import { SURVEY_SKIP_TIME } from 'Common/constants/survey';

class UserService {
  constructor(httpClient, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'user';
    this.$q = $q;
  }

  PersonalInfoGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/PersonalInfoGet`);
  }

  PersonalInfoSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/PersonalInfoSet`, data);
  }

  CompanyInfoGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/CompanyInfoGet`);
  }

  CompanyInfoSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/CompanyInfoSet`, data);
  }

  BrandsInfoGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/BrandsInfoGet`);
  }

  UserPermissionListGet(adviserFamilyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/UserPermissionListGet`, { adviserFamilyId });
  }

  UserPermissionSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/UserPermissionSet`, data);
  }

  ProviderInfoGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/ProviderInfoGet`);
  }

  ProviderInfoSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ProviderInfoSet`, data);
  }

  GetUserInfo() {
    // TODO: move this to generalService
    return this.httpClient.get('GetUserInfo', null, true, false);
  }

  UserDefaultTemplateGet(moduleId, userTemplateTypeID) {
    return this.httpClient.get(`${this.apiBaseUrl}/UserDefaultTemplate`, { moduleId, userTemplateTypeID });
  }

  UserDefaultTemplateSet(params) {
    if (params && params.moduleId && params.userTemplateId && params.userTemplateTypeID) {
      return this.httpClient.post(`${this.apiBaseUrl}/UserDefaultTemplate`, {}, params);
    }
    return this.$q.reject({ data: 'Invalid arguments' });
  }

  UserRequirementTemplateGet(args) {
    const params = args || {};
    params.requirementTemplateId = args.requirementTemplateId || 0;
    params.requirementTypeId = args.requirementTypeId || 0;
    return this.httpClient.get(`${this.apiBaseUrl}/RequirementTemplate`, params);
  }

  UserRequirementTemplateSet(params) {
    if (params && params.requirementTemplate && params.requirementTypeId) {
      return this.httpClient.post(`${this.apiBaseUrl}/RequirementTemplate`, {}, params);
    }
    return this.$q.reject({ error: 'Invalid arguments!' });
  }

  UserRequirementTemplateDelete(requirementTemplateId) {
    if (requirementTemplateId) {
      return this.httpClient.delete(`${this.apiBaseUrl}/RequirementTemplate`, { requirementTemplateId });
    }
    return this.$q.reject({ error: 'Invalid argument!' });
  }

  UserRequirementTemplateSortingSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/RequirementTemplateSorting`, data);
  }

  getUserDigest(notificationSettingId = 0) {
    return this.httpClient.get(`${this.apiBaseUrl}/daily-digest`, { notificationSettingId })
      .then(response => response.data.map(dailyDigestBuilderForUI));
  }
  updateUserDigest(digest) {
    return this.httpClient.post(`${this.apiBaseUrl}/daily-digest?notificationSettingId=${digest.notificationSettingId}&isEnabled=${digest.isEnabled}`)
      .then(response => dailyDigestBuilderForUI(response.data));
  }
  getUserSurvey() {
    return this.httpClient.get(`${this.apiBaseUrl}/survey`, true, false)
      .then(response => response.data.map(userSurveyBuilderForUI));
  }
  putUserSurvey(surveyId, startSurvey, dismissSurvey, hoursHidden) {
    return this.httpClient.put(`${this.apiBaseUrl}/survey?surveyId=${surveyId}&startSurvey=${startSurvey}&dismissSurvey=${dismissSurvey}&hoursHidden=${hoursHidden}`);
  }
  getSurveyDismissOptions() {
    this.dissmissOptions = [{
      hoursHidden: SURVEY_SKIP_TIME.TWENTYFOUR_HOURS,
      text: 'Remind me in 24 hours',
    },
    {
      hoursHidden: SURVEY_SKIP_TIME.UNTIL_NEXT_SURVEY,
      text: 'Until next survey',
    }];
    return this.dissmissOptions;
  }
  getBrokerBasicinfo() {
    return this.httpClient.get(`GetBrokerBasicinfo`);
  }
}

export default UserService;
