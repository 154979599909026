import angular from 'angular';
import _ from 'lodash';
import { openDate, formatCustomAddress } from 'Common/utilities/addressHistory';

angular.module('app').controller('EditAddressHistoryCtrl', function EditAddressHistoryCtrl(
  $scope,
  $uibModalInstance,
  $timeout,
  generalService,
  loanScenarioService,
  dashboardService,
  contactService,
  addressHistoryService,
  uiService,
  addressIndex,
  item,
  toaster
) {
  $scope.action = 'Edit';

  $scope.modalHeading = `${$scope.action} an Address`;

  $scope.AddressHistory.Address = _.cloneDeep($scope.AddressHistory.List.Address[addressIndex]);

  $scope.uiService = uiService;

  const AddressType = (_.find($scope.AddressHistory.addressTypeList, (o) => {
    return o.Name === $scope.AddressHistory.Address.Type;
  }));

  if (AddressType && AddressType.Value) {
    $scope.AddressHistory.AddressType = AddressType.Value;
  }

  $scope.AddressHistory.RentalAmount = $scope.AddressHistory.Address.RentalAmount;

  $scope.AddressHistory.StartDate = ($scope.AddressHistory.Address.StartDate !== '') ? new Date($scope.AddressHistory.Address.StartDate) : '';
  $scope.AddressHistory.EndDate = ($scope.AddressHistory.Address.EndDate !== '') ? new Date($scope.AddressHistory.Address.EndDate) : '';
  if (String($scope.AddressHistory.StartDate) === 'Invalid Date') {
    $scope.AddressHistory.StartDate = '';
  }
  if (String($scope.AddressHistory.EndDate) === 'Invalid Date') {
    $scope.AddressHistory.EndDate = '';
  }
  $scope.AddressHistory.NumberOfYears = $scope.AddressHistory.Address.NumberOfYears;
  $scope.AddressHistory.NumberOfMonths = $scope.AddressHistory.Address.NumberOfMonths;

  if (Object.prototype.hasOwnProperty.call($scope.AddressHistory.Address, 'OwnershipTypeId'))
    $scope.AddressHistory.OwnershipTypeId = $scope.AddressHistory.Address.OwnershipTypeId.toString();


  $scope.AddressHistory.changeAddressType();


  $scope.cancel = () => {
    $uibModalInstance.close({ FamilyId: $scope.AddressHistory.SelectedClient });
  };

  $scope.AddressHistory.datePopUp = {
    StartDate: false,
    EndDate: false,
  };

  $scope.showExistAddressResult = false;
  $scope.showHideExistAddressResult = (status) => {
    $scope.showExistAddressResult = !status;
    $scope.AddressHistory.showSuggestionModal = false;
  };

  $scope.getExistAddress = () => {
    contactService.contactAddressGet($scope.AddressHistory.SelectedClient).then((response) => {
      $scope.existingAddress = _.uniqBy(response.data, addressObj => addressObj.AddressID);
    });
  };
  $scope.getExistAddress();

  // Open Date Popup
  $scope.AddressHistory.openDate = dateField => openDate($scope.AddressHistory, dateField);

  $scope.getAddressOwnership();

  /**
   * Get applicants for ownership dropdown
   * */
  $scope.getApplicants($scope.action);

  $scope.AddressHistory.format = 'dd MMM yyyy';
  $scope.AddressHistory.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  $scope.AddressHistory.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };

  $scope.AddressHistory.getFormattedAddress = () => {
    $scope.AddressHistory.closeAutoHomeAddress();
    $timeout.cancel($scope.searchTimeout);
    if (_.isUndefined($scope.AddressHistory.Address)) {
      $scope.AddressHistory.Address = {};
    }
    $scope.AddressHistory.Address.geoCoded = false;
    $scope.AddressHistory.Address.latitude = 0;
    $scope.AddressHistory.Address.longitude = 0;
    $scope.searchTimeout = $timeout(() => {
      addressHistoryService.populateAddressHistorySuggestions($scope.AddressHistory);
    }, 500);
  };

  $scope.AddressHistory.closeAutoHomeAddress = () => {
    $scope.AddressHistory.addressSuggestions = [];
  };

  $scope.AddressHistory.pickAddressSuggestion = (homeAddress) => {
    $scope.AddressHistory.Address = homeAddress;

    $scope.AddressHistory.ContactAddress = homeAddress;

    $scope.AddressHistory.closeAutoHomeAddress();
    $scope.AddressHistory.showSuggestionModal = false;
    $scope.showExistAddressResult = false;
  };

  $scope.saveAddress = (isValid) => {
    if (!isValid) {
      toaster.pop('warning', 'Form incomplete', 'Please fill up all fields');
      return false;
    }
    $scope.saveAddClicked = true;

    $scope.AddressHistory.selectedFamily = $scope.AddressHistory.SelectedClient;
    const typeName = (_.find($scope.AddressHistory.addressTypeList, (o) => {
      return o.Value === $scope.AddressHistory.AddressType;
    }));

    if (typeName && typeName.Name) {
      $scope.AddressHistory.Type = typeName.Name;
    }

    formatCustomAddress($scope.AddressHistory);

    dashboardService.addressDetailsSet({
      ...$scope.AddressHistory.Address,
      IsMailing: false,
      Type: $scope.AddressHistory.Type,
      countryCode: $scope.AddressHistory.Address.CountryCode,
      FamilyId: $scope.AddressHistory.selectedFamily,
      TypeId: $scope.AddressHistory.AddressType,
      AddressID: $scope.AddressHistory.Address.AddressID,
      AddressValuationExtendedDetailsId: item.AddressValuationExtendedDetailsId,
    })
      .then((response1) => {
        const { NumberOfYears, NumberOfMonths } = $scope.AddressHistory;
        return loanScenarioService.setAddress({
          AddressID: response1.data,
          FamilyId: $scope.AddressHistory.selectedFamily,
          Type: $scope.AddressHistory.Type,
          TypeId: $scope.AddressHistory.AddressType,
          StartDate: $scope.AddressHistory.StartDate,
          EndDate: $scope.AddressHistory.EndDate,
          OwnershipTypeId: parseInt($scope.AddressHistory.OwnershipTypeId, 10),
          ContactLivingIn: $scope.AddressHistory.ContactLivingIn,
          AddressValuationExtendedDetailsId: item.AddressValuationExtendedDetailsId,
          RentalAmount: $scope.isWithRental($scope.AddressHistory.OwnershipTypeId) ? $scope.AddressHistory.RentalAmount : 0,
          FrequencyTypeId: $scope.AddressHistory.FrequencyTypeId,
          NumberOfYears: NumberOfYears && parseInt(NumberOfYears, 10),
          NumberOfMonths: NumberOfMonths && parseInt(NumberOfMonths, 10),
        });
      })
      .then(() => {
        $scope.AddressHistory.List.Address.splice(addressIndex, 1);
        $scope.AddressHistory.List.Address.push($scope.AddressHistory.Address);
        $timeout(() => {
          $scope.getAddressHistory();
        }, 500);
        $scope.cancel();
      })
      .finally(() => {
        $scope.saveAddClicked = false;
      });
  };
});
