import template from './opportunityCalculations.html';
import controller from './opportunityCalculationsCtrl';

export default {
  template,
  bindings: {
    opportunityId: '<',
    opportunityTitle: '<',
  },
  controller,
  controllerAs: 'vm',
};
