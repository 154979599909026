import angular from 'angular';
import _ from 'lodash';

angular.module('app')
  .controller('MergeContactsModalCtrl', function MergeContactsModalCtrl(
    $scope,
    $q,
    $uibModalInstance,
    $state,
    $window,
    clientsToMerge,
    countryCode,
    contactService,
    mergeContactsService,
    toaster
  ) {
    $scope.clientsToMerge = clientsToMerge;
    $scope.countryCode = countryCode;
    $scope.mergeability = {};

    $scope.activate = () => {
      mergeContactsService.initializeClientsToMergeFamilyInfo($scope.clientsToMerge).then(() => $scope.getClientsLoanApps());
    };

    $scope.viewContact = (client) => {
      const url = $state.href('app.contactsSingle', { familyId: client.FamilyID });
      $window.open(url, '_blank');
    };

    $scope.getClientsLoanApps = () => {
      const promises = [];

      _.each($scope.clientsToMerge, (client) => {
        promises.push(contactService.loanListGet(client.FamilyID));
      });

      $q.all(promises).then((responses) => {
        _.each(responses, (response) => {
          const familyID = $scope.getParameterByName('familyID', response.config.url);
          const client = _.find($scope.clientsToMerge, (obj) => {
            return obj.FamilyID === familyID;
          });
          if (client) {
            client.loanApps = _.sortBy(_.filter(response.data, (loanApp) => {
              return loanApp.Lender && loanApp.Lender.LenderName;
            }), (loanApp) => {
              return loanApp.CreatedDate;
            });
          }
        });
      });
    };

    $scope.getParameterByName = (name, url) => {
      const regex = new RegExp(`[?&]${name.replace(/[[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      return results && results.length === 3 && results[2] ? decodeURIComponent(results[2].replace(/\+/g, ' ')) : null;
    };

    $scope.cancel = () => {
      _.each($scope.clientsToMerge, (client) => {
        client.keepRecords = false;
      });
      $uibModalInstance.dismiss('cancelled');
    };

    $scope.merge = () => {
      $scope.merging = true;

      mergeContactsService.mergeDuplicateContacts($scope.clientsToMerge).then((response) => {
        if (response.data) {
          toaster.pop('success', 'Clients successfully merged');
          $uibModalInstance.close();
          $scope.openMergedClient(response.data.FamilyID);
        } else {
          toaster.pop('error', 'Clients not merged successfullly');
        }

        $scope.merging = false;
      }, () => {
        $scope.merging = false;
        toaster.pop('error', 'Clients not merged successfullly');
      });
    };

    $scope.openMergedClient = (mainFamilyId) => {
      $state.go('app.contactsSingle', { familyId: mainFamilyId });
    };

    $scope.toggleClientKeepRecords = (client) => {
      _.each($scope.clientsToMerge, (obj) => {
        obj.keepRecords = obj.FamilyID === client.FamilyID;
      });

      mergeContactsService.checkClientsMergeability($scope.clientsToMerge, client.FamilyID).then((response) => {
        $scope.mergeability.mergeable = response.mergeable;
        $scope.mergeability.warning = response.warning;
      });
    };

    $scope.activate();
  });
