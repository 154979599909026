class OnboardingNavigationCtrl {
  constructor(
    $sce
  ) {
    'ngInject';

    this.tabs = [];
    this.activeTab = 0;
    this.completedColor = '#42B4B4';
    this.incompleteColor = '#9A86A8';
    this.$sce = $sce;
  }

  trustAs(src) {
    return this.$sce.trustAsHtml(src);
  }

  $onChanges(changes) {
    if (changes && changes.tabs) {
      this.tabs = changes.tabs.currentValue;
    }
    if (changes && changes.activeTab) {
      this.activeTab = parseInt(changes.activeTab.currentValue, 0);
    }
  }
}

export default OnboardingNavigationCtrl;
