import angular from 'angular';

angular.module('app').directive('bindCompiledHtml', function bindCompiledHtml(
  $compile,
  $rootScope
) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const html = scope.$eval(attrs.bindCompiledHtml);
      const toCompile = angular.element(html);
      element.append($compile(toCompile)(scope));

      scope.init = function () {
        const html2 = scope.$eval(attrs.htmlToAddAttr);
        const toCompile2 = angular.element(html2);
        element.append($compile(toCompile2)(scope));
      };

      const forceCompileBroadcastHandler = $rootScope.$on('forceCompile', () => {
        scope.init();
      });
      scope.$on('$destroy', forceCompileBroadcastHandler);
    },
  };
});
