const INSURANCE_CLIENTS = {
  MAX: 2,
  MORE: 3,
};


class LimitClients {
  constructor(toaster) {
    'ngInject';

    this.toaster = toaster;
  }
  onlyTwoClientsProfiler(numberOfClients, formData) {
    if (numberOfClients === INSURANCE_CLIENTS.MORE) {
      formData.NumberOfClients = INSURANCE_CLIENTS.MAX;
      this.limitMessage();
    } else this.clientsAdult = formData;
    return this.clientsAdult;
  }
  onlyTwoClientsQuote(numberOfClients) {
    if (numberOfClients === INSURANCE_CLIENTS.MORE) {
      this.limitMessage();
      this.clientsAdult = false;
    } else this.clientsAdult = true;
    return this.clientsAdult;
  }
  limitMessage() {
    this.toaster.pop('info', 'Maximum Allowed Clients', 'Only 2 Clients are Allowed');
  }
}
export default LimitClients;
