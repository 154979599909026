import { LOAN_SUBMISSION_EMAIL_TYPE } from 'Common/constants/loanSubmissionEmailType';

class EditEmailContentModalCtrl {
  constructor($q, loanScenarioService, loanSubmissionEmailContentService, ckEditorConfigService, toaster, $state) {
    'ngInject';

    this.$q = $q;
    this.loanScenarioService = loanScenarioService;
    this.loanSubmissionEmailContentService = loanSubmissionEmailContentService;
    this.ckEditorConfigService = ckEditorConfigService;
    this.toaster = toaster;
    this.$state = $state;
  }

  $onInit() {
    this.emailEditorOptions = this.ckEditorConfigService.getCommonEmailEditorConfig();
    this.emailContent = {};

    if (this.lenderSubmissionObj) {
      this.initEmailContent();
    }
  }

  initEmailContent() {
    this.emailContent = Object.assign({}, this.lenderSubmissionObj.LenderSubmissionSet);
    switch (this.emailType) {
    case LOAN_SUBMISSION_EMAIL_TYPE.CLIENT:
      this.initClientEmailContent();
      break;
    case LOAN_SUBMISSION_EMAIL_TYPE.LENDER:
      this.initLenderEmailContent();
      break;
    default:
      break;
    }
  }

  initLenderEmailContent() {
    const { loanAppId, familyId } = this.lenderSubmissionObj;
    this.loanSubmissionEmailContentService.getDefaultEmail(loanAppId, familyId, true)
      .then((defaultEmail) => {
        if (!defaultEmail) return;

        if (!this.emailContent) this.initEmailContent();
        if (defaultEmail.subject) {
          this.emailContent.CustomEmailSubject = defaultEmail.subject;
        }
        if (defaultEmail.content) {
          this.emailContent.CustomEmailContent = defaultEmail.content;
        }
      });
  }

  initClientEmailContent() {
    const { ApplicantEmailSubject: subject, ApplicantEmailBody: content } = this.lenderSubmissionObj.LenderSubmissionSet;
    if (!this.emailContent) this.initEmailContent();
    this.emailContent.CustomEmailSubject = subject;
    this.emailContent.CustomEmailContent = content;
  }

  cancel() {
    this.modalInstance.dismiss('cancelled');
  }

  save() {
    if (!this.loanSubmissionEmailContentService.isValidLenderSubmissionObj(this.lenderSubmissionObj)) {
      this.modalInstance.dismiss('invalid_args');
    } else if (this.emailContent && this.emailContent.CustomEmailSubject) {
      this.isSaving = true;
      this.emailContent.CustomEmailContent = this.emailContent.CustomEmailContent || '';
      const promises = [];
      const lenderSubmissionEmailSubject = this.getEmailSubjectPostBody();
      const lenderSubmissionEmailContent = this.getEmailContentPostBody();
      const { familyId } = this.$state.params;
      const isUpdatingCorrectFamilyId = lenderSubmissionEmailSubject.FamilyId === familyId && lenderSubmissionEmailContent.FamilyId === familyId;
      if (isUpdatingCorrectFamilyId) {
        const saveSubjectPromise = this.loanScenarioService.lenderSubmissionSet(lenderSubmissionEmailSubject);
        const saveContentPromise = this.loanScenarioService.lenderSubmissionSet(lenderSubmissionEmailContent);
        promises.push(saveSubjectPromise);
        promises.push(saveContentPromise);

        this.$q.all(promises).then(() => {
          this.isSaving = false;
          this.modalInstance.close(this.emailContent);
          this.toaster.pop('success', 'Saving Success', 'Changes to the Email Content were saved.');
        }, () => {
          this.isSaving = false;
          this.toaster.pop('error', 'Saving Error', 'There was an issue saving your changes to the Email Content.');
        });
      } else {
        this.isSaving = false;
      }
    }
  }

  getEmailSubjectPostBody() {
    const { loanAppId: LoanScenarioId, familyId: FamilyId, emailSubjectQuestion } = this.lenderSubmissionObj;
    const { QuestionId, QuestionDisplayTypeId: DisplayOptionId } = emailSubjectQuestion;
    return {
      LoanScenarioId,
      FamilyId,
      QuestionId,
      DisplayOptionId,
      answerString: [String(this.emailContent.CustomEmailSubject).trim()],
    };
  }

  getEmailContentPostBody() {
    const { loanAppId: LoanScenarioId, familyId: FamilyId, emailContentQuestion } = this.lenderSubmissionObj;
    const { QuestionId, QuestionDisplayTypeId: DisplayOptionId } = emailContentQuestion;
    return {
      LoanScenarioId,
      FamilyId,
      QuestionId,
      DisplayOptionId,
      answerString: [String(this.emailContent.CustomEmailContent).trim()],
    };
  }
}

export default EditEmailContentModalCtrl;
