import { pipelineSettingsBuilderForAPI,
  pipelineSettingsBuilderForUI } from 'Common/mappers/pipeline';


class PipelineSettings {
  constructor(args = {}) {
    'ngInject';

    this.setSavedSettings(args);
  }

  setSavedSettings(args) {
    const {
      sortColumn,
      sortType,
      statusId,
      advisorId,
      labelId,
      probability,
      referralItemID,
      referralCategoryID,
      referral,
      isCompact,
      sortingMode,
      isTableView,
      customerSort,
      tableViewExcludedColumns,
      subStatusId,
      searchClientName,
      pipelineCardsId } = args;

    this.sortColumn = sortColumn || 'ClientName';
    this.sortType = sortType || 'DESC';
    this.statusId = statusId || 0;
    this.advisorId = advisorId || 0;
    this.labelId = labelId || 0;
    this.probability = probability || 'All';
    this.referralItemID = referralItemID || 0;
    this.referralCategoryID = referralCategoryID || 0;
    this.referral = referral || 'All';
    this.isCompact = isCompact || false;
    this.sortingMode = sortingMode || '';
    this.isTableView = isTableView || false;
    this.customerSort = customerSort || '';
    this.tableViewExcludedColumns = tableViewExcludedColumns || '';
    this.subStatusId = subStatusId || 0;
    this.searchClientName = searchClientName || '';
    this.pipelineCardsId = pipelineCardsId || 0;
    this.isOpportunity = true;
  }
}

export class PipelineOpportunitySettingsService extends PipelineSettings {
  constructor(args = {}, services = {}) {
    'ngInject';

    super(args);
    const { pipelineService } = services;
    const { advisorId } = args;

    if (pipelineService && args && advisorId) {
      this.pipelineService = pipelineService;
      this.setSavedSettings(args);
      this.setPipelineSettings({ ...this });
    } else if (pipelineService && !args && !advisorId) {
      this.pipelineService = pipelineService;
      this.setSavedSettings({ ...this });
      this.setPipelineSettings();
    }
  }

  setPipelineSettings(args) {
    this.pipelineService.setPipelineCardFilters(pipelineSettingsBuilderForAPI(args))
      .then((res) => {
        const { data } = res;
        if (!data || !data.length) return;
        this.setSavedSettings(pipelineSettingsBuilderForUI(data[0]));
      });
  }
}
