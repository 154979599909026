import angular from 'angular';
import _ from 'lodash';
import { getCommissionUpdateData } from 'Common/utilities/commission';

angular.module('app').controller('ExpectedCommissionDetailsCtrl', function ExpectedCommissionDetailsCtrl(
  $uibModalInstance,
  SweetAlert,
  toaster,
  commissionDetails,
  typeList,
  dateSettings,
  contactService,
  utilitiesService,
  commissionTypesList,
  isFutureExpected
) {
  const vm = this;

  vm.isFutureExpected = isFutureExpected;
  vm.commissionDetails = commissionDetails;
  vm.typeList = typeList;
  vm.dateSettings = dateSettings;

  /*
  *@name cancel
  *@desc closes modal
  */
  vm.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

  vm.updateCommission = () => {
    SweetAlert.swal({
      title: 'Are you sure?', text: 'This record will be removed from your commission list', type: 'info', showCancelButton: true, confirmButtonColor: '#F68F8F', confirmButtonText: 'Yes, confirm it!', closeOnConfirm: true,
    }, (confirm) => {
      if (confirm) {
        const type = _.find(commissionTypesList, (obj) => {
          return obj.Name === vm.commissionDetails.TypeName;
        });
        const commissionUpdate = getCommissionUpdateData(vm.commissionDetails, type);

        contactService.loansExpectedCommissionsSet(commissionUpdate).then(() => {
          toaster.pop('success', 'Updated', 'Commission successfully updated');
        });
      } else {
        vm.commissionDetails.isConfirm = false;
      }
    });
  };

  vm.getInitials = (name) => {
    return utilitiesService.filterInitialOneString(name);
  };

  vm.triggerDelete = () => {
    SweetAlert.swal({
      title: 'Are you sure?',
      text: 'This commission will be removed from the list.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, remove it!',
      closeOnConfirm: true,
    }, (isConfirm) => {
      if (isConfirm) {
        contactService.loansExpectedCommissionsDelete(vm.commissionDetails.CommissionId).then(() => toaster.pop('success', 'Deleted', 'Commission successfully deleted'));

        $uibModalInstance.close({ commission: vm.commissionDetails, actionDone: 'delete' });
      }
    });
  };
});
