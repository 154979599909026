import angular from 'angular';
import sendIcon from './sendIcon.svg';
import infoIcon from './infoIcon.svg';
import thumbnailIcon from './thumbnailIcon.svg';
import circledpersonIcon from './circledpersonIcon.svg';
import shieldIcon from './shieldIcon.svg';
import iconRelationships from './iconRelationships.svg';
import phoneSm from './phoneSm.svg';
import iconSubscriptions from './iconSubscriptions.svg';
import factFindIcon from './factFindIcon.svg';
import smsIcon from './smsIcon.svg';
import npsIcon from './npsIcon.svg';
import campaignBreezeIcon from './campaignBreezeIcon.svg';
import ownedIcon from './ownedIcon.svg';
import close from './close.svg';
import arrowRight from './arrowRight.svg';
import addSmIcon from './addSmIcon.svg';
import bankStatementIcon from './bankStatementIcon.svg';
import illustrationDocIcon from './illustrationDocIcon.svg';
import illustrationInviteIcon from './illustrationInviteIcon.svg';
import illustrationLocks from './illustrationLocks.svg';
import illustrationNotifyIcon from './illustrationNotifyIcon.svg';
import illustrationSmsIcon from './illustrationSmsIcon.svg';
import mycrmLogo from './mycrmLogo.svg';
import trashIcon from './trashIcon.svg';
import headerSurveyIcon from './headerSurveyIcon.svg';
import gmailIcon from './gmailIcon.svg';
import gmailIconBig from './gmailIconBig.svg';
import phoneIcon from './phoneIcon.svg';
import emailIcon from './emailIcon.svg';
import openNewWindowIcon from './openNewWindowIcon.svg';
import arrowIcon from './arrowIcon.svg';
import settingsIcon from './settingsIcon.svg';
import applicationIcon from './applicationIcon.svg';
import gdriveIconBig from './gdriveIconBig.svg';
import gdriveIconSmall from './gdriveIconSmall.svg';
import referralBrokerIcon from './iconReferralBrokerSupport.svg';
import referralOptionsIcon from './iconReferralOptions.svg';
import folderGdriveIcon from './folderGdriveIcon.svg';
import folderGdriveIconBig from './folderGdriveIconBig.svg';
import pipelineNodots from './pipelineNodots.svg';
import iconPdf from './iconPdf.svg';
import iconGearV2 from './iconGearV2.svg';
import iconPersonMf from './iconPersonMf.svg';
import iconPersonChild from './iconPersonChild.svg';
import iconConvert from './iconConvert.svg';
import downloadIcon from './downloadIcon.svg';
import fundingDeficitIcon from './fundingDeficitIcon.svg';
import fundingSurplusIcon from './fundingSurplusIcon.svg';
import lendingIcon from './lendingIcon.svg';
import editIcon from './editIcon.svg';
import deleteIcon from './deleteIcon.svg';
import taskListIcon from './taskListIcon.svg';
import workbenchIcon from './workbenchIcon.svg';
import addressBookIcon from './iconAddressBook.svg';
import refreshIcon from './refreshIcon.svg';
import incomeEmploymentIcon from './incomeEmploymentIcon.svg';
import noteIcon from './noteIcon.svg';
import addPersonIcon from './addPersonIcon.svg';
import autoMarketingIcon from './autoMarketingIcon.svg';
import eye from './eye.svg';
import eyeHide from './eyeHide.svg';
import searchIcon from './searchIcon.svg';
import starIcon from './iconStar.svg';
import plusIcon from './plusIcon.svg';
import iconComment from './iconComment.svg';
import gdriveFolderBetaIconSmall from './gdriveFolderBetaIconSmall.svg';
import gdriveFolderBetaIconBig from './gdriveFolderBetaIconBig.svg';
import gmailBetaIconSmall from './gmailBetaIconSmall.svg';
import gmailBetaIconBig from './gmailBetaIconBig.svg';
import gdriveBetaIconSmall from './gdriveBetaIconSmall.svg';
import gdriveBetaIconBig from './gdriveBetaIconBig.svg';
import documentTabIcon from './documentTabIcon.svg';
import iconXls from './iconXls.svg';
import iconPowerpoint from './iconPowerpoint.svg';
import iconText from './iconText.svg';
import iconGeneric from './iconGeneric.svg';
import documentPdfIcon from './documentPdfIcon.svg';
import caretUpIcon from './caret-up.svg';
import caretDownIcon from './caret-down.svg';
import complianceInfoIcon from './complianceInfoIcon.svg';
import dateAscIcon from './dateAscIcon.svg';
import dateDescIcon from './dateDescIcon.svg';
import selectedSortingIcon from './selectedSortingIcon.svg';
import categorySortingIcon from './categorySortingIcon.svg';
import resetIcon from './resetIcon.svg';
import arrowPreviousRelease from './arrowPreviousRelease.svg';
import replay from './replay.svg';
import iconSubtract from './iconSubtract.svg';
import iconAdd from './iconAdd.svg';
import noIncomeIcon from './noIncomeIcon.svg';
import attachementIcon from './attachment-icon.svg';
import removeIcon from './removeIcon.svg';
import sectionLockedIcon from './sectionLockedIcon.svg';
import creditCheckIcon from './creditCheckIcon.svg';
import subscriptionCreditIcon from './subscriptionCreditIcon.svg';
import documentSpreadsheetIcon from './documentSpreadsheetIcon.svg';
import tealNoteIcon from './tealNoteIcon.svg';
import quickFindCheckIcon from './quickFindCheckIcon.svg';
import quickFindFilterIcon from './quickFindFilterIcon.svg';
import check from './check.svg';
import times from './times.svg';
import refreshIconVividBlue from './refreshIconVividBlue.svg';
import iconBusiness from './iconBusiness.svg';
import iconEmail from './iconEmail.svg';
import iconMail from './iconMail.svg';
import iconSendMail from './iconSendMail.svg';
import iconHome from './iconHome.svg';
import iconMapPin from './iconMapPin.svg';
import threeDotsOption from './threeDotsOption.svg';
import generalSetting from './generalSetting.svg';
import heartDarker from './heartDarker.svg';
import heartLighter from './heartLighter.svg';
import heartPink from './heartPink.svg';
import taskCheckIcon from './taskCheckIcon.svg';
import taskTrashIcon from './taskTrashIcon.svg';
import flagAu from './au.svg';
import flagNz from './nz.svg';
import flagId from './id.svg';
import gmailArrowIconUp from './gmailArrowIconUp.svg';
import gmailArrowIconDown from './gmailArrowIconDown.svg';
import iconCalendar from './iconCal.svg';
import iconShared from './iconShared.svg';
import iconLocked from './iconLocked.svg';
import iconLockSm from './iconLockSm.svg';
import lockedIconCircle from './lockedIconCircle.svg';
import sectionWarning from './sectionWarning.svg';
import sectionNotStarted from './sectionNotStarted.svg';
import sectionDone from './sectionDone.svg';
import iconQuestion from './iconQuestion.svg';
import iconRisk from './iconRisk.svg';
import iconSolution from './iconSolution.svg';
import workbench from './workbench.svg';
import due from './due.svg';
import task from './task.svg';
import notes from './notes.svg';
import peopleCircle from './peopleCircle.svg';
import summaryCircle from './summaryCircle.svg';
import phoneHome from './phoneHome.svg';
import phoneMobile from './phoneMobile.svg';
import kanbanViewIcon from './kanbanViewIcon.svg';
import tableViewIcon from './tableViewIcon.svg';
import servicibilityCalculator from './servicibilityCalculator.svg';
import fundingCalculator from './fundingCalculator.svg';
import productComparison from './productComparison.svg';
import calculationSectionCircle from './calculationSectionCircle.svg';
import calendar from './calendar.svg';
import dollar from './dollar.svg';
import leftNotStartedIcon from './leftNotStartedIcon.svg';
import iconNotePin from './iconNotePin.svg';
import iconNotePinSm from './iconNotePinSm.svg';
import iconMultiNotes from './iconMultiNotes.svg';
import goToIcon from './goToIcon.svg';
import iconChevUp from './iconChevUp.svg';
import iconChevDown from './iconChevDown.svg';
import iconXlsTransparent from './iconXlsTransparent.svg';
import iconDocumentTransparent from './iconDocumentTransparent.svg';
import iconEdit from './iconEdit.svg';
import iconInfoCircle from './iconInfoCircle.svg';
import smileColored from './smileColored.svg';
import frownColored from './frownColored.svg';
import magnifyingGlass from './magnifyingGlass.svg';
import linkIcon from './linkIcon.svg';
import unlinkIcon from './unlinkIcon.svg';
import illustrationSurvey from './illustrationSurveyImage.svg';
import iconLink from './iconLink.svg';
import iconDiscount from './iconDiscount.svg';
import iconGuarantor from './iconGuarantor.svg';
import downloadStatementIcon from './downloadStatementIcon.svg';
import iconDeselect from './iconDeselect.svg';
import iconFeatures from './iconFeatures.svg';
import iconStateTeam from './iconStateTeam.svg';
import iconPhone from './iconPhone.svg';
import iconAccounts from './iconAccounts.svg';
import iconAgreements from './iconAgreements.svg';
import iconCommissions from './iconCommissions.svg';
import iconCompliance from './iconCompliance.svg';
import iconIt from './iconIt.svg';
import iconMarketing from './iconMarketing.svg';
import iconOnboarding from './iconOnboarding.svg';
import adviceNotesSummary from './adviceNotesSummary.svg';
import iconCheckbox from './iconCheckbox.svg';
import iconClearing from './iconClearing.svg';
import iconLoan from './iconLoan.svg';
import iconArrowUp from './iconArrowUp.svg';
import iconArrowDown from './iconArrowDown.svg';
import iconBack from './iconBack.svg';
import iconWeb from './iconWeb.svg';
import ballparkTotal from './ballparkTotal.svg';
import iconConnect from './iconConnect.svg';
import iconListView from './iconListView.svg';
import iconCardsView from './iconCardsView.svg';
import iconHealthCover from './iconHealthCover.svg';
import iconLifeCover from './iconLifeCover.svg';
import iconFamilyProtectionCover from './iconFamilyProtectionCover.svg';
import iconTraumaCover from './iconTraumaCover.svg';
import iconTPDCover from './iconTPDCover.svg';
import iconIncomeProtection from './iconIncomeProtection.svg';
import iconMortgageRepaymentCover from './iconMortgageRepaymentCover.svg';
import iconRedundancyCover from './iconRedundancyCover.svg';
import iconWaiverOfPremium from './iconWaiverOfPremium.svg';
import iconProperty from './iconProperty.svg';
import iconTextNotes from './iconTextNotes.svg';
import iconMenu from './iconMenu.svg';
import iconDuplicate from './iconDuplicate.svg';
import iconTripleGears from './iconTripleGears.svg';
import iconApplicationOnline from './iconApplicationOnline.svg';
import iconSettlement from './iconSettlement.svg';
import iconLeadManagement from './iconLeadManagement.svg';
import iconLeadStatus from './iconLeadStatus.svg';
import distanceMarkerIcon from './distanceMarkerIcon.svg';
import allocations from './allocations.svg';
import iconDuplicateContact from './iconDuplicateContact.svg';
import iconJoinFamily from './iconJoinFamily.svg';
import iconMarryClientCheck1 from './iconMarryClientCheck1.svg';
import iconMarryClientCheck2 from './iconMarryClientCheck2.svg';
import iconMarryData1 from './iconMarryData1.svg';
import iconMarryData2 from './iconMarryData2.svg';
import iconMarryClientPlus from './iconMarryClientPlus.svg';
import iconInsurance from './iconInsurance.svg';

export default angular.module('common.icons', [])
  .component('magnifyingGlass', { template: magnifyingGlass })
  .component('sendIcon', { template: sendIcon })
  .component('infoIcon', { template: infoIcon })
  .component('thumbnailIcon', { template: thumbnailIcon })
  .component('circledpersonIcon', { template: circledpersonIcon })
  .component('shieldIcon', { template: shieldIcon })
  .component('iconRelationships', { template: iconRelationships })
  .component('phoneSm', { template: phoneSm })
  .component('iconSubscriptions', { template: iconSubscriptions })
  .component('factFindIcon', { template: factFindIcon })
  .component('smsIcon', { template: smsIcon })
  .component('npsIcon', { template: npsIcon })
  .component('campaignBreezeIcon', { template: campaignBreezeIcon })
  .component('ownedIcon', { template: ownedIcon })
  .component('close', { template: close })
  .component('arrowRight', { template: arrowRight })
  .component('addSmIcon', { template: addSmIcon })
  .component('bankStatementIcon', { template: bankStatementIcon })
  .component('illustrationDocIcon', { template: illustrationDocIcon })
  .component('illustrationInviteIcon', { template: illustrationInviteIcon })
  .component('illustrationLocks', { template: illustrationLocks })
  .component('illustrationNotifyIcon', { template: illustrationNotifyIcon })
  .component('illustrationSmsIcon', { template: illustrationSmsIcon })
  .component('mycrmLogo', { template: mycrmLogo })
  .component('trashIcon', { template: trashIcon })
  .component('headerSurveyIcon', { template: headerSurveyIcon })
  .component('gmailIcon', { template: gmailIcon })
  .component('gmailIconBig', { template: gmailIconBig })
  .component('phoneIcon', { template: phoneIcon })
  .component('emailIcon', { template: emailIcon })
  .component('openNewWindowIcon', { template: openNewWindowIcon })
  .component('arrowIcon', { template: arrowIcon })
  .component('settingsIcon', { template: settingsIcon })
  .component('applicationIcon', { template: applicationIcon })
  .component('gdriveIconBig', { template: gdriveIconBig })
  .component('gdriveIconSmall', { template: gdriveIconSmall })
  .component('referralBrokerIcon', { template: referralBrokerIcon })
  .component('referralOptionsIcon', { template: referralOptionsIcon })
  .component('folderGdriveIcon', { template: folderGdriveIcon })
  .component('folderGdriveIconBig', { template: folderGdriveIconBig })
  .component('iconPdf', { template: iconPdf })
  .component('iconGearV2', { template: iconGearV2 })
  .component('iconPersonMf', { template: iconPersonMf })
  .component('iconPersonChild', { template: iconPersonChild })
  .component('iconConvert', { template: iconConvert })
  .component('downloadIcon', { template: downloadIcon })
  .component('fundingDeficitIcon', { template: fundingDeficitIcon })
  .component('fundingSurplusIcon', { template: fundingSurplusIcon })
  .component('lendingIcon', { template: lendingIcon })
  .component('editIcon', { template: editIcon })
  .component('deleteIcon', { template: deleteIcon })
  .component('taskListIcon', { template: taskListIcon })
  .component('workbenchIcon', { template: workbenchIcon })
  .component('addressBookIcon', { template: addressBookIcon })
  .component('refreshIcon', { template: refreshIcon })
  .component('incomeEmploymentIcon', { template: incomeEmploymentIcon })
  .component('noteIcon', { template: noteIcon })
  .component('addPersonIcon', { template: addPersonIcon })
  .component('autoMarketingIcon', { template: autoMarketingIcon })
  .component('eye', { template: eye })
  .component('eyeHide', { template: eyeHide })
  .component('searchIcon', { template: searchIcon })
  .component('starIcon', { template: starIcon })
  .component('plusIcon', { template: plusIcon })
  .component('iconComment', { template: iconComment })
  .component('gdriveFolderBetaIconSmall', { template: gdriveFolderBetaIconSmall })
  .component('gdriveFolderBetaIconBig', { template: gdriveFolderBetaIconBig })
  .component('gmailBetaIconSmall', { template: gmailBetaIconSmall })
  .component('gmailBetaIconBig', { template: gmailBetaIconBig })
  .component('gdriveBetaIconSmall', { template: gdriveBetaIconSmall })
  .component('gdriveBetaIconBig', { template: gdriveBetaIconBig })
  .component('documentTabIcon', { template: documentTabIcon })
  .component('iconXls', { template: iconXls })
  .component('iconPowerpoint', { template: iconPowerpoint })
  .component('iconText', { template: iconText })
  .component('iconGeneric', { template: iconGeneric })
  .component('documentPdfIcon', { template: documentPdfIcon })
  .component('caretUpIcon', { template: caretUpIcon })
  .component('caretDownIcon', { template: caretDownIcon })
  .component('complianceInfoIcon', { template: complianceInfoIcon })
  .component('dateAscIcon', { template: dateAscIcon })
  .component('selectedSortingIcon', { template: selectedSortingIcon })
  .component('dateDescIcon', { template: dateDescIcon })
  .component('categorySortingIcon', { template: categorySortingIcon })
  .component('resetIcon', { template: resetIcon })
  .component('arrowPreviousRelease', { template: arrowPreviousRelease })
  .component('replay', { template: replay })
  .component('iconSubtract', { template: iconSubtract })
  .component('iconAdd', { template: iconAdd })
  .component('noIncomeIcon', { template: noIncomeIcon })
  .component('attachementIcon', { template: attachementIcon })
  .component('removeIcon', { template: removeIcon })
  .component('sectionLockedIcon', { template: sectionLockedIcon })
  .component('creditCheckIcon', { template: creditCheckIcon })
  .component('subscriptionCreditIcon', { template: subscriptionCreditIcon })
  .component('documentSpreadsheetIcon', { template: documentSpreadsheetIcon })
  .component('tealNoteIcon', { template: tealNoteIcon })
  .component('quickFindCheckIcon', { template: quickFindCheckIcon })
  .component('quickFindFilterIcon', { template: quickFindFilterIcon })
  .component('check', { template: check })
  .component('times', { template: times })
  .component('refreshIconVividBlue', { template: refreshIconVividBlue })
  .component('iconBusiness', { template: iconBusiness })
  .component('iconEmail', { template: iconEmail })
  .component('iconMail', { template: iconMail })
  .component('iconSendMail', { template: iconSendMail })
  .component('iconHome', { template: iconHome })
  .component('iconMapPin', { template: iconMapPin })
  .component('threeDotsOption', { template: threeDotsOption })
  .component('generalSetting', { template: generalSetting })
  .component('heartDarker', { template: heartDarker })
  .component('heartLighter', { template: heartLighter })
  .component('heartPink', { template: heartPink })
  .component('taskCheckIcon', { template: taskCheckIcon })
  .component('taskTrashIcon', { template: taskTrashIcon })
  .component('flagAu', { template: flagAu })
  .component('flagNz', { template: flagNz })
  .component('flagId', { template: flagId })
  .component('gmailArrowIconDown', { template: gmailArrowIconDown })
  .component('gmailArrowIconUp', { template: gmailArrowIconUp })
  .component('iconCalendar', { template: iconCalendar })
  .component('iconShared', { template: iconShared })
  .component('iconLocked', { template: iconLocked })
  .component('iconLockSm', { template: iconLockSm })
  .component('pipelineNodotsIcon', { template: pipelineNodots })
  .component('lockedIconCircle', { template: lockedIconCircle })
  .component('sectionWarning', { template: sectionWarning })
  .component('sectionNotStarted', { template: sectionNotStarted })
  .component('sectionDone', { template: sectionDone })
  .component('iconQuestion', { template: iconQuestion })
  .component('iconRisk', { template: iconRisk })
  .component('iconSolution', { template: iconSolution })
  .component('workbench', { template: workbench })
  .component('due', { template: due })
  .component('task', { template: task })
  .component('notes', { template: notes })
  .component('peopleCircle', { template: peopleCircle })
  .component('phoneHome', { template: phoneHome })
  .component('phoneMobile', { template: phoneMobile })
  .component('summaryCircle', { template: summaryCircle })
  .component('kanbanViewIcon', { template: kanbanViewIcon })
  .component('tableViewIcon', { template: tableViewIcon })
  .component('servicibilityCalculator', { template: servicibilityCalculator })
  .component('fundingCalculator', { template: fundingCalculator })
  .component('productComparison', { template: productComparison })
  .component('calculationSectionCircle', { template: calculationSectionCircle })
  .component('calendar', { template: calendar })
  .component('dollar', { template: dollar })
  .component('leftNotStartedIcon', { template: leftNotStartedIcon })
  .component('iconNotePin', { template: iconNotePin })
  .component('iconNotePinSm', { template: iconNotePinSm })
  .component('iconMultiNotes', { template: iconMultiNotes })
  .component('goToIcon', { template: goToIcon })
  .component('iconChevUp', { template: iconChevUp })
  .component('iconChevDown', { template: iconChevDown })
  .component('iconXlsTransparent', { template: iconXlsTransparent })
  .component('iconDocumentTransparent', { template: iconDocumentTransparent })
  .component('iconEdit', { template: iconEdit })
  .component('iconInfoCircle', { template: iconInfoCircle })
  .component('smileColored', { template: smileColored })
  .component('frownColored', { template: frownColored })
  .component('linkIcon', { template: linkIcon })
  .component('unlinkIcon', { template: unlinkIcon })
  .component('illustrationSurveyImage', { template: illustrationSurvey })
  .component('iconLink', { template: iconLink })
  .component('iconDiscount', { template: iconDiscount })
  .component('iconGuarantor', { template: iconGuarantor })
  .component('downloadStatementIcon', { template: downloadStatementIcon })
  .component('iconDeselect', { template: iconDeselect })
  .component('iconFeatures', { template: iconFeatures })
  .component('iconStateTeam', { template: iconStateTeam })
  .component('iconPhone', { template: iconPhone })
  .component('iconAccounts', { template: iconAccounts })
  .component('iconAgreements', { template: iconAgreements })
  .component('iconCommissions', { template: iconCommissions })
  .component('iconCompliance', { template: iconCompliance })
  .component('iconIt', { template: iconIt })
  .component('iconMarketing', { template: iconMarketing })
  .component('iconOnboarding', { template: iconOnboarding })
  .component('ballparkTotal', { template: ballparkTotal })
  .component('adviceNotesSummary', { template: adviceNotesSummary })
  .component('iconCheckbox', { template: iconCheckbox })
  .component('iconClearing', { template: iconClearing })
  .component('iconLoan', { template: iconLoan })
  .component('iconArrowUp', { template: iconArrowUp })
  .component('iconArrowDown', { template: iconArrowDown })
  .component('iconBack', { template: iconBack })
  .component('iconWeb', { template: iconWeb })
  .component('iconConnect', { template: iconConnect })
  .component('iconListView', { template: iconListView })
  .component('iconCardsView', { template: iconCardsView })
  .component('iconHealthCover', { template: iconHealthCover })
  .component('iconLifeCover', { template: iconLifeCover })
  .component('iconFamilyProtectionCover', { template: iconFamilyProtectionCover })
  .component('iconTraumaCover', { template: iconTraumaCover })
  .component('iconTpdCover', { template: iconTPDCover })
  .component('iconIncomeProtection', { template: iconIncomeProtection })
  .component('iconMortgageRepaymentCover', { template: iconMortgageRepaymentCover })
  .component('iconRedundancyCover', { template: iconRedundancyCover })
  .component('iconWaiverOfPremium', { template: iconWaiverOfPremium })
  .component('iconProperty', { template: iconProperty })
  .component('iconTextNotes', { template: iconTextNotes })
  .component('iconMenu', { template: iconMenu })
  .component('iconDuplicate', { template: iconDuplicate })
  .component('iconTripleGears', { template: iconTripleGears })
  .component('iconApplicationOnline', { template: iconApplicationOnline })
  .component('iconSettlement', { template: iconSettlement })
  .component('iconLeadManagement', { template: iconLeadManagement })
  .component('iconLeadStatus', { template: iconLeadStatus })
  .component('distanceMarkerIcon', { template: distanceMarkerIcon })
  .component('allocations', { template: allocations })
  .component('iconDuplicateContact', { template: iconDuplicateContact })
  .component('iconJoinFamily', { template: iconJoinFamily })
  .component('iconMarryClientCheck1', { template: iconMarryClientCheck1 })
  .component('iconMarryClientCheck2', { template: iconMarryClientCheck2 })
  .component('iconMarryData1', { template: iconMarryData1 })
  .component('iconMarryData2', { template: iconMarryData2 })
  .component('iconMarryClientPlus', { template: iconMarryClientPlus })
  .component('iconInsurance', { template: iconInsurance })
  .name;
