import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { isMobileValid } from 'Common/utilities/mobile';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { REFERRAL_ITEM, CLIENT_REFERRAL, ADDITIONAL_REFERRAL } from 'Common/constants/enquirySource';
import OpenOnlineFactFindForm from 'Components/usersSubscription/openOnlineFactFindForm';
import { ADDRESS_TYPE } from 'Common/constants/addressType';
import { displayField } from 'Common/utilities/displayField';
import { CONTACT_TYPE } from 'Common/constants/contactType';

angular.module('app').controller('CreateAccountCtrl',
  function CreateAccountCtrl(
    $q,
    $scope,
    contactService,
    $uibModal,
    $timeout,
    $state,
    SweetAlert,
    toaster,
    $localStorage,
    $interval,
    $stateParams,
    optionsService,
    corporateService,
    userService,
    generalService,
    dashboardService,
    $rootScope,
    $window,
    enquirySourceService,
    referencedReferralService,
    contactStatusService,
    contactSharedData,
    clientInfoService,
    loanScenarioService,
    accountService,
    configService,
    usersSubscriptionService,
    currentUserService
  ) {
    $scope.getNewContactModel = function (role) {
      return {
        FamilyId: '0',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        PreferredName: '',
        Title: '',
        Gender: '',
        DOB: '',
        Role: role,
        Employment: [
          {
            Occupation: '',
            OccupationClass: 0,
            Employer: '',
          },
        ],
        SmokerStatus: '',
        Deceased: false,
        DOBReminder: false,
        BestTimeToCall: '',
        IsResident: true,
        Phone: [
          {
            Type: 'Home',
            Number: '',
          },
          {
            Type: 'Work',
            Number: '',
          },
          {
            Type: 'Mobile',
            Number: '',

          },
        ],
        Email: [
          {
            Type: 'Email',
            EmailAddress: '',
          },
        ],
        Notes: '',
      };
    };

    $scope.client = {};
    $scope.business = {};
    $scope.accountTypeList = [];
    $scope.personDetails = [$scope.getNewContactModel('Adult')];
    $scope.childDetails = [];
    $scope.homeAutoAddressDetails = [];

    /* $scope.familyDetails = {
         "Categories": [],
         "ClientTypes": [],
         "Rating": [],
         "isMailingAddress": false,
         }; */
    // @TODO : Add mobile phone number
    $scope.contactAddress = {
      MailingAddress: '',
      HomeAddress: '',
      MainEmail: '',
      isMailingAddress: false,
    };

    $scope.currentMobileTab = 1;
    $scope.finalMobileTab = 3;

    /* If we are going to create a new contact from somewhere in the page that redirects here */
    if ($localStorage.loanApplicationCreateNewContact) {
      if ($localStorage.loanApplicationSelectedClientName !== undefined) {
        const fullName = $localStorage.loanApplicationSelectedClientName.split(' ');
        $scope.personDetails[0].FirstName = fullName[0];

        if (fullName.length > 1)
          $scope.personDetails[0].LastName = fullName[fullName.length - 1];
      }
    }
    /* End */

    /* for normal account contact create,  choose allocated adviser, for angucomplete-alt a 3rd party directive */
    $scope.allocatedAdviser = {};
    $scope.allocatedAdviser.adviserSelected = {};
    $scope.allocatedAdviser.adviserSelected.description = {};
    $scope.allocatedAdviser.adviserInitialValue = '';
    /* things needed to get the data, need to conenct to corporateService */
    if (typeof $scope.brokersInfo === 'undefined') {
      userService.GetUserInfo().then((response) => {
        if (response && response.data) {
          $scope.loggedInBrokerInfo = response.data;
          const { AccessType: access } = $scope.loggedInBrokerInfo;
          dashboardService.getBrokerBasicInfo().then((infoResponse) => {
            $scope.brokersInfo = infoResponse.data;
            if (parseInt(access, 10) === ACCESS_TYPE.PRINCIPAL_ADVISER || parseInt(access, 10) === ACCESS_TYPE.ADVISER)
              $scope.AssignedAdviserId = $scope.brokersInfo.BrokerId;
            $scope.getAccountType();
          });
        }
      });
    }
    angular.extend($scope, {
      getAdvisersList() {
        generalService.getBrokerAll().then((response) => {
          $scope.advisersList = response.data;
        });
      },
      sampleChange(id) {
        $scope.AssignedAdviserId = id;
        $scope.referredByHelper.loopCount = 0;
      },
      getAllBroker() {
        generalService.getBrokerAll().then((response) => {
          $scope.brokersList = response.data;
        });
      },
      getAccountType() {
        clientInfoService.getAccounTypeNumber().then((res) => {
          $scope.accessType = res.data;
          if ($scope.accessType) {
            if (parseInt($scope.accessType, 10) === 2) { /* adviser, change this else statement if condition is wrong based on the relationship */
              $scope.getAdvisersList($scope.brokerInfo.BrokerId);
            } else if (parseInt($scope.accessType, 10) === 1) { /* principal adviser */
              $scope.getAllBroker();
              $scope.getUserInfo();
            } else {
              $scope.getUserInfo();
            }
          }
        });
      },
      getUserInfo() {
        contactService.getUserInfo().then((responseContactCreate) => {
          $scope.userInfo = responseContactCreate.data;
          $scope.currentCountryId = responseContactCreate.data.CountryId || 0;
          $scope.currentUserName = responseContactCreate.data.FullName;
          if (parseInt($scope.accessType, 10) !== 1 && parseInt($scope.accessType, 10) !== 2) {
            $scope.getAdvisersDropdown();
          }
        });
      },
      getAdvisersDropdown() {
        corporateService.getAdvisersDropdown($scope.userInfo.FamilyId, 0, 0).then((response) => {
          $scope.assistantAdvisersList = _.filter(response.data, (data) => { return data.FullName !== ''; });
          $scope.AssignedAdviserId = $scope.assistantAdvisersList[0].FamilyId;
          $scope.sampleChange($scope.AssignedAdviserId);

          return $scope.assistantAdvisersList;
        });
      },
    });

    $scope.isFromPipeline = function () {
      $scope.isPipeline = $stateParams.fromTool;
      if ($scope.isPipeline === 'pipeline') {
        $scope.pipeLineParam = true;
        $scope.headerForPipeline = 'Add New Contact to Card';
        $scope.accountType = 'Client';
      }
    };
    $scope.isFromPipeline();
    $scope.getContactType = function () {
      contactService.getContactType().then((response) => {
        if (!response.data && !response.data.length) return;
        $scope.accountTypeList = response.data.filter(x => parseInt(x.ContactTypeId, 10) === CONTACT_TYPE.CLIENT);
        $scope.accountTypeList.push({ ContactTypeId: '1', ContactTypeDesc: 'Business' });

        if ($localStorage.loanApplicationCreateNewContact || $localStorage.loanProfilerCreateNewContact || $localStorage.fundingCalculatorCreateNewContact) {
          $scope.accountType = 'Client';
        }
      });
    };

    $scope.getContactType();

    $scope.AddPerson = function (role) {
      if (role === 'Adult') {
        if ($scope.personDetails.length < 2)
          $scope.personDetails.push($scope.getNewContactModel(role));
        else
          toaster.pop('error', 'Maximum Allowed Adults', 'Only 2 Adults are allowed');
      } else if (role === 'Child') {
        $scope.childDetails.push($scope.getNewContactModel(role));
      }
    };

    $scope.removePersonEntry = function (index) {
      if ($scope.personDetails.length > 1) {
        if (typeof index !== 'undefined') {
          $scope.personDetails.splice(index, 1);
        }
      } else
        toaster.pop('error', 'Can\'t Delete Adult ', 'You need at least 1 adult to create a new contact');
    };

    $scope.removeChildEntry = function (index) {
      if (typeof index !== 'undefined') {
        $scope.childDetails.splice(index, 1);
      }
    };

    $scope.indexTitle = function (role, index) {
      let foundRole = 0;
      for (let i = 0; i <= index; i++) {
        if ($scope.personDetails[index].Role === role)
          foundRole++;
      }

      if (role === 'Adult')
        return `Person ${foundRole}`;
      else if (role === 'Child')
        return `Child ${foundRole}`;
    };

    $scope.successStopFun = () => {
      const successStop = $interval(() => {
        if ($scope.maxSeconds > 0) {
          $timeout(() => {
            const el = $window.document.querySelectorAll('.sweet-alert button.confirm');
            angular.element(el).html(`Redirecting in ${$scope.maxSeconds}s...`);
          }, 0);

          $scope.maxSeconds--;
        } else {
          $interval.cancel(successStop);
          $timeout(() => {
            const el = $window.document.querySelectorAll('.sweet-alert button.confirm');
            angular.element(el).trigger('click');
          }, 0);
        }
      }, 1000, 0);
    };

    const redirectToContactPage = () => {
      if ($scope.FamilyId) {
        $state.go('app.contactsSingle', {
          familyId: $scope.FamilyId,
        });
      }
    };

    $scope.showFactFindTick = () => {
      if (!currentUserService) return false;
      const { countryId, brandingCategoryID } = currentUserService;
      const showFactFindTick = countryId === COUNTRY_TYPE.AUSTRALIA || (countryId === COUNTRY_TYPE.NEW_ZEALAND && brandingCategoryID === BRANDING_CATEGORY_TYPE.LOAN_MARKET);
      return showFactFindTick;
    };

    const openFactFindModal = (loanAppId) => {
      if (!loanAppId) return;
      const modalInstance = $uibModal.open({
        templateUrl: 'assets/views/profileManagement/Users/openOnlineFactFindForm.html',
        controller: OpenOnlineFactFindForm,
        controllerAs: 'vm',
        resolve: {
          loanId: () => loanAppId,
        },
        size: 'lg',
        windowClass: 'online-fact-find-form',
      });
      modalInstance.result.then(() => {
        redirectToContactPage();
      });
    };

    const openSubscriptionModal = (loanAppId, allocatedAdviserId) => {
      if (!loanAppId && !allocatedAdviserId) return;
      const { FamilyId } = $scope.loggedInBrokerInfo;
      const props = {
        familyId: allocatedAdviserId,
        subscriptionData: $scope.factFindData,
        orderedById: FamilyId,
      };
      usersSubscriptionService.subscribeToService(props)
        .then((response) => {
          if (response) {
            $scope.getSubscription(loanAppId, allocatedAdviserId);
          } else {
            redirectToContactPage();
          }
        });
    };

    const checkSubscriptionAccess = (loanAppId, allocatedAdviserId) => {
      if (!loanAppId && $scope.loggedInBrokerInfo) return;
      const showUserSubscription = (configService.feature && configService.feature.userSubscription && configService.feature.onlineFactFind) || 0;
      const checkValid = showUserSubscription && ($scope.factFindData && !$scope.factFindData.isActive);
      if (checkValid && allocatedAdviserId) {
        openSubscriptionModal(loanAppId, allocatedAdviserId);
      } else {
        openFactFindModal(loanAppId);
      }
    };

    $scope.getSubscription = (loanAppId, allocatedAdviserId) => {
      if (allocatedAdviserId && loanAppId) {
        contactService.subscriptionInfo(allocatedAdviserId).then((response) => {
          if (response && response.length) {
            $scope.factFindData = response && response.find(obj => obj.subscriptionProductId
              === SUBSCRIPTIONTYPE.ONLINE_FACT_FIND);
            checkSubscriptionAccess(loanAppId, allocatedAdviserId);
          }
        });
      }
    };

    const loanScenarioSet = (setAdviser) => {
      if (!$scope.FamilyId && !setAdviser) return;
      const familyId = $scope.FamilyId.toString();
      const { allocatedAdviserId } = setAdviser;
      const adviserId = parseInt(allocatedAdviserId, 10);
      const dummyscenario = {
        LoanScenarioId: '0',
        AdviserId: adviserId,
        Title: `Loan Application ${Math.random().toString(36).substring(20)}`,
        StatusName: 'ongoing',
        FamilyId: familyId,
        AssignedLenderId: '0',
      };
      loanScenarioService.scenarioSet(dummyscenario).then((scenarioSetResponse) => {
        if (scenarioSetResponse && scenarioSetResponse.data) {
          const { data: loanAppId } = scenarioSetResponse;
          const { CountryId } = $scope.loggedInBrokerInfo;
          if (CountryId === COUNTRY_TYPE.AUSTRALIA) {
            $scope.getSubscription(loanAppId, allocatedAdviserId);
          } else {
            openFactFindModal(loanAppId);
          }
        }
      });
    };

    $scope.saveContactAddress = function (setAdviser) {
      $scope.familyDetails.Addresses = [];

      $scope.familyDetails.ReferralCategoryId = $scope.familyDetails.ReferralCategoryId || 0;
      $scope.familyDetails.ReferralItemId = $scope.familyDetails.ReferralItemId || 0;

      if ($scope.familyDetails.ReferralCategoryId !== REFERRAL_ITEM.REFERRAL && $scope.familyDetails.ReferralItemId !== REFERRAL_ITEM.REFERRAL) {
        $scope.familyDetails.ReferrerAgreementTypeId = 0;
        $scope.familyDetails.ReferrerID = 0;
        $scope.familyDetails.ReferrerName = '';
        $scope.familyDetails.ReferrerNameLabel = '';
        $scope.familyDetails.ReferrerOrgID = 0;
        $scope.familyDetails.ReferrerOrgName = '';
      }

      if ($scope.contactAddress.HomeAddress) {
        $scope.familyDetails.Addresses.push({
          Type: 'Home',
          geoCoded: true,
          formatted_address: $scope.contactAddress.HomeAddress,
          IsMailing: false,
        });
        if ((!$scope.contactAddress.isMailingAddress && $scope.contactAddress.MailingAddress) || $scope.contactAddress.isMailingAddress) {
          $scope.familyDetails.Addresses.push({
            Type: 'Postal',
            TypeId: ADDRESS_TYPE.POSTAL,
            geoCoded: true,
            formatted_address: $scope.contactAddress.isMailingAddress ? $scope.contactAddress.HomeAddress : $scope.contactAddress.MailingAddress,
            IsMailing: true,
          });
        }
      }

      contactService.contactFamilyInfoSet($scope.familyDetails).then((response) => {
        if (!_.isUndefined(setAdviser.FamilyId) && !_.isUndefined(setAdviser.allocatedAdviserId)) {
          // Update Allocation API
          if ($rootScope.isCorporateUser) {
            corporateService.assignClientToAdviserSet(setAdviser.allocatedAdviserId, setAdviser.FamilyId, 0).then((setResponse) => {
              if (setResponse.status === 200) {
                toaster.pop('success', 'Successfully', 'Assigned Adviser to Client');
              }
            });
          } else {
            contactService.putAssignAdviser(setAdviser.FamilyId, setAdviser.allocatedAdviserId).then(() => {
              toaster.pop('success', 'Successfully', 'Assigned Adviser to Client');
            });
          }
        }

        if ($scope.pipeLineParam === true) {
          let familyName = '';
          if ($scope.personDetails.length > 0) {
            familyName = `${$scope.personDetails[0].FirstName} ${$scope.personDetails[0].LastName}`;
          }

          const statusName = $localStorage.pipelineStatus;
          $localStorage.pipelineCardSatusName = $scope.ContactStatus.Name;
          let AdviserName = $scope.familyDetails.AdviserFullName;
          if (AdviserName.slice(-1) === 's')
            AdviserName += "'";
          else
            AdviserName += "'s";
          SweetAlert.swal({
            title: 'Saved to New Card!',
            text: `Successfully added ${familyName} as a ${statusName}`,
            type: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: '#30C1C4',
            confirmButtonText: 'Redirecting in s...',
            html: true,
          }, () => {
            $state.go($localStorage.previousState, { createdContact: response.data });
          });
          $scope.maxSeconds = 3;
          $scope.successStopFun();
        } else if ($localStorage.loanApplicationCreateNewContact || $localStorage.loanProfilerCreateNewContact || $localStorage.fundingCalculatorCreateNewContact) {
          let AdviserName = $scope.familyDetails.AdviserFullName;
          if (AdviserName.slice(-1) === 's')
            AdviserName += "'";
          else
            AdviserName += "'s";

          if ($localStorage.loanApplicationCreateNewContact) {
            // Set familyid for local storage
            $localStorage.loanApplicationSelectedFamilyId = $scope.familyDetails.FamilyId;
            SweetAlert.swal({
              title: 'Saved to Related Parties!',
              text: `Successfully added <b>${$localStorage.loanApplicationSelectedClientName}</b> to ${AdviserName} Loan Application`,
              type: 'success',
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#30C1C4',
              confirmButtonText: 'Redirecting in s...',
              html: true,
            }, () => {
              $state.go($localStorage.previousState, $localStorage.previousStateParams);
            });
          }
          if ($localStorage.loanProfilerCreateNewContact) {
            $localStorage.loanProfilerFamilyId = response.data;
            $localStorage.loanProfilerContactFirstName = $scope.personDetails[0].FirstName;
            $localStorage.loanProfilerContactLastName = $scope.personDetails[0].LastName;
            SweetAlert.swal({
              title: 'Saved to Related Parties!',
              text: `Successfully added to ${AdviserName} Loan Calculator`,
              type: 'success',
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#30C1C4',
              confirmButtonText: 'Redirecting in s...',
              html: true,
            }, () => {
              $state.go($localStorage.previousState, $localStorage.previousStateParams);
            });
          }
          if ($localStorage.fundingCalculatorCreateNewContact) {
            $localStorage.fundingCalculatorFamilyId = response.data;
            $localStorage.fundingCalculatorContactFirstName = $scope.personDetails[0].FirstName;
            $localStorage.fundingCalculatorContactLastName = $scope.personDetails[0].LastName;
            SweetAlert.swal({
              title: 'Saved to Related Parties!',
              text: `Successfully added to ${AdviserName} Funding Calculator`,
              type: 'success',
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonColor: '#30C1C4',
              confirmButtonText: 'Redirecting in s...',
              html: true,
            }, () => {
              $state.go($localStorage.previousState, $localStorage.previousStateParams);
            });
          }

          $scope.maxSeconds = 3;
          $scope.successStopFun();
        } else if ($scope.sendFactFind) {
          loanScenarioSet(setAdviser);
        } else {
          redirectToContactPage();
        }
      });
    };

    $scope.contactFamilyInfoGet = function (setAdviser) {
      contactService.contactFamilyInfoGet($scope.FamilyId).then((response) => {
        $scope.familyDetails = response.data;
        $scope.familyDetails.FamilyId = $scope.FamilyId;
        const { ReferralItemId } = $scope.contactInfoSetValue || $scope.contactInfoSetValue.EnquirySource;
        const isSaveClient = ReferralItemId ? $scope.displayField(ReferralItemId, $scope.clientEnquiryList) : false;
        const isSaveDetails = ReferralItemId ? $scope.displayField(ReferralItemId, $scope.additionalEnquiryList) : false;
        if (isSaveClient && $scope.client.selected && $scope.client.selected.originalObject) {
          $scope.familyDetails.ClientReferrerFamilyId = $scope.client.selected.originalObject.FamilyID || 0;
        }
        if (isSaveDetails) {
          $scope.familyDetails.OtherContactReferrerName = $scope.client.additionalDetails;
        }
        $scope.familyDetails.isMailingAddress = $scope.contactAddress.isMailingAddress;
        $scope.familyDetails.MainEmail = $scope.contactAddress.MainEmail;
        $scope.familyDetails.ContactStatusId = angular.copy($scope.ContactStatus.ContactStatusId);
        $scope.familyDetails = accountService.copyReferral($scope.familyDetails, $scope.contactInfoSetValue);
        $scope.saveContactAddress(setAdviser);
      });
    };

    $scope.ContactStatus = {};
    $scope.updateContactStatus = (status) => {
      clientInfoService.updateContactStatus($scope.ContactStatus, status);
    };

    const checkReferralRequirements = () => {
      if (!$scope.contactInfoSetValue || !$scope.contactInfoSetValue.EnquirySource || !$scope.contactInfoSetValue.EnquirySource.ReferralCategoryId) return true;
      const selectedEnquiry = $scope.contactInfoSetValue.EnquirySource.ReferralCategoryId;
      const isValid = !!$scope.contactInfoSetValue.ReferrerNameLabel && !!$scope.contactInfoSetValue.ReferrerID;
      if (parseInt(selectedEnquiry, 10) !== parseInt(REFERRAL_ITEM.REFERRAL, 10)) return true;
      $scope.showReferralAlert = !isValid;
      $scope.canSave = !isValid;
      return isValid;
    };

    $scope.canSave = true;
    $scope.sendFactFind = false;
    $scope.sendFactFindCheck = function (value) {
      $scope.sendFactFind = value;
    };
    $scope.isInvalidBusinessName = false;
    $scope.saveAccount = function (form) {
      $scope.canSave = false;
      if (!checkReferralRequirements()) return;
      $scope.isInvalidForm = true;
      angular.forEach($scope.personDetails, (data) => {
        const DOB = moment(data.DOB, ['DD-MM-YYYY']);
        if (data.DOB !== '' && !DOB.isValid()) {
          toaster.pop('warning', 'Invalid Date of Birth', 'Please fill in a valid date');
          $scope.isInvalidForm = false;
          $scope.canSave = true;
          return;
        }

        const dobSplit = data.DOB.split('/');
        if (dobSplit[2] < 1700) {
          toaster.pop('warning', 'Invalid Date of Birth', 'Year must not be below 1700');
          $scope.isInvalidForm = false;
          $scope.canSave = true;
        }
      });

      if ($scope.isInvalidForm) {
        $scope.saveAccountForPipeline(form);
      }
    };
    $scope.saveAccountForPipeline = (form) => {
      if (!checkReferralRequirements()) return;
      if ($scope.accountType === 'Client') $scope.saveClientAccount(form);
      if ($scope.accountType === 'Business') $scope.saveBusinessAccount(form);
    };
    $scope.cancel = function () {
      if ($localStorage.loanApplicationCreateNewContact || $localStorage.loanProfilerCreateNewContact) {
        $state.go($localStorage.previousState, $localStorage.previousStateParams);
      } else
        $state.go('app.contacts');
    };

    $scope.saveClientAccount = function (form) {
      let firstError = null;
      if (form.$invalid) {
        $scope.prevStep();

        firstError = null;
        form && Object.keys(form).forEach((field) => {
          if (field[0] !== '$') {
            if (firstError === null && !form[field].$valid) {
              firstError = form[field].$name;
            }

            if (form[field].$pristine) {
              form[field].$dirty = true;
            }
          }
        });

        angular.element(`.ng-invalid[name=${firstError}]`).focus();
        toaster.pop('error', 'Invalid Form', 'Please fill up the form correctly');
        $scope.canSave = true;
        return;
      }
      const checkMobileNumber = $scope.personDetails && $scope.personDetails.length && $scope.sendFactFind;
      if (checkMobileNumber) {
        const { MobileNumber } = $scope.personDetails[0];
        const isValidMobile = isMobileValid(MobileNumber);
        if (!isValidMobile) {
          toaster.pop('error', 'Invalid Mobile Number', 'Please fill up the mobile number correctly');
          return;
        }
      }

      const allContacts = [];
      /* assigning adviserid to the contact */
      $scope.allocatedAdviser.adviserSelected.description.FamilyId = $scope.AssignedAdviserId;
      $scope.personDetails.forEach((obj) => {
        obj.AllocatedAdviserID = $scope.AssignedAdviserId || 0;
        obj.Phone = [{ Type: 'Mobile', Number: obj.MobileNumber }];
        obj.Email = [{ Type: 'Email', EmailAddress: obj.MainEmail }];
        allContacts.push(obj);
      });

      $scope.childDetails.forEach((obj) => {
        allContacts.push(obj);
      });

      if (allContacts.length > 0) {
        clientInfoService.dateFormattingList(angular.copy(allContacts)).then((res) => {
          const tempAllContacts = res.data;
          contactService.addContact(tempAllContacts)
            .then((response) => {
              // @TODO : Get the newly created family ID
              // Use /contacts/ContactFamilyInfoGet one we will have the family ID

              $scope.FamilyId = response.data;
              $scope.contactFamilyInfoGet({
                FamilyId: $scope.FamilyId,
                allocatedAdviserId: $scope.allocatedAdviser.adviserSelected.description.FamilyId,
              });

              if (!$localStorage.loanApplicationCreateNewContact)
                toaster.pop('success', 'Added!', 'Contact has been Added Successfully.');
            }).catch(() => {
              $scope.canSave = true;
            });
        });
      }
    };

    $scope.saveBusinessAccount = function (form) {
      $scope.isInvalidBusinessName = form.businessName && form.businessName.$invalid;
      if (form.$invalid) {
        $scope.prevStep();
        toaster.pop('error', 'Invalid Form', 'Please fill up the form correctly');
        $scope.canSave = true;
        return false;
      }

      const org = {
        OrganisationId: '0',
        Name: $scope.business.Name,
        Description: $scope.business.Description,
        OrganisationTaxNumber: $scope.business.OrganisationTaxNumber,
        OrganisationCompanyNumber: $scope.business.OrganisationCompanyNumber,
        FamilyId: '0',
        Phone: [
          {
            Type: 'Work',
            Number: $scope.business.Phone,
          },
        ],
        Address: [
          {
            formatted_address: $scope.business.Address,
            geoCoded: $scope.geoCodedAddress,
          },
        ],
        Email: [
          {
            Type: 'Email',
            EmailAddress: $scope.business.OfficeEmail,
          },
        ],
        Notes: $scope.business.Notes,
        PersonId: '0',
      };


      contactService.organisationInfoSet(org)
        .then(
          (response) => {
            if (response.data.Message !== undefined) {
              toaster.pop('info', 'Information', response.data.Message);
            } else {
              toaster.pop('success', 'Added!', 'Business has been Added Successfully.');
              $state.go('app.BusinessAccount', { familyId: response.data });
            }
          }
        );
    };

    $scope.homeAutoAddresss = function () {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService.placeSearch($scope.contactAddress.HomeAddress).then((respond) => {
          $scope.homeAutoAddressDetails = respond.data;
        });
      }, 500);
    };
    $scope.isGeocodeHome = false;
    $scope.isGeocodeMail = false;
    $scope.isClickedHome = false;
    $scope.isClickedMail = false;
    $scope.selectHomeAddress = function (homeAddress) {
      $scope.contactAddress.HomeAddress = homeAddress;
      $scope.isGeocodeHome = true;
      $scope.closeAutoHomeAddress();
    };
    $scope.$watch('contactAddress.HomeAddress', () => {
      if ($scope.isClickedHome) {
        $scope.isClickedHome = false;
      } else if ($scope.isGeocodeHome) {
        $scope.isGeocodeHome = false;
      }
    });

    $scope.geoCodedAddress = false;
    $scope.selectBusinesAddress = function (homeAddress) {
      $scope.business.Address = homeAddress;
      $scope.geoCodedAddress = true;
      $scope.closeOfficeAddress();
    };

    $scope.businessAutoAddresss = function () {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService.placeSearch($scope.business.Address).then((respond) => {
          $scope.officeAutoAddressDetails = respond.data;
        });
      }, 500);
    };

    $scope.closeAutoHomeAddress = function () {
      $scope.homeAutoAddressDetails = [];
    };
    $scope.closeOfficeAddress = function () {
      $scope.officeAutoAddressDetails = [];
    };

    $scope.mailAutoAddresss = function () {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService.placeSearch($scope.contactAddress.MailingAddress).then((respond) => {
          $scope.mailAutoAddressDetails = respond.data;
        });
      }, 500);
    };


    $scope.selectMailAddress = function (mailAddress) {
      $scope.contactAddress.MailingAddress = mailAddress;
      $scope.isGeocodeMail = true;
      $scope.closeAutoMailAddress();
    };
    $scope.$watch('contactAddress.MailingAddress', () => {
      if ($scope.isClickedMail) {
        $scope.isClickedMail = false;
      } else if ($scope.isGeocodeMail) {
        $scope.isGeocodeMail = false;
      }
    });

    $scope.closeAutoMailAddress = function () {
      $scope.mailAutoAddressDetails = [];
    };

    // app behavior
    $scope.getNumberArray = function (num) {
      return new Array(num);
    };
    $scope.nextStep = function (form) {
      if ($scope.currentMobileTab !== $scope.finalMobileTab) {
        $scope.currentMobileTab++;
      } else {
        $scope.saveAccount(form);
      }
    };
    $scope.prevStep = function () {
      if ($scope.currentMobileTab > 1) {
        $scope.currentMobileTab--;
      }
    };

    $scope.contactInfoSetValue = {};
    enquirySourceService.getEnquirySourceList($scope);

    // Enquiry is changed
    $scope.enquiryChanged = () => {
      clientInfoService.enquiryChanged($scope.contactInfoSetValue);
    };

    $scope.referredByHelper = {
      showClientList: false,
      loopCount: 0,
      getReferralOrgList() {
        if ($scope.referredByHelper && $scope.referredByHelper.loopCount < 1 && $scope.AssignedAdviserId > 0) {
          referencedReferralService.getReferencedReferralList($scope.AssignedAdviserId, $scope);
        }
      },
      searchClient() {
        $scope.clientNotFound = false;
        $scope.referredByHelper.showClientList = true;
        $scope.referredByHelper.getReferralOrgList();
      },
    };
    $scope.referredByHelper.selectClient = (client) => {
      clientInfoService.selectClient($scope.contactInfoSetValue, $scope.referredByHelper, client);
    };
    $scope.toggleChildrenDiv = function (referralCategory) {
      const { referralCategoryList } = $scope;
      contactSharedData.toggleChildrenDiv(referralCategory, referralCategoryList)
        .then((response) => {
          $scope.contactInfoSetValue = response.contactInfoSetValue;
          $scope.selectedReferralCategory = response.selectedReferralCategory;
        });
    };

    $scope.openReferralModal = function (size) {
      const { getReferralCategoryList: getReferralCategory, contactFamilyInfoGet, contactInfoSetValue, selectedReferralCategory, referralCategoryList } = $scope;
      const modalInstance = contactSharedData.openReferralModal(size, getReferralCategory, contactFamilyInfoGet, contactInfoSetValue, selectedReferralCategory, referralCategoryList);
      modalInstance.result.then(() => {
        enquirySourceService.getEnquirySourceList($scope);
      }, () => {
        enquirySourceService.getEnquirySourceList($scope);
      });
    };

    optionsService.ContactStatusGet().then((response) => {
      $scope.contactStatusList = contactStatusService.populateStatusWithColor(response.data);
    });

    $scope.clientEnquiryList = CLIENT_REFERRAL;
    $scope.additionalEnquiryList = ADDITIONAL_REFERRAL;
    $scope.displayField = displayField;
    $scope.clientSearchApi = (userInputString) => {
      const defer = $q.defer();
      defer.resolve([]);
      if (!userInputString || !$scope.AssignedAdviserId) return defer.promise;
      return contactService.searchFamilyContactType(userInputString, 'Client', $scope.AssignedAdviserId);
    };
  });
