import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { APPROVAL_EXPIRIES_ID } from 'Common/constants/customerCareModules';

angular.module('app').controller('PreApprovalCtrl', function PreApprovalCtrl($scope,
  $uibModal,
  SweetAlert,
  NgTableParams,
  $window,
  $location,
  $state,
  $stateParams,
  toaster,
  cfpLoadingBar,
  $timeout,
  $rootScope,
  $filter,
  $localStorage,
  customerCareService) {
  // Pre Approval Expires
  //--------------------------------------------------------------------------
  $scope.mainObj = {};
  $scope.mainObj.adviserId = 0;
  $scope.mainObj.dateRange = 60;
  $scope.mainObj.ShowCompleted = false;

  // Table
  $scope.loadPreApprovalTable = function () {
    $scope.preApprovalTableParams = new NgTableParams({
      page: 1,
      count: 10,
      sorting: {
        ExpiryDate: 'asc',
      },
    },
    {
      counts: [],
      total: 0,
      getData($defer, params) {
        const sortColumn = params.orderBy()[0].substring(1);
        const sortType = _.values(params.sorting())[0].toUpperCase();
        customerCareService.PreApprovalExpiresListGet($scope.mainObj.adviserId, $scope.mainObj.formDate, $scope.mainObj.toDate, params.page(),
          params.count(), sortColumn, sortType, $scope.mainObj.ShowCompleted)
          .then((response) => {
            const hasPreAppovalList = response.data && response.data.PreApprovalExpireList;
            if (hasPreAppovalList) {
              $scope.preApprovalListTable = response.data.PreApprovalExpireList;
              _.map($scope.preApprovalListTable, (item) => {
                const today = moment().format('D MMMM YYYY');
                const exDate = moment(item.ExpiryDate).format('D MMMM YYYY');

                if (today === exDate)
                  item.IsDueToday = true;
                else
                  item.IsDueToday = false;
              });
              if (typeof response.data.TotalCount !== 'undefined')
                params.total(response.data.TotalCount);
              else
                params.total(0);
              $defer.resolve($scope.preApprovalListTable);
            }
          }, () => {
          });
      },
    });
  };

  // Date Range Filter
  $scope.dateRangeFilter = function () {
    $scope.mainObj.formDate = moment().format('D MMMM YYYY');
    $scope.mainObj.toDate = moment().add(Number($scope.mainObj.dateRange), 'days').format('D MMMM YYYY');
    $scope.loadPreApprovalTable();
  };
  $scope.dateRangeFilter();

  // Set Pre Approval as Complete
  $scope.setPreApprovalCompilation = function (preApproval) {
    const expDate = moment(preApproval.ExpiryDate).format('D MMM');
    const notiHeader = `Approval Expires (${expDate}) Completed`;
    const $postData = [{
      CustomerCareType: 'PRE_APPROVAL_EXPIRY',
      FamilyID: 0,
      LoanID: preApproval.LoanID,
      IsComplete: preApproval.isComplete,
      NotificationHeader: notiHeader,
    }];
    customerCareService.setCompeletion($postData)
      .then(() => {
        $scope.preApprovalTableParams.reload();
      }, () => {
      });
  };

  // Open modal For Get Pre Approval Detail in Mobile
  $scope.openModalForGetPreApprovalDetail = function (preApproval, size) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/pre_approval_filter_modal.html',
      controller: 'PreApprovalDetailsModalCtrl',
      size,
      resolve: {},
    });
  };

  $scope.openSendEmailModal = function (size, preApprovalObj, source) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/send_email_modal.html',
      resolve: {
        preApprovalListTable() {
          return $scope.preApprovalListTable;
        },
        preApprovalObj() {
          return preApprovalObj;
        },
        source() {
          return source;
        },
        loadPreApprovalTable() {
          return $scope.loadPreApprovalTable;
        },
      },
      controller: 'OpenPreApprovalSendEmailModalCtrl',
      size,
    });
  };
  $scope.openSendMailModal = function (size, preApprovalObj, source) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/send_mail_modal.html',
      resolve: {
        listTableData() {
          return $scope.preApprovalListTable;
        },
        sendMailObj() {
          return preApprovalObj;
        },
        source() {
          return source;
        },
        currentModuleId() {
          return APPROVAL_EXPIRIES_ID;
        },
      },
      controller: 'OpenSendMailModalCtrl',
      size,
    });
  };
  $scope.openSMSModal = function (size, preApprovalObj, source) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/send_sms_modal.html',
      resolve: {
        preApprovalListTable() {
          return $scope.preApprovalListTable;
        },
        preApprovalObj() {
          return preApprovalObj;
        },
        source() {
          return source;
        },
        loadPreApprovalTable() {
          return $scope.loadPreApprovalTable;
        },
      },
      controller: 'OpenPreApprovalSMSModalCtrl',
      size,
    });
  };
});
