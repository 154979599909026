import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { MAIN_STATUS_LENGTH } from 'Common/constants/mainLoanStatusLength';
import { EXTRA_STATUS } from 'Common/constants/extraLoanStatus';
import { splitByCapitalLetters, camelize } from 'Common/utilities/string';

angular.module('app').controller('LendingDetailCtrl',
  function LendingDetailCtrl($scope,
    contactService,
    $q,
    $uibModal,
    imageDataURI,
    dashboardService,
    $state,
    $stateParams,
    $filter,
    toaster,
    $timeout,
    loanScenarioService,
    $rootScope,
    lendingScenarioService,
    utilitiesService,
    loanProfilerService,
    fundingCalculatorService,
    nextGenLoanAppService,
    $window,
    pipelineService,
    contactSharedData,
    broadcastDataService,
    corporateService,
    $location,
    $sce,
    optionsService,
    $localStorage,
    configService,
    loanCalculatorLinking,
    loanAppDetailsService,
    uiService,
    loanAppSharedData) {
    $scope.uiService = uiService;
    $scope.lendingScenarioId = $stateParams.lendingScenarioId;
    $scope.savedCalculationsList = [];
    $scope.appsStatusList = [];
    $scope.isAdviserAccess = false;
    $scope.isLockLoanappFeatureEnabled = (configService.feature && configService.feature.lockLoanapp) || 0;

    const CATEGORY = {
      EXISTING: 'Existing',
      INPROGRESS: 'InProgress',
      OPPORTUNITY: 'Opportunity',
      PREVIOUS: 'Previous',
    };

    const getUserInfo = () => {
      contactService.getUserInfo().then((response) => {
        const data = response.data;
        if (data) {
          $scope.userInfo = data;
          $scope.isCorporateUser = parseInt(data.AccessType, 10) === ACCESS_TYPE.CORPORATE;
          const adviserAccessList = [ACCESS_TYPE.ADVISER, ACCESS_TYPE.PRINCIPAL_ADVISER];
          $scope.isAdviserAccess = adviserAccessList.indexOf(data.AccessType) !== -1;
          $scope.userFamilyId = data.FamilyId;
        }
      });
    };

    getUserInfo();

    $scope.goToLendingScenario = (id) => {
      if ($scope.crmCountry === 'New Zealand') {
        $state.go('app.LoanProfilerSingle',
          {
            familyId: $stateParams.familyId,
            lendingScenarioId: id,
          }
        );
      } else {
        $state.go('app.LendingScenarioSingle',
          {
            familyId: $stateParams.familyId,
            lendingScenarioId: id,
          }
        );
      }
    };

    $scope.$watch('selectedContacts', (newValue) => {
      if (newValue === 'lending') {
        $scope.getLoanListGet();
      }
    });

    /**
     * Open initial tab
     * */
    switch ($stateParams.lendingTab) {
    case 'inprogress':
      $scope.lendingTab = 'inprogressLoans';
      break;
    case 'previous':
      $scope.lendingTab = 'previousLoans';
      break;
    default:
      $scope.lendingTab = 'loans';
      break;
    }

    $scope.$watch('lendingTab', (newVal) => {
      switch (newVal) {
      case 'loans':
        $scope.lendingTemplate = '/assets/views/contacts/client/lending/loans.html';
        if (contactSharedData.summaryData.destinationTabToLending)
          contactSharedData.summaryData.destinationTabToLending = '';
        break;

      case 'inprogressLoans':
        $scope.lendingTemplate = '/assets/views/contacts/client/lending/inProgress.html';
        if (contactSharedData.summaryData.destinationTabToLending)
          contactSharedData.summaryData.destinationTabToLending = '';
        break;

      case 'previousLoans':
        $scope.lendingTemplate = '/assets/views/contacts/client/lending/previousLoans.html';
        break;
      default:
        break;
      }
    });

    $scope.existingLoans = [];
    $scope.previousLoans = [];
    $scope.inprogressLoans = [];

    /**
     * Description: Loan status list
     * */
    $scope.getLoanStatusList = () => {
      $scope.SettlementDateType = [
        {
          Type: 'Estimated Date',
          Value: true,
        },
        {
          Type: 'Actual Date',
          Value: false,
        },
      ];
      optionsService.getLoanStatus(`${EXTRA_STATUS.CANCELLED},${EXTRA_STATUS.LOAN_REPAID}`).then((response) => {
        $scope.LoanStatusList = [];
        $scope.LoanStatusMainGroup = [];
        $scope.LoanStatusMiscGroup = [];
        _.forEach(response.data, (status, index) => {
          const statusValue = {
            LoanStatusGroupName: status.GroupName,
            LoanStatusName: status.Name,
            LoanStatusID: status.Value,
          };

          if (index < MAIN_STATUS_LENGTH.STATUS_INDEX) {
            $scope.LoanStatusList.push(statusValue);
          }
          if (index < MAIN_STATUS_LENGTH.STATUS_DIVIDER) {
            $scope.LoanStatusMainGroup.push(statusValue);
          } else {
            $scope.LoanStatusMiscGroup.push(statusValue);
          }
        });
      });
    };

    $scope.deleteImportantDate = (loan, type) => {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to remove this date?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes!',
        closeOnConfirm: false,
      }, (willDelete) => {
        if (willDelete) {
          contactService.loanImportantDatesDelete(loan.LoanID, type, false)
            .then(() => {
              switch (type) {
              case IMPORTANT_DATE_TYPES.DELETE.APPROVALEXPIRY:
                loan.PreApprovalExpiry = null;
                break;
              case IMPORTANT_DATE_TYPES.DELETE.FINANCE:
                loan.FinanceDate = null;
                break;
              case IMPORTANT_DATE_TYPES.DELETE.SUBMITTED:
                loan.SubmittedDate = null;
                break;
              case IMPORTANT_DATE_TYPES.DELETE.APPROVAL:
                loan.ApprovalDate = null;
                break;
              case IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT:
                loan.SettlementDate = null;
                break;
              case IMPORTANT_DATE_TYPES.DELETE.ACTUAL_SETTLEMENT:
                loan.SettlementDate = null;
                break;
              default:
                break;
              }
              swal({
                title: 'Success',
                text: 'Date has been successfully deleted.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            });
        }
      });
    };

    $scope.getLoanStatusList();
    function loanListGet() {
      $scope.showExistingLoansLoading = true;
      $scope.showInProgressLoansLoading = true;
      if (parseInt($state.params.inprogress, 0) === 1) {
        contactSharedData.summaryData.destinationTabToLending = 'inprogressLoans';
      }
      if (contactSharedData.summaryData.destinationTabToLending)
        $scope.lendingTab = contactSharedData.summaryData.destinationTabToLending;
      contactService.loanListGet($scope.familyId, true).then((response) => {
        const opportunityLoans = [];
        const existingLoans = [];
        const previousLoans = [];
        const inprogressLoans = [];
        const { data } = response;

        angular.forEach(data, (value) => {
          $scope.CountryCode = '';
          switch ($scope.crmCountry) {
          case 'Australia':
            $scope.CountryCode = 'AU';
            break;
          case 'New Zealand':
            $scope.CountryCode = 'NZ';
            break;
          case 'Indonesia':
            $scope.CountryCode = 'ID';
            break;
          default:
            $scope.CountryCode = '';
          }
          value.CountryCode = $scope.CountryCode;

          /**
           * Null and TRUE  = Estimated Date
           * FALSE = Actual Date
           * */
          if (_.isUndefined(value.IsEstimated) || value.IsEstimated) {
            value.IsEstimated = true;
          } else {
            value.IsEstimated = false;
          }

          if (value.SettlementDate) {
            const tdate = moment.parseZone(value.SettlementDate);
            value.SettlementDate = tdate._d;
          }
          if (value.ApprovalDate) {
            const tdate = moment.parseZone(value.ApprovalDate);
            value.ApprovalDate = tdate._d;
          }
          if (value.SubmittedDate) {
            const tdate = moment.parseZone(value.SubmittedDate);
            value.SubmittedDate = tdate._d;
          }
          if (value.FinanceDate) {
            const tdate = moment.parseZone(value.FinanceDate);
            value.FinanceDate = tdate._d;
          }
          if (value.PreApprovalExpiry) {
            const tdate = moment.parseZone(value.PreApprovalExpiry);
            value.PreApprovalExpiry = tdate._d;
          }

          if (!_.isUndefined(value.ExpiryDate)) {
            value.ExpiryHumanize = 'Next Fixed Expiry: ';
          } else if (!_.isUndefined(value.NextFixedExpiry)) {
            value.ExpiryHumanize = 'Next Fixed Expiry: ';
          } else {
            value.ExpiryHumanize = null;
          }

          if (value.LoanStatus.LoanStatusCategory === CATEGORY.EXISTING) {
            existingLoans.push(value);
          } else if (value.LoanStatus.LoanStatusCategory === CATEGORY.INPROGRESS) {
            inprogressLoans.push(value);
          } else if (value.LoanStatus.LoanStatusCategory === CATEGORY.OPPORTUNITY || value.IsOpportunity) {
            value.IsPrevious = (value.LoanStatus.LoanStatusCategory === CATEGORY.PREVIOUS);
            opportunityLoans.push(value);
          } else {
            previousLoans.push(value);
          }

          if (String(value.LoanID) === String($stateParams.loanId) && !$state.params.inprogress) {
            switch (value.LoanStatus.LoanStatusCategory) {
            case 'Existing':
              $scope.lendingTab = 'loans';
              break;
            case 'InProgress':
              $scope.lendingTab = 'inprogressLoans';
              break;
            default:
              $scope.lendingTab = 'previousLoans';
              break;
            }
          }

          if (!_.isUndefined(value.LoanPurpose)) {
            const tempText = value.LoanPurpose;
            value.LoanPurpose = [];
            value.LoanPurpose = tempText.split(',');
          }
          if (!_.isUndefined(value.FamilyList[0].AdviserFullName)) {
            value.FamilyList[0].Initials = utilitiesService.filterInitialOneString(value.FamilyList[0].AdviserFullName);
          }
        });
        $scope.opportunityLoans = loanAppSharedData.distinguishPartyForLendingTab(opportunityLoans);
        $scope.existingLoans = loanAppSharedData.distinguishPartyForLendingTab(existingLoans);
        $scope.previousLoans = loanAppSharedData.distinguishPartyForLendingTab(previousLoans);
        $scope.inprogressLoans = loanAppSharedData.distinguishPartyForLendingTab(inprogressLoans);
      }).finally(() => {
        $scope.showExistingLoansLoading = false;
        $scope.showInProgressLoansLoading = false;
      });
    }

    $scope.existingLoansSeeAll = false;
    $scope.inprogressLoansSeeAll = false;
    $scope.seeAllInprogress = () => {
      $scope.inprogressLoansSeeAll = true;
    };

    $scope.seeAllExisting = (bool = true) => {
      $scope.existingLoansSeeAll = bool;
      $scope.isShowInProgress = bool;
    };

    $scope.isShowPreviousLoan = () => {
      return $scope.isShowInProgress && !$scope.showPreviousLoansLoading && !$scope.showPreviousLoansLoading && $scope.previousLoans.length;
    };

    function selectStatus(statusObj, inprogressObj) {
      $scope.inprogressLoan = inprogressObj;
      $scope.statusName = statusObj.PipelineStatus;
      $scope.inprogressLoan.PipelineStatus = $scope.statusName;
      $scope.cardMove = {};
      $scope.cardMove.PipelineCardsID = $scope.inprogressLoan.PipelineCardsID;
      $scope.cardMove.ToStatusID = statusObj.PipelineStatusID;

      pipelineService.PipelineCardsMoveToNextStatusSet($scope.cardMove)
        .then(() => {
          contactSharedData.lendingSharedData.inProgressToPipeline = {
            statusObj,
            inprogressObj,
          };

          toaster.pop('success', 'Moved', 'Stage has been updated successfully.');
        }, () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        });
      if (statusObj.PipelineStatusID === 100 || statusObj.PipelineStatusID === 101) {
        loanListGet();
      }
    }
    function saveReferenceNo(inProgressObj) {
      contactService
        .loanImportantDatesSet(
          inProgressObj.LoanID,
          1,
          moment(inProgressObj.SettlementDate).format('YYYY-MM-DD'),
          inProgressObj.ReferenceNumber,
          inProgressObj.IsEstimated
        )
        .then((res) => {
          if (res.data === IMPORTANT_DATE_TYPES.API_STATUS.INVALID_DATE) {
            toaster.pop(
              'warning',
              'Reference number failed to update.'
            );
          } else {
            contactSharedData.lendingSharedData.referenceUpdate = {
              loanScenarioID: inProgressObj.LoanScenarioID,
              referenceNumber: inProgressObj.ReferenceNumber,
              isUpdating: false,
            };
            toaster.pop(
              'success',
              'Reference number updated successfully.'
            );
          }
        });
    }
    /**
     * auto-load-updated-sections
     * */
    angular.extend($scope, {
      pipelineStatusData: {},
      storedRefNo: '',
      /**
       * loan-status-update
       * */
      selectStatus(statusObj, inprogressObj) {
        selectStatus(statusObj, inprogressObj);
      },
      /**
       * refno-update
       * */
      updateRefNo(inProgressObj) {
        if ($scope.storedRefNo !== inProgressObj.ReferenceNumber) {
          saveReferenceNo(inProgressObj);
        } else {
          $timeout(() => {
            contactSharedData.lendingSharedData.referenceUpdate.isUpdating = false;
          }, 1000);
        }
      },
      storeRefno(inProgressObj) {
        contactSharedData.lendingSharedData.referenceUpdate = {
          loanScenarioID: inProgressObj.LoanScenarioID,
          referenceNumber: inProgressObj.ReferenceNumber,
          isUpdating: true,
        };
        $scope.storedRefNo = inProgressObj.ReferenceNumber;
      },
      checkIfHasSettlementDate: (loan) => {
        if (_.trim(loan.SettlementDate) === '' || _.isUndefined(loan.SettlementDate)) {
          return false;
        }
        return true;
      },
    });

    $scope.viewSubmission = (loan) => {
      nextGenLoanAppService.nextGenAOLView(loan).then(((response) => {
        $scope.nextGenObj = response.data;
        $scope.nextGenObj.https = $sce.trustAsResourceUrl($scope.nextGenObj.https);
        const guid = $scope.nextGenObj.GUID || '';
        const startPage = $scope.nextGenObj.StartPage || '';
        $scope.applicationViewURL = `${$scope.nextGenObj.https}?GUID=${guid}&StartPage=${startPage}`;
        $window.open($scope.applicationViewURL);
      }));
    };

    $scope.getLoanListGet = () => {
      loanListGet();
    };

    /**
     * watchers - contactSharedData
     * */
    $scope.$watch(() => {
      return contactSharedData.lendingSharedData.loanDetail;
    }, () => {
      const obj = contactSharedData.lendingSharedData.loanDetail;
      if (obj.processResult === 'close') {
        $scope.getLoanListGet();
      }
    });

    /**
     * detects changes for pipeline menu
     * */
    $scope.$watch(() => {
      return contactSharedData.pipelineStatusData.statusUpdate;
    }, () => {
      const obj = contactSharedData.pipelineStatusData.statusUpdate;
      if (obj.StatusID !== 100 && obj.StatusID !== 101) {
        if ($scope.inprogressLoans && $scope.inprogressLoans.length) {
          _.forEach($scope.inprogressLoans, (o) => {
            if (o.LoanScenarioID === obj.LoanScenarioID) {
              for (let x = 0; x < $scope.appsStatusList.length; x++) {
                if ($scope.appsStatusList[x]) {
                  if ($scope.appsStatusList[x].PipelineStatusID === obj.StatusID) {
                    o.PipelineStatus = $scope.appsStatusList[x].PipelineStatus;
                  }
                }
              }
            }
          });
        }
      } else {
        loanListGet();
      }
    });

    $scope.$watch(() => {
      return contactSharedData.pipelineStatusData.referenceUpdate;
    }, () => {
      $scope.pipelineStatusData.referenceUpdate = contactSharedData.pipelineStatusData.referenceUpdate;
      const obj = $scope.pipelineStatusData.referenceUpdate;

      if ($scope.inprogressLoans && $scope.inprogressLoans.length) {
        _.forEach($scope.inprogressLoans, (o) => {
          if (o.LoanScenarioID === obj.LoanScenarioID) {
            $scope.storedRefNo = obj.ReferenceNumber;
            o.ReferenceNumber = obj.ReferenceNumber;
          }
        });
      }
    });

    $scope.dateParser = (dateString) => {
      if (dateString) {
        let dateArray = dateString.split('-');
        dateArray = dateString.split(' ');
        const parsedDate = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`;
        return parsedDate;
      }
    };

    $scope.format = 'dd MMM yyyy';
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };

    $scope.importantDateSave = (obj, type, date, loanreferenceNumber) => {
      if (!date) return;
      let message;
      const dateFormat = moment(date).format('YYYY-MM-DD');

      switch (type) {
      case IMPORTANT_DATE_TYPES.IN_PROGRESS.SETTLEMENT:
        message = 'Settlement Date successfully updated!';
        break;
      case IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL:
        message = 'Approval Date successfully updated!';
        break;
      case IMPORTANT_DATE_TYPES.IN_PROGRESS.SUBMITTED:
        message = 'Submitted Date successfully updated!';
        break;
      case IMPORTANT_DATE_TYPES.IN_PROGRESS.FINANCE:
        message = 'Finance Date successfully updated!';
        break;
      case IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVALEXPIRY:
        message = 'Pre Approval Expiry Date successfully updated!';
        break;
      default:
        message = '';
      }
      contactService.loanImportantDatesSet(obj.LoanID, type, dateFormat, loanreferenceNumber, obj.IsEstimated).then((res) => {
        if (res.data === IMPORTANT_DATE_TYPES.API_STATUS.INVALID_DATE) {
          toaster.pop('warning', 'Date is invalid');
        } else {
          toaster.pop('success', message);
        }
      }, () => {
        toaster.pop('error', 'Error saving important date.');
      });
    };

    /**
     * logo (when logo is not displayed)
     * */
    $scope.errPreviousLoanImg = (lender) => {
      if (lender) {
        const indexOfResult = parseInt(lender['0'].name, 10);
        $scope.previousLoans[indexOfResult].Lender.noImg = true;
      }
    };
    $scope.errInProgressLoanImg = (lender) => {
      if (lender) {
        const indexOfResult = parseInt(lender['0'].name, 10);
        $scope.inprogressLoans[indexOfResult].Lender.noImg = true;
      }
    };
    $scope.errExistingLoansImg = (lender) => {
      if (lender) {
        const indexOfResult = parseInt(lender['0'].name, 10);
        $scope.existingLoans[indexOfResult].Lender.noImg = true;
      }
    };

    /**
     * duration
     * */
    $scope.getDuration = (endParam) => {
      let end = endParam;
      const start = moment();
      end = moment(end);

      if (!_.isUndefined(end) || end >= start) {
        try {
          return ((moment.duration(end - start)).humanize());
        } catch (e) {
          return 'Cant evaluate';
        }
      } else {
        return null;
      }
    };

    const getContactCalculations = () => {
      return contactService.savedCalculationsGet($scope.familyId)
        .then((response) => {
          if (!response) return;

          return response.data;
        });
    };

    const getLoanCalculations = () => {
      return contactService.getLoanCalculations($scope.familyId)
        .then((response) => {
          if (!response) return;

          return response.data;
        });
    };

    /**
     * Saved Calculations
     * */
    function savedCalculationsListGet() {
      const calculationsPromises = [
        getContactCalculations(),
        getLoanCalculations(),
      ];

      /**
      * please make sure not to interchange the values of @param calculationsPromises
      * as it is tightly coupled with the code block below
      */
      $q.all(calculationsPromises)
        .then((data) => {
          if (!data || !data.length) return;

          const savedCalculationsList = _.orderBy([...data[0], ...data[1]], ['DateOfCalculation'], ['asc']);

          $scope.savedCalculationsList = savedCalculationsList.map((calculation) => {
            calculation.calcTypeDesc = splitByCapitalLetters(calculation.TypeOfCalculation);
            return calculation;
          });
        });
    }

    savedCalculationsListGet();


    $scope.setSessionStorage = () => {
      $window.sessionStorage.setItem('loanApplication', $scope.loanAppId);
      $window.sessionStorage.setItem('loanApplicationContact', $scope.familyId);
    };

    $scope.goToFundsCalc = () => {
      $scope.setSessionStorage();
      $scope.redirectToFundingCalculator();
    };


    /**
     * Redirect To Funding Calculator Page
     * */
    $scope.redirectToFundingCalculator = (eventId) => {
      if (!_.isUndefined(eventId)) {
        $window.sessionStorage.setItem('contactFamilyIdForFundingCalculator', $scope.familyId);
        $state.go('app.fundingCalculator', { brokerEventId: eventId });
      } else {
        // Get Broker Event Id
        fundingCalculatorService.getFundingCalculatorNewBrokerEvent().then((response) => {
          $window.sessionStorage.setItem('contactFamilyIdForFundingCalculator', $scope.familyId);
          $state.go('app.fundingCalculator', { brokerEventId: response.data });
        });
      }
    };

    $scope.redirectToLoanProfiler = (tabName, eventId) => {
      const obj = {
        tabName,
        eventId,
        familyId: $scope.familyId,
        contact: loanCalculatorLinking.LINKERS.CONTACT,
      };
      $localStorage.contactLoanCalculatorLinker = { linkerId: loanCalculatorLinking.LINKERS.CONTACT, familyId: $scope.familyId };
      loanCalculatorLinking.redirectToLoanProfiler(obj);
    };

    $scope.redirectToLoanCalc = (calcObj) => {
      if (typeof calcObj.IsOpportunity === 'undefined') {
        switch (calcObj.TypeOfCalculation) {
        case 'Serviceability':
          $scope.redirectToLoanProfiler('serviceability', calcObj.BrokerEventID);
          break;
        case 'ProductFinder':
          $scope.redirectToLoanProfiler('productFinder', calcObj.BrokerEventID);
          break;
        case 'FundingCalculator':
          $scope.redirectToFundingCalculator(calcObj.BrokerEventID);
          break;
        default:
          break;
        }
      } else {
        const args = {
          tabName: camelize(calcObj.TypeOfCalculation),
          eventId: calcObj.BrokerEventID,
          id: calcObj.LoanScenarioId,
          linkerId: loanCalculatorLinking.LINKERS.OPPORTUNITY,
          title: calcObj.Title,
          isEdit: true,
          isOpportunityOrLoanapp: calcObj.IsOpportunity,
          familyId: $scope.familyId,
          familyName: $scope.clientHeaderName || $scope.business.Name,
          isBusiness: $scope.isBusiness,
          previousRouteStateParams: {
            opportunityId: calcObj.LoanScenarioId,
            loanId: calcObj.LoanId,
            sourceFamilyId: $scope.familyId,
            sourceFamilyName: $scope.clientHeaderName,
            isContactOpener: 1,
            tabName: 'calculations',
          },
        };

        const { tabName } = args;
        if (tabName === 'fundingCalculator') {
          loanCalculatorLinking.redirectToFundingCalculator(args);
        } else {
          loanCalculatorLinking.redirectToLoanProfiler(args);
        }
      }
    };

    /**
     * Delete Calculations
     * */
    $scope.removeSavedCalc = (calc, e) => {
      e && e.stopPropagation();
      const { LoanScenarioId: opportunityId } = calc;
      let calcType = 0;
      switch (calc.TypeOfCalculation) {
      case 'FundingCalculator':
        calcType = 1;
        break;
      case 'Serviceability':
        calcType = 2;
        break;
      case 'ProductFinder':
        calcType = 3;
        break;
      default:
        toaster.pop('error', 'Unable to delete unknown Calculation Type');
        break;
      }
      if (calcType !== 0) {
        contactService.savedCalculationsDelete(calcType, calc.LoanScenariOID || opportunityId, calc.BrokerEventID)
          .then((response) => {
            if (response.data === 1) {
              toaster.pop('success', 'Successfully deleted Saved Calculation');
              savedCalculationsListGet();
            } else toaster.pop('error', 'Error deleting Saved Calculation');
          });
      }
    };

    $scope.addExistingLoansModal = (action, familyId, loanId) => {
      $scope.params = {
        loanInformationAction: action,
        familyId: parseInt(familyId, 10),
        loanId,
      };
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/loan_modal_v2_add_existing.html',
        controller: 'LoanInformationAddExistingCtrl',
        scope: $scope,
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      });

      modalInstance.result.then((args) => {
        if (_.size(args) > 0)
          $scope.loanInformationModal(args.action, args.familyId, args.loanId);
      }, (args) => {
        if (_.size(args) > 0)
          $scope.loanInformationModal(args.action, args.familyId, args.loanId);
      });
    };

    $scope.loanInformationModal = (action, familyId, loanId, type = 'npw') => {
      $scope.loanType = type;
      $scope.params = {
        loanInformationAction: action,
        familyId: parseInt(familyId, 10),
        loanId,
      };
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
        controller: 'LoanInformationCtrl',
        scope: $scope,
        size: 'wide',
        backdrop: 'static',
        keyboard: false,
      });

      modalInstance.result.then(() => {
        $scope.getLoanListGet();
      }, () => {
        $scope.getLoanListGet();
      });
    };

    $rootScope.$on('Open_loanInformationModal', (e, info) => {
      $scope.loanInformationModal(info.action, info.familyId, info.loanId);
    });


    /**
     * Loan Application
     * */
    $scope.CreateDummyLoanScenario = () => {
      broadcastDataService.InlinePreloader = {
        preLoaderText: 'Creating New Loan Application',
        preLoaderSize: '',
        showPreLoader: true,
      };
      contactService.getContactAssignedAdviser($stateParams.familyId).then((response) => {
        if (response.data) {
          $scope.dummyscenario =
            {
              LoanScenarioId: '0',
              AdviserId: response.data,
              Title: `Loan Application ${Math.random().toString(36).substring(20)}`,
              StatusName: 'ongoing',
              FamilyId: $stateParams.familyId,
              AssignedLenderId: '0',
            };
          if (!$scope.isAdviserAccess) {
            $scope.userFamilyId = response.data;
          }
          loanScenarioService.scenarioSet($scope.dummyscenario).then((respond) => {
            if (!respond.data) return;
            loanAppDetailsService.setInsuranceConcierge(respond.data, $stateParams.familyId, $scope.userFamilyId);
            toaster.pop('success', 'Created', 'Loan application successfully created');
            $state.go('app.loanApplicationDetailsV3', {
              familyId: $stateParams.familyId,
              loanAppId: respond.data,
            });
            $rootScope.$on('$stateChangeSuccess', () => {
              broadcastDataService.InlinePreloader = {
                preLoaderText: '',
                preLoaderSize: '',
                showPreLoader: false,
              };
            });
          });
        }
      });
    };


    $rootScope.countLoanApplication = 0;


    $scope.deleteScenario = (id) => {
      lendingScenarioService.scenarioDelete(id).then((response) => {
        if (response.status === 200) {
          toaster.pop(
            'success',
            'Successful',
            'Successfully Deleted'
          );
          $scope.GetLendingScenarioList();
        } else {
          toaster.pop(
            'warning',
            'Error',
            'Something went wrong.'
          );
        }
      });
    };


    /**
     * lending scenario
     * */
    $scope.GetLendingScenarioList = () => {
      lendingScenarioService.scenarioListGet($stateParams.familyId).then(
        (response) => {
          $scope.lendingScenario = response.data;
          $scope.loanPurposeList = [
            {
              Name: 'First Home',
              Value: 'FirstHome',
            },
            {
              Name: 'Refinancing',
              Value: 'Refinancing',
            },
            {
              Name: 'Property Purchase',
              Value: 'PropertyPurchase',
            },
            {
              Name: 'Investment Property',
              Value: 'InvestmentProperty',
            },
            {
              Name: 'Building',
              Value: 'Building',
            },
            {
              Name: 'Home Improvements',
              Value: 'HomeImprovements',
            },
            {
              Name: 'Debt Consolidation',
              Value: 'DebtConsolidation',
            },
            {
              Name: 'Monthly Income/Equity Release',
              Value: 'MonthlyIncome_EquityRelease',
            },
            {
              Name: 'Other',
              Value: 'Other',
            },
          ];

          const LoanPurposes = [];

          angular.forEach($scope.lendingScenario, (lp) => {
            angular.forEach($scope.loanPurposeList, (val) => {
              if (lp.LoanPurpose === val.Value) {
                LoanPurposes.push(val.Name);
              }
            });

            lp.LoanPurposes = LoanPurposes.join(', ');
          });
        }
      );
    };


    /**
     * Initialize Lending Scenario List
     * */
    $scope.GetLendingScenarioList();

    $scope.addScenario = () => {
      $uibModal.open({
        templateUrl: '/assets/views/contacts/client/lending/lendingScenario/addScenario.html',
        scope: $scope,
        controller: 'AddScenarioModalCtrl',
        size: 'md',
      });
    };


    if ($rootScope.createLoanAppPipeline === true) {
      $rootScope.createLoanAppPipeline = false;
      $scope.lendingTab = 'loanApplications';
      $scope.CreateDummyLoanScenario();
    }

    /**
     * josandi starts here
     * */


    $scope.$watch(
      () => {
        if ($('.loan-modal-v2').length) {
          $('.modal-dialog').addClass('modal-v2');
        }
      }
    );

    $scope.addSecurity = false;
    $scope.addSecurityFunct = () => {
      $scope.addSecurity = true;
    };
    $scope.clearSecurityStructure = () => {
      contactSharedData.lendingSharedData.secCtrlEditSecurityStructure = [];
    };
    $rootScope.$on('cancelAddSecurity', (event, value) => {
      $scope.addSecurity = value;
    });

    /**
     * dont automatically show ADD LOAN SPLIT if there is already a list
     * */
    $scope.addLoanVar = contactSharedData.showLoanSplit;

    $scope.addLoanSplitButton = true;
    $scope.showAddLoanSplitButton = () => {
      $scope.addLoanSplitButton = !$scope.addLoanSplitButton;
    };

    /**
     * $scope.getFamEnquirySource
     * Used as callback after loading the enquirylist or contactInfoSetValue
     * */
    $scope.getFamEnquirySource = () => $q((resolve, reject) => {
      contactService.contactFamilyInfoGet($scope.familyId).then((res) => {
        if (!res || !res.data) reject();
        const { ReferralCategoryId, ReferralCategory: ReferralCategoryName, ReferralItemId, ReferralItem: ReferralItemName, ReferrerAgreementTypeId, ReferrerID, ReferrerName, ReferrerOrgID, ReferrerOrgName } = res.data;
        const source = {
          ReferralCategoryId,
          ReferralCategoryName,
          ReferralItemId,
          ReferralItemName,
          ReferrerAgreementTypeId,
          ReferrerID,
          ReferrerName,
          ReferrerOrgID,
          ReferrerOrgName,
        };
        if (source.ReferralItemId) {
          resolve(source);
        } else {
          reject();
        }
      });
    });

    /**
    * $scope.processEnquirySource()
    * check if Enquiry Source should be disabled
    * Disabled if Category is Website - Loan Market or Affiliate - Loan Market
    * */
    $scope.processEnquirySource = (ReferralCategoryId) => {
      contactService.getUserInfo().then((userlevel) => {
        /**
         * Override condition if Corporate User
         */
        if (parseInt(userlevel.data.AccessType, 10) !== ACCESS_TYPE.CORPORATE) {
          if (ReferralCategoryId === REFERRAL_TYPE.WEB_LOANMARKET || ReferralCategoryId === REFERRAL_TYPE.CONTACT_US) {
            $('.loan-details').find('#EnquirySourceDropdown').attr('disabled', 'disabled'); // disable the dropdown
          }
        }
      });
    };

    $scope.hasThreeOvals = (loanPurpose) => {
      return loanPurpose ? 'three-ovals' : 'two-ovals';
    };

    $scope.detailTabChange = (value, value2) => {
      $scope[`rateIndexTab_${value}`] = value2;
    };

    $scope.detailTabCheck = (value, value2) => {
      return $scope[`rateIndexTab_${value}`] === value2;
    };

    $scope.toggleDate = (obj, property) => {
      obj[property] = !obj[property];
    };
  }
);
