import template from './workbenchAddNote.html';
import WorkbenchAddNoteCtrl from './workbenchAddNoteCtrl';

export default {
  template,
  bindings: {
    loanId: '<',
    familyId: '<',
    noteToEdit: '=',
    onBack: '&',
  },
  controller: WorkbenchAddNoteCtrl,
  controllerAs: 'vm',
};
