import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { POST_SETTLEMENT_FOLLOW_UP_ID } from 'Common/constants/customerCareModules';

angular.module('app').controller('PostSettlementCtrl', function PostSettlementCtrl(
  $scope,
  $uibModal,
  SweetAlert,
  NgTableParams,
  $window,
  $location,
  $state,
  $stateParams,
  $document,
  toaster,
  cfpLoadingBar,
  $timeout,
  $rootScope,
  $filter,
  $localStorage,
  customerCareService) {
  // Fixed Post Settlement
  //--------------------------------------------------------------------------
  $scope.mainObj = {};
  $scope.mainObj.adviserId = 0;
  $scope.mainObj.dateRange = 'tab1';
  $scope.mainObj.settlementName = 'Settlement Day - SMS / Email';
  $scope.mainObj.ShowCompleted = false;

  // Table
  $scope.loadPostSettlementTable = function () {
    $scope.postSettlementTableParams = new NgTableParams({
      page: 1,
      count: 10,
      sorting: {
        SettlementDate: 'asc',
      },
    },
    {
      counts: [],
      total: 0,
      getData($defer, params) {
        $scope.isTableDataLoaderOverlayDisplay = true;
        const sortColumn = params.orderBy()[0].substring(1);
        const sortType = _.values(params.sorting())[0].toUpperCase();
        customerCareService.SettlementmentDateListGet($scope.mainObj.adviserId, $scope.mainObj.toDate, $scope.mainObj.formDate, params.page(),
          params.count(), sortColumn, sortType, $scope.mainObj.ShowCompleted)
          .then((response) => {
            if (typeof response.data !== 'undefined' && typeof response.data.SettlementmentDateList !== 'undefined') {
              $scope.postSettlementListTable = response.data.SettlementmentDateList;
              _.map($scope.postSettlementListTable, (item) => {
                item.SettlementDate = new Date(item.SettlementDate);
                const today = moment().format('D MMMM YYYY');
                const stlDate = moment(item.SettlementDate).format('D MMMM YYYY');

                if (today === stlDate)
                  item.IsDueToday = true;
                else
                  item.IsDueToday = false;
              });
              /* $scope.postSettlementListTable = $scope.postSettlementListTable.filter(function (o) {
                                    return o.isComplete === $scope.mainObj.ShowCompleted;
                                    }); */
              if (typeof response.data.TotalCount !== 'undefined')
                params.total(response.data.TotalCount);
              else
                params.total(0);
              $defer.resolve($scope.postSettlementListTable);
              $scope.isTableDataLoaderOverlayDisplay = false;
            }
          }, () => {
          });
      },
    });
  };

  // Get Post Settlement
  $scope.getPostSettlementList = function () {
    $scope.loadPostSettlementTable();
  };

  // Date Range Filter
  $scope.dateRangeFilter = function () {
    $scope.isTableDataLoaderOverlayDisplay = true;
    if ($scope.mainObj.dateRange === 'tab1') {
      $scope.mainObj.toDate = moment().subtract(6, 'days').format('D MMMM YYYY');
      $scope.mainObj.formDate = moment().format('D MMMM YYYY');
      $scope.mainObj.settlementName = 'Settlement Day - SMS / Email';
    } else if ($scope.mainObj.dateRange === 'tab2') {
      const tempDate = moment().subtract(7, 'days').format('D MMMM YYYY');
      $scope.mainObj.toDate = moment(tempDate).subtract(6, 'days').format('D MMMM YYYY');
      $scope.mainObj.formDate = tempDate;
      $scope.mainObj.settlementName = '7 Days - Settlement Gift';
    } else if ($scope.mainObj.dateRange === 'tab3') {
      const tempDate = moment().subtract(14, 'days').format('D MMMM YYYY');
      $scope.mainObj.toDate = moment(tempDate).subtract(6, 'week').format('D MMMM YYYY');
      $scope.mainObj.formDate = tempDate;
      $scope.mainObj.settlementName = '6 Week Account Check Up';
    } else if ($scope.mainObj.dateRange === 'tab4') {
      const tempDate1 = moment().subtract(6, 'week').format('D MMMM YYYY');
      const tempDate = moment(tempDate1).subtract(15, 'day').format('D MMMM YYYY');
      $scope.mainObj.toDate = moment(tempDate).subtract(3, 'months').format('D MMMM YYYY');
      $scope.mainObj.formDate = tempDate;
      $scope.mainObj.settlementName = '3 Months - How is going?';
    } else if ($scope.mainObj.dateRange === 'tab5') {
      const tempDate1 = moment().subtract(6, 'week').format('D MMMM YYYY');
      const tempDate2 = moment(tempDate1).subtract(3, 'months').format('D MMMM YYYY');
      const tempDate = moment(tempDate2).subtract(16, 'day').format('D MMMM YYYY');
      $scope.mainObj.toDate = moment(tempDate).subtract(6, 'months').format('D MMMM YYYY');
      $scope.mainObj.formDate = tempDate;
      $scope.mainObj.settlementName = '6 Months - Asset Finance';
    }
    $scope.loadPostSettlementTable();
  };

  // Set Post Settlement as Complete
  $scope.setPostSettlementCompilation = function (postSettlement) {
    const notiHeader = `Post Settlement • ${$scope.mainObj.settlementName} Completed`;
    const $postData = [{
      CustomerCareType: 'SETTLEMENT',
      FamilyID: 0,
      LoanID: postSettlement.LoanID,
      IsComplete: postSettlement.isComplete,
      NotificationHeader: notiHeader,
    }];
    customerCareService.setCompeletion($postData)
      .then(() => {
        $scope.postSettlementTableParams.reload();
      }, () => {
      });
  };

  // Filter Through Day
  $scope.selectDayFilter = function (tab) {
    $timeout(() => {
      const el = $document.find(`#${tab} .nav-link`);
      angular.element(el).triggerHandler('click');
    }, 0);
    $scope.dateRangeFilter();
  };
  $scope.selectDayFilter($scope.mainObj.dateRange);

  $scope.changeDaySelection = function (tab) {
    $scope.mainObj.dateRange = tab;
    $scope.dateRangeFilter();
  };

  // Open modal For Get Post Settlement Detail in Mobile
  $scope.openModalForGetPostSettlementDetail = function (postSettlement, size) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/post_settlement_filter_modal.html',
      controller: 'PostSettlementDetailsModalCtrl',
      size,
      resolve: {
        postSettlement() {
          return postSettlement;
        },
      },
    });
  };

  const modalResolve = (postSettlementObj, source) => {
    return {
      postSettlementListTable() {
        return $scope.postSettlementListTable;
      },
      postSettlementObj() {
        return postSettlementObj;
      },
      source() {
        return source;
      },
      loadPostSettlementTable() {
        return $scope.loadPostSettlementTable;
      },
      mainObj: () => $scope.mainObj,
    };
  };

  $scope.openSendEmailModal = function (size, postSettlementObj, source) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/send_email_modal.html',
      resolve: modalResolve(postSettlementObj, source),
      controller: 'OpenPostSettlementSendEmailModalCtrl',
      size,
    });
  };
  $scope.openSendMailModal = function (size, postSettlementObj, source) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/send_mail_modal.html',
      resolve: {
        listTableData() {
          return $scope.postSettlementListTable;
        },
        source() {
          return source;
        },
        currentModuleId() {
          return POST_SETTLEMENT_FOLLOW_UP_ID;
        },
        sendMailObj: () => postSettlementObj,
      },
      controller: 'OpenSendMailModalCtrl',
      size,
    });
  };
  $scope.openSMSModal = function (size, postSettlementObj, source) {
    $uibModal.open({
      templateUrl: '/assets/views/customerCare/partials/modal/send_sms_modal.html',
      // PRE_APPROVAL_EXPIRY
      resolve: modalResolve(postSettlementObj, source),
      controller: 'OpenPostSettlementSMSModalCtrl',
      size,
    });
  };
});
