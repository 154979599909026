export function policyInsurerPrimary(data) {
  return {
    policyID: data.PolicyID,
    policyName: data.PolicyName,
  };
}

export function insurerMergeFieldsBuilderForUI(data) {
  return data.Policies.map(policyInsurerPrimary);
}

