import angular from 'angular';

app.factory('loanProfilerSharedData', function loanCalculatorSharedData($localStorage, loanProfilerService, lendingScenarioService, toaster) {
    var factory = this;

    angular.extend(factory, {
        selectClient (client) {
          this.selectedClient = client;
          this.selectedClientName = `${client.FirstName} ${client.LastName}`;
          this.showCreateNewContact = false;
          this.clientScenarioList = [];
          loanProfilerService.getScenarioList(client.FamilyID).then((response) => {
            this.clientScenarioList = response.data;
            this.showClientList = false;
          });
          if ($localStorage.loanProfilerCreateNewContact == true) {
              $localStorage.$reset();
          }
        },
        getSearchClient (searchString) {
            this.isShowContactNotFoundMessage = false;
          if (searchString && searchString.length >= 3) {
            return loanProfilerService.getSearchClient(searchString, 2).then((response) => {
                this.searchClientList = response.data;
            this.loanProfilerClientSearchList = [];
            angular.forEach(response.data, (item) => {
                item.FullName = `${item.FirstName} ${item.LastName}`;
            this.loanProfilerClientSearchList.push(item);
          });
            this.isShowContactNotFoundMessage = this.loanProfilerClientSearchList.length === 0;
            return this.loanProfilerClientSearchList;
        });
        }
            this.innerResetObject();
            this.loanProfilerClientSearchList = [];
        return this.loanProfilerClientSearchList;
        },
        productFinderLinkSet (lendingScenarioId, productId) {
            lendingScenarioService.productFinderLinkSet(
              {
                lendingScenarioId: lendingScenarioId,
                productId: productId,
              }
            ).then(() => {
              toaster.pop(
                'success',
                'Success',
                'Product are saved to Lending Scenario'
              );
            });
        },
    });
    return factory;
});
