export function productPdfBuilderForUI(data) {
  return {
    name: data.Name,
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    isSvg: data.IsSvg,
  };
}
export function putProductFinderSettingsBuilderForMyCRM(settings) {
  const { generalSetting, customSelection } = settings;
  return {
    GeneralSetting: {
      Lender: generalSetting.lender,
      loanTerm: generalSetting.loanTerm,
      loanAmount: generalSetting.loanAmount,
      security: generalSetting.security,
      LVR: generalSetting.lvr,
      splitLoan: generalSetting.splitLoan,
    },
    CustomSelection: {
      LoanAmount: customSelection.loanAmount,
      DepositAmount: customSelection.depositAmount,
      MaxRate: customSelection.maxRate,
      RateType: customSelection.rateType,
      Financials: customSelection.financials,
      ProductType: customSelection.productType,
      Intention: customSelection.intention,
      Repayment: customSelection.repayment,
      AdvanceFilters: customSelection.advanceFilters,
      loanStructureID: customSelection.loanStructureId,
    },
  };
}
export function assessmentDetailCalculationsBuilderForUI(data) {
  return {
    familyId: data.FamilyId,
    dateOfCalculation: data.DateOfCalculation,
    typeOfCalculation: data.TypeOfCalculation,
  };
}
export function incomeTypeOptionBuilderForMyCRM(data) {
  return {
    Name: data.Name,
    Value: data.Value,
  };
}
export function clientOptionBuilderForMyCRM(data) {
  return {
    ClientNo: data.ClientNo,
    ClientName: data.ClientName,
    ClientID: data.ClientID,
  };
}
