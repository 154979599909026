import { findIndex } from 'lodash';
import { checkPipelineColumnHasMore, isClientFilterValid, isConversionStatus } from 'Common/utilities/insurancePipeline';
import { displayError, toastError } from 'Common/utilities/alert';
import {
  getStatusIdConvertedTo,
  checkIsInsuranceAppProceeded,
} from 'Common/utilities/insurance';
import { getElementYOffset, revertMoveCardToOtherColumn } from 'Common/utilities/kanbanHelper';

const getColumnWithSortingChanges = (sortingChanges) => {
  if (!sortingChanges) return;

  const { previousValue, currentValue } = sortingChanges;
  if (!currentValue) return;

  if (!previousValue) {
    const sortedColumnId = Object.keys(currentValue)[0];
    return parseInt(sortedColumnId, 10);
  }
  const columnIdWithChangedSorting = Object.keys(currentValue).find((key) => {
    const isNew = !previousValue[key];
    if (isNew) return true;
    const fieldChanged = previousValue[key].field !== currentValue[key].field;
    const directionChanged = previousValue[key].direction !== currentValue[key].direction;
    return fieldChanged || directionChanged;
  });
  return parseInt(columnIdWithChangedSorting, 10);
};

const buildInsurancePipelineColumns = (statuses, sizePerColumn) => {
  return statuses && statuses.reduce((accum, column) => {
    if (isConversionStatus(column)) return accum;
    return [...accum, { ...column, page: 1, pageSize: sizePerColumn, totalAnnualPremium: 0, cards: [] }];
  }, []);
};

const INSURANCE_PIPELINE_PAGE_SIZE = 10;

export default class InsurancePipelineKanbanBoardCtrl {
  constructor(
    $window,
    $element,
    $timeout,
    currentUserService,
    pipelineSharedData,
    insurancePipelineService,
    modalRenderService
  ) {
    'ngInject';

    this.$window = $window;
    this.$element = $element;
    this.$timeout = $timeout;
    this.currentUserService = currentUserService;
    this.pipelineSharedData = pipelineSharedData;
    this.insurancePipelineService = insurancePipelineService;
    this.modalRenderService = modalRenderService;
    this.isLoading = true;
  }

  $onInit() {
    this.pipelineSharedData.initCardViewStates();
    this.cardViewStates = this.pipelineSharedData.cardViewStates;
    this.initKanbanOptions();
    this.getHeightToFillWindow();
    this.onInit && this.onInit({
      api: {
        refresh: () => this.loadPipelineCards(1, null, true),
        silentlyRefresh: () => this.loadPipelineCards(1, null, false),
      },
    });
    this.proceededCards = [];
    this.notProceededCards = [];
  }

  $onChanges(changes) {
    const { columnsSorting, clientFilter, statuses } = changes;
    const changedSortingColumnId = getColumnWithSortingChanges(columnsSorting);
    if (changedSortingColumnId && !this.isLoading) {
      this.loadPipelineCards(1, changedSortingColumnId, true, true);
    }
    if (clientFilter && !clientFilter.isFirstChange()) {
      this.filterByClient(clientFilter.currentValue);
    }
    if (statuses && statuses.currentValue) {
      this.isLoading = false;
      this.loadPipelineColumns();
    }
  }

  $onDestroy() {
    this.onAccepTimeout && this.$timeout.cancel(this.onAccepTimeout);
    this.windowHeightTimeout && this.$timeout.cancel(this.windowHeightTimeout);
    this.updateCardLabelsTimeout && this.$timeout.cancel(this.updateCardLabelsTimeout);
    this.filterByClientTimeout && this.$timeout.cancel(this.filterByClientTimeout);
  }

  initKanbanOptions() {
    this.kanbanOptions = {
      allowDuplicates: true,
      containerPositioning: 'relative',
      containment: '#insurancePipelineContainment',
      dragStart: this.pipelineSharedData.handleDragStart,
      dragEnd: this.pipelineSharedData.handleDragEnd,
      dragMove: this.pipelineSharedData.handleDragMove,
      accept: (src, dest) => this.acceptCardToColumn(src, dest),
      itemMoved: event => this.cardMoved(event),
    };
  }

  acceptCardToColumn(sourceScope, destinationScope) {
    this.cardViewStates.isSettledDropAreaEnabled = false;
    this.cardViewStates.isNotProceedingDropAreaEnabled = false;
    this.onAccepTimeout = this.$timeout(() => {
      const destinationElem = destinationScope && destinationScope.element;
      if (!destinationElem || !destinationElem.length) return;
      const dropAreaId = destinationElem[0].id;
      const areaDragged = this.pipelineSharedData.onDropAreaMouseIn(dropAreaId);
      if (!areaDragged) return;
      this.cardViewStates[areaDragged] = this.cardViewStates.cardOnDrag;
    });
    return true;
  }

  cardMoved(event) {
    if (this.pipelineSharedData.checkItemMove(event)) {
      const srcColumn = event.source.sortableScope.$parent.$parent.column;
      const destColumn = event.dest.sortableScope.$parent.$parent.column;
      const toStatusId = destColumn.id;
      const fromStatusId = srcColumn.id;
      const { card } = event.source.itemScope;
      const { quoteId } = card;

      this.insurancePipelineService.movePipelineCardToStatus({ fromStatusId, toStatusId, quoteId })
        .then(() => {
          const cardInDestColumn = destColumn.cards.find(cardInList => cardInList.quoteId === card.quoteId);
          cardInDestColumn.status = { id: destColumn.id, name: destColumn.name };
          this.loadPipelineCards(destColumn.page, toStatusId, false, true);
          this.loadPipelineCards(srcColumn.page, fromStatusId, false, true);
        })
        .catch(toastError);
    } else {
      const proceeding = checkIsInsuranceAppProceeded(event);
      this.promptToContinueConversion(proceeding)
        .result.then((response) => {
          if (response.isOk) {
            this.proceedCardConversion(event, response.selectedReasonId);
          } else {
            revertMoveCardToOtherColumn(event);
          }
        });
    }
  }

  promptToContinueConversion(isProceeding) {
    return this.modalRenderService.renderPipelineStatusMoveConfirmationModal({
      content: `This card will now be moved to ${isProceeding ? 'In Force' : 'Not Proceeding'}`,
      reasonList: isProceeding ? null : this.notProceedingReasons,
      showNurtureCampaignsQuestion: false,
    });
  }

  proceedCardConversion(event, reasonForNotProceedingID) {
    const convertedCard = event.source.itemScope.modelValue;
    const toStatusId = getStatusIdConvertedTo(event);
    const { quoteId, status } = convertedCard;
    const fromStatusId = status.id;
    this.insurancePipelineService.movePipelineCardToStatus({ fromStatusId, toStatusId, quoteId, reasonForNotProceedingID })
      .then(() => {
        convertedCard.status.id = toStatusId;
        this.onApplicationConverted && this.onApplicationConverted();
        const srcColumn = event.source.sortableScope.$parent.$parent.column;
        this.loadPipelineCards(srcColumn.page, fromStatusId, false, true);
      })
      .catch(displayError);
  }

  getHeightToFillWindow() {
    this.windowHeightTimeout = this.$timeout(() => {
      const yOFfset = getElementYOffset(this.$element, this.$window);
      const heightToFill = (this.$window.innerHeight - yOFfset) - 55;
      this.componentHeight = heightToFill && `${heightToFill}px`;
    }, 500);
  }

  loadPipelineColumns() {
    this.columns = buildInsurancePipelineColumns(this.statuses, INSURANCE_PIPELINE_PAGE_SIZE);
    this.loadPipelineCards();
  }

  loadPipelineCards(pageNumber, statusId, showLoading = true, refresh) {
    this.isLoading = !statusId && showLoading;
    const doLoadDefaultPage = (refresh && statusId) || !pageNumber;
    const actualPage = doLoadDefaultPage ? 1 : pageNumber;
    const pageSize = refresh && statusId ? (pageNumber || 1) * INSURANCE_PIPELINE_PAGE_SIZE : INSURANCE_PIPELINE_PAGE_SIZE;
    statusId && refresh && this.setColumnLoaderVisibility(statusId, showLoading);
    this.insurancePipelineService.getInsurancePipelineCards({
      pageNumber: actualPage, pageSize, statusId, searchClient: this.clientFilter,
    })
      .then((response) => {
        if (!response) return;
        if (!statusId) {
          this.populateColumnsWithCards(response);
        } else if (refresh) {
          this.refreshColumnCards(statusId, response[0]);
        } else {
          this.insertCardsToColumn(statusId, response[0]);
        }
      })
      .catch(toastError)
      .finally(() => {
        this.isLoading = false;
        this.setColumnLoaderVisibility(statusId, false);
      });
  }

  populateColumnsWithCards(columns) {
    this.columns = this.columns.map((column) => {
      const columnFromResponse = columns.find(col => col.id === column.id);
      if (columnFromResponse) {
        columnFromResponse.hasMore = checkPipelineColumnHasMore(columnFromResponse, INSURANCE_PIPELINE_PAGE_SIZE);
        return columnFromResponse;
      }
      return { ...column, totalAnnualPremium: 0, totalCards: 0, cards: [] };
    });
  }

  refreshColumnCards(statusId, refreshedColumn) {
    this.updateColumn(statusId, refreshedColumn, false);
  }

  insertCardsToColumn(statusId, updatedColumn) {
    if (!updatedColumn || !updatedColumn.cards || !updatedColumn.cards.length) return;
    this.updateColumn(statusId, updatedColumn, true);
  }

  updateColumn(statusId, updatedColumn, isInsert) {
    const columnInList = this.columns.find(col => col.id === statusId);
    if (isInsert) {
      columnInList.cards = [...columnInList.cards, ...updatedColumn.cards];
      columnInList.page = updatedColumn.page;
    } else {
      columnInList.cards = updatedColumn ? [...updatedColumn.cards] : [];
      columnInList.page = Math.ceil(columnInList.cards.length / INSURANCE_PIPELINE_PAGE_SIZE);
    }
    columnInList.totalCards = updatedColumn ? updatedColumn.totalCards : 0;
    columnInList.hasMore = checkPipelineColumnHasMore(columnInList, INSURANCE_PIPELINE_PAGE_SIZE);
    columnInList.totalAnnualPremium = updatedColumn ? updatedColumn.totalAnnualPremium : 0;
  }

  setColumnLoaderVisibility(columnId, showLoader) {
    const columnInList = this.columns.find(col => col.id === columnId);
    if (!columnInList) return;
    columnInList.isLoading = showLoader;
    columnInList.isLoadingMore = false;
  }

  viewMore(column) {
    if (column.isLoadingMore) return;

    column.isLoadingMore = true;
    this.loadPipelineCards(column.page + 1, column.id, false);
  }

  onAddLabelToCard(columnId, cardId, label) {
    if (!columnId || !cardId || !label) return;
    this.insurancePipelineService.addPipelineItemLabel({ quoteId: cardId, labelIds: label.id })
      .then(() => {
        const card = this.getCardInColumn(columnId, cardId);
        this.updateCardLabels(card, label, 'insert');
      })
      .catch(toastError);
  }

  onDeleteLabelFromCard(columnId, cardId, label) {
    if (!columnId || !cardId || !label) return;
    this.insurancePipelineService.deletePipelineItemLabel({ quoteId: cardId, labelIds: label.id })
      .then(() => {
        const card = this.getCardInColumn(columnId, cardId);
        this.updateCardLabels(card, label, 'delete');
      })
      .catch(toastError);
  }

  getCardInColumn(columnId, cardId) {
    const column = this.columns.find(col => col.id === columnId);
    return column && column.cards && column.cards.find(cardInlist => cardInlist.quoteId === cardId);
  }

  updateCardLabels(card, label, type) {
    if (!card) return;
    this.updateCardLabelsTimeout = this.$timeout(() => {
      if (type === 'insert') {
        card.labels = card.labels ? [...card.labels, label] : [label];
      } else {
        const indexOfLabel = findIndex(card.labels, labelInList => labelInList.id === label.id);
        card.labels = [
          ...card.labels.slice(0, indexOfLabel),
          ...card.labels.slice(indexOfLabel + 1),
        ];
      }
    });
  }

  filterByClient(filter) {
    if (!isClientFilterValid(filter)) return;
    this.$timeout.cancel(this.filterByClientTimeout);
    this.filterByClientTimeout = this.$timeout(() => {
      this.loadPipelineCards(1, null, true);
    }, 1000);
  }
}
