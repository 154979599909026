import angular from 'angular';

class CommissionEnquiryModalCtrl {
  constructor(
    $uibModalInstance,
    contactService,
    commission,
    familyId,
    loanId
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.contactService = contactService;
    this.commission = commission;
    this.familyId = familyId;
    this.loanId = loanId;
  }

  close() {
    this.$uibModalInstance.dismiss();
  }
}

angular.module('app').controller('CommissionEnquiryModalCtrl', CommissionEnquiryModalCtrl);
