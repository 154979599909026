
import { getDatePickerConfig } from 'Common/utilities/date';

class DatePickerCtrl {
  $onInit() {
    if (!this.config) {
      this.config = getDatePickerConfig();
    }
  }

  toggleCalendar() {
    this.isCalendarOpen = !this.isCalendarOpen;
  }
}

export default DatePickerCtrl;
