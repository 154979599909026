import angular from 'angular';

angular.module('app').directive('month', function month() {
  return {
    scope: {
      createdmonth: '=',
    },
    replace: true,
    template: '<span>{{monthvalue}}</span>',
    controller: ['$scope', function ($scope) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ];

      $scope.monthvalue = months[$scope.createdmonth - 1];
    }],
  };
});
