import template from './calendarRangePicker.html';
import controller from './calendarRangePickerCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    pipelineSharedData: '<',
    activeRangeIndex: '<',
    onUpdate: '&',
    onRangeSelected: '&',
  },
};
