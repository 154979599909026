import angular from 'angular';
import { ANNUAL_REVIEWS_ID, CUSTOMER_CARE_TYPES } from 'Common/constants/customerCareModules';

angular.module('app').controller('AnnualReviewSendEmailModalCtrl', function AnnualReviewSendEmailModalCtrl(
  $scope,
  $rootScope,
  $uibModalInstance,
  $uibModal,
  $state,
  $controller,
  toaster,
  contactService,
  corporateService,
  customerCareService,
  emailTemplatesService,
  ckEditorConfigService,
  annualReviewListTable,
  source,
  annualReviewObj,
  formErrorHandlerServices,
  loadAnnualReviewTable,
  customerCareTemplateService) {
  const vm = this;
  angular.extend(vm, $controller('CustomerCareSendEmailModalBaseCtrl', { $scope }));

  $scope.recipients = {};
  $scope.IsComplete = true;
  $scope.currentModule = ANNUAL_REVIEWS_ID;

  if (source === 'single') {
    if (annualReviewObj.Email !== '') {
      const notiHeader = `Annual Reviews Completed via Email`;
      $scope.recipients.email = [{
        FamilyID: annualReviewObj.FamilyID,
        FamilyFullname: annualReviewObj.FamilyFullName,
        LoanID: annualReviewObj.LoanID,
        EmailAddress: annualReviewObj.Email,
        NotificationHeader: notiHeader,
      }];
    }
    $scope.recipients.CustomerCareType = 'CLIENT_ANNUAL_REVIEW';
  }
  $scope.annualReviewListTable = annualReviewListTable;

  if (source === 'all') {
    $scope.all = true;
    if ($scope.annualReviewListTable.length > 0) {
      $scope.selectEmailList = [];

      for (let i = 0; i < $scope.annualReviewListTable.length; i++) {
        $scope.emailAdd = {};
        if ($scope.annualReviewListTable[i].Email !== '') {
          $scope.emailAdd.FamilyID = $scope.annualReviewListTable[i].FamilyID;
          $scope.emailAdd.FamilyFullname = $scope.annualReviewListTable[i].FamilyFullName;
          $scope.emailAdd.LoanID = $scope.annualReviewListTable[i].LoanID;

          $scope.emailAdd.EmailAddress = $scope.annualReviewListTable[i].Email;
          $scope.selectEmailList.push($scope.emailAdd);
        }
      }
      if ($scope.selectEmailList.length > 0) {
        $scope.recipients.email = $scope.selectEmailList;
        $scope.noOfPeople = $scope.selectEmailList.length;
      }
    }
  }
  $scope.getRecipientAutoSuggestion = (userInput) => {
    contactService.getRecipientAutoSuggestion(userInput).then((response) => {
      $scope.selectEmailList = [];
      $scope.withEmail = response.data;
      for (let i = 0; i < $scope.withEmail.length; i++) {
        if ((typeof $scope.withEmail[i].EmailAddress !== 'undefined') && ($scope.withEmail[i].EmailAddress !== '')) {
          $scope.selectEmail = {};
          $scope.selectEmail.FamilyFullname = $scope.withEmail[i].Name;
          $scope.selectEmail.EmailAddress = $scope.withEmail[i].EmailAddress;
          $scope.selectEmailList.push($scope.selectEmail);
        }
      }
    });
  };

  // EmailTemplates
  const getMergeFields = () => {
    $scope.preferredName = '';
    if (typeof annualReviewObj.FamilyID !== 'undefined') {
      $scope.familyId = annualReviewObj.FamilyID;
    } else {
      $scope.familyId = 0;
    }
    if (typeof annualReviewObj.LoanID !== 'undefined') {
      $scope.loanId = annualReviewObj.LoanID;
    } else {
      $scope.loanId = 0;
    }
    $scope.clientId = 0;
    corporateService.MergeFieldsGet($scope.familyId, $scope.clientId, $scope.loanId).then((response) => {
      $scope.emailMergeFieldList = response.data;
      $scope.mergeFieldsObj = response.data[0];
      $scope.mergeFields = [];
      $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
      for (let k = 0; k < $scope.mergeFields.length; k++) {
        if ($scope.mergeFields[k].Name === 'Full Name') {
          $scope.preferredName = $scope.mergeFields[k].Value;
        }
      }

      $scope.getEmailTemplates($scope.preferredName);
      $scope.getDefaultTemplate();
    });
  };

  getMergeFields();

  $rootScope.getEmailTemplatesFromEmailPage = () => {
    $scope.getEmailTemplates('');
  };
  $scope.selectedTemplate = {
    UserTemplateID: 0,
    UserTemplateName: 'Blank',
    UserTemplateEmailSubject: '',
  };

  $scope.refreshResults = (select) => {
    const search = select.search;
    $scope.getRecipientAutoSuggestion(search);
  };
  $scope.sendEmail = function (form) {
    const customerCareFormValid = formErrorHandlerServices.sendEmailFormValidation(form);
    if (customerCareFormValid) {
      $scope.recipientsEmailArray = [];
      angular.forEach($scope.recipients.email, (item) => {
        $scope.recipientsEmailArray.push(item);
      });
      $scope.email.ClientList = $scope.recipientsEmailArray;
      $scope.email.CustomerCareType = CUSTOMER_CARE_TYPES.ANNUAL_REVIEWS;
      $scope.email.IsComplete = $scope.IsComplete;


      customerCareService.CustomerCareEmailSendv2($scope.email).then((annualReviewRes) => {
        if (annualReviewRes.data && annualReviewRes.data.length) {
          const annualReviewEmailValid = customerCareTemplateService.customerCareResponseMsg(annualReviewRes.data, 'EMAIL');
          if (annualReviewEmailValid) {
            $scope.email.EmailSubject = '';
            $scope.email.EmailBody = '';
            loadAnnualReviewTable();
            form.$setPristine(true);
          }
          $scope.cancel();
        }
      });
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
});
