import angular from 'angular';
import _ from 'lodash';
import { SECTION_ID, LIST_PURPOSE_QUESTION_ID, PREFERRED_LENDER_QUESTION_ID } from 'Common/constants/loanappSectionIds';

angular.module('app').controller('LoanAppCustomerObjectivesCtrl',
  function LoanAppCustomerObjectivesCtrl($rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    colorService,
    $document,
    loanProfilerService,
    optionsService,
    loanAppSharedData,
    fundingCalculatorService,
    configService,
    objectivesQuestionService,
    uiService) {
    $scope.clickCounter = 0;
    $scope.loanAppId = $stateParams.loanAppId;
    $scope.familyId = $stateParams.familyId;
    $scope.uiService = uiService;
    $scope.requirementsAndObjectives = (configService.feature && configService.feature.requirementsAndObjectives) || 0;
    $scope.objectivesQuestionService = objectivesQuestionService;

    $scope.getLoanDepositObject = {
      DepositBreakDown: [],
    };
    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    /* end of listeners */
    const returnLoanListQuestionObject = () => {
      return loanAppSharedData.loanAppSummary.LoanObjectives.find(object => object.QuestionId === LIST_PURPOSE_QUESTION_ID.AU);
    };

    const returnPreferredLendersQuestionObject = () => {
      return loanAppSharedData.loanAppSummary.LoanObjectives.find(object => object.QuestionId === PREFERRED_LENDER_QUESTION_ID.AU);
    };

    const populateLoanListOnLoad = (loanListObject) => {
      $scope.listPurpose = [];
      let tempValue = [];

      optionsService.loanpurpose().then((response) => {
        loanListObject.answerInt.forEach((purposeId) => {
          tempValue = response.data.find(loanPurpose => parseInt(loanPurpose.Value, 10) === parseInt(purposeId, 10));
          $scope.listPurpose.push(tempValue);
        });
      });
    };

    const populatePreferredLendersOnLoad = (preferredLenderListObject) => {
      $scope.listLenders = [];
      if (preferredLenderListObject && preferredLenderListObject.answerInt) {
        preferredLenderListObject.answerInt.forEach((lenderId) => {
          $scope.listLenders.push({ ProviderId: lenderId, ProviderName: '' });
        });
      }
    };

    $scope.fundsAvailable = [];

    optionsService.countryListGet()
      .then((response) => {
        $scope.countryList = response.data;
      }, () => {
        $scope.countryList = [];
      });

    loanApplicationServices.loanSummaryGet($state.params.loanAppId).then(() => {
      $scope.processCustomerObjectives = () => {
        if ($scope.clickCounter !== 0) return;

        $scope.clickCounter++;
        $scope.validateObjectives = () => {
          if (!$scope.listPurpose) {
            $scope.getLoanObjectives();
          }
          const isValidObjectivesQuestion = (objectivesQuestionService.objectivesQuestionValid() || !$scope.requirementsAndObjectives);
          const isValidImportantDates = ((($scope.loanAppOptions.dates.settlementDate.active === true || $scope.loanAppOptions.dates.financeDate.active === true) && $scope.ObjectivesSet.ImportantDates === true) || $scope.ObjectivesSet.ImportantDates === false);
          const isValidSpecificGoals = ($scope.ObjectivesSet.SpecificGoalsObjectives !== '' && $scope.ObjectivesSet.SpecificGoalsObjectives !== null);
          const isValidBrankruptNote = (($scope.ObjectivesSet.Bankrupt === true && !_.isUndefined($scope.ObjectivesSet.BankruptNote) && $scope.ObjectivesSet.BankruptNote !== '') || $scope.ObjectivesSet.Bankrupt === false);
          const isValidCurrentDebtNote = (($scope.ObjectivesSet.CurrentDebt === true && !_.isUndefined($scope.ObjectivesSet.CurrentDebtNote) && $scope.ObjectivesSet.CurrentDebtNote !== '') || $scope.ObjectivesSet.CurrentDebt === false);
          const isValidRiskInsurance = ($scope.ObjectivesSet.RiskInsurance === true || $scope.ObjectivesSet.RiskInsurance === false);
          const isValidHomeInsurance = ($scope.ObjectivesSet.HomeInsurance === true || $scope.ObjectivesSet.HomeInsurance === false);
          const isValidWhatAreYouWantingToDo = ($scope.ObjectivesSet.WhatAreYouWantingToDo !== '' && $scope.ObjectivesSet.WhatAreYouWantingToDo !== null);
          const isValidBankWith = ($scope.ObjectivesSet.BankingWith !== '' && $scope.ObjectivesSet.BankingWith !== null);
          const isValidHappyWithCurrentBank = (($scope.ObjectivesSet.HappyWithCurrentBank === false && !_.isUndefined($scope.ObjectivesSet.HappyWithCurrentBankNote) && $scope.ObjectivesSet.HappyWithCurrentBankNote !== '') || $scope.ObjectivesSet.HappyWithCurrentBank === true);
          const isValidTopUp = (($rootScope.crmCountry !== 'New Zealand') || ($rootScope.crmCountry === 'New Zealand' && (($scope.purposeHasType('Top Up') && $scope.ObjectivesSet.ReasonForTopUp) || (!$scope.purposeHasType('Top Up')))));
          const isValidRefinance = (($rootScope.crmCountry !== 'New Zealand') || ($rootScope.crmCountry === 'New Zealand' && (($scope.purposeHasType('Refinance') && $scope.ObjectivesSet.BankToRefinanceFrom) || (!$scope.purposeHasType('Refinance')))));
          const isValidDirector = (
            ($rootScope.crmCountry !== 'Australia') ||
($rootScope.crmCountry === 'Australia' &&
(!_.isUndefined($scope.ObjectivesSet.directorForYears) &&
(
  ($scope.ObjectivesSet.directorForYears && $scope.ObjectivesSet.directorForYearsNote !== '' &&
!_.isUndefined($scope.ObjectivesSet.directorForYearsNote)
  ) ||
$scope.ObjectivesSet.directorForYears === false
)
)));

          const firstConditionIsvalidObjectives = (
            $scope.listPurpose.length > 0 &&
            $scope.listLenders.length > 0 &&
            $scope.listLoanFeatures.length > 0 &&
            isValidImportantDates &&
            isValidSpecificGoals &&
            isValidBrankruptNote &&
            isValidCurrentDebtNote &&
            isValidRiskInsurance &&
            isValidHomeInsurance &&
            isValidWhatAreYouWantingToDo &&
            isValidBankWith &&
            isValidHappyWithCurrentBank &&
            isValidTopUp &&
            isValidRefinance &&
            isValidDirector &&
            isValidObjectivesQuestion
          );
          const secondConditionIsValidObjectives = (
            $rootScope.crmCountry === 'New Zealand' &&
            $scope.listPurpose && $scope.listPurpose.length > 0 &&
            $scope.ObjectivesSet && $scope.ObjectivesSet.SpecificGoalsObjectives
          );

          $scope.isValidObjectives = firstConditionIsvalidObjectives || secondConditionIsValidObjectives;

          if ($scope.ObjectivesSet && typeof $scope.ObjectivesSet.depositAmount !== 'undefined' && $scope.ObjectivesSet.depositAmount) {
            $scope.ObjectivesSet.depositAmount = '';
          }
          const lastConditionIsStartedObjectives = ($rootScope.crmCountry === 'New Zealand' || ($scope.listPurpose && $scope.listPurpose.length > 0) || ($scope.ObjectivesSet && typeof $scope.ObjectivesSet.SpecificGoalsObjectives !== 'undefined' && $scope.ObjectivesSet.SpecificGoalsObjectives));

          $scope.isStartedObjectives = (
            $scope.listPurpose.length > 0 ||
            $scope.fundsAvailable.length > 0 ||
            $scope.listLenders.length > 0 ||
            $scope.listLoanFeatures.length > 0 ||
            (((($scope.loanAppOptions.dates.settlementDate.active === true || $scope.loanAppOptions.dates.financeDate.active === true) && $scope.ObjectivesSet.ImportantDates === true) || $scope.ObjectivesSet.ImportantDates === false) &&
            ($scope.ObjectivesSet.SpecificGoalsObjectives !== '' && $scope.ObjectivesSet.SpecificGoalsObjectives !== null)) ||
            (($scope.ObjectivesSet.Bankrupt === true && !_.isUndefined($scope.ObjectivesSet.BankruptNote)) || $scope.ObjectivesSet.Bankrupt === false) ||
            (($scope.ObjectivesSet.CurrentDebt === true && !_.isUndefined($scope.ObjectivesSet.CurrentDebtNote)) || $scope.ObjectivesSet.CurrentDebt === false) ||
            ($scope.ObjectivesSet.RiskInsurance === true || $scope.ObjectivesSet.RiskInsurance === false) ||
            ($scope.ObjectivesSet.HomeInsurance === true || $scope.ObjectivesSet.HomeInsurance === false) ||
            ($scope.ObjectivesSet.WhatAreYouWantingToDo !== '' && $scope.ObjectivesSet.WhatAreYouWantingToDo !== null) ||
            ($scope.ObjectivesSet.BankingWith !== '' && $scope.ObjectivesSet.BankingWith !== null) ||
            (($scope.ObjectivesSet.HappyWithCurrentBank === false && !_.isUndefined($scope.ObjectivesSet.HappyWithCurrentBankNote)) || $scope.ObjectivesSet.HappyWithCurrentBank === true) ||
            (($rootScope.crmCountry !== 'New Zealand') || ($rootScope.crmCountry === 'New Zealand' && (($scope.purposeHasType('Top Up') && $scope.ObjectivesSet.ReasonForTopUp) || (!$scope.purposeHasType('Top Up'))))) ||
            (($rootScope.crmCountry !== 'New Zealand') || ($rootScope.crmCountry === 'New Zealand' && (($scope.purposeHasType('Refinance') && $scope.ObjectivesSet.BankToRefinanceFrom) || (!$scope.purposeHasType('Refinance')))))
          ) || lastConditionIsStartedObjectives;

          const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.CUSTOMER_OBJECTIVES);

          const isValidObjectivesToPass = !!$scope.isValidObjectives;
          loanAppSharedData.completionStatusSet(SECTION_ID.CUSTOMER_OBJECTIVES, sectionObject.IsCompleted, isValidObjectivesToPass);

          $scope.countValids($scope.isValidObjectives, 'objectives');
        };

        $scope.listeners = () => {
          /**
*  Validates every update on object
*/
          const objectivesSetWatcher = $scope.$watchCollection('ObjectivesSet', () => {
            // will set the purpose on service
            loanAppSharedData.loanAppPurpose = $scope.ObjectivesSet.PrimaryPurpose;
            $scope.validateObjectives();
          });

          const listPurposeWatcher = $scope.$watchCollection('listPurpose', () => {
            $scope.validateObjectives();
          });

          const listLendersWatcher = $scope.$watchCollection('listLenders', () => {
            $scope.validateObjectives();
          });

          const listLoanFeaturesWatcher = $scope.$watchCollection('listLoanFeatures', () => {
            $scope.validateObjectives();
          });

          const fundsAvailableWatcher = $scope.$watchCollection('fundsAvailable', () => {
            $scope.validateObjectives();
          });

          $scope.$on('$destroy', () => {
            if (objectivesSetWatcher) objectivesSetWatcher();
            if (listPurposeWatcher) listPurposeWatcher();
            if (listLendersWatcher) listLendersWatcher();
            if (listLoanFeaturesWatcher) listLoanFeaturesWatcher();
            if (fundsAvailableWatcher) fundsAvailableWatcher();
          });
        };

        /**
*  ff. are question id for each sections
*  Primary Purpose = 5
Deposit Source = 6
Deposit Amount = pending
Preferred Lenders = 7
Loan Features = 8
Specific Goals/Objectives = 9
Any previous impairements/bankruptcy? = 10 / SubQuestion = 11
Arrears in current debt? = 12 / SubQuestion = 13
Any Foreseable changes in circumstance? = 14 / SubQuestion = 15
Guaranteeing any other loan = 16 / SubQuestion = 17
Any Risk Insurance? = 18 / SubQuestion = 19
Any Home/Contents Insurance? = 20 / SubQuestion = 21
*/

        $scope.getQuestion = () => {
          optionsService.question('Objectives').then((response) => {
            $scope.objectiveQuestions = response.data;
          });
        };

        $scope.DisplayOptionList = [
          { Id: 1, Type: 'answerBool', Value: 'YesNoOnly' },
          { Id: 2, Type: 'answerString', Value: 'TextOnly' },
          { Id: 3, Type: 'answerString', Value: 'TextboxOnYes' },
          { Id: 4, Type: 'answerString', Value: 'TextboxOnNo' },
          { Id: 5, Type: 'answerInt', Value: 'SingleDropDown' },
          { Id: 6, Type: 'answerInt', Value: 'MultiDropDown' },
          { Id: 7, Type: 'answerInt', Value: 'SingleDropDownNoFrame' },
          { Id: 8, Type: 'answerBool', Value: 'CheckBox' },
          { Id: 9, Type: 'answerString', Value: 'TextboxWithDropdown' },
          { Id: 10, Type: 'answerDecimal', Value: 'TextFloat' },
        ];

        $scope.getLoanObjectives = () => {
          loanApplicationServices.getLoanObjectives($scope.loanAppId).then((response) => {
            if (!response || !response.data) return;
            const loanObjectivesGet = response.data;
            const listPurpose = [];
            const listLenders = [];
            const listLoanFeatures = [];
            $scope.isStartedObjectives = loanObjectivesGet && loanObjectivesGet.length;
            $scope.loanObjectivesData = loanObjectivesGet;

            objectivesQuestionService.setObjectives(loanObjectivesGet);

            _.forEach(loanObjectivesGet, (obj) => {
              switch (obj.QuestionId) {
              case 5:
                _.forEach($scope.LoanPurposeList, (o) => {
                  const isAnswerFound = _.find(obj.answerInt, (ans) => {
                    return parseInt(ans, 10) === parseInt(o.Value, 10);
                  });
                  if (isAnswerFound) {
                    o.Id = o.Value;
                    listPurpose.push(o);

                    o.show = false;
                  } else {
                    o.show = true;
                  }
                });
                $scope.listPurpose = listPurpose;
                break;
                // @TODO : Put other cases here
              case 6:
                $scope.ObjectivesSet.DepositSource = _.last(obj.answerInt).toString();
                break;
              case 37:
                $scope.ObjectivesSet.depositAmount = _.last(obj.answerDecimal);
                break;
              case 7:
                _.forEach($scope.loanProviderLendersList, (o) => {
                  if (_.find(obj.answerInt,
                    (ans) => {
                      return parseInt(ans, 10) === parseInt(o.ProviderId, 10);
                    })) {
                    o.Id = o.ProviderId;
                    listLenders.push(o);
                    o.show = false;
                  } else {
                    o.show = true;
                  }
                });
                $scope.listLenders = listLenders;
                break;
              case 8:

                _.forEach($scope.LoanFeaturesList, (o) => {
                  if (_.find(obj.answerInt,
                    (ans) => {
                      return parseInt(ans, 10) === parseInt(o.Value, 10);
                    })) {
                    o.Id = o.Value;
                    listLoanFeatures.push(o);

                    o.show = false;
                  } else {
                    o.show = true;
                  }
                });

                $scope.listLoanFeatures = listLoanFeatures;
                break;
              case 9:
                $scope.ObjectivesSet.SpecificGoalsObjectives = _.last(obj.answerString);
                break;

                // with subquestions
              case 10:
                $scope.ObjectivesSet.Bankrupt = _.last(obj.answerBool);
                break;
              case 11:
                $scope.ObjectivesSet.BankruptNote = _.last(obj.answerString);
                break;
              case 12:
                $scope.ObjectivesSet.CurrentDebt = _.last(obj.answerBool);
                break;
              case 13:
                $scope.ObjectivesSet.CurrentDebtNote = _.last(obj.answerString);
                break;
              case 14:
                $scope.ObjectivesSet.Circumstance = _.last(obj.answerBool);
                break;
              case 15:
                $scope.ObjectivesSet.CircumstanceNote = _.last(obj.answerString);
                break;
              case 16:
                $scope.ObjectivesSet.OtherLoan = _.last(obj.answerBool);
                break;
              case 17:
                $scope.ObjectivesSet.OtherLoanNote = _.last(obj.answerString);
                break;
              case 18:
                $scope.ObjectivesSet.RiskInsurance = _.last(obj.answerBool);
                break;
              case 19:
                $scope.ObjectivesSet.RiskInsuranceNote = _.last(obj.answerString);
                break;
              case 20:
                $scope.ObjectivesSet.HomeInsurance = _.last(obj.answerBool);
                break;
              case 21:
                $scope.ObjectivesSet.HomeInsuranceNote = _.last(obj.answerString);
                break;
              case 43:
                $scope.ObjectivesSet.WhatAreYouWantingToDo = _.last(obj.answerString);
                break;
              case 44:
                $scope.ObjectivesSet.ImportantDates = _.last(obj.answerBool);
                break;
              case 45:
                $scope.ObjectivesSet.ImportantDatesNote = _.last(obj.answerString);
                break;
              case 46:
                $scope.ObjectivesSet.BankingWith = _.last(obj.answerString);
                break;
              case 47:
                $scope.ObjectivesSet.HappyWithCurrentBank = _.last(obj.answerBool);
                break;
              case 48:
                $scope.ObjectivesSet.HappyWithCurrentBankNote = _.last(obj.answerString);
                break;
              case 108:
                $scope.ObjectivesSet.ReasonForTopUp = _.last(obj.answerInt);
                break;
              case 109:
                $scope.ObjectivesSet.BankToRefinanceFrom = String(_.last(obj.answerInt));
                break;
              case 122:
                $scope.ObjectivesSet.directorForYears = _.last(obj.answerBool);
                break;
              case 123:
                $scope.ObjectivesSet.directorForYearsNote = _.last(obj.answerString);
                break;
              default:
                break;
              }
            });
          });
        };
        // place all the value that you want to turn off on module click
        $scope.eventModuleGlobal = () => {
          $scope.isDisplayAddSource = false;
        };

        $scope.showDepositAddSource = (e) => {
          e.stopPropagation();
          $scope.isDisplayAddSource = true;
        };

        $scope.getFundsAvailable = () => {
          // Let's get the funds first for easier object manipulation
          fundingCalculatorService.getFundingCalculatorFunds($scope.securityBrokerEventId).then((fundsResult) => {
            // These funds will be used after parameters, using FUNDING_REQUIRED FundingType
            const fundsData = fundsResult;
            // remove the funds available types
            _.remove(fundsData, (fund) => {
              return fund.fundingType === 'FUNDING_REQUIRED';
            });

            $scope.fundsAvailable = fundsData;

            $rootScope.$broadcast('REFRESH_FUNDS_AVAILABLE_SECTION', { fundsAvailable: $scope.fundsAvailable, isNew: false });
          });
        };
        $scope.fundsAvailableInit = () => {
          $scope.depositSourceObj = {
            type: null,
            amount: null,
          };
          loanScenarioService.getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId).then((cusObjectivesCalcBrokerEventResponse) => {
            $scope.securityBrokerEventId = cusObjectivesCalcBrokerEventResponse.data;
            $scope.getFundsAvailable();
          });
        };
        $scope.fundsAvailableInit();

        const refreshObjectivesDepositSectionWatcher = $rootScope.$on('REFRESH_OBJECTIVES_DEPOSIT_SECTION', (e, args) => {
          if (!args.isNew && args.fundsAvailable)
            $scope.fundsAvailable = args.fundsAvailable;
          else
            $scope.getFundsAvailable();
        });
        $scope.$on('$destroy', () => {
          if (refreshObjectivesDepositSectionWatcher) refreshObjectivesDepositSectionWatcher();
        });

        $scope.addDepositSource = () => {
          const fundingSet = {
            brokerEventId: $scope.securityBrokerEventId,
            id: 0,
            fundingType: 'FUNDS_AVAILABLE',
            fundingParticulars: $scope.depositSourceObj.type,
            fundingValue: $scope.depositSourceObj.amount,
            description: $scope.depositSourceObj.description,
            isOnlyValueEditable: false,
          };

          fundingCalculatorService.setFundingCalculatorFunds(fundingSet).then(() => {
            $scope.getFundsAvailable();
            $rootScope.$broadcast('getFundingCalculatorFundsSummary');
            $scope.isDisplayAddSource = false;
            $scope.depositSourceObj = {};
          });
        };

        $scope.updateObjective = (QuestionId, QuestionType, QuestSectionId, answer) => {
          let QuestionSectionId = 0;

          if (QuestSectionId) {
            QuestionSectionId = QuestSectionId;
          }

          const selectedOption = _.find($scope.DisplayOptionList, (o) => { return o.Value === QuestionType; });
          const postObject = {
            LoanScenarioId: $scope.loanAppId,
            FamilyId: $scope.familyId,
            QuestionSectionId,
            QuestionId,
            DisplayOptionId: selectedOption.Id,
          };

          switch (selectedOption.Type) {
          case 'answerString':
            postObject.answerString = [answer];
            break;
          case 'answerBool':
            postObject.answerBool = [answer];
            break;
          case 'answerInt':
            // Multiple
            if (selectedOption.Id === 6) {
              postObject.answerInt = [];
              _.forEach(answer, (o) => {
                postObject.answerInt.push(parseInt(o.Id, 10));
              });
            } else {
              postObject.answerInt = [answer];
            }
            break;
          case 'answerDecimal':
            postObject.answerDecimal = [answer];
            break;
          default:
            break;
          }

          loanApplicationServices.postLoanObjectives(postObject).then(() => {
          });
        };

        $scope.objectivesInit = () => {
          $scope.ObjectivesSet = {};
          $scope.LoanPurposeList = [];
          $scope.TopUpList = [];
          $scope.loanProviderLendersList = [];
          $scope.listPurpose = [];
          $scope.listLenders = [];
          $scope.listLoanFeatures = [];
          $scope.ObjectivesSet.depositAmount = [];
          $scope.objectiveQuestions = [];

          optionsService.loanpurpose().then((response) => {
            $scope.LoanPurposeList = response.data;

            _.map($scope.LoanPurposeList, (o) => {
              o.show = true;
              return o;
            });
            $scope.getLoanObjectives();
          });

          optionsService.TopUpTypeGet().then((response) => {
            $scope.TopUpList = response.data;
          });

          optionsService.DepositType().then((response) => {
            $scope.DepositSourceList = response.data;
          });

          optionsService.LoanFeaturesGet().then((response) => {
            _.map(response.data, (o) => {
              o.Value = parseInt(o.Value, 10);
              o.show = true;

              return o;
            });

            $scope.LoanFeaturesList = response.data;
            $scope.getLoanObjectives();
          });

          const lenderPlace = 1;
          contactService.lenderListGet(null, lenderPlace).then((response) => {
            $scope.bankToRefinanceLendersList = response.data;
            $scope.loanProviderLendersList = response.data;
            $scope.loanProviderLendersList.unshift({ ProviderId: '-2', ProviderName: 'No Preferred Lenders' });

            _.map($scope.loanProviderLendersList, (o) => {
              o.show = true;
              return o;
            });

            $scope.getQuestion();
            $scope.getLoanObjectives();
          });
        };

        $scope.objectivesInit();

        $scope.getFilterData = () => {
          $scope.filtersParamtersList = {};
          $scope.loanProviderLendersListGetAll = [];
          $scope.loanProviderLendersListSet = [];

          // This is test, to be removed
          loanProfilerService.getServiceabilityFiltersParamters().then((response) => {
            $scope.filtersParamtersList = response.data;
            angular.forEach(response.data.Lenders, (item) => {
              const length = 7;
              const trimmedString = item.Name.length > length ? `${item.Name.substring(0, 3)}...` : item.Name;
              if (item.Value !== 'FavouriteLenders') {
                $scope.loanProviderLendersListGetAll.push({
                  icon: `<img src="/assets/images/lenderLogos/AU/${item.Value}.png"/>`,
                  name: item.Name,
                  shortName: trimmedString,
                  value: item.Value,
                  ticked: false,
                });
              }
            });
          });
        };
        $scope.purposeHasType = (type) => {
          return !!_.find($scope.listPurpose, (o) => {
            return (o.Name && o.Name.toLowerCase().indexOf(type.toLowerCase()) > -1);
          });
        };

        $scope.Settings = {
          noLender: false,
        };
        const listLendersWatcher = $scope.$watch('listLenders', (nv) => {
          if (_.find(nv, (o) => { return o.ProviderId === '-2'; })) {
            $scope.Settings.noLender = true;

            _.forEach(nv, (otherLenders) => {
              if (!_.isUndefined(otherLenders) && otherLenders.ProviderId !== '-2') {
                $scope.removeLender(otherLenders);
              }
            });
          } else {
            $scope.Settings.noLender = false;
          }
        }, true);
        $scope.$on('$destroy', () => {
          if (listLendersWatcher) listLendersWatcher();
        });

        $scope.selectLender = () => {
          $scope.ObjectivesSet.PreferredLender.Id = $scope.ObjectivesSet.PreferredLender.ProviderId;
          $scope.listLenders.push($scope.ObjectivesSet.PreferredLender);

          const foundLenderAtList = _.find($scope.loanProviderLendersList, { Id: $scope.ObjectivesSet.PreferredLender.Id });
          _.map($scope.loanProviderLendersList, (obj) => {
            if (obj.Id === foundLenderAtList.Id) {
              obj.show = false;
            }
            return obj;
          });

          $scope.ObjectivesSet.PreferredLender = '';

          $scope.updateObjective(7, 'MultiDropDown', 0, $scope.listLenders);
        };

        $scope.removeLender = (lender) => {
          _.remove($scope.listLenders, (o) => {
            return _.isEqual(o, lender);
          });
          const foundLenderAtList = _.find($scope.loanProviderLendersList, { Id: lender.Id });
          _.map($scope.loanProviderLendersList, (obj) => {
            if (obj.Id === foundLenderAtList.Id) {
              obj.show = true;
            }
            return obj;
          });
          $scope.updateObjective(7, 'MultiDropDown', 0, $scope.listLenders);
        };

        $scope.selectLoan = () => {
          $scope.ObjectivesSet.LoanFeatures.Id = $scope.ObjectivesSet.LoanFeatures.Value;

          $scope.listLoanFeatures.push($scope.ObjectivesSet.LoanFeatures);
          const foundObj = _.find($scope.LoanFeaturesList, { Id: $scope.ObjectivesSet.LoanFeatures.Id });
          _.map($scope.LoanFeaturesList, (obj) => {
            if (obj.Id === foundObj.Id) {
              obj.show = false;
            }
            return obj;
          });

          $scope.ObjectivesSet.LoanFeatures = '';

          $scope.updateObjective(8, 'MultiDropDown', 0, $scope.listLoanFeatures);
        };

        $scope.removeLoan = (loan) => {
          _.remove($scope.listLoanFeatures, (o) => {
            return _.isEqual(o, loan);
          });
          const foundObj = _.find($scope.LoanFeaturesList, { Id: loan.Id });
          _.map($scope.LoanFeaturesList, (obj) => {
            if (obj.Id === foundObj.Id) {
              obj.show = true;
            }
            return obj;
          });
          $scope.updateObjective(8, 'MultiDropDown', 0, $scope.listLoanFeatures);
        };

        $scope.selectPurpose = () => {
          $scope.ObjectivesSet.PrimaryPurpose.Id = $scope.ObjectivesSet.PrimaryPurpose.Value;
          $scope.listPurpose.push($scope.ObjectivesSet.PrimaryPurpose);
          const foundObj = _.find($scope.LoanPurposeList, { Id: $scope.ObjectivesSet.PrimaryPurpose.Id });
          _.map($scope.LoanPurposeList, (obj) => {
            if (obj.Id === foundObj.Id) {
              obj.show = false;
            }
            return obj;
          });

          $scope.ObjectivesSet.PrimaryPurpose = '';

          $scope.updateObjective(5, 'MultiDropDown', 0, $scope.listPurpose);
        };

        $scope.removePurpose = (purpose) => {
          _.remove($scope.listPurpose, (o) => {
            return _.isEqual(o, purpose);
          });
          const foundObj = _.find($scope.LoanPurposeList, { Id: purpose.Id });
          _.map($scope.LoanPurposeList, (obj) => {
            if (obj.Id === foundObj.Id) {
              obj.show = true;
            }
            return obj;
          });
          $scope.updateObjective(5, 'MultiDropDown', 0, $scope.listPurpose);

          $scope.brokerNotes.getLoanObjectives();
        };

        $scope.deleteDeposit = (fundsId) => {
          fundingCalculatorService.deleteFundingCalculatorFunds(fundsId).then(() => {
            $scope.getFundsAvailable();
            $rootScope.$broadcast('getFundingCalculatorFundsSummary');
          });
        };

        $scope.objectivesQuestionUpdate = () => {
          $scope.validateObjectives();
        };

        $scope.listeners();
      };

      const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.CUSTOMER_OBJECTIVES);
      if (typeof sectionObject.IsCompleted === 'undefined') {
        $scope.processCustomerObjectives();
      } else {
        $scope.isValidObjectives = sectionObject.IsCompleted;
        const { LoanObjectives } = loanAppSharedData.loanAppSummary;
        $scope.isStartedObjectives = LoanObjectives && LoanObjectives.length;

        if ($scope.isStartedObjectives) {
          const loanListQuestionObject = returnLoanListQuestionObject();
          const preferredLendersQuestionObject = returnPreferredLendersQuestionObject();

          if (loanListQuestionObject) {
            populateLoanListOnLoad(loanListQuestionObject);
          }

          if (preferredLendersQuestionObject) {
            populatePreferredLendersOnLoad(preferredLendersQuestionObject);
          }
        }
      }
    });
  });
