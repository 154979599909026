import angular from 'angular';

angular.module('app').controller('EducationCtrl', function EducationCtrl(
  $uibModal,
  loanToolsService,
  SweetAlert
) {
  const vm = this;

  // Models
  vm.cpdList = [];

  const openEducationModal = (isAdd, educationIdForOpeningEdit) => {
    const modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: '/assets/views/modals/resource/educationModal.html',
      controller: 'EducationModalCtrl',
      controllerAs: 'modal',
      windowClass: 'education-modal-class',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        educationId: () => (!isAdd ? educationIdForOpeningEdit : null),
      },
    });

    modalInstance.result.then((newSetOfCpd) => {
      vm.cpdList = null;
      vm.cpdList = newSetOfCpd;
    });
  };

  const populateCpdList = () => {
    vm.cpdList = null;

    loanToolsService.resourceEducationGet(0).then((response) => {
      vm.cpdList = response.data;
    });
  };

  const editCpd = (educationId) => {
    openEducationModal(false, educationId);
  };

  const deleteEducation = (id) => {
    SweetAlert.swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, delete it!',
    }, (confirm) => {
      if (confirm) {
        loanToolsService.resourceEducationDelete(id).then(() => {
          populateCpdList();
        });
      }
    });
  };

  // function assignation
  vm.openEducationModal = openEducationModal;
  vm.deleteEducation = deleteEducation;
  vm.editCpd = editCpd;

  // Calling of init
  const init = () => {
    populateCpdList();
  };
  init();
});
