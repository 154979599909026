import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

const defaultActivityValues = {
  activityTypeList: [],
  isShowFilterBanner: false,
  totalNumberOfActivity: 0,
};

angular.module('app').controller('ShowActivityInfoModalCtrl',
  function ShowActivityInfoModalCtrl(
    $scope,
    $uibModalInstance,
    $filter,
    $timeout,
    FamilyId,
    brokerBrandingId,
    contactService,
    utilitiesService,
    $uibModal,
    generalService,
    $state,
    optionsService) {
    $scope.activityValues = { ...defaultActivityValues };

    angular.extend($scope, {
      activityList: [],
    });
    const cancelApplyFilterTimeout = () => {
      if (!$scope.applyFilterTimeout) return;

      $timeout.cancel($scope.applyFilterTimeout);
      $scope.applyFilterTimeout = null;
    };
    const setTotalNumberOfActivity = (value) => {
      $scope.activityValues.totalNumberOfActivity = value;
    };
    const setIsShowFilterBanner = (value) => {
      $scope.activityValues.isShowFilterBanner = value;
    };
    const assignTotalActivity = (adviserId, notificationTypeId, totalCount) => {
      const isZeroFilterAndActivity = !adviserId && !notificationTypeId && !$scope.activityValues.totalNumberOfActivity;
      const totalActivity = (isZeroFilterAndActivity && totalCount) || $scope.activityValues.totalNumberOfActivity;
      setTotalNumberOfActivity(totalActivity);
    };
    angular.extend($scope, {
      RecentActivityListFullDetailsGet(adviserId = 0, notificationTypeId = 0) {
        contactService.RecentActivityListFullDetailsGet(FamilyId, brokerBrandingId, adviserId, notificationTypeId)
          .then((response) => {
            const { data } = response;

            if (!data) return;

            $scope.activityList = data;
            if ($scope.activityList.ClientName) {
              $scope.activityList.ClientName = $scope.activityList.ClientName.split(',');
            }
            assignTotalActivity(adviserId, notificationTypeId, $scope.activityList.TotalCount);
          });
      },
      separateCapital(string) {
        return string.replace(/([a-z])([A-Z])/g, '$1 $2');
      },
      filterInitial(string) {
        return utilitiesService.filterInitialOneString(string);
      },
      filterTimeStamp(time) {
        return moment(time).format('hh:mma');
      },
      checkIfDateToday(ActivityGroupDay, ActivityGroupDate) {
        return (_.isEqual(moment(ActivityGroupDate).format('dd MMM yyyy'), moment(new Date()).format('dd MMM yyyy')) ? 'today' : ActivityGroupDay);
      },
      cancel() {
        $uibModalInstance.dismiss('cancel');
      },
    });
    $scope.RecentActivityListFullDetailsGet();

    $scope.viewTaskModal = function (id) {
      $scope.task_id = id;
      $uibModal.open({
        templateUrl: '/assets/views/tasks/modals/add.html',
        controller: 'ViewTaskModalCtrl',
        windowClass: 'edit-task-window',
        size: 'md',
        scope: $scope,
        resolve: {
          id() {
            return $scope.task_id;
          },
          type() {
            return 'view';
          },
        },
      });
    };
    const initFilters = () => {
      $scope.filter = {
        adviserSelected: 0,
        activityTypeSelected: 0,
      };
    };
    $scope.clearFilters = () => {
      initFilters();
    };
    $scope.applyFilter = (adviserSelected, activityTypeSelected) => {
      cancelApplyFilterTimeout();
      $scope.applyFilterTimeout = $timeout(() => {
        setIsShowFilterBanner(true);
        $scope.RecentActivityListFullDetailsGet(adviserSelected, activityTypeSelected);
      }, 300);
    };
    const populateAdviserList = () => {
      if (!$state.params.familyId) return;

      optionsService.getActivityAdvisers($state.params.familyId)
        .then((brokerAllResponse) => {
          const { data } = brokerAllResponse;
          if (!data) return;

          $scope.brokerList = [{
            Value: 0,
            Name: 'All Advisers',
          },
          ...data];
        });
    };

    const populateActivityTypeList = () => {
      if (!$state.params.familyId) return;

      optionsService.getActivityType($state.params.familyId)
        .then((activityTypleResponse) => {
          const { data } = activityTypleResponse;
          if (!data) return;

          $scope.activityValues.activityTypeList = [{
            Value: 0,
            Name: 'All Types',
          },
          ...data];
        });
    };
    const init = () => {
      initFilters();
      populateAdviserList();
      populateActivityTypeList();
    };
    init();

    $scope.$on('$destroy', () => {
      cancelApplyFilterTimeout();
    });
  });
