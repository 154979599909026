import angular from 'angular';

angular.module('app').directive('format', function format($filter) {
  return {
    require: '?ngModel',
    link(scope, elem, attrs, ctrl) {
      if (!ctrl) return;

      ctrl.$formatters.unshift(() => {
        return $filter(attrs.format)(ctrl.$modelValue);
      });

      ctrl.$parsers.unshift((viewValue) => {
        const plainNumber = viewValue.replace(/[^\d|\-+|.+]/g, '');
        elem.val($filter(attrs.format)(plainNumber));
        return plainNumber;
      });
    },
  };
});
