import angular from 'angular';

angular.module('app').factory('SendReferralModel', function sendReferralModel(httpRequest) {
  const apiBaseUrl = 'Referral/';
  const apiCorporateBaseUrl = 'corporate/';
  const apiContactsBaseURL = 'contacts/';

  function SendReferralModel() {}

  SendReferralModel.prototype = {
    getReferralAdviserOrgMemberList(referrerId, adviserOrgId, callback) {
      httpRequest.get(`${apiBaseUrl}ReferralAdviserOrgMemberList?referrerId=${referrerId}&adviserOrgId=${adviserOrgId}`, (response) => {
        return callback(response);
      });
    },
    referralAdviserOrgList(brandId, familyId, callback) {
      httpRequest.get(`${apiBaseUrl}ReferralAdviserOrgList?brandingCatergoryId=${brandId}&adviserId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    getStatusList(callback) {
      httpRequest.get(`${apiBaseUrl}StatusList`, (response) => {
        return callback(response);
      });
    },
    getAdvisersList(pFamilyID, pSearchSameAdviserOrg, callback) {
      httpRequest.get(`GetAdvisersList?pFamilyID=${pFamilyID}&pSearchSameAdviserOrg=${pSearchSameAdviserOrg}`, (response) => {
        return callback(response);
      });
    },
    rayWhiteOrganisationGet(size, from, callback) {
      httpRequest.get(`${apiCorporateBaseUrl}RayWhiteOrganisationsGet?size=${size}&from=${from}`, (response) => {
        return callback(response);
      });
    },
    rayWhiteAdvisersGet(orgId, size, from, callback) {
      httpRequest.get(`${apiCorporateBaseUrl}RayWhiteAdvisersGet?orgId=${orgId}&size=${size}&from=${from}`, (response) => {
        return callback(response);
      });
    },
    searchClientGet(searchString, partyType, callback) {
      const partyTypeQuery = (typeof partyType !== 'undefined' ? `&partyType=${partyType}` : '');
      httpRequest.get(`SearchClientGet?searchString=${searchString}${partyTypeQuery}`, (response) => {
        return callback(response);
      });
    },
    referralReferralTypeGet(callback) {
      httpRequest.get(`${apiBaseUrl}ReferralReferralTypeGet`, (response) => {
        return callback(response);
      });
    },
    referralReferralListGet(queryFilter, callback) {
      let query = '';
      query += (queryFilter.status ? `status=${queryFilter.status}` : '');
      query += (queryFilter.sendTo ? `sendTo=${queryFilter.sendTo}` : '');
      query += `&pageNumber=${queryFilter.pageNumber}`;
      query += `&pageSize=${queryFilter.pageSize}`;
      query += `&referralTypeId=${queryFilter.referralTypeId}`;
      query += `&adviserName=${queryFilter.adviserName}`;

      httpRequest.get(`${apiBaseUrl}ReferralReferralListGet?${query}`, (response) => {
        return callback(response);
      });
    },
    referralSendToListGet(referralTypeID, callback) {
      httpRequest.get(`${apiBaseUrl}ReferralSendToListGet?referralTypeID=${referralTypeID}`, (response) => {
        return callback(response);
      });
    },
    referralRayWhiteSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ReferralRayWhiteSet`, $postData, (response) => {
        return callback(response);
      });
    },
    referralUKPensionSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ReferralUKPensionSet`, $postData, (response) => {
        return callback(response);
      });
    },
    referralFnGSet($postData, callback, errorCallback) {
      httpRequest.post(`${apiBaseUrl}ReferralFnGSet`, $postData, (response) => {
        return callback(response);
      }, (err)=>{
        return errorCallback(err);
      });
    },
    referralOFXSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ReferralOFXSet`, $postData, (response) => {
        return callback(response);
      });
    },
    referralXESet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ReferralXESet`, $postData, (response) => {
        return callback(response);
      });
    },
    referralWealthMarketSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ReferralWealthMarketSet`, $postData, (response) => {
        return callback(response);
      });
    },
    referralLoanMarketSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ReferralLoanMarketSet`, $postData, (response) => {
        return callback(response);
      });
    },
    referralOtherSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ReferralOtherSet`, $postData, (response) => {
        return callback(response);
      });
    },
    geoCodeSearch(search, callback) {
      httpRequest.get(`${apiContactsBaseURL}GeoCodeSearch?address=${search}`, (response) => {
        return callback(response);
      });
    },
    addressDetailsSet(postData, callback) {
      httpRequest.post('AddressDetailsSet', postData, (response) => {
        return callback(response);
      });
    },
  };
  return SendReferralModel;
});
