import template from './insurancePipelineCardMenu.html';
import controller from './insurancePipelineCardMenuCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    clientId: '<',
    clientName: '<',
    labels: '<',
    selectedLabelIds: '<',
    doForceToMainMenu: '<',
    onAddLabel: '&',
    onUpdateLabel: '&',
    onDeleteLabel: '&',
    onAddLabelToCard: '&',
    onDeleteLabelFromCard: '&',
    onViewClient: '&',
  },
};
