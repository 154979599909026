// TODO: move to loanScenarioService
import { factFindHeadingsBuilderForUI } from 'Common/mappers/factFind';

class LoanApplicationServices {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'LoanScenario';
    this.defaultOptions = [null, true, false];
  }

  getEmploymentType() {
    // TODO: move to optionsService
    return this.httpClient.get('options/General/EmploymentTypeGet', ...this.defaultOptions);
  }

  getEmploymentStatus() {
    // TODO: move to optionsService
    return this.httpClient.get('options/General/EmploymentStatusGet', ...this.defaultOptions);
  }

  getEmploymentBasis() {
    // TODO: move to optionsService
    return this.httpClient.get('options/General/EmploymentBasisGet', ...this.defaultOptions);
  }

  getEmployerType() {
    // TODO: move to optionsService
    return this.httpClient.get('options/General/EmployerTypeGet', ...this.defaultOptions);
  }

  setEmployment(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/EmploymentSet`, data);
  }

  setApplicantClientEntityDetail(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ApplicantClientEntityDetailSet`, data);
  }

  getApplicantClientEntityDetail(loanScenarioId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicantClientEntityDetailGet`, { loanScenarioId });
  }

  getEmployment(loanApplicationId, employmentId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicantEmployment`, { loanApplicationId, employmentId });
  }

  deleteEmployment(employmentId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/EmploymentDelete`, { employmentId });
  }

  deleteAddress(addressValuationExtendedDetailsId, familyId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/AddressDelete`, { addressValuationExtendedDetailsId, familyId });
  }

  getDepositType() {
    // TODO: move to optionsService
    return this.httpClient.get('options/LoanApplication/DepositType', ...this.defaultOptions);
  }

  getLoanDeposit(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanDepositGet`, { loanApplicationId });
  }

  setLoanDeposit(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanDepositSet`, data);
  }

  getLoanDepositBreakDown(loanApplicationId, depositId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanDepositBreakDownGet`, { loanApplicationId, depositId });
  }

  setLoanDepositBreakDown(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanDepositBreakDownSet`, data);
  }

  deleteLoanDepositBreakDown(loanScenarioId, depositBreakDownId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LoanDepositBreakDownDelete`, { loanScenarioId, depositBreakDownId });
  }

  getLoanSecurity(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanSecurityGet`, { loanApplicationId });
  }

  getLoanSecurityInfo(loanApplicationId, loanSecurityInfoId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanSecurityInfoGet`, { loanApplicationId, loanSecurityInfoId });
  }

  getLoanSecurityMortgage() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanSecurityMortgageGet`);
  }

  setLoanSecurityMortgage(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanSecurityMortgageSet`, data);
  }

  setLoanSecurityInfo(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanSecurityInfoSet`, data);
  }

  setLoanSecurity(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanSecuritySet`, data);
  }

  deleteLoanSecurityMortgage(loanApplicationId, SecurityMortgageId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LoanSecurityMortgageDelete`, { loanApplicationId, SecurityMortgageId });
  }

  deleteLoanSecurityInfo(loanApplicationId, securityInfoId, fundingCalParametersID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LoanSecurityInfoDelete`, { loanApplicationId, securityInfoId, fundingCalParametersID });
  }

  getAvailableDocumentList(loanScenarioId, searchName, count = 100, pageNumber = 1) {
    return this.httpClient.get(`${this.apiBaseUrl}/AvailableDocumentListGet`, { loanScenarioId, searchName, count, pageNumber });
  }

  getDocumentList(loanScenarioId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Documents`, { loanScenarioId });
  }

  getDocumentDetails(loanScenarioId, documentId, thumbWidth, generateThumbnail) {
    return this.httpClient.get(`${this.apiBaseUrl}/DocumentDetailsGet`, {
      loanScenarioId,
      documentId,
      thumbWidth,
      generateThumbnail,
    });
  }

  getDocumentPageCount(documentId) {
    return this.httpClient.get(`${this.apiBaseUrl}/DocumentPageCountGet`, { documentId });
  }

  getIdentificationDocumentsType() {
    // TODO: move to optionsService
    return this.httpClient.get('options/General/IdentificationDocumentsTypeGet', ...this.defaultOptions);
  }

  setDocument(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/DocumentSet`, data);
  }

  setDocumentPage(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/DocumentPageSet`, data);
  }

  deleteDocument(loanScenarioId, documentId, loanScenarioDocumentId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/Documents/${loanScenarioDocumentId}`, { loanScenarioId, documentId });
  }

  getImportantDate(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ImportantDateGet`, { loanApplicationId });
  }

  putImportantDate(loanApplicationId, dateTypeId, date) {
    return this.httpClient.put(`${this.apiBaseUrl}/ImportantDate`, {}, { loanApplicationId, dateTypeId, date });
  }

  getLoanObjectives(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanObjectivesGet`, { loanApplicationId });
  }

  postLoanObjectives(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanObjectivesSet`, data);
  }

  cloneLoan(loanApplicationId, familyId) {
    return this.httpClient.post(`${this.apiBaseUrl}/CloneableResource/LoanApp/${loanApplicationId}`, {}, { familyId });
  }


  loanSummaryGet(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanSummary`, { loanApplicationId }, true);
  }

  postMultipleApplicants(param) {
    return this.httpClient.post(`${this.apiBaseUrl}/ScenarioMultiApplicantSet`, param);
  }

  partialShare(data, eMail, sms) {
    return this.httpClient.post(`${this.apiBaseUrl}/share/partial`, data, { userInitiated: true, eMail, sms });
  }

  getFactFindHeadings() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/fact-find-headings`)
      .then(response => response.data.map(factFindHeadingsBuilderForUI));
  }
}

export default LoanApplicationServices;
