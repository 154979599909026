class SelectRadioCtrl {
  $onInit() {
    if (!this.values || !this.values.length) {
      this.values = [];
    }
  }

  toggleRadio(obj) {
    if (obj && this.values.indexOf(obj) === -1) {
      this.values.push(obj);
    } else if (obj && this.values.indexOf(obj) !== -1) {
      const idx = this.values.indexOf(obj);
      this.values.splice(idx, 1);
    }

    this.valuesTextArray = [];
    this.options && this.options.forEach((optionsGroup) => {
      optionsGroup.map((option) => {
        if (this.values.indexOf(this.options && option) !== -1) {
          option.show = true;
          this.valuesTextArray.push(option.PipelineStatus);
        }
        return option;
      });
      return optionsGroup;
    });
    this.valuesText = this.valuesTextArray.join(', ');

    if (obj) {
      this.onChange({ values: this.values });
    }
  }

  $onChanges(changes) {
    const { values } = changes;
    if (values) {
      this.toggleRadio();
    }
  }
}

export default SelectRadioCtrl;
