export function openDate(addressHistory, dateField) {
  if (addressHistory && dateField) {
    addressHistory.datePopUp[dateField] = !addressHistory.datePopUp[dateField];
  }
}

export function formatCustomAddress(addressHistory) {
  if (addressHistory && addressHistory.Address && addressHistory.Address.isCustomAddress) {
    const { street_address: streetAddress, locality, postal_code: postalCode, stateShort_Name: state } = addressHistory.Address;
    const local = locality ? `, ${locality}` : '';
    const zip = postalCode ? `, ${postalCode}` : '';
    const stateShortName = state ? `, ${state}` : '';
    addressHistory.Address.formatted_address = `${streetAddress}${local}${stateShortName}${zip}`;
  }
}
