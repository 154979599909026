import angular from 'angular';
import _ from 'lodash';
import { INSURANCE_LOAN_APP } from 'Common/constants/insuranceOptions';
import { LENDER_ID } from 'Common/constants/lenderConstants';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { getBorrowerFullName } from 'Common/utilities/loanApplication';

angular.module('app').factory('loanAppSharedData', function loanAppSharedData(
  loanApplicationServices,
  uiService,
  optionsService,
  loanScenarioService,
  utilitiesService,
  colorService,
  employmentService,
  loanAppPaymentAbilityService,
  loanAppDetailsService,
  $state,
  $q,
  $timeout
) {
  const factory = this;

  factory.loanAppPurpose = null;
  factory.Employment = {
    Income: [],
    EmploymentStatusId: '',
    EmploymentBasisId: '',
    EmploymentTypeId: '',
    EmployerTypeId: '',
    DateStarted: '',
    DateEnded: '',
    showEndDate: false,
    IsProbation: false,
    EmployerName: '',
    EmployerAddress: '',
    EmployerContactFirstName: '',
    EmployerContactLastName: '',
    EmployerNumber: '',
    List: {
      Employment: [],
    },
  };
  factory.employmentPersonList = [];
  factory.employmentContactList = [];
  factory.getEmploymentType = (action) => {
    loanApplicationServices.getEmploymentType()
      .then((response) => {
        factory.Employment.Types = response.data;
        if (!uiService.isCountry('New Zealand')) {
          factory.Employment.Types.unshift({ Name: 'Please select', Value: '' });
        } else if (factory.Employment.Types && factory.Employment.Types.length && action !== 'edit') {
          factory.Employment.EmploymentTypeId = factory.Employment.Types[0].Value;
        }
      });
  };
  factory.getEmploymentStatus = (action) => {
    loanApplicationServices.getEmploymentStatus()
      .then((response) => {
        if (!response.data) return;

        factory.Employment.PrevEmpId = response.data.filter(status => status.Name === 'Previous Employment')
          .reduce((accum, status) => parseInt(status.Value, 10), 0);
        if (employmentService.isCurrentEmploymentStatusShown()) {
          factory.Employment.StatusList = response.data.filter(status => status.Name !== 'Previous Employment');
        } else {
          factory.Employment.StatusList = response.data;
        }

        if (!uiService.isCountry('New Zealand')) {
          const isSelectPlaceholderPresent = _.find(factory.Employment.StatusList, { Name: 'Please select' });
          const { StatusList } = factory.Employment;
          factory.Employment.StatusList = isSelectPlaceholderPresent ? [...StatusList] : [{ Name: 'Please select', Value: '' }, ...StatusList];
        } else if (factory.Employment.StatusList && factory.Employment.StatusList.length && action !== 'edit') {
          factory.Employment.EmploymentStatusId = factory.Employment.StatusList[0].Value;
        }
      });
  };
  factory.getEmploymentBasis = (action) => {
    optionsService.getEmploymentBasis()
      .then((response) => {
        factory.Employment.BasisList = response.data;

        /**
         * Detect CamelCase and add space in between words
         * */
        angular.forEach(factory.Employment.BasisList, (val) => {
          const name = val.Name;
          val.Name = name.replace(/([a-z](?=[A-Z]))/g, '$1 ');
        });

        if (!uiService.isCountry('New Zealand')) {
          factory.Employment.BasisList.unshift({ Name: 'Please select', Value: '' });
        } else if (factory.Employment.BasisList && factory.Employment.BasisList.length && action !== 'edit') {
          factory.Employment.EmploymentBasisId = factory.Employment.BasisList[0].Value;
        }
      });
  };
  factory.getEmployerType = () => {
    loanApplicationServices.getEmployerType()
      .then((response) => {
        factory.Employment.EmployerTypeList = [{ Name: 'Please select', Value: '' }, ...response.data];
      });
  };
  factory.getFrequency = () => {
    optionsService.FinancialFrequency()
      .then((response) => {
        _.forEach(response.data, (freq) => {
          freq.Value = parseInt(freq.Value, 10);
          return freq;
        });
        factory.Employment.FrequencyList = [{ Name: 'Please select', Value: '' }, ...response.data];
      });
  };
  factory.depositGet = (loanAppId) => {
    return $q((resolve, reject) => {
      loanApplicationServices.getLoanDeposit(loanAppId)
        .then((response) => {
          const data = response.data;
          if (data) {
            resolve(data);
          } else {
            reject(data);
          }
        });
    });
  };
  factory.getIncomeType = () => {
    loanScenarioService.IncomeTypeGet()
      .then((response) => {
        factory.Employment.IncomeTypeList = [{ Name: 'Please select', Value: '' }, ...response.data];

        _.remove(factory.Employment.IncomeTypeList, (rmLoanAppItem) => {
          return rmLoanAppItem.GroupId !== '6' && rmLoanAppItem.GroupId !== '7' && rmLoanAppItem.GroupId !== '8' && rmLoanAppItem.GroupId !== '9' && rmLoanAppItem.GroupId !== '10';
        });
      });
  };
  factory.getIncomeVerificationType = () => {
    optionsService.IncomeVerificationType('employment').then((response) => {
      factory.incomeVerficationTypeList = response.data;
    });
  };
  factory.changeEmploymentOwnership = (paramPersonId, background) => {
    const personId = paramPersonId.toString();
    const selectedContact = _.filter(factory.employmentPersonList, (o) => {
      return o.PersonId === (!_.isUndefined(personId) ? personId : factory.employmentContactList.SelectedClient);
    });
    if (selectedContact.length > 0) {
      factory.employmentContactList.SelectedClient = selectedContact[0].PersonId;
      factory.employmentContactList.ClientFirstName = selectedContact[0].FirstName;
      factory.employmentContactList.ClientLastName = selectedContact[0].LastName;
      factory.employmentContactList.initials = utilitiesService.filterInitialOneString(selectedContact[0].FullName);
      if (background) {
        factory.employmentContactList.background = background;
      } else {
        factory.employmentContactList.background = colorService.getRandomColor();
      }
    }
  };
  factory.EmptyEmployment = () => {
    if (!factory.Employment) {
      factory.Employment = {};
    }
    factory.Employment.Income = [];
    factory.Employment.EmploymentStatusId = '';
    factory.Employment.EmploymentBasisId = '';
    factory.Employment.EmploymentTypeId = '';
    factory.Employment.EmployerTypeId = '';
    factory.Employment.DateStarted = '';
    factory.Employment.DateEnded = '';
    factory.Employment.showEndDate = false;
    factory.Employment.IsProbation = false;
    factory.Employment.EmployerName = '';
    factory.Employment.EmployerAddress = '';
    factory.Employment.EmployerContactFirstName = '';
    factory.Employment.EmployerContactLastName = '';
    factory.Employment.EmployerNumber = '';
  };
  factory.clearRoleLookup = () => {
    if (!factory.Employment) {
      factory.Employment = {};
    }
    factory.Employment.selectedLookup = '';
    factory.Employment.criteria = '';
    factory.Employment.EmploymentRole = '';
    factory.Employment.EmploymentRollID = '';
    factory.Employment.empRolesWrapper = {
      empRoles: [],
    };
    factory.Employment.empRoleCount = 0;
  };

  factory.getLoanAppApplicants = (loanAppId, refreshData) => {
    const isDifferentLoanApp = parseInt(loanAppId, 10) !== parseInt(factory.currentLoanAppId, 10);
    if (isDifferentLoanApp) {
      factory.clearLoanAppApplicants();
      factory.currentLoanAppId = loanAppId;
    }

    const isFetchingLoanApplicants = factory.loanAppApplicantsPromise;
    if (isFetchingLoanApplicants) return factory.loanAppApplicantsPromise;

    const isEmptyApplicants = !factory.loanAppApplicants;
    if (isEmptyApplicants || refreshData) {
      factory.loanAppApplicantsPromise = loanScenarioService.scenarioApplicantListGet(loanAppId).then((response) => {
        factory.loanAppApplicants = response && response.data;
        factory.loanAppApplicantsPromise = null;
        return response;
      });
      return factory.loanAppApplicantsPromise;
    } else {
      const deferred = $q.defer();
      deferred.resolve({ data: factory.loanAppApplicants });
      return deferred.promise;
    }
  };

  factory.clearLoanAppApplicants = () => {
    factory.loanAppApplicants = null;
    factory.loanAppApplicantsPromise = null;
    factory.currentLoanAppId = null;
  };

  factory.getLoanAppPaymentAbilities = (loanAppId, refreshData) => {
    return loanAppPaymentAbilityService.getPaymentAbility(loanAppId, refreshData);
  };

  factory.getScenarioDetails = (loanAppId, refreshData) => {
    return loanAppDetailsService.getLoanAppDetails(loanAppId, refreshData);
  };

  factory.clearSharedData = () => {
    factory.clearLoanAppApplicants();
    loanAppPaymentAbilityService.clearPaymentAbility();
    loanAppDetailsService.clearLoanAppDetails();
  };

  factory.lenderId = null;
  factory.documentListLenderWorksheet = []; // worksheet uploaded by user
  factory.worksheet = {}; // default worksheet from lender
  factory.isRefinance = null;
  factory.addressList = [];

  factory.LoanApplicationDetails = {};

  factory.UPDATE_EMPLOYMENT = {};

  factory.lendingTabPipelineStatusData = {};

  factory.refreshSecurityList = false;

  factory.borrowerList = [];
  // Insurance
  factory.insurance = {
    section: {
      excludeIds: [INSURANCE_LOAN_APP.QUESTIONS.CONCIERGE, INSURANCE_LOAN_APP.QUESTIONS.COMMENTS, INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE, INSURANCE_LOAN_APP.QUESTIONS.MORE_DETAILS],
    },
  };

  factory.isOverrideValidation = false;

  // Supporting Documents
  factory.documents = {
    documentStatusList: [
      { id: 1, StatusName: 'Included' },
      { id: 2, StatusName: 'Forthcoming' },
      { id: 3, StatusName: 'Not Included' },
    ],
    $findStatusName($id) { // private
      return _.find(factory.documents.documentStatusList, (status) => {
        return String(status.id) === String($id);
      });
    },
    fillStatusName($list) { // public
      _.each($list, (obj) => {
        const status = factory.documents.$findStatusName(obj.StatusId);

        if (typeof status === 'object') {
          obj.StatusName = status.StatusName;
        }
      });

      return $list;
    },
    getStatusName($id) { // public
      const status = factory.documents.$findStatusName($id);

      return typeof status === 'object' ? status.StatusName : '';
    },
  };

  factory.simpology = {
    bnzId: '24',
  };
  factory.applicantsLength = (applicantsList) => {
    let toReturnValue = 0;
    _.forEach(applicantsList, (object) => {
      toReturnValue += object.length;
    });
    return toReturnValue;
  };

  factory.securityOrAssetLenderChanged = (mortgage) => {
    if (!factory.isLenderOthers(mortgage.CreditorId)) {
      mortgage.CreditorNameOther = '';
    }
  };

  factory.isLenderOthers = (lenderId) => {
    return parseInt(lenderId, 10) === LENDER_ID.OTHERS || parseInt(lenderId, 10) === LENDER_ID.AU_OTHERS;
  };

  factory.completionStatusSet = (sectionID, currentIsComplete, isCompleted) => {
    const postData = {
      loanApplicationId: $state.params.loanAppId,
      sectionID,
      isCompleted,
    };


      const sectionObject = factory.getSectionObjectById(factory.completionListObject, SECTION_ID.APPLICANTS);
      sectionObject.IsCompleted = isCompleted;

      loanScenarioService.completionStatusSet(postData).then(() => {
      });

  };

  factory.getSectionObjectById = (completionListObject, sectionId) => {
    let toReturnValue = completionListObject.filter(object => object.SectionID === sectionId);
    if(toReturnValue && toReturnValue.length) {
      return toReturnValue[0];
    } else {
      return {};
    }

  };

  factory.populateBackgroundForApplicantSection = (applicantGuarantorList, getBGcolor) => {
   applicantGuarantorList.forEach((object) => {
      if(object.FamilyId) {
        object.initials = utilitiesService.filterInitial(object.FirstName, object.LastName) || utilitiesService.filterInitialOneString(object.FamilyFullName);
        object.background = getBGcolor(object.FamilyId) || getBGcolor(object.FamilyId);
        factory.backgroundList[object.FamilyId.toString()] = object.background;
      }
    });
    return applicantGuarantorList;
  };

  factory.distinguishPartyForLendingTab = (loans) => {
    if (!loans) return;
    const newLoans = loans.map((loan) => {
      const { Borrower } = loan;
      loan.Person = getBorrowerFullName(Borrower, false);
      loan.Guarantor = getBorrowerFullName(Borrower, true);
      return loan;
    });
    return newLoans;
  };

  factory.isCompletionSetLoanapp;
  factory.loanAppSummary = [];
  factory.completionListObject = [];
  factory.backgroundList = {} ;
  return factory;
});
