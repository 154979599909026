import angular from 'angular';
import { notesBuilderForUI } from 'Common/mappers/loanOpportunity';
import swal from 'sweetalert';

angular.module('app').controller('CreateNoteModalCtrl', function CreateNoteModalCtrl(
  $scope, $uibModalInstance, $stateParams, toaster, contactService, loanOpportunityService, familyId, note
) {
  function setNoteModel() {
    return {
      IsSticky: false,
      FamilyID: 0,
      ActivityTitle: '',
      ActivityDetails: '',
      CommunicationID: 0,
      ActivityOwner: '',
      ActivityDate: new Date(),
      AdviserName: '',
      AdviserId: '',
      IsReferrer: false,
      IsPrivate: true,
      IsNotification: false,
      NotificationSMS: false,
      NotificationEmail: false,
      ActivityType: '',
      Notes: '',
    };
  }

  $scope.setNote = setNoteModel();
  if ($stateParams.adviserOrgId) {
    $scope.setNote.FamilyID = 0;
    $scope.setNote.BrokerBrandingID = $stateParams.adviserOrgId;
  } else {
    $scope.setNote.FamilyID = familyId;
  }
  $scope.options = {
    language: 'en',
    removePlugins: 'toolbar',
    allowedContent: true,
    uiColor: '#DFD6E4',
    height: 200,
    resize_enabled: false,
  };
  $scope.setNote.IsSticky = note.IsSticky;
  $scope.setNote.IsOpportunity = note.IsOpportunity;
  $scope.setNote.ActivityTitle = note.Title;
  $scope.setNote.ActivityDetails = note.Detail;
  $scope.setNote.CommunicationID = note.CommunicationID;
  $scope.setNote.ActivityOwner = note.WrittenBy;
  $scope.setNote.ActivityDate = note.EventTime;
  $scope.setNote.AdviserName = note.AdviserName;
  $scope.setNote.AdviserId = note.AdviserID;
  $scope.setNote.IsReferrer = note.IsReferrer;
  $scope.setNote.IsPrivate = note.IsPrivate;
  $scope.setNote.IsNotification = note.IsNotification;
  $scope.setNote.NotificationSMS = note.NotificationSMS;
  $scope.setNote.NotificationEmail = note.NotificationEmail;
  $scope.setNote.ActivityType = note.ActivityType;
  $scope.setNote.Notes = note.Notes;

  $scope.saveCreateNote = () => {
    if (!$scope.setNote.ActivityTitle) {
      toaster.pop('warning', 'Invalid Note', 'Please create a valid note');
    } else {
      let service;
      let method;
      let params;
      if (!$scope.setNote.IsOpportunity) {
        service = contactService;
        method = 'noteSet';
        params = { note: $scope.setNote };
      } else {
        service = loanOpportunityService;
        method = 'updateLoanOpportunityNote';
        params = { note: notesBuilderForUI($scope.setNote), communicationId: $scope.setNote.CommunicationID };
      }
      service[method](params.note, params.communicationId || null)
        .then(() => {
          swal({
            title: 'Success',
            text: `Note has been successfully ${$scope.setNote.CommunicationID ? 'updated' : 'created'}.`,
            type: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
          if ($scope.$parent && $scope.$parent.getNotesList) {
            $scope.$parent.getNotesList();
          }
          $scope.cancel();
        });
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
});
