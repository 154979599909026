import angular from 'angular';

class AddressModalCtrl {
  constructor(
    $uibModalInstance,
    tempAddress,
    contactId,
    isOrg
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.tempAddress = tempAddress;
    this.contactId = contactId;
    this.isOrg = isOrg;
  }
}
angular.module('app').controller('AddressModalCtrl', AddressModalCtrl);
