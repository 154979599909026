import { objectLength } from 'Common/utilities/objectValidation';

class InputService {
  constructor(
  ) {
    this.concatValueList = {};
  }
  concatInputService(type, order, value) {
    const isOrderAlreadyExist = this.concatValueList[type] ? this.concatValueList[type].find(object => object.order === order) : false;
    if (isOrderAlreadyExist) {
      const indexOfDuplicateOrder = this.concatValueList[type].findIndex(object => object.order === order);
      this.concatValueList[type][indexOfDuplicateOrder] = { order, value };
    } else {
      this.concatValueList[type] = this.concatValueList[type] ? [...this.concatValueList[type], { order, value }] : [{ order, value }];
    }
    this.concatValueList[type].sort((a, b) => {
      return a.order - b.order;
    });
  }

  getConcatValue(type) {
    return objectLength(this.concatValueList) && this.concatValueList[type] && this.concatValueList[type]
      .map(object => object.value)
      .reduce((accumulator, currentValue) => `${accumulator} ${currentValue}`);
  }
}

export default InputService;
