

app.factory('errorMessageHandler', ['$q', function ($q) {
  var factory = this;

  /* Send Referral Messages */
  factory.sendReferralError = function (instance, objParam) {
    var errorMessageObj = {};
    switch (instance) {
    case 'clientInformGet-error':
      errorMessageObj = {
        title: 'Unable to Get Adviser Information',
        text: `There was an error getting Adviser Information. 
          Maybe due to slow connection or system maintenance issue. 
          Please contact administrator.`,
        type: 'error',
        showCancelButton: false,
      };
      break;

    case 'adviserInfoFetch-error':
      errorMessageObj = {
        title: 'Selected Adviser',
        text: "Unable to fetch selected adviser's information. " +
                        'Maybe due to slow connection or system maintenance issue. ' +
                        'Please contact administrator.',
        type: 'error',
        showCancelButton: false,
      };
      break;

    case 'userFetch-error':
      errorMessageObj = {
        title: 'Current User',
        text: 'Unable to fetch current user information. ' +
                        'Maybe due to slow connection or system maintenance issue. ' +
                        'Please contact administrator.',
        type: 'error',
        showCancelButton: false,
      };
      break;

    case 'wm-selectedContact':
      errorMessageObj = {
        title: 'Contact Details',
        text: 'Selected contact has no valid contact details (i.e. email or phone). You are unable to send this referral.',
        type: 'error',
        showCancelButton: false,
      };
      break;
    case 'referral-invalid-contactDetail':
      errorMessageObj = {
        title: 'Contact Details',
        text: 'Clients must have at least one valid phone number to be sent as a referral.',
        type: 'error',
        showCancelButton: false,
      };
      break;
    case 'adviserAgreement':
      errorMessageObj = {
        title: 'Adviser Agreement',
        text: `Adviser has no agreement with ${objParam.orgname}. You are unable to send this referral.`,
        type: 'error',
        showCancelButton: false,
      };
      break;

    case 'adviserPostal':
      errorMessageObj = {
        title: 'Selected Adviser',
        text: 'Adviser has no valid address postal code. You will not be able to send this referral.',
        type: 'error',
        showCancelButton: false,
      };
      break;

    case 'contactPostal':
      errorMessageObj = {
        title: "Contact's Address",
        text: 'Unable to send referral to a Contact with no postal code. You need to set Current Address type for this Contact.',
        type: 'error',
        showCancelButton: false,
      };
      break;

    case 'contactNoCurrentAddress':
      errorMessageObj = {
        title: "Contact's Address",
        text: 'Unable to send referral to a Contact with no Current Address. You need to set Current Address type with valid Postal Code for this Contact.',
        type: 'error',
        showCancelButton: false,
      };
      break;

    default:
      errorMessageObj = {
        title: 'Yikes!',
        text: 'Something went wrong! Please contact system administrator.',
        type: 'error',
        showCancelButton: false,
      };
      break;
    }
    swalPopUpHandler(errorMessageObj);
  };

  function swalPopUpHandler(messageObj) {
    swal(messageObj);
  }

  return factory;
}]);

