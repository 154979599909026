import { camelize } from 'Common/utilities/string';

export function featureBuilderForUI(features) {
  const featuresObj = {};
  features.forEach((feature) => {
    /*
    * Any string will be converted into camelCase to match our property standards
    */
    const prop = camelize(feature.Name);

    /*
    * Sample return: { opportunity: true, gmail: false }
    */
    featuresObj[prop] = feature.IsEnabled;
  });
  return featuresObj;
}

export function corporateTeamBuilderForUI(team) {
  return {
    corporateTeamId: team.CorporateTeamId,
    corporateTeam: team.CorporateTeam,
  };
}
export function brokerBasicInfoForUI(data) {
  return {
    brokerId: data.BrokerId,
    fullName: data.FullName,
    lastName: data.LastName,
    firstName: data.FirstName,
    middleName: data.MiddleName,
    preferredName: data.PreferredName,
    reportingStateId: data.ReportingStateId,
    isACLUser: data.IsACLUser,
    asicId: data.ASICID,
    email: data.Email.map((obj) => {
      return {
        emailAddress: obj.EmailAddress,
      };
    }),
    sourceFamilyID: data.SourceFamilyID,
  };
}
