import moment from 'moment';
import { toastError } from 'Common/utilities/alert';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';

const LIFE_OF_LOAN = '0';
const RATE_TYPE_FIXED = 'Fixed';
const ONE_YEAR = 12;
class loanAppDiscountsRateCtrl {
  constructor(
    loanProfilerService,
    loanScenarioService,
    contactService,
    optionsService
  ) {
    'ngInject';

    this.loanProfilerService = loanProfilerService;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.optionsService = optionsService;
  }

  $onInit() {
    this.getLoanTimePeriod();
    this.resetDicountRate();
  }

  $onChanges(changes) {
    const { productId, loanId, loanApplicationId, brokerEventId, numberOfProduct, isOnLoanDetailsModal } = changes;
    if (productId && productId.currentValue) {
      this.productId = productId.currentValue;
      this.getSelectedProductDetails();
      this.getLoanDetails();
    }
    if (loanId && loanId.currentValue) {
      this.loanId = loanId.currentValue;
    }
    if (loanApplicationId && loanApplicationId.currentValue) {
      this.loanApplicationId = typeof loanApplicationId.currentValue === 'object' ? loanApplicationId.currentValue.LoanScenarioID : loanApplicationId.currentValue;
      this.getImportantDate();
    }
    if (brokerEventId && brokerEventId.currentValue) {
      this.brokerEventId = brokerEventId.currentValue;
      this.getLoanDetails();
    }
    if (isOnLoanDetailsModal && isOnLoanDetailsModal.currentValue) {
      this.isOnLoanDetailsModal = isOnLoanDetailsModal.currentValue;
    }
    if (numberOfProduct && numberOfProduct.currentValue) {
      this.numberOfProduct = numberOfProduct.currentValue;
      if (!this.isOnLoanDetailsModal) {
        this.isProductNewSet = true;
        this.getLoanDetails();
      }
    }
  }

  initData() {
    this.isProductNewSet = false;
    this.isAddButtonShow = false;
    this.initialRateData = {
      isAnotherDiscountSet: false,
      isDetailShow: false,
      isEditDetails: false,
      isDeleted: false,
      isLoanPeriodSet: false,
    };
    this.ongoingRateData = {
      isDetailShow: false,
      isEditDetails: false,
      isDeleted: false,
    };
    this.isDefaultRateTermSet = false;
  }

  getSelectedProductDetails() {
    if (!this.productId) return;
    this.initData();
    this.loanProfilerService.getProductFullDetails(this.productId)
      .then(({ data }) => {
        if (!data) return;
        this.fullProductDetails = data;
        if (!data.InitialRate) this.fullProductDetails.InitialRate = data.IntialRate || 0;
        const defaultRateTerm = this.fullProductDetails.MaxTermFixedRate;
        this.isDefaultRateTermSet = !!defaultRateTerm;
        this.initialRateData.loanPeriod = this.loanTimePeriod.find(item => parseInt(item.value, 10) === (defaultRateTerm * ONE_YEAR));
      });
  }

  getLoanDetails() {
    this.contactService.loanDetailsGet(this.loanId)
      .then(({ data }) => {
        if (!data || !data.loan || !data.loan.LoanStructureList || !data.loan.LoanStructureList.length) return;
        this.loanStructureId = data.loan.LoanStructureList[0].LoanStructureId;
        this.getLoanStructureDetails();
      });
  }

  getProductDetails() {
    if (!this.brokerEventId || !this.productId) return;
    this.loanProfilerService.getSelectedProductDetailsGetByLoanStructure(this.brokerEventId)
      .then(({ data }) => {
        if (!data || !data.length) return;
        const { LoanStructureID, SelectedProduct } = data[0];
        this.productLoanStructureId = LoanStructureID;
        const isProductNotSelected = SelectedProduct && SelectedProduct.length && SelectedProduct.find(item => item.ProductID === this.productId);
        if (!isProductNotSelected) return this.setProductSelected();
        this.setProductData(SelectedProduct);
      });
  }

  setProductSelected() {
    const postData = {
      brokerEventID: this.brokerEventId,
      ProductID: this.fullProductDetails.ProductID,
      LenderID: this.fullProductDetails.LenderID,
      loanStructureID: this.productLoanStructureId,
      IsFavourite: true,
      IsSeletedInFavourite: false,
    };
    this.loanProfilerService.setFavouriteProduct(postData)
      .then(({ data }) => {
        if (!data) return;
        this.setProductData();
      });
  }

  setProductData(selectedProduct = []) {
    this.productData = selectedProduct.find(item => item.ProductID === this.productId) || this.fullProductDetails;
    if (this.productData.NewInitialRate && this.isOnLoanDetailsModal) this.getInitialActualRate({ rate: this.productData.NewInitialRate });
    if (this.isProductNewSet && !this.productData) {
      this.productData = selectedProduct && selectedProduct[0];
      this.isProductNewSet = false;
    }
    if (this.productData && this.loanStructureData) {
      this.loanStructureData.LenderDetails = { LenderId: this.productData.LenderID };
      this.loanStructureData.ProductDetails = { Product: this.productData.ProductName, ProductID: this.productData.ProductID };
    }
    if (!this.isDefaultRateTermSet) {
      this.initialRateData.loanPeriod = this.loanTimePeriod.find(item => parseInt(item.value, 10) === this.productData.InterestRateTerm);
    }
    this.ratesInit();
  }

  getLoanStructureDetails() {
    if (!this.loanId || !this.loanStructureId) return;
    this.contactService.loanStructureDetailsGet(this.loanId, this.loanStructureId)
      .then((response) => {
        if (!response || !response.data) return;
        this.loanStructureData = response.data;
        this.loanStructureData.LoanId = this.loanId;
        this.loanStructureData.LoanStructureId = this.loanStructureId;
        const rateTerm = (this.loanStructureData && this.loanStructureData.RateTerm) || parseInt(LIFE_OF_LOAN, 10);
        this.rateTerm = rateTerm ? rateTerm * ONE_YEAR : rateTerm;
        this.getProductDetails();
      });
  }

  getLoanTimePeriod() {
    this.optionsService.getDiscountTimePeriod().then((response) => {
      if (!response || !response.length) return;
      this.timePeriod = response;
      this.lifeOfLoanPeriod = response.find(item => this.checkIfLifeOfLoan(item.value));
      this.loanTimePeriod = response.filter(item => !this.checkIfLifeOfLoan(item.value));
    });
  }

  getImportantDate() {
    if (!this.loanApplicationId) return;
    this.loanScenarioService.importantDateGet(this.loanApplicationId)
      .then((response) => {
        if (!response && !response.data && !response.data.length) return;
        const importantDate = response.data.find(item => item.TypeOfDateID === LOAN_APP_STATUS_TYPES.SETTLEMENT_DATE_ID);
        this.isHasLoanSettlementDate = !!importantDate;
        this.importantDate = importantDate && importantDate.Date;
      });
  }

  ratesInit() {
    if (!this.productData) return;

    if (this.productData.NewInitialRate) {
      this.initialRateData.isDetailShow = true;
      this.initialRateData.actualRate = this.productData.NewInitialRate;
      this.initialRateData.discountRate = parseFloat((this.productData.InitialRate - this.productData.NewInitialRate).toFixed(2));
      if (!this.initialRateData.loanPeriod) {
        this.initialRateData.loanPeriod = this.loanTimePeriod.find(item => parseInt(item.value, 10) === this.rateTerm);
        this.isAddButtonShow = this.initialRateData.loanPeriod.value === LIFE_OF_LOAN;
      }
      if (!this.isAddButtonShow) this.getValidDate(this.initialRateData.loanPeriod);
    }

    if (this.productData.NewOngoingRate) {
      this.ongoingRateData.isDetailShow = true;
      this.ongoingRateData.actualRate = this.productData.NewOngoingRate;
      this.ongoingRateData.discountRate = parseFloat((this.productData.OngoingRate - this.productData.NewOngoingRate).toFixed(2));
    }
  }

  saveFavouriteProductRates(rateType) {
    const postData = {
      BrokerEventID: this.brokerEventId,
      LoanStructureID: this.productLoanStructureId,
      ProductID: this.productData.ProductID,
      RateType: rateType === 'initial' ? 'InitialRate' : 'OngoingRate',
      NewRate: rateType === 'initial' ? `${this.initialRateData.actualRate}` : `${this.ongoingRateData.actualRate}`,
      InterestRateTerm: this.initialRateData.loanPeriod.value || 0,
    };
    this.setFavouriteProductRates(postData);
  }

  deleteFavouriteProductRates(rateType) {
    this.setFavouriteProductRates({
      BrokerEventID: this.brokerEventId,
      ProductID: this.productData.ProductID,
      LoanStructureID: this.productLoanStructureId,
      RateType: rateType === 'initial' ? 'InitialRate' : 'OngoingRate',
      NewRate: '',
      InterestRateTerm: 0,
    });
  }

  setFavouriteProductRates(postData) {
    this.loanProfilerService
      .setFavouriteProductRates(postData)
      .then((response) => {
        if (response.data !== 1) toastError();
      });
  }

  deleteDiscountRate(section) {
    if (section === 'initial') {
      this.deleteFavouriteProductRates(section);
      this.initialRateData.isDetailShow = false;
      this.initialRateData.isEditDetails = false;
      this.initialRateData.actualRate = 0;
      this.initialRateData.isDeleted = true;
      this.isAddButtonShow = false;
    } else {
      this.deleteFavouriteProductRates(section);
      this.ongoingRateData.isDetailShow = false;
      this.ongoingRateData.isEditDetails = false;
      this.ongoingRateData.actualRate = 0;
      this.ongoingRateData.isDeleted = true;
      this.isAddButtonShow = false;
      if (this.ongoingRateData.isDeleted && !this.initialRateData.isDetailShow) this.initialRateData.isAnotherDiscountSet = false;
    }
    if (this.ongoingRateData.isDeleted && this.initialRateData.isDeleted) {
      this.initialRateData.isAnotherDiscountSet = false;
    }
  }

  saveDiscountRate(section) {
    const { rateValue, loanPeriod } = this.discountRate;
    if (!rateValue) return;
    const discountRate = parseFloat(rateValue);
    if (section === 'ongoing') {
      this.ongoingRateData.discountRate = discountRate;
      this.ongoingRateData.actualRate = this.getActualRate(this.productData.OngoingRate, this.ongoingRateData.discountRate);
      this.ongoingRateData.isDetailShow = true;
      this.ongoingRateData.isDeleted = false;
    } else {
      if (!loanPeriod) return;
      this.initialRateData.discountRate = discountRate;
      this.initialRateData.actualRate = this.getActualRate(this.productData.InitialRate, this.initialRateData.discountRate);
      this.initialRateData.isDetailShow = true;
      this.initialRateData.isDeleted = false;
      this.isAddButtonShow = false;
      if (!this.isDefaultRateTermSet) {
        this.initialRateData.loanPeriod = loanPeriod;
        this.isAddButtonShow = loanPeriod.value === LIFE_OF_LOAN;
      }
      if (!this.isAddButtonShow) this.getValidDate(loanPeriod);
      if (this.isOnLoanDetailsModal) this.getInitialActualRate({ rate: this.initialRateData.actualRate });
    }
    this.applyDiscount(section);
    const rateType = section === 'another' ? 'initial' : section;
    this.saveFavouriteProductRates(rateType);
  }

  getActualRate(baseRate, DiscountRate) {
    this.actualRateResult = parseFloat((baseRate - DiscountRate).toFixed(2));
    return this.actualRateResult;
  }

  applyDiscount(section) {
    this.resetDicountRate();
    switch (section) {
    case 'initial':
      this.initialRateData.isInitialEntryShow = this.initialRateData.isEditDetails ? false : !this.initialRateData.isInitialEntryShow;
      this.ongoingRateData.isOngoingEntryShow = false;
      this.initialRateData.isInitialAnotherEntryShow = false;
      this.initialRateData.isDetailShow = this.initialRateData.actualRate;
      break;
    case 'ongoing':
      this.ongoingRateData.isOngoingEntryShow = this.ongoingRateData.isEditDetails ? false : !this.ongoingRateData.isOngoingEntryShow;
      this.initialRateData.isInitialEntryShow = false;
      this.initialRateData.isInitialAnotherEntryShow = false;
      break;
    case 'another':
      this.initialRateData.isInitialAnotherEntryShow = !this.initialRateData.isInitialAnotherEntryShow;
      this.initialRateData.isInitialEntryShow = false;
      this.ongoingRateData.isOngoingEntryShow = false;
      break;
    default:
      this.resetDicountRate();
    }
    this.initialRateData.isEditDetails = false;
    this.ongoingRateData.isEditDetails = false;
  }

  getValidDate(loanPeriod) {
    if (!loanPeriod || !loanPeriod.value) return;
    const { value } = loanPeriod;
    if (this.loanStructureData && this.loanStructureData.InterestRateType === RATE_TYPE_FIXED) {
      this.current = (this.loanStructureData && this.loanStructureData.FixedRateBegin) || this.importantDate;
    } else {
      this.current = this.importantDate;
    }
    this.isValidDateShow = !!this.current;

    const days = 'months';
    this.currentDate = moment(this.current);
    this.futureDate = {
      year: moment(this.currentDate).add(value, days).format('YYYY'),
      month: moment(this.currentDate).add(value, days).format('MMMM'),
      date: moment(this.currentDate).add(value, days).format('D'),
    };
  }

  editDiscounts(section) {
    this.resetDicountRate();
    if (section === 'initial') {
      this.initialRateData.isDeleted = false;
      this.initialRateData.isEditDetails = !this.initialRateData.isEditDetails;
      this.discountRate = {
        rateValue: this.initialRateData.discountRate,
        loanPeriod: this.initialRateData.loanPeriod,
      };
    } else {
      this.ongoingRateData.isDeleted = false;
      this.ongoingRateData.isEditDetails = !this.ongoingRateData.isEditDetails;
      this.discountRate = {
        rateValue: this.ongoingRateData.discountRate,
      };
    }
  }

  resetDicountRate() {
    this.discountRate = {
      rateValue: 0,
      loanPeriod: this.loanTimePeriod && this.loanTimePeriod.length && this.loanTimePeriod[0],
    };
  }

  showValidDates() {
    return !this.isAddButtonShow && this.isValidDateShow && this.initialRateData.loanPeriod;
  }

  checkIfLifeOfLoan(value) {
    this.lifeOfLoanValue = 0;
    return parseInt(value, 10) === this.lifeOfLoanValue;
  }
}

export default loanAppDiscountsRateCtrl;
