import angular from 'angular';
import crmTableView from './crmTableView';
import crmTableViewOpportunity from './crmTableViewOpportunity';
import crmTablePagination from './crmTablePagination';

export default angular.module('components.crmTableView', [])
  .component('crmTableView', crmTableView)
  .component('crmTableViewOpportunity', crmTableViewOpportunity)
  .component('crmTablePagination', crmTablePagination)
  .name;
