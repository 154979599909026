import angular from 'angular';

angular.module('app').controller('MobileFamilyFilterModalCtrl', function MobileFamilyFilterModalCtrl(
  $scope,
  $uibModalInstance
) {
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
