import { SECTION_STRING } from 'Common/constants/opportunity';
import { REFERRAL_STRINGS, REFERRAL_ITEM, CLIENT_REFERRAL, ADDITIONAL_REFERRAL } from 'Common/constants/enquirySource';
import swal from 'sweetalert';
import { ENTITY_PARTY_TYPES } from 'Common/constants/partyTypes';
import { REQUESTER as CONTACT_REQUESTER } from 'Common/constants/addNewContactRequesters';
import ManagePartiesViewCtrl from '../manageParties/managePartiesViewCtrl';

class OpportunityDetailsCtrl {
  constructor(
    referencedReferralService,
    loanOpportunityService,
    enquirySourceService,
    loanScenarioService,
    currentUserService,
    optionsService,
    contactService,
    $uibModal,
    toaster,
    $stateParams,
    $timeout,
    uiService,
    $q
  ) {
    'ngInject';

    this.referencedReferralService = referencedReferralService;
    this.loanOpportunityService = loanOpportunityService;
    this.enquirySourceService = enquirySourceService;
    this.loanScenarioService = loanScenarioService;
    this.currentUserService = currentUserService;
    this.SECTION_STRING = SECTION_STRING;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.$stateParams = $stateParams;
    this.uiService = uiService;
    this.$q = $q;

    this.clientSearchApi = this.clientSearchApi.bind(this);
    this.selectClient = this.selectClient.bind(this);
  }

  $onInit() {
    this.clientEnquiryList = CLIENT_REFERRAL;
    this.additionalEnquiryList = ADDITIONAL_REFERRAL;
    this.purposeList = [];
    this.referralOrgList = [];
    this.referrerObj = {};
    if (this.summary.ClientReferrerFamilyId) {
      this.defaultClient = {
        FamilyID: this.summary.ClientReferrerFamilyId,
        FamilyFullName: this.summary.ClientReferrerName,
      };
    }

    this.getPurposeList();
    this.getEnquirySourceList();
    this.getCountryList();
    this.calculateLvr();
  }

  $onChanges(changes) {
    const { involvedParties, selectedAdviserId, summary } = changes;
    if (involvedParties) {
      const { currentValue: involvedPartiesValues } = involvedParties;
      if (involvedPartiesValues) this.formatInvolvedPartyPerson();
    }

    if (selectedAdviserId) {
      const { currentValue: selectedAdviserIdValue } = selectedAdviserId;
      if (selectedAdviserIdValue) this.getReferrerOrgList(selectedAdviserIdValue);
      this.allocatedAdviserId = selectedAdviserIdValue || 0;
    }

    if (summary) {
      const { currentValue: opportunityDetailsValues } = summary;
      if (opportunityDetailsValues) this.calculateLvr();
    }
  }

  getEnquirySourceList() {
    this.enquirySourceService.getEnquirySourceList()
      .then((res) => {
        this.enquiryList = res;
        const { EnquirySource } = this.summary;
        if (EnquirySource) {
          const { ReferralItemId, ReferralCategoryId } = EnquirySource;
          this.enquirySource = this.enquiryList.find(obj => obj.ReferralItemId === ReferralItemId && obj.ReferralCategoryId === ReferralCategoryId) || {};
        }
      });
  }

  formatInvolvedPartyPerson() {
    const { InvolvedPartyEntity, PersonBorrowers } = this.involvedParties;
    this.isEnableDeleteParty = (InvolvedPartyEntity.length + PersonBorrowers.length) - 1;

    Object.keys(this.involvedParties).forEach((partyType) => {
      this.involvedParties[partyType].map((clientEntity) => {
        clientEntity.Phone = clientEntity.Phone.filter(phone => phone.Type === 'Home' || phone.Type === 'Mobile');
        return clientEntity;
      });
    });
  }

  toggleOtherInfo(section, idx) {
    if (!this.involvedParties[section] || !this.involvedParties[section][idx]) return;

    this.involvedParties[section][idx].showOtherInfo = !this.involvedParties[section][idx].showOtherInfo;
  }

  openManagePartiesModal() {
    const { opportunityId } = this.$stateParams;
    const { involvedParties, selectedAdviserId } = this;

    this.$uibModal.open({
      template: `<manage-parties
                  modal-instance="vm.modalInstance"
                  opportunity-id="vm.opportunityId"
                  involved-parties="vm.involvedParties"
                  selected-adviser-id="vm.selectedAdviserId"
                  show-select-adviser="vm.showSelectAdviser"
                >
                </manage-parties>`,
      controller: ManagePartiesViewCtrl,
      windowClass: 'manage-parties-modal',
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        opportunityId: () => opportunityId,
        involvedParties: () => involvedParties,
        selectedAdviserId: () => selectedAdviserId,
        showSelectAdviser: () => false,
      },
    }).result
      .then(() => {
        this.onRefreshDetails();
      },
      () => {
        this.onRefreshDetails();
      });
  }

  openEditClientModal(e, familyId, clientId, isClient, partyObject) {
    e.stopPropagation();

    if (isClient) {
      this.$uibModal.open({
        templateUrl: '/assets/views/contacts/partials/add_client_modal_v2.html',
        controller: 'AddClientModalCtrl',
        size: 'lg',
        resolve: {
          countryList: () => this.countryList,
          isChild: () => false,
          clientId: () => clientId,
          applicantFamilyId: () => familyId,
        },
      }).result
        .then((res) => {
          res && res.updateApplicant && this.onRefreshDetails();
        });
    } else {
      this.$uibModal.open({
        templateUrl: '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
        controller: 'AddCompanyRelatedModalCtrl',
        size: 'md',
        resolve: {
          selectedPartyType: () => String(ENTITY_PARTY_TYPES.COMPANY),
          person: () => partyObject,
          requester: () => CONTACT_REQUESTER.NEW_LOAN_APP_MODAL,
        },
        backdrop: 'static',
        keyboard: false,
      }).result
        .then((res) => {
          res && this.onRefreshDetails();
        });
    }
  }

  openDeleteClientModal(e, clientId, isClient) {
    e.stopPropagation();
    swal({
      title: 'Are you sure?',
      text: 'This record will be removed from your involved party list',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, remove it!',
      closeOnConfirm: true,
    }, (confirm) => {
      if (confirm) {
        this.loanScenarioService.scenarioClientDelete(this.opportunityId, clientId, isClient)
          .then((response) => {
            const { data: result } = response;
            if (result) {
              this.toaster.pop('success', 'Success', 'Successfully deleted!');
              this.onRefreshDetails();
            }
          });
      }
    });
  }

  calculateLvr() {
    const { ProposedLoanAmount, SecurityAmount } = this.summary;
    const isInvalidAmounts = !parseInt(ProposedLoanAmount, 10) || !parseInt(SecurityAmount, 10);
    this.summary.lvr = isInvalidAmounts ? 0 : ((ProposedLoanAmount / SecurityAmount) * 100).toFixed(2);
  }

  /*
  * everything that is changed in the summary panel should update the this.summary property
  * please refer to loanOpportunityService.setLoanOpportunitySummary api in swagger for the model structure
  */
  reassignSummary(newObj = {}) {
    this.summary = Object.assign({}, this.summary, { ...newObj });
  }

  summaryChange(isEnquiryAndReferralChanged = false, showToaster = false) {
    this.loanOpportunityService.setLoanOpportunitySummary(this.opportunityId, { ...this.summary })
      .then(() => { showToaster && this.toaster.pop('success', 'Success', 'Successfully updated!'); })
      .then(() => { isEnquiryAndReferralChanged && this.onRefreshDetails(); });
    this.calculateLvr();
  }

  checkIfReferrer() {
    return this.enquirySource ? REFERRAL_STRINGS.indexOf(this.enquirySource.ReferralItemName) !== -1 : false;
  }

  getPurposeList() {
    this.optionsService.loanpurpose()
      .then((res) => {
        const { data } = res;
        const purposeList = data.map((obj) => {
          obj.Value = parseInt(obj.Value, 10);
          return obj;
        });

        this.purposeList = purposeList;
      });
    this.purpose = {};
  }

  enquiryChanged(showSuccessToaster, isDetails) {
    if (!this.enquirySource) return;
    this.summary.ClientReferrerFamilyId = 0;
    this.defaultClient = {
      FamilyID: 0,
      FamilyFullName: '',
    };
    const { ReferralItemId, ReferralCategoryId } = this.enquirySource;
    const isReferral = ReferralItemId === REFERRAL_ITEM.REFERRAL;
    this.referrerRequired = isReferral && !this.summary.EnquirySource.ReferrerId;
    if (this.referrerRequired) {
      this.referrerObj.ReferralMemberName = '';
      return;
    }
    const clientObj = this.displayClient(ReferralItemId) && this.clientModel && this.clientModel.originalObject;
    this.summary.ClientReferrerFamilyId = clientObj ? clientObj.FamilyID : 0;
    this.summary.OtherContactReferrerName = isDetails && this.displayDetails(ReferralItemId) ? this.summary.OtherContactReferrerName : '';
    const ReferrerId = isReferral ? this.summary.EnquirySource.ReferrerId : 0;
    const ReferrerOrgId = isReferral ? this.summary.EnquirySource.ReferrerOrgId : 0;
    const ReferrerName = isReferral ? this.summary.EnquirySource.ReferrerName : '';
    const ReferrerOrgName = isReferral ? this.summary.EnquirySource.ReferrerOrgName : '';
    const ReferrerAgreementTypeId = isReferral ? this.summary.EnquirySource.ReferrerAgreementTypeId : 0;

    const isEnquiryAndReferralChanged = isReferral && ReferrerId;
    this.reassignSummary({ EnquirySource: { ...this.summary.EnquirySource, ReferrerId, ReferrerOrgId, ReferralItemId, ReferralCategoryId, ReferrerName, ReferrerOrgName, ReferrerAgreementTypeId } });
    this.summaryChange(isEnquiryAndReferralChanged, showSuccessToaster);
  }

  getReferrerOrgList(adviserId) {
    this.referencedReferralService.getReferencedReferralList(adviserId, null)
      .then((res) => {
        this.referralOrgList = res.map((o) => {
          const ReferralOrganizationName = o.ReferralOrganizationName ? ` - ${o.ReferralOrganizationName}` : ``;
          o.ReferralMemberNameBak = `${o.ReferralMemberName}`;
          o.ReferralMemberName = `${o.ReferralMemberName}${ReferralOrganizationName}`;
          return o;
        });

        const { EnquirySource } = this.summary;
        if (EnquirySource) {
          const { ReferrerId, ReferrerOrgId, ReferrerName, ReferrerOrgName, ReferrerAgreementTypeId } = EnquirySource;
          const hasPreviousReferrer = !!ReferrerId;
          const orgName = EnquirySource.ReferrerOrgName ? ` - ${EnquirySource.ReferrerOrgName}` : ``;
          const referredBy = `${EnquirySource.ReferrerName}${orgName}`;
          this.referrerObj = {
            AgreementTypeId: ReferrerAgreementTypeId,
            ReferralMemberId: ReferrerId,
            ReferralMemberName: referredBy,
            ReferralMemberNameBak: ReferrerName,
            ReferralOrganizationId: ReferrerOrgId,
            ReferralOrganizationName: ReferrerOrgName,
          };

          if (hasPreviousReferrer && !Object.keys(this.referrerObj).length) this.enquiryChanged();
        }
      });
  }

  getCountryList() {
    this.countryList = [];
    this.optionsService.countryListGet()
      .then((response) => {
        this.countryList = response.data || [];
      });
  }

  searchReferrer() {
    const { ReferralMemberName } = this.referrerObj;
    this.referrerObj = { ReferralMemberName };
    this.showReferrerList = true;
  }

  selectReferrer(referrer) {
    this.showReferrerList = false;
    this.referrerObj = { ...referrer };
    const { ReferralMemberId: ReferrerId, ReferralOrganizationId: ReferrerOrgId, ReferralMemberNameBak: ReferrerName, ReferralOrganizationName: ReferrerOrgName, AgreementTypeId: ReferrerAgreementTypeId } = this.referrerObj;
    this.reassignSummary({ EnquirySource: { ...this.summary.EnquirySource, ReferrerId, ReferrerOrgId, ReferrerName, ReferrerOrgName, ReferrerAgreementTypeId } });
    this.enquiryChanged(true);
  }
  displayClient() {
    if (!this.enquirySource || !this.enquirySource.ReferralItemId) return false;
    return this.clientEnquiryList.indexOf(this.enquirySource.ReferralItemId) !== -1;
  }
  displayDetails() {
    if (!this.enquirySource || !this.enquirySource.ReferralItemId) return false;
    return this.additionalEnquiryList.indexOf(this.enquirySource.ReferralItemId) !== -1;
  }
  clientSearchApi(userInputString) {
    const defer = this.$q.defer();
    defer.resolve([]);
    if (!userInputString || !this.allocatedAdviserId) return defer.promise;
    return this.contactService.searchFamilyContactType(userInputString, 'Client', this.allocatedAdviserId);
  }
  selectClient(client) {
    this.clientModel = client;
    this.enquiryChanged();
  }
}

export default OpportunityDetailsCtrl;
