import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

angular.module('app').controller('OverviewDashboardCtrl',
  function OverviewDashboardCtrl(
    $scope,
    $rootScope,
    $http,
    configService,
    $filter,
    dashboardService,
    imageDataURI,
    $timeout,
    $templateCache,
    overviewDashboardService,
    $localStorage,
    $uibModal,
    DEMO_VIDEO) {
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_multi_video_modal.html',
        scope: $scope,
        controller($uibModalInstance) {
          $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };
          $scope.headerName = DEMO_VIDEO.DASHBOARD.Header_Name;
          $scope.videoList = DEMO_VIDEO.DASHBOARD.Video_List;
          $scope.videoId = DEMO_VIDEO.DASHBOARD.Video_List[0].Url;
          $scope.isActive = function (id) {
            const isActive = (id === $scope.videoId);
            return isActive;
          };

          // For Change Video URL
          $scope.changeVideoUrl = function (url) {
            $scope.videoId = url;
            const $url = `https://player.vimeo.com/video/${$scope.videoId}?player_id=video1`;
            angular.element('#video-player iframe').attr('src', $url);
          };
        },
        size,
        windowClass: 'demo-video-modal-window-class multi-demo-video',
      });
    };
    $scope.testData = [1, 2, 3];
    $scope.isEditMode = false;
    $scope.hideAddedIndex = [];
    $scope.showDeletedIndex = [];
    const buttonHtml = "  <i class=\"fa fa-times-circle-o widgetDeleteBtn\" ng-click=\"deleteItems('replace')\"></i>";
    $scope.widget = {
      widgetList: [],
    };
    const mapping = {
      1: '<fixed-rate-reviews my-param="testData"></fixed-rate-reviews>',
      3: '<upcoming-birthdays-small my-param="testData"></upcoming-birthdays-small>',
      5: '<commissions-received my-param="testData"></commissions-received>',
      8: '<outstanding-commissions-small my-param="testData"></outstanding-commissions-small>',
      10: '<client-reviews-small my-param="testData"></client-reviews-small>',
      12: '<tasks my-param="testData"></tasks>',
      6: '<commission-received-table my-param="testData"></commission-received-table>',
      11: '<client-reviews my-param="testData"></client-reviews>',
      9: '<outstanding-commission my-param="testData"></outstanding-commission>',
      7: '<commission-received-chart my-param="testData"></commission-received-chart>',
      13: '<tasks-table my-param="testData"></tasks-table>',
      2: '<fixed-rate-reviews-table my-param="testData"></fixed-rate-reviews-table>',
      4: '<upcoming-birthdays my-param="testData"></upcoming-birthdays>',
      14: '<upcoming-events-table my-param="testData"></upcoming-events-table>',
      15: '<system-updates my-param="testData"></system-updates>',
      16: '<provider-updates my-param="testData"></provider-updates>',
      17: '<interest-rate-updates-table my-param="testData"></interest-rate-updates-table>',
      22: '<target-tracking my-param="testData"></target-tracking>',
      29: '<nps-score-small my-param="testData"></nps-score-small>',
      27: '<lender-split my-param="testData"></lender-split>',
      26: '<adviser-leaderboard my-param="testData"></adviser-leaderboard>',
      28: '<referral-source my-param="testData"></referral-source>',
      24: '<settlement my-param="testData"></settlement>',
      31: '<customer-comment my-param="testData"></customer-comment>',
      30: '<customer-comment-table my-param="testData"></customer-comment-table>',
      32: '<pipeline-lodged my-param="testData"></pipeline-lodged>',
      34: '<pipeline-approved my-param="testData"></pipeline-approved>',
      42: '<pipeline-settled my-param="testData"></pipeline-settled>',
      36: '<pipeline-status-small my-param="testData"></pipeline-status-small>',
      41: '<pipeline-new-leads my-param="testData"></pipeline-new-leads>',
      38: '<pipeline-stats-chart my-param="testData"></pipeline-stats-chart>',
      39: '<pipeline-stats-table my-param="testData"></pipeline-stats-table>',
      47: '<pre-approval-expiries my-param="testData"></pre-approval-expiries>',
      44: '<pre-approval-expiries-small my-param="testData"></pre-approval-expiries-small>',
      50: '<interest-rates-table-directive my-param="testData"></interest-rates-table-directive>',
    };
    $scope.deleteItems = function (items) {
      const itemsD = items.substr(0, items.length - 1);
      const itemsToRemove = itemsD.split(',');
      $scope.showDeletedIndex.push(parseInt(itemsToRemove[0], 10));
      for (let i = 0; i < itemsToRemove.length; i++) {
        const index = parseInt(itemsToRemove[i], 10);
        $scope.layoutsW[index].isDeleted = true;
      }
    };
    $scope.generateHtml = function () {
      overviewDashboardService.LayoutGet()
        .then((response) => {
          $scope.layouts = response.data;
          $scope.layouts = $filter('orderBy')($scope.layouts, 'Position');
          let smCounter = 0;
          let lgCounter = 0;
          let htmlStr = '';
          let widgetStr = '';
          for (let i = 0; i < $scope.layouts.length; i++) {
            if ($scope.layouts[i].Category === 'Small Widget') {
              if (lgCounter !== 0) {
                for (let m = 0; m < 2 - lgCounter; m++) {
                  htmlStr += '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10"></div>';
                }
                lgCounter = 0;
                htmlStr += '</div>';
              }
              if (smCounter === 0) {
                htmlStr += '<div class="row">';
              }
              smCounter++;
              widgetStr = mapping[$scope.layouts[i].WidgetId];
              if (widgetStr === null || widgetStr === undefined) {
                widgetStr = '';
              } else {
                widgetStr = widgetStr.replace('testData', `layouts[${i}]`);
              }
              htmlStr += `<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 wrap-dashboard-widget">${widgetStr}</div>`;
              if (smCounter === 4) {
                smCounter = 0;
                htmlStr += '</div>';
              }
            } else if ($scope.layouts[i].Category !== 'Small Widget') {
              if (smCounter !== 0) {
                for (let j = 0; j < 4 - smCounter; j++) {
                  htmlStr += '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 wrap-dashboard-widget"></div>';
                }
                smCounter = 0;
                htmlStr += '</div>';
              }
              if (lgCounter === 0) {
                htmlStr += '<div class="row">';
              }
              lgCounter++;
              widgetStr = mapping[$scope.layouts[i].WidgetId];
              if (widgetStr === null || widgetStr === undefined) {
                widgetStr = '';
              } else {
                widgetStr = widgetStr.replace('testData', `layouts[${i}]`);
              }
              htmlStr += `<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10">${widgetStr}</div>`;
              if (lgCounter === 2) {
                lgCounter = 0;
                htmlStr += '</div>';
              }
            }
          }
          $scope.bindedDir = htmlStr;
          $scope.showBinded = true;
        },
        () => {
        });
    };
    $scope.addSmallWidgetRow = function () {
      $scope.addSmallWButtonActive = false;
      let i = $scope.layoutsW.length - 1;
      let lastItem = $scope.layoutsW[$scope.layoutsW.length - 1];
      if (lastItem === undefined) {
        lastItem =
                {
                  Category: 'Big Widget',
                  Position: 0,
                  WidgetId: '13',
                  WidgetName: 'Tasks',
                  Widget_UserID: '0' };

        lastItem.dropDownOption = $scope.layoutListSm;
        i = -1;
      }
      let posToAssign = lastItem.Position + 1;
      let deletableIndices = '';
      let newHtml = '';
      let m;
      if ($scope.layoutsW.length > 0 && $scope.layoutsW[i].Category === 'Small Widget') {
        newHtml += '<div class="row">';
        for (m = 0; m < 2; m++) {
          newHtml += '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
        }
        newHtml += '</div>';
        $scope.rowCounter++;
        posToAssign += 2;
      }
      newHtml += `<div class="row margin-top-15" style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${i + 1}].isDeleted">`;
      $scope.rowCounter++;
      for (m = 0; m < 4; m++) {
        const newObj = {
          WidgetId: '0',
          WidgetName: 'None',
          Widget_UserID: '0',
          Position: posToAssign++,
          Category: 'Small Widget',
          dropDownOption: $scope.layoutListSm,
        };
        $scope.layoutsW.push(newObj);
        newHtml += `<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"><small-widget-settings my-param="layoutsW[${++i}]" index-in-array="0"></small-widget-settings></div>`;
        deletableIndices += (`${i},`);
      }
      let htmlToPlace = buttonHtml;
      htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
      newHtml += htmlToPlace;
      deletableIndices = '';
      newHtml += '</div>';
      $scope.bindedDirSettings += newHtml;
      $scope.htmlToAdd = newHtml;
      const forceCompileTimeout = $timeout(() => {
        $rootScope.$broadcast('forceCompile');
        $timeout.cancel(forceCompileTimeout);
      });

      $scope.addSmallWButtonActive = true;
    };
    $scope.addBigWidgetRow = function () {
      $scope.addBigWButtonActive = false;
      let i = $scope.layoutsW.length - 1;
      let lastItem = $scope.layoutsW[$scope.layoutsW.length - 1];
      if (lastItem === undefined) {
        lastItem =
                {
                  Category: 'Big Widget',
                  Position: 0,
                  WidgetId: '13',
                  WidgetName: 'Tasks',
                  Widget_UserID: '0' };

        lastItem.dropDownOption = $scope.layoutListSm;
        i = -1;
      }
      let posToAssign = lastItem.Position + 1;
      let deletableIndices = '';
      let newHtml = '';
      let m;
      if ($scope.layoutsW.length > 0 && $scope.layoutsW[i].Category !== 'Small Widget') {
        newHtml += '<div class="row">';
        for (m = 0; m < 4; m++) {
          newHtml += '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
        }
        newHtml += '</div>';
        $scope.rowCounter++;
        posToAssign += 4;
      }
      newHtml += `<div class="row margin-top-15" style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${i + 1}].isDeleted">`;
      $scope.rowCounter++;
      for (m = 0; m < 2; m++) {
        const newObj = {
          WidgetId: '0',
          WidgetName: '',
          Widget_UserID: '0',
          Position: posToAssign++,
          Category: 'Big Widget',
          dropDownOption: $scope.layoutListLg,
        };
        $scope.layoutsW.push(newObj);
        newHtml += `<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"><big-widget-settings my-param="layoutsW[${++i}]" index-in-array="0"></big-widget-settings></div>`;
        deletableIndices += (`${i},`);
      }
      let htmlToPlace = buttonHtml;
      htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
      newHtml += htmlToPlace;
      deletableIndices = '';
      newHtml += '</div>';
      $scope.bindedDirSettings += newHtml;
      $scope.htmlToAdd = newHtml;
      const forceCompileTimeout = $timeout(() => {
        $rootScope.$broadcast('forceCompile');
        $timeout.cancel(forceCompileTimeout);
      });
      $scope.addBigWButtonActive = true;
    };
    const LayoutListGet = (layout) => {
      overviewDashboardService.LayoutListGet(1)
        .then((res) => {
          $scope.layoutListSm = res.data;
          const dropdownFirstItemSmall =
                {
                  WidgetId: '0',
                  WidgetName: 'None',
                  Category: 'Small Widget' };

          $scope.layoutListSm.splice(0, 0, dropdownFirstItemSmall);
          overviewDashboardService.LayoutListGet(5)
            .then((overviewDashboardServiceResponse) => {
              $scope.layoutListLg = overviewDashboardServiceResponse.data;
              const layoutListTimeout = $timeout(() => {
                const dropdownFirstItemBig =
                  {
                    WidgetId: '0',
                    WidgetName: 'None',
                    Category: 'Big Widget' };
                $scope.layoutListLg.splice(0, 0, dropdownFirstItemBig);
                $timeout.cancel(layoutListTimeout);
              });
              let expectingWidgetRow = 'sm';
              $scope.layoutsW = layout;
              $scope.layoutsW = $filter('orderBy')($scope.layoutsW, 'Position');
              let smCounter = 0;
              let lgCounter = 0;
              $scope.rowCounter = 0;
              let htmlStr = '';
              let deletableIndices = '';
              let m;
              let j;
              let htmlToPlace;
              for (let i = 0; i < $scope.layoutsW.length; i++) {
                if ($scope.layoutsW[i].Category === 'Small Widget') {
                  if (lgCounter !== 0) {
                    for (m = 0; m < 2 - lgCounter; m++) {
                      htmlStr += '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
                    }
                    htmlToPlace = buttonHtml;
                    htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
                    htmlStr += htmlToPlace;
                    deletableIndices = '';
                    lgCounter = 0;
                    htmlStr += '</div>';
                    expectingWidgetRow = 'sm';
                  }
                  if (expectingWidgetRow === 'lg') {
                    htmlStr += '<div class="row">';
                    for (m = 0; m < 2; m++) {
                      htmlStr += '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
                    }
                    htmlStr += '</div>';
                    $scope.rowCounter++;
                    expectingWidgetRow = 'sm';
                  }
                  if (smCounter === 0) {
                    htmlStr += `<div class="row margin-top-15" style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${i}].isDeleted"   >`;
                    $scope.rowCounter++;
                  }
                  const overviewCategory = $scope.layoutListSm.filter(item => item.WidgetId === $scope.layoutsW[i].WidgetId)[0];
                  if (overviewCategory) {
                    $scope.layoutsW[i].Category = overviewCategory.Category;
                  }
                  $scope.layoutsW[i].dropDownOption = $scope.layoutListSm;
                  smCounter++;
                  htmlStr += `<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"><small-widget-settings my-param="layoutsW[${i}]" index-in-array="${i}"></small-widget-settings></div>`;
                  deletableIndices += (`${i},`);
                  if (smCounter === 4) {
                    htmlToPlace = buttonHtml;
                    htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
                    htmlStr += htmlToPlace;
                    deletableIndices = '';
                    smCounter = 0;
                    htmlStr += '</div>';
                    htmlStr += '</div>';
                    expectingWidgetRow = 'lg';
                  }
                } else if ($scope.layoutsW[i].Category !== 'Small Widget') {
                  if (smCounter !== 0) {
                    for (j = 0; j < 4 - smCounter; j++) {
                      htmlStr += '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
                    }
                    htmlToPlace = buttonHtml;
                    htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
                    htmlStr += htmlToPlace;
                    deletableIndices = '';
                    smCounter = 0;
                    htmlStr += '</div>';
                    expectingWidgetRow = 'lg';
                  }
                  if (expectingWidgetRow === 'sm') {
                    htmlStr += '<div class="row">';
                    for (m = 0; m < 4; m++) {
                      htmlStr += '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
                    }
                    htmlStr += '</div>';
                    $scope.rowCounter++;
                    expectingWidgetRow = 'lg';
                  }
                  if (lgCounter === 0) {
                    htmlStr += `<div class="row margin-top-15"style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${i}].isDeleted"  >`;
                    $scope.rowCounter++;
                  }

                  const layoutListLg = $scope.layoutListLg.filter(item => item.WidgetId === $scope.layoutsW[i].WidgetId);
                  $scope.layoutsW[i].Category = (layoutListLg[0] && layoutListLg[0].Category) || '';
                  $scope.layoutsW[i].dropDownOption = $scope.layoutListLg;
                  lgCounter++;
                  htmlStr += `<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"><big-widget-settings my-param="layoutsW[${i}]"  index-in-array="${i}"></big-widget-settings></div>`;
                  deletableIndices += (`${i},`);
                  if (lgCounter === 2) {
                    htmlToPlace = buttonHtml;
                    htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
                    htmlStr += htmlToPlace;
                    deletableIndices = '';
                    lgCounter = 0;
                    htmlStr += '</div>';
                    expectingWidgetRow = 'sm';
                  }
                }
              }
              if (lgCounter !== 0) {
                for (m = 0; m < 2 - lgCounter; m++) {
                  htmlStr += '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
                }
                htmlToPlace = buttonHtml;
                htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
                htmlStr += htmlToPlace;
                deletableIndices = '';
                lgCounter = 0;
                htmlStr += '</div>';
                expectingWidgetRow = 'sm';
              }
              if (smCounter !== 0) {
                for (j = 0; j < 4 - smCounter; j++) {
                  htmlStr += '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
                }
                htmlToPlace = buttonHtml;
                htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
                htmlStr += htmlToPlace;
                deletableIndices = '';
                smCounter = 0;
                htmlStr += '</div>';
                expectingWidgetRow = 'lg';
              }
              $scope.bindedDirSettings = htmlStr;
              $scope.showBindedSettings = true;
              const forceCompileTimeout = $timeout(() => {
                $rootScope.$broadcast('forceCompile');
                $timeout.cancel(forceCompileTimeout);
              });
              $scope.settingsButtonActive = true;
            }, () => {
            });
        }, () => {
        });
    };
    const LayoutGet = () => {
      overviewDashboardService.LayoutGet()
        .then((response) => {
          $scope.layoutsW = [];
          $scope.layoutsW = response.data;
          const indicesToRemove = [];
          let i;
          for (i = 0; i < $scope.layoutsW.length; i++) {
            if ($scope.layoutsW[i].WidgetName === 'Outstanding Commissions')
              indicesToRemove.push(i);
          }
          for (i = indicesToRemove.length - 1; i >= 0; i--)
            $scope.layoutsW.splice(indicesToRemove[i], 1);
          LayoutListGet($scope.layoutsW);
        }, () => {
        });
    };
    const init = () => {
      $scope.addSmallWButtonActive = true;
      $scope.addBigWButtonActive = true;
      $scope.settingsButtonActive = true;
      $scope.showBinded = false;
      $scope.showBindedSettings = false;
      $scope.generateHtml();
      LayoutGet();
    };
    $scope.saveChanges = function () {
      $scope.htmlToAdd = '';
      $scope.layoutsW = $scope.layoutsW.filter(item => !item.isDeleted);
      overviewDashboardService
        .LayoutSet($scope.layoutsW)
        .then(() => {
          swal('Updated', 'Widgets have been Updated Successfully.', 'success');
          init();
          $scope.isEditMode = false;
        })
        .catch(displayError);
    };
    $scope.cancelChanges = function () {
      $scope.settingsButtonActive = false;
      $scope.isEditMode = false;
      $scope.htmlToAdd = '';
      LayoutGet();
    };
    const widgetCategoryUpdatedBroadcastHandler = $rootScope.$on('categoryUpdated', () => {
      $scope.categoryUpdated = true;
    });
    $scope.$on('$destroy', widgetCategoryUpdatedBroadcastHandler);
    $scope.toggleEditMode = function () {
      if ($scope.categoryUpdated === true) {
        $scope.categoryUpdated = false;
        init();
      }
      $scope.isEditMode = !$scope.isEditMode;
    };
    init();
  });
