

app.factory('apiRefactorService', ['$q', 'apiRefactorData', 'contactService', '$timeout', 'dashboardService', function ($q, apiRefactorData, contactService, $timeout, dashboardService) {
  var factory = this;
  // models
  angular.extend(factory, {
    randomNumber: [],
  });

  // extend for common methods
  factory.commonMethods = {};
  angular.extend(factory, {
    getRandomNumber() {
      var rn = Math.floor(Math.random() * 100);
      var isRnExist = _.some(factory.randomNumber, rn);

      if (isRnExist === true)
        factory.getRandomNumber();
      else {
        factory.randomNumber.push(rn);
        return rn;
      }
    },
  });

  // extend for functions that calls api
  angular.extend(factory, {
    getUserInfo() {
      return $q((resolve, reject) => {
        if (apiRefactorData.getUser.key === 0) {
          apiRefactorData.getUser.key = factory.getRandomNumber();
          // call the api
          // we put key outside the api call because there is already a request going on
          contactService.getUserInfo().then((response) => {
            resolve(response);
            apiRefactorData.getUser.data = response.data;
          });
        } else {
          // will loop until apiRefactorData has a data
          var looper = function () {
            $timeout(() => {
              if (_.isUndefined(apiRefactorData.getUser.data) || _.isEmpty(apiRefactorData.getUser.data) || _.isNull(apiRefactorData.getUser.data)) {
                looper();
              } else {
                resolver();
              }
            }, 250);
          };
          // the function to call when apiRefactorData returns a data
          var resolver = function () {
            resolve(apiRefactorData.getUser);
          };
          // initiate the loop
          looper();
        }
      });
    },

    getRecentlyViewed(oldOrNew) {
      return $q((resolve, reject) => {
        if (apiRefactorData.getRecentlyViewed.key === 0 || oldOrNew === 'new') {
          apiRefactorData.getRecentlyViewed.key = factory.getRandomNumber();
          // call the api
          // we put key outside the api call because there is already a request going on
          dashboardService.getRecentlyViewed().then((response) => {
            resolve(response);
            apiRefactorData.getRecentlyViewed.data = response.data;
          });
        } else {
          // will loop until apiRefactorData has a data
          var looper = function () {
            $timeout(() => {
              if (_.isUndefined(apiRefactorData.getRecentlyViewed.data) || _.isEmpty(apiRefactorData.getRecentlyViewed.data) || _.isNull(apiRefactorData.getRecentlyViewed.data)) {
                looper();
              } else {
                resolver();
              }
            }, 250);
          };
          // the function to call when apiRefactorData returns a data
          var resolver = function () {
            resolve(apiRefactorData.getRecentlyViewed);
          };
          // initiate the loop
          looper();
        }
      });
    },

    contactFamilyInfoGet(familyId, oldOrNew) {
      var mainArgs = arguments;
      var newArgs = [];
      if (mainArgs.length > 0) {
        newArgs = _.slice(mainArgs, 0, mainArgs.length);
        newArgs = _.pull(newArgs, 'new', 'old');
      }
      var theData = apiRefactorData.contactFamilyInfoGet;

      return $q((resolve, reject) => {
        if (theData.key === 0 || oldOrNew === 'new' || (!_.isEqual(newArgs, theData.oldArgs) && !_.isEmpty(newArgs))) {
          theData.key = factory.getRandomNumber();
          if (mainArgs.length > 0)
            theData.oldArgs = newArgs;

          // call the api
          // we put key outside the api call because there is already a request going on
          contactService.contactFamilyInfoGet(familyId).then((response) => {
            resolve(response);
            theData.data = response.data;
          });
        } else {
          // will loop until apiRefactorData has a data
          var looper = function () {
            $timeout(() => {
              if (_.isUndefined(theData.data) || _.isEmpty(theData.data) || _.isNull(theData.data)) {
                looper();
              } else {
                resolver();
              }
            }, 250);
          };
          // the function to call when apiRefactorData returns a data
          var resolver = function () {
            resolve(theData);
          };
          // initiate the loop
          looper();
        }
      });
    },
    // next functions here

  });

  return factory;
}]);
