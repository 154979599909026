import { complianceReviewRatingBuilderForUI } from 'Common/mappers/complianceReviewRating';
import { accessControlMatrixesForUI } from 'Common/mappers/accessControlMatrixes';
import { contactsByUserOrgBuilderForUI } from 'Common/mappers/contactsByUserOrg';
import { teamContactDetailBuilderForUI } from 'Common/mappers/teamContactDetail';
import {
  preferredAdvisersBuilderForUI,
  adviserProfileBuilderForUI,
  adviserProfileBuilderForMyCRM,
  workingDaysBuilderForUI,
} from 'Common/mappers/corporate';
import {
  operatingCountriesBuilderForUI,
  reportingRegionsBuilderForUI,
  reportingStatesBuilderForUI,
} from 'Common/mappers/address';
import {
  importantDocumentDetailsBuilderForUI,
  importantDocumentDetailsBuilderForMyCRM,
} from 'Common/mappers/importantDocument';


class CorporateModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'corporate';
  }
  complianceReviewGet(adviserFamilyId, complianceReviewId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ComplianceReviewGet`, { complianceReviewId, adviserFamilyId })
      .then(response => response.data.map(complianceReviewRatingBuilderForUI));
  }
  complianceReviewSet(complianceObj) {
    return this.httpClient.post(`${this.apiBaseUrl}/ComplianceReviewSet`, {}, complianceObj);
  }
  accessControlMatrixes(pageName = null, tabName = null, subTabName = null, panelName = null, fieldName = null) {
    return this.httpClient.get(`/access-control/matrixes/`, { pageName, tabName, subTabName, panelName, fieldName })
      .then(response => response.data && response.data.map(accessControlMatrixesForUI));
  }
  operatingCountriesGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/OperatingCountriesGet`)
      .then(response => response.data.map(operatingCountriesBuilderForUI));
  }
  reportingRegionsGet(countryId = null) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReportingRegionsGet`, { countryId })
      .then(response => response.data.map(reportingRegionsBuilderForUI));
  }
  reportingStatesGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReportingStatesGet`)
      .then(response => response.data.map(reportingStatesBuilderForUI));
  }

  importantDocumentDetailsGet(documentId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ImportantDocumentDetailsGet`, { documentId })
      .then(response => importantDocumentDetailsBuilderForUI(response.data));
  }

  importantDocumentSet(data) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/ImportantDocumentSet`, importantDocumentDetailsBuilderForMyCRM(data));
  }
  contactsByUserOrganizationGet(searchName) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/organization/contacts`, { searchName })
      .then(response => response.data.map(contactsByUserOrgBuilderForUI));
  }

  teamContactDetailGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/team-contact-detail`)
      .then(response => response.data.map(teamContactDetailBuilderForUI));
  }
  isFirstFamilyLoan(familyId, loanId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/is-first-family-loan`, { familyId, loanId });
  }
  getAgreementPreferredAdvisers(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/agreement-preferred-advisers`, params)
      .then(response => response.data.map(preferredAdvisersBuilderForUI));
  }

  getFeaturesSettings() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/features/settings`);
  }

  putFeaturesSettings({ id, settingTypeId }) {
    return this.httpClient
      .put(`${this.apiBaseUrl}/features/${id}`, {}, { settingTypeId });
  }

  getAdviserProfile(adviserId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/adviser-profile`, { adviserId })
      .then(response => adviserProfileBuilderForUI(response.data));
  }

  setAdviserProfile(data) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/adviser-profile`, adviserProfileBuilderForMyCRM(data))
      .then(response => response.data);
  }

  getCommissionTiers(userId = 0, commissionTierId = null) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/CommissionTiersGet`, { userId, commissionTierId });
  }

  getLeadAllocationWorkingDays() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lead-allocation-working-days`)
      .then(response => response.data.map(workingDaysBuilderForUI));
  }
}

export default CorporateModelService;

