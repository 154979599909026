import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import { SMS_SUBSCRIPTION } from 'Common/constants/smsSubcription';
import { convertUtcToUserDate } from 'Common/utilities/date';
import { adviserAllocateSwal } from 'Common/utilities/adviserAllocateSwal';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { toastError } from 'Common/utilities/alert';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { leadsToOpportunityConvert } from 'Common/utilities/opportunityConversion';
import { PIPELINE_TYPES_STR, PIPELINE_TYPES_ID } from 'Common/constants/pipelineType';

angular.module('app').controller('ContactDetailCtrl',
  function ContactDetailCtrl($scope,
    $http,
    $timeout,
    $interval,
    $uibModal,
    $state,
    $stateParams,
    $filter,
    contactService,
    corporateService,
    userService,
    mapService,
    $sce,
    SweetAlert,
    $rootScope,
    NgTableParams,
    toaster,
    $cookies,
    dashboardService,
    pipelineService,
    utilitiesService,
    loanScenarioService,
    contactSharedData,
    generalService,
    broadcastDataService,
    loanAppSharedData,
    commonFnService,
    timeoutService,
    uiService,
    $q,
    $window,
    $log,
    modalRenderService,
    pipelineSharedData,
    clientInfoService,
    loanAppDetailsService,
    contactSharedDataService,
    currentUserService,
    opportunityNewModalService) {
    // state params
    $scope.familyId = $state.params.familyId;
    $scope.brokerBasicinfo = {};
    $scope.uiService = uiService;

    generalService.isClientNotCorpLockedOut($stateParams.familyId).then((res) => {
      if (!res.data) {
        if ($window.history.length > 2) {
          $window.history.back();
        } else {
          $state.go('app.contacts');
        }
        swal('Security Restriction', 'Access to this record is restricted for Corporate users', 'error');
      }
    });

    generalService.isClientNotCorpLockedOut($stateParams.familyId).then((res) => {
      if (!res.data) {
        if ($window.history.length > 2) {
          $window.history.back();
        } else {
          $state.go('app.contacts');
        }
        swal('Security Restriction', 'Access to this record is restricted for Corporate users', 'error');
      }
    });

    // Variables
    $rootScope.contacts = {};

    //  Normal Contact
    $scope.tabs = [];
    $scope.tabs.summary = 0;
    $scope.tabs.clients = 1;
    $scope.tabs.addresses = 2;
    $scope.tabs.lending = 3;
    $scope.tabs.insurance = 4;
    $scope.tabs.financials = 5;
    $scope.tabs.employment = 6;
    $scope.tabs.workflow = 7;
    // $scope.tabs['loan_application']  = 7;
    $scope.tabs.records = 8;
    $scope.tabs.emails = 9;
    $scope.tabs.notes = 10;
    $scope.tabs.documents = 11;
    $scope.defaultTabOnloadAction = '';
    $scope.contactHomeAddress = '';
    $scope.contactHomeAddressID = 0;
    $scope.allocatedAdviser = [];
    $scope.lastTwoSubstatusesArr = [];

    $scope.active = $state.params.activeTab || 'summary';

    $scope.contactModel = {
      isSmsEnabled: false,
      isCorporateUser: false,
    };
    $scope.aLIQuoteEnabled = false;
    $scope.showSystemInfo = false;

    $scope.toggleDropDown = (bool) => {
      $scope.showSystemInfo = bool;
    };

    const getSmsEnabledSettings = (adviserFamilyId) => {
      corporateService.familyBrokerSupportGet(adviserFamilyId, 0)
        .then((response) => {
          if (!response || !response.data) return false;
          const { data } = response;
          $scope.contactModel.isSmsEnabled = data.SMSEnabled && $scope.contactModel.isSmsEnabled;
        });
    };
    function isCorporateUser() {
      contactService.getUserInfo().then((response) => {
        const { data } = response;
        if (data) {
          $scope.aLIQuoteEnabled = data.ALIQuoteEnabled;
          if (data.AccessType === ACCESS_TYPE.CORPORATE) {
            $scope.contactModel.isCorporateUser = true;
          }
          const assistantTypeList = [ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT];
          if (assistantTypeList.indexOf(data.AccessType) !== -1) {
            contactService.getContactAssignedAdviser($scope.familyId)
              .then((res) => {
                if (!res || !res.data) return false;
                getSmsEnabledSettings(res.data);
              });
          } else {
            $scope.contactModel.isSmsEnabled = data.SMSEnabled && $scope.contactModel.isSmsEnabled;
          }
        }
      });
    }

    $scope.selectNormalContacts = function (selectedContacts, defaultAction) {
      if (defaultAction === 'inprogressLoans' || defaultAction === 'loans') {
        contactSharedData.summaryData.destinationTabToLending = defaultAction;
      }
      $scope.selectedContacts = selectedContacts;

      if (defaultAction)
        $scope.defaultTabOnloadAction = defaultAction;
    };

    $scope.goToTabAndOpenModal = function (tab, defaultAction) {
      $scope.selectNormalContacts(tab, defaultAction);
    };

    $scope.openCreditGuideModal = () => {
      const isAdviserAsicMissing = currentUserService && (currentUserService.accessType === ACCESS_TYPE.PRINCIPAL_ADVISER || currentUserService.accessType === ACCESS_TYPE.ADVISER) && $scope.brokerBasicinfo && !$scope.brokerBasicinfo.asicId;
      if (isAdviserAsicMissing) {
        swal({
          title: 'Oops!',
          text: `It seems your broker profile may be missing some info necessary to generate the credit guide. Please contact Onboarding team to correct this info on <a href="mailto:onboarding@loanmarket.com.au">onboarding@loanmarket.com.au</a>.`,
          type: 'error',
          html: true,
          showConfirmButton: true,
        });
        return;
      }
      const contactDetails = {
        familyID: $scope.familyId,
        clientName: $scope.clientHeaderName,
        familyInfo: $scope.getContactFamilyInfo,
        adviserName: $scope.adviserName,
        clients: $scope.client.information,
      };
      const props = {
        contactDetails,
      };
      $uibModal.open({
        template: `<send-credit-guide-modal modal-instance="vm.modalInstance" contact-details="vm.props.contactDetails"></send-credit-guide-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => props,
        },
      });
    };

    let actionButtonInterval = '';
    if ($scope.familyId) {
      if ($state.params.activeTab) {
        $scope.tabs && Object.keys($scope.tabs).forEach((x) => {
          if (x === $state.params.activeTab) {
            $scope.selectedContacts = x;
            $scope.active = x;
          }
        });
        // Please don't remove this!
        if ($scope.active === 'lending' && $state.params.loanId) {
          if ($state.params.inprogress) {
            actionButtonInterval = $interval(() => {
              const $ele = angular.element(`#loansContainer .loan-detail-item #loan-${$state.params.loanId} .lending-manage-btn .lending-manage-menu li.menu-section:nth-child(1) ul.menu-items li a`);
              if ($ele.length) {
                $interval.cancel(actionButtonInterval);
                const eventTimeout = $timeout(() => {
                  $ele.click();
                }, 0);
                $scope.$on('$destroy', () => $timeout.cancel(eventTimeout));
              }
            }, 1000);
          }
        }
      } else {
        $scope.selectedContacts = 'summary';
      }
      $scope.selectNormalContacts($scope.selectedContacts);
    }

    $scope.pipelineBanner = true;
    $scope.cancelPipelineBanner = function () {
      $scope.pipelineBanner = false;
    };
    $scope.cardsMoveToNextStatus = {};
    $scope.pipelineType = '';
    $scope.cardId = 0;
    $scope.isolateStatus = '';
    $scope.nextPtr = 1;
    $scope.nextLocation = {};

    $scope.$watch('nextPtr', () => {
      switch ($scope.nextPtr) {
      case 2:
        $scope.nextLocation = { left: 345 };
        break;
      case 3:
        $scope.nextLocation = { left: 645 };
        break;
      case 4:
        $scope.nextLocation = { left: 944 };
        break;
      case 5:
        $scope.nextLocation = { left: 1244 };
        break;
      default:
        $scope.nextLocation = { display: 'none' };
        break;
      }
    });

    $scope.leadStat = false;
    $scope.appStat = false;
    pipelineService.SettingsStatusGet(false).then((response) => {
      $scope.pipelineStatusList = [];
      const pipelineStatusList = [];
      _.forEach(response.data, (o) => {
        if (o.TypeName === 'Lead') {
          pipelineStatusList.push(
            {
              StatusID: o.PipelineStatusID,
              SubStatusName: 'Please select',
              pipelineSubStatusID: 0,
            }
          );

          _.forEach(o.SubStatus, (sub) => {
            pipelineStatusList.push(sub);
          });
        }
      });

      $scope.pipelineStatusList = pipelineStatusList;
    });
    $scope.isLast2Chosen = (cardObject, subStatusId) => {
      const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal({
        showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion($scope.getContactFamilyInfo.ReferralItem),
      });
      confirmationPopup.result.then((result) => {
        if (result.isOk) {
          pipelineService.PipelineCardsMoveToNextStatusSet({
            PipelineCardsID: cardObject.PipelineCardsID,
            ToStatusID: $scope.notProceededObject.PipelineStatusID,
            reasonSubstatusID: subStatusId,
            shouldSendNurtureCampaigns: result.shouldSendNurtureCampaigns,
          }).then(() => {
            $scope.PipelineCardsTimeLineViewGet();
          });
        }
      });
    };
    $scope.changeSubStatus = function (cardObj, subStatusId) {
      const isLast2 = _.includes($scope.lastTwoSubstatusesArr, subStatusId);
      if (isLast2) {
        $scope.isLast2Chosen(cardObj, subStatusId);
      } else {
        pipelineService
          .PipelineCards(cardObj.PipelineCardsID, {
            SubStatusID: cardObj.SubStatusId,
          })
          .then((response) => {
            toaster.pop('success', 'Set', 'Substatus has been Set successfully');
            if (response.data.Result) {
              cardObj.LastModifiedDate = response.data.LastUpdate;
            }
          })
          .catch(toastError);
      }
    };

    $scope.PipelineCardsTimeLineViewGet = function () {
      pipelineService.PipelineCardsTimeLineViewGet($scope.familyId).then((response) => {
        const { data } = response;
        const firstStatus = data && data.length && data[0];
        if (!data || !data.length) $scope.timelineData = [];
        if (!firstStatus || firstStatus.PipelineStatusID === PIPELINE_STATUS.NEW_OPPORTUNITY) return;

        $scope.timelineData = response.data;
        $scope.inProgress = false;
        $scope.almostDone = false;
        $scope.done = false;
        $scope.adjustLineIndex = -1;
        $scope.loanAmount = 0;
        $scope.loanScenarioId = 0;
        $scope.lender = '';
        $scope.lenderId = '';
        $scope.country = '';
        $scope.countHistoryIndex = -1;
        $scope.createdDate = '';


        for (let i = 0; i < $scope.timelineData.length; i++) {
          $scope.timelineData[i].done = false;
          if ($scope.timelineData[i].HistoryDate) {
            const rangeTime = _.range(0, i);
            _.forEach(rangeTime, (index) => {
              // check if previous timeline is not done yet current index timeline is done
              if ($scope.timelineData[i].HistoryDate) {
                $scope.timelineData[index].done = true;
                $scope.adjustLineIndex = index + 1;
              }
            });
            $scope.statusType = $scope.timelineData[i];
            $scope.timelineData[i].done = true;
            $scope.cardsMoveToNextStatus.PipelineCardsID = $scope.timelineData[i].PipelineCardsID;
            $scope.pipelineType = $scope.timelineData[i].PipelineType;
            $scope.loanAmount = $scope.timelineData[i].LoanAmount;
            $scope.lender = $scope.timelineData[i].Lender;
            $scope.loanScenarioId = $scope.timelineData[i].LoanScenarioID ? $scope.timelineData[i].LoanScenarioID : 0;
            $scope.cardId = $scope.timelineData[i].PipelineCardsID;
            $scope.lenderId = $scope.timelineData[i].LenderID;
            $scope.country = $scope.timelineData[i].Country;
            $scope.countHistoryIndex = i;

            if ($scope.timelineData[i].done) {
              $scope.nextPtr = $scope.timelineData[i].Sequence + 1;
            }
          } else if (!$scope.timelineData[i].done && $scope.adjustLineIndex === -1) {
            $scope.adjustLineIndex = i - 1;
          }
        }
        let timelineData;
        if ($scope.countHistoryIndex >= -1) {
          timelineData = $scope.timelineData[$scope.countHistoryIndex];
        } else {
          $scope.timelineData = [];

          return;
        }


        /**
           * continue if timelineData has value
           * */

        if (timelineData) {
          $scope.adviserName = timelineData.Adviser;
          $scope.createdDate = timelineData.HistoryDate;

          $scope.cardDetails = angular.copy(timelineData);

          const dateArr = $scope.createdDate.split('T');
          const splittedDate = dateArr[0];

          const dateFull = new Date(splittedDate);
          const date = dateFull.getDate();
          const today = new Date();
          const todaysDate = today.getDate();
          if (date === todaysDate) {
            $scope.day = 'Today';
          } else if (date === (todaysDate - 1)) {
            $scope.day = 'Yesterday';
          } else {
            $scope.day = '';
          }

          $scope.getStatus($scope.pipelineType, $scope.statusType);
          $scope.getLoanApplications($scope.cardId);

          const offsetArray = [0, 0, 54, 42, 35, 35, 33, 31, 30, 30];
          if ($scope.timelineData.length > 9) {
            $scope.offsetForPileline = 20 + ($scope.timelineData.length);
          } else
            $scope.offsetForPileline = offsetArray[$scope.timelineData.length];
        } else {
          $scope.timelineData = [];
        }
      });
    };

    // auto-updated-when-changed-listener
    $scope.$watch(() => { return contactSharedData.lendingSharedData.updateLinkToLoan; }, () => {
      const updateLinkToLoan = contactSharedData.lendingSharedData.updateLinkToLoan;
      if (updateLinkToLoan) {
        contactSharedData.lendingSharedData.updateLinkToLoan = false;
        if ($scope.cardId) {
          $scope.getLoanApplications($scope.cardId);
        } else {
          $scope.PipelineCardsTimeLineViewGet();
        }
      }
    });

    $scope.$watch(() => { return contactSharedData.lendingSharedData.referenceUpdate; }, (nv, ov) => {
      if (nv.referenceNumber !== ov.referenceNumber && !nv.isUpdating) {
        $scope.lendingSharedData.referenceUpdate = nv;
        if (nv.referenceNumber !== 0 && nv.loanScenarioID === $scope.loanScenarioId) {
          $scope.referenceNo = nv.referenceNumber;
        }
      }
    });

    angular.extend($scope, {
      lendingSharedData: {},
      getLoanDetails() {
        if ($scope.timelineData[0] && $scope.timelineData[0].LoanScenarioID) {
          if (typeof $scope.timelineData[0].LoanScenarioID !== 'undefined') {
            loanScenarioService.scenarioDetailsGet($scope.timelineData[0].LoanScenarioID).then((response) => {
              if (typeof response.data !== 'undefined' && response.data) {
                if (response.data && parseInt(response.data.LoanId, 10)) {
                  contactService.loanDetailsGet(response.data.LoanId).then(() => {
                    $scope.referenceNo = (response.data.loan && response.data.loan.ReferenceNumber) || null;
                  });
                }
              }
            });
          }
        }
      },
      getLoanScenarioDetails(scenarioId) {
        loanScenarioService.scenarioDetailsGet(scenarioId).then((response) => {
          if (response.data && parseInt(response.data.LoanId, 10)) {
            $scope.saveReferenceNo(response.data.LoanId);
          } else toaster.pop('error', 'Unable to set get loan details');
        });
      },
      saveReferenceNo(loanId) {
        contactService.loanImportantDatesSet(loanId, '', '', $scope.storedRefno).then((response) => {
          if (response.status === 200) {
            toaster.pop('success', 'Reference number updated successfully.');
            contactSharedData.pipelineStatusData.referenceUpdate = {
              LoanScenarioID: $scope.loanScenarioId,
              ReferenceNumber: $scope.storedRefno,
              isUpdating: false,
            };
          } else toaster.pop('warning', 'Reference number failed to update.');
        });
      },
      item: {
        storedRefno: '',
        updateReference(refno) {
          if (refno && refno !== $scope.storedRefno) {
            $scope.storedRefno = refno;
            // get loanId using LoanScenarioID
            if ($scope.timelineData && $scope.timelineData.length > 0) {
              if (typeof $scope.timelineData[0].LoanScenarioID !== 'undefined') {
                $scope.getLoanScenarioDetails($scope.timelineData[0].LoanScenarioID);
              }
            }
          } else {
            $timeout(() => {
              contactSharedData.pipelineStatusData.referenceUpdate.isUpdating = false;
            }, 1000);
          }
        },
        storeReference(refno) {
          $scope.storedRefno = refno;
          contactSharedData.pipelineStatusData.referenceUpdate = {
            LoanScenarioID: $scope.loanScenarioId,
            ReferenceNumber: $scope.storedRefno,
            isUpdating: true,
          };
        },
      },
    });

    $scope.$watch('timelineData', () => {
      if ($scope.timelineData && $scope.timelineData.length > 0) {
        if (typeof $scope.timelineData[0].LoanScenarioID !== 'undefined') {
          $scope.getLoanDetails();
        }
      }
    });

    // watcher for changes in inProgress Loans
    $scope.$watch(() => { return contactSharedData.lendingSharedData.inProgressToPipeline; }, () => {
      const obj = contactSharedData.lendingSharedData.inProgressToPipeline;
      if (obj.statusObj.PipelineStatusID) {
        $scope.PipelineCardsTimeLineViewGet();
      }
    });

    $scope.PipelineCardsTimeLineViewGet();
    $scope.getLoanApplications = function (cardId) {
      loanScenarioService.scenarioListGet($scope.familyId).then((response) => {
        $scope.loanApplications = response.data;
        for (let i = 0; i < $scope.loanApplications.length; i++) {
          $scope.formattedDate = $filter('date')($scope.loanApplications[i].DateCreated, 'dd MMM yyyy');
          let textField;
          if (typeof $scope.loanApplications[i].LenderName === 'undefined') {
            textField = $scope.formattedDate;
          } else {
            textField = `${$scope.loanApplications[i].LenderName} ${$scope.formattedDate}`;
          }
          $scope.loanApplications[i].loanAppName = textField;
          $scope.loanApplications[i].PipelineCardID = cardId;
        }
        // filter NotProceeded LoanTypes
        $scope.loanApplications = _.filter($scope.loanApplications, (l) => {
          return l.StatusName === 'Ongoing';
        });

        $scope.loanApplications.splice(0, 0, { LoanScenarioId: -1, loanAppName: 'Select Loan Application', PipelineCardID: cardId });
        $scope.loanApp = $scope.loanApplications[0];
      });
    };
    $scope.linkLoanToPipeline = function (loanApp) {
      if (parseInt(loanApp.LoanScenarioId, 10) === -1) {
        toaster.pop('error', 'Error', 'Yikes! Please select valid Loan Application');
      }
      $scope.loanAppObj = {};
      $scope.loanAppObj.pipelineCardsID = loanApp.PipelineCardID;
      $scope.loanAppObj.loanScenarioID = loanApp.LoanScenarioId;

      pipelineService
        .PipelineCardsLoanScenarioSet($scope.loanAppObj)
        .then(() => {
          toaster.pop('success', 'Linked', 'Loan Application has been linked successfully.');
          $scope.PipelineCardsTimeLineViewGet();
          loanAppDetailsService.loadLoanList = true;
        })
        .catch(toastError);
    };
    $scope.getStatus = function (pipelineType, statusType) {
      if (_.isUndefined(statusType)) {
        return false;
      }

      pipelineService.SettingsStatusGet(false).then((response) => {
        $scope.statuses = response.data;

        const settled = { BrokerBrandingID: 0,
          CategoryName: 'Application in Progress',
          PipelineCategoryID: 2,
          PipelineStatus: 'Settled',
          PipelineStatusID: 100,
          TypeName: 'Application',
        };
        const notProceeded = { BrokerBrandingID: 0,
          CategoryName: 'Application in Progress',
          PipelineCategoryID: 2,
          PipelineStatus: 'Not Proceeded',
          PipelineStatusID: 101,
          TypeName: 'Application',
        };
        const convertToApplication = { BrokerBrandingID: 0,
          CategoryName: 'New Lead',
          PipelineCategoryID: 1,
          PipelineStatus: 'Convert To Application',
          PipelineStatusID: 7,
          TypeName: 'Lead',
        };
        const convertToOpportunity = { BrokerBrandingID: 0,
          CategoryName: 'New Opportunity',
          PipelineCategoryID: 3,
          PipelineStatus: 'Convert To Opportunity',
          PipelineStatusID: 13,
          TypeName: 'Lead',
        };
        const notProceededLead = { BrokerBrandingID: 0,
          CategoryName: 'New Lead',
          PipelineCategoryID: 1,
          PipelineStatus: 'Not Proceeded',
          PipelineStatusID: 101,
          TypeName: 'Lead',
        };

        $scope.statuses.push(settled);
        $scope.statuses.push(notProceeded);
        $scope.statuses.push(convertToApplication);
        $scope.statuses.push(convertToOpportunity);
        $scope.statuses.push(notProceededLead);
        $scope.leadsStatus = [];
        $scope.applicationStatus = [];
        $scope.notProceededObject = [];
        for (let i = 0; i < $scope.statuses.length; i++) {
          if (statusType.PipelineType === 'Leads') {
            $scope.leadStat = true;
            $scope.appStat = false;
            if ($scope.statuses[i].TypeName === 'Lead') {
              $scope.leadsStatus.push($scope.statuses[i]);
            }

            _.remove($scope.pipelineStatusList, (o) => {
              return o.TypeName === 'Application' || o.TypeName === 'Opportunity';
            });
          } else {
            $scope.appStat = true;
            $scope.leadStat = false;
            if ($scope.statuses[i].TypeName === 'Application') {
              $scope.applicationStatus.push($scope.statuses[i]);
            }

            _.remove($scope.pipelineStatusList, (o) => {
              return o.TypeName === 'Lead' || o.TypeName === 'Opportunity';
            });
          }
        }
        $scope.notProceededObject = _.find($scope.leadsStatus, objectLeadStatus => objectLeadStatus.PipelineStatus === 'Not Proceeded');
        if (!statusType.PipelineStatusID) {
          $scope.statusTypeLead = $scope.leadsStatus[0];
        } else {
          for (let j = 0; j < $scope.leadsStatus.length; j++) {
            if (parseInt($scope.leadsStatus[j].PipelineStatusID, 10) === parseInt(statusType.PipelineStatusID, 10)) {
              $scope.statusTypeLead = $scope.leadsStatus[j];
              return;
            }
          }
        }

        if (statusType.PipelineStatusID === undefined) {
          $scope.statusTypeApp = $scope.applicationStatus[0];
        } else {
          for (let k = 0; k < $scope.applicationStatus.length; k++) {
            if (parseInt($scope.applicationStatus[k].PipelineStatusID, 10) === parseInt(statusType.PipelineStatusID, 10)) {
              $scope.statusTypeApp = $scope.applicationStatus[k];
              return;
            }
          }
        }
      });
    };
    $scope.leadStat = false;
    $scope.appStat = false;

    $scope.$watch(() => { return loanAppSharedData.lendingTabPipelineStatusData; }, (nv) => {
      if (nv && nv.PipelineStatusID) {
        $scope.PipelineCardsTimeLineViewGet();
        $scope.pipelineBanner = true;
        const statusChanged = {
          LoanScenarioID: $scope.loanScenarioId,
          StatusID: nv.PipelineStatusID,
        };
        if ($scope.statusTypeApp && $scope.statusTypeApp.PipelineStatusID) {
          statusChanged.StatusID = $scope.statusTypeApp.PipelineStatusID;
        }

        contactSharedData.pipelineStatusData.statusUpdate = statusChanged;
      }
    }, true);

    $scope.pipelineCardsMoveNextProcedure = (pipelineStatus) => {
      pipelineService
        .PipelineCardsMoveToNextStatusSet($scope.cardsMoveToNextStatus)
        .then(() => {
          toaster.pop('success', 'Moved', 'Status has been updated successfully.');
          $rootScope.$broadcast('UPDATE_LENDING_ON_CHANGE_STATUS', true);
          $scope.PipelineCardsTimeLineViewGet();
          $scope.pipelineBanner = true;
          const statusChanged = {
            LoanScenarioID: $scope.loanScenarioId,
            StatusID: pipelineStatus.StatusID,
          };
          if ($scope.statusTypeApp && typeof $scope.statusTypeApp.PipelineStatusID !== 'undefined') {
            statusChanged.StatusID = $scope.statusTypeApp.PipelineStatusID;
          }

          contactSharedData.pipelineStatusData.statusUpdate = statusChanged;
        })
        .catch(toastError);
    };

    $scope.openPipelineAppMovedToSettledModal = function (size, familyID, LoanScenarioID, pipelineStatus) {
      loanScenarioService.scenarioDetailsGet(LoanScenarioID).then((response) => {
        const $modalInstance = $uibModal.open({
          templateUrl: '/assets/views/pipeline/leads/partials/moved_settled_modal.html',
          controller: 'PipelineAppMoveToSettledModalCtrl',
          resolve: {
            LoanScenarioID: () => response.data.LoanId,
            familyID: () => familyID,
          },
          size,
        });

        $modalInstance.result.then(() => {
          $scope.pipelineCardsMoveNextProcedure(pipelineStatus);
        }, () => {
          $scope.statusTypeApp.PipelineStatusID = $scope.statusType.PipelineStatusID;
        });
      });
    };

    $scope.updateStage = function (pipelineStatus, origValueInString) {
      $scope.cardsMoveToNextStatus.ToStatusID = pipelineStatus.PipelineStatusID;
      if ($scope.cardsMoveToNextStatus.ToStatusID === LOAN_APP_STATUS_TYPES.NOT_PROCEEDED_STATUS_ID) {
        $scope.updateStageIsNotProceeding(pipelineStatus, origValueInString);
      } else if ($scope.cardsMoveToNextStatus.ToStatusID === LOAN_APP_STATUS_TYPES.SETTLE_STATUS_ID && pipelineStatus.TypeName === 'Application' && $scope.loanScenarioId) {
        $scope.openPipelineAppMovedToSettledModal('md', $scope.familyId, $scope.loanScenarioId, pipelineStatus);
      } else if ($scope.cardsMoveToNextStatus.ToStatusID === PIPELINE_STATUS.NEW_OPPORTUNITY) {
        const isContactOpener = 1;
        const tabName = 'details';
        const sourceFamilyId = $scope.familyId;
        const redirectParams = { sourceFamilyId, isContactOpener, tabName };

        leadsToOpportunityConvert($scope.familyId, contactService, opportunityNewModalService, redirectParams, $scope.cardId);
      } else if ($scope.statusType.PipelineStatusID !== pipelineStatus.PipelineStatusID) {
        $scope.pipelineCardsMoveNextProcedure(pipelineStatus);
      }
    };

    $scope.getApplicationReasonList = () => {
      pipelineService.getNotProceedReasons(PIPELINE_TYPES_ID.APPLICATION)
        .then(({ reasonList }) => {
          $scope.applicationReasonList = reasonList || null;
        });
    };
    $scope.getApplicationReasonList();

    $scope.populateSettingsReasonList = () => {
      pipelineService.settingsReasonGet().then((response) => {
        if (response.data) {
          $scope.settingsReasonList = response.data;
          _.forEach($scope.settingsReasonList, (object) => {
            $scope.lastTwoSubstatusesArr.push(object.PipelineSubStatusID);
          });
        }
      });
    };
    $scope.populateSettingsReasonList();
    $scope.updateStageIsNotProceeding = (pipelineStatus, origValueInString) => {
      const isLead = pipelineStatus.TypeName === 'Lead';
      const isApplication = pipelineStatus.TypeName.toLowerCase() === PIPELINE_TYPES_STR.APPLICATION;
      const reasonListLead = isLead && $scope.settingsReasonList;
      const reasonListApplication = isApplication && $scope.applicationReasonList;

      const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal({
        reasonList: reasonListLead || reasonListApplication,
        showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion($scope.getContactFamilyInfo.ReferralItem),
      });
      confirmationPopup.result.then((result) => {
        if (result.isOk) {
          if (isLead || isApplication) {
            $scope.cardsMoveToNextStatus.reasonSubstatusID = result.selectedReasonId;
          }
          $scope.cardsMoveToNextStatus.shouldSendNurtureCampaigns = result.shouldSendNurtureCampaigns;
          $scope.pipelineCardsMoveNextProcedure(pipelineStatus);
        } else {
          pipelineStatus.PipelineStatusID = Number(origValueInString);
        }
      });
    };
    // pipeline-end
    $scope.formatDate = function (date) {
      return new Date(date);
    };

    $scope.hiddenTabs = false;
    $scope.narrowNav = true;

    $scope.openHiddenTabs = function () {
      $scope.hiddenTabs = $scope.hiddenTabs === false;
    };

    $scope.closeHiddenTabs = function () {
      $scope.hiddenTabs = false;
    };
    $scope.open = function (size) {
      const modalInstance = $uibModal.open({
        templateUrl: 'insuranceDetailsModal.html',
        controller: 'ModalInstanceCtrl',
        size,
        resolve: {
          items() {
            return $scope.items;
          },
        },
      });
      modalInstance.result.then((selectedItem) => {
        $scope.selected = selectedItem;
      }, () => {
        $log.info(`Modal dismissed at: ${new Date()}`);
      });
    };

    $scope.filterInitial = function (FirstName, LastName) {
      return utilitiesService.filterInitial(FirstName || '', LastName || '');
    };

    // Client Summary Data
    //------------------------------------------------------------

    $scope.contactFamilyAddress = function () {
      if ($scope.familyId) {
        contactService.contactFamilyInfoGet($scope.familyId).then((response) => {
          $scope.getContactFamilyInfo = response.data;
          if (typeof $scope.getContactFamilyInfo.BrokerID !== 'undefined' && $scope.getContactFamilyInfo.BrokerID !== null) {
            $scope.getContactFamilyInfo.BrokerID = $scope.getContactFamilyInfo.BrokerID.toString();
          } else {
            $scope.getContactFamilyInfo.BrokerID = 0;
          }
          $scope.getContactFamilyInfo.AdviserFullNameInitials = utilitiesService.filterInitialOneString($scope.getContactFamilyInfo.AdviserFullName);

          $scope.getContactFamilyInfo.Source = $scope.getContactFamilyInfo.ReferralCategory ? $scope.getContactFamilyInfo.ReferralCategory.split('-') : null;

          angular.forEach($scope.getContactFamilyInfo.Addresses, (value) => {
            const contactValueAddress = typeof value.formatted_address !== 'undefined' ? value.formatted_address : '';
            const contactValueAddressID = typeof value.AddressID !== 'undefined' ? value.AddressID : 0;
            if (value.Type === 'Home' || value.Type === 'Current Address') {
              $scope.contactHomeAddress = contactValueAddress;
              $scope.contactHomeAddressID = contactValueAddressID;
            }
          });
        });
      }
    };
    $scope.getContactFamilyInfo = {};
    $scope.adviserSelected = function (data) {
      if (data) {
        /**
         * Timeout to delay user's keyboard activity
         */
        $timeout(() => {
          adviserAllocateSwal(
            data.title,
            (confirmed) => {
              contactSharedDataService.isAdviserContactList = true;
              if (!confirmed) return;

              $scope.getContactFamilyInfo.AdviserFullName = data.title;
              const adviserFamilyId = typeof data.originalObject !== 'undefined' ? data.originalObject.FamilyId : 0;
              if ($scope.contactModel.isCorporateUser) {
                corporateService.assignClientToAdviserSet(adviserFamilyId, $scope.familyId, 0).then(() => {
                  toaster.pop('success', 'Successfully', 'Assigned Adviser to Client');
                  commonFnService.tranferLoanApp(adviserFamilyId, $scope.familyId, data.title, $scope.clientHeaderName, 0);
                });
              } else {
                contactService.putAssignAdviser($scope.familyId, adviserFamilyId).then(() => {
                  toaster.pop('success', 'Successfully', 'Assigned Adviser to Client');
                  commonFnService.tranferLoanApp(adviserFamilyId, $scope.familyId, data.title, $scope.clientHeaderName, 0);
                });
              }
              contactSharedDataService.isAdviserContactList = false;
            });
        }, 500);
      }
    };

    $scope.saveAdviser = function () {
      $scope.listView = false;
    };


    function getRecentView() {
      broadcastDataService.recentView.get().then((res) => {
        broadcastDataService.recentView.list = res;
      });
    }

    $scope.getSummaryData = function (clientsData) {
      getRecentView();

      $scope.getClientInformation = () => {
        contactService.clientInformGet($scope.familyId)
          .then((response) => {
            $scope.client = {
              information: response.data,
            };
            const primaryClient = $scope.client.information.filter(item => item.isPrimary);
            $scope.aLIQuoteEnabled = (primaryClient.length) ? primaryClient[0].IsAllocatedAdviserALIQuoteEnabled : false;

            clientInfoService.getClientHeader($scope.client);
            $scope.clientHeaderName = $scope.client.headerName;
            $scope.clientInitials = $scope.client.initials;

            $scope.contactModel.isSmsEnabled = false;
            if ($scope.client.information.length) {
              let mobileCount = 0;
              $scope.client.information.forEach((contact) => {
                const filteredMobile = contact.Phone.filter(value => value.Type === 'Mobile');
                mobileCount += filteredMobile.length;
              });
              $scope.contactModel.isSmsEnabled = mobileCount || false;
            }
            isCorporateUser();
            $scope.contactFamilyAddress();
          });
      };

      $scope.getClientInformation();

      $scope.summaryData = clientInfoService.buildSummary(clientsData);
    };


    $scope.$watch('contactHomeAddress', (newValue) => {
      if (typeof newValue === 'undefined') {
        $scope.contactHomeAddress = 'New Zealand';
      }
      if ($scope.contactHomeAddressID && $scope.contactHomeAddressID > 0) {
        generalService.addressDetailsGet($scope.contactHomeAddressID).then((response) => {
          const responseData = response.data;
          if (responseData) {
            $scope.geoCoded = responseData.geoCoded || '';
            $scope.contactHomeAddressCountry = responseData.country || '';
            const googleInterval = $interval(() => {
              if (!$window.google) return false;
              const toolTipContent =
                `${'<div class="iw-container margin-left-10 margin-right-10 margin-top-10"><div>'}${
                  $scope.contactHomeAddress
                }</div></div>`;
              if (responseData.latitude && responseData.longitude) {
                mapService.mapDesign(responseData.latitude, responseData.longitude, $scope.contactHomeAddress, toolTipContent, true, 'contactHomeAddressMapSummary', '10');
              }
              $interval.cancel(googleInterval);
            }, 500);
          }
        });
      }
    });


    // Returns trusted URL for Google Map Embed CORS Issue
    //------------------------------------------------------------
    $scope.trustedSource = function (source) {
      return $sce.trustAsResourceUrl(source);
    };

    // Open a new tab
    //------------------------------------------------------------
    $scope.selectClientsTab = function () {
      $timeout(() => {
        angular.element('#clients').find('a').triggerHandler('click');
      }, 100);
    };

    // Summary Transaction
    //------------------------------------------------------------
    /*
// Non existing :
    $scope.summaryTransaction = [];
    contactService.transList($scope.familyId).then(function (response) {
      $scope.summaryTransaction = response.data;
    });
*/

    // Tagged List Data
    //------------------------------------------------------------
    // Tag Modal
    $scope.openTagModal = function (action, index) {
      const familyId = $scope.familyId;
      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/create_tag_modal.html',
        controller: 'OpenTagModalCtrl',
        scope: $scope,
        resolve: {
          index: () => index,
          action: () => action,
          familyId: () => familyId,
        },
      });
    };


    $scope.getTaggedList = function () {
      contactService.taggedList($scope.familyId).then((response) => {
        $scope.taggedListDetails = response.data;
      });
    };

    $scope.getTaggedList();

    // Print Summary
    //------------------------------------------------------------
    $scope.printSummaryDownloading = false;
    $scope.printSummary = function () {
      if ($scope.printSummaryDownloading) {
        toaster.pop('warning', 'Processing...', 'Still downloading contact summary pdf');
      }
      $scope.printSummaryDownloading = true;
      contactService.contactSummaryPDFGet($scope.familyId).then((response) => {
        const eventTimeout = $timeout(() => {
          const data = response.data;
          const currentDate = $filter('date')(new Date(), 'MM-dd-yy');
          const element = $window.document.createElement('a');
          element.setAttribute('href', `data:${data.ContentType};base64,${data.DocumentContent}`);
          element.setAttribute('download', `contactsummary_${currentDate}.pdf`);

          element.style.display = 'none';
          $window.document.body.appendChild(element);

          element.click();

          $window.document.body.removeChild(element);
          $scope.printSummaryDownloading = false;
        });
        $scope.$on('$destroy', () => $timeout.cancel(eventTimeout));
      });
    };

    // Recent Activity List Data
    //------------------------------------------------------------
    $scope.recentActivityList = {};
    $scope.pages = [];
    $scope.recentActivityArray = [];

    if ($scope.familyId) {
      contactService.geRecentActivityList($scope.familyId).then((response) => {
        $scope.recentActivityList = response.data;
        $scope.recentActivityArray = _.chunk($scope.recentActivityList, 5);
        $scope.showIndex = 1;
      });
    }


    const viewport = () => {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = $window.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function () {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch($scope.getWindowWidth, () => {
      // For Slick Carousel Slide Height Change
      $scope.updateSlickHeight();
    }, true);


    $scope.updateSlickHeight = function () {
      $timeout(() => {
        angular.element('.slick-list').height(angular.element('.slide.slick-active').height());
      }, 500);
    };

    $scope.PrevActivity = function () {
      angular.element('.carousel').slick('slickPrev');
      $scope.updateSlickHeight();
    };
    $scope.nextActivity = function () {
      angular.element('.carousel').slick('slickNext');
      $scope.updateSlickHeight();
    };


    // Document
    $scope.documentUpload = {};
    $scope.documentUpload.isUploading = false;
    $scope.documentUpload.progress = {
      percent: 0,
      total: 0,
      loaded: 0,
    };

    $scope.convertToDate = function (date) {
      return $filter('date')(new Date(date), 'dd MMM yyyy');
    };

    $scope.contactFamilyAddress();

    $scope.isDateValid = function (date) {
      const d = new Date(date);
      return parseInt(d.getFullYear(), 10) > 1;
    };

    $scope.convertUtcToUserDate = convertUtcToUserDate;

    // sms subscription
    angular.extend($scope, {
      sendSms() {
        const vm = this;
        if ($scope.contactModel && !$scope.contactModel.isSmsEnabled) return;
        if ($rootScope.crmCountry === 'New Zealand') {
          generalService.SMSSubscriptionStatusGet($scope.familyId).then((response) => {
            if (_.size(response.data) === 0) {
              //  show the subscription modal
              vm.openSMSSubscriptionModal();
            } else if (_.size(response.data) > 0 && _.first(response.data).SMSSubscriptionId === SMS_SUBSCRIPTION.SUBSCRIPTION_FOR_APPROVAL) {
              //  show the subscription pending modal
              vm.openSMSSubscriptionPendingModal();
            } else {
              //  proceed
              $state.go('app.corporateSendSms',
                {
                  familyId: vm.familyId,
                  contactType: 'client',
                  isFromContact: true,
                }
              );
            }
          });
        } else {
          $state.go('app.corporateSendSms',
            {
              familyId: vm.familyId,
              contactType: 'client',
              isFromContact: true,
            }
          );
        }
      },
      openSMSSubscriptionModal() {
        const vm = this;
        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/modals/sms_subscription.html',
          controller: 'OpenSMSSubscriptionModalCtrl',
          size: 'md',
          resolve: {
            familyId: () => {
              return vm.familyId;
            },
          },
          windowClass: 'sms-subscription',
        });

        modalInstance.result.then(() => {
          // close
          vm.openSMSSubscriptionPendingModal();
        }, () => {
          // dismiss
        });
      },
      openSMSSubscriptionPendingModal() {
        $uibModal.open({
          templateUrl: '/assets/views/modals/sms_pending.html',
          controller: 'OpenSMSSubscriptionPendingModalCtrl',
          size: 'md',
          windowClass: 'sms-subscription',
        });
      },
    });

    $scope.deleteContact = function (clientName) {
      let contactClientName = clientName;
      if (typeof contactClientName === 'undefined') {
        contactClientName = 'this client';
      }
      SweetAlert.swal({
        title: 'Are you sure?',
        text: `Remove ${contactClientName} from contacts.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          contactService.deleteContact($scope.familyId)
            .then(() => {
              toaster.pop('success', 'Client Deleted', 'Client successfully deleted from contacts.');
              $state.go('app.contacts');
            }, (e) => {
              if (!_.isNil(e.data.Message)) {
                SweetAlert.swal('Delete Message', e.data.Message, 'error');
              } else {
                SweetAlert.swal('Delete Message', 'We are unable to delete client from contacts', 'error');
              }
            });
        }
      });
    };

    $scope.navigateTo = (state, familyId, contactType) => {
      $state.go(state, { familyId, contactType });
    };

    $scope.openNewCreditCheckModal = () => {
      const contactForCreditCheck = {
        FamilyID: $scope.familyId,
        ClientFirstName: $scope.clientHeaderName,
        ClientLastName: '',
      };

      const modalInstance = $uibModal.open({
        templateUrl: 'assets/views/creditChecks/set_credit_checks_modal.html',
        controller: 'SetCreditChecksModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        windowClass: 'credit-checks-modal-window',
        resolve: {
          selectedContact: () => contactForCreditCheck,
        },
      });

      if (modalInstance && modalInstance.result) {
        modalInstance.result
          .then((response) => {
            $scope.handleCreditCheckModalResponse(response);
          });
      }
    };

    $scope.handleCreditCheckModalResponse = (response) => {
      if (!response) return;
      const modalTimeout = $timeout(() => {
        swal({
          title: 'Credit check arranged',
          text: '',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#42B4B4',
          confirmButtonText: 'Got it',
          closeOnConfirm: true,
        });
      }, 300);
      timeoutService.addTimeout(modalTimeout, WATCHER_CONTEXT.CONTACT_DETAILS_CTRL);
    };

    $scope.showCreditCheckButton = () => {
      return uiService.isCountry('Australia') && uiService.showCreditCheck;
    };

    $scope.getBrokerBasicinfo = () => {
      generalService.getBrokerBasicinfo()
        .then((response) => {
          if (!response) return;
          $scope.brokerBasicinfo = response;
        });
    };
    $scope.getBrokerBasicinfo();

    $scope.$on('$destroy', () => {
      timeoutService.cancelTimeouts(WATCHER_CONTEXT.CONTACT_DETAILS_CTRL);
    });
  });
