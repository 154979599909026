export default class PipelineStatsCtrl {
  $onInit() {
    this.proceededStatsOptions = this.initStatsOptions('proceeded');
    this.notProceededStatsOptions = this.initStatsOptions('not-proceeded');
  }

  $onChanges(changes) {
    if (changes.proceededStatsValue) {
      this.roundedProceededStatsValue = Math.round(changes.proceededStatsValue.currentValue);
    }
    if (changes.notProceededStatsValue) {
      this.roundedNotProceededStatsValue = Math.round(changes.notProceededStatsValue.currentValue);
    }
  }

  initStatsOptions(type) {
    return {
      displayPrevious: false,
      barCap: 3,
      trackWidth: 3,
      barWidth: 3,
      size: 36,
      readOnly: true,
      trackColor: 'transparent',
      unit: '%',
      barColor: this.getStatTypeColor(type),
      textColor: this.getStatTypeColor(type),
    };
  }

  getStatTypeColor(type) {
    switch (type) {
    case 'proceeded':
      return this.proceededStatsColor;
    case 'not-proceeded':
      return this.notProceededStatsColor;
    default:
      return null;
    }
  }
}
