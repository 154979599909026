import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular.module('app').controller('OpenCreateTaskModalCtrl',
  function OpenCreateTaskModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    $timeout,
    toaster,
    tasksSharedData,
    taskService,
    dashboardService,
    $stateParams,
    init,
    currentUserService,
    contactModelService
  ) {
    $scope.taskSet = {
      FamilyId: 0,
      TaskType: 'Business',
      Notes: '',
    };
    $scope.mainObject = {};
    $scope.mainObject.isSearchEnable = false;
    $scope.mainObject.datePopUp = false;
    $scope.format = 'dd MMM yyyy';
    $scope.isSaveTaskDisable = false;
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    $scope.init = init;

    // Open Date Popup
    $scope.openDate = function () {
      $scope.mainObject.datePopUp = !$scope.mainObject.datePopUp;
    };
    $scope.updateFamilyOnAdviserChange = tasksSharedData.updateFamilyOnAdviserChange;
    $scope.closeFamilyList = function () {
      $scope.taskInput.familyValue = '';
      $scope.mainObject.isSearchEnable = false;
      $scope.familyDetailsList = [];
    };
    if ($scope.taskName) {
      $scope.taskSet.Title = $scope.taskName;
    }
    $scope.selectFamily = function (dataId, dataFullname) {
      $scope.mainObject.isSearchEnable = false;
      $scope.taskInput.familyValue = dataFullname;
      $scope.taskSet.FamilyId = dataId;
      $scope.familyDetailsList = [];
    };
    $scope.$on('$destroy', () => {
      $timeout.cancel($scope.dismissTimeout);
      $scope.dismissTimeout = null;
    });
    if (parseInt($scope.task_id, 10) !== 0) {
      taskService.taskDetailsGet($scope.task_id).then((respond) => {
        $scope.taskSet = respond.data;

        $scope.selectFamily($scope.taskSet.FamilyID, $scope.taskSet.ClientFamilyName);
        $scope.taskSet.DueDate = new Date($scope.taskSet.DueDate);
        $scope.taskSet.CreatedByUser = String($scope.taskSet.CreatedByUser);
        if (typeof $scope.taskSet.Notes === 'undefined' || $scope.taskSet.Notes.trim() === '')
          $scope.showNotes = false;
        else
          $scope.showNotes = true;
      });
    } else {
      $scope.taskSet.DueDate = new Date();
    }
    $scope.showNotes = true;
    if (typeof $scope.taskSet.Notes === 'undefined' || $scope.taskSet.Notes.trim() === '') {
      $scope.showNotes = false;
    }
    $scope.addNotes = function () {
      const addNotesNote = $scope.taskSet.Notes;
      if (typeof addNotesNote === 'undefined' || addNotesNote.trim() === '') {
        $scope.showNotes = true;
      }
    };
    $scope.isCorporateUser = currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    $scope.isNzBased = COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
    const isLoanWriter = $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
    contactModelService.taskAdviserListGet(isLoanWriter).then((respond) => {
      $scope.taskAdviserList = respond.data;
      $scope.taskAdviserList = _.reject(respond.data, ['BrokerID', 0]);
      angular.forEach(respond.data, (item) => {
        item.BrokerID = item.BrokerID.toString();
      });
      if (parseInt($scope.task_id, 10) === 0) {
        dashboardService.getBrokerBasicInfo().then((dashboardServicerResponse) => {
          $scope.taskSet.assignedClientID = dashboardServicerResponse.data.BrokerId;
          $scope.taskSet.CreatedByUser = dashboardServicerResponse.data.BrokerId;
        });
      }
    });
    $scope.taskInput = {};
    $scope.searchFamilyInput = function () {
      $scope.mainObject.isSearchEnable = true;
      taskService.searchFamily($scope.taskInput.familyValue, 'TaskClient', $scope.taskSet.assignedClientID).then((response) => {
        if (typeof response.data !== 'undefined') {
          $scope.familyDetailsList = response.data;
        }
      });
    };
    contactService.getAllByFilter('*').then((respond) => {
      angular.forEach(respond.data.FamilyList, (value) => {
        if (value.FamilyID === $scope.familyId) {
          $scope.taskInput.familyValue = value.FamilyFullName;
          if (parseInt($scope.task_id, 10) === 0) {
            $scope.taskSet.FamilyId = value.FamilyID;
          }
        }
      });
    });
    $scope.saveNewTask = function () {
      $scope.isSaveTaskDisable = true;
      if ($scope.taskInput.familyValue === '') {
        $scope.taskSet.FamilyId = 0;
      }
      const $postData = {
        FamilyID: $scope.taskSet.FamilyId || $stateParams.familyId,
        Title: $scope.taskSet.Title,
        assignedClientID: $scope.taskSet.assignedClientID,
        DueDate: moment($scope.taskSet.DueDate).format('YYYY-MM-DD'),
        Notes: $scope.taskSet.Notes,
      };

      const dismissModalTimeOut = () => {
        $scope.init();
        $scope.dismissTimeout = $timeout(() => {
          $uibModalInstance.dismiss('cancel');
        }, 100);
      };

      let msg;
      let serviceMethod;

      if ($scope.task_id) {
        $postData.TaskID = $scope.task_id;
        msg = 'Updated';
        serviceMethod = 'updateTask';
      } else {
        msg = 'Added';
        serviceMethod = 'storeTask';
      }
      taskService[serviceMethod]($postData)
        .then(() => {
          toaster.pop('success', msg, `Task successfully ${msg.toLowerCase()}`);
          dismissModalTimeOut();
        });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.isSaveDisable = () => {
      return $scope.isSaveTaskDisable || ($scope.loanDataList && $scope.loanDataList.length <= 0) || $scope.mainObject.type === 'view';
    };
  });
