import { LEADS_STATUS } from 'Common/constants/adviserStatus';

class UsersLeadManagementCtrl {
  constructor(
    corporateService
  ) {
    'ngInject';

    this.corporateService = corporateService;
  }

  getLeadProfile() {
    if (!this.familyId) return;
    this.corporateService.leadsProfileGet(this.familyId, 0)
      .then((response) => {
        this.isLoadingLeads = false;
        if (!response || !response.data) return;
        this.leadProfileInfo = response.data;
        this.isEditable = this.leadProfileInfo.ReceiveStatus !== 1 || false;
        this.isLeadsEnable = this.leadProfileInfo.ReceiveStatus === LEADS_STATUS.ACTIVE;
      })
      .catch(() => {
        this.isLoadingLeads = false;
      });
  }

  getLeadProfileAddress() {
    if (!this.familyId) return;
    this.mapAddressAtrr = {};
    this.corporateService.familyAddressGet(this.familyId)
      .then((response) => {
        if (!response || !response.data) return;
        const bussinessAddressValue = response.data.filter(address => address.isBusiness);
        const mailingAddressValue = response.data.filter(address => address.isMailing);
        const homeAddressValue = response.data.filter(address => !address.isMailing && !address.isBusiness);
        if (bussinessAddressValue && bussinessAddressValue.length) {
          this.mapAddressAtrr = bussinessAddressValue[0];
        } else if (mailingAddressValue && mailingAddressValue.length) {
          this.mapAddressAtrr = mailingAddressValue[0];
        } else {
          this.mapAddressAtrr = homeAddressValue && homeAddressValue.length ? homeAddressValue[0] : {};
        }
      });
  }

  $onInit() {
    this.isLoadingLeads = true;
    this.getLeadProfile();
    this.getLeadProfileAddress();
  }
}


export default UsersLeadManagementCtrl;
