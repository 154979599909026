export function insurerFileBuilderForUI(thumbnailUrl, fileUrl, type) {
  return {
    thumbnailUrl,
    fileUrl,
    type,
  };
}

export function insuranceQuoteBrochureBuilderForUI(insuranceQuoteBrochure) {
  const policyBrochures = insuranceQuoteBrochure.Brochures && insuranceQuoteBrochure.Brochures.map((file) => {
    return insurerFileBuilderForUI(file.BrochureThumbnailUrl, file.BrochureUrl, 'Policy Brochure');
  });
  const policyDocuments = insuranceQuoteBrochure.Documents && insuranceQuoteBrochure.Documents.map((file) => {
    return insurerFileBuilderForUI(file.DocumentThumbnailUrl, file.DocumentUrl, 'Policy Document');
  });
  const downloadableFiles = [...(policyBrochures || []), ...(policyDocuments || [])];
  return {
    insurerId: insuranceQuoteBrochure.ProviderID,
    insurerName: insuranceQuoteBrochure.ProviderName,
    insurerLogoUrl: insuranceQuoteBrochure.ProviderLogoUrl,
    policyBrochures,
    policyDocuments,
    downloadableFiles,
  };
}

export function sendReportViaEmailBuilderForMyCRM(email) {
  return {
    BenefitIDs: email.benefitIds,
    ProviderIDs: email.providerIds,
    IncludeBrochure: email.includeBrochures,
    IncludeDocuments: email.includePolicyDocuments,
    SendToCurrentUser: email.sendToCurrentUser,
    FamilyID: parseInt(email.familyId, 10),
    QuoteID: parseInt(email.quoteId, 10),
    ReportOptions: email.reportOptions,
    Subject: email.subject,
    Content: email.body,
    HasAttachment: true,
  };
}

export function insuranceQuoteBenefitColorBuilderForUI(benefitColor) {
  return {
    benefitType: benefitColor.ID,
    color: benefitColor.ColorCode,
  };
}
