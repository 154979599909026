import { getCountryName, getCountryCode } from 'Common/utilities/user';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export function convertContentHtmlToRtfBuilderForUI(parameter) {
  return parameter && {
    contentType: parameter.ContentType,
    documentContent: parameter.DocumentContent,
    name: parameter.Name,
  };
}


export function userInfoBuilderForUI(user) {
  return {
    aLIQuoteEnabled: user.ALIQuoteEnabled,
    accessType: user.AccessType,
    brandingCategory: user.BrandingCategory,
    brandingCategoryID: user.BrandingCategoryID,
    campaignBreezeEnabled: user.CampaignBreezeEnabled,
    corpAccessTypeID: user.CorpAccessTypeID,
    corporateTeam: user.CorporateTeam,
    corporateTeamId: user.CorporateTeamId,
    countryId: user.CountryId,
    familyId: user.FamilyId,
    firstName: user.FirstName,
    lastName: user.LastName,
    fullName: user.FullName,
    gotItTicked: user.GotItTicked,
    isAllowCustomizedTheme: user.IsAllowCustomizedTheme,
    myCRMActive: user.MyCRMActive,
    nPSEnabled: user.NPSEnabled,
    preferredFullName: user.PreferredFullName,
    sMSEnabled: user.SMSEnabled,
    sMSEnabledDate: user.SMSEnabledDate,
    crmCountry: getCountryName(user.CountryId),
    isNZ: user.CountryId === COUNTRY_TYPE.NEW_ZEALAND,
    isAU: user.CountryId === COUNTRY_TYPE.AUSTRALIA,
    isID: user.CountryId === COUNTRY_TYPE.INDONESIA,
    reportingStateName: user.ReportingStateName,
    countryCode: getCountryCode(user.CountryId),
    isPrincipalAdviser: user.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER,
    isAdviser: user.AccessType === ACCESS_TYPE.ADVISER,
    isAdminAssistant: user.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT,
    isAssistant: user.AccessType === ACCESS_TYPE.ASSISTANT,
    isCorporate: user.AccessType === ACCESS_TYPE.CORPORATE,
    isReferrer: user.AccessType === ACCESS_TYPE.REFERRER,
  };
}

export function subscriptionBuilderForMyCRM(subscription) {
  return {
    BrokerID: subscription.brokerId,
    Description: subscription.description,
    FranchiseBillingDate: subscription.franchiseBillingDate,
    IsActive: subscription.isActive,
    IsCancelled: subscription.isCancelled,
    IsTrial: subscription.isTrial,
    MonthlyRecurringFee: subscription.monthlyRecurringFee,
    NextRenewDate: subscription.nextRenewDate,
    OrderedBy: subscription.orderedBy,
    OrderedById: subscription.orderedById,
    SubscriptionId: subscription.subscriptionId,
    SubscriptionProductId: subscription.subscriptionProductId,
    SubscriptionStartDate: subscription.subscriptionStartDate,
    Title: subscription.title,
    TermsAndConditionsLink: subscription.termsAndConditionsLink,
    SubDetailIDs: subscription.subDetailIDs,
    SubscriptionEndDate: subscription.subscriptionEndDate,
    IsImmediately: subscription.isImmediately,
  };
}

export function creditGuideBuilderForUI(res) {
  return {
    succeeded: res.succeeded,
    data: res.data,
    messages: res.messages,
  };
}

export function mergeFamilyProfileBuilderForUI(data, familyId) {
  return {
    succeeded: data,
    data: { familyId },
  };
}

export function subIncomeTypeBuilderForUI(subIncomeTypeList) {
  return {
    rentalTypeId: subIncomeTypeList.RentalTypeId,
    rentalIncomeName: subIncomeTypeList.RentalIncomeName,
  };
}
