import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { ProviderKeyContactRequest } from './providerKeyContactsRequest';

export default class ProviderKeyContactsCtrl {
  constructor(providerInformationService, uiService, currentUserService) {
    'ngInject';

    this.providerInformationService = providerInformationService;
    this.uiService = uiService;
    this.currentUserService = currentUserService;
    this.keyContactsProperty = new ProviderKeyContactRequest(this);
    this.filterState = { region: 'No State' };
    this.toggleForm = false;
    this.forAUonly = this.keyContactsProperty.forAUonly();
    this.forCorpUseronly = this.keyContactsProperty.forCorpUseronly(ACCESS_TYPE.CORPORATE);
  }

  $onInit() {
    this.loadInInitFunctions();
  }
  loadKeyContacts() {
    this.keyContactsProperty.populateKeyContacts((keyContactsInfo) => {
      this.keyContactsInfo = keyContactsInfo;
      this.unfilteredKeyContacts = [...keyContactsInfo];
      this.filterRegion(this.states);
    });
  }

  loadCountryStates() {
    this.keyContactsProperty.populateStates((states) => {
      this.statesList = states;
      this.states = this.keyContactsProperty.setDefaultStateFilter(this.statesList[0].reportingStateName);
      this.filterRegion(this.states);
    });
  }
  filterRegion(region) {
    if (!this.forAUonly) return;
    if (!this.unfilteredKeyContacts || !region) return;
    this.keyContactsInfo = this.keyContactsProperty.filterKeyContacts(region);
  }
  toggleForms(keyContacts) {
    this.passFormData(keyContacts);
    this.toggleForm = !this.toggleForm;
  }

  passFormData(data) {
    if (data) {
      this.keycontactData = data;
    }
  }

  onFormHidden() {
    this.keycontactData = null;
  }

  onSave(keyContactData) {
    this.keyContactsProperty.onSaveKeyContacts(keyContactData, (saveSuccess) => {
      if (saveSuccess) {
        swal('Added', 'Contact has been Added Successfully.', 'success');
        this.loadKeyContacts();
        this.toggleForm = false;
      }
    });
  }

  onDelete(keyRelationshipId) {
    this.keyContactsProperty.deleteKeyContact(keyRelationshipId, (deleteSuccess) => {
      if (deleteSuccess) {
        swal('Success', 'Relationship has been successfully deleted.', 'success');
        this.loadKeyContacts();
      }
    });
  }

  deleteKeyContact(keyRelationshipId) {
    swal(this.keyContactsProperty.deleteConfirmation(), (confirm) => {
      if (confirm) {
        this.onDelete(keyRelationshipId);
      }
    });
  }
  toggleFilterRegion(region) {
    this.filterRegion(region);
  }

  loadInInitFunctions() {
    this.loadKeyContacts();
    this.loadCountryStates();
  }
}
