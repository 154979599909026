import angular from 'angular';

angular.module('app').controller('ForSelectedProductModalCtrl', function ForSelectedProductModalCtrl(
  $scope,
  $uibModalInstance,
  selectLoanStructureProduct,
  changeSelectionInLoanStructure,
  loanStructure
) {
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.loanStructure = loanStructure;

  $scope.selectLoanStructureProduct = function (selectedLoanStructure, product) {
    selectLoanStructureProduct(selectedLoanStructure, product);
    changeSelectionInLoanStructure(selectedLoanStructure, product);
  };
});
