import angular from 'angular';

angular.module('app').directive('documentThumb', ['contactService', function documentThumb(contactService) {
  return {
    link(scope, elem, attr) {
      contactService.documentThumbnailGet({
        documentID: attr.documentThumb,
        startPage: 1,
        pageNumber: 1,
        thumbWidth: attr.thumbWidth || 200,
      })
        .then((respond) => {
          const thumbObj = respond.data[0];
          if (thumbObj.ContentType && thumbObj.ThumbnailContent) {
            elem.attr('src', `data:${thumbObj.ContentType};base64,${thumbObj.ThumbnailContent}`);
            elem.attr('style', `width:${attr.thumbWidth}px;`);
          }
        });
    },
  };
}]);
