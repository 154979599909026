export const CONTACT_TYPE = {
  CLIENT: 0,
  ADVISER: 1,
  ADVISER_ORG: 2,
  ASSISTANT: 3,
  REFERRER: 4,
  REFERRER_ORG: 5,
  BUSSINESS_PARTNER: 'BusinessPartner',
  DIRECTOR: 'Director',
  SHARE_HOLDER: 'Shareholder',
  PRINCIPAL_ADVISER: 'Principal Adviser',
  ADVISER_STR: 'Adviser',
};

