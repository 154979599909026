import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

angular.module('app').controller('ClientTypeModalCtrl',
  function ClientTypeModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    toaster
  ) {
    $scope.clientTypeModalType = 'Select';
    $scope.isNewClientTypeVisible = false;
    $scope.newClientTypeList = [];
    $scope.existingClientTypeNameList = [];

    $scope.isClientTypePresent = function (clientTypeName) {
      return $scope.getContactFamilyInfo.ClientTypes.indexOf(clientTypeName) !== -1;
    };

    function getClientType() {
      contactService.getClientType()
        .then((response) => {
          $scope.existingClientTypeNameList = [];
          const listOfClientType = [];
          angular.forEach(response.data, (value) => {
            if (listOfClientType.indexOf(value.ClientTypeName) === -1 && value.ClientTypeName !== '') {
              listOfClientType.push(value.ClientTypeName);
              $scope.existingClientTypeNameList.push(value);
            }
          });
        });
    }
    $scope.setCorporate = function (clientTypeId, isExclusiveForCorpUser, positionIndex) {
      $scope.filteredItems[positionIndex].isExclusiveForCorpUser = !(typeof isExclusiveForCorpUser === 'undefined' ? false : isExclusiveForCorpUser);
      contactService.setClientTypeCorpUser(clientTypeId, !(typeof isExclusiveForCorpUser === 'undefined' ? false : isExclusiveForCorpUser)).then(() => {
      });
    };

    $scope.addClientType = function () {
      $scope.isNewClientTypeVisible = true;
      const newClientType = { ClientTypeID: Math.random(), ClientTypeName: '', IsChecked: true };
      $scope.newClientTypeList.push(newClientType);
    };

    $scope.deleteClientType = function (clientTypeID) {
      swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your client type list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      }, (confirm) => {
        if (confirm) {
          contactService
            .deleteClientType(clientTypeID)
            .then(() => {
              getClientType();
              toaster.pop('success', 'Deleted', 'Client Type has been successfully deleted');
            })
            .catch(displayError);
        }
      });
    };
    $scope.saveClientType = function (clientType) {
      if (clientType.ClientTypeName === '') {
        swal('Empty Name', 'Client Type Name cannot be empty.', 'error');
        return;
      }

      contactService
        .addClientType(clientType.ClientTypeName)
        .then(() => {
          const index = $scope.newClientTypeList.indexOf(clientType);
          if (index > -1)
            $scope.newClientTypeList.splice(index, 1);
          getClientType();
          toaster.pop('success', 'Added', 'Client Type has been successfully added');
        })
        .catch(displayError);

      $scope.contactFamilyInfoGet();
    };

    $scope.clientTypeCheckedAdd = function (clientType, checked) {
      if (checked) {
        $scope.contactInfoSetValue.ClientTypes.push(clientType);
      } else {
        const clientTypeIndex = $scope.contactInfoSetValue.ClientTypes.indexOf(clientType);
        $scope.contactInfoSetValue.ClientTypes.splice(clientTypeIndex, 1);
      }
    };

    $scope.hideEditButton = function (clientType) {
      clientType.IsEditMode = true;
    };

    $scope.updateClientType = function (currentlyEditedClientType) {
      if (currentlyEditedClientType.ClientTypeName === '') {
        swal('Empty Name', 'Client Type Name cannot be empty.', 'error');
        return;
      }
      currentlyEditedClientType.IsEditMode = false;

      contactService
        .updateClientType(
          currentlyEditedClientType.ClientTypeID,
          currentlyEditedClientType.ClientTypeName
        )
        .then(() => {
          toaster.pop('success', 'Updated', 'Client Type has been successfully updated');
        })
        .catch(displayError);
    };

    function reorder(clientTypeID, orderBy) {
      contactService.updateOrder(clientTypeID, orderBy)
        .then(() => {
          getClientType();
        });
    }

    $scope.reorderUp = function (clientType, index) {
      if (index > 0) {
        const itemAbove = $scope.filteredItems[$scope.filteredItems.indexOf(clientType) - 1];
        reorder(clientType.ClientTypeID, itemAbove.OrderBy);
      }
    };

    $scope.reorderDown = function (clientType, index) {
      if (index + 1 < $scope.existingClientTypeNameList.length) {
        const itemBelow = $scope.filteredItems[$scope.filteredItems.indexOf(clientType) + 1];
        reorder(clientType.ClientTypeID, itemBelow.OrderBy);
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.done = function () {
      contactService.contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue)).then(() => {
        $scope.contactFamilyInfoGet();
        toaster.pop('success', 'Updated', 'Client Type successfully updated');
      });
      $uibModalInstance.dismiss('cancel');
    };

    function init() {
      getClientType();
    }

    init();
  });
