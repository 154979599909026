import angular from 'angular';
import swal from 'sweetalert';

angular.module('app').directive('profilerToQuickQuote', function profilerToQuickQuote($window, $state, $timeout, toaster, insuranceQuoteService) {
  return {
    scope: {
      profilerData: '=',
    },
    template: `
      <button class="btn action-btn redirect">
        Open in Quick Quote
      </button>
    `,
    link: (scope, element) => {
      const quickQuoteSet = (profilerData) => {
        const quoteId = 0;
        const familyId = 0;
        insuranceQuoteService.insuranceQuoteSet(
          quoteId,
          familyId,
          profilerData.NumberOfClients,
          profilerData.NumberOfChildren
        ).then((response) => {
          if (response.data) {
            const quickQuoteId = response.data;
            $state.go('app.profilerQuickQuoteFamily', { profilerId: scope.profilerData.profilerId, familyId: scope.profilerData.familyId, insQuoteId: quickQuoteId });
          }
        });
      };

      const clickListener = () => {
        if (scope.profilerData.familyId) {
          scope.notSavedText = 'You will be redirected to QuickQuote';
        } else {
          scope.notSavedText = 'The Profiler made is not yet save \n and you will be redirected to QuickQuote';
        }

        if (scope.profilerData.profilerId) {
          swal({
            title: 'Notice',
            text: scope.notSavedText,
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Proceed',
            closeOnConfirm: true,
          }, (confirm) => {
            if (confirm) {
              if (scope.profilerData.familyId) {
                quickQuoteSet(scope.profilerData);
              } else {
                $timeout(() => {
                  $state.go('app.profilerQuickQuote', { profilerId: scope.profilerData.profilerId });
                });
              }
            }
          });
        } else {
          toaster.pop('warning', 'Warning', 'Please calculate first before proceeding to QuickQuote.');
        }
      };

      element.on('click', clickListener);
      scope.$on('destroy', () => {
        element.off('click', clickListener);
      });
    },
  };
});
