import angular from 'angular';

angular.module('app').controller('AddDepositModalCtrl', function AddDepositModalCtrl(
  $scope, $uibModalInstance, toaster, modalType, deposit, loanApplicationServices
) {
  $scope.Title = (modalType === 'new' ? 'Add' : 'Edit');
  $scope.SaveTitle = (modalType === 'new' ? 'Add' : 'Save');

  $scope.Deposits = {};
  let firstError = null;

  // add necessary properties
  angular
    .extend($scope.Deposits, {
      LoanScenarioId: $scope.loanAppId,
      DepositTypeId: $scope.DepositTypeList[0].Value,
      DepositBreakDownId: 0,
      Description: '',
      TotalAmount: null,
    });

  if (modalType === 'edit') {
    angular.extend($scope.Deposits, deposit);
  }

  $scope.Deposits.DepositTypeId = $scope.Deposits.DepositTypeId.toString();

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.addDeposit = (form) => {
    if (form.$invalid) {
      form && Object.keys(form).forEach((field) => {
        if (field[0] !== '$') {
          if (firstError === null && !form[field].$valid) {
            firstError = form[field].$name;
          }

          if (form[field].$pristine) {
            form[field].$dirty = true;
          }
        }
      });
      angular.element(`.ng-invalid[name=${firstError}]`).focus();
      toaster.pop('error', 'Invalid Form', 'Please fill up the form correctly');
      return;
    }
    $scope.Deposits.DepositTypeId = parseInt($scope.Deposits.DepositTypeId, 10);
    $scope.Deposits.TotalAmount = parseFloat($scope.Deposits.TotalAmount);
    loanApplicationServices.setLoanDepositBreakDown($scope.Deposits).then(() => {
      $scope.DepositGet();
      $scope.cancel();
    });
  };
});
