import angular from 'angular';

angular.module('app').directive('landingHeader', function landingHeader($window) {
  return {
    restrict: 'A',
    link(scope, element) {
      angular.element($window).bind('scroll', function () {
        if (this.pageYOffset >= 60) {
          element.addClass('min');
        } else {
          element.removeClass('min');
        }
      });
    },
  };
});
