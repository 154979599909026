import angular from 'angular';

angular.module('app').controller('PipelineModalAppFilterCtrl', function PipelineModalAppFilterCtrl(
  $scope,
  $uibModalInstance,
  pipelineService,
  pipelineSharedData,
  DisplayMode,
  taskAdviserList,
  labelsList,
  adviser
) {
  $scope.pipelineSharedData = {};
  angular.copy(pipelineSharedData, $scope.pipelineSharedData);

  $scope.labelsList = [];
  angular.copy(labelsList, $scope.labelsList);
  $scope.labelsList.splice(0, 0, {
    pipelineSettingsLabelID: 0,
    LabelName: 'All',
  });

  $scope.adviser = adviser;
  $scope.taskAdviserList = taskAdviserList;
  $scope.DisplayMode = DisplayMode;

  $scope.cancel = () => {
    $uibModalInstance.dismiss();
  };

  $scope.save = () => {
    const { SortColumn: sortColumn, SortType: sortType, PipelineStatusID: statusId, adviserId: advisorId, labelId, probability, enquiryId: enquirySource, referralId: referral, sortingMode, CustomerSort: customerSort, TableViewExcludeColumns } = $scope.pipelineSharedData.leadFilterData;

    const data = {
      sortColumn,
      sortType,
      statusId,
      advisorId,
      labelId,
      probability,
      enquirySource,
      referral,
      sortingMode,
      customerSort,
      TableViewExcludeColumns,
      isLeads: false,
      isCompact: ($scope.pipelineSharedData.leadFilterData.isCompact === '1'),
      isTableView: false,
    };

    pipelineService.setPipelineCardFilters(data).then(() => {});
    $uibModalInstance.close($scope.pipelineSharedData);
  };
});

