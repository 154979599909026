import angular from 'angular';
import swal from 'sweetalert';

angular.module('app').directive('editField', function editField($rootScope, $http, SweetAlert) {
  return {
    scope: {
      type: '@type',
      label: '@label',
      isEmail: '@isEmail',
      fieldKey: '=',
      fieldKeyValue: '@fieldKeyValue',
      object: '=',
      objectValue: '@objectValue',
      objectSetType: '=',
      saveType: '@saveType',
      dateIcon: '@dateIcon',
      selectArr: '=',
      selectObj: '=',
      selectKey: '@selectKey',
      classLabel: '@classLabel',
      classField: '@classField',
      // editInputType    : '@editInputType',
      // readOnly         : '@readOnly',
      // isMultiSelect    : '@isMultiSelect',
      // isDescription    : '@isDescription',
      // itemArr          : '=',
      // itemLabel        : '@itemLabel',
      // outputLabel      : '=',
      // withIcon         : '@withIcon',
      // isLetterIcon     : '@isLetterIcon',
      // isIcon           : '@isIcon',
      // iconClass        : '@iconClass',
      // isPhone          : '@isPhone',
      // placeholder      : '@placeholder',
      // maskValue        : '@maskValue'
    },
    templateUrl: 'assets/views/field-template/input-type.html',
    link(scope) {
      function UpdateField(obj, key, field) {
        const tmp = [];

        if (scope.itemArr) {
          scope.itemArr && Object.keys(scope.itemArr).forEach((x) => {
            if (scope.itemArr[x].ticked) {
              tmp.push(scope.itemArr[x].name);
            }
          });
          obj[key] = tmp.join(', ');
        }

        if (key === 'MyCRMActive') {
          SweetAlert.swal({
            title: 'Confirmation',
            text: 'Are you absolutely sure that this user has completed the verification process and is ready to move to MyCRM? This will impact all existing system integrations and this change cannot be reversed.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes!',
            closeOnConfirm: false,
          }, (confirm) => {
            if (confirm) {
              $rootScope.$broadcast('UPDATE_FIELD_DETAIL', scope.objectSetType, obj, key, field, scope.saveType);
              swal('Updated!', 'myCRM Active updated.', 'success');
            }
          });
        } else {
          $rootScope.$broadcast('UPDATE_FIELD_DETAIL', scope.objectSetType, obj, key, field, scope.saveType);
        }
      }

      function SelectAllItem(obj, key) {
        const tmp = [];
        scope.itemArr && Object.keys(scope.itemArr).forEach((x) => {
          tmp.push(scope.itemArr[x].name);
        });
        obj[key] = tmp.join(', ');
        $rootScope.$broadcast('UPDATE_FIELD_DETAIL', scope.objectSetType, obj, key, null, scope.saveType);
      }

      function SelectItem(obj, key) {
        const tmp = [];
        scope.itemArr && Object.keys(scope.itemArr).forEach((x) => {
          if (scope.itemArr[x].ticked) {
            tmp.push(scope.itemArr[x].name);
          }
        });
        obj[key] = tmp.join(', ');
        $rootScope.$broadcast('UPDATE_FIELD_DETAIL', scope.objectSetType, obj, key, null, scope.saveType);
      }

      function DeselectItem(obj, key) {
        obj[key] = '';
        $rootScope.$broadcast('UPDATE_FIELD_DETAIL', scope.objectSetType, obj, key, null, scope.saveType);
      }

      scope.UpdateField = UpdateField;

      scope.localLang = {
        selectAll: "<span><i class='fa fa-check'></i> Select All</span>",
        selectNone: "<span><i class='fa fa-close'></i> Deselect All </span>",
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: "<div class='buttonLabel deselect'>Deselect All</div>", // default-label is deprecated and replaced with this.
      };

      scope.SelectAllItem = SelectAllItem;
      scope.SelectItem = SelectItem;
      scope.DeselectItem = DeselectItem;
    },
  };
});
