import angular from 'angular';
import swal from 'sweetalert';

angular.module('app').directive('downloadProfilerReport', function downloadProfilerReport($window, $timeout, insuranceProfilerService, toaster) {
  return {
    scope: {
      insurerProviderId: '=',
      familyId: '<',
      onReportGenerated: '&',
      onGeneratingReport: '&',
    },
    template: `
      <a class="insurance-gear-settings generate-report-pdf">
        <preloader-dot id="loading" class="hidden preloader"></preloader-dot>
        <icon-pdf class="icon-pdf-svg"></icon-pdf>
        <span class="icon-pdf">Generate Report</span>
        <span id="loadingText" class="loading-text hidden">Generating PDF...(will take up to 30 seconds)</span>
      </a>
    `,
    link: (scope, element) => {
      const a = $window.document.createElement('a');
      const generatePdf = (saveToClientDocuments) => {
        angular.element('.insurance-gear-settings .icon-pdf').addClass('hidden');
        angular.element('#loading, #loadingText').removeClass('hidden');
        if (scope.onGeneratingReport) scope.onGeneratingReport();

        const familyId = saveToClientDocuments ? scope.familyId : 0;
        insuranceProfilerService.profilerInsuranceProfilePdfRpt(scope.insurerProviderId, familyId)
          .then((response) => {
            if (!response || !response.data) return;
            const { data } = response;
            data.Name = 'Insurance Profiler Report';

            a.setAttribute(`href`, `data:${data.ContentType};base64,${data.DocumentContent}`);
            a.setAttribute(`download`, data.Name);
            a.style.display = `none`;
            $window.document.body.appendChild(a);

            scope.timeOut = $timeout(() => {
              a.click();
            });

            angular.element('#loading, #loadingText').addClass('hidden');
            angular.element('.insurance-gear-settings .icon-pdf').removeClass('hidden');
            if (saveToClientDocuments) toaster.pop('success', 'Saved', 'Document successfully saved to client.');

            if (scope.onReportGenerated) scope.onReportGenerated();
          });
      };
      const clickListener = () => {
        if (!scope.insurerProviderId) {
          toaster.pop('warning', 'Warning', 'Please calculate first before generating PDF Report.');
          return;
        }

        if (scope.familyId) {
          swal({
            title: 'Would you like to save this report to this clients records tab?',
            showCancelButton: true,
            confirmButtonColor: '#5b5084',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            generatePdf(isConfirm);
          });
        } else {
          generatePdf();
        }
      };

      element.on('click', clickListener);
      scope.$on('$destroy', () => {
        if (scope.timeOut) {
          $timeout.cancel(scope.timeOut);
        }
        a && a.remove();
        element.off('click', clickListener);
      });
    },
  };
});
