import angular from 'angular';

angular.module('app').controller('ConnectLogsCtrl', function ConnectLogsCtrl(
  $scope,
  connectService,
  NgTableParams,
  $filter
) {
  $scope.logs = {};
  $scope.dateTo = new Date();
  $scope.dateFrom = new Date();
  const loadLogs = () => {
    $scope.tableParams = new NgTableParams({
      page: 1,
      count: 15, // count per page
      sorting: {
        id: 'asc', // initial sorting
      },
    }, {
      total: 0, // length of data
      getData($defer, params) {
        connectService.connectLogs($scope.dateFrom, $scope.dateTo).then((response) => {
          const data = response.data;
          params.total(response.data.length);

          let filterData = data;
          filterData = params.sorting() ? $filter('orderBy')(filterData, params.orderBy()) : filterData;
          filterData = filterData.slice((params.page() - 1) * params.count(), params.page() * params.count());
          $defer.resolve(filterData);
        });
      },
    });
  };

  const initialize = () => {
    const dateTo = $scope.formatDateString(new Date());
    const dateFrom = $scope.formatDateString(new Date());
    loadLogs(dateTo, dateFrom);
  };

  $scope.datechange = function () {
    $scope.tableParams.reload();
  };

  $scope.formatDateString = function (date) {
    const tmpDate = new Date(date);
    let month = tmpDate.getMonth() + 1;
    let day = tmpDate.getDate();

    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;

    return `${tmpDate.getFullYear()}-${month}-${day}`;
  };

  initialize();
});
