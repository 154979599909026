// Elmer Datolayta
// This Controller is temporarily not use due to transfer from other Wizard for Agreement. Please don't remove this for further notice about agreement.
import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('AgreementNewCtrl',
  function AgreementNewCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    corporateService,
    $rootScope,
    familyId,
    clientId,
    agreementData,
    referralsService,
    SweetAlert) {
    const callerId = $rootScope.callerId;
    $scope.statusList = [{ id: 1, value: 'Active', valueClass: 'active' }, { id: 2, value: 'Inactive', valueClass: 'inactive' }, { id: 3, value: 'In Progress', valueClass: 'in-progress' }];
    $scope.statusListSelected = $scope.statusList[0];
    $scope.roleList = [{ id: 1, value: 'Principal' }, { id: 2, value: 'Agent' }, { id: 3, value: 'Property Manager' }, { id: 4, value: 'Assistant' }, { id: 5, value: 'Adviser' }];
    $scope.roleListSelected = $scope.statusList[0];
    $scope.isAddNewMember = false;
    $scope.setStatus = function (data) {
      $scope.statusListSelected = data;
    };
    $scope.closeModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.agreementTypeListItems = {};
    $scope.agreementTypeList = {};
    $scope.agreement = {};
    $scope.agreement.isActive = false;

    // Ray white

    $scope.raywhiteOrg = {};

    function getAgreementType() {
      corporateService.getAgreementType().then((response) => {
        response.data.splice(3, 1);
        response.data[0].AgreementType = 'Loan Market / Wealth Market';
        $scope.agreementTypeList = response.data;
        $scope.agreementTypeListItems = response.data[0];

        if (response.data.length > 0) {
          angular.forEach($scope.agreementTypeList, (value) => {
            if (agreementData) {
              if (value.AgreementTypeId === agreementData.AgreementTypeId) {
                $scope.agreementTypeListItems = value;
              }
            }
          });
        }
      });
    }
    getAgreementType();

    $scope.localLang = {
      reset: "<i class='fa fa-plus'></i>",
      search: 'Search',
      nothingSelected: "<i class='fa fa-cog violet-color'></i>",
    };
    $scope.adviser_org = [];

    function getAdviserUnderOrganization(orgId) {
      contactService.getAdviserUnderOrganization(orgId).then((response) => {
        $scope.adviserList = [];
        response.data.forEach((data) => {
          if (agreementData) {
            if (agreementData.Advisers_FamilyId && agreementData.Advisers_FamilyId.indexOf(data.FamilyId) > -1) {
              data.isChecked = true;
            }
          } else {
            data.isChecked = false;
          }
          $scope.adviserList.unshift(data);
        });
      });
    }

    function getAdviserOrganizationList() {
      $scope.AgreementId = $rootScope.AgreementId;
      contactService.getAdviserOrganizationList().then((response) => {
        const data = _.filter(response.data, (obj) => { return obj.AdviserOrganizatioName !== ''; });
        if ($rootScope.modeForModal === 'addAdviser' || $rootScope.modeForModal === 'addAdviserOrg' || $rootScope.modeForModal === 'editAdviser') {
          angular.forEach(data, (value) => {
            value.icon = '<i class="fa fa-cog violet-color"></i>';
            if (agreementData) {
              if (agreementData.AdviserOrgId === value.AdviserOrganizationId) {
                value.ticked = true;
                $scope.selected_adviser_org.push(value);
                getAdviserUnderOrganization(value.AdviserOrganizationId);
              } else {
                value.ticked = false;
              }
            } else if (callerId === value.AdviserOrganizationId) {
              value.ticked = true;
              $scope.selected_adviser_org.push(value);
            } else {
              value.ticked = false;
            }

            if (value.ticked) {
              $scope.adviser_org.unshift(value);
            } else {
              $scope.adviser_org.push(value);
            }
          });
        } else {
          const temp = { AdviserOrganizatioName: 'Default', AdviserOrganizationId: 0, Fax: '', Phone: '' };
          $scope.adviserOrganizationList = data;
          data.unshift(temp);
          const tmp = [];
          data && Object.keys(data).forEach((x) => {
            const value = data[x];
            value.icon = '<i class="fa fa-cog violet-color"></i>';
            value.ticked = x === 0;
            tmp.push(value);
          });
          $scope.adviser_org = tmp;
          $scope.selected_adviser_org = [{ AdviserOrganizatioName: 'Default', AdviserOrganizationId: 0, Fax: '', Phone: '', ticked: true }];
        }
      });
    }
    $scope.adviserList = [];

    $scope.isDisabled = false;
    $scope.selectAgreementTypeList = function () {
      if (typeof $scope.agreementTypeListItems !== 'undefined') {
        if ($scope.agreementTypeListItems.AgreementTypeId === 2) {
          $scope.isDisabled = true;
          $scope.agreement.upfront = 20;
          $scope.agreement.trail = 20;
          $scope.currentCommissionOpened = 1;
        } else {
          $scope.isDisabled = false;
          $scope.agreement.upfront = 0;
          $scope.agreement.trail = 0;
        }
      }
    };

    $scope.setAdviser = function (data) {
      getAdviserUnderOrganization(data.AdviserOrganizationId);
    };

    $scope.currentCommissionOpened = 0;
    $scope.isNoCommission = false;
    $scope.setCommission = function (id) {
      if ($scope.currentCommissionOpened === id) {
        $scope.currentCommissionOpened = 0;
      } else {
        $scope.currentCommissionOpened = id;
      }

      if (id === 3) {
        $scope.isNoCommission = true;
        $scope.agreement.upfront = 0;
        $scope.agreement.trail = 0;
      } else if (id === 1 && $scope.isDisabled) {
        $scope.isNoCommission = false;
        $scope.agreement.upfront = 20;
        $scope.agreement.trail = 20;
      } else {
        $scope.isNoCommission = false;
      }
    };
    $scope.isAddNewReferrer = false;
    $scope.referrerAddList = [];

    function clearReferrer() {
      $scope.referrerListSelected = null;
      $scope.phone = '';
      $scope.email = '';
      $scope.isAddNewReferrer = false;
    }

    $scope.pushReferrer = function () {
      if (typeof $scope.referrerListSelected !== 'undefined' && $scope.referrerListSelected !== null) {
        $scope.referrerAddList.push($scope.referrerListSelected.originalObject);
        clearReferrer();
      }
    };
    $scope.raywhiteAdviser = {};
    $scope.pushReferrerRayWhite = function () {
      if (typeof $scope.raywhiteAdviser.selected !== 'undefined') {
        const data = {
          ReferrerFamilyId: $scope.raywhiteAdviser.selected.id || 0,
          ReferrerName: $scope.raywhiteAdviser.selected.name || '',
          Email: 'Not Available',
          Phone: 'Not Available',
        };
        $scope.referrerAddList.push(data);
        clearReferrer();
      }
    };

    $scope.cancelReferrer = function () {
      clearReferrer();
    };
    $scope.referrerList = [];
    function getReferrer() {
      corporateService.getReferrer().then((response) => {
        $scope.referrerList = response.data;
      });
    }
    getReferrer();
    $scope.$watch('referrerListSelected', () => {
      if (typeof $scope.referrerListSelected !== 'undefined' && $scope.referrerListSelected !== null) {
        $scope.phone = $scope.referrerListSelected.originalObject.Phone;
        $scope.email = $scope.referrerListSelected.originalObject.Email;
      }
    });

    $scope.organization = {};

    function getOrganizationTypes() {
      corporateService.organizationTypesGet().then((response) => {
        $scope.organizationTypeList = response.data;
        if (agreementData) {
          const data = {
            OrganizationTypeId: $scope.referrerOrgData.OrganisationTypeId || 0,
            OrganizationTypeName: $scope.referrerOrgData.OrganisationType || '',
          };
          $scope.organization.organizationTypeSelected = data;
        } else if (response.data.length) {
          $scope.organization.organizationTypeSelected = response.data[0];
        }
      });
    }
    $scope.isOrgdetails = true;
    $scope.adviserRequired = false;

    function getCheckedAdviser() {
      const ret = [];
      $scope.adviserList.forEach((data) => {
        if (data.isChecked) {
          ret.push(data.FullName);
        }
      });
      return ret.length > 0 ? ret.join() : '';
    }
    function getCheckedAdviserId() {
      const ret = [];
      $scope.adviserList.forEach((data) => {
        if (data.isChecked) {
          ret.push(data.FamilyId);
        }
      });
      return ret.length > 0 ? ret.join() : '';
    }
    function getCheckedReferrerId() {
      const ret = [];
      $scope.referrerAddList.forEach((data) => {
        ret.push(data.ReferrerFamilyId);
      });
      return ret.length > 0 ? ret.join() : '';
    }
    function getCheckedReferrer() {
      const ret = [];
      $scope.referrerAddList.forEach((data) => {
        ret.push(data.ReferrerName);
      });
      return ret.length > 0 ? ret.join() : '';
    }

    function getCommissionType() {
      let value = 'None';
      switch ($scope.currentCommissionOpened) {
      case 1:
        value = 'Lender';
        break;
      case 2:
        value = 'Broker';
        break;
      case 3:
        value = 'None';
        break;
      default:
        break;
      }
      return value;
    }

    function saveAgreement(complianceId) {
      $scope.adviserRequired = false;
      const agreementOp = $rootScope.modeForModal === 'addAdviser' ? 0 : $rootScope.AgreementId;
      const referrerOrgOp1 = typeof $scope.raywhite.officeSelected === 'undefined' ? 0 : $scope.raywhite.officeSelected.originalObject.id;
      const referrerOrgOp2 = $rootScope.modeForModal === 'addReferrer' ? callerId : 0;
      if (getCheckedAdviser().length > 0) {
        const data = {
          AgreementId: agreementData ? agreementData.AgreementId : agreementOp,
          ReferrerOrgID: $scope.agreementTypeListItems.AgreementTypeId === 2 ? referrerOrgOp1 : referrerOrgOp2,
          AgreementTypeId: $scope.agreementTypeListItems.AgreementTypeId,
          AgreementType: $scope.agreementTypeListItems.AgreementType,
          AdviserOrgId: $rootScope.modeForModal === 'addAdviser' || $rootScope.modeForModal === 'addAdviserOrg' ? callerId : $scope.selected_adviser_org[0].AdviserOrganizationId,
          CommissionPaidType: getCommissionType(),
          UpfrontPercent: typeof $scope.agreement.upfront === 'undefined' || $scope.agreement.upfront === '' ? 0 : $scope.agreement.upfront,
          TrailPercent: typeof $scope.agreement.trail === 'undefined' || $scope.agreement.trail === '' ? 0 : $scope.agreement.trail,
          Advisers_FamilyId: getCheckedAdviserId(),
          Advisers_FullNames: getCheckedAdviser(),
          Referrers_FamilyId: getCheckedReferrerId(),
          Referrers_FullNames: getCheckedReferrer(),
          AdviserOrg_AddressId: 0,
          IsActive: $scope.agreement.isActive,
        };

        corporateService.setOrganizationAgreement(complianceId, data).then((response) => {
          if (response.data > 0) {
            SweetAlert.swal({
              title: 'Success!',
              text: 'Successfully saved the Agreement!',
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Close',
              closeOnConfirm: true,
            },
            () => {
              $rootScope.loadAgreemnt();
              $uibModalInstance.dismiss('cancel');
            });
          } else {
            SweetAlert.swal('Oops...', 'Something went wrong in saving agreement!', 'error');
          }
        });
      } else {
        $scope.isOrgdetails = false;
        $scope.adviserRequired = true;
      }
    }


    function saveReferrerOrg() {
      if ($scope.agreementTypeListItems.AgreementTypeId === 2) {
        saveAgreement($scope.raywhite.officeSelected.originalObject.id);
      } else {
        const agreementOp = $rootScope.modeForModal === 'addAdviser' ? 0 : $rootScope.AgreementId;
        const data = {
          AgreementId: agreementData ? agreementData.AgreementId : agreementOp,
          ReferrerOrgComplianceId: 0,
          ReferrerOrgId: agreementData ? agreementData.ReferrerOrgID : 0,
          ComplianceId: 0,
          ReferrerOrgName: $scope.legalName || '',
          TradingName: $scope.tradingName || '',
          ABN: $scope.abn || '',
          ACN: $scope.acn || '',
          BusinessActivitiesDesc: $scope.activities || '',
          OrganisationTypeId: typeof $scope.organization.organizationTypeSelected === 'undefined' ? 0 : $scope.organization.organizationTypeSelected.OrganizationTypeId,
          OrganisationType: typeof $scope.organization.organizationTypeSelected === 'undefined' ? '' : $scope.organization.organizationTypeSelected.OrganizationTypeName,
          CreatedByFamilyId: 0,
          CreatedByName: '',
          DateCreated: '',
          LastModifiedByFamilyId: 0,
          LastModifiedByName: '',
          DateModified: '',
          Directors: [],
        };
        contactService.setReferrerOrg(data).then((response) => {
          if (response.data > 0) {
            saveAgreement(response.data);
          } else {
            SweetAlert.swal('Oops...', 'Something went wrong in saving Referrer Org!', 'error');
          }
        });
      }
    }


    $scope.chk = [];
    $scope.adviserCheck = function (data, index) {
      data.isChecked = $scope.chk[data.FamilyId];
      $scope.adviserList[index] = data;
    };

    $scope.$watch('selected_adviser_org', () => {
      if (typeof $scope.selected_adviser_org !== 'undefined') {
        if ($scope.selected_adviser_org.length > 0) {
          if ($scope.selected_adviser_org[0].AdviserOrganizationId > 0) {
            getAdviserUnderOrganization($scope.selected_adviser_org[0].AdviserOrganizationId);
          }
        }
      }
    });

    $scope.saveAgreement = function () {
      if ($scope.agreementTypeListItems.AgreementTypeId === 2 && typeof $scope.raywhite.officeSelected === 'undefined') {
        SweetAlert.swal('Required Message', 'Ray White Office is Required. Please select an office.', 'error');
        return false;
      }

      if ($scope.agreementTypeListItems.AgreementTypeId === 2 && typeof agreementData === 'undefined') {
        referralsService.getCheckAgreementExist($scope.selected_adviser_org[0].AdviserOrganizationId || 0, $scope.raywhite.officeSelected.originalObject.id || 0).then((response) => {
          if (response.data > 0) {
            SweetAlert.swal('Agreement Message', 'There is already an agreement in place between these organizations. Please request an update to the existing agreement.', 'error');
          } else {
            saveReferrerOrg();
          }
        });
      } else {
        saveReferrerOrg();
      }
    };


    $scope.raywhite = {};
    $scope.raywhite.officeList = [];
    $scope.raywhite.initialValue = '';

    $scope.getRayWhiteOrganisations = function (searchText) {
      if (searchText.length >= 3) {
        corporateService.getRayWhiteOrganisations(30, 0, searchText).then((response) => {
          $scope.raywhite.officeList = _.filter(response.data, (data) => { return data.name !== ''; });
        });
      }
    };


    $scope.$watch('raywhite.officeSelected', () => {
      if ($scope.raywhite.officeSelected !== null) {
        $scope.getRayWhiteAdvisers($scope.raywhite.officeSelected.originalObject);
      }
    });

    $scope.rayWhiteAdviserList = [];
    $scope.getRayWhiteAdvisers = function (adviserOrg) {
      const orgId = adviserOrg.id;
      const size = 500;
      const from = 0;
      corporateService.getRayWhiteAdvisers(size, from, orgId).then((response) => {
        $scope.rayWhiteAdviserList = _.filter(response.data, (data) => { return data.name !== ''; });
      });
    };

    function init() {
      getAdviserOrganizationList();
      getOrganizationTypes();
    }

    if (agreementData) {
      const currentCommissionOp1 = agreementData.CommissionPaidType.toLowerCase() === 'broker' ? 2 : 3;
      const currentCommissionOp2 = agreementData.CommissionPaidType.toLowerCase() === 'lender' ? 1 : currentCommissionOp1;
      $scope.agreement.isActive = agreementData.IsActive;
      $scope.currentCommissionOpened = agreementData.CommissionPaidType ? currentCommissionOp2 : 1;
      $scope.agreement.upfront = agreementData.UpfrontPercent;
      $scope.agreement.trail = agreementData.TrailPercent;
      if (agreementData.Referrers_FamilyId) {
        const referrer = agreementData.Referrers_FamilyId.split(',');
        referrer.forEach((row) => {
          corporateService.corporateContactGet(0, row).then((response) => {
            if (response.data) {
              const temp = {};
              temp.ReferrerFamilyId = response.data.FamilyId;
              temp.ReferrerName = `${response.data.FirstName} ${response.data.LastName}`;
              temp.Phone = response.data.WorkNumber;
              temp.Email = response.data.Email;
              $scope.referrerAddList.push(temp);
            }
          });
        });
      }
      corporateService.referrerOrgGet(0, agreementData.ReferrerOrgID).then((response) => {
        if (response.data) {
          $scope.referrerOrgData = response.data;
          const data = response.data;
          $scope.legalName = data.ReferrerOrgName;
          $scope.abn = data.ABN;
          $scope.acn = data.ACN;
          $scope.tradingName = data.TradingName;
          $scope.activities = data.BusinessActivitiesDesc;
        }
        init();
      });
    } else {
      init();
    }

    $scope.mode = function () {
      if (agreementData) {
        return 'Edit';
      }
      return 'Add';
    };
  });
