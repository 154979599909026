import angular from 'angular';

angular.module('app').controller('AddScenarioModalCtrl',
  function AddScenarioModalCtrl(
    $scope,
    $uibModalInstance,
    dashboardService,
    lendingScenarioService,
    $stateParams,
    $timeout) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    dashboardService.getBrokerBasicInfo()
      .then((response) => {
        $scope.assignedBrokerId = response.data.BrokerId;
      });

    lendingScenarioService.scenarioClientEntityGet(0, $stateParams.familyId).then((response) => {
      $scope.clientEntity = [];

      angular.forEach(
        response.data,
        (val) => {
          $scope.clientEntity.push(val.ClientID);
        }
      );
    });

    $scope.save = function () {
      lendingScenarioService.scenarioSet({
        scenarioId: 0,
        clientIds: $scope.clientEntity.join(','),
        entityIds: '',
        assignedBrokerId: $scope.assignedBrokerId,
        scenarioTitle: $scope.Title,
        familyId: $stateParams.familyId,
      }).then((response) => {
        if (response.status === 200) {
          $timeout(() => {
            $scope.goToLendingScenario(response.data);
          }, 1000);
        }
      });
    };
  });
