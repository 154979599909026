import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('OpenLeadDetailModalCtrl',
  function OpenLeadDetailModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    $state,
    pipelineService,
    utilitiesService,
    card,
    probabilityList,
    leadStatusList,
    labels,
    toaster,
    $uibModal) {
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.card = card;
    $scope.card.AdviserName = `${$scope.card.AdviserFirstName} ${$scope.card.AdviserLastName}`;
    $scope.probabilityList = probabilityList;
    $scope.labels = labels;
    $scope.createdStatuses = leadStatusList;

    $scope.toStatus = {};
    $scope.selectStatus = (statusObj, cardObj) => {
      $scope.toStatus = statusObj;
      $scope.card = cardObj;
      $scope.statusName = statusObj.PipelineStatus;
      $scope.card.PipelineStatus = $scope.statusName;
      $scope.card.PipelineStatusID = statusObj.PipelineStatusID;
    };
    $scope.toggleDropdown = (event) => {
      event.preventDefault();
      event.stopPropagation();
      $scope.expandDroqdown = !$scope.expandDroqdown;
    };
    $scope.save = () => {
      $scope.cardMove = {};
      $scope.cardMove.PipelineCardsID = $scope.card.PipelineItemId;
      $scope.cardMove.ToStatusID = $scope.card.PipelineStatusID;

      pipelineService.PipelineCardsMoveToNextStatusSet($scope.cardMove)
        .then(() => {
          toaster.pop('success', 'Moved', 'Stage has been updated successfully.');
          $scope.expandDroqdown = false;
        }, () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        });
      $uibModalInstance.close('saved');
    };
    $scope.convertCard = (conversionStatus) => {
      const $postData = {
        pipelineCardsID: $scope.card.PipelineItemId,
        conversionStatus,
        clientFamilyId: $scope.card.clientFamilyId,
      };

      pipelineService.PipelineCardsConversionSet($postData).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          toaster.pop('success', 'Moved', 'Item successfully moved');
          if (parseInt(conversionStatus, 10) === 1) {
            $scope.openLinkToLoanAppModal('modalLinkLoanToPipeline', $scope.card.clientFamilyId, $scope.card.PipelineItemId, $scope.card.ClientName);
          }
        } else {
          toaster.pop('error', 'Error', 'Moving item went wrong');
        }

        $uibModalInstance.close('saved');
      });
    };
    $scope.openLinkToLoanAppModal = (sizeModal, familyID, cardID, familyName) => {
      $uibModal.open({
        templateUrl: '/assets/views/pipeline/leads/partials/link_loanApp_modal.html',
        resolve: {
          familyID() {
            return familyID;
          },
          cardID() {
            return cardID;
          },
          familyName() {
            return familyName;
          },
        },
        controller: 'LinkToLoanCtrl',
        sizeModal,
      });
    };
    $scope.setProbability = (probObj) => {
      $scope.probabilityToSet = {};
      $scope.probabilityToSet.cardID = $scope.card.PipelineItemId;
      $scope.probabilityToSet.probability = probObj.probability;
      $scope.card.labelType = probObj.probability;

      pipelineService.PipelineCardsProbabilitySet($scope.probabilityToSet)
        .then(() => {
          toaster.pop('success', 'Added', 'Probability has been added successfully.');
        }, () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        });
    };
    $scope.checkLabelExistInList = (label) => {
      const findCheckLabel = _.find($scope.labels, (o) => {
        return String(o.pipelineSettingsLabelID) === String(label.labelID);
      });

      if (_.size(findCheckLabel) > 0) {
        label.labelColor = findCheckLabel.ColorName;
        return true;
      }
      return false;
    };
    $scope.getInitials = (string1, string2) => {
      return _.isEmpty(string2) ? utilitiesService.filterInitialOneString(string1) : utilitiesService.filterInitial(string1, string2);
    };
    $scope.openLabelsList = () => {
      $scope.currentModalContent = 'add_labels';
    };
    $scope.goBackToPrevModalContent = () => {
      $scope.currentModalContent = 'card_info';
    };
    $scope.addlabel = () => {
      $scope.card.addLabelShown = true;
      $scope.defineColors();
    };
    $scope.defineColors = () => {
      $scope.labelColor2 = false;
      $scope.labelColor1 = false;
      $scope.labelColor3 = false;
      $scope.labelColor4 = false;
      $scope.labelColor5 = false;
      $scope.labelColor6 = false;
      $scope.labelColor7 = false;
      $scope.labelColor8 = false;
      $scope.labelColor9 = false;
      $scope.labelColor10 = false;
    };
    $scope.colorPicked = (color) => {
      $scope.pickedColorCode = color;
    };
    $scope.updateLabel = () => {
      $scope.lblToUpdate = {};
      $scope.lblsToUpdate = [];
      if ($scope.card.lblObjToEdit) {
        $scope.labelObj = $scope.card.lblObjToEdit;

        $scope.lblToUpdate.LabelName = $scope.card.labelTitle;
        $scope.lblToUpdate.LabelID = $scope.labelObj.pipelineSettingsLabelID;
        $scope.lblToUpdate.ColorName = !_.isEmpty($scope.pickedColorCode) ? $scope.pickedColorCode : $scope.labelObj.ColorName;
        $scope.lblsToUpdate.push($scope.lblToUpdate);
      } else {
        $scope.lblToUpdate.LabelID = 0;
        $scope.lblToUpdate.LabelName = $scope.card.newLabelTitle;
        $scope.lblToUpdate.ColorName = $scope.pickedColorCode;
        $scope.lblsToUpdate.push($scope.lblToUpdate);
      }

      pipelineService.PipelineSettingsLabelSet($scope.lblsToUpdate)
        .then(() => {
          toaster.pop('success', $scope.card.lblObjToEdit ? 'Updated' : 'Added', $scope.card.lblObjToEdit ? 'Label has been updated successfully.' : 'Label has been added successfully.');
          $scope.card.addLabelShown = false;
          $scope.card.editLabelShown = false;
          if (!$scope.card.lblObjToEdit) {
            $scope.labels.push($scope.lblToUpdate);
          } else {
            $scope.card.lblObjToEdit.LabelName = $scope.card.labelTitle;
            if ($scope.pickedColorCode) $scope.card.lblObjToEdit.ColorName = $scope.pickedColorCode;
            const labelInCard = _.find($scope.card.labels, (cardLabel) => {
              return parseInt(cardLabel.labelID, 10) === parseInt($scope.card.lblObjToEdit.pipelineSettingsLabelID, 10);
            });
            if (labelInCard) {
              labelInCard.labelName = $scope.card.labelTitle;
              labelInCard.labelColor = $scope.card.lblObjToEdit.ColorName;
            }
          }
        }, () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        });
    };
    $scope.performAction = (label, index) => {
      $scope.result = $scope.isIncluded(label, index);

      if ($scope.result === false) {
        $scope.setLabelForCard(label);
      } else {
        $scope.deleteLabelFromCard(label);
      }
    };
    $scope.isIncluded = (label) => {
      if (typeof $scope.card === 'undefined' || typeof $scope.card.labels === 'undefined') {
        return false;
      }
      const isMatch = $scope.card.labels.filter((l) => {
        return parseInt(l.labelID, 10) === parseInt(label.pipelineSettingsLabelID, 10);
      });
      if (isMatch.length !== 0) {
        return true;
      }
      return false;
    };
    $scope.deleteLabel = (labelObj) => {
      if (labelObj.pipelineSettingsLabelID) {
        $scope.deletelbl = {};
        $scope.deletelbl.LabelID = labelObj.pipelineSettingsLabelID;
        pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(() => {
          toaster.pop('success', 'Deleted', 'Label has been deleted successfully.');
          _.remove($scope.labels, (label) => {
            return label.pipelineSettingsLabelID === labelObj.pipelineSettingsLabelID;
          });
        }, () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        });
      }
    };
    $scope.setLabelForCard = (lblObj) => {
      $scope.pipelineCardsLabelSet = {};
      $scope.pipelineCardsLabelSet.cardID = $scope.card.PipelineItemId;
      $scope.pipelineCardsLabelSet.labelsID = lblObj.pipelineSettingsLabelID;
      $scope.pipelineCardsLabelSet.colorCode = lblObj.ColorName;

      if (_.isEmpty($scope.card.labels)) $scope.card.labels = [];
      $scope.card.labels.push({
        labelID: lblObj.pipelineSettingsLabelID,
        labelColor: lblObj.ColorName,
        labelName: lblObj.LabelName,
      });

      pipelineService.PipelineCardsLabelsSet($scope.pipelineCardsLabelSet)
        .then(() => {
          toaster.pop('success', 'Added', 'Label  has been added successfully.');
        }, () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        });
    };
    $scope.editLabel = (label) => {
      $scope.defineColors();
      $scope.card.editLabelShown = true;

      $scope.card.lblObjToEdit = label;
      $scope.card.labelTitle = label.LabelName;
      switch (label.ColorName) {
      case '#f98169':
        $scope.labelColor1 = true;
        break;
      case '#fda829':
        $scope.labelColor2 = true;
        break;
      case '#fde000':
        $scope.labelColor3 = true;
        break;
      case '#9c4b85':
        $scope.labelColor4 = true;
        break;
      case '#694573':
        $scope.labelColor5 = true;
        break;
      case '#017cc9':
        $scope.labelColor6 = true;
        break;
      case '#6b499d':
        $scope.labelColor7 = true;
        break;
      case '#05d0ee':
        $scope.labelColor8 = true;
        $scope.labelColor10 = true;
        break;
      case '#58cd40':
        $scope.labelColor9 = true;
        break;
      default:
        break;
      }
    };
    $scope.deleteLabelFromCard = (lblObj) => {
      $scope.deleteLblFromCard = {};
      $scope.deleteLblFromCard.cardsId = $scope.card.PipelineItemId;
      $scope.deleteLblFromCard.labelsID = lblObj.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelsDelete($scope.deleteLblFromCard).then(() => {
        _.remove($scope.card.labels, (cardLabel) => {
          return parseInt(cardLabel.labelID, 10) === parseInt(lblObj.pipelineSettingsLabelID, 10);
        });
        toaster.pop('success', 'Deleted', 'Label has been deleted from your list.');
      }, () => {
        toaster.pop('error', 'Error', 'Yikes! Something is wrong');
      });
    };

    $scope.currentModalContent = 'card_info';
  });
