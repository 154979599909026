import template from './topNavbar.html';
import './topNavbar.scss';

export default {
  template,
  bindings: {
    uiService: '<',
    configService: '<',
    isEnvNotifDismissed: '<',
    isProductionBanner: '&',
    onDismiss: '&',
  },
};
