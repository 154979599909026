class CompanyTrustModel {
  getCompanyTrustModel(entity) {
    this.data = {
      OrganisationId: '',
      Name: '',
      FamilyId: '',
      OrganisationType: '',

      Phone: [
        {
          Type: 'Work',
          Number: '',
        },
      ],
      Address: [{ AddressID: 0 }],
      Email: [
        {
          Type: 'Email',
          EmailAddress: '',
        },
      ],

      DateIncorporated: '',
      TrusteeName: '',
      TrustBeneficiary: [],

      CompanyTypeId: 1,
      ABN: '',
      ACN: '',
      ABBN: '',
      NatureOfBusiness: '',
      Director: '',
      Shares: '',
    };

    if (entity) {
      this.data = { ...entity };
      this.data.CompanyTypeId = (!entity.CompanyTypeId ? 1 : entity.CompanyTypeId);
      this.data.DateIncorporated = (entity.DateIncorporated ? new Date(entity.DateIncorporated) : '');

      this.data.Email = [
        {
          Type: 'Email',
          EmailAddress: (entity.Email[0] ? entity.Email[0].EmailAddress : ''),
        },
      ];

      this.data.Phone = [
        {
          Type: 'Work',
          Number: (entity.Phone[0] ? entity.Phone[0].Number : ''),
        },
      ];

      this.data.chosenCompany = { Id: entity.CompanyTypeId };

      if (this.data.Address && this.data.Address.length) {
        this.data.Address = { ...this.data.Address[0] };
        this.data.FullAddress = { ...this.data.Address };
      }
    }

    return this.data;
  }

  getTrustSubmitModel(entity) {
    this.data = entity;
    const {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Email,
      Address,
      DateIncorporated,
      TrustBeneficiary,
      TrusteeName,
    } = this.data;

    return {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Address,
      Email,
      DateIncorporated,
      TrustBeneficiary,
      TrusteeName,
    };
  }

  getCompanySubmitModel(entity) {
    this.data = entity;
    const {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Email,
      Address,
      CompanyTypeId,
      ABN,
      ACN,
      ABBN,
      NatureOfBusiness,
      Director,
      Shares,
    } = this.data;

    return {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Email,
      Address: [{ ...Address }],
      CompanyTypeId,
      ABN,
      ACN,
      ABBN,
      NatureOfBusiness,
      Director,
      Shares,
    };
  }
}

export default CompanyTrustModel;
