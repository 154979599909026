import angular from 'angular';
import { APPROVAL_EXPIRIES_ID } from 'Common/constants/customerCareModules';
import { toastError } from 'Common/utilities/alert';
import moment from 'moment';

angular.module('app').controller('OpenPreApprovalSMSModalCtrl', function OpenPreApprovalSMSModalCtrl($scope,
  $uibModalInstance,
  $state,
  $controller,
  source,
  toaster,
  preApprovalObj,
  preApprovalListTable,
  optionsService,
  contactService,
  customerCareService,
  formErrorHandlerServices,
  customerCareTemplateService,
  loadPreApprovalTable) {
  const vm = this;
  angular.extend(vm, $controller('CustomerCareSendMessageModalBaseCtrl', { $scope }));

  $scope.recipients = [];
  $scope.smsList = [];
  $scope.IsComplete = true;
  $scope.currentModule = APPROVAL_EXPIRIES_ID;
  function getCountryList() {
    optionsService.countryListGet()
      .then((response) => {
        $scope.countryList = response.data;
        $scope.country = $scope.countryList[0];
        $scope.selectedCountryCode = $scope.country.CountryCode;
        $scope.makeObject($scope.selectedCountryCode);
      });
  }

  $scope.makeObject = function (selectedCountryCode) {
    if (source === 'single') {
      if (preApprovalObj.Mobile !== '') {
        if (typeof selectedCountryCode !== 'undefined') {
          $scope.countryObj = $scope.countryList.filter((item) => {
            if (selectedCountryCode === item.CountryCode) {
              return item;
            }
            return false;
          });

          if (typeof $scope.countryObj !== 'undefined') {
            $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
          }
        }

        const expDate = moment(preApprovalObj.ExpiryDate).format('D MMM');
        const notiHeader = `Approval Expires (${expDate}) Completed via SMS`;
        $scope.smsList.sms = [
          {
            FamilyFullname: preApprovalObj.Customer,
            LoanID: preApprovalObj.LoanID,
            FamilyID: preApprovalObj.FamilyID,
            MobileNumber: $scope.callingCode + preApprovalObj.Mobile,
            NotificationHeader: notiHeader,
          }];
        $scope.recipients.email = [{
          EmailAddress: preApprovalObj.EmailAddress,
          FamilyFullname: preApprovalObj.Customer,
          MobileNumber: preApprovalObj.Mobile,
        }];
      }
    }
    $scope.preApprovalListTable = preApprovalListTable;

    if (source === 'all') {
      $scope.all = true;
      if (typeof selectedCountryCode !== 'undefined') {
        $scope.countryObj = $scope.countryList.filter((item) => {
          if (selectedCountryCode === item.CountryCode) {
            return item;
          }
          return false;
        });

        if (typeof $scope.countryObj !== 'undefined') {
          $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
        }
      }
      if ($scope.preApprovalListTable.length > 0) {
        $scope.selectSMSList = [];
        $scope.emailRecipients = [];

        for (let i = 0; i < $scope.preApprovalListTable.length; i++) {
          $scope.smsAdd = {};
          $scope.emailAdd = {};
          if ($scope.preApprovalListTable[i].Mobile !== '') {
            $scope.smsAdd.FamilyFullname = $scope.preApprovalListTable[i].Customer;
            $scope.smsAdd.LoanID = $scope.preApprovalListTable[i].LoanID;

            $scope.smsAdd.FamilyID = $scope.preApprovalListTable[i].FamilyID;
            $scope.smsAdd.MobileNumber = $scope.callingCode + $scope.preApprovalListTable[i].Mobile;

            $scope.selectSMSList.push($scope.smsAdd);

            $scope.emailAdd.EmailAddress = $scope.preApprovalListTable[i].EmailAddress;
            $scope.emailAdd.FamilyFullname = $scope.preApprovalListTable[i].Customer;
            $scope.emailAdd.MobileNumber = $scope.callingCode + $scope.preApprovalListTable[i].Mobile;
            $scope.emailRecipients.push($scope.emailAdd);
          }
        }
        if ($scope.selectSMSList.length > 0) {
          $scope.smsList.sms = $scope.selectSMSList;
          $scope.noOfPeople = $scope.selectSMSList.length;
        }
        if ($scope.emailRecipients.length > 0) {
          $scope.recipients.email = $scope.emailRecipients;
        }
      }
    }
  };
  $scope.countryChanged = function (selectedCountryCode) {
    $scope.makeObject(selectedCountryCode);
  };

  $scope.selectEmailList = [];
  $scope.getRecipientAutoSuggestion = function (userInputText) {
    contactService.getRecipientAutoSuggestion(userInputText).then((response) => {
      $scope.selectEmailList = response.data;
    });
  };
  $scope.selectedTemplate = [{
    UserTemplateID: 0,
    UserTemplateName: 'Blank',
  }];
  $scope.refreshResults = function (select) {
    const search = select.search;

    $scope.getRecipientAutoSuggestion(search);
  };

  $scope.sendSMS = function (form) {
    const customerCareFormValid = formErrorHandlerServices.sendEmailFormValidation(form);
    if (customerCareFormValid) {
      $scope.recipientsSMSArray = [];
      $scope.smsToSend = {};
      $scope.smsToSend.IsComplete = $scope.IsComplete;

      $scope.smsToSend.Message = $scope.sms.Message;
      $scope.smsToSend.CustomerCareType = 'PRE_APPROVAL_EXPIRY';
      angular.forEach($scope.smsList.sms, (item) => {
        $scope.recipientsSMSArray.push(item);
      });
      $scope.smsToSend.SmsModelList = $scope.recipientsSMSArray;
      customerCareService
        .CustomerCareSMSSend($scope.smsToSend)
        .then((preApprovalRes) => {
          if (preApprovalRes.data && preApprovalRes.data.length) {
            const preApprovalSMSValid = customerCareTemplateService.customerCareResponseMsg(preApprovalRes.data, 'SMS');
            if (preApprovalSMSValid) {
              loadPreApprovalTable();
              $scope.smsToSend.Message = '';
              form.$setPristine(true);
            }
            $scope.cancel();
          }
        })
        .catch(toastError);
    }
  };
  function init() {
    getCountryList();

    $scope.phone = preApprovalObj.MobileNo;
    $scope.content = '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';
    $scope.familyId = preApprovalObj.FamilyID;
  }

  init();
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
