export const AUSTRALIAN_STATE = [
  {
    value: 'ACT',
    name: 'ACT (Australian Capital Territory)',
  },
  {
    value: 'NSW',
    name: 'NSW (New South Wales)',
  },
  {
    value: 'NT',
    name: 'NT (North Territory)',
  },
  {
    value: 'NZ',
    name: 'NZ (New Zealand)',
  },
  {
    value: 'OS',
    name: 'OS (Overseas)',
  },
  {
    value: 'OTH',
    name: 'OTH (Other State or Territory of Australia)',
  },
  {
    value: 'QLD',
    name: 'QLD (Queensland)',
  },
  {
    value: 'SA',
    name: 'SA (South Australia)',
  },
  {
    value: 'TAS',
    name: 'TAS (Tasmania)',
  },
  {
    value: 'VIC',
    name: 'VIC (Victoria)',
  },
  {
    value: 'WA',
    name: 'WA (Western Australia)',
  },
];
