import angular from 'angular';

angular.module('app').directive('numberInput', function numberInput() {
  return {
    scope: {
      model: '=',
      inputClass: '@inputClass',
    },
    template: '<div class="input-group spinner numberInput"><input type="text" class="form-control {{inputClass}}" text-format="number" ng-model="model"/>' +
        '<div class="input-group-btn-vertical"><button class="btn btn-default" type="button" ng-click="increment()"><i class="fa fa-caret-up"></i></button>' +
        '<button class="btn btn-default" type="button" ng-click="decrement()"><i class="fa fa-caret-down"></i></button></div></div>',
    link(scope) {
      scope.increment = function () {
        scope.model++;
      };
      scope.decrement = function () {
        if (scope.model > 0) {
          scope.model--;
        }
      };
    },
  };
});
