import angular from 'angular';
import _ from 'lodash';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';

angular.module('app').controller('LoanAppBrokerDeclarationsCtrl',
  function LoanAppBrokerDeclarationsCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    optionsService,
    loanAppSharedData
  ) {
    let clickCounter = 0;
    // Property
    angular.extend($scope, {
      loanAppId: $stateParams.loanAppId,
      declarationQuestionList: [],
      declarationObjSet: {},
      editExecCount: 0,
      modalOpened: 0,
    });
    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    /* end of listeners */
    loanApplicationServices.loanSummaryGet($state.params.loanAppId).then(() => {
      $scope.processBrokerDeclarationSection = () => {
        if (clickCounter !== 0) return;

        clickCounter++;

        // Method
        angular.extend($scope, {

          init() {
            /**
             * Calling watchers and listeners :)
             * */
            $scope.listeners();

            optionsService.question('Broker Declaration').then((response) => {
              $scope.declarationQuestionList = response.data;
              for (let i = 0; i < $scope.declarationQuestionList.length; i++) {
                if ($scope.declarationQuestionList[i].QuestionId === 102) {
                  $scope.declarationQuestionList[i].forOrdering = 'A';
                } else {
                  $scope.declarationQuestionList[i].forOrdering = 'Z';
                }
              }
              $scope.orderByDeclaration = 'forOrdering';
            });
            $scope.getDeclarationQuestionValue();

            $scope.$on('$destroy', () => {
              if ($scope.declarationObjSetWatcher) {
                $scope.declarationObjSetWatcher();
              }
              if ($scope.decEditCountTimeout) {
                $timeout.cancel($scope.decEditCountTimeout);
              }
            });
          },

          listeners() {
            /**
             *  Validates every update on object
             */
            $scope.declarationObjSetWatcher = $scope.$watch('declarationObjSet', () => {
              $scope.validateDeclaration();
            }, true);
          },

          getDeclarationQuestionValue() {
            loanScenarioService.loanDeclarationGet($scope.loanAppId).then((response) => {
              _.forEach(response.data, (obj) => {
                let answerValue = null;
                switch (true) {
                case typeof obj.answerInt !== 'undefined':
                  answerValue = String(_.head(obj.answerInt));
                  break;
                case typeof obj.answerString !== 'undefined':
                  answerValue = _.head(obj.answerString);
                  break;
                case typeof obj.answerBool !== 'undefined':
                  answerValue = _.head(obj.answerBool);
                  break;
                default:
                  break;
                }
                $scope.declarationObjSet[obj.QuestionId] = answerValue;
              });
            });
          },

          updateDeclarationQuestion(questionId, displayType, displayTypeId, otherArgs) {
            // if text is empty will return to avoid undefined
            if (typeof $scope.declarationObjSet[questionId] === 'undefined' && !otherArgs) {
              return false;
            }
            let brokerDeclarationSet = {};
            if (!otherArgs) {
              brokerDeclarationSet = {
                LoanScenarioId: $scope.loanAppId,
                FamilyId: $stateParams.familyId,
                QuestionId: questionId,
                DisplayOptionId: displayTypeId,
              };

              switch (displayType) {
              case 'CheckBox':
              case 'RadioButton':
              case 'YesNoOnly':
              case 'RadioBoxOnYes':
              case 'RadioBoxOnNo':
                brokerDeclarationSet.answerBool = [$scope.declarationObjSet[questionId]];
                break;
              case 'TextboxOnNo':
              case 'TextboxOnYes':
              case 'TextOnly':
                brokerDeclarationSet.answerString = [String($scope.declarationObjSet[questionId])];
                break;
              case 'MultiDropDown':
              case 'SingleDropDown':
              case 'SingleDropDownNoFrame':
                brokerDeclarationSet.answerInt = [parseInt($scope.declarationObjSet[questionId], 10)];
                break;
              default:
                break;
              }
              loanScenarioService.loanDeclarationSet(brokerDeclarationSet).then(() => {
                $scope.getDeclarationQuestionValue();
              });
            } else if (otherArgs.mainObj.QuestionId === 102) {
              let SubQuestions = angular.copy(otherArgs.mainObj.SubQuestion);

              const filterSubQuestions = o => (o.QuestionDisplayType === 'RadioBoxOnYes' || o.QuestionDisplayType === 'RadioBoxOnNo');
              SubQuestions = _.filter(SubQuestions, filterSubQuestions);

              _.forEach(SubQuestions, (o, i) => {
                if (o.QuestionDisplayType === 'RadioBoxOnYes' || o.QuestionDisplayType === 'RadioBoxOnNo') {
                  // uncheck the rest
                  if (questionId !== o.QuestionId)
                    $scope.declarationObjSet[o.QuestionId] = false;

                  brokerDeclarationSet = {
                    LoanScenarioId: $scope.loanAppId,
                    FamilyId: $stateParams.familyId,
                    QuestionId: o.QuestionId,
                    DisplayOptionId: o.QuestionDisplayTypeId,
                    answerBool: [(questionId === o.QuestionId)],
                  };

                  loanScenarioService.loanDeclarationSet(brokerDeclarationSet).then(() => {
                    if (i === (SubQuestions.length - 1)) {
                      $scope.getDeclarationQuestionValue();
                    }
                  });
                }
              });
            }
          },

          /**
                 * Validates declaration section
                 */
          validateDeclaration() {
            let validDeclarationProp = false;
            let countDeclarationPop = 0;
            let countDeclarationLength = 2;
            $scope.declarationObjSet && Object.keys($scope.declarationObjSet).forEach((key) => {
              if (Object.prototype.hasOwnProperty.call($scope.declarationObjSet, key)) {
                validDeclarationProp = $scope.declarationObjSet[key] !== null;
                if (validDeclarationProp) {
                  if (!(key === '114' && _.size($scope.declarationObjSet[key]) === 0)) {
                    countDeclarationPop++;
                  }
                }

                if ($scope.declarationObjSet[key] === true && key === '113') {
                  countDeclarationLength = 3;
                }
              }
            });
            $scope.isStartedDeclaration = countDeclarationPop >= 1;
            $scope.isValidDeclarations = validDeclarationProp && countDeclarationPop >= countDeclarationLength;

            const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.BROKER_DECLARATION);
            loanAppSharedData.completionStatusSet(SECTION_ID.BROKER_DECLARATION, sectionObject.IsCompleted, $scope.isValidDeclarations);

            $scope.countValids($scope.isValidDeclarations, 'broker declaration');
          },

          decEditCount() {
            $scope.decEditCountTimeout = $timeout(() => {
              if ($scope.isValidDeclarations) {
                $scope.editExecCount++;
              }
            }, 200);
          },

        });


        $scope.init();
      };

      const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.BROKER_DECLARATION);

      if (!sectionObject.IsCompleted) {
        $scope.processBrokerDeclarationSection();
      } else {
        $scope.isValidDeclarations = sectionObject.IsCompleted;
        $scope.isStartedDeclaration = true;
      }
    });
  });
