import angular from 'angular';
import _ from 'lodash';

class QuickLMICalculatorCtrl {
  constructor(
    fundingCalculatorService,
    fundingCalculatorSharedData,
    loanProfilerService,
    toaster,
    $state,
    $timeout,
    $uibModal,
    fundingCalculatorDetailService
  ) {
    'ngInject';

    this.fundingCalculatorService = fundingCalculatorService;
    this.fundingCalculatorSharedData = fundingCalculatorSharedData;
    this.fundingCalculatorDetailService = fundingCalculatorDetailService;
    this.loanProfilerService = loanProfilerService;
    this.timeout = $timeout;
    this.uibModal = $uibModal;
    this.mortgageObj = {};
    this.goalTypeValue = 0;
    this.toaster = toaster;
    this.state = $state;
    this.status = {
      empty: 'empty',
      waiting: 'waiting',
      done: 'done',
    };
    this.selectedLenderObj = null;
  }
  preloaderShowHide() {
    this.timeout(() => {
      this.IsPreloaderHide = true;
    }, 2000);
  }

  getSecurities() {
    this.fundingCalculatorService.getQuickLMISecurities(this.brokerEventId)
      .then((response) => {
        this.addSecurityEnabled = response && response.data && !!response.data.length;
        if (!this.addSecurityEnabled) return;
        const { data } = response;
        this.securityAmountTotal = _.sumBy(data, item => parseInt(item.SecurityAmount, 10));
        this.mortgageObj.security = data.map((item) => {
          const security = {
            FundingCalParametersID: item.FundingCalParametersID,
            OccupancyType: item.OccupancyType,
            StateOfSercurity: item.StateName,
            Value: item.SecurityAmount,
            formInEditMode: false,
          };
          return security;
        });
        this.mortgageObj.NewLVRValue = this.fundingCalculatorSharedData.updateQuickLmiLvrValue(this.mortgageObj.NewLoanAmount, this.securityAmountTotal);
        this.mortgageObj.LVRValue = this.mortgageObj.NewLVRValue.replace('%', '');
      });
  }

  getFundingCalculatorFundsQuickLMIFavorite() {
    this.fundingCalculatorService.getFundingCalculatorFundsQuickLMIFavorite(this.brokerEventId).then((response) => {
      if (response && response.data.length) {
        this.selectedLenderObj = response && response.data && response.data.length && response.data[0];
        this.LMIPremiumObj.status = this.status.waiting;
        this.getQuickLMICalculatorReport();
        this.IsPreloaderHide = true;
      } else {
        this.preloaderShowHide();
      }
    });
  }

  getFundingCalculatorFundsQuickLMI() {
    this.fundingCalculatorService.getFundingCalculatorFundsQuickLMI(this.brokerEventId).then((response) => {
      this.mortgageObj = response.data;
      this.IsQuickCalc = !this.mortgageObj.IsQuickCalc;
      this.mortgageObj = this.fundingCalculatorSharedData.getQuickLmiMortgageObj(this.mortgageObj, this.brokerEventId);
      this.getSecurities();
      if (this.IsQuickCalc) {
        this.getFundingCalculatorFundsQuickLMIFavorite();
      } else {
        this.IsPreloaderHide = true;
      }
    });
  }

  setLenderIsFavorite(lendersObj) {
    this.selectedLenderObj = [];
    this.selectedLenderShow = false;
    lendersObj.forEach((item) => {
      if (item.IsFavorite) {
        this.selectedLenderObj.push(item);
        this.selectedLenderShow = true;
        this.isSaveToFundingCalcDisabled = false;
      }
    });
  }

  getQuickLMICalculatorReport() {
    this.fundingCalculatorService.getQuickLMICalculatorReport(this.brokerEventId).then((response) => {
      const returnObj = response.data;
      if (returnObj) {
        this.LMIPremiumObj.data = returnObj;
        this.LMIPremiumObj.status = this.status.done;
        this.setLenderIsFavorite(returnObj);
      } else {
        this.selectedLenderObj = [];
      }
    });
  }

  setFundingCalculatorFundsQuickLMI(postData) {
    this.fundingCalculatorService.setFundingCalculatorFundsQuickLMI(postData).then(() => {
      this.getQuickLMICalculatorReport();
    }).catch(() => {
      this.LMIPremiumObj.data = null;
      this.LMIPremiumObj.status = this.status.done;
    });
  }

  openModal(postObj) {
    this.uibModal.open({
      templateUrl: 'assets/views/loanCalculator/partials/lmi_calculator_modal.html',
      controller: 'QuickLMICalculatorModalCtrl as vm',
      size: 'sm',
      resolve: {
        modalObj: () => postObj,
      },
    });
  }

  setSelectedLenderObj() {
    return this.selectedLenderObj && this.selectedLenderObj.length && this.selectedLenderObj[0];
  }

  saveToFundingCalculator() {
    this.fundingCalculatorService.setFundingCalculatorFundsQuickLMIFavorite(this.brokerEventId, this.setSelectedLenderObj().LenderId, true)
      .then(() => {
        const modalObj = {
          status: false,
          brokerEventId: this.brokerEventId,
        };
        const filterData = this.LMIPremiumObj.data.filter(item => item.LenderId === this.setSelectedLenderObj().LenderId);
        if (filterData && filterData.length && filterData[0].TotalIncStampDuty) {
          if (!_.isEmpty(this.fundingCalculatorDetailService.quickLmiFundingCalculatorFunds)) {
            const postData = {
              fundsId: this.fundingCalculatorDetailService.quickLmiFundingCalculatorFunds.id,
              fundingCalParametersId: this.fundingCalculatorDetailService.quickLmiFundingCalculatorFunds.fundingCalParametersId,
              fundingType: 'FUNDING_REQUIRED',
              fundingParticulars: this.fundingCalculatorDetailService.quickLmiFundingCalculatorFunds.fundingParticulars,
              fundingValue: filterData[0].TotalIncStampDuty,
              isOnlyValueEditable: this.fundingCalculatorDetailService.quickLmiFundingCalculatorFunds.isOnlyValueEditable,
              brokerEventId: this.brokerEventId,
            };
            this.fundingCalculatorService.setFundingCalculatorFunds(postData);
          }
          if (!_.isEmpty(this.fundingCalculatorDetailService.quickLmiProposedLending)) {
            const postData = {
              proposedLendingId: this.fundingCalculatorDetailService.quickLmiProposedLending.proposedLendingId,
              loanAmount: this.fundingCalculatorDetailService.quickLmiProposedLending.loanAmount,
              rate: this.fundingCalculatorDetailService.quickLmiProposedLending.rate,
              rateType: this.fundingCalculatorDetailService.quickLmiProposedLending.rateType,
              loanTermInYears: this.fundingCalculatorDetailService.quickLmiProposedLending.loanTermInYears,
              isInterestOnly: this.fundingCalculatorDetailService.quickLmiProposedLending.isInterestOnly,
              rateTerm: this.fundingCalculatorDetailService.quickLmiProposedLending.rateTerm,
              brokerEventId: this.brokerEventId,
              lmiAmount: filterData[0].TotalIncStampDuty,
            };
            this.fundingCalculatorService.setProposedLending(postData);
          }
        }
        // Set or Update the StateName and First Home Buyer on Funding Calculator
        const postData = {
          brokerEventID: this.brokerEventId,
          isFirstBuyer: this.mortgageObj.IsFirstHomeBuyer,
        };
        this.fundingCalculatorService.setFirstHomeBuyer(postData);
        this.openModal(modalObj);
      });
  }

  getLowestLoanAmount() {
    let tempValue = -1;
    this.selectedLenderObj.forEach((item) => {
      if (item.LoanAmountExLMI < tempValue || tempValue < 0) {
        tempValue = item.LoanAmountExLMI;
      }
    });
    return tempValue;
  }

  checkProductFinderBroker() {
    this.fundingCalculatorService.getFundingCalculatorQuickLMIProductFinderBrokerEventId(this.brokerEventId, null)
      .then((response) => {
        if (response && response.data.ProductFinderBrokerEventId) {
          this.saveToProductFinder(response.data.ProductFinderBrokerEventId);
        } else {
          this.loanProfilerService.getServiceabilityNewBrokerEvent().then((brokerResponse) => {
            this.saveToProductFinder(brokerResponse.data);
          });
        }
      });
  }

  saveToProductFinder(brokerEventIdForProductFinder) {
    this.fundingCalculatorService.setFundingCalculatorQuickLMIProductFinderBrokerEventId(this.brokerEventId, brokerEventIdForProductFinder)
      .then(() => {
        const securityAmount = _.sumBy(this.mortgageObj.security, item => item.Value);
        const loanAmount = this.mortgageObj && this.mortgageObj.GoalType ? this.getLowestLoanAmount() : this.mortgageObj.NewLoanAmount;
        const modalObj = {
          status: true,
          brokerEventIdForProductFinder,
          selectedLender: this.selectedLenderObj,
          loanAmount,
          securityAmount,
        };
        this.openModal(modalObj);
      });
  }

  setAllSecuritySaved(security) {
    this.securityObj = security
      .filter(item => !item.formInEditMode)
      .map((item) => {
        const objData = {
          FundingCalParametersID: item.FundingCalParametersID,
          BrokerEventID: this.brokerEventId,
          StateName: item.StateOfSercurity,
          SecurityAmount: item.Value,
          OccupancyType: item.OccupancyType,
        };
        return objData;
      });
  }

  calculateLMI() {
    const { Lenders, NewLoanAmount, PredominantLoanPurpose, GoalType, TargetLVR, NewLVRValue, security } = this.mortgageObj;
    this.setAllSecuritySaved(security);
    if (Lenders && !Lenders.length) {
      this.toaster.pop('error', 'Invalid', 'Please first select Lenders');
      return;
    } else if (!GoalType && !NewLoanAmount) {
      this.toaster.pop('error', 'Invalid', 'Please first set the total loan amount');
      return;
    } else if (this.securityObj && !this.securityObj.length) {
      this.toaster.pop('error', 'Invalid', 'Please first add/save securities');
      return;
    } else if (!PredominantLoanPurpose) {
      this.toaster.pop('error', 'Invalid', 'Please select predominant loan purpose');
      return;
    } else if (GoalType && !TargetLVR) {
      this.toaster.pop('error', 'Invalid', 'Please first set the target LVR');
      return;
    } else if (GoalType && TargetLVR > 100) {
      this.toaster.pop('error', 'Invalid', 'Target LVR should be within 100%');
      return;
    } else if (!GoalType && !NewLVRValue && NewLVRValue.replace('%', '') === '0') {
      this.toaster.pop('error', 'Invalid', 'Please set security amount higher than loan amount to have LVR result');
      return;
    }
    const { IsSelfEmployed, IsFirstHomeBuyer, IsQuickCalc, CapitalizeLMI, LVRValue } = this.mortgageObj;
    const postData = {
      BrokerEventID: this.brokerEventId,
      Lenders,
      LVRValue,
      IsSelfEmployed,
      NewLoanAmount,
      PredominantLoanPurpose,
      IsFirstHomeBuyer,
      TargetLVR,
      GoalType,
      IsQuickCalc,
    };
    this.goalTypeValue = this.mortgageObj.GoalType;
    this.LMIPremiumObj.status = this.status.waiting;
    if (IsQuickCalc) {
      this.fundingCalculatorService.setQuickLMISecurities(this.securityObj);
    } else {
      postData.CapitalizeLMI = CapitalizeLMI;
    }
    this.setFundingCalculatorFundsQuickLMI(postData);
  }

  $onInit() {
    this.IsQuickCalc = false;
    this.IsPreloaderHide = false;
    this.isSaveToFundingCalcDisabled = true;
    this.brokerEventId = this.state.params.brokerEventId;
    this.LMIPremiumObj = {
      data: [],
      status: this.status.empty,
    };
    this.selectedLenderShow = false;
    this.getFundingCalculatorFundsQuickLMI();
  }
}

angular.module('app').controller('QuickLMICalculatorCtrl', QuickLMICalculatorCtrl);
