class AddressHistoryService {
  constructor(
    generalService
  ) {
    'ngInject';

    this.generalService = generalService;
  }

  populateAddressHistorySuggestions(addressHistory) {
    if (!addressHistory || !addressHistory.Address) return;

    this.generalService.placeSearch(addressHistory.Address.formatted_address).then((response) => {
      addressHistory.addressSuggestions = response.data;
      addressHistory.showSuggestionModal = true;
    });
  }
}

export default AddressHistoryService;
