export function getDateSelection(currentMonth, currentDay, currentYear) {
  const selectedDate = `${currentMonth}/${currentDay}/${currentYear}`;
  const currentDate = new Date(selectedDate);
  const calendarTitle = `${['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][currentMonth - 1]} ${currentYear}`;
  const yearSelectionValue = currentYear;

  return {
    selectedDate,
    currentDate,
    calendarTitle,
    yearSelectionValue,
  };
}

export function getCurrentDateParts(currentDate) {
  return currentDate && {
    currentYear: currentDate.getFullYear(),
    currentMonth: currentDate.getMonth() + 1,
    currentDay: currentDate.getDate(),
  };
}
