import { kanbanHeight, kanbanItemMoved } from 'Common/utilities/kanbanHelper';
import toastr from 'toastr';
import moment from 'moment';
import KanbanSorting from './kanbanSorting';

class Kanban {
  constructor($window, pipelineSharedData, pipelineService, modalRenderService, loanOpportunityService, $timeout, name, columns = []) {
    this.name = name;
    this.columns = columns;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$window.addEventListener('resize', this.setHeight.bind(this));

    this.pipelineService = pipelineService;
    this.modalRenderService = modalRenderService;
    this.loanOpportunityService = loanOpportunityService;
    this.pipelineSharedData = pipelineSharedData;
    this.pipelineSharedData.initCardViewStates();

    this.setHeight();
    this.setWidth();
    this.setOptions();
    this.setDropboxModel();
    this.setReasons();
  }

  setHeight() {
    this.height = kanbanHeight(this.$window, this.height);
  }

  setWidth() {
    this.width = {
      value: !this.columns.length ? 100 : ((this.columns.length * 220) + 250),
      unit: !this.columns.length ? '%' : 'px',
    };
  }

  setOptions() {
    const { pipelineSharedData } = this;
    this.options = {
      /**
      * @param event = {}
      */
      itemMoved() {},
      /**
      * @param event = {}
      */
      orderChanged() {},
      containerPositioning: 'relative',
      /**
      * this is the expected id of the general container for your kanban
      */
      containment: '#kanban-containment',
      accept() { return true; },
      allowDuplicates: true,
      /**
      * please see pipelineSharedData.cardViewStates during this hook
      * as there are properties in there than are ready to use for you
      */
      dragStart: pipelineSharedData.handleDragStart,
      /**
      * please see pipelineSharedData.cardViewStates during this hook
      * as there are properties in there than are ready to use for you
      */
      dragEnd: pipelineSharedData.handleDragEnd,
      dragMove: pipelineSharedData.handleDragMove,
    };

    this.customOptions = {
      /**
      * @params itemToHighlight = {}, destinationColumn = {}
      */
      itemMovedPostCallback() {},
      /**
      * @param result = [optional, return of api post]
      * required itemMovedPostCallback for this success callback to be called
      */
      itemMovedPostSuccessCallback() {
        toastr.success('Card successfully moved!', 'Success');
      },
      /*
      * @param depends on the pipeline Type
      */
      itemConvertPostCallback() {},
      /**
      * @param result = [optional, return of api post]
      * required itemConvertPostCallback for this success callback to be called
      */
      itemConvertPostSuccessCallback() {
        toastr.success('Card successfully converted!', 'Success');
      },
    };
  }

  setDropboxModel() {
    this.dropboxModel = {
      cardApprove: [],
      cardNotProceeding: [],
    };
  }

  setReasons() {
    this.loanOpportunityService.getReasonSubstatus()
      .then((res) => {
        if (!res || !res.data) return;
        const { data } = res;
        this.reasonList = data;
      });
  }
}

export class KanbanOpportunity extends Kanban {
  constructor(args = {}) {
    const { $window, pipelineSharedData, pipelineService, modalRenderService, loanOpportunityService, $timeout, name, columns, generalCards, totalLoanAmounts, statusList } = args;
    super($window, pipelineSharedData, pipelineService, modalRenderService, loanOpportunityService, $timeout, name, columns);

    this.loanOpportunityService = loanOpportunityService;
    this.setOptionItemMoved = this.setOptionItemMoved.bind(this);
    this.updateCustomerSortString = this.updateCustomerSortString.bind(this);
    this.getLoanOpportunityTotalLoanAmounts = this.getLoanOpportunityTotalLoanAmounts.bind(this);

    this.setOptionAccept();
    this.setOptionItemMoved();
    this.convertCardsToColumns(generalCards, totalLoanAmounts, statusList);
    this.kanbanSorting = new KanbanSorting({ isLeads: false, isOpportunity: true }, { pipelineService });
  }

  setOptionAccept() {
    this.options.accept = (sourceItemHandleScope, destSortableScope) => {
      this.pipelineSharedData.cardViewStates.isNotProceedingDropAreaEnabled = false;
      this.pipelineSharedData.cardViewStates.isSettledDropAreaEnabled = false;
      this.$timeout(() => {
        if (destSortableScope && destSortableScope.element && destSortableScope.element.length) {
          const dropAreaId = destSortableScope.element[0].id;
          const areaDragged = this.pipelineSharedData.onDropAreaMouseIn(dropAreaId);
          if (areaDragged) {
            this.pipelineSharedData.cardViewStates[areaDragged] = this.pipelineSharedData.cardViewStates.cardOnDrag;
          }
        }
      });
      return true;
    };
  }

  /**
  * This method is tighly coupled for oppurtunity behavior
  * You can copy this to you subclass and change the action on itemMovedPostCallback
  */

  updateCustomerSortString() {
    this.kanbanSorting.updateCardList(this.columns);
  }

  setOptionItemMoved() {
    this.customOptions.itemMovedPostCallback = (itemToHighlight, destinationColumn) => {
      const { loanId } = itemToHighlight;
      const { pipelineStatusId } = destinationColumn;
      return this.loanOpportunityService.setLoanOpportunityStatus(loanId, pipelineStatusId);
    };

    this.customOptions.itemMovedPostSuccessCallback = (itemToHighlight, destinationColumn) => {
      toastr.success('Opportunity successfully moved!', 'Success');
      const card = destinationColumn.cards.find(o => o.loanId === itemToHighlight.loanId) || {};
      card.pipelineStatusId = destinationColumn.pipelineStatusId;
      card.lastModifiedDate = moment(new Date()).format();
      this.updateCustomerSortString();
      this.getLoanOpportunityTotalLoanAmounts();
    };

    this.customOptions.itemConvertPostCallback = (opportunityId, pipelineStatusId, selectedReasonId) => {
      const conversionStatus = { ConversionStatusId: pipelineStatusId, ReasonSubStatusId: selectedReasonId || 0 };
      return this.loanOpportunityService.setConversion(opportunityId, conversionStatus);
    };

    this.customOptions.itemConvertPostSuccessCallback = (isApproveApplication, response) => {
      if (isApproveApplication && !response.data.IsVisibleInPipeline) {
        this.modalRenderService.cardHiddenInApplication(response.data.FirstFamilyBorrowerName)
          .result
          .then(() => {
            this.itemConvertPostSuccessCallbackAction();
          });
      } else {
        this.itemConvertPostSuccessCallbackAction();
      }
    };

    this.itemConvertPostSuccessCallbackAction = () => {
      toastr.success('Opportunity successfully converted!', 'Success');
      this.updateCustomerSortString();
      this.getLoanOpportunityTotalLoanAmounts();
    };

    this.options.itemMoved = (event) => {
      kanbanItemMoved(
        event,
        this.pipelineSharedData,
        this.customOptions.itemMovedPostCallback,
        this.customOptions.itemMovedPostSuccessCallback,
        this.customOptions.itemConvertPostCallback,
        this.customOptions.itemConvertPostSuccessCallback,
        'card',
        'loanScenarioId',
        this.modalRenderService,
        this.reasonList
      );
    };

    this.options.orderChanged = () => {
      this.updateCustomerSortString();
    };
  }

  /**
  * this is a tightly coupled method that supports the model being used in
  * 'Pipeline/opportunity/views/cards' API (Cards & TotalLoanAmounts collection) - please see documentation
  */
  convertCardsToColumns(generalCards, totalLoanAmounts, statusList) {
    const columns = statusList.reduce((accum, current) => {
      const { PipelineStatus: name, PipelineStatusID: pipelineStatusId } = current;
      const filterCards = generalCards.filter(card => card.pipelineStatusId === pipelineStatusId);

      return accum.concat({
        name,
        pipelineStatusId,
        cards: filterCards,
      });
    }, []);

    this.columns = columns;
    this.putTotalLoanAmountsToColumns(totalLoanAmounts);
    this.setWidth();
  }

  putTotalLoanAmountsToColumns(totalLoanAmounts) {
    this.columns.map((column) => {
      const filterTotalLoanAmount = totalLoanAmounts.find(o => o.statusId === column.pipelineStatusId) || { totalLoanAmount: 0 };
      const { totalLoanAmount } = filterTotalLoanAmount;

      column.totalLoanAmount = totalLoanAmount;
      return column;
    });
  }

  getLoanOpportunityTotalLoanAmounts() {
    this.loanOpportunityService.getLoanOpportunityTotalLoanAmounts()
      .then(totalLoanAmounts => this.putTotalLoanAmountsToColumns(totalLoanAmounts));
  }
}
