import './style.scss';
import template from './featureManagement.html';
import controller from './featureManagementCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
  },
};
