import angular from 'angular';

angular.module('app').controller('InsInProgressCtrl',
  function InsInProgressCtrl(
    $scope,
    $timeout,
    $uibModal,
    $state,
    $localStorage,
    contactService,
    imageDataURI,
    $stateParams,
    insuranceQuoteService,
    toaster,
    insuranceSharedData
  ) {
    // In Progress INSURANCES
    // MODELS
    const tabName = 'InProgress';
    $scope.data = {
      familyId: parseInt($stateParams.familyId, 10) || 0,
      inProgressInsuranceList: [],
      insuranceProviderList: [],
      insurancePolicyList: [],
      filter: {
        policyNumber: 'Show All Policies',
        providerId: 0,
      },
    };

    // METHODS
    // Functionalities
    $scope.vm = {
      addExisting: () => {
        insuranceSharedData.addExistingInsurance('Existing');
      },
      deletePrevious: (familyId, policyId, caller) => {
        insuranceSharedData.deleteInsurance(familyId, policyId, caller);
      },
      editPrevious: (insurance) => {
        insuranceSharedData.editExistingInsurance(insurance, 'Existing');
      },
      changeFilters() {
        const data = $scope.data;
        const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
        insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
      },
      resetFilters() {
        $scope.data.filter = {
          policyNumber: 'Show All Policies',
          providerId: 0,
        };
        const data = $scope.data;
        const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
        insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
      },
    };
    // WATCHERS
    $scope.$watch(() => { return insuranceSharedData.sharedData.insuranceList; }, (newVal) => {
      if (newVal) {
        $scope.data.inProgressInsuranceList = newVal;
      }
    });

    $scope.$watch(() => { return insuranceSharedData.sharedData.insuranceProviderList; }, (newVal) => {
      if (newVal) {
        $scope.vm.resetFilters();
        $scope.data.insuranceProviderList = newVal;
      }
    });

    $scope.$watch(() => { return insuranceSharedData.sharedData.insurancePolicyList; }, (newVal) => {
      if (newVal) {
        $scope.vm.resetFilters();
        $scope.data.insurancePolicyList = newVal;
      }
    });

    const init = () => {
      const data = $scope.data;
      const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
      insuranceSharedData.getFiltersList(data.familyId, tabName);
      insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
    };

    init();
  });
