import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export default class InsurancePipelieFiltersCtrl {
  constructor(currentUserService) {
    'ngInject';

    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.isCorporateUser = this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    this.adviserFilter = this.adviserFilter || 0;
    this.labelFilter = this.labelFilter || 0;
  }

  $onChanges(changes) {
    const { labels, advisers, statusFilters } = changes;
    if (labels && labels.currentValue) {
      this.labels = [{ id: 0, name: 'All' }, ...labels.currentValue];
    }
    if (advisers && advisers.currentValue) {
      this.advisers = [{ brokerId: 0, displayName: 'All' }, ...advisers.currentValue];
    }
    if (statusFilters) {
      this.statusFilters = statusFilters.currentValue || [];
    }
  }
}
