import { incomeTypeOptionBuilderForMyCRM, clientOptionBuilderForMyCRM } from 'Common/mappers/serviceability';

class ServiceabilityService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Serviceability';
  }

  getIncomeOptions(brokerEventId, filters) {
    return this.httpClient.get(`${this.apiBaseUrl}/broker-event/${brokerEventId}/client-income-options`, { ...filters })
      .then((response) => {
        const { IncomeTypeOptions: incomeTypes, ClientOptions: clients } = (response && response.data) || { incomeTypes: [], clients: [] };

        return {
          incomeTypes: (incomeTypes && incomeTypes.map(incomeTypeOptionBuilderForMyCRM)) || [],
          clients: (clients && clients.map(clientOptionBuilderForMyCRM)) || [],
        };
      });
  }
}

export default ServiceabilityService;
