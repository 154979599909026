import OpenOnlineFactFindForm from 'Components/usersSubscription/openOnlineFactFindForm';

export default class OnlineFactFindModalCtrl {
  constructor(
    $uibModal
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  invite() {
    this.$uibModal.open({
      size: 'lg',
      windowClass: 'online-fact-find-form',
      templateUrl: 'assets/views/profileManagement/Users/openOnlineFactFindForm.html',
      controller: OpenOnlineFactFindForm,
      controllerAs: 'vm',
      resolve: {
        loanId: () => this.loanId,
      },
    });
    this.cancel();
  }

  request() {
    const props = {
      loanId: this.loanId,
    };
    this.$uibModal.open({
      template: `<request-fact-find
                    modal-instance='vm.modalInstance'
                    loan-id='vm.props.loanId'>
                </request-fact-find>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'online-fact-find-form',
      size: 'lg',
      resolve: {
        props: () => props,
      },
    });
    this.cancel();
  }

  cancel() {
    this.modalInstance.dismiss();
  }
}
