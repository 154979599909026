import angular from 'angular';
import _ from 'lodash';
import { openDate, formatCustomAddress } from 'Common/utilities/addressHistory';

angular.module('app').controller('AddAddressHistoryCtrl', function AddAddressHistoryCtrl(
  $scope,
  $uibModalInstance,
  $timeout,
  generalService,
  loanScenarioService,
  dashboardService,
  contactService,
  addressHistoryService,
  uiService,
  toaster
) {
  $scope.AddressHistory.Address = [];
  $scope.action = 'Add';
  $scope.modalHeading = `${$scope.action} an Address`;
  $scope.addAnotherAddress = true;
  $scope.getNewAddressModel = () => {
    $scope.AddressHistory.Type = '';
    $scope.AddressHistory.AddressType = null;
    $scope.AddressHistory.StartDate = '';
    $scope.AddressHistory.EndDate = '';
    $scope.AddressHistory.OwnershipTypeId = null;
    $scope.AddressHistory.RentalAmount = '';
    $scope.AddressHistory.FrequencyTypeId = '';
    $scope.AddressHistory.NumberOfMonths = null;
    $scope.AddressHistory.NumberOfYears = null;
  };
  $scope.getNewAddressModel();
  $scope.uiService = uiService;

  /**
   * Get applicants for ownership dropdown
   * */
  $scope.getApplicants($scope.action);

  $scope.cancel = () => {
    $uibModalInstance.close({ FamilyId: $scope.AddressHistory.SelectedClient });
  };

  $scope.AddressHistory.datePopUp = {
    StartDate: false,
    EndDate: false,
  };

  $scope.showExistAddressResult = false;
  $scope.showHideExistAddressResult = (status) => {
    $scope.showExistAddressResult = !status;
    $scope.AddressHistory.showSuggestionModal = false;
  };

  $scope.getExistAddress = () => {
    contactService.contactAddressGet($scope.AddressHistory.SelectedClient).then((response) => {
      $scope.existingAddress = _.uniqBy(response.data, address => address.AddressID);
    });
  };
  $scope.getExistAddress();

  // Open Date Popup
  $scope.AddressHistory.openDate = dateField => openDate($scope.AddressHistory, dateField);

  $scope.AddressHistory.format = 'dd MMM yyyy';
  $scope.AddressHistory.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  $scope.AddressHistory.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };

  $scope.getAddressOwnership();

  $scope.AddressHistory.getFormattedAddress = () => {
    $scope.AddressHistory.closeAutoHomeAddress();
    $timeout.cancel($scope.searchTimeout);
    if (_.isUndefined($scope.AddressHistory.Address)) {
      $scope.AddressHistory.Address = {};
    }
    $scope.AddressHistory.Address.geoCoded = false;
    $scope.AddressHistory.Address.latitude = 0;
    $scope.AddressHistory.Address.longitude = 0;
    $scope.searchTimeout = $timeout(() => {
      if (_.isUndefined($scope.AddressHistory.Address)) {
        $scope.AddressHistory.Address = {};
      }
      addressHistoryService.populateAddressHistorySuggestions($scope.AddressHistory);
    }, 1000);
  };

  $scope.AddressHistory.closeAutoHomeAddress = () => {
    $scope.AddressHistory.addressSuggestions = [];
  };

  $scope.AddressHistory.pickAddressSuggestion = (homeAddress) => {
    $scope.AddressHistory.Address = homeAddress;

    // Address to post
    $scope.AddressHistory.ContactAddress = homeAddress;

    $scope.AddressHistory.closeAutoHomeAddress();
    $scope.AddressHistory.showSuggestionModal = false;
    $scope.showExistAddressResult = false;
  };

  $scope.saveAddress = (isValid) => {
    if (!isValid) {
      toaster.pop('warning', 'Form incomplete', 'Please fill up all fields');
      return false;
    }
    const filteredAddressType = _.find($scope.AddressHistory.addressTypeList, (o) => {
      return o.Value === $scope.AddressHistory.AddressType;
    });
    $scope.saveAddClicked = true;
    $scope.AddressHistory.selectedFamily = $scope.AddressHistory.SelectedClient;
    if (filteredAddressType && filteredAddressType.Name) {
      $scope.AddressHistory.Type = filteredAddressType.Name;
    }

    formatCustomAddress($scope.AddressHistory);

    dashboardService.addressDetailsSet({
      ...$scope.AddressHistory.Address,
      IsMailing: false,
      Type: $scope.AddressHistory.Type,
      countryCode: $scope.AddressHistory.Address.CountryCode,
      FamilyId: $scope.AddressHistory.selectedFamily,
      TypeId: $scope.AddressHistory.AddressType,
      AddressValuationExtendedDetailsId: 0,
    })
      .then((response1) => {
        const { NumberOfYears, NumberOfMonths } = $scope.AddressHistory;
        return loanScenarioService.setAddress({
          AddressID: response1.data,
          FamilyId: $scope.AddressHistory.selectedFamily,
          Type: $scope.AddressHistory.Type,
          TypeId: $scope.AddressHistory.AddressType,
          StartDate: $scope.AddressHistory.StartDate,
          EndDate: $scope.AddressHistory.EndDate,
          OwnershipTypeId: $scope.AddressHistory.OwnershipTypeId,
          ContactLivingIn: $scope.AddressHistory.ContactLivingIn,
          RentalAmount: $scope.isWithRental($scope.AddressHistory.OwnershipTypeId) ? $scope.AddressHistory.RentalAmount : 0,
          FrequencyTypeId: $scope.AddressHistory.FrequencyTypeId,
          NumberOfYears: NumberOfYears && parseInt(NumberOfYears, 10),
          NumberOfMonths: NumberOfMonths && parseInt(NumberOfMonths, 10),
        });
      })
      .then(() => {
        $scope.AddressHistory.List.Address.push($scope.AddressHistory.Address);
        $scope.getAddressHistory();
        $scope.UPDATE_ADDRESS();
        $scope.AddressHistory.Address = [];
        if ($scope.addAnotherAddress) {
          $scope.modalHeading = 'Add Another Address';
        } else {
          $scope.cancel();
        }
        toaster.pop('success', 'Success', 'Address successfully saved!');
      })
      .finally(() => {
        $scope.saveAddClicked = false;
      });
  };
});
