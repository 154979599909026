import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

class UnmatchedCommissionCtrl {
  constructor(
    $timeout,
    $filter,
    $uibModal,
    uiService,
    utilitiesService,
    SweetAlert,
    commissionService,
    optionsService,
    contactService,
    NgTableParams
  ) {
    this.$timeout = $timeout;
    this.$filter = $filter;
    this.$uibModal = $uibModal;
    this.uiService = uiService;
    this.utilitiesService = utilitiesService;
    this.SweetAlert = SweetAlert;
    this.commissionService = commissionService;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.NgTableParams = NgTableParams;
    this.countPerPage = 10;
    this.unmatchedCommissions = [];
    this.brokerFilter = 0;
    this.typeFilter = 0;
    this.lenderFilter = 0;
    this.countryCode = 'NZ';
  }

  getInitials(name) {
    return this.utilitiesService.filterInitialOneString(name);
  }

  loadUnmatchedCommissions(callback) {
    if (this.uiService.isCorporateUser && !this.brokerFilter) return;

    this.isLoadingList = true;
    this.contactService.loanUnmatchedComissionSummary(this.brokerFilter, this.typeFilter, this.lenderFilter, this.unmatchedCommissionsTableParams.CurrentPage, this.countPerPage).then((response) => {
      this.unmatchedCommissions = response.data;

      _.each(this.unmatchedCommissions, (commission) => {
        if (commission.DatePaid && commission.DatePaid.trim()) {
          commission.DatePaid = moment(commission.DatePaid).toDate();
        }
      });

      if (this.unmatchedCommissions && this.unmatchedCommissions.length) {
        this.unmatchedCommissionsTableParams.total(this.unmatchedCommissions[0].TotalCount);
      } else {
        this.unmatchedCommissionsTableParams.total(0);
      }

      this.isLoadingList = false;
      if (callback) {
        callback();
      }
    }, () => {
      this.isLoadingList = false;
      return this.isLoadingList;
    });
  }

  applyFilter() {
    this.unmatchedCommissionsTableParams.CurrentPage = 1;
    this.initTableConfig();
  }

  commissionDetails(obj) {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'commissionDetails.html',
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      controller: 'UnmatchedCommissionDetailsCtrl as vm',
      resolve: {
        commissionDetails: () => {
          return obj;
        },
        typeList: () => {
          return this.types;
        },
        dateSettings: () => {
          return this.dateSettings;
        },
        commissionTypesList: () => {
          return this.types;
        },
        brokerId: () => {
          return this.brokerFilter;
        },
      },
      windowClass: 'commission-details-modal',
    });

    modalInstance.result.then((response) => {
      if (response && response.commission && response.actionDone === 'delete') {
        this.removeCommissionFromList(response.commission);
      }
    });
  }

  searchClient(commission, delay) {
    if (this.searchClientFilter && this.searchClientFilter.length >= 3) {
      if (delay) {
        this.$timeout(() => {
          this.getClientSearchResults(commission, this.searchClientFilter, true);
        }, 1500);
      } else {
        this.getClientSearchResults(commission, this.searchClientFilter);
      }
    }
  }

  getClientSearchResults(commission, fromAutoSearch) {
    if (!fromAutoSearch || !this.isSearchingClient || this.searchClientFilter !== this.previousFilter) {
      this.isSearchingClient = true;
      this.previousFilter = this.searchClientFilter;
      this.contactService.searchFamily(this.searchClientFilter, 0).then((response) => {
        this.isSearchingClient = false;
        if (response.data) {
          this.searchClientResults = response.data.FamilyList;
          commission.showSearchResults = true;
        }
      });
    }
  }

  clearSearchResultsFor(commission) {
    if (commission) {
      commission.showSearchResults = false;

      if (this.searchClientResults && this.searchClientResults.length) {
        this.searchClientResults.length = 0;
      }
    }
    this.searchClientFilter = '';
  }

  onClientSelected(commission, selectedClient) {
    if (selectedClient) {
      this.clearSearchResultsFor(commission);
      const modalInstance = this.$uibModal.open({
        templateUrl: 'assets/views/commission/modal/matchCommission.html',
        backdrop: 'static',
        keyboard: false,
        controller: 'MatchCommissionCtrl as vm',
        windowClass: 'match-commissions-modal',
        resolve: {
          commission: () => commission,
          familyIdToMatch: () => selectedClient.FamilyID,
        },
      });

      modalInstance.result.then((response) => {
        if (response && response.success && response.matchedCommission) {
          this.removeCommissionFromList(response.matchedCommission);
        }
      });
    }
  }

  removeCommissionFromList(commission) {
    if (!commission) return;

    const indexToRemove = _.findIndex(this.unmatchedCommissions, obj => obj.Commission_Spreadsheet_ID === commission.Commission_Spreadsheet_ID);
    this.unmatchedCommissions.splice(indexToRemove, 1);
    this.unmatchedCommissionsTableParams.reload();
    this.unmatchedCommissionsTableParams.page(this.unmatchedCommissionsTableParams.CurrentPage);
  }

  loadAllBrokers(callback) {
    this.commissionService.getAllBrokers().then((response) => {
      if (!response.data) return;

      if (this.uiService.isCorporateUser) {
        response.data.shift();
        this.brokers = [{ BrokerFullName: 'Please select broker', BrokerID: 0 }, ...response.data];
      } else {
        this.brokers = response.data;
      }

      if (callback) {
        callback();
      }
    });
  }

  loadCommissionTypes() {
    this.commissionService.commissionsTypeGet().then((response) => {
      this.types = [{ Value: 0, Name: 'All Types' }, ...response.data];
      return this.types;
    });
  }

  loadLenders() {
    this.optionsService.getLenderList().then((response) => {
      this.lenders = [{ ProviderId: 0, ProviderName: 'All Lenders' }, ...response.data];
      return this.lenders;
    });
  }

  initTableConfig() {
    this.unmatchedCommissionsTableParams = new this.NgTableParams({
      page: 1,
      count: this.countPerPage,
    },
    {
      total: 0,
      getData: ($defer, params) => {
        this.unmatchedCommissionsTableParams.CurrentPage = params.page();
        this.loadUnmatchedCommissions(() => {
          this.unmatchedCommissionsGrouped = this.utilitiesService.groupList(this.unmatchedCommissions, 5);
          $defer.resolve(this.unmatchedCommissions);
        });
      },
    });
  }

  $onInit() {
    this.initTableConfig();
    this.loadAllBrokers();
    this.loadCommissionTypes();
    this.loadLenders();

    this.dateSettings = {
      format: 'dd MMM yyyy',
      dateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
      altInputFormats: ['M!/d!/yyyy'],
      openDatePaidPicker: (commission) => {
        commission.datePaidIsOpen = !commission.datePaidIsOpen;
      },
    };
  }
}

angular.module('app').controller('UnmatchedCommissionCtrl', UnmatchedCommissionCtrl);
