import angular from 'angular';

angular.module('app').directive('scenarioModule', function scenarioModule() {
  return {
    restrict: 'E',
    scope: {
      name: '@',
      title: '@',
      status: '@',
    },
    transclude: true,
    template: '<div class="panel-column panel-column-{{status}}"> ' +
        '<div class="panel-column-heading flex"> ' +
        '<div class="checkbox clip-check check-info"> ' +
        '<input type="checkbox" id="{{name}}" value="1"> ' +
        '<label for="{{name}}"></label> ' +
        '</div> ' +
        '<span class="icon-scenario icon-scenario-{{name}}">' +
        '</span>' +
        '<p>{{title}}</p> ' +
        '</div> ' +
        '<div class="flex" ng-transclude> ' +
        '</div> ' +
        '<div class="flex text-right"> ' +
        '<a href="" class="panel-column-link"><i class="ti-arrow-circle-right"></i></a> ' +
        '</div> ' +
        '</div>',
    controller() {},
  };
});
