import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

angular.module('app').controller('InsuranceExistingModalCtrl',
  function InsuranceExistingModalCtrl(
    $scope,
    $timeout,
    $interval,
    $http,
    $uibModalInstance,
    $uibModal,
    $compile,
    toaster,
    $state,
    contactInsuranceService,
    insuranceSharedData,
    contactInsuranceSharedDataService,
    items
  ) {
    /*
    Table of Contents
    1. MODELS
    2. Functions
    3. METHODS
    4. Shared Data
    */

    // 1. MODELS
    $scope.formData = {
      isSaving: false,
      isEditing: false,
      hasExcess: false,
    };
    const tempInsuranceDetailsInput = contactInsuranceSharedDataService.tempInsuranceDetailsInput($state.params.familyId);

    const tempFrequencyList = [
      { Name: 'Select Frequency', id: '' },
      { Name: 'Fortnightly', id: 26 },
      { Name: 'Weekly', id: 52 },
      { Name: 'Monthly', id: 12 },
      { Name: 'Yearly', id: 1 },
    ];

    const tempLoadingList = [
      { Name: 'Select an option', id: 0 },
      { Name: '0%', id: 1 },
      { Name: '50%', id: 2 },
      { Name: '75%', id: 3 },
      { Name: '100%', id: 4 },
      { Name: '125%', id: 5 },
      { Name: '150%', id: 6 },
      { Name: '175%', id: 7 },
      { Name: '200%', id: 8 },
      { Name: '250%', id: 9 },
      { Name: '300%', id: 10 },
      { Name: '400%', id: 11 },
      { Name: '500%', id: 12 },
    ];

    const tempFPBenefitPeriodList = [
      // { benefitPeriodID: 1, Description: 'Fixed Terms', Value: 1 },
      // { benefitPeriodID: 2, Description: 'To Age', Value: 2 },
      { benefitPeriodID: '', Description: 'Select Period', Value: '' },
      { benefitPeriodID: 1, Description: '1 Year', Value: 1 },
      { benefitPeriodID: 2, Description: '2 Years', Value: 2 },
      { benefitPeriodID: 3, Description: '5 Years', Value: 3 },
      { benefitPeriodID: 4, Description: 'To Age 65', Value: 4 },
      { benefitPeriodID: 5, Description: 'To Age 70', Value: 5 },
    ];

    const benefitPeriodListGet = () => {
      contactInsuranceService.insuranceQuoteIPBenefitPeriodGet().then((response) => {
        if (response.data) {
          $scope.benefitPeriodList = [{ Key: 'Select benefit period', Value: '' }, ...response.data];
        }
      });
    };

    const weekWaitPeriodListGet = () => {
      contactInsuranceService.insuranceQuoteWeekWaitPeriodGet().then((response) => {
        if (response.data) {
          $scope.weekWaitPeriodList = [{ Key: 'Select wait period', Value: '' }, ...response.data];
        }
      });
    };

    const insuranceQuoteExcessGet = () => {
      contactInsuranceService.insuranceQuoteExcessGet().then((response) => {
        if (response.data) {
          $scope.excessList = [{ Key: 'Select Excess', Value: '' }, ...response.data];
        }
      });
    };

    angular.extend($scope, {

      benefitTypeList: [],
      insuranceModalTab: 'overview',
      insuranceDetailsInput: tempInsuranceDetailsInput,
      excessList: [],
      clientId: null,
      familyAdultList: [],
      familyList: [],
      selectedInsuredPerson: '',
      insurersList: [],
      productList: [],
      statusList: [],
      coverTypeList: [],
      frequencyList: tempFrequencyList,
      loadingList: tempLoadingList,
      insuranceCalcPeriodList: [],
      fpBenefitPeriodList: tempFPBenefitPeriodList,
      benefitPeriodList: [],
      weekWaitPeriodList: [],
    });

    // 2. Functions
    function insuranceDetailsSet() {
      $scope.insuranceDetailsInput.InsuranceDetails.ClientID = $scope.selectedInsuredPerson;
      $scope.insuranceDetailsInput.InsuranceDetails.CommencementDate = moment($scope.insuranceDetailsInput.InsuranceDetails.TempCommencementDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      _.forEach($scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions, (exclusion) => {
        if (exclusion.LoadingExclusion) {
          exclusion.LoadingExclusionDate = moment(exclusion.LoadingExclusionDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }
      });
      contactInsuranceService.insuranceDetailsSet($scope.insuranceDetailsInput).then((response) => {
        if (response.data) {
          toaster.pop('success', 'Success', 'Insurace successfully saved');
          $scope.formData.isSaving = false;
          $uibModalInstance.close('close');
        }
      });
    }

    function insuranceProductGet() {
      contactInsuranceService.insuranceProductGet($scope.insuranceDetailsInput.InsuranceDetails.ProviderID, $scope.insuranceDetailsInput.InsuranceDetails.BenefitTypeID).then((response) => {
        if (response.data && response.data.length > 0) {
          $scope.productList = [{ ProductName: 'Select product', ProductID: '' }, ...response.data];
        } else {
          $scope.productList.push({ ProductName: 'Select product', ProductID: '' });
        }
      });
    }

    function insuranceCalcPeriodGet() {
      contactInsuranceService.insuranceCalculationPeriodGet().then((response) => {
        response.data.unshift({ Key: 'Select calculation period', Value: '' });
        $scope.insuranceCalcPeriodList = response.data;
      });
    }

    function insuranceQuoteProviderGet() {
      const data = $scope.insuranceDetailsInput.InsuranceDetails;
      const providerId = data.ProviderID === '' ? 0 : data.ProviderID;
      contactInsuranceService.insuranceExistingProviderGet(providerId, data.BenefitTypeID).then((response) => {
        response.data.unshift({ ProviderName: 'Select Insurer', ProviderId: '' });
        $scope.insurersList = response.data;
      });
    }

    function insuranceBenefitsGet() {
      contactInsuranceService.insuranceBenefitTypesGet().then((response) => {
        if (response.data && response.data.length > 0) {
          $scope.benefitTypeList = [{ BenefitTypeName: 'Select benefit', BenefitTypeID: '', InsuranceType: '', QPRBenefitID: null }, ...response.data];
        }
      });
    }

    function insuranceStatusGet() {
      contactInsuranceService.insuranceStatusGet().then((response) => {
        response.data.unshift({ StatusName: 'Select Status', StatusID: '' });
        $scope.statusList = response.data;
      });
    }

    function iPCoverTypeGet() {
      contactInsuranceService.insuranceQuoteIPCoverTypeGet().then((response) => {
        response.data.unshift({ Key: 'Select cover type', Value: '' });
        $scope.coverTypeList = response.data;
      });
    }

    function editDataSet() {
      if (items.data.BenefitID > 0) {
        $scope.formData.isEditing = true;
        const insDetails = items.data;
        const tdate = new Date(insDetails.InsuranceDetails.CommencementDate);
        insDetails.InsuranceDetails.CommencementDate = moment(tdate).format('DD-MM-YYYY');
        items.data.InsuranceDetails.TempCommencementDate = angular.copy(items.data.InsuranceDetails.CommencementDate);
        _.forEach(items.data.InsuranceDetails.LoadingExclusions, (exclusion) => {
          const excDate = new Date(exclusion.LoadingExclusionDate);
          exclusion.LoadingExclusionDate = moment(excDate).format('DD-MM-YYYY');
        });
        if (insDetails.InsuranceDetails.ClientID && insDetails.InsuranceDetails.ClientID !== 0) {
          $scope.selectedInsuredPerson = typeof insDetails.InsuranceDetails.ClientID === 'number' ? insDetails.InsuranceDetails.ClientID.toString() : insDetails.InsuranceDetails.ClientID;
        }
        $scope.insuranceDetailsInput = insDetails;
        $scope.benefitTypeChange(insDetails.InsuranceDetails.BenefitTypeID);
        $scope.insuranceDetailsInput.InsuranceDetails.ProviderID = insDetails.InsuranceDetails.ProviderID;
        $scope.insuranceDetailsInput.InsuranceDetails.ProductID = insDetails.InsuranceDetails.ProductID;
      }
    }

    function loadingExclusionList(value) {
      return {
        ClientID: value.PersonId,
        LoadingExclusion: false,
        Loading: 0,
        LoadingExclusionDetails: '',
        LoadingExclusionDate: '',
        FullName: value.FullName,
        StandardRates: true,
        SelectedOptions: 1,
      };
    }

    function policyOwnershipList(value, insuredId) {
      return {
        ClientID: value.PersonId,
        IsDependent: true,
        IsOwnership: value.Role === 'Adult',
        FullName: value.FullName,
        IsInsured: value.PersonId === insuredId,
      };
    }

    function otherPolicyOwnershipList() {
      return {
        ClientID: 0,
        IsDependent: false,
        IsOwnership: false,
        FullName: 'Other',
        IsInsured: false,
        Other: '',
      };
    }

    function addPolicyOwnership() {
      if (!$scope.formData.isEditing) {
        $scope.insuranceDetailsInput.InsuranceDetails.PolicyOwnership = [];
        _.forEach($scope.familyAdultList, (value) => {
          $scope.insuranceDetailsInput.InsuranceDetails.PolicyOwnership.push(policyOwnershipList(value, $scope.selectedInsuredPerson));
        });
        $scope.insuranceDetailsInput.InsuranceDetails.PolicyOwnership.push(otherPolicyOwnershipList());
      } else {
        _.forEach($scope.insuranceDetailsInput.InsuranceDetails.PolicyOwnership, (po) => {
          if (po.ClientID !== 0) {
            const fltr = _.filter($scope.familyAdultList, (member) => {
              return parseInt(member.PersonId, 10) === po.ClientID;
            });

            if (fltr && fltr.length > 0) {
              po.IsDependent = true;
              po.FullName = fltr[0].FullName;
              po.IsInsured = fltr[0].PersonId === $scope.selectedInsuredPerson;
            }
          } else {
            po.IsDependent = true;
            po.FullName = 'Other';
            po.IsInsured = po.Other !== '';
          }
        });
      }
    }

    function addLoadingExclusion() {
      if (!$scope.formData.isEditing) {
        $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions = [];

        if ($scope.insuranceDetailsInput.InsuranceDetails.BenefitTypeID === 3) {
          _.forEach($scope.familyList, (value) => {
            $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions.push(loadingExclusionList(value));
          });
        } else {
          let selectedInsuredPerson = {};
          if ($scope.selectedInsuredPerson !== '') {
            _.forEach($scope.familyAdultList, (member) => {
              if ($scope.selectedInsuredPerson === member.PersonId) {
                selectedInsuredPerson = member;
              }
            });
          } else {
            selectedInsuredPerson = $scope.familyAdultList[0] || {};
          }
          $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions.push(loadingExclusionList(selectedInsuredPerson));
        }
      } else {
        const loadingExcl = $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions || [];
        const excl = [];

        if ($scope.insuranceDetailsInput.InsuranceDetails.BenefitTypeID === 3) {
          _.forEach($scope.familyList, (fl) => {
            const fltrRes = loadingExcl.filter((le) => {
              return le.ClientID === parseInt(fl.PersonId, 10);
            });

            if (fltrRes.length > 0) {
              fltrRes[0].FullName = fl.FullName;
              fltrRes[0].Loading = fltrRes[0].Loading || 0;
              fltrRes[0].SelectedOptions = fltrRes[0].Loading > 0 ? 0 : 1;
              fltrRes[0].LoadingExclusion = fltrRes[0].SelectedOptions !== 1;
              fltrRes[0].Excess = fltrRes[0].Excess || '';
              excl.push(fltrRes[0]);
            } else {
              excl.push(loadingExclusionList(fl));
            }
          });

          $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions = excl;
        } else {
          const exclusions = loadingExcl || $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions;

          if (exclusions[0]) {
            const loadingExclusions = exclusions[0];
            if ($scope.selectedInsuredPerson !== '') {
              loadingExclusions.ClientID = parseInt($scope.selectedInsuredPerson, 10);
            }

            _.filter($scope.familyList, (member) => {
              if (parseInt(member.PersonId, 10) === loadingExclusions.ClientID) {
                $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions[0] = {
                  ClientID: member.PersonId,
                  LoadingExclusion: loadingExclusions.Loading ? loadingExclusions.Loading !== 0 : false,
                  Loading: loadingExclusions.Loading || 0,
                  LoadingExclusionDetails: loadingExclusions.LoadingExclusionDetails,
                  LoadingExclusionDate: loadingExclusions.LoadingExclusionDate,
                  FullName: member.FullName,
                  StandardRates: false,
                  SelectedOptions: loadingExclusions.Loading > 0 ? 0 : 1,
                };
              }
            });
          } else {
            $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions.push(loadingExclusionList($scope.familyAdultList[0]));
          }
        }
      }
    }

    function clientBenefitsHealthCoverList(value) {
      return {
        ClientID: value.PersonId,
        FullName: value.FullName,
        Role: value.Role,
        BaseCover: false,
        Loading: 0,
        Excess: '',
        SpecialistsTest: false,
        GpPrescriptions: false,
        DentalOptical: false,
      };
    }

    function addClientBenefitsHealthCover() {
      if (!$scope.formData.isEditing) {
        $scope.insuranceDetailsInput.ClientBenefits.HealthCover = [];

        _.forEach($scope.familyList, (value) => {
          $scope.insuranceDetailsInput.ClientBenefits.HealthCover.push(clientBenefitsHealthCoverList(value));
        });
      } else {
        const healthCover = $scope.insuranceDetailsInput.ClientBenefits.HealthCover || [];
        const cover = [];

        _.forEach($scope.familyList, (fl) => {
          const fltrRes = healthCover.filter((hc) => {
            return hc.ClientID === parseInt(fl.PersonId, 10);
          });

          if (fltrRes.length > 0) {
            fltrRes[0].FullName = fl.FullName;
            fltrRes[0].Role = fl.Role;
            fltrRes[0].Loading = 0;
            fltrRes[0].Excess = fltrRes[0].Excess || 0;
            cover.push(fltrRes[0]);
          } else {
            cover.push(clientBenefitsHealthCoverList(fl));
          }
        });

        $scope.insuranceDetailsInput.ClientBenefits.HealthCover = cover;
      }
    }

    function clientInformGet() {
      contactInsuranceService.clientInformGet($scope.$stateParams.familyId, $scope.clientId).then((response) => {
        $scope.familyList = response.data;

        if ($scope.familyList.length >= 1) {
          $scope.familyAdultList = _.filter($scope.familyList, (o) => {
            if (o.Role === 'Adult') {
              return o;
            }
          });

          if ($scope.familyAdultList.length >= 1) {
            $scope.selectedInsuredPerson = $scope.familyAdultList[0].PersonId;
          }

          insuranceBenefitsGet();
          insuranceQuoteProviderGet();
          insuranceProductGet();
          insuranceStatusGet();
          insuranceCalcPeriodGet();
          iPCoverTypeGet();
          editDataSet();

          addPolicyOwnership();
          addLoadingExclusion();
          addClientBenefitsHealthCover();
        }
      });
    }

    function setQPRBenefitID(value) {
      _.find($scope.benefitTypeList, (o) => {
        if (o.BenefitTypeID === value) {
          $scope.insuranceDetailsInput.InsuranceDetails.QPRBenefitID = o.QPRBenefitID;
        }
      });
    }

    function funcOtherOwnershipChange(value) {
      if (value.FullName === 'Other' && !value.IsOwnership) {
        value.Other = '';
      }
    }

    function funcWaivePolicyChange(waivePolicyValue) {
      $scope.insuranceDetailsInput.InsuranceDetails.PolicyFee = 0;
      if (waivePolicyValue) {
        $scope.insuranceDetailsInput.InsuranceDetails.AutoPolicyFee = false;
        $scope.disablePolicyFee = true;
        $scope.policyFeeInput = false;
      } else {
        $scope.disablePolicyFee = false;
        $scope.policyFeeInput = true;
      }
    }

    function funcautoPremiumsChange() {
      $scope.insuranceDetailsInput.InsuranceDetails.TotalPremium = 0;
    }

    function funcAutoPolicyFeeChange(autoPolicyFeeValue) {
      if (autoPolicyFeeValue) {
        $scope.insuranceDetailsInput.InsuranceDetails.WaivePolicy = false;
        $scope.disablePolicyFee = false;
        $scope.policyFeeInput = true;
      } else {
        $scope.insuranceDetailsInput.InsuranceDetails.PolicyFee = 0;
      }
    }

    // 2. METHODS
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.init = () => {
      clientInformGet();
      benefitPeriodListGet();
      weekWaitPeriodListGet();
      insuranceQuoteExcessGet();
    };

    $scope.baseCoverChange = (indx) => {
      if ($scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].Excess === '') {
        $scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].BaseCover = false;
      } else {
        const baseCover = $scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].BaseCover;
        if (!baseCover) {
          $scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].SpecialistsTest = baseCover;
          $scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].GpPrescriptions = baseCover;
          $scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].DentalOptical = baseCover;
        }
      }
    };

    $scope.hcExcessChange = (indx) => {
      if (!$scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].Excess) {
        $scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].BaseCover = false;
        $scope.baseCoverChange(indx);
        $scope.formData.hasExcess = false;
      } else {
        $scope.insuranceDetailsInput.ClientBenefits.HealthCover[indx].BaseCover = true;
      }
      $scope.excessChecker();
    };

    $scope.excessChecker = () => {
      _.forEach($scope.insuranceDetailsInput.ClientBenefits.HealthCover, (hc) => {
        if (hc.Excess && hc.Excess > 0) {
          $scope.formData.hasExcess = true;
        }
      });
    };

    $scope.benefitTypeChange = (value) => {
      insuranceQuoteProviderGet();
      insuranceProductGet();
      addLoadingExclusion();
      setQPRBenefitID(value);
    };

    $scope.providerChange = () => {
      insuranceProductGet();
    };

    $scope.insuredPersonChange = (personId) => {
      $scope.selectedInsuredPerson = personId;
      addLoadingExclusion();
    };

    $scope.standardRatesChange = (value, indx) => {
      if ($scope.insuranceDetailsInput.InsuranceDetails.BenefitTypeID !== '') {
        if (value.SelectedOptions === '1') {
          value.LoadingExclusion = false;
          value.StandardRates = true;
        } else {
          value.LoadingExclusion = true;
          value.StandardRates = false;
        }
      } else {
        if ($scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions[indx].SelectedOptions) {
          $scope.insuranceDetailsInput.InsuranceDetails.LoadingExclusions[indx].SelectedOptions = '1';
        }
        toaster.pop('warning', 'Required Field', 'Please select Benefit Type first before adding Loading or Exclusion.');
      }
    };

    $scope.saveNewExistingInsurance = () => {
      $scope.formData.isSaving = true;
      insuranceDetailsSet();
    };

    $scope.otherOwnershipChange = (value) => {
      funcOtherOwnershipChange(value);
    };

    $scope.waivePolicyChange = (waivePolicyValue) => {
      funcWaivePolicyChange(waivePolicyValue);
    };

    $scope.autoPremiumsChange = () => {
      funcautoPremiumsChange();
    };

    $scope.autoPolicyFeeChange = (autoPolicyFeeValue) => {
      funcAutoPolicyFeeChange(autoPolicyFeeValue);
    };

    $scope.init();

    // 4. Shared Data
  });
