import angular from 'angular';
import { BIRTHDAYS_ID, CUSTOMER_CARE_TYPES } from 'Common/constants/customerCareModules';
import moment from 'moment';

angular.module('app').controller('BirthdaysSendEmailModalCtrl', function BirthdaysSendEmailModalCtrl(
  $scope,
  $rootScope,
  $uibModalInstance,
  $uibModal,
  $state,
  $controller,
  toaster,
  contactService,
  corporateService,
  customerCareService,
  emailTemplatesService,
  ckEditorConfigService,
  emailTemplatesSharedData,
  birthdayBatchList,
  source,
  birthdayObj,
  formErrorHandlerServices,
  loadBirthdaysTable,
  customerCareTemplateService) {
  const vm = this;
  angular.extend(vm, $controller('CustomerCareSendEmailModalBaseCtrl', { $scope }));

  $scope.recipients = {};
  $scope.IsComplete = true;
  $scope.currentModule = BIRTHDAYS_ID;

  if (source === 'single') {
    if (birthdayObj.Email !== '') {
      const birthDate = moment(birthdayObj.DateOfBirth).format('D MMM');
      const notiHeader = `Birthdays (${birthDate}) Completed via Email`;
      $scope.recipients.email = [{
        FamilyID: birthdayObj.FamilyID,
        FamilyFullname: birthdayObj.FamilyFullName,
        LoanID: birthdayObj.LoanID,
        EmailAddress: birthdayObj.Email,
        NotificationHeader: notiHeader,
      }];
    }
    $scope.recipients.CustomerCareType = 'BIRTH_DAY';
  }

  $scope.birthdayBatchList = birthdayBatchList;

  if (source === 'selected') {
    $scope.all = true;
    if ($scope.birthdayBatchList && $scope.birthdayBatchList.length > 0) {
      $scope.selectEmailList = [];

      for (let i = 0; i < $scope.birthdayBatchList.length; i++) {
        $scope.emailAdd = {};
        if ($scope.birthdayBatchList[i].Email !== '') {
          $scope.emailAdd.FamilyID = $scope.birthdayBatchList[i].FamilyID;
          $scope.emailAdd.FamilyFullname = $scope.birthdayBatchList[i].FamilyFullName;
          $scope.emailAdd.LoanID = $scope.birthdayBatchList[i].LoanID;

          $scope.emailAdd.EmailAddress = $scope.birthdayBatchList[i].Email;
          $scope.selectEmailList.push($scope.emailAdd);
        }
      }
      if ($scope.selectEmailList.length > 0) {
        $scope.recipients.email = $scope.selectEmailList;
        $scope.noOfPeople = $scope.selectEmailList.length;
      }
    }
  }
  $scope.getRecipientAutoSuggestion = (userInput) => {
    contactService.getRecipientAutoSuggestion(userInput).then((response) => {
      $scope.withEmail = response.data;
      $scope.selectEmailList = !$scope.withEmail ? [] : $scope.withEmail.reduce((accum, recipient) => {
        if (!recipient.EmailAddress || !recipient.EmailAddress.trim()) return accum;
        $scope.selectEmail = {};
        $scope.selectEmail.FamilyFullname = recipient.Name;
        $scope.selectEmail.EmailAddress = recipient.EmailAddress;
        return accum.concat($scope.selectEmail);
      }, []);
    });
  };

  // EmailTemplates
  const getMergeFields = () => {
    $scope.preferredName = '';
    if (typeof birthdayObj.FamilyID !== 'undefined') {
      $scope.familyId = birthdayObj.FamilyID;
    } else {
      $scope.familyId = 0;
    }
    if (typeof birthdayObj.LoanID !== 'undefined') {
      $scope.loanId = birthdayObj.LoanID;
    } else {
      $scope.loanId = 0;
    }
    $scope.clientId = 0;
    corporateService.MergeFieldsGet($scope.familyId, $scope.clientId, $scope.loanId).then((response) => {
      $scope.emailMergeFieldList = response.data;
      $scope.mergeFieldsObj = response.data[0];
      $scope.mergeFields = [];
      $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
      for (let k = 0; k < $scope.mergeFields.length; k++) {
        if ($scope.mergeFields[k].Name === 'Full Name') {
          $scope.preferredName = $scope.mergeFields[k].Value;
        }
      }

      $scope.getEmailTemplates($scope.preferredName);
      $scope.getDefaultTemplate();
    });
  };

  getMergeFields();

  $rootScope.getEmailTemplatesFromEmailPage = () => {
    $scope.getEmailTemplates('');
  };
  $scope.selectedTemplate = {
    UserTemplateID: 0,
    UserTemplateName: 'Blank',
    UserTemplateEmailSubject: '',
  };

  $scope.refreshResults = (select) => {
    const search = select.search;
    $scope.getRecipientAutoSuggestion(search);
  };

  const init = () => {
    $scope.subject = 'Happy Birthday!';
  };

  init();

  $scope.sendEmail = (form) => {
    const customerCareFormValid = formErrorHandlerServices.sendEmailFormValidation(form);
    if (customerCareFormValid) {
      $scope.recipientsEmailArray = [];
      angular.forEach($scope.recipients.email, (item) => {
        $scope.recipientsEmailArray.push(item);
      });
      $scope.email.ClientList = $scope.recipientsEmailArray;
      $scope.email.CustomerCareType = CUSTOMER_CARE_TYPES.BIRTHDAYS;
      $scope.email.IsComplete = $scope.IsComplete;


      customerCareService.CustomerCareEmailSendv2($scope.email).then((birthdayRes) => {
        if (birthdayRes.data && birthdayRes.data.length) {
          const birthdayEmailValid = customerCareTemplateService.customerCareResponseMsg(birthdayRes.data, 'EMAIL');
          if (birthdayEmailValid) {
            $scope.email.EmailSubject = '';
            $scope.email.EmailBody = '';
            loadBirthdaysTable();
            form.$setPristine(true);
          }
          $scope.cancel();
        }
      });
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
});
