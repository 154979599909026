import angular from 'angular';
import _ from 'lodash';

angular.module('app').factory('ContactModel', ['httpRequest', 'httpCacheService', function (httpRequest, httpCacheService) {
  var apiBaseUrl = 'contacts/';

  function ContactModel() {
  }

  ContactModel.prototype = {

    clientInformGet(familyID, clientId, isHttpRequest, callback) {
      var clientQuery = (typeof clientId !== 'undefined' ? `&clientId=${clientId}` : '');
      var httpVar;

      if (isHttpRequest) {
        httpVar = httpRequest;
      } else {
        httpVar = httpCacheService;
      }

      httpVar.get(`${apiBaseUrl}ClientInformGet?familyId=${familyID}${clientQuery}`, (response) => {
        return callback(response);
      });
    },
    familyAddressInformGet(familyID, callback) {
      if (!familyID) return false;
      httpRequest.get(`${apiBaseUrl}FamilyAddressInformGet?familyId=${familyID}`, (response) => {
        return callback(response);
      });
    },
    addressIDGet(familyID, callback) {
      if (!familyID) return false;
      httpRequest.get(`${apiBaseUrl}AddressIDGet?familyId=${familyID}`, (response) => {
        return callback(response);
      });
    },

    organisationInfoGet(id, callback) {
      httpRequest.get(`${apiBaseUrl}OrganisationInfoGet?familyId=${id}`, (response) => {
        return callback(response);
      });
    },

    organisationInfoSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}OrganisationInfoSet`, data, (response) => {
        return callback(response);
      });
    },

    categorySet(familyId, categoryId, categoryName, callback) {
      httpRequest.post(`${apiBaseUrl}CategorySet?familyId=${familyId}&categoryId=${categoryId}&categoryName=${categoryName}`, {}, (response) => {
        return callback(response);
      });
    },

    getCompanyTypes(callback) {
      httpRequest.get(`${apiBaseUrl}/CompanyTypeList`, (response) => {
        return callback(response);
      });
    },

    /*
//Non existing

    transList: function (familyID, callback) {
      if (!familyID) return false;
      httpRequest.get(apiBaseUrl + "TransList?familyId=" + familyID, function (response) {
        return callback(response);
      })ž
    },
*/
    notesList(familyID, count, brokerbrandingId, callback) {
      if (!familyID) return false;
      httpRequest.get(`${apiBaseUrl}NoteList?familyId=${familyID}&count=${count}&brokerBrandingId=${brokerbrandingId}`, (response) => {
        return callback(response);
      });
    },

    noteGet(familyID, commId, brokerbrandingId, isOpportunity, callback) {
      httpRequest.get(`${apiBaseUrl}NoteGet?communicationId=${commId}&familyId=${familyID}&brokerBrandingId=${brokerbrandingId}&isOpportunity=${isOpportunity}`, (response) => {
        return callback(response);
      });
    },
    notesListCount(familyID, brokerbrandingId, callback) {
      if (!familyID) return false;
      httpRequest.get(`${apiBaseUrl}NoteListCount?familyId=${familyID}&brokerbrandingId=${brokerbrandingId}`, (response) => {
        return callback(response);
      });
    },

    noteSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}NoteSet`, data, (response) => {
        return callback(response);
      });
    },

    noteDelete(id, callback) {
      httpRequest.delete(`${apiBaseUrl}NoteDelete?communicationId=${id}`, (response) => {
        return callback(response);
      });
    },

    taggedList(familyID, callback) {
      if (!familyID) return false;
      httpRequest.get(`${apiBaseUrl}TaggedList?familyID=${familyID}`, (response) => {
        return callback(response);
      });
    },
    taggedSet(familyID, taggedReason, callback) {
      if (!familyID) return false;
      var data = {
        familyID,
        taggedReason,
      };
      httpRequest.post(`${apiBaseUrl}TaggedSet`, data, (response) => {
        return callback(response);
      });
    },

    taggedDelete(familyID, taggedId, callback) {
      var data = {
        familyID,
        taggedId,
      };
      httpRequest.post(`${apiBaseUrl}TaggedDelete`, data, (response) => {
        return callback(response);
      });
    },

    // Active Or Inactive Tasks
    //-----------------------------------------------------------------------------------
    tasksList(familyID, count, callback) {
      if (!familyID) return false;
      httpRequest.get(`${apiBaseUrl}TaskList?familyID=${familyID}&count=${count}`, (response) => {
        return callback(response);
      });
    },

    // Adviser Comment
    //-----------------------------------------------------------------------------------
    adviserCommentGet(familyID, callback) {
      httpRequest.post(`${apiBaseUrl}AdviserCommentGet`, { familyId: familyID }, (response) => {
        return callback(response);
      });
    },

    adviserCommentSet(familyID, comment, callback) {
      httpRequest.post(`${apiBaseUrl}AdviserCommentSet?familyId=${familyID}&comment=${comment}`, {}, (response) => {
        return callback(response);
      });
    },


    taskAdviserListGet(callback) {
      httpRequest.get(`${apiBaseUrl}TaskAdviserListGet`, (response) => {
        return callback(response);
      });
    },

    taskSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}TaskSet`, $postData, (response) => {
        return callback(response);
      });
    },

    getTaskListCount(familyID, callback) {
      if (!familyID) return false;
      httpRequest.get(`${apiBaseUrl}TaskListCount?familyID=${familyID}`, (response) => {
        return callback(response);
      });
    },
    setTaskCompletion($postData, callback) {
      httpRequest.post(`${apiBaseUrl}TaskCompletionSet`, $postData, (response) => {
        return callback(response);
      });
    },

    getAllByFilter(startWith, page, count, sortColumn, sortMode, searchCriteria, contactTypes, adviserFamilyId, clientContactStatusId, clientRecordType, ratings, categories, callback) {
      httpRequest.get(`${apiBaseUrl}FamilyListGet?startWith=${startWith}&page=${page}&count=${count}&sortColumn=${sortColumn}&sortMode=${sortMode}${searchCriteria ? `&searchCriteria=${searchCriteria}` : ''}&contactTypes=${contactTypes}&adviserFamilyId=${adviserFamilyId}&clientContactStatusId=${clientContactStatusId}&clientRecordType=${clientRecordType}&ratings=${ratings}&categories=${categories}`, (response) => {
        return callback(response);
      });
    },
    getAdviserClient(startWith, callback) {
      httpRequest.get(`${apiBaseUrl}FamilyListGet?startWith=${startWith}&count=20`, (response) => {
        return callback(response);
      });
    },

    searchFamily(searchCriteria, adviserId, byPassFilter, callback) {
      if (!byPassFilter)
        byPassFilter = false;

      httpRequest.get(`${apiBaseUrl}FamilyListGet?searchCriteria=${searchCriteria}&adviserFamilyId=${adviserId}&byPassFilter=${byPassFilter}`, (response) => {
        return callback(response);
      });
    },

    searchContacts(searchCriteria, adviserId, callback) {
      httpRequest.get(`${apiBaseUrl}FamilyListGet?searchCriteria=${searchCriteria}&adviserFamilyId=${adviserId}`, (response) => {
        return callback(response);
      });
    },

    familyListGet(searchCriteria, byPassFilter = false, callback) {
      httpRequest.get(`${apiBaseUrl}FamilyListGet?searchCriteria=${searchCriteria || ''}&byPassFilter=${byPassFilter}`, (response) => {
        return callback(response);
      });
    },

    searchFamilyContactType(searchCriteria, contactTypes, adviserFamilyId=0, callback) {
      httpRequest.get(`${apiBaseUrl}FamilyListGet?startWith=*&searchCriteria=${searchCriteria}&adviserFamilyId=${adviserFamilyId}`, (response) => {
        return callback(response);
      });
    },

    contactSummaryPDFGet(familyID, callback) {
      httpRequest.get(`${apiBaseUrl}ContactSummaryPDFGet?familyId=${familyID}`, (response) => {
        return callback(response);
      });
    },
    deleteContact(familyId, callback, errorCallback) {
      httpRequest.delete(`${apiBaseUrl}ContactDelete?familyId=${familyId}`, (response) => {
        return callback(response);
      }, (e) => {
        return errorCallback(e);
      });
    },
    relationshipGet(familyId, entityID, callback) {
      var entityQuery = entityID ? `&entityID=${entityID}` : '';
      httpRequest.get(`${apiBaseUrl}RelationshipGet?familyId=${familyId}${entityQuery}`, (response) => {
        return callback(response);
      });
    },
    contactRelationshipGet(familyId, entityID, callback) {
      httpRequest.get(`${apiBaseUrl}ContactRelationshipGet?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },
    // relationshipSet: function (obj, callback) {
    //     httpRequest.post(apiBaseUrl + "RelationshipSet", obj, function (response) {
    //     return callback(response);
    //   });
    // },
    relationshipSet(obj, callback) {
      httpRequest.post(`${apiBaseUrl}ContactRelationshipSet`, obj, (response) => {
        return callback(response);
      });
    },
    // relationshipDelete: function (familyId, entityId, callback) {
    //   httpRequest.delete(apiBaseUrl + "RelationshipDelete?familyID=" + familyId + "&entityID=" + entityId, function (response) {
    //     return callback(response);
    //   });
    // },

    relationshipDelete(familyId, GroupId, callback) {
      httpRequest.delete(`${apiBaseUrl}ContactRelationshipDelete?familyID=${familyId}&GroupId=${GroupId}`, (response) => {
        return callback(response);
      });
    },
    roleType(callback) {
      httpRequest.get('SelectOptions/RoleType', (response) => {
        return callback(response);
      });
    },
    titleType(callback) {
      httpRequest.get('SelectOptions/Title', (response) => {
        return callback(response);
      });
    },
    addContact($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ContactSet`, $postData, (response) => {
        return callback(response);
      });
    },
    contactClientDelete(familyId, clientId, callback) {
      httpRequest.delete(`${apiBaseUrl}ContactClientDelete?familyID=${familyId}&clientID=${clientId}`, (response) => {
        return callback(response);
      });
    },
    // Loan
    //-----------------------------------------------------------------------------------
    loanList(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}loanList?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },

    loanListGet(familyId, includeOpportunity = false, callback) {
      httpRequest.get(`${apiBaseUrl}LoanListGet?familyID=${familyId}&includeOpportunity=${includeOpportunity}`, (response) => {
        return callback(response);
      });
    },

    loanListGetNewDesign(familyId, statusName, callback) {
      var statusNameQuery = (typeof statusName !== 'undefined' ? `&statusName=${statusName}` : '');
      httpRequest.get(`${apiBaseUrl}LoanListGetNewDesign?familyID=${familyId}${statusNameQuery}`, (response) => {
        return callback(response);
      });
    },

    calculationsSet: (id, postData, callback) => {
      httpRequest.post(`${apiBaseUrl}${id}/Calculations`, postData, response => {
          return callback(response);
      });
    },

    getLoanCalculations(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}${familyId}/loan-calculations`, (response) => {
        return callback(response);
      });
    },

    savedCalculationsGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}SavedCalculationsGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },

    savedCalculationsDelete(type, loanScenarioId, brokerEventId, callback) {
      httpRequest.delete(`${apiBaseUrl}SavedCalculationsDelete?typeOfCalculation=${type}&loanScenarioId=${loanScenarioId}&brokerEventId=${brokerEventId}`, (response) => {
        return callback(response);
      });
    },

    loanDetailsGet(loanId, callback) {
      httpRequest.get(`${apiBaseUrl}LoanDetailsGet?loanID=${loanId}`, (response) => {
        return callback(response);
      });
    },

    loanSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}LoanSet`, $postData, (response) => {
        return callback(response);
      });
    },

    loanDelete(loanId, callback) {
      httpRequest.delete(`${apiBaseUrl}LoanDelete?loanID=${loanId}`, (response) => {
        return callback(response);
      });
    },

    loanStatusListGet(callback) {
      httpRequest.get(`${apiBaseUrl}loanStatusListGet`, (response) => {
        return callback(response);
      });
    },

    loanStructureSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}LoanStructureSet`, $postData, (response) => {
        return callback(response);
      });
    },

    loanStructureDetailsGet(loanId, loanStructureId, callback) {
      httpRequest.get(`${apiBaseUrl}LoanStructureDetailsGet?loanID=${loanId}&loanStructureID=${loanStructureId}`, (response) => {
        return callback(response);
      });
    },


    loanStructureDelete(loanId, loanStructureId, callback) {
      httpRequest.delete(`${apiBaseUrl}LoanStructureDelete?loanID=${loanId}&loanStructureID=${loanStructureId}`, (response) => {
        return callback(response);
      });
    },

    FixedRateHistoryGet(loanStructureId, callback) {
      httpRequest.get(`${apiBaseUrl}FixedRateHistoryGet?loanStructureId=${loanStructureId}`, (response) => {
        return callback(response);
      });
    },

    CurrentFixedRateGet(loanStructureId, callback) {
      httpRequest.get(`${apiBaseUrl}CurrentFixedRateGet?loanStructureId=${loanStructureId}`, (response) => {
        return callback(response);
      });
    },

    FixedRatesSet(obj, callback) {
      httpRequest.post(`${apiBaseUrl}FixedRatesSet`, obj, (response) => {
        return callback(response);
      });
    },

    FixedRatesDelete(loanStructureId, rateId, callback) {
      httpRequest.delete(`${apiBaseUrl}FixedRatesDelete?loanStructureId=${loanStructureId}&rateId=${rateId}`, (response) => {
        return callback(response);
      });
    },

    // Borrower
    //-----------------------------------------------------------------------------------
    borrowersDetailsGet(familyId, loanId, isIncludeGuarantor, callback) {
      httpCacheService.get(`${apiBaseUrl}BorrowersDetailsGet?familyID=${familyId}&loanID=${loanId}&isIncludeGuarantor=${isIncludeGuarantor}`, (response) => {
        return callback(response);
      });
    },
    // Insurance
    //-----------------------------------------------------------------------------------
    insuranceList(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}InsuranceList?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },

    insuranceDetailsGet(benefitId, familyId, callback) {
      httpRequest.get(`${apiBaseUrl}InsuranceDetailsGet?familyID=${familyId}&benefitId=${benefitId}`, (response) => {
        return callback(response);
      });
    },

    insuranceListGet(familyId, providerId, policyNumber, callback) {
      var url = `${apiBaseUrl}InsuranceListGet?familyID=${familyId}`;
      if (providerId)
        url += `&providerId=${providerId}`;
      if (policyNumber)
        url += `&policyNumber=${policyNumber}`;
      httpRequest.get(url, (response) => {
        return callback(response);
      });
    },

    generalInsuranceListGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}GeneralInsuranceListGet?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },

    insuranceListGetNew(familyId, providerId, policyNumber, callback) {
      var url = `${apiBaseUrl}InsuranceListGet?familyID=${familyId}`;
      if (providerId)
        url += `&providerId=${providerId}`;
      if (policyNumber)
        url += `&policyNumber=${policyNumber}`;
      httpRequest.get(url, (response) => {
        return callback(response);
      });
    },

    insurersGet(benefitID, callback) {
      httpRequest.get(`${apiBaseUrl}InsurersGet?benefitId=${benefitID}`, (response) => {
        return callback(response);
      });
    },

    deleteInsurance(familyId, benefitId, callback) {
      httpRequest.get(`${apiBaseUrl}InsuranceDelete?familyId=${familyId}&benefitId=${benefitId}`, (response) => {
        return callback(response);
      });
    },

    productsGet(benefitID, insurerID, callback) {
      httpRequest.get(`${apiBaseUrl}ProductsGet?insurerId=${insurerID}&benefitId=${benefitID}`, (response) => {
        return callback(response);
      });
    },

    loanProductListGet(providerID, callback) {
      httpRequest.get(`${apiBaseUrl}LoanProductListGet?providerID=${providerID}`, (response) => {
        return callback(response);
      });
    },

    insuranceTypesGet(callback) {
      httpRequest.get(`${apiBaseUrl}InsuranceTypesGet`, (response) => {
        return callback(response);
      });
    },

    insuranceStatusesGet(type, callback) {
      var url = `${apiBaseUrl}InsuranceStatusesGet`;
      if (type)
        url += `?type=${type}`;

      httpRequest.get(url, (response) => {
        return callback(response);
      });
    },

    insuranceDetailsSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}InsuranceDetailsSet`, data, (response) => {
        return callback(response);
      });
    },
    // Security Structure
    //-----------------------------------------------------------------------------------
    securityListGet(loanId, callback) {
      httpRequest.get(`${apiBaseUrl}SecurityListGet?loanID=${loanId}`, (response) => {
        return callback(response);
      });
    },

    securitySet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}SecuritySet`, $postData, (response) => {
        return callback(response);
      });
    },

    securityDelete(securityId, callback) {
      httpRequest.delete(`${apiBaseUrl}SecurityDelete?securityID=${securityId}`, (response) => {
        return callback(response);
      });
    },

    securityClientsListGet(familyId, securityId, callback) {
      httpRequest.get(`${apiBaseUrl}SecurityClientsListGet?familyID=${familyId}&securityID=${securityId}`, (response) => {
        return callback(response);
      });
    },

    lenderListGet(isCommercial, lenderPlace, callback) {
      var $url = `${apiBaseUrl}LenderListGet?lenderPlace=${lenderPlace}`;
      if (isCommercial != undefined) {
        $url += `&IsCommercial=${isCommercial}`;
      }
      httpCacheService.get($url, (response) => {
        return callback(response);
      });
    },

    securityDetailsGet(familyId, securityId, callback) {
      httpRequest.get(`${apiBaseUrl}SecurityDetailsGet?familyID=${familyId}&securityID=${securityId}`, (response) => {
        return callback(response);
      });
    },

    loanImportantDatesSet(loanId, type, dateValue, refNo, isEstimated, callback) {
      var query = `LoanImportantDatesSet?loanId=${loanId}&typeOfDateId=${type}`;

      if (refNo) {
        query = `${query}&loanreferenceNumber=${refNo}&date=${dateValue}`;
      } else {
        query = `${query}&date=${dateValue}`;
      }

      if (!_.isUndefined(isEstimated)) {
        query = `${query}&isEstimated=${isEstimated}`;
      }

      httpRequest.post(`${apiBaseUrl}${query}`, {}, (response) => {
        return callback(response);
      });
    },

    loanImportantDatesDelete(loanId, dateTypeId, isEstimated, callback) {
      httpRequest.delete(`${apiBaseUrl}${loanId}/ImportantDates/${dateTypeId}?isEstimated=${isEstimated}`, (response) => {
        return callback(response);
      });
    },

    // Client Type , Rating , Category , Referral
    //-----------------------------------------------------------------------------------
    addClientType(clientTypeName, callback) {
      var data = {
        clientTypeName,
      };
      httpRequest.post(`${apiBaseUrl}ClientTypeAdd`, data, (response) => {
        return callback(response);
      });
    },
    updateClientType(clientTypeID, clientTypeName, callback) {
      var data = {
        clientTypeID,
        clientTypeName,
      };
      httpRequest.post(`${apiBaseUrl}ClientTypeEdit`, data, (response) => {
        return callback(response);
      });
    },
    updateOrder(clientTypeID, orderBy, callback) {
      var data = {
        clientTypeID,
        orderBy,
      };
      httpRequest.post(`${apiBaseUrl}ClientTypeReorder`, data, (response) => {
        return callback(response);
      });
    },
    contactFamilyInfoGet(familyId, callback) {
      httpCacheService.get(`${apiBaseUrl}ContactFamilyInfoGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    contactDetailsSMSGet(clientId, familyId, callback) {
      httpCacheService.get(`${apiBaseUrl}ContactDetailsSMSGet?clientId=${clientId}&familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },

    contactFamilyInfoSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}ContactFamilyInfoSet`, data, (response) => {
        return callback(response);
      });
    },

    familyInfoGet(familyId, clientId, callback) {
      var queryAdded = (typeof clientId !== 'undefined' ? `&clientId=${clientId}` : '');
      httpRequest.get(`${apiBaseUrl}FamilyInfoGet?familyId=${familyId}${queryAdded}`, (response) => {
        return callback(response);
      });
    },
    familyInfoSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}FamilyInfoSet`, data, (response) => {
        return callback(response);
      });
    },

    GeoCodeSearch(address, latitude, longitude, callback) {
      // do not search if the address contains the ff.
      // "PO" "Post" "Box" "Private"
      var excludeStrings = ['po', 'post', 'box', 'private'];
      var isValidAddress = true;
      var addressSplit = address.split(' '); // contains array for words
      _.forEach(excludeStrings, (str) => {
        if (_.find(addressSplit, (findWord) => {
          return _.toLower(findWord) === str;
        })) {
          isValidAddress = false;
        }
      });

      if (!isValidAddress) {
        return false;
      }

      if (address == '') {
        httpRequest.get(`${apiBaseUrl}GeoCodeSearch?lat=${latitude}&lng=${longitude}`, (response) => {
          return callback(response);
        });
      } else {
        httpRequest.get(`${apiBaseUrl}GeoCodeSearch?address=${address}`, (response) => {
          return callback(response);
        });
      }
    },

    getClientType(callback) {
      httpRequest.get(`${apiBaseUrl}ClientTypeListGet`, (response) => {
        return callback(response);
      });
    },
    deleteClientType(clientTypeID, callback) {
      var data = {
        clientTypeID,

      };
      httpRequest.post(`${apiBaseUrl}ClientTypeDelete`, data, (response) => {
        return callback(response);
      });
    },
    addRating(ratingName, callback) {
      var data = {
        ratingName,
      };
      httpRequest.post(`${apiBaseUrl}RatingAdd`, data, (response) => {
        return callback(response);
      });
    },
    updateRating(ratingID, ratingName, callback) {
      var data = {
        ratingID,
        ratingName,
      };
      httpRequest.post(`${apiBaseUrl}RatingEdit`, data, (response) => {
        return callback(response);
      });
    },
    updateRatingOrder(ratingID, orderBy, callback) {
      var data = {
        ratingID,
        orderBy,
      };
      httpRequest.post(`${apiBaseUrl}RatingReorder`, data, (response) => {
        return callback(response);
      });
    },
    getRating(callback) {
      httpRequest.get(`${apiBaseUrl}RatingListGet`, (response) => {
        return callback(response);
      });
    },
    deleteRating(ratingID, callback) {
      var data = {
        ratingID,

      };
      httpRequest.post(`${apiBaseUrl}RatingDelete`, data, (response) => {
        return callback(response);
      });
    },
    addCategory(categoryName, callback) {
      var data = {
        categoryName,
      };
      httpRequest.post(`${apiBaseUrl}CategoryAdd`, data, (response) => {
        return callback(response);
      });
    },
    updateCategory(categoryID, categoryName, callback) {
      var data = {
        categoryID,
        categoryName,
      };
      httpRequest.post(`${apiBaseUrl}CategoryEdit`, data, (response) => {
        return callback(response);
      });
    },
    updateCategoryOrder(categoryID, orderBy, callback) {
      var data = {
        categoryID,
        orderBy,
      };
      httpRequest.post(`${apiBaseUrl}CategoryReorder`, data, (response) => {
        return callback(response);
      });
    },
    getCategory(callback) {
      httpRequest.get(`${apiBaseUrl}CategoryListGet`, (response) => {
        return callback(response);
      });
    },
    categoryListsGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}CategoryListsGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    deleteCategory(categoryID, callback) {
      var data = {
        categoryID,

      };
      httpRequest.post(`${apiBaseUrl}CategoryDelete`, data, (response) => {
        return callback(response);
      });
    },
    getReferralCategory(callback) {
      httpRequest.get(`${apiBaseUrl}ReferralCategoryListGet`, (response) => {
        return callback(response);
      });
    },
    getReferralChildList(referralCategoryID, callback) {
      httpRequest.get(`${apiBaseUrl}ReferralItemListGet?referralCategoryID=${referralCategoryID}`, (response) => {
        return callback(response);
      });
    },


    updateRefCategory(referralCategoryID, referralCategoryName, callback) {
      var data = {
        referralCategoryID,
        referralCategoryName,
      };
      httpRequest.post(`${apiBaseUrl}ReferralCategoryEdit`, data, (response) => {
        return callback(response);
      });
    },

    updateRefCategoryOrder(referralCategoryID, orderBy, callback) {
      var data = {
        referralCategoryID,
        orderBy,
      };
      httpRequest.post(`${apiBaseUrl}ReferralCategoryReorder`, data, (response) => {
        return callback(response);
      });
    },
    updateRefItemOrder(referralCategoryID, referralItemId, orderBy, callback) {
      var data = {
        referralCategoryID,
        referralItemId,
        orderBy,
      };
      httpRequest.post(`${apiBaseUrl}ReferralItemReorder`, data, (response) => {
        return callback(response);
      });
    },
    updateRefCategoryItem(referralItemID, itemName, callback) {
      var data = {
        referralItemID,
        itemName,
      };
      httpRequest.post(`${apiBaseUrl}ReferralItemEdit`, data, (response) => {
        return callback(response);
      });
    },
    deleteReferralCategory(referralCategoryId, callback) {
      var data = {
        referralCategoryId,

      };
      httpRequest.post(`${apiBaseUrl}ReferralCategoryDelete`, data, (response) => {
        return callback(response);
      });
    },
    deleteReferralItem(referralItemId, callback) {
      var data = {
        referralItemId,

      };
      httpRequest.post(`${apiBaseUrl}ReferralItemDelete`, data, (response) => {
        return callback(response);
      });
    },
    addReferralCategory(referralCategoryName, callback) {
      var data = {
        referralCategoryName,
      };
      httpRequest.post(`${apiBaseUrl}ReferralCategoryAdd`, data, (response) => {
        return callback(response);
      });
    },
    addReferralItem(referralCategoryID, itemName, ItemPhone, callback) {
      var data = {
        referralCategoryID,
        itemName,
        ItemPhone,
      };
      httpRequest.post(`${apiBaseUrl}ReferralItemAdd`, data, (response) => {
        return callback(response);
      });
    },

    // financials Tab
    //-----------------------------------------------------------------------------------

    // v2

    getAssetList(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}AssetInfoGet?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },

    getLiabilityList(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}LiabilityInfoGet?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },

    getIncomeList(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeInfoGet?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },

    getExpenseList(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}ExpenseInfoGet?familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },


    // v3
    assetInfoGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}AssetInfoGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    liabilityInfoGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}LiabilityInfoGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    incomeInfoGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeInfoGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    incomeClientGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeClientGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    expenseInfoGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}ExpenseInfoGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },

    assetDetailsSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}AssetDetailsSet`, data, (response) => {
        return callback(response);
      });
    },
    liabilityDetailsSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}LiabilityDetailsSet`, data, (response) => {
        return callback(response);
      });
    },
    incomeDetailsSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}IncomeDetailsSet`, data, (response) => {
        return callback(response);
      });
    },
    expenseDetailsSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}ExpenseDetailsSet`, data, (response) => {
        return callback(response);
      });
    },

    deleteAsset(assetId, familyId, callback) {
      httpRequest.delete(`${apiBaseUrl}AssetDelete?familyId=${familyId}&assetId=${assetId}`, (response) => {
        return callback(response);
      });
    },
    deleteLiability(liabilityId, familyId, callback) {
      httpRequest.delete(`${apiBaseUrl}LiabilityDelete?familyId=${familyId}&liabilityId=${liabilityId}`, (response) => {
        return callback(response);
      });
    },
    deleteIncome(incomeId, familyId, callback) {
      httpRequest.delete(`${apiBaseUrl}IncomeDelete?familyId=${familyId}&incomeId=${incomeId}`, (response) => {
        return callback(response);
      });
    },
    deleteExpense(expenseId, familyId, callback) {
      httpRequest.delete(`${apiBaseUrl}ExpenseDelete?familyId=${familyId}&expenseId=${expenseId}`, (response) => {
        return callback(response);
      });
    },


    /*
          getIncomeList: function (familyId, callback) {
            httpRequest.get(apiBaseUrl + "IncomeListGet?familyID=" + familyId, function (response) {
              return callback(response);
            });
          },
          getExpenseList: function (familyId, callback) {
            httpRequest.get(apiBaseUrl + "ExpenseListGet?familyID=" + familyId, function (response) {
              return callback(response);
            });
          },
          getAssetList: function (familyId, callback) {
            httpRequest.get(apiBaseUrl + "AssetListGet?familyID=" + familyId, function (response) {
              return callback(response);
            });
          },
          getLiabilityList: function (familyId, callback) {
            httpRequest.get(apiBaseUrl + "LiabilityListGet?familyID=" + familyId, function (response) {
              return callback(response);
            });
          },

          */

    getExpenseType(callback) {
      httpRequest.get('SelectOptions/ExpenseType', (response) => {
        return callback(response);
      });
    },
    getLiabilityType(callback) {
      httpRequest.get('SelectOptions/LiabilityType', (response) => {
        return callback(response);
      });
    },
    getAssetType(callback) {
      httpRequest.get('SelectOptions/AssetType', (response) => {
        return callback(response);
      });
    },
    getIncomeType(callback) {
      httpRequest.get('SelectOptions/IncomeType', (response) => {
        return callback(response);
      });
    },
    getLoanIncomeFrequency(callback) {
      httpRequest.get('SelectOptions/LoanIncomeFrequency', (response) => {
        return callback(response);
      });
    },
    getLoanTerm(callback) {
      httpRequest.get('SelectOptions/LoanTerm', (response) => {
        return callback(response);
      });
    },
    getExpenseClientList(familyId, expenseId, callback) {
      httpRequest.get(`${apiBaseUrl}ExpenseClientsListGet?familyID=${familyId}&expenseID=${expenseId}`, (response) => {
        return callback(response);
      });
    },
    addExpense($postdata, callback) {
      httpRequest.post(`${apiBaseUrl}ExpenseSet`, $postdata, (response) => {
        return callback(response);
      });
    },
    getLiabilityClientList(familyId, liabilityId, callback) {
      httpRequest.get(`${apiBaseUrl}LiabilityClientsListGet?familyID=${familyId}&liabilityID=${liabilityId}`, (response) => {
        return callback(response);
      });
    },
    addLiability($postdata, callback) {
      httpRequest.post(`${apiBaseUrl}LiabilitySet`, $postdata, (response) => {
        return callback(response);
      });
    },
    getLiabilityDetails(familyId, liabilityId, callback) {
      httpRequest.get(`${apiBaseUrl}LiabilityDetailsGet?familyId=${familyId}&liabilityId=${liabilityId}`, (response) => {
        return callback(response);
      });
    },
    getExpenseDetails(familyId, expenseId, callback) {
      httpRequest.get(`${apiBaseUrl}ExpenseDetailsGet?familyID=${familyId}&expenseID=${expenseId}`, (response) => {
        return callback(response);
      });
    },
    getIncomeClientList(familyId, incomeId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeClientsListGet?familyID=${familyId}&incomeID=${incomeId}`, (response) => {
        return callback(response);
      });
    },
    getIncomeAssetsList(familyId, incomeId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeAssetsListGet?familyID=${familyId}&incomeID=${incomeId}`, (response) => {
        return callback(response);
      });
    },
    getIncomeDetails(familyId, incomeId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeDetailsGet?familyID=${familyId}&incomeID=${incomeId}`, (response) => {
        return callback(response);
      });
    },
    getIncomeAssetAddress(assetId, callback) {
      httpRequest.get(`${apiBaseUrl}IncomeAssetAddressGet?assetID=${assetId}`, (response) => {
        return callback(response);
      });
    },
    addIncome($postdata, callback) {
      httpRequest.post(`${apiBaseUrl}IncomeSet`, $postdata, (response) => {
        return callback(response);
      });
    },
    getAssetClientList(familyId, assetId, callback) {
      httpRequest.get(`${apiBaseUrl}AssetClientGet?familyID=${familyId}&assetID=${assetId}`, (response) => {
        return callback(response);
      });
    },
    addAsset($postdata, callback) {
      httpRequest.post(`${apiBaseUrl}AssetSet`, $postdata, (response) => {
        return callback(response);
      });
    },
    getAssetDetails(familyId, assetId, callback) {
      httpRequest.get(`${apiBaseUrl}AssetDetailsGet?familyID=${familyId}&assetID=${assetId}`, (response) => {
        return callback(response);
      });
    },

    // Documents Tab
    //-----------------------------------------------------------------------------------

    getDocumentList(familyId, searchName, OrganisationId, forLoanApp, callback) {
      let query = `familyID=${familyId}`;
      if (typeof searchName !== 'undefined' && searchName) {
        query += `&searchName=${searchName}`;
      }
      if (forLoanApp !== null && typeof forLoanApp !== 'undefined') {
        query += `&forLoanApp=${forLoanApp}`;
      }
      httpRequest.get(`${apiBaseUrl}DocumentListGet?${query}&OrganisationId=${OrganisationId}`, (response) => {
        return callback(response);
      });
    },
    getDocument(documentId, familyId, callback) {
      httpRequest.get(`${apiBaseUrl}DocumentGet?documentID=${documentId}&familyID=${familyId}`, (response) => {
        return callback(response);
      });
    },
    removeDocument(familyId, documentId, callback) {
      httpRequest.delete(`${apiBaseUrl}DocumentDelete?familyID=${familyId}&DocumentID=${documentId}`, (response) => {
        return callback(response);
      });
    },

    documentThumbnailGet(documentObj, callback) {
      if (documentObj.documentID === '') {
        return false;
      }
      httpRequest.get(`${apiBaseUrl}DocumentThumbNailGet?documentID=${documentObj.documentID}`, (response) => {
        return callback(response);
      });
    },
    documentPropertySet(documentObj, callback, errorCallback) {
      httpRequest.post(`${apiBaseUrl}DocumentPropertySet`, documentObj, (response) => {
        return callback(response);
      }, () => {
        return errorCallback();
      });
    },

    documentImageDataURIGet(documentId, thumbWidth, callback) {
      httpRequest.get(`${apiBaseUrl}DocumentImageDataURI?documentID=${documentId}&thumbWidth=${thumbWidth}`, (response) => {
        return callback(response);
      });
    },
    // Recent Activity
    //-----------------------------------------------------------------------------------
    geRecentActivityList(familyId, count, callback) {
      var parameters = _.isUndefined(count) ? `?familyID=${familyId}` : `?familyID=${familyId}&count=${count}`;
      httpRequest.get(`${apiBaseUrl}RecentActivityList${parameters}`, (response) => {
        return callback(response);
      });
    },

    // Emails Tab
    //-----------------------------------------------------------------------------------

    getEmailList(familyId, pageNumber = null, pageSize = null, includeGoogleMail = false, isIncludeMyCRMEmail = true, isIncludeSMS = true, searchCriteria = '', callback) {
      httpRequest.get(`${apiBaseUrl}EmailListGet?familyID=${familyId}&includeGoogleMail=${includeGoogleMail}&isIncludeMyCRMEmail=${isIncludeMyCRMEmail}&isIncludeSMS=${isIncludeSMS}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchCriteria}`, (response) => {
        return callback(response);
      });
    },
    getEmail(familyId, emailId, callback) {
      httpRequest.get(`${apiBaseUrl}EmailGet?familyID=${familyId}&emailID=${emailId}`, (response) => {
        return callback(response);
      });
    },
    removeEmail(familyId, emailId, callback) {
      httpRequest.delete(`${apiBaseUrl}EmailDelete?familyID=${familyId}&EmailID=${emailId}`, (response) => {
        return callback(response);
      });
    },
    sendEmail($postData, callback, errorCallback) {
      httpRequest.post(`${apiBaseUrl}EmailSend`, $postData, (response) => {
        return callback(response);
      }, (e) => {
        return errorCallback(e);
      });
    },

    getRecipientAutoSuggestion(userInput, callback) {
      httpRequest.get(`${apiBaseUrl}RecipientAutoSuggestionGet?userInput=${userInput}`, (response) => {
        return callback(response);
      });
    },
    getEmailTemplateCategory(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}EmailTemplateCategory?FamilyID=${familyId}`, (response) => {
        return callback(response);
      });
    },
    getEmailTemplateContent(userTemplateId, familyId, callback) {
      httpRequest.get(`${apiBaseUrl}EmailTemplateContent?UsertemplateID=${userTemplateId}&FamilyID=${familyId}`, (response) => {
        return callback(response);
      });
    },
    convertContentHtmlToRTF(postData, callback) {
      httpRequest.post(`${apiBaseUrl}templates/converter/rtf`, postData, (response) => {
        return callback(response);
      });
    },
    // Address Tab
    //-----------------------------------------------------------------------------------
    contactAddressGet(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}ContactAddressGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    contactPropertyDelete(addressValuationExtendedDetailsId, familyId, callback) {
      httpRequest.delete(`${apiBaseUrl}ContactPropertyDelete?addressValuationExtendedDetailsId=${addressValuationExtendedDetailsId}&familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    contactAddressDetailsGet(addressValuationExtendedDetailsId, familyId, callback) {
      httpRequest.get(`${apiBaseUrl}ContactAddressDetailsGet?addressValuationExtendedDetailsId=${addressValuationExtendedDetailsId}&familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    contactAddressSet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ContactAddressSet`, $postData, (response) => {
        return callback(response);
      });
    },
    contactAddressDelete(addressValuationExtendedDetailsId, familyId, callback) {
      var optQuery = (familyId ? `&familyId=${familyId}` : '');
      httpRequest.delete(`${apiBaseUrl}ContactAddressDelete?addressValuationExtendedDetailsId=${addressValuationExtendedDetailsId}${optQuery}`, (response) => {
        return callback(response);
      });
    },
    contactPropertySet($postData, callback) {
      httpRequest.post(`${apiBaseUrl}ContactPropertySet`, $postData, (response) => {
        return callback(response);
      });
    },
    // Employment/ID
    clientEntityIdentificationGet(familyId, identificationId, callback) {
      var identificationQuery = (typeof identificationId !== 'undefined' ? `&identificationId=${identificationId}` : '');
      httpCacheService.get(`${apiBaseUrl}ClientEntityIdentificationGet?familyId=${familyId
      }${identificationQuery}`, (response) => {
        return callback(response);
      });
    },

    /* , clientEntityIdentificationSet: function (identificationDocId,clientId,entityId, nameOnDoc, documentName, documentTypeID, documentNumber, issueDate, expiryDate, placeOfissue, countryCode, callback) {
          var identificationDocIdQuery  = (typeof identificationDocId !== 'undefined' ? "&identificationDocId=" + identificationDocId : '');
          var clientIdQuery             = (typeof clientId !== 'undefined' ? "&clientId=" + clientId : '');
          var entityIdQuery             = (typeof entityId !== 'undefined' ? "&entityId=" + entityId : '');
          var nameOnDocQuery            = (typeof nameOnDoc !== 'undefined' ? "&nameOnDoc=" + nameOnDoc : '');
          var documentNameQuery         = (typeof documentName !== 'undefined' ? "&documentName=" + documentName : '');
          var documentTypeIDQuery       = (typeof documentTypeID !== 'undefined' ? "&documentTypeID=" + documentTypeID : '');
          var documentNumberQuery       = (typeof documentNumber !== 'undefined' ? "&documentNumber=" + documentNumber : '');
          var issueDateQuery            = (typeof issueDate !== 'undefined' ? "&issueDate=" + issueDate : '');
          var expiryDateQuery           = (typeof expiryDate !== 'undefined' ? "&expiryDate=" + expiryDate : '');
          var placeOfissueQuery         = (typeof placeOfissue !== 'undefined' ? "&placeOfissue=" + placeOfissue : '');
          var countryCodeQuery          = (typeof countryCode !== 'undefined' ? "&countryCode=" + countryCode : '');

          httpRequest.post(apiBaseUrl + 'ClientEntityIdentificationSet?' + identificationDocIdQuery + clientIdQuery + entityIdQuery + nameOnDocQuery + documentNameQuery + documentTypeIDQuery + documentNumberQuery + issueDateQuery + expiryDateQuery + placeOfissueQuery + countryCodeQuery,{}, function (response) {
              return callback(response);
          });
      } */
    clientEntityIdentificationSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}ClientEntityIdentificationSet`, data, (response) => {
        return callback(response);
      });
    },

    clientEntityIdentificationDelete(identificationId, callback) {
      httpRequest.post(`${apiBaseUrl}ClientEntityIdentificationDelete?identificationId=${parseInt(identificationId)}`, {}, (response) => {
        return callback(response);
      });
    },

    clientEntityEmploymentGet(familyId, employmentId, callback) {
      var employmentQuery = (typeof employmentId !== 'undefined' ? `&employmentId=${parseInt(employmentId)}` : '');
      httpRequest.get(`${apiBaseUrl}ClientEntityEmploymentGet?familyId=${familyId
      }${employmentQuery}`, (response) => {
        return callback(response);
      });
    },

    clientEntityEmploymentSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}clientEntityEmploymentSet`, data, (response) => {
        return callback(response);
      });
    },

    clientEntityEmploymentDelete(employmentId, callback) {
      httpRequest.post(`${apiBaseUrl}ClientEntityEmploymentDelete?employmentId=${employmentId}`, {}, (response) => {
        return callback(response);
      });
    },
    // Corporate
    getContactType(callback) {
      httpRequest.get('/corporate/ContactTypesGet', (response) => {
        return callback(response);
      });
    },
    getEntityType(callback) {
      httpRequest.get('/corporate/AdviserOrganizationEntityTypesGet', (response) => {
        return callback(response);
      });
    },
    getAdminUser(callback) {
      httpRequest.get('/corporate/AdminUsersGet', (response) => {
        return callback(response);
      });
    },
    getOperatingCountry(callback) {
      httpRequest.get('/corporate/OperatingCountriesGet', (response) => {
        return callback(response);
      });
    },
    getOrganizationStatus(callback) {
      httpRequest.get('/corporate/AdviserOrganizationStatusesGet', (response) => {
        return callback(response);
      });
    },
    getAdviserUnderOrganization(id, callback) {
      httpRequest.get(`/corporate/AdvisersUnderOrganizationGet?adviserOrganizationId=${id}`, (response) => {
        return callback(response);
      });
    },
    setAdviserOrganization(saveType, data, callback) {
      httpRequest.post(`/corporate/AdviserOrganizationSet?saveType=${saveType}`, data, (response) => {
        return callback(response);
      });
    },
    setFamilyAddress(id, clientId, address, isMailing, isBusiness, callback) {
      httpRequest.post(`/corporate/FamilyAddressSet?familyId=${id}&clientId=${clientId}&address=${address}&isMailing=${isMailing}&isBusiness=${isBusiness}`, { header: 'Accept: application/json' }, (response) => {
        return callback(response);
      });
    },
    getAdviserOrganizationList(callback) {
      httpRequest.get('/corporate/AdviserOrganizationListGet', (response) => {
        return callback(response);
      });
    },
    getBrokerStatusList(callback) {
      httpRequest.get('/corporate/BrokerStatusListGet', (response) => {
        return callback(response);
      });
    },
    getContactTypeRoles(callback) {
      httpRequest.get('/corporate/ContactTypeRolesGet', (response) => {
        return callback(response);
      });
    },
    getReportingStates(callback) {
      httpRequest.get('/corporate/ReportingStatesGet', (response) => {
        return callback(response);
      });
    },
    getReportingRegion(countryId, callback) {
      httpRequest.get(`/corporate/ReportingRegionsGet?countryId=${countryId}`, (response) => {
        return callback(response);
      });
    },
    getCommissionTiers(countryId, callback) {
      httpRequest.get(`/corporate/CommissionTiersGet?countryId=${countryId}`, (response) => {
        return callback(response);
      });
    },
    getAdviserAchievements(callback) {
      httpRequest.get('/corporate/AdviserAchievementsGet', (response) => {
        return callback(response);
      });
    },
    oneOffCommissionRateGet: (lenderId, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}/Loans/OneOffCommissionRate?lenderId=${lenderId}`, (response) => {
        callback(response);
      }, (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    getEnquirySources(callback) {
      httpRequest.get('/corporate/EnquirySourcesGet', (response) => {
        return callback(response);
      });
    },
    getGeneralInterest(callback) {
      httpRequest.get('/corporate/GeneralInterestGet', (response) => {
        return callback(response);
      });
    },
    getSportsTeam(callback) {
      httpRequest.get('/corporate/SportsTeamGet', (response) => {
        return callback(response);
      });
    },
    getEmploymentType(callback) {
      httpRequest.get('/corporate/EmploymentTypeGet', (response) => {
        return callback(response);
      });
    },
    setAdviser(savetype, data, callback) {
      httpRequest.post(`/corporate/ContactSet?saveType=${savetype}`, data, (response) => {
        return callback(response);
      });
    },
    getAdviserOrgAddress(orgId, callback) {
      httpRequest.get(`/corporate/OrganizationAddressGet?organizationId=${orgId}`, (response) => {
        return callback(response);
      });
    },
    getUserInfo(callback) {
      httpCacheService.get('GetUserInfo', (response) => {
        return callback(response);
      });
    },
    setOrganizationAddress(organizationId, orgComplianceId, addressId, isMailing, callback) {
      httpRequest.post(`/corporate/OrganizationAddressSet?organizationId=${organizationId}&orgComplianceId=${orgComplianceId}&isMailing=${isMailing}&addressId=${addressId}`, { }, (response) => {
        return callback(response);
      });
    },
    getOrganizationType(callback) {
      httpRequest.get('/corporate/OrganizationTypesGet', (response) => {
        return callback(response);
      });
    },
    setReferrerOrg(data, callback) {
      httpRequest.post('/corporate/ReferrerOrgSet', data, (response) => {
        return callback(response);
      });
    },
    getIfOnboardingStatus(familyId, clientId, callback) {
      httpRequest.get(`/corporate/CheckBrokerStatusIfOnboardingGet?familyId=${familyId}&clientId=${clientId}`, (response) => {
        return callback(response);
      });
    },
    getAdviserContactSearchOptions(familyId, callback) {
      httpRequest.get(`/corporate/AdviserContactSearchOptionsGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    getSkipWelcomeScreen(familyId, callback) {
      httpRequest.get(`/corporate/SkipWelcomeScreenGet?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    setSkipWelcomeScreen(familyId, skipWelcomeScreen, callback) {
      httpRequest.post(`/corporate/SkipWelcomeScreenSet?familyId=${familyId}&skipWelcomeScreen=${skipWelcomeScreen}`, { header: 'Accept: application/json' }, (response) => {
        return callback(response);
      });
    },

    // Loan Application (Previously Loan Scenario)
    loanApplicationCompletionStatusGet(loanApplicationId, callback) {
      httpRequest.get(`${apiBaseUrl}LoanApplicationCompletionStatusGet?loanApplicationId=${loanApplicationId}`, (response) => {
        return callback(response);
      });
    },

    loanApplicationCompletionStatusSet(loanApplicationId, sectionID, callback) {
      httpRequest.post(`${apiBaseUrl}LoanApplicationCompletionStatusSet?loanApplicationId=${loanApplicationId}&sectionID=${sectionID}`, { header: 'Accept: application/json' }, (response) => {
        return callback(response);
      });
    },

    getLoanApplicationCQP(loanApplicationId, callback) {
      httpRequest.get(`${apiBaseUrl}LoanApplicationCQPGet?loanApplicationId=${loanApplicationId}`, (response) => {
        return callback(response);
      });
    },

    setLoanApplicationCQP(loanApplicationId, lenderId, callback) {
      httpRequest.post(`${apiBaseUrl}LoanApplicationCQPSet?loanApplicationId=${loanApplicationId}&lenderId=${lenderId}`, { header: 'Accept: application/json' }, (response) => {
        return callback(response);
      });
    },

    getLoanApplicationCQPFeeStructure(loanApplicationId, callback) {
      httpRequest.get(`${apiBaseUrl}LoanApplicationCQPFeeStructureGet?loanApplicationId=${loanApplicationId}`, (response) => {
        return callback(response);
      });
    },

    deleteLoanApplicationCQPFeeStructure(loanApplicationId, LoanApplicationCQPFeeId, callback) {
      httpRequest.delete(`${apiBaseUrl}LoanApplicationCQPFeeStructureDelete?loanApplicationId=${loanApplicationId}&LoanApplicationCQPFeeId=${LoanApplicationCQPFeeId}`, (response) => {
        return callback(response);
      });
    },

    setLoanApplicationCQPFeeStructure(structure, callback) {
      httpRequest.post(`${apiBaseUrl}LoanApplicationCQPFeeStructureSet`, structure, (response) => {
        return callback(response);
      });
    },

    getLoanApplicationCQPReferralFeeStructure(loanApplicationId, callback) {
      httpRequest.get(`${apiBaseUrl}LoanApplicationCQPReferralFeeStructureGet?loanApplicationId=${loanApplicationId}`, (response) => {
        return callback(response);
      });
    },

    deleteLoanApplicationCQPReferralFeeStructure(loanApplicationId, LoanApplicationCQPReferralFeeId, callback) {
      httpRequest.delete(`${apiBaseUrl}LoanApplicationCQPReferralFeeStructureDelete?loanApplicationId=${loanApplicationId}&LoanApplicationCQPReferralFeeId=${LoanApplicationCQPReferralFeeId}`, (response) => {
        return callback(response);
      });
    },

    setLoanApplicationCQPReferralFeeStructure(structure, callback) {
      httpRequest.post(`${apiBaseUrl}LoanApplicationCQPReferralFeeStructureSet`, structure, (response) => {
        return callback(response);
      });
    },

    getCQPDocumentGet(loanApplicationId, callback) {
      httpRequest.get(`${apiBaseUrl}CQPDocumentGet?loanApplicationId=${loanApplicationId}`, (response) => {
        return callback(response);
      });
    },
    setClientTypeCorpUser(clientTypeId, isExclusiveForCorpUser, callback) {
      httpRequest.post(`/corporate/ClientTypeCorpUserSet?clientTypeId=${clientTypeId}&isExclusiveForCorpUser=${isExclusiveForCorpUser}`, {}, (response) => {
        return callback(response);
      });
    },
    setContactCategoryCorpUser(categoryId, isExclusiveForCorpUser, callback) {
      httpRequest.post(`/corporate/ContactCategoryCorpUserSet?categoryId=${categoryId}&isExclusiveForCorpUser=${isExclusiveForCorpUser}`, {}, (response) => {
        return callback(response);
      });
    },
    setReferralCategoryCorpUser(referralCategoryId, isExclusiveForCorpUser, callback) {
      httpRequest.post(`/corporate/ReferralCategoryCorpUserSet?referralCategoryId=${referralCategoryId}&isExclusiveForCorpUser=${isExclusiveForCorpUser}`, {}, (response) => {
        return callback(response);
      });
    },
    setReferralItemCorpUser(referralItemId, isExclusiveForCorpUser, callback) {
      httpRequest.post(`/corporate/ReferralItemCorpUserSet?referralItemId=${referralItemId}&isExclusiveForCorpUser=${isExclusiveForCorpUser}`, {}, (response) => {
        return callback(response);
      });
    },
    getPropertyRelatedClient(familyId, addressValuationExtendedDetailsId, callback) {
      if (typeof addressValuationExtendedDetailsId !== 'undefined')
        var addressIdQuery = `&addressValuationExtendedDetailsId=${addressValuationExtendedDetailsId}`;
      else
        var addressIdQuery = '';
      httpRequest.get(`${apiBaseUrl}PropertyRelatedClientGet?familyId=${familyId}${addressIdQuery}`, (response) => {
        return callback(response);
      });
    },
    putAssignAdviser(familyId, assignedAdviserId, callback) {
      httpRequest.put(`${apiBaseUrl}ContactAssignAdviser?familyId=${familyId}&assignedAdviserId=${assignedAdviserId}`, {}, (response) => {
        return callback(response);
      });
    },
    getContactAssignedAdviser(familyId, callback) {
      httpRequest.get(`${apiBaseUrl}ContactAssignedAdviser?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    sendSms(data, callback) {
      httpRequest.post(`${apiBaseUrl}SendSms`, data, (response) => {
        return callback(response);
      });
    },
    LoanDetailsSet(data, callback) {
      httpRequest.post(`${apiBaseUrl}LoanDetailsSet`, data, (response) => {
        return callback(response);
      });
    },
    EnquiryCategoryReorder(familyId, referralCategoryID, orderBy, callback) {
      var data = `familyId=${familyId}&referralCategoryID=${referralCategoryID}&orderBy=${orderBy}`;

      httpRequest.post(`${apiBaseUrl}EnquiryCategoryReorder?${data}`, {}, (response) => {
        return callback(response);
      });
    },
    RecentActivityFeedListGet(familyId, brokerBrandingId, callback) {
      httpRequest.get(`${apiBaseUrl}RecentActivityFeedListGet?familyId=${familyId}&brokerBrandingId=${brokerBrandingId}`, (response) => {
        return callback(response);
      });
    },
    RecentActivityListFullDetailsGet(familyId, brokerBrandingId = 0, adviserId = 0, notificationTypeId = 0, callback) {
      httpRequest.get(`${apiBaseUrl}RecentActivityListFullDetailsGet?familyId=${familyId}&brokerBrandingId=${brokerBrandingId}&adviserId=${adviserId}&notificationTypeId=${notificationTypeId}`, (response) => {
        return callback(response);
      });
    },

    AdviserOrgCommentGet(adviserOrgId, callback) {
      httpRequest.get(`${apiBaseUrl}AdviserOrgCommentGet?adviserOrgId=${adviserOrgId}`, (response) => {
        return callback(response);
      });
    },
    AdviserOrgCommentSet(adviserOrgId, comment, callback) {
      httpRequest.post(`${apiBaseUrl}AdviserOrgCommentSet?adviserOrgId=${adviserOrgId}&comment=${comment}`, {}, (response) => {
        return callback(response);
      });
    },
    LoanStatusSet(loanId, statusId, shouldSendNurtureCampaigns=false, reasonSubstatusID='', callback) {
      httpRequest.post(`${apiBaseUrl}LoanStatusSet?loanId=${loanId}&statusId=${statusId}&shouldSendNurtureCampaigns=${shouldSendNurtureCampaigns}&reasonSubstatusID=${reasonSubstatusID}`, {}, (response) => {
        return callback(response);
      });
    },

    clientEntityPrimarySet(clientId, entityId, callback) {
      httpRequest.post(`${apiBaseUrl}ClientEntityPrimarySet?clientId=${clientId}&entityId=${entityId}`, {}, (response) => {
        return callback(response);
      });
    },

    /*
      * @desc Commissions
      */
    loansExpectedCommissionsGet: (loanId, expectedCommissionId, callback, callbackOnError) => {
      loanId = loanId || 0;
      expectedCommissionId = expectedCommissionId || 0;
      httpRequest.get(`${apiBaseUrl}LoansExpectedCommissions?loanId=${loanId}&expectedCommissionId=${expectedCommissionId}`, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          return callbackOnError(error);
        }
      });
    },

    loansOneOffCommissionsGet: (loanId, callback, callbackOnError) => {
      loanId = loanId || 0;
      httpRequest.get(`${apiBaseUrl}loans/${loanId}/oneoffcommissions`, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          return callbackOnError(error);
        }
      });
    },

    loansExpectedCommissionsSet: (commission, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}LoansExpectedCommissions`, commission, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          return callbackOnError(error);
        }
      });
    },

    loansExpectedCommissionsDelete: (commissionId, callback, callbackOnError) => {
      httpRequest.delete(`${apiBaseUrl}LoansExpectedCommissions?expectationId=${commissionId}`, (response) => {
        return callback(response);
      }, (error) => {
        return callbackOnError(error);
      });
    },

    loansCommissionRatingsGet: (loanId, callback, callbackOnError) => {
      loanId = loanId || 0;
      httpRequest.get(`${apiBaseUrl}Loans/${loanId}/CommissionRatings`, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          return callbackOnError(error);
        }
      });
    },

    loanCommissionLoanStructuresGet: (loanId, callback, callbackOnError) => {
      loanId = loanId || 0;
      httpRequest.get(`${apiBaseUrl}Loans/${loanId}/CommissionLoanStructures`, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          return callbackOnError(error);
        }
      });
    },

    loansExpectedCommissionsRecurringGet: (loanId, recurringId, callback, callbackOnError) => {
      loanId = loanId || 0;
      recurringId = recurringId || 0;
      httpRequest.get(`${apiBaseUrl}LoansExpectedCommissionsRecurring?loanId=${loanId}&recurringId=${recurringId}`, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          return callbackOnError(error);
        }
      });
    },

    loansExpectedCommissionsRecurringSet: (recurringCommission, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}LoansExpectedCommissionsRecurring`, recurringCommission, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          return callbackOnError(error);
        }
      });
    },

    loansExpectedCommissionsRecurringDelete: (loanId, recurringId, callback, callbackOnError) => {
      httpRequest.delete(`${apiBaseUrl}LoansExpectedCommissionsRecurring?loanId=${loanId}&recurringId=${recurringId}`, (response) => {
        return callback(response);
      }, (error) => {
        return callbackOnError(error);
      });
    },

    expectedCommissionSummaryTotalGet: (callback) => {
      httpRequest.get(`${apiBaseUrl}ExpectedCommissionSummaryTotal`, (response) => {
        return callback(response);
      });
    },

    loansExpectedCommissionsQuerySet: (query, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}LoanExpectedCommissionsQuery`, query, (response) => {
        return callback(response);
      }, (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    loanUnmatchedComissionSummary: (adviserId, typeId, providerId, pageNumber, pageSize, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}LoanUnmatchedComissionSummary?adviserId=${adviserId}&typeId=${typeId}&providerId=${providerId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, (response) => {
        callback(response);
      }, (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    mergeClients: (mergeToFamilyId, mergeFromFamilyId, callback, callbackError) => {
      httpRequest.post(`${apiBaseUrl}MergeClientProfile?mergeToFamilyId=${mergeToFamilyId}&mergeFromFamilyId=${mergeFromFamilyId}&primaryFamilyID=${mergeToFamilyId}`, {}, (response) => {
        callback(response);
      }, (error) => {
        if (callbackError) {
          callbackError(error);
        }
      });
    },
    mergeClientsWithSecondary: (data, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}MergeClientProfileWithSecondary?mergeToFamilyId=${data.mergeToFamilyId}&mergeFromFamilyId=${data.mergeFromFamilyId}&primaryFamilyID=${data.primaryFamilyID}&mergedToClientID1=${data.mergedToClientID1}&victimClientID1=${data.victimClientID1}&mergedToClientID2=${data.mergedToClientID2}&victimClientID2=${data.victimClientID2}`, {}, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    studentLoanRepaymentEstimate: (data, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}StudentLoanRepaymentEstimate?familyId=${data.familyId}&clientId=${data.clientId}&frequencyId=${data.frequencyId}`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    convertHTMLtoRTF: (htmlString, callback, callbackError) => {
      httpRequest.post(`${apiBaseUrl}Converter/HTMLtoRTF`, { htmlString }, (response) => {
        callback(response);
      }, (error) => {
        if (callbackError) {
          callbackError(error);
        }
      });
    },

    mailTemplatesGet: (userTemplateID, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}DocumentRTFTemplate?userTemplateID=${userTemplateID}`, (response) => {
        callback(response);
      }, (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    mailTemplatesSet: (template, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}DocumentRTFTemplate`, template, (response) => {
        callback(response);
      }, (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    mailTemplatesDelete: (userTemplateID, callback, callbackOnError) => {
      httpRequest.delete(`${apiBaseUrl}DocumentRTFTemplate?userTemplateID=${userTemplateID}`, (response) => {
        callback(response);
      }, (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    mailTemplateFoldersSet: (templateFolder, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}DocumentRTFTemplateFolder?folderId=${templateFolder.UserTemplateID}&folderName=${templateFolder.UserTemplateName}`, {}, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    mailTemplateFoldersDelete: (templateFolderId, callback, callbackOnError) => {
      httpRequest.delete(`${apiBaseUrl}DocumentRTFTemplateFolder?folderId=${templateFolderId}`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    mailTemplateSortingSet: (data, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}DocumentRTFTemplateSorting`, data, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    loanStructureChangeLog: (loanId, loanStructureID, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}LoanStructureChangeLog?loanId=${loanId}&loanStructureID=${loanStructureID}`, (response) => {
        callback(response);
      }, (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    unmatchedCommissionsLoanStructuresGet: (args, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}LoanMatchedCommissionLoanStructures?familyId=${args.familyId}&providerId=${args.providerId}`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    matchCommission: (args, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}LoanMatchedCommission?expectationID=${args.expectationID}&loanID=${args.loanID}&loanStructureID=${args.loanStructureID}&commissionSpreadSheetID=${args.commissionSpreadSheetID}`, {}, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    commissionAccess: (familyId, callback) => {
      httpRequest.get(`${apiBaseUrl}CommissionAccess?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },
    commissionAccessSet: (familyId, brokerFamilyId, isActive, callback, callbackError) => {
      httpRequest.post(`${apiBaseUrl}CommissionAccess?familyId=${familyId}&brokerFamilyId=${brokerFamilyId}&isActive=${isActive}`, {}, (response) => {
        callback(response);
      }, (error) => {
        if (callbackError) {
          callbackError(error);
        }
      });
    },
    brokerAccessDelete: (familyId, brokerFamilyId, callback, callbackOnError) => {
      httpRequest.delete(`${apiBaseUrl}BrokerAccess?familyId=${familyId}&brokerFamilyId=${brokerFamilyId}`, (response) => {
        return callback(response);
      }, (error) => {
        return callbackOnError(error);
      });
    },
    getGoogleEmail: (familyId, callback) => {
      httpRequest.get(`google/Gmail?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },

    authGoogle: (code, callback) => {
      httpRequest.get(`google/GmailAuth?code=${code}`, (response) => {
        return callback(response);
      });
    },
    smsTemplatesSet: (template, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}SMSTemplate`, template, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    smsTemplatesGet: (userTemplateID, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}SMSTemplate?userTemplateID=${userTemplateID}`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    smsTemplatesDelete: (userTemplateID, callback, callbackOnError) => {
      httpRequest.delete(`${apiBaseUrl}SMSTemplate?userTemplateID=${userTemplateID}`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    smsTemplateFolderSet: (templateFolder, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}SMSTemplateFolder?folderId=${templateFolder.UserTemplateID}&folderName=${templateFolder.UserTemplateName}`, {}, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    smsTemplateFolderDelete: (folderId, callback, callbackOnError) => {
      httpRequest.delete(`${apiBaseUrl}SMSTemplateFolder?folderId=${folderId}`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    smsTemplateSortingSet: (templateSortings, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}SMSTemplateSorting`, templateSortings, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    mailTemplateSet: (data, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}MailTemplate?familyId=${data.familyId}&title=${data.title}&templateName=${data.templateName}&workbenchLoanId=${data.workbenchLoanId}&htmlContent=${data.htmlContent}&communicationID=0`, {}, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    subscriptionInfo: (familyId, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}${familyId}/SubscriptionInfo`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    subscriptionPricing: (familyId, subscriptionTypeId, callback, callbackOnError) => {
      httpRequest.get(`${apiBaseUrl}${familyId}/SubscriptionPricing?SubscriptionProductId=${subscriptionTypeId}`, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    subscription: (data, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}Subscriptions`, data, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    subscriptionCancel: (data, familyId, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}${familyId}/SubscriptionCancel`, data, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    getGoogleDriveRoot: (callback) => {
      httpRequest.get(`google/Drive?isFoldersOnly=true`, (response) => {
        return callback(response);
      });
    },

    getGoogleDriveFolderId: (isFoldersOnly, folderId, callback) => {
      httpRequest.get(`google/Drive?isFoldersOnly=${isFoldersOnly}&folderId=${folderId}`, (response) => {
        return callback(response);
      });
    },

    authGdrive: (code, callback) => {
      httpRequest.get(`google/DriveAuth?code=${code}`, (response) => {
        return callback(response);
      });
    },

    getDriveUserMapping: (familyId, callback) => {
      httpRequest.get(`google/DriveUserMapping?familyId=${familyId}`, (response) => {
        return callback(response);
      });
    },

    postDriveUserMapping: (postParams, callback, callbackOnError) => {
      httpRequest.post(`google/DriveUserMapping`, postParams, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    subscriptionAdviserDetailsSet: (postParams, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}SubscriptionAdviserDetailsSet`, postParams, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    getGmailSync: (familyId, callback) => {
      httpRequest.get(`google/GMAILSync?familyId=${familyId}`, response => callback(response));
    },

    postGmailSync: (postParams, callback, callbackOnError) => {
      httpRequest.post(`google/GMAILSync`, postParams, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },

    getGmailSyncStatus: (familyId, callback) => {
      httpRequest.get(`google/GMAILSyncStatus?familyId=${familyId}`, response => callback(response));
    },

    getEmailSignatureImage: (familyId, type, callback) => {
      httpRequest.get(`${apiBaseUrl}${familyId}/email-signature-image?type=${type}`, response => callback(response));
    },

    deleteSignatureImage: (familyId, documentId, callback) => {
      httpRequest.delete(`${apiBaseUrl}${familyId}/email-signature-image/${documentId}`, response => callback(response));
    },

    getEmailSignatureSettings: (familyId, callback) => {
      httpRequest.get(`${apiBaseUrl}${familyId}/email-signature-settings`, response => callback(response));
    },

    getEmailSignaturePreviewDetails: (familyId, callback) => {
      httpRequest.get(`${apiBaseUrl}${familyId}/email-signature-preview`, response => callback(response));
    },

    postEmailSignatureSettings: (data, familyId, callback, callbackOnError) => {
      httpRequest.post(`${apiBaseUrl}${familyId}/email-signature-settings`, data, response => callback(response), (error) => {
        if (callbackOnError) {
          callbackOnError(error);
        }
      });
    },
    
    getFamiliesPerLoan: (loanId, callback) => {
      httpRequest.get(`${apiBaseUrl}loan/${loanId}/involved-families`, response => callback(response));
    },

  };
  return ContactModel;
}]);
