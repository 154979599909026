/*
* Please use any of these valid types
* Sample code on using the crmConfirmation
*
* crmConfirmation.open({
      type: 'success',
      title: 'Reminder Set',
      description: `We will remind you to take the survey again in the next 24 hours`,
      buttonText: 'Okay, got it!',
    });
* the type of the crmConfirmation corresponds to the swal type. You can use, warning, success, etc.
*
*/
const validTypes = ['info', 'success', 'warning'];

export default class CrmConfirmation {
  constructor(
    $uibModal
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  open(settings) {
    const { type, title, description, buttonText, showCancelButton, showCloseButton, renderAsComponent, commonObject, modalSize } = settings;
    if (validTypes.indexOf(type) === -1) throw new Error('Invalid Type');

    const props = {
      type,
      title,
      description,
      buttonText: buttonText || 'Okay, got it!',
      showCancelButton,
      modalSize: modalSize || 'sm',
      showCloseButton,
      renderAsComponent,
      commonObject,
    };

    return this.$uibModal.open({
      template: `<crm-confirmation
                      c-title='vm.props.title'
                      c-description='vm.props.description'
                      c-button-text='vm.props.buttonText'
                      c-modal-instance='vm.modalInstance'
                      c-show-cancel-button='vm.props.showCancelButton'
                      c-type='vm.props.type'
                      c-modal-size='vm.props.modalSize'
                      c-show-close-button="vm.props.showCloseButton"
                      c-render-as-component="vm.props.renderAsComponent"
                      c-common-object="vm.props.commonObject">
                </crm-confirmation>`,
      size: 'lg',
      windowClass: 'crm-confirmation-window',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        props: () => props,
      },
    });
  }
}
