import angular from 'angular';
import addTrust from './addTrust';
import partyType from './partyType';
import entityDetails from './entityDetails';
import entityContactDetails from './entityContactDetails';
import advanceDetails from './advanceDetails';
import beneficiaryField from './beneficiaryField';

export default angular.module('components.addTrust', [])
  .component('addTrust', addTrust)
  .component('partyType', partyType)
  .component('entityDetails', entityDetails)
  .component('entityContactDetails', entityContactDetails)
  .component('advanceDetails', advanceDetails)
  .component('beneficiaryField', beneficiaryField)
  .name;
