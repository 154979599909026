import {
  DATE_PATTERN,
  ALT_DATE_INPUT_FORMATS,
  DD_MMM_YYYY_FORMAT,
} from 'Common/constants/dateConfigs';

export function isDateValid(date) {
  const currentDate = new Date(date);
  return parseInt(currentDate.getFullYear(), 0) > 1;
}

export function convertUtcToUserDate(date) {
  if (isDateValid(date)) {
    const dateConverted = date.substring(0, date.indexOf('.'));
    return new Date(dateConverted);
  }
}

export function setCurrrentDate() {
  return new Date();
}

export function setCurrrentYear() {
  const currentDate = new Date();
  return currentDate.getFullYear();
}

export function setPreviousYear() {
  const currentDate = new Date();
  return currentDate.getFullYear() - 1;
}

export function setDateFormat(format = 'dd-MM-yyyy') {
  return format;
}

export function validateDate(date) {
  return date ? new Date(date) : null;
}

export function getShortDatePattern() {
  return '(0[1-9]|1[0-9]|2[0-9]|3[01])\\-(0[1-9]|1[0-2])\\-\\d{4}|\\d{1,2}\\/\\d{1,2}\\/\\d{4}|([0-9]{8})';
}

export function getDatePickerConfig(customConfig) {
  const defaultConfig = {
    pattern: DATE_PATTERN,
    altInputFormats: ALT_DATE_INPUT_FORMATS,
    format: DD_MMM_YYYY_FORMAT,
    dateOptions: { showWeeks: false },
  };

  return customConfig ? { ...defaultConfig, ...customConfig } : defaultConfig;
}
