import angular from 'angular';

angular.module('app').directive('convertContentHtmlToRtf', function saveEmailContentHtmlToRTF($document, $timeout, contactService, $window) {
  return {
    restrict: 'E',
    template: '<button type="button" class="btn btn-sm btn-info text-small pull-left" ng-attr-style=""><img class="margin-right-top-3" src="/assets/images/icon/icon-send-mail.svg" height="13" width="11">Open in Word</button>',
    link(scope, element, attrs) {
      const elementConstant = () => {
        if (!attrs.htmlContent || !attrs.htmlContent.trim()) return;
        contactService.convertContentHtmlToRTF({ htmlString: attrs.htmlContent }).then((response) => {
          const data = response;
          const elements = $window.document.createElement('a');
          elements.setAttribute('href', `data:${data.contentType};base64,${data.documentContent}`);
          elements.setAttribute('download', data.name);

          elements.style.display = 'none';
          $window.document.body.appendChild(elements);
          const clickDelay = $timeout(() => {
            elements.click();
          }, 100);
          clickDelay.then(() => {
            $window.document.body.removeChild(elements);
          });

          scope.$on('$destroy', () => {
            $timeout.cancel(clickDelay);
          });
        });
      };
      element.on('click', elementConstant);
      scope.$on('$destroy', () => {
        element.off('click', elementConstant);
      });
    },
  };
});
