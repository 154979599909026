class NavigationMobileCtrl {
  showSubMenu(headerName) {
    this.sidebarMainObj = {};
    this.sidebarMainObj.isSubMenuEnabled = true;
    this.sidebarMainObj.subMenuHeaderName = headerName;
  }
}


export default NavigationMobileCtrl;

