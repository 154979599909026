import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('pipelineLodged', function pipelineLodged($timeout, overviewDashboardService) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { myParam: '=' },
    templateUrl: 'assets/views/overviewDashboard/directiveTemplates/pipelineLodged.html',

    link(scope) {
      scope.tableMode = true;


      scope.fixedRateReviewsDropDownList = [];
      scope.fixedRateReviewsDropDowns = {};

      scope.fixedRateReviewTableData = [];

      scope.WidgetOptionGetData = [];
      scope.pipelineLodgedMultiAdviser = [];
      scope.pipelineLodgedMultiAdviserObj = {};

      scope.filteredFixedRateReviewsDropDownList = [];


      function WidgetOptionAdviserListGet() {
        overviewDashboardService.WidgetOptionAdviserListGet()
          .then((response) => {
            scope.WidgetOptionAdviserList = response.data;
            for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
              scope.pipelineLodgedMultiAdviserObj = { FamilyName: scope.WidgetOptionAdviserList[i].FamilyName, FamilyId: scope.WidgetOptionAdviserList[i].FamilyId, OptionValueId: scope.WidgetOptionAdviserList[i].OptionValueId, OptionDB_Value: scope.WidgetOptionAdviserList[i].OptionDB_Value, ticked: false, WidgetOptionId: scope.WidgetOptionAdviserList[i].OptionId };

              scope.pipelineLodgedMultiAdviser.push(scope.pipelineLodgedMultiAdviserObj);
            }

            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((res) => {
                scope.WidgetOptionGetData = res.data;

                scope.WidgetOptionGetAdviser = {};
                scope.Advisers = [];
                for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                  if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 3) {
                    scope.Advisers = scope.WidgetOptionGetData[j].SelectedUerValues;
                    const AdvisersArray = scope.Advisers.split(',');

                    scope.pipelineLodgedMultiAdviser = scope.pipelineLodgedMultiAdviser.map((obj) => {
                      if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                        obj.ticked = true;
                        return obj;
                      }


                      return obj;
                    });
                  }
                }
              }, () => {
              });
          }, () => {
          });
      }

      function WidgetPiplineLodgedGet() {
        overviewDashboardService.WidgetPiplineLodgedGet(scope.myParam.Widget_UserID)
          .then((response) => {
            scope.lodgeSmallData = response.data;
            scope.totalLodgedAmount = scope.lodgeSmallData.TotalAmount;
            scope.totalLodgedTransaction = scope.lodgeSmallData.TotalTransaction;
          }, () => {
          });
      }

      function widgetOptionGet() {
        overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
          .then((response) => {
            scope.WidgetOptionGetData = response.data;

            for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
              if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 17) {
                const widgetOptionValue = scope.WidgetOptionGetData[i].Widget_OptionValue;
                scope.filteredLodgedDropDownList = scope.fixedRateReviewsDropDownList.filter(item => parseInt(item.OptionValueId, 10) === parseInt(widgetOptionValue, 10));

                scope.fixedRateReviewsDropDowns = scope.filteredLodgedDropDownList[0];
              }
              if (parseInt(scope.WidgetOptionGetData[i].WidgetOptionId, 10) === 3) {
                scope.Advisers = scope.WidgetOptionGetData[i].SelectedUerValues;
                const AdvisersArray = scope.Advisers.split(',');

                scope.pipelineLodgedMultiAdviser = scope.pipelineLodgedMultiAdviser.map((obj) => {
                  if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                    obj.ticked = true;
                    return obj;
                  }


                  return obj;
                });
              }
            }
          }, () => {
          });
      }

      function saveForTimePeriod(widgetSetObj) {
        scope.OptionToSet = widgetSetObj;

        overviewDashboardService
          .WidgetOptionSet(scope.OptionToSet)
          .then(() => {
            WidgetPiplineLodgedGet();
            widgetOptionGet();
          })
          .catch(displayError);
      }

      scope.updateMultiselectFilterData = function (clickedItem) {
        let dataToSend = [];
        scope.MultiOptionSet = {};
        scope.MultiOptionSetForAll = {};
        const itemData = _.find(scope.pipelineLodgedMultiAdviser, ['ticked', true]);
        if (_.isUndefined(itemData)) {
          _.map(scope.pipelineLodgedMultiAdviser, (item) => {
            if (item.FamilyName === clickedItem.FamilyName) {
              item.ticked = true;
            }
          });
        }
        if (clickedItem.FamilyName !== 'All') {
          if (_.size(scope.pipelineLodgedMultiAdviser) !== 0) {
            scope.pipelineLodgedMultiAdviser[0].ticked = false;
          }

          dataToSend = scope.pipelineLodgedMultiAdviser.filter(item => item.FamilyName !== 'All' && item.ticked === true).map((obj) => {
            return { PersonId: obj.FamilyId };
          });


          scope.MultiOptionSet.SelectedUser = dataToSend;
          scope.MultiOptionSet.WidgetOptionId = scope.WidgetOptionAdviserList[0].OptionId;
          scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
          scope.MultiOptionSet.Widget_OptionValue = scope.WidgetOptionAdviserList[1].OptionValueId;

          saveForTimePeriod(scope.MultiOptionSet);
        } else {
          const all = scope.outp.filter(item => item.FamilyName === 'All');
          if (all.length > 0 && scope.outp.length > 1) {
            scope.pipelineLodgedMultiAdviser = scope.pipelineLodgedMultiAdviser.map((obj) => {
              if (obj.FamilyName === 'All')
                return obj;

              obj.ticked = false;
              return obj;
            });
          }
          dataToSend.push({ PersonId: scope.pipelineLodgedMultiAdviser[0].FamilyId });


          scope.MultiOptionSetForAll.SelectedUser = dataToSend;
          scope.MultiOptionSetForAll.WidgetOptionId = scope.WidgetOptionAdviserList[0].OptionId;
          scope.MultiOptionSetForAll.Widget_UserID = scope.myParam.Widget_UserID;
          scope.MultiOptionSetForAll.Widget_OptionValue = scope.WidgetOptionAdviserList[0].OptionValueId;

          saveForTimePeriod(scope.MultiOptionSetForAll);
        }
      };
      function WidgetOptionTimePeriodList8Get() {
        overviewDashboardService.WidgetOptionTimePeriodList8Get()
          .then((response) => {
            scope.fixedRateReviewsDropDownList = response.data;
            scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[0];
            for (let i = 0; i < scope.fixedRateReviewsDropDownList.length; i++) {
              scope.fixedRateReviewsDropDownList[i].index = 0 + i;
            }
            widgetOptionGet();
          }, () => {
          });
      }

      scope.showNextVal = function (displayObj) {
        scope.currentObj = displayObj;
        const displayPos = scope.currentObj.index;
        const arrayPos = displayPos;

        if (arrayPos + 1 > scope.fixedRateReviewsDropDownList.length - 1) {
          scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[0];
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;
            }, () => {
            });
          scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
            SelectedUser: null };

          saveForTimePeriod(scope.objForWidgetOptionSet);
          return;
        }
        scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[arrayPos + 1];
        overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
          .then((response) => {
            scope.WidgetOptionGetData = response.data;
          }, () => {
          });
        scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
          Widget_UserID: scope.myParam.Widget_UserID,
          Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
          SelectedUser: null };
        saveForTimePeriod(scope.objForWidgetOptionSet);
      };

      scope.showPrevVal = function (displayObj) {
        scope.currentObj = displayObj;
        let displayPos;
        if (!_.isNil(scope.currentObj.index)) {
          displayPos = scope.currentObj.index;
        } else {
          displayPos = 0;
        }
        const arrayPos = displayPos;

        if (arrayPos === 0) {
          const lastPos = scope.fixedRateReviewsDropDownList.length - 1;
          scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[lastPos];
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;
            }, () => {
            });
          scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
            SelectedUser: null };

          saveForTimePeriod(scope.objForWidgetOptionSet);
          return;
        }
        scope.fixedRateReviewsDropDowns = scope.fixedRateReviewsDropDownList[arrayPos - 1];
        overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
          .then((response) => {
            scope.WidgetOptionGetData = response.data;
          }, () => {
          });
        scope.objForWidgetOptionSet = { WidgetOptionId: scope.fixedRateReviewsDropDowns.OptionId,
          Widget_UserID: scope.myParam.Widget_UserID,
          Widget_OptionValue: scope.fixedRateReviewsDropDowns.OptionValueId,
          SelectedUser: null };

        saveForTimePeriod(scope.objForWidgetOptionSet);
      };
      scope.doAction = function (msg) {
        if (msg === 'edit')
          scope.customizeMode = true;
        else
          scope.customizeMode = false;
        if (msg === 'notEdit') {
          scope.customizeMode = false;
        } else
          scope.customizeMode = true;
      };

      function init() {
        WidgetPiplineLodgedGet();
        WidgetOptionTimePeriodList8Get();
        WidgetOptionAdviserListGet();
      }

      init();
    },
  };
});
