import angular from 'angular';
import { POST_SETTLEMENT_FOLLOW_UP_ID, CUSTOMER_CARE_TYPES } from 'Common/constants/customerCareModules';
import _ from 'lodash';

angular.module('app').controller('OpenPostSettlementSendEmailModalCtrl', function OpenSendEmailModalCtrl($scope,
  $rootScope,
  $uibModal,
  $uibModalInstance,
  $state,
  $controller,
  source,
  toaster,
  postSettlementListTable,
  postSettlementObj,
  contactService,
  corporateService,
  customerCareService,
  formErrorHandlerServices,
  loadPostSettlementTable,
  mainObj,
  customerCareTemplateService) {
  const vm = this;
  angular.extend(vm, $controller('CustomerCareSendEmailModalBaseCtrl', { $scope }));

  $scope.recipients = [];
  $scope.IsComplete = true;
  if (source === 'single') {
    if ((postSettlementObj === '') && (postSettlementObj.Email === '')) {
      $scope.recipients.email = [{ EmailAddress: '', FamilyFullname: '' }];
    }
    if (postSettlementObj.Email !== '') {
      const settlementName = mainObj ? mainObj.settlementName : '';
      const notiHeader = `Post Settlement • ${settlementName} Completed via Email`;
      $scope.recipients.email = [{
        FamilyID: postSettlementObj.FamilyID,
        FamilyFullname: postSettlementObj.Customer,
        LoanID: postSettlementObj.LoanID,
        EmailAddress: postSettlementObj.Email,
        NotificationHeader: notiHeader,
      }];
    }

    $scope.recipients.CustomerCareType = CUSTOMER_CARE_TYPES.POST_SETTLEMENT_FOLLOW_UP;
  }
  $scope.currentModule = POST_SETTLEMENT_FOLLOW_UP_ID;
  $scope.postSettlementListTable = postSettlementListTable;
  if (source === 'all') {
    $scope.all = true;
    if ($scope.postSettlementListTable.length > 0) {
      $scope.selectEmailList = [];
      $scope.ClientList = [];
      for (let i = 0; i < $scope.postSettlementListTable.length; i++) {
        $scope.emailAdd = {};
        if ($scope.postSettlementListTable[i].Email !== '') {
          $scope.emailAdd.FamilyID = $scope.postSettlementListTable[i].FamilyID;
          $scope.emailAdd.FamilyFullname = $scope.postSettlementListTable[i].Customer;
          $scope.emailAdd.LoanID = $scope.postSettlementListTable[i].LoanID;

          $scope.emailAdd.EmailAddress = $scope.postSettlementListTable[i].Email;
          $scope.selectEmailList.push($scope.emailAdd);
        }
      }
      if ($scope.selectEmailList.length > 0) {
        $scope.recipients.email = $scope.selectEmailList;
        $scope.noOfPeople = $scope.selectEmailList.length;
      }
    }
  }
  $scope.getRecipientAutoSuggestion = function (userInput) {
    contactService.getRecipientAutoSuggestion(userInput).then((response) => {
      $scope.selectEmailList = [];
      $scope.withEmail = response.data;
      _.forEach($scope.withEmail, (obj) => {
        if ((typeof obj.EmailAddress !== 'undefined') && (obj.EmailAddress !== '')) {
          $scope.selectEmail = {};
          $scope.selectEmail.FamilyFullname = obj.Name;
          $scope.selectEmail.EmailAddress = obj.EmailAddress;
          $scope.selectEmailList.push($scope.selectEmail);
        }
      });
    });
  };

  // EmailTemplates
  function getMergeFields() {
    $scope.preferredName = '';
    if (typeof postSettlementObj.FamilyID !== 'undefined') {
      $scope.familyId = postSettlementObj.FamilyID;
    } else {
      $scope.familyId = 0;
    }
    if (typeof postSettlementObj.LoanID !== 'undefined') {
      $scope.loanId = postSettlementObj.LoanID;
    } else {
      $scope.loanId = 0;
    }
    $scope.clientId = 0;
    corporateService.MergeFieldsGet($scope.familyId, $scope.clientId, $scope.loanId).then((response) => {
      $scope.emailMergeFieldList = response.data;
      $scope.mergeFieldsObj = response.data[0];
      $scope.mergeFields = [];
      $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
      for (let k = 0; k < $scope.mergeFields.length; k++) {
        if ($scope.mergeFields[k].Name === 'Full Name') {
          $scope.preferredName = $scope.mergeFields[k].Value;
        }
      }
      $scope.getEmailTemplates($scope.preferredName);
      $scope.getDefaultTemplate();
    });
  }
  getMergeFields();

  $rootScope.getEmailTemplatesFromEmailPage = function () {
    $scope.getEmailTemplates('');
  };
  $scope.selectedTemplate = {
    UserTemplateID: 0,
    UserTemplateName: 'Blank',
    UserTemplateEmailSubject: '',
  };

  $scope.refreshResults = function (select) {
    const search = select.search;

    $scope.getRecipientAutoSuggestion(search);
  };
  $scope.sendEmail = function (form) {
    const customerCareFormValid = formErrorHandlerServices.sendEmailFormValidation(form);
    if (customerCareFormValid) {
      $scope.recipientsEmailArray = [];
      angular.forEach($scope.recipients.email, (item) => {
        $scope.recipientsEmailArray.push(item);
      });
      $scope.email.ClientList = $scope.recipientsEmailArray;
      $scope.email.CustomerCareType = CUSTOMER_CARE_TYPES.POST_SETTLEMENT_FOLLOW_UP;
      $scope.email.IsComplete = $scope.IsComplete;

      customerCareService.CustomerCareEmailSendv2($scope.email).then((postSettlementRes) => {
        if (postSettlementRes.data && postSettlementRes.data.length) {
          const postSettlementEmailValid = customerCareTemplateService.customerCareResponseMsg(postSettlementRes.data, 'EMAIL');
          if (postSettlementEmailValid) {
            $scope.email.EmailSubject = '';
            $scope.email.EmailBody = '';
            loadPostSettlementTable();
            form.$setPristine(true);
          }
          $scope.cancel();
        }
      });
    }
  };


  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
