import { COMPANY_TYPES } from 'Common/constants/partyTypes';

class entityDetailsCtrl {
  constructor(
    contactService
  ) {
    'ngInject';

    this.contactService = contactService;
  }

  getCompanyTypes() {
    this.contactService.getCompanyTypes()
      .then((response) => {
        const { data } = response;
        this.companyTypes = data;
      });
  }

  companyTypeChange() {
    this.entityData.CompanyTypeId = this.entityData.chosenCompany.Id;
  }


  $onInit() {
    this.partyType = parseInt(this.partyType, 10);
    this.COMPANY_TYPES = COMPANY_TYPES;

    this.getCompanyTypes();
  }
}

export default entityDetailsCtrl;
