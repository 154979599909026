import _ from 'lodash';
import swal from 'sweetalert';
import { haversineInKM } from 'Common/utilities/math';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

class BrokerAllocationToolCtrl {
  constructor(
    searchPreferenceSetting,
    broadcastDataService,
    corporateService,
    generalService,
    userService,
    mapService,
    $compile,
    toaster,
    $scope
  ) {
    'ngInject';

    this.searchPreferenceSetting = searchPreferenceSetting;
    this.broadcastDataService = broadcastDataService;
    this.corporateService = corporateService;
    this.generalService = generalService;
    this.userService = userService;
    this.mapService = mapService;
    this.$compile = $compile;
    this.toaster = toaster;
    this.$scope = $scope;
  }
  loadAdviserToMap() {
    const mapDetails = this.mapService.mapInitialization(
      'map-address-adviser-modal',
      this.latitude,
      this.longitude,
      this.zoomValue,
      this.formattedAddress,
      true,
      this.locations,
      true,
      this.toolTipContent);

    this.map = mapDetails[0];
    this.loadingBroker = false;
  }
  getAdviserWithDistance(adviserList) {
    this.locations = [];
    this.clientDetailsLocation = [];
    const loctmp = [];
    const obj = {};
    let toolTipContent;
    let compiled;
    let counter = 1;

    if (adviserList && adviserList.length) {
      adviserList.map((adviser) => {
        let fullName = '';
        let email = '';
        let workNumber = '';
        let mobileNumber = '';
        let familyId = 0;
        if (adviser && adviser.details && Object.keys(adviser.details).length) {
          const details = adviser.details;
          fullName = details.FullName ? details.FullName : '';
          email = details.Email ? details.Email : '';
          workNumber = details.WorkNumber ? details.WorkNumber : '';
          mobileNumber = details.MobileNumber ? details.MobileNumber : '';
          familyId = details.FamilyId ? details.FamilyId : 0;
        }
        const allocationButtonTemplate = `<button id="allocated" class="btn btn-wide" data-ng-click="vm.allocateAdviser(${familyId})"><b>Allocate</b></button></div>`;
        toolTipContent = `${'<div class="col-md-12 map-allocate-adviser">' +
                         '<div class="margin-bottom-5 letter-icon-wrapper ng-isolate-scope size-sm box-round" data="'}${fullName}" char-count="2" color="#7f6AA1"><span class="letter-icon"></span></div>` +
                         `<h5 class="violet-color margin-left-10 inline-block"><b>${fullName}</b></h5>` +
                         `<h5><a href="#">${email}</a></h5>` +
                         `<h5><span class="violet-color"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;&nbsp;${workNumber}</span></h5>` +
                         `<h5><span class="violet-color"><i class="fa fa-mobile" aria-hidden="true"></i>&nbsp;&nbsp;${mobileNumber}</span></h5>` +
                         `<h5><span class="violet-color">${adviser.address}</span></h5>`;
        toolTipContent = this.showAllocationButton ? (toolTipContent + allocationButtonTemplate) : toolTipContent;

        compiled = this.$compile(toolTipContent)(this.$scope);
        if (adviser.distance <= this.searchPreference.radius) {
          const newKey = `${adviser.lat}${adviser.lng}`;
          if (obj[newKey]) {
            obj[newKey]++;
          } else {
            obj[newKey] = 1;
          }
          const val = [
            adviser.address,
            adviser.lat,
            adviser.lng,
            compiled[0],
          ];

          loctmp.push(val);
          this.clientDetailsLocation.push(adviser);
          this.zoomValue = 8;
        }

        if (counter === adviserList.length) {
          this.locations = loctmp;
          this.loadAdviserToMap();
        }
        counter++;
        return adviserList;
      });

      if (!adviserList.length && this.mapSetting.isLoaded) {
        this.locations = [];
        this.loadAdviserToMap();
      }
    } else if (this.mapSetting.isLoaded) {
      this.locations = [];
      this.loadAdviserToMap();
    }
  }

  getAdviserCalculateDistance(geocoded, adviserList) {
    this.distance = [];
    const latitude = geocoded.latitude || 0;
    const longitude = geocoded.longitude || 0;
    const formattedAddress = geocoded.formatted_address || 0;
    this.latitude = latitude;
    this.longitude = longitude;
    this.formattedAddress = formattedAddress;

    adviserList && Object.keys(adviserList).forEach((x) => {
      if (adviserList[x].BusinessAddressLatitude && adviserList[x].BusinessAddressLongitude) {
        this.distance.push({
          distance: haversineInKM(latitude, longitude, adviserList[x].BusinessAddressLatitude, adviserList[x].BusinessAddressLongitude),
          details: adviserList[x],
          address: adviserList[x].BusinessAddressFormatted,
          lat: adviserList[x].BusinessAddressLatitude,
          lng: adviserList[x].BusinessAddressLongitude,
        });
      } else if (adviserList[x].HomeAddressLatitude && adviserList[x].HomeAddressLongitude) {
        this.distance.push({
          distance: haversineInKM(latitude, longitude, adviserList[x].HomeAddressLatitude, adviserList[x].HomeAddressLongitude),
          details: adviserList[x],
          address: adviserList[x].HomeAddressFormatted,
          lat: adviserList[x].HomeAddressLatitude,
          lng: adviserList[x].HomeAddressLongitude,
        });
      } else if (adviserList[x].PostalAddressLatitude && adviserList[x].PostalAddressLatitude) {
        this.distance.push({
          distance: haversineInKM(latitude, longitude, adviserList[x].PostalAddressLatitude, adviserList[x].PostalAddressLongitude),
          details: adviserList[x],
          address: adviserList[x].PostalAddressFormatted,
          lat: adviserList[x].PostalAddressLatitude,
          lng: adviserList[x].PostalAddressLongitude,
        });
      }
    });
    this.getAdviserWithDistance(this.distance);
  }
  searchAddress(adviserList) {
    const address = this.defaultAddress || '';
    if (address) {
      this.generalService.postCodeSearch(address)
        .then((placeResponse) => {
          const placeData = placeResponse.data && placeResponse.data[0];
          if (placeData && placeData.formatted_address) {
            this.zoomValue = 5;
            this.getAdviserCalculateDistance(placeData, adviserList);
          } else {
            this.loadingBroker = false;
          }
        });
    } else {
      this.clientDetailsLocation = 'No Address';
    }
  }
  loadAdviserData() {
    const familyId = 0;
    const sameSearchOrg = 0;
    const excludeNoAddress = 1;
    const getAdviserDetail = 0;
    const withGeoCode = 1;
    const statusArray = this.statusArrayId ? this.statusArrayId.join() : '1';
    const priorityId = this.priorityIds ? this.priorityIds.join() : '';
    const campaignListId = this.campaignListId ? this.campaignListId.join() : '';
    const businessUnitId = '1,5';
    this.loadingBroker = true;
    this.corporateService.advisersGet(familyId, sameSearchOrg, excludeNoAddress, getAdviserDetail, withGeoCode, statusArray, priorityId, campaignListId, businessUnitId)
      .then((adviser) => {
        this.adviserList = _.uniqBy(adviser.data, 'FamilyId');
        this.clientDetailsLocation = [];

        if (this.mapSetting.isLoaded) {
          this.searchAddress(this.adviserList);
        } else {
          this.mapSettingService.loadAPI().then(() => {
            this.mapSetting.isLoaded = true;
            this.searchAddress(this.adviserList);
          });
        }
      });
  }
  assignDefaultAddress() {
    let address = 'Australia';
    if (this.userInfo.CountryId === COUNTRY_TYPE.NEW_ZEALAND) {
      address = 'New Zealand';
    }
    if (this.postalCode) {
      this.displayAddress = `Postal Code ${this.postalCode}`;
      this.defaultAddress = this.postalCode;
      this.noPostalCode = false;
    } else {
      this.displayAddress = address;
      this.defaultAddress = address;
      this.noPostalCode = true;
    }
  }
  searchPostCode() {
    this.assignDefaultAddress();
    this.searchAddress(this.adviserList);
    this.getDisplayLabel();
    this.loadingBroker = true;
  }
  cancel() {
    this.modalInstance.dismiss();
  }
  getLeadCampaign() {
    this.campaignList = [];
    this.corporateService.corpLeadCampaignGet().then((response) => {
      const data = response.data;
      if (data && data.length) {
        this.campaignList = data.map((item) => {
          item.IsChecked = false;
          return item;
        });
      }
    });
  }
  refreshPreference() {
    this.noPostalCode = false;
    this.isPreference = false;
    this.campaignListId = [];
    const statusArray = [];
    this.statusArrayId = [];
    if (this.searchPreference.statusActive) {
      statusArray.push('Active');
      this.statusArrayId.push(1);
    }
    if (this.searchPreference.statusSuspended) {
      statusArray.push('Suspended');
      this.statusArrayId.push(4);
    }
    if (this.searchPreference.statusInactive) {
      statusArray.push('Inactive');
      this.statusArrayId.push(3);
    }
    if (this.searchPreference.statusTemporary) {
      statusArray.push('Temporarily Inactive');
      this.statusArrayId.push(2);
    }

    this.priorityIds = [];

    if (this.searchPreference.priority1) {
      this.priorityIds.push(1);
    }
    if (this.searchPreference.priority2) {
      this.priorityIds.push(2);
    }
    if (this.searchPreference.priority3) {
      this.priorityIds.push(3);
    }
    this.campaignList && this.campaignList.forEach((data) => {
      if (data.IsChecked) {
        this.campaignListId = [...this.campaignListId, data.CorpLeadCampaignID];
      }
    });
    this.loadAdviserData();
  }
  getDisplayLabel() {
    const withPostalCode = `Displaying Brokers within ${this.searchPreference.radius}km radius of ${this.displayAddress}`;
    const withoutPostalCode = `Displaying ${this.searchPreference.radius}km radius of ${this.displayAddress}`;
    this.displayLabel = this.noPostalCode ? withoutPostalCode : withPostalCode;
  }
  setPreferences() {
    this.isPreference = !this.isPreference;
  }
  allocateAdviser(familyId) {
    const adviserInfo = this.adviserList.filter((adviser) => {
      return parseInt(adviser.FamilyId, 10) === parseInt(familyId, 10);
    });
    if (adviserInfo && adviserInfo.length) {
      swal({
        title: 'Confirm Change of Adviser',
        text: `By clicking proceed, the allocated adviser of this loan will be changed to ${adviserInfo.FullName}?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Proceed',
        closeOnConfirm: true,
      }, (confirm) => {
        if (confirm) {
          this.modalInstance.close(adviserInfo[0]);
        }
      });
    } else {
      swal('Empty Information', 'Adviser info is empty.', 'error');
    }
  }
  $onInit() {
    this.userService.GetUserInfo().then((response) => {
      this.userInfo = response.data;
      this.isPreference = false;
      this.mapSetting = this.broadcastDataService.map;
      this.zoomValue = 8;
      this.searchPreference = this.searchPreferenceSetting.getSearchPreference();
      this.assignDefaultAddress();
      this.getLeadCampaign();
      this.getDisplayLabel();
      if (this.mapSetting.isLoaded) {
        this.loadAdviserData();
      } else {
        this.mapSettingService.loadAPI().then(() => {
          this.mapSetting.isLoaded = true;
          this.loadAdviserData();
        });
      }
    });
  }
}

export default BrokerAllocationToolCtrl;
