export function getBorrowerFullName(borrower, isGuarantor) {
  const borrowers = borrower.reduce((accum, item) => {
    if (!isGuarantor && !item.IsGuarantor) {
      accum.push(`${item.FirstName} ${item.LastName}`);
    } else if (isGuarantor && item.IsGuarantor === isGuarantor) {
      accum.push(`${item.FirstName} ${item.LastName}`);
    }
    return accum;
  }, []).join(', ');
  return borrowers;
}
