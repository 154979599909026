import { ADDRESS_FIELD } from 'Common/constants/addressField';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';

export function formatAddress(address) {
  return Object.keys(ADDRESS_FIELD).reduce((accum, key) => {
    const field = ADDRESS_FIELD[key];
    const fieldValue = address[field] && address[field].trim();
    if (fieldValue) {
      const appendComma = field === ADDRESS_FIELD.STREET_NAME || field === ADDRESS_FIELD.STATE;
      return appendComma ? `${accum} ${fieldValue}, ` : `${accum} ${fieldValue}`;
    }
    return accum;
  }, '');
}

export function addressToVM(address) {
  if (!address) return;

  const {
    route,
    street_address: streetAddress,
    street_number: streetNumber,
    locality,
    stateShort_Name: stateShortName,
    postal_code: postCode,
  } = address;

  return {
    route,
    streetAddress,
    streetNumber,
    locality,
    stateShortName,
    postCode,
  };
}

export function getCurrentAddress(personAddresses) {
  return personAddresses && personAddresses.find((address) => {
    return parseInt(address.TypeId, 10) === ADDRESS_TYPE.CURRENT_ADDRESS;
  });
}
