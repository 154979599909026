import angular from 'angular';
import _ from 'lodash';

angular.module('app').directive('newLoanApplicationEvents', function newLoanApplicationEvents() {
  return {
    link(scope, elem) {
      const par = elem.closest('.interactive-form');

      elem.on('click', () => {
        par.toggleClass('active');
      });

      scope.$on('$destroy', () => {
        elem.off('click', _.noop);
      });
    },
  };
});
