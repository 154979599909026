import angular from 'angular';

class ImportantDocumentCtrl {
  constructor(
    importantDocumentService,
    corporateModelService,
    loanToolsService,
    corporateService,
    contactService,
    configService,
    userService,
    generalService,
    SweetAlert,
    $uibModal,
    toaster
  ) {
    this.importantDocumentService = importantDocumentService;
    this.corporateModelService = corporateModelService;
    this.loanToolsService = loanToolsService;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.generalService = generalService;
    this.configService = configService;
    this.userService = userService;
    this.SweetAlert = SweetAlert;
    this.$uibModal = $uibModal;
    this.toaster = toaster;
  }
  getImportantDocumentFlags() {
    this.importantDocumentFlags = [];
    this.generalService.getImportantDocumentFlags()
      .then((response) => {
        if (!response || !response.data.length) return;
        this.importantDocumentFlags = [...response.data];
      });
  }
  getImportantDocumentFlagsName(flagId) {
    return this.importantDocumentFlags.filter(flag => Number(flag.DocumentFlagId) === Number(flagId))[0];
  }
  getImportantDocs() {
    this.loanToolsService.getImportantDocs()
      .then((data) => {
        if (!data) return;
        const dataFiltered = data.map(doc => ({ ...doc, documentFlagName: this.getImportantDocumentFlagsName(doc.documentFlagId) }));
        this.importantDocs = this.importantDocumentService.documentFileExtension(dataFiltered);
      });
  }
  getUserInfo() {
    this.contactService.getUserInfo().then((response) => {
      this.userInfo = response.data;
      this.isCorporateUser = response.data.AccessType === 5;
    });
  }
  openUploadedDocumentModal(size, documentId, isUploading, importantDocumentFlagId) {
    const modalInstance = this.$uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: '/assets/views/loanTools/modals/importantDocumentModal.html',
      controller: 'ImportantDocumentModalCtrl',
      controllerAs: 'modal',
      windowClass: 'upload-mydocuments-modal-class',
      size,
      resolve: {
        documentId: () => {
          return documentId;
        },
        isUploading: () => {
          return isUploading;
        },
        userInfo: () => {
          return this.userInfo;
        },
        importantDocumentFlags: () => {
          return this.importantDocumentFlags;
        },
        importantDocs: () => {
          return this.importantDocs;
        },
        importantDocumentFlagId: () => {
          return importantDocumentFlagId;
        },
      },
    });

    modalInstance.result.then((isSaved) => {
      if (isSaved) {
        this.getImportantDocs();
      }
    });
  }

  deleteDocument(documentId) {
    if (documentId) {
      this.SweetAlert.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, delete it!',
      }, (confirm) => {
        if (confirm) {
          this.corporateModelService.importantDocumentDetailsGet(documentId)
            .then((data) => {
              if (data) {
                const details = data.documentDetails;
                details.isActive = 0;
                this.corporateModelService.importantDocumentSet(details)
                  .then(() => {
                    this.toaster.pop('success', 'Delete Document', 'Successfully deleted document');
                    this.getImportantDocs();
                  });
              }
            });
        }
      });
    }
  }

  $onInit() {
    this.getUserInfo();
    this.getImportantDocumentFlags();
    this.getImportantDocs();
    this.toUploadReady = this.importantDocumentService.toUploadReady;
    this.uploadFile = this.importantDocumentService.uploadFile;
    this.successUpload = this.importantDocumentService.successUpload;
    this.errorUpload = this.importantDocumentService.errorUpload;
  }
}

angular.module('app').controller('ImportantDocumentCtrl', ImportantDocumentCtrl);
