export const FACT_FIND_HEADINGS_VALUES = {
  YOUR_GOAL: 1,
  YOUR_HOUSEHOLD: 2,
  YOUR_HOME: 3,
  TO_PURCHASE: 4,
  OTHER_PROPERTIES: 5,
  ASSETS: 6,
  LIABILITIES: 7,
  INCOME: 8,
  EXPENSES: 9,
  YOUR_DETAILS: 10,
  CO_APPLICATIONS: 11,
  ADDRESS_HISTORY: 12,
  EMPLOYMENT_HISTORY: 13,
  FORSEEABLE_CHANGES: 14,
  CREDIT_HISTORY: 15,
  SUPPORTING_DOCUMENTS: 16,
};
