import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import OpenOnlineFactFindForm from 'Components/usersSubscription/openOnlineFactFindForm';

angular.module('app').controller('FactFindCtrl',
  function FactFindCtrl($rootScope,
    $scope,
    contactService,
    $state,
    $uibModal,
    onlineFactFindModal,
    loanScenarioService,
    configService,
    usersSubscriptionService,
    currentUserService) {
    // methods
    const { userSubscription, onlineFactFind, splitOnlineFactFind } = configService.feature;
    $scope.onlineFactFindSubscription = (userSubscription && onlineFactFind) || 0;
    $scope.splitOnlineFactFind = splitOnlineFactFind || 0;
    $scope.factFindSubData = {};
    $scope.disabledShowModal = true;
    $scope.openFactFindModal = () => {
      const checkValid = $scope.CountryID === COUNTRY_TYPE.AUSTRALIA && ($scope.factFindSubData && !$scope.factFindSubData.isActive) && $scope.onlineFactFindSubscription;
      if (checkValid) {
        $scope.openFactFindSubscription();
      } else {
        $scope.checkFactFindFeature();
      }
    };

    $scope.checkFactFindFeature = () => {
      if ($scope.splitOnlineFactFind) {
        $scope.openGenerateFactFindModal();
      } else {
        $scope.openOnlineFactFindForm();
      }
    };

    $scope.openGenerateFactFindModal = () => {
      const loanId = $state.params.loanAppId;
      onlineFactFindModal.open({
        loanId,
      });
    };

    $scope.openFactFindSubscription = () => {
      const props = {
        familyId: $scope.familyId,
        subscriptionData: $scope.factFindSubData,
        orderedById: currentUserService.familyId,
      };
      usersSubscriptionService.subscribeToService(props)
        .then((response) => {
          if (!response) return;
          $scope.getSubscription(response);
        });
    };
    $scope.openOnlineFactFindForm = () => {
      const loanId = $state.params.loanAppId;
      $uibModal.open({
        templateUrl: 'assets/views/profileManagement/Users/openOnlineFactFindForm.html',
        controller: OpenOnlineFactFindForm,
        controllerAs: 'vm',
        resolve: {
          loanId: () => loanId,
        },
        size: 'lg',
        windowClass: 'online-fact-find-form',
      });
    };
    $scope.getSubscription = (afterSubscribeState = false) => {
      if ($scope.familyId && $scope.onlineFactFindSubscription) {
        contactService.subscriptionInfo($scope.familyId).then((response) => {
          if (response && response.length) {
            $scope.factFindSubData = response && response.find(obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.ONLINE_FACT_FIND);
            $scope.showSplitOnlineFF = $scope.splitOnlineFactFind && ($scope.CountryID === COUNTRY_TYPE.AUSTRALIA) && ($scope.factFindSubData && $scope.factFindSubData.isActive);
            if (!afterSubscribeState) return;
            $scope.checkFactFindFeature();
          }
        });
      }
    };
    $scope.getAllocatedAdviserInfo = () => {
      const loanAppId = $state.params.loanAppId;
      loanScenarioService.scenarioDetailsGet(loanAppId).then(({ data }) => {
        if (data && data.AdviserId) {
          $scope.LoanApplicationDetail = data;
          $scope.familyId = parseInt($scope.LoanApplicationDetail.AdviserId, 10);
          $scope.CountryID = $scope.LoanApplicationDetail.CountryID;
          $scope.getSubscription();
        }
      });
    };

    const init = () => {
      $scope.showSplitOnlineFF = !$scope.onlineFactFindSubscription && $scope.splitOnlineFactFind;
      $scope.showSubscriptionMangement && $scope.getAllocatedAdviserInfo();
    };
    init();
  });
