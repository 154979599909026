import angular from 'angular';
import { SETTLEMENT_OPTIONS } from 'Common/constants/settlementOptions';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('settlement',
  function settlement(
    $timeout,
    overviewDashboardService,
    overviewDashboardSharedService,
    corporateService,
    $filter
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl: 'assets/views/overviewDashboard/directiveTemplates/settlement.html',

      link(scope) {
        scope.commissionReceivedTableDropDownList = [];
        scope.commissionReceivedTableDropDowns = {};
        scope.filteredCommissionReceivedDropDownList = [];
        scope.commissionReceivedMultiAdviser = [];
        scope.commissionReceivedMultiAdviserObj = {};
        scope.commissionReceivedMultiLender = [];
        scope.commissionReceivedMultiLenderObj = {};
        scope.WidgetOptionGetData = [];
        scope.outp = [];
        scope.outpLender = [];

        scope.settlement = {};
        scope.timePeriodMonth = 0;
        scope.timePeriodYear = 0;
        scope.isCorporateUser = false;
        function isCorp() {
          overviewDashboardSharedService.isCorpShared(scope);
        }
        isCorp();
        const populateBusinessUnit = (widgetValue) => {
          const value = parseInt(widgetValue, 10) || 0;
          if (scope.businessUnitList && scope.businessUnitList.length) {
            const filtered = scope.businessUnitList.filter((unit) => {
              return parseInt(unit.BusinessUnitID, 10) === value;
            });
            if (filtered && filtered.length) {
              scope.settlement.businessUnit = filtered[0];
            }
          }
        };
        const getBusinessUnit = () => {
          scope.businessUnitList = [];
          corporateService.businessUnitGet().then((response) => {
            const data = response.data;
            if (data && data.length) {
              const all = {
                BusinessUnitID: 0,
                BusinessUnitName: 'All Business Units',
              };
              scope.businessUnitList = [all, ...data];
              populateBusinessUnit();
            }
          });
        };
        getBusinessUnit();

        scope.showBy = [
          { id: 1, name: '# of Transactions', value: 'number' },
          { id: 2, name: 'Value of Transactions', value: 'amount' },
        ];
        scope.settlement.showBy = scope.showBy[0];
        function reportingStatesGet() {
          corporateService.reportingStatesGet().then((response) => {
            const data = {
              ReportingStateId: 0,
              ReportingStateName: 'All',
            };
            response.data.unshift(data);
            scope.reportingStateList = response.data;
            scope.settlement.reportingState = response.data[0];
          });
        }

        reportingStatesGet();

        function populateDropdownShowForStatus(value) {
          const val = parseInt(value, 10);
          for (let i = 0; i < scope.statusList.length; i++) {
            if (scope.statusList[i].LoanStatusID === val) {
              scope.settlement.status = scope.statusList[i];
            }
          }
        }

        function populateDropdownShowBy(value) {
          for (let i = 0; i < scope.showBy.length; i++) {
            if (scope.showBy[i].value === value) {
              scope.settlement.showBy = scope.showBy[i];
            }
          }
        }

        function populateDropdownShowFor(value) {
          const val = parseInt(value, 10);
          if (scope.reportingStateList && scope.reportingStateList.length > 0) {
            scope.reportingStateList.forEach((item) => {
              if (item.ReportingStateId === val) {
                scope.settlement.reportingState = item;
              }
            });
          }
        }

        function populateDateAndYear(value) {
          switch (value) {
          case 'This Year':
            scope.timePeriodMonth = 0;
            scope.timePeriodYear = $filter('date')(new Date(), 'yyyy');
            break;
          case 'This Month':
            scope.timePeriodMonth = $filter('date')(new Date(), 'M');
            scope.timePeriodYear = $filter('date')(new Date(), 'yyyy');
            break;
          case 'Last Month': {
            let lastMonthDate = new Date();
            lastMonthDate = new Date(lastMonthDate.setMonth(lastMonthDate.getMonth() - 1));
            scope.timePeriodMonth = $filter('date')(lastMonthDate, 'M');
            scope.timePeriodYear = $filter('date')(lastMonthDate, 'yyyy');
            break;
          }
          case 'Last 2 Months': {
            let lastMonthDate = new Date();
            lastMonthDate = new Date(lastMonthDate.setMonth(lastMonthDate.getMonth() - 2));
            scope.timePeriodMonth = $filter('date')(lastMonthDate, 'M');
            scope.timePeriodYear = $filter('date')(lastMonthDate, 'yyyy');
            break;
          }
          case 'Last 3 Months': {
            let lastMonthDate = new Date();
            lastMonthDate = new Date(lastMonthDate.setMonth(lastMonthDate.getMonth() - 3));
            scope.timePeriodMonth = $filter('date')(lastMonthDate, 'M');
            scope.timePeriodYear = $filter('date')(lastMonthDate, 'yyyy');
            break;
          }
          case 'Last Year':
            scope.timePeriodMonth = 0;
            scope.timePeriodYear = $filter('date')(new Date(), 'yyyy') - 1;
            break;
          default:
          // default case
          }
        }

        const getTargetLoanSummaryStatus = () => {
          const statusId = scope.settlement.status.LoanStatusID || 0;
          const reportingStateId = scope.settlement.reportingState.ReportingStateId || 0;
          const showBy = scope.settlement.showBy.value || 'number';
          const userId = scope.myParam.Widget_UserID;
          const businessUnitId = scope.settlement.businessUnit.BusinessUnitID;
          corporateService.getTargetLoanSummaryStatus(statusId, reportingStateId, showBy, scope.timePeriodMonth, scope.timePeriodYear, userId, businessUnitId)
            .then((response) => {
              const data = response.data;
              if (data && data.length) {
                scope.targetLoanSummary = data[0];
              }
            });
        };

        function WidgetOptionGet() {
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.settlementOptions = response.data;

              response.data.forEach((data) => {
                if (data.SelectedUerValues) {
                  switch (parseInt(data.WidgetOptionId, 10)) {
                  case SETTLEMENT_OPTIONS.STATUS:
                    populateDropdownShowForStatus(data.SelectedUerValues);
                    break;
                  case SETTLEMENT_OPTIONS.SHOW_BY:
                    populateDropdownShowBy(data.SelectedUerValues);
                    break;
                  case SETTLEMENT_OPTIONS.SHOW_FOR:
                    populateDropdownShowFor(data.SelectedUerValues);
                    break;
                  case SETTLEMENT_OPTIONS.BUSINESS_UNIT:
                    populateBusinessUnit(data.SelectedUerValues);
                    break;
                  default:
                  // default case
                  }
                }
                if (parseInt(data.WidgetOptionId, 10) === SETTLEMENT_OPTIONS.TIME_PERIOD) {
                  populateDateAndYear(data.DisplayValue);
                }
              });

              $timeout(() => {
                getTargetLoanSummaryStatus();
              }, 1000);
            }, () => {
            });
        }

        function getStatus() {
          corporateService.getRelatedLoanStatus().then((response) => {
            scope.statusList = response.data;
            scope.settlement.status = response.data[0];
            $timeout(() => {
              WidgetOptionGet();
            }, 100);
          });
        }
        getStatus();

        function saveOption(widgetSetObj) {
          overviewDashboardService
            .WidgetOptionSet(widgetSetObj)
            .then(WidgetOptionGet)
            .catch(displayError);
        }

        scope.targetLoanSummary = [];
        scope.saveDropdownValue = function (widgetOptionId) {
          const filterData = scope.settlementOptions.filter(item => parseInt(item.WidgetOptionId, 10) === parseInt(widgetOptionId, 10));
          if (filterData.length > 0) {
            let value = '';
            filterData[0].WidgetId = scope.myParam.WidgetId;
            filterData[0].Widget_UserID = scope.myParam.Widget_UserID;
            switch (parseInt(widgetOptionId, 10)) {
            case SETTLEMENT_OPTIONS.STATUS:
              value = scope.settlement.status.LoanStatusID;
              break;
            case SETTLEMENT_OPTIONS.SHOW_BY:
              value = scope.settlement.showBy.value;
              break;
            case SETTLEMENT_OPTIONS.SHOW_FOR:
              value = scope.settlement.reportingState.ReportingStateId;
              break;
            case SETTLEMENT_OPTIONS.BUSINESS_UNIT:
              value = scope.settlement.businessUnit.BusinessUnitID;
              break;
            default:
            // default case
            }
            filterData[0].SelectedUerValues = value;
            saveOption(filterData[0]);
          }
        };


        function WidgetOptionTimePeriodListv3Get() {
          corporateService.getWidgetOptionTimePeriodList6()
            .then((response) => {
              scope.commissionReceivedTableDropDownList = response.data;
              scope.commissionReceivedTableDropDowns = scope.commissionReceivedTableDropDownList[0];
              for (let i = 0; i < scope.commissionReceivedTableDropDownList.length; i++) {
                scope.commissionReceivedTableDropDownList[i].index = 0 + i;
              }
              overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
                .then((option) => {
                  scope.WidgetOptionGetData = option.data;
                  if (scope.WidgetOptionGetData && scope.WidgetOptionGetData.length) {
                    scope.WidgetOptionGetData.forEach((value) => {
                      if (parseInt(value.WidgetOptionId, 10) === SETTLEMENT_OPTIONS.TIME_PERIOD) {
                        const widgetOptionValue = value.Widget_OptionValue;
                        scope.filteredCommissionReceivedDropDownList = scope.commissionReceivedTableDropDownList.filter(item => parseInt(item.Option_ValueID, 10) === parseInt(widgetOptionValue, 10));
                        if (scope.filteredCommissionReceivedDropDownList && scope.filteredCommissionReceivedDropDownList.length) {
                          scope.commissionReceivedTableDropDowns = scope.filteredCommissionReceivedDropDownList[0];
                        }
                      }
                    });
                  }
                });
            });
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(WidgetOptionGet)
            .catch(displayError);
        }

        scope.showNextVal = function (displayObj) {
          scope.currentObj = displayObj;
          const displayPos = scope.currentObj.index;
          const arrayPos = displayPos;
          if (arrayPos + 1 > scope.commissionReceivedTableDropDownList.length - 1) {
            scope.commissionReceivedTableDropDowns = scope.commissionReceivedTableDropDownList[0];
            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionID,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.commissionReceivedTableDropDowns.Option_ValueID,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
          } else {
            scope.commissionReceivedTableDropDowns = scope.commissionReceivedTableDropDownList[arrayPos + 1];
            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionID,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.commissionReceivedTableDropDowns.Option_ValueID,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
          }
        };
        scope.showPrevVal = function (displayObj) {
          scope.currentObj = displayObj;
          let displayPos;
          if (scope.currentObj && scope.currentObj.index) {
            displayPos = scope.currentObj.index;
          } else {
            displayPos = 0;
          }
          const arrayPos = displayPos;

          if (arrayPos === 0) {
            const lastPos = scope.commissionReceivedTableDropDownList.length - 1;
            scope.commissionReceivedTableDropDowns = scope.commissionReceivedTableDropDownList[lastPos];
            overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
              .then((response) => {
                scope.WidgetOptionGetData = response.data;
              }, () => {
              });
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionID,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.commissionReceivedTableDropDowns.Option_ValueID,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            return;
          }

          scope.commissionReceivedTableDropDowns = scope.commissionReceivedTableDropDownList[arrayPos - 1];
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.WidgetOptionGetData = response.data;
            }, () => {
            });
          scope.objForWidgetOptionSet = {
            WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionID,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.commissionReceivedTableDropDowns.Option_ValueID,
            SelectedUser: null,
          };

          saveForTimePeriod(scope.objForWidgetOptionSet);
        };
        scope.doAction = function (msg) {
          if (msg === 'edit')
            scope.customizeMode = true;
          else
            scope.customizeMode = false;
          if (msg === 'notEdit') {
            scope.customizeMode = false;
          } else
            scope.customizeMode = true;
        };
        function init() {
          WidgetOptionTimePeriodListv3Get();
        }

        init();
      },
    };
  });
