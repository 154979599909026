import angular from 'angular';
import _ from 'lodash';
import { INPUT_DATE_FORMAT, ALT_INPUT_FORMATS, DATE_OPTIONS } from 'Common/constants/workbench';

angular.module('app').controller('LoanAppFinancialLiabilitiesCtrl',
  function LoanAppFinancialLiabilitiesCtrl($rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    optionsService,
    SweetAlert,
    financeService,
    financialsSharedService,
    loanAppSharedData,
    uiService) {
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.financialsSharedService = financialsSharedService;
    $scope.listRepaymentFrequency = [];
    optionsService.FinancialFrequency().then((response) => {
      _.map(response.data, (o) => {
        o.Desc = o.Name;
        o.Value = parseInt(o.Value, 10);

        return o;
      });

      $scope.listRepaymentFrequency = response.data;
    });

    // Models
    angular.extend($scope, {
      loanAppId: $stateParams.loanAppId,
      familyId: $stateParams.familyId,
      listLiabilities: [],
      listLender: [],
      listLenderSearch: [],
      liabilityTypes: [],
      displayLiabilitiesForm: false,
      modalType: false,
      //* ************************************/
      //  owners multi Select                /
      //* ************************************/
      localLang: {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: '', // default-label is deprecated and replaced with this.
        // nothingSelected: "<div class='buttonLabel'>All Clients</div>"         //default-label is deprecated and replaced with this.
      },
      LiabilityTotalAmount: 0,
      showCreditorResults: false,
      hasLiability: false,
      liabilityLoading: false,
    });


    // Methods
    angular.extend($scope, {
      getNewLiabilityModel(familyId) {
        return {
          IsRefinance: false,
          AccountName: '',
          AccountNumber: '',
          BSB: '',
          Limit: null,
          CreditorId: 0,
          CreditorName: '',
          InterestTaxDeductible: null,
          RepaymentFrequencyId: 4,
          Repayments: null,
          InterestRate: null,
          IsAddToExpenses: false,
          PriorityId: 0,
          IsAccountDetailsKnown: false,
          Id: 0,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: familyId,
          FinancialId: 0,
          Type: null,
          TypeId: 0,
          Description: '',
          Amount: null,
          LiabilityValue: null,
          CurrencyCode: 'USD',
          Category: '',
          CurrencyCodeId: '1',
          Borrowers: [],
          FrequencyID: 4,
          FrequencyName: '',
          Provider: '',
          InArrears: null,
          LoanStartDate: '',
          InterestOnlyEndDate: '',
          DocumentedLoanTerm: '',
          IsRevolvingCredit: false,
          CreditCardNumber: '',
        };
      },
      IsBNZRequirement() {
        const requirements = _.find($scope.LiabilitiesLoanBNZRequirements, (o) => {
          return $scope.LiabilitiesSet.Type && o === $scope.LiabilitiesSet.Type.Name;
        });
        const checkBNZRequirement = $rootScope.crmCountry === 'New Zealand' && !$scope.LiabilitiesSet.IsRefinance && requirements;
        if (checkBNZRequirement) {
          return true;
        }
        return false;
      },
      LiabilitiesLoanBNZRequirements: [
        'Personal Loan',
        'Home Loan',
        'Business Loan',
      ],
      LiabilitiesDateSettings: {
        altInputFormats: ALT_INPUT_FORMATS,
        format: INPUT_DATE_FORMAT,
        dateOptions: DATE_OPTIONS,
        datePopUp: {
          StartDate: false,
          EndDate: false,
        },
        openDate(dateField) {
          $scope.LiabilitiesDateSettings.datePopUp[dateField] = !$scope.LiabilitiesDateSettings.datePopUp[dateField];
        },
      },
      LiabilityInfoGet() {
        $scope.financialsSharedService.LiabilityInfoGet();
      },
      changeSelectedFamily() {
        let family = _.find($scope.listLiabilities, (o) => { return _.isEqual(o.FamilyId, $scope.financialsSharedService.selectedFamily.FamilyId); });
        if (!family) {
          family = {
            FamilyName: '',
          };
        }
        angular.extend($scope.financialsSharedService.selectedFamily, {
          initials: utilitiesService.filterInitialOneString(family.FamilyName),
        });
      },
      LiabilityInfoDelete(liabilityId) {
        SweetAlert.swal({
          title: 'Are you sure?',
          text: 'This record will be removed from your liability list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            loanScenarioService.LiabilityInfoDelete($scope.loanAppId, liabilityId).then(() => {
              $timeout(() => {
                $scope.liabilitiesInit(false);
                financialsSharedService.liabilityDeleteSuccess();
              }, 100);
            });
          }
        });
      },
      LiabilityDetailsGet(familyId, liabilityId) {
        if (_.isUndefined(liabilityId))
          return false;

        loanScenarioService.LiabilityDetailsGet(familyId, $scope.loanAppId, liabilityId).then((liabilityDetailsGetResponse) => {
          // reset the actual model
          $scope.resetLiabilityModel('edit', familyId);
          // patch api data to actual model
          financialsSharedService.LiabilitiesSet = { ...financialsSharedService.LiabilitiesSet, ...liabilityDetailsGetResponse.data };
          // patch temporary type to actual model
          financialsSharedService.creditcardValidator(parseInt(financialsSharedService.LiabilitiesSet.TypeId, 10));
          const CreditorId = financialsSharedService.LiabilitiesSet.CreditorId ? String(financialsSharedService.LiabilitiesSet.CreditorId) : '';
          angular.extend(financialsSharedService.LiabilitiesSet, {
            Type: _.find($scope.liabilityTypes, (obj) => { return parseInt(obj.Value, 10) === parseInt(financialsSharedService.LiabilitiesSet.TypeId, 10); }),
            CreditorId,
            LoanStartDate: new Date(financialsSharedService.LiabilitiesSet.LoanStartDate),
            InterestOnlyEndDate: new Date(financialsSharedService.LiabilitiesSet.InterestOnlyEndDate),
          });

          // Get clients of particular liability
          $scope.LiabilityClientGet(financialsSharedService.LiabilitiesSet.Id, familyId, angular.copy(financialsSharedService.LiabilitiesSet.Borrowers));

          // Open the edit modal
          $scope.LiabilitiesModal('edit');
        });
      },
      LiabilityClientGet(liabilityId, familyId, borrowers) {
        if (!familyId) return;
        loanScenarioService.LiabilityClientGet(familyId, $scope.loanAppId, null).then((respond) => {
          financialsSharedService.liabilityOwnersListSelected = borrowers || [];
          const tmp = [];
          const temp = {
            Percentage: 0,
            BorrowerID: 0,
            FirstName: 'All',
            LastName: 'Clients',
            PreferedName: 'Joint',
            ticked: true,
            disabled: true,
          };
          if (financialsSharedService.liabilityOwnersListSelected.length < 1 || (financialsSharedService.liabilityOwnersListSelected.length === respond.data.length)) {
            respond.data && Object.keys(respond.data).forEach((x) => {
              const value = respond.data[x];
              value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
              tmp.push(value);
            });
            if (tmp.length === 1) {
              tmp[0].ticked = true;
            } else {
              tmp.unshift(temp);
            }
            financialsSharedService.liabilityOwnersList = tmp;
          } else {
            temp.ticked = false;
            // Set default selected from edit
            // @TODO : Edit should match a borrowers array
            if (_.size(borrowers) <= _.size(respond.data)) {
              respond.data && Object.keys(respond.data).forEach((x) => {
                const value = respond.data[x];
                value.ticked = (!!_.find(borrowers, (o) => { return o.BorrowerID === value.BorrowerID; }));
                value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
                tmp.push(value);
              });
            } else {
              respond.data && Object.keys(respond.data).forEach((x) => {
                const value = respond.data[x];
                value.ticked = value.BorrowerID === -1;
                value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
                tmp.push(value);
              });
            }
            if (respond.data.length > 1) {
              tmp.unshift(temp);
            }

            financialsSharedService.liabilityOwnersList = tmp;
          }
        });
      },
      LiabilityTypeGet() {
        loanScenarioService.LiabilityTypeGet().then((response) => {
          _.map(response.data, (o) => {
            const cardRegExp = new RegExp('Card - ', 'i');
            const loanRegExp = new RegExp('Loan - ', 'i');
            const otherRegExp = new RegExp('Other - ', 'i');

            if (o.Name.match(cardRegExp)) {
              o.Group = 'Cards';
              o.Name = o.Name.replace('Card - ', '');
            }

            if (o.Name.match(loanRegExp)) {
              o.Group = 'Loans';
              o.Name = o.Name.replace('Loan - ', '');
            }

            if (o.Name.match(otherRegExp)) {
              o.Group = 'Others';
              o.Name = o.Name.replace('Other - ', '');
            }

            return o;
          });
          $scope.liabilityTypes = response.data;
        });
      },
      resetOwnersSelected() {
        financialsSharedService.liabilityOwnersListSelected = _.filter(financialsSharedService.liabilityOwnersList, obj => obj.ticked);
      },
      untickNonJoint() {
        financialsSharedService.liabilityOwnersList = _.map(financialsSharedService.liabilityOwnersList, (obj) => {
          if (obj.BorrowerID !== 0) {
            obj.ticked = false;
          }
          return obj;
        });
      },
      untickJoint() {
        financialsSharedService.liabilityOwnersList = _.map(financialsSharedService.liabilityOwnersList, (obj) => {
          if (obj.BorrowerID === 0) {
            obj.ticked = false;
          }
          return obj;
        });
      },
      onStudentNoOwnershipSwal() {
        SweetAlert.swal({
          title: 'Ooops',
          text: 'Student Loan should have at least 1 ownership',
          type: 'warning',
          showConfirmButton: true,
        });
      },
      onStudentJointSwal() {
        SweetAlert.swal({
          title: 'Ooops',
          text: 'Student Loan cannot have Joint ownership',
          type: 'warning',
          showConfirmButton: true,
        });
      },
      nzStudentLoanChecker(isStudent) {
        return uiService.isCountry('New Zealand') &&
          financialsSharedService.LiabilitiesSet &&
          financialsSharedService.LiabilitiesSet.Type &&
          (isStudent ? financialsSharedService.LiabilitiesSet.Type.Name === 'Student Loan' : financialsSharedService.LiabilitiesSet.Type.Name !== 'Student Loan');
      },
      onItemSelect(data) {
        if (data.BorrowerID === 0 && data.ticked && $scope.nzStudentLoanChecker(true)) {
          $scope.onStudentJointSwal();
          financialsSharedService.liabilityTickOwnersList(data, false);
        } else if (data.BorrowerID === 0 && data.ticked && (!uiService.isCountry('New Zealand') || $scope.nzStudentLoanChecker(false))) {
          $scope.untickNonJoint();
        } else if (data.BorrowerID !== 0 && data.ticked) {
          $scope.untickJoint();

          if ($scope.nzStudentLoanChecker(true)) {
            // count how many owners are selected
            if (financialsSharedService.liabilityCountOwnersSelected() === 2) {
              financialsSharedService.liabilityTickOwnersList(data, false, true);
            }
          }
        } else if (data.BorrowerID !== 0 && !data.ticked) {
          if ($scope.nzStudentLoanChecker(true)) {
            // count how many owners are selected
            if (financialsSharedService.liabilityCountOwnersSelected() === 0) {
              $scope.onStudentNoOwnershipSwal();
              financialsSharedService.liabilityTickOwnersList(data, true);
            }
          }
        }

        $scope.resetOwnersSelected();

        if ($scope.nzStudentLoanChecker(true)) {
          financialsSharedService.checkStudentRepayment();
        }
      },
      onSelectAll() {
        const findJoint = _.find(financialsSharedService.liabilityOwnersList, (obj) => {
          return obj.BorrowerID === 0;
        });
        // need to check if single owner
        if (!_.isEmpty(findJoint)) {
          financialsSharedService.liabilityOwnersList = _.map(financialsSharedService.liabilityOwnersList, (obj) => {
            obj.ticked = obj.BorrowerID === 0;
            return obj;
          });
        } else {
          for (let i = 0; i < financialsSharedService.liabilityOwnersList.length; i++) {
            if (financialsSharedService.liabilityOwnersList[i].BorrowerID > -1) {
              financialsSharedService.liabilityOwnersList[i].ticked = false;
            } else {
              financialsSharedService.liabilityOwnersList[i].ticked = true;
            }
          }
        }
      },
      LenderListGet(lenderId) {
        const lenderPlace = 1;
        contactService.lenderListGet(lenderId, lenderPlace).then((response) => {
          $scope.listLender = response.data;
        });
      },
      resetLiabilityModel(type, famId) {
        let familyId = famId;
        if (type !== 'edit')
          familyId = $scope.familyId;

        financialsSharedService.LiabilitiesSet = $scope.getNewLiabilityModel(familyId);
      },
      liabilitiesInit(isFull) {
        $scope.listLiabilities = [];

        if (isFull) {
          $scope.liabiliTypeTemplates = financialsSharedService.liabilityTypeTemplate();
          financialsSharedService.getLiabilityType($scope);
          $scope.LenderListGet();
        }

        $scope.resetLiabilityModel();
        $scope.LiabilityInfoGet(isFull);
        $scope.LiabilityClientGet(undefined, $scope.financialsSharedService.selectedFamily.FamilyId);
      },
      changeLiability() {
        financeService.changeLiabilityType($scope);
      },
      addLiability() {
        const joinFamilyObj = _.find(financialsSharedService.liabilityOwnersList, (obj) => {
          return obj.BorrowerID === 0;
        });

        if (joinFamilyObj) {
          if (joinFamilyObj.ticked) {
            financialsSharedService.liabilityOwnersListSelected = _.map(financialsSharedService.liabilityOwnersList, (obj) => {
              if (obj.BorrowerID !== 0) {
                obj.ticked = true;
                return obj;
              }
            });
          }
        } else {
          financialsSharedService.liabilityOwnersListSelected = _.filter(financialsSharedService.liabilityOwnersList, (obj) => {
            return obj.ticked === true;
          });
        }
        financialsSharedService.LiabilitiesSet.Borrowers = [];
        financialsSharedService.liabilityOwnersList.forEach((data) => {
          if (data.ticked && data.BorrowerID !== 0) {
            financialsSharedService.LiabilitiesSet.Borrowers.push({
              Percentage: 0,
              BorrowerID: data.BorrowerID,
              IsEntity: data.IsEntity,
            });
          }
        });

        financialsSharedService.LiabilitiesSet.TypeId = financialsSharedService.LiabilitiesSet.Type.Value;
        financialsSharedService.LiabilitiesSet.Type = financialsSharedService.LiabilitiesSet.Type.Name;
        // action taken : change the familyIds of the model
        angular.extend(financialsSharedService.LiabilitiesSet, {
          FamilyId: $scope.financialsSharedService.selectedFamily.FamilyId.toString(),
        });

        loanScenarioService.LiabilityDetailsSet(financialsSharedService.LiabilitiesSet).then(() => {
          $scope.liabilitiesInit(false);
          $scope.financialsSharedService.ExpenseInfoGet();

          loanAppSharedData.REFRESH_FUNDING_REQUIRED_SECTION = { status: true };
        });
      },
      LiabilitiesModal(type) {
        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/loanApplication/clientAssess/modal/liabilities.add.html',
          controller($uibModalInstance, modalType) {
            $scope.Title = (modalType === 'new' ? 'Add' : 'Edit');

            $scope.cancel = () => {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.saveBreakdown = () => {
              $scope.addLiability();
              $scope.cancel();
              $scope.displayLiabilitiesForm = false;
            };
          },
          size: 'md',
          scope: $scope,
          resolve: {
            modalType() {
              return type;
            },
          },
          backdrop: 'static',
          keyboard: false,
        });

        modalInstance.result.then(() => {
          $scope.resetLiabilityModel('edit', $scope.financialsSharedService.selectedFamily.FamilyId);
        }, () => {
          $scope.resetLiabilityModel('edit', $scope.financialsSharedService.selectedFamily.FamilyId);
        });
      },
      selectLiability(familyId, liabilityId) {
        $scope.LiabilityDetailsGet(familyId, liabilityId);
      },
      selectLiabilityTemp(liability) {
        angular.extend(financialsSharedService.LiabilitiesSet, liability);
        financialsSharedService.LiabilitiesSet.Type = _.filter($scope.liabilityTypes, (o) => { return o.Desc === financialsSharedService.LiabilitiesSet.Type; })[0];
        $scope.LiabilitiesModal('edit');
      },
      LiabilityInfoDeleteTemp(liability) {
        _.remove($scope.listLiabilities[0].overview.FinancialInfo, (o) => {
          return _.isEqual(o, liability);
        });
      },
      cardType() {
        loanScenarioService.subLiabilityTypeGet(0).then((response) => {
          $scope.subLiabilityTypeList = response.data;
        });
      },
      LiabilitiesSet: {
        LiabilitySubTypeID: -1,
      },
      clearSearchCreditor() {
        $timeout(() => {
          $scope.showCreditorResults = false;
          $scope.listLenderSearch = [];
        }, 800);
      },
      searchCreditorMethod() {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          $scope.listLenderSearch = _.filter($scope.listLender, (lender) => {
            const sourceString = _.lowerCase(lender.ProviderName);
            const checkString = _.lowerCase(financialsSharedService.LiabilitiesSet.CreditorName);

            return financialsSharedService.LiabilitiesSet.CreditorName ? (sourceString.indexOf(checkString) !== -1) : false;
          });
        }, 800);

        angular.extend(financialsSharedService.LiabilitiesSet, {
          CreditorId: 0,
        });
      },
      pickedCreditorLookup(lender) {
        angular.extend(financialsSharedService.LiabilitiesSet, {
          CreditorId: lender.ProviderId,
          CreditorName: lender.ProviderName,
        });
      },
      getGuaranteeingLoans(isRefresh = true) {
        loanAppSharedData.getLoanAppPaymentAbilities($scope.loanAppId, isRefresh).then((response) => {
          if (!response || !response.data) return;

          $scope.GuaranteeingLoanList = _.filter(response.data, (obj) => {
            return obj.ClientID !== 0;
          });

          _.forEach($scope.GuaranteeingLoanList, (o) => {
            o.Background = $scope.getBGcolor(o.ClientID);
            o.checkGuaranteeingLoan = o.Details.length > 0;
          });
        });
      },
      saveGuaranteeingLoan(applicant) {
        applicant.Details = applicant.GuaranteeingLoan;
        if (applicant.Details.length > 0 && !applicant.checkGuaranteeingLoan) {
          applicant.Details = '';
          applicant.GuaranteeingLoan = '';
        }

        const data = {
          loanApplicationId: $scope.loanAppId,
          clientEntityID: applicant.ClientID,
          details: applicant.Details,
          GuaranteeingLoan: applicant.GuaranteeingLoan,
          isClient: true,
        };

        loanApplicationServices.setApplicantClientEntityDetail(data).then(() => {
          $rootScope.$broadcast('REFRESH_CLIENT_ENTITY_DETAIL', { module: 'employment' });
        });
      },
      displayLiabilitiesFormCallback() {
        $scope.liabilityLoading = true;
        $timeout(() => {
          $scope.displayLiabilitiesForm = true;
          $scope.liabilityLoading = false;
          $scope.resetLiabilityModel();
          $scope.tooltipDisplay = true;
          if (!$scope.hasLiability) {
            $scope.LiabilitiesModal('new');
            $scope.displayLiabilitiesForm = false;
          }
          $timeout(() => {
            $scope.tooltipDisplay = false;
          }, 6500);
        }, 2000);
      },

    });

    $scope.getGuaranteeingLoans(false);
    $scope.cardType();

    $rootScope.$on('REFRESH_CLIENT_ENTITY_DETAIL', (e, args) => {
      if (args.module === 'liabilities')
        $scope.getGuaranteeingLoans();
    });

    const liabilitiesValidation = () => {
      const filterLiability = _.find($scope.financialsSharedService.listLiabilities, (obj) => {
        return obj.FamilyId === parseInt($scope.financialsSharedService.selectedFamily.FamilyId, 10);
      }) || { overview: { FinancialInfo: [] } };
      $scope.hasLiability = filterLiability.overview.FinancialInfo.length > 0;
    };

    // Watch for changes in selected family
    $scope.$watch('financialsSharedService.selectedFamily.FamilyId', (nv) => {
      $scope.LiabilityClientGet(undefined, nv);
      financialsSharedService.updateLiability(nv);
      liabilitiesValidation();
    });

    $scope.$watch('financialsSharedService.listLiabilities', () => {
      liabilitiesValidation();
    });

    // caller
    $scope.liabilitiesInit(true);

    $scope.$watch('loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS', (nv) => {
      if (nv && _.size(nv) > 0 && nv.RefreshLiability) {
        $scope.getGuaranteeingLoans();
        $scope.liabilitiesInit(true);
        nv.RefreshLiability = false;
      }
    });
  });
