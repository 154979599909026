import moment from 'moment';

export function expectedCommissionsQueryBuilderForMyCRM(query) {
  return query && {
    AdviserID: query.adviserId,
    AdviserName: query.adviserName,
    AdviserEmail: query.adviserEmail,
    ClientIDs: query.clientId && query.clientId.toString(),
    ClientName: query.clientName,
    Entities: query.borrowerEntity,
    ProviderID: parseInt(query.lenderId, 10),
    SettlementDate: query.settlementDate && moment(query.settlementDate).format('YYYY-MM-DDThh:mm:ss'),
    LoanAmount: parseInt(query.loanAmount, 10),
    ReferenceNumber: query.referenceNumber,
    Notes: query.notes,
  };
}

export function commissionReportBuilderForUI(report) {
  return {
    key: report.Key,
    size: report.Size,
    lastModified: report.LastModified,
  };
}


export function loansOneOffCommissionsBuilderForUI(commission) {
  return {
    id: commission.ExpectationID,
    date: commission.DateExpected ? moment(commission.DateExpected).toDate() : null,
    typeId: commission.TypeId,
    loanStructureID: commission.LoanStructureID,
    expectedAmount: commission.ExpectedCommission,
    paidAmount: commission.AmountPaid,
    isPaid: commission.Paid,
    paidDate: commission.DatePaid ? moment(commission.DatePaid).toDate() : null,
    rate: commission.CommissionRatio,
    rateAmount: commission.Amount,
    isActualPayment: commission.IsActualPayment,
    settlementDate: commission.settlementDate,
    isRatio: commission.IsRatio,
    productName: commission.ProductName,
    modifiable: commission.CanModify,
  };
}

export function oneOffCommissionRatesBuilderForUI(commObj) {
  return {
    commissionTypeID: commObj.CommissionTypeID,
    numberOfDays: commObj.NumberOfDays,
    commissionRate: commObj.CommissionRate,
    isPercentage: commObj.IsPercentage,
  };
}

export function expectedCommissionsSummaryBuilderForUI(summary) {
  return {
    amount: summary.TotalAmount || 0,
    percent: summary.TotalPercent,
    type: summary.TotalType,
  };
}

export function loansExpectedCommissionsRecurringBuilderForUI(commission) {
  return {
    id: commission.RecurringID,
    productId: commission.LoanStructureID ? commission.LoanStructureID : 0,
    frequencyId: commission.FrequencyID ? commission.FrequencyID : 0,
    expectedAmount: commission.ExpectedAmount,
    amount: commission.ActualPaidAmount,
    isActive: commission.IsStillRecurring,
    rate: commission.CommissionRatio * 100,
    rateAmount: commission.LoanAmount,
    dateStart: commission.DateStart ? moment(commission.DateStart).toDate() : null,
    dateCreated: commission.DateCreated,
  };
}

export function commissionLoanStructureBuilderForUI(commissionLoanStructure) {
  return {
    expectationID: commissionLoanStructure.ExpectationID,
    loanID: commissionLoanStructure.LoanID,
    loanStructureID: commissionLoanStructure.LoanStructureID,
    loanAmount: commissionLoanStructure.LoanAmount,
    familyID: commissionLoanStructure.FamilyID,
    clientName: commissionLoanStructure.ClientName,
    providerID: commissionLoanStructure.ProviderID,
    providerName: commissionLoanStructure.ProviderName,
    productName: commissionLoanStructure.ProductName,
    adviserName: commissionLoanStructure.AdviserName,
    expectedCommission: commissionLoanStructure.ExpectedCommission,
    typeName: commissionLoanStructure.TypeName,
    dateExpected: commissionLoanStructure.DateExpected,
    datePaid: commissionLoanStructure.DatePaid,
  };
}
