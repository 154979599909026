import angular from 'angular';
import _ from 'lodash';
import { COMMON_LENDER } from 'Common/constants/commonLenderIds';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

const loanToolsTabMapper = {
  'lender-information': 2,
  'lender-rates': 3,
  'maximum-lvr': 4,
};

angular.module('app').controller('LoanToolsCtrl',
  function LoanToolsCtrl($q,
    $scope,
    $state,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanToolsService,
    dashboardService,
    $filter,
    $element,
    $window,
    toaster,
    SweetAlert,
    configService,
    contactService,
    loanProfilerService,
    $rootScope,
    $localStorage,
    DEMO_VIDEO,
    corporateService,
    uiService,
    lenderRatesService) {
    $scope.uiService = uiService;
    const convertRateType = (rateType) => {
      if (!rateType) return;

      return rateType === 'Variable' ? 'Variable' : `Fixed|${rateType.charAt(0)}`;
    };
    $scope.redirectToProductFinder = (lenderRate, lenderRateObject, rateType) => {
      const lenderIdTempArray = [];
      lenderIdTempArray.push(lenderRateObject.LenderID);

      lenderRatesService.setLenderRateInfoForProductFinder(
        { ...lenderRate,
          lenderIdArray: lenderIdTempArray,
          lenderName: lenderRateObject.LenderName,
          year: rateType,
          rateType: convertRateType(rateType) }
      );
      uiService.redirectToLoanProfiler('productFinder');
    };
    if ($stateParams.activeTab) {
      $scope.active = loanToolsTabMapper[$stateParams.activeTab];
    }

    // Open Model For Demo Video
    $scope.openModalForDemoVideo = (size) => {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId() {
            return DEMO_VIDEO.LENDER_INFORATION.Url;
          },
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    $scope.selectTab = (e, activeTab) => {
      e.preventDefault();
      $state.go('app.loanTools', { activeTab }, { notify: false });
    };

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.refreshIdentificationGrid = false;
    $scope.screenSize = $window.innerWidth;
    const viewport = () => {
      let e = $window;
      let a = 'inner';
      if (!('innerWidth' in $window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = () => {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch($scope.getWindowWidth, (newValue) => {
      $scope.screenSize = newValue.w;
    }, true);


    //
    $scope.selectedIndex = 0;
    $scope.selectedTabXs = '1';
    $scope.lenderDetailsInfo = [];
    $scope.lenderToolsAndDownloadsDocs = [];

    const refreshIdentificationGridBroadcastHandler = $rootScope.$on('refreshIdentificationGrid', (event, args) => {
      $scope.getLoanProviderDocuments(args);
    });
    $scope.$on('$destroy', refreshIdentificationGridBroadcastHandler);


    // Model
    angular.extend($scope, {
      filterForResourcePersonObject: {
        statesList: [],
        selectedState: '',
      },
      brokerBasicInfo: [],
    });

    // Methods
    angular.extend($scope, {
      getStatesList() {
        const defer = $q.defer();
        // Note: doesn't need corporate access to use this api
        corporateService.reportingStatesGet().then((response) => {
          const tempStateList = response.data;
          const toReturnList = [];
          let toLoop = [];
          if (!_.isNull(tempStateList) && !_.isEmpty(tempStateList)) {
            _.forEach(tempStateList, (object) => {
              toLoop = _.split(object.ReportingStateName, '/', 2);
              for (let i = 0; i < toLoop.length; i++) {
                toReturnList.push({ state: toLoop[i], stateId: object.ReportingStateId });
              }
            });
            return defer.resolve(toReturnList);
          }
        });
        return defer.promise;
      },
      getBrokerBasicInfo() {
        const defer = $q.defer();
        dashboardService.getBrokerBasicInfo().then((response) => {
          const brokerInfo = response.data;
          return defer.resolve(brokerInfo);
        });

        return defer.promise;
      },
    });

    function getLendersList() {
      loanToolsService.getLendersList()
        .then((response) => {
          $scope.lenderList = response.data;
          for (let i = 0; i < $scope.lenderList.length; i++) {
            // Banks that need adjustments in names
            if ($scope.lenderList[i].ShortName.indexOf('P&N') > -1) {
              $scope.lenderList[i].ShortName = 'P&N Bank';
            }

            if ($scope.lenderList[i].ShortName.indexOf('Vow Home Loans') > -1) {
              $scope.lenderList[i].ShortName = 'Vow Home Loans';
            }
          }

          if (!$scope.selectedLender) {
            $scope.selectedLender = $scope.lenderList[0];
          }
          $scope.selectedLenderProviderID = `${$scope.selectedLender.ProviderID}`;
          $scope.getLenderContactDetail($scope.selectedLender);
          $scope.getLoanProviderKeyRelationships($scope.selectedLender, true);
          $scope.getLoanProviderDocuments($scope.selectedLender);
        }, () => {
        });
    }


    $scope.openDoc = (uri) => {
      const a = uri;
      $window.open(a);
    };

    $scope.falseDetailsMode = () => {
      $scope.detailsMode = false;
    };

    $scope.getLenderContactDetail = (lender) => {
      if (!lender.displayedLenderDetails) {
        lender.displayedLenderDetails = {};
        loanToolsService.getLenderContactDetail(lender.ProviderID)
          .then((response) => {
            lender.displayedLenderDetails = response.data;
            if (lender.ProviderID === COMMON_LENDER.ASB || lender.ProviderID === COMMON_LENDER.WESTPAC) {
              if (response.data.length && typeof response.data[0] === 'object') {
                const details = [];
                _.forEach(response.data[0].Details.split('<br>'), (data) => {
                  const dataHolder = data.trim();
                  if (!dataHolder.includes('Phone -') && !dataHolder.includes('Fax -') && !dataHolder.includes('Web -') && !dataHolder.includes('Email')) {
                    details.push(dataHolder);
                  }
                });
                lender.displayedLenderOtherDetails = details;
              }
            }
          }, () => {
          });
      }
    };

    $scope.getLoanProviderKeyRelationships = (lender, forceCall) => {
      if (!lender.LoanProviderKeyRelationships || forceCall) {
        lender.LoanProviderKeyRelationships = [];
        loanToolsService.getLoanProviderKeyRelationships(lender.ProviderID)
          .then((response) => {
            lender.LoanProviderKeyRelationships = response.data;
            _.forEach(lender.LoanProviderKeyRelationships, (object) => {
              if (_.isUndefined(object.Region)) {
                object.Region = 'No State';
              }
            });
          }, () => {
          });
      }
    };
    $scope.stateDropdownChanged = (selectedState) => {
      if (!$scope.selectedLender || !$scope.selectedLender.LoanProviderKeyRelationships) return;
      $scope.selectedLenderLoanProviderKeyRelationships = $scope.selectedLender.LoanProviderKeyRelationships.filter(object => object.Region === selectedState.state);
    };
    $scope.selectElementChanged = (ProviderID) => {
      $scope.selectedLender = _.find($scope.lenderList, item => item.ProviderID === parseInt(ProviderID, 10));
      if ($scope.selectedLender) {
        $scope.changeSelectedLender($scope.lenderList.indexOf($scope.selectedLender), $scope.selectedLender);
      }
    };

    $scope.changeSelectedLender = (index, lender) => {
      $scope.selectedLender = lender;
      $scope.selectedIndex = index;
      $scope.getLenderContactDetail(lender);
      $scope.getLoanProviderKeyRelationships(lender, false);
      $scope.getLoanProviderDocuments(lender);
    };

    // Key Contact

    $scope.editKeyContact = (loadProviderKeyContact) => {
      $scope.selectedKeyContact = loadProviderKeyContact;
      $scope.openAddKeyModal('modalWidthAuto');
      $scope.selectedKeyContact = {};
    };
    $scope.deleteKeyContact = (loadProviderKeyContact) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          loanToolsService.deleteKeyContact(loadProviderKeyContact.KeyRelationshipID).then(() => {
            $timeout(() => {
              $scope.fullInit();
              SweetAlert.swal('Success', 'Relationship has been successfully deleted.', 'success');
            }, 100);
          });
        }
      });
    };

    // Documents
    $scope.deleteDoc = (docId) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          loanToolsService.LoanProviderDocumentsDelete(docId).then(() => {
            $timeout(() => {
              SweetAlert.swal('Success', 'Relationship has been successfully deleted.', 'success');
              $scope.getLoanProviderDocuments($scope.selectedLender);
            }, 100);
          });
        }
      });
    };
    $scope.loadLoanProviderDocuments = (lender) => {
      lender.LoanProviderKeyDocs = [];
      loanToolsService.getLoanProviderDocuments(lender.ProviderID)
        .then((response) => {
          lender.LoanProviderKeyDocs = response.data;

          $scope.lenderToolsAndDownloadsDocs = lender.LoanProviderKeyDocs;

          for (let i = 0; i < $scope.lenderToolsAndDownloadsDocs.length; i++) {
            if ($scope.lenderToolsAndDownloadsDocs[i].IsPrivateDocument === true) {
              const docUri = `data:application/pdf;base64,${$scope.lenderToolsAndDownloadsDocs[i].Documents}`;
              $scope.lenderToolsAndDownloadsDocs[i].docDownloadUri = docUri;
            } else {
              const docUri = $scope.lenderToolsAndDownloadsDocs[i].DocumentKey;
              $scope.lenderToolsAndDownloadsDocs[i].docDownloadUri = docUri;
            }
          }
        }, () => {
        });
    };
    $scope.getLoanProviderDocuments = (lender) => {
      $scope.lenderToolsAndDownloadsDocs = [];
      if (!lender.LoanProviderKeyDocs) {
        $scope.loadLoanProviderDocuments(lender);
      } else {
        $scope.loadLoanProviderDocuments(lender);
      }
    };

    function getImportantDocs() {
      loanToolsService.getImportantDocs()
        .then((response) => {
          $scope.importantDocs = response && response.map((data) => {
            data.fileExtension = data.documentKey ? data.documentKey.split('.').pop() : '';
            return data;
          });
        }, () => {
        });
    }

    // Add/Edit Key Modal
    $scope.openAddKeyModal = (size) => {
      $uibModal.open({
        templateUrl: '/assets/views/loanTools/partials/add_key_modal.html',
        resolve: {
          lenderList() {
            return $scope.lenderList;
          },
          selectedLender() {
            return $scope.selectedLender;
          },
          selectedKeyContact() {
            return $scope.selectedKeyContact;
          },
        },
        controller: 'AddKeyModalCtrl',
        size,
      });
    };

    // document
    $scope.uploadFile = () => {


    };

    // img
    $scope.errImg = (lender) => {
      const indexOfResult = parseInt(lender['0'].name, 10);
      $scope.lenderList[indexOfResult].noImg = true;
    };

    $scope.errImgDetail = (lender) => {
      const indexOfResult = parseInt(lender['0'].name, 10);
      $scope.lenderDetailsInfo[indexOfResult].noImg = true;
    };

    $scope.errImgRates = (lender) => {
      const indexOfResult = parseInt(lender['0'].name, 10);
      $scope.lenderRateArr[indexOfResult].noImg = true;
    };
    $scope.errImgMax = (lender) => {
      const indexOfResult = parseInt(lender['0'].name, 10);
      $scope.lenderMaxLVRList[indexOfResult].noImg = true;
    };
    $scope.errImgRatesXsTbl = (lender) => {
      const indexOfResult = parseInt(lender['0'].name, 10);
      $scope.rateBlockArr.variable[indexOfResult].noImg = true;
    };
    // Document Modal


    $scope.interfaceWrapper = {};
    $scope.interfaceWrapper.interface = {};
    $scope.hideDocumentProgress = false;
    $scope.documentProgressVisibility = (visibility) => {
      $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
    };

    function initDroplet() {
      $scope.interfaceWrapper.interface.allowedExtensions(['pdf', 'docx', 'doc', 'odt', 'xls', 'xlsx', 'ods', 'odp', 'ppt', 'pptx', 'jpg', 'jpeg', 'png']);
      $scope.interfaceWrapper.interface.setRequestUrl(`${configService.resource}/contacts/DocumentUpload`);
      $scope.interfaceWrapper.interface.setRequestHeaders({ Authorization: configService.token });
      $scope.interfaceWrapper.interface.defineHTTPSuccess([/2.{2}/]);
      $scope.interfaceWrapper.interface.useArray(false);
      $scope.dropletMode = true;
    }

    $scope.switchToDetail = function (index, providerID) {
      $scope.lenderDetailsInfo = $scope.lenderList.filter(item => providerID === item.ProviderID);


      if ($scope.lenderDetailsInfo.length > 0) {
        $scope.detailsMode = true;
        $scope.changeSelectedLender(index, $scope.lenderDetailsInfo[0]);

        $scope.getStatesList().then((response) => {
          if (!uiService.isCountry('Australia')) {
            $scope.selectedLenderLoanProviderKeyRelationships = $scope.selectedLender.LoanProviderKeyRelationships;
            return;
          }
          if (!response || !response.length) return;

          $scope.filterForResourcePersonObject.statesList = response;
          $scope.filterForResourcePersonObject.selectedState = _.find($scope.filterForResourcePersonObject.statesList, { stateId: $scope.brokerBasicInfo.ReportingStateId }) || $scope.filterForResourcePersonObject.statesList[0];
          $scope.stateDropdownChanged($scope.filterForResourcePersonObject.selectedState);
        });
      }

      $timeout(initDroplet, 500);
    };

    $scope.$on('$dropletFileAdded', () => {
      if (!$scope.interfaceWrapper.interface.isUploading()) {
        $scope.documentProgressVisibility(1);
        $scope.interfaceWrapper.interface.uploadFiles();
      }
    });


    $scope.$on('$dropletSuccess', (event, response, files) => {
      const documentObj = response[0];
      $scope.docName = documentObj.Name;
      $scope.docID = documentObj.DocumentId;

      $scope.uploadCount = files.length;
      $scope.success = true;

      $scope.documentThumbnail = {};
      contactService.documentThumbnailGet({
        documentID: documentObj.DocumentId,
        startPage: 1,
        pageNumber: 1,
        thumbWidth: 300,
      }).then((respond) => {
        const thumbObj = respond.data[0];
        if (thumbObj.ContentType && thumbObj.ThumbnailContent) {
          if (thumbObj.ContentType.indexOf('png') > -1 || thumbObj.ContentType.indexOf('PNG') > -1 ||
            thumbObj.ContentType.indexOf('jpg') > -1 || thumbObj.ContentType.indexOf('JPG') > -1 ||
            thumbObj.ContentType.indexOf('jpeg') > -1 || thumbObj.ContentType.indexOf('JPEG') > -1)
            $scope.documentThumbnail = `data:${thumbObj.ContentType};base64,${thumbObj.ThumbnailContent}`;
          else
            $scope.documentThumbnail = false;
          $scope.dropletMode = false;
        } else {
          $scope.documentThumbnail = false;
        }
        $scope.openAddDocModal('modalWidthDocumentUpload');
      });


      // Hides the upload progressbar
      $scope.documentProgressVisibility(0);

      $timeout(() => {
        $scope.success = false;
      }, 5000);
    });

    $scope.$on('$dropletError', () => {
      $scope.error = true;
      toaster.pop('error', 'Something went wrong', 'Please try again');
      $timeout(() => {
        $scope.error = false;
      }, 5000);
    });
    $scope.openAddDocModal = (size) => {
      $uibModal.open({
        templateUrl: '/assets/views/loanTools/partials/add_documents_modal.html',
        resolve: {
          lenderList() {
            return $scope.lenderList;
          },
          selectedLender() {
            return $scope.selectedLender;
          },
          selectedKeyContact() {
            return $scope.selectedKeyContact;
          },
          documentThumbnail() {
            return $scope.documentThumbnail;
          },
          docID() {
            return $scope.docID;
          },
          docName() {
            return $scope.docName;
          },
        },
        controller: 'AddDocumentsModalCtrl',
        size,
      });
    };

    $scope.searchContact = (searchContactText) => {
      $scope.searchText = searchContactText;
      if ($scope.tableParams && $scope.tableParams.reload) {
        $scope.tableParams.reload();
      }
    };

    $scope.downloadFile = (value) => {
      const eventTimeout = $timeout(() => {
        const splitData = value.DocumentKey.split('/');
        const slicedData = splitData.slice(-1)[0];
        const splitDataTwo = slicedData.split('.');
        let fileType = splitDataTwo.slice(-1)[0];

        if (fileType === 'pdf') {
          fileType = 'application/pdf';
        } else if (fileType === 'jpg' || fileType === 'jpeg') {
          fileType = 'image/jpeg';
        } else if (fileType === 'png') {
          fileType = 'image/png';
        } else if (fileType === 'xls') {
          fileType = 'application/vnd.ms-excel';
        } else if (fileType === 'docx') {
          fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        } else {
          fileType = `application/${fileType}`;
        }

        // Convert the Base64 string back to text.
        const byteString = $window.atob(value.Documents);
        const element = $window.document.createElement('a');

        // Convert that text into a byte array.
        const elementAB = new ArrayBuffer(byteString.length);
        const elementUA = new Uint8Array(elementAB);
        for (let i = 0; i < byteString.length; i++) {
          elementUA[i] = byteString.charCodeAt(i);
        }

        const blob = new $window.Blob([elementUA], { type: fileType });

        element.target = fileType === 'pdf' ? '_blank' : 'self';
        element.rel = 'noopener noreferrer';
        element.href = $window.URL.createObjectURL(blob);
        element.download = value.Title;
        element.click();
        $timeout.cancel(eventTimeout);
      });
    };

    let currentLenderId = -1;
    let lenderRateArrPosition = -1;

    // Rates Tab
    function insertData(array, loanRate) {
      array.push({
        CurrentRate: loanRate.CurrentRate,
        LenderID: loanRate.LenderID,
        LenderName: loanRate.LenderName,
        LoanTerm: loanRate.LoanTerm,
        LoanTermText: loanRate.LoanTermText,
        PrevRate: loanRate.PrevRate,
      });
    }

    function getLenderRates() {
      $scope.lenderRatesToBind = [];
      $scope.lenderRates = [];
      loanToolsService.LoanRateGet()
        .then((response) => {
          $scope.lenderRateArr = [];
          const loanRates = response.data;
          $scope.rateBlockArr = {
            variable: [],
            year1: [],
            year2: [],
            year3: [],
            year4: [],
            year5: [],
            year6: [],
            year7: [],
            year8: [],
            year9: [],
            year10: [],
            year15: [],
            year30: [],
          };

          for (let k = 0; k < loanRates.length; k++) {
            if (parseInt(currentLenderId, 10) === -1 || parseInt(currentLenderId, 10) !== parseInt(loanRates[k].LenderID, 10)) {
              currentLenderId = loanRates[k].LenderID;
              lenderRateArrPosition++;
              const lenderRateItem = {};

              lenderRateItem.LoanTerm = loanRates[k].LoanTerm;
              lenderRateItem.PrevRate = loanRates[k].PrevRate;
              lenderRateItem.CurrentRate = loanRates[k].CurrentRate;

              const itemToInsert = {};
              itemToInsert[loanRates[k].LoanTermText] = lenderRateItem;
              itemToInsert.LenderName = loanRates[k].LenderName;
              itemToInsert.LenderID = loanRates[k].LenderID;
              itemToInsert.CountryCode = loanRates[k].CountryCode;
              $scope.lenderRateArr[lenderRateArrPosition] = itemToInsert;
            } else {
              const lenderRateItem = {};

              lenderRateItem.LoanTerm = loanRates[k].LoanTerm;
              lenderRateItem.PrevRate = loanRates[k].PrevRate;
              lenderRateItem.CurrentRate = loanRates[k].CurrentRate;

              $scope.lenderRateArr[lenderRateArrPosition][loanRates[k].LoanTermText] = lenderRateItem;
            }


            switch (loanRates[k].LoanTermText) {
            case 'Variable':
              insertData($scope.rateBlockArr.variable, loanRates[k]);
              break;
            case '1 year':
              insertData($scope.rateBlockArr.year1, loanRates[k]);
              break;
            case '2 years':
              insertData($scope.rateBlockArr.year2, loanRates[k]);
              break;
            case '3 years':
              insertData($scope.rateBlockArr.year3, loanRates[k]);
              break;
            case '4 years':
              insertData($scope.rateBlockArr.year4, loanRates[k]);
              break;
            case '5 years':
              insertData($scope.rateBlockArr.year5, loanRates[k]);
              break;
            case '6 years':
              insertData($scope.rateBlockArr.year6, loanRates[k]);
              break;
            case '7 years':
              insertData($scope.rateBlockArr.year7, loanRates[k]);
              break;
            case '8 years':
              insertData($scope.rateBlockArr.year8, loanRates[k]);
              break;
            case '9 years':
              insertData($scope.rateBlockArr.year9, loanRates[k]);
              break;
            case '10 years':
              insertData($scope.rateBlockArr.year10, loanRates[k]);
              break;
            case '15 years':
              insertData($scope.rateBlockArr.year15, loanRates[k]);
              break;
            case '30 years':
              insertData($scope.rateBlockArr.year30, loanRates[k]);
              break;
            default:
            }
          }

          $scope.selectedRate = '0';
        }, () => {
        });
    }


    $scope.displayAllFilters = () => {
      $scope.rateTableFilter = {
        variableColumnShown: true,
        y1ColumnShown: true,
        y2ColumnShown: true,
        y3ColumnShown: true,
        y4ColumnShown: true,
        y5ColumnShown: true,
        y6ColumnShown: true,
        y7ColumnShown: true,
        y8ColumnShown: true,
        y9ColumnShown: true,
        y10ColumnShown: true,
        y15ColumnShown: true,
        y30ColumnShown: true,
      };
    };
    $scope.displayAllFilters();

    // Get User Information

    function getUserInfo() {
      contactService.getUserInfo().then((response) => {
        $scope.userInfo = response.data;
      });
    }

    // Interest Rates End

    // Maximum LVR Start

    $scope.maximumLVRFilter = {
      loanAmount: 500000,
      repaymentType: 'P&I',
      columnOrder: '',
    };

    $scope.goSortLVR = (column) => {
      $scope.maximumLVRFilter.columnOrder = column;
    };

    function getMaximumLVRList() {
      let repaymentType = null;
      if ($scope.maximumLVRFilter.repaymentType === 'P&I') {
        repaymentType = 'P%26I';
      } else {
        repaymentType = $scope.maximumLVRFilter.repaymentType;
      }
      loanToolsService.getMaximumLVRList($scope.maximumLVRFilter.loanAmount, repaymentType).then((maximumLVRList) => {
        $scope.lenderMaxLVRList = maximumLVRList;
      });
    }

    function getMaximumRepaymentType() {
      loanProfilerService.getServiceabilityFiltersParamters().then((response) => {
        $scope.filtersParamtersList = response.data;
      });
    }

    $scope.$watch('maximumLVRFilter.loanAmount', (newValue) => {
      if (newValue) {
        $scope.filterTime = $timeout(() => {
          getMaximumLVRList();
        }, 500);
      }
    }, true);

    $scope.$watch('maximumLVRFilter.repaymentType', (newValue) => {
      if (newValue) {
        $scope.filterTime = $timeout(() => {
          getMaximumLVRList();
        }, 500);
      }
    }, true);

    $scope.maxLVRDisplayAllFilter = () => {
      $scope.maxLVRTableFilter = {
        bridgingShown: true,
        commercialShown: true,
        constructionShown: true,
        equityFinanceShown: true,
        equityShown: true,
        investmentShown: true,
        locShown: true,
        ownerOccupiedShown: true,
        reverseShown: true,
      };
      $scope.maxLVRTableFilterNZ = {
        leaseholdShown: true,
        holidayHomeShown: true,
        terracedHousingShown: true,
        ruralLifestyleG10Shown: true,
        ruralLifestyleBlocks10haShown: true,
        standardResidentialNonResidentShown: true,
        residentialLeaseholdPropertyShown: true,
        companyShareShown: true,
        lifestyleBlockL10haShown: true,
        constructionShown: true,
        standardApartmentsL50Shown: true,
        lifestyleBlockL15haVSShown: true,
        lifestyleBlockL15haVNSShown: true,
        vacantLandServicedShown: true,
        vacantLandUnservicedShown: true,
        partialBuildContractShown: true,
        pensionerflatsretirementvillageShown: true,
        propertyDevelopmentShown: true,
        standardResidentialShown: true,
        luxuryShown: true,
        nonStandardApartmentShown: true,
        standardApartmentShown: true,
      };
    };
    $scope.maxLVRDisplayAllFilter();

    // Maximum LVR End

    function init() {
      getLendersList();
      getMaximumLVRList();
      getMaximumRepaymentType();
      getImportantDocs();
      getLenderRates();
      getUserInfo();
      $scope.getBrokerBasicInfo().then((response) => {
        $scope.brokerBasicInfo = response;
      });
      $scope.maximumLVRShown = (configService.feature && configService.feature.maxlvr) || 0;
      $scope.countryType = COUNTRY_TYPE;
    }

    $scope.fullInit = () => {
      init();
    };

    const initialize = $rootScope.$on('callInit', () => {
      init();
    });
    $scope.$on('$destroy', initialize);

    init();
  });
