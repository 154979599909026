export const EMAIL_TYPES = {
  GMAIL: 'gmail',
  CRM_EMAIL: 'email',
  SMS: 'phone',
};

export const EMAIL_CTYPES = {
  GMAIL: 'Gmail',
  CRM_EMAIL: 'Email',
  SMS: 'Phone',
};
