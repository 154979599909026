import {
  getModalName,
} from 'Common/utilities/subscription';

class CreditCheckSubscriptionService {
  constructor(
    $uibModal
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  openCreditCheckSubModal(familyId, creditCheckSubInfo, orderedById = familyId) {
    const validArgs = familyId && creditCheckSubInfo;
    if (!validArgs) return;
    const props = {
      familyId,
      subscriptionData: creditCheckSubInfo,
      orderedById,
    };
    const { subscriptionProductId } = creditCheckSubInfo;
    const componentName = getModalName(subscriptionProductId);
    if (!componentName) return;

    const modalInstance = this.$uibModal.open({
      template: `<${componentName}
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId" 
                  subscription-data="vm.props.subscriptionData" 
                  ordered-by-id="vm.props.orderedById">
                </${componentName}>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'mc-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });

    return modalInstance.result;
  }
}

export default CreditCheckSubscriptionService;
