import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import _ from 'lodash';

class MailTemplatesSelectionCtrl {
  constructor(
    $scope,
    contactService,
    templateService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.contactService = contactService;
    this.templateService = templateService;
    this.mailTemplateListing = [];
    this.blankTemplate = { UserTemplateID: 0, UserTemplateName: 'Blank' };

    this.templatesStatus = {
      mailTemplatesLoaded: false,
      defaultTemplateLoaded: false,
      onAllTemplatesLoaded: () => {
        this.onAllTemplatesLoaded();
      },
    };
  }

  $onInit() {
    this.getDefaultTemplate();
    this.getMailTemplates();
    if (this.onInitialized) {
      this.api = {};
      this.api.refresh = () => {
        this.getMailTemplates(true);
      };
      this.onInitialized({ api: this.api });
    }
  }

  getDefaultTemplate() {
    if (this.module) {
      this.templateService.getModuleDefaultTemplate(this.module, SEND_METHOD_TYPES.MAIL).then((response) => {
        this.defaultTemplate = response.data;
        this.templatesStatus.defaultTemplateLoaded = true;
        this.onAllTemplatesLoaded();
      });
    } else {
      this.defaultTemplateLoaded = true;
      this.onAllTemplatesLoaded();
    }
  }

  getMailTemplates(isRefresh) {
    this.contactService.mailTemplatesGet().then((response) => {
      if (response.data) {
        this.blankCategory = _.remove(response.data, folder => this.templateService.isBlankFolderOrTemplate(folder))[0];
        this.blankTemplate = this.templateService.getBlankTemplateIn(this.blankCategory);
        this.mailTemplateListing = this.templateService.filterTemplatesByUserType(response.data);
      }
      this.refreshSelection = false;
      this.templatesStatus.mailTemplatesLoaded = true;
      if (!isRefresh) {
        this.onAllTemplatesLoaded();
      }
    });
  }

  onAllTemplatesLoaded() {
    if (this.templatesStatus.defaultTemplateLoaded && this.templatesStatus.mailTemplatesLoaded) {
      const defaultTemplate = this.defaultTemplate ? _.map(this.mailTemplateListing, folder => folder.DocRtfSMSTemplateList).flatten()
        .find(template => template.UserTemplateID === this.defaultTemplate.UserTemplateID) : null;

      if (this.onTemplateSelected) {
        this.onTemplateSelected({ template: defaultTemplate || this.blankTemplate });
      }
    }
  }
}

export default MailTemplatesSelectionCtrl;
