import {
  getIncludedBenefits,
  cascadeBenefitInclusion,
} from 'Common/utilities/insurance';
import { quoteDataBuilderForAPI } from 'Common/mappers/insurance';
import { toastError } from 'Common/utilities/alert';
import { isFunction } from 'lodash';

class InsuranceQuoteModsWidgetCtrl {
  constructor(
    insuranceQuoteService
  ) {
    'ngInject';

    this.insuranceQuoteService = insuranceQuoteService;
    this.insuredPersons = this.getInsuredPersons();
    this.isPolicyIncluded = true;
  }

  getInsuredPersons() {
    return this.quoteData.insuredPersons.map((person) => {
      const benefits = getIncludedBenefits(person);
      return { ...person, isIncluded: true, benefits };
    });
  }

  calculateQuote() {
    const validation = this.getValidation();
    if (!validation.isValid) {
      toastError(validation);
      return;
    }

    this.calculatingQuote = true;
    this.getQuote();
  }

  getValidation() {
    const hasValidInsuredPerson = !!this.insuredPersons.find((insured) => {
      const isIncludedAdult = insured.isIncluded && !insured.isChild;
      const hasIncludedBenefits = !!insured.benefits.find(benefit => benefit.isIncluded);
      return isIncludedAdult && hasIncludedBenefits;
    });
    if (!hasValidInsuredPerson) {
      return {
        data: { Message: 'Please select at least one adult with at least one benefit.' },
        isValid: false,
      };
    }

    return { isValid: true };
  }

  getQuoteDataForCalc() {
    const insuredPersons = this.insuredPersons.filter(person => person.isIncluded)
      .map((person) => {
        const benefits = person.benefits.filter(benefit => benefit.isIncluded);
        return { ...person, benefits };
      });
    return quoteDataBuilderForAPI({ ...this.quoteData, insuredPersons });
  }

  getQuote() {
    if (isFunction(this.onQuoteUpdating)) {
      this.onQuoteUpdating();
    }

    const quoteData = this.getQuoteDataForCalc();
    this.insuranceQuoteService
      .insuranceQuoteMonsterGet(quoteData)
      .then((response) => {
        if (isFunction(this.onQuoteUpdated)) {
          this.onQuoteUpdated({ updateQuoteData: quoteData, updatedGraphData: response.data });
        }
      })
      .catch(toastError)
      .finally(() => {
        this.calculatingQuote = false;
      });
  }

  onPersonInclusionChanged(personId) {
    const person = this.insuredPersons.find(insured => insured.clientId === personId);
    if (!person) return;

    person.benefits = person.benefits.map((benefit) => {
      return { ...benefit, isIncluded: person.isIncluded };
    });
  }

  onBenefitInclusionChanged(personId, benefitId) {
    const person = this.insuredPersons.find(insured => insured.clientId === personId);
    if (!person) return;

    const updatedBenefit = person.benefits && person.benefits.find(benefit => benefit.benefitId === benefitId);
    if (!updatedBenefit) return;

    person.benefits = cascadeBenefitInclusion(updatedBenefit, person);

    if (!person.isIncluded) {
      person.isIncluded = updatedBenefit.isIncluded;
    }
  }
}

export default InsuranceQuoteModsWidgetCtrl;
