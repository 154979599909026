class LoanAppPaymentAbilityService {
  constructor(loanApplicationServices, $q) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.$q = $q;
  }

  getPaymentAbility(loanAppId, refreshData) {
    const isDifferentLoanApp = parseInt(loanAppId, 10) !== parseInt(this.currentLoanAppId, 10);
    if (isDifferentLoanApp) {
      this.clearPaymentAbility();
      this.currentLoanAppId = loanAppId;
    }

    const isFetchingPaymentAbility = this.getPaymentAbilityPromise;
    if (isFetchingPaymentAbility) return this.getPaymentAbilityPromise;

    const isEmptyPaymentAbility = !this.paymentAbility || !this.paymentAbility.length;
    if (isEmptyPaymentAbility || refreshData) {
      this.getPaymentAbilityPromise = this.loanApplicationServices.getApplicantClientEntityDetail(loanAppId).then((response) => {
        this.paymentAbility = response && response.data;
        this.getPaymentAbilityPromise = null;
        return response;
      });
      return this.getPaymentAbilityPromise;
    }

    return this.fetchedPaymentAbility();
  }

  fetchedPaymentAbility() {
    const deferred = this.$q.defer();
    if (this.paymentAbility) {
      deferred.resolve({ data: this.paymentAbility });
    } else {
      deferred.reject({ data: `Something went wrong. Service didn't fetch the Payment Ability.` });
    }
    return deferred.promise;
  }

  clearPaymentAbility() {
    this.paymentAbility = null;
    this.getPaymentAbilityPromise = null;
    this.currentLoanAppId = null;
  }
}

export default LoanAppPaymentAbilityService;
