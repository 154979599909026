import './style.scss';
import template from './template.html';
import NewLoanApplicationV2Ctrl from './createNewLoanApplicationCtrlV2';

export default {
  template,
  controller: NewLoanApplicationV2Ctrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
  },
};
