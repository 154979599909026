import angular from 'angular';

angular.module('app').controller('AddUserPermissionCtrl',
  function AddUserPermissionCtrl(
    $scope,
    $stateParams,
    userService,
    configService,
    $timeout,
    toaster,
    $uibModalInstance,
    $uibModal,
    accountService
  ) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.addUserInterface = {};
    $scope.uploadCount = 0;
    $scope.success = false;
    $scope.error = false;
    $scope.familyId = $stateParams.familyId;
    $scope.hideDocumentProgress = false;
    $scope.addUserDocumentProgressVisibility = function (visibility) {
      $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
    };
    $scope.openAvatarModal = function (obj) {
      $scope.documentDataObject = obj;

      $uibModal.open({
        templateUrl: '/assets/views/user/partials/add_image_modal.html',
        controller: 'MyAccountModalCtrl',
        scope: $scope,
      });
    };

    $scope.$on('$dropletReady', () => {
      if ($scope.addUserInterface) {
        $scope.addUserInterface = accountService.accountDropletReadyOptions($scope.addUserInterface);
      }
    });

    $scope.$on('$dropletFileAdded', () => {
      if (!$scope.addUserInterface.isUploading()) {
        $scope.addUserDocumentProgressVisibility(1);
        $scope.addUserInterface.uploadFiles();
      }
    });

    $scope.$on('$dropletSuccess', () => {
      if ($scope.addUserInterface.isReady) {
        $scope.addUserInterface.uploadFiles();
      }
    });

    // Listen for when the files have been successfully uploaded.
    $scope.$on('$dropletSuccess', (event, response, files) => {
      const documentObj = response[0];
      $scope.uploadCount = files.length;
      $scope.success = true;
      if ($scope.infoType === 'personal') {
        $scope.openAvatarModal({
          Title: documentObj.Name,
          DocumentID: documentObj.DocumentId,
          FamilyID: $scope.familyId,
        });
      }
      $scope.AvatarID = documentObj.DocumentId;
      // Hides the upload progressbar
      $scope.addUserDocumentProgressVisibility(0);

      $timeout(() => {
        $scope.success = false;
      }, 5000);
    });

    // Listen for when the files have failed to upload.
    $scope.$on('$dropletError', () => {
      $scope.error = true;
    });

    $scope.addUserInfo = function () {
      const data =
                [{
                  AdviserFamilyId: $scope.AdviserFamilyId,
                  AvatarDocumentId: $scope.AvatarID,
                  FirstName: $scope.addUser.FirstName,
                  LastName: $scope.addUser.LastName,
                  UserName: $scope.addUser.UserName,
                }];
      userService.UserPermissionSet(data).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          toaster.pop('success', 'User Updated', 'User info has been updated');
        }
      });
    };
  });
