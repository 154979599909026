export function checkLabelsEqual(left, right) {
  if (!left && !right) return true;
  if ((!left && right) || (left && !right)) return false;
  const leftLabelsId = left.map(label => label.id).sort().join(',');
  const rightLabelsId = right.map(label => label.id).sort().join(',');
  return leftLabelsId === rightLabelsId;
}

export default class InsurancePipelineCardCtrl {
  constructor($sce, insurancePipelineService, utilitiesService) {
    'ngInject';

    this.$sce = $sce;
    this.insurancePipelineService = insurancePipelineService;
    this.utilitiesService = utilitiesService;
  }

  $onInit() {
    this.providerLogo = `assets/images/insurers/bordered/${this.providerId}.png`;
    const brokerAvatar = this.broker.avatar && this.$sce.trustAsResourceUrl(this.broker.avatar);
    this.broker = { ...this.broker, avatar: brokerAvatar };
  }

  $onChanges(changes) {
    const { labelsSelection, labels, statusId } = changes;
    if (labelsSelection || labels) {
      this.updateCardLabelsColor();
    }
    const labelsChanged = labels && !labels.isFirstChange() && !checkLabelsEqual(labels.previousValue, labels.currentValue);
    const cardHasChanges = labelsChanged || (statusId && !statusId.isFirstChange());
    if (cardHasChanges) {
      this.updateLastDateUpatedDisplay();
    }
    this.initSelectedLabelIds();
  }

  updateCardLabelsColor() {
    if (!this.labelsSelection) return;
    this.labels = this.labels && this.labels.reduce((accum, cardLabel) => {
      const labelInSelection = this.labelsSelection.find(label => label.id === cardLabel.id);
      if (!labelInSelection) return accum;

      return [...accum, {
        ...cardLabel,
        color: labelInSelection.color,
        name: labelInSelection.name,
      }];
    }, []);
  }

  updateLastDateUpatedDisplay() {
    this.lastDateUpdated = new Date();
  }

  initSelectedLabelIds() {
    this.selectedLabelIds = this.labels && this.labels.map(label => label.id);
  }

  closeMenu() {
    this.isMenuOpen = false;
    this.doForceToMainMenu = true;
  }

  onDropdownToggled() {
    this.doForceToMainMenu = !this.isMenuOpen;
  }
}
