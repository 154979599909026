import angular from 'angular';
import _ from 'lodash';
import { FINANCIAL_OBJECTS } from 'Common/constants/financialAssets';
import { parseIntValue } from 'Common/utilities/parse';
import { checkIfInvestment } from 'Common/utilities/security';

angular.module('app').controller('FinancialAssetsCtrl',
  function FinancialAssetsCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    optionsService,
    SweetAlert,
    dashboardService,
    uiService,
    financeService,
    loanAppSharedData,
    loanApplicantAssetInfoService,
    inputService,
    currentUserService,
    contactModelService
  ) {
    $scope.uiService = uiService;
    const defaultReaValuesAU = financeService.getDefaultREAAU();
    const defaultMortgageValuesAU = financeService.getDefaultMortgageAU();
    $scope.formSubmittedValidation = false;
    $scope.listRepaymentFrequency = [];
    const lenderPlace = 0;

    const clearVehicleTypeChangedInterval = () => {
      if ($scope.vehicleTypeChangedInterval) {
        $interval.cancel($scope.vehicleTypeChangedInterval);
        $scope.vehicleTypeChangedInterval = null;
      }
    };
    const isValidForChangingDescriptionVehicle = () => {
      const isFieldsNotEmpty = $scope.AssetsSet.AssetSubtypeId && $scope.AssetsSet.Year && $scope.AssetsSet.Make;

      return isFieldsNotEmpty && ($scope.AssetsSet.Description === $scope.previousInputValues || !$scope.AssetsSet.Description);
    };
    const isValidForChangingDescriptionBank = () => {
      return $scope.AssetsSet.Institution && ($scope.AssetsSet.Description === $scope.previousInputValues || !$scope.AssetsSet.Description);
    };
    const populateDescription = (type, isValidForChange) => {
      $scope.AssetsSet.Description = isValidForChange ? inputService.getConcatValue(type) : $scope.AssetsSet.Description;
    };

    $scope.vehicleTypeChanged = (subTypeId) => {
      $scope.vehicleTypeChangedInterval = $interval(() => {
        if ($scope.subAssetTypes) {
          clearVehicleTypeChangedInterval();
          $scope.previousInputValues = inputService.getConcatValue('contactAssetVehicle');

          const subType = $scope.subAssetTypes.length && $scope.subAssetTypes.find(object => object.Value === subTypeId);
          if (subType && subType.Name) {
            inputService.concatInputService('contactAssetVehicle', 3, `(${subType.Name})`);
          }
          if (!isValidForChangingDescriptionVehicle()) return;
          populateDescription('contactAssetVehicle', isValidForChangingDescriptionVehicle());
        }
      }, 1000);
    };
    $scope.vehicleYearChanged = (year) => {
      $scope.previousInputValues = inputService.getConcatValue('contactAssetVehicle');
      inputService.concatInputService('contactAssetVehicle', 1, year);
      if (!isValidForChangingDescriptionVehicle()) return;

      populateDescription('contactAssetVehicle', isValidForChangingDescriptionVehicle());
    };
    $scope.vehicleMakeChanged = (make) => {
      $scope.previousInputValues = inputService.getConcatValue('contactAssetVehicle');
      inputService.concatInputService('contactAssetVehicle', 2, make);

      if (!isValidForChangingDescriptionVehicle()) return;
      populateDescription('contactAssetVehicle', isValidForChangingDescriptionVehicle());
    };
    $scope.bankInstitutionChanged = (institution) => {
      $scope.previousInputValues = inputService.getConcatValue('contactAssetBank');
      inputService.concatInputService('contactAssetBank', 2, institution);

      if (!isValidForChangingDescriptionBank()) return;
      populateDescription('contactAssetBank', isValidForChangingDescriptionBank());
    };
    optionsService.FinancialFrequency().then((response) => {
      _.map(response.data, (o) => {
        o.Desc = o.Name;
        o.Value = parseInt(o.Value, 10);

        return o;
      });

      $scope.listRepaymentFrequency = response.data;
    });

    const setDefaultValuesMethodContacts = (fieldName) => {
      switch (fieldName) {
      case 'all':
        $scope.AssetsSet.ZoningId = defaultReaValuesAU.zoning;
        $scope.AssetsSet.PrimaryPurposeId = defaultReaValuesAU.primaryPurposeId;
        break;
      case 'propertyTypeId':
        $scope.AssetsSet.PropertyTypeId = defaultReaValuesAU.propertyTypeId;
        break;
      case 'primaryPurposeId':
        $scope.AssetsSet.PrimaryPurposeId = defaultReaValuesAU.primaryPurposeId;
        break;
      case 'zoning':
        $scope.AssetsSet.ZoningId = defaultReaValuesAU.zoning;
        break;
      default:
        break;
      }
    };

    /**
     * To know that this Asset is from contact page, not loan application
     * $scope.contactAsset = true
     * */
    $scope.contactAsset = true;

    $scope.Settings = {
      collapseList: true,
      displayIncomeForm: false,
    };

    $scope.$watch('selectedContacts', (newValue) => {
      if (newValue === 'financials') {
        $scope.assetsInit(true);
      }
    });

    // Models
    angular.extend($scope, {
      familyId: $stateParams.familyId,
      listAssets: [],
      listLender: [],
      subAssetTypes: [],
      assetTypes: [],
      displayAssetsFormWrapper: { displayAssetsForm: false }, // scope was changing with bool property
      modalType: false,
      localLang: {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: "<div class='buttonLabel'>All Clients</div>", // default-label is deprecated and replaced with this.
      },
      ownersList: [],
      ownersListSelected: [],
      ownersListSelectedArray: [],
      ListPropertyType: [],
      AssetTotalAmount: 0,
    });


    // Methods
    angular.extend($scope, {
      getNewRentalIncomeModel(index) {
        return {
          IsEvidenceOfTenancy: true,
          Id: 0,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          Type: '',
          Description: '',
          Amount: null,
          CurrencyCode: ($rootScope.crmCurrencyCode), // temp values
          Category: '',
          CurrencyCodeId: ($rootScope.crmCountry === 'Australia' ? '8' : '106'), // temp values
          Borrowers: [],
          FrequencyID: null,
          FrequencyName: '',
          Provider: '',
          IncomeSubTypeId: 1,
          RentalVerificationID: '',
          index,
        };
      },
      getNewMortgageLiabilityModel(index) {
        return {
          IsRefinance: false,
          Ownership: [],
          AccountName: '',
          AccountNumber: '',
          BSB: '',
          Limit: null,
          CreditorId: null,
          CreditorNameOther: null,
          InterestTaxDeductible: null,
          RepaymentFrequencyId: 4,
          Repayments: null,
          InterestRate: null,
          IsAddToExpenses: true,
          MortgagePriorityId: defaultMortgageValuesAU.mortgagePriorityId || null,
          Expense: {},
          Id: 0,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          Type: '',
          Description: '',
          Amount: null,
          CurrencyCode: ($rootScope.crmCurrencyCode), // temp values
          Category: '',
          CurrencyCodeId: ($rootScope.crmCountry === 'Australia' ? '8' : '106'), // temp values
          Borrowers: [],
          FrequencyID: null,
          FrequencyName: '',
          Provider: '',
          LoanTerm: defaultMortgageValuesAU.loanTerm || null,
          principalInterest: defaultMortgageValuesAU.principalInterest || 2,
          index,
        };
      },
      getNewAssetModel() {
        return {
          ValueBasisId: '1',
          Value: null,
          AccountName: '',
          AccountNumber: '',
          BSB: '',
          Make: '',
          Year: 0,
          PrimaryPurposeId: null,
          PropertyType: '',
          Zoning: '',
          IsRentalIncome: false,
          IsExistingMortgages: false,
          Address: {
            IsMailing: true,
            AddressID: '0',
            Type: '',
            TypeId: '',
            formatted_address: '',
            AddressValuationExtendedDetailsId: 0,
          },
          RentalIncome: [],
          MortgageLiability: [],
          Id: 0,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          Type: '',
          TypeId: 0,
          Description: '',
          Amount: null,
          CurrencyCode: ($rootScope.crmCurrencyCode),
          XECountryCode: ($rootScope.crmCountry === 'Australia' ? 'au' : 'nz'), // temp values
          Category: '',
          CurrencyCodeId: ($rootScope.crmCountry === 'Australia' ? '8' : '106'), // temp values
          Borrowers: [],
          Provider: '',
          ShowCurrencyConversion: false,
        };
      },
      getPropertyType(zoningId) {
        optionsService.getPropertyType(zoningId).then((response) => {
          $scope.ListPropertyType = response.data;
        });
      },
      AssetInfoGet() {
        contactService.assetInfoGet($scope.familyId).then((response) => {
          const asset = response.data;
          $scope.AssetTotalAmount = 0;
          $scope.NoAssets = (!(_.size(response.data) > 0));
          _.forEach(asset.FinancialInfo, (financialInfo) => {
            _.map(financialInfo.Borrowers, (borrower) => {
              borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
              return borrower;
            });
          });
          $scope.AssetTotalAmount += asset.TotalAmount;
          $scope.financials.assetTotal = $scope.AssetTotalAmount;
          $scope.listAssets = asset;

          if (_.size($scope.listAssets.FinancialInfo) > 0) {
            $scope.Settings.collapseList = false;
          }
        });
      },
      AssetInfoDelete(assetId, isFromREA) {
        SweetAlert.swal({
          title: 'Are you sure?',
          text: 'This record will be removed from your asset list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            contactService.deleteAsset(assetId, $scope.familyId).then(() => {
              $timeout(() => {
                $scope.assetsInit(false);

                if (isFromREA) {
                  $rootScope.$broadcast('REFRESH_INCOME');
                  $rootScope.$broadcast('REFRESH_LIABILITY');
                }
                SweetAlert.swal({
                  title: 'Success',
                  text: 'Asset has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }, 100);
            });
          }
        });
      },
      AssetDetailsGet(familyId, assetIdParam) {
        let assetId = assetIdParam;
        if (_.isUndefined(assetId))
          assetId = 0; // catch set

        contactService.getAssetDetails(familyId, assetId).then((response) => {
          // reset the actual model
          $scope.resetAssetModel('edit', familyId);

          // patch api data to actual model
          angular.extend($scope.AssetsSet, response.data);

          // patch temporary type to actual model
          angular.extend($scope.AssetsSet, {
            Type: _.find($scope.assetTypes, (o) => { return o.Desc === $scope.AssetsSet.Type; }),
            PrimaryPurposeId: $scope.AssetsSet.PrimaryPurposeId ? parseInt($scope.AssetsSet.PrimaryPurposeId, 10) : null,
            ValueBasisId: $scope.AssetsSet.ValueBasisId ? parseInt($scope.AssetsSet.ValueBasisId, 10) : null,
            Amount: $scope.AssetsSet.Value,
            FullAddress: $scope.AssetsSet.Address,
            Year: $scope.AssetsSet.Year ? parseInt($scope.AssetsSet.Year, 10) : null,
            PropertyTypeId: $scope.AssetsSet.PropertyTypeId ? parseInt($scope.AssetsSet.PropertyTypeId, 10) : null,
            PropertyTypeId_original: parseInt($scope.AssetsSet.PropertyTypeId, 10),
            ZoningId: $scope.AssetsSet.ZoningId ? parseInt($scope.AssetsSet.ZoningId, 10) : null,
            AssetSubtypeId: $scope.AssetsSet.AssetSubtypeId ? parseInt($scope.AssetsSet.AssetSubtypeId, 10) : 1,
          });

          _.map($scope.AssetsSet.MortgageLiability, (obj) => {
            obj.MortgagePriorityId = obj.MortgagePriorityId ? parseInt(obj.MortgagePriorityId, 10) : null;
            obj.CreditorId = obj.CreditorId ? String(obj.CreditorId) : '-1';
            return obj;
          });

          _.map($scope.AssetsSet.RentalIncome, (object) => {
            object.IncomeSubTypeId = parseInt(object.IncomeSubTypeId, 10) || null;
            return object;
          });

          // Get clients of particular asset
          $scope.AssetClientGet($scope.AssetsSet.Id, familyId, angular.copy($scope.AssetsSet.Borrowers));
          if (financeService.hasSubType($scope)) {
            $scope.getSubAssetType();
          }

          // Open the edit modal
          $scope.AssetsModal('edit');
          $scope.checkIfInvestment();
        });
      },
      AssetClientGet(assetIdParam, familyId, borrowers) {
        let assetId = assetIdParam;
        $scope.ownersListSelected = [];

        if (_.isUndefined(assetId))
          assetId = 0; // catch set
        contactService.borrowersDetailsGet($scope.familyId, 0).then((respond) => {
          $scope.ownersListSelected = borrowers || [];
          $scope.ownersList = $scope.processBorrowersData(respond, borrowers, $scope.ownersListSelected);
        });
      },
      isOtherTicked(data) {
        let bool = false;
        data.forEach((val) => {
          if (val.BorrowerID > -1 && val.ticked) {
            bool = true;
          }
        });

        return bool;
      },
      onItemSelect(data) {
        let isJointFamily = true;
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ((typeof $scope.ownersList[i].ticked === 'undefined' || !$scope.ownersList[i].ticked) && $scope.ownersList[i].BorrowerID >= 1) {
            isJointFamily = false;
          }
        }

        // will be true if user clicks on joint
        if (data.BorrowerID === 0) {
          isJointFamily = true;
        }
        if (!isJointFamily) {
          $scope.ownersList = _.map($scope.ownersList, (obj) => {
            if (obj.BorrowerID === 0) {
              obj.ticked = false;
            }
            return obj;
          });
        } else {
          const findJoint = _.find($scope.ownersList, (obj) => {
            return obj.BorrowerID === 0;
          });
          // need to check if single owner
          if (!_.isEmpty(findJoint)) {
            $scope.ownersList = _.map($scope.ownersList, (obj) => {
              obj.ticked = obj.BorrowerID === 0;
              return obj;
            });
          }
        }
      },
      onSelectAll() {
        const findJoint = _.find($scope.ownersList, (obj) => {
          return obj.BorrowerID === 0;
        });
        // need to check if single owner
        if (!_.isEmpty(findJoint)) {
          $scope.ownersList = _.map($scope.ownersList, (obj) => {
            obj.ticked = obj.BorrowerID === 0;
            return obj;
          });
        } else {
          for (let i = 0; i < $scope.ownersList.length; i++) {
            if ($scope.ownersList[i].BorrowerID > -1) {
              $scope.ownersList[i].ticked = false;
            } else {
              $scope.ownersList[i].ticked = true;
            }
          }
        }
      },
      LenderListGet(lenderId) {
        loanApplicantAssetInfoService.lenderListGet(lenderId, lenderPlace).then((response) => {
          $scope.listLender = response.data;
        });
      },
      resetAssetModel(type, familyIdParam) {
        let familyId = familyIdParam;
        if (type !== 'edit') {
          familyId = $scope.familyId;
        }

        $scope.AssetsSet = $scope.getNewAssetModel(familyId);
      },
      assetsInit(isFull) {
        $scope.listAssets = [];
        $scope.resetAssetModel();
        $scope.AssetInfoGet(isFull);
        $scope.AssetClientGet(undefined, $scope.familyId);

        if (isFull) {
          $scope.LenderListGet();
          optionsService.getZoningType().then((response) => {
            $scope.ZoningList = parseIntValue(response.data);
            $scope.ZoningList = [{ Name: 'Please select', Value: '' }, ...$scope.ZoningList];
            $scope.AssetsSet.ZoningId = '';

            if (uiService.isCountry('Australia')) {
              setDefaultValuesMethodContacts('zoning');
            }
          });

          $scope.ListPropertyType = [{ AddressPropertyType: 'Please select Zoning first', AddressPropertyTypeID: '' }];
          $scope.AssetsSet.PropertyTypeId = '';

          optionsService.getPrimaryPurposeType().then((response) => {
            $scope.LoanPurposeList = parseIntValue(response.data);
            $scope.LoanPurposeList = [{ Name: 'Please select', Value: '' }, ...$scope.LoanPurposeList];

            if (uiService.isCountry('Australia')) {
              setDefaultValuesMethodContacts('primaryPurposeId');
            }
          });

          optionsService.MortgagePriorityGet().then((response) => {
            $scope.MortgagePriorityList = parseIntValue(response.data);
            $scope.MortgagePriorityList = [{ Name: 'Please select', Value: '' }, ...$scope.MortgagePriorityList];
          });

          optionsService.AssetValueBasisGet().then((response) => {
            $scope.ValueBasisList = parseIntValue(response.data);
            const ValueBasisList = _.first($scope.ValueBasisList);
            $scope.AssetsSet.ValueBasisId = ValueBasisList ? ValueBasisList.Value : '';
          });

          // Get country list first before calling supported currencies
          optionsService.countryListGet().then((response) => {
            $scope.countryListGet = response.data;
          });

          optionsService.AssetTypeGet().then((response) => {
            $scope.assetTypes = financeService.mapAssetType(response.data);
          });

          contactService.lenderListGet(undefined, lenderPlace).then((response) => {
            $scope.CreditorList = response.data;
          });
        }

        $scope.$watch(
          'AssetsSet.ZoningId',
          (nv) => {
            if (nv !== '') {
              optionsService.getPropertyType(nv).then((response) => {
                _.map(response.data, (obj) => {
                  obj.Name = obj.AddressPropertyType;
                  obj.Value = obj.AddressPropertyTypeID;
                  return obj;
                });

                $scope.ListPropertyType = response.data;
                $scope.ListPropertyType.unshift({ Name: 'Please select', Value: '' });
                $scope.AssetsSet.PropertyTypeId = '';

                if (!_.find($scope.ListPropertyType, (o) => {
                  return parseInt(o.AddressPropertyTypeID, 10) === parseInt($scope.AssetsSet.PropertyTypeId_original, 10);
                })) {
                  $scope.AssetsSet.PropertyTypeId = '';
                } else {
                  $scope.AssetsSet.PropertyTypeId = angular.copy($scope.AssetsSet.PropertyTypeId_original);
                  $scope.AssetsSet.PropertyTypeId = $scope.AssetsSet.PropertyTypeId;
                }

                if (nv === defaultReaValuesAU.zoning && uiService.isCountry('Australia') && !$scope.AssetsSet.PropertyTypeId) {
                  setDefaultValuesMethodContacts('propertyTypeId');
                }
              });
            }
          }
        );
      },
      changeAsset() {
        financeService.changeAssetType($scope);
      },
      getSubAssetType() {
        financeService.getSubAssetType($scope);
      },
      addAsset(isModal) {
        // Test data
        $scope.displayAssetsFormWrapper.displayAssetsForm = false;
        const joinFamilyObj = _.find($scope.ownersList, (obj) => {
          return obj.BorrowerID === 0;
        });

        if (joinFamilyObj) {
          if (joinFamilyObj.ticked) {
            $scope.ownersListSelected = _.map($scope.ownersList, (obj) => {
              if (obj.BorrowerID !== 0) {
                obj.ticked = true;
                return obj;
              }
            });
          }
        } else {
          $scope.ownersListSelected = _.filter($scope.ownersList, (obj) => {
            return obj.ticked === true;
          });
        }
        $scope.AssetsSet.Borrowers = [];
        $scope.ownersList.forEach((data) => {
          // data.isEntity = true;
          // $scope.AssetsSet.Borrowers.push(data);
          if (data.ticked && data.BorrowerID !== 0) {
            $scope.AssetsSet.Borrowers.push({
              Percentage: 0,
              BorrowerID: data.BorrowerID,
              IsEntity: data.IsEntity,
            });
          }
        });

        $scope.AssetsSet.TypeId = $scope.AssetsSet.Type.Value;
        $scope.AssetsSet.Type = $scope.AssetsSet.Type.Desc;
        $scope.AssetsSet.Year = parseInt($scope.AssetsSet.Year, 10);
        $scope.AssetsSet.Amount = angular.copy($scope.AssetsSet.Value);

        if ($scope.AssetsSet.RentalIncome && $scope.AssetsSet.RentalIncome.length > 0)
          $scope.AssetsSet.IsRentalIncome = true;
        else
          $scope.AssetsSet.IsRentalIncome = false;

        if ($scope.AssetsSet.MortgageLiability && $scope.AssetsSet.MortgageLiability.length > 0)
          $scope.AssetsSet.IsExistingMortgages = true;
        else
          $scope.AssetsSet.IsExistingMortgages = false;

        // action taken : change the familyIds of the model
        angular.extend($scope.AssetsSet, {
          FamilyId: $scope.familyId.toString(),
        });

        angular.extend($scope.AssetsSet.RentalIncome, {
          FamilyId: $scope.familyId.toString(),
        });

        angular.extend($scope.AssetsSet.MortgageLiability, {
          FamilyId: $scope.familyId.toString(),
        });

        if (isModal && $scope.AssetsSet.FullAddress) {
          const getType = _.find($scope.assetTypes, (o) => { return parseInt(o.Value, 10) === parseInt($scope.AssetsSet.TypeId, 10); });
          if (getType && getType.Group === FINANCIAL_OBJECTS.GROUP_TYPE) {
            const isRemoveIncomeInfo = $scope.AssetsSet.PrimaryPurposeId === FINANCIAL_OBJECTS.PRIMARY_PURPOSE_ID && $scope.AssetsSet.RentalIncome.length && !currentUserService.isNZ;
            if (isRemoveIncomeInfo) {
              $scope.AssetsSet.RentalIncome = null;
            }

            let description = '';

            if ($scope.AssetsSet && $scope.AssetsSet.FullAddress) {
              description = $scope.AssetsSet.FullAddress.formatted_address;
            }
            angular.extend($scope.AssetsSet, {
              Address: {},
              Description: description,
            });

            let copyAssetSetTemp = {};
            copyAssetSetTemp = angular.copy($scope.AssetsSet);

            dashboardService.addressDetailsSet(copyAssetSetTemp.FullAddress).then((response) => {
              const id = response.data;

              copyAssetSetTemp.FullAddress.AddressID = id;
              copyAssetSetTemp.Address = angular.copy(copyAssetSetTemp.FullAddress);

              angular.extend($scope.AssetsSet, copyAssetSetTemp);

              contactService.assetDetailsSet($scope.AssetsSet).then(() => {
                $rootScope.$broadcast('REFRESH_INCOME');
                $rootScope.$broadcast('REFRESH_LIABILITY');
                $scope.assetsInit(false);
                $scope.displayAssetsFormWrapper.displayAssetsForm = false;
              });
            });
          } else {
            contactService.assetDetailsSet($scope.AssetsSet).then(() => {
              $rootScope.$broadcast('REFRESH_INCOME');
              $rootScope.$broadcast('REFRESH_LIABILITY');
              $scope.assetsInit(false);
            });
          }
        } else {
          contactService.assetDetailsSet($scope.AssetsSet).then(() => {
            $rootScope.$broadcast('REFRESH_INCOME');
            $rootScope.$broadcast('REFRESH_LIABILITY');
            $scope.assetsInit(false);
          });
        }
      },
      AssetsModal(type) {
        if (type === 'new') {
          loanApplicantAssetInfoService.updateIds($scope.AssetsSet, $scope.ValueBasisList);
          setDefaultValuesMethodContacts('all');
        }

        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/contacts/client/financial/modal/asset.html',
          controller: 'AssetsModalCtrl',
          size: 'md',
          scope: $scope,
          resolve: {
            modalType() {
              return type;
            },
          },
          backdrop: 'static',
          keyboard: false,
        });

        modalInstance.result.then(() => {
          $scope.resetAssetModel('edit', $scope.familyId);
          $scope.AssetClientGet(undefined, $scope.familyId);
        }, () => {
          $scope.resetAssetModel('edit', $scope.familyId);
          $scope.AssetClientGet(undefined, $scope.familyId);
        });
      },
      selectAsset(familyId, assetId) {
        $scope.AssetDetailsGet(familyId, assetId);
      },
      selectAssetTemp(asset) {
        angular.extend($scope.AssetsSet, asset);
        $scope.AssetsSet.Type = _.filter($scope.assetTypes, (o) => { return o.Desc === $scope.AssetsSet.Type; })[0];
        $scope.AssetsModal('edit');
      },
      AssetInfoDeleteTemp(asset) {
        _.remove($scope.listAssets[0].overview.FinancialInfo, (o) => {
          return _.isEqual(o, asset);
        });
      },
      changeCurrencyCode(code, XECountryCode, CurrencyCodeId) {
        $scope.AssetsSet.CurrencyCode = code;
        $scope.AssetsSet.XECountryCode = XECountryCode;
        $scope.AssetsSet.CurrencyCodeId = CurrencyCodeId;
      },
      checkIfInvestment() {
        $scope.isInvestment = checkIfInvestment($scope.AssetsSet.PrimaryPurposeId);
      },
    });

    // Watcher
    $scope.$watch('listAssets', () => {
      let length = 0;
      if (_.size($scope.listAssets) > 0) {
        _.forEach($scope.listAssets, (o) => {
          length += _.size(o.FinancialInfo);
        });

        $rootScope.$broadcast('updateAsset', { length, TotalAmount: $scope.AssetTotalAmount });
      } else {
        $rootScope.$broadcast('updateAsset', { length: 0, TotalAmount: 0 });
      }
    });

    // Watch for changes in selected family
    $scope.$watch('selectedFamily.FamilyId', (nv) => {
      $scope.AssetClientGet(undefined, nv);
    });

    // initialize again this section because there are changes on application section
    $rootScope.$on('REFRESH_APPLICANT_DEPENDENT_SECTIONS', () => {
      $scope.assetsInit(true);
      $scope.Settings.displayAssetsForm = false;
    });

    $scope.$on('$destroy', () => {
      clearVehicleTypeChangedInterval();
    });
    const init = () => {
      contactModelService.getSubIncomeType()
        .then((subIncomeResponse) => {
          if (!subIncomeResponse) return;

          $scope.incomeSubTypeSample = subIncomeResponse;
        });
    };
    init();
  });
