import angular from 'angular';
import _ from 'lodash';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { NPS_WIDGET_OPTIONS } from 'Common/constants/npsWidgetOptions';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('npsScoreSmall',
  function npsScoreSmallDirective(
    $timeout,
    overviewDashboardService,
    corporateService,
    queryService,
    npsService,
    userService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl: 'assets/views/overviewDashboard/directiveTemplates/npsScoreSmall.html',

      link(scope) {
        scope.npsScoreModel = {};
        scope.tableMode = false;
        scope.chartMode = true;
        scope.customizeMode = false;
        scope.layoutView = 'detail';
        scope.switch = true;
        scope.nps_scores = [];
        scope.currentObj = {};
        scope.objForWidgetOptionSet = {};
        scope.period = 0;
        scope.periods = [
          'All Time',
          'This Year',
          'This Month',
          'Last Month',
          'Last 2 Months',
          'Last 3 Months',
          'Last Year',
        ];
        scope.npsScoreModel.adviserList = [];
        scope.npsScoreModel.selectedAdviserFamilyId = 0;
        const saveForTimePeriod = (widgetSetObj) => {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .catch(displayError);
        };

        let showForOptionObj = {};
        let showForStateOptionObj = {};
        scope.npsShowForList = [
          {
            id: 0,
            name: 'All',
          },
          {
            id: 1,
            name: 'State',
          },
        ];
        scope.getReportingStateLabel = () => {
          let name = 'All';
          if (scope.reportingStateList && scope.reportingStateList.length && scope.npsShowFor > 0) {
            const filtered = scope.reportingStateList.filter((data) => {
              return data.ReportingStateId === scope.reportingState;
            });
            if (filtered.length) {
              name = filtered[0].ReportingStateName || '';
            }
          }
          return name;
        };
        const getNpsScore = () => {
          if (scope.npsScoreModel.userInfo && scope.npsScoreModel.userInfo.AccessType === ACCESS_TYPE.CORPORATE) {
            const reportingStateId = scope.npsShowFor ? scope.reportingState : 0;
            npsService.dashboardScoreGet(reportingStateId).then((response) => {
              scope.switch = false;
              scope.nps_scores.NpsScore = 0;
              if (response.data > 0) {
                scope.nps_scores.NpsScore = response.data.toFixed(0);
              }
            });
          } else {
            const adviserFamilyId = scope.npsScoreModel.selectedAdviserFamilyId || 0;
            npsService.getSurveyResponse(scope.period, 1, 1, 0, adviserFamilyId, true).then((response) => {
              if (response && response.data) {
                scope.nps_scores = response.data;
              }
            });
          }
        };
        const getReportingState = () => {
          corporateService.reportingStatesGet()
            .then((response) => {
              scope.reportingStateList = [];
              if (response.data) {
                scope.reportingStateList = response.data;
              }
            });
        };
        getReportingState();
        scope.changeShowFor = (showForId) => {
          scope.npsShowFor = showForId;
          showForOptionObj.SelectedUerValues = showForId;
          showForOptionObj.Widget_UserID = scope.myParam.Widget_UserID;
          saveForTimePeriod(showForOptionObj);
          getNpsScore();
        };
        scope.changeReportingState = (reportingStateId) => {
          scope.reportingState = reportingStateId;
          showForStateOptionObj.SelectedUerValues = reportingStateId;
          showForStateOptionObj.Widget_UserID = scope.myParam.Widget_UserID;
          saveForTimePeriod(showForStateOptionObj);
          getNpsScore();
        };

        function WidgetOptionGet() {
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
            .then((response) => {
              let tempCounter = 0;
              scope.npsScoreModel.selectionWidget = response.data;
              response.data.forEach((data) => {
                tempCounter++;
                if (data.SelectedUerValues) {
                  switch (parseInt(data.WidgetOptionId, 10)) {
                  case NPS_WIDGET_OPTIONS.ADVISER_SELECTION: {
                    const userSelection = parseInt(data.SelectedUerValues, 10);
                    const temp = _.filter(scope.npsScoreModel.adviserList, (d) => { return d.FamilyId === userSelection; });
                    if (temp.length > 0) {
                      scope.npsScoreModel.selectedAdviserFamilyId = temp[0].FamilyId;
                    }
                    break;
                  }
                  case NPS_WIDGET_OPTIONS.TIME_PERIOD: {
                    const periodIndex = parseInt(data.SelectedUerValues, 10);
                    if (scope.periods[periodIndex]) {
                      scope.period = parseInt(data.SelectedUerValues, 10);
                    }
                    break;
                  }
                  case NPS_WIDGET_OPTIONS.LAYOUT_VIEW: {
                    const defaultValue = data.SelectedUerValues === '' || parseInt(data.SelectedUerValues, 10) === 0 ? 'detail' : data.SelectedUerValues;
                    scope.layoutView = defaultValue;
                    break;
                  }
                  case NPS_WIDGET_OPTIONS.SWITCH_VALUE: {
                    const defaultValue = data.SelectedUerValues === '' ? true : data.SelectedUerValues;
                    scope.switch = defaultValue;
                    break;
                  }
                  case NPS_WIDGET_OPTIONS.SHOW_FOR:
                    showForOptionObj = data;
                    scope.npsShowFor = data.SelectedUerValues ? parseInt(data.SelectedUerValues, 10) : 0;
                    break;
                  case NPS_WIDGET_OPTIONS.SHOW_FOR_STATE:
                    showForStateOptionObj = data;
                    scope.reportingState = parseInt(data.SelectedUerValues, 10) > 0 ? parseInt(data.SelectedUerValues, 10) : 1;
                    break;
                  default:
                    break;
                  }
                }

                if (tempCounter >= response.data.length) {
                  getNpsScore();
                }
              });
              if (response.data.length === 0) {
                getNpsScore();
              }
            }, () => {
            });
        }

        function _init() {
          WidgetOptionGet();
        }

        function getAdvisersList(familyId) {
          corporateService.getAdvisersDropdown(familyId, 1).then((response) => {
            if (response.data.length > 0) {
              scope.npsScoreModel.adviserList = response.data;
              scope.npsScoreModel.selectedAdviserFamilyId = response.data[0].FamilyId;
            }
            _init();
          });
        }

        userService.GetUserInfo().then((response) => {
          if (response.data) {
            scope.npsScoreModel.userInfo = response.data;
            if (scope.npsScoreModel.userInfo.AccessType === 1 || scope.npsScoreModel.userInfo.AccessType === 3 || scope.npsScoreModel.userInfo.AccessType === 4) {
              getAdvisersList(response.data.FamilyId);
            } else {
              scope.npsScoreModel.selectedAdviserFamilyId = response.data.FamilyId;
              _init();
            }
          }
        });

        scope.changeAdviser = function () {
          const temp = _.filter(scope.npsScoreModel.selectionWidget, (data) => { return parseInt(data.WidgetOptionId, 10) === 3; });
          let tempData = {};
          if (temp.length === 0) {
            tempData = {
              WidgetOptionId: '3',
              DisplayValue: 'All',
              OptionName: 'Adviser',
              Widget_OptionValue: '9',
              DB_Value: '0',
              SelectedUerValues: scope.npsScoreModel.selectedAdviserFamilyId,
              SelectedUserDisplayValues: '',
              Widget_UserID: scope.myParam.Widget_UserID,
            };
          } else {
            tempData = temp[0];
            tempData.SelectedUerValues = scope.npsScoreModel.selectedAdviserFamilyId;
            tempData.Widget_UserID = scope.myParam.Widget_UserID;
          }
          saveForTimePeriod(tempData);
          getNpsScore();
        };

        function savePeriod() {
          const temp = _.filter(scope.npsScoreModel.selectionWidget, (data) => { return parseInt(data.WidgetOptionId, 10) === 15; });
          let tempData = {};
          if (temp.length === 0) {
            tempData = {
              WidgetOptionId: '15',
              DisplayValue: 'This Month',
              OptionName: 'Time Period 6',
              Widget_OptionValue: '42',
              DB_Value: '6',
              SelectedUerValues: scope.period,
              SelectedUserDisplayValues: '',
              Widget_UserID: scope.myParam.Widget_UserID,
            };
          } else {
            tempData = temp[0];
            tempData.SelectedUerValues = scope.period;
            tempData.Widget_UserID = scope.myParam.Widget_UserID;
          }
          saveForTimePeriod(tempData);
        }

        scope.showPrevVal = function () {
          scope.period--;
          if (scope.period < 0) {
            scope.period = scope.periods.length - 1;
          } else {
            scope.period--;
          }
          savePeriod();
          getNpsScore();
        };

        scope.showNextVal = function () {
          if (scope.periods.length - 1 === scope.period) {
            scope.period = 0;
          } else {
            scope.period++;
          }
          savePeriod();
          getNpsScore();
        };

        scope.showLayoutView = function (view) {
          scope.layoutView = view;
          const temp = _.filter(scope.npsScoreModel.selectionWidget, (data) => { return parseInt(data.WidgetOptionId, 10) === 18; });
          let tempData = {};
          if (temp.length === 0) {
            tempData = {
              WidgetOptionId: '18',
              DisplayValue: 'Detail',
              OptionName: 'Layout',
              Widget_OptionValue: '54',
              DB_Value: '1',
              SelectedUerValues: view,
              SelectedUserDisplayValues: '',
              Widget_UserID: scope.myParam.Widget_UserID,
            };
          } else {
            tempData = temp[0];
            tempData.SelectedUerValues = view;
            tempData.Widget_UserID = scope.myParam.Widget_UserID;
          }
          saveForTimePeriod(tempData);
        };


        scope.doAction = function (msg) {
          if (msg === 'edit') {
            scope.customizeMode = true;
          } else if (msg === 'notEdit') {
            scope.customizeMode = false;
          }
        };

        scope.changeRanking = function () {
          const temp = _.filter(scope.npsScoreModel.selectionWidget, (data) => { return parseInt(data.WidgetOptionId, 10) === 19; });
          let tempData = {};
          if (temp.length === 0) {
            tempData = {
              WidgetOptionId: '19',
              DisplayValue: 'Yes',
              OptionName: 'Show Ranking',
              Widget_OptionValue: '56',
              DB_Value: '1',
              SelectedUerValues: scope.npsScoreModel.switch,
              SelectedUserDisplayValues: '',
              Widget_UserID: scope.myParam.Widget_UserID,
            };
          } else {
            tempData = temp[0];
            tempData.SelectedUerValues = scope.npsScoreModel.switch;
            tempData.Widget_UserID = scope.myParam.Widget_UserID;
          }
          saveForTimePeriod(tempData);
        };
      },
    };
  });
