/*
How to use
1. put magnify attribute
2. put magnify-is-promised = 'true' for images that requires api to populate src
2. put magnify-is-promised = 'false' for images that is statically looped using ng-repeat or statically inputted
3. wrap your <img> in a relative div

eg. <div class='parent'>
<img src='something.png' magnify magnify-is-promised="false"/>
</div>


NOTE: MAKE SURE ALL YOUR IDs ARE UNIQUE
*/
import angular from 'angular';

angular.module('app').directive('magnify', function magnify(magnifyService) {
  return {
    link(scope, elem, attr) {
      scope.$watchGroup([
        function () { return elem.attr('src'); },
        function () { return attr.magnifyIsPromised === 'false' ? elem[0].offsetHeight : null; },
      ],
      (newValue, oldValue) => {
        scope.attachPreview = function () {
          elem.attr('id', elem.attr('id') + magnifyService.getUniqueId());
          let offSetMultiplier;
          if (attr.size === 'sm') {
            offSetMultiplier = 4;
          } else {
            offSetMultiplier = 0.5;
          }

          const previewDiv = angular.element('<div/>')
            .attr('class', 'magnifier-preview visibility-hidden')
            .attr('id', `${elem.attr('id')}-preview`)
            .attr('style',
              `width:${(elem[0].offsetWidth * offSetMultiplier) + elem[0].offsetWidth}px;` +
              `height:${(elem[0].offsetHeight * offSetMultiplier) + elem[0].offsetHeight}px;` +
              `left:${elem[0].offsetWidth + 10}px;` +
              `z-index: 1000`);

          // You need to wrap the image in a relative div
          elem.parent().append(previewDiv);
          magnifyService.Magnify.attach({
            thumb: `#${elem.attr('id')}`,
            large: newValue[0],
            largeWrapper: previewDiv.attr('id'),
            mode: 'outside',
            zoom: 2,
            zoomable: true,
            onthumbenter() {
              previewDiv.removeClass('visibility-hidden');
            },
            onthumbleave() {
              previewDiv.addClass('visibility-hidden');
            },
          });
        };

        if (attr.magnifyIsPromised === 'true' && (newValue[0] !== oldValue[0])) {
          scope.attachPreview();
        } else if ((attr.magnifyIsPromised === 'false' || !attr.magnifyIsPromised) && (elem[0].offsetWidth > 0 && elem[0].offsetHeight > 0)) {
          scope.attachPreview();
        }
      }
      );
    },
  };
});
