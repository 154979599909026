import angular from 'angular';
import { ENTITY_TYPE } from 'Common/constants/entityType';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular.module('app').controller('EntityDetailsCtrl',
  function EntityDetailsCtrl(
    $scope,
    $timeout,
    toaster,
    corporateService,
    onboardingService,
    contactService,
    dashboardService,
    commonFnService,
    modelDataService,
    dataService,
    onboardingDataService,
    addressModalService,
    broadcastDataService) {
    let adviserOrgId = 0;
    $scope.entityDetails = {};
    $scope.entityDetails.isSameAsPostal = false;
    $scope.entityDetails.isGstRegistered = false;
    $scope.isUserCountryNewZealand = false;
    $scope.phoneMask = null;
    $scope.entityTypeIsCompany = false;
    $scope.adviserOrganisationId = 0;
    $scope.contactAddress = {
      mailingAddress: modelDataService.addressModelData(),
      homeAddress: modelDataService.addressModelData(),
      businessAddress: modelDataService.addressModelData(),
    };

    const getRecruitmentContact = () => {
      onboardingService.getRecruitmentContact(onboardingDataService.getFamilyId(), onboardingDataService.getClientId()).then((response) => {
        if (response && response.data) {
          const data = response.data[0] || [];
          $scope.entityDetails.isGstRegistered = data.GSTRegistered || false;
          $scope.entityDetails.gstNumber = data.IRDGSTNumber ? parseInt(data.IRDGSTNumber, 10) : 0;
        }
      }).then(() => {
        const dataTmp = {
          entityDetails: $scope.entityDetails,
        };
        onboardingDataService.setAllOnboardingDataForPreview(dataTmp);
      });
    };

    const saveAddress = (addressObj, isMailing) => {
      dashboardService.addressDetailsSet(addressObj)
        .then((response) => {
          if (response && response.data) {
            onboardingService.setRecruitmentOrganizationAddress(onboardingDataService.getFamilyId(), adviserOrgId, 0, response.data, isMailing, false, false)
              .then((adviserOrg) => {
                adviserOrgId = adviserOrg;
                $scope.adviserOrgId = adviserOrg;
              });
          }
        });
    };

    $scope.selecthomeAddress = (addressObj, isMailing) => {
      saveAddress(addressObj, isMailing);
    };

    $scope.addressValueOnBlur = (address, isMailing) => {
      const addressObjTmp = modelDataService.addressModelData();
      if (address) {
        addressObjTmp.formatted_address = address;
        saveAddress(addressObjTmp, isMailing);
      }
    };

    $scope.onSameAsPostal = (value) => {
      $scope.entityDetails.isSameAsPostal = value;
      if ($scope.entityDetails.isSameAsPostal) {
        saveAddress($scope.contactAddress.homeAddress, true);
      }
    };

    const getOrgAddress = (orgId) => {
      corporateService.organizationAddressGet(orgId).then((response) => {
        if (response && response.data) {
          const addressTmp = commonFnService.setAddressTypes(response.data);
          if (addressTmp) {
            $scope.contactAddress.homeAddress = { ...addressTmp.home };
            $scope.contactAddress.mailingAddress = { ...addressTmp.mailing };
          }
          if ($scope.contactAddress.homeAddress.formatted_address === $scope.contactAddress.mailingAddress.formatted_address) {
            $scope.entityDetails.isSameAsPostal = true;
          }
        }
      }).then(() => {
        $scope.entityDetails.address = $scope.contactAddress.homeAddress;
      });
    };

    const getOnboardingAdviserOrganisationDetails = (isOnload) => {
      onboardingDataService.getAdviserOrgDetails().then((response) => {
        if (response) {
          const data = response;
          $scope.entityDetails.entityType = data.EntityType || '';
          $scope.entityDetails.entityName = data.AdviserOrganisationName || '';
          $scope.entityDetails.acn = data.ACN || '';
          $scope.entityDetails.abn = data.ABN || '';
          $scope.entityDetails.businessPhone = data.Phone || '';
          $scope.entityDetails.fax = data.Fax || '';
          $scope.entityDetails.AdviserOrganisationId = data.AdviserOrganisationId || 0;

          if (typeof data.AdviserOrganisationId !== 'undefined') {
            adviserOrgId = data.AdviserOrganisationId;
            $scope.adviserOrganisationId = data.AdviserOrganisationId;
            if (adviserOrgId && isOnload) {
              getOrgAddress(adviserOrgId);
            }
          }
        }
      });
    };

    const checkAdviserOrg = () => {
      if (parseInt(adviserOrgId, 10) === 0) {
        getOnboardingAdviserOrganisationDetails(false);
      }
    };

    $scope.saveEntity = (type, formName, inputName) => {
      if (formName && inputName) {
        if (formName[inputName].$invalid) {
          angular.element(`[name="${inputName}"]`).addClass('invalid');
          return false;
        }
        angular.element(`[name="${inputName}"]`).removeClass('invalid');
      }

      switch (type) {
      case 'EntityName': {
        const entityAdviserOrgData = {
          MainOrganisationId: ($scope.entityDetails.entityName && $scope.entityDetails.entityType === ENTITY_TYPE.PARTNERSHIP ? adviserOrgId : 0),
          AdviserOrganizationId: adviserOrgId,
          LeadAdviser_FamilyId: onboardingDataService.getFamilyId(),
          AdviserOrganizationName: $scope.entityDetails.entityName,
          Phone: $scope.entityDetails.businessPhone || '',
          ABN: $scope.entityDetails.abn || '',
          ACN: $scope.entityDetails.acn || '',
          Fax: $scope.entityDetails.fax || '',
          OnboardingContactsFamilyIds: onboardingDataService.getFamilyIds().toString(),
        };
        onboardingService.setRecruitmentAdviserOrganization(entityAdviserOrgData).then(() => { checkAdviserOrg(); });
        break;
      }
      default: {
        const data = {
          ClientID: onboardingDataService.getClientId() || 0,
          FamilyId: onboardingDataService.getFamilyId() || 0,
          IRDGSTNumber: $scope.entityDetails.gstNumber || '',
          GSTRegistered: $scope.entityDetails.isGstRegistered,
          OnboardingContactsFamilyIds: onboardingDataService.getFamilyIds().toString(),
        };
        onboardingService.setRecruitmentContact(onboardingDataService.getFamilyId(), data).then(() => { checkAdviserOrg(); });
        break;
      }
      }
    };

    const setAddressOnAfterModalChanges = (response) => {
      $scope.contactAddress.homeAddress.formatted_address = response.homeAddress.formatted_address;
      $scope.contactAddress.homeAddress.isBusiness = false;
      $scope.contactAddress.homeAddress.isMailing = false;

      $scope.contactAddress.mailingAddress.formatted_address = response.mailingAddress.formatted_address;
      $scope.contactAddress.mailingAddress.isBusiness = false;
      $scope.contactAddress.mailingAddress.isMailing = true;

      $scope.contactAddress.businessAddress.formatted_address = response.businessAddress.formatted_address;
      $scope.contactAddress.businessAddress.isBusiness = true;
      $scope.contactAddress.businessAddress.isMailing = false;
    };
    $scope.launchAddressModal = (value) => {
      const size = 'md';
      const modalInstance = addressModalService.launchAddressModal(size, value.AdviserOrganisationId, true, $scope.contactAddress);
      modalInstance.result.then((response) => {
        $scope.timeOutAdviserInstance = $timeout(() => {
          if (value.AdviserOrganisationId) {
            getOrgAddress(value.AdviserOrganisationId);
            toaster.pop('success', 'Successfully Updated', 'Address');
          } else {
            if (!response) return;
            setAddressOnAfterModalChanges(response);
          }
        }, 500);
      });
    };

    $scope.$on('$destory', () => {
      $timeout.cancel($scope.timeOutAdviserInstance);
    });

    const initialize = () => {
      /* get Current Country check if NewZealand */
      $scope.isUserCountryNewZealand = onboardingDataService.isUserCurrentCountry(COUNTRY_TYPE.NEW_ZEALAND);
      /* set phonemask */
      $scope.phoneMask = broadcastDataService.getPhoneFormat(onboardingDataService.getCountryId());
      /* get Entity details */
      getOnboardingAdviserOrganisationDetails(true);
      /* get Current Entity check if Company */
      $scope.entityTypeIsCompany = onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) || onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY_TRUST);
      getRecruitmentContact();
    };

    initialize();
  });
