import angular from 'angular';

class GdriveFolderModalCtrl {
  constructor($uibModalInstance) {
    'ngInject';

    this.modalInstance = $uibModalInstance;
    this.isChosenAFolder = false;
  }

  getGdriveModalResolvedData(gdriveFolderId) {
    this.modalInstance.close({ gdriveFolderId, isChosenAFolder: this.isChosenAFolder });
  }
  folderChosen(bool) {
    this.isChosenAFolder = bool;
  }
}
angular.module('app')
  .controller('GdriveFolderModalCtrl', GdriveFolderModalCtrl);
