import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';

angular.module('app').controller('LoanAppEmploymentCtrl',
  function LoanAppEmploymentCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    selectOptionsService,
    dashboardService,
    optionsService,
    colorService,
    toaster,
    SweetAlert,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    loanAppSharedData,
    financialsSharedService,
    uiService,
    employmentService
  ) {
    $scope.showSpinner = false;
    loanAppSharedData.UPDATE_EMPLOYMENT = {};
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.financialsSharedService = financialsSharedService;
    $scope.Employmnent = $scope.loanAppSharedData.Employment;

    /**
     * Models
     * */

    angular.extend($scope, {
      isPAYG: false,
      uiService,
    });

    // will check if employment type is PAYG
    $scope.$watch('Employment.EmploymentTypeId', (nv) => {
      $scope.isPAYG = parseInt(nv, 10) === 1;
    });


    /**
     * Methods
     * */
    angular.extend($scope,
      {
        getEmployment(loanAppId, EmpId) {
          loanApplicationServices.getEmployment(loanAppId, EmpId)
            .then((response) => {
              $scope.loanAppSharedData.employmentPersonList = [];
              let employmentHistories = [];
              if (!response && !response.data) return;
              _.forEach(response.data, (data) => {
                const { Employment } = data;
                let livingEmployment = _.omit(data, ['Employment']);
                const person = _.filter($scope.listApplicants, (o) => {
                  return parseInt(livingEmployment.FamilyId, 10) === parseInt(o.FamilyId, 10);
                });
                // TODO: Change to actual data
                const aliveEmployerHistory = Employment.filter(employment => !employment.IsClientDeceased);
                if (aliveEmployerHistory.length) {
                  livingEmployment = { ...livingEmployment, Employment: aliveEmployerHistory };
                  if (person.length > 0 && typeof person[0].listPriority !== 'undefined') {
                    livingEmployment.listPriority = (person[0].listPriority === 0 ? 0 : 1);
                  }
                }
                employmentHistories = [...employmentHistories, livingEmployment];
              });
              $scope.loanAppSharedData.Employment.AllList = _.orderBy(employmentHistories, ['listPriority'], ['asc']);
              $scope.getPaymentAbility(false);
              $scope.ListInitials();
            });
        },

        getEmploymentStatus(action) {
          loanApplicationServices.getEmploymentStatus()
            .then((response) => {
              if (!response.data) return;

              $scope.checkIfPrevEmp(response.data);
              $scope.loanAppSharedData.Employment.StatusList = employmentService.isCurrentEmploymentStatusShown() ? response.data.filter(status => status.Name !== 'Previous Employment') : response.data;
              if (!uiService.isCountry('New Zealand')) {
                $scope.loanAppSharedData.Employment.StatusList.unshift({ Name: 'Please select', Value: '' });
              } else if ($scope.loanAppSharedData.Employment.StatusList && $scope.loanAppSharedData.Employment.StatusList.length && action !== 'edit') {
                $scope.loanAppSharedData.Employment.EmploymentStatusId = $scope.loanAppSharedData.Employment.StatusList[0].Value;
              }
            });
        },

        getEmploymentBasis(action) {
          optionsService.getEmploymentBasis()
            .then((response) => {
              $scope.loanAppSharedData.Employment.BasisList = response.data;

              /**
               * Detect CamelCase and add space in between words
               * */
              angular.forEach($scope.loanAppSharedData.Employment.BasisList, (val) => {
                const name = val.Name;
                val.Name = name.replace(/([a-z](?=[A-Z]))/g, '$1 ');
              });

              if (!uiService.isCountry('New Zealand')) {
                $scope.loanAppSharedData.Employment.BasisList.unshift({ Name: 'Please select', Value: '' });
              } else if ($scope.loanAppSharedData.Employment.BasisList && $scope.loanAppSharedData.Employment.BasisList.length && action !== 'edit') {
                $scope.loanAppSharedData.Employment.EmploymentBasisId = $scope.loanAppSharedData.Employment.BasisList[0].Value;
              }
            });
        },

        savePaymentAbility(applicant) {
          if (applicant.Details.length > 0 && applicant.checkPaymentAbility !== true) {
            applicant.Details = '';
          }

          const data = {
            loanApplicationId: $scope.loanAppId,
            clientEntityID: applicant.ClientID,
            details: applicant.Details,
            GuaranteeingLoan: applicant.GuaranteeingLoan,
            isClient: true,
          };

          loanApplicationServices.setApplicantClientEntityDetail(data).then(() => {
            $rootScope.$broadcast('REFRESH_CLIENT_ENTITY_DETAIL', { module: 'liabilities' });
          });
        },

        getPaymentAbility(isRefresh = true) {
          loanAppSharedData.getLoanAppPaymentAbilities($scope.loanAppId, isRefresh).then((response) => {
            if (!response || !response.data) return;

            $scope.PaymentAbilityList = _.filter(response.data, (obj) => {
              return obj.ClientID !== 0;
            });

            angular.forEach($scope.PaymentAbilityList, (o) => {
              o.Background = $scope.getBGcolor(o.ClientID);
              o.checkPaymentAbility = o.Details && o.Details.length > 0;
            });
          });
        },

        getEmployerType() {
          loanApplicationServices.getEmployerType()
            .then((response) => {
              $scope.loanAppSharedData.Employment.EmployerTypeList = response.data;
              $scope.loanAppSharedData.Employment.EmployerTypeList.unshift({ Name: 'Please select', Value: '' });
            });
        },

        getFrequency() {
          optionsService.FinancialFrequency()
            .then((response) => {
              $scope.loanAppSharedData.Employment.FrequencyList = [];
              $scope.loanAppSharedData.Employment.FrequencyList = response.data;
              $scope.loanAppSharedData.Employment.FrequencyList.unshift({ Name: 'Please select', Value: '' });
            });
        },

        getIncomeType() {
          loanScenarioService.IncomeTypeGet()
            .then((response) => {
              $scope.loanAppSharedData.Employment.IncomeTypeList = [];
              $scope.loanAppSharedData.Employment.IncomeTypeList = response.data;

              _.remove($scope.loanAppSharedData.Employment.IncomeTypeList, (o) => {
                return o.GroupId !== '6' && o.GroupId !== '7' && o.GroupId !== '8' && o.GroupId !== '9' && o.GroupId !== '10';
              });

              $scope.loanAppSharedData.Employment.IncomeTypeList.unshift({ Name: 'Please select', Value: '' });
            });
        },

        getIncomeVerificationType() {
          optionsService.IncomeVerificationType('employment').then((response) => {
            $scope.incomeVerficationTypeList = response.data;
          });
        },

        getListOfApplicants(isRefresh = true) {
          $scope.listApplicants = [];
          loanAppSharedData.getLoanAppApplicants($scope.loanAppId, isRefresh).then((response) => {
            if (!response || !response.data) return;

            const tempList = [];
            angular.forEach(response.data.InvolvedPartyPerson, (family) => {
              angular.forEach(family, (client) => {
                client.Background = $scope.getBGcolor(client.PersonId);
                client.listPriority = (client.IsApplicant ? 0 : 1);
                tempList.push(client);
              });
            });
            $scope.listApplicants = _.orderBy(tempList, ['listPriority'], ['asc']);
          });
        },

        checkIfPrevEmp(employmentStatusList) {
          if (!employmentStatusList) return;

          $scope.PrevEmpId = employmentStatusList.filter(status => status.Name === 'Previous Employment')
            .reduce((accum, status) => parseInt(status.Value, 10), 0);
          $scope.defaultEmpStatus = _.find(employmentStatusList, status => status.Name === 'Primary Employment');
        },

        changeEmploymentStatus() {
          $scope.showEndDate = $scope.loanAppSharedData.Employment.PrevEmpId === $scope.loanAppSharedData.Employment.EmploymentStatusId || !$scope.loanAppSharedData.Employment.IsCurrent;
        },

        SetFamily(id, type) {
          if (type === 'family') {
            $scope.loanAppSharedData.Employment.ListFamily = _.find($scope.loanAppSharedData.Employment.AllList, (obj) => {
              return parseInt(obj.FamilyId, 10) === parseInt(id, 10);
            });
          } else {
            $scope.loanAppSharedData.Employment.List = _.find($scope.loanAppSharedData.employmentPersonList, (obj) => {
              return parseInt(obj.PersonId, 10) === parseInt(id, 10);
            });
          }

          $scope.UPDATE_EMPLOYMENT();
        },

        changeFamily() {
          const familySelected = _.find($scope.loanAppSharedData.Employment.AllList, (family) => {
            return parseInt(family.FamilyId, 10) === parseInt($scope.loanAppSharedData.Employment.SelectedFamily, 10);
          });
          $scope.loanAppSharedData.Employment.ListFamily = familySelected;
          $scope.loanAppSharedData.Employment.SelectedFamily = $scope.loanAppSharedData.Employment.ListFamily.FamilyId;
          $scope.SetFamily($scope.loanAppSharedData.Employment.SelectedClient);
        },

        changeFamilyGroup() {
          const personListSelected = _.find($scope.loanAppSharedData.employmentPersonList, (obj) => {
            return parseInt(obj.FamilyId, 10) === parseInt($scope.loanAppSharedData.Employment.SelectedFamily, 10);
          });
          if (personListSelected) {
            $scope.loanAppSharedData.Employment.List = personListSelected;
            $scope.loanAppSharedData.Employment.SelectedClient = String($scope.loanAppSharedData.Employment.List.PersonId);
            $scope.SetFamily($scope.loanAppSharedData.Employment.SelectedFamily, 'family');
          }
        },

        ListInitials() {
          $scope.loanAppSharedData.employmentPersonList = [];
          $scope.allEmployment = [];
          const allArrayEmployment = [];
          if (typeof $scope.loanAppSharedData.Employment.ListFamily === 'undefined') {
            $scope.loanAppSharedData.Employment.ListFamily = [];
          }
          angular.forEach($scope.loanAppSharedData.Employment.AllList, (val, parentIndex) => {
            val.background = $scope.getBGcolor(val.FamilyId);

            val.initials = utilitiesService.filterInitialOneString(val.FamilyName);
            $scope.loanAppSharedData.Employment.parentIndex = parentIndex;

            contactService
              .clientInformGet(val.FamilyId)
              .then((response) => {
                if (!$scope.loanAppSharedData || !$scope.loanAppSharedData.Employment) return;
                const adultList = _.filter(response.data, { Role: 'Adult' }) || response.data;
                if (adultList.length < 1) {
                  $scope.loanAppSharedData.Employment.AllList = _.filter($scope.loanAppSharedData.Employment.AllList, (obj) => {
                    return obj.FamilyId !== val.FamilyId;
                  });
                } else {
                  angular.forEach(adultList, (obj, childIndex) => {
                    $scope.loanAppSharedData.Employment.childIndex = childIndex;
                    obj.initials = utilitiesService.filterInitialOneString(obj.FullName);
                    obj.FullName = `${obj.FirstName} ${obj.LastName}`;
                    obj.background = $scope.getBGcolor(obj.PersonId);
                    obj.FamilyId = val.FamilyId;

                    obj.Employment = _.map(_.filter(val.Employment, (employment) => {
                      return parseInt(employment.ClientId, 10) === parseInt(obj.PersonId, 10);
                    }), (employeeSet) => {
                      employeeSet.FamilyId = val.FamilyId;
                      employeeSet.background = obj.background;
                      employeeSet.initials = utilitiesService.filterInitialOneString(obj.FullName);
                      return employeeSet;
                    });

                    allArrayEmployment.push(obj.Employment);
                    $scope.loanAppSharedData.employmentPersonList.push(obj);
                    const allEmploymentListLength = $scope.loanAppSharedData.Employment.AllList ? $scope.loanAppSharedData.Employment.AllList.length : 0;
                    if (($scope.loanAppSharedData.Employment.parentIndex + 1) === allEmploymentListLength && ($scope.loanAppSharedData.Employment.childIndex + 1) === adultList.length) {
                      $scope.loanAppSharedData.Employment.List = _.first($scope.loanAppSharedData.employmentPersonList);
                      $scope.loanAppSharedData.Employment.SelectedClient = String($scope.loanAppSharedData.Employment.List.PersonId);
                      $scope.loanAppSharedData.getEmploymentStatus();
                      $scope.allEmployment = _.flattenDeep(allArrayEmployment);
                      $scope.UPDATE_EMPLOYMENT();
                    }

                    /* initialize contactList */
                    $scope.loanAppSharedData.Employment.SelectedClient = $scope.loanAppSharedData.employmentPersonList[0].PersonId;
                    $scope.loanAppSharedData.employmentContactList = {
                      SelectedClient: $scope.loanAppSharedData.employmentPersonList[0].PersonId,
                      ClientFirstName: $scope.loanAppSharedData.employmentPersonList[0].FirstName,
                      ClientLastName: $scope.loanAppSharedData.employmentPersonList[0].LastName,
                      initials: utilitiesService.filterInitialOneString($scope.loanAppSharedData.employmentPersonList[0].FullName),
                      background: $scope.loanAppSharedData.employmentPersonList[0].background,
                    };
                  });
                }
                // must be inside for async call
                const filterEmploymentList = _.filter($scope.loanAppSharedData.Employment.AllList, (o) => {
                  return o.listPriority === 0;
                });
                $scope.loanAppSharedData.Employment.ListFamily = _.first(filterEmploymentList);
                if (typeof $scope.loanAppSharedData.Employment.ListFamily !== 'undefined') {
                  $scope.loanAppSharedData.Employment.SelectedFamily = parseInt($scope.loanAppSharedData.Employment.ListFamily.FamilyId, 10);
                }

                if (typeof $scope.loanAppSharedData.Employment.ListFamily === 'undefined' && $scope.loanAppSharedData.Employment.AllList.length > 0) {
                  $scope.loanAppSharedData.Employment.ListFamily = _.first($scope.loanAppSharedData.Employment.AllList);
                  $scope.loanAppSharedData.Employment.SelectedFamily = parseInt($scope.loanAppSharedData.Employment.ListFamily.FamilyId, 10);
                }

                if ($scope.familyID) {
                  $scope.loanAppSharedData.Employment.SelectedFamily = $scope.familyID;
                  $scope.SetFamily($scope.familyID, 'family');
                }
              });
          });
        },

        delete(id, index) {
          SweetAlert.swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }, (confirm) => {
            if (confirm) {
              swal(
                'Deleted!',
                'Employment has been removed.',
                'success'
              );
              $scope.loanAppSharedData.Employment.List.Employment.splice(index, 1);
              loanApplicationServices.deleteEmployment(id);
              $scope.getEmployment($scope.loanAppId, 0);
              $scope.UPDATE_EMPLOYMENT();
              $scope.financialsSharedService.IncomeInfoGet();
            }
          });
        },

        openAddEditEmployment(employmentId = 0, clientId = 0, action = 'Add', background) {
          action !== 'Add' && $scope.loanAppSharedData.changeEmploymentOwnership(clientId.toString(), background);
          const modalInstance = $uibModal.open({
            backdrop: 'static',
            keyboard: false,
            templateUrl: '/assets/views/modals/employment.html',
            controller: 'EmploymentModalCtrl',
            controllerAs: 'vm',
            size: 'lg',
            windowClass: 'modal-larger',
            resolve: {
              newIncomeDetails: () => null,
              isFromIncome: () => false,
              familyId: () => $scope.loanAppSharedData.Employment.SelectedFamily,
              employmentId: () => employmentId,
              clientId: () => clientId.toString(),
              loanAppId: () => $scope.loanAppId,
              action: () => action,
            },
          });

          modalInstance.result.then((response) => {
            if (response && response.FamilyId) {
              $scope.getEmployment($scope.loanAppId, 0);
              $scope.UPDATE_EMPLOYMENT();
              $scope.familyID = response.FamilyId;
              $scope.loanAppSharedData.Employment.SelectedFamily = response.FamilyId;
              $scope.changeFamilyGroup();
              $scope.financialsSharedService.IncomeInfoGet();
            }
          });
        },

        // Watch for changes in list
        UPDATE_EMPLOYMENT() {
          employmentService.countEmploymentYears($scope.loanAppId, 0)
            .then((count) => {
              if ($scope.loanAppSharedData.Employment && $scope.loanAppSharedData.Employment.List && $scope.loanAppSharedData.Employment.List.Employment) {
                const isHasEmployment = [];
                _.forEach($scope.loanAppSharedData.Employment.AllList, (o) => {
                  isHasEmployment.push(o.Employment.length > 0);
                });
                const hasEmployment = !_.has(isHasEmployment, false);
                loanAppSharedData.UPDATE_EMPLOYMENT = { status: true, invalidCount: count.length, hasEmployment };
              }
            });
        },

        listeners() {
          $scope.$watch('loanAppSharedData', (nv) => {
            if (nv && nv.refreshEmploymentList) {
              $scope.getEmployment($scope.loanAppId, 0);
              nv.refreshEmploymentList = false;
            }
          }, true);

          $scope.$watch('loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS', (nv) => {
            if (nv && _.size(nv) > 0 && nv.RefreshEmployment) {
              $scope.getEmployment($scope.loanAppId, 0);
              $scope.getListOfApplicants();
              nv.RefreshEmployment = false;
            }
          });

          $rootScope.$on('REFRESH_CLIENT_ENTITY_DETAIL', (e, args) => {
            if (args.module === 'employment')
              $scope.getPaymentAbility();
          });
        },

        init() {
          $scope.listeners();
          $scope.getListOfApplicants(false);
          $scope.getEmployment($scope.loanAppId, 0);
        },
      }
    );
    $scope.init();
  });
