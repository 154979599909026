import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('app').controller('NotesDetailsCtrl',
  function NotesDetailsCtrl($scope,
    $http,
    $timeout,
    $uibModal,
    $state,
    $stateParams,
    contactService,
    NgTableParams,
    $filter,
    SweetAlert,
    utilitiesService,
    toaster,
    userService,
    loanOpportunityService) {
    $scope.notesWidget = false;
    $scope.notesWidgetMenu = false;
    $scope.notesWidgetPanel = false;
    $scope.filterReferrerNotes = 'all';
    $scope.searchNotes = '';


    $scope.brokerBrandingId = $stateParams.adviserOrgId || 0;
    $scope.isClient = true;
    const getUserInfo = () => {
      userService.GetUserInfo().then((response) => {
        $scope.userInfo = response.data;
        if ($scope.brokerBrandingId || $state.params.clientId !== undefined) {
          $scope.isClient = false;
        }
      });
    };
    getUserInfo();

    $scope.actionButtonVisible = (ownerId) => {
      if ($scope.isClient) return true;
      if (!$scope.userInfo && !$scope.userInfo.FamilyId) return true;
      return parseInt($scope.userInfo.FamilyId, 10) === parseInt(ownerId, 10);
    };


    $scope.showWidget = function (notesWidgetPanel) {
      $scope.notesWidgetPanel = !notesWidgetPanel;
      if ($scope.notesWidgetPanel === true) {
        $('#contacts-sidebar').addClass('overlap');
      } else {
        $('#contacts-sidebar').removeClass('overlap');
      }
    };
    //
    $scope.filterNotes = function () {
      const temp = [];
      if ($scope.filterReferrerNotes === 'referrer') {
        $scope.noteListData = {}; // resets the value of noteslistdata
        angular.forEach($scope.notesContainer, (value, index) => {
          if (value.IsReferrer) {
            temp[index] = value;
          }
        });
        $scope.noteListData = temp; // stores the value based on filter
      } else {
        $scope.noteListData = $scope.notesContainer; // store all the original noteslistdata
      }
    };

    angular.extend($scope, {
      pagination: {
        bigTotalItems: 0,
        bigCurrentPage: 1,
        maxSize: 5,
        itemsPerPage: 5,
      },
    });
    // Notes [corporate] - moved from contactDetailsCtrl
    $scope.getNotesList = function () {
      // Set family ID default to 1 if Adviser Org Contact is opened
      const tempFamilyId = typeof $scope.familyId !== 'undefined' && $scope.familyId > 0 ? $scope.familyId : 1;
      contactService.notesListCount(tempFamilyId, $scope.brokerBrandingId).then((response) => {
        $scope.pagination.bigTotalItems = response.data;

        contactService.notesList(tempFamilyId, $scope.pagination.bigTotalItems, $scope.brokerBrandingId).then((listResponse) => {
          $scope.noteListData = listResponse.data;
          for (let i = 0; i < $scope.noteListData.length; i++) {
            $scope.noteListData[i].initial = utilitiesService.filterInitialOneString($scope.noteListData[i].ActivityOwner);
          }
          $scope.$watch($scope.noteListData, () => {
            $scope.notesContainer = $scope.noteListData;
          });
        });
      });
    };
    $scope.getNotesList();

    $scope.editNote = function (action, note) {
      let familyId = $scope.familyId;
      let noteObj = {};
      const isOpportunity = note.IsOpportunity || (typeof note.IsOpportunity !== 'undefined' && !note.IsOpportunity);

      if ($stateParams.adviserOrgId) {
        familyId = 0;
      }
      contactService.noteGet(familyId, note.CommunicationID, $scope.brokerBrandingId, isOpportunity)
        .then((response) => {
          const { data } = response;
          if (data && !data.length) return;

          noteObj = data[0];
          noteObj.IsOpportunity = isOpportunity;
          if (noteObj) {
            $scope.openCreateNoteModal(action, noteObj);
          } else toaster.pop('error', 'Error retrieving note info.');
        });
    };

    $scope.deleteNote = function (note) {
      if (!angular.isUndefined(note.CommunicationID)) {
        SweetAlert.swal({
          title: 'Are you sure?',
          text: 'This record will be removed from your notes',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            let service;
            let method;
            if (typeof note.IsOpportunity === 'undefined') {
              service = contactService;
              method = 'noteDelete';
            } else {
              service = loanOpportunityService;
              method = 'deleteLoanOpportunityNote';
            }
            service[method](note.CommunicationID)
              .then(() => {
                _.remove($scope.noteListData, (o) => { return _.isEqual(o, note); });

                SweetAlert.swal({
                  title: 'Success',
                  text: 'Note has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              });
          }
        });
      } else {
        _.remove($scope.noteListData, (o) => { return _.isEqual(o, note); });
      }
    };

    $scope.openCreateNoteModal = function (action, noteObj = {}) {
      $scope.action = action;
      const familyId = $scope.familyId;

      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/create_note_modal.html',
        controller: 'CreateNoteModalCtrl',
        scope: $scope,
        resolve: {
          familyId() {
            return familyId;
          },
          note() {
            return noteObj;
          },
        },
      });
      modalInstance.result.then(() => {
        $scope.getNotesList();
      });
    };
  });
