import { ADDRESS_TYPE } from 'Common/constants/addressType';

class AddressModalCtrl {
  constructor(
    corporateService,
    modelDataService,
    commonFnService,
    uiService,
    $timeout
  ) {
    'ngInject';

    this.corporateService = corporateService;
    this.modelDataService = modelDataService;
    this.commonFnService = commonFnService;
    this.uiService = uiService;
    this.$timeout = $timeout;
  }
  cancel() {
    this.modalInstance.dismiss();
  }
  getAddressByType(data) {
    if (data) {
      const addressTmp = this.commonFnService.setAddressTypes(data);
      if (addressTmp.business) {
        this.businessAddress = { ...addressTmp.business };
      }
      if (addressTmp.home) {
        this.homeAddress = { ...addressTmp.home };
      }
      if (addressTmp.mailing) {
        this.mailingAddress = { ...addressTmp.mailing };
        const homeAddressId = parseInt(this.homeAddress.AddressID, 10);
        const mailingAddressId = parseInt(this.mailingAddress.AddressID, 10);
        this.mailingAddress.isSameAsPrimary = this.isOrgAddress ? (mailingAddressId === homeAddressId) : (this.mailingAddress.formatted_address === this.businessAddress.formatted_address);
      }
    }
  }
  getAdviserAddress(familyId) {
    if (familyId) {
      this.corporateService.familyAddressGet(familyId).then((response) => {
        this.getAddressByType(response.data);
      });
    }
  }
  getOrgAddress(orgId) {
    if (orgId) {
      this.corporateService.organizationAddressGet(orgId).then((response) => {
        this.getAddressByType(response.data);
      });
    }
  }
  getFormattedAddress(data) {
    const streetAddress = data.street_address ? `${data.street_address},` : '';
    const suburb = data.locality ? `${data.locality},` : '';
    const postal = data.postal_code;
    const state = data.stateShort_Name;
    let country = this.userCountry;
    data.country = country;
    let statePostal = '';
    if (state && postal) {
      statePostal = `${state} ${postal},`;
    } else if (state) {
      statePostal = `${state},`;
    } else if (postal) {
      statePostal = `${postal},`;
    }
    if (!streetAddress && !suburb && !postal && !state) {
      country = '';
      data.country = '';
    }
    return `${streetAddress} ${suburb} ${statePostal} ${country}`;
  }
  saveAddress() {
    const subId = 0;
    const isOrgAddress = this.isOrgAddress || false;
    this.isSaving = true;
    if (!this.contactId) {
      this.modalInstance.close({ businessAddress: this.businessAddress, homeAddress: this.homeAddress, mailingAddress: this.mailingAddress });
      return;
    }
    if (this.businessAddress) {
      if (this.businessAddress.isCustomAddress) {
        const businessAddress = { ...this.modelDataService.addressModelData() };
        businessAddress.formatted_address = this.getFormattedAddress(this.businessAddress);
        businessAddress.street_address = this.businessAddress.street_address;
        businessAddress.locality = this.businessAddress.locality;
        businessAddress.postal_code = this.businessAddress.postal_code;
        businessAddress.stateShort_Name = this.businessAddress.stateShort_Name;
        businessAddress.isCustomAddress = this.businessAddress.isCustomAddress;
        this.businessAddress = { ...businessAddress };
      }
      const isBusiness = true;
      const isMailing = false;
      this.commonFnService.saveAddress(this.contactId, subId, this.businessAddress, isMailing, isBusiness, isOrgAddress, this.businessAddress.isCustomAddress)
        .then(() => {
          this.modalInstance.close({ businessAddress: this.businessAddress, homeAddress: this.homeAddress, mailingAddress: this.mailingAddress });
        });
    }
    if (this.homeAddress) {
      if (this.homeAddress.isCustomAddress) {
        const homeAddress = { ...this.modelDataService.addressModelData() };
        homeAddress.formatted_address = this.getFormattedAddress(this.homeAddress);
        homeAddress.street_address = this.homeAddress.street_address;
        homeAddress.locality = this.homeAddress.locality;
        homeAddress.postal_code = this.homeAddress.postal_code;
        homeAddress.stateShort_Name = this.homeAddress.stateShort_Name;
        homeAddress.isCustomAddress = this.homeAddress.isCustomAddress;
        this.homeAddress = { ...homeAddress };
      }
      const isBusiness = false;
      const isMailing = false;
      this.commonFnService.saveAddress(this.contactId, subId, this.homeAddress, isMailing, isBusiness, isOrgAddress, this.homeAddress.isCustomAddress)
        .then(() => {
          this.modalInstance.close({ businessAddress: this.businessAddress, homeAddress: this.homeAddress, mailingAddress: this.mailingAddress });
        });
    }
    if (this.mailingAddress && this.mailingAddress.isSameAsPrimary) {
      if (this.isOrgAddress) {
        this.mailingAddress = { ...this.homeAddress };
      } else {
        this.mailingAddress = { ...this.businessAddress };
      }
      this.mailingAddress.isSameAsPrimary = true;
    }
    if (this.mailingAddress) {
      if (this.mailingAddress.isCustomAddress) {
        const mailingAddress = { ...this.modelDataService.addressModelData() };
        mailingAddress.formatted_address = this.getFormattedAddress(this.mailingAddress);
        mailingAddress.street_address = this.mailingAddress.street_address;
        mailingAddress.locality = this.mailingAddress.locality;
        mailingAddress.postal_code = this.mailingAddress.postal_code;
        mailingAddress.stateShort_Name = this.mailingAddress.stateShort_Name;
        mailingAddress.isCustomAddress = this.mailingAddress.isCustomAddress;
        mailingAddress.isSameAsPrimary = this.mailingAddress.isSameAsPrimary;
        this.mailingAddress = { ...mailingAddress };
      }
      if (this.mailingAddress.isCustomAddress) {
        this.mailingAddress.AddressID = 0;
        this.mailingAddress.formatted_address = this.getFormattedAddress(this.mailingAddress);
      }
      const isBusiness = false;
      const isMailing = true;
      this.commonFnService.saveAddress(this.contactId, subId, this.mailingAddress, isMailing, isBusiness, isOrgAddress, this.mailingAddress.isCustomAddress)
        .then(() => {
          this.modalInstance.close({ businessAddress: this.businessAddress, homeAddress: this.homeAddress, mailingAddress: this.mailingAddress });
        });
    }
  }
  $onInit() {
    this.userCountry = this.uiService.getCrmCountry();
    this.businessAddress = { ...this.modelDataService.addressModelData() };
    this.homeAddress = { ...this.modelDataService.addressModelData() };
    this.mailingAddress = { ...this.modelDataService.addressModelData() };
    this.isSaving = false;
    this.addressType = ADDRESS_TYPE;
    if (this.isOrgAddress) {
      this.getOrgAddress(this.contactId);
    } else if (this.contactId) {
      this.getAdviserAddress(this.contactId);
    } else {
      const { businessAddress, homeAddress, mailingAddress } = this.tempAddress;
      this.getAddressByType({ businessAddress, homeAddress, mailingAddress });
    }
  }
}

export default AddressModalCtrl;
