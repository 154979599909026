export default class EmailRecipientsFieldCtrl {
  $onChanges() {
    this.updateRecipientsList();
  }

  updateRecipientsList() {
    this.recipients = this.selectedRecipients ? [...this.selectedRecipients] : [];
  }

  onRemove(recipient) {
    this.onRemoveRecipient && this.onRemoveRecipient({ recipient });
    this.updateRecipientsList();
  }
}
