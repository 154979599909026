import {
  taskFilterBuilderForUI,
  taskFilterBuilderForMyCRM,
  taskAdviserListForUI,
} from 'Common/mappers/tasks';

class TasksService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Tasks';
  }

  taskFilterGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/taskfilter`)
      .then(response => taskFilterBuilderForUI(response.data));
  }
  taskFilterUpdate(data) {
    return this.httpClient.put(`${this.apiBaseUrl}/taskfilter`, taskFilterBuilderForMyCRM(data));
  }
  taskAdviserListGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/TaskAdviserListGet`)
      .then(response => response.data.map(taskAdviserListForUI));
  }

  getTaskList(params) {
    const defaultParams = {
      adviserFilter: 0,
      dateFilter: 'all',
      familyId: 0,
    };
    const paramsCopy = { ...defaultParams, ...params };
    return this.httpClient.get(`${this.apiBaseUrl}/TaskListGet`, paramsCopy);
  }
}

export default TasksService;
