import angular from 'angular';
import _ from 'lodash';
import {
  setCurrrentDate,
  setDateFormat,
} from 'Common/utilities/date';

angular.module('app').controller('AdviserLeadsCtrl', function AdviserLeadsCtrl(
  broadcastDataService,
  corporateService,
  commonFnService,
  dataService,
  queryService,
  toaster,
  $filter,
  $state,
  $scope
) {
  /* State Params */
  $scope.clientId = $state.params.clientId || 0;
  $scope.familyId = $state.params.familyId || 0;
  /*    VARIABLE(s)    */
  /* Data Type: {Object} */
  $scope.leadsProfileGet = {};
  $scope.editMode = {};
  /* Data Type: {Array} */
  $scope.selectedLeadCampaign = [];
  $scope.corpLeadCampaignGet = [];
  $scope.altInputFormats = [];
  $scope.leadPriorities = [];
  $scope.setterType = [];
  $scope.saveType = [];
  $scope.allWeeks = [];
  $scope.loanType = [];
  /* Data Type: {String} */
  $scope.dateStaticValue = '1900-01-01';
  $scope.dateFormat = '';
  let watchAdviserMarketingLeadsEditMode = null;
  /*  FUNCTION REFERENCE      */
  /*  FUNCTION DEFINITION(S)  */
  /* ------------------------------------------ [ SETTER ] --------------------------------------------------- */
  /**
      *   @name querySet
      *   @desc This will save the value from api.
      *   @params {String} setter
      *   @params {String} key
      *   @params {Array} arrays
      *   @params {Boolean} isCorporate
      */
  function querySet(setter, key, field, arrays, isCorporate) {
    $scope.editMode[key] = false;
    commonFnService.setEditMode($scope.editMode, field);
    if (isCorporate) {
      corporateService[setter].apply(null, arrays)
        .then((response) => {
          if (response) {
            if (response.status === 200) {
              toaster.pop('success', 'Update', 'successfully updated!');
            }
          }
        });
    }
    toaster.clear();
  }
  /**
      *   @name setCampaignLead
      */
  function setCampaignLead(corpLeadCampaignName) {
    $scope.selectedLeadCampaign = [];
    $scope.corpLeadCampaignGet && Object.keys($scope.corpLeadCampaignGet).forEach((key) => {
      corpLeadCampaignName && Object.keys(corpLeadCampaignName).forEach((y) => {
        if ($scope.corpLeadCampaignGet[key].CorpLeadCampaignDesc === corpLeadCampaignName[y]) {
          $scope.selectedLeadCampaign.push($scope.corpLeadCampaignGet[key]);
        }
      });
    });
    return $scope.selectedLeadCampaign;
  }
  /**
      *   @name onRetrieveFamilyWebManagementSuccess
      *   @desc This will load data from Api: `familyWebManagementGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveLeadsProfileSuccess(response) {
    if (response && response.data) {
      dataService.getCorpLeadCampaign()
        .then((campaign) => {
          $scope.corpLeadCampaignGet = campaign;
          let corpLeadCampaignName = [];
          corpLeadCampaignName = response.data.CorpLeadCampaignDesc ? response.data.CorpLeadCampaignDesc.split(',') : [];
          response.data.CorpLeadCampaignDescArr = setCampaignLead(corpLeadCampaignName);
        });
      let contactWeekName = [];
      let loanTypeName = [];
      contactWeekName = response.data.AllocationWorkingdays ? response.data.AllocationWorkingdays.split(',') : [];
      response.data.AllocationWorkingdaysArr = contactWeekName || [];
      loanTypeName = response.data.LoanType ? response.data.LoanType.split(',') : [];
      response.data.LoanTypeArr = loanTypeName || '';
      response.data.PriorityName = queryService.getArrValue(response.data.Priority, null, 'id', 'name', $scope.leadPriorities);

      queryService.getAllArrayDetails($scope, corporateService, 'leadsReceiveStatusDropdownGet', [],
        'leadStatus', response.data, 'ReceiveStatusName', 'ReceiveStatus',
        'LeadReceiveStatusId', 'LeadReceiveStatus');
      $scope.leadsProfileGet = response.data;
    }
    $scope.isLoadingLeads = false;
  }
  /*
      *   @name initialize
      *   @desc This will load/initialize data needed on load.
      */
  function initialize() {
    dataService.getUserInfo()
      .then((response) => {
        $scope.isCorpTeam = response;
      });
    $scope.setterType = dataService.getSetterTypes('Adviser');
    $scope.saveType = dataService.getSaveTypes('Adviser');
    $scope.dateFormat = setDateFormat();
    $scope.altInputFormats = dataService.getDateValidityFormats();
    $scope.currentDate = setCurrrentDate();
    $scope.leadPriorities = dataService.getLeadPriorities();
    $scope.allWeeks = dataService.getAllWeeks();
    $scope.loanType = dataService.getLoanType();
    $scope.isLoadingLeads = true;
    corporateService.leadsProfileGet($scope.familyId, $scope.clientId)
      .then(onRetrieveLeadsProfileSuccess, commonFnService.onRetrieveError);

    watchAdviserMarketingLeadsEditMode = $scope.$watch('editModeObj', (newValue) => {
      $scope.editMode = newValue;
    });
  }
  $scope.$on('$destroy', () => {
    watchAdviserMarketingLeadsEditMode();
  });
  /*  ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */
  $scope.dateEditMode = function (obj, key) {
    return commonFnService.dateEditMode(obj, key, $scope.editMode);
  };

  $scope.formatDateString = function (date) {
    return commonFnService.formatDateString(date);
  };

  $scope.formatDate = function (date) {
    return commonFnService.formatDate(date);
  };

  $scope.setStatus = function (name, setterType, obj) {
    let key;
    let field;
    const error = false;

    if (setterType === 'leadsProfileSet') {
      $scope.leadsProfileGet.ReceiveStatusName = name.LeadReceiveStatus;
      key = 'ReceiveStatusName';
      field = key;
      $scope.leadsProfileGet.ReceiveStatus = name.LeadReceiveStatusId;
      key = 'ReceiveStatus';
      field = key;
    }

    if (!error) {
      $scope.updateAdviserDetails(setterType, obj, key, field);
    }
  };

  $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
    return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
  };

  $scope.updateAdviserDetails = function (setterType, corpAdviserLeadObj, key, field) {
    let dataArray = [];
    const obj = corpAdviserLeadObj;
    const weekName = [];
    const loanTypeName = [];
    const corpLeadCampaignDescName = [];
    const corpLeadCampaignDescId = [];
    if (setterType === 'leadsProfileSet') {
      switch (key) {
      case 'PriorityName':
        obj.Priority = queryService.getArrValue(null, obj.PriorityName, 'id', 'name', $scope.leadPriorities);
        break;
      case 'AllocationWorkingdays':
        obj.AllocationWorkingdaysArr && Object.keys(obj.AllocationWorkingdaysArr).forEach((x) => {
          weekName.push(obj.AllocationWorkingdaysArr[x]);
        });
        obj.AllocationWorkingdays = _.size(weekName) > 0 ? weekName.join(',  ') : '';
        break;
      case 'LoanType':
        obj.LoanTypeArr && Object.keys(obj.LoanTypeArr).forEach((i) => {
          loanTypeName.push(obj.LoanTypeArr[i]);
        });
        obj.LoanType = _.size(loanTypeName) > 0 ? loanTypeName.join(',  ') : '';
        break;
      case 'CorpLeadCampaignDesc':
        obj.CorpLeadCampaignDescArr && Object.keys(obj.CorpLeadCampaignDescArr).forEach((x) => {
          corpLeadCampaignDescName.push(obj.CorpLeadCampaignDescArr[x].CorpLeadCampaignDesc);
          corpLeadCampaignDescId.push(obj.CorpLeadCampaignDescArr[x].CorpLeadCampaignID);
        });
        obj.CorpLeadCampaignDesc = _.size(corpLeadCampaignDescName) > 0 ? corpLeadCampaignDescName.join(',  ') : '';
        obj.CorpLeadCampaignIDs = _.size(corpLeadCampaignDescId) > 0 ? corpLeadCampaignDescId.join(',') : '';
        break;
      default:
        break;
      }

      dataArray = [$scope.familyId, $scope.clientId, obj];
    }
    if (_.size(dataArray) > 0) {
      querySet(setterType, key, field, dataArray, true);
    }
  };
  $scope.getDateFormatInView = (date) => {
    return dataService.setDateFormatInView(date);
  };
  /**
      *   Function Call
      */
  initialize();
});
