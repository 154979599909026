import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

angular.module('app').controller('LoanRepaymentCalculatorCtrl',
  function LoanRepaymentCalculatorCtrl($scope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanToolsService,
    contactService,
    utilitiesService,
    $filter,
    $element,
    $window,
    $rootScope,
    SweetAlert,
    toaster,
    $state,
    lendingScenarioService,
    $localStorage,
    userService,
    DEMO_VIDEO,
    formattingService,
    uiService,
    lenderRatesModalService) {
    $scope.uiService = uiService;
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId() {
            return DEMO_VIDEO.REPAYMENT_CALCULATOR.Url;
          },
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------

    $scope.localizeObj = {};
    $scope.screenSize = $window.innerWidth;
    const viewport = function () {
      let e = $window;
      let a = 'inner';
      if (!('innerWidth' in $window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function () {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch($scope.getWindowWidth, (newValue) => {
      $scope.screenSize = newValue.w;
    }, true);


    $scope.series = ['Total To Pay', 'Principal To Pay'];

    $scope.colors = [{
      fillColor: 'rgba(220,220,220,0)',
      strokeColor: '#f9b7b7',
      pointColor: '#fff',
      pointStrokeColor: '#fd7575',
      pointHighlightFill: '#fd7575',
      pointHighlightStroke: '#fd7575',
    }, {
      fillColor: 'rgba(151,187,205,0)',
      strokeColor: '#abc5de',
      pointColor: '#fff',
      pointStrokeColor: '#6297cb',
      pointHighlightFill: '#6297cb',
      pointHighlightStroke: '#6297cb',
    }];

    $scope.options = utilitiesService.getLoanOptions();
    $scope.repayList = { repayTable: [],
      repayChart: [],
      repayTit: [] };
    $scope.repay = { years: 30,
      frequency: 'Monthly',
      loan: $scope.uiService.isCountry('Indonesia') ? '400000' : '400,000',
      r: 6,
      interestOnly: false,

    };
    $scope.resultPanelData = {};


    $scope.showBottom = true;
    $scope.changeShowBottom = function (val) {
      $scope.showBottom = val;
      if (val)
        $scope.constructDataForGraph();
    };

    $scope.downloadPdf = function () {
      $scope.pdf = true;
      $('#makePDF').show();
      html2canvas($window.document.body, {
        onpreloaded() {
        },

        onrendered(canvas) {
          const imgData = canvas.toDataURL('image/jpeg');
          const doc = new JsPDF();
          doc.addImage(imgData, 'jpeg', 10, 10, 190, 0);
          const corte = 1710; // configura tamanho do corte
          let image = new $window.Image();
          image = $window.Canvas2Image.convertToJPEG(canvas);

          let croppingYPosition = corte;
          const count = (image.height) / corte;


          for (let i = 1; i < count; i++) {
            doc.addPage();
            const sourceX = 0;
            const sourceY = croppingYPosition;
            const sourceWidth = image.width;
            const sourceHeight = corte;
            const destWidth = sourceWidth;
            const destHeight = sourceHeight;
            const destX = 0;
            const destY = 0;
            const canvas1 = canvas;
            canvas1.setAttribute('height', (image.height) - (corte * i));
            canvas1.setAttribute('width', destWidth);
            const ctx = canvas1.getContext('2d');
            ctx.drawImage(image, sourceX,
              sourceY,
              sourceWidth,
              sourceHeight,
              destX,
              destY,
              destWidth,
              destHeight);
            let image2 = new $window.Image();
            image2 = $window.Canvas2Image.convertToJPEG(canvas1);
            const image2Data = image2.src;
            doc.addImage(image2Data, 'JPEG', 10, 10, 190, 0);
            croppingYPosition += destHeight;
          }

          doc.save('report.pdf');
        },
      });
    };
    $scope.downloadPDF2 = function () {
      const canvas = $window.document.querySelector('#pdf');
      // creates image
      const canvasImg = canvas.toDataURL('image/jpeg');

      // creates PDF from img
      const doc = new JsPDF('landscape');
      doc.setFontSize(20);
      doc.text(15, 15, 'Cool Chart');
      doc.addImage(canvasImg, 'JPEG', 10, 10, 190, 0);
      doc.save('canvas.pdf');
    };

    function getParsed(amount) {
      const replaced = amount.replace(/,/g, '');
      const parsed = parseFloat(replaced);
      return parsed;
    }
    $scope.formatToCurrency = formattingService.calculatorFormatCurrency;
    $scope.formatInterestRate = (amount) => {
      return formattingService.calculatorFormatInterestRate(amount);
    };

    $scope.openLenderRatesModal = (size) => {
      lenderRatesModalService.launchLenderListModal(size, $scope.lenderList, $scope.repay);
    };
    $scope.loadTable = function () {
      const copiedObj = {};
      angular.copy($scope.repay, copiedObj);
      copiedObj.years = parseInt(copiedObj.years, 10);
      copiedObj.r = parseFloat(copiedObj.r);
      copiedObj.loan = parseFloat(getParsed(copiedObj.loan));

      loanToolsService.getRepaymentCalculations(copiedObj)
        .then((response) => {
          $scope.calculationObject = response.data;

          $scope.constructDataForGraph();
          $scope.constructDataForXSTable();
          if ($scope.repay.frequency === 'Monthly') {
            $scope.resultPanelData = 'Monthly';
          } else if ($scope.repay.frequency === 'Weekly') {
            $scope.resultPanelData = 'Weekly';
          } else {
            $scope.resultPanelData = 'Fortnightly';
          }
        });
    };


    $scope.constructDataForGraph = function () {
      const arrTotalToPay = [];
      const arrPrincipalToPay = [];
      $scope.labels = [];
      $scope.graphData = [];

      for (let i = 0; i < $scope.calculationObject.RepaymentTableArrayChart.length; i++) {
        const data = $scope.calculationObject.RepaymentTableArrayChart[i];
        arrTotalToPay.push(_.toInteger(data[2]).toFixed(2));
        arrPrincipalToPay.push(_.toInteger(data[7]).toFixed(2));
        $scope.labels.push(i);
      }

      $scope.data = [arrTotalToPay, arrPrincipalToPay];
      $scope.graphData = $scope.data;
    };


    $scope.constructDataForXSTable = function () {
      let maxPeriod = 0;
      switch ($scope.repay.frequency) {
      case 'Monthly':
        maxPeriod = 12;
        break;
      case 'Weekly':
        maxPeriod = 52;
        break;
      case 'Fortnightly':
        maxPeriod = 26;
        break;
      default:
        break;
      }


      $scope.repaymentTableArrayXs = [];
      let yrCount = 1;
      let prdCount = 1;
      const splicedArray = [];
      angular.copy($scope.calculationObject.RepaymentTableArray, splicedArray);
      splicedArray.splice(0, 1);
      const firstRow = splicedArray[0];


      $scope.totalRepaymentAmount = firstRow[1];
      const lastRow = splicedArray[splicedArray.length - 1];

      $scope.totalInterest = lastRow[6];

      for (let i = 0; i < splicedArray.length; i++) {
        if (prdCount > maxPeriod) {
          prdCount = 1;
          yrCount += 1;
        }

        const row = splicedArray[i];
        $scope.repaymentTableArrayXs.push({ id: i,
          selectText: `Year ${yrCount} Period ${prdCount}`,
          yearText: `Year ${yrCount}`,
          periodText: `Period ${prdCount}`,
          RepaymentAmount: row[1],
          PrincipalPaid: row[3],
          InterestPaid: row[4],
          AccumulativePrincipalPaid: row[5],
          AccumulativeInterestPaid: row[6],
          PrincipalToPay: row[7] });

        prdCount += 1;
      }
      $scope.selectedRepaymentXs = $scope.repaymentTableArrayXs[0];
    };


    $scope.openPdfPreviewModal = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/loanCalculator/partials/pdf_preview.html',
        resolve: {
          graphData() {
            return $scope.graphData;
          },
          options() {
            return $scope.options;
          },
          labels() {
            return $scope.labels;
          },
          colors() {
            return $scope.colors;
          },
          loanAmount() {
            return $scope.repay.loan;
          },
          years() {
            return $scope.repay.years;
          },
          interestRate() {
            return $scope.repay.r;
          },
          frequency() {
            return $scope.repay.frequency;
          },
          totalRepaymentAmount() {
            return $scope.totalRepaymentAmount;
          },
          resultPanelData() {
            return $scope.resultPanelData;
          },
          totalInterest() {
            return $scope.totalInterest;
          },
          series() {
            return $scope.series;
          },
        },
        controller: 'RepaymentPdfPreviewModalCtrl',
        size,

      });
    };


    $scope.fillresultPanel = function () {
      const splicedArray = [];
      angular.copy($scope.calculationObject.RepaymentTableArray, splicedArray);
      splicedArray.splice(0, 1);
      const firstRow = splicedArray[0];
      const lastRow = splicedArray[splicedArray.length - 1];

      $scope.resultPanelData.installment = firstRow[1];
      $scope.resultPanelData.interest = lastRow[6];
    };
    function getLendersList() {
      loanToolsService.getLendersList()
        .then((response) => {
          $scope.lenderList = response.data;
        });
    }
    function localizeHeading(moduleName) {
      const currency = $rootScope.crmCurrency;
      if (currency === 'Rp' && $rootScope.crmCurrency) {
        loanToolsService.LocalizationGet(moduleName)
          .then((response) => {
            $scope.localizeObj = response.data;
            $scope.repaymentTitle = $scope.localizeObj.LocalizationValue;
          });
      }
    }

    $rootScope.$watch('crmCurrency', (newValue) => {
      if (typeof newValue !== 'undefined') {
        localizeHeading('Repayment');
      }
    });


    function init() {
      $scope.loadTable();
      getLendersList();
      localizeHeading('Repayment');
    }

    init();


    /**
         * Start Lending Scenario
         * */

    $scope.backToLendingScenario = function () {
      toaster.pop(
        'info',
        'Redirecting',
        'Going back to Lending Scenario'
      );

      $timeout(
        () => {
          $state.go('app.LendingScenarioSingle', {
            familyId: $window.sessionStorage.getItem('lendingScenarioContact'),
            lendingScenarioId: $window.sessionStorage.getItem('lendingScenario'),
          });

          $window.sessionStorage.removeItem('lendingScenarioContact');
          $window.sessionStorage.removeItem('lendingScenario');
        },
        1000
      );
    };

    $scope.saveToLendingScenario = function () {
      let repayFrequency = 1;
      switch ($scope.repay.frequency) {
      case 'Monthly':
        repayFrequency = 1;
        break;

      case 'Weekly':
        repayFrequency = 2;
        break;

      case 'Fortnightly':
        repayFrequency = 3;
        break;

      default:
        repayFrequency = 1;
        break;
      }


      lendingScenarioService.repaymentCalculatorSet({
        repaymentCalculatorId: 0,
        loanAmount: $scope.repay.loan,
        repaymentFrequencyID: repayFrequency,
        years: $scope.repay.years,
        interestRate: $scope.repay.r,
        monthlyInstallment: $scope.totalRepaymentAmount,
        totalInterest: $scope.totalInterest,

      }).then((response) => {
        lendingScenarioService.repaymentCalculatorLinkSet(
          $scope.lendingScenarioId,
          response.data
        ).then(() => {
          toaster.pop(
            'success',
            'Success',
            'Successful'
          );
          $scope.backToLendingScenario();
        });
      });
    };


    $scope.lendingScenarioId = $window.sessionStorage.getItem('lendingScenario');

    /**
         * END Lending Scenario
         * */
  });
