import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('ReferrerAsMemberNewCtrl',
  function ReferrerAsMemberNewCtrl(
    $scope,
    $uibModalInstance,
    corporateService,
    contactService,
    $rootScope,
    $timeout,
    $q,
    referrerOrganizationId,
    modeForModal,
    callback,
    $stateParams,
    SweetAlert,
    optionsService) {
    const referrerOrgId = referrerOrganizationId;
    const mode = modeForModal;
    $scope.modeForModal = modeForModal;
    $scope.disableInput = false;

    optionsService.getTitleList().then((response) => {
      $scope.titleList = response;
      $scope.titleSelected = $scope.titleList[0];
    });
    $scope.statusList = [{ id: 1, value: 'Active', valueClass: 'active' }, { id: 2, value: 'Inactive', valueClass: 'inactive' }, { id: 3, value: 'In Progress', valueClass: 'in-progress' }];
    $scope.statusListSelectedMembership = $scope.statusList[0];
    $scope.statusListSelectedRelationship = $scope.statusList[0];
    $scope.isAddNewMember = false;
    $scope.isAddNewRelation = false;
    $scope.referrerOrganizationMembershipsByOrganizationGet = {};

    $scope.setStatusMembership = function (data) {
      $scope.statusListSelectedMembership = data;
    };
    $scope.setStatusRelationship = function (data) {
      $scope.statusListSelectedRelationship = data;
    };
    $scope.closeModal = function () {
      $uibModalInstance.dismiss('cancel');
    };


    $scope.referrerOrgList = [];
    function getReferrerOrganization() {
      corporateService.getReferrerOrganization().then((response) => {
        $scope.referrerOrgList = response.data;
      });
    }
    getReferrerOrganization();

    $scope.referrerList = [];
    function getReferrer() {
      corporateService.getReferrer().then((response) => {
        if ($stateParams.familyId) {
          for (let x = 0; x <= response.data.length; x++) {
            if (response.data[x]) {
              if (response.data[x].ReferrerFamilyId === $stateParams.familyId) {
                $scope.disableInput = true;
                $scope.selectedReferrerTmp = response.data[x];
              }
            }
          }
        }

        $scope.referrerList = response.data;
      });
    }
    getReferrer();

    $scope.membershipRoleList = [];
    $scope.membership = {};
    function getMembershipRoles() {
      corporateService.getMembershipRoles().then((response) => {
        $scope.membershipRoleList = response.data;
        if (response.data.length > 0) {
          $scope.membership.roleListSelected = response.data[0];
        }
      });
    }
    getMembershipRoles();

    $scope.membershipList = [];
    function clearMembership() {
      $scope.selectedReferrerOrg = [];
      $scope.isAddNewMember = false;
    }
    $scope.cancelMembership = function () {
      clearMembership();
    };

    function clearRelationship() {
      $scope.selectedAdviserOrg = [];
      $scope.selectedAdviser = [];
      $scope.isAddNewRelation = false;
    }

    $scope.cancelRelationship = function () {
      clearRelationship();
    };

    $scope.adviserOrganizationList = [];

    if ($stateParams.familyId) {
      corporateService.adviserOrganizationListGet().then((response) => {
        $scope.adviserOrganizationList = _.uniqBy(response.data, 'AdviserOrganizationId');
      });
    }

    function getAdviserOrganizationList(referrerOrgIds) {
      corporateService.getOrganizationWithAgreement(referrerOrgIds, 0).then((response) => {
        $scope.adviserOrganizationList = _.uniqBy(response.data, 'AdviserOrganizationId');
      });
    }

    $scope.adviserList = [];
    function getAdviserUnderOrganization(orgId) {
      contactService.getAdviserUnderOrganization(orgId).then((response) => {
        $scope.adviserList = response.data;
      });
    }
    $scope.membershipList = [];

    function getAllReferrersOrgId() {
      return $scope.membershipList.map((elem) => {
        return elem.ReferrerOrganizationId;
      }).join(',');
    }

    $scope.saveMembership = function () {
      if ($scope.selectedReferrerOrg) {
        const data = {
          ReferrerOrganizationId: $scope.selectedReferrerOrg.originalObject.ReferrerOrganizationId,
          ReferrerOrganizationName: $scope.selectedReferrerOrg.originalObject.ReferrerOrganizationName,
          RoleId: $scope.membership.roleListSelected.RoleId,
          RoleDescription: $scope.membership.roleListSelected.RoleDesc,
          Status: $scope.statusListSelectedMembership.value,
        };
        $scope.membershipList.push(data);
        $scope.selectedReferrerOrg = null;
        $scope.isAddNewMember = false;
        getAdviserOrganizationList(getAllReferrersOrgId());
      }
    };
    $scope.relationshipList = [];
    $scope.saveRelationship = function () {
      if ($scope.selectedAdviserOrg) {
        const data = {
          RelationshipId: 0,
          OrganizationId: $scope.selectedAdviserOrg.originalObject.AdviserOrganizationId,
          OrganizationName: $scope.selectedAdviserOrg.originalObject.AdviserOrganizationName,
          AdviserFamilyId: $scope.selectedAdviser.originalObject.FamilyId,
          AdviserName: $scope.selectedAdviser.originalObject.FullName,
          Status: $scope.statusListSelectedRelationship.value,
        };
        $scope.relationshipList.push(data);
        $scope.selectedReferrerOrg = {};
        $scope.isAddNewRelation = false;
      }
    };

    $scope.$watch('selectedAdviserOrg', () => {
      if ($scope.selectedAdviserOrg) {
        if (typeof $scope.selectedAdviserOrg.originalObject !== 'undefined') {
          getAdviserUnderOrganization($scope.selectedAdviserOrg.originalObject.AdviserOrganizationId);
        }
      }
    });

    function saveMembership(clientId, familyId) {
      $scope.membershipList.forEach((data) => {
        const temp = {
          FamilyId: familyId,
          ClientId: clientId,
          ReferrerOrganizationId: data.ReferrerOrganizationId,
          RoleId: data.RoleId,
          Status: data.Status,
        };
        corporateService.setReferrerOrgMembership(temp).then(() => {
          $timeout(() => {
            $uibModalInstance.dismiss('cancel');
          }, 1000);
        });
      });
    }

    function saveRelationship(clientId, familyId) {
      $scope.relationshipList.forEach((data) => {
        const temp = {
          FamilyId: familyId,
          ClientId: clientId,
          Relationship: 0,
          OrganizationId: data.OrganizationId,
          AdviserFamilyId: data.AdviserFamilyId,
          Status: data.Status,
        };
        corporateService.setReferrerOrgRelationship(temp).then(() => {
        });
      });
    }

    // Save to DB
    function saveReferrer() {
      const data = {
        ClientID: 0,
        FamilyId: 0,
        FranchiseeDetailsId: 0,
        Title: $scope.titleSelected.value,
        FirstName: $scope.firstName || '',
        MiddleName: '',
        LastName: $scope.lastName || '',
        PreferredName: '',
        AdviserOrganizationId: referrerOrgId,
        WorkNumber: $scope.phone || '',
        Mobile: '',
        Fax: '',
        Email: $scope.email || '',
        BrokerStatusId: 0,
        Description: '',
        RecruitedBy_FamilyId: 0,
        ContactType: 'referrer',
        AccountOwner_FamilyId: 0,
        ReportingCountryId: 0,
        ReportingStateId: 0,
        ReportingRegionId: 0,
        StartDate: '',
        DOB: '',
        Gender: 3,
        SecondaryEmail: '',
        HomeNumber: '',
        LanguagesSpoken: '',
        GeneralInterests: '',
        SportsTeam: '',
        ReceiveRecruitNewsLetter: 0,
        CommissionTierId: 0,
        AchievementId: 0,
        IndividualAchievementId: 0,
        JobTitle: '',
        PreviousAggregator: '',
        BrokerReferredBy: '',
        EnquirySourceId: 0,
        AppointmentEntity: '',
        RoleId: '',
        EmploymentType: '',
        EntityTypeId: '',
        AppointmentEntityShareholder: '',
        ABN: '',
        ACN: '',
        DisplayTitle: '',
        Location: '',
        EmploymentTypeId: 0,
      };
      contactService.setAdviser(0, data).then((response) => {
        if (response.data > 0) {
          saveMembership(response.data, 0);
          saveRelationship(response.data, 0);
        } else {
          SweetAlert.swal('Oops...', 'Something went wrong!', 'error');
        }
      });
    }

    $scope.saveAll = function () {
      if (mode === 'add') {
        saveReferrer();
      } else if (mode === 'existing') {
        if (typeof $scope.selectedReferrer !== 'undefined' && $scope.selectedReferrer != null) {
          saveMembership(0, $scope.selectedReferrer.originalObject.ReferrerFamilyId);
          saveRelationship(0, $scope.selectedReferrer.originalObject.ReferrerFamilyId);
        } else {
          SweetAlert.swal('Required', 'Please select existing Referrer!', 'warning');
        }
      }
    };
  });
