import angular from 'angular';

angular.module('app').controller('GetBirthdayDetailModalCtrl', function GetBirthdayDetailModalCtrl(
  $scope,
  $uibModalInstance,
  birthday) {
  $scope.birthday = birthday;
  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
});
