const getMimeType = (fileType) => {
  switch (fileType) {
  case 'pdf':
    return 'application/pdf';
  case 'jpg':
  case 'jpeg':
    return 'image/jpeg';
  case 'png':
    return 'image/png';
  case 'xls':
    return 'application/vnd.ms-excel';
  case 'docx':
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  default:
    return `application/${fileType}`;
  }
};

export default class ProviderInfoDownloadables {
  constructor(downloadDataService) {
    'ngInject';

    this.downloadDataService = downloadDataService;
  }

  download(doc) {
    const fileNameSplits = doc.fileName.split('.');
    const type = getMimeType(fileNameSplits[fileNameSplits.length - 1]);
    const content = `data:${type};base64,${doc.content}`;
    this.downloadDataService.download(content, doc.fileName, type);
  }
}
