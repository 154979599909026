import './style.scss';
import SaveProfilerToContactCtrl from './saveProfilerToContactCtrl';

export default {
  bindings: {
    profilerData: '<',
  },
  controller: SaveProfilerToContactCtrl,
  controllerAs: 'vm',
  template: `
  <button class="btn button-ip light-green" ng-click="vm.clickListener()"><i class="fa ti-plus"></i>Save</button>
  `,
};
