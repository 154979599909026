import angular from 'angular';
import _ from 'lodash';
import { filterCapped } from 'Common/utilities/productFinder';

angular.module('app').controller('ProductFinderCtrl',
  function ProductFinderCtrl($scope,
    $uibModal,
    SweetAlert,
    loanProfilerService,
    downloadDataService,
    selectOptionsService,
    loanScenarioService,
    contactService,
    NgTableParams,
    $window,
    $location,
    $state,
    toaster,
    cfpLoadingBar,
    $timeout,
    $rootScope,
    $filter,
    lendingScenarioService,
    $localStorage,
    uiService,
    commonFnService,
    productFinderService,
    bouncingTooltipService,
    lenderRatesService,
    currentUserService) {
    $scope.fromLenderRateObject = {
      isFromLenderRates: !!Object.keys(lenderRatesService.getLenderRateInfoForProductFinder()).length,
    };

    $scope.brokerEventId = $state.params.brokerEventId;
    $scope.loanScenarioId = $state.params.loanScenarioId;
    $scope.isSettingDisplyInMobile = true;

    const putProductFinderSettingsFromLenderRates = (lenderRateData) => {
      const filter = {
        generalSetting: {
          lender: lenderRateData.lenderIdArray,
          loanTerm: 0,
          loanAmount: '',
          security: '',
          lvr: '',
          splitLoan: true,
        },
        customSelection: {
          loanAmount: 0,
          depositAmount: 0,
          maxRate: lenderRateData.CurrentRate,
          rateType: lenderRateData.rateType,
          financials: '',
          productType: '',
          intention: '',
          repayment: '',
          advanceFilters: [
            {
              name: '',
              value: '',
              isChecked: true,
              CountryID: 0,
            },
          ],
          loanStructureId: 0,
        },
      };
      if (!$state.params.brokerEventId) return;
      loanProfilerService.putProductFinderSettings($state.params.brokerEventId, filter)
        .then(() => {
          const loanStructure = {};
          $scope.loadProductFinderList(loanStructure);
        });
    };

    const lenderRatesProductFinderProcedure = () => {
      if (!$scope.fromLenderRateObject.isFromLenderRates) return;

      putProductFinderSettingsFromLenderRates(lenderRatesService.getLenderRateInfoForProductFinder() || {});
    };
    // Product Finder Tab
    //--------------------------------------------------------------------------------------
    bouncingTooltipService.resetTooltipVisibility();

    // Serviceability Filter Dropdown Data
    $scope.getFilterData = function () {
      $scope.filtersParamtersList = {};
      $scope.loanProviderLendersListGetAll = [];
      $scope.loanProviderLendersListSet = [];
      loanProfilerService.getServiceabilityFiltersParamters().then((productFinderResponse) => {
        if (!productFinderResponse || !productFinderResponse.data) return;
        $scope.filtersParamtersList = productFinderResponse.data;
        $scope.loanProviderLendersListGetAll = productFinderResponse.data.Lenders && productFinderResponse.data.Lenders.reduce((accum, productFinderItem) => {
          const length = 12;
          const trimmedString = productFinderItem.Name.length > length ? `${productFinderItem.Name.substring(0, length - 3)}...` : productFinderItem.Name;
          if (productFinderItem.Value !== 'FavouriteLenders') {
            return [...accum, {
              name: productFinderItem.Name,
              shortName: trimmedString,
              value: productFinderItem.Value,
              ticked: false,
            }];
          }
          return accum;
        }, []);
        $scope.getDefaultFilterSelection();
        $scope.getLoanStructure(true);
      });
    };

    // Serviceability Filter Dropdown Data Set
    // ----------------------------------------------------------------------------
    $scope.getCustomerSelectionFilterData = function (loanStructure) {
      loanStructure.getFilterDataList = [];
      loanStructure.lenderSelection = [];
      if (!$scope.brokerEventId || !loanStructure.LoanStructureID) return;
      loanProfilerService.getServiceabilityFiltersParamtersCustomerSelection($scope.brokerEventId, loanStructure.LoanStructureID).then((response) => {
        if (!response.data && !response.data.AdvanceFilters) return;
        loanStructure.getFilterDataList = response.data;
        const { AdvanceFilters: advanceFilters } = response.data;
        if (currentUserService.isNZ) {
          loanStructure.getFilterDataList.AdvanceFilters = filterCapped(advanceFilters);
        }
        loanStructure.lenderSelection = response.data.Lender;
        const productFinderValidateHasNoLoanAmount = (typeof loanStructure.getFilterDataList.LoanAmount === 'undefined' || loanStructure.getFilterDataList.LoanAmount === 0);
        const productFinderValidateHasLoanAmount = loanStructure.getFilterDataList.LoanAmount !== '';
        if (productFinderValidateHasNoLoanAmount) {
          loanStructure.getFilterDataList.LoanAmount = '';
          if ($scope.defaultSelection && $scope.defaultSelection.loanAmount > 0) {
            loanStructure.getFilterDataList.LoanAmount = $scope.defaultSelection.loanAmount;
          }
        } else if (productFinderValidateHasLoanAmount) {
          loanStructure.getFilterDataList.LoanAmount = Number(loanStructure.getFilterDataList.LoanAmount);
        }
        loanStructure.loanProviderLendersListSet = [];
        if (typeof response.data.Lender !== 'undefined' && response.data.Lender.length !== 0) {
          angular.forEach(response.data.Lender, (item) => {
            const data = _.find(loanStructure.loanProviderLendersListGetAll, (itemGet) => {
              if (itemGet.value === item) {
                itemGet.ticked = true;
              }
              return itemGet.value === item;
            });
            if (typeof data !== 'undefined')
              loanStructure.loanProviderLendersListSet.push(data);
          });
        }
        $scope.isSerachdesabled = false;
      });
    };
    // Get Selected Product List
    $scope.getSelectedProductList = function (loanStructure) {
      if (!$scope.brokerEventId || !loanStructure.LoanStructureID) return;
      loanProfilerService.getFavouriteProduct($scope.brokerEventId, loanStructure.LoanStructureID).then((response) => {
        loanStructure.selectedProduct = response.data;
        $scope.showSelctedProduct = 2;
        if ($scope.screenSize > 1440)
          $scope.showSelctedProduct = 3;
        else
          $scope.showSelctedProduct = 2;
      });
    };

    // Get Product List
    $scope.loadProductFinderList = function (loanStructure) {
      if (!$scope.brokerEventId || !loanStructure) return;
      $scope.countPerPage = 10;
      loanStructure.IsSearchByLender = false;
      loanStructure.LoanStructureID = $scope.fromLenderRateObject.isFromLenderRates ? 0 : loanStructure.LoanStructureID;
      loanStructure.productFinderTableParams = new NgTableParams({
        page: 1,
        count: $scope.countPerPage,
        sorting: {
          InitialRate: 'asc',
        },
        defaultSort: 'asc',
      }, {
        total: 0,
        getData($defer, params) {
          loanStructure.isProductLoaderOverlayDisplay = true;
          if (loanStructure.IsSearchByLender === false) {
            const sortColumn = params.orderBy()[0].substring(1);
            const sortType = _.values(params.sorting())[0].toUpperCase();

            loanProfilerService.getServiceabilityProductFinder(
              $scope.brokerEventId,
              params.page(),
              sortColumn,
              sortType,
              params.count(),
              loanStructure.LoanStructureID,
              null,
              $scope.fromLenderRateObject.isFromLenderRates)
              .then((response) => {
                if (response.data && response.data[0]) {
                  params.total(response.data[0].TotalRecord);
                } else {
                  params.total(0);
                }

                if (uiService.isCountry('Australia')) {
                  productFinderService.returnAddedSpreadsheetToProductList(response.data)
                    .then((withSpreadsheet) => {
                      if (withSpreadsheet) {
                        $defer.resolve(withSpreadsheet);
                        loanStructure.isProductLoaderOverlayDisplay = false;
                      }
                    });
                } else {
                  $defer.resolve(response.data);
                  loanStructure.isProductLoaderOverlayDisplay = false;
                }
              });
          } else {
            loanProfilerService.lenderListWithProductCountGet($scope.brokerEventId, loanStructure.LoanStructureID, params.page(), params.count(), null, null, $scope.fromLenderRateObject.isFromLenderRates)
              .then((response) => {
                if (response.data && response.data.Count) {
                  params.total(response.data.Count);
                } else {
                  params.total(0);
                }
                $defer.resolve(response.data.LenderProducts);
                loanStructure.isProductLoaderOverlayDisplay = false;
              });
          }
        },
      });
    };

    // Get Data When Expand Lender
    $scope.getGroupByLenderProduct = function (lender, loanStructure) {
      lender.$hideRows = !lender.$hideRows;
      if (lender.$hideRows === true && $scope.brokerEventId && loanStructure.LoanStructureID && lender.LenderID) {
        loanProfilerService.getServiceabilityProductFinder($scope.brokerEventId, undefined, undefined,
          undefined, undefined, loanStructure.LoanStructureID, lender.LenderID).then((response) => {
          lender.productData = response.data;
          _.map(loanStructure.productFinderTableParams.data, (lenderItem) => {
            if (lenderItem.LenderID !== lender.LenderID) {
              lenderItem.$hideRows = false;
            }
          });
        });
      }
    };

    // Loan Structure Wise get All Detail of Product
    //-----------------------------------------------------------------------------
    $scope.loanStructureList = [];

    $scope.getLoanStructure = function (isOverlay) {
      if (!$scope.brokerEventId) return;
      loanProfilerService.getProductFinderLoanStructureList($scope.brokerEventId).then((response) => {
        $scope.loanStructureList = response.data;
        if ($scope.loanStructureList.length === 0) {
          $scope.saveLoanStructure();
        }
        angular.forEach($scope.loanStructureList, (loanStructure) => {
          loanStructure.loanProviderLendersListGetAll = [];
          loanStructure.selectedProduct = [];

          $scope.loadProductFinderList(loanStructure);

          $scope.getCustomerSelectionFilterData(loanStructure);
          $scope.getSelectedProductList(loanStructure);
          if (isOverlay)
            $scope.updateOverlaySearch();
        });
      });
    };

    // Change Sorting
    $scope.changeSorting = function (loanStructure, $column) {
      if (typeof $column.sortType === 'undefined')
        $column.sortType = 'asc';
      else if ($column.sortType === 'asc')
        $column.sortType = 'desc';
      else {
        $column.sortType = 'asc';
      }
      loanStructure.productFinderTableParams.sorting($column.sortable(), $column.sortType);
      loanStructure.productFinderTableParams.reload();
    };


    // For general setting save first time
    $scope.applySettings = () => {
      $scope.isSettingDisplyInMobile = false;
      $scope.setDefaultFilterSelection();
    };

    $scope.selectedProductCount = 0;
    // Product Comparison Tab
    //-----------------------------------------------------------------------------
    $scope.getSelectedProductDetailsGetByLoanStructure = function () {
      $scope.selectedProductCount = 0;
      $scope.subTotal = 0;
      if (!$scope.brokerEventId) return;
      loanProfilerService.getSelectedProductDetailsGetByLoanStructure($scope.brokerEventId).then((response) => {
        response.data.forEach((item) => {
          if (!item || !item.SelectedProduct) return;
          $scope.selectedProductCount += item.SelectedProduct.length;

          const tollTipState = item.SelectedProduct.find(
            tooltipItem => !tooltipItem.IsToolTipShown
          );
          item.SelectedProduct = item.SelectedProduct.map((itemValue) => {
            itemValue.IsToolTipShownPopOver = false;
            return itemValue;
          });
          if (!tollTipState) {
            let isTollTipAllow = true;
            const recommendState = item.SelectedProduct.find(
              tooltipItem => tooltipItem.IsRecommended
            );
            if (!recommendState) {
              item.SelectedProduct = item.SelectedProduct.map((itemValue) => {
                if (isTollTipAllow && itemValue.IsToolTipShown) {
                  itemValue.IsToolTipShownPopOver = true;
                  isTollTipAllow = false;
                }
                return itemValue;
              });
            }
          }
        });
        $scope.loanStructureWiseSelectedProducts = response.data;
        angular.forEach($scope.loanStructureWiseSelectedProducts, (item) => {
          item.selectedProductDataList = [];
          $scope.subtotal = 0;
          loanProfilerService.getFavouriteProduct($scope.brokerEventId, item.LoanStructureID).then((productResponse) => {
            item.selectedProductDataList = productResponse.data;
            if (item.selectedProductDataList.length > 0) {
              $scope.subTotal += 1;
            }
          });
        });
        $scope.loanStructureWiseSelectedProductsMobile = angular.copy(response.data);
        if (typeof $scope.recommendInMobile !== 'undefined') {
          const itemMobile = $scope.itemMobileFn();
          if (typeof itemMobile !== 'undefined') {
            const itemMobileData = _.find(itemMobile.SelectedProduct, (item) => {
              if (parseInt($scope.recommendInMobile.ProductID, 10) === parseInt(item.ProductID, 10)) {
                return item;
              }
            });
            if (typeof itemMobileData !== 'undefined')
              $scope.recommendInMobile = itemMobileData;
          }
        }
        let blankSelectedProductObject = {};
        let len = 0;
        angular.forEach($scope.loanStructureWiseSelectedProducts, (item) => {
          if (item.SelectedProduct.length > 0 && item.SelectedProduct.length < 3) {
            len = item.SelectedProduct.length;
            for (let i = 0; i < 3 - len; i++) {
              blankSelectedProductObject = {
                LenderID: i.toString(),
                LenderName: 'empty',
                Country: 'empty',
                ProductID: 'empty',
                ProductName: 'empty',
                ComparisonRate: 'empty',
                InitialRate: 'empty',
                OngoingRate: 'empty',
                MaxLVR: 'empty',
                MaxLoanAmount: 'empty',
                UpFrontFee: 'empty',
                OngoingMonthlyFee: 'empty',
                AnnualFees: 'empty',
                DirectSalaryCredit: 'empty',
                CreditCard: 'empty',
                ChequeAccount: 'empty',
                InternetBanking: 'empty',
                MaxLoanSplits: 'empty',
                Notes: 'empty',
                OffsetAccount: 'empty',
                RepaymentFrequencyTypeOption: 'empty',
                RepaymentMonthlyInitial: 'empty',
                RepaymentMonthlyOngoing: 'empty',
                Redraw: 'empty',
                ExtraRepayments: 'empty',
              };
              item.SelectedProduct.push(blankSelectedProductObject);
            }
          }
        });
      });
    };
    if ($state.params.tab === 'productFinder') {
      if ($scope.fromLenderRateObject.isFromLenderRates) {
        $scope.getLoanStructure(true);
        lenderRatesProductFinderProcedure();
      } else {
        $scope.getFilterData();
        $scope.getSelectedProductDetailsGetByLoanStructure();
      }
    }

    // Add Loan Structure
    $scope.saveLoanStructure = function () {
      if (!$scope.brokerEventId) return;
      const postData = {
        BrokerEventID: Number($scope.brokerEventId),
      };

      loanProfilerService.setNewProductLoanStructure(postData).then((response) => {
        if (parseInt(response.data, 10) !== 0) {
          if ($scope.loanStructureList.length !== 0) {
            toaster.pop('success', 'Added', 'Loan split added Successfully');
          }
          loanProfilerService.getProductFinderLoanStructureList($scope.brokerEventId).then((loanStructureResponse) => {
            const $loanStructureListOldData = angular.copy($scope.loanStructureList);
            const $loanStructureListNewData = loanStructureResponse.data;
            const $differData = _.differenceBy($loanStructureListNewData, $loanStructureListOldData, 'LoanStructureID');
            if (typeof $differData !== 'undefined') {
              angular.forEach($differData, (loanStructure) => {
                $scope.loanStructureList.push(loanStructure);
                loanStructure.loanProviderLendersListGetAll = [];
                loanStructure.selectedProduct = [];

                $scope.loadProductFinderList(loanStructure);

                $scope.getCustomerSelectionFilterData(loanStructure);
                $scope.getSelectedProductList(loanStructure);
                loanStructure.isProductSearchOverlayDisplay = true;
                $rootScope.isProductSearchOverlayDisplay = loanStructure.isProductSearchOverlayDisplay;
              });
            }
          });
        } else {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      });
    };

    // Delete Loan Structure
    $scope.deleteLoanStructure = function (loanStructure, isMsg) {
      if (!loanStructure) return;
      if (Math.floor(loanStructure.LoanStructureID) === 0) {
        const index = $scope.newLoanStructureList.indexOf(loanStructure);
        if (index > -1)
          $scope.newLoanStructureList.splice(index, 1);
      } else {
        if (isMsg)
          toaster.pop('info', 'Please Wait...', '');
        if (!$scope.brokerEventId) return;
        loanProfilerService.deleteProductFinderLoanStructure($scope.brokerEventId, loanStructure.LoanStructureID).then((response) => {
          if (parseInt(response.data, 10) === 1) {
            $scope.getLoanStructure(true);
            if (isMsg)
              toaster.pop('success', 'Deleted', 'Loan split deleted Successfully');
            $timeout(() => {
              $scope.isLoanStructureDetail = false;
            }, 500);
            $scope.getSelectedProductDetailsGetByLoanStructure();
          } else {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        });
      }
    };

    $scope.deleteMoreLoanStructure = function () {
      if ($scope.defaultSelection.splitLoan === false) {
        if ($scope.loanStructureList.length > 1) {
          angular.forEach($scope.loanStructureList, (item, key) => {
            if (parseInt(key, 10) !== 0) {
              $scope.deleteLoanStructure(item, false);
            }
          });
        }
      }
    };

    // Update LVR
    $scope.updateLVR = function () {
      if ($scope.defaultSelection && $scope.defaultSelection.loanAmount && $scope.defaultSelection.security) {
        $scope.lvrDropDownVale = ($scope.defaultSelection.loanAmount / $scope.defaultSelection.security) * 100;
        if ($scope.lvrDropDownVale > 100 || Number($scope.lvrDropDownVale, 2) === 0)
          $scope.lvrDropDownVale = 0;

        if ($scope.lvrDropDownVale !== 0) {
          if (Number($scope.lvrDropDownVale, 2) === 80) {
            $scope.defaultSelection.LVR = '80.01';
          } else
            $scope.defaultSelection.LVR = `+${Number($scope.lvrDropDownVale, 2).toFixed(2)}`;
        }
      }
    };

    // Get Default Filter Selection
    $scope.getDefaultFilterSelection = function () {
      $scope.defaultSelection = {};
      if (!$scope.brokerEventId) return;
      loanProfilerService.getServiceabilityFilteringProductsGeneralSettings($scope.brokerEventId).then((response) => {
        $scope.defaultSelection = response.data;
        if (Number($scope.defaultSelection.loanAmount) === 0) {
          $scope.defaultSelection.loanAmount = '';
        } else if ($scope.defaultSelection.loanAmount !== '')
          $scope.defaultSelection.loanAmount = Number($scope.defaultSelection.loanAmount);
        if (!$scope.defaultSelection.security || Number($scope.defaultSelection.security) === 0)
          $scope.defaultSelection.security = '';
        else
          $scope.defaultSelection.security = Number($scope.defaultSelection.security);
        $scope.lenderSelection = response.data.Lender;
        $scope.loanProviderLendersListSet = [];
        if (typeof response.data.Lender !== 'undefined' && response.data.Lender.length !== 0) {
          angular.forEach($scope.defaultSelection.Lender, (item) => {
            const data = _.find($scope.loanProviderLendersListGetAll, (itemGet) => {
              if (itemGet.value === item) {
                itemGet.ticked = true;
              }
              return itemGet.value === item;
            });
            if (typeof data !== 'undefined')
              $scope.loanProviderLendersListSet.push(data);
          });
        }
        $scope.updateLVR();
      });
    };

    $scope.changeFirstLoanAmountValue = function () {
      if ($scope.loanStructureList && $scope.loanStructureList.length && $scope.loanStructureList[0].getFilterDataList && $scope.defaultSelection) {
        $scope.loanStructureList[0].getFilterDataList.LoanAmount = Number($scope.defaultSelection.loanAmount);
      }
    };

    // Set Default Filter Selection
    $scope.setDefaultFilterSelection = function () {
      const $lenders = _.map($scope.loanProviderLendersListSet, 'value');
      if (typeof $scope.defaultSelection !== 'undefined' && typeof $scope.defaultSelection.Lender !== 'undefined') {
        $scope.defaultSelection.Lender = [];
        $scope.defaultSelection.Lender = $lenders;
      }
      if (!$scope.defaultSelection || !$scope.defaultSelection.brokerEventID) return;
      loanProfilerService.updateServiceabilityFilteringProductsSettings($scope.defaultSelection.brokerEventID, { GeneralSetting: $scope.defaultSelection }).then((response) => {
        if (!response || response.data) {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      });
    };

    $scope.updateFilterData = function (loanStructure, isLoadTable) {
      if (!loanStructure || !$scope.defaultSelection) return;
      if (!$scope.defaultSelection.loanAmount) {
        toaster.pop('error', 'Invalid', 'Please first set the total loan amount');
        loanStructure.isProductSearchOverlayDisplay = true;
        $rootScope.isProductSearchOverlayDisplay = loanStructure.isProductSearchOverlayDisplay;

        return;
      } else if (!$scope.defaultSelection.security) {
        toaster.pop('error', 'Invalid', 'Please first set the security amount');
        loanStructure.isProductSearchOverlayDisplay = true;
        $rootScope.isProductSearchOverlayDisplay = loanStructure.isProductSearchOverlayDisplay;

        return;
      }
      let $totalLoanAmount = 0;
      angular.forEach($scope.loanStructureList, (item) => {
        $totalLoanAmount += parseInt(item.getFilterDataList.LoanAmount, 10);
      });
      if ($totalLoanAmount > $scope.defaultSelection.loanAmount) {
        toaster.pop('error', 'Invalid', 'Total of Loan Splits cannot exceed the Total Loan Amount');
        loanStructure.isProductSearchOverlayDisplay = true;
        $rootScope.isProductSearchOverlayDisplay = loanStructure.isProductSearchOverlayDisplay;

        return;
      }
      loanStructure.isProductSearchOverlayDisplay = false;
      $rootScope.isProductSearchOverlayDisplay = loanStructure.isProductSearchOverlayDisplay;

      if (isLoadTable)
        loanStructure.isProductLoaderOverlayDisplay = true;
      const postData = {
        CustomSelection: {
          DepositAmount: loanStructure.getFilterDataList.DepositAmount,
          MaxRate: loanStructure.getFilterDataList.MaxRate,
          Financials: loanStructure.getFilterDataList.Financials,
          ProductType: loanStructure.getFilterDataList.ProductType,
          Intention: loanStructure.getFilterDataList.Intention,
          RateType: loanStructure.getFilterDataList.RateType,
          AdvanceFilters: loanStructure.getFilterDataList.AdvanceFilters,
          LoanAmount: loanStructure.getFilterDataList.LoanAmount || 0,
          Repayment: loanStructure.getFilterDataList.Repayment,
          loanStructureID: loanStructure.LoanStructureID,
        },
      };

      if (!$scope.brokerEventId) return;
      loanProfilerService.updateServiceabilityFilteringProductsSettings($scope.brokerEventId, postData).then((response) => {
        if (response && !response.data) {
          if (isLoadTable === true) {
            $scope.loadProductFinderList(loanStructure);
            bouncingTooltipService.setTooltipVisible(true);
          }
          $timeout(() => {
            $rootScope.isSaveContactEnable = false;
          }, 500);
        } else {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      });
    };

    // Update First Loan Structure Filter Data
    $scope.updateFirstFilterData = function (loanStructure) {
      if (!loanStructure || !loanStructure.getFilterDataList) return;
      loanStructure.getFilterDataList.LoanAmount = loanStructure.getFilterDataList.LoanAmount === null || loanStructure.getFilterDataList.LoanAmount === '' ? 0 : loanStructure.getFilterDataList.LoanAmount;
      const postdata = {
        CustomSelection: {
          DepositAmount: loanStructure.getFilterDataList.DepositAmount,
          MaxRate: loanStructure.getFilterDataList.MaxRate,
          Financials: loanStructure.getFilterDataList.Financials,
          ProductType: loanStructure.getFilterDataList.ProductType,
          Intention: loanStructure.getFilterDataList.Intention,
          RateType: loanStructure.getFilterDataList.RateType,
          AdvanceFilters: loanStructure.getFilterDataList.AdvanceFilters,
          LoanAmount: parseInt(loanStructure.getFilterDataList.LoanAmount, 10),
          Repayment: loanStructure.getFilterDataList.Repayment,
          loanStructureID: loanStructure.LoanStructureID,
        },
      };

      if (!$scope.brokerEventId) return;
      loanProfilerService.updateServiceabilityFilteringProductsSettings($scope.brokerEventId, postdata).then((response) => {
        if (!response || response.data) {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      });
    };


    // Set overlay when genral setting change
    $scope.updateOverlaySearch = function () {
      angular.forEach($scope.loanStructureList, (loanStructure) => {
        loanStructure.isProductSearchOverlayDisplay = true;
        $rootScope.isProductSearchOverlayDisplay = loanStructure.isProductSearchOverlayDisplay;
      });
    };

    // Update Default Lenders List
    $scope.updateDefaultLendersListSet = function (LendersListSet) {
      const lendersListData = _.find(LendersListSet, (item) => {
        return item.value === 'ALL';
      });
      const loanProviderLendersListData = _.find($scope.loanProviderLendersListSet, (item) => {
        return item.value === 'ALL';
      });
      const productFinderValidateHasNoLendersList = (typeof lendersListData === 'undefined' && typeof loanProviderLendersListData !== 'undefined');
      const productFinderValidateHasLendersList = (typeof lendersListData !== 'undefined' && typeof loanProviderLendersListData !== 'undefined');
      const productFinderValidateHasNoProviderLenderList = (typeof lendersListData !== 'undefined' && typeof loanProviderLendersListData === 'undefined');
      if (productFinderValidateHasNoProviderLenderList) {
        angular.forEach(LendersListSet, (productFinderItem) => {
          if (productFinderItem.value !== 'ALL') {
            _.map($scope.loanProviderLendersListGetAll, (productFinderItemGet) => {
              if (productFinderItemGet.value === productFinderItem.value) {
                productFinderItemGet.ticked = false;
              }
              return productFinderItemGet;
            });
          }
        });
        $scope.loanProviderLendersListSet = [];
        $scope.loanProviderLendersListSet.push(lendersListData);
      } else if (productFinderValidateHasNoLendersList || productFinderValidateHasLendersList) {
        _.map($scope.loanProviderLendersListGetAll, (productFinderItemGet) => {
          if (productFinderItemGet.value === 'ALL') {
            productFinderItemGet.ticked = false;
          }
          return productFinderItemGet;
        });
        $scope.loanProviderLendersListSet = LendersListSet;
        const index = $scope.loanProviderLendersListSet.indexOf(lendersListData);
        if (index > -1)
          $scope.loanProviderLendersListSet.splice(index, 1);
      } else {
        $scope.loanProviderLendersListSet = LendersListSet;
      }
      $scope.setDefaultFilterSelection();
    };

    // Select Default All Lender
    $scope.selectDefaultAllLender = function () {
      _.map($scope.loanProviderLendersListGetAll, (selectDefaultAllLenderItemGet) => {
        if (selectDefaultAllLenderItemGet.value === 'ALL') {
          $scope.loanProviderLendersListSet = [];
          $scope.loanProviderLendersListSet.push(selectDefaultAllLenderItemGet);
        } else {
          selectDefaultAllLenderItemGet.ticked = false;
        }
        return selectDefaultAllLenderItemGet;
      });
      $scope.setDefaultFilterSelection();
    };

    // Select Default None Lender
    $scope.selectDefaultNoneLender = function () {
      _.map($scope.loanProviderLendersListGetAll, (selectNoneLenderItemGet) => {
        selectNoneLenderItemGet.ticked = false;
        return selectNoneLenderItemGet;
      });
      $scope.loanProviderLendersListSet = [];
      $scope.setDefaultFilterSelection();
    };

    // Set Initial Rate and Ongoing Rate
    $scope.setFavouriteProductRates = function (loanStructureId, productId, rateType, newRate) {
      const postData = {
        BrokerEventID: $scope.brokerEventId,
        LoanStructureID: loanStructureId,
        ProductID: productId,
        RateType: rateType,
        NewRate: newRate,
      };
      loanProfilerService.setFavouriteProductRates(postData).then((response) => {
        if (response.data === 1) {
          $scope.getSelectedProductDetailsGetByLoanStructure();
        }
      });
    };
    $scope.numberLoaded = true;
    $scope.setFavouriteProductRatesInMobile = function (loanStructureId, productId, rateType, newRate) {
      if (!$scope.brokerEventId) return;
      const postData = {
        BrokerEventID: $scope.brokerEventId,
        LoanStructureID: loanStructureId,
        ProductID: productId,
        RateType: rateType,
        NewRate: newRate,
      };
      $scope.isCompareDetail = false;
      $scope.slickConfig.enabled = false;

      loanProfilerService.setFavouriteProductRates(postData).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          loanProfilerService.getSelectedProductDetailsGetByLoanStructure($scope.brokerEventId).then((loanStructureResponse) => {
            angular.forEach(loanStructureResponse.data, (item) => {
              $scope.selectedProductCount += item.SelectedProduct.length;

              const tollTipState = _.find(item.SelectedProduct, (tooltipItem) => {
                return !tooltipItem.IsToolTipShown;
              });
              _.map(item.SelectedProduct, (itemValue) => {
                itemValue.IsToolTipShownPopOver = false;
              });
              if (typeof tollTipState === 'undefined') {
                let isAllow = true;
                const recommendState = _.find(item.SelectedProduct, (tooltipItem) => {
                  return tooltipItem.IsRecommended;
                });
                if (typeof recommendState === 'undefined') {
                  _.map(item.SelectedProduct, (itemValue) => {
                    if (isAllow) {
                      if (itemValue.IsToolTipShown) {
                        itemValue.IsToolTipShownPopOver = true;
                        isAllow = false;
                      }
                    }
                  });
                }
              }
            });
            $scope.loanStructureWiseSelectedProductsMobile = [];
            $scope.loanStructureWiseSelectedProductsMobile = loanStructureResponse.data;
            if (typeof $scope.recommendInMobile !== 'undefined') {
              const itemMobile = $scope.itemMobileFn();
              if (typeof itemMobile !== 'undefined') {
                const itemMobileData = _.find(itemMobile.SelectedProduct, (item) => {
                  if ($scope.recommendInMobile.ProductID === item.ProductID) {
                    return item;
                  }
                });
                if (typeof itemMobileData !== 'undefined')
                  $scope.recommendInMobile = itemMobileData;
              }
            }
            const loanStructureData = $scope.itemMobileFn();
            const mobileProduct = $scope.selectedProductInMobile;
            const isValidMobileProduct = mobileProduct && mobileProduct.SelectedProduct && mobileProduct.SelectedProduct.length;
            const isProductSelected = isValidMobileProduct && loanStructureData && loanStructureData.SelectedProduct && loanStructureData.SelectedProduct.length && $scope.currentIndex;
            if (isProductSelected) {
              $scope.selectedProductInMobile.SelectedProduct[$scope.currentIndex] = loanStructureData.SelectedProduct[$scope.currentIndex];
            }
            $scope.slickConfig.enabled = true;
            $scope.isCompareDetail = true;
          });
        }
      });
    };

    // Open Modal For Set Initial Rate and Ongoing Rate In Mobile
    $scope.openModalForSetRate = function (rateType, loanStructure, productId, oldRate, newRate, size) {
      $uibModal.open({
        templateUrl: '/assets/views/loanProfiler/partials/modal/new_rate_modal.html',
        scope: $scope,
        controller: 'ForSetRateModalCtrl',
        size,
        resolve: {
          setFavouriteProductRatesInMobile() {
            return $scope.setFavouriteProductRatesInMobile;
          },
          rateType() {
            return rateType;
          },
          loanStructure() {
            return loanStructure;
          },
          productId() {
            return productId;
          },
          oldRate() {
            return oldRate;
          },
          newRate() {
            return newRate;
          },
        },
      });
    };
    $scope.ldLoading = {
      zoom_in: false,
    };
    $scope.printPdf = (product) => {
      $scope.ldLoading.zoom_in = true;
      loanProfilerService.productPdf(product.ProductID)
        .then((response) => {
          const data = response;
          $scope.ldLoading.zoom_in = false;

          if (!data || !data.contentType || !data.documentContent || !data.name) return;
          downloadDataService.download(`data:${data.contentType};base64,${data.documentContent}`, `${data.name}`, data.contentType);
          toaster.pop('success', 'PDF Ready', 'Successfully generated product PDF');
        }, () => {
          toaster.pop('error', 'No Product', 'There are 0 product to print.');
        });
    };
    // Select Product in LoanStructure
    $scope.selectLoanStructureProduct = function (loanStructure, product) {
      product.isAddedForComparison = !product.isAddedForComparison;
      const postData = {
        brokerEventID: $scope.brokerEventId,
        ProductID: product.ProductID,
        LenderID: product.LenderID,
        loanStructureID: loanStructure.LoanStructureID,
        IsFavourite: product.isAddedForComparison,
      };
      loanProfilerService.setFavouriteProduct(postData).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          $scope.getSelectedProductList(loanStructure);
          angular.element(`.nav-tabs-${loanStructure.LoanStructureID} li.more-product-${loanStructure.LoanStructureID} ul li.contact-parent-tab`).remove();
          angular.element(`.nav-tabs-${loanStructure.LoanStructureID} > li.contact-parent-tab`).remove();
          $timeout(() => {
            $scope.calcWidth(loanStructure);
          }, 100);
          $scope.getSelectedProductDetailsGetByLoanStructure();
        } else
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
      });
    };
    // Select Product in LoanStructure
    $scope.deleteLoanStructureProduct = function (loanStructure, product) {
      product.isAddedForComparison = !product.isAddedForComparison;
      const postData = {
        brokerEventID: $scope.brokerEventId,
        ProductID: product.ProductID,
        LenderID: product.LenderID,
        loanStructureID: loanStructure.LoanStructureID,
        IsFavourite: product.isAddedForComparison,
      };
      loanProfilerService.setFavouriteProduct(postData).then((response) => {
        if (response.data === 1) {
          $scope.getSelectedProductList(loanStructure);
          angular.element(`.nav-tabs-${loanStructure.LoanStructureID} li.more-product-${loanStructure.LoanStructureID} ul li.contact-parent-tab`).remove();
          angular.element(`.nav-tabs-${loanStructure.LoanStructureID} > li.contact-parent-tab`).remove();
          $timeout(() => {
            $scope.calcWidth(loanStructure);
          }, 100);
          $scope.changeSelectionInLoanStructure(loanStructure, product);
          $scope.getSelectedProductDetailsGetByLoanStructure();
        } else
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
      });
    };

    $scope.changeSelectionInLoanStructure = (loanStructure, product) => {
      _.map(loanStructure.productFinderTableParams.data, (item) => {
        if (parseInt(item.ProductID, 10) === parseInt(product.ProductID, 10)) {
          item.isAddedForComparison = false;
        }
      });
    };
    $scope.selectLoanStructureProductAndStopEvent = (loanStructure, product, event) => {
      $scope.selectLoanStructureProduct(loanStructure, product);
      event.stopPropagation();
    };
    // Open Model For View More Selected Product
    //-----------------------------------------------------------------------------
    $scope.openModalForSelectedProduct = function (loanStructure, size) {
      $uibModal.open({
        templateUrl: '/assets/views/loanProfiler/partials/modal/selected_product_modal.html',
        controller: 'ForSelectedProductModalCtrl',
        size,
        resolve: {
          loanStructure() {
            return loanStructure;
          },
          selectLoanStructureProduct() {
            return $scope.selectLoanStructureProduct;
          },
          loadProductFinderList() {
            return $scope.loadProductFinderList;
          },
          changeSelectionInLoanStructure() {
            return $scope.changeSelectionInLoanStructure;
          },
        },
      });
    };

    // For Mobile
    //-----------------------------------------------------------------------------

    // Get Loan Structure detail
    $scope.getLoanStructureDetailInMobile = function (loanStructure) {
      $scope.isLoanStructureDetail = !$scope.isLoanStructureDetail;
      $scope.mobileLoanStructureId = loanStructure.LoanStructureID;
      if ($scope.defaultSelection.splitLoan === false)
        $scope.mobileLoanStructureName = 'Loan Details';
      else
        $scope.mobileLoanStructureName = loanStructure.Name;
      loanStructure.IsSelectedProductShow = false;
    };

    $scope.cols = [
      { field: 'LenderName', title: 'Lender', show: true },
      { field: 'ProductName', title: 'Product', show: true },
      { field: 'RateType', title: 'Rate Type', show: false },
      { field: 'InitialRate', title: 'Initial Rate', show: false },
      { field: 'OngoingRate', title: 'Ongoing Rate', show: false },
      { field: 'ComparisonRate', title: 'Comparison Rate', show: false },
      { field: 'MaxLVR', title: 'Max LVR', show: false },
      { field: 'MaxLoanAmount', title: 'Max Loan Amount', show: false },
      { field: 'UpFrontFee', title: 'UpFront Fees', show: false },
      { field: 'OngoingMonthlyFee', title: 'Ongoing Monthly Fees', show: false },
      { field: 'AnnualFees', title: 'Annual Fees', show: false },
    ];

    $scope.ViewSelectedProductInMobile = function (loanStructure) {
      loanStructure.IsSelectedProductShow = !loanStructure.IsSelectedProductShow;
    };

    // Open Model For Advance Filter in Mobile
    //-----------------------------------------------------------------------------
    $scope.openModalForAdvanceFilter = function (loanStructure, size) {
      $uibModal.open({
        templateUrl: '/assets/views/loanProfiler/partials/modal/advanced_filter_modal.html',
        scope: $scope,
        controller: 'ForAdvanceFilterModalCtrl',
        size,
        windowClass: 'general_setting_modal',
        resolve: {
          loanStructure() {
            return loanStructure;
          },
          updateFilterData() {
            return $scope.updateFilterData;
          },
        },
      });
    };

    // Open Model For Select Table Column in Product Finder Table
    //-----------------------------------------------------------------------------
    $scope.openModelForDisplayProductFinderTableColumn = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/loanProfiler/partials/modal/product_table_select_option_modal.html',
        scope: $scope,
        controller: 'ForDisplayProductFinderTableColumnCtrl',
        size,
        windowClass: 'general_setting_modal',
        resolve: {
          colmuns() {
            return $scope.cols;
          },
        },
      });
    };

    // Open Model For Add Edit Loan Structure
    //-----------------------------------------------------------------------------
    $scope.openModelForAddLoanStructure = function (size, mode, loanStructure) {
      $uibModal.open({
        templateUrl: '/assets/views/loanProfiler/partials/modal/add_loan_structure_modal.html',
        scope: $scope,
        controller: 'ForAddLoanStructureModalCtrl',
        size,
        windowClass: 'general_setting_modal',
        resolve: {
          saveLoanStructure() {
            return $scope.saveLoanStructure;
          },
          mode() {
            return mode;
          },
          loanStructure() {
            return loanStructure;
          },
        },
      });
    };

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    if ($scope.screenSize > 768) {
      $scope.isLenderDisplayInMobile = false;
    }
    const viewport = function () {
      let e = $window;
      let a = 'inner';
      if (!('innerWidth' in $window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function () {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch($scope.getWindowWidth, (newValue) => {
      $scope.screenSize = newValue.w;
      if (newValue.w > 768) {
        $scope.isLenderDisplayInMobile = false;
      }
    }, true);

    // Open Tab For Compare Product
    //-----------------------------------------------------------------------------
    $scope.goToProductComparison = function () {
      $timeout(() => {
        $rootScope.selectedTab = 'productComparison';
        $rootScope.selectedTabHeader = 'Product Comparison';
        const el = $window.document.querySelectorAll('#productComparison .nav-link');
        angular.element(el).triggerHandler('click');
        $scope.isLoanStructureDetail = false;
      }, 0);
      $timeout(() => {
        angular.element('.table-desktop').trigger('click');
      }, 100);
    };

    // Set Product For Select
    $scope.setServiceabilitySelectedProduct = function (item) {
      if (!item.IsAddedToLoanScenario) {
        item.IsAddedToLoanScenario = !item.IsAddedToLoanScenario;
        const postData = {
          LoanScenarioID: $scope.loanScenarioId,
          ProductID: item.ProductID,
        };
        loanScenarioService.setSearchedProduct(postData).then((response) => {
          if (parseInt(response.data, 10) === 1) {
            toaster.pop('success', 'Added', 'Product has been added to selection Successfully');
          } else {
            item.IsAddedToLoanScenario = !item.IsAddedToLoanScenario;
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        });
      }
    };

    // For Open Mobile Serviceability Setting
    //-----------------------------------------------------------------------------
    $scope.serviceabilitySettings = function () {
      $scope.isLenderDisplayInMobile = false;
    };

    // For Open Mobile Product Finder Setting
    //-----------------------------------------------------------------------------
    $scope.productFinderSettings = function () {
      $scope.isProductDisplayInMobile = false;
    };

    // For Open Mobile Product Finder's Product Result
    //-----------------------------------------------------------------------------
    $scope.productFinderresults = function () {
      $scope.isProductDisplayInMobile = true;
    };

    // Open Model For Lender Detail In Mobile
    //-----------------------------------------------------------------------------
    $scope.openModalForLenderDetail = function (lenderId, productId, size) {
      $uibModal.open({
        templateUrl: '/assets/views/loanProfiler/partials/modal/lender_mobile_detail_modal.html',
        scope: $scope,
        controller: 'ForLenderDetailModalCtrl',
        size,
        resolve: {
          lendersSummaryList() {
            return $scope.lendersSummaryList;
          },
          lenderId() {
            return lenderId;
          },
          productId() {
            return productId;
          },
        },
      });
    };

    // Set Favourite Product Sequence
    $scope.setFavouriteProductSequence = function (newValue, oldValue, loanStructureID) {
      const postData = {
        BrokerEventID: Number($scope.brokerEventId),
        LoanStructureID: Number(loanStructureID),
        NewProductID: Number(newValue),
        OldProductID: Number(oldValue),
      };

      loanProfilerService.setFavouriteProductSequence(postData).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          $scope.getSelectedProductDetailsGetByLoanStructure();
        }
      });
    };

    // Set Favourite Product Recommendation
    $scope.setFavouriteProductRecommendation = function (productSetFavourite, loanStructureID) {
      if (!productSetFavourite || !loanStructureID) return;
      productSetFavourite.IsRecommended = !productSetFavourite.IsRecommended;
      const postData = {
        BrokerEventID: Number($scope.brokerEventId),
        LoanStructureID: Number(loanStructureID),
        ProductID: productSetFavourite.ProductID,
        IsRecommended: productSetFavourite.IsRecommended,
        IsToolTipShown: productSetFavourite.IsToolTipShown,
      };

      loanProfilerService.setFavouriteProductRecommendation(postData).then((response) => {
        $scope.getSelectedProductDetailsGetByLoanStructure();
        if (parseInt(response.data, 10) !== 1) {
          productSetFavourite.IsRecommended = !productSetFavourite.IsRecommended;
        }
      });
    };

    $scope.setFavouriteProductRecommendationForMobile = function (productSetFavouriteForMobile, loanStructureID) {
      productSetFavouriteForMobile.IsRecommended = !productSetFavouriteForMobile.IsRecommended;
      const postData = {
        BrokerEventID: Number($scope.brokerEventId),
        LoanStructureID: Number(loanStructureID),
        ProductID: productSetFavouriteForMobile.ProductID,
        IsRecommended: productSetFavouriteForMobile.IsRecommended,
        IsToolTipShown: productSetFavouriteForMobile.IsToolTipShown,
      };

      loanProfilerService.setFavouriteProductRecommendation(postData).then((response) => {
        if (!$scope.brokerEventId) return;
        loanProfilerService.getSelectedProductDetailsGetByLoanStructure($scope.brokerEventId).then((responsd) => {
          angular.forEach(responsd.data, (item) => {
            $scope.selectedProductCount += item.SelectedProduct.length;

            const tollTipState = _.find(item.SelectedProduct, (tooltipItem) => {
              return !tooltipItem.IsToolTipShown;
            });
            _.map(item.SelectedProduct, (itemValue) => {
              itemValue.IsToolTipShownPopOver = false;
            });
            if (typeof tollTipState === 'undefined') {
              let isAllow = true;
              const recommendState = _.find(item.SelectedProduct, (tooltipItem) => {
                return tooltipItem.IsRecommended;
              });
              if (typeof recommendState === 'undefined') {
                _.map(item.SelectedProduct, (itemValue) => {
                  if (isAllow) {
                    if (itemValue.IsToolTipShown) {
                      itemValue.IsToolTipShownPopOver = true;
                      isAllow = false;
                    }
                  }
                });
              }
            }
          });
        });
        $scope.loanStructureWiseSelectedProducts = response.data;
        if (parseInt(response.data, 10) !== 1) {
          productSetFavouriteForMobile.IsRecommended = !productSetFavouriteForMobile.IsRecommended;
        }
      });
    };

    // Set Don't show again tooltip
    $scope.setDontShowAgainToolTip = function (product, loanStructureID) {
      const postData = {
        BrokerEventID: Number($scope.brokerEventId),
        LoanStructureID: Number(loanStructureID),
        ProductID: product.ProductID,
        IsRecommended: product.IsRecommended,
        IsToolTipShown: false,
      };

      loanProfilerService.setFavouriteProductRecommendation(postData).then(() => {
        $scope.getSelectedProductDetailsGetByLoanStructure();
      });
    };

    // Get Product Comparison detail
    $scope.selectedProductInMobile = {};
    $scope.isCompareDetail = false;
    $scope.getProductComparisonDetailInMobile = function (loanStructure) {
      angular.element('.emerald.us-mobilebutton-c50731562836a85c59cfa515f8f8ab70').css('display', 'none');
      $scope.selectedProductInMobile = {};
      $scope.isCompareDetail = true;
      if ($scope.defaultSelection.splitLoan === false)
        $scope.mobileLoanStructureNameForCompare = 'Loan Details';
      else
        $scope.mobileLoanStructureNameForCompare = loanStructure.Name;

      $scope.selectedProductInMobile = loanStructure;
      $scope.selectedProductInMobile.selectedProductDataList = [];
      loanProfilerService.getFavouriteProduct($scope.brokerEventId, $scope.selectedProductInMobile.LoanStructureID).then((response) => {
        $scope.selectedProductInMobile.selectedProductDataList = response.data;
      });
    };
    $scope.itemMobileFn = () => {
      return _.find($scope.loanStructureWiseSelectedProductsMobile, (item) => {
        if (parseInt($scope.recommendInMobileLoanStructureId, 10) === parseInt(item.LoanStructureID, 10)) {
          return item;
        }
      });
    };
    $scope.slickIndex = 0;
    $scope.changeSlickIndex = function (index) {
      $scope.slickIndex = index;
    };
    $scope.slickConfig = {
      method: {},
      enabled: true,
      autoplay: false,
      draggable: true,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 300,
      swipe: true,
      swipeToSlide: true,
      event: {
        afterChange(event, slick, currentSlide) {
          $scope.recommendInMobile = {};
          $scope.currentIndex = currentSlide; // save current index each time
          $scope.recommendInMobileLoanStructureId = $scope.selectedProductInMobile.LoanStructureID;
          const itemMobile = $scope.itemMobileFn();
          if (typeof itemMobile !== 'undefined') {
            $scope.recommendInMobile = itemMobile.SelectedProduct[$scope.currentIndex];
          }
        },
        init(event, slick) {
          slick.slickGoTo($scope.currentIndex); // slide to correct index when init
        },
      },
    };

    // Open Modal For Save Contact In Mobile
    $scope.openModalForProductDetail = function (loanStructure, product) {
      $uibModal.open({
        scope: $scope,
        templateUrl: '/assets/views/loanProfiler/partials/modal/product_detail_modal.html',
        controller: 'ForProductDetailModalCtrl',
        size: 'lg',
        windowClass: 'product-detail-modal-window-class',
        resolve: {
          loanStructure() {
            return loanStructure;
          },
          product() {
            return product;
          },
        },
      });
    };

    // Open Model For General Setting in Mobile
    //-----------------------------------------------------------------------------
    $scope.openModalForGeneralSetting = (size) => {
      $uibModal.open({
        templateUrl: '/assets/views/loanProfiler/partials/modal/general_setting_modal.html',
        controller: 'GeneralSettingModalCtrl',
        size,
        windowClass: 'general_setting_modal',
        resolve: {
          getDefaultFilterSelection() {
            return $scope.getDefaultFilterSelection;
          },
          setDefaultFilterSelection() {
            return $scope.setDefaultFilterSelection;
          },
          getLoanStructure() {
            return $scope.getLoanStructure;
          },
          updateOverlaySearch() {
            return $scope.updateOverlaySearch;
          },
        },
      });
    };

    // View More Selected Product
    $scope.calcWidth = function (loanSrtucture) {
      let navwidth = 0;
      loanSrtucture.narrowNav = false;
      const mainId = loanSrtucture.LoanStructureID;
      loanSrtucture.hiddenTabCount = angular.element(`li.more-product-${mainId} li`).length;
      const morewidth = 210;
      let availablespace = 0;
      angular.element(`.nav-tabs-${mainId} > li.contact-parent-tab`).each((i, item) => {
        navwidth += (angular.element(item).outerWidth(true) + 10);
      });
      if (loanSrtucture.hiddenTabCount > 0) {
        availablespace = angular.element(`ul.nav-tabs-${mainId}`).outerWidth(true) - morewidth;
      } else {
        availablespace = angular.element(`ul.nav-tabs-${mainId}`).outerWidth(true);
      }
      if (availablespace < 0) {
        availablespace = 0;
      }
      if (navwidth > availablespace) {
        const lastItem = angular.element(`.nav-tabs-${mainId} > li.contact-parent-tab`).last();
        lastItem.attr('data-width', lastItem.outerWidth(true));
        lastItem.prependTo(angular.element(`.nav-tabs-${mainId} li.more-product-${mainId} ul`));
        $timeout(() => $scope.calcWidth(loanSrtucture), 100);
      } else {
        loanSrtucture.viewMoreSelectedProduct = angular.element(`.nav-tabs-${mainId} li.more-product-${mainId} ul`).find('li.contact-parent-tab').length;
        const hiddenMoreElement = angular.element(`.nav-tabs-${mainId} li.more-product-${mainId} li`).find('li.contact-parent-tab').length;
        if (parseInt(hiddenMoreElement, 10) !== 0) {
          const firstMoreElement = angular.element(`.nav-tabs-${mainId} li.more-product-${mainId} li`).first();
          if ((navwidth + firstMoreElement.outerWidth(true)) < availablespace) {
            firstMoreElement.insertBefore(angular.element(`.nav-tabs-${mainId} .more-product-${mainId}`));
          }
        }
      }

      if (loanSrtucture.hiddenTabCount > 0) {
        loanSrtucture.narrowNav = true;
      } else {
        loanSrtucture.narrowNav = false;
      }
    };
    $scope.loadFunction = function () {
      $timeout(() => {
        angular.forEach($scope.loanStructureList, (loanStructure) => {
          $scope.calcWidth(loanStructure);
        });
      }, 100);
    };

    angular.element(window).on('resize orientationchange', () => {
      $scope.loadFunction();
    });

    /** NZ Requirements By Elmer Datolayta */
    $scope.hiddenClientForNz = false;
    commonFnService.hiddenClientForNz().then((response) => {
      $scope.hiddenClientForNz = response;
    });

    $scope.isSpreadsheetAvailable = () => {
      return !uiService.isCountry('New Zealand');
    };
  });
