import angular from 'angular';

// filter to abbreviate frequency
angular.module('app').filter('frequency', function frequency() {
  return function (frequencyVal) {
    const abbv = {
      Weekly: 'week',
      Fortnightly: 'fortnight',
      Monthly: 'mo',
      Quarterly: 'qtr',
      'Half-Yearly': '6mos',
      Yearly: 'yr',
    };
    return abbv[frequencyVal] || null;
  };
});
