import angular from 'angular';

angular.module('app').directive('insertAtCursorOnClick', function insertAtCursorOnClick($window) {
  return {
    restrict: 'A',
    scope: {
      textToInsert: '@insertAtCursorOnClick',
    },
    link: (scope, elem, attr) => {
      if (!attr.textarea || !attr.textarea.trim()) return;

      const textarea = angular.element(attr.textarea)[0];
      if (!textarea) return;

      const onClick = () => {
        if ($window.document.selection) {
          textarea.focus();
          const range = $window.document.selection.createRange();
          range.text = scope.textToInsert;
          textarea.focus();
        } else if (textarea.selectionStart || textarea.selectionStart === 0) {
          const startPos = textarea.selectionStart;
          const endPos = textarea.selectionEnd;
          const scrollTop = textarea.scrollTop;
          textarea.value = `${textarea.value.substring(0, startPos)}${scope.textToInsert}${textarea.value.substring(endPos, textarea.value.length)}`;
          textarea.focus();
          textarea.selectionStart = startPos + scope.textToInsert.length;
          textarea.selectionEnd = textarea.selectionStart;
          textarea.scrollTop = scrollTop;
        } else {
          textarea.value = `${textarea.value}${scope.textToInsert}`;
          textarea.focus();
        }

        angular.element(textarea).triggerHandler('input');
        angular.element(textarea).triggerHandler('change');
      };

      elem.on('click', onClick);
      scope.$on('$destroy', () => {
        elem.off('click', onClick);
      });
    },
  };
});
