class LendingScenarioService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'LendingScenario';
  }

  scenarioListGet(familyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ScenarioListGet`, { familyId }, true);
  }

  scenarioClientEntityGet(scenarioId, familyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ScenarioClientEntityGet`, { scenarioId, familyId });
  }

  scenarioSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/ScenarioSet`, {}, params);
  }

  scenarioDelete(lendingScenarioId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/ScenarioDelete`, { lendingScenarioId });
  }

  scenarioClientSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ScenarioClientSet`, data);
  }

  scenarioClientDelete(lendingScenarioId, clientEntityId, isClient) {
    return this.httpClient.post(`${this.apiBaseUrl}/ScenarioClientDelete`, {}, { lendingScenarioId, clientEntityId, isClient });
  }

  scenarioDetailsGet(familyId, lendingScenarioId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ScenarioDetailsGet`, { familyId, lendingScenarioId });
  }

  loanRequirementSet(lendingScenarioId, data) {
    return this.httpClient.post(`${this.apiBaseUrl}LoanRequirementSet`, data, { lendingScenarioId });
  }

  repaymentCalculatorSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/RepaymentCalculatorSet`, data, data);
  }

  productFinderLinkSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/ProductFinderLinkSet`, {}, params);
  }

  serviceabilityNameSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/ServiceabilityNameSet`, {}, params);
  }

  serviceabilityLinkSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/ServiceabilityLinkSet`, {}, params);
  }

  fundsRequiredNameSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/FundsRequiredNameSet`, {}, params);
  }

  fundsRequiredLinkSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/FundsRequiredLinkSet`, {}, params);
  }

  repaymentCalculatorLinkSet(lendingScenarioId, repaymentCalculatorId) {
    return this.httpClient.post(`${this.apiBaseUrl}/RepaymentCalculatorLinkSet`, { lendingScenarioId }, { lendingScenarioId, repaymentCalculatorId });
  }

  insuranceGet(loanScenarioID) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceGet`, { loanScenarioID });
  }

  insuranceSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/InsuranceSet`, data);
  }
}

export default LendingScenarioService;
