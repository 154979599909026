
import angular from 'angular';

angular.module('app').directive('interestRatesTableDirective', function interestRatesTableDirective(loanToolsService, $q, overviewDashboardSharedService, queryService, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { myParam: '=' },
    templateUrl: 'assets/views/overviewDashboard/directiveTemplates/interestRatesTable.html',

    link: ($scope) => {
      $scope.slickConfig = { method: {} };
      // Model
      $scope.countryList = [];
      $scope.interestRatesObject = {};
      $scope.dataLoaded = false;
      $scope.showSlick = false;

      // init Methods
      $scope.LoanRateGet = overviewDashboardSharedService.LoanRateGet;
      $scope.mapLoanRateToTable = overviewDashboardSharedService.mapLoanRateToTable;

      function populateCountryList() {
        overviewDashboardSharedService.getCountryList().then((response) => {
          $scope.countryList = response;
        });
      }

      $scope.populateCountryList = populateCountryList;
      // actual functions
      function init() {
        $scope.LoanRateGet().then((response) => {
          $scope.populateCountryList();
          $scope.interestRatesObject.list = queryService.queryPerPage(4, $scope.mapLoanRateToTable(response));
          if ($scope.interestRatesObject.list) {
            $timeout(() => {
              $scope.dataLoaded = true;
              $scope.showSlick = true;
            }, 220);

            $timeout(() => {
              try {
                $scope.slickConfig.method.slickGoTo(0);
              } catch (error) {
                // Continue regardless of error
              }
            }, 220);
          }
        });
      }

      init();
      // end of test
    },
  };
});
