import {
  subscriptionBuilderForMyCRM,
  creditGuideBuilderForUI,
  subIncomeTypeBuilderForUI,
} from 'Common/mappers/contact';

class ContactModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'contacts';
  }

  setGmailSignature(html) {
    return this.httpClient.post(`google/gmail-signature`, html);
  }

  subscription(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/Subscriptions`, subscriptionBuilderForMyCRM(data));
  }

  updateSubscription(data) {
    return this.httpClient.put(`${this.apiBaseUrl}/Subscriptions`, subscriptionBuilderForMyCRM(data));
  }

  getLoans(id) {
    return this.httpClient.get(`${this.apiBaseUrl}/${id}/loans`);
  }

  sendCreditGuide(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/credit-guide`, data)
      .then(res => creditGuideBuilderForUI(res));
  }
  getSubIncomeType() {
    return this.httpClient.get(`${this.apiBaseUrl}/sub-income-type`)
      .then(response => response.data.map(subIncomeTypeBuilderForUI));
  }
  postMergeFamilyProfile(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/MergeFamilyProfile`, {}, params);
  }
  getBrokerCommissionAccess() {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokerCommissionAccess`);
  }
  taskAdviserListGet(loanWritersOnly = null) {
    return this.httpClient.get(`${this.apiBaseUrl}/TaskAdviserListGet`, { loanWritersOnly });
  }
}

export default ContactModelService;
