import template from './subscriptionCancelNowModal.html';
import controller from './subscriptionCancelNowModalCtrl';


export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
    subscriptionData: '<',
    unsubscribeText: '<',
    selectedServicesLength: '<',
  },
};
