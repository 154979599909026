export const DATE_RANGE = {
  Month1: '1Month',
  Months3: '3Months',
  Month6: '6Months',
  Months12: '12Months',
};

export const DATE_COUNT = {
  DAYS_IN_YEAR: 365,
  MONTHS_IN_YEAR: 12,
};
