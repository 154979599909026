import angular from 'angular';
import swal from 'sweetalert';

angular.module('app').controller('CorporateTargetManagementNewCtrl', function CorporateTargetManagementNewCtrl(
  $scope,
  $uibModalInstance,
  contactService,
  corporateService,
  $rootScope,
  targetManagementData,
  $filter,
  toaster) {
  // targetManagementData.TargetPeriod = $filter('date')(targetManagementData.TargetPeriod, 'dd-MM-yyyy');
  $scope.targetManagement = angular.copy(targetManagementData);
  const month = targetManagementData.TargetMonth === 0 ? 1 : targetManagementData.TargetMonth;
  const year = targetManagementData.TargetYear;
  $scope.targetManagement.TargetPeriod = year === 0 ? '' : new Date(`${month}-1-${year}`);
  $scope.targetManagementModel = {};

  $scope.closeModal = function () {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.currentTargetType = 0;
  $scope.setTargetType = function (value) {
    if ($scope.currentTargetType === value) {
      $scope.currentTargetType = 0;
    } else {
      $scope.currentTargetType = value;
    }
  };
  // Team
  function reportingStatesGet() {
    corporateService.reportingStatesGet().then((response) => {
      $scope.teamList = response.data;
      if ($scope.targetManagement.TeamId > 0) {
        for (let i = 0; i < response.data.length; i++) {
          if ($scope.targetManagement.TeamId === response.data[i].ReportingStateId) {
            $scope.targetManagementModel.team = response.data[i];
          }
        }
      }
    });
  }
  reportingStatesGet();

  // Target Owner
  function getAdminUsers() {
    corporateService.getAdminUsers().then((response) => {
      $scope.ownerList = response.data;
      if ($scope.targetManagement.TargetOwnerID > 0) {
        for (let i = 0; i < response.data.length; i++) {
          if ($scope.targetManagement.TargetOwnerID === response.data[i].FamilyId) {
            $scope.targetManagementModel.owner = response.data[i];
          }
        }
      }
    });
  }
  getAdminUsers();

  // Related Loan Status
  function getRelatedLoanStatus() {
    corporateService.getRelatedLoanStatus().then((response) => {
      $scope.loanStatusList = response.data;
      if ($scope.targetManagement.RelatedLoanStatusID > 0) {
        for (let i = 0; i < response.data.length; i++) {
          if ($scope.targetManagement.RelatedLoanStatusID === response.data[i].LoanStatusID) {
            $scope.targetManagementModel.loanStatus = response.data[i];
          }
        }
      }
    });
  }
  getRelatedLoanStatus();

  // Related Product Group
  function getRelatedProductGroup() {
    corporateService.getRelatedProductGroup().then((response) => {
      $scope.productGroupList = response.data;
      if ($scope.targetManagement.RelatedProductGroupID > 0) {
        for (let i = 0; i < response.data.length; i++) {
          if ($scope.targetManagement.RelatedProductGroupID === response.data[i].RelatedProductGroupID) {
            $scope.targetManagementModel.productGroup = response.data[i];
          }
        }
      }
    });
  }
  getRelatedProductGroup();

  // Currency
  function getActiveCRMCountryCurrency() {
    corporateService.getActiveCRMCountryCurrency().then((response) => {
      $scope.currencyList = response.data;
      if ($scope.targetManagement.RelatedProductGroupID > 0) {
        for (let i = 0; i < response.data.length; i++) {
          if ($scope.targetManagement.CurrencyCodeID === response.data[i].CurrencyCodeID) {
            $scope.targetManagementModel.currency = response.data[i];
          }
        }
      }
    });
  }
  getActiveCRMCountryCurrency();

  $scope.getTargetValueIcon = function () {
    let icon = 'icon-usd';
    if ($scope.targetManagement.ValueType === 'Currency') {
      if (typeof $scope.targetManagementModel.currency !== 'undefined') {
        switch ($scope.targetManagementModel.currency.CurrencyCodeID) {
        case 8:
          icon = 'icon-aud'; break;
        case 61:
          icon = 'icon-inr'; break;
        case 106:
          icon = 'icon-nzd'; break;
        default:
          break;
        }
      }
    } else if ($scope.targetManagement.ValueType === 'Number') {
      icon = 'icon-num';
    } else if ($scope.targetManagement.ValueType === 'Percentage') {
      icon = 'icon-percent';
    }
    return icon;
  };
  $scope.saveTargetManagement = function () {
    $scope.targetManagement.IsRecurring = $scope.targetManagement.IsRecurring ? 1 : 0;
    $scope.targetManagement.TargetMonth = typeof $scope.targetManagement.TargetPeriod === 'undefined' ? 0 : $filter('date')($scope.targetManagement.TargetPeriod, 'M');
    $scope.targetManagement.TargetYear = typeof $scope.targetManagement.TargetPeriod === 'undefined' ? 0 : $filter('date')($scope.targetManagement.TargetPeriod, 'yyyy');
    $scope.targetManagement.TeamId = typeof $scope.targetManagementModel.team === 'undefined' ? 0 : $scope.targetManagementModel.team.ReportingStateId;
    $scope.targetManagement.Team = typeof $scope.targetManagementModel.team === 'undefined' ? '' : $scope.targetManagementModel.team.ReportingStateName;
    $scope.targetManagement.TargetOwnerID = typeof $scope.targetManagementModel.owner === 'undefined' ? 0 : $scope.targetManagementModel.owner.FamilyId;
    $scope.targetManagement.TargetOwnerName = typeof $scope.targetManagementModel.owner === 'undefined' ? '' : $scope.targetManagementModel.owner.FullName;
    $scope.targetManagement.RelatedLoanStatusID = typeof $scope.targetManagementModel.loanStatus === 'undefined' ? 0 : $scope.targetManagementModel.loanStatus.LoanStatusID;
    $scope.targetManagement.RelatedLoanStaus = typeof $scope.targetManagementModel.loanStatus === 'undefined' ? '' : $scope.targetManagementModel.loanStatus.LoanStatusName;
    $scope.targetManagement.RelatedProductGroupID = typeof $scope.targetManagementModel.productGroup === 'undefined' ? 0 : $scope.targetManagementModel.productGroup.RelatedProductGroupID;
    $scope.targetManagement.RelatedProductGroup = typeof $scope.targetManagementModel.productGroup === 'undefined' ? '' : $scope.targetManagementModel.productGroup.RelatedProductGroup;

    if ($scope.targetManagement.ValueType === 'Currency') {
      $scope.targetManagement.CurrencyCodeID = typeof $scope.targetManagementModel.currency === 'undefined' ? 0 : $scope.targetManagementModel.currency.CurrencyCodeID;
      $scope.targetManagement.CurrencyName = typeof $scope.targetManagementModel.currency === 'undefined' ? '' : $scope.targetManagementModel.currency.CurrencyName;
      $scope.targetManagement.CurrencySign = typeof $scope.targetManagementModel.currency === 'undefined' ? '' : $scope.targetManagementModel.currency.CurrencySign;
    }

    corporateService.setTargetManagement($scope.targetManagement).then((response) => {
      if (response.data > 0) {
        toaster.pop('success', 'Saved!', 'Target successfully saved.');
        $rootScope.loadTargets();
        $uibModalInstance.dismiss('cancel');
      } else if (response.data === -1) {
        swal('Red Threshold Validation', 'Red Threshold must not exceed the value of Amber Threshold.', 'error');
      } else if (response.data === -2) {
        swal('Amber Threshold Validation', 'Amber Threshold must not exceed the Target Value.', 'error');
      } else {
        toaster.pop('error', 'Error!', 'Saving encountered error! Try again.');
      }
    });
  };

  $scope.getDateFormat = function () {
    let ret = 'dd-MM-yyyy';
    if ($scope.targetManagement.TargetType === 'Annually') {
      ret = 'yyyy';
    } else if ($scope.targetManagement.TargetType === 'Monthly') {
      ret = 'MMMM yyyy';
    }
    return ret;
  };
});
