import swal from 'sweetalert';

class selectGdriveFolderModalCtrl {
  constructor(
    contactService,
    $state
  ) {
    'ngInject';

    this.contactService = contactService;
    this.state = $state;
    this.folderFiles = [];
    this.folderFilesToShow = [];
    this.parentFolderFileIdArray = [];
    this.folderChosen = '';
    this.userInfo = [];
    this.isLoadingRootFolders = true;
    this.isLoadingFolderFromId = true;
  }

  setFamilyAFolder() {
    const objectToPost = {
      familyId: this.state.params.familyId,
      googleDriveFolderID: this.folderChosen,
    };

    this.contactService.postDriveUserMapping(objectToPost).then(() => {
      swal({
        title: 'Google Drive Integration Successful',
        text: '',
        type: 'success',
        confirmButtonColor: '#42B4B4',
        confirmButtonText: 'Got it',
        closeOnConfirm: true,
      }, (isConfirm) => {
        if (isConfirm) {
          this.hasChosenAFolder({ bool: true });
          this.resolvedData({ gdriveFolderId: this.folderChosen });
        }
      });
    });
  }

  static addInitialCustomPropertyToList(list) {
    list.forEach((object) => {
      object.GoogleDriveFolderFiles = [];
      object.isClicked = false;
    });
  }

  getUserInfo() {
    this.contactService.getUserInfo().then((response) => {
      if (response.data) {
        this.userInfo = response.data;
      }
    });
  }
  upALevelFolder() {
    const indexOfLastValue = this.parentFolderFileIdArray.length - 1;
    const indexToCopy = this.parentFolderFileIdArray[indexOfLastValue];

    this.folderFilesToShow = this.folderFiles[indexToCopy];
    this.parentFolderFileIdArray.pop();
  }
  getGdriveFolderFileFromRoot() {
    this.isLoadingFolderFromId = true;
    this.isLoadingRootFolders = true;
    this.contactService.getGoogleDriveRoot().then((response) => {
      this.isLoadingFolderFromId = false;
      this.isLoadingRootFolders = false;
      delete response.data.AuthSuccess;

      response.data.isClicked = true;
      response.data.FileId = 'root';
      this.folderFilesToShow = response.data;
      this.folderFiles.root = response.data;
    });
  }

  getGdriveFolderFileFromFileId(objectReference) {
    this.parentFolderFileIdArray.push(this.folderFilesToShow.FileId);
    this.isLoadingFolderFromId = true;

    if (objectReference.isClicked) {
      this.isLoadingFolderFromId = false;
      this.folderFilesToShow = this.folderFiles[objectReference.FileId];
    } else {
      this.contactService.getGoogleDriveFolderId(true, objectReference.FileId).then((response) => {
        this.isLoadingFolderFromId = false;
        delete response.data.AuthSuccess;

        objectReference.isClicked = true;
        objectReference.GoogleDriveFolderFiles = response.data.GoogleDriveFolderFiles;
        selectGdriveFolderModalCtrl.addInitialCustomPropertyToList(objectReference.GoogleDriveFolderFiles);
        this.folderFilesToShow = objectReference;
        this.folderFiles[objectReference.FileId] = objectReference;
        /* continue here... think about structure of the list when the user clicks on the file or trigger this function... */
      });
    }
  }

  folderClickedHandler(objectReference) {
    if (objectReference.isClicked) {
      this.folderFilesToShow = objectReference.GoogleDriveFolderFiles;
    } else {
      this.getGdriveFolderFileFromFileId(objectReference);
    }
  }


  $onInit() {
    this.getUserInfo();
    this.getGdriveFolderFileFromRoot();
  }
}


export default selectGdriveFolderModalCtrl;
