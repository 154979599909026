import angular from 'angular';
import { toastError } from 'Common/utilities/alert';

angular.module('app').controller('TaskSidebarCtrl',
  function TaskSidebarCtrl($scope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanToolsService,
    dashboardService,
    $filter,
    $element,
    $window,
    toaster,
    SweetAlert,
    configService,
    contactService,
    taskService,
    tasksService,
    $rootScope) {
    $scope.tasksTableDropDowns = {};
    $scope.alltasksTableData = [];
    $scope.brokerId = 0;
    $scope.AssignedTo = '';
    $scope.dueDate = 'today';
    const init = () => {
      $scope.getSignedInBroker();
      $scope.taskName = '';
    };
    $scope.openCreateTaskModal = function (id) {
      $scope.task_id = id;
      $uibModal.open({
        templateUrl: '/assets/views/tasks/modals/add.html',
        controller: 'OpenCreateTaskModalCtrl',
        windowClass: 'edit-task-window',
        size: 'md',
        scope: $scope,
        resolve: {
          init() {
            return init;
          },
        },
      });
    };
    $scope.openCreateTaskTemplateModal = function () {
      $uibModal.open({
        templateUrl: 'assets/views/tasks/modals/add_template_task_modal.html',
        controller: 'OpenCreateTaskTemplateModalCtrl',
        scope: $scope,
        windowClass: 'view-task-window',
        resolve: {
          init() {
            return init;
          },
        },
      });
    };
    const getTasks = (brokerID) => {
      const getParams = {
        adviserFilter: brokerID,
        dateFilter: $scope.dueDate,
        familyId: 0,
        isShowCompleted: false,
      };

      tasksService.getTaskList(getParams)
        .then((response) => {
          if (!response || !response.data) return;

          $scope.tasksTableData = [];
          $scope.alltasksTableData = response.data.TaskList;
          $scope.tasksTableData = [];
          for (let i = 0; i < $scope.alltasksTableData.length; i++) {
            if ($scope.alltasksTableData[i].IsCompleted === false) {
              $scope.tasksTableData.push($scope.alltasksTableData[i]);
            }
          }
          $scope.allTasks = false;
          if ($scope.tasksTableData.length < 10) {
            $scope.lessThanExpected = true;
          } else {
            $scope.lessThanExpected = false;
          }
        });
    };
    $scope.toggleOneComplete = function (task) {
      taskService
        .taskCompletionSet(task.FamilyID, task.ActivityID, task.IsCompleted)
        .then(() => {
          getTasks(task.AdviserId);
          toaster.pop('success', 'Completed', 'Task has been updated successfully.');
        })
        .catch(toastError);
    };
    $scope.changeDueDate = function (dueDate) {
      $scope.dueDate = dueDate;
      init();
    };

    $scope.getSignedInBroker = function () {
      dashboardService.getBrokerBasicInfo().then((response) => {
        $scope.AssignedTo = { BrokerID: parseInt(response.data.BrokerId, 10) };
        $scope.brokerId = $scope.AssignedTo.BrokerID;
        getTasks($scope.brokerId);
      });
    };
    $rootScope.$on('reloadTaskData', (event, args) => {
      if (args.reloadTask === true) {
        getTasks($scope.brokerId);
      }
    });
    $scope.minnumber = 0;
    $scope.maxnumber = 10;
    $scope.toggleTasks = function () {
      let length = $scope.alltasksTableData.length;
      if ($scope.alltasksTableData.length > $scope.maxnumber) {
        $scope.minnumber += 10;
        $scope.maxnumber += 10;
        length -= 10;
      }
      if (length < 10) {
        $scope.lessThanExpected = true;
      }
      $scope.allTasks = true;
    };
    $scope.openModal = function (size, task) {
      $uibModal.open({
        templateUrl: '/assets/views/overviewDashboard/partials/update_task_details.html',
        resolve: {
          tasksTableData() {
            return $scope.tasksTableData;
          },
          allTasksTableData() {
            return $scope.alltasksTableData;
          },
          task() {
            return task;
          },
          getTasks(param) {
            return getTasks(param);
          },
        },
        controller: 'OpenModalCtrl',
        size,
      });
    };
    init();
  });
