import angular from 'angular';
import AuthService from './authService';
import BusinessService from './businessService';
import BrokerSupportService from './brokerSupportService';
import CommissionService from './commissionService';
import CommunicateService from './communicateService';
import ConnectService from './connectService';
import ContactInsuranceService from './contactInsuranceService';
import CustomerCareService from './customerCareService';
import DashboardService from './dashboardService';
import FinancialCalculatorService from './financialCalculatorService';
import TasksService from './tasksService';
import FundingCalculatorService from './fundingCalculatorService';
import GeneralService from './generalService';
import InsuranceAppService from './insuranceAppService';
import InsuranceProfilerService from './insuranceProfilerService';
import InsuranceQuoteService from './insuranceQuoteService';
import LendingScenarioService from './lendingScenarioService';
import LoanApplicationServices from './loanApplicationServices';
import LoanProfilerService from './loanProfilerService';
import LoanToolsService from './loanToolsService';
import NextGenLoanAppService from './nextGenLoanAppService';
import NotificationSidebarService from './notificationSidebarService';
import NpsService from './npsService';
import OptionsService from './optionsService';
import RateService from './rateService';
import ReportService from './reportService';
import UserService from './userService';
import AnnouncementService from './announcementService';
import AliService from './aliService';
import PepperService from './pepperService';
import SubscriptionService from './subscriptionService';
import SelectOptionsService from './selectOptionsService';
import PipelineService from './pipelineService';
import CreditChecksService from './creditChecksService';
import CorporateModelService from './corporateModelService';
import JasperService from './jasperService';
import LoanOpportunityService from './loanOpportunityService';
import BriServices from './briServices';
import ContactModelService from './contactModelService';
import ServiceabilityService from './serviceabilityService';
import ReferralService from './referralService';
import ReferralModelService from './referralModelService';
import ProviderInformationService from './providerInformationService';
import ReleaseNotesService from './releaseNotesService';
import InsurancePipelineService from './insurancePipelineService';

export default angular.module('common.models', [])
  .service('authService', AuthService)
  .service('businessService', BusinessService)
  .service('brokerSupportService', BrokerSupportService)
  .service('commissionService', CommissionService)
  .service('communicateService', CommunicateService)
  .service('connectService', ConnectService)
  .service('contactInsuranceService', ContactInsuranceService)
  .service('customerCareService', CustomerCareService)
  .service('dashboardService', DashboardService)
  .service('financialCalculatorService', FinancialCalculatorService)
  .service('fundingCalculatorService', FundingCalculatorService)
  .service('tasksService', TasksService)
  .service('generalService', GeneralService)
  .service('insuranceAppService', InsuranceAppService)
  .service('insuranceProfilerService', InsuranceProfilerService)
  .service('insuranceQuoteService', InsuranceQuoteService)
  .service('lendingScenarioService', LendingScenarioService)
  .service('loanApplicationServices', LoanApplicationServices)
  .service('loanProfilerService', LoanProfilerService)
  .service('loanToolsService', LoanToolsService)
  .service('nextGenLoanAppService', NextGenLoanAppService)
  .service('notificationSidebarService', NotificationSidebarService)
  .service('npsService', NpsService)
  .service('optionsService', OptionsService)
  .service('rateService', RateService)
  .service('reportService', ReportService)
  .service('userService', UserService)
  .service('announcementService', AnnouncementService)
  .service('aliService', AliService)
  .service('pepperService', PepperService)
  .service('subscriptionService', SubscriptionService)
  .service('selectOptionsService', SelectOptionsService)
  .service('pipelineService', PipelineService)
  .service('creditChecksService', CreditChecksService)
  .service('corporateModelService', CorporateModelService)
  .service('jasperService', JasperService)
  .service('loanOpportunityService', LoanOpportunityService)
  .service('briServices', BriServices)
  .service('contactModelService', ContactModelService)
  .service('serviceabilityService', ServiceabilityService)
  .service('referralService', ReferralService)
  .service('referralModelService', ReferralModelService)
  .service('providerInformationService', ProviderInformationService)
  .service('releaseNotesService', ReleaseNotesService)
  .service('insurancePipelineService', InsurancePipelineService)
  .name;
