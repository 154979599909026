export function releaseNoteItemsBuilderForUI(releaseNoteItem) {
  return releaseNoteItem && {
    id: releaseNoteItem.ReleaseNoteItemId,
    description: releaseNoteItem.ReleaseItemDescription,
  };
}

export function releaseNoteTypesBuilderForUI(releaseNoteType) {
  return releaseNoteType && {
    typeId: releaseNoteType.ReleaseTypeId,
    typeName: releaseNoteType.ReleaseTypeName,
    items: releaseNoteType.ReleaseNoteItems.map(releaseNoteItemsBuilderForUI),
  };
}

export function releaseNotesBuilderForUI(releaseNote) {
  return {
    id: releaseNote.ReleaseNoteId,
    name: releaseNote.ReleaseName,
    description: releaseNote.ReleaseDescription,
    dateReleased: releaseNote.DateReleased,
    types: releaseNote.ReleaseNoteTypes.map(releaseNoteTypesBuilderForUI),
    totalReleaseCount: releaseNote.TotalReleaseCount,
  };
}
