import { opportunityCardsBuilderForUI, opportunityTableBuilderForUI } from 'Common/mappers/pipeline';

class PipelineService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Pipeline';
  }

  PipelineCardsTimeLineViewGet(clientFamilyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineCardsTimeLineViewGet`, { clientFamilyId });
  }

  SettingsStatusGet(isShowSettled = false, isShowNotProceeded = false) {
    return this.httpClient.get(`${this.apiBaseUrl}/SettingsStatusGet`, { isShowSettled, isShowNotProceeded });
  }

  PipelineSettingsLabel() {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineSettingsLabel`);
  }

  PipelineCardsGet(isLeads, statusID, advisorsID = 0, labelID, probability, EnquirySource, Referral, isCompact = '', sortingMode = '', familyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineCardsGet`, { isLeads, statusID, advisorsID, labelID, probability, EnquirySource, Referral, isCompact, sortingMode, familyId });
  }

  PipelineCardsTableViewGet(isLeads, statusId, advisorsId, labelId, probability, EnquirySource, Referral, SortColumn, SortType, familyId = 0) {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineCardsTableViewGet`, { isLeads, statusId, advisorsId, labelId, probability, EnquirySource, Referral, SortColumn, SortType, familyId });
  }

  PipelineCardsStatusActivityGet(pipelineCardsID, pipelineNewStatusID) {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineCardsStatusActivityGet`, { pipelineCardsID, pipelineNewStatusID });
  }

  SettingsActivityGet(PipelineStatusID) {
    return this.httpClient.get(`${this.apiBaseUrl}/SettingsActivityGet`, { PipelineStatusID });
  }

  PipelineSettingsActivityTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineSettingsActivityTypeGet`);
  }

  PipelineCategoryGet(pipelineTypeID) {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineCategoryGet`, { pipelineTypeID });
  }

  PipelineCardsSet(params) {
    // this prevents any null value being passed on api that will be converted to null
    if (!params.clientFamilyId) {
      params.clientFamilyId = 0;
    }
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsInsert`, {}, params, false);
  }

  PipelineCardsLoanScenarioSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsLoanScenarioSet`, {}, params);
  }

  PipelineSettingsLabelSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineSettingsLabelSet`, data);
  }

  PipelineCardsLabelsSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsLabelsSet`, {}, params);
  }

  PipelineCardsLabelSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsLabel`, {}, params);
  }

  PipelineCardsMoveToNextStatusSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsMoveToNextStatusSet`, {}, params);
  }

  SettingsStatusSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/SettingsStatusSet`, {}, params);
  }

  PipelineSettingsStatusDelete(PipelineStatusID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/PipelineSettingsStatusDelete`, { PipelineStatusID });
  }

  PipelineCardsDelete(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsDelete`, {}, params);
  }

  PipelineCardsLabelsDelete(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsLabelsDelete`, {}, params);
  }

  PipelineSettingLabelDelete(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineSettingLabelDelete`, {}, params);
  }

  PipelineSettingsActivityDelete(PipelineSettingsActivityID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/PipelineSettingsActivityDelete`, { PipelineSettingsActivityID });
  }

  SettingsActivitySet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/SettingsActivitySet`, data);
  }

  PipelineCardsProbabilitySet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsProbabilitySet`, {}, params);
  }

  SetPipelineCardsProbability(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsProbability`, {}, params);
  }

  CardsSubStatusSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/CardsSubStatusSet`, {}, params);
  }

  PipelineItemGet(pipelineId) {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineItemGet`, { pipelineId });
  }

  PipelineTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineTypeGet`);
  }

  ConvertionRateGet(pipelineId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ConvertionRateGet`, { pipelineId });
  }

  ItemProgressViewGet(familyId, pipelineId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ItemProgressViewGet`, { familyId, pipelineId });
  }

  StatusTypeGet(pipelineId, isIncludeFinalStatus) {
    return this.httpClient.get(`${this.apiBaseUrl}/StatusTypeGet`, { pipelineId, isIncludeFinalStatus });
  }

  StatusTypeSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/StatusTypeSet`, data);
  }

  ItemStatusSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/ItemStatusSet`, {}, params);
  }

  PipelineCardsConversionSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsConversionSet`, {}, params);
  }

  PipelineCardsConvertPostAddressToCurrentSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/PipelineCardsConvertPostAddressToCurrentSet`, {}, params);
  }

  getPipelineCardsSummary(FromDate, ToDate, IsApplication) {
    return this.httpClient.get(`${this.apiBaseUrl}/PipelineCardsSummaryGet`, { FromDate, ToDate, IsApplication });
  }

  getPipelineCardFilters(isLeads, isOpportunity) {
    return this.httpClient.get(`${this.apiBaseUrl}/CardFilters`, { isLeads, isOpportunity });
  }

  setPipelineCardFilters(data) {
    if (!data.StatusId || Number.isNaN(data.StatusId)) {
      data.StatusId = 'All';
    }
    if (!data.EnquirySource || Number.isNaN(data.EnquirySource)) {
      data.EnquirySource = 'All';
    }
    if (data.Referral === -1 || !data.Referral || Number.isNaN(data.Referral)) {
      data.Referral = 'All';
    }
    if (!data.Probability || Number.isNaN(data.Probability)) {
      data.Probability = 'All';
    }
    if (!data.SubStatusId || Number.isNaN(data.SubStatusId)) {
      data.SubStatusId = 'All';
    }

    return this.httpClient.put(`${this.apiBaseUrl}/CardFilters`, data);
  }

  getPipelineCardViewTable(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/cards/views/table`, params);
  }

  getPipelineCardViewCard(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/cards/views/cards`, params);
  }

  getGroupedPipelineCards(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/cards/views/groupedCards`, params);
  }

  PipelineCards(id, data) {
    return this.httpClient.patch(`${this.apiBaseUrl}/PipelineCards/${id}`, data);
  }

  settingsReasonGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/SettingsReason`, null, true);
  }

  getNotProceedReasons(id) {
    return this.httpClient.get(`${this.apiBaseUrl}/categories/${id}/reasons`)
      .then((response) => {
        return {
          reasonList: response.data,
        };
      });
  }

  getPipelineOpportunityCards() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/opportunity/views/cards`)
      .then(response => opportunityCardsBuilderForUI(response.data));
  }

  getOpportunityTable(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/opportunity/views/table`, params)
      .then(response => response.data.map(opportunityTableBuilderForUI));
  }

  putConvertToOpportunity(id, model) {
    return this.httpClient.put(`${this.apiBaseUrl}/${id}/convert-to-opportunity`, model);
  }
}

export default PipelineService;
