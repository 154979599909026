import angular from 'angular';
import swal from 'sweetalert';

angular.module('app').controller('AccountAccreditationCtrl',
  function AccountAccreditationCtrl($scope,
    SweetAlert,
    corporateService,
    onboardingService,
    $stateParams,
    contactService,
    $filter,
    toaster) {
    const familyId = $stateParams.familyId;
    $scope.dateArray = [];
    const getLenderAccreditation = () => {
      onboardingService.getFamilyLenderAccreditation(familyId).then((response) => {
        $scope.lenderList = response.data;
      });
    };
    getLenderAccreditation();
    $scope.lendersUnique = [];
    $scope.lendersUniqueInputs = [];
    $scope.lendersUniqueInputsEdit = [];
    $scope.tableHover = function (lenderId) {
      $scope.lendersUnique = [];
      $scope.lendersUnique[lenderId] = true;
    };
    $scope.lenderDynamicTemplate = {
      templateUrl: 'lenderDates.html',
    };
    $scope.showDates = false;
    $scope.editAcceditation = function (lender) {
      $scope.currentLenderId = lender.LenderID;
      $scope.lendersUniqueInputsEdit[lender.LenderID] = true;
      $scope.showDates = true;
      $scope.dateArray[lender.LenderID].dateSent = parseInt($filter('date')(lender.DateSent, 'yyyy'), 10) === 1 ? '' : new Date(lender.DateSent);
      $scope.dateArray[lender.LenderID].dateReceived = parseInt($filter('date')(lender.DateReceived, 'yyyy'), 10) === 1 ? '' : new Date(lender.DateReceived);
    };
    $scope.cancelEdit = function (id) {
      $scope.showDates = false;
      $scope.lendersUniqueInputsEdit[id] = false;
    };
    $scope.setFamilyLenderAccreditation = function (lenderData) {
      const lenderId = lenderData.LenderID;
      const data = {
        LenderID: lenderId,
        LenderName: lenderData.LenderName,
        Lender_IsActive: lenderData.Lender_IsActive,
        IssueCodes: $scope.lendersUniqueInputs[lenderId].issueCodes || '',
        TransferCodes: $scope.lendersUniqueInputs[lenderId].transferCodes || '',
        Type: lenderData.Type,
        Status: $scope.lendersUniqueInputs[lenderId].status || '',
        DateSent: $filter('date')($scope.dateArray[lenderId].dateSent, 'MM-dd-yyyy'), // convertDateManually(document.getElementsByClassName('date-sent')[index].value),
        DateReceived: $filter('date')($scope.dateArray[lenderId].dateReceived, 'MM-dd-yyyy'), // convertDateManually(document.getElementsByClassName('date-received')[index].value)
      };
      onboardingService.setFamilyLenderAccreditation(familyId, data).then((response) => {
        if (response.data > 0) {
          $scope.lendersUniqueInputsEdit = [];
          $scope.showDates = false;
          getLenderAccreditation();
          swal('Success', 'Accreditation successfully saved!', 'success');
        } else {
          swal('Failed', 'Failed in saving accreditation! Please try again.', 'error');
        }
      });
    };
    $scope.accreditationStatus = ['--None--', 'Attained', 'Not Submitted', 'Submitted'];
    $scope.convertDate = function (date) {
      return parseInt($filter('date')(date, 'yyyy'), 10) === 1 ? '' : `<img src="assets/images/icon/icon-cal.svg" /> ${$filter('date')(date, 'dd MM yyyy')}`;
    };
    const deleteLender = (lenderId, lenderName) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: `Remove ${lenderName} from accreditation list.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          corporateService.deleteFamilyLenderAccreditation(familyId, lenderId).then((response) => {
            if (response.data > 0) {
              toaster.pop('success', 'Client Deleted', 'Client successfully deleted from contacts.');
              getLenderAccreditation();
            }
          });
        }
      });
    };
    $scope.deleteLender = deleteLender;
  });
