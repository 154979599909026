import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { convertUtcToUserDate } from 'Common/utilities/date';
import { ADVISER_ORG_TAB } from 'Common/constants/contactTabIndex';
import { displayMap, displayCustomAddress } from 'Common/utilities/map';
import { ACCESS_TYPE, CORP_ACCESS_TYPE } from 'Common/constants/accessTypes';

angular.module('app').controller('ReferrerOrgCtrl', function ReferrerOrgCtrl(
  referralAgreementService,
  regionalizationService,
  broadcastDataService,
  addressModalService,
  dashboardService,
  corporateService,
  modelDataService,
  referralsService,
  commonFnService,
  contactService,
  generalService,
  queryService,
  dataService,
  mapService,
  SweetAlert,
  uiService,
  $uibModal,
  $timeout,
  toaster,
  $filter,
  $window,
  $scope,
  $state
) {
  /* State Params */
  $scope.referrerComplianceId = $state.params.referrerComplianceId || 0;
  $scope.referrerOrgId = $state.params.referrerOrgId || 0;
  /*   VARIABLE(s)        */
  /* Data Type: {Object} */
  $scope.referrerOrganizationMembershipsByOrganizationGet = {};
  $scope.referrerOrgGet = {};
  $scope.director = {};
  $scope.editMode = {};
  /* Data Type: {Array} */
  $scope.organizationTypesGet = [];
  $scope.membershipRoles = [];
  $scope.isHomeAddress = [];
  $scope.addressTypes = [];
  $scope.setterType = [];
  $scope.isMailing = [];
  $scope.saveType = [];
  /* Data Type: {String} */
  $scope.isHomeAddressMapId = 'map-referrerorg-address-home';
  $scope.addressTypeValue = 'Home Address';
  $scope.isMailingMapId = 'map-referrerorg-address-mailing';
  $scope.isProfileAdmin = false;
  $scope.editMailingAdd = false;
  $scope.isAddDirector = false;
  $scope.editHomeAdd = false;
  /* Data Type: {Array of Objects} */
  $scope.tabs_referrers_org = [
    { name: 'Org Details', page: 'assets/views/corporate/referrer-org/org-details.html', value: 'org-details' },
    { name: 'Org Members', page: 'assets/views/corporate/referrer-org/org-members.html', value: 'org-members' },
    { name: 'Agreements', page: 'assets/views/corporate/referrer-org/agreements.html', value: 'agreements' },
  ];
  $scope.deleteType = ['referrerOrgDirectorDelete'];
  const currentState = {
    state: 'app.contactsReferrerOrg',
    params: {
      referrerComplianceId: $scope.referrerComplianceId,
      referrerOrgId: $scope.referrerOrgId,
    },
  };

  const getReferrerOrgAddress = () => {
    corporateService.organizationAddressGet($scope.referrerOrgId)
      .then((response) => {
        const referrerOrgAddressTmp = commonFnService.setAddressTypes(response.data);
        if (response && referrerOrgAddressTmp) {
          $scope.isHomeAddress = angular.extend({}, referrerOrgAddressTmp.home);
          $scope.isMailing = angular.extend({}, referrerOrgAddressTmp.mailing);
        }

        if (_.isUndefined($scope.isMailing)) {
          $scope.isMailing = modelDataService.addressModelData();
        }
        if (_.isUndefined($scope.isHomeAddress)) {
          $scope.isHomeAddress = modelDataService.addressModelData();
        }
      });
  };
  const getAllReferrerOrgDetails = (selectedContactTab) => {
    switch (selectedContactTab) {
    case 'Org Details':
      getReferrerOrgAddress();
      dataService.getOrganizationTypes()
        .then((organizationtypes) => {
          $scope.organizationTypesGet = organizationtypes;
        });
      break;
    case 'Org Members':
      $scope.loadMembers();
      break;
    case 'Agreements':
      corporateService.organizationAgreementGet(0, $scope.referrerOrgId, 0, 0, 0)
        .then((response) => {
          if (response) {
            const tempAgreements = _.uniqBy(response.data, 'AgreementId');
            _.map(tempAgreements, (data) => {
              if (data) {
                if (data.AdviserList.length > 1) {
                  data.showAdvisers = false;
                } else {
                  data.showAdvisers = true;
                }
              }
              return data;
            });

            $scope.getOrganizationWithAgreement = _.map(tempAgreements, (agreement) => {
              if (agreement.AgreementType === 'Loan Market') {
                agreement.AgreementType = 'Loan Market / Wealth Market';
              }
              return agreement;
            });
          }
        });
      referralAgreementService.getCreateAgreementAccess()
        .then((response) => {
          $scope.editMode.allReadOnly = !response;
        });
      break;
    default:
      break;
    }
  };
  /*  FUNCTION DEFINITION(S)  */
  /**
  *   @name initialize
  *   @desc This will load/initialize data needed on load.
  */
  const initialize = () => {
    dataService.getUserInfo(true)
      .then((response) => {
        const { data } = response;
        if (!data) return;
        $scope.isProfileAdmin = data.CorpAccessTypeID === CORP_ACCESS_TYPE.PROFILE_ADMIN;
        $scope.isEnabledFeature = data.AccessType === ACCESS_TYPE.CORPORATE;
        $scope.isSidebarDisabled = !$scope.isEnabledFeature;
      });
    $scope.setterType = dataService.getSetterTypes('Referrer Org');
    $scope.saveType = dataService.getSaveTypes('Adviser');
    dataService.getAddressTypes()
      .forEach((address) => {
        if (address !== 'Business Address') {
          $scope.addressTypes.push(address);
        }
      });
    regionalizationService.GetMembershipRoles()
      .then((response) => {
        if (response) {
          response.data.forEach((value) => {
            $scope.membershipRoles.push(value);
          });
        }
      });
    if ($scope.referrerComplianceId && $scope.referrerOrgId) {
      const currentTab = parseInt($window.sessionStorage.getItem('currentTab'), 10);
      if (currentTab === ADVISER_ORG_TAB.AGREEMENTS) {
        $scope.selectedContacts = currentTab;
        $window.sessionStorage.removeItem('currentTab');
      } else {
        $window.sessionStorage.removeItem('currentTab');
        $scope.selectedContacts = 0;
      }
      $scope.$watch('selectedContacts',
        (newValue) => {
          corporateService.referrerOrgGet($scope.referrerComplianceId, $scope.referrerOrgId)
            .then((response) => {
              if (response) {
                dataService.getRecentViewed();
                if ($scope.tabs_referrers_org[newValue]) {
                  $scope.referrerOrgGet = response.data;
                  getAllReferrerOrgDetails($scope.tabs_referrers_org[newValue].name);
                }
              }
            }, commonFnService.onRetrieveError);
        });
    }
  };
  /*
  *   @name queryReferrerOrgSet
  *   @desc This will save the value from api.
  *   @params {String} setter
  *   @params {String} key
  *   @params {Array} arrays
  *   @params {Boolean} isCorporate
  */
  const queryReferrerOrgSet = (referrerOrgSetter, key, referrerOrgField, referrerOrgArrays) => {
    $scope.editMode[key] = false;
    commonFnService.setEditMode($scope.editMode, referrerOrgField);
    corporateService[referrerOrgSetter].apply(null, referrerOrgArrays)
      .then((response) => {
        if (response) {
          toaster.pop('success', 'Update', 'successfully updated!');
        }
      });
    toaster.clear();
  };

  const saveMembership = (member) => {
    const temp = {
      FamilyId: member.ReferrerFamilyId,
      ClientId: 0,
      ReferrerOrganizationId: $scope.referrerOrgId,
      RoleId: member.RoleId,
      Status: member.Status,
    };

    $scope.referrerOrganizationMembershipsByOrganizationGet = {};
    corporateService.setReferrerOrgMembership(temp)
      .then((response) => {
        if (response) {
          if (response && response.data > 0) {
            toaster.pop('success', 'Update', 'successfully updated!');
            $scope.loadMembers();
          }
        }
      });
  };

  const initDeleteItem = (setType, data, arr, idx) => {
    corporateService[setType](data)
      .then((response) => {
        if (response) {
          toaster.pop('success', 'Delete', '"Successfully Deleted!');
        }
        arr.splice(idx, 1);
      });
  };

  const saveReferrer = (member) => {
    let data = {};
    data = modelDataService.referrerModelDate(member, $scope.referrerOrgId);
    contactService.setAdviser(0, data)
      .then((response) => {
        if (response) {
          if (response.data > 0) {
            saveMembership(member);
          } else {
            swal('Oops...', 'Something went wrong!', 'error');
          }
        }
      });
  };

  /* ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */
  $scope.loadMembers = () => {
    $scope.referrerOrganizationMembershipsByOrganizationGet = {};
    corporateService.referrerOrganizationMembershipsByOrganizationGet($scope.referrerOrgId)
      .then((response) => {
        if (response) {
          $scope.referrerOrganizationMembershipsByOrganizationGet = response.data;
        }
      });
  };

  $scope.showAddress = (addressValue) => {
    if (addressValue === 'Home Address') {
      $scope.editMailingAdd = false;
    } else if (addressValue === 'Mailing Address') {
      $scope.editHomeAdd = false;
    }
    getReferrerOrgAddress();
  };

  $scope.editAddressModal = (type) => {
    if (type === 'Home Address') {
      $scope.editHomeAdd = !$scope.editHomeAdd;
    } else if (type === 'Mailing Address') {
      $scope.editMailingAdd = !$scope.editMailingAdd;
    }
  };

  $scope.saveAddress = (type, address) => {
    const isMailing = (type === 'Mailing Address');
    commonFnService.saveAddress($scope.referrerOrgId, 0, address, isMailing, false, true)
      .then(() => {
        getReferrerOrgAddress();
        $scope.editMailingAdd = false;
        $scope.editHomeAdd = false;
      });
  };

  $scope.updateReferrerOrgDetails = (setterType, obj, key, saveType, field) => {
    let dataArray = [];
    switch (setterType) {
    case 'referrerOrgSet':
      switch (key) {
      case 'OrganisationType':
        obj.OrganisationTypeId = queryService.getArrValue(null, obj.OrganisationType, 'OrganizationTypeId', 'OrganizationTypeName', $scope.organizationTypesGet);
        break;
      default:
        break;
      }
      dataArray = [$scope.referrerComplianceId, $scope.referrerOrgId, obj];
      break;
    case 'referrerOrgDirectorSet':
      obj.ReferrerOrgId = $scope.referrerOrgId;
      dataArray = [obj.ReferrerOrgId, obj];
      break;
    case 'setReferrerOrgMembership':
      obj.ClientId = 0;
      switch (key) {
      case 'RoleDescription':
        if (obj.Referrer) {
          obj.FamilyId = obj.Referrer.originalObject.ReferrerFamilyId;
          obj.ReferrerOrganizationId = $scope.referrerOrgId;
          obj.RoleId = queryService.getArrValue(null, obj.RoleDescription, 'RoleId', 'RoleDesc', $scope.membershipRoles);
        } else {
          obj.FamilyId = obj.ReferrerFamilyId;
        }
        break;
      default:
        break;
      }
      dataArray = [$scope.referrerComplianceId, $scope.referrerOrgId, obj];
      break;
    case 'setReferrerOrgRelationship':
      obj.ClientId = 0;
      break;
    default:
      break;
    }

    if (dataArray && dataArray.length > 0) {
      queryReferrerOrgSet(setterType, key, field, dataArray);
    }
  };

  $scope.viewAdvisers = (data) => {
    data.showAdvisers = !data.showAdvisers;
  };

  $scope.deleteItem = (setterType, obj, arr, idx) => {
    obj.organizationId = $scope.referrerOrgId;
    initDeleteItem(setterType, obj, arr, idx);
  };

  $scope.saveDirector = () => {
    if (typeof $scope.director.name !== 'undefined' || $scope.director.name !== '') {
      const referrerDirectorData = {
        DirectorId: 0,
        Name: $scope.director.name || '',
        Contact: $scope.director.number || '',
        Email: $scope.director.email || '',
      };
      corporateService.referrerOrgDirectorSet($scope.referrerOrgId, referrerDirectorData)
        .then((response) => {
          if (response) {
            if (response.data > 0) {
              $scope.cancelDirector();
              corporateService.referrerOrgGet($scope.referrerComplianceId, $scope.referrerOrgId)
                .then((referreOrg) => {
                  if (referreOrg) {
                    $scope.referrerOrgGet = referreOrg.data;
                    toaster.pop('success', 'Add', '"Successfully added the director!');
                  }
                });
            }
          }
        });
    }
  };

  $scope.addDirector = () => {
    $scope.isAddDirector = true;
  };

  $scope.cancelDirector = () => {
    $scope.director = {};
    $scope.isAddDirector = false;
  };

  $scope.addNewOrgMemberExisting = function () {
    $uibModal.open({
      templateUrl: 'assets/views/corporate/referrer-org/referrer_member.html',
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      controller: 'ReferrerAsMemberNewCtrl',
      resolve: {
        referrerOrganizationId() {
          return $state.params.referrerOrgId;
        },
        modeForModal() {
          return 'existing';
        },
        callback() {
          return $scope.loadMembers();
        },
      },
    });
  };


  $scope.isDateValid = (date) => {
    const d = new Date(date);
    return parseInt(d.getFullYear(), 10) > 1;
  };

  $scope.convertToDate = (date) => {
    return new Date(date);
  };

  $scope.convertUtcToUserDate = date => convertUtcToUserDate(date);

  $scope.editMember = (member, index) => {
    if (index >= 0) {
      corporateService.corporateContactGet(0, member.ReferrerFamilyId)
        .then((response) => {
          if (response) {
            if (response.data) {
              const data = response.data;
              $scope.referrerOrganizationMembershipsByOrganizationGet[index].FirstName = data.FirstName;
              $scope.referrerOrganizationMembershipsByOrganizationGet[index].LastName = data.LastName;
            }
          }
        });
    }
  };

  $scope.updateMember = (member) => {
    $scope.referrerOrganizationMembershipsByOrganizationGet = [];
    if (member && typeof member.ReferrerFamilyId !== 'undefined') {
      saveReferrer(member);
    }
  };

  $scope.editAgreement = (agreementId) => {
    referralsService.getReferrerOrgSummary(agreementId)
      .then((response) => {
        $window.sessionStorage.setItem('currentTab', ADVISER_ORG_TAB.AGREEMENTS);
        $window.sessionStorage.setItem('currentState', JSON.stringify(currentState));
        const typeId = response.data.AgreementTYpeID === 2 ? 4 : 0;
        const orgTypeId = response.data.AgreementTYpeID === 3 ? response.data.OrganisationTypeId : typeId;
        const agreementTypeId = response.data.AgreementTYpeID;
        $state.go('app.newReferral', { referralType: orgTypeId, agreementId, agreementTypeId, requestId: 1 });
      });
  };

  $scope.addAgreement = (referralType) => {
    $window.sessionStorage.setItem('currentTab', ADVISER_ORG_TAB.AGREEMENTS);
    referralAgreementService.launchNewReferralModal(referralType, currentState, $scope);
  };

  $scope.addNewOrgMember = () => {
    $uibModal.open({
      templateUrl: 'assets/views/corporate/referrer-org/referrer_member.html',
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      controller: 'ReferrerAsMemberNewCtrl',
      resolve: {
        referrerOrganizationId() {
          return $state.params.referrerOrgId;
        },
        modeForModal() {
          return 'add';
        },
        callback() {
          return $scope.loadMembers();
        },
      },
    });
  };

  $scope.launchAddressModal = () => {
    const size = 'md';
    const isOrgAddress = true;
    const modalInstance = addressModalService.launchAddressModal(size, $scope.referrerOrgId, isOrgAddress);
    modalInstance.result.then(() => {
      $scope.timeOutRefOrgInstance = $timeout(() => {
        getReferrerOrgAddress();
        toaster.pop('success', 'Successfully Updated', 'Address');
      }, 500);
    });
  };
  $scope.$on('$destory', () => {
    $timeout.cancel($scope.timeOutRefOrgInstance);
  });
  $scope.displayMap = displayMap;
  $scope.displayCustomAddress = displayCustomAddress;
  /**
    *   Function Call
    */
  initialize();
});
