import angular from 'angular';

angular.module('app').controller('FinPosExpenseModalCtrl', function FinPosExpenseModalCtrl($scope,
  $uibModalInstance) {
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.saveBreakdown = function () {
    $scope.cancel();
  };
});
