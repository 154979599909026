import angular from 'angular';
import swal from 'sweetalert';

angular.module('app').controller('CorporateTargetManagementCtrl', function CorporateTargetManagementCtrl(
  $scope,
  NgTableParams,
  $filter,
  $uibModal,
  corporateService,
  $timeout,
  $rootScope,
  toaster
) {
  $scope.targetManagementList = [];
  $scope.targetManagementModel = {};

  const reportingStatesGet = () => {
    corporateService.reportingStatesGet().then((response) => {
      response.data.unshift({
        ReportingStateId: 0,
        ReportingStateName: 'All Team',
      });
      $scope.teamList = response.data;
      $scope.targetManagementModel.team = response.data[0];
    });
  };
  reportingStatesGet();

  const getAdminUsers = () => {
    corporateService.getAdminUsers().then((response) => {
      response.data.unshift({
        FamilyId: 0,
        FullName: 'All Owners',
      });
      $scope.ownerList = response.data;
      $scope.targetManagementModel.owner = response.data[0];
    });
  };
  getAdminUsers();

  $scope.targetManagementModel.periodRelated = 'Month';
  $scope.getDateFormat = () => {
    let ret = 'MMMM yyyy';
    if ($scope.targetManagementModel.periodRelated === 'Year') {
      ret = 'yyyy';
    }
    return ret;
  };

  const getTargetManagement = () => {
    const teamId = $scope.targetManagementModel.team ? $scope.targetManagementModel.team.ReportingStateId : 0;
    const targetOwnerId = $scope.targetManagementModel.owner ? $scope.targetManagementModel.owner.FamilyId : 0;
    const period = $scope.targetManagementModel.period ? $filter('date')($scope.targetManagementModel.period, 'M') : 0;
    const month = $scope.targetManagementModel.periodRelated === 'Month' ? period : 0;
    const year = $scope.targetManagementModel.period ? $filter('date')($scope.targetManagementModel.period, 'yyyy') : 0;
    corporateService.getTargetManagement(0, month, year, teamId, targetOwnerId).then((response) => {
      $scope.targetManagementList = response.data;
      $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
        sorting: {
          name: 'TargetDescription',
        },
      }, {
        total: $scope.targetManagementList.length,
        getData($defer, params) {
          const orderedData = params.sorting() ? $filter('orderBy')($scope.targetManagementList, params.orderBy()) : $scope.targetManagementList;
          $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        },
      });
    });
  };
  getTargetManagement();

  $rootScope.loadTargets = () => {
    getTargetManagement();
  };

  $scope.sample = [
    { id: 1, name: 'All Item' },
    { id: 2, name: 'Item 1' },
  ];

  $scope.targetManagementNewModel = {
    TargetManagementID: 0,
    TargetDescription: '',
    TargetType: '',
    TargetMonth: '',
    TargetYear: '',
    AllocationType: '',
    TeamId: 0,
    Team: '',
    TargetOwnerID: 0,
    TargetOwnerName: 's',
    ValueType: '',
    CurrencyCodeID: 0,
    CurrencyName: '',
    CurrencySign: '',
    PercentageIndicator: '',
    TargetValue: 0,
    AmberThreshold: 0,
    RedThreshold: 0,
    RelatedLoanStatusID: 0,
    RelatedLoanStaus: '',
    RelatedProductGroupID: 0,
    RelatedProductGroup: '',
    IsRecurring: 0,
  };

  $scope.showTargetModal = (model) => {
    model.IsActive = 1;
    const data = model;
    $uibModal.open({
      templateUrl: 'assets/views/corporate/targetManagement/target_management_new.html',
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      controller: 'CorporateTargetManagementNewCtrl',
      resolve: {
        targetManagementData() {
          return data;
        },
      },
    });
  };

  $scope.selectionChange = () => {
    getTargetManagement();
  };

  $scope.deleteTarget = (data) => {
    swal({
      title: 'Are you sure you want to delete this Target?',
      text: 'If you want to recover just contact the administrator!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, Delete it!',
      closeOnConfirm: true,
    },
    () => {
      data.IsActive = 0;
      corporateService.setTargetManagement(data).then((response) => {
        if (response.data) {
          toaster.pop('success', 'Deleted!', 'Target successfully deleted.');
          getTargetManagement();
        } else {
          toaster.pop('error', 'Error!', 'Deleting target encountered error! Try again.');
        }
      });
    });
  };

  $scope.getMonthName = (month) => {
    let monthName = '';
    switch (month) {
    case 1:
      monthName = 'January '; break;
    case 2:
      monthName = 'February '; break;
    case 3:
      monthName = 'March '; break;
    case 4:
      monthName = 'April '; break;
    case 5:
      monthName = 'May '; break;
    case 6:
      monthName = 'June '; break;
    case 7:
      monthName = 'July '; break;
    case 8:
      monthName = 'August '; break;
    case 9:
      monthName = 'September '; break;
    case 10:
      monthName = 'October '; break;
    case 11:
      monthName = 'November '; break;
    case 12:
      monthName = 'December '; break;
    default: break;
    }
    return monthName;
  };

  $scope.$watch('targetManagementModel.periodRelated', () => {
    getTargetManagement();
  });

  $scope.getTargetValueOutput = (data) => {
    let retValue = data.TargetValue;
    if (data.ValueType === 'Currency') {
      retValue = $filter('currency')(data.TargetValue, data.CurrencySign, 0);
    } else if (data.ValueType === 'Percentage') {
      retValue = `${retValue}%`;
    } else {
      retValue = $filter('number')(parseInt(retValue, 10), 0);
    }

    return retValue;
  };
});
