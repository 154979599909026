import _ from 'lodash';

class lmiSecuritiesCtrl {
  constructor(
    toaster,
    fundingCalculatorService,
    fundingCalculatorSharedData
  ) {
    'ngInject';

    this.toaster = toaster;
    this.fundingCalculatorService = fundingCalculatorService;
    this.fundingCalculatorSharedData = fundingCalculatorSharedData;
  }

  getState() {
    this.fundingCalculatorService.getFundingCalculatorStates()
      .then((response) => {
        this.StateObj = response.data;
      });
  }

  addSecurity() {
    if (this.addSecurityEnabled) {
      this.addSecurityEnabled = false;
      this.mortgageObj.security.push(this.fundingCalculatorSharedData.lmiSecurity());
    }
  }

  editSecurity(idx) {
    if (this.addSecurityEnabled && this.mortgageObj.IsQuickCalc) {
      this.addSecurityEnabled = false;
      this.mortgageObj.security[idx].formInEditMode = true;
    }
  }

  getOccupancyType(occupancyType) {
    this.isOwnerOccupied = occupancyType === 'PurchaseOwnerOccupied' || occupancyType === 'ReleaseOwnerOccupied' || occupancyType === 'LandOwnerOccupied' ? 'Owner Occupied' : 'Rental';
    return this.isOwnerOccupied;
  }

  saveSecurity(idx) {
    if (!this.mortgageObj.security[idx].Value) {
      this.toaster.pop('error', 'Invalid', 'Please set the security value amount');
      return;
    }
    this.mortgageObj.security[idx].Value = this.mortgageObj.security[idx].Value.replace(/,/g, '');
    this.addSecurityEnabled = true;
    this.mortgageObj.security[idx].formInEditMode = false;
    this.updateLVR();
  }

  deleteSecurity(idx) {
    this.addSecurityEnabled = true;
    this.mortgageObj.security.splice(idx, 1);
    if (!this.mortgageObj.security.length) {
      this.mortgageObj.security.push(this.fundingCalculatorSharedData.lmiSecurity());
    }
    this.updateLVR();
  }

  updateLVR() {
    const { NewLoanAmount, security } = this.mortgageObj;
    const SecurityAmount = _.sumBy(security, item => parseFloat(item.Value));
    this.mortgageObj.NewLVRValue = this.fundingCalculatorSharedData.updateQuickLmiLvrValue(NewLoanAmount, SecurityAmount);
    this.mortgageObj.LVRValue = this.mortgageObj.NewLVRValue.replace('%', '');
  }

  $onChanges(changes) {
    if (changes && changes.brokerEventId && changes.brokerEventId.currentValue) {
      this.brokerEventId = changes.brokerEventId.currentValue;
    }
  }

  $onInit() {
    this.getState();
  }
}

export default lmiSecuritiesCtrl;
