import angular from 'angular';
import _ from 'lodash';

angular.module('app').directive('customBrandingLogo', [
  function customBrandingLogo() {
    return {
      restrict: 'A',
      scope: {
        brandingLogo: '=customBrandingLogo',
      },
      link($scope, $elem, $attrs) {
        $attrs.$observe('custom-branding-default-logo', (value) => {
          $scope.defaultLogo = value;
        });

        function setDefaultLogo() {
          if (!$elem.hasClass('logo-default')) {
            const rawElem = $elem[0];
            rawElem.src = $scope.defaultLogo ? $scope.defaultLogo : 'assets/images/logo.svg';
            rawElem.classList.add('logo-default');
          }
        }

        $scope.$watch(() => {
          return $scope.brandingLogo ? $scope.brandingLogo.logo + $scope.brandingLogo.logoPreview : $scope.brandingLogo;
        }, () => {
          if ($scope.brandingLogo) {
            const previewParentElem = angular.element('.sidebar-preview');
            const previewParentChildImg = previewParentElem ? previewParentElem.find('#brand-logo img') : undefined;
            if (previewParentElem && !_.isEmpty(previewParentChildImg) && _.isEqual(previewParentChildImg[0], $elem[0])) {
              if ($scope.brandingLogo.logoPreview) {
                const rawElem = $elem[0];
                rawElem.src = $scope.brandingLogo.logoPreview;
                rawElem.classList.remove('logo-default');
              } else {
                setDefaultLogo();
              }
            } else if ($scope.brandingLogo.logo) {
              const rawElem = $elem[0];
              rawElem.src = $scope.brandingLogo.logo;
              rawElem.classList.remove('logo-default');
            } else {
              setDefaultLogo();
            }
          } else {
            setDefaultLogo();
          }
        });
      },
    };
  },
]);
