class LenderRatesService {
  constructor() {
    let lenderRate = {};

    this.setLenderRateInfoForProductFinder = (info) => {
      lenderRate = info;
    };

    this.getLenderRateInfoForProductFinder = () => {
      return lenderRate;
    };
  }
}
export default LenderRatesService;

