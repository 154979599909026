import angular from 'angular';
import $ from 'jquery';
import { INSURANCE_QUOTE_REPORT_SECTION } from 'Common/constants/insuranceOptions';

angular.module('app').directive('pdfDownload', function pdfDownload($window, $timeout, insuranceQuoteService, toaster) {
  return {
    restrict: 'A',
    scope: {
      insurerProviderId: '=',
      reportSettings: '<',
      onPdfDownloaded: '&',
    },
    link: (scope, element) => {
      const clickListener = () => {
        const processTimeOut = $timeout(() => {
          const { ReportOptions: reportOptions } = scope.reportSettings;
          const isInsurerRequired = reportOptions.Sections.indexOf(INSURANCE_QUOTE_REPORT_SECTION.INSURER_RECOMMENDATION) !== -1;
          const isValidInsurer = !isInsurerRequired || !!reportOptions.ProviderID;

          if (!isValidInsurer) {
            toaster.pop('warning', 'Required!', 'Please select an Insurer.');
            return;
          }

          $('#quoteGenerateRerportBtn').addClass('hidden');
          $('#quoteGenerateRerportLoader').removeClass('hidden');

          insuranceQuoteService.insuranceInsurerReportGet(scope.reportSettings.quoteId, scope.reportSettings).then((response) => {
            const data = response.data;
            data.Name = 'Insurer Report';

            const a = $window.document.createElement('a');
            a.setAttribute(`href`, `data:${data.ContentType};base64,${data.DocumentContent}`);
            a.setAttribute(`download`, data.Name);

            a.style.display = `none`;
            $window.document.body.appendChild(a);

            const clickDelay = $timeout(() => {
              a.click();
            });
            scope.$on('destroy', () => {
              $timeout.cancel(clickDelay);
            });

            $window.document.body.removeChild(a);
            $('#quoteGenerateRerportBtn').removeClass('hidden');
            $('#quoteGenerateRerportLoader').addClass('hidden');

            if (scope.reportSettings.ReportOptions.SaveClientDocument) {
              toaster.pop('success', 'Saved', 'Document successfully saved to client.');
            }

            if (typeof scope.onPdfDownloaded === 'function') {
              scope.onPdfDownloaded({ pdf: data });
            }
          });
        });
        scope.$on('destroy', () => {
          $timeout.cancel(processTimeOut);
        });
      };

      element.on('click', clickListener);
      scope.$on('destroy', () => {
        element.off('click', clickListener);
      });
    },
  };
});
