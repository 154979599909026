import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

angular.module('app').controller('InsuranceModalCtrl',
  function InsuranceModalCtrl(
    $rootScope,
    $scope,
    $timeout,
    $state,
    $uibModal,
    $localStorage,
    contactService,
    selectOptionsService,
    SweetAlert
  ) {
    $scope.maskOptions = {
      maskDefinitions: {
        9: /\d/,
      },
      clearOnBlur: false,
    };
    // Insurance defaults
    $scope.insurance = {
      FamilyId: $state.params.familyId,
      BenefitID: 0,
      AppId: 0,
      PolicyNumberChangeAll: false,
      ArrearsChangeAll: false,
      StatusChangeAll: false,
      FrequencyChangeAll: false,
      StatusClientType: '',

      Indexed: true,
      isAutoCalculation: true,
      IsWaivePolicy: false,
      isLoadinExclusions: false,
      IsRebate: false,
      RebateChequeSent: false,
      FutureInsurability: false,
      IsPremier: false,
      Comprehensive: false,
      Standalone: false,
      OwnOccupation: false,
      LifeBuyback: false,
      TraumaBuyback: false,
      EarlyCancerUpgrade: false,
      EarlyTraumaBenefit: false,
      TPDExtension: false,
      IsCurrent: false,
    };
    let oinsurance = {};

    function pickFromObj(obj, key, val) {
      let s;
      _.each(obj, (item) => {
        if (item[key] === val) {
          s = item;
          return true;
        }
      });
      return s ? angular.copy(s) : null;
    }

    // Insurers
    function _getInsurers() {
      const insurance = angular.copy($scope.insurance);
      if (!insurance.BenefitType || insurance.BenefitType.BenefitTypeID === '') return;
      contactService.insurersGet(insurance.BenefitType.BenefitTypeID).then((response) => {
        $scope.insurers = response.data;
      });
    }

    function getInsurers() {
      // Reset
      $scope.insurance.Insurer = null;
      $scope.insurance.Product = null;
      _getInsurers();
    }
    $scope.getInsurers = getInsurers;

    // Products
    function getProducts() {
      const insurance = angular.copy($scope.insurance);
      if (!insurance.BenefitType || !insurance.Insurer) return;
      contactService.productsGet(insurance.BenefitType.BenefitTypeID, insurance.Insurer.InsurerId).then((response) => {
        $scope.products = response.data;
      });
    }
    $scope.getProducts = getProducts;

    function loadSelectOptions() {
      const insurance = angular.copy($scope.insurance);

      // Insurance Types
      contactService.insuranceTypesGet().then((response) => {
        $scope.BenefitTypes = response.data;
        if (typeof insurance.BenefitID !== typeof undefined
          && insurance.BenefitID !== 0) {
          $timeout(() => {
            $scope.insurance.BenefitType = pickFromObj($scope.BenefitTypes, 'BenefitTypeID', insurance.BenefitTypeID);
            _getInsurers();
            getProducts();
          });
        }
      });


      // Insurance Statuses
      contactService.insuranceStatusesGet().then((response) => {
        $scope.insuranceStatuses = _.sortBy(response.data, 'Category');
        if (typeof insurance.StatusId !== typeof undefined
          && insurance.StatusId !== 0) {
          $timeout(() => {
            $scope.insurance.StatusObj = pickFromObj($scope.insuranceStatuses, 'StatusId', insurance.StatusId);
          });
        }
      });

      // PaymentFrequency
      selectOptionsService.getPaymentFrequency().then((response) => {
        $scope.paymentFrequency = response.data;
        if (insurance.Frequency && insurance.Frequency !== null) {
          $timeout(() => {
            $scope.insurance.FrequencyObj = pickFromObj($scope.paymentFrequency, 'Value', insurance.Frequency);
          });
        }
      });

      // CalculationPeriods
      selectOptionsService.getCalculationPeriod().then((response) => {
        $scope.CalculationPeriods = response.data;
        if (insurance.CalculationPeriod && insurance.CalculationPeriod !== null) {
          $timeout(() => {
            $scope.insurance.CalculationPeriodObj = pickFromObj($scope.CalculationPeriods, 'Value', insurance.CalculationPeriod);
          });
        }
      });

      // WaitPeriods
      selectOptionsService.getWaitPeriod().then((response) => {
        $scope.WaitPeriods = response.data;
        if (insurance.WaitPeriod && insurance.WaitPeriod !== null) {
          $timeout(() => {
            $scope.insurance.WaitPeriodObj = pickFromObj($scope.WaitPeriods, 'Value', insurance.WaitPeriod);
          });
        }
      });

      // IPCoverTypes
      selectOptionsService.getIPCoverType().then((response) => {
        $scope.CoverTypes = response.data;
        if (insurance.IPCoverType && insurance.IPCoverType !== null) {
          $timeout(() => {
            $scope.insurance.IPCoverTypeObj = pickFromObj($scope.CoverTypes, 'Value', insurance.IPCoverType);
          });
        }
      });

      // BenefitPeriods
      selectOptionsService.getBenefitPeriods().then((response) => {
        $scope.BenefitPeriods = response.data;
        if (insurance.BenefitPeriod && insurance.BenefitPeriod !== null) {
          $timeout(() => {
            $scope.insurance.BenefitPeriodObj = pickFromObj($scope.BenefitPeriods, 'Value', insurance.BenefitPeriod);
          });
        }
      });
    }

    if ($localStorage.ActiveBenefitID) {
      $timeout(() => {
        contactService.insuranceDetailsGet(
          $localStorage.ActiveBenefitID,
          $state.params.familyId
        ).then((response) => {
          let insurance = angular.copy($scope.insurance);
          insurance = angular.extend(insurance, response.data);

          insurance.Insurer = insurance.Product.Insurer;
          $scope.LoadingExclusions = angular.copy(insurance.LoadingOrExclusions);
          if (insurance.InsuredIndividualClientId) {
            insurance.InsuredIndividual = pickFromObj($scope.ContactClients, 'PersonId', insurance.InsuredIndividualClientId);
          }
          $scope.InsuredIndividual = angular.copy(insurance.Options);
          $scope.PolicyOwnerships = angular.copy(insurance.PolicyOwnership);

          insurance.CommencementDate = moment(new Date(insurance.CommencementDate)).zone('+1300').format('MM/DD/YYYY');
          oinsurance = angular.copy(insurance);

          $timeout(() => {
            $scope.insurance = insurance;
            loadSelectOptions();
          });
        });
      }, 1000);
    } else {
      oinsurance = angular.copy($scope.insurance);
      loadSelectOptions();
    }

    function setBenefitType() {
      getInsurers();
      const insurance = angular.copy($scope.insurance);
      const isBenefitId = !!insurance.BenefitID;

      if (isBenefitId) return;

      $timeout(() => {
        if (insurance.BenefitType && insurance.BenefitType.BenefitCategory === 'Health') {
          $scope.LoadingExclusions = angular.copy($scope.ContactClients);
          $scope.InsuredIndividual = angular.copy($scope.ContactClients);

          angular.forEach($scope.InsuredIndividual, (insured) => {
            insured.Excess = 'Nil Excess';
            insured.BaseCover = false;
            insured.Specialists = false;
            insured.GPPrescriptions = false;
            insured.DentalOptical = false;
          });
        } else {
          $scope.InsuredIndividual = null;
          $scope.LoadingExclusions = [angular.copy($scope.ContactAdult[0])];
          $scope.insurance.InsuredIndividual = angular.copy($scope.ContactAdult[0]);
        }
      });
    }
    $scope.setBenefitType = setBenefitType;

    $scope.submitInsurance = function () {
      const insurance = angular.copy($scope.insurance);
      const benefitType = insurance.BenefitType;
      const insuredIndividual = insurance.InsuredIndividual;

      insurance.AppId = 0;
      if (!insurance.BenefitType) {
        return SweetAlert.swal('Stop', 'Please select Benefit Type!', 'error');
      }

      if (!insurance.Insurer) {
        return SweetAlert.swal('Stop', 'Please select Insurer!', 'error');
      }

      if (!insurance.Product) {
        return SweetAlert.swal('Stop', 'Please select Product!', 'error');
      }

      if (!insurance.FrequencyObj) {
        return SweetAlert.swal('Stop', 'Please select Frequency!', 'error');
      }

      if (!insurance.StatusObj) {
        return SweetAlert.swal('Stop', 'Please select Status!', 'error');
      }

      if (!insurance.CommencementDate) {
        return SweetAlert.swal('Stop', 'Please fillup Commencement Date!', 'error');
      }

      if (moment(new Date(insurance.CommencementDate)).isAfter(new Date())) {
        return SweetAlert.swal('Stop', 'Invalid Commencement Date!', 'error');
      }

      if (benefitType.BenefitTypeName !== 'Health Cover') {
        if (!insurance.CoverAmount || Number(insurance.CoverAmount) <= 0) {
          return SweetAlert.swal('Stop', 'Please input a valid Current Cover Amount!', 'error');
        }
      }

      insurance.BenefitTypeID = benefitType.BenefitTypeID;
      insurance.BenefitTypeName = benefitType.BenefitTypeName;
      delete insurance.BenefitType;

      insurance.Product.Insurer = angular.copy(insurance.Insurer);
      delete insurance.Insurer;

      if (typeof $scope.InsuredIndividual !== typeof null && $scope.InsuredIndividual.length > 0) {
        insurance.Options = angular.copy($scope.InsuredIndividual);
      }
      if (insuredIndividual) {
        insurance.InsuredIndividualClientId = insuredIndividual.PersonId;
        insurance.Options = angular.copy([insuredIndividual]);
        delete insurance.InsuredIndividual;
      }
      angular.forEach(insurance.Options, (option) => {
        delete option.SmokerStatus;
        delete option.Phone;
        delete option.Email;
        delete option.Gender;
        delete option.Title;
        option.BaseCover = option.BaseCover || false;
        option.Specialists = option.Specialists || false;
        option.GPPrescriptions = option.GPPrescriptions || false;
        option.DentalOptical = option.DentalOptical || false;
        option.Excess = option.Excess || 0;
      });

      insurance.PolicyOwnership = angular.copy($scope.PolicyOwnerships);

      insurance.TraumaChildren = [];
      angular.forEach($scope.TraumaChildren, (child) => {
        insurance.TraumaChildren.push({
          ClientId: child.PersonId,
          isTrauma: child.isTrauma || false,
          Name: `${child.FirstName} ${child.LastName}`,
        });
      });

      let isValidLE = true;
      angular.forEach($scope.LoadingExclusions, (le) => {
        if (le.IsLoadingExclusion && le.ExclusionReviewDate) {
          if (moment(new Date(le.ExclusionReviewDate)).isAfter(new Date())) {
            isValidLE = false;
          }
        }
      });
      if (!isValidLE) {
        return SweetAlert.swal('Stop', 'Invalid Exclusion Review Date!', 'error');
      }

      insurance.LoadingOrExclusions = angular.copy($scope.LoadingExclusions);

      angular.forEach(insurance.LoadingOrExclusions, (person) => {
        if (!person.ClientId && person.PersonId) {
          person.ClientId = person.PersonId;
          delete person.PersonId;
        }
      });

      angular.forEach(insurance.Options, (person) => {
        if (!person.ClientId && person.PersonId) {
          person.ClientId = person.PersonId;
          delete person.PersonId;
        }
      });

      if (insurance.StatusObj.Category === 'Previous')
        insurance.StatusClientType = 'Former Client';
      else if (insurance.StatusObj.Category === 'Existing')
        insurance.StatusClientType = 'Current Client';
      else
        insurance.StatusClientType = '';

      insurance.StatusId = insurance.StatusObj.StatusId;
      insurance.Status = insurance.StatusObj.StatusName;
      delete insurance.StatusObj;

      insurance.Frequency = insurance.FrequencyObj.Value;
      delete insurance.FrequencyObj;

      if (insurance.CalculationPeriodObj) {
        insurance.CalculationPeriod = insurance.CalculationPeriodObj.Value;
        delete insurance.CalculationPeriodObj;
      }

      if (insurance.WaitPeriodObj) {
        insurance.WaitPeriod = insurance.WaitPeriodObj.Value;
        delete insurance.WaitPeriodObj;
      }

      if (insurance.BenefitPeriodObj) {
        insurance.BenefitPeriod = insurance.BenefitPeriodObj.Value;
        delete insurance.BenefitPeriodObj;
      }

      if (insurance.IPCoverTypeObj) {
        insurance.IPCoverType = insurance.IPCoverTypeObj.Value;
        delete insurance.IPCoverTypeObj;
      }

      if (insurance.IsPolicyOwnerOther) {
        insurance.PolicyOwnership.push({
          ClientId: 0,
          FirstName: insurance.PolicyOwnerOther,
        });
      }

      contactService.insuranceDetailsSet(insurance).then((response) => {
        if (response.status === 200) {
          SweetAlert.swal('Success', 'Insurance information has been successfully added', 'success');
          $scope.$close();
          $rootScope.$broadcast('reload-insurance');
        } else {
          SweetAlert.swal('Stop', 'Failed to add Insurance information!', 'error');
        }
      });
    };

    $scope.onCancel = $scope.$close;

    // Clients list
    contactService.clientInformGet($state.params.familyId).then((response) => {
      $scope.ContactClients = [];
      $scope.ContactAdult = [];
      $scope.ContactChild = [];
      if (response.data.length > 0) {
        angular.forEach(response.data, (person) => {
          if (person.Role !== 'PolicyOwner')
            $scope.ContactClients.push(person);

          if (person.Role === 'Adult') {
            $scope.ContactAdult.push(person);
          } else if (person.Role === 'Child') {
            $scope.ContactChild.push(person);
          }
        });
        if ($scope.insurance.BenefitID === 0) {
          $scope.TraumaChildren = angular.copy($scope.ContactChild);
          const policyOwnerships = [];
          angular.forEach($scope.ContactClients, (client) => {
            policyOwnerships.push({
              IsPolicyOwner: true,
              Name: `${client.FirstName} ${client.LastName}`,
              ClientId: client.PersonId,
            });
          });
          $scope.PolicyOwnerships = policyOwnerships;
          if ($scope.ContactAdult.length > 0) {
            $scope.insurance.InsuredIndividual = angular.copy($scope.ContactAdult[0]);
            $scope.LoadingExclusions = [angular.copy($scope.ContactAdult[0])];
          }
        }
      }
    });

    $scope.ExcessOptions = ['Nil Excess', '250 Excess', '500 Excess', '1000 Excess', '2000 Excess'];

    $scope.LoadingPercents = [
      '0 %',
      '50 %',
      '75 %',
      '100 %',
      '125 %',
      '150 %',
      '175 %',
      '200 %',
      '225 %',
      '250 %',
      '275 %',
      '300 %',
      '325 %',
      '350 %',
      '400 %',
      '500 %',
    ];

    function promptUpdate(field, str) {
      SweetAlert.swal({
        title: 'Confirm Update',
        text: `Do you want to change the other ${str} with same old ${str} to new one?`,
        type: 'info',
        showCancelButton: true,
        confirmButtonClass: 'btn-primay',
        confirmButtonText: 'Yes, change all!',
        cancelButtonText: 'No!',
        closeOnConfirm: true,
      },
      (confirm) => {
        $scope.insurance[field] = confirm;
      });
    }

    $scope.$watch('insurance.PolicyNumber', (policyNo) => {
      if (oinsurance.BenefitID && oinsurance.BenefitID !== 0 && policyNo && policyNo !== oinsurance.PolicyNumber) {
        promptUpdate('PolicyNumberChangeAll', 'Policy Number');
      }
    });

    $scope.$watch('insurance.InArrears', (InArrears) => {
      if (oinsurance.BenefitID && oinsurance.BenefitID !== 0 && typeof InArrears !== typeof undefined && InArrears !== oinsurance.InArrears) {
        promptUpdate('ArrearsChangeAll', 'Arrears');
      }
    });

    $scope.$watch('insurance.StatusObj', (StatusObj) => {
      if (!StatusObj) return;
      if (oinsurance.BenefitID && oinsurance.BenefitID !== 0 && StatusObj.Name && StatusObj.Name !== oinsurance.Status) {
        promptUpdate('StatusChangeAll', 'Status');
      }
    }, true);

    $scope.$watch('insurance.FrequencyObj', (FrequencyObj) => {
      if (!FrequencyObj) return;
      if (oinsurance.BenefitID && oinsurance.BenefitID !== 0 && FrequencyObj.Name && FrequencyObj.Name !== oinsurance.Frequency) {
        promptUpdate('frequencyChangeAll', 'Frequency');
      }
    }, true);
  });
