import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';


angular.module('app').controller('SidebarDetailCtrl',
  function SidebarDetailCtrl($scope,
    contactService,
    dashboardService,
    notificationSidebarService,
    taskService,
    $uibModal,
    $timeout,
    SweetAlert,
    toaster,
    $state,
    utilitiesService,
    $stateParams,
    loanScenarioService,
    tasksSharedData,
    userService,
    corporateService,
    contactSharedData) {
    $scope.isClientView = $state.includes('app.contactsSingle');

    $scope.loanList = [];
    if ($state.params.familyId) {
      contactService.loanList($scope.familyId).then((respond) => {
        $scope.loanList = respond.data;
      });
    }

    let brokerBrandingId = 0;
    if ($stateParams.adviserOrgId) {
      brokerBrandingId = $stateParams.adviserOrgId;
    } else if ($stateParams.referrerOrgId) {
      brokerBrandingId = $stateParams.referrerOrgId;
    }
    $scope.openDate = function (dateField) {
      $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
    };

    $scope.datePopUp = {
      DueDate: false,
    };

    $scope.format = 'MM-dd-y';
    $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    $scope.editAdviserComment = false;
    $scope.notes = {
      comment: '',
    };
    $scope.getAdviserComment = function () {
      if ($state.params.familyId) {
        contactService.adviserCommentGet($scope.familyId).then((response) => {
          if (!response || !response.data || !response.data.trim()) return;
          $scope.notes.comment = response.data;
        });
      } else {
        $scope.adviserOrgId = $state.params.adviserOrgId ? $state.params.adviserOrgId : $state.params.referrerOrgId;
        contactService.adviserOrgCommentGet($scope.adviserOrgId).then((response) => {
          if (!response || !response.data) return;
          $scope.notes.comment = response.data;
        });
      }
    };

    $scope.setAdviserComment = function () {
      if ($state.params.familyId) {
        contactService.adviserCommentSet($scope.familyId, escape($scope.notes.comment)).then(() => {
          $scope.editAdviserComment = false;
          toaster.pop('success', 'Added', 'Adviser comment successfully added');
          $scope.getAdviserComment();
        });
      } else {
        $scope.adviserOrgId = $state.params.adviserOrgId ? $state.params.adviserOrgId : $state.params.referrerOrgId;
        contactService.adviserOrgCommentSet($scope.adviserOrgId, escape($scope.notes.comment)).then(() => {
          $scope.editAdviserComment = false;
          toaster.pop('success', 'Added', 'Adviser comment successfully added');
          $scope.getAdviserComment();
        });
      }
    };

    $scope.getAdviserComment();
    $scope.tasksListData = [];
    $scope.activeTasksList = [];
    $scope.inactiveTasksList = [];
    $scope.viewActiveTaskCount = 2;
    $scope.viewInactiveTaskCount = 2;
    $scope.FamilyId = $state.params.familyId || 1;
    $scope.taskToRefresh = false;

    $scope.$watch(() => { return tasksSharedData.refreshTaskList; }, () => {
      $scope.taskToRefresh = tasksSharedData.refreshTaskList;
      if ($scope.taskToRefresh === true) {
        $scope.getTasksList();
        tasksSharedData.refreshTaskList = false;
      }
    });

    $scope.getTasksList = function (count) {
      if ($state.params.familyId) {
        contactService.tasksList($scope.familyId, count).then((response) => {
          $scope.tasksListData = response.data;
          $scope.incompletedTaskArr = [];
          for (let i = 0; i < $scope.tasksListData.length; i++) {
            if ($scope.tasksListData[i].IsCompleted === false) {
              $scope.incompletedTaskArr.push($scope.tasksListData[i]);
            }
          }
          $scope.dueTodayCount = $scope.incompletedTaskArr.filter(item => item.IsDueToday).length;

          $scope.taskcount = _.countBy($scope.tasksListData, (item) => {
            return item.IsCompleted === false ? 'active' : 'inactive';
          });

          if (typeof $scope.taskcount.active === 'undefined')
            $scope.taskcount.active = 0;
          if (typeof $scope.taskcount.inactive === 'undefined')
            $scope.taskcount.inactive = 0;

          $scope.taskListCount = 0;
          contactService.getTaskListCount($scope.familyId).then((taskListCountResponse) => {
            $scope.taskListCount = taskListCountResponse.data - $scope.tasksListData.length;
          });
        });
      }
    };
    $scope.getTasksList(0);

    $scope.setTaskCompletion = (taskId, isCompleted) => {
      const $postData = {
        familyID: parseInt($scope.familyId, 10),
        taskID: parseInt(taskId, 10),
        isComplete: isCompleted,
      };

      contactService.setTaskCompletion($postData).then(() => {
        $scope.getTasksList(0);
      });
    };

    $scope.getAllTasks = function (taskListShow) {
      $timeout(() => {
        if (taskListShow === true)
          $scope.viewInactiveTaskCount = $scope.taskcount.inactive;
        else if (taskListShow === false)
          $scope.viewActiveTaskCount = $scope.taskcount.active;
        else {
          $scope.viewActiveTaskCount = 2;
          $scope.viewInactiveTaskCount = 2;
        }
      }, 500);
    };

    $scope.loadTasks = () => {
      $scope.getTasksList(0);
    };

    $scope.getLessTasks = function (taskListShow) {
      if (taskListShow === true)
        $scope.viewInactiveTaskCount = 2;
      else if (taskListShow === false)
        $scope.viewActiveTaskCount = 2;
    };

    $scope.openTaskDetailModal = function (size, Id) {
      $uibModal.open({
        templateUrl: '/assets/views/overviewDashboard/partials/update_task_details.html',
        resolve: {
          tasksListData() {
            return $scope.tasksListData;
          },
          FamilyId() {
            return $scope.FamilyId;
          },
          Id: () => Id,
        },
        controller: 'OpenTaskDetailModalCtrl',
        size,
      });
    };
    $scope.openCreateTaskModal = function (id, hasFilter = false) {
      $scope.task_id = id;
      $uibModal.open({
        templateUrl: '/assets/views/tasks/modals/add.html',
        controller: 'AddTaskCtrl',
        windowClass: 'edit-task-window',
        size: 'md',
        scope: $scope,
        resolve: {
          triggerFilter() {
            return $scope.loadTasks;
          },
          hasFilter: () => hasFilter,
        },
      });
    };

    $scope.openCreateTaskTemplateModal = function () {
      $uibModal.open({
        templateUrl: 'assets/views/tasks/modals/add_template_task_modal.html',
        controller: 'OpenCreateTaskTemplateModalSBCtrl',
        scope: $scope,
        windowClass: 'view-task-window',

      });
    };
    function activityLimitList(activities) {
      let truncated = [];
      if (activities.length > 0) {
        if (activities.length > 3) {
          truncated = activities.slice($scope.limitFrom, $scope.limitTo);
        } else truncated = activities;

        for (let i = 0; i < truncated.length; i++) {
          truncated[i].initials = utilitiesService.filterInitialOneString(truncated[i].WrittenBy);
          switch (truncated[i].NotificationType) {
          case 'ApplicationCompleted':
            truncated[i].typeComment = 'Application Completed';
            break;
          case 'ApplicationStarted':
            truncated[i].typeComment = 'Applications Started';
            break;
          case 'CampaignNewsletter':
            truncated[i].typeComment = 'Campaign Newsletter Related';
            break;
          case 'Email':
            truncated[i].typeComment = 'Email Related';
            break;
          case 'Loan Application':
            truncated[i].typeComment = 'Loan Application Related';
            break;
          case 'LoanApplication':
            truncated[i].typeComment = 'Loan Application Related';
            break;
          case 'Mail':
            truncated[i].typeComment = 'Mail Related';
            break;
          case 'Phone':
            truncated[i].typeComment = 'Phone Related Task';
            break;
          case 'Referral':
            truncated[i].typeComment = 'Referrals Related Activity';
            break;
          case 'Task':
            truncated[i].typeComment = 'Task Related';
            break;
          case 'WorkBench':
            truncated[i].typeComment = 'Workbench Related';
            break;
          default:
            break;
          }
        }
      }
      return truncated;
    }
    $scope.ActivityFeed = {};

    $scope.limitFrom = 0;
    $scope.recentActivityListAll = [];
    $scope.$watch('limitFrom', () => {
      $scope.limitTo = $scope.limitFrom + 3;
      $scope.recentActivityList = activityLimitList($scope.recentActivityListAll);
    });

    $scope.viewMore = function () {
      $scope.limitFrom += 3;
    };

    $scope.ActivityFeed.getAll = function () {
      const familyId = $scope.familyId || 1;
      contactService.RecentActivityFeedListGet(familyId, brokerBrandingId).then((response) => {
        const activityList = activityLimitList(response.data);
        $scope.recentActivityList = activityList.map((val) => {
          if (moment(val.EventDate).isValid()) {
            const activityDates = moment.parseZone(val.EventDate).format('DD MMM YYYY');
            val.activityMonth = moment(activityDates).format('MMM');
            val.activityDays = moment(activityDates).format('DD');
          } else {
            val.activityMonth = '-';
            val.activityDays = '-';
          }
          return val;
        });
      });
    };

    $scope.ActivityFeed.getAll();

    angular.extend($scope, {
      showActivityInfoModal() {
        $uibModal.open({
          templateUrl: '/assets/views/modals/sidebar/activity.modal.html',
          resolve: {
            FamilyId() {
              return $scope.FamilyId;
            },
            brokerBrandingId() {
              return brokerBrandingId;
            },
          },
          controller: 'ShowActivityInfoModalCtrl',
          size: 'wide',
        });
      },
    });

    angular.extend($scope, {
      applicationList: [],
      scenarioListGet() {
        const vm = this;
        if (vm.familyId) {
          loanScenarioService.scenarioListGet(vm.familyId).then((response) => {
            vm.applicationList = _.filter(response.data, (o) => {
              return o.IsLockable;
            });
          });
        }
      },
      _initApplication() {
        const vm = this;
        vm.scenarioListGet();
        if ($scope.isClientView) {
          $scope.utmDetails();
        }
      },
      toggleLockStatus(app) {
        app.IsLocked = !app.IsLocked;
        loanScenarioService.loanApplicationLockStatus(app.LoanScenarioId, app.IsLocked).then(() => {
        });
      },
      goToLoanApp(app) {
        $state.go('app.loanApplicationDetailsV3', { familyId: $scope.familyId, loanAppId: app.LoanScenarioId });
      },
    });
    $scope.editMode = {};
    $scope.utmCampaigns = [];
    $scope.utmDetails = function () {
      contactSharedData.accessByCorporate($scope.familyId).then((scope) => {
        const { isCorporateUser, utmCampaigns } = scope;
        $scope.utmCampaigns = utmCampaigns;
        $scope.isCorporateUser = isCorporateUser;
        if ($scope.isCorporateUser) {
          corporateService.corporateContactGet(0, $scope.familyId).then((corporateAdviser) => { $scope.symmertyIDCorp = corporateAdviser.data; });
        }
      });
    };

    $scope.setUtmDetails = function (data, field) {
      corporateService.clientUTMDetailsSet($scope.familyId, 0, data)
        .then((response) => {
          if (response && response.data) {
            $scope.editMode[field] = false;
            toaster.pop('success', 'Update', 'successfully updated!');
          }
        });
    };

    $scope._initApplication();

    $scope.options = {
      language: 'en',
      removePlugins: 'toolbar',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 200,
      resize_enabled: false,
    };
  });
