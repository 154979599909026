import { pipelineSettingsBuilderForAPI } from 'Common/mappers/pipeline';
import CrmTableViewCtrl from '../crmTableViewCtrl';

export default class CrmTableViewOpportunity extends CrmTableViewCtrl {
  constructor(pipelineService, $interval) {
    'ngInject';

    super($interval);
    this.pipelineService = pipelineService;
    this.lastSortColumn = this.filterSettings.sortColumn;
    this.currentPage = 1;
  }

  columnSorting(columnObj = {}) {
    const isOfflineSorting = this.totalRecords <= this.pageSize;
    const { dataSortObjectKey } = columnObj;
    this.showLoader = true;
    this.filterSettings.sortColumn = dataSortObjectKey;

    if (dataSortObjectKey !== this.lastSortColumn) {
      this.filterSettings.sortType = 'ASC';
    } else {
      this.filterSettings.sortType = this.filterSettings.sortType === 'DESC' ? 'ASC' : 'DESC';
    }
    this.pipelineService.setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.filterSettings))
      .then(() => {
        this.lastSortColumn = dataSortObjectKey;
        if (isOfflineSorting) {
          this.sortColumn(columnObj.offlineSortObjKey, this.filterSettings.sortType);
        } else {
          this.onRefreshTable();
        }
      });
  }

  rowDeletion(rowData, $event) {
    const isOfflineDeleting = this.totalRecords <= this.pageSize;
    const { loanId, id: rowId } = rowData;
    const params = { $e: {
      loanId,
      event: $event,
      successCallback: (isOfflineDeleting ? this.removeRow : this.onRefreshTable),
      successCallbackParams: (isOfflineDeleting ? { rowId } : {}),
    } };

    this.onDeletePipelineItem(params);
  }
}
