import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { COLOR } from 'Common/constants/colors';

const MAX_TAB_LENGTH = 4;
class UsersCtrl {
  constructor(
    $state,
    $localStorage,
    $stateParams,
    $timeout,
    contactService,
    configService,
    toaster,
    corporateService,
    contactSharedData
  ) {
    'ngInject';

    this.contactService = contactService;
    this.configService = configService;
    this.$localStorage = $localStorage;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.corporateService = corporateService;
    this.contactSharedData = contactSharedData;
  }

  $onInit() {
    this.familyId = null;
    this.selectedTab = 'profile';
    this.adviserInfo = {};
    this.getBrokerInfo();
    this.activeTab = 0;
    this.getActiveTab(this.$state.params.activeTab || 'templates');
    this.checkSendEmailClientLinker();
  }

  checkSendEmailClientLinker() {
    this.isSendEmailLinkerShow = false;
    this.sendEmailLinker = this.$localStorage.sendEmailClientLinker;
    if (!this.sendEmailLinker || !this.sendEmailLinker.familyId) return;
    this.getClientInfo();
    this.getActiveTab('signature');
    this.isSendEmailLinkerShow = true;
    this.gradientBannerObject = {
      startColor: COLOR.DARKER_BLUE,
      endColor: COLOR.BLUE_VIOLET,
      paddingLeftRight: '3%',
    };
  }

  getClientInfo() {
    if (!this.sendEmailLinker || !this.sendEmailLinker.familyId) return;
    this.contactService.clientInformGet(this.sendEmailLinker.familyId)
      .then((response) => {
        if (!response || !response.data || !response.data.length) return;
        this.clientFullName = response.data
          .reduce((accum, item) => {
            if (item.Role === 'Adult') {
              accum.push(`${item.FirstName} ${item.LastName}`);
            }
            return accum;
          }, []).join(' & ');
        this.gradientBannerObject.text = `You were sending an email to Contact ${this.clientFullName}. Click here to go back`;
      });
  }

  goBackToSendEmailClient() {
    this.$localStorage.sendEmailClientLinker = null;
    this.$state.go('app.sendEmail', { contactType: 'client', familyId: this.sendEmailLinker.familyId });
  }

  getActiveTab(activeTab) {
    this.usersTab = {
      profile: 0,
      subscription: 1,
      referral: 2,
      signature: 3,
      templates: 4,
    };
    this.active = this.contactSharedData.getUsersActiveIndexTab(activeTab, this.usersTab);
  }

  $onDestroy() {
    this.waitTabTimeOut && this.$timeout.cancel(this.waitTabTimeOut);
  }

  setTabFlags() {
    const { userProfileManagement, userProfile, userReferral, userSubscription } = this.configService.feature;
    this.showUserProfile = (userProfileManagement && userProfile) || 0;
    this.showUserReferral = !this.isAssistant() && ((userProfileManagement && userReferral) || 0);
    this.showUserSubscription = !this.isAssistant() && ((userProfileManagement && userSubscription) || 0);
    if (this.$stateParams.activeTab) {
      this.waitTabTimeOut = this.$timeout(() => {
        const tabIndex = parseInt(this.$stateParams.activeTab, 10);
        this.activeTab = (tabIndex < MAX_TAB_LENGTH) ? tabIndex : 0;
      }, 0);
    }
  }

  leadsManagementTabDisplay() {
    const clientId = 0;
    this.corporateService.corporateContactGet(clientId, this.familyId)
      .then((response) => {
        const { data } = response;
        if (!data || !data.AdviserOrganizationId) {
          this.showLeadsManagement = 0;
          return;
        }
        const complianceId = 0;
        return this.corporateService.adviserOrganizationGet(complianceId, data.AdviserOrganizationId);
      })
      .then((response) => {
        if (!response || !response.data || !response.data.length) {
          this.showLeadsManagement = 0;
          return;
        }
        this.showLeadsManagement = response.data[0].BrandingCategoryId !== BRANDING_CATEGORY_TYPE.OWN_BRAND;
      });
  }

  getBrokerInfo() {
    this.contactService.getUserInfo().then((response) => {
      if (response.data && response.data.FamilyId) {
        this.familyId = response.data.FamilyId;
        this.adviserInfo = response.data;
        this.showEmailSignature = (this.configService.feature && this.configService.feature.emailSignature && this.adviserInfo.BrandingCategoryID === BRANDING_CATEGORY_TYPE.LOAN_MARKET) || 0;
        this.checkSubscriptionAccess();
        this.setTabFlags();
        this.showLeadsManagement = !this.isAssistant() && this.adviserInfo.CountryId === COUNTRY_TYPE.AUSTRALIA && this.configService.feature && this.configService.feature.userLeadsManagement;
        if (!this.showLeadsManagement) return;
        this.leadsManagementTabDisplay();
      }
    });
  }
  isAssistant() {
    const assistantList = [ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT];
    return assistantList.indexOf(this.adviserInfo.AccessType) !== -1;
  }
  checkSubscriptionAccess() {
    const { AccessType } = this.adviserInfo || 0;
    const { CountryId } = this.adviserInfo;
    this.showUserProfileManagement = (this.configService.feature && this.configService.feature.userProfileManagement && CountryId === COUNTRY_TYPE.AUSTRALIA && AccessType !== ACCESS_TYPE.CORPORATE) || 0;
    if (this.showUserProfileManagement) return;
    this.toaster.pop('warning', 'Page is still under development', 'Come back soon!!!');
    this.$state.go('app.dashboard');
  }
}

angular.module('app').controller('UsersCtrl', UsersCtrl);
