class CorporateTeamCtrl {
  constructor(
    corporateModelService,
    corporateTeamService,
    contactService,
    userService,
    uiService,
    $uibModal,
    toaster,
    $state
  ) {
    'ngInject';

    this.corporateModelService = corporateModelService;
    this.corporateTeamService = corporateTeamService;
    this.contactService = contactService;
    this.userService = userService;
    this.uiService = uiService;
    this.$uibModal = $uibModal;
    this.toaster = toaster;
    this.$state = $state;
  }
  regroupTeam(data, key) {
    this.corporateTeamDetails = data.reduce((list, detail) => {
      detail.icon = this.corporateTeamService.getTeamIcon(detail.detailId);
      detail.contactUrl = this.parseTeamContactUrl(detail.teamContactUrls);
      if (!detail[key]) {
        list.push(detail);
      } else {
        (list[detail[key]] = list[detail[key]] || []).push(detail);
      }
      return list;
    }, []);
  }
  getTeamContactDetail() {
    this.corporateModelService.teamContactDetailGet()
      .then((data) => {
        if (!data || !data.length) return;
        this.regroupTeam(data, 'groupingNumber');
        this.isCorporateTeamLoading = false;
      });
  }
  displayEnquiryBox(index) {
    this.isEnquiryBoxShow[index] = true;
  }
  setRecipient(team) {
    const recipient = {
      Name: team.teamName,
      EmailAddress: team.email,
    };
    this.email.Recipients.push(recipient);
  }
  launchEnquiryModal(team) {
    this.setRecipient(team);
    this.$uibModal.open({
      animation: true,
      template: `<corporate-team-modal
                    modal-instance="vm.modalInstance"
                    email="vm.email">
                  </corporate-team-modal>`,
      controller: 'CorporateTeamModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        email: () => this.email,
      },
    });
  }
  sendEnquiry(index, team, form) {
    if (!team || form.$invalid) return;
    this.sendingEnquiry[index] = true;
    this.setRecipient(team);
    this.email.Content = this.enquiryBoxFields[index] || '';
    this.contactService.sendEmail(this.email)
      .then((response) => {
        const { data } = response;
        this.sendingEnquiry[index] = false;
        if (!data) return;
        this.corporateTeamService.promptSuccessMessage();
        this.enquiryBoxFields[index] = '';
        this.email.Recipients = [];
        this.isEnquiryBoxShow[index] = false;
      })
      .catch(() => {
        this.sendingEnquiry[index] = false;
      });
  }
  initEmailDefault() {
    if (!this.userInfo) return;
    const emailOwner = {
      Name: this.userInfo.FullName,
      EmailAddress: this.userInfo.Email,
    };
    this.email = {
      Subject: 'Enquiry',
      ReplyTo: emailOwner,
      From: emailOwner,
      Recipients: [],
      SentDate: new Date(),
      FamilyID: this.userInfo.FamilyId,
    };
  }
  getUserInfo() {
    this.userService.GetUserInfo().then((response) => {
      const { data } = response;
      if (!data) return;
      this.userInfo = data;
      this.initEmailDefault();
    });
  }
  parseTeamContactUrl(contactUrl) {
    if (!contactUrl) return [];
    this.teamContactUrlParsed = [];
    const contactList = contactUrl.split('||');
    contactList.map((contact) => {
      const item = contact.split('|');
      if (!item.length) return contact;
      const temp = {
        title: item[0],
        url: item[1],
      };
      this.teamContactUrlParsed.push(temp);
      return contact;
    });
    return this.teamContactUrlParsed;
  }
  $onInit() {
    this.sendingEnquiry = [];
    this.isCorporateTeamLoading = true;
    this.getUserInfo();
    this.corporateTeamDetails = [];
    this.getTeamContactDetail();
    this.isEnquiryBoxShow = [];
    this.enquiryBoxFields = [];
    this.enquiryForms = [];
    this.email = {};
  }
}


export default CorporateTeamCtrl;
