import angular from 'angular';
import _ from 'lodash';
import {
  setCurrrentDate,
  setDateFormat,
} from 'Common/utilities/date';

angular.module('app').controller('AdviserComplianceCtrl', function AdviserComplianceCtrl(
  broadcastDataService,
  corporateService,
  commonFnService,
  dataService,
  queryService,
  $uibModal,
  toaster,
  $filter,
  $state,
  $scope
) {
  /*    VARIABLE(s)    */
  /* Data Type: {Object} */
  $scope.familyComplianceGet = {};
  $scope.familyId = $state.params.familyId;
  $scope.adviserDetails = {};
  $scope.editMode = {};
  /* Data Type: {Array} */
  $scope.altInputFormats = [];
  $scope.review_months = [];
  $scope.eDRSchemes = [];
  $scope.setterType = [];
  $scope.saveType = [];
  /* Data Type: {String} */
  $scope.dateStaticValue = '1900-01-01';
  $scope.dateFormat = '';
  $scope.userReportingCountryId = '';

  let watchAdviserComplianceEditMode = null;
  /*  FUNCTION REFERENCE      */
  /*  FUNCTION DEFINITION(S)  */
  /* ------------------------------------------ [ GETTER ] --------------------------------------------------- */
  function getCorporateContactGet() {
    corporateService.corporateContactGet($state.params.clientId, $state.params.familyId)
      .then((response) => {
        $scope.adviserDetails = response.data;
      });
  }
  /**
  *   @name onRetrieveFamilyComplianceSuccess
  *   @desc This will load data from Api: `familyComplianceGet` on success.
  *   @params {response} data from the api result.
  */
  function onRetrieveFamilyComplianceSuccess(response) {
    if ($scope.subMenuVal === 'Compliance') {
      if (response && response.data) {
        dataService.getRecentViewed();
        getCorporateContactGet();
        dataService.getEdrSchemes()
          .then((eDRSchemes) => {
            eDRSchemes && Object.keys(eDRSchemes).forEach((key) => {
              $scope.eDRSchemes.push(eDRSchemes[key]);
            });
            response.data.ReviewMonth = response.data.ReviewMonth || 0;
            response.data.EDRScheme = queryService.getArrValue(response.data.EDRSchemeId, null, 'EDRSchemeID', 'EDRSchemeDesc', $scope.eDRSchemes);
            response.data.EDRSchemeId = queryService.getArrValue(null, response.data.EDRScheme, 'EDRSchemeID', 'EDRSchemeDesc', $scope.eDRSchemes);
          });

        $scope.familyComplianceGet = response.data;
      }
    }
  }
  /*
      *   @name initialize
      *   @desc This will load/initialize data needed on load.
      */
  function initialize() {
    dataService.getUserInfo()
      .then((response) => {
        $scope.isCorpTeam = response;
      });
    $scope.setterType = dataService.getSetterTypes('Adviser');
    $scope.saveType = dataService.getSaveTypes('Adviser');
    $scope.dateFormat = setDateFormat();
    $scope.altInputFormats = dataService.getDateValidityFormats();
    $scope.currentDate = setCurrrentDate();
    $scope.review_months = dataService.getAllMonths();

    corporateService.familyComplianceGet($state.params.familyId, $state.params.clientId)
      .then(onRetrieveFamilyComplianceSuccess, commonFnService.onRetrieveError);

    watchAdviserComplianceEditMode = $scope.$watch('editModeObj', () => {
      $scope.editMode = $scope.editModeObj;
    });

    corporateService.corporateContactGet($state.params.clientId, $state.params.familyId)
      .then((response) => {
        if (response && response.data) {
          if (response.data.ReportingCountryId) {
            $scope.userReportingCountryId = response.data.ReportingCountryId;
          }
          dataService.getUserInfo(true)
            .then((users) => {
              if (users && users.data) {
                $scope.userReportingCountryId = users.data.CountryId;
              }
            });
        }
      });
  }
  $scope.$on('$destroy', () => {
    watchAdviserComplianceEditMode();
  });
  /* ------------------------------------------ [ SETTER ] --------------------------------------------------- */
  /**
  *   @name querySet
  *   @desc This will save the value from api.
  *   @params {String} setter
  *   @params {String} key
  *   @params {Array} arrays
  *   @params {Boolean} isCorporate
  */
  function querySet(setter, key, field, arrays, isCorporate) {
    $scope.editMode[key] = false;
    commonFnService.setEditMode($scope.editMode, field);
    if (isCorporate) {
      corporateService[setter].apply(null, arrays)
        .then((response) => {
          if (response) {
            if (response.status === 200) {
              toaster.pop('success', 'Update', 'successfully updated!');
            }
          }
        });
    }
    toaster.clear();
  }

  $scope.setDateFormatInView = (date) => {
    return dataService.setDateFormatInView(date);
  };
  /*  ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */
  $scope.dateEditMode = function (obj, key) {
    return commonFnService.dateEditMode(obj, key, $scope.editMode);
  };

  $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
    return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
  };

  $scope.updateAdviserDetails = function (setterType, obj, key, field, saveType, formName, inputName) {
    let dataArray = [];
    let keyTmp = key;
    if (setterType === 'familyComplianceSet') {
      if (formName && inputName) {
        if (keyTmp === 'PiFields') {
          keyTmp = 'PIExpiry';
        }
        if (!commonFnService.checkFormDateValidation(formName, inputName, obj, keyTmp)) {
          return false;
        }
      } else {
        if (keyTmp === 'ReviewMonth') {
          obj.ReviewMonth = Number(obj.ReviewMonth);
        }
        if (keyTmp === 'EDRScheme') {
          obj.EDRSchemeId = queryService.getArrValue(null, obj.EDRScheme, 'EDRSchemeID', 'EDRSchemeDesc', $scope.eDRSchemes);
          obj.EDRScheme = queryService.getArrValue(obj.EDRSchemeId, null, 'EDRSchemeID', 'EDRSchemeDesc', $scope.eDRSchemes);
        }
        if (keyTmp === 'SendEDRExpiryNotification') {
          obj.SendEDRExpiryNotification = commonFnService.setBooleanToInt(obj.SendEDRExpiryNotification);
        }

        if (keyTmp === 'SendPIExpirationNotification') {
          obj.SendPIExpirationNotification = commonFnService.setBooleanToInt(obj.SendPIExpirationNotification);
        }
        if (keyTmp === 'SendProfSchemeRenewalNotif') {
          obj.SendProfSchemeRenewalNotif = commonFnService.setBooleanToInt(obj.SendProfSchemeRenewalNotif);
        }
      }
      delete obj.FamilyId;
      delete obj.FamilyComplianceId;
      dataArray = [$state.params.familyId, $state.params.clientId, obj];
    } else if (setterType === 'contactSet') {
      dataArray = [$scope.saveType[saveType], obj];
    }
    if (_.size(dataArray) > 0) {
      querySet(setterType, keyTmp, field, dataArray, true);
    }
  };

  $scope.editGroupFields = function (obj) {
    $scope.editMode.PiFields = true;
    $scope.dateEditMode(obj, 'PIExpiry');
  };
  /**
  *   Function Call
  */
  initialize();
});
