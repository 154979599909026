import { maxBy, range } from 'lodash';
import { roundOffToNearestHundreds } from 'Common/utilities/math';
import { getFirstLetter } from 'Common/utilities/string';
import {
  buildQuoteGraphStack,
  buildQuoteGraphStackLabel,
} from 'Common/utilities/insurance';
import { quoteDataBuilderForAPI } from 'Common/mappers/insurance';

class InsurerComparisonGraphCtrl {
  constructor(insuranceQuoteService) {
    'ngInject';

    this.insuranceQuoteService = insuranceQuoteService;
    this.initializeGraph();
  }

  initializeGraph() {
    if (!this.graphData || !this.graphData.length) return;

    const maxTotalPremium = maxBy(this.graphData, item => item.TotalPremium).TotalPremium;
    this.maxRange = roundOffToNearestHundreds(maxTotalPremium);

    this.graph = {
      dataRanges: this.getDataRanges(),
      data: this.getData(),
    };

    this.initializeRatings();
  }

  getDataRanges() {
    const rangeCount = 5;
    const rangeFromNonZero = rangeCount - 1;
    const rangePerTick = this.maxRange / rangeFromNonZero;
    return range(rangeCount).reduce((accum, item, index) => {
      const rangeValue = (rangeFromNonZero - index) * rangePerTick;
      accum.push(rangeValue);
      return accum;
    }, []);
  }

  getData() {
    this.barWidth = Math.floor(100 / this.graphData.length) - 2;
    return this.graphData.reduce((accum, quote) => {
      if (!quote.TotalPremium) return accum;

      const providerId = quote.ProviderId;
      const stack = buildQuoteGraphStack(quote, this.maxRange, this.graphColors);
      const label = buildQuoteGraphStackLabel(quote);
      return [...accum, { providerId, stack, label }];
    }, []);
  }

  initializeRatings() {
    this.isLoadingRatings = true;
    this.insuranceQuoteService
      .insuranceQuoteStarRatingGet(quoteDataBuilderForAPI(this.quoteData))
      .then((response) => {
        if (!response || !response.data) return;

        this.graph.data.forEach((graphStack) => {
          const rating = response.data.find(item => item.Id === graphStack.providerId);
          if (rating) {
            graphStack.label.financialRating = rating.FinancialRating;
            const firstLetter = getFirstLetter(rating.FinancialRating);
            graphStack.label.financialRatingClass = firstLetter && firstLetter.toLowerCase();
            graphStack.label.starRating = rating.StartRating;
          }
        });
      }).finally(() => {
        this.isLoadingRatings = false;
      });
  }
}

export default InsurerComparisonGraphCtrl;
