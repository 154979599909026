class SubscriptionCheckIsShowPreLoader {
  constructor(
    toaster
  ) {
    'ngInject';

    this.toaster = toaster;
  }

  checkIsShowPreLoader({ brokerBrandingID }) {
    let response = true;
    if (!brokerBrandingID) {
      this.toaster.pop('error', 'Error', 'You cannot subscribe to this service because your profile is missing some data about your franchise. Please contact your State team to resolve this issue.');
      response = false;
    }

    return response;
  }
}

export default SubscriptionCheckIsShowPreLoader;
