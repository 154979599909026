import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { removeCountryCode } from 'Common/utilities/phone';
import { displayMap, displayCustomAddress } from 'Common/utilities/map';
import {
  setCurrrentDate,
  setDateFormat,
  setPreviousYear,
  setCurrrentYear,
} from 'Common/utilities/date';
import { displayError } from 'Common/utilities/alert';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular.module('app').controller('AdviserInfoCtrl', function AdviserInfoCtrl(
  corporatePermissionService,
  broadcastDataService,
  addressModalService,
  dashboardService,
  corporateService,
  modelDataService,
  commonFnService,
  contactService,
  generalService,
  queryService,
  dataService,
  mapService,
  SweetAlert,
  $uibModal,
  $timeout,
  toaster,
  $filter,
  $scope,
  $state,
  $sce,
  $q
) {
  /* State Params */
  $scope.clientId = $state.params.clientId || 0;
  $scope.familyId = $state.params.familyId || 0;
  /*   VARIABLE(s)        */
  /* Data Type: {Object} */
  $scope.familyAddressGet = {};
  $scope.adviserDetails = {};
  $scope.editMode = {};
  /* Data Type: {Array} */
  $scope.adviserAchievementsPreviousYear = [];
  $scope.adviserAchievementsCurrentYear = [];
  $scope.selectedAdviserOrg = [];
  $scope.operatingCountries = [];
  $scope.brokerStatusLists = [];
  $scope.reportingRegions = [];
  $scope.commissionTiers = [];
  $scope.altInputFormats = [];
  $scope.addressTypes = [];
  $scope.genderLists = [];
  $scope.clientTypes = [];
  $scope.adviserOrg = [];
  $scope.adminUsers = [];
  $scope.setterType = [];
  $scope.saveType = [];
  $scope.business = {};
  $scope.mailing = {};
  $scope.homeAddress = {};
  /* Data Type: {String} */
  $scope.adviserPreviousNameGet = '';
  $scope.userCurrentCountyId = 0;
  $scope.isHomeAddressMapId = 'map-adviser-address-home';
  $scope.previousYearValue = '';
  $scope.addressTypeValue = 'Business Address';
  $scope.currentYearValue = '';
  $scope.editBusinessAdd = false;
  $scope.dateStaticValue = '1900-01-01';
  $scope.isBusinessMapId = 'map-adviser-address';
  $scope.isMailingMapId = 'map-adviser-address-mailing';
  $scope.editMailingAdd = false;
  $scope.currrentYear = '';
  $scope.previousYear = '';
  $scope.currentDate = '';
  $scope.editHomeAdd = false;
  $scope.dateFormat = '';
  let watchAdviserInfoEditModeObj = null;
  /*  FUNCTION REFERENCE */

  /*  FUNCTION DEFINITION(S)  */
  /* ------------------------------------------ [ SETTER ] --------------------------------------------------- */
  /*
  *   @name setClientTypesDetails
  *   @desc This will set data of the client types separated by comma.
  *   @params {String} clientTypes - list of selected client types per adviser.
  */
  const setClientTypesDetails = (clientTypes) => {
    const selectedClientTypes = [];
    let clientTypeNames = [];
    const selectedNames = [];

    clientTypeNames = $scope.corporateAdviser.ClientTypeNames ? $scope.corporateAdviser.ClientTypeNames.split(',') : [];

    angular.forEach(clientTypes, (clientType) => {
      angular.forEach(clientTypeNames, (clientName) => {
        if (clientType.ClientTypeName === clientName) {
          selectedClientTypes.push(clientType);
          selectedNames.push(clientType.ClientTypeName);
        }
      });
    });

    $scope.corporateAdviser.ClientTypeNames = _.size(selectedNames) > 0 ? selectedNames.join(',') : '';
    $scope.corporateAdviser.ClientTypeNameArr = selectedClientTypes;
  };
  /*
    *   @name querySet
    *   @desc This will save the value from api.
    *   @params {String} setter
    *   @params {String} key
    *   @params {Array} arrays
    *   @params {Boolean} isCorporate
    */
  function querySet(setter, key, field, arrays, isCorporate) {
    $scope.editMode[key] = false;
    commonFnService.setEditMode($scope.editMode, field);
    if (isCorporate) {
      corporateService[setter].apply(null, arrays)
        .then((response) => {
          if (response) {
            toaster.pop('success', 'Update', 'successfully updated!');
          }
        });

      if (setter === 'adviserPreviousNameSet') {
        corporateService.adviserPreviousNameGet($scope.familyId, $scope.clientId)
          .then((response) => {
            $scope.adviserPreviousNameGet = response.data;
          });
      }
    }
    toaster.clear();
  }
  /* ------------------------------------------ [ GETTER ] --------------------------------------------------- */

  /*
    *   @name reportingRegionsLists
    *   @desc This will load data from Api: `ReportingRegionsGet`.
    */
  function reportingRegionsLists(countryId) {
    dataService.getReportingRegions(countryId)
      .then((response) => {
        $scope.reportingRegions = response;
      });
  }
  /*
    *   @name getAchievements
    *   @desc This will load data from Api: `familyAchievementGet` and `adviserAchievementsGet`.
    */
  function getAchievements() {
    corporateService.familyAchievementGet($scope.familyId)
      .then((achievements) => {
        if (achievements && achievements.data) {
          angular.forEach(achievements.data, (value) => {
            if (value.FiscalYear === $scope.currrentYear) {
              $scope.currentYearAchievementID = value.AchievementID;
            } else if (value.FiscalYear === $scope.previousYear) {
              $scope.previousYearAchievementID = value.AchievementID;
            }
          });
        }
      })
      .then(() => {
        corporateService.adviserAchievementsGet($scope.previousYear).then((response) => {
          $scope.adviserAchievementsPreviousYear = [];
          if (response && response.data) {
            angular.forEach(response.data, (value) => {
              $scope.adviserAchievementsPreviousYear.push(value);
            });
            $scope.AdviserAchievementNamePrevYear = queryService.getArrValue($scope.previousYearAchievementID, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsPreviousYear);
          }
        });
      });
  }
  /*
    *   @name getClientTypes
    *   @desc This will load data from Api: `getClientType`.
    *   @return {Promise}
    */
  function getClientTypes() {
    const defer = $q.defer();
    corporateService.getClientType()
      .then((response) => {
        $scope.clientTypes = [];
        if (response && response.data) {
          angular.forEach(response.data, (value) => {
            $scope.clientTypes.push(value);
          });
        }
        defer.resolve($scope.clientTypes);
      }, (err) => {
        defer.reject(err);
      });

    return defer.promise;
  }
  /*
    *   @name getAdviserPreviousName
    *   @desc This will load data from Api: `adviserPreviousNameGet`.
    */
  function getAdviserPreviousName() {
    corporateService.adviserPreviousNameGet($scope.familyId, $scope.clientId)
      .then((response) => {
        if (response && response.data) {
          $scope.adviserPreviousNameGet = response.data;
        }
      });
  }
  /*
    *   @name getIndiviudalAchievement
    *   @desc This will load data from Api: `adviserAchievementsGet`.
    *   @return {Promise}
    */
  function getIndiviudalAchievement() {
    const defer = $q.defer();
    corporateService.adviserAchievementsGet($scope.currrentYear)
      .then((response) => {
        $scope.adviserAchievementsCurrentYear = [];
        if (response && response.data) {
          angular.forEach(response.data, (value) => {
            $scope.adviserAchievementsCurrentYear.push(value);
          });
          defer.resolve();
        }
      });
    return defer.promise;
  }
  /*
  *   @name getAdviserAddress
  *   @desc This will load data from Api: `familyAddressGet`.
  */
  function getAdviserAddress() {
    corporateService.familyAddressGet($scope.familyId)
      .then((response) => {
        if (response && response.data) {
          $scope.familyAddressGet = response.data;
          const adviserAddressTmp = commonFnService.setAddressTypes($scope.familyAddressGet);
          if (response && adviserAddressTmp) {
            $scope.business = angular.extend({}, adviserAddressTmp.business);
            $scope.mailing = angular.extend({}, adviserAddressTmp.mailing);
            $scope.homeAddress = angular.extend({}, adviserAddressTmp.home);
          }
          if (_.isUndefined($scope.business)) {
            $scope.business = modelDataService.addressModelData();
          }
          if (_.isUndefined($scope.mailing)) {
            $scope.mailing = modelDataService.addressModelData();
          }
          if (_.isUndefined($scope.homeAddress)) {
            $scope.homeAddress = modelDataService.addressModelData();
          }
          // check if mailing is same with business address reference variable `$scope.isSameinBussinessAddress` //
          if (!_.isEmpty($scope.business) && !_.isEmpty($scope.mailing)) {
            if ($scope.business.formatted_address === $scope.mailing.formatted_address) {
              $scope.isSameinBussinessAddress = true;
            }
          }
        }
      });
  }

  const getCommissionTierName = (commissionTiers, commissionTierId) => {
    const commissionTierFilter = commissionTiers.filter(commissionTier => Number(commissionTier.CommissionTierId) === Number(commissionTierId));
    const commissionTierName = commissionTierFilter && commissionTierFilter.length ? commissionTierFilter[0].CommissionTierName : null;
    return commissionTierName;
  };
  /*
  *   @name onRetrieveCorporateContactGetSuccess
  *   @desc This will load data from Api: `corporateContactGet` on success.
  *   @params {Object} response - data from the api result.
  */
  function onRetrieveCorporateContactGetSuccess(response) {
    if (response && response.data) {
      $scope.corporateAdviser = response.data;

      if ($scope.corporateAdviser) {
        dataService.getRecentViewed();
        dataService.getAdviserOrgLists($scope.corporateAdviser.AdviserOrganizationId)
          .then((adviserOrgData) => {
            $scope.adviserOrg = [];
            $scope.selectedAdviserOrg = [];
            $scope.selectedAdviserOrg = adviserOrgData.selected;
            $scope.adviserOrg = adviserOrgData.data;
          });
        getAdviserPreviousName();

        getClientTypes()
          .then((clientTypes) => {
            setClientTypesDetails(clientTypes);
          });

        getIndiviudalAchievement()
          .then(() => {
            if (_.size($scope.adviserAchievementsCurrentYear) > 0) {
              $scope.AdviserAchievementName = queryService.getArrValue($scope.currentYearAchievementID, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
              $scope.corporateAdviser.IndividualAchievementName = queryService.getArrValue($scope.corporateAdviser.IndividualAchievementId, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
            }
          });

        dataService.getBrokerStatus()
          .then((brokerStatus) => {
            if (_.size(brokerStatus) > 0) {
              $scope.brokerStatusLists = brokerStatus;
              $scope.corporateAdviser.BrokerStatusName = $scope.corporateAdviser.BrokerStatusId > -1 ? queryService.getArrValue($scope.corporateAdviser.BrokerStatusId, null, 'BrokerStatusId', 'BrokerStatusName', $scope.brokerStatusLists) : 'None';
            }
          });

        dataService.getOperatingCountries()
          .then((countries) => {
            if (_.size(countries) > 0) {
              $scope.operatingCountries = countries;
              $scope.corporateAdviser.ReportingCountryName = queryService.getArrValue($scope.corporateAdviser.ReportingCountryId, null, 'CountryId', 'CountryName', $scope.operatingCountries);
              $scope.corporateAdviser.ReportingCountryCode = queryService.getArrValue($scope.corporateAdviser.ReportingCountryId, null, 'CountryId', 'CountryCode', $scope.operatingCountries);
              $scope.corporateAdviser.ReportingCountryPhoneCode = queryService.getArrValue($scope.corporateAdviser.ReportingCountryId, null, 'CountryId', 'CallingCode', $scope.operatingCountries);
              $scope.mobileMask = broadcastDataService.getMobileFormat($scope.corporateAdviser.ReportingCountryId);
              $scope.phoneMask = broadcastDataService.getPhoneFormat($scope.corporateAdviser.ReportingCountryId);
            }
          });

        dataService.getAdminUsers()
          .then((users) => {
            if (_.size(users) > 0) {
              $scope.adminUsers = users;
              $scope.corporateAdviser.AccountOwner_FullName = queryService.getArrValue($scope.corporateAdviser.AccountOwner_FamilyId, null, 'FamilyId', 'FullName', $scope.adminUsers);
              $scope.corporateAdviser.AccountOwner_FullNameTmp = queryService.getArrValue($scope.corporateAdviser.AccountOwner_FamilyId, null, 'FamilyId', 'FullName', $scope.adminUsers);
            }
          });

        queryService.getAllArrayDetails($scope, corporateService, 'businessUnitGet', [], 'business_units',
          $scope.corporateAdviser, 'BusinessUnitName', 'BusinessUnitID',
          null, null);

        if ($scope.corporateAdviser.ReportingCountryId && $scope.corporateAdviser.ReportingCountryId < 3) {
          $scope.userCurrentCountyId = $scope.corporateAdviser.ReportingCountryId;
        }

        dataService.getCommissionTiers($scope.mainUserId)
          .then((completeTierLists) => {
            if (!completeTierLists || !completeTierLists.length) return;
            $scope.commissionTiers = completeTierLists;
            if ($scope.isCorpTeam) {
              $scope.corporateAdviser.CommissionTierName = getCommissionTierName($scope.commissionTiers, $scope.corporateAdviser.CommissionTierId);
            }
          });

        if (!$scope.isCorpTeam) {
          dataService.getCommissionTiers($scope.userId, $scope.corporateAdviser.CommissionTierId)
            .then((tiers) => {
              if (!tiers || !tiers.length) return;
              $scope.corporateAdviser.CommissionTierName = getCommissionTierName(tiers, $scope.corporateAdviser.CommissionTierId);
            });
        }

        queryService.getAllArrayDetails($scope, corporateService, 'reportingRegionsGet', [$scope.userCurrentCountyId], 'reportingRegions',
          $scope.corporateAdviser, 'ReportingRegionName', 'ReportingRegionId',
          null, null);

        reportingRegionsLists($scope.userCurrentCountyId);

        queryService.getAllArrayDetails($scope, corporateService, 'reportingStatesGet', [], 'reportingStates',
          $scope.corporateAdviser, 'ReportingStateName', 'ReportingStateId',
          null, null);

        $scope.corporateAdviser.GenderName = queryService.getArrValue($scope.corporateAdviser.Gender, null, 'id', 'value', $scope.genderLists);

        $scope.corporateAdviser.Mobile = removeCountryCode($scope.corporateAdviser.Mobile);
        $scope.corporateAdviser.WorkNumber = removeCountryCode($scope.corporateAdviser.WorkNumber);

        getAdviserAddress();
        // set data
        $scope.adviserDetails = $scope.corporateAdviser;
        generalService.getFeeCardTypes().then((data) => {
          if (!data || !data.length) return;
          $scope.feeCardValues = data;
          const adviserFeeCard = data.find(x => parseInt(x.feeCardId, 10) === parseInt($scope.adviserDetails.FeeCardId, 10));
          if (!adviserFeeCard || !adviserFeeCard.feeCardDesc) return;
          $scope.adviserDetails.FeeCardDesc = adviserFeeCard.feeCardDesc;
        });
      }
    }
  }
  const getAdviserAddressPermission = () => {
    $scope.isAdviserAddressEditable = false;
    const pageName = 'AdviserContact';
    const tabName = 'AdviserDetails';
    const subTabName = 'AdviserInfo';
    corporatePermissionService.isPermissionReadAndWrite(pageName, tabName, subTabName)
      .then((response) => {
        $scope.isAdviserAddressEditable = response;
      });
  };
  /**
  *   @name initialize
  *   @desc This will load/initialize data needed on load.
  */
  function initialize() {
    $scope.subMenuVal = 'Adviser Info';
    dataService.getUserInfo(true)
      .then((response) => {
        if (!response || !response.data) return;
        $scope.isCorpTeam = response.data.CorpAccessTypeID === 1;
        $scope.userCurrentCountyId = response.data.CountryId;
        $scope.isAustralia = $scope.userCurrentCountyId === COUNTRY_TYPE.AUSTRALIA;
        $scope.userId = $scope.isCorpTeam ? response.data.FamilyId : 0;
        $scope.mainUserId = response.data.FamilyId || 0;
      });
    getAdviserAddressPermission();
    $scope.setterType = dataService.getSetterTypes('Adviser');
    $scope.saveType = dataService.getSaveTypes('Adviser');
    $scope.genderLists = dataService.getGenderTypes();
    $scope.addressTypes = dataService.getAddressTypes();
    $scope.currrentYear = setCurrrentYear();
    $scope.previousYear = setPreviousYear();
    $scope.dateFormat = setDateFormat();
    $scope.altInputFormats = dataService.getDateValidityFormats();
    $scope.currentDate = setCurrrentDate();
    $scope.currentYearValue = (`${$scope.currentDate.getFullYear()}`).slice(-2);
    $scope.previousYearValue = (`${$scope.currentDate.getFullYear() - 1}`).slice(-2);
    getAchievements();
    corporateService.corporateContactGet($scope.clientId, $scope.familyId)
      .then(onRetrieveCorporateContactGetSuccess, commonFnService.onRetrieveError);
    getAdviserAddress();
    watchAdviserInfoEditModeObj = $scope.$watch('editModeObj', () => {
      $scope.editMode = $scope.editModeObj;
    });
  }

  $scope.$on('$destroy', () => {
    watchAdviserInfoEditModeObj();
  });

  /* ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */
  $scope.setClasses = function (status, statusId, isbtn) {
    return isbtn ? dataService.getBtnClasses()[statusId] : dataService.getFontClasses()[statusId];
  };

  $scope.dateEditMode = function (obj, key) {
    return commonFnService.dateEditMode(obj, key, $scope.editMode);
  };

  $scope.formatDateString = function (date) {
    return commonFnService.formatDateString(date);
  };

  $scope.formatDate = function (date) {
    return commonFnService.formatDate(date);
  };

  $scope.convertPipeToCSV = function (str) {
    return commonFnService.convertPipeToCSV(str);
  };

  $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
    return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
  };

  $scope.showAddress = function (addressValue) {
    if (addressValue === 'Business Address') {
      $scope.editHomeAdd = false;
      $scope.editMailingAdd = false;
    } else if (addressValue === 'Home Address') {
      $scope.editBusinessAdd = false;
      $scope.editMailingAdd = false;
    } else if (addressValue === 'Mailing Address') {
      $scope.editBusinessAdd = false;
      $scope.editHomeAdd = false;
    }
    getAdviserAddress();
  };

  $scope.editAddressModal = function (type) {
    if (type === 'Business Address') {
      $scope.editBusinessAdd = !$scope.editBusinessAdd;
    } else if (type === 'Home Address') {
      $scope.editHomeAdd = !$scope.editHomeAdd;
    } else if (type === 'Mailing Address') {
      $scope.editMailingAdd = !$scope.editMailingAdd;
    }
  };

  $scope.openCategoryModal = function (size) {
    $uibModal.open({
      templateUrl: '/assets/views/contacts/partials/category_modal.html',
      scope: $scope,
      controller($uibModalInstance) {
        $scope.categoryModalType = 'Select';
        $scope.isNewCategoryVisible = false;
        $scope.newCategoryList = [];
        $scope.existingCategoryNameList = [];
        $scope.selectedCategoryNames = [];

        function getCategory() {
          contactService.getCategory()
            .then((response) => {
              $scope.existingCategoryNameList = [];
              const listOfCategory = [];
              angular.forEach(response.data, (value) => {
                if (listOfCategory.indexOf(value.CategoryName) === -1 &&
                                        value.CategoryName !== '') {
                  listOfCategory.push(value.CategoryName);
                  $scope.existingCategoryNameList.push(value);
                }
              });
              if ($scope.adviserDetails) {
                $scope.selectedCategoryNames = $scope.adviserDetails.UserCategoryNames
                  .split('|')
                  .map((item) => {
                    return item.trim();
                  });
              }
            });
        }

        $scope.setCorporate = function (categoryId, isExclusiveForCorpUser, positionIndex) {
          $scope.filteredItems[positionIndex].isExclusiveForCorpUser = !(typeof isExclusiveForCorpUser === 'undefined' ? false : isExclusiveForCorpUser);
          contactService.setContactCategoryCorpUser(categoryId, !(typeof isExclusiveForCorpUser === 'undefined' ? false : isExclusiveForCorpUser))
            .then(() => {});
        };

        $scope.isCategoryPresent = function (category) {
          return $scope.selectedCategoryNames.indexOf(category) !== -1;
        };

        $scope.categoryCheckedAdd = function (categoryName, checked) {
          if (checked) {
            $scope.selectedCategoryNames.push(categoryName);
          } else {
            const categoryIndex = $scope.selectedCategoryNames.indexOf(categoryName);
            $scope.selectedCategoryNames.splice(categoryIndex, 1);
          }
        };

        $scope.addCategory = function () {
          $scope.isNewCategoryVisible = true;
          const newCategory = { CategoryID: Math.random(), CategoryName: '', IsChecked: true };
          $scope.newCategoryList.push(newCategory);
        };

        $scope.deleteCategory = function (categoryID) {
          swal({
            title: 'Are you sure?',
            text: 'This record will be removed from your category list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          }, (confirm) => {
            if (confirm) {
              contactService
                .deleteCategory(categoryID)
                .then(() => {
                  getCategory();
                  toaster.pop('success', 'Deleted', 'Category has been successfully deleted');
                })
                .catch(displayError);
            }
          });
        };

        $scope.saveCategory = function (categoryAdviser) {
          if (categoryAdviser.CategoryName === '') {
            swal('Empty Name', 'Category Name cannot be empty.', 'error');
            return;
          }
          contactService
            .addCategory(categoryAdviser.CategoryName)
            .then(() => {
              const idx = $scope.newCategoryList.indexOf(categoryAdviser);
              if (idx > -1)
                $scope.newCategoryList.splice(idx, 1);
              getCategory();
              toaster.pop('success', 'Added', 'Category has been successfully added');
            })
            .catch(displayError);
        };

        $scope.hideEditButton = function (category) {
          category.IsEditMode = true;
        };

        $scope.updateCategory = function (currentlyEditedAdviserCategory) {
          if (currentlyEditedAdviserCategory.CategoryName === '') {
            swal('Empty Name', 'Category Name cannot be empty.', 'error');
            return;
          }
          currentlyEditedAdviserCategory.IsEditMode = false;
          contactService
            .updateCategory(
              currentlyEditedAdviserCategory.CategoryID,
              currentlyEditedAdviserCategory.CategoryName
            )
            .then(() => {
              toaster.pop('success', 'Updated', 'Category has been successfully updated');
            })
            .catch(displayError);
        };

        function reorder(category, orderBy) {
          contactService.updateCategoryOrder(category, orderBy)
            .then(() => {
              getCategory();
            });
        }
        $scope.reorderUp = function (category, index) {
          if (index > 0) {
            const itemAbove = $scope.filteredItems[$scope.filteredItems.indexOf(category) - 1];
            reorder(category.CategoryID, itemAbove.OrderBy);
          }
        };

        $scope.reorderDown = function (category, index) {
          if (index + 1 < _.size($scope.existingCategoryNameList)) {
            const itemBelow = $scope.filteredItems[$scope.filteredItems.indexOf(category) + 1];
            reorder(category.CategoryID, itemBelow.OrderBy);
          }
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.done = function () {
          // todo: checkedCategories contains categories which are checked
          $scope.existingCategoryNameList.filter((item) => {
            return item.IsChecked ? item : '';
          });

          const categoryIds = [];
          const categoryNames = [];
          angular.forEach($scope.selectedCategoryNames, (value) => {
            angular.forEach($scope.existingCategoryNameList, (existingCategory) => {
              if (existingCategory.CategoryName === value) {
                categoryIds.push(existingCategory.CategoryID);
                categoryNames.push(existingCategory.CategoryName);
              }
            });
          });

          if ($scope.adviserDetails) {
            $scope.adviserDetails.UserCategoryNames = _.size(categoryNames) > 0 ? categoryNames.join('|') : '';
            $scope.adviserDetails.UserCategoryIDs = _.size(categoryIds) > 0 ? categoryIds.join(',') : '';
          }

          if (_.size($scope.setterType) > 0) {
            $scope.updateAdviserDetails($scope.setterType[0], $scope.adviserDetails, 'UserCategoryIDs', 'UserCategoryIDs', 'all', null);
          }
          $uibModalInstance.dismiss('cancel');
        };

        function init() {
          getCategory();
        }
        init();
      },
      size,
    });
  };

  $scope.updateAdviserStatus = (name, setterType, obj, saveType) => {
    corporateService.corporateContactGet($scope.clientId, $scope.familyId).then((response) => {
      if (!response || !response.data) return;
      if ($scope.selectedAdviserOrg) $scope.selectedAdviserOrg.AdviserOrganizationId = response.data.AdviserOrganizationId;
      obj.AdviserOrganizationId = response.data.AdviserOrganizationId;
      $scope.setStatus(name, setterType, obj, saveType);
    });
  };

  $scope.setStatus = function (name, setterType, obj, saveType) {
    let key;
    let field;
    let error = false;
    obj.IsActive = null;
    const previousStatusName = $scope.adviserDetails.BrokerStatusName;

    if (setterType === 'contactSet') {
      if (name === 'Terminated by LM' || name === 'Active' ||
                    name === 'Ceased by Adviser' || name === 'Suspended') {
        if ($scope.selectedAdviserOrg.AdviserOrganizationId !== 0) {
          error = false;
          $scope.adviserDetails.BrokerStatusName = name;
          key = 'BrokerStatusName';
          field = 'BrokerStatusName';
        } else {
          swal('Warning!', 'Please check that there is an Adviser Organization for this Adviser before changing status', 'warning');
          error = true;
        }
      } else if (name === 'Prospect' || name === 'Onboarding') {
        error = true;
        corporateService.checkIfFamilyHasValidEmailGet($scope.familyId, $scope.clientId)
          .then((response) => {
            if (response.data !== 1) {
              error = true;
              toaster.pop('error', 'Error', 'Email is not valid!');
            } else {
              $scope.adviserDetails.BrokerStatusName = name;
              key = 'BrokerStatusName';
              field = 'BrokerStatusName';
              $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
            }
          });
      } else {
        error = false;
        $scope.adviserDetails.BrokerStatusName = name;
        key = 'BrokerStatusName';
        field = 'BrokerStatusName';
      }
    }
    if (!error) {
      if (name === 'Active' && obj.Email) {
        generalService.getCheckIfEmailExists(obj.Email, $scope.familyId).then((response) => {
          if (response.data) {
            swal('Update Status Failed', 'Unable to update status. Email is already used by other active user.', 'error');
            $scope.adviserDetails.BrokerStatusName = previousStatusName;
          } else {
            $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
          }
        });
      } else {
        $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
      }
    }
  };

  $scope.saveAddress = function (type, address) {
    const isBusiness = (type === 'Business Address');
    const isMailing = (type === 'Mailing Address');
    commonFnService.saveAddress($scope.familyId, 0, address, isMailing, isBusiness, false)
      .then(() => {
        switch (type) {
        case 'Mailing Address':
          $scope.editMailingAdd = false;
          break;
        case 'Business Address':
          $scope.editBusinessAdd = false;
          break;
        default:
          $scope.editHomeAdd = false;
          break;
        }
        getAdviserAddress();
      });
  };

  $scope.updateAdviserDetails = function (setterType, corpAdviserDetails, key, field, saveType, formName, inputName) {
    let dataArray = [];
    let obj = corpAdviserDetails;
    const invalidAdviserPhoneNumber = (key === 'Mobile' && !obj.Mobile) || (key === 'WorkNumber' && !obj.WorkNumber) || (key === 'HomeNumber' && !obj.HomeNumber);
    if (invalidAdviserPhoneNumber) {
      toaster.pop('error', 'Error', 'Invalid Number.');
      return false;
    }
    switch (setterType) {
    case 'contactSet':
      if (formName && inputName) {
        if (!commonFnService.checkFormDateValidation(formName, inputName, obj, key)) {
          return false;
        }
      } else {
        /**
          *   This will set selected value based on pass selected value on dropdown.
          *   Checking per key of each dropdown.
          */
        /* broker status */
        if (key === 'BrokerStatusName') {
          obj.BrokerStatusId = queryService.getArrValue(null, obj.BrokerStatusName, 'BrokerStatusId', 'BrokerStatusName', $scope.brokerStatusLists);
        }
        /* reporting country */
        if (key === 'ReportingCountryCode') {
          obj.ReportingCountryId = queryService.getArrValue(null, obj.ReportingCountryCode, 'CountryId', 'CountryCode', $scope.operatingCountries);
          if (obj.ReportingCountryId && obj.ReportingCountryId < 3) {
            reportingRegionsLists(obj.ReportingCountryId);
          } else {
            reportingRegionsLists($scope.userCurrentCountyId);
          }
          obj.ReportingCountryName = queryService.getArrValue(obj.ReportingCountryId, null, 'CountryId', 'CountryName', $scope.operatingCountries);
          obj.ReportingCountryCode = queryService.getArrValue(obj.ReportingCountryId, null, 'CountryId', 'CountryCode', $scope.operatingCountries);
          obj.ReportingCountryPhoneCode = queryService.getArrValue(obj.ReportingCountryId, null, 'CountryId', 'CallingCode', $scope.operatingCountries);
        }
        /* commission */
        if (key === 'CommissionTierName') {
          const commissionTierFiltered = $scope.commissionTiers && $scope.commissionTiers.length && $scope.commissionTiers.filter(tier => Number(tier.CommissionTierId) === Number($scope.corporateAdviser.CommissionTierId));
          obj.CommissionTierName = commissionTierFiltered && commissionTierFiltered.length ? commissionTierFiltered[0].CommissionTierName : null;
        }
        /* industrial achievement */
        if (key === 'IndividualAchievementName') {
          obj.IndividualAchievementId = queryService.getArrValue(null, obj.IndividualAchievementName, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
          obj.IndividualAchievementName = queryService.getArrValue(obj.IndividualAchievementId, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
        }
        /* enquiry source */
        if (key === 'EnquirySourceName') {
          obj.EnquirySourceId = queryService.getArrValue(null, obj.EnquirySourceName, 'EnquirySourceId', 'EnquirySourceName', $scope.enquirySources);
          obj.EnquirySourceName = queryService.getArrValue(obj.EnquirySourceId, null, 'EnquirySourceId', 'EnquirySourceName', $scope.enquirySources);
        }
        /* reporting state */
        if (key === 'ReportingStateName') {
          obj.ReportingStateId = queryService.getArrValue(null, obj.ReportingStateName, 'ReportingStateId', 'ReportingStateName', $scope.reportingStates);
          obj.ReportingStateName = queryService.getArrValue(obj.ReportingStateId, null, 'ReportingStateId', 'ReportingStateName', $scope.reportingStates);
        }
        /* reporting region */
        if (key === 'ReportingRegionName') {
          obj.ReportingRegionId = queryService.getArrValue(null, obj.ReportingRegionName, 'ReportingRegionId', 'ReportingRegionName', $scope.reportingRegions);
          obj.ReportingRegionName = queryService.getArrValue(obj.ReportingRegionId, null, 'ReportingRegionId', 'ReportingRegionName', $scope.reportingRegions);
        }
        /* business data */
        if (key === 'BusinessUnitName') {
          obj.BusinessUnitID = queryService.getArrValue(null, obj.BusinessUnitName, 'BusinessUnitID', 'BusinessUnitName', $scope.business_units);
          obj.BusinessUnitName = queryService.getArrValue(obj.BusinessUnitID, null, 'BusinessUnitID', 'BusinessUnitName', $scope.business_units);
        }
        /* team access level */
        if (key === 'ReqdTeamAcessLevelName') {
          obj.ReqdTeamAcessLevel = queryService.getArrValue(null, obj.ReqdTeamAcessLevelName, 'id', 'value', $scope.level_accessList);
          obj.ReqdTeamAcessLevelName = queryService.getArrValue(obj.ReqdTeamAcessLevel, null, 'id', 'value', $scope.level_accessList);
        }
        /*  account owner */
        if (obj.AccountOwner_FullName) {
          if (typeof (obj.AccountOwner_FullName) === 'object') {
            obj.AccountOwner_FullName = obj.AccountOwner_FullName.originalObject.FullName;
            obj.AccountOwner_FamilyId = queryService.getArrValue(null, obj.AccountOwner_FullName, 'FamilyId', 'FullName', $scope.adminUsers);
            obj.AccountOwner_FullNameTmp = queryService.getArrValue(obj.AccountOwner_FamilyId, null, 'FamilyId', 'FullName', $scope.adminUsers);
          }
        } else {
          obj.AccountOwner_FullName = '';
          obj.AccountOwner_FamilyId = 0;
          obj.AccountOwner_FullNameTmp = '';
        }
        /* boker referred by */
        if (obj.BrokerReferredByTmp) {
          obj.BrokerReferredBy = obj.BrokerReferredByTmp.originalObject.FullName;
        }
        /* gender */
        if (key === 'GenderName') {
          obj.Gender = queryService.getArrValue(null, obj.GenderName, 'id', 'value', $scope.genderLists);
          obj.GenderName = queryService.getArrValue(obj.Gender, null, 'id', 'value', $scope.genderLists);
        }
        /* receive recruit newsLetter */
        if (key === 'ReceiveRecruitNewsLetter') {
          obj.ReceiveRecruitNewsLetter = commonFnService.setBooleanToInt(obj.ReceiveRecruitNewsLetter);
        }
        /* client type names */
        if (key === 'ClientTypeNames') {
          const ClientTypeName = [];
          const ClientTypeId = [];
          angular.forEach(obj.ClientTypeNameArr, (value) => {
            ClientTypeName.push(value.ClientTypeName);
            ClientTypeId.push(value.ClientTypeID);
          });
          obj.ClientTypeNames = _.size(ClientTypeName) > 0 ? ClientTypeName.join(',  ') : '';
          obj.ClientTypeIDs = _.size(ClientTypeId) > 0 ? ClientTypeId.join(',') : '';
        }
        if (key === 'FeeCardId') {
          const feeCard = $scope.feeCardValues.find(x => parseInt(x.feeCardId, 10) === parseInt(obj.FeeCardId, 10));
          if (!feeCard || !feeCard.feeCardDesc) return;
          obj.FeeCardDesc = feeCard.feeCardDesc;
        }
      }
      dataArray = [$scope.saveType[saveType], obj];
      break;
    case 'adviserPreviousNameSet':
      dataArray = [obj, $scope.familyId, $scope.clientId];
      break;
    case 'familyAchievementSet':
      if (key === 'AdviserAchievementName') {
        $scope.currentYearAchievementID = queryService.getArrValue(null, obj, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
        obj = queryService.getArrValue($scope.currentYearAchievementID, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsCurrentYear);
        dataArray = [$scope.familyId, $scope.currentYearAchievementID, $scope.currrentYear];
      }
      if (key === 'AdviserAchievementNamePrevYear') {
        $scope.previousYearAchievementID = queryService.getArrValue(null, obj, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsPreviousYear);
        obj = queryService.getArrValue($scope.previousYearAchievementID, null, 'AdviserAchievementId', 'AdviserAchievementName', $scope.adviserAchievementsPreviousYear);
        dataArray = [$scope.familyId, $scope.previousYearAchievementID, $scope.previousYear];
      }
      break;
    default:
      break;
    }

    if (dataArray && dataArray.length > 0) {
      querySet(setterType, key, field, dataArray, true);
    }
  };

  $scope.setAdviserAchievement = function (model, name) {
    if (name === 'AdviserAchievementName') {
      $scope.AdviserAchievementName = model;
    } else {
      $scope.AdviserAchievementNamePrevYear = model;
    }
  };

  const updateAdviserEmail = (setterType, obj, key, field, saveType) => {
    if ($scope.adviserinfo.adviserEmail.$invalid) {
      toaster.pop('error', 'Error', 'Invalid email address.');
    } else if ($scope.adviserDetails.BrokerStatusName === 'Active') {
      generalService.getCheckIfEmailExists(obj[key], $scope.familyId, 0).then((response) => {
        if (response.data) {
          swal('Email Validation', 'Email address is already used by other user.', 'error');
        } else {
          $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
        }
      });
    } else {
      $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
    }
  };
  $scope.updateAdviserEmail = updateAdviserEmail;
  $scope.getMobileView = (maskValue, mobile) => {
    return maskValue || mobile || 'None';
  };

  $scope.launchAddressModal = () => {
    const size = 'md';
    const modalInstance = addressModalService.launchAddressModal(size, $scope.familyId);
    modalInstance.result.then(() => {
      $scope.timeOutAdviserInstance = $timeout(() => {
        getAdviserAddress();
        toaster.pop('success', 'Successfully Updated', 'Address');
      }, 500);
    });
  };
  $scope.$on('$destory', () => {
    $timeout.cancel($scope.timeOutAdviserInstance);
  });

  $scope.setDateFormatInView = (date) => {
    return dataService.setDateFormatInView(date);
  };

  $scope.displayMap = displayMap;
  $scope.displayCustomAddress = displayCustomAddress;
  $scope.turnOnEditMode = (field) => {
    $scope.editMode[field] = true;
  };

  initialize();
});
