import ProfilerToContactCtrl from './profilerToContactCtrl';

class SaveProfilerToContactCtrl {
  constructor(
    $window,
    $uibModal,
    toaster
  ) {
    'ngInject';

    this.$window = $window;
    this.$uibModal = $uibModal;
    this.toaster = toaster;
  }

  clickListener() {
    if (this.profilerData.profilerId) {
      this.$uibModal.open({
        templateUrl: '/src/components/saveProfilerToContact/profilerToContact.html',
        controller: ProfilerToContactCtrl,
        controllerAs: 'vm',
        size: 'md',
        backdrop: 'static',
        // keyboard: false,
        windowClass: 'ipSaveTocontact-modal-v2',
        resolve: {
          profilerData: this.profilerData,
        },
      });
    } else {
      this.toaster.pop('warning', 'Warning', 'Please calculate first before saving to existing client.');
    }
  }
}

export default SaveProfilerToContactCtrl;
