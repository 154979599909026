import template from './template.html';
import BorrowersCtrl from './borrowersCtrl';

export default {
  template,
  bindings: {
    onPersonModal: '&',
    onShowAddExistingPanel: '&',
    onSearchClient: '&',
    onSelectClient: '&',
    onEditFamily: '&',
    onRemoveFamily: '&',
    onCompanyModal: '&',
    onEditCompany: '&',
    onRemoveCompany: '&',
    showThisPanel: '<',
    searchClientName: '=',
    showClientList: '<',
    clientList: '<',
    displayFamily: '<',
    displayCompany: '<',
    formData: '<',
    isAddingDisabled: '<',
  },
  controller: BorrowersCtrl,
  controllerAs: 'vm',
};
