import workbenchSuccess from './successModal';

class WorkbenchAddNoteCtrl {
  constructor($state, contactService, $window, toaster) {
    'ngInject';

    this.$state = $state;
    this.contactService = contactService;
    this.$window = $window;
    this.toaster = toaster;
  }

  $onInit() {
    this.isAdding = false;
    if (!this.noteToEdit) {
      this.setNote = this.setNoteModel();
    } else {
      this.setNote = Object.assign({}, {
        ActivityTitle: this.noteToEdit.Title,
        ActivityDetails: this.noteToEdit.Detail,
        CommunicationID: this.noteToEdit.CommunicationID,
        LoanId: this.loanId,
      });
    }

    // adding familyID to our main object
    if (this.$state.params.adviserOrgId) {
      this.setNote.FamilyID = 0;
      this.setNote.BrokerBrandingID = this.$state.params.adviserOrgId;
    } else {
      this.setNote.FamilyID = this.familyId;
    }
  }

  setNoteModel() {
    return {
      IsSticky: false,
      FamilyID: 0,
      ActivityTitle: '',
      ActivityDetails: '',
      CommunicationID: 0,
      ActivityOwner: '',
      ActivityDate: new Date(),
      AdviserName: '',
      AdviserId: '',
      IsReferrer: false,
      IsPrivate: true,
      IsNotification: false,
      NotificationSMS: false,
      NotificationEmail: false,
      ActivityType: '',
      Notes: '',
      LoanId: this.loanId,
    };
  }

  saveCreateNote() {
    if (!this.setNote.ActivityTitle || !this.setNote.ActivityDetails) {
      this.toaster.pop('warning', 'Invalid Note', 'Please create a valid note');
    } else {
      this.isAdding = true;
      const msg = (this.setNote.CommunicationID ? 'updated.' : 'created.');
      this.contactService.noteSet(this.setNote).then(() => {
        this.isAdding = false;
        workbenchSuccess(`Note has been successfully ${msg}`, () => {
          this.onBack({ $event: { refresh: true } });
        });
      });
    }
  }
}

export default WorkbenchAddNoteCtrl;
