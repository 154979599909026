import angular from 'angular';

class CommissionReportCtrl {
  constructor(
    $filter,
    NgTableParams,
    commissionService
  ) {
    'ngInject';

    this.$filter = $filter;
    this.NgTableParams = NgTableParams;
    this.commissionService = commissionService;
  }

  getCommissionReports() {
    this.commissionService.reports().then((data) => {
      const reportList = data || [];
      this.tableParams = new this.NgTableParams({
        page: 1,
        count: 10,
      }, {
        total: reportList.length,
        getData($defer, params) {
          $defer.resolve(reportList.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        },
      });
      this.isReportLoading = false;
    }, () => {
      this.isReportLoading = false;
    });
  }

  $onInit() {
    this.isReportLoading = true;
    this.downloading = {};
    this.getCommissionReports();
  }
}

angular.module('app').controller('CommissionReportCtrl', CommissionReportCtrl);
