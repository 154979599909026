import { PIPELINE_CATEGORIES } from 'Common/constants/pipelineCategories';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

export function getInvolvedPartyBasicModel(isClient) {
  return {
    ClientEntityID: 0,
    FamilyID: 0,
    DisplayName: '',
    IsClient: isClient,
    AddRelatedChildren: true,
  };
}

export function getLoanOpportunityBasicModel() {
  return {
    AllocatedAdviserID: 0,
    Title: '',
    Borrowers: [],
    Guarantors: [],
  };
}

export function goToOpportunity($state, queryParams) {
  $state.go('app.opportunity', queryParams);
}

export function getOpportunityStatus(pipelineService) {
  return pipelineService.SettingsStatusGet(false, false)
    .then((res) => {
      const { data } = res;
      if (!res || !data) return;

      const statusList = data.filter(obj => obj.PipelineCategoryID === PIPELINE_CATEGORIES.OPPORTUNITY) || [];
      if (statusList.length) {
        statusList.push(
          {
            PipelineStatus: 'Convert to Application',
            PipelineStatusID: PIPELINE_STATUS.APPLICATION,
            IsConversionStatus: true,
          },
          {
            PipelineStatus: 'Not Proceeded',
            PipelineStatusID: PIPELINE_STATUS.NOT_PROCEEDED,
            IsConversionStatus: true,
          }
        );
      }
      return statusList;
    });
}
