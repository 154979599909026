class SelectOptionsService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'SelectOptions';
    this.defaultOptions = [null, true, false];
  }

  getLoanTerm() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanTerm`, ...this.defaultOptions);
  }

  getLoanRepaymentFrequency() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanRepaymentFrequency`, ...this.defaultOptions);
  }

  getPaymentFrequency() {
    return this.httpClient.get(`${this.apiBaseUrl}/PaymentFrequency`, ...this.defaultOptions);
  }

  getCalculationPeriod() {
    return this.httpClient.get(`${this.apiBaseUrl}/CalculationPeriod`, ...this.defaultOptions);
  }

  getIPCoverType() {
    return this.httpClient.get(`${this.apiBaseUrl}/IPCoverType`, ...this.defaultOptions);
  }

  getWaitPeriod() {
    return this.httpClient.get(`${this.apiBaseUrl}/WaitPeriod`, ...this.defaultOptions);
  }

  getBenefitPeriods() {
    return this.httpClient.get(`${this.apiBaseUrl}/BenefitPeriod`, ...this.defaultOptions);
  }

  getSecurityType() {
    return this.httpClient.get(`${this.apiBaseUrl}/SecurityType`, ...this.defaultOptions);
  }

  getPropertyOwnership() {
    return this.httpClient.get(`${this.apiBaseUrl}/PropertyOwnership`, ...this.defaultOptions);
  }

  getPropertyType() {
    return this.httpClient.get(`${this.apiBaseUrl}/PropertyType`, ...this.defaultOptions);
  }

  getPropertyDevelopmentStatus() {
    return this.httpClient.get(`${this.apiBaseUrl}/PropertyDevelopmentStatus`, ...this.defaultOptions);
  }

  getPropertyIntention() {
    return this.httpClient.get(`${this.apiBaseUrl}/PropertyIntention`, ...this.defaultOptions);
  }

  getPropertyZoning() {
    return this.httpClient.get(`${this.apiBaseUrl}/PropertyZoning`, ...this.defaultOptions);
  }

  getPropertyTenure() {
    return this.httpClient.get(`${this.apiBaseUrl}/PropertyTenure`, ...this.defaultOptions);
  }

  getPropertyValuationType() {
    return this.httpClient.get(`${this.apiBaseUrl}/PropertyValuationType`, ...this.defaultOptions);
  }

  getIncomeTypeForRegion(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/IncomeTypeForRegion`, { brokerEventID });
  }

  getLiabiltyTypeForRegion(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LiabiltyTypeForRegion`, { brokerEventID });
  }

  getExpenseType() {
    return this.httpClient.get(`${this.apiBaseUrl}/ExpenseType`, ...this.defaultOptions);
  }
}

export default SelectOptionsService;
