import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { isCancelled } from 'Common/utilities/subscription';
import { isEnvironment } from 'Common/utilities/env';

class LayoutCtrl {
  constructor(
    $localStorage,
    uiService,
    configService,
    usersSubscriptionService,
    currentUserService
  ) {
    'ngInject';

    this.uiService = uiService;
    this.configService = configService;
    this.localStorage = $localStorage;
    this.usersSubscriptionService = usersSubscriptionService;
    this.currentUserService = currentUserService;
    this.isProductionBanner = () => isEnvironment('uat');
  }

  $onInit() {
    const isEnvNotifDefined = typeof this.localStorage.isEnvNotifDismissed;
    this.waitPriceFinderResponse = false;
    if (isEnvNotifDefined === 'undefined' || isEnvNotifDefined === 'null') {
      this.localStorage.isEnvNotifDismissed = false;
    }
  }

  dismissEnvNotif() {
    this.localStorage.isEnvNotifDismissed = true;
  }

  showPriceFinder() {
    return (this.configService.feature && this.configService.feature.priceFinder && this.uiService.isCountry('Australia')) || 0;
  }

  priceFinderClick(mobileCLick) {
    if (mobileCLick) this.uiService.menuToggleInMobile();
    const familyId = this.uiService.userFamilyId();
    if (this.waitPriceFinderResponse || !familyId) return;
    const priceFinderData = this.usersSubscriptionService.getPriceFinderData();
    const { AccessType } = this.uiService.loggedUserData;
    const checkPriceFinderStatus = (AccessType === ACCESS_TYPE.CORPORATE) || ((AccessType === ACCESS_TYPE.ADVISER || AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER) && priceFinderData && (priceFinderData.isActive || isCancelled(priceFinderData)));
    if (checkPriceFinderStatus) {
      this.usersSubscriptionService.priceFinderRedirect();
    } else {
      this.waitPriceFinderResponse = true;
      const props = {
        familyId,
        subscriptionData: priceFinderData,
        orderedById: familyId,
      };
      this.usersSubscriptionService.subscribeToService(props)
        .then((response) => {
          if (!response) return;
          this.usersSubscriptionService.priceFinderRedirect();
          this.uiService.getCurrentUserSubscriptions();
        })
        .finally(() => {
          this.waitPriceFinderResponse = false;
        });
    }
  }
}

export default LayoutCtrl;
