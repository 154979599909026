import angular from 'angular';

angular.module('app').controller('RecommendationReportCtrl',
  function RecommendationReportCtrl(
    uiService,
    $scope,
    $uibModal) {
    // models

    // methods
    const openRecommendationModal = () => {
      $uibModal.open({
        templateUrl: '/assets/views/loanApplication/sidebar/modal/recommendationReport.html',
        controller: 'RecommendationModalCtrl',
        size: 'md',
        scope: $scope,
      });
    };

    const isUserNZ = () => {
      return uiService.isCountry('New Zealand');
    };
    angular.extend($scope, {
      openRecommendationModal,
      isUserNZ,
    });
  });
