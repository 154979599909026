import angular from 'angular';

angular.module('app').controller('TaskViewTaskModalCtrl', function TaskViewTaskModalCtrl(
  $scope,
  $uibModalInstance,
  id,
  tasksListTable
) {
  const taskObj = tasksListTable.filter((o) => {
    return parseInt(o.ActivityID, 10) === id;
  });
  $scope.SelectedTask = taskObj[0];

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
