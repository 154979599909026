import { QUESTION_ID } from 'Common/constants/questionId';
import { QUESTION_TYPE } from 'Common/constants/questionType';
import { MERGE_FIELDS } from 'Common/constants/mergeFields';
import MergeFields from './mergeFields';

class LoanSubmissionClientEmailContent {
  constructor(clientFamilyId, loanId, lenderSubmission, corporateService) {
    this.clientFamilyId = clientFamilyId;
    this.loanId = loanId;
    this.lenderSubmission = lenderSubmission;
    this.corporateService = corporateService;
    this.initialize();
    this.create();
  }

  isValidClientLoanDetails() {
    return this.clientFamilyId && this.loanId;
  }

  hasValidContent() {
    return this.hasValidSubject() && this.hasValidBody();
  }

  hasValidSubject() {
    return this.Subject && this.Subject.indexOf('{Lender Name}') === -1;
  }

  hasValidBody() {
    return this.Body && this.Body.indexOf('{Lender Name}') === -1;
  }

  initialize() {
    const { ApplicantEmailSubject: Subject, ApplicantEmailBody: Body } = this.lenderSubmission;
    const { APPLICANT_EMAIL_SUBJECT: QuestionId, APPLICANT_EMAIL_CONTENT: BodyQuestionId } = QUESTION_ID;
    const { TEXT_ONLY: QuestionDisplayTypeId } = QUESTION_TYPE;
    this.SubjectQuestion = {
      QuestionId,
      QuestionDisplayTypeId,
    };
    this.BodyQuestion = {
      QuestionId: BodyQuestionId,
      QuestionDisplayTypeId,
    };
    this.Subject = Subject;
    this.Body = Body;
  }

  create() {
    if (!this.isValidClientLoanDetails()) return;
    this.corporateService.MergeFieldsGet(this.clientFamilyId, 0, this.loanId).then((response) => {
      if (!response.data) return;
      this.mergeFields = new MergeFields(response.data);
      const originalSubject = this.Subject;
      const originalBody = this.Body;
      this.createSubject();
      this.createBody();

      const subjectIsChanged = originalSubject !== this.Subject;
      const bodyIsChanged = originalBody !== this.Body;
      const hasChanges = subjectIsChanged || bodyIsChanged;
      if (hasChanges && this.onCreatedListener) {
        this.onCreatedListener();
      }
    });
  }

  createSubject() {
    const lenderName = this.mergeFields.getLoanFieldValue(MERGE_FIELDS.LENDER_NAME) || '{Lender Name}';
    const subject = `Copy of your ${lenderName} Loan Application Document`;
    this.Subject = subject;
  }

  createBody() {
    this.createOpening();
    this.createBodyContent();
    this.createClosing();
  }

  createOpening() {
    const clientName = this.mergeFields.getContactFieldValue(MERGE_FIELDS.PREFERRED_NAME);
    const opening = `<p>Dear ${clientName},</p>`;
    this.Body = opening;
  }

  createBodyContent() {
    const lenderName = this.mergeFields.getLoanFieldValue(MERGE_FIELDS.LENDER_NAME) || '{Lender Name}';
    let content = `<p>Please find attached a copy of the loan application document submitted to ${lenderName}.</p>`;
    content = `${content}<p>If there are any errors or omissions, or you have any questions please don't hesitate to contact me.</p>`;
    this.Body = `${this.Body}${content}`;
  }

  createClosing() {
    let closing = '<p>Kind regards,</p>';
    const adviserName = this.mergeFields.getOtherFieldValue(MERGE_FIELDS.ADVISER_NAME);
    const businessName = this.mergeFields.getOtherFieldValue(MERGE_FIELDS.BUSINESS_NAME);
    closing = `${closing}${adviserName} (adviser)<br/>`;
    closing = `${closing}${businessName}`;
    this.Body = `${this.Body}${closing}`;
  }

  onCreated(listener) {
    this.onCreatedListener = listener;
  }
}

export default LoanSubmissionClientEmailContent;
