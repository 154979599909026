import template from './contactNameCell.html';
import './style.scss';

export default {
  template,
  bindings: {
    contactName: '<',
    loanId: '<',
    opportunityId: '<',
    onRedirectToOpportunity: '&',
  },
};
