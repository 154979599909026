import angular from 'angular';
angular.module('app').factory('importantDocumentService', function importantDocumentService(
    configService,
    toaster
) {
    const toUploadReady = (uploadMyDocumentDroplet, documentId) => {
        uploadMyDocumentDroplet.allowedExtensions(['pdf', 'docx', 'doc', 'odt', 'xls', 'xlsx', 'ods', 'odp', 'ppt', 'pptx', 'jpg', 'jpeg', 'png']);
        uploadMyDocumentDroplet.setRequestUrl(`${configService.resource}/corporate/ImportantDocumentUpload?documentId=${documentId}`);
        uploadMyDocumentDroplet.setRequestHeaders({ Authorization: configService.token });
        uploadMyDocumentDroplet.defineHTTPSuccess([/2.{2}/]);
        uploadMyDocumentDroplet.useArray(false);
    };

    const uploadFile = (upload, caller) => {
        if (upload.type === caller.uploadMyDocumentDroplet.FILE_TYPES.INVALID) {
            toaster.pop('error', 'Invalid', 'Bad file type added');
        } else {
            caller.isProgressBarVisible = true;
            caller.uploadMyDocumentDroplet.uploadFiles();
        }
    };

    const successUpload = (response, caller) => {
        if (response && response.length > 0) {
            caller.openUploadedDocumentModal('lg', response[0].DocumentId, true);
            caller.isProgressBarVisible = false;
        }
    };

    const errorUpload = () => {
        toaster.pop('error', 'Something went wrong', 'Please try again');
    };

    const documentFileExtension = (arrData) => {
        if (!arrData || !arrData.length) return [];
        return arrData.map((value) => {
            value.fileExtension = value.documentKey ? value.documentKey.split('.').pop() : '';
            return value;
          });
    };

    const service = {
        documentFileExtension,
        toUploadReady,
        successUpload,
        errorUpload,
        uploadFile,
    };

    return service;
});
