import angular from 'angular';

angular.module('app').controller('OpenModelForAddReferralModalCtrl',
  function OpenModelForAddReferralModalCtrl(
    $scope,
    $uibModalInstance,
    $uibModal
  ) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.newReferralType = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/referrals/partials/referral_type_modal.html',
        controller: 'NewReferralTypeModalCtrl',
        size,
        windowClass: 'referral_type',
        resolve: {
          cancel() {
            return $scope.cancel;
          },
        },
      });
    };

    $scope.selectOrganisation = function (size) {
      $uibModal.open({
        templateUrl: '/assets/views/referrals/partials/select_organisation_modal.html',
        controller: 'SelectOrganisationModalCtrl',
        size,
        windowClass: 'referral_type',
        resolve: {
          cancel() {
            return $scope.cancel;
          },
        },
      });
    };
  });
