// Enter Event Directive
import angular from 'angular';

angular.module('app').directive('ngEnter', function ngEnter($timeout) {
  return function (scope, element, attrs) {
    element.bind('keydown keypress', (event) => {
      if (event.which === 13) {
        $timeout(() => scope.$eval(attrs.ngEnter));
        event.preventDefault();
      }
    });
  };
});
