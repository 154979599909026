const JS_REQUIRES = ['JS_REQUIRES', {
  // *** Scripts
  scripts: {
    // *** Javascript Plugins
    d3: 'vendor/d3/d3.min.js',

    // *** jQuery Plugins
    chartjs: 'vendor/chartjs/Chart.min.js',
    'ckeditor-plugin': 'vendor/ckeditor/ckeditor.js',
    'jquery-nestable-plugin': ['vendor/jquery-nestable/jquery.nestable.js'],
    'touchspin-plugin': ['vendor/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.js', 'vendor/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css'],

    angularAwesomeSlider: ['vendor/angular-awesome-slider/dist/angular-awesome-slider.min.js', 'vendor/angular-awesome-slider/dist/css/angular-awesome-slider.min.css'],
    // 'bootstrapSlider: ['vendor/seiyria-bootstrap-slider/dist/bootstrap-slider.js', 'vendor/seiyria-bootstrap-slider/dist/css/bootstrap-slider.min.css'],

    'jquery-appear-plugin': ['vendor/jquery-appear/build/jquery.appear.min.js'],
    'spectrum-plugin': ['vendor/spectrum/spectrum.js', 'vendor/spectrum/spectrum.css'],
    selectize: 'vendor/selectize/dist/css/selectize.default.css',
    'RWD-Table-Patterns': ['vendor/RWD-Table-Patterns/dist/css/rwd-table.min.css', 'vendor/RWD-Table-Patterns/dist/js/rwd-table.min.js'],
    'table-responsive': ['vendor/RWD-Table-Patterns/dist/css/rwd-table.min.css', 'vendor/RWD-Table-Patterns/dist/js/rwd-table.js'],
    angucomplete: ['vendor/angucomplete/angucomplete.css', 'vendor/angucomplete/angucomplete.js'],
    'jquery.inputmask': ['vendor/jquery.maskedinput-master/dist/jquery.maskedinput.min.js'],
    select2: ['vendor/select2/select2.min.js', 'vendor/select2/select2.css'],
    'ui.select': ['vendor/angular-ui-select/dist/select.min.js', 'vendor/angular-ui-select/dist/select.min.css'],
    'jQuery-Mask-Plugin': ['vendor/jquery-mask-plugin/dist/jquery.mask.min.js'], // ['vendor/jQuery-Mask-Plugin-master/dist/jquery.mask.js'],
    'dom-to-image': ['vendor/dom-to-image/dist/dom-to-image.min.js'],
    jspdf: ['vendor/jspdf/dist/jspdf.min.js'],

    // *** Controllers
    DashboardCtrl: 'assets/js/controllers/dashboardCtrl.js',
    GeneralSearchCtrl: 'assets/js/controllers/dashboard/generalSearchCtrl.js',

    /*
         * Contacts
         * */

    // logs
    ConnectLogsCtrl: 'assets/js/controllers/connectLogsCtrl.js',

    // Create New Contact
    createAccountCtrl: 'assets/js/controllers/contacts/contactCreate.js',
    contactsNormalNewCtrl: 'assets/js/controllers/contacts/contactsNormalNewCtrl.js',
    ContactsNewCtrl: 'assets/js/controllers/contacts/contactsNewCtrl.js',

    // Contacts: Client
    ContactsCtrl: 'assets/js/controllers/contactsCtrl.js',
    ContactDetailCtrl: 'assets/js/controllers/contactDetailCtrl.js',
    NotesDetailsCtrl: 'assets/js/controllers/contacts/notesDetailsCtrl.js',
    SidebarDetailCtrl: 'assets/js/controllers/contacts/sidebarDetailCtrl.js',
    LoanDetailCtrl: 'assets/js/controllers/contacts/loanDetailCtrl.js',
    LoanDetailsModalCtrl: 'assets/js/controllers/contacts/modal/loanDetailsModalCtrl.js',
    SecurityStructureCtrl: 'assets/js/controllers/contacts/modal/securityStructureCtrl.js',
    LoanInformationCtrl: 'assets/js/controllers/contacts/modal/loanInformationCtrl.js',
    LoanInformationAddExistingCtrl: 'assets/js/controllers/contacts/modal/loanInformationAddExistingCtrl.js',
    LoanCommissionCtrl: 'assets/js/controllers/contacts/modal/loanCommissionCtrl.js',
    LoanCommissionEnquiryCtrl: 'assets/js/controllers/contacts/modal/loanCommissionEnquiryCtrl.js',
    LoanStructureCtrl: 'assets/js/controllers/contacts/modal/loanStructureCtrl.js',
    LoanStructureAddEditCtrl: 'assets/js/controllers/contacts/modal/loanStructureAddEditCtrl.js',
    ModifyRefixCtrl: 'assets/js/controllers/contacts/modal/modifyRefixCtrl.js',
    SummaryDetailCtrl: 'assets/js/controllers/contacts/summaryDetailCtrl.js',
    ClientDetailCtrl: 'assets/js/controllers/contacts/clientDetailCtrl.js',
    AddClientModalCtrl: 'assets/js/controllers/contacts/client/addClientModalCtrl.js',
    ClientTypeModalCtrl: 'assets/js/controllers/contacts/client/clientTypeModalCtrl.js',
    CategoryModalCtrl: 'assets/js/controllers/contacts/client/categoryModalCtrl.js',
    RatingModalCtrl: 'assets/js/controllers/contacts/client/ratingModalCtrl.js',
    ReferralModalCtrl: 'assets/js/controllers/contacts/client/referralModalCtrl.js',
    PersonCompanyModalCtrl: 'assets/js/controllers/contacts/client/personCompanyModalCtrl.js',
    DocumentModalCtrl: 'assets/js/controllers/contacts/client/documentModalCtrl.js',
    DocumentPreviewCtrl: 'assets/js/controllers/contacts/client/documentPreviewCtrl.js',
    ViewEmailCtrl: 'assets/js/controllers/contacts/client/viewEmailCtrl.js',
    CreateNoteModalCtrl: 'assets/js/controllers/contacts/client/createNoteModalCtrl.js',
    financialsCtrl: 'assets/js/controllers/contacts/financialsCtrl.js',
    InsuranceDetailCtrl: 'assets/js/controllers/contacts/insuranceDetailCtrl.js',
    InsExistingCtrl: 'assets/js/controllers/contacts/client/insurances/insExistingCtrl.js',
    InsInProgressCtrl: 'assets/js/controllers/contacts/client/insurances/insInProgressCtrl.js',
    InsurancePreviousCtrl: 'assets/js/controllers/contacts/client/insurances/insPreviousCtrl.js',
    InsuranceModalCtrl: 'assets/js/controllers/contacts/insuranceModalCtrl.js',
    DocumentsDetailCtrl: 'assets/js/controllers/contacts/documentsDetailCtrl.js',
    EmailsDetailCtrl: 'assets/js/controllers/contacts/emailsDetailCtrl.js',
    AddressesCtrl: 'assets/js/controllers/contacts/addressesCtrl.js',
    AddressesDetailCtrl: 'assets/js/controllers/contacts/addresses/addressesDetailCtrl.js',
    ContactEmploymentCtrl: 'assets/js/controllers/contacts/contactEmploymentCtrl.js',
    IdentificationCtrl: 'assets/js/controllers/contacts/modal/identificationCtrl.js',
    AddEmploymentCtrl: 'assets/js/controllers/contacts/modal/addEmploymentCtrl.js',
    EditEmploymentCtrl: 'assets/js/controllers/contacts/modal/editEmploymentCtrl.js',
    AddEmploymentRolesCtrl: 'assets/js/controllers/contacts/modal/addEmploymentRolesCtrl.js',
    AddressesNewModalCtrl: 'assets/js/controllers/contacts/addresses/addressesNewModalCtrl.js',
    InsuranceExistingModalCtrl: 'assets/js/controllers/contacts/insurance/insuranceExistingModalCtrl.js',
    clientModalCtrl: 'assets/js/controllers/contacts/modal/clientModalCtrl.js',

    FinancialAssetsCtrl: 'assets/js/controllers/contacts/client/financialAssetsCtrl.js',
    FinancialLiabilitiesCtrl: 'assets/js/controllers/contacts/client/financialLiabilitiesCtrl.js',
    FinancialIncomeCtrl: 'assets/js/controllers/contacts/client/financialIncomeCtrl.js',
    FinancialExpenseCtrl: 'assets/js/controllers/contacts/client/financialExpenseCtrl.js',

    // Lending Scenario
    LendingDetailCtrl: 'assets/js/controllers/contacts/client/lendingDetailCtrl.js',
    lendingScenarioDetailCtrl: 'assets/js/controllers/contacts/client/lendingScenarioDetailCtrl.js',
    lendingScenarioReqCtrl: 'assets/js/controllers/contacts/client/lendingScenarioReqCtrl.js',
    lendingScenarioRelPartyCtrl: 'assets/js/controllers/contacts/client/lendingScenarioRelParty.js',
    lendingScenarioInsuranceCtrl: 'assets/js/controllers/contacts/client/lendingScenarioInsuranceCtrl.js',

    // Business Account
    BusinessAccountSingleCtrl: 'assets/js/controllers/contacts/business/businessAccountSingleCtrl.js',

    // Corporate
    corporateDetailCtrl: 'assets/js/controllers/corporateDetailCtrl.js',
    ComplianceReviewRatingModalCtrl: 'assets/js/controllers/corporate/adviser/modal/complianceReviewRatingModalCtrl.js',
    BrokerAllocationToolModalCtrl: 'assets/js/controllers/contacts/modal/BrokerAllocationToolModalCtrl.js',
    AddressModalCtrl: 'assets/js/controllers/corporate/adviser/modal/AddressModalCtrl.js',
    fieldType: 'assets/js/directives/Contact/field-type.js',

    EmailManagementCtrl: 'assets/js/controllers/corporate/emailManagement/emailManagementCtrl.js',
    emailTemplateCtrl: 'assets/js/controllers/corporate/emailManagement/tabs/emailTemplateCtrl.js',
    sharingAndPrivacyCtrl: 'assets/js/controllers/corporate/emailManagement/tabs/sharingAndPrivacyCtrl.js',
    CorporateSendEmailCtrl: 'assets/js/controllers/corporate/corporateSendEmailCtrl.js',
    CorporateSendSmsCtrl: 'assets/js/controllers/corporate/corporateSendSmsCtrl.js',
    AttachFileMgmtCtrl: 'assets/js/controllers/corporate/attachFileMgmt/attachFileMgmtCtrl.js',

    // adviser
    AdviserCtrl: 'assets/js/controllers/corporate/adviserCtrl.js',
    // sub tab adviser
    AdviserInfoCtrl: 'assets/js/controllers/corporate/adviser/adviserInfoCtrl.js',
    AdviserComplianceCtrl: 'assets/js/controllers/corporate/adviser/adviserComplianceCtrl.js',
    AdviserRecruitmentCtrl: 'assets/js/controllers/corporate/adviser/adviserRecruitmentCtrl.js',
    AdviserWMComplianceCtrl: 'assets/js/controllers/corporate/adviser/adviserWMComplianceCtrl.js',
    AdviserLeadsCtrl: 'assets/js/controllers/corporate/adviser/adviserLeadsCtrl.js',
    AdviserMarketingWebCtrl: 'assets/js/controllers/corporate/adviser/adviserMarketingWebCtrl.js',
    // adviserOrg
    AdviserOrgCtrl: 'assets/js/controllers/corporate/adviserOrgCtrl.js',
    // assitant
    AssistantCtrl: 'assets/js/controllers/corporate/assistantCtrl.js',
    ReferrerAsMemberNewCtrl: 'assets/js/controllers/contacts/referrer/referrerAsMemberNewCtrl.js',
    AgreementNewCtrl: 'assets/js/controllers/contacts/referrer/agreementNewCtrl.js',
    // referrer
    ReferrerDetailCtrl: 'assets/js/controllers/contacts/referrer/referrerDetailCtrl.js',
    // referrer org
    ReferrerOrgCtrl: 'assets/js/controllers/corporate/referrerOrgCtrl.js',
    referrerOrgDetailCtrl: 'assets/js/controllers/contacts/referrer/referrerOrgDetailCtrl.js',
    // loan tools
    LoanToolsCtrl: 'assets/js/controllers/loanTools/loanToolsCtrl.js',
    addKeyModalCtrl: 'assets/js/controllers/loanTools/addKeyModalCtrl.js',
    AddDocumentsModalCtrl: 'assets/js/controllers/loanTools/addDocumentsModalCtrl.js',
    ImportantDocumentCtrl: 'assets/js/controllers/loanTools/importantDocumentCtrl.js',
    ImportantDocumentModalCtrl: 'assets/js/controllers/loanTools/importantDocumentModalCtrl.js',
    // googleAuthCode
    GoogleAuthCodeCtrl: 'assets/js/controllers/googleAuthCode/googleAuthCodeCtrl.js',
    GmailCtrl: 'assets/js/controllers/googleauthcode/gmailCtrl.js',
    GdriveCtrl: 'assets/js/controllers/googleauthcode/gdriveCtrl.js',
    // pipeline
    PipelineCtrl: 'assets/js/controllers/pipeline/pipelineCtrl.js',
    PipelineTimelineCtrl: 'assets/js/controllers/pipeline/leads/pipelineTimelineCtrl.js',
    PipelineListViewCtrl: 'assets/js/controllers/pipeline/leads/pipelineListViewCtrl.js',
    PipelineAppCtrl: 'assets/js/controllers/pipeline/pipelineCtrlApp.js',
    LinkToLoanCtrl: 'assets/js/controllers/pipeline/linkToLoanCtrl.js',

    // insuranceTools
    insuranceToolsCtrl: 'assets/js/controllers/insuranceTools/insuranceToolsCtrl.js',
    InsurancePersonAddCtrl: 'assets/js/controllers/insuranceTools/modals/insurancePersonAddCtrl.js',
    NewQouteCtrl: 'assets/js/controllers/insuranceTools/newQouteCtrl.js',
    InsurerListCtrl: 'assets/js/controllers/insuranceTools/modals/insurerListCtrl.js',
    QuoteSettingsCtrl: 'assets/js/controllers/insuranceTools/modals/quoteSettingsCtrl.js',
    QuoteReportPDFCtrl: 'assets/js/controllers/insuranceTools/modals/quoteReportPDFctrl.js',
    BrochuresModalCtrl: 'assets/js/controllers/insuranceTools/modals/brochuresModalctrl.js',
    InsuranceProfilerCtrl: 'assets/js/controllers/insuranceTools/insuranceProfilerCtrl.js',

    // insuranceApplication
    InsuranceAppCtrl: 'assets/js/controllers/insuranceTools/insuranceAppCtrl.js',
    InsAppApplicationCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/application.js',
    InsAppCustObjectivesCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/custObj.js',
    InsAppProdSelCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/prodSel.js',
    InsAppFinancialCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/financial.js',
    InsAppFinancialAssetsCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/finPos/assets.js',
    InsAppFinancialLiabilitiesCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/finPos/expense.js',
    InsAppFinancialIncomeCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/finPos/income.js',
    InsAppFinancialExpenseCtrl: 'assets/js/controllers/insuranceTools/insApp/preAssess/finPos/liabilities.js',
    InsurerAppCtrl: 'assets/js/controllers/insuranceTools/insApp/insurerApp/insurerAppCtrl.js',
    InsurerAppStatementCtrl: 'assets/js/controllers/insuranceTools/insApp/insurerApp/statementCtrl.js',
    questionField: 'assets/js/directives/insApplication//questionField.js',

    // communicate
    CommunicateCtrl: 'assets/js/controllers/communicate/communicateCtrl.js',
    NewEmailCampaignCtrl: 'assets/js/controllers/communicate/newEmailCampaignCtrl.js',
    // report
    ReportCtrl: 'assets/js/controllers/report/reportCtrl.js',
    // resources
    resourcesCtrl: 'assets/js/controllers/resources/resourcesCtrl.js',

    // resource
    ComplaintsCtrl: 'assets/js/controllers/resource/complaintsCtrl.js',
    EducationCtrl: 'assets/js/controllers/resource/educationCtrl.js',
    MyDocumentsCtrl: 'assets/js/controllers/resource/myDocumentsCtrl.js',
    OnlineTrainingCtrl: 'assets/js/controllers/resource/onlineTrainingCtrl.js',
    TemplatesCtrl: 'assets/js/controllers/resource/templatesCtrl.js',

    // referrals
    ReferralsCtrl: 'assets/js/controllers/referrals/referralsCtrl.js',
    referralCtrl: 'assets/js/controllers/referrals/referralCtrl.js',
    NewReferralCtrl: 'assets/js/controllers/referrals/newReferralCtrl.js',
    ReferrerInfoCtrl: 'assets/js/controllers/referrals/referrerInfoCtrl.js',
    GroupInfoCtrl: 'assets/js/controllers/referrals/groupInfoCtrl.js',

    // send referrals
    SendReferralsCtrl: 'assets/js/controllers/referrals/sendReferralsCtrl.js',
    SendReferralModalCtrl: 'assets/js/controllers/referrals/sendReferrals/sendReferralModalCtrl.js',

    // tasks
    tasksCtrl: 'assets/js/controllers/tasks/tasksCtrl.js',
    addTasksCtrl: 'assets/js/controllers/tasks/addTaskCtrl.js',
    AddTaskTemplateCtrl: 'assets/js/controllers/tasks/addTaskTemplateCtrl.js',

    // loan calculator
    LoanRepaymentCalculatorCtrl: 'assets/js/controllers/loanCalculator/loanRepaymentCalculatorCtrl.js',
    AdditionalRepaymentCalculatorCtrl: 'assets/js/controllers/loanCalculator/additionalRepaymentCalculatorCtrl.js',
    IncomeCalculatorCtrl: 'assets/js/controllers/loanCalculator/incomeCalculatorCtrl.js',
    CompareLoanCalculatorCtrl: 'assets/js/controllers/loanCalculator/compareLoanCalculatorCtrl.js',
    SavingGoalCalculatorCtrl: 'assets/js/controllers/loanCalculator/savingGoalCalculatorCtrl.js',
    LoanProfilerCtrl: 'assets/js/controllers/loanProfiler/loanProfilerCtrl.js',
    ServiceabilityModalCtrl: 'assets/js/controllers/contacts/modal/serviceabilityModalCtrl.js',
    ForSetRateModalCtrl: 'assets/js/controllers/contacts/modal/forSetRateModalCtrl.js',
    ForDisplayProductFinderTableColumnCtrl: 'assets/js/controllers/contacts/modal/forDisplayProductFinderTableColumnCtrl.js',
    ForSelectedProductModalCtrl: 'assets/js/controllers/contacts/modal/forSelectedProductModalCtrl.js',
    ForAddLoanStructureModalCtrl: 'assets/js/controllers/contacts/modal/forAddLoanStructureModalCtrl.js',
    ForLenderDetailModalCtrl: 'assets/js/controllers/contacts/modal/forLenderDetailModalCtrl.js',
    ForProductDetailModalCtrl: 'assets/js/controllers/contacts/modal/forProductDetailModalCtrl.js',
    ForAdvanceFilterModalCtrl: 'assets/js/controllers/contacts/modal/forAdvanceFilterModalCtrl.js',
    ProductFinderCtrl: 'assets/js/controllers/loanProfiler/productFinderCtrl.js',
    serviceabilityCtrl: 'assets/js/controllers/loanProfiler/serviceabilityCtrl.js',
    FundingCalculatorCtrl: 'assets/js/controllers/fundingCalculator/fundingCalculatorCtrl.js',
    OffsetCalculatorCtrl: 'assets/js/controllers/loanCalculator/offsetCalculatorCtrl.js',

    // notification sidebar
    NotificationSidebarCtrl: 'assets/js/controllers/notificationSidebar/notificationSidebarCtrl.js',
    NotificationSidebarXsCtrl: 'assets/js/controllers/notificationSidebar/notificationSidebarXsCtrl.js',
    ActivitySidebarCtrl: 'assets/js/controllers/notificationSidebar/activitySidebarCtrl.js',
    TaskSidebarCtrl: 'assets/js/controllers/notificationSidebar/taskSidebarCtrl.js',
    AppointmentSidebarCtrl: 'assets/js/controllers/notificationSidebar/appointmentSidebarCtrl.js',

    // Loan Application v3
    LoanAppCtrl: 'assets/js/controllers/loanApp/loanAppCtrl.js',
    WorkbenchModalCtrl: 'assets/js/controllers/loanApp/workbenchModalCtrl.js',
    AddPersonModalCtrl: 'dev/assets/js/controllers/contacts/modal/addPersonModalCtrl.js',
    FactFindCtrl: 'assets/js/controllers/loanApp/sidebar/factFind.js',
    FactFindModalCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/factFindModalCtrl.js',
    ApplyOnlineSentCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/applyOnlineSentCtrl.js',
    RecommendationReportCtrl: 'assets/js/controllers/loanApp/sidebar/recommendationReportCtrl.js',
    RecommendationModalCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/recommendationModalCtrl.js',
    LoanAppLoanSubmissionCtrl: 'assets/js/controllers/loanApp/loanAppLoanSubmission.js',
    LoanAppApplicationCtrl: 'assets/js/controllers/loanApp/clientAssess/application.js',
    LenderWorksheetCtrl: 'assets/js/controllers/loanApp/lenderWorksheetCtrl.js',
    LoanAppEmploymentCtrl: 'assets/js/controllers/loanApp/clientAssess/application.employment.js',
    LoanAppAddEmploymentCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/loanAppAddEmploymentCtrl.js',
    LoanAppEditEmploymentCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/loanAppEditEmploymentCtrl.js',
    ApplicantModalCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/applicantModalCtrl.js',
    AddCompanyRelatedModalCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/addCompanyRelatedModalCtrl.js',
    LoanAppIdentificationModalCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/loanAppIdentificationModalCtrl.js',
    LoanAppAddressCtrl: 'assets/js/controllers/loanApp/clientAssess/application.address.js',
    AddAddressHistoryCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/addAddressHistoryCtrl.js',
    EditAddressHistoryCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/editAddressHistoryCtrl.js',
    loanAppCreditHistoryCtrl: 'assets/js/controllers/loanApp/clientAssess/creditHistory.js',
    LoanAppLoanDetailsCtrl: 'assets/js/controllers/loanApp/clientAssess/loanDetails.js',
    LoanAppFinancialCtrl: 'assets/js/controllers/loanApp/clientAssess/financial.js',
    LoanAppFinancialAssetsCtrl: 'assets/js/controllers/loanApp/clientAssess/financial/assets.js',
    LoanAppFinancialLiabilitiesCtrl: 'assets/js/controllers/loanApp/clientAssess/financial/liabilities.js',
    LoanAppFinancialIncomeCtrl: 'assets/js/controllers/loanApp/clientAssess/financial/income.js',
    LoanAppFinancialExpenseCtrl: 'assets/js/controllers/loanApp/clientAssess/financial/expense.js',

    LoanAppInsuranceCtrl: 'assets/js/controllers/loanApp/clientAssess/insurance.js',
    LoanAppBrokerDeclarationsCtrl: 'assets/js/controllers/loanApp/clientAssess/brokerDeclarations.js',
    LoanAppSecurityCtrl: 'assets/js/controllers/loanApp/clientAssess/security.js',
    loanAppSecurity: 'assets/js/controllers/loanApp/clientAssess/security.js',
    LoanAppCustomerObjectivesCtrl: 'assets/js/controllers/loanApp/clientAssess/customerObjectives.js',
    LoanAppDepositCtrl: 'assets/js/controllers/loanApp/clientAssess/deposits.js',
    AddDepositModalCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/addDepositModalCtrl.js',
    LoanAppVideoDemoCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/loanAppVideoDemoCtrl.js',
    LoanAppLoanFundingDetailsCtrl: 'assets/js/controllers/loanApp/clientAssess/loanFundingDetails.js',
    LoanAppProductFinderCtrl: 'assets/js/controllers/loanApp/clientAssess/modal/productFinder.js',
    LoanAppSupportDocsCtrl: 'assets/js/controllers/loanApp/supportDocs/loanAppSupportDocsCtrl.js',

    // Loan Application v3 - Components


    // General Loan
    NewLoanApplicationCtrl: 'assets/js/controllers/newLoanApplicationCtrl.js',

    // General Loan
    NewInsuranceApplicationCtrl: 'assets/js/controllers/newInsuranceApplicationCtrl.js',

    // dashboard
    OverviewDashboardCtrl: 'assets/js/controllers/overviewDashboardCtrl.js',
    AnnouncementModalCtrl: 'assets/js/controllers/announcement/announcementModalCtrl.js',
    fixedRateReviews: 'assets/js/directives/Dashboard/fixedRateReviewsDirective.js',
    fixedRateReviewsTable: 'assets/js/directives/dashboard/fixedRateReviewsTableDirective.js',
    netPromoterScore: 'assets/js/directives/dashboard/netPromoterScoreDirective.js',
    referrals: 'assets/js/directives/dashboard/referrals.js',
    pipelineStatus: 'assets/js/directives/dashboard/pipelineStatusDirective.js',
    upcomingEventsTable: 'assets/js/directives/dashboard/upcomingEventsTableDirective.js',
    providerUpdates: 'assets/js/directives/dashboard/providerUpdatesDirective.js',
    systemUpdates: 'assets/js/directives/dashboard/systemUpdatesDirective.js',
    upcomingBirthdays: 'assets/js/directives/dashboard/upcomingBirthdaysDirective.js',
    clientReviews: 'assets/js/directives/dashboard/clientReviewsDirective.js',
    tasks: 'assets/js/directives/dashboard/tasksDirective.js',
    commissionsReceived: 'assets/js/directives/dashboard/commissionsReceivedDirective.js',
    pipelineStatsChart: 'assets/js/directives/Dashboard/pipelineStatsChartDirective.js',
    leadsChart: 'assets/js/directives/Dashboard/leadsChartDirective.js',
    commissionReceivedChart: 'assets/js/directives/Dashboard/commissionReceivedChartDirective.js',
    pipelineOverview: 'assets/js/directives/Dashboard/pipelineOverviewDirective.js',
    commissionReceivedTable: 'assets/js/directives/Dashboard/commissionReceivedTableDirective.js',
    pipelineStatusTable: 'assets/js/directives/Dashboard/pipelineStatusTableDirective.js',
    outstandingCommission: 'assets/js/directives/Dashboard/outstandingCommissionDirective.js',
    leadsTable: 'assets/js/directives/Dashboard/leadsTableDirective.js',
    tasksTable: 'assets/js/directives/Dashboard/tasksTableDirective.js',
    interestRateUpdatesTable: 'assets/js/directives/Dashboard/interestRateUpdatesDirective.js',
    upcomingBirthdaysSmall: 'assets/js/directives/Dashboard/upcomingBirthdaysSmallDirective.js',
    outstandingCommissionsSmall: 'assets/js/directives/Dashboard/outstandingCommissionsSmallDirective.js',
    clientReviewsSmall: 'assets/js/directives/Dashboard/clientReviewsSmallDirective.js',
    smallWidgetSettings: 'assets/js/directives/Dashboard/smallWidgetSettingsDirective.js',
    bigWidgetSettings: 'assets/js/directives/Dashboard/bigWidgetSettingsDirective.js',
    CustomerCommentModalCtrl: 'assets/js/controllers/dashboard/customerCommentModalCtrl.js',
    CustomerCommentModalSingleCtrl: 'assets/js/controllers/dashboard/customerCommentModalSingleCtrl.js',
    CustomerCommentViewAllModalCtrl: 'assets/js/controllers/dashboard/customerCommentViewAllModalCtrl.js',
    pipelineLodged: 'assets/js/directives/Dashboard/pipelineLodgedDirective.js',
    pipelineApproved: 'assets/js/directives/Dashboard/pipelineApprovedDirective.js',
    pipelineSettled: 'assets/js/directives/Dashboard/pipelineSettledDirective.js',
    pipelineStatusSmall: 'assets/js/directives/Dashboard/pipelineStatusSmallDirective.js',
    preApprovalExpiries: 'assets/js/directives/Dashboard/preApprovalExpiriesDirective.js',
    preApprovalExpiriesSmall: 'assets/js/directives/Dashboard/preApprovalExpiriesSmallDirective.js',


    bindCompiledHtml: 'assets/js/directives/Dashboard/bindCompiledHtml.js',
    SendEmailCtrl: 'assets/js/controllers/contacts/sendEmailCtrl.js',


    // user account
    UserAccountCtrl: 'assets/js/controllers/user/userAccountCtrl.js',
    UserPermissionsCtrl: 'assets/js/controllers/user/permissions.js',
    UserLendersCtrl: 'assets/js/controllers/user/lenders.js',

    // Customize Theme
    CustomThemeCtrl: 'assets/js/controllers/customThemeCtrl.js',

    // Commission
    CommissionCtrl: 'assets/js/controllers/commissionCtrl.js',
    commissionTransCtrl: 'assets/js/controllers/commissionCtrl.js',

    // Expected Commission
    ExpectedCommissionCtrl: 'assets/js/controllers/commission/expectedCommissionCtrl.js',

    // Unmatched Commission
    UnmatchedCommissionCtrl: 'assets/js/controllers/commission/unmatchedCommissionCtrl.js',


    // Onboarding
    OnboardingCtrl: 'assets/js/controllers/onboarding/onboardingCtrl.js',
    EntityDetailsCtrl: 'assets/js/controllers/onboarding/tabs/entityDetailsCtrl.js',
    AsicAppoinmentCtrl: 'assets/js/controllers/onboarding/tabs/asicAppoinmentCtrl.js',
    PrincipalDetailsCtrl: 'assets/js/controllers/onboarding/tabs/principalDetailsCtrl.js',
    RequiredDocumentsCtrl: 'assets/js/controllers/onboarding/tabs/requiredDocumentsCtrl.js',
    AdditionalStaffCtrl: 'assets/js/controllers/onboarding/tabs/additionalStaffCtrl.js',
    IndividualDetailsCtrl: 'assets/js/controllers/onboarding/tabs/individualDetailsCtrl.js',
    OnboardingQuestionaireCtrl: 'assets/js/controllers/onboarding/tabs/onboardingQuestionaireCtrl.js',
    BankDetailsCtrl: 'assets/js/controllers/onboarding/tabs/bankDetailsCtrl.js',
    CompleteOnboardingCtrl: 'assets/js/controllers/onboarding/completeOnboardingCtrl.js',
    AccountAccreditationCtrl: 'assets/js/controllers/onboarding/accountAccreditationCtrl.js',


    // Corporate
    CorporateTargetManagementCtrl: 'assets/js/controllers/corporate/targetManagement/corporateTargetManagementCtrl.js',
    CorporateTargetManagementNewCtrl: 'assets/js/controllers/corporate/targetManagement/targetManagementNewCtrl.js',

    // Help & Support
    BrokerSupportRequestCtrl: 'assets/js/controllers/helpSupport/brokerSupportRequestCtrl.js',
    BrokerViewBrokerSupporCtrl: 'assets/js/controllers/helpSupport/brokerViewBrokerSupporCtrl.js',
    BrokerSupportNewRequestCtrl: 'assets/js/controllers/helpSupport/brokerSupportNewRequestCtrl.js',

    // Communicate
    SatisfactionSurveyCtrl: 'assets/js/controllers/communicate/satisfactionSurveyCtrl.js',
    ResendSurveyModalCtrl: 'assets/js/controllers/communicate/modal/resendSurveyModalCtrl.js',

    // Customer Care
    CustomerCareCtrl: 'assets/js/controllers/customerCare/CustomerCareCtrl.js',
    FixedRateCtrl: 'assets/js/controllers/customerCare/partial/fixedRateCtrl.js',
    PreApprovalCtrl: 'assets/js/controllers/customerCare/partial/preApprovalCtrl.js',
    birthdaysCtrl: 'assets/js/controllers/customerCare/partial/birthdaysCtrl.js',
    AnnualReviewCtrl: 'assets/js/controllers/customerCare/partial/annualReviewCtrl.js',
    PostSettlementCtrl: 'assets/js/controllers/customerCare/partial/postSettlementCtrl.js',

    // Profile Management -> Business
    BusinessCtrl: 'assets/js/controllers/profileManagement/Business/businessCtrl.js',

    // Profile Management -> Users
    UsersCtrl: 'assets/js/controllers/profileManagement/Users/usersCtrl.js',

    // Common
    CommonModalPlaceholderCtrl: 'assets/js/controllers/common/commonModalPlaceholderCtrl.js',

    // 'iconsCtrl': 'assets/js/controllers/iconsCtrl.js',
    // 'vAccordionCtrl': 'assets/js/controllers/vAccordionCtrl.js',
    // 'ckeditorCtrl': 'assets/js/controllers/ckeditorCtrl.js',
    // 'laddaCtrl': 'assets/js/controllers/laddaCtrl.js',
    // 'ngTableCtrl': 'assets/js/controllers/ngTableCtrl.js',
    // 'cropCtrl': 'assets/js/controllers/cropCtrl.js',
    // 'asideCtrl': 'assets/js/controllers/asideCtrl.js',
    // 'toasterCtrl': 'assets/js/controllers/toasterCtrl.js',
    // 'sweetAlertCtrl': 'assets/js/controllers/sweetAlertCtrl.js',
    // 'mapsCtrl': 'assets/js/controllers/mapsCtrl.js',
    // 'chartsCtrl': 'assets/js/controllers/chartsCtrl.js',
    // 'calendarCtrl': 'assets/js/controllers/calendarCtrl.js',
    // 'nestableCtrl': 'assets/js/controllers/nestableCtrl.js',
    // 'validationCtrl': ['assets/js/controllers/validationCtrl.js'],
    // 'userCtrl': ['assets/js/controllers/userCtrl.js'],
    // 'selectCtrl': 'assets/js/controllers/selectCtrl.js',
    // 'wizardCtrl': 'assets/js/controllers/wizardCtrl.js',
    // 'uploadCtrl': 'assets/js/controllers/uploadCtrl.js',
    // 'treeCtrl': 'assets/js/controllers/treeCtrl.js',
    // 'inboxCtrl': 'assets/js/controllers/inboxCtrl.js',
    // 'xeditableCtrl': 'assets/js/controllers/xeditableCtrl.js',
    // 'chatCtrl': 'assets/js/controllers/chatCtrl.js',
    // 'dynamicTableCtrl': 'assets/js/controllers/dynamicTableCtrl.js',
    // 'notificationIconsCtrl': 'assets/js/controllers/notificationIconsCtrl.js',
    // 'dateRangeCtrl': 'assets/js/controllers/daterangeCtrl.js',
    // 'notifyCtrl': 'assets/js/controllers/notifyCtrl.js',
    // 'sliderCtrl': 'assets/js/controllers/sliderCtrl.js',
    // 'knobCtrl': 'assets/js/controllers/knobCtrl.js',
  },
  // *** angularJS Modules
  modules: [{
    name: 'toaster',
    files: ['vendor/AngularJS-Toaster/toaster.js', 'vendor/AngularJS-Toaster/toaster.css'],
  }, {
    name: 'angularBootstrapNavTree',
    files: ['vendor/angular-bootstrap-nav-tree/dist/abn_tree_directive.js', 'vendor/angular-bootstrap-nav-tree/dist/abn_tree.css'],
  }, {
    name: 'ngTable',
    files: ['vendor/ng-table/dist/ng-table.min.js', 'vendor/ng-table/dist/ng-table.min.css'],
  }, {
    name: 'ui.mask',
    files: ['vendor/angular-ui-utils/mask.min.js'],
  }, {
    name: 'ngImgCrop',
    files: ['vendor/ngImgCrop/compile/minified/ng-img-crop.js', 'vendor/ngImgCrop/compile/minified/ng-img-crop.css'],
  }, {
    name: 'angularFileUpload',
    files: ['vendor/angular-file-upload/angular-file-upload.min.js'],
  }, {
    name: 'monospaced.elastic',
    files: ['vendor/angular-elastic/elastic.js'],
  }, {
    name: 'ngMap',
    files: ['vendor/ngmap/build/scripts/ng-map.min.js'],
  }, {
    name: 'chart.js',
    files: ['vendor/angular-chart.js/dist/angular-chart.min.js', 'vendor/angular-chart.js/dist/angular-chart.min.css'],
  }, {
    name: 'flow',
    files: ['vendor/ng-flow/dist/ng-flow-standalone.min.js'],
  }, {
    name: 'ckeditor',
    files: ['vendor/angular-ckeditor/angular-ckeditor.min.js'],
  }, {
    name: 'mwl.calendar',
    files: ['vendor/angular-bootstrap-calendar/dist/js/angular-bootstrap-calendar-tpls.js', 'vendor/angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.min.css', 'assets/js/config/config-calendar.js'],
  }, {
    name: 'ng-nestable',
    files: ['vendor/ng-nestable/src/angular-nestable.js'],
  }, {
    name: 'ngNotify',
    files: ['vendor/ng-notify/dist/ng-notify.min.js', 'vendor/ng-notify/dist/ng-notify.min.css'],
  }, {
    name: 'xeditable',
    files: ['vendor/angular-xeditable/dist/js/xeditable.min.js', 'vendor/angular-xeditable/dist/css/xeditable.css', 'assets/js/config/config-xeditable.js'],
  }, {
    name: 'checklist-model',
    files: ['vendor/checklist-model/checklist-model.js'],
  }, {
    name: 'ui.knob',
    files: ['vendor/ng-knob/dist/ng-knob.min.js'],
  }, {
    name: 'ngAppear',
    files: ['vendor/angular-appear/build/angular-appear.min.js'],
  }, {
    name: 'countTo',
    files: ['vendor/angular-count-to-0.1.1/dist/angular-filter-count-to.min.js'],
  }, {
    name: 'angularSpectrumColorpicker',
    files: ['vendor/spectrum/spectrum.js', 'vendor/spectrum/spectrum.css', 'vendor/angular-spectrum-colorpicker/dist/angular-spectrum-colorpicker.min.js'],
  }, {
    name: 'anguFixedHeaderTable',
    files: ['vendor/angu-fixed-header-table/angu-fixed-header-table.js'],
  }, {
    name: 'ui.select',
    files: ['vendor/angular-ui-select/dist/select.min.js'],
    /*
         * select2 css in being concat and minified with the rest
         * */
  }, {
    name: 'datetimepicker',
    files: ['vendor/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css', 'vendor/eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js', 'vendor/angular-bootstrap-datetimepicker-directive/angular-bootstrap-datetimepicker-directive.min.js'],
  }, {
    name: 'angucomplete',
    files: ['vendor/angucomplete/angucomplete.css', 'vendor/angucomplete/angucomplete.js'],
  }, {
    name: 'ui.tab.scroll',
    files: ['vendor/angular-ui-tab-scroll/angular-ui-tab-scroll.css', 'vendor/angular-ui-tab-scroll/angular-ui-tab-scroll.js'],
  }, {
    name: 'dndLists',
    files: ['vendor/angular-drag-and-drop-lists/angular-drag-and-drop-lists.js'],
  },


    /* , {
          name: 'isteven-multi-select',
          files: ['vendor/isteven-angular-multiselect/isteven-multi-select.css','vendor/isteven-angular-multiselect/isteven-multi-select.js']
          } */
  ],
}];

export default JS_REQUIRES;
