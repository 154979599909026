// Put all the repeated modal open code here please
angular.module('app').factory('modalRenderService', function ($uibModal, $state, crmConfirmation) {
  // check add block
  const factory = {};

  factory.renderPipelineStatusMoveConfirmationModal = function(data) {
    const modalContent = {
      title: 'Are you sure?',
      content: 'This card will now be moved to Not Proceeding',
      ...data,
    }
    return $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: '/assets/views/pipeline/modals/pipelineStatusMoveConfirmation.html',
      windowTopClass: 'not-proceeding-modal',
      controller: 'PipelineStatusMoveConfirmationCtrl',
      size: 'md',
      keyboard: 'static',
      backdrop: false,
      resolve: {
        modalContent
      },
    });
  }

  factory.openMovedToSettledModal = function (size, familyID, LoanScenarioID, cardID, conversionStatus) {
    const $modalInstance = $uibModal.open({
      templateUrl: '/assets/views/pipeline/leads/partials/moved_settled_modal.html',
      controller: 'PipelineAppMoveToSettledModalCtrl',
      resolve: {
        familyID: () => familyID,
        LoanScenarioID: () => LoanScenarioID,
      },
      size,
    });
    return $modalInstance.result;
  };

  factory.cardHiddenInApplication = function(firstContactName) {
    try {
      return crmConfirmation.open({
        type: 'info',
        title: 'Just a heads up',
        description: `This converted opportunity will not show in the application pipeline as the first contact added to this opportunity (${firstContactName}), already has existing cards in the application pipeline.<br/><br/>We\'re working hard to fix this issue, but in the meantime, you can view this and any other applications in the Lending tab of the involved parties.`,
        buttonText: 'Okay, got it!'
      });
    } catch(e) {
      console.log(`Developer message: ${e.message}`);
    }
  }

  factory.openNewOpportunityModal = function(familyId, clients, pipelineItemId) {
    const props = { familyId, clients, pipelineItemId };
    return $uibModal.open({
      animation: true,
      template: `<opportunity-modal
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId"
                    clients="vm.props.clients"
                    pipeline-item-id="vm.props.pipelineItemId">
                  </opportunity-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        props: () => props,
      },
    });
  }

  return factory;
});
