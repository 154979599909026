import template from './opportunityCard.html';

export default {
  template,
  bindings: {
    card: '<',
    isCompact: '<',
    onDeletePipelineItem: '&',
  },
};
