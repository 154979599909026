import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';

angular.module('app').controller('AddressesNewModalCtrl',
  function AddressesNewModalCtrl($scope,
    $uibModal,
    $uibModalInstance,
    modalType,
    optionsService,
    contactService,
    toaster,
    dashboardService,
    contactPropertySet,
    $state,
    moment,
    broadcastDataService,
    $timeout,
    generalService,
    uiService) {
    $scope.isDisplayOtherDetails = false;
    $scope.isDisplayExtendDetails = false;
    $scope.modalType = modalType;
    $scope.familyId = $state.params.familyId;
    $scope.uiService = uiService;

    const OwnersGet = () => {
      contactService.clientInformGet($state.params.familyId).then((response) => {
        const temp = {
          ClientEntityId: -1,
          PersonId: -1,
          FirstName: 'All',
          LastName: 'Owners',
          Role: 'Adult',
          ticked: (modalType !== 'edit'),
          isTicked: (modalType !== 'edit'),
          PreferredName: 'All Owners',
        };

        response.data.unshift(temp);
        const tmp = [];

        if (modalType === 'edit') {
          let selectedOwners = {};

          _.forEach($scope.contactPropertySet.ContactLivingIn, (value) => {
            selectedOwners = {
              ClientEntityId: value.BorrowerID,
              FirstName: value.FirstName,
              LastName: value.LastName,
              PersonId: value.BorrowerID,
              PreferredName: (`${value.FirstName} ` || '') + (value.LastName || ''),
              Role: 'Adult',
              isTicked: true,
              ticked: true,
            };
            $scope.ownersListSelected.push(selectedOwners);
          });

          response.data && Object.keys(response.data).forEach((x) => {
            const value = response.data[x];

            if (value && (value.Role === 'Adult' || value.Role === 'Guarantor')) {
              value.ClientEntityId = value.PersonId;
              value.isTicked = false;
              value.ticked = false;
              const indexFinder = _.filter($scope.ownersListSelected, (obj) => {
                return parseInt(obj.ClientEntityId, 10) === parseInt(value.ClientEntityId, 10);
              });
              if (indexFinder && indexFinder.length > 0) {
                value.isTicked = true;
                value.ticked = true;
              }
              tmp.push(value);
            }
          });
        } else {
          response.data && Object.keys(response.data).forEach((x) => {
            const value = response.data[x];
            if (value) {
              value.ClientEntityId = value.PersonId;
              value.isTicked = (!!value.ticked);
              value.ticked = (!!value.ticked);
              if (value.Role === 'Adult') {
                tmp.push(value);
              }
            }
          });
          $scope.ownersListSelected.push(temp);
        }
        $scope.ownersList = tmp;
      });
    };

    const clientTickSetter = (client) => {
      if (client.PersonId === -1 && !client.isTicked) {
        _.forEach($scope.ownersList, (value) => {
          if (value.PersonId !== client.PersonId) {
            value.isTicked = false;
            value.ticked = false;
          } else {
            value.isTicked = true;
            value.ticked = true;
          }
        });
      } else {
        _.forEach($scope.ownersList, (value) => {
          if (value.PersonId === client.PersonId && value.PersonId !== -1) {
            if (value.isTicked) {
              value.isTicked = false;
              value.ticked = false;
            } else {
              value.isTicked = true;
              value.ticked = true;
            }
          } else if (value.PersonId === -1) {
            value.isTicked = false;
            value.ticked = false;
          }
        });
      }
    };

    if (modalType === 'new') {
      $scope.contactPropertySet = {};
      $scope.contactPropertySet.Ownership = [];
      $scope.contactPropertySet.ContactLivingIn = [];
    } else {
      $scope.contactPropertySet = contactPropertySet;
      $scope.contactPropertySet.OwnershipTypeId = $scope.contactPropertySet && $scope.contactPropertySet.OwnershipTypeId && $scope.contactPropertySet.OwnershipTypeId.toString();
      $scope.contactPropertySet.Address = {};

      angular.extend($scope.contactPropertySet.Address, {
        formatted_address: $scope.contactPropertySet.formatted_address,
        geoCoded: $scope.contactPropertySet.geoCoded,
        latitude: $scope.contactPropertySet.latitude,
        longitude: $scope.contactPropertySet.longitude,
      });

      $scope.contactPropertySet.FullAddress = angular.copy($scope.contactPropertySet.Address);
    }

    $scope.isWithRental = (id) => {
      return (_.find(broadcastDataService.addressWithRentalIds, (o) => {
        return parseInt(o, 10) === parseInt(id, 10);
      }));
    };

    $scope.datePopUp = {
      DischargeDate: false,
      PurchaseDate: false,
      StartDate: false,
      EndDate: false,
    };

    // Open Date Popup
    $scope.openDate = (dateField) => {
      $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
    };

    $scope.lengthOfTime = '0 months';
    $scope.getLengthOfTime = () => {
      let a;
      if (parseInt(contactPropertySet.TypeId, 10) === ADDRESS_TYPE.CURRENT_ADDRESS || !$scope.contactPropertySet.EndDate) {
        a = moment();
      } else {
        a = moment($scope.contactPropertySet.EndDate);
      }
      const b = moment($scope.contactPropertySet.StartDate);

      const years = a.diff(b, 'year');
      b.add(years, 'years');

      const months = a.diff(b, 'months');
      b.add(months, 'months');

      if (years < 0 || months < 0 || Number.isNaN(years) || Number.isNaN(months)) {
        $scope.lengthOfTime = '0 months';
      } else if (parseInt(years, 10) === 0) {
        $scope.lengthOfTime = `${months} months`;
      } else {
        $scope.lengthOfTime = `${years} years - ${months} months`;
      }
    };
    $scope.getLengthOfTime();

    $scope.resetDateDetail = () => {
      $scope.contactPropertySet.StartDate = undefined;
      $scope.contactPropertySet.EndDate = undefined;
      $scope.lengthOfTime = '0 months';
    };

    $scope.format = 'dd MMM yyyy';
    $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };

    $scope.toggleOtherDetails = () => {
      $scope.isDisplayOtherDetails = !$scope.isDisplayOtherDetails;
    };
    $scope.toggleExtendDetails = () => {
      $scope.isDisplayExtendDetails = !$scope.isDisplayExtendDetails;
    };
    $scope.toggleOwnerShipDetails = () => {
      $scope.isOwnerShipDetails = !$scope.isOwnerShipDetails;
    };
    $scope.isAddressDetails = true;
    $scope.toggleAddressDetails = () => {
      $scope.isAddressDetails = !$scope.isAddressDetails;
    };


    $scope.addressesModalHeader = modalType === 'new' ? 'New Address' : 'Edit Address';

    // format discharge date and purchase date
    if (modalType === 'edit') {
      if (typeof $scope.contactPropertySet.StartDate !== 'undefined') {
        $scope.contactPropertySet.StartDate = new Date($scope.contactPropertySet.StartDate);
      }

      if (typeof $scope.contactPropertySet.EndDate !== 'undefined') {
        $scope.contactPropertySet.EndDate = new Date($scope.contactPropertySet.EndDate);
      }
    }

    function getContactLivingIn(data) {
      return {
        BorrowerID: parseInt(data.ClientEntityId, 10),
        FirstName: data.FirstName,
        LastName: data.LastName,
        IsInclude: true,
        IsEntity: false,
        IncludeGuarantor: 0,
      };
    }

    $scope.saveInstance = 0;
    $scope.saveBtnPressed = false;

    $scope.save = (form) => {
      $scope.saveBtnPressed = true;
      if (form.$valid) {
        $scope.saveInstance++;
        if ($scope.saveInstance === 1) {
          if (angular.isUndefined($scope.ownersListSelected)) {
            toaster.pop(
              'error',
              'Owners',
              'No owners found'
            );
            return false;
          }
          if (!_.isUndefined($scope.ownersListSelected[0])) {
            if ($scope.ownersListSelected[0].ClientEntityId === -1) { // loop all owners
              $scope.ownersList.forEach((data) => {
                if (data.ClientEntityId !== -1) {
                  if (!_.isUndefined($scope.contactPropertySet.Ownership)) {
                    $scope.contactPropertySet.Ownership.push(data);
                  }

                  $scope.contactPropertySet.ContactLivingIn.push(getContactLivingIn(data));
                }
              });
            } else {
              /**
               * loop owners selected
               * */
              const contactsLivingIn = [];
              $scope.ownersListSelected.forEach((data) => {
                if (!_.isUndefined($scope.contactPropertySet.Ownership)) {
                  $scope.contactPropertySet.Ownership.push(data);
                }
                contactsLivingIn.push(getContactLivingIn(data));
              });
              $scope.contactPropertySet.ContactLivingIn = contactsLivingIn;
            }
          }


          /**
           * will set ownership
           * */
          $scope.contactPropertySet.FullAddress = {};
          if ($scope.contactPropertySet.OwnershipTypeId) {
            $scope.contactPropertySet.FullAddress.OwnershipTypeId = $scope.contactPropertySet.OwnershipTypeId;
          }
          if (modalType === 'new') {
            /**
             * the user might be adding a po box address
             * */
            if (!Object.prototype.hasOwnProperty.call($scope.contactPropertySet.FullAddress, 'formatted_address')) {
              const fullAddressObject = {
                OwnershipTypeId: $scope.contactPropertySet.OwnershipTypeId,
                formatted_address: $scope.contactPropertySet.Address.formatted_address,
                geoCoded: false,
              };
              Object.assign($scope.contactPropertySet.FullAddress, fullAddressObject);
            }
            dashboardService.addressDetailsSet($scope.contactPropertySet.FullAddress).then((response) => {
              $scope.contactPropertySet.AddressID = response.data;
              const { NumberOfYears, NumberOfMonths } = $scope.contactPropertySet;
              const obj = {
                FamilyId: $state.params.familyId,
                AddressID: $scope.contactPropertySet.AddressID,
                IsMailing: false,
                CategoryId: $scope.contactPropertySet.CategoryId,
                FloorTypeId: $scope.contactPropertySet.FloorTypeId,
                TitleTypeId: $scope.contactPropertySet.TitleTypeId,
                TenureTypeId: $scope.contactPropertySet.TenureTypeId,
                OwnershipTypeId: $scope.contactPropertySet.OwnershipTypeId,
                TypeId: $scope.contactPropertySet.TypeId,
                StartDate: moment($scope.contactPropertySet.StartDate).format('DD/MMM/YYYY'),
                EndDate: ($scope.contactPropertySet.EndDate ? moment($scope.contactPropertySet.EndDate).format('DD/MMM/YYYY') : null),
                ContactLivingIn: $scope.contactPropertySet.ContactLivingIn,
                AddressValuationExtendedDetailsId: 0,
                RentalAmount: $scope.contactPropertySet.RentalAmount,
                FrequencyTypeId: $scope.contactPropertySet.FrequencyTypeId,
                NumberOfYears: NumberOfYears && parseInt(NumberOfYears, 10),
                NumberOfMonths: NumberOfMonths && parseInt(NumberOfMonths, 10),
              };

              angular.extend(obj, $scope.contactPropertySet.Address);

              contactService.contactAddressSet([obj]).then(() => {
                toaster.pop('success', 'Added', 'New address successfully added');
                $scope.cancel();
              });
            });
          } else {
            $scope.contactPropertySet.FullAddress = angular.copy($scope.contactPropertySet.Address);
            $scope.contactPropertySet.FullAddress.AddressValuationExtendedDetailsId = $scope.contactPropertySet.AddressValuationExtendedDetailsId;
            dashboardService.addressDetailsSet($scope.contactPropertySet.FullAddress).then((response) => {
              $scope.contactPropertySet.FullAddress.AddressID = response.data;
              const { NumberOfYears, NumberOfMonths } = $scope.contactPropertySet;
              const obj = {
                FamilyId: $state.params.familyId,
                AddressID: $scope.contactPropertySet.FullAddress.AddressID,
                IsMailing: false,
                CategoryId: $scope.contactPropertySet.CategoryId,
                FloorTypeId: $scope.contactPropertySet.FloorTypeId,
                TitleTypeId: $scope.contactPropertySet.TitleTypeId,
                TenureTypeId: $scope.contactPropertySet.TenureTypeId,
                OwnershipTypeId: $scope.contactPropertySet.OwnershipTypeId,
                TypeId: $scope.contactPropertySet.TypeId,
                StartDate: moment($scope.contactPropertySet.StartDate).format('DD/MMM/YYYY'),
                EndDate: ($scope.contactPropertySet.EndDate ? moment($scope.contactPropertySet.EndDate).format('DD/MMM/YYYY') : null),
                ContactLivingIn: $scope.contactPropertySet.ContactLivingIn,
                AddressValuationExtendedDetailsId: $scope.contactPropertySet.AddressValuationExtendedDetailsId,
                RentalAmount: $scope.contactPropertySet.RentalAmount,
                FrequencyTypeId: $scope.contactPropertySet.FrequencyTypeId,
                NumberOfYears: NumberOfYears && parseInt(NumberOfYears, 10),
                NumberOfMonths: NumberOfMonths && parseInt(NumberOfMonths, 10),
              };

              angular.extend(obj, $scope.contactPropertySet.FullAddress);

              contactService.contactAddressSet([obj]).then(() => {
                toaster.pop(
                  'success',
                  'Edited',
                  'Address successfully edited'
                );
                $scope.cancel();
              });
            });
          }
        }
      } else {
        $scope.saveBtnPressed = false;
        toaster.pop(
          'warning',
          'Required fields',
          'Please fill in all fields'
        );
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.propertyFieldChecker = (value) => {
      return !value;
    };

    $scope.AddressTypeGet = () => {
      optionsService.addressTypeGet().then((response) => {
        $scope.addressTypeList = response.data;
        if (modalType === 'edit') {
          if ($scope.propertyFieldChecker($scope.contactPropertySet.TypeId)) {
            $scope.contactPropertySet.TypeId = ($scope.addressTypeList[0] && $scope.addressTypeList[0].Value) || null;
          }
        }
      });
    };

    $scope.OwnershipTypeGet = () => {
      optionsService.ownershipTypeGet().then((response) => {
        $scope.ownershipTypeList = response.data;
      });
    };

    $scope.PropertyTypeGet = () => {
      optionsService.propertyTypeGet().then((response) => {
        $scope.propertyTypeList = response.data;

        if ($scope.propertyFieldChecker($scope.contactPropertySet.CategoryId)) {
          $scope.contactPropertySet.CategoryId = ($scope.propertyTypeList[0] && $scope.propertyTypeList[0].Value) || null;
        }
      });
    };

    $scope.FloorTypeGet = () => {
      optionsService.floorTypeGet().then((response) => {
        $scope.floorTypeList = response.data;

        if ($scope.propertyFieldChecker($scope.contactPropertySet.FloorTypeId)) {
          $scope.contactPropertySet.FloorTypeId = ($scope.floorTypeList[0] && $scope.floorTypeList[0].Value) || null;
        }
      });
    };

    $scope.TitleTypeGet = () => {
      optionsService.titleTypeGet().then((response) => {
        $scope.titleTypeList = response.data;

        if ($scope.propertyFieldChecker($scope.contactPropertySet.TitleTypeId)) {
          $scope.contactPropertySet.TitleTypeId = ($scope.titleTypeList[0] && $scope.titleTypeList[0].Value) || null;
        }
      });
    };

    $scope.TenureTypeGet = () => {
      optionsService.tenureTypeGet().then((response) => {
        $scope.tenureTypeList = response.data;

        if ($scope.propertyFieldChecker($scope.contactPropertySet.TenureTypeId)) {
          $scope.contactPropertySet.TenureTypeId = ($scope.tenureTypeList[0] && $scope.tenureTypeList[0].Value) || null;
        }
      });
    };


    $scope.existingAddressEmpty = false;
    $scope.existingAddressSearch = false;
    $scope.fullAutoAddressDetailsShow = false;
    $scope.showHideExistAddressResult = (status) => {
      $scope.existingAddressSearch = !status;
      $scope.fullAutoAddressDetailsShow = false;
    };
    $scope.getExistAddress = () => {
      contactService.contactAddressGet($state.params.familyId).then((response) => {
        $scope.existingAddress = _.uniqBy(response.data, address => address.AddressID);
      });
    };

    // init
    $scope._init = () => {
      $scope.addressTypeList = [];
      $scope.ownersList = [];
      $scope.ownershipTypeList = [];
      $scope.propertyTypeList = [];
      $scope.floorTypeList = [];
      $scope.titleTypeList = [];
      $scope.tenureTypeList = [];

      $scope.AddressTypeGet();
      OwnersGet();
      $scope.OwnershipTypeGet();
      $scope.PropertyTypeGet();
      $scope.FloorTypeGet();
      $scope.TitleTypeGet();
      $scope.TenureTypeGet();
      $scope.getExistAddress();

      $scope.listRentalFrequency = [];
      optionsService.FinancialFrequency().then((response) => {
        _.map(response.data, (o) => {
          o.Desc = o.Name;
          o.Value = parseInt(o.Value, 10);

          return o;
        });

        $scope.listRentalFrequency = response.data;
      });
    };

    $scope._init();


    $scope.fullAutoAddress = () => {
      $scope.closeAutoFullAddress();
      $scope.existingAddressSearch = false;
      $timeout.cancel($scope.searchTimeout);
      if (_.isUndefined($scope.contactPropertySet.Address)) {
        $scope.contactPropertySet.Address = {};
      }
      $scope.contactPropertySet.Address.geoCoded = false;
      $scope.contactPropertySet.Address.latitude = 0;
      $scope.contactPropertySet.Address.longitude = 0;
      $scope.searchTimeout = $timeout(() => {
        if (_.isUndefined($scope.contactPropertySet.Address)) {
          $scope.contactPropertySet.Address = {};
        }
        generalService.placeSearch($scope.contactPropertySet.Address.formatted_address).then((respond) => {
          $scope.fullAutoAddressDetails = respond.data;
          $scope.fullAutoAddressDetailsShow = $scope.fullAutoAddressDetails && $scope.fullAutoAddressDetails.length >= 1;
        });
      }, 500);
    };

    $scope.selectFullAddress = (fullAddress) => {
      const excludeProp = [
        'AddressID',
        'AddressValuationExtendedDetailsId',
        'OwnershipType',
        'OwnershipTypeId',
        'StartDate',
        'Type',
        'TypeId',
        'ContactLivingIn',
      ];

      const newFullAddress = Object.keys(fullAddress).reduce((accum, prop) => {
        if (excludeProp.indexOf(prop) === -1) {
          accum[prop] = fullAddress[prop];
        }
        return accum;
      }, {});

      $scope.existingAddressSearch = false;
      $scope.contactPropertySet.FullAddress = newFullAddress;
      $scope.contactPropertySet.Address = angular.copy(newFullAddress);
      $scope.closeAutoFullAddress();
    };

    $scope.closeAutoFullAddress = () => {
      $scope.fullAutoAddressDetails = [];
    };

    //* ************************************/
    //  owners multi Select                /
    //* ************************************/
    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel'>No Owner Selected</div>", // default-label is deprecated and replaced with this.
    };
    $scope.ownersList = [];
    $scope.ownersListSelected = [];


    $scope.onItemSelect = (data) => {
      clientTickSetter(data);
    };

    $scope.ownersListSelectedArray = [];

    $scope.onSelectAll = () => {
      for (let i = 0; i < $scope.ownersList.length; i++) {
        if ($scope.ownersList[i].ClientEntityId > -1) {
          $scope.ownersList[i].ticked = false;
        } else {
          $scope.ownersList[i].ticked = true;
        }
      }
    };

    $scope.$watch('ownersListSelected', () => {
      $('.hide-in-mobile .multiSelect').nextAll('div.buttonLabel').remove();
      if ($scope.ownersListSelected.length >= 3) {
        $('.multiSelect .buttonLabel:last-child').html(
          `${$scope.ownersListSelected.length - 2} more ...` +
                    `<span class="caret"></span>`
        );
      }
      $('.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))').each((e) => {
        $(e.currentTarget).html(`${$(e.currentTarget).text()}<i class="fa fa-close"></i> <span class="caret"></span>`);
      });
    });
  });
