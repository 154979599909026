import angular from 'angular';

angular.module('app').controller('EditPermissionCtrl',
  function EditPermissionCtrl(
    $scope,
    $stateParams,
    userService,
    configService,
    $timeout,
    toaster,
    $uibModalInstance,
    $uibModal,
    accountService
  ) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.updateUserInfo = function () {
      const data = [{
        AdminFamilyId: $scope.editUser.AdminFamilyId,
        AdviserFamilyId: $scope.editUser.AdviserFamilyId,
        AvatarDocumentId: $scope.editUser.AvatarDocumentId,
        FirstName: $scope.editUser.FirstName,
        UserName: $scope.editUser.UserName,
      }];
      userService.UserPermissionSet(data).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          toaster.pop('success', 'User Updated', 'User info has been updated');
        }
      });
    };


    $scope.UserPermissionSet = function (user) {
      const data = [{
        AdminFamilyId: user.AdminFamilyId,
        AdviserFamilyId: user.AdviserFamilyId,
        IsCommissionAccess: user.IsCommissionAccess,
        IsUserAccess: user.IsUserAccess,
      }];
      userService.UserPermissionSet(data).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          toaster.pop('success', 'User Updated', 'User info has been updated');
        }
      });
    };


    $scope.editPermissionInterface = {};
    $scope.uploadCount = 0;
    $scope.success = false;
    $scope.error = false;
    $scope.familyId = $stateParams.familyId;
    $scope.hideDocumentProgress = false;
    $scope.editUserDocumentProgressVisibility = function (visibility) {
      $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
    };
    $scope.openAvatarModal = function (obj) {
      $scope.documentDataObject = obj;

      $uibModal.open({
        templateUrl: '/assets/views/user/partials/add_image_modal.html',
        controller: 'MyAccountModalCtrl',
        scope: $scope,
      });
    };

    $scope.$on('$dropletReady', () => {
      if ($scope.editPermissionInterface) {
        $scope.editPermissionInterface = accountService.accountDropletReadyOptions($scope.editPermissionInterface);
      }
    });

    $scope.$on('$dropletFileAdded', () => {
      if (!$scope.editPermissionInterface.isUploading()) {
        $scope.editUserDocumentProgressVisibility(1);
        $scope.editPermissionInterface.uploadFiles();
      }
    });

    $scope.$on('$dropletSuccess', () => {
      if ($scope.editPermissionInterface.isReady) {
        $scope.editPermissionInterface.uploadFiles();
      }
    });

    // Listen for when the files have been successfully uploaded.
    $scope.$on('$dropletSuccess', (event, response, files) => {
      const documentObj = response[0];
      $scope.uploadCount = files.length;
      $scope.success = true;
      if ($scope.infoType === 'personal') {
        $scope.openAvatarModal({
          Title: documentObj.Name,
          DocumentID: documentObj.DocumentId,
          FamilyID: $scope.AdviserFamilyId,
        });
      }
      $scope.AvatarID = documentObj.DocumentId;

      $scope.updateUserAvatar($scope.AvatarID);

      // Hides the upload progressbar
      $scope.editUserDocumentProgressVisibility(0);

      $timeout(() => {
        $scope.success = false;
      }, 5000);
    });

    // Listen for when the files have failed to upload.
    $scope.$on('$dropletError', () => {
      $scope.error = true;
    });


    $scope.updateUserAvatar = function (AvatarID) {
      $scope.editUser.AvatarDocumentId = AvatarID;
      $scope.updateUserInfo();
    };
  });
