import angular from 'angular';

class quickLMICalculatorModalCtrl {
  constructor(
    $timeout,
    $state,
    toaster,
    $uibModalInstance,
    loanProfilerService,
    modalObj
  ) {
    'ngInject';

    this.timeout = $timeout;
    this.state = $state;
    this.uibModalInstance = $uibModalInstance;
    this.toaster = toaster;
    this.loanProfilerService = loanProfilerService;
    this.modalObj = modalObj;
  }

  countDown() {
    this.timeout(() => {
      this.timer--;
      if (!this.timer) {
        if (this.modalObj.status) {
          this.state.go('app.loanProfiler', { brokerEventId: this.brokerEventId, loanScenarioId: 0, tab: 'productFinder' });
        } else {
          this.state.go('app.fundingCalculator', { brokerEventId: this.modalObj.brokerEventId });
        }
        this.uibModalInstance.close(true);
      } else {
        this.countDown();
      }
    }, 1000);
  }

  setProductFinder(itemObj) {
    const postData = {
      GeneralSetting: {
        LVR: this.getLVRValue(),
        Lender: this.newLenderList,
        loanAmount: itemObj.loanAmount,
        security: itemObj.securityAmount,
        loanTerm: 30,
        splitLoan: false,
      },
    };
    this.loanProfilerService.updateServiceabilityFilteringProductsSettings(this.brokerEventId, postData)
      .then((response) => {
        if (!response || response.data) {
          this.toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      });
  }

  getLVRValue() {
    const { loanAmount, securityAmount } = this.modalObj;
    const newValue = (loanAmount / securityAmount) * 100;
    let lvrValue = `0`;
    if (loanAmount && securityAmount) {
      lvrValue = `+${newValue.toFixed(2)}`;
    } else {
      lvrValue = `0`;
    }
    return lvrValue;
  }

  setLenderValue(lenderList) {
    this.newLenderList = [];
    lenderList.map((itemGet) => {
      this.newLenderList.push(`${itemGet.LenderId}`);
      return itemGet;
    });
  }

  $onInit() {
    this.timer = 3;
    this.countDown();
    if (this.modalObj.status) {
      this.modalMessage = 'Product Finder';
      this.brokerEventId = this.modalObj.brokerEventIdForProductFinder;
      this.setLenderValue(this.modalObj.selectedLender);
      this.setProductFinder(this.modalObj);
    } else {
      this.modalMessage = 'Funding Calculator';
    }
  }
}

angular.module('app').controller('QuickLMICalculatorModalCtrl', quickLMICalculatorModalCtrl);
