import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import moment from 'moment';
import {
  displayError,
  toastError,
} from 'Common/utilities/alert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular.module('app').controller('BusinessCtrl',
  function BusinessCtrl($scope,
    $uibModal,
    $window,
    $location,
    $state,
    $stateParams,
    toaster,
    $timeout,
    $rootScope,
    $filter,
    $localStorage,
    uiService,
    businessService,
    optionsService,
    contactService,
    currentUserService,
    contactModelService) {
    $scope.tempObject = {};
    $scope.isShowDataDiv = true;

    const populateActiveUserId = () => {
      contactService.getUserInfo()
        .then((response) => {
          if (!response || !response.data) return;

          $scope.activeUserId = response.data.FamilyId;
        });
    };

    const taskOrderChanged = (event) => {
      if (!event) return;

      const {
        sortableScope,
        itemScope,
      } = event.source;
      const data = [];

      sortableScope.modelValue.forEach((task, key) => {
        data.push({
          TaskSettingsId: task.TaskSettingsID,
          Orderby: key,
        });
      });

      businessService.taskOrderSet(itemScope.modelValue.TaskTemplateID, data);
    };
    const taskDragging = (event) => {
      event.source.itemScope.element.addClass('business-template-task-dragging');
    };


    $scope.templateDragListener = {
      accept(sourceItemHandleScope, destSortableScope) {
        return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
      },
      orderChanged(event) {
        taskOrderChanged(event);
      },
      dragStart(event) {
        taskDragging(event);
      },
      clone: false,
      allowDuplicates: false,
      additionalPlaceholderClass: 'task-item',
    };
    // For Open Tab
    $scope.selectTab = selectedTab => uiService.selectTab(selectedTab);

    $scope.selectTab('task_templates');
    $scope.selectedTab = 'task_templates';

    $scope.DueDateOptions = {
      DateCreated: '1',
      RelativeToDate: '2',
      RelativeToAnotherTask: '3',
    };

    $scope.currentIndex = 0;
    $scope.slickConfig = {
      method: {},
      enabled: true,
      autoplay: false,
      draggable: false,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 300,
      swipe: true,
      swipeToSlide: true,
      event: {
        afterChange(event, slick, currentSlide) {
          $scope.templateIdInMobile = 0;
          $scope.currentIndex = currentSlide;
          const itemMobile = $scope.taskTemplateDetailList[$scope.currentIndex];
          if (itemMobile) {
            $scope.templateIdInMobile = itemMobile.TaskTemplateID;
          }
        },
        init(event, slick) {
          slick.slickGoTo($scope.currentIndex); // slide to correct index when init
        },
      },
    };


    // Tab Selection
    $scope.selectTabUpdate = (tab) => {
      $scope.selectedTab = tab;
    };

    // Filters
    $scope.filterInitial = (...args) => {
      let i;
      let initials = '';
      for (i = 0; i < args.length; i++) {
        if (!_.isNil(args[i]) && _.isString(args[i])) {
          initials = `${initials}${args[i].charAt(0).toUpperCase()}`;
        }
      }
      return initials;
    };

    // Get Template Details
    //--------------------------------------------------------------------------------------
    $scope.taskTemplateDetailList = [];
    $scope.getTaskTemplateListFullDetails = () => {
      businessService.TaskTemplateListFullDetailsGet().then((response) => {
        $scope.taskTemplateDetailList = response.data;
        $scope.isShowDataDiv = true;
      });
    };
    $scope.getTaskTemplateListFullDetails();

    $scope.dueDateTypes = [];
    optionsService.getDueDateTypes()
      .then((response) => {
        $scope.dueDateTypes = (response && response.data) || '';
      });

    $scope.setTaskTemplate = (templateId, taskTemplate) => {
      taskTemplate.isInvalidTemplateName = false;
      if (!taskTemplate.TaskTemplateName) {
        $timeout(() => {
          taskTemplate.isInvalidTemplateName = true;
        }, 100);
        return;
      }
      $scope.isShowDataDiv = false;
      taskTemplate.isEditMode = false;
      const postData = {
        TemplateID: templateId,
        TemplateName: taskTemplate.TaskTemplateName,
      };
      businessService.TaskTemplateSet(postData).then((response) => {
        if (parseInt(response.data, 10) === 1) {
          if (parseInt(templateId, 10) === 0) {
            $scope.tempObject.isShowAddTemplateDiv = false;
            $scope.tempObject.TaskTemplateName = '';
            toaster.pop('success', 'Added', 'Template added successfully');
          } else {
            toaster.pop('success', 'Updated', 'Template updated successfully');
          }

          $scope.getTaskTemplateListFullDetails();
        } else
          toastError();
      });
    };

    $scope.deleteTaskTemplate = (templateId) => {
      $scope.isShowDataDiv = false;
      swal({
        title: 'Are you sure?',
        text: 'This template will be removed from your template list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          businessService.TaskTemplateDelete(templateId)
            .then(() => {
              swal('Deleted', 'Template delete successfully', 'success');
              $scope.getTaskTemplateListFullDetails();
            })
            .catch(displayError);
        } else {
          $scope.isShowDataDiv = true;
        }
      });
    };

    $scope.deleteTemplateTask = function (templateId, taskId, $event) {
      $scope.isShowDataDiv = false;
      swal({
        title: 'Are you sure?',
        text: 'This task will be removed from your template task list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          businessService.TaskSettingsDelete(templateId, taskId)
            .then(() => {
              swal('Deleted', 'Task delete successfully', 'success');
              $scope.getTaskTemplateListFullDetails();
            })
            .catch(displayError);
        } else {
          $scope.isShowDataDiv = true;
        }
      });
      $event.stopPropagation();
    };

    $scope.hideMainDiv = () => {
      $scope.isShowDataDiv = false;
    };
    $scope.openModalForAddEditTaskInTemplate = (taskId, templateId) => {
      $scope.taskId = taskId;
      $scope.templateId = templateId;
      $uibModal.open({
        templateUrl: 'assets/views/profileManagement/Business/modals/add_edit_task.html',
        controller($uibModalInstance) {
          $scope.taskSet = {
            DueDate: moment().format('D MMM YYYY h:mma'),
            FirstName: '',
            LastName: '',
            DueDateOptionID: '1',
            IsAutoAssigned: true,
          };
          if (!$scope.taskId) {
            $scope.taskSet.AssignedAdvisorID = $scope.activeUserId;
          }
          $scope.otherTemplateTasks = [];

          const taskTemplateData = _.find($scope.taskTemplateDetailList, item => item.TaskTemplateID === $scope.templateId) || null;
          let taskSettingData = {};

          if (taskTemplateData && taskTemplateData.TaskSettingsList) {
            taskSettingData = _.find(taskTemplateData.TaskSettingsList, item => item.TaskSettingsID === $scope.taskId) || {};
            $scope.otherTemplateTasks = _.filter(taskTemplateData.TaskSettingsList, item => item.TaskSettingsID !== $scope.taskId) || [];
          }

          const {
            TaskName,
            AssignedAdvisorID,
            IsAutoAssigned,
            DueDate,
            FirstName,
            LastName,
            DueDateOptionID,
            RelativeTaskSettingsID,
            RelativeNoOfDays,
          } = taskSettingData;

          if ($scope.taskId) {
            if (taskSettingData) {
              $scope.taskSet.Title = TaskName;
              $scope.taskSet.AssignedAdvisorID = AssignedAdvisorID;
              $scope.taskSet.IsAutoAssigned = AssignedAdvisorID ? IsAutoAssigned : true;
              $scope.taskSet.DueDate = DueDate;
              $scope.taskSet.FirstName = FirstName;
              $scope.taskSet.LastName = LastName;

              if (DueDateOptionID) {
                $scope.taskSet.DueDateOptionID = DueDateOptionID.toString();
                $scope.taskSet.RelativeTaskSettingsID = RelativeTaskSettingsID;
                $scope.taskSet.RelativeNoOfDays = RelativeNoOfDays;
              }
            }
          }
          $scope.cancel = () => {
            $uibModalInstance.dismiss('cancel');
          };
          $scope.isCorporateUser = currentUserService.accessType === ACCESS_TYPE.CORPORATE;
          $scope.isNzBased = COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
          const isLoanWriter = $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
          contactModelService.taskAdviserListGet(isLoanWriter).then((response) => {
            $scope.taskAdviserList = response.data;
            $scope.taskAdviserList = _.reject(response.data, ['BrokerID', 0]);
          });

          const proceedSave = () => {
            $scope.hideMainDiv();
            const postData = {
              TaskSettingsID: $scope.taskId,
              TaskTemplateID: $scope.templateId,
              TaskName: $scope.taskSet.Title,
              AssignedAdvisorID: $scope.taskSet.AssignedAdvisorID,
              IsAutoAssigned: $scope.taskSet.IsAutoAssigned,
              DueDate: $scope.taskSet.DueDate,
              FirstName: $scope.taskSet.FirstName,
              LastName: $scope.taskSet.LastName,
              DueDateOptionID: $scope.taskSet.DueDateOptionID,
              RelativeTaskSettingsID: $scope.taskSet.RelativeTaskSettingsID,
              RelativeNoOfDays: $scope.taskSet.RelativeNoOfDays,
            };
            businessService.TaskSettingsSet(postData)
              .then((response) => {
                if (parseInt(response.data, 10)) {
                  if (postData.TaskSettingsID) {
                    toaster.pop('success', 'Updated', 'Task updated successfully');
                  } else {
                    toaster.pop('success', 'Added', 'Task added successfully');
                  }
                  $scope.getTaskTemplateListFullDetails();
                  $scope.cancel();
                } else
                  toaster.pop('error', 'Error', 'Yikes! Something is wrong');
              }, () => {
                toaster.pop('error', 'Error', `Could not update Task Settings (Looping)`);
              });
          };

          $scope.saveTemplateTask = function () {
            if (!$scope.taskSet.Title) {
              toaster.pop('error', 'Error', 'Please create a valid task');
            } else {
              proceedSave();
            }
          };
        },
        scope: $scope,
        windowClass: 'view-task-window',
      });
    };

    $scope.newZealandOnly = () => {
      return uiService.isCountry('New Zealand');
    };

    const init = () => {
      populateActiveUserId();
    };
    init();
  });
