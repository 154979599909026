export function adviserAssistantsBuilderForUI(assistant) {
  return {
    assistantId: parseInt(assistant.Value, 10),
    assistantName: assistant.Name,
  };
}

export function businessTypesBuilderForUI(type) {
  return {
    name: type.Name,
    value: parseInt(type.Value, 10),
  };
}
