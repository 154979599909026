import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';
import { REFERRAL_PAYMENT_TYPE } from 'Common/constants/referralPaymentType';
import { AGREEMENT_REFERRAL_TYPE } from 'Common/constants/referralType';
import { BUSINESS_TYPES } from 'Common/constants/businessTypes';
import { AGREEMENT_TYPE, REQUEST_ID } from 'Common/constants/agreementType';
import { ALLOWED_ACTION } from 'Common/constants/accessPermission';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular.module('app').controller('NewReferralCtrl',
  function NewReferralCtrl($scope,
    $http,
    SweetAlert,
    configService,
    $filter,
    referralsService,
    $timeout,
    $uibModal,
    $stateParams,
    contactService,
    toaster,
    $q,
    $state,
    brokerSupportService,
    dashboardService,
    corporateService,
    userService,
    generalService,
    optionsService,
    currentUserService,
    broadcastDataService,
    corporatePermissionService,
    corporateModelService,
    referralService,
    crmConfirmation,
    $window) {
    $scope.referrerModel = {};
    $scope.referrerModel.existingReferrers = [];
    $scope.referrerModel.isBrokerEmpty = false;
    $scope.referrerModel.isAddressGeocoded = false;
    $scope.referrerModel.isRequesting = false;
    $scope.referrerModel.addressPropertyObj = {};
    $scope.generatedRefString = {};
    $scope.generatedRefString.loanMarket = null;
    $scope.referralType = Number($stateParams.referralType);
    $scope.agreementId = Number($stateParams.agreementId);
    $scope.agreementTypeId = Number($stateParams.agreementTypeId);
    $scope.requestId = Number($stateParams.requestId);
    $scope.referralTypeName = '';
    $scope.currentCommissionOpened = 1;
    $scope.isNewDirector = false;
    $scope.isAgreementEditable = false;
    $scope.referrerModel.existingAdviserList = [];
    $scope.businessTypeConstant = BUSINESS_TYPES;
    const exemptedAccessForDateRequested = currentUserService.accessType === ACCESS_TYPE.CORPORATE && currentUserService.corporateTeamId === CORPORATE_TEAM.A_AND_O;
    $scope.setNewDirector = function () {
      $scope.isNewDirector = !$scope.isNewDirector;
      $scope.addDirector();
    };
    $scope.isNewReferrer = false;
    $scope.setNewReferrer = function () {
      $scope.isNewReferrer = !$scope.isNewReferrer;
      $scope.addReferrer();
    };
    const getCallingCode = (countryId) => {
      $scope.callingCode = '00';
      contactService.getOperatingCountry().then((response) => {
        const countryList = response.data;
        if (countryList && countryList.length) {
          const filteredList = countryList.splice(0, 3);
          filteredList.forEach((country) => {
            if (parseInt(country.CountryId, 10) === parseInt(countryId, 10)) {
              $scope.callingCode = country.CallingCode;
            }
          });
        }
      });
    };
    $scope.referrerModel.preferredAdviserList = [];
    const getAgreementPreferredAdvisers = (referrerOrgId, adviserOrgId) => {
      const params = {
        referrerOrgId,
        adviserOrgId,
        agreementTypeId: $scope.agreementTypeId,
      };
      return corporateModelService.getAgreementPreferredAdvisers(params)
        .then((advisers) => {
          if (!advisers || !advisers.length) return;
          $scope.referrerModel.preferredAdviserList = _.uniqBy(advisers, 'adviserId');
          $scope.referrerModel.preferredAdviserListRaw = [...$scope.referrerModel.preferredAdviserList];
          return $scope.referrerModel.preferredAdviserList;
        });
    };
    const processPreferredAdvisers = () => {
      const adviserOrg = $scope.orgOwnerGroupList && $scope.orgOwnerGroupList.find(x => x.OrganizationID === $scope.newReferrer.pAdvisorOrgID);
      const checkedAdviserList = $scope.orgMembersList && $scope.orgMembersList.filter(x => x.IsChecked);
      const isOwnerGroupAvailable = adviserOrg && adviserOrg.OrganizationID && checkedAdviserList && checkedAdviserList.length;
      if (!isOwnerGroupAvailable) {
        $scope.referrerModel.preferredAdviserList = [...$scope.referrerModel.preferredAdviserListRaw];
        return;
      }
      const processAdviser = checkedAdviserList.reduce((accum, value) => {
        const existingAdviser = $scope.referrerModel.preferredAdviserListRaw && $scope.referrerModel.preferredAdviserListRaw.filter(x => x.adviserId === value.FamilyID);
        if (existingAdviser && existingAdviser.length) return accum;
        const tempAdviser = {
          agreementId: 0,
          adviserId: value.FamilyID,
          adviserName: value.MemberName,
          adviserOrganizationName: adviserOrg.OrganizationName,
          referrerOrgPreferredAdviserId: $scope.newReferrer.pAdvisorOrgID,
          preferredByReferrerIds: '',
        };
        accum.push(tempAdviser);
        return accum;
      }, []);
      $scope.referrerModel.preferredAdviserListRaw = $scope.referrerModel.preferredAdviserListRaw || [];
      $scope.referrerModel.preferredAdviserList = [...$scope.referrerModel.preferredAdviserListRaw, ...processAdviser];
    };
    const processOrgAdvisers = () => {
      if (!$scope.newReferrer.pReferrerOrgId || !$scope.newReferrer.pAdvisorOrgID) return;
      getAgreementPreferredAdvisers($scope.newReferrer.pReferrerOrgId, $scope.newReferrer.pAdvisorOrgID)
        .then(() => {
          processPreferredAdvisers();
        });
    };
    $scope.requestIdContact = REQUEST_ID;
    const getAgreementPermission = () => {
      const pageName = 'ReferralAgreementDetail';
      corporateModelService.accessControlMatrixes(pageName)
        .then((data) => {
          $scope.referralAgreementAccess = data;
        });
    };

    $scope.isAccessGranted = (panel, field) => {
      if (!$scope.referrerModel.isCorporateUser) return true;
      const restrictedForCreate = ['CorporateInformation', 'AdviserCheckbox'];
      if (!$scope.referralAgreementAccess || !$scope.referralAgreementAccess.length) return false;
      const filteredByPanel = panel ? $scope.referralAgreementAccess.filter(x => x.panelName === panel) : $scope.referralAgreementAccess;
      if (!field) {
        const permission = filteredByPanel && filteredByPanel.length ? filteredByPanel[0].allowedActionId === ALLOWED_ACTION.READ_AND_WRITE : false;
        const isNotRestrictedPanel = !permission && !$scope.agreementId && restrictedForCreate.indexOf(panel) === -1;
        if (isNotRestrictedPanel) return true;
        return permission;
      }
      const filteredByField = $scope.referralAgreementAccess.find(x => x.fieldName === field);
      if (!filteredByField || !filteredByField.allowedActionId) return false;
      return filteredByField.allowedActionId === ALLOWED_ACTION.READ_AND_WRITE;
    };
    const getProcessor = () => {
      $scope.processorList = [];
      corporateService.getAdminUsers().then((response) => {
        $scope.processorList = response.data;
        if ($scope.agreementId === 0) {
          $scope.newReferrer.pAgreementProcessor = $scope.referrerModel.userInfo.FamilyId;
        }
      });
    };
    userService.GetUserInfo().then((response) => {
      $scope.referrerModel.isCorporateUser = false;
      const userInfo = response.data;
      if (userInfo) {
        getCallingCode(userInfo.CountryId);
        $scope.referrerModel.userInfo = userInfo;
        $scope.mobileMask = broadcastDataService.getMobileFormat(userInfo.CountryId);
        $scope.phoneMask = broadcastDataService.getPhoneFormat(userInfo.CountryId);
        $scope.phoneDigit = userInfo.CountryId === 1 ? -6 : -9;
        $scope.referrerModel.isCorporateUser = userInfo.AccessType === 5;
        getProcessor();
        getAgreementPermission();
      }
    });
    $scope.setCommission = function (id) {
      $scope.newReferrer.pReferrerType = id;
      if (id === 2) {
        $scope.isNoCommission = true;
        $scope.newReferrer.pUpfrontPercent = 0;
        $scope.newReferrer.pTrailPercent = 0;
      } else if (id === 0 && $scope.isDisabled) {
        $scope.isNoCommission = false;
        $scope.newReferrer.pUpfrontPercent = 20;
        $scope.newReferrer.pTrailPercent = $scope.agreementId ? $scope.newReferrer.pTrailPercentTemp : 0.0;
      } else if (!$scope.referrerModel.isLoanOrWealth) {
        $scope.isNoCommission = false;
        $scope.newReferrer.pUpfrontPercent = 0;
        $scope.newReferrer.pTrailPercent = 0;
      } else if ($scope.referrerModel.isLoanOrWealth) {
        $scope.newReferrer.pReferrerType = 0;
        $scope.newReferrer.pUpfrontPercent = 15;
        $scope.newReferrer.pTrailPercent = 0;
      }
    };
    $scope.isDisabled = false;
    const getAgreementStatusLookup = () => {
      $scope.agreementStatusList = [];
      corporateService.getAgreementStatusLookup().then((response) => {
        $scope.agreementStatusList = response.data;
        if ($scope.agreementId === 0) {
          $scope.newReferrer.pAgreementStatus = $scope.agreementStatusList[0].AgreementStatusId;
        }
      });
    };
    getAgreementStatusLookup();
    $scope.referralModel = {};
    const getOrganizationTypes = () => {
      $scope.referralModel.organisationTypeList = [];
      referralsService.getOrganizationTypes().then((response) => {
        response.data.splice(3, 1); // Remove Ray White Office from the list
        $scope.referralModel.organisationTypeList = response.data;
      });
    };
    getOrganizationTypes();
    $scope.changeEntityType = function () {
      const $data = _.find($scope.agreementTypes, (item) => {
        return item.OrganizationTypeId === $scope.newReferrer.pOrganisationTypeId;
      });
      if ($data) {
        $scope.referralTypeName = $data.OrganizationTypeName;
      } else {
        switch ($scope.agreementTypeId) {
        case 2:
          $scope.referralTypeName = 'Ray White'; break;
        case 1:
          $scope.referralTypeName = 'Loan Market / Wealth Market'; break;
        case 4:
          $scope.referralTypeName = 'Wealth Market'; break;
        default:
          break;
        }
      }
    };
    const setWealthMarketCommission = () => {
      $scope.referrerModel.isLoanOrWealth = true;
      $scope.newReferrer.pReferrerType = 0;
      $scope.newReferrer.pUpfrontPercent = 15;
      $scope.newReferrer.pTrailPercent = 0;
    };
    const loanMarketOrWealthMarketOrg = (id) => {
      if ($scope.agreementTypeId === 1 || $scope.agreementTypeId === 4) { // This is only applicable for LM/WM Agreement)
        $scope.referrerModel.isLoanOrWealth = false;
        corporateService.adviserOrganizationGet(0, id).then((response) => {
          if (response.data.length > 0) {
            const data = response.data[0];
            if (data.BrandingCategoryId === 3) {
              setWealthMarketCommission();
            }
          }
        });
      }
    };
    const wealthMarketMember = (ids) => {
      _.map(ids, (data) => {
        if (!$scope.referrerModel.isLoanOrWealth && ($scope.agreementTypeId === 1 || $scope.agreementTypeId === 4)) { // This is only applicable for LM/WM Agreement
          corporateService.corporateContactGet(0, data).then((response) => {
            if (response.data) {
              if (response.data.BusinessUnitID === 3) {
                setWealthMarketCommission();
              }
            }
          });
        }
      });
      if (_.size(ids) === 0) {
        if (typeof $scope.newReferrer.pAdvisorOrgID !== 'undefined' && $scope.newReferrer.pAdvisorOrgID > 0) {
          loanMarketOrWealthMarketOrg($scope.newReferrer.pAdvisorOrgID);
        }
      }
    };
    const getBrokerActive = (ids) => {
      ids.forEach((data) => {
        if (!$scope.isReferrerRequired) {
          corporateService.corporateContactGet(0, data).then((response) => {
            if (response.data) {
              if (!response.data.MyCRMActive) {
                $scope.isReferrerRequired = true;
              }
            }
          });
        }
      });
    };
    // init Function
    $scope.init = function () {
      $scope.referralModel.existingAdviserCount = 0;
      $scope.newReferrer = {
        pReferrerOrgName: '',
        pTradingName: '',
        pABN: '',
        pACN: '',
        pBusinessActivitiesDesc: '',
        pBusinessAddressID: 0,
        pOrganisationTypeId: $scope.referralType,
        ReferrerOrgDirectorsList: [],
        ReffererOrgReferrersList: [],
        pLeadAdvisor_FamilyID: 0,
        pAdvisorOrgID: 0,
        pIsLoanMarketToPrepare: true,
        pAdvisorsFamilyIDList: [],
        pAdvisersActiveFlag: [],
        pIsLoanMarketToFacilitate: true,
        pIsCalculateBasedonBrokerPayment: true,
        pUpfrontPercent: 0,
        pTrailPercent: 0,
        phone: '',
        email: '',
        raywhiteAgents: '',
        pReferrerOrgId: 0,
        agreementID: $scope.agreementId,
        pAgreementTypeID: 0,
        PropertyAddress: '',
        pAgreementStatus: 0,
        pAgreementProcessor: 0,
        Notes: '',
        pReferrerType: 0,
        AgreementAdviserFamilyId: 0,
        IsActive: false,
        hasLicenseOrAsic: false,
        TradingAsName: '',
        ReasonForAgreement: '',
      };
      referralsService.getOrganizationTypes().then((response) => {
        $scope.agreementTypes = response.data;
        $scope.changeEntityType();
      });
      $scope.newReferrer.pAgreementTypeID = $scope.agreementTypeId;
      const rayWhiteCommision = () => {
        if ($scope.agreementTypeId === 2) {
          $scope.isDisabled = true;
          $scope.newReferrer.pUpfrontPercent = 20;
          $scope.newReferrer.pTrailPercent = 20;
        }
      };
      rayWhiteCommision();
      const ReferrerOrgOwnerGroup = referralsService.getReferrerOrgOwnerGroup(false);
      const AgreementTypes = referralsService.getAgreementTypes();
      $scope.orgOwnerGroupList = [];
      $scope.orgMembersList = [];
      $scope.agreementTypesList = [];
      $scope.combineResult = $q.all([
        ReferrerOrgOwnerGroup,
        // ReferrerOrgMembers,
        AgreementTypes,
      ]).then((resp) => {
        // Get Organisation Owner Group
        $scope.orgOwnerGroupList = _.filter(resp[0].data, (data) => { return data.OrganizationName !== ''; });
        const $data0 = _.first($scope.orgOwnerGroupList);
        if ($scope.agreementId === 0) {
          $scope.newReferrer.pAdvisorOrgID = _.size($data0) > 0 ? $data0.OrganizationID : 0;
          referralsService.getReferrerOrgMembers($scope.newReferrer.pAdvisorOrgID).then((response) => {
            $scope.orgMembersList = response.data;
            const members = response.data && response.data.length ? response.data[0] : null;
            if (!$scope.agreementId) {
              $scope.newReferrer.pLeadAdvisor_FamilyID = (members && members.FamilyID) || 0;
            }
            _.map($scope.orgMembersList, (item) => {
              item.IsChecked = false;
              return item;
            });
          });
        }
        // Get Agreement Types
        $scope.agreementTypesList = resp[1].data;
        const $data2 = _.first(resp[1].data);
        // For Get Referrer Detail
        if ($scope.agreementId !== 0) {
          // Get Organisaton Members
          referralsService.getReferrerOrgSummary($scope.agreementId).then((response) => {
            const $data = response.data;
            $scope.referrerModel.existingAdviserList = $data.pAdvisorsFamilyIDList.reduce((accum, adviser) => {
              accum.push(adviser.FamilyID);
              return accum;
            }, []);
            referralsService.getReferrerOrgMembers($data.AdvisorOrgID.toString()).then((getReferrerOrgMembersResponse) => {
              $scope.orgMembersList = _.filter(getReferrerOrgMembersResponse.data, (data) => { return data.MemberName !== ''; });
            });
            $scope.$watch('orgMembersList', () => {
              if ($scope.orgMembersList.length > 0) {
                const adviserFlag = $data.AdvisersFlag.split(',');
                _.map($scope.orgMembersList, (item) => {
                  let counter = 0;
                  angular.forEach($data.pAdvisorsFamilyIDList, (innerItem) => {
                    if (Number(item.FamilyID) === Number(innerItem.FamilyID)) {
                      item.IsChecked = true;
                      if ($data.AdvisersFlag.length > 0) {
                        item.IsActive = parseInt(adviserFlag[counter], 10) === 1;
                      }
                      wealthMarketMember([item.FamilyID]);
                      getBrokerActive([item.FamilyID]);
                    }
                    counter++;
                  });
                  return item;
                });
              }
            });
            $scope.referralModel.existingAdviserCount = $data.pAdvisorsFamilyIDList ? $data.pAdvisorsFamilyIDList.length : 0;
            if (typeof $data.LeadAdvisorFamilytID === 'undefined') {
              if (typeof $data2 !== 'undefined') {
                $data.LeadAdvisorFamilytID = $data2.FamilyID;
              }
            }
            if (typeof $data.AdvisorOrgID === 'undefined') {
              $data.AdvisorOrgID = $data0.OrganizationID;
              if (($scope.agreementTypeId === 1 || $scope.agreementTypeId === 4)) {
                loanMarketOrWealthMarketOrg($data.AdvisorOrgID);
              }
            }
            if (typeof $data.AgreementTYpeID === 'undefined') {
              $data.AgreementTYpeID = $data2.AgreementTypeId;
            }
            // $scope.newReferrer = response.data;
            $scope.newReferrer = {
              pReferrerOrgName: $data.ReferrerOrgName,
              pTradingName: $data.TradingName,
              pABN: $data.ABN,
              pACN: $data.ACN,
              pBusinessActivitiesDesc: $data.BusinessActivitiesDesc,
              pBusinessAddressID: $data.BusinessAddressID,
              pOrganisationTypeId: $scope.agreementTypeId === 3 ? $data.OrganisationTypeId : 0, // Default Organisation ID to 0 if not Third Party
              ReferrerOrgDirectorsList: [],
              ReffererOrgReferrersList: [],
              pLeadAdvisor_FamilyID: typeof $data.LeadAdvisorFamilytID === 'undefined' ? '' : $data.LeadAdvisorFamilytID.toString(),
              pAdvisorOrgID: $data.AdvisorOrgID,
              pIsLoanMarketToPrepare: $data.IsPrepareByLoanMarket,
              pAdvisorsFamilyIDList: $scope.orgMembersList,
              pIsLoanMarketToFacilitate: $data.IsFacilitatePaymentByLoanMarket,
              pIsCalculateBasedonBrokerPayment: $data.IsCalculateBasedOnBrokerPayment,
              pUpfrontPercent: $data.UpfrontPercent,
              pTrailPercent: $data.TrailPercent,
              pTrailPercentTemp: $data.TrailPercent,
              phone: $data.Phone,
              email: $data.Email,
              raywhiteAgents: $data.Agents,
              pReferrerOrgId: $data.ReferrerOrgID,
              agreementID: $scope.agreementId,
              pAgreementTypeID: $data.AgreementTYpeID.toString(),
              PropertyAddress: '',
              pAgreementStatus: $data.AgreementStatusId,
              pAgreementProcessor: $data.AgreementProcessor,
              Notes: $data.Notes,
              pReferrerType: $data.ReferrerType,
              AgreementAdviserFamilyId: $data.AgreementAdviserFamilyId,
              IsActive: $data.IsActive,
              GeneratedRefStrList: $data.GeneratedRefStrList,
              pAdvisersActiveFlag: $data.AdvisersFlag,
              hasLicenseOrAsic: $data.hasLicenseOrAsic || false,
              TradingAsName: $data.TradingAsName || '',
              ReasonForAgreement: $data.ReasonForAgreement || '',
              IncomingLeadPreferredAdviserId: $data.IncomingLeadPreferredAdviserId,
              BusinessTypeId: $data.BusinessTypeId,
            };
            getAgreementPreferredAdvisers($data.ReferrerOrgID, $data.AdvisorOrgID);
            $scope.referrerModel.existingReferrers = $data.Referrers || [];
            if ($data.ReffererOrgReferrersList.length > 0) {
              $scope.isNewReferrer = true;
              const tempData = _.filter($data.ReffererOrgReferrersList, (item) => { return item.pName !== ''; });
              $scope.newReferrer.ReffererOrgReferrersList = _.map(tempData, (data) => {
                data.Contact = data.pContact;
                data.ContactAlt = data.pContact && typeof data.pContact === 'string' ? data.pContact.replace(/ /g, '').slice($scope.phoneDigit) : '';
                data.Email = data.pEmail;
                data.FamilyId = data.FamilyID;
                return data;
              });
            }
            if ($data.ReferrerOrgDirectorsList && $data.ReferrerOrgDirectorsList.length > 0) {
              $scope.newReferrer.ReferrerOrgDirectorsList = _.map($data.ReferrerOrgDirectorsList, (data) => {
                data.pContactAlt = data.pContact && typeof data.pContact === 'string' ? data.pContact.replace(/ /g, '').slice($scope.phoneDigit) : '';
                return data;
              });
            }
            if ($scope.agreementTypeId === 1 || $scope.agreementTypeId === 4) { // 1 = Loan Market, 4 = Wealth Market
              $scope.newReferrer.referrerOrgObjInitial = {
                OrganizationID: $data.ReferrerOrgID,
                OrganizationName: $data.ReferrerOrgName,
              };
              // Temporary comment out
              // Remove later if new logic is stable
              // Display same branding category
              // displaySameBrandingOrg($data.AdvisorOrgID);
              // displaySameBrandingAdviser($data.AgreementAdviserFamilyId);
            }
            if ($data.ReferrerOrgDirectorsList.length > 0) {
              $scope.isNewDirector = true;
            }
            if ($scope.agreementTypeId === 2) {
              $scope.raywhite.initialValue = {
                id: $data.ReferrerOrgID,
                name: $data.ReferrerOrgName,
              };
            }
            if ($data.BusinessAddressID !== 0) {
              referralsService.getAddressDetails($data.BusinessAddressID).then((getAddressDetailsResponse) => {
                if (_.size(getAddressDetailsResponse.data) > 0) {
                  if (typeof getAddressDetailsResponse.data.formatted_address !== 'undefined' && getAddressDetailsResponse.data.formatted_address !== null) {
                    $scope.newReferrer.PropertyAddress = getAddressDetailsResponse.data.formatted_address;
                  }
                }
              });
            }
          });
        }
        if (($scope.agreementTypeId === 1 || $scope.agreementTypeId === 4)) {
          loanMarketOrWealthMarketOrg($scope.newReferrer.pAdvisorOrgID);
        }
      });
      $scope.selectAdviserOrg = function () {
        referralsService.getReferrerOrgMembers($scope.newReferrer.pAdvisorOrgID).then((response) => {
          $scope.orgMembersList = response.data;
          const $data1 = _.first(response.data);
          if ($scope.agreementId === 0)
            $scope.newReferrer.pLeadAdvisor_FamilyID = $data1.length > 0 ? $data1.FamilyID : 0;
          _.map($scope.orgMembersList, (item) => {
            item.IsChecked = false;
            return item;
          });
          processOrgAdvisers();
        });
        if (($scope.agreementTypeId === 1 || $scope.agreementTypeId === 4)) {
          loanMarketOrWealthMarketOrg($scope.newReferrer.pAdvisorOrgID);
        }
      };
      // For Mobile
      //-----------------------------------------------------------------------------
      // Initial Value
      $scope.currentStep = 1;
      $scope.totalStep = 0;
      if ($scope.referralType === 1 || $scope.referralType === 3)
        $scope.totalStep = 4;
      if ($scope.referralType === 2)
        $scope.totalStep = 3;
      if ($scope.referralType === 4)
        $scope.totalStep = 2;
      const nextStep = () => {
        $scope.currentStep++;
      };
      const prevStep = () => {
        $scope.currentStep--;
      };
      $scope.form = {
        next(form) {
          $scope.toTheTop();
          if (form.$valid) {
            form.$setPristine();
            nextStep();
          } else {
            let firstError = null;
            form && Object.keys(form).forEach((field) => {
              if (field[0] !== '$') {
                if (firstError === null && !form[field].$valid) {
                  firstError = form[field].$name;
                }
                if (form[field].$pristine) {
                  form[field].$dirty = true;
                }
              }
            });
            angular.element(`.ng-invalid[name=${firstError}]`).focus();
          }
        },
        prev() {
          $scope.toTheTop();
          prevStep();
        },
        submit() {
        },
        reset() {
        },
      };
    };
    $scope.referrerModel.isLoanOrWealth = false;
    $scope.raywhite = {};
    $scope.raywhite.officeList = [];
    $scope.raywhite.initialValue = '';

    $scope.getRayWhiteOrganisations = function (searchText) {
      if (searchText.length >= 3) {
        corporateService.getRayWhiteOrganisations(30, 0, searchText).then((response) => {
          _.map(response.data, (data) => {
            data.status = data.status || 'Inactive';
            data.public = data.public || 'false'; // String format from API
          });
          $scope.raywhite.officeList = _.filter(response.data, (data) => { return data.name !== '' && data.status === 'Active'; });
        });
      }
    };
    $scope.raywhiteAdviser = {};
    $scope.rayWhiteAdviserList = [];
    $scope.getRayWhiteAdvisers = function (adviserOrg) {
      $scope.newReferrer.pReferrerOrgName = adviserOrg.name;
      $scope.newReferrer.pReferrerOrgId = adviserOrg.id;
      const orgId = adviserOrg.id;
      const size = 1000;
      const from = 0;
      corporateService.getRayWhiteAdvisers(size, from, orgId).then((response) => {
        _.map(response.data, (data) => {
          if (typeof data.status === 'undefined') {
            data.status = 'Inactive';
          }
          if (typeof data.public === 'undefined') {
            data.public = 'false'; // String format in API
          }
        });
        $scope.rayWhiteAdviserList = _.filter(response.data, (data) => { return data.name !== ''; });
        // Display Ray White Referrers
        if ($scope.agreementTypeId === 2) {
          $scope.newReferrer.ReffererOrgReferrersList.forEach((item) => {
            const temp = _.filter($scope.rayWhiteAdviserList, (data) => { return data.id === item.FamilyId; });
            if (temp.length) {
              temp[0].GeneratedRefString = item.GeneratedRefString;
              $scope.referrerAddList.push(temp[0]);
            }
          });
        }
        $scope.rayWhiteAdviserList = _.filter($scope.rayWhiteAdviserList, (data) => { return data.status === 'Active'; });
      });
      processOrgAdvisers();
    };
    $scope.referrerAddList = [];
    $scope.pushReferrerRayWhite = function () {
      if ($scope.raywhiteAdviser.selected) {
        $scope.raywhiteAdviser.selected.GeneratedRefString = $scope.referrerModel.generatedRefStringRaywhite;
        $scope.referrerAddList.push($scope.raywhiteAdviser.selected);
        $scope.referrerModel.isNewRayWhite = false;
        $scope.newReferrer.raywhiteAgents = _.map($scope.referrerAddList, 'name').join(',');
        const data = {
          FamilyID: $scope.raywhiteAdviser.selected.id,
          pName: $scope.raywhiteAdviser.selected.name || '',
          pContact: $scope.raywhiteAdviser.selected.phone || '',
          pEmail: $scope.raywhiteAdviser.selected.email || '',
          GeneratedRefString: $scope.referrerModel.generatedRefStringRaywhite,
          PreferredAdviserId: $scope.raywhiteAdviser.preferredAdviserId || 0,
        };
        $scope.referrerModel.generatedRefStringRaywhite = null;
        $scope.raywhiteAdviser.preferredAdviserId = null;
        $scope.newReferrer.ReffererOrgReferrersList.push(data);
      }
    };
    $scope.joinData = function (data, name) {
      const joined = _.map(data, name).join(',');
      return joined === '' ? 'Not Available' : joined;
    };
    $scope.isNewRayWhite = false;
    $scope.addRayWhite = function () {
      $scope.referrerModel.isNewRayWhite = true;
    };
    $scope.cancelReferrer = function () {
      $scope.referrerModel.isNewRayWhite = false;
      $scope.referrerModel.generatedRefStringRaywhite = null;
    };
    $scope.requiredAdviser = false;
    $scope.init();
    // Add New Referral
    const checkEmailValidity = (email, familyId) => {
      const query = $q.defer();
      generalService.getCheckIfEmailExists(email, familyId).then((response) => {
        query.resolve(response.data > 0);
      });
      return query.promise;
    };
    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };
    const removeExcessAddress = (address) => {
      let retVal = '';
      if (typeof address !== 'undefined' && address) {
        const excessString = '&region=AU';
        retVal = _.replace(address, excessString, '');
      }
      return retVal;
    };
    const cancelAgreement = () => {
      if (_.size($window.sessionStorage.getItem('currentState')) > 0) {
        const data = JSON.parse($window.sessionStorage.getItem('currentState'));
        $window.sessionStorage.removeItem('currentState');
        $state.go(data.state, data.params);
      } else {
        $state.go('app.referral');
      }
    };
    const setDateRequested = () => {
      const existingAdviser = $scope.referrerModel.existingAdviserList;
      if (!existingAdviser || !existingAdviser.length) {
        $scope.newReferrer.changeDateRequested = 1;
        return;
      }
      const addedAdviser = $scope.orgMembersList && $scope.orgMembersList.filter((member) => {
        return member.IsChecked && existingAdviser.indexOf(member.FamilyID) === -1;
      });
      $scope.newReferrer.changeDateRequested = addedAdviser && addedAdviser.length ? 1 : 0;
      if ($scope.newReferrer.changeDateRequested) return;
      const referrerObj = $scope.newReferrer.ReffererOrgReferrersList;
      if (referrerObj && referrerObj.length) {
        const isMyCrmActive = $scope.referrerModel.userInfo.MyCRMActive || false;
        const newReferrers = referrerObj.reduce((accumulator, current) => {
          const familyId = current.FamilyID || current.FamilyId;
          return [...accumulator, familyId];
        }, []);
        const existingReferrersSplitted = $scope.referrerModel.existingReferrers && $scope.referrerModel.existingReferrers.length ? $scope.referrerModel.existingReferrers.split(',') : [];
        const existingReferrers = existingReferrersSplitted && existingReferrersSplitted.reduce((accumulator, current) => {
          if (!current) return accumulator;
          accumulator.push(parseInt(current, 10));
          return accumulator;
        }, []);

        const addedReferrers = newReferrers.filter(e => existingReferrers.indexOf(e) === -1);
        if (addedReferrers && addedReferrers.length) {
          $scope.newReferrer.changeDateRequested = isMyCrmActive ? 0 : 1;
        }
      }
    };
    const saveReferral = () => {
      $scope.newReferrer.IncomingLeadPreferredAdviserId = $scope.newReferrer.IncomingLeadPreferredAdviserId || 0;
      const referrerList = $scope.newReferrer.ReffererOrgReferrersList || [];
      if (referrerList.length && parseInt($scope.newReferrer.pAgreementTypeID, 10) === AGREEMENT_TYPE.RAY_WHITE) {
        $scope.newReferrer.ReferrersIncomingLeadPreferredAdviserId = referrerList.map(adviser => adviser.PreferredAdviserId || 0).join();
      }
      $scope.newReferrer.changeDateRequested = 0;
      if (!exemptedAccessForDateRequested) {
        setDateRequested();
      }
      const $adviser = _.filter($scope.orgMembersList, (item) => {
        return item.IsChecked === true;
      });
      const $adviserFlag = _.filter($scope.orgMembersList, (item) => {
        return item.IsChecked === true;
      });
      if ($adviser !== undefined && $adviser !== null) {
        $scope.newReferrer.pAdvisorsFamilyIDList = _.map(_.toArray($adviser), 'FamilyID');
      }
      if ($adviserFlag !== undefined && $adviserFlag !== null) {
        $scope.newReferrer.pAdvisersActiveFlag = _.map(_.toArray($adviserFlag), (row) => {
          return row.IsActive ? 1 : 0;
        });
      }
      if ($scope.newReferrer.pIsLoanMarketToFacilitate === false) {
        $scope.newReferrer.pUpfrontPercent = 0;
        $scope.newReferrer.pTrailPercent = 0;
        $scope.newReferrer.pIsCalculateBasedonBrokerPayment = true;
      }
      $scope.newReferrer.pBusinessActivitiesDesc = $scope.newReferrer.BusinessTypeId === BUSINESS_TYPES.OTHERS ? $scope.newReferrer.pBusinessActivitiesDesc : '';
      referralsService.setReferrerOrgSummary($scope.newReferrer).then((response) => {
        $scope.requiredAdviser = false;
        if (response.data > 0) {
          if ($scope.agreementId === 0) {
            toaster.pop('success', 'Added', 'Agreement has been successfully added');
          } else {
            toaster.pop('success', 'Updated', 'Agreement has been successfully updated');
          }
          const existingCount = $scope.referralModel.existingAdviserCount;
          const currentCount = $scope.newReferrer.pAdvisorsFamilyIDList.length;
          const showMessage = !exemptedAccessForDateRequested && $scope.newReferrer.agreementID && currentCount > existingCount;
          if (showMessage) {
            swal({
              title: 'Success!',
              text: 'Thank you for your request to add a broker to your agreement. This will be processed by the Agreements Team and a Broker Undertaking addendum will be issued. Only once this has been signed and activated, will the requested referrers be available to the newly added broker. If you have any further questions please contact the agreements team on 07 3231 2118 or agreements@loanmarket.com.au.',
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              closeOnConfirm: true,
              confirmButtonColor: '#3085d6',
              confirmButtonClass: 'btn btn-success',
            },
            (isConfirm) => {
              if (isConfirm) {
                cancelAgreement();
              }
            });
          } else {
            cancelAgreement();
          }
        } else if (response.data === -1) {
          $scope.requiredAdviser = true;
          swal('Required Field', 'You must select an Adviser who require this agreement', 'error');
          $scope.referrerModel.isRequesting = false;
        } else {
          swal('Saving Failed', 'Saving agreement failed. Contact administrator for more info.', 'error');
          $scope.referrerModel.isRequesting = false;
        }
      });
    };
    $scope.referrerModel.isSubmitted = false;
    $scope.isEmpty = function (model) {
      return typeof model === 'undefined' || model.trim() === '';
    };
    const validateReferralPayment = () => {
      const excludedType = [AGREEMENT_TYPE.RAY_WHITE];
      const validatedTypeList = [REFERRAL_PAYMENT_TYPE.LENDER, REFERRAL_PAYMENT_TYPE.BROKER];
      if (excludedType.indexOf($scope.agreementTypeId) !== -1) return true;
      if (validatedTypeList.indexOf($scope.newReferrer.pReferrerType) === -1) return true;
      return parseInt($scope.newReferrer.pUpfrontPercent, 10) > 0 || parseInt($scope.newReferrer.pTrailPercent, 10) > 0;
    };
    $scope.isDirectorEmpty = false;
    $scope.isReferrerEmpty = false;
    $scope.forms = {};
    $scope.setNewReferral = function () {
      $scope.referrerModel.isSubmitted = true;
      if ($scope.requestId === 2 || $scope.requestId === 3) {
        saveReferral();
      } else {
        if ($scope.newReferrer.pOrganisationTypeId === 1) { // Company
          if (typeof $scope.forms.company !== 'undefined' && $scope.forms.company.$invalid) {
            swal('Required Message', 'Please complete required fields in Company Details section', 'error');
            return false;
          }
        } else if ($scope.newReferrer.pOrganisationTypeId === 2) { // Individual
          if (typeof $scope.forms.individual !== 'undefined' && $scope.forms.individual.$invalid) {
            swal('Required Message', 'Please complete required fields in Individual/Person Details section', 'error');
            return false;
          }
        } else if ($scope.newReferrer.pOrganisationTypeId === 3) { // partnership
          if (typeof $scope.forms.partnership !== 'undefined' && $scope.forms.partnership.$invalid) {
            swal('Required Message', 'Please complete required fields in Partnership Details section', 'error');
            return false;
          }
        }
        if ($scope.agreementTypeId === 2 && typeof $scope.raywhite.officeSelected === 'undefined') {
          swal('Required Message', 'Ray White Office is Required. Please select an office.', 'error');
          return false;
        }
        if ($scope.newReferrer && $scope.newReferrer.ReffererOrgReferrersList.length === 0 && ($scope.agreementTypeId === 1 || $scope.agreementTypeId === 4)) {
          swal('Required Field', 'Loan Market Broker/Adviser is required in this agreement', 'error');
          $scope.referrerModel.isBrokerEmpty = true;
          return false;
        }
        $scope.isDirectorEmpty = false;
        if ($scope.newReferrer && $scope.agreementTypeId === 3 && $scope.newReferrer.pOrganisationTypeId !== 2 && $scope.newReferrer.ReferrerOrgDirectorsList.length === 0) {
          $scope.isDirectorEmpty = true;
          swal('Agreement Message', 'Director is required. Please add at least one in the list.', 'error');
          return false;
        }
        $scope.isReferrerEmpty = false;
        if ($scope.newReferrer && ($scope.agreementTypeId === 3 || $scope.agreementTypeId === 2) && $scope.isReferrerRequired && $scope.newReferrer.ReffererOrgReferrersList.length === 0) { // && $scope.referralType != 2 (Remove this validation for Individual means all Referral type requires Referrer)
          $scope.isReferrerEmpty = true;
          swal('Agreement Message', 'Referrer is required. Please add at least one in the list.', 'error');
          return false;
        }
        if (!validateReferralPayment()) {
          swal('Commission Rate is Required', 'As you have said "Yes" to Loan Market facilitating payment to the referrer in this agreement, you need to provide either an upfront or trailing commission percentage before saving this agreement.', 'error');
          $scope.referralPaymentRequired = true;
          return false;
        }
        $scope.referrerModel.isRequesting = true;
        if ($scope.agreementTypeId !== 3 && $scope.agreementId === 0) {
          referralsService.getCheckAgreementExist($scope.newReferrer.pAdvisorOrgID, $scope.newReferrer.pReferrerOrgId).then((response) => {
            if (response.data > 0) {
              swal('Agreement Message', 'There is already an agreement in place between these organizations. Please request an update to the existing agreement.', 'error');
              $scope.referrerModel.isRequesting = false;
            } else {
              saveReferral();
            }
          });
        } else if ($scope.agreementTypeId === 3) {
          if ($scope.newReferrer.pBusinessAddressID === 0) {
            let addressObj = {};
            if ($scope.referrerModel.isAddressGeocoded && _.size($scope.referrerModel.addressPropertyObj) > 0 && $scope.referrerModel.addressPropertyObj.geoCoded) {
              addressObj = $scope.referrerModel.addressPropertyObj;
            } else {
              addressObj = {
                formatted_address: removeExcessAddress($scope.newReferrer.PropertyAddress),
                geoCoded: false,
              };
            }
            referralsService.setAddressDetails(addressObj).then((response) => {
              if (response.data !== 'undefined') {
                $scope.newReferrer.pBusinessAddressID = response.data;
                saveReferral();
              } else {
                swal('Address Error', 'Invalid address please select or enter address again.', 'error');
                $scope.referrerModel.isRequesting = false;
              }
            });
          } else {
            saveReferral();
          }
        } else {
          saveReferral();
        }
      }
    };
    $scope.homeAutoAddresss = function () {
      $scope.referrerModel.isAddressGeocoded = false;
      $scope.newReferrer.pBusinessAddressID = 0;
      generalService.placeSearch($scope.newReferrer.PropertyAddress).then((respond) => {
        $scope.propertyAddressDetails = respond.data;
      });
    };
    $scope.referrerModel.individualPhoneMasked = '99-9999-9999';
    $scope.setAddreess = function (property) {
      $scope.newReferrer.PropertyAddress = property.formatted_address;
      $scope.referrerModel.addressPropertyObj = property;
      $scope.referrerModel.isAddressGeocoded = true;
    };
    $scope.closeAutoHomeAddress = function () {
      $scope.propertyAddressDetails = [];
    };
    // Referrer Organisation Directors
    //-------------------------------------------------------------------
    $scope.newReferrerDirectorsList = [];
    // Add Director Row
    $scope.addDirector = function () {
      const newDirector = {
        pDirectorId: Math.random(),
        pName: '',
        pContact: '',
        pEmail: '',
        pContactAlt: '',
      };
      $scope.newReferrerDirectorsList.push(newDirector);
    };
    // Add Or Update Director
    $scope.saveDirector = function (director) {
      if (validateEmail(director.pEmail) || !director.pEmail) {
        const $index = $scope.newReferrerDirectorsList.indexOf(director);
        if ($index > -1) {
          $scope.newReferrerDirectorsList.splice($index, 1);
        }
        const postData = {
          pDirectorId: director.pDirectorId,
          pName: director.pName,
          pContact: director.pContact,
          pEmail: director.pEmail,
          pContactAlt: director.pContactAlt,
        };
        if (!director.IsEditMode) {
          postData.pDirectorId = 0;
          $scope.newReferrer.ReferrerOrgDirectorsList.push(postData);
        } else {
          const $index1 = $scope.newReferrer.ReferrerOrgDirectorsList.indexOf(director);
          if ($index1 > -1) {
            $scope.newReferrer.ReferrerOrgDirectorsList[$index1].pDirectorId = director.pDirectorId;
            $scope.newReferrer.ReferrerOrgDirectorsList[$index1].pName = director.pName;
            $scope.newReferrer.ReferrerOrgDirectorsList[$index1].pContact = director.pContact;
            $scope.newReferrer.ReferrerOrgDirectorsList[$index1].pContactAlt = director.pContactAlt;
            $scope.newReferrer.ReferrerOrgDirectorsList[$index1].pEmail = director.pEmail;
          }
        }
        director.IsEditMode = false;
      } else {
        swal('Email Validation', 'Invalid Email Address.', 'error');
      }
    };
    // Delete Director
    $scope.deleteDirector = function (director) {
      let $index;
      if (Math.floor(director.pDirectorId) !== 0) {
        SweetAlert.swal({
          title: 'Are you sure?',
          text: 'This record will be removed from your company directors list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            referralsService
              .deleteReferrerOrgDirector(director.pDirectorId)
              .then((response) => {
                if (parseInt(response.data, 10) === 1) {
                  const $index1 = $scope.newReferrer.ReferrerOrgDirectorsList.indexOf(director);
                  if ($index1 > -1) {
                    $scope.newReferrer.ReferrerOrgDirectorsList.splice($index1, 1);
                  }
                  swal('Deleted', 'Company director has been deleted.', 'success');
                }
              })
              .catch(displayError);
          }
        });
      } else {
        $index = $scope.newReferrer.ReferrerOrgDirectorsList.indexOf(director);
        if ($index > -1)
          $scope.newReferrer.ReferrerOrgDirectorsList.splice($index, 1);
      }
      if ($scope.newReferrer.ReferrerOrgDirectorsList.length === 0 && $scope.newReferrerDirectorsList.length === 0) {
        $scope.isNewDirector = !$scope.isNewDirector;
      } else if ($scope.newReferrer.ReferrerOrgDirectorsList.length === 0 && $scope.newReferrerDirectorsList.length === 1) {
        $scope.isNewDirector = !$scope.isNewDirector;
        $scope.newReferrerDirectorsList.splice($index, 1);
      } else if ($scope.newReferrerDirectorsList.length > 1 || $scope.newReferrer.ReferrerOrgDirectorsList.length > 0) {
        $scope.newReferrerDirectorsList.splice($index, 1);
      }
    };
    // Referrer Organisation Referrers
    $scope.newReferrerreferrersList = [];
    // Add Referrer Row
    $scope.addReferrer = function () {
      const $newReferrer = {
        FamilyId: 0,
        FirstName: '',
        MiddleName: '',
        LastName: '',
        Contact: '',
        Email: '',
        GeneratedRefString: null,
      };
      $scope.newReferrerreferrersList.push($newReferrer);
    };
    // Add Or Update Referrer
    const saveReferrerFunc = (referrer) => {
      $scope.isPushingReferrer = false;
      const index = $scope.newReferrerreferrersList.indexOf(referrer);
      if (index > -1) {
        $scope.newReferrerreferrersList.splice(index, 1);
      }
      const postData = {
        FamilyId: referrer.FamilyId,
        FirstName: referrer.FirstName,
        MiddleName: '',
        LastName: referrer.LastName,
        Contact: referrer.Contact,
        ContactAlt: referrer.ContactAlt,
        MobilePhone: referrer.MobilePhone,
        Email: referrer.Email,
        GeneratedRefString: referrer.GeneratedRefString,
        FamilyID: referrer.FamilyId,
      };
      if (!referrer.IsEditMode) {
        $scope.newReferrer.ReffererOrgReferrersList.push(postData);
      } else {
        const refLocation = $scope.newReferrer.ReffererOrgReferrersList.indexOf(referrer);
        if (refLocation > -1) {
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].FamilyId = referrer.FamilyId;
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].FirstName = referrer.FirstName;
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].MiddleName = referrer.MiddleName;
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].LastName = referrer.LastName;
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].Contact = referrer.Contact;
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].ContactAlt = referrer.ContactAlt;
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].Email = referrer.Email;
          $scope.newReferrer.ReffererOrgReferrersList[refLocation].GeneratedRefString = referrer.GeneratedRefString;
        }
      }
      referrer.IsEditMode = false;
    };
    const sameReferrer = (newDetails, existingDetails) => {
      if (!newDetails || !existingDetails) return false;
      const { FirstName, LastName, MobilePhone } = newDetails;
      const { firstName, lastName, mobilePhone } = existingDetails;
      const isSameValue = FirstName === firstName && LastName === lastName && MobilePhone === mobilePhone;
      return isSameValue;
    };
    $scope.isPushingReferrer = false;
    $scope.saveReferrer = (referrer) => {
      if ($scope.isPushingReferrer) return;
      $scope.isPushingReferrer = true;
      $scope.existingReferreDetail = {};
      if (validateEmail(referrer.Email)) {
        if ($scope.referrerModel.isCorporateUser) {
          referralService.existingReferrerDetails(referrer.Email).then((data) => {
            const existingDetails = data && data.length ? data[0] : null;
            if (!existingDetails) {
              saveReferrerFunc(referrer);
              return;
            }
            referrer.FamilyId = existingDetails.familyId;
            if (!sameReferrer(referrer, existingDetails)) {
              const referrerObj = {
                existingDetails,
                newDetails: referrer,
              };
              const modalInstance = crmConfirmation.open({
                type: 'warning',
                title: 'Hot diggity, we found a match!',
                description: `<referrer-comparison referrer-obj="vm.cCommonObject"></referrer-comparison>`,
                buttonText: 'Confirm and Update',
                modalSize: 'md',
                showCloseButton: true,
                renderAsComponent: true,
                commonObject: referrerObj,
              });
              modalInstance.result.then(() => {
                saveReferrerFunc(referrer);
              }, () => {
                $scope.isPushingReferrer = false;
              });
            } else {
              saveReferrerFunc(referrer);
            }
          });
          return;
        }
        checkEmailValidity(referrer.Email, referrer.FamilyId).then((prom) => {
          if (prom) {
            swal('Email Validation', 'Email is already used by other user. Please contact the Agreement Team for further assistance.', 'error');
            $scope.isPushingReferrer = false;
          } else {
            saveReferrerFunc(referrer);
          }
        });
      } else if (typeof referrer.Email === 'string' && referrer.Email.trim() === '') {
        saveReferrerFunc(referrer);
      } else {
        swal('Email Validation', 'Invalid Email Address.', 'error');
        $scope.isPushingReferrer = false;
      }
    };
    $scope.generateRefString = function (referrer, index, source) {
      let generatedRefString = '';
      generatedRefString = `Ref_${$scope.newReferrer.pReferrerOrgName}_${referrer.FirstName} ${referrer.LastName}`;
      source[index].GeneratedRefString = generatedRefString;
    };
    // Delete Referrer
    $scope.deleteReferrer = function (referrer) {
      let $index;
      if (Math.floor(referrer.FamilyId) !== 0) {
        SweetAlert.swal({
          title: 'Are you sure?',
          text: 'This record will be removed from your referrers directors list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            referralsService
              .deleteReffererOrgReferrer(referrer.FamilyId)
              .then((response) => {
                if (response.data === 1) {
                  const $index1 = $scope.newReferrer.ReffererOrgReferrersList.indexOf(referrer);
                  if ($index1 > -1) {
                    $scope.newReferrer.ReffererOrgReferrersList.splice($index1, 1);
                  }
                  swal('Deleted', 'Referrer has been deleted.', 'success');
                }
              })
              .catch(displayError);
          }
        });
      } else {
        $index = $scope.newReferrer.ReffererOrgReferrersList.indexOf(referrer);
        if ($index > -1) {
          $scope.newReferrer.ReffererOrgReferrersList.splice($index, 1);
        }
      }
      if ($scope.newReferrer.ReffererOrgReferrersList.length === 0 && $scope.newReferrerreferrersList.length === 0) {
        $scope.isNewReferrer = !$scope.isNewReferrer;
      } else if ($scope.newReferrer.ReffererOrgReferrersList.length === 0 && $scope.newReferrerreferrersList.length === 1) {
        $scope.newReferrerreferrersList.splice($index, 1);
        $scope.isNewReferrer = !$scope.isNewReferrer;
      } else if ($scope.newReferrerreferrersList.length > 1 || $scope.newReferrer.ReffererOrgReferrersList.length > 0) {
        $scope.newReferrerreferrersList.splice($index, 1);
      }
    };
    // Set Auto Generated Ticket for Referrals by Elmer
    $scope.brokerInfo = [];
    const getUserFullNameNewReferral = () => {
      dashboardService.getBrokerBasicInfo().then((response) => {
        $scope.brokerInfo = response.data;
      });
    };
    getUserFullNameNewReferral();
    $scope.raywhite.oldValue = {};
    $scope.$watch('raywhite.officeSelected', () => {
      if (!_.isUndefined($scope.raywhite.officeSelected) && $scope.raywhite.officeSelected) {
        if (!_.isUndefined($scope.raywhite.oldValue) && _.size($scope.raywhite.oldValue) > 0) {
          $scope.newReferrer.ReffererOrgReferrersList = [];
          $scope.referrerAddList = [];
        }
        $scope.raywhite.oldValue = $scope.raywhite.officeSelected;
        $scope.getRayWhiteAdvisers($scope.raywhite.officeSelected.originalObject);
      }
    });

    $scope.removeRayWhiteMember = function (familyId) {
      $scope.referrerAddList.splice(_.findIndex($scope.referrerAddList, (item) => { return item.id === familyId; }), 1);
      $scope.newReferrer.ReffererOrgReferrersList.splice(_.findIndex($scope.newReferrer.ReffererOrgReferrersList, (item) => { return item.FamilyID === familyId; }), 1);
    };
    $scope.generatedRefStringRaywhite = null;
    $scope.generateRefStringRaywhite = function () {
      const generatedRefString = `Ref_${$scope.raywhite.officeSelected.originalObject.name}_${$scope.raywhiteAdviser.selected.name}`;
      $scope.referrerModel.generatedRefStringRaywhite = generatedRefString;
    };
    $scope.$watch('raywhiteAdviser.selected', () => {
      $scope.generatedRefStringRaywhite = null;
      $scope.referrerModel.generatedRefStringRaywhite = null;
      $scope.raywhiteAdviser.preferredAdviserId = null;
      if (!$scope.referrerModel.isCorporateUser || !$scope.raywhiteAdviser.selected || !$scope.raywhiteAdviser.selected.id) return;
      const referrerPreferred = $scope.referrerModel.preferredAdviserList && $scope.referrerModel.preferredAdviserList.find((adviser) => {
        const splittedIds = adviser.preferredByReferrerIds && adviser.preferredByReferrerIds.split();
        const referrerIds = splittedIds && splittedIds.reduce((accum, value) => {
          accum.push(parseInt(value, 10));
          return accum;
        }, []);
        return referrerIds && referrerIds.indexOf($scope.raywhiteAdviser.selected.id) !== -1;
      });
      $scope.raywhiteAdviser.preferredAdviserId = (referrerPreferred && referrerPreferred.adviserId) || null;
    });
    $scope.selectAdviserBroker = function () {
      if (typeof $scope.newReferrer.AgreementAdviserFamilyId !== 'undefined' && $scope.newReferrer.AgreementAdviserFamilyId) {
        corporateService.corporateContactGet(0, $scope.newReferrer.AgreementAdviserFamilyId).then((response) => {
          const adviserInfo = response.data;
          $scope.newReferrer.pReferrerOrgId = _.isUndefined(adviserInfo.AdviserOrganizationId) ? 0 : adviserInfo.AdviserOrganizationId;
          const data = {
            FamilyId: adviserInfo.FamilyId,
            FirstName: adviserInfo.FirstName,
            MiddleName: '',
            LastName: adviserInfo.LastName,
            Contact: adviserInfo.Mobile,
            Email: adviserInfo.Email,
            GeneratedRefString: null,
          };
          $scope.newReferrer.ReffererOrgReferrersList = [data];
        });
        $scope.generatedRefString.loanMarket = null;
      }
    };
    $scope.generatingRef = false;
    $scope.generateRefStringLoanMarket = function () {
      if (_.size($scope.newReferrer.selectedAdviserObj) > 0) {
        $scope.generatingRef = true;
        const referrerFullName = $scope.newReferrer.selectedAdviserObj.FullName || '';
        let referrerOrgName = '[No Organisation]';
        if (!_.isUndefined($scope.newReferrer.referrerOrgObj) && _.size($scope.newReferrer.referrerOrgObj.originalObject) > 0) {
          referrerOrgName = $scope.newReferrer.referrerOrgObj.originalObject.OrganizationName;
        }
        const tempAdviserOrg = _.filter($scope.orgOwnerGroupList, (data) => {
          return data.OrganizationID === $scope.newReferrer.pAdvisorOrgID;
        });
        const adviserOrgName = tempAdviserOrg.length > 0 ? tempAdviserOrg[0].OrganizationName : '';
        const tempAdviser = _.filter($scope.orgMembersList, (item) => {
          return item.IsChecked;
        });
        if (tempAdviserOrg.length === 0 || tempAdviser.length === 0) {
          $scope.generatingRef = false;
          swal('Generation Error', 'Some field need to complete. Adviser Organisation, Adviser and Loan Broker/Adviser', 'error');
          return false;
        }
        let generatedRefString = `Ref_${referrerOrgName}_${referrerFullName}_${adviserOrgName}`;
        _.map(tempAdviser, (data) => {
          generatedRefString += `_${data.MemberName}`;
        });
        $scope.generatedRefString.loanMarket = generatedRefString;
        $scope.generatingRef = false;
      }
    };
    function changeBroker() {
      $scope.generatedRefString.loanMarket = null;
    }
    $scope.changeBroker = changeBroker;
    $scope.isReferrerRequired = false;
    $scope.checkMember = function (isChecked) {
      if (!isChecked) {
        $scope.referrerModel.isLoanOrWealth = false;
      }
      $scope.isReferrerRequired = false;
      const $adviser = _.filter($scope.orgMembersList, (item) => {
        return item.IsChecked === true;
      });
      if ($adviser !== undefined && $adviser !== null) {
        const ids = _.map(_.toArray($adviser), 'FamilyID');
        if (!$scope.referrerModel.isLoanOrWealth) {
          wealthMarketMember(ids);
        }
        if (!$scope.isReferrerRequired) {
          getBrokerActive(ids);
        }
      }
      processPreferredAdvisers();
    };
    const getAdviserList = (orgId) => {
      if (orgId > 0) {
        // Set True to allow Adviser Login to View Adviser
        corporateService.advisersUnderOrganizationGet(orgId, true).then((response) => {
          $scope.adviserListUnderOrg = response.data;
        });
      }
    };
    $scope.referralModel.oldLmValue = {};
    $scope.$watch('newReferrer.referrerOrgObj', () => {
      $scope.adviserListUnderOrg = [];
      $scope.generatedRefString.loanMarket = '';
      if (!_.isUndefined($scope.newReferrer.referrerOrgObj) && _.size($scope.newReferrer.referrerOrgObj.originalObject) > 0) {
        $scope.newReferrer.pReferrerOrgId = $scope.newReferrer.referrerOrgObj.originalObject.OrganizationID;
        if (_.size($scope.referralModel.oldLmValue) > 0) {
          $scope.newReferrer.ReffererOrgReferrersList = [];
        } else {
          $scope.referralModel.oldLmValue = $scope.newReferrer.referrerOrgObj.originalObject;
        }
        $scope.referrerModel.isNewReferrer = false;
        getAdviserList($scope.newReferrer.pReferrerOrgId);
      } else {
        $scope.newReferrer.pReferrerOrgId = 0;
      }
    });
    const addNewReferrer = () => {
      $scope.referrerModel.isNewReferrer = true;
    };
    const addLMReferrer = (adviserObj) => {
      if (_.size(adviserObj) > 0) {
        const temp = {
          FamilyId: adviserObj.FamilyId,
          pName: adviserObj.FullName,
          FirstName: '',
          MiddleName: '',
          LastName: '',
          Contact: '',
          Email: '',
          GeneratedRefString: $scope.generatedRefString.loanMarket ? $scope.generatedRefString.loanMarket : '',
        };
        $scope.newReferrer.ReffererOrgReferrersList.push(temp);
        $scope.generatedRefString.loanMarket = '';
      }
    };
    const isRefEmpty = (value) => {
      return !_.isUndefined(value) && _.size(value) > 0;
    };
    const removeExistIn = (sourceObj, sourceFieldName, existingListObj, existingFieldName) => {
      const sourceObjReturn = _.clone(sourceObj);
      _.map(existingListObj, (existing) => {
        _.remove(sourceObjReturn, (source) => {
          return source[sourceFieldName] === existing[existingFieldName];
        });
      });

      return sourceObjReturn;
    };
    const removeLMMember = (index) => {
      if ($scope.newReferrer.ReffererOrgReferrersList.length > 0 && _.size($scope.newReferrer.ReffererOrgReferrersList[index]) > 0) {
        $scope.newReferrer.ReffererOrgReferrersList.splice(index, 1);
      }
    };
    const cancelLMReferrer = () => {
      $scope.referrerModel.isNewReferrer = false;
      $scope.generatedRefString.loanMarket = '';
    };
    $scope.adviserOrgForLM = [];
    const getAdviserOrgForLM = () => {
      referralsService.getReferrerOrgOwnerGroup(true).then((response) => {
        if (response.data.length > 0) {
          $scope.adviserOrgForLM = _.filter(response.data, (data) => { return data.OrganizationName !== ''; });
        }
      });
    };
    getAdviserOrgForLM();
    $scope.addNewReferrer = addNewReferrer;
    $scope.addLMReferrer = addLMReferrer;
    $scope.isRefEmpty = isRefEmpty;
    $scope.removeExistIn = removeExistIn;
    $scope.removeLMMember = removeLMMember;
    $scope.cancelLMReferrer = cancelLMReferrer;
    $scope.removeExcessAddress = removeExcessAddress;
    $scope.cancelAgreement = cancelAgreement;
    // Scope Watches
    $scope.$watch('newReferrer.pIsLoanMarketToFacilitate', () => {
      if ($scope.newReferrer.pIsLoanMarketToFacilitate) {
        $scope.setCommission($scope.newReferrer.pReferrerType);
      } else {
        $scope.newReferrer.pUpfrontPercent = 0;
        $scope.newReferrer.pTrailPercent = 0;
      }
    });
    const isSizeEmpty = (obj) => {
      return _.size(obj) === 0;
    };
    $scope.isSizeEmpty = isSizeEmpty;
    $scope.disableCheckbox = (familyId) => {
      const accessDenied = !$scope.isAccessGranted('AdviserOrganisationDetails', 'AdviserCheckbox');
      return accessDenied || $scope.referrerModel.existingAdviserList.indexOf(familyId) !== -1;
    };
    const getBusinessTypes = () => {
      optionsService.getBusinessTypes()
        .then((data) => {
          if (!data || !data.length) return;
          $scope.referralModel.businessTypeList = data;
        });
    };
    $scope.isTrailReadOnly = () => {
      return $scope.newReferrer.pReferrerType === AGREEMENT_REFERRAL_TYPE.INDIVIDUAL || $scope.referrerModel.isLoanOrWealth || $scope.agreementTypeId === AGREEMENT_TYPE.RAY_WHITE;
    };
    getBusinessTypes();
  });
