import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { COLOR } from 'Common/constants/colors';

angular.module('app').controller('PipelineLeadsListDetailModalCtrl', function PipelineLeadsListDetailModalCtrl(
  $scope,
  $uibModalInstance,
  contactService,
  pipelineService,
  toaster,
  $state,
  statusListDdl,
  probabilityList,
  labels,
  checkLabelExistInList,
  deleteLabelFromCard,
  listeners,
  list) {
  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.list = list;
  $scope.probObj = {};
  $scope.lblObj = {};
  $scope.statusObj = {};
  $scope.checkLabelExistInList = checkLabelExistInList;
  $scope.deleteLabelFromCard = deleteLabelFromCard;

  $scope.createdStatuses = statusListDdl;
  $scope.probabilityList = probabilityList;
  $scope.labels = labels;
  $scope.list.list = `${$scope.list.AdviserFirstName} ${$scope.list.AdviserLastName}`;

  $scope.toggleDropdown = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.expandDroqdown = !$scope.expandDroqdown;
  };

  $scope.setProbability = (probObj, listObj) => {
    $scope.probObj = probObj;
    $scope.list = listObj;
    $scope.list.Probability = probObj.probability;
  };

  $scope.selectStatus = (statusObj, listObj) => {
    $scope.list = listObj;
    $scope.statusObj = statusObj;
    $scope.statusName = statusObj.PipelineStatus;
    $scope.list.PipelineStatus = $scope.statusName;
  };

  $scope.setOrRemoveLabelForCardInModal = (lblObj, listObj) => {
    $scope.list = listObj;
    $scope.lblObj = lblObj;
    if ($scope.list && $scope.lblObj) {
      const lbl = {};
      if (!lblObj.ColorName) {
        lblObj.ColorName = COLOR.APRICOT;
      }
      lbl.labelColor = lblObj.ColorName;
      lbl.labelID = lblObj.pipelineSettingsLabelID.toString();
      lbl.labelName = lblObj.LabelName;
      const index = _.findIndex($scope.list.LabelArray, { labelID: lbl.labelID });
      if (index > -1) {
        $scope.list.LabelArray.splice(index, 1);
        $scope.deleteLabelFromCard($scope.lblObj, $scope.list);
      } else {
        $scope.list.LabelArray.push(lbl);
        $scope.setLbl($scope.lblObj, $scope.list);
      }
    }
  };

  $scope.moveCardOnSidebar = (leads, convertionStatus) => {
    if (listeners && listeners.onMoveCardOnSidebar) {
      listeners.onMoveCardOnSidebar(leads, convertionStatus);
    }
  };

  $scope.save = () => {
    if ($scope.probObj && $scope.probObj.probability) {
      $scope.setProb($scope.probObj, $scope.list);
    }
    if ($scope.statusObj && $scope.statusObj.PipelineStatus) {
      $scope.setStat($scope.statusObj, $scope.list, '');
    }
    $uibModalInstance.dismiss('close');
  };

  $scope.setProb = (probObj, listObj) => {
    $scope.probabilityToSet = { cardID: listObj.PipelineCardsID, probability: probObj.probability };
    pipelineService
      .PipelineCardsProbabilitySet($scope.probabilityToSet)
      .then(() => {
        toaster.pop('success', 'Added', 'Probability has been added successfully.');
        if (listeners && listeners.onProbabilitySet) {
          listeners.onProbabilitySet();
        }
      })
      .catch(toastError);
  };

  $scope.setStat = (statusObj, listObj) => {
    listObj.PipelineStatus = statusObj.PipelineStatus;
    pipelineService
      .PipelineCards(listObj.PipelineCardsID, {
        PipelineStatusID: statusObj.PipelineStatusID,
      })
      .then((response) => {
        toaster.pop('success', 'Set', 'Stage has been updated successfully');
        if (response.data && response.data.Result) {
          listObj.LastModifiedDate = response.data.LastUpdate;
        }
        if (listeners && listeners.onStatusChanged) {
          listeners.onStatusChanged();
        }
      })
      .catch(toastError);
  };

  $scope.setLbl = (lblObj, listObj) => {
    $scope.pipelineCardsLabelSet = { cardID: listObj.PipelineCardsID, labelsID: lblObj.pipelineSettingsLabelID };

    pipelineService
      .PipelineCardsLabelsSet($scope.pipelineCardsLabelSet)
      .then(() => {
        toaster.pop('success', 'Added', 'Label has been added successfully.');
        listObj.Labels = lblObj.ColorName;
        if (listeners && listeners.onLabelSet) {
          listeners.onLabelSet();
        }
      })
      .catch(toastError);
  };

  $scope.filterInitial = (...args) => {
    if (!args || !args[0]) {
      return;
    }

    let initials = '';
    for (let i = 0; i < args.length; i++) {
      if (!_.isNil(args[i]) && _.isString(args[i])) {
        initials = `${initials}${args[i].charAt(0).toUpperCase()}`;
      }
    }
    return initials;
  };
});
