import angular from 'angular';

// filter to accept and evaluate to add years
angular.module('app').filter('years', function years() {
  return function (text) {
    const value = parseInt(text, 10);
    return value > 1 ? `${value} years` : `${value} year`;
  };
}
);
