import angular from 'angular';

angular.module('app').controller('PostSettlementDetailsModalCtrl', function PostSettlementDetailsModalCtrl($scope,
  $uibModalInstance,
  postSettlement) {
  $scope.postSettlement = postSettlement;
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
