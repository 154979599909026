import angular from 'angular';

angular.module('app').directive('scrollx', ['$timeout', function scrollx($timeout) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const el = element[0];


      element.bind('scroll', () => {
        const personLabel = el.querySelector('thead > tr').firstElementChild;
        const dataLabel = el.querySelectorAll('tbody > tr');

        personLabel.style.left = `${el.scrollLeft}px`;

        for (let i = 0; i < dataLabel.length; i++) {
          dataLabel[i].firstElementChild.style.left = `${el.scrollLeft}px`;
        }

        if (el.scrollLeft + el.offsetWidth > el.offsetWidth) {
          $timeout(attrs.scrollx);
        }
      });
    },
  };
}]);
