export default class EmailBodyFieldCtrl {
  $onInit() {
    this.editorOptions = {
      language: 'en',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 160,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['BGColor'] }, { items: ['JustifyLeft'] }, { items: ['BulletedList', '-'] }, { items: ['Link'] },
        { items: ['Image'] }, { items: ['Table'] }, { items: ['Paste', '-'] }, { items: ['Smiley'] }, { items: ['-'] },
        '/',
        { items: ['Format'] }, { items: ['Font'] }, { items: ['FontSize', '-'] }, { items: ['Bold'] }, { items: ['Italic'] }, { items: ['Underline'] },
        { items: ['Subscript'] }, { items: ['Superscript'] },
      ],
    };
  }
}
