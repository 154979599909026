import { forEach, map, filter } from 'lodash';

class generalSearchService {
  constructor($timeout, $q, dashboardService) {
    'ngInject';

    this.timeout = $timeout;
    this.dashboardService = dashboardService;
    this.q = $q;
    this.defaultListLimit = 3;
    this.limit = {
      client: this.defaultListLimit,
      adviser: this.defaultListLimit,
      adviserOrg: this.defaultListLimit,
      referrer: this.defaultListLimit,
      referrerOrg: this.defaultListLimit,
      assistant: this.defaultListLimit,
    };
  }

  getClientResult(response, generalSearchText) {
    const defer = this.q.defer();
    const responseConfig = response.config;
    const searchCriteria = responseConfig.params && responseConfig.params.searchCriteria;
    if (searchCriteria !== generalSearchText) return false;

    const data = response.data;
    this.generalSearchResult = {
      allResults: [],
    };

    forEach(data, (row) => {
      if (row.Result.length > 0) this.generalSearchResult.allResults = [...this.generalSearchResult.allResults, ...row.Result];
    });

    forEach(this.generalSearchResult.allResults, (item) => {
      map(item.list = filter(this.generalSearchResult.allResults,
        (obj) => {
          return obj.ResultType === item.type;
        })
      );
    });

    if (this.generalSearchResult) {
      defer.resolve(this.generalSearchResult);
    } else {
      defer.reject();
    }

    return defer.promise;
  }

  processClientSearch(allResults) {
    this.familyDetailsList = [];
    allResults.forEach((client) => {
      if (client.ResultType === 'Client' || client.ResultType === 'Entity' || client.ResultType === 'Name') {
        const result = {
          FamilyFullName: client.ResultName || client.ProviderName || client.ResultContent,
          FamilyId: client.ResultFamilyID,
          FamilyType: client.ResultType,
          ClientId: client.ClientId,
        };
        this.familyDetailsList.push(result);
      }
    });
    return this.familyDetailsList;
  }
  searchFamilyInput(scope, search) {
    if (scope.mainObject) {
      scope.mainObject.isSearchEnable = typeof scope.mainObject.isSearchEnable !== 'undefined' && scope.mainObject.isSearchEnable !== null;
    }
    const searchString = search || (scope.taskInput && scope.taskInput.familyValue);
    scope.familyDetailsList = [];
    if (!searchString) return;
    return this.dashboardService.getGenralSearchResult(searchString)
      .then((response) => {
        return this.getClientResult(response, searchString);
      }).then((searchResult) => {
        scope.familyDetailsList = this.processClientSearch(searchResult.allResults);
        scope.showMsg = scope.familyDetailsList.length < 1;
      });
  }
}

export default generalSearchService;
