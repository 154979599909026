
import moment from 'moment';

export function resourceMyDocumentsBuilderForUI(data) {
  return {
    documentFileID: data.DocumentFileID,
    documentID: data.DocumentID,
    title: data.Title,
    writtenBy: data.WrittenBy,
    dateUploaded: moment(data.DateUploaded, 'YYYY-MM-DD ').format('DD MMM YYYY'),
    description: data.Description,
    version: data.Version,
  };
}

export function resourceMyDocumentsBuilderForMyCRM(data) {
  return {
    description: data.description,
    documentFileId: data.documentFileID,
    documentId: data.documentID,
    title: data.title,
    version: data.version,
  };
}
export function maximumLVRBuilderForUI(maximumLVR) {
  return {
    bridging: maximumLVR.Bridging,
    commercial: maximumLVR.Commercial,
    companyShare: maximumLVR.CompanyShare,
    construction: maximumLVR.Construction,
    countryCode: maximumLVR.CountryCode,
    equity: maximumLVR.Equity,
    equityFinance: maximumLVR.EquityFinance,
    holidayHome: maximumLVR.HolidayHome,
    investment: maximumLVR.Investment,
    loc: maximumLVR.LOC,
    leasehold: maximumLVR.Leasehold,
    lenderID: maximumLVR.LenderID,
    lenderName: maximumLVR.LenderName,
    lifestyleBlockL10ha: maximumLVR.LifestyleBlockL10ha,
    lifestyleBlockL15haVNS: maximumLVR.LifestyleBlockL15haVNS,
    lifestyleBlockL15haVS: maximumLVR.LifestyleBlockL15haVS,
    luxury: maximumLVR.Luxury,
    nonStandardApartment: maximumLVR.NonStandardApartment,
    ownerOccupied: maximumLVR.OwnerOccupied,
    partialBuildContract: maximumLVR.PartialBuildContract,
    pensionerflatsretirementvillage: maximumLVR.Pensionerflatsretirementvillage,
    propertyDevelopment: maximumLVR.PropertyDevelopment,
    residentialLeaseholdProperty: maximumLVR.ResidentialLeaseholdProperty,
    reverse: maximumLVR.Reverse,
    ruralLifestyleBlocks10ha: maximumLVR.RuralLifestyleBlocks10ha,
    ruralLifestyleG10: maximumLVR.RuralLifestyleG10,
    standardApartment: maximumLVR.StandardApartment,
    standardApartmentsL50: maximumLVR.StandardApartmentsL50,
    standardResidential: maximumLVR.StandardResidential,
    standardResidentialNonResident: maximumLVR.StandardResidentialNonResident,
    terracedHousing: maximumLVR.TerracedHousing,
    vacantLand: maximumLVR.VacantLand,
    vacantLandServiced: maximumLVR.VacantLandServiced,
    vacantLandUnserviced: maximumLVR.VacantLandUnserviced,
  };
}

export function offsetPaymentChartBuilderForUI(data) {
  return {
    principalToPaywithOffset: data.PrincipalToPaywithOffset,
    principalToPaywithoutOffset: data.PrincipalToPaywithoutOffset,
  };
}

export function repaymentHistoryBuilderForUI(data) {
  return {
    period: data.Period,
    repayment: data.Repayment,
    principalPaid: data.PrincipalPaid,
    interestPaid: data.InterestPaid,
    accumulativePrincipalPaid: data.AccumulativePrincipalPaid,
    accumulativeInterestPaid: data.AccumulativeInterestPaid,
    principalToPay: data.PrincipalToPay,
  };
}

export function offsetCalculatorBuilderForUI(data) {
  return {
    offsetPaymentChart: data.OffsetPaymentChart.map(offsetPaymentChartBuilderForUI),
    repaymentHistory: data.RepaymentHistory.map(repaymentHistoryBuilderForUI),
    interestDifference: data.InterestDifference,
    periodDifference: data.PeriodDifference,
  };
}

export function discountTimePeriodBuilderForUI(timePeriod) {
  return {
    name: timePeriod.Name,
    value: timePeriod.Value,
  };
}
