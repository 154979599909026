export const LIST_OF_QUESTIONS = {
  DEPOSIT_SOURCE: 6,
  SPECIFIC_GOALS: 9,
  BANKRUPT: 10,
  BANKRUPT_NOTE: 11,
  CURRENT_DEBT: 12,
  CURRENT_DEBT_NOTE: 13,
  CIRCUMSTANCE: 14,
  CIRCUMSTANCE_NOTE: 15,
  OTHER_LOAN: 16,
  OTHER_LOAN_NOTE: 17,
  RISK_INSURANCE: 18,
  RISK_INSURANCE_NOTE: 19,
  HOME_INSURANCE: 20,
  HOME_INSURANCE_NOTE: 21,
  DEPOSIT_AMOUNT: 37,
  WHAT_ARE_YOU_WANTING_TO_DO: 43,
  IMPORTANT_DATES: 44,
  IMPORTANT_DATES_NOTE: 45,
  BANKING_WITH: 46,
  HAPPY_WITH_CURRENT_BANK: 47,
  HAPPY_WITH_CURRENT_BANK_NOTE: 48,
  REASON_FOR_TOP_UP: 108,
  BANK_TO_REFENANCE_FROM: 109,
  DIRECTOR_FOR_YEARS: 122,
  DIRECTOR_FOR_YEARS_NOTE: 123,
  RATE_PREFERENCES: 135,
  CHOOSE_RATE_PREFERENCE: 136,
  RATE_DURATION: 137,
  RATE_PREFERENCES_IMPORTANT: 138,
  RATE_IMPORTANT_DETAILS: 139,
  SPECIAL_RATE_PREFERENCES: 146,
  SPECIAL_CHOOSE_RATE_PREFERENCE: 147,
  SPECIAL_RATE_PREFERENCES_IMPORTANT: 148,
  SPECIAL_RATE_IMPORTANT_DETAILS: 149,
  REPAYMENT_PREFERENCE: 150,
  CHOOSE_REPAYMENT_PREFERENCE: 151,
  REPAYMENT_FREQUENCY: 152,
  REPAYMENT_INTEREST_ONLY: 153,
  REPAYMENT_OPTION: 154,
  REPAYMENT_IMPORTANT: 155,
  REPAYMENT_DETAILS: 164,
  REPAYMENT_OPTION_DETAILS: 165,
  CONFLICT_DECLARATION: 166,
  CONFLICT_DECLARATION_DETAILS: 167,
  OTHER_REQUIREMENTS_AND_OBJECTIVES: 168,
  OTHER_REQUIREMENTS_DETAILS: 169,
};

export const DISPLAY_OPTIONS = {
  YES_NO_ONLY: 1,
  TEXT_ONLY: 2,
  TEXTBOX_ON_YES: 3,
  TEXTBOX_ON_NO: 4,
  SINGLE_DROPDOWN: 5,
  MULTI_DROPDOWN: 6,
  SINGLE_DROPDOWN_NO_FRAME: 7,
  CHECK_BOX: 8,
  TEXTBOX_WITH_DROPDOWN: 9,
  TEXT_FLOAT: 10,
  RADIO_BOX_ON_YES: 11,
  RADIO_BOX_ON_NO: 12,
  SINGLE_DROPDOWN_STRING: 14,
};

export const STRING_OPTION = [
  DISPLAY_OPTIONS.TEXT_ONLY,
  DISPLAY_OPTIONS.TEXTBOX_ON_YES,
  DISPLAY_OPTIONS.TEXTBOX_ON_NO,
  DISPLAY_OPTIONS.SINGLE_DROPDOWN_STRING,
];

export const BOOL_OPTION = [
  DISPLAY_OPTIONS.YES_NO_ONLY,
  DISPLAY_OPTIONS.CHECK_BOX,
  DISPLAY_OPTIONS.RADIO_BOX_ON_YES,
  DISPLAY_OPTIONS.RADIO_BOX_ON_NO,
];

export const INT_OPTION = [
  DISPLAY_OPTIONS.SINGLE_DROPDOWN,
  DISPLAY_OPTIONS.MULTI_DROPDOWN,
  DISPLAY_OPTIONS.SINGLE_DROPDOWN_NO_FRAME,
];

export const DECIMAL_OPTION = [
  DISPLAY_OPTIONS.TEXT_FLOAT,
];

export const ANSWER_TYPE = {
  ANSWER_STRING: 'answerString',
  ANSWER_BOOL: 'answerBool',
  ANSWER_INT: 'answerInt',
  ANSWER_DECIMAL: 'answerDecimal',
};

export const RATE_TYPE = {
  FIXED: 'fixed',
  VARIABLE: 'variable',
  SPLIT: 'split',
};

export const RATE_IMPORTANT = {
  RISK: 'Risk',
  POTENTIAL_RATE_DECREASE: 'PotentialRateDecrease',
  RATE_RISK_AND_BENEFITS: 'RateRiskAndBenefits',
  PAYMENT_FLEXIBILITY: 'PaymentFlexibility',
  BUDGETING: 'Budgeting',
  OTHER: 'Other',
};

export const SPECIAL_RATE_IMPORTANT = {
  FAST_REPAYMENTS: 'FastRepayments',
  FAST_ACCESS_TO_FUNDS: 'FastAccessToFunds',
  FAX_PURPOSES: 'TaxPurposes',
  OTHER: 'Other',
};

export const REPAYMENT_OPTION = {
  LOAN_PRIOR: '1',
  DOWNSIZING: '2',
  INVESTMENT_INCOME: '3',
  CO_APPLICANT_INCOME: '4',
  OTHER: 'Other',
};

export const REPAYMENT_IMPORTANT = {
  MINIMISE: '1',
  HIGHER: '2',
  LOWER: '3',
  BUILD: '4',
  OTHER: 'Other',
};

export const RATE_TYPE_IMPORTANT = {
  FIXED: [
    RATE_IMPORTANT.RISK,
    RATE_IMPORTANT.BUDGETING,
    RATE_IMPORTANT.OTHER,
  ],
  VARIABLE: [
    RATE_IMPORTANT.POTENTIAL_RATE_DECREASE,
    RATE_IMPORTANT.PAYMENT_FLEXIBILITY,
    RATE_IMPORTANT.OTHER,
  ],
  SPLIT: [
    RATE_IMPORTANT.RATE_RISK_AND_BENEFITS,
    RATE_IMPORTANT.PAYMENT_FLEXIBILITY,
    RATE_IMPORTANT.BUDGETING,
    RATE_IMPORTANT.OTHER,
  ],
};

export const REPAYMENT_PREFERENCE = {
  PRINCIPAL_AND_INTEREST: '1',
  INTEREST_ONLY_REPAYMENTS: '2',
  INTEREST_IN_ADVANCE: '3',
  LINE_OF_CREDIT: '4',
};
