import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';

angular.module('app').controller('OpenEditlabelModalCtrl',
  function OpenEditlabelModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    $state,
    labels,
    pipelineService,
    toaster,
    $rootScope
  ) {
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.save = () => {
      $scope.cancel();
    };

    $scope.makeEditable = (labelObj, $index) => {
      $scope.clickedIndex = $index;
      labelObj.editLabel = true;
    };
    $scope.addnewPanelForLabel = () => {
      $scope.addLabel = true;
      $scope.labelName = '';
    };

    function initLabels() {
      $scope.addLabelsTempArray = [];
      $scope.insertLabelsTempArray = [];
      $scope.updateLabelsTempArray = [];
      $scope.prepareLabelSetArray = [];
      $scope.labelList = labels;
      $scope.addLabelsTempArray = angular.copy($scope.labelList);
    }

    initLabels();
    $scope.updateTempLabel = (labelObject) => {
      if (labelObject.pipelineSettingsLabelID !== undefined) {
        for (let i = 0; i < $scope.addLabelsTempArray.length; i++) {
          if (labelObject.pipelineSettingsLabelID === $scope.addLabelsTempArray[i].pipelineSettingsLabelID) {
            $scope.addLabelsTempArray[i].LabelName = labelObject.LabelName;
            $scope.addLabelsTempArray[i].pipelineSettingsLabelID = labelObject.pipelineSettingsLabelID;
            $scope.addLabelsTempArray[i].LabelID = labelObject.pipelineSettingsLabelID;
          }
        }
        labelObject.editLabel = false;
      } else {
        $scope.addLabel = false;
        $scope.newLabel = {};
        $scope.newLabel.LabelName = labelObject;
        $scope.newLabel.pipelineSettingsLabelID = 0;
        $scope.newLabel.temp = true;
        $scope.addLabelsTempArray.push($scope.newLabel);
      }
    };
    $scope.saveLables = () => {
      $scope.labelsToSave = [];

      for (let i = 0; i < $scope.addLabelsTempArray.length; i++) {
        const lbl = {};
        lbl.LabelID = $scope.addLabelsTempArray[i].pipelineSettingsLabelID;
        lbl.LabelName = $scope.addLabelsTempArray[i].LabelName;
        $scope.labelsToSave.push(lbl);
      }
      pipelineService.PipelineSettingsLabelSet($scope.labelsToSave)
        .then(() => {
          toaster.pop('success', 'Added', 'Label has been added successfully.');
          $rootScope.$broadcast('_initListView');
          $scope.filterProbability($scope.probabilityObj);
          pipelineService.PipelineSettingsLabel()
            .then((pipelineSettingsLabelResponse) => {
              $scope.labelList = pipelineSettingsLabelResponse.data;
              $scope.addLabelsTempArray = angular.copy($scope.labelList);
            }, () => {});
          $scope.cancel();
          $scope.getAllLabels(() => {});
        }, () => {
          toastError();
        });
      // /Pipeline/PipelineSettingsLabelSet
    };
    $scope.deleteLabel = (labelObj, index) => {
      if (labelObj.pipelineSettingsLabelID !== undefined) {
        $scope.deletelbl = {};
        $scope.deletelbl.LabelID = labelObj.pipelineSettingsLabelID;
        pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(() => {
          toaster.pop('success', 'Deleted', 'Label has been deleted successfully.');
          $scope.labelsToDelete = $scope.addLabelsTempArray.filter((item) => {
            return labelObj.pipelineSettingsLabelID === item.pipelineSettingsLabelID;
          });
          const b = _.indexOf($scope.addLabelsTempArray, $scope.labelsToDelete);
          $scope.addLabelsTempArray.splice(b, 1);
          $scope.filterProbability($scope.probabilityObj);
          $scope.getAllLabels(() => {});
          $rootScope.$broadcast('_initListView');
          $rootScope.$broadcast('getAllLabels');
        }, () => {
          toastError();
        });
      } else if (labelObj === '' || index === '') {
        $scope.addLabel = false;
        $scope.labelName = '';
      } else {
        $scope.addLabelsTempArray.splice(index, 1);
      }
    };
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
