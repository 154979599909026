import angular from 'angular';

angular.module('app').controller('PipelineStatusMoveConfirmationCtrl', function PipelineStatusMoveConfirmationCtrl(
  $uibModalInstance,
  modalContent,
  $scope
) {
  $scope.locals = modalContent;
  $scope.ok = function (params) {
    $uibModalInstance.close({ isOk: true, ...params });
  };

  $scope.cancel = function (params) {
    $uibModalInstance.close({ isOk: false, ...params });
  };
});
