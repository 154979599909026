import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

export function opportunityConvert(pipelineSharedData, modalRenderService, loanOpportunityService, pipelineStatusId, opportunityId, reasonList) {
  const movedToStatus = pipelineStatusId === PIPELINE_STATUS.APPLICATION ? 'Application' : 'Not Proceeded';
  const isApproveApplication = pipelineStatusId === PIPELINE_STATUS.APPLICATION;
  const modalContent = { content: `This record will be moved to ${movedToStatus}` };
  if (!isApproveApplication) {
    modalContent.reasonList = reasonList;
  }
  return modalRenderService.renderPipelineStatusMoveConfirmationModal(modalContent)
    .result
    .then((result) => {
      const { isOk } = result;
      if (!isOk) return false;

      const { selectedReasonId } = result;
      const conversionStatus = { ConversionStatusId: pipelineStatusId, ReasonSubStatusId: selectedReasonId || 0 };
      return loanOpportunityService.setConversion(opportunityId, conversionStatus);
    })
    .then((renderResponse) => {
      if (!renderResponse) return { isOk: false };

      return isApproveApplication && !renderResponse.data.IsVisibleInPipeline ? modalRenderService.cardHiddenInApplication(renderResponse.data.FirstFamilyBorrowerName).result : { isOk: true };
    });
}

export function leadsToOpportunityConvert(clientFamilyId, contactService, opportunityNewModalService, redirectParams = {}, pipelineItemId) {
  return contactService.clientInformGet(clientFamilyId)
    .then((res) => {
      const { data } = res;
      if (!data) return;

      const clients = data.filter(obj => (obj.Role === 'Adult' || obj.isEntity)) || [];

      return opportunityNewModalService.openNewOpportunityModal(clientFamilyId, clients, redirectParams, pipelineItemId);
    });
}
