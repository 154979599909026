import { OpportunityNotes } from 'Common/services/opportunityNotes';
import { getTextCharacterLimit } from 'Common/utilities/opportunityNotes';
import { toastError, displayError, toastSuccess } from 'Common/utilities/alert';

export default class OpportunityNotesModalCtrl {
  constructor(
    loanOpportunityService,
    referralsService,
    contactService
  ) {
    'ngInject';

    this.loanOpportunityService = loanOpportunityService;
    this.referralsService = referralsService;
    this.contactService = contactService;
  }

  $onInit() {
    this.opportunityNotes = new OpportunityNotes(this.noteObj);
    this.noteObj = this.opportunityNotes.note;
    this.characterCount = `0 / 140`;
    this.onLoadCheckSharingOptions();
  }

  onLoadCheckSharingOptions() {
    const minimumNumberLength = 8;
    const isNumberValid = this.noteObj && this.noteObj.smsObj && this.noteObj.smsObj.mobile && this.noteObj.smsObj.mobile.length > minimumNumberLength;
    const isEmailValid = this.noteObj && this.noteObj.emailObj && this.noteObj.emailObj.referrerEmail && this.noteObj.emailObj.referrerEmail.length;

    this.disableShareSms = !(this.referralId && this.isSmsEnabled && isNumberValid);
    this.disableShareEmail = !(this.referralId && isEmailValid);
  }


  back() {
    this.modalInstance.dismiss('cancel');
  }

  saveNote() {
    this.isSaving = true;
    const { communicationId } = this.noteObj;
    const method = communicationId ? 'updateLoanOpportunityNote' : 'setLoanOpportunityNote';
    this.loanOpportunityService[method](this.noteObj, communicationId)
      .then((response) => {
        const { communicationId: resCommunicationId } = response;
        if (resCommunicationId) {
          if (this.noteObj.referrerShareSms) this.shareToReferrerViaSms(resCommunicationId);
          if (this.noteObj.referrerShareEmail) this.shareToReferrerViaEmail(resCommunicationId);
          this.modalInstance.close();
        } else {
          toastError();
          this.isSaving = false;
        }
      })
      .catch((err) => {
        displayError(err);
      });
  }

  shareToReferrerViaSms(communicationId) {
    const { smsObj } = this.noteObj;
    this.loanOpportunityService.shareReferrerViaSms(communicationId, smsObj)
      .then(({ Succeeded: succeeded }) => {
        if (succeeded) toastSuccess(`Successfully sent SMS to Referrer`);
      });
  }

  shareToReferrerViaEmail(communicationId) {
    const { emailObj } = this.noteObj;
    this.loanOpportunityService.shareReferrerViaEmail(communicationId, emailObj)
      .then(({ Succeeded: succeeded }) => {
        if (succeeded) toastSuccess('Successfully sent Email to Referrer');
      });
  }

  changeSmsBody(textAreaModel) {
    this.noteObj.smsObj.body = textAreaModel;
    this.getCharacterCount();
  }

  getCharacterCount() {
    const currentCharacterCount = (this.noteObj && this.noteObj.smsObj.body && this.noteObj.smsObj.body.length) || 0;
    const baseCharacterLimit = 140;
    const characterLimit = getTextCharacterLimit(baseCharacterLimit, currentCharacterCount);
    this.characterCount = `${currentCharacterCount} / ${characterLimit}`;
  }

  changeEmailObj(emailModel) {
    this.noteObj.emailObj = { ...emailModel };
  }
}
