import angular from 'angular';

angular.module('app').filter('initialOneString', function initialOneString() {
  return function (pStr) {
    let str = pStr;
    let initials = '';
    str = String(str);
    if (str) {
      const stringArray = str.split(/(\s+)/);

      let i = 0; // first word
      initials = stringArray[i].charAt(0).toUpperCase();

      i = stringArray.length - 1; // last word
      if (i > 0)
        initials += stringArray[i].charAt(0).toUpperCase();
    }

    return initials;
  };
}
);
