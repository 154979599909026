export default class ContactInfoLink {
  $onInit() {
    this.initialize();
  }

  $onChange() {
    this.initialize();
  }

  initialize() {
    this.formattedLink = this.getFormattedLink();
  }

  getFormattedLink() {
    switch (this.type) {
    case 'email':
      return `mailto:${this.link}`;
    case 'phone':
      return `tel:${this.link}`;
    default:
      return this.link;
    }
  }
}
