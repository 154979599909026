import angular from 'angular';

angular.module('app').factory('regionalizationModel', ['httpRequest', function (httpRequest) {
  var apiBaseUrl = '/';

  function regionalizationModel() {
  }

  regionalizationModel.prototype = {
    GetBrokerRegionalization(brokerId, callback, errorCallback) {
      httpRequest.get(`${apiBaseUrl}BrokerRegionalizationGet?brokerID=${brokerId}`, (response) => {
        return callback(response);
      }, (e) => {
        return errorCallback(e);
      });
    },

    MembershipRolesGet(callback) {
      httpRequest.get(`${apiBaseUrl}MembershipRolesGet`, (response) => {
        return callback(response);
      });
    },

    AddressGet(addressID, callback) {
      httpRequest.get(`${apiBaseUrl}AddressGet?addressID=${addressID}`, (response) => {
        return callback(response);
      });
    },
  };

  return regionalizationModel;
}]);
