import angular from 'angular';

// remove duplicate objects from array
angular.module('app').filter('unique', function unique() {
  return function (arr, key) {
    const distinct = [];
    const uniq = [];
    angular.forEach(arr, (a) => {
      if (uniq.indexOf(a[key]) === -1) {
        uniq.push(a[key]);
        distinct.push(a);
      }
    });
    return distinct;
  };
});
