
export default class BenefeciaryFieldCtrl {
  constructor(
    utilitiesService
  ) {
    'ngInject';

    this.utilitiesService = utilitiesService;
  }

  changeShowInput() {
    this.showInput = !this.showInput;
    this.beneficiary.isSubmitted = false;
  }

  submitBeneficiary() {
    if (!this.beneficiaryName) {
      return false;
    }
    this.changeShowInput();
    this.beneficiary.BeneficiaryName = this.beneficiaryName;
    this.beneficiary.isSubmitted = true;
    this.formHasError = false;
    this.beneficiaryInitials = this.utilitiesService.filterInitialOneString(this.beneficiary.BeneficiaryName);
  }

  $onInit() {
    this.showInput = true;
    if (this.beneficiary.Id) {
      this.showInput = false;
      this.beneficiary.isSubmitted = true;
    }
    const { BeneficiaryName } = this.beneficiary;
    this.beneficiaryName = BeneficiaryName;
    this.beneficiaryInitials = this.utilitiesService.filterInitialOneString(this.beneficiary.BeneficiaryName);
  }
}
