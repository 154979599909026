
app.factory('questionOptionService', ['$q', function ($q) {
  var factory = this;

  factory.loanAppPurpose = null;
  factory.lenderId = null;
  factory.isDocumentUpdated = null;
  factory.documentListLenderWorksheet = null;

  /**
     * Get the option id based on Question Type - alternative if api will not return
     *
     * @param questionId
     * @param questionObj
     * @returns {*}
     */
  factory.getDisplayOptionId = function (questionId, questionObj) {
    var questionObjValue = _.find(questionObj, (obj) => {
      return obj.QuestionId === questionId;
    });
    if (questionObjValue) {
      switch (questionObjValue.QuestionDisplayType) {
      case 'YesNoOnly':
        return 1;
      case 'TextOnly':
        return 2;
      case 'TextboxOnYes':
        return 3;
      case 'TextboxOnNo':
        return 4;
      case 'SingleDropDown':
        return 5;
      case 'MultiDropDown':
        return 6;
      case 'SingleDropDownNoFrame':
        return 7;
      case 'CheckBox':
        return 8;
      case 'RadioButton':
        return 9;
      case 'TextFloat':
        return 10;
      default:
        return null;
      }
    } else {
      return null;
    }
  };

  /**
     * Get the answer type
     *
     * @param questionId
     * @param questionObj
     * @returns {*}
     */
  factory.getAnswerType = function (questionId, questionObj) {
    var questionObjValue = _.find(questionObj, (obj) => {
      return obj.QuestionId === questionId;
    });
    if (questionObjValue) {
      switch (questionObjValue.QuestionDisplayType) {
      case 'YesNoOnly':
        return 'answerBool';
      case 'TextOnly':
        return 'answerString';
      case 'TextboxOnYes':
        return 'answerString';
      case 'TextboxOnNo':
        return 'answerString';
      case 'SingleDropDown':
        return 'answerInt';
      case 'MultiDropDown':
        return 'answerInt';
      case 'SingleDropDownNoFrame':
        return 'answerInt';
      case 'CheckBox':
        return 'answerBool';
      case 'RadioButton':
        return 'answerBool';
      case 'TextFloat':
        return 'answerDecimal';
      default:
        return null;
      }
    } else {
      return null;
    }
  };

  factory.setAnswerValue = function (type, value) {
    switch (type) {
    case 'answerString':
      return String(value);
    case 'answerInt':
      return parseInt(value);
    case 'answerBool':
      return Boolean(value);
    case 'answerDecimal':
      return parseFloat(value);
    default:
      return null;
    }
  };

  return factory;
}]);
