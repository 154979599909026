import angular from 'angular';

angular.module('app').controller('QuoteSettingsCtrl',
  function QuoteSettingsCtrl(
    $scope,
    $uibModalInstance,
    insuranceQuoteService,
    toaster,
    $window
  ) {
    $scope.data = {
      isMobileDisplayView: false,
      form: {
        Trauma: {},
        Health: {},
        IP75: {},
        IP55: {},
        Mortgage: {},
      },
      formSelect: {},
      modalTitle: 'Quote Settings',
      quoteSettingsObjList: [],
      quoteSettings: [],
      isSaving: false,
    };

    $scope.dataLoaded = false;
    $scope.slickConfig = { method: {} };

    $scope.$watch(
      () => {
        $scope.windowWidth = $window.innerWidth;
        if ($scope.windowWidth > 992) {
          $scope.isMobileDisplayView = false;
        } else if ($scope.windowWidth <= 992) {
          $scope.isMobileDisplayView = true;
        }
        return $scope.windowWidth;
      }
    );

    const quoteSettingsGet = () => {
      insuranceQuoteService.insuranceQuoteUserSettingsGet().then((response) => {
        const data = response.data;
        if (typeof data !== 'undefined') {
          $scope.data.quoteSettingsObjList = data;
          $scope.dataLoaded = true;
        } else {
          toaster.pop('error', 'User Settings Fetch Error', 'Failed to load User Quote Settings.');
          return [];
        }
      });
    };

    /*
      @name: formInit
      @desc: initializes the quote setting modal form
      @param: obj: ProductEntityList, benefit: BenefitName, index: quoteSettingsObjList object index
      @return: assign value to ng-model as default selected
  */
    $scope.formInit = (obj, objTitle) => {
      if (obj) {
        $scope.data.formSelect[objTitle] = {};
        const filteredObj = obj.filter(o => o.IsSelected);
        const opt = filteredObj[0] || {};
        if (opt && opt.ProductId) {
          $scope.data.formSelect[objTitle].productId = opt.ProductId;
        } else {
          $scope.data.formSelect[objTitle].productId = obj[0].ProductId;
        }
      }
    };

    const newSetting = (obj, isSelected) => {
      return {
        SettingID: obj.SettingID,
        ProviderID: obj.ProviderId,
        IsActive: isSelected || obj.IsSelected,
      };
    };

    /*
      @name: changeSelection
      @desc: update $scope.data.quoteSettingsObjList model based on user selection
      @param: providerObj, productId, benefit
      @return: none
  */
    $scope.changeSelection = (providerObj, productId, benefit) => {
      const qouteSettings = $scope.data.quoteSettings;

      if (qouteSettings && qouteSettings.length > 0) {
        const fltrd = qouteSettings.filter(qs => qs.SettingID === providerObj.SettingID);
        if (fltrd && fltrd.length > 0) {
          angular.forEach(qouteSettings, (s) => {
            if (s.SettingID === providerObj.SettingID) {
              s[benefit] = productId;
              s.IsActive = providerObj.IsSelected;
            }
          });
          $scope.data.quoteSettings = qouteSettings;
        } else {
          const setting = newSetting(providerObj);
          setting[benefit] = productId;
          $scope.data.quoteSettings.push(setting);
        }
      } else {
        const setting = newSetting(providerObj);
        setting[benefit] = productId;
        $scope.data.quoteSettings.push(setting);
      }
    };

    $scope.toggleSelection = (providerObj, isSelected) => {
      let hasModelUpdated = false;
      const qtSetting = $scope.data.quoteSettings;

      if (qtSetting && qtSetting.length > 0) {
        angular.forEach(qtSetting, (qs) => {
          if (qs.SettingID === providerObj.SettingID) {
            qs.IsActive = isSelected;
            hasModelUpdated = true;
          }
        });
        if (!hasModelUpdated) {
          qtSetting.push(newSetting(providerObj, isSelected));
        }
      } else {
        const setting = {
          SettingID: providerObj.SettingID,
          ProviderID: providerObj.ProviderId,
          IsActive: isSelected,
        };
        qtSetting.push(setting);
      }
      $scope.data.quoteSettings = qtSetting;
    };

    $scope.saveQuoteSetting = () => {
      $scope.data.isSaving = true;
      if ($scope.data.quoteSettings && $scope.data.quoteSettings.length > 0) {
        insuranceQuoteService.insuranceQuoteUserSettingsSet($scope.data.quoteSettings).then((response) => {
          if (response.data === 1) {
            $scope.data.isSaving = false;
            toaster.pop('success', 'Quote Settings', 'Setting saved successfully.');
            $uibModalInstance.close('close');
          }
        });
      } else {
        toaster.pop('warning', 'Nothing to Save', 'No modification detected.');
        $scope.data.isSaving = false;
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    const init = () => {
      quoteSettingsGet();
    };

    init();
  });
