// TODO: move to serviceabilityService
import {
  productPdfBuilderForUI,
  putProductFinderSettingsBuilderForMyCRM,
  assessmentDetailCalculationsBuilderForUI,
} from 'Common/mappers/serviceability';

class LoanProfilerService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Serviceability';
  }

  getFrequencyType() {
    // TODO: move to selectOptionsService
    return this.httpClient.get('SelectOptions/FrequencyType');
  }

  LenderInfoSpreadsheetDataGet(ProviderID, brokerEventID) {
    // TODO: move to serviceabilityService
    return this.httpClient.get(`${this.apiBaseUrl}/LenderInfoSpreadsheetDataGet`, { ProviderID, brokerEventID });
  }

  getServiceabilityIncome(brokerEventID, LoanScenarioID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityIncomeListGet`, { brokerEventID, LoanScenarioID });
  }

  setServiceabilityIncome(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ServiceabilityIncomeSet`, data);
  }

  deleteServiceabilityIncome(incomeID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/ServiceabilityIncomeDelete`, { incomeID });
  }

  getExpenseType() {
    // TODO: move to optionsService
    return this.httpClient.get('Options/Financial/ExpenseTypeGet');
  }

  getServiceabilityExpense(brokerEventID, LoanScenarioID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityExpenseListGet`, { brokerEventID, LoanScenarioID });
  }

  setServiceabilityExpense(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ServiceabilityExpenseSet`, data);
  }

  deleteServiceabilityExpense(expenseID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/ServiceabilityExpenseDelete`, { expenseID });
  }

  getServiceabilityFamilyMembers(brokerEventID, loanScenarioID) {
    return this.httpClient.get(`${this.apiBaseUrl}/AssessmentDetail`, { brokerEventID, loanScenarioID });
  }

  setServiceabilityFamilyMembers(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/AssessmentDetail`, data);
  }

  getServiceabilityIncomeDetails(brokerEventID, loanScenarioID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityIncomeDetailsGet`, { brokerEventID, loanScenarioID });
  }

  getServiceabilityLendersSummary(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityLendersSummaryGet`, { brokerEventID });
  }

  getServiceabilityFiltersParamters() {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityFiltersParamtersGet`);
  }

  getServiceabilityNewBrokerEvent() {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityNewBrokerEventGet`);
  }

  getServiceabilityFiltersParamtersCustomerSelection(brokerEventID, loanStructureID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityFiltersParamtersCustomerSelectionGet`, { brokerEventID, loanStructureID });
  }

  getServiceabilityLendersList(brokerEventID, loanScenarioId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityLendersListGet`, { brokerEventID, loanScenarioId });
  }

  getServiceabilityProductFinder(brokerEventID, pageNumber, sortColumn, sortType, PageSize, loanStructureID, lenderId, fromLenderRates) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityProductFinderGet`, {
      brokerEventID,
      pageNumber,
      sortColumn,
      sortType,
      PageSize,
      loanStructureID,
      lenderId,
      fromLenderRates,
    });
  }

  getServiceabilityProductList(BrokerEventID, LenderIDList) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityProductListGet`, { BrokerEventID, LenderIDList });
  }

  setServiceabilitySelectedProduct(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ServiceabilitySelectedProductSet`, data);
  }

  getServiceabilitySelectedProduct(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilitySelectedProductGet`, { brokerEventID });
  }

  deleteServiceabilitySelectedProduct(brokerEventID, productID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/ServiceabilitySelectedProductDelete`, { brokerEventID, productID });
  }

  setServiceabilityProductValue(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ServiceabilityProductValueSet`, data);
  }

  getServiceabilityNextGenAccessment(brokerEventID, loanScenarioId) {
    return this.httpClient.get(`${this.apiBaseUrl}/NextGenAssessment`, { brokerEventID, loanScenarioId });
  }

  setServiceabilitySettings(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ServiceabilitySettingsSet`, data);
  }

  getServiceabilitySelectedProductDetails(productIDs) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilitySelectedProductDetailsGet`, { productIDs });
  }

  getServiceabilityFilteringProductsGeneralSettings(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityFilteringProductsGeneralSettingsGet`, { brokerEventID });
  }

  updateServiceabilityFilteringProductsSettings(id, data) {
    return this.httpClient.put(`${this.apiBaseUrl}/ProductFinder/${id}/Settings`, data);
  }

  getServiceabilityStandaloneProductFinder(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/serviceabilityStandaloneProductFinderGet`, params);
  }

  setNewProductLoanStructure(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ProductFinderNewLoanStructureSet`, data);
  }

  getProductFinderLoanStructureList(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ProductFinderLoanStructureListGet`, { brokerEventID });
  }

  deleteProductFinderLoanStructure(brokerEventID, loanStructureID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/ProductFinderLoanStructureDelete`, { brokerEventID, loanStructureID });
  }

  setFavouriteProduct(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/FavouriteProductSet`, data);
  }

  getFavouriteProduct(brokerEventID, loanStructureID) {
    return this.httpClient.get(`${this.apiBaseUrl}/FavouriteProductGet`, { brokerEventID, loanStructureID });
  }

  getProductType() {
    return this.httpClient.get(`${this.apiBaseUrl}/ProductTypeGet`);
  }

  getRateTypes() {
    return this.httpClient.get(`${this.apiBaseUrl}/RateTypes`);
  }

  getSelectedProductDetailsGetByLoanStructure(brokerEventID) {
    return this.httpClient.get(`${this.apiBaseUrl}/SelectedProductDetailsGetByLoanStructure`, { brokerEventID });
  }

  getServiceabilityProductSearch(SearchProduct) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityProductSearchGet`, { SearchProduct });
  }

  setFavouriteProductSequence(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/FavouriteProductSequenceSet`, data);
  }

  getProductFullDetails(ProductID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ProductFullDetailsGet`, { ProductID });
  }

  setFavouriteProductRecommendation(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/FavouriteProductRecommendationSet`, data);
  }

  setFavouriteProductRates(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/FavouriteProductRatesSet`, data);
  }

  getSearchClient(searchString, partyType) {
    // TODO: move to generalService
    return this.httpClient.get(`SearchClientGet`, { searchString, partyType });
  }

  getScenarioList(familyId) {
    // TODO: move to loanScenarioService
    return this.httpClient.get(`LoanScenario/ScenarioListGet`, { familyId });
  }

  setScenario($postData) {
    // TODO: move to loanScenarioService
    return this.httpClient.post('LoanScenario/ScenarioSet', $postData);
  }

  setScenarioApplicant(data) {
    // TODO: move to loanScenarioService
    return this.httpClient.post('LoanScenario/ScenarioApplicantSet', data);
  }

  getProductComparisonPDFReport(brokerEventID, familyId, clientId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ProductComparisonPDFReport`, { brokerEventID, familyId, clientId });
  }

  getLiabilityTypeList(LiabilityCategoryID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LiabilityTypeListGet`, { LiabilityCategoryID });
  }

  getLiabilityList(BrokerEventID, loanScenarioID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LiabilityListGet`, { BrokerEventID, loanScenarioID });
  }

  setLiability(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LiabilitySet`, data);
  }

  deleteLiability(LiabilityID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LiabilityDelete`, { LiabilityID });
  }

  newBrokerEventForScenarioGet(loanScenarioID) {
    // TODO: move to serviceabilityService
    return this.httpClient.get(`Serviceability/NewBrokerEventForScenarioGet`, { loanScenarioID });
  }

  lenderListGet() {
    // TODO: move to serviceabilityService
    return this.httpClient.get('Serviceability/LenderListGet');
  }

  lenderListWithProductCountGet(brokerEventID, loanStructureID, pageNumber, PageSize) {
    return this.httpClient.get(`${this.apiBaseUrl}/LenderListWithProductCount`, {
      brokerEventID,
      loanStructureID,
      pageNumber,
      PageSize,
    });
  }

  productPdf(id) {
    return this.httpClient.get(`${this.apiBaseUrl}/pdf/product/${id}`)
      .then(response => productPdfBuilderForUI(response.data));
  }

  ServiceabilityProviderWorksheetGet(brokerEventID, providerId, loanAmount) {
    return this.httpClient.get(`${this.apiBaseUrl}/ServiceabilityProviderWorksheetGet`, {
      brokerEventID,
      providerId,
      loanAmount,
    });
  }

  putProductFinderSettings(brokerEventId, filter) {
    return this.httpClient.put(`${this.apiBaseUrl}/ProductFinder/${brokerEventId}/Settings`, putProductFinderSettingsBuilderForMyCRM(filter));
  }

  getAssessmentDetailCalculations(brokerEventId) {
    return this.httpClient.get(`${this.apiBaseUrl}/assessment-detail/calculations?brokerEventId=${brokerEventId}`)
      .then(response => response.data.map(assessmentDetailCalculationsBuilderForUI));
  }
}

export default LoanProfilerService;
