import {
  insurancePipelineStatusForUI,
  insurancePipelineLabelForUI,
  insurancePipelineLabelForMyCRM,
  insurancePipelineColumnForUI,
  insuranceFamilyListForUI,
  insuranceQuoteForUI,
  insuranceProvidersQuoteForUI,
  insurancePipelineConversionSummaryForUI,
  insuranceNotProceededReasonForUI,
} from 'Common/mappers/insurancePipeline';
import {
  mapInsurancePipelineTable,
  mapInsurancePipelineFiltersForUI,
  mapInsurancePipelineFiltersForMyCRM,
} from 'Common/utilities/insurancePipeline';

export default class InsurancePipelineService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'insurance-pipeline';
    this.contacts = 'contacts/';
    this.insuranceQuote = 'InsuranceQuote/';
  }

  getInsurancePipelineStatus(excludeConversionStatus) {
    const doCache = true;
    const doRemoveFromCache = false;
    return this.httpClient.get(`${this.apiBaseUrl}/status`, { isExcludeStatus: excludeConversionStatus }, doCache, doRemoveFromCache)
      .then(({ data }) => data && data.map(insurancePipelineStatusForUI));
  }

  getInsurancePipelineCards(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/cards`, params)
      .then(({ data }) => data && data.map(insurancePipelineColumnForUI));
  }

  getInsurancePipelineLabels() {
    return this.httpClient.get(`${this.apiBaseUrl}/label`, {})
      .then(({ data }) => data && data.map(insurancePipelineLabelForUI));
  }

  saveInsurancePipelineLabel(label) {
    return this.httpClient.post(`${this.apiBaseUrl}/label`, insurancePipelineLabelForMyCRM(label));
  }

  deleteInsurancePipelineLabel(labelId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/label`, { labelId });
  }

  addQuoteLabel(params = {}) {
    return this.httpClient.post(`${this.apiBaseUrl}/${params.quoteId}/label`, {}, params);
  }

  getInsuranceProviders(quoteId) {
    return this.httpClient.get(`${this.apiBaseUrl}/${quoteId}/providers`)
      .then(({ data }) => data && data.map(insuranceProvidersQuoteForUI));
  }


  saveInsuranceApplication(params = {}) {
    return this.httpClient.post(`${this.apiBaseUrl}/${params.quoteId}/card/status`, {}, params);
  }


  getSearchFamily(filters) {
    const params = {
      searchCriteria: '',
      byPassFilter: true,
      ...filters,
    };
    return this.httpClient.get(`${this.contacts}FamilyListGet`, params)
      .then(({ data }) => data && data.FamilyList.map(insuranceFamilyListForUI));
  }

  insuranceQuoteGet(filters) {
    const params = {
      module: 1,
      quoteId: 0,
      familyId: 0,
      ...filters,
    };
    return this.httpClient.get(`${this.insuranceQuote}InsuranceQuoteGet`, params)
      .then(({ data }) => data && data.map(insuranceQuoteForUI));
  }

  addPipelineItemLabel(params = {}) {
    return this.httpClient.post(`${this.apiBaseUrl}/${params.quoteId}/label`, {}, params);
  }

  deletePipelineItemLabel(params = {}) {
    return this.httpClient.delete(`${this.apiBaseUrl}/${params.quoteId}/label`, params);
  }

  movePipelineCardToStatus(params = {}) {
    return this.httpClient.post(`${this.apiBaseUrl}/${params.quoteId}/card/status`, {}, params);
  }

  getPipelineCoversionSummary() {
    return this.httpClient.get(`${this.apiBaseUrl}/conversion`)
      .then(({ data }) => data && data.map(insurancePipelineConversionSummaryForUI));
  }

  getNotProceededReasons() {
    return this.httpClient.get(`${this.apiBaseUrl}/reasons`, {}, true, false)
      .then(({ data }) => data && data.map(insuranceNotProceededReasonForUI));
  }

  getInsurancePipelineTableItems(params = {}) {
    return this.httpClient.get(`${this.apiBaseUrl}/tables`, params)
      .then(({ data }) => mapInsurancePipelineTable(data));
  }

  deletePipelineCard(quoteId = 0) {
    return this.httpClient.delete(`${this.apiBaseUrl}/cards/${quoteId}`);
  }

  getInsurancePipelineFilters() {
    return this.httpClient.get(`${this.apiBaseUrl}/filter`)
      .then(({ data }) => {
        const hasData = data && !data.Succeeded && !data.Messages;
        return mapInsurancePipelineFiltersForUI(hasData ? data : null);
      });
  }

  saveInsurancePipelineFilters(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/filter`, mapInsurancePipelineFiltersForMyCRM(data));
  }
}
