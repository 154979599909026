import angular from 'angular';

angular.module('app').controller('ForAdvanceFilterModalCtrl', function ForAdvanceFilterModalCtrl(
  $scope,
  $uibModalInstance,
  updateFilterData,
  loanProfilerService,
  loanStructure
) {
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.filtersParamtersList = {};
  $scope.loanProviderLendersListGetAll = [];
  $scope.loanProviderLendersListSet = [];
  loanProfilerService.getServiceabilityFiltersParamters().then((response) => {
    $scope.filtersParamtersList = response.data;
    angular.forEach(response.data.Lenders, (item) => {
      const length = 12;
      const trimmedString = item.Name.length > length ?
        `${item.Name.substring(0, length - 3)}...` :
        item.Name;
      $scope.loanProviderLendersListGetAll.push({
        name: item.Name,
        shortName: trimmedString,
        value: item.Value,
        ticked: false,
      });
    });
  });
  $scope.loanStructure = loanStructure;

  $scope.refreshResults = function () {
    updateFilterData(loanStructure, true);
    $scope.cancel();
  };
});
