class DobDMYCtrl {
  constructor(
    toaster,
    moment
  ) {
    'ngInject';

    this.toaster = toaster;
    this.moment = moment;
    this.dobChunked = { year: '0' };
    this.yearRange = [];
    this.leapYear = false;
    this.endYear = !this.personData.IsChild ? new Date().getFullYear() - 17 : new Date().getFullYear();
    this.dateOfBirth = this.personData.DateOfBirth;

    this.MONTH_OF_FEB = 2;
    this.FEB_DAYS_LEAP_YEAR = 29;
    this.FEB_DAYS_NON_LEAP_YEAR = 28;
  }

  generateYearRange() {
    let startYear = 1900;
    const year = [];
    while (startYear <= this.endYear) {
      year.push({ Value: `${startYear}`, Key: `${startYear}` });
      startYear++;
    }
    this.yearRange = [{ Value: '0', Key: 'YYYY' }, ...year];
  }

  checkValidDay(day) {
    const dd = parseInt(day, 10);
    if (dd > 31) {
      this.dobChunked.day = '31';
    }
    this.validateDayforLeapYear();
  }

  checkValidMonth(month) {
    const mo = parseInt(month, 10);
    if (mo > 12) {
      this.dobChunked.month = '12';
    }
    if (mo === 0) {
      this.dobChunked.month = '01';
    }

    const day = parseInt(this.dobChunked.day, 10);
    if (day > 30) {
      switch (mo) {
      case 4:
        this.dobChunked.day = '30';
        break;
      case 6:
        this.dobChunked.day = '30';
        break;
      case 9:
        this.dobChunked.day = '30';
        break;
      case 11:
        this.dobChunked.day = '30';
        break;
      default:
        break;
      }
    }
    this.validateDayforLeapYear();
  }

  validateDayforLeapYear() {
    const mo = parseInt(this.dobChunked.month, 10);
    if (mo === this.MONTH_OF_FEB) {
      const maxNumOfDays = this.leapYear ? this.FEB_DAYS_LEAP_YEAR : this.FEB_DAYS_NON_LEAP_YEAR;
      const isInvalidDay = this.dobChunked.day > maxNumOfDays;
      this.dobChunked.day = isInvalidDay ? maxNumOfDays : this.dobChunked.day;
    }

    this.dateOfBirth = this.dateFormatter() ? this.dateFormatter().format() : '';
    this.formatDate();
    this.calcAge();
  }

  dateFormatter() {
    let dateFormatted = null;
    if (this.dobChunked.day && this.dobChunked.month && this.dobChunked.year) {
      dateFormatted = this.moment(`${this.dobChunked.day}-${this.dobChunked.month}-${this.dobChunked.year}`, 'DD/MM/YYYY', false);
    }
    return dateFormatted;
  }

  formatDate() {
    if (this.dateOfBirth === '') {
      if (parseInt(this.dobChunked.year, 10) > 0) {
        this.dateOfBirth = this.dateFormatter().format();
      }
    } else {
      this.dateOfBirth = this.dateFormatter() ? this.dateFormatter().format() : this.dateOfBirth;
    }
    const dob = this.dateOfBirth;
    const getYear = new Date(dob).getFullYear();
    if (getYear && getYear > 0) {
      this.dobChunked.year = getYear.toString();

      const day = new Date(dob).getDate().toString();
      const getMonth = new Date(dob).getMonth() + 1;

      this.dobChunked.day = day.length > 1 ? day : `0${day}`;
      this.dobChunked.month = getMonth.toString().length > 1 ? `${getMonth}` : `0${getMonth}`;

      if (this.dobChunked.year > this.endYear) {
        this.toaster.pop('warning', 'Invalid birth year detected.');
      }
      this.personData.DateOfBirth = this.dateOfBirth;
    }
  }

  calcAge() {
    if (this.dateOfBirth && this.dateOfBirth !== 'Invalid date') {
      const dob = this.dateOfBirth;
      const getYear = new Date(dob).getFullYear();

      if (getYear) {
        const birth = new Date(dob).getTime();
        const now = new Date().getTime();
        const age = Math.floor((now - birth) / (365.25 * 24 * 60 * 60 * 1000));
        const updatedPersonData = { ...this.personData, Age: age };
        if (this.onUpdated) this.onUpdated({ updatedPersonData });
      }
    }
  }

  checkLeapYear() {
    if (this.dobChunked.year % 4 === 0 && !this.leapYear) {
      this.leapYear = true;
      this.checkValidDay(this.dobChunked.day);
      this.checkValidMonth(this.dobChunked.month);
    } else if (this.leapYear) {
      this.leapYear = false;
      this.checkValidDay(this.dobChunked.day);
      this.checkValidMonth(this.dobChunked.month);
    } else {
      this.leapYear = false;
    }
    this.formatDate();
    this.calcAge();
  }

  validateDateInput() {
    this.checkLeapYear();
  }

  $onInit() {
    this.generateYearRange();
    this.formatDate();
    this.calcAge();
  }
}

export default DobDMYCtrl;
