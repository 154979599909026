import angular from 'angular';
import moment from 'moment';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';

angular.module('app').controller('PipelineAppMoveCardToSettledModalCtrl', function PipelineAppMoveCardToSettledModalCtrl(
  $scope,
  $uibModalInstance,
  contactService,
  pipelineService,
  loanApplicationServices,
  toaster,
  familyID,
  cardID,
  LoanScenarioID,
  itemToHighlight) {
  $scope.getAllAddresses = () => {
    contactService.contactAddressGet(familyID).then((response) => {
      $scope.propertyList = response.data;
      $scope.postSettlementAddress = [];
      $scope.currentAddress = [];

      const addressData = $scope.propertyList
        .filter(address => address.Type === 'Post-Settlement Address' || address.Type === 'Current Address')
        .reduce((accum, obj) => {
          const addressType = obj.Type === 'Current Address' ? 'current' : 'postSettlement';
          accum[addressType] = obj;
          return accum;
        }, {});
      $scope.hasPostSettlement = !!addressData.postSettlement;
      $scope.hasCurrentAddress = !!addressData.current;
      $scope.selectedPostSettlementAddress = addressData.postSettlement;
      $scope.selectedCurrentAddress = addressData.current;
    });
  };
  $scope.getAllAddresses();


  $scope.selectPostAddress = (postaddress) => {
    $scope.selectedPostSettlementAddress = postaddress;
  };
  $scope.selectCurrentAddress = (currentaddress) => {
    $scope.selectedCurrentAddress = currentaddress;
  };
  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.SettlementDate = {
    checkbox: false,
    date: new Date(),
    IsEstimated: true,
  };

  $scope.SettlementDate.format = 'dd MMM yyyy';
  $scope.SettlementDate.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  $scope.SettlementDate.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };

  $scope.SettlementDate.datePopUp = {
    settlement: false,
  };

  $scope.SettlementDate.openDate = (dateField) => {
    $scope.SettlementDate.datePopUp[dateField] = !$scope.SettlementDate.datePopUp[dateField];
  };

  if (LoanScenarioID) {
    contactService.loanDetailsGet(LoanScenarioID).then((loanDetailsGetResponse) => {
      if (loanDetailsGetResponse && loanDetailsGetResponse.data && loanDetailsGetResponse.data.loan) {
        const loan = loanDetailsGetResponse.data.loan;
        if (loan && loan.SettlementDate) {
          $scope.SettlementDate.date = new Date(loan.SettlementDate);
          $scope.SettlementDate.IsEstimated = false;
        }
      }
    });
  }

  $scope.updateLoanStatus = () => {
    let updateAddressData = null;
    if ($scope.selectedCurrentAddress) {
      updateAddressData = [];
      updateAddressData.familyId = familyID;
      if ($scope.hasPostSettlement) {
        updateAddressData = $scope.selectedPostSettlementAddress;
      } else if ($scope.hasCurrentAddress) {
        updateAddressData = $scope.selectedCurrentAddress;
      }
    }
    const settlementDate = moment($scope.SettlementDate.date).format('YYYY-MM-DD');
    contactService.loanImportantDatesSet(LoanScenarioID, IMPORTANT_DATE_TYPES.IN_PROGRESS.SETTLEMENT, settlementDate, null, $scope.SettlementDate.IsEstimated)
      .then((response) => {
        if (response && response.data === IMPORTANT_DATE_TYPES.API_STATUS.INVALID_DATE) {
          toaster.pop('warning', 'Date is invalid');
        }
      }, () => {
        toaster.pop('error', 'Error saving important date.');
      });
    if ($scope.hasPostSettlement && $scope.hasCurrentAddress && $scope.SettlementDate.checkbox) {
      contactService.contactAddressSet([updateAddressData]).then(() => {
        toaster.pop('success', 'Updated', 'Your address has been updated successfully');
      });
    }

    const returnData = {
      PipelineItemId: cardID,
      Status: 2,
      itemHighlight: itemToHighlight,
    };
    $uibModalInstance.close(returnData);
  };
});
