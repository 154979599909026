import _ from 'lodash';
import swal from 'sweetalert';
import {
  CLIENT_AGE,
  GENDER,
  INSURANCE_OPTIONS,
  INCOME_PROTECTION_BENEFITS,
  INSURANCE_POLICY_FEE_GRAPH_COLOR,
  INSURANCE_PIPELINE_STATUS,
  CALC_PERIOD,
  BENEFIT_INDICES,
} from 'Common/constants/insuranceOptions';

export function generateAgeList(type) {
  const dependent = _.range(CLIENT_AGE.CHILD);
  const adult = _.range(CLIENT_AGE.ADULT.FROM, CLIENT_AGE.ADULT.TO);
  const ageBracket = type === CLIENT_AGE.TYPE_CHILD ? dependent : adult;
  const setAge = [];
  ageBracket.forEach((age) => {
    setAge.push({
      Key: age,
      Value: age,
    });
  });
  return setAge;
}

export function personToSetContactModel(person) {
  if (!person) return person;

  const { FirstName, LastName, Gender, DateOfBirth: DOB, Age, IsChild, Smoker } = person;
  return {
    FirstName,
    LastName,
    FullName: `${FirstName} ${LastName || ''}`.trim(),
    Gender: parseInt(Gender, 10) === GENDER.MALE ? 'Male' : 'Female',
    Role: IsChild ? 'Child' : 'Adult',
    SmokerStatus: Smoker ? 'Yes' : 'No',
    DOB,
    Age,
  };
}

export function sortPeopleEntities(peopleEntities) {
  return peopleEntities && _.sortBy(peopleEntities, (person) => {
    if (!person.IsChild) {
      return person.IsPrimary ? 0 : 1;
    }
    return 2;
  });
}

export function getBenefitTabName(benefitTypeId) {
  switch (benefitTypeId) {
  case INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER:
    return 'Health Insurance';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER:
    return 'Life Insurance';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER:
    return 'Trauma Insurance';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TPD:
    return 'Total Permanent Disablement';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION:
    return 'Income Protection';
  default:
    return null;
  }
}

export function getBenefitTypeNameById(benefitTypeId) {
  switch (benefitTypeId) {
  case INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER:
    return 'Health Cover';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER:
    return 'Life Cover';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.FAMILY_PROTECTION:
    return 'Family Protection';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER:
    return 'Trauma Cover';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TPD:
    return 'Total & Permanent Disability';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION:
    return 'Income Protection';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.MORTAGE_REPAYMENT:
    return 'Mortgage Repayment Cover';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.REDUNDANCY:
    return 'Redundancy Cover';
  case INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM:
    return 'Waiver of Premium';
  default:
    return null;
  }
}

export function getIncludedBenefits(insuredPerson) {
  if (!insuredPerson || !insuredPerson.benefits || !insuredPerson.benefits.length) return;

  return insuredPerson.benefits.reduce((accum, benefit) => {
    const isIncluded = !!benefit.benefitId;
    if (isIncluded) {
      const benefitItem = { ...benefit, isIncluded, name: getBenefitTypeNameById(benefit.benefitId) };
      return [...accum, benefitItem];
    }
    return accum;
  }, []);
}

export function getBenefitsWithPremium(quoteFromInsurer) {
  return quoteFromInsurer && quoteFromInsurer.PeopleEntity.reduce((accum, entity) => {
    const benefitsWithPremium = entity.BenefitList && entity.BenefitList.filter((benefit) => {
      return benefit.BenefitTotalOfPremium > 0;
    });
    return [...accum, ...(benefitsWithPremium || [])];
  }, []);
}

export function paymentFrequenciesForUI(paymentFrequencies) {
  if (!paymentFrequencies || !paymentFrequencies.length) return paymentFrequencies;

  return paymentFrequencies.reduce((accum, currentFrequency) => {
    const { Key: name, Value: value } = currentFrequency;
    accum.push({ name, value: parseInt(value, 10) });
    return accum;
  }, []);
}

export function buildPolicyFeeGraphStackItem(quotePolicyFee, maxPremium) {
  const validatedQuotePolicyFee = parseFloat(quotePolicyFee || 0, 10);
  return {
    benefitId: -1,
    benefitName: 'Policy Fee',
    totalBenefitPremium: validatedQuotePolicyFee,
    value: (validatedQuotePolicyFee / maxPremium) * 100,
    color: INSURANCE_POLICY_FEE_GRAPH_COLOR,
  };
}

export function sortQuoteGraphStack(stack) {
  return stack && stack.sort((rightValue, leftValue) => {
    return leftValue.benefitId < rightValue.benefitId;
  });
}

export function buildQuoteGraphStack(insurerQuote, maxPremium, graphColors) {
  if (!insurerQuote) return;

  const benefits = getBenefitsWithPremium(insurerQuote);
  const stack = benefits && benefits.length && benefits.reduce((accum, benefit) => {
    const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
    const isIncomeProtection = INCOME_PROTECTION_BENEFITS.indexOf(benefit.BenefitId) !== -1;
    const benefitId = isIncomeProtection ? BENEFIT_TYPE.INCOME_PROTECTION : benefit.BenefitId;
    const { BenefitTotalOfPremium: benefitTotalOfPremium } = benefit;

    const stackItem = accum.find((item) => {
      return item.benefitId === benefitId;
    }) || { totalBenefitPremium: 0 };
    const itemNotInStack = !stackItem.benefitId;

    stackItem.benefitId = benefitId;
    stackItem.benefitName = getBenefitTypeNameById(benefitId);
    stackItem.totalBenefitPremium += benefitTotalOfPremium;
    stackItem.value = (stackItem.totalBenefitPremium / maxPremium) * 100;
    const graphColor = graphColors.find(color => color.benefitType === benefitId);
    stackItem.color = graphColor && graphColor.color;

    return itemNotInStack ? [...accum, stackItem] : accum;
  }, []);

  return stack && [...sortQuoteGraphStack(stack), buildPolicyFeeGraphStackItem(insurerQuote.PolicyFee, maxPremium)];
}

export function buildQuoteGraphStackLabel(insurerQuote) {
  return insurerQuote && {
    totalPremium: insurerQuote.TotalPremium,
    providerName: insurerQuote.ProviderName,
    providerLogo: `assets/images/insurers/bordered/${insurerQuote.ProviderId}.png`,
  };
}

export function launchInsuranceQuoteReportModal(modalBuilder, reportData) {
  modalBuilder.open({
    templateUrl: '/assets/views/insuranceTools/modals/newQuoteReportPDF.html',
    controller: 'QuoteReportPDFCtrl',
    windowClass: 'report-pdf-modal-window',
    backdrop: 'static',
    keyboard: false,
    resolve: {
      reportPDFdata: () => reportData,
    },
  });
}

export function responseHasValuesOfObjects(response) {
  const isHasValue = Object.keys(response);
  return isHasValue.length;
}

export function confirmAction(swalProperty = {}, confirmCallback) {
  return swal(swalProperty, confirmCallback);
}

export function canHaveMultipleCovers(benefitId) {
  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  const multipleCoverBenefits = [BENEFIT_TYPE.LIFE_COVER, BENEFIT_TYPE.TRAUMA_COVER, BENEFIT_TYPE.TPD];
  return multipleCoverBenefits.indexOf(benefitId) !== -1;
}

export function countBenefitCovers(benefitId, benefits) {
  return benefitId && benefits && benefits.filter(benefit => benefit.BenefitId === benefitId).length;
}

export function getInitialLifeCover() {
  return {
    BenefitId: null,
    LifeCover: {
      Indexed: true,
      FutureInsurability: false,
      Loading: 1,
      CoverAmount: 0,
      CalcPeriod: CALC_PERIOD.YEARLY,
    },
  };
}

export function getInitialTraumaCover() {
  return {
    BenefitId: null,
    TraumaCover: {
      Indexed: true,
      LifeBuyBack: false,
      TraumaBuyBack: false,
      TpdAddOn: false,
      Loading: 1,
      CoverAmount: 0,
      OptionValue: 'Standalone',
      OptionValue2: 'TpdOwnOccupation',
      Standalone: 'true',
      Accelerated: 'false',
      TpdAnyOccupation: false,
      TpdOwnOccupation: true,
      ChildCoverAmount: null,
      CalcPeriod: CALC_PERIOD.YEARLY,
    },
  };
}

export function getInitialTPD() {
  return {
    BenefitId: null,
    TotalAndPermanentDisability: {
      Indexed: true,
      Loading: 1,
      CoverAmount: 0,
      OptionValue: 'TpdOwnOccupation',
      OptionValue2: 'Standalone',
      OwnOccupation: 'true',
      AnyOccupation: 'false',
      Standalone: 'true',
      Accelerated: 'false',
      CalcPeriod: CALC_PERIOD.YEARLY,
    },
  };
}

export function getInitialBenefitCover(benefitId) {
  switch (benefitId) {
  case INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER:
    return getInitialLifeCover();
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER:
    return getInitialTraumaCover();
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TPD:
    return getInitialTPD();
  default:
    return {};
  }
}

export function formatQuoteData(quoteData) {
  if (!quoteData) return;

  return {
    ...quoteData,
    PeopleEntity: quoteData.PeopleEntity.map((person) => {
      const { BenefitList: benefits } = person;
      const formattedBenefits = person.BenefitList.reduce((accum, benefit) => {
        const maxNumberOfCovers = 2;
        const { BenefitId: benefitId } = benefit;
        const doInsertAnotherCover = canHaveMultipleCovers(benefitId) && countBenefitCovers(benefitId, benefits) < maxNumberOfCovers;
        return doInsertAnotherCover ? [...accum, benefit, getInitialBenefitCover(benefit.BenefitId)] : [...accum, benefit];
      }, []);

      return { ...person, BenefitList: formattedBenefits };
    }),
  };
}

export function getBenefitCover(benefit) {
  if (!benefit) return;

  switch (benefit.BenefitId) {
  case INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER:
    return benefit.HealthCover;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER:
    return benefit.LifeCover;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.FAMILY_PROTECTION:
    return benefit.FamilyProtection;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER:
    return benefit.TraumaCover;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TPD:
    return benefit.TotalAndPermanentDisability;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION:
    return benefit.IncomeProtection;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.MORTAGE_REPAYMENT:
    return benefit.MortgageRepaymentCover;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.REDUNDANCY:
    return benefit.RedundancyCover;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM:
    return benefit.WaiverOfPremium;
  default:
    return null;
  }
}

export function getBenefitIdByIndex(benefitIndex) {
  switch (benefitIndex) {
  case BENEFIT_INDICES.HEALTH_COVER:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER;
  case BENEFIT_INDICES.LIFE_COVER_1:
  case BENEFIT_INDICES.LIFE_COVER_2:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER;
  case BENEFIT_INDICES.FAMILY_PROTECTION:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.FAMILY_PROTECTION;
  case BENEFIT_INDICES.TRAUMA_COVER_1:
  case BENEFIT_INDICES.TRAUMA_COVER_2:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER;
  case BENEFIT_INDICES.TPD_1:
  case BENEFIT_INDICES.TPD_2:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.TPD;
  case BENEFIT_INDICES.INCOME_PROTECTION:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION;
  case BENEFIT_INDICES.MORTGAGE_REPAYMENT:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.MORTAGE_REPAYMENT;
  case BENEFIT_INDICES.REDUNDANCY:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.REDUNDANCY;
  case BENEFIT_INDICES.WAIVER_OF_PREMIUM:
    return INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM;
  default:
    return null;
  }
}

export function getCoverByBenefitId(benefit) {
  if (!benefit) return;

  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  switch (benefit.benefitId) {
  case BENEFIT_TYPE.HEALTH_COVER:
    return benefit.healthCover;
  case BENEFIT_TYPE.LIFE_COVER:
    return benefit.lifeCover;
  case BENEFIT_TYPE.FAMILY_PROTECTION:
    return benefit.familyProtection;
  case BENEFIT_TYPE.TRAUMA_COVER:
    return benefit.traumaCover;
  case BENEFIT_TYPE.TPD:
    return benefit.totalAndPermanentDisability;
  case BENEFIT_TYPE.INCOME_PROTECTION:
  case BENEFIT_TYPE.INCOME_PROTECTION_NON_TAXABLE:
    return benefit.incomeProtection;
  case BENEFIT_TYPE.MORTAGE_REPAYMENT:
    return benefit.mortgageRepaymentCover;
  case BENEFIT_TYPE.REDUNDANCY:
    return benefit.redundancyCover;
  case BENEFIT_TYPE.WAIVER_OF_PREMIUM:
    return benefit.waiverOfPremium;
  default:
    return null;
  }
}

export function getBenefitIndexById(benefitId, secondCover) {
  switch (benefitId) {
  case INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER:
    return BENEFIT_INDICES.HEALTH_COVER;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER:
    return secondCover ? BENEFIT_INDICES.LIFE_COVER_2 : BENEFIT_INDICES.LIFE_COVER_1;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.FAMILY_PROTECTION:
    return BENEFIT_INDICES.FAMILY_PROTECTION;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER:
    return secondCover ? BENEFIT_INDICES.TRAUMA_COVER_2 : BENEFIT_INDICES.TRAUMA_COVER_1;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TPD:
    return secondCover ? BENEFIT_INDICES.TPD_2 : BENEFIT_INDICES.TPD_1;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION:
    return BENEFIT_INDICES.INCOME_PROTECTION;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.MORTAGE_REPAYMENT:
    return BENEFIT_INDICES.MORTGAGE_REPAYMENT;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.REDUNDANCY:
    return BENEFIT_INDICES.REDUNDANCY;
  case INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM:
    return BENEFIT_INDICES.WAIVER_OF_PREMIUM;
  default:
    return -1;
  }
}

export function getBenefitYears(isFixed) {
  const minYears = isFixed ? 2 : 31;
  const maxYears = isFixed ? 31 : 71;
  return _.range(minYears, maxYears).map((year) => {
    return { benefitYearID: year, Description: year, Value: year };
  });
}

export function isSecondCover(benefitIndex) {
  const secondBenefitCovers = [
    BENEFIT_INDICES.LIFE_COVER_2,
    BENEFIT_INDICES.TRAUMA_COVER_2,
    BENEFIT_INDICES.TPD_2,
  ];
  return secondBenefitCovers.indexOf(benefitIndex) !== -1;
}

export function getCommonCoverAmountFielConfig() {
  return {
    type: 'currency',
    label: 'Cover Amount',
    name: 'CoverAmount',
    required: true,
    minValue: 1,
    maxValue: 99999999,
  };
}

export function getCommonIndexFieldConfig(visibilityCondition) {
  return {
    type: 'checkbox',
    label: 'Indexed',
    name: 'Indexed',
    isOption: true,
    visibilityCondition,
  };
}

export function getCommonLoadingFieldConfig(loadings) {
  return {
    type: 'dropdown',
    label: 'Loading',
    name: 'Loading',
    isOption: true,
    options: loadings,
    optionValueProperty: 'Value',
    optionNameProperty: 'Key',
    defaultValueIndex: 0,
  };
}

export function getCommonCalPeriodFieldConfig(calcPeriods) {
  return {
    type: 'dropdown',
    label: 'Calc Period',
    name: 'CalcPeriod',
    options: calcPeriods,
    optionValueProperty: 'Value',
    optionNameProperty: 'Key',
    defaultValueIndex: 0,
  };
}

export function getCommonWaitPeriodFieldConfig(waitPeriods) {
  return {
    type: 'dropdown',
    label: 'Wait Period',
    name: 'WeekWaitPeriod',
    options: waitPeriods,
    optionValueProperty: 'Value',
    optionNameProperty: 'Key',
  };
}

export function getCommonBenefitPeriodFieldConfig(benefitPeriods) {
  return {
    type: 'dropdown',
    label: 'Benefit Period',
    name: 'BenefitPeriod',
    options: benefitPeriods,
    optionValueProperty: 'Value',
    optionNameProperty: 'Key',
  };
}

export function getIndexedVisibilityConfig() {
  return {
    fieldDependency: 'CalcPeriod',
    fieldDependencyValue: 1,
    condition: 'equal',
  };
}

export function getLifeCoverFieldsConfig(dropdownOptions = {}) {
  const { calcPeriods, loadings } = dropdownOptions;
  return [
    getCommonCoverAmountFielConfig(),
    getCommonCalPeriodFieldConfig(calcPeriods),
    getCommonIndexFieldConfig(getIndexedVisibilityConfig()),
    {
      type: 'checkbox',
      label: 'Future Insurability',
      name: 'FutureInsurability',
      isOption: true,
    },
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getTraumaCoverFieldsConfig(dropdownOptions = {}) {
  const { coverTypes, calcPeriods, loadings } = dropdownOptions;
  return [
    getCommonCoverAmountFielConfig(),
    {
      type: 'dropdown',
      label: 'Type',
      name: 'OptionValue',
      options: coverTypes,
      optionValueProperty: 'value',
      optionNameProperty: 'key',
      defaultValueIndex: 1,
    },
    getCommonCalPeriodFieldConfig(calcPeriods),
    getCommonIndexFieldConfig(getIndexedVisibilityConfig()),
    {
      type: 'checkbox',
      label: 'Life Buyback',
      name: 'LifeBuyBack',
      isOption: true,
    },
    {
      type: 'checkbox',
      label: 'Trauma Buyback',
      name: 'TraumaBuyBack',
      isOption: true,
    },
    {
      type: 'checkbox',
      label: 'TPD Add-on',
      name: 'TpdAddOn',
      isOption: true,
    },
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getTPDCoverFieldsConfig(dropdownOptions = {}) {
  const { coverTypes, calcPeriods, loadings, occupations } = dropdownOptions;
  return [
    getCommonCoverAmountFielConfig(),
    {
      type: 'dropdown',
      label: 'Occupation',
      name: 'OptionValue',
      options: occupations,
      optionValueProperty: 'Key',
      optionNameProperty: 'Value',
      defaultValueIndex: 1,
    },
    {
      type: 'dropdown',
      label: 'Type',
      name: 'OptionValue2',
      options: coverTypes,
      optionValueProperty: 'Value',
      optionNameProperty: 'Key',
    },
    getCommonCalPeriodFieldConfig(calcPeriods),
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getHealthCoverFieldsConfig(dropdownOptions = {}) {
  const { excesses, loadings } = dropdownOptions;
  return [
    {
      type: 'dropdown',
      label: 'Excess',
      name: 'Excess',
      options: excesses,
      optionValueProperty: 'Value',
      optionNameProperty: 'Key',
    },
    {
      type: 'checkbox',
      label: 'Specialists & Tests',
      name: 'SpecialistsTest',
    },
    {
      type: 'checkbox',
      label: 'GP & Prescriptions',
      name: 'GpPrescriptions',
    },
    {
      type: 'checkbox',
      label: 'Dental & Optical',
      name: 'DentalOptical',
    },
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getFamilyProtectionFieldsConfig(dropdownOptions = {}) {
  const { benefitPeriods, benefitYearsFixed, benefitYearsAge, loadings } = dropdownOptions;
  return [
    getCommonCoverAmountFielConfig(),
    {
      type: 'dropdown',
      label: 'Benefit Period',
      name: 'BenefitPeriodTerm',
      options: benefitPeriods,
      optionValueProperty: 'Value',
      optionNameProperty: 'Key',
      subField: {
        label: 'Yrs.',
        name: 'BenefitPeriodYear',
        isConditionalOption: true,
        conditionalOptions: [
          {
            options: benefitYearsFixed,
            optionValueProperty: 'benefitYearID',
            optionNameProperty: 'Description',
          },
          {
            options: benefitYearsAge,
            optionValueProperty: 'benefitYearID',
            optionNameProperty: 'Description',
          },
        ],
        required: true,
      },
    },
    getCommonIndexFieldConfig(),
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getIncomeProtectionFieldsConfig(dropdownOptions = {}) {
  const { waitPeriods, benefitPeriods, policyTypes, loadings } = dropdownOptions;
  return [
    {
      type: 'currency',
      label: 'Benefit Amount',
      name: 'CoverAmount',
      required: true,
      minValue: 1,
      maxValue: 99999999,
    },
    getCommonWaitPeriodFieldConfig(waitPeriods),
    getCommonBenefitPeriodFieldConfig(benefitPeriods),
    {
      type: 'dropdown',
      label: 'Policy Type',
      name: 'IsTaxable',
      options: policyTypes,
      optionValueProperty: 'Value',
      optionNameProperty: 'Key',
    },
    getCommonIndexFieldConfig(),
    {
      type: 'checkbox',
      label: 'Booster',
      name: 'Booster',
      isOption: true,
    },
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getMortgageRepaymentFieldsConfig(dropdownOptions = {}) {
  const { waitPeriods, benefitPeriods, loadings } = dropdownOptions;
  return [
    getCommonCoverAmountFielConfig(),
    getCommonWaitPeriodFieldConfig(waitPeriods),
    getCommonBenefitPeriodFieldConfig(benefitPeriods),
    getCommonIndexFieldConfig(),
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getRedundancyFieldsConfig(loadings) {
  return [
    getCommonCoverAmountFielConfig(),
    getCommonIndexFieldConfig(),
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getWaiverOfPremiumFieldsConfig(dropdownOptions = {}) {
  const { waitPeriods, loadings } = dropdownOptions;
  return [
    getCommonWaitPeriodFieldConfig(waitPeriods),
    getCommonLoadingFieldConfig(loadings),
  ];
}

export function getBenefitFieldsConfig(benefitType, dropdownOptions) {
  switch (benefitType) {
  case INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER:
    return getHealthCoverFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER:
    return getLifeCoverFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.FAMILY_PROTECTION:
    return getFamilyProtectionFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER:
    return getTraumaCoverFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.TPD:
    return getTPDCoverFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION:
    return getIncomeProtectionFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.MORTAGE_REPAYMENT:
    return getMortgageRepaymentFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.REDUNDANCY:
    return getRedundancyFieldsConfig(dropdownOptions);
  case INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM:
    return getWaiverOfPremiumFieldsConfig(dropdownOptions);
  default:
    return null;
  }
}

export function isWaiverOfPremiumDependent(benefitType) {
  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  const wopDependentBenefits = [BENEFIT_TYPE.INCOME_PROTECTION, BENEFIT_TYPE.MORTAGE_REPAYMENT];
  return wopDependentBenefits.indexOf(benefitType) !== -1;
}

export function isBenefitLifeCoverDependent(benefit) {
  if (!benefit) return false;
  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  const lifeCoverDependentBenefitIds = [BENEFIT_TYPE.TRAUMA_COVER, BENEFIT_TYPE.TPD];
  const isBenefitInList = lifeCoverDependentBenefitIds.indexOf(benefit.benefitId) !== -1;
  if (!isBenefitInList) return false;

  const benefitCover = getCoverByBenefitId(benefit);
  return benefitCover.isAccelerated;
}

export function isBenefitWOPDependent(benefit) {
  if (!benefit) return false;
  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  const wopDependentBenefits = [BENEFIT_TYPE.INCOME_PROTECTION, BENEFIT_TYPE.MORTAGE_REPAYMENT];
  return wopDependentBenefits.indexOf(benefit.benefitId) !== -1;
}

export function isWaiverOfPremiumRequiredFor(person) {
  const isValidPersonBenefits = person && person.benefits && person.benefits.length;
  if (!isValidPersonBenefits) return false;

  return !!person.benefits.find((benefit) => {
    return isBenefitWOPDependent(benefit) && benefit.isIncluded;
  });
}

export function cascadeLifeCoverInclusion(benefits, updatedLifeCover) {
  if (!updatedLifeCover) return benefits;
  return benefits && benefits.map((benefit) => {
    if (!isBenefitLifeCoverDependent(benefit)) return benefit;
    return { ...benefit, isIncluded: updatedLifeCover.isIncluded };
  });
}

export function cascadeLifeCoverDependentBenefitInclusion(benefits, updatedBenefit) {
  if (!updatedBenefit) return benefits;
  return benefits && benefits.map((benefit) => {
    const isLifeCover = benefit.benefitId === INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER;
    const updateBenefitInclusion = isLifeCover && !benefit.isIncluded;
    return updateBenefitInclusion ? { ...benefit, isIncluded: updatedBenefit.isIncluded } : benefit;
  });
}

export function cascadeWOPInclusion(benefits, isWOPIncluded) {
  const isEmptyBenefits = !benefits || !benefits.length;
  if (isWOPIncluded || isEmptyBenefits) return benefits;

  return benefits.map((benefit) => {
    if (!isBenefitWOPDependent(benefit)) return benefit;
    return { ...benefit, isIncluded: isWOPIncluded };
  });
}

export function updateWOPInclusionInBenefits(benefits, isIncluded) {
  if (!benefits || !benefits.length) return benefits;

  const waiverOfPremiumIndex = _.findIndex(benefits, benefit => benefit.benefitId === INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM);
  if (waiverOfPremiumIndex === -1) return benefits;

  const waiverOfPremium = { ...benefits[waiverOfPremiumIndex], isIncluded };
  return [...benefits.slice(0, waiverOfPremiumIndex), waiverOfPremium, ...benefits.slice(waiverOfPremiumIndex + 1)];
}

export function cascadeBenefitInclusion(updatedBenefit, insuredPerson) {
  if (!insuredPerson) return;
  if (!updatedBenefit) return insuredPerson.benefits;

  const { benefits } = insuredPerson;
  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  if (updatedBenefit.benefitId === BENEFIT_TYPE.LIFE_COVER) {
    return cascadeLifeCoverInclusion(benefits, updatedBenefit);
  } else if (isBenefitLifeCoverDependent(updatedBenefit)) {
    return cascadeLifeCoverDependentBenefitInclusion(benefits, updatedBenefit);
  } else if (updatedBenefit.benefitId === BENEFIT_TYPE.WAIVER_OF_PREMIUM) {
    return cascadeWOPInclusion(benefits, updatedBenefit.isIncluded);
  } else if (isWaiverOfPremiumRequiredFor(insuredPerson)) {
    return updateWOPInclusionInBenefits(benefits, true);
  }
  return benefits;
}

export function getInsuranceBenefitName(benefitData) {
  const benefitTypesForProductName = [
    INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER,
    INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER,
    INSURANCE_OPTIONS.BENEFIT_TYPE.MORTAGE_REPAYMENT,
  ];
  return (benefitTypesForProductName.includes(benefitData.BenefitId)) ? benefitData.ProductEntityList[0].ProductName : benefitData.BenefitName;
}

export function getConciseConversionSummary(conversionSummaries) {
  if (!conversionSummaries) return {};
  const inForce = conversionSummaries.find(summary => summary.statusId === INSURANCE_PIPELINE_STATUS.IN_FORCE);
  const notProceeded = conversionSummaries.find(summary => summary.statusId === INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED);
  return {
    inForcePercentage: inForce && inForce.conversionPercentage,
    notProceededPercentage: notProceeded && notProceeded.conversionPercentage,
  };
}

export function checkIsInsuranceAppProceeded(event) {
  return event && event.dest.sortableScope.element[0].id === 'approve';
}

export function getStatusIdConvertedTo(event) {
  const isProceeded = checkIsInsuranceAppProceeded(event);
  return isProceeded ? INSURANCE_PIPELINE_STATUS.IN_FORCE : INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED;
}
