
class SurveyIframeCtrl {
  constructor(
    $sce
  ) {
    'ngInject';

    this.$sce = $sce;
  }

  $onInit() {
    this.surveyUrl = this.$sce.trustAsResourceUrl(this.props);
  }
  closeModal() {
    this.modalInstance.close();
  }
}

export default SurveyIframeCtrl;
