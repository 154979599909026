import angular from 'angular';
import { forEach } from 'lodash';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { typelessEqual } from 'Common/utilities/math';
import { PIPELINE_TYPES_ID } from 'Common/constants/pipelineType';

angular.module('app').controller('LoanDetailCtrl',
  function LoanDetailCtrl($scope,
    contactService,
    loanApplicationServices,
    $state,
    $stateParams,
    $uibModal,
    imageDataURI,
    SweetAlert,
    $timeout,
    contactSharedData,
    toaster,
    loanAppSharedData,
    $window,
    modalRenderService,
    pipelineSharedData,
    loanAppDetailsService,
    pipelineService
  ) {
    $scope.newlyClonedLoanApp = {};

    const refreshLendingBroadcastHandler = $scope.$on('UPDATE_LENDING_ON_CHANGE_STATUS', (isUpdate) => {
      if (isUpdate) {
        $scope.getLoanListGet();
      }
    });
    $scope.$on('$destroy', refreshLendingBroadcastHandler);

    $scope.$watch('defaultTabOnloadAction', (newValue, oldValue) => {
      if (newValue === 'loanInformationModal' && oldValue !== 'loanInformationModal') {
        $scope.loanInformationModal('create', $scope.familyId, 0);
      }
      if (newValue === 'addExistingLoansModal' && oldValue !== 'addExistingLoansModal') {
        $scope.addExistingLoansModal('create', $scope.familyId, 0);
      }
    });

    const loadLoanWatch = $scope.$watch(() => { return loanAppDetailsService.loadLoanList; }, (nv) => {
      if (nv) {
        $scope.getLoanListGet();
      }
      loanAppDetailsService.loadLoanList = false;
    });

    $scope.$on('$destroy', () => {
      loadLoanWatch();
    });

    $scope.deleteLoan = (loanId) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your loan list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          contactService.loanDelete(loanId).then(() => {
            $timeout(() => {
              $scope.getLoanListGet();
              if (typeof $scope.PipelineCardsTimeLineViewGet === 'function') $scope.PipelineCardsTimeLineViewGet();
              SweetAlert.swal({
                title: 'Success',
                text: 'Loan has been been successfully deleted.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            }, 100);

            contactSharedData.lendingSharedData.updateLinkToLoan = true;
          });
        }
      });
    };

    $scope.loanInformationModal = (action, familyId, loanId, loanScenarioID, loanInformation) => {
      $scope.loanType = loanScenarioID;
      $scope.params = {
        loanInformationAction: action,
        familyId: parseInt(familyId, 10),
        loanId,
        loanInformation,
        loanScenarioID,
      };

      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
        controller: 'LoanInformationCtrl',
        scope: $scope,
        size: 'wide',
        backdrop: 'static',
        keyboard: false,
      });

      modalInstance.result.then((result) => {
        // TODO: call up lending-details > loan-list-get
        contactSharedData.lendingSharedData.loanDetail = {
          processAction: action,
          processResult: result,
        };
        if (typeof $scope.PipelineCardsTimeLineViewGet === 'function') $scope.PipelineCardsTimeLineViewGet();
      });
    };

    $scope.loanDetailsModal = (action, familyId, loanId, loanInfo) => {
      $scope.params = {
        loanInformationAction: action,
        familyId: parseInt(familyId, 10),
        loanId,
      };

      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/client/lending/detailsModal.html',
        controller: 'LoanDetailsModalCtrl',
        resolve: {
          loanInfo() {
            return loanInfo;
          },
          action() {
            return action;
          },
        },
      });

      modalInstance.result.then(() => {
        $scope.getLoanListGet();
      }, () => {
        $scope.getLoanListGet();
      });
    };

    $scope.callLoanStatusSet = (status, inprogressLoan, shouldSendNurtureCampaigns, reasonSubstatusID) => {
      angular.extend(inprogressLoan.LoanStatus, {
        LoanStatusName: status.LoanStatusName,
        LoanStatusID: status.LoanStatusID,
      });
      contactService.LoanStatusSet(inprogressLoan.LoanID, status.LoanStatusID, shouldSendNurtureCampaigns, reasonSubstatusID)
        .then(() => {
          toaster.pop(
            'success',
            'Loan Status successfully updated!'
          );

          if (inprogressLoan.PipelineCardsID) {
            loanAppSharedData.lendingTabPipelineStatusData = {
              PipelineStatusID: inprogressLoan.LoanStatus.LoanStatusID,
            };
          }

          /**
         * Check if status is Approval then save the current date as Approval Date
         * */
          if (status.LoanStatusName === 'Unconditional Approval') {
          // Assign current date
            inprogressLoan.ApprovalDate = new $window.Date();

            // Save the approval date
            $scope.importantDateSave(inprogressLoan, IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL, inprogressLoan.ApprovalDate);
          }

          /**
         * Do a timeout so the item does not disappear instantly
         * */
          $timeout(() => {
          // Refresh the Loan List
            $scope.getLoanListGet();
          }, 1000);
        });
    };

    $scope.updateLoanStatus = (status, inprogressLoan) => {
      if (typelessEqual(status.LoanStatusID, LOAN_APP_STATUS_TYPES.NOT_PROCEEDED)) {
        modalRenderService.renderPipelineStatusMoveConfirmationModal({
          showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion($scope.getContactFamilyInfo.ReferralItem),
          reasonList: $scope.reasonListView,
        }).result.then((result) => {
          if (result.isOk) $scope.callLoanStatusSet(status, inprogressLoan, result.shouldSendNurtureCampaigns, result.selectedReasonId);
        });
      } else if (typelessEqual(status.LoanStatusID, LOAN_STATUS.EXISTING.SETTLED)) {
        $scope.callLoanStatusSet(status, inprogressLoan);
        modalRenderService.openMovedToSettledModal('modalLinkLoanToPipeline', $scope.familyId, inprogressLoan.LoanID, inprogressLoan.PipelineCardsID).then(() => {
          $scope.getLoanListGet();
        });
      } else {
        $scope.callLoanStatusSet(status, inprogressLoan);
      }
    };

    $scope.cloneLoan = (loan, loansSectionLoadingStatus) => {
      $scope[loansSectionLoadingStatus] = true;
      loanApplicationServices.cloneLoan(loan.LoanScenarioID, $scope.familyId).then((response) => {
        $scope[loansSectionLoadingStatus] = false;
        $scope.getLoanListGet();
        $scope.newlyClonedLoanApp.loanAppId = response.data;
        $state.go('app.loanApplicationDetailsV3',
          { familyId: $scope.familyId, loanAppId: $scope.newlyClonedLoanApp.loanAppId }
        );
      }, () => {
        $scope[loansSectionLoadingStatus] = false;
      });
    };

    $scope.workbench = (inprogressLoan) => {
      const modalInstance = $uibModal.open({
        templateUrl: 'assets/views/loanApplication/components/workbench.html',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          cLoanId: () => inprogressLoan.LoanID,
          CLoanStatusId: () => inprogressLoan.LoanStatus.LoanStatusID,
          CPipelineCardsID: () => inprogressLoan.PipelineCardsID,
          cLoanScenarioId: () => inprogressLoan.LoanScenarioID,
          cFamilyId: () => $scope.familyId,
          cEnquirySource: () => $scope.getContactFamilyInfo.ReferralItem,
        },
        controller: 'WorkbenchModalCtrl',
        controllerAs: 'vm',
        windowTopClass: 'workbench-modal',
      });

      modalInstance.result.then(() => {
      }, (result) => {
        forEach($scope.LoanStatusList, (stat) => {
          if (String(stat.LoanStatusID) === result.loanStatusId) {
            inprogressLoan.LoanStatus.LoanStatusID = result.loanStatusId;
            inprogressLoan.LoanStatus.LoanStatusName = stat.LoanStatusName;
          }
        });
        $scope.getLoanListGet();
      });
    };

    $scope.getReasonList = () => {
      pipelineService.getNotProceedReasons(PIPELINE_TYPES_ID.APPLICATION)
        .then(({ reasonList }) => {
          $scope.reasonListView = reasonList || null;
        });
    };
    $scope.getReasonList();
  });
