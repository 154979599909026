import {
  maximumLVRBuilderForUI,
  resourceMyDocumentsBuilderForUI,
  resourceMyDocumentsBuilderForMyCRM,
  offsetCalculatorBuilderForUI,
} from 'Common/mappers/loanTools';
import { importantDocumentBuilderForUI } from 'Common/mappers/importantDocument';

class LoanToolsService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'LoanTools';
  }

  getLendersList() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanProviderLendersGet`);
  }

  getMaximumLVRList(loanAmount, repaymentType) {
    return this.httpClient.get(`${this.apiBaseUrl}/LenderInfoMaxLVRGet`, { loanAmount, repaymentType })
      .then(response => response.data.map(maximumLVRBuilderForUI));
  }

  LocalizationGet(module) {
    // TODO: move to generalService
    return this.httpClient.get(`LocalizationGet`, { module });
  }

  getImportantDocs() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanProviderImportantDocumentsGet`)
      .then(response => response.data.map(importantDocumentBuilderForUI));
  }

  LoanRateGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanRateGet`);
  }

  getLenderContactDetail(providerID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanProviderContactDetailGet`, { providerID });
  }

  getLoanProviderKeyRelationships(providerID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanProviderKeyRelationshipsGet`, { providerID });
  }

  getLenderRates(providerIDs) {
    // TODO: fix the query string of providerIDs
    return this.httpClient.get(`${this.apiBaseUrl}/LoanProviderInterestRatesGet?${providerIDs}`);
  }

  getLoanProviderDocuments(providerID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanProviderDocumentsGet`, { providerID });
  }

  getRepaymentCalculations(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/RepaymentCalculationsGet`, params);
  }

  getAdditionalRepaymentCalculations(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/RepaymentCalculationsGet`, params);
  }

  getSavingGoalCalculations(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/SavingCalculationsGet`, params);
  }

  getCompareLoanCalculations(data) {
    const {
      loanProviderMine,
      loanMine,
      loanTermMine,
      fixedRateMine,
      fixedPeriodMine,
      ongoingRateMine,
      upFrontFeesMine,
      otherFeesMine,
      otherFeesFrequencyMine,
      dischargedFeesMine,
      loanProviderOther,
      loanOther,
      loanTermOther,
      fixedRateOther,
      fixedPeriodOther,
      ongoingRateOther,
      upFrontFeesOther,
      otherFeesOther,
      otherFeesFrequencyOther,
      dischargedFeesOther,
      upFrontCashMine,
      upFrontCashOther,
    } = data;
    const params = {
      [`mortgageProviders.myLender.provider`]: loanProviderMine,
      [`mortgageProviders.myLender.loanAmount`]: loanMine,
      [`mortgageProviders.myLender.loanTerm`]: loanTermMine,
      [`mortgageProviders.myLender.fixedRate`]: fixedRateMine,
      [`mortgageProviders.myLender.fixedPeriod`]: fixedPeriodMine,
      [`mortgageProviders.myLender.onGoingRate`]: ongoingRateMine,
      [`mortgageProviders.myLender.upfrontFrees`]: upFrontFeesMine,
      [`mortgageProviders.myLender.otherFees`]: otherFeesMine,
      [`mortgageProviders.myLender.otherFeesFrequency`]: otherFeesFrequencyMine,
      [`mortgageProviders.myLender.dischargedFees`]: dischargedFeesMine,
      [`mortgageProviders.otherLender.provider`]: loanProviderOther,
      [`mortgageProviders.otherLender.loanAmount`]: loanOther,
      [`mortgageProviders.otherLender.loanTerm`]: loanTermOther,
      [`mortgageProviders.otherLender.fixedRate`]: fixedRateOther,
      [`mortgageProviders.otherLender.fixedPeriod`]: fixedPeriodOther,
      [`mortgageProviders.otherLender.onGoingRate`]: ongoingRateOther,
      [`mortgageProviders.otherLender.upfrontFrees`]: upFrontFeesOther,
      [`mortgageProviders.otherLender.otherFees`]: otherFeesOther,
      [`mortgageProviders.otherLender.otherFeesFrequency`]: otherFeesFrequencyOther,
      [`mortgageProviders.otherLender.dischargedFees`]: dischargedFeesOther,
      [`mortgageProviders.myLender.upfrontCash`]: upFrontCashMine,
      [`mortgageProviders.otherLender.upfrontCash`]: upFrontCashOther,
    };
    return this.httpClient.get(`${this.apiBaseUrl}/LoanComparisonCalculatorGet`, params);
  }

  hemCalculatorInfoGet(data) {
    return this.httpClient.get(`${this.apiBaseUrl}/HEMCalculatorInfoGet`, data);
  }

  hemCalculatorStatesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/HEMCalculatorStatesGet`);
  }

  hemMaritalStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/HEMMaritalStatusGet`);
  }

  saveKeyContact(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanProviderKeyRelationshipsSet`, data);
  }

  updateKeyContact(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanProviderKeyRelationshipsSet`, data);
  }

  deleteKeyContact(keyRelationshipID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LoanProviderKeyRelationshipsDelete`, { keyRelationshipID });
  }

  LoanProviderDocumentsDelete(iD) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LoanProviderDocumentsDelete`, { iD });
  }

  OffsetCalculationsSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/OffsetCalculator`, params)
      .then(response => offsetCalculatorBuilderForUI(response.data));
  }

  LoanProviderDocumentsSlimSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanProviderDocumentsSlimSet`, data);
  }

  resourceMyDocumentsSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/Resources/MyDocuments`, resourceMyDocumentsBuilderForMyCRM(data));
  }

  resourceMyDocumentsGet(documentFileId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/MyDocuments`, { documentFileId })
      .then(response => response.data.map(resourceMyDocumentsBuilderForUI));
  }

  resourceMyDocumentsSearch(searchName) {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/MyDocuments`, { searchName })
      .then(response => response.data.map(resourceMyDocumentsBuilderForUI));
  }

  resourceMyDocumentsDelete(documentFileId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/Resources/MyDocuments`, { documentFileId });
  }

  resourceTemplatesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Templates`);
  }

  resourceTemplatesSearch(searchName) {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Templates`, { searchName });
  }

  resourceEducationGet(educationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Education`, { educationId });
  }

  resourceEducationSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/Resources/Education`, data);
  }

  resourceEducationDelete(educationId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/Resources/Education`, { educationId });
  }

  resourceTrainingGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Training`);
  }

  resourceComplaintGet(complaintId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Complaint`, { complaintId });
  }

  resourceComplaintSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/Resources/Complaint`, data);
  }

  resourceComplaintDelete(complaintId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/Resources/Complaint`, { complaintId });
  }

  rubikServiceabilityCalcGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/RubikServiceabilityCalc`, {}, true, false);
  }
}

export default LoanToolsService;
