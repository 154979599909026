/* global $ */

import angular from 'angular';

angular.module('app').directive('loanApplicationPage', function loanApplicationPage(loanAppSharedData, $document, $timeout) {
  return {
    link: (scope, elem) => {
      scope.loanAppSharedData = loanAppSharedData;

      const startScrollSubSection = (sectionElement, elemParent, offsetElem) => {
        elem.on('scroll.sectionloanapp', () => {
          const elementIsActive = elemParent.hasClass('is-active');
          const winTop = $(window).scrollTop();
          if (typeof sectionElement !== 'undefined' && elementIsActive) {
            if (typeof offsetElem.top !== 'undefined' && winTop > offsetElem.top && (elemParent.height() + offsetElem.top) - 60 > winTop) {
              const topCalculate = Math.floor(winTop - offsetElem.top) + 53;
              sectionElement.css({
                position: 'relative',
                top: `${topCalculate}px`,
              });
            } else {
              sectionElement.removeAttr('style');
            }
          } else {
            sectionElement.removeAttr('style');
          }
        });

        loanAppSharedData.sectionSelected = null;
        loanAppSharedData.subSectionSelected = null;
      };

      const startScrollSection = () => {
        const elemId = elem.find(`#${loanAppSharedData.sectionSelected}`).parent().attr('id');
        elem.off('scroll.sectionloanapp');
        elem.find('.level-2-heading').removeAttr('style');

        // scroll to the parent sectionm
        const htmlElement = elem.find(`#${elemId}`);
        $document.scrollToElementAnimated(htmlElement, 55, 800);
        $document.scrollToElementAnimated(elem.find(`#${loanAppSharedData.subSectionSelected}`), 105, 800);
        const sectionElement = elem.find(`#${loanAppSharedData.sectionSelected}`);
        const elemParent = elem.find(`#${loanAppSharedData.sectionSelected}`).parent();
        const offsetElem = elemParent.offset();

        $timeout(startScrollSubSection(sectionElement, elemParent, offsetElem), 1300);
      };

      scope.$watch('loanAppSharedData.sectionSelected', (nv) => {
        if (nv) {
          $timeout(startScrollSection, 100);
        }
      }, true);
    },
  };
});
