import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('app').directive('appWalkThru', function appWalkThru($timeout, $state, $stateParams, $rootScope, contactService, generalService, $document, $window, configService) {
  return {
    restrict: 'E',
    link(scope) {
      const walkthruInit = function () {
        if ($('body').width() > 991) {
          scope.wtSettings = [];
          scope.isFirstTimeWalkthru = true;

          const setFalse = {
            WalkThroughID: 0,
            IsWelcomeMessage: false,
            IsDashboardTopControls: false,
            IsDashboardWidgets: false,
            IsContacts: false,
            IsTasks: false,
            IsServiceAbilityStep1: false,
            IsServiceAbilityStep2: false,
            IsserviceabilityStep3: false,
            IsProductFinderStep1: false,
            IsProductFinderStep2: false,
            IsProductFinderStep3: false,
            IsFundingCalcStep1: false,
            IsFundingCalcStep2: false,
            IsContactSummary: false,
            IsPipelineLeads: false,
            IsPipelineApplication: false,
          };
          const setTrue = {
            WalkThroughID: 0,
            IsWelcomeMessage: true,
            IsDashboardTopControls: true,
            IsDashboardWidgets: true,
            IsContacts: true,
            IsTasks: true,
            IsServiceAbilityStep1: true,
            IsServiceAbilityStep2: true,
            IsserviceabilityStep3: true,
            IsProductFinderStep1: true,
            IsProductFinderStep2: true,
            IsProductFinderStep3: true,
            IsFundingCalcStep1: true,
            IsFundingCalcStep2: true,
            IsContactSummary: true,
            IsPipelineLeads: true,
            IsPipelineApplication: true,
          };

          generalService.getWalkThrough().then((getResp) => {
            if (getResp.data === null) {
              /*
                             * false to show, true to hide
                             * */

              scope.isFirstTimeWalkthru = true;

              generalService.setWalkThrough(
                setFalse
              ).then(
                (setResp) => {
                  scope.wtSettings = setResp.data;
                }
              );
            } else {
              scope.isFirstTimeWalkthru = false;

              scope.wtSettings = getResp.data;
            }

            contactService.getUserInfo().then((response) => {
              const user = response.data;
              const FamilyId = user.FamilyId;
              const Email = user.Email;
              const FullName = user.FullName.charAt(0).toUpperCase() + user.FullName.split(' ')[0].slice(1);
              let wsConfig = { emails: [], ids: [] };
              if (configService && configService.welcome_screen) {
                wsConfig = configService.welcome_screen;
              }
              const isWhiteList = (wsConfig.emails && wsConfig.emails.includes(Email)) || (wsConfig.ids && wsConfig.ids.includes(FamilyId));

              const ElementData = function (data) {
                scope.currentSection = data;

                switch (data) {
                case 'navigation':
                  this.data = {
                    menuBtn: {
                      set: 1,
                      position: 'left',
                      target: 'menu',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#menu').offset().left + 20,
                      offsetTop: angular.element('#menu').offset().top,
                      offset: 0,
                      desc: 'Expand or contract your left menu to give you more room',
                    },
                    newBtn: {
                      set: 2,
                      position: 'left',
                      target: 'top-nav-launch',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#top-nav-launch').offset().left + 50,
                      offsetTop: angular.element('#top-nav-launch').offset().top - 20,
                      offset: 0,
                      desc: 'Launch into creating new contacts, loan applications and quick calculators',
                    },
                    appointmentsBtn: {
                      set: 2,
                      position: 'right',
                      target: 'top-nav-appointment',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#top-nav-appointment').offset().left - 95,
                      offsetTop: angular.element('#top-nav-appointment').offset().top - 5,
                      offset: 0,
                      desc: 'Integrate with your Google Calendar to view todays appointments',
                    },
                    searchField: {
                      set: 3,
                      position: 'left',
                      target: 'main-search',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#main-search').offset().left + 50,
                      offsetTop: angular.element('#main-search').offset().top,
                      offset: 0,
                      desc: 'Find a contact using a name, email or phone number',
                    },
                    tasksBtn: {
                      set: 3,
                      position: 'right',
                      target: 'top-nav-task',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#top-nav-task').offset().left - 95,
                      offsetTop: angular.element('#top-nav-task').offset().top - 5,
                      offset: 0,
                      desc: 'View tasks that you have due today',
                    },
                    viewedBtn: {
                      set: 4,
                      position: 'left',
                      target: 'main-search',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#main-search').offset().left + 225,
                      offsetTop: angular.element('#main-search').offset().top,
                      offset: 0,
                      desc: 'View the last contacts you opened',
                    },
                    activityFeedBtn: {
                      set: 4,
                      position: 'right',
                      target: 'top-nav-notifications',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#top-nav-notifications').offset().left - 95,
                      offsetTop: angular.element('#top-nav-notifications').offset().top - 5,
                      offset: 0,
                      desc: 'See whats happened in your database, and status updates from lenders',
                    },
                    logoutBtn: {
                      set: 5,
                      position: 'right',
                      target: 'top-nav-logout',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#top-nav-logout').offset().left - 200,
                      offsetTop: angular.element('#top-nav-logout').offset().top - 5,
                      offset: 110,
                      desc: 'Logout of the system',
                    },
                    feedbackBtn: {
                      set: 5,
                      position: 'bottom',
                      target: 'temp-feedback-btn',
                      flip: false,
                      rotate: '70deg',
                      offsetLeft: angular.element('#temp-feedback-btn').offset().left - 200,
                      offsetTop: 200,
                      offset: 120,
                      desc: 'Send us feedback on any improvements you would like to see',
                    },
                    dashboardSettings: {
                      set: 6,
                      position: 'left',
                      target: 'settingsIcon',
                      flip: false,
                      rotate: '70deg',
                      offsetLeft: angular.element('#settingsIcon').offset().left + 20,
                      offsetTop: angular.element('#settingsIcon').offset().top - 100,
                      imgTop: -100,
                      offset: -20,
                      desc: 'Dashboard Settings / Add or Remove widgets',
                    },
                    addWidgetImage: {
                      set: 7,
                      custom: true,
                      target: 'settingsIcon',
                      position: 'custom',
                      offsetLeft: angular.element('#settingsIcon').offset().left - 200,
                      offsetTop: angular.element('#settingsIcon').offset().top - 33,
                      offset: 0,
                      imgSrc: '/assets/images/walkthru/add-widget.png',
                      desc: '',
                    },
                    addWidget: {
                      set: 7,
                      target: 'settingsIcon',
                      position: 'left',
                      rotate: '70deg',
                      offsetLeft: angular.element('#settingsIcon').offset().left + 200,
                      offsetTop: angular.element('#settingsIcon').offset().top + 100,
                      imgTop: -100,
                      offset: 0,
                      desc: 'Add remove widgets to your dashboard',
                    },
                    widgetTimeframeImage: {
                      set: 8,
                      custom: true,
                      target: 'settingsIcon',
                      position: 'custom',
                      offsetLeft: angular.element('#settingsIcon').offset().left - 530,
                      offsetTop: angular.element('#settingsIcon').offset().top - 33,
                      offset: 300,
                      imgSrc: '/assets/images/walkthru/widget-timeframes.png',
                      desc: '',
                    },
                    widgetTimeframe: {
                      set: 8,
                      target: 'settingsIcon',
                      position: 'bottom',
                      rotate: '70deg',
                      offsetLeft: angular.element('#settingsIcon').offset().left + 370,
                      offsetTop: angular.element('#settingsIcon').offset().top - 300,
                      imgTop: 0,
                      offset: -100,
                      desc: 'Choose what timeframes you are seeing for a dashboard item',
                    },
                    widgetSettingsImage: {
                      set: 9,
                      custom: true,
                      target: 'settingsIcon',
                      position: 'custom',
                      offsetLeft: angular.element('#settingsIcon').offset().left - 530,
                      offsetTop: angular.element('#settingsIcon').offset().top - 33,
                      offset: 300,
                      imgSrc: '/assets/images/walkthru/widget-timeframes.png',
                      desc: '',
                    },
                    widgetSettings: {
                      set: 9,
                      target: 'settingsIcon',
                      position: 'bottom',
                      rotate: '70deg',
                      offsetLeft: angular.element('#settingsIcon').offset().left + 460,
                      offsetTop: angular.element('#settingsIcon').offset().top - 300,
                      imgTop: 0,
                      offset: -100,
                      desc: 'Choose any specific settings that a widget has',
                    },
                    widgetLinkImage: {
                      set: 10,
                      custom: true,
                      target: 'settingsIcon',
                      position: 'custom',
                      offsetLeft: angular.element('#settingsIcon').offset().left - 530,
                      offsetTop: angular.element('#settingsIcon').offset().top - 33,
                      offset: 300,
                      imgSrc: '/assets/images/walkthru/widget-timeframes.png',
                      desc: '',
                    },
                    widgetLink: {
                      set: 10,
                      target: 'settingsIcon',
                      position: 'bottom',
                      rotate: '70deg',
                      offsetLeft: angular.element('#settingsIcon').offset().left - 60,
                      offsetTop: angular.element('#settingsIcon').offset().top - 300,
                      imgTop: 20,
                      offset: -100,
                      desc: 'Click on a widget to open the relevant Pipeline or Customer Care section',
                    },

                  };
                  break;
                case 'contacts':
                  this.data = {
                    createContactBtn: {
                      set: 1,
                      position: 'left',
                      target: 'createContact',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#createContact').offset().left + 20,
                      offsetTop: angular.element('#createContact').offset().top - 85,
                      offset: 40,
                      imgTop: -100,
                      desc: 'Click to create a new contact in your customer base',
                    },
                    lettersFilter: {
                      set: 1,
                      position: 'left',
                      target: 'menu',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#menu').offset().left + 160,
                      offsetTop: angular.element('#menu').offset().top + 235,
                      offset: -100,
                      imgTop: 0,
                      desc: 'Sort your customer base by letter',
                    },
                    orderByColumn: {
                      set: 2,
                      position: 'left',
                      target: 'menu',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#menu').offset().left + 270,
                      offsetTop: angular.element('#menu').offset().top + 215,
                      offset: -100,
                      imgTop: 0,
                      desc: 'Order the results by any column',
                    },
                    displayFilter: {
                      set: 2,
                      position: 'right',
                      target: 'user-header-name',
                      flip: true,
                      rotate: '100deg',
                      offsetLeft: angular.element('#user-header-name').offset().left - 30,
                      offsetTop: angular.element('#user-header-name').offset().top + 30,
                      offset: 0,
                      desc: 'Choose Filter options to help you search your customers',
                    },
                    searchField: {
                      set: 3,
                      position: 'right',
                      target: 'user-header-name',
                      flip: true,
                      rotate: '100deg',
                      offsetLeft: angular.element('#user-header-name').offset().left + 50,
                      offsetTop: angular.element('#user-header-name').offset().top + 50,
                      offset: 200,
                      desc: 'Click to search for a customer by name',
                    },
                    clickToOpen: {
                      set: 3,
                      position: 'left',
                      target: 'menu',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#menu').offset().left + 200,
                      offsetTop: angular.element('#menu').offset().top + 355,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Click directly on a name to open that contact',
                    },
                    deleteContactImage: {
                      set: 4,
                      custom: true,
                      position: 'custom',
                      target: 'user-header-name',
                      offsetLeft: angular.element('#user-header-name').offset().left + 190,
                      offsetTop: angular.element('#user-header-name').offset().top + 170,
                      offset: 0,
                      imgSrc: '/assets/images/walkthru/contact-delete.png',
                      desc: '',
                    },
                    deleteContact: {
                      set: 4,
                      position: 'right',
                      target: 'user-header-name',
                      flip: true,
                      rotate: '100deg',
                      offsetLeft: angular.element('#user-header-name').offset().left - 10,
                      offsetTop: angular.element('#user-header-name').offset().top + 250,
                      offset: 150,
                      imgTop: 0,
                      desc: 'Delete contact here',
                    },
                    paging: {
                      set: 5,
                      imgTop: 100,
                      scroll: angular.element('body').height(),
                      scrollTimeout: 1,
                      position: 'bottom',
                      target: 'crm-pager',
                      flip: false,
                      rotate: '70deg',
                      offsetLeft: angular.element('#temp-feedback-btn').offset().left - 300,
                      offsetTop: 100,
                      offset: 120,
                      desc: 'Navigate the pages',
                    },
                  };
                  break;
                case 'tasks':
                  this.data = {
                    newTask: {
                      set: 1,
                      position: 'right',
                      target: 'wt-add-new-task',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-add-new-task').offset().left,
                      offsetTop: angular.element('#wt-add-new-task').offset().top - 155,
                      offset: -40,
                      desc: 'Create a new task or templated task by clicking here',
                    },
                    templatedTaskSettings: {
                      set: 2,
                      position: 'right',
                      target: 'wt-temp-settings',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-temp-settings').offset().left - 45,
                      offsetTop: angular.element('#wt-temp-settings').offset().top - 155,
                      offset: -40,
                      desc: 'Manage your templated task settings here',
                    },
                    search: {
                      set: 3,
                      position: 'left',
                      target: 'wt-search',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-search').offset().left + 145,
                      offsetTop: angular.element('#wt-search').offset().top - 275,
                      offset: -100,
                      imgTop: 0,
                      desc: 'Search for task you already created by entering some of the content here',
                    },
                    dueDate: {
                      set: 1,
                      position: 'left',
                      target: 'wt-due-date',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-due-date').offset().left + 50,
                      offsetTop: angular.element('#wt-due-date').offset().top - 155,
                      offset: 40,
                      desc: 'Filter your results by Due Date, Assigned to, Created by',
                    },
                    showCompleted: {
                      set: 2,
                      position: 'right',
                      target: 'wt-show-completed',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-show-completed').offset().left - 150,
                      offsetTop: angular.element('#wt-show-completed').offset().top - 165,
                      offset: 40,
                      desc: 'Show tasks that you have already completed today',

                    },
                    orderByCol: {
                      set: 3,
                      position: 'right',
                      target: 'wt-order-col',
                      flip: true,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-order-col').offset().left + 60,
                      offsetTop: angular.element('#wt-order-col').offset().top - 210,
                      offset: 40,
                      desc: 'Order by column',
                    },
                  };
                  break;
                case 'serviceability-step-1':
                  this.data = {
                    clientCountPanel: {
                      set: 1,
                      position: 'left',
                      target: 'family-detail-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#family-detail-panel').offset().left + 240,
                      offsetTop: angular.element('#family-detail-panel').offset().top - 210,
                      offset: 40,
                      desc: 'Choose the number of clients and dependants ',
                      scroll: 130,
                    },
                    incomePanel: {
                      set: 2,
                      position: 'left',
                      target: 'income-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#income-panel').offset().left + 180,
                      offsetTop: angular.element('#income-panel').offset().top - 410,
                      offset: -80,
                      imgTop: -30,
                      desc: 'Add any income for each of your clients',
                      scroll: 130,

                    },
                    expensePanel: {
                      set: 1,
                      position: 'left',
                      target: 'expense-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#expense-panel').offset().left + 230,
                      offsetTop: angular.element('#expense-panel').offset().top - 530,
                      offset: 40,
                      desc: 'Use either default expenses or create a list',
                      scroll: 130,

                    },
                    liabilitiesPanel: {
                      set: 2,
                      position: 'left',
                      target: 'income-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#income-panel').offset().left + 200,
                      offsetTop: angular.element('#income-panel').offset().top - 200,
                      offset: -80,
                      imgTop: -30,
                      desc: 'Add any liabilities for your clients',
                      scroll: 130,

                    },
                    calculate: {
                      set: 3,
                      position: 'bottom',
                      target: 'expense-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#expense-panel').offset().left + 30,
                      offsetTop: angular.element('#expense-panel').offset().top - 850,
                      offset: 120,
                      top: 600,
                      desc: 'Click Calculate to see your results',
                      scroll: 150,
                      imgTop: -30,
                      scrollTimeout: 1000,
                    },
                  };
                  break;
                case 'serviceability-step-2-income':
                  this.data = {
                    selectType: {
                      set: 1,
                      position: 'left',
                      target: 'income-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#income-panel').offset().left + 150,
                      offsetTop: angular.element('#income-panel').offset().top - 350,
                      offset: -100,
                      desc: 'Choose a type of income, and which client it relates to',
                    },
                    limit: {
                      set: 2,
                      position: 'left',
                      target: 'income-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#income-panel').offset().left + 290,
                      offsetTop: angular.element('#income-panel').offset().top - 350,
                      offset: -100,
                      desc: 'Enter in the amount of income',
                    },
                    frequency: {
                      set: 3,
                      position: 'left',
                      target: 'income-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#income-panel').offset().left + 330,
                      offsetTop: angular.element('#income-panel').offset().top - 350,
                      offset: -100,
                      desc: 'Select how often that income is received',
                    },
                    save: {
                      set: 4,
                      position: 'left',
                      target: 'income-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#income-panel').offset().left + 370,
                      offsetTop: angular.element('#income-panel').offset().top - 350,
                      offset: -100,
                      desc: 'Tick to confirm that income',
                    },
                  };
                  break;
                case 'serviceability-step-2-liability':
                  this.data = {
                    selectType: {
                      set: 1,
                      position: 'left',
                      target: 'liabilities-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#liabilities-panel').offset().left + 170,
                      offsetTop: angular.element('#liabilities-panel').offset().top - 560,
                      offset: -100,
                      scroll: 200,
                      desc: 'Click on the heart to favourite a lender for your product comparison',
                    },
                    repayments: {
                      set: 2,
                      position: 'left',
                      target: 'liabilities-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#liabilities-panel').offset().left + 250,
                      offsetTop: angular.element('#liabilities-panel').offset().top - 540,
                      offset: -100,
                      scroll: 200,
                      desc: 'Here it he maximum borrowing available from this lender',
                    },
                    frequency: {
                      set: 3,
                      position: 'left',
                      target: 'liabilities-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#liabilities-panel').offset().left + 320,
                      offsetTop: angular.element('#liabilities-panel').offset().top - 540,
                      offset: -100,
                      scroll: 200,
                      desc: 'Servicing rate used by the lender',
                    },
                    balance: {
                      set: 4,
                      position: 'left',
                      target: 'liabilities-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#liabilities-panel').offset().left + 250,
                      offsetTop: angular.element('#liabilities-panel').offset().top - 520,
                      offset: -100,
                      scroll: 200,
                      desc: 'Spreadsheet for the lender',
                    },
                    save: {
                      set: 4,
                      position: 'left',
                      target: 'liabilities-panel',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#liabilities-panel').offset().left + 250,
                      offsetTop: angular.element('#liabilities-panel').offset().top - 520,
                      offset: -100,
                      scroll: 200,
                      desc: 'Tick to confirm that liability',
                    },
                  };
                  break;
                case 'serviceability-step-3':
                  this.data = {
                    LenderDisplay: {
                      set: 1,
                      position: 'left',
                      target: 'LenderDisplay',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#LenderDisplay').offset().left + 120,
                      offsetTop: angular.element('#LenderDisplay').offset().top - 220,
                      offset: -100,
                      desc: 'Favourite',
                    },
                    LoanCalculation: {
                      set: 1,
                      position: 'left',
                      target: 'MaxLoanAmount',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#MaxLoanAmount').offset().left + 170,
                      offsetTop: angular.element('#MaxLoanAmount').offset().top - 380,
                      offset: -80,
                      imgTop: -30,
                      desc: 'Max Loan Amount',
                    },
                    spreadsheet: {
                      set: 2,
                      position: 'right',
                      target: 'headerSpreadSheet',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#headerSpreadSheet').offset().left - 150,
                      offsetTop: angular.element('#headerSpreadSheet').offset().top - 470,
                      offset: 80,
                      imgTop: -30,
                      desc: 'Spreadsheet',
                    },
                    viewProductFinder: {
                      set: 2,
                      position: 'left',
                      target: 'viewSingleProduct',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#viewSingleProduct').offset().left + 150,
                      offsetTop: angular.element('#viewSingleProduct').offset().top - 380,
                      offset: -80,
                      imgTop: -30,
                      desc: 'View in Finder',
                    },
                  };
                  break;
                case 'product-finder-step-1':
                  this.data = {
                    totalLoan: {
                      set: 1,
                      position: 'right',
                      target: 'wt-total-loan',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-total-loan').offset().left - 190,
                      offsetTop: angular.element('#wt-total-loan').offset().top - 420,
                      offset: 170,
                      imgTop: -30,
                      desc: 'Enter Total Loan Amount',
                    },
                    security: {
                      set: 1,
                      position: 'left',
                      target: 'wt-total-security',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-total-loan').offset().left + 350,
                      offsetTop: angular.element('#wt-total-loan').offset().top - 320,
                      offset: -100,
                      imgTop: -30,
                      desc: 'Enter Total Loan Security',
                    },
                    lenders: {
                      set: 2,
                      position: 'right',
                      target: 'wt-lenders',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-lenders').offset().left - 190,
                      offsetTop: angular.element('#wt-lenders').offset().top - 420,
                      offset: 170,
                      imgTop: -30,
                      desc: 'Choose which lenders, or leave default for all',
                    },
                    loanTerm: {
                      set: 2,
                      position: 'left',
                      target: 'wt-loan-term',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-loan-term').offset().left + 150,
                      offsetTop: angular.element('#wt-loan-term').offset().top - 310,
                      offset: -100,
                      imgTop: -30,
                      desc: 'Specify a loan term',
                    },
                    lvr: {
                      set: 3,
                      position: 'right',
                      target: 'wt-lvr',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-lvr').offset().left - 190,
                      offsetTop: angular.element('#wt-lvr').offset().top - 400,
                      offset: 150,
                      imgTop: -30,
                      desc: 'Choose an LVR range',
                    },
                    splitLoan: {
                      set: 3,
                      position: 'left',
                      target: 'wt-split-loan',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-split-loan').offset().left + 100,
                      offsetTop: angular.element('#wt-split-loan').offset().top - 200,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Click here to create loan splits or leave off for basic loan',
                    },
                    loanAmount: {
                      set: 4,
                      position: 'right',
                      target: 'wt-loan-amount',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-loan-amount').offset().left - 120,
                      offsetTop: angular.element('#wt-loan-amount').offset().top - 630,
                      offset: 80,
                      imgTop: 40,
                      desc: 'Specify the loan amount for this split',
                    },
                    rateType: {
                      set: 4,
                      position: 'left',
                      target: 'wt-rate-type',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-rate-type').offset().left + 150,
                      offsetTop: angular.element('#wt-rate-type').offset().top - 470,
                      offset: -100,
                      imgTop: -30,
                      desc: 'Specify the rate type you want',
                    },
                    productType: {
                      set: 5,
                      position: 'right',
                      target: 'wt-product-type',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-product-type').offset().left - 120,
                      offsetTop: angular.element('#wt-product-type').offset().top - 630,
                      offset: 80,
                      imgTop: 30,
                      desc: 'Specify a Product Type',
                    },
                    advancedFilters: {
                      set: 5,
                      position: 'left',
                      target: 'wt-advanced-filters',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-advanced-filters').offset().left + 150,
                      offsetTop: angular.element('#wt-advanced-filters').offset().top - 470,
                      offset: -100,
                      imgTop: -30,
                      desc: 'Click to expand advanced filters',
                    },
                    search: {
                      set: 6,
                      position: 'left',
                      target: 'wt-search-btn',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-search-btn').offset().left + 150,
                      offsetTop: angular.element('#wt-search-btn').offset().top - 470,
                      offset: -100,
                      imgTop: -30,
                      desc: 'Ready? Click Search to get results',
                    },
                  };
                  break;
                case 'product-finder-step-2':
                  this.data = {
                    columnHeaders: {
                      set: 1,
                      position: 'right',
                      target: 'wt-loan-amount',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-loan-amount').offset().left - 120,
                      offsetTop: angular.element('#wt-loan-amount').offset().top - 580,
                      offset: 80,
                      imgTop: 30,
                      scroll: 200,
                      desc: 'Click to order results by these ',
                    },
                    productName: {
                      set: 1,
                      position: 'left',
                      target: 'wt-loan-amount',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-loan-amount').offset().left + 250,
                      offsetTop: angular.element('#wt-loan-amount').offset().top - 310,
                      offset: 0,
                      imgTop: -100,
                      scroll: 200,
                      desc: 'Click on a Product to review full details of that product',
                    },
                    favourite: {
                      set: 2,
                      position: 'left',
                      target: 'wt-loan-amount',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-loan-amount').offset().left + 10,
                      offsetTop: angular.element('#wt-loan-amount').offset().top - 290,
                      offset: 0,
                      imgTop: -100,
                      scroll: 200,
                      desc: 'Click on Favourite to add to your loan comparison',
                    },
                    compare: {
                      set: 2,
                      position: 'right',
                      target: 'wt-compare-btn',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-compare-btn').offset().left - 400,
                      offsetTop: angular.element('#wt-compare-btn').offset().top - 590,
                      offset: 190,
                      imgTop: 30,
                      scroll: 200,
                      wtVisible: ['compareBtn'],
                      desc: 'Click to view those products side by side and produce a report',
                    },
                  };
                  break;
                case 'product-finder-step-3':
                  break;
                case 'funding-calc-step-1':
                  this.data = {
                    propertyName: {
                      set: 1,
                      position: 'left',
                      target: 'wt-funding-required',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-funding-required').offset().left + 100,
                      offsetTop: angular.element('#wt-funding-required').offset().top - 60,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Enter a property name, full address or leave blank',
                    },
                    state: {
                      set: 2,
                      position: 'left',
                      target: 'wt-funding-required',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-funding-required').offset().left + 100,
                      offsetTop: angular.element('#wt-funding-required').offset().top + 0,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Select the state (for stamp duty calculations)',
                    },
                    purpose: {
                      set: 3,
                      position: 'left',
                      target: 'wt-funding-required',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-funding-required').offset().left + 100,
                      offsetTop: angular.element('#wt-funding-required').offset().top + 50,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Choose the purpose',
                    },
                    status: {
                      set: 4,
                      position: 'left',
                      target: 'wt-funding-required',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-funding-required').offset().left + 100,
                      offsetTop: angular.element('#wt-funding-required').offset().top + 95,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Choose the Status',
                    },
                    value: {
                      set: 5,
                      position: 'left',
                      target: 'wt-funding-required',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-funding-required').offset().left + 100,
                      offsetTop: angular.element('#wt-funding-required').offset().top + 150,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Enter in a value for the property',
                    },
                    save: {
                      set: 7,
                      position: 'left',
                      target: 'wt-funding-required',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-funding-required').offset().left + 70,
                      offsetTop: angular.element('#wt-funding-required').offset().top + 200,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Save the property (and then add more if you liike)',
                    },
                    firstTime: {
                      set: 6,
                      position: 'right',
                      target: 'wt-first-time-buyer',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-first-time-buyer').offset().left - 100,
                      offsetTop: angular.element('#wt-first-time-buyer').offset().top - 210,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Choose if the customer is a first home buyer',
                    },
                  };
                  break;
                case 'funding-calc-step-2':
                  this.data = {
                    menuBtn: {
                      set: 1,
                      position: 'left',
                      target: 'menu',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#menu').offset().left + 20,
                      offsetTop: angular.element('#menu').offset().top,
                      offset: 0,
                      desc: 'Expand or contract your left menu to give you more room',
                    },
                  };
                  break;
                case 'pipelineLeads':
                  this.data = {
                    listView: {
                      set: 1,
                      position: 'left',
                      target: 'wt-list-view',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-list-view').offset().left + 20,
                      offsetTop: angular.element('#wt-list-view').offset().top - 100,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Click to view your pipeline as a list',
                    },
                    cardView: {
                      set: 2,
                      position: 'left',
                      target: 'wt-list-view',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-list-view').offset().left + 60,
                      offsetTop: angular.element('#wt-list-view').offset().top - 100,
                      offset: 0,
                      desc: 'Click to view your pipeline as cards',
                    },
                    addCard: {
                      set: 3,
                      position: 'left',
                      target: 'wt-list-view',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-list-view').offset().left + 120,
                      offsetTop: angular.element('#wt-list-view').offset().top - 100,
                      offset: 0,
                      desc: 'Click here to add a new card',
                    },
                    showStats: {
                      set: 4,
                      position: 'left',
                      target: 'wt-show-stats',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-show-stats').offset().left + 20,
                      offsetTop: angular.element('#wt-show-stats').offset().top - 100,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Show the statistics',
                    },
                    filter: {
                      set: 5,
                      position: 'right',
                      target: 'wt-show-stats',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-show-stats').offset().left + 30,
                      offsetTop: angular.element('#wt-show-stats').offset().top - 215,
                      imgTop: -100,
                      offset: 0,
                      desc: 'Click to choose how to filter your list or cards',
                    },
                  };
                  break;
                case 'pipelineApplications':
                  this.data = {
                    listView: {
                      set: 1,
                      position: 'left',
                      target: 'wt-list-view',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-list-view').offset().left + 20,
                      offsetTop: angular.element('#wt-list-view').offset().top - 100,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Click to view your pipeline as a list',
                    },
                    cardView: {
                      set: 2,
                      position: 'left',
                      target: 'wt-list-view',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-list-view').offset().left + 60,
                      offsetTop: angular.element('#wt-list-view').offset().top - 100,
                      offset: 0,
                      desc: 'Click to view your pipeline as cards',
                    },
                    addCard: {
                      set: 3,
                      position: 'left',
                      target: 'wt-list-view',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-list-view').offset().left + 120,
                      offsetTop: angular.element('#wt-list-view').offset().top - 100,
                      offset: 0,
                      desc: 'Click here to add a new card',
                    },
                    showStats: {
                      set: 4,
                      position: 'left',
                      target: 'wt-show-stats',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-show-stats').offset().left + 20,
                      offsetTop: angular.element('#wt-show-stats').offset().top - 100,
                      offset: 0,
                      imgTop: -100,
                      desc: 'Show the statistics',
                    },
                    filter: {
                      set: 5,
                      position: 'right',
                      target: 'wt-show-stats',
                      flip: false,
                      rotate: '150deg',
                      offsetLeft: angular.element('#wt-show-stats').offset().left + 30,
                      offsetTop: angular.element('#wt-show-stats').offset().top - 215,
                      imgTop: -100,
                      offset: 0,
                      desc: 'Click to choose how to filter your list or cards',
                    },
                  };
                  break;
                default:
                  break;
                }
              };

              if (isWhiteList) {
                const doc = $document[0];
                let elementsHolder = {};
                const hacks = {
                  feedback() {
                    const feedbackBtn = doc.createElement('div');
                    feedbackBtn.setAttribute('id', 'temp-feedback-btn');
                    feedbackBtn.setAttribute('style', 'position:absolute;bottom:10px;right:30px;width:100px;height:20px');
                    doc.body.appendChild(feedbackBtn);
                  },
                  pagination() {
                    const feedbackBtn = doc.createElement('div');
                    feedbackBtn.setAttribute('id', 'wt-pagination');
                    feedbackBtn.setAttribute('style', 'position:absolute;bottom:10px;right:30px;width:100px;height:20px');
                    doc.body.appendChild(feedbackBtn);
                  },
                };
                hacks.feedback();
                hacks.pagination();

                const walkThru = {

                  activeGroup: 1,

                  welcomeListeners() {
                    let navLoadDone = false;

                    $timeout(() => {
                      elementsHolder = new ElementData('navigation');
                      navLoadDone = true;
                    }, 3000);

                    $document.on('click', '.close-welcome', () => {
                      $('.welcome-modal').fadeTo('slow', 0, () => {
                        $('.welcome-modal').remove();
                      });
                    });

                    $document.on('click', 'body .start-tour', () => {
                      if (angular.element('#dm').is(':checked')) {
                        scope.wtSettings.IsWelcomeMessage = true; // don't show welcome but show the rest

                        generalService.setWalkThrough(
                          scope.wtSettings
                        ).then(() => {
                          $('.welcome-modal').remove();
                        });
                      } else {
                        scope.wtSettings.IsWelcomeMessage = false; // revert it - show welcome msg next time
                      }

                      $('.welcome-box').remove();

                      $timeout(() => {
                        if (_.size(elementsHolder) > 0)
                          walkThru.init(elementsHolder.data);

                        $('.welcome-modal').remove();
                      }, navLoadDone === true ? 1000 : 3000);
                    });

                    $document.on('click', '.skip-tour', () => {
                      scope.wtSettings = setTrue;

                      if (angular.element('#dm').is(':checked')) {
                        scope.wtSettings.IsWelcomeMessage = true; // don't show welcome but show the rest
                      } else {
                        scope.wtSettings.IsWelcomeMessage = false; // revert it - show welcome msg next time
                      }

                      generalService.setWalkThrough(
                        scope.wtSettings
                      ).then(() => {
                        $('.welcome-modal').remove();
                      });
                    });
                  },

                  loader: {

                    create() {
                      const modalDiv = doc.createElement('div');
                      modalDiv.className = 'walkthru-loading-modal';
                      doc.body.setAttribute('style', `overflow:hidden;height:${$document.outerHeight()}px`);

                      angular.element(modalDiv).hide().appendTo($document[0].body).fadeIn(1000);

                      const section = doc.createElement('section');
                      modalDiv.appendChild(section);

                      const preloaderDiv = doc.createElement('div');
                      preloaderDiv.className = 'preloader';
                      section.appendChild(preloaderDiv);

                      const preloaderDot = [];
                      preloaderDot[1] = doc.createElement('span');
                      preloaderDot[2] = doc.createElement('span');
                      preloaderDot[3] = doc.createElement('span');
                      preloaderDot[1].className = 'preloader-dot';
                      preloaderDot[2].className = 'preloader-dot';
                      preloaderDot[3].className = 'preloader-dot';

                      const preloaderText = doc.createElement('span');
                      preloaderText.className = 'preloader-text';

                      preloaderText.appendChild(doc.createTextNode('Loading Page Tour'));
                      preloaderDiv.appendChild(preloaderDot[1]);
                      preloaderDiv.appendChild(preloaderDot[2]);
                      preloaderDiv.appendChild(preloaderDot[3]);
                      preloaderDiv.appendChild(preloaderText);
                    },

                    show() {
                      angular.element('html,body').animate({ scrollTop: 0 }, 0);

                      if ($state.is('app.dashboard')) {
                        if (scope.wtSettings.IsDashboardTopControls === false) {
                          this.create();
                        }
                      }

                      if ($state.is('app.contacts')) {
                        if (scope.wtSettings.IsContacts === false) {
                          this.create();
                        }
                      }

                      if ($state.is('app.tasks')) {
                        if (scope.wtSettings.IsTasks === false) {
                          this.create();
                        }
                      }

                      if ($state.is('app.fundingCalculator')) {
                        if (scope.wtSettings.IsServiceAbilityStep1 === false) {
                          this.create();
                        }
                      }

                      if ($state.is('app.leads')) {
                        if (scope.wtSettings.IsPipelineLeads === false) {
                          this.create();
                        }
                      }

                      if ($state.is('app.application')) {
                        if (scope.wtSettings.IsPipelineApplication === false) {
                          this.create();
                        }
                      }

                      if ($state.is('app.loanProfiler')) {
                        if ($stateParams.tab === 'serviceability') {
                          if (scope.wtSettings.IsServiceAbilityStep1 === false) {
                            this.create();
                          }
                        }

                        if ($stateParams.tab === 'productFinder') {
                          if (scope.wtSettings.IsProductFinderStep1 === false) {
                            this.create();
                          }
                        }
                      }
                    },

                    hide() {
                      doc.body.setAttribute('style', '');
                      angular.element('.walkthru-loading-modal').fadeOut('fast').remove();
                    },
                  },


                  createWelcome() {
                    walkThru.welcomeListeners();

                    const modalDiv = doc.createElement('div');
                    modalDiv.className = 'welcome-modal';
                    doc.body.setAttribute('style', 'overflow:hidden');
                    doc.body.appendChild(modalDiv);

                    const popupDiv = doc.createElement('div');
                    popupDiv.className = 'welcome-box';
                    modalDiv.appendChild(popupDiv);

                    // close
                    const close = doc.createElement('i');
                    close.className = 'fa';
                    close.className += ' fa-times';
                    close.className += ' cursor-pointer';
                    close.className += ' close-welcome';
                    popupDiv.appendChild(close);

                    // heading
                    const heading = doc.createElement('div');
                    heading.className = 'heading';
                    heading.appendChild(doc.createTextNode(`Hi ${FullName}, Welcome to`));

                    const logo = doc.createElement('img');
                    logo.setAttribute('src', '/assets/images/walkthru/MyCRM-logo.png');
                    logo.setAttribute('align', 'text-bottom');
                    logo.setAttribute('width', '124');
                    logo.setAttribute('height', '46');
                    heading.appendChild(logo);
                    popupDiv.appendChild(heading);

                    // content
                    const content = doc.createElement('div');
                    content.className = 'content';
                    content.className += ' margin-top-20';
                    content.appendChild(doc.createTextNode('As you navigate through the system you will be ' +
                                            'presented with some handy hints to help you get started. ' +
                                            'If you would prefer not to see these, then simply check the ' +
                                            '"Don\'t show again" option.'));

                    const content2 = doc.createElement('div');
                    content2.className = 'content';
                    content2.className += ' bold';
                    content2.className += ' margin-top-20';
                    content2.appendChild(doc.createTextNode('Have a look around and enjoy!'));

                    popupDiv.appendChild(content);
                    popupDiv.appendChild(content2);

                    // button

                    const btnContainer = doc.createElement('div');
                    btnContainer.className = 'padding-top-20';
                    const startBtn = doc.createElement('BUTTON');
                    startBtn.setAttribute('value', 'Start Tour');
                    startBtn.className = 'start-tour';
                    startBtn.className += ' margin-right-20';
                    startBtn.className += ' cursor-pointer';
                    startBtn.appendChild(doc.createTextNode('Start Tour'));
                    const skipBtn = doc.createElement('a');
                    skipBtn.className = 'cursor-pointer';
                    skipBtn.className += ' skip-tour';
                    skipBtn.appendChild(doc.createTextNode('Skip Tour'));
                    btnContainer.appendChild(startBtn);
                    btnContainer.appendChild(skipBtn);
                    popupDiv.appendChild(btnContainer);


                    // footer

                    const footer = doc.createElement('div');
                    footer.className = 'footer';
                    const checkboxDontShow = doc.createElement('input');
                    checkboxDontShow.type = 'checkbox';
                    checkboxDontShow.id = 'dm';
                    checkboxDontShow.setAttribute('checked', true);

                    const dmLabel = doc.createElement('label');
                    dmLabel.setAttribute('for', 'dm');
                    dmLabel.className = 'cursor-pointer';
                    dmLabel.appendChild(doc.createTextNode('Don\'t show again'));

                    footer.appendChild(checkboxDontShow);
                    footer.appendChild(dmLabel);

                    popupDiv.appendChild(footer);
                  },

                  createModal(data) {
                    angular.element('body').animate({ scrollTop: 0 }, 0);
                    angular.element('.felling-stuck-popover').css({ visibility: 'hidden' });

                    /**
                     * Create modal container
                     * */

                    const that = this;

                    const modalDiv = doc.createElement('div');
                    modalDiv.className = 'walkthru-modal';
                    modalDiv.setAttribute('style', `overflow:hidden;height:${$document.outerHeight()}px`);
                    // doc.body.setAttribute("style", 'overflow:hidden');
                    doc.body.appendChild(modalDiv);

                    that.listeners();
                    /**
                     * Process each element to walkthru
                     * */

                    _.forEach(data, (o) => {
                      o.top = _.isUndefined(o.top) ? 0 : o.top;
                      o.imgTop = _.isUndefined(o.imgTop) ? o.offsetTop - 100 : o.imgTop;

                      switch (o.position) {
                      case 'left':
                        o.rotate = '25deg';
                        break;
                      case 'right':
                        o.rotate = '180deg';
                        break;
                      default:
                        break;
                      }

                      const arrowDiv = doc.createElement('div');
                      arrowDiv.className = 'wi';
                      arrowDiv.setAttribute('data-walkthrough', 'data-walkthrough');
                      arrowDiv.setAttribute('data-set', o.set);
                      arrowDiv.setAttribute('data-top', o.top);
                      arrowDiv.setAttribute('data-position', o.position);
                      arrowDiv.setAttribute('data-ol', o.offsetLeft);
                      arrowDiv.setAttribute('data-ot', o.offsetTop + 140);
                      arrowDiv.setAttribute('data-target', o.target);
                      arrowDiv.setAttribute('data-scroll', o.scroll);
                      arrowDiv.setAttribute('data-show-el', !_.isUndefined(o.wtVisible) && o.wtVisible.length > 0 ? o.wtVisible.join(' ') : '');

                      const innerWrap = doc.createElement('div');
                      innerWrap.className = 'inner-wrap';
                      innerWrap.appendChild(doc.createTextNode(o.desc));
                      const img = doc.createElement('img');


                      if (o.custom !== undefined && o.custom === true) {
                        img.setAttribute('src', o.imgSrc);
                        img.setAttribute('style', 'box-shadow:1px 1px 10px #333;opacity:0.7');
                      } else
                        img.setAttribute('src', '/assets/images/arrow.svg');

                      img.setAttribute('data-rotate', o.rotate);
                      img.setAttribute('data-top', o.imgTop);
                      img.setAttribute('data-os', o.offset);
                      if (o.flip) {
                        img.setAttribute('data-flip', 'data-flip');
                      }

                      innerWrap.appendChild(img);

                      arrowDiv.appendChild(innerWrap);
                      modalDiv.appendChild(arrowDiv);
                    });


                    /**
                     * Create container for Next & Finish buttons
                     * */
                    const modalAction = doc.createElement('div');
                    modalAction.className = 'modal-action';
                    modalDiv.appendChild(modalAction);

                    const backBtn = doc.createElement('BUTTON');
                    backBtn.setAttribute('id', 'back');
                    modalAction.appendChild(backBtn);
                    backBtn.appendChild(doc.createTextNode('Back'));

                    const nextBtn = doc.createElement('BUTTON');
                    nextBtn.setAttribute('id', 'next');
                    modalAction.appendChild(nextBtn);
                    nextBtn.appendChild(doc.createTextNode('Next'));

                    const finBtn = doc.createElement('BUTTON');
                    finBtn.setAttribute('id', 'fin');
                    modalAction.appendChild(finBtn);
                    finBtn.appendChild(doc.createTextNode('Finish'));

                    const dontShowWalkthru = doc.createElement('div');
                    dontShowWalkthru.className = 'dontShowWalkthru';
                    modalAction.appendChild(dontShowWalkthru);

                    const checkboxDontShow = doc.createElement('input');
                    checkboxDontShow.type = 'checkbox';
                    checkboxDontShow.id = 'dm';
                    checkboxDontShow.setAttribute('checked', true);


                    const dmLabel = doc.createElement('label');
                    dmLabel.setAttribute('for', 'dm');
                    dmLabel.appendChild(doc.createTextNode('Don\'t show again'));

                    dontShowWalkthru.appendChild(checkboxDontShow);
                    dontShowWalkthru.appendChild(dmLabel);
                  },

                  /**
                   * Assign styles
                   * */
                  css(element, styles) {
                    styles && Object.keys(styles).forEach((property) => {
                      element.style[property] = styles[property];
                    });
                  },

                  /**
                   * Attach positions to elements
                   * */
                  setWalkThroughElement(_pos, element) {
                    walkThru.css(element, {
                      top: `${_pos.top + parseInt(element.dataset.ot, 10) + parseInt(element.dataset.top, 10)}px`,
                      left: `${parseInt(element.dataset.ol, 10)}px`,
                    });
                  },

                  attach() {
                    // position the tips to their parent

                    const _targets = [];

                    const tips = doc.querySelectorAll('[data-walkthrough]');

                    _.forEach(tips, (element) => {
                      const _par = doc.querySelector(`#${element.dataset.target}`);
                      const _pos = _par.getBoundingClientRect();

                      //  assign the targets
                      _targets.push(element.dataset.target);


                      // loop through targets and assign them individually
                      for (let i = 0, x = _targets.length; i < x; i++) {
                        if (element.dataset.target === _targets[i]) {
                          if (!element.dataset.position || element.dataset.position === 'left' || element.dataset.position === 'custom') {
                            walkThru.setWalkThroughElement(_pos, element);
                          }

                          if (element.dataset.position === 'right') {
                            walkThru.setWalkThroughElement(_pos, element);
                          }

                          if (element.dataset.position === 'bottom') {
                            walkThru.css(element, {
                              top: `${_pos.top - parseInt(element.dataset.ot, 10)}px`,
                              left: `${parseInt(element.dataset.ol, 10)}px`,
                            });
                          }
                        }
                      } // end loop

                      // position the images
                      const img = element.querySelector('img');

                      // assign initial arrow positioning
                      walkThru.css(img, {
                        top: `${img.dataset.top}px`,
                      });

                      // if arrow is flipped
                      // flip = rotate in Y axis
                      if (img.dataset.flip) {
                        img.style.transform = `rotateY(${img.dataset.rotate})`;
                      } else if (element.dataset.position === 'bottom')
                        img.style.transform = 'rotate(70deg) scaleX(-1)';
                      else
                        img.style.transform = `rotate(${img.dataset.rotate})`;

                      // if data has position assigned
                      if (img.dataset.position) {
                        img.style.right = `${img.dataset.ost}px`;
                      } else {
                        img.style.left = `${img.dataset.os}px`;
                      }
                    });
                  },


                  listeners() {},

                  init(data) {
                    const that = this;
                    /**
                     * Call to create the modal element
                     * */

                    that.createModal(data);

                    const modal = doc.getElementsByClassName('walkthru-modal');
                    const back = doc.getElementById('back');
                    const next = doc.getElementById('next');
                    const fin = doc.getElementById('fin');
                    const tips = doc.querySelectorAll('[data-walkthrough]');
                    const steps = [];
                    let maxSet = 0;
                    let activeGroup = 1;

                    /**
                     * Toggle visibility of walkthru arrows
                     * */

                    _.forEach(tips, (element) => {
                      if (maxSet < parseInt(element.dataset.set, 10)) {
                        maxSet = parseInt(element.dataset.set, 10);
                      }

                      if (element.dataset.set === '1') {
                        angular.element('.modal-action #back').attr('disabled', 'disabled').addClass('disabled').attr('style', 'background:#cccccc !important;border:none !important');

                        element.classList.remove('hidden');

                        if (!_.isUndefined(element.dataset.scroll)) {
                          const timeout = element.dataset.scrollTimeout !== undefined ? element.dataset.scrollTimeout : 10;

                          angular.element('html,body').animate({ scrollTop: element.dataset.scroll }, timeout);
                        }
                      } else {
                        element.classList.add('hidden');
                      }

                      steps.push(element.dataset.set);
                    });

                    /**
                     * Call to attach position to arrows
                     * */
                    that.attach();

                    /**
                     * Attach new position when resized
                     * */
                    angular.element(window).on('resize orientationchange load', () => {
                      angular.element('html,body').animate({ scrollTop: 0 }, 0);
                      that.attach();
                    });


                    /**
                     * When user clicks Back button
                     * */
                    back.checkScroll = (element) => {
                      if (!_.isUndefined(element.dataset.scroll)) {
                        angular.element('body').animate({ scrollTop: element.dataset.scroll }, 0);
                      }
                    };

                    back.addEventListener('click', () => {
                      activeGroup--;

                      angular.element('.walkthru-modal').css({
                        overflow: 'hidden',
                        height: $document.outerHeight(),
                      });

                      if (activeGroup > parseInt(steps[parseInt((steps.length - 1), 10)], 10)) {
                        modal.remove();
                        doc.body.setAttribute('style', '');
                        angular.element('html,body').animate({ scrollTop: 0 }, 1000);
                      } else {
                        _.forEach(tips, (element) => {
                          const counter = `${activeGroup}`;

                          if (element.dataset.set === counter) {
                            if (activeGroup < 2) {
                              angular.element('.modal-action #back').attr('disabled', 'disabled').addClass('disabled').attr('style', 'background:#cccccc !important;border:none !important');
                            } else {
                              angular.element('.modal-action #next').removeAttr('disabled').removeClass('disabled').removeAttr('style');
                            }

                            back.checkScroll(element);

                            element.classList.remove('hidden');
                          } else {
                            element.classList.add('hidden');
                          }
                        });
                      }
                    });

                    /**
                     * When user clicks Next button
                     * */
                    next.addEventListener('click', () => {
                      activeGroup++;

                      angular.element('.walkthru-modal').css({
                        overflow: 'hidden',
                        height: $document.outerHeight(),
                      });

                      if (activeGroup > parseInt(steps[parseInt((steps.length - 1), 10)], 10)) {
                        modal.remove();
                        doc.body.setAttribute('style', '');
                        angular.element('html,body').animate({ scrollTop: 0 }, 1000);
                      } else {
                        _.forEach(tips, (element) => {
                          const counter = `${activeGroup}`;

                          if (element.dataset.set === counter) {
                            if (maxSet <= activeGroup) {
                              angular.element('.modal-action #next').attr('disabled', 'disabled').addClass('disabled').attr('style', 'background:#cccccc !important;border:none !important');
                            } else {
                              angular.element('.modal-action #back').removeAttr('disabled').removeClass('disabled').removeAttr('style');
                            }

                            back.checkScroll(element);

                            element.classList.remove('hidden');
                          } else {
                            element.classList.add('hidden');
                          }
                        });
                      }
                    });

                    /**
                     * When user clicks Finished button
                     * */
                    fin.addEventListener('click', () => {
                      switch (scope.currentSection) {
                      case 'navigation':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsDashboardTopControls = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsDashboardTopControls = false; // revert it - show welcome msg next time

                        break;
                      case 'contacts':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsContacts = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsContacts = false; // revert it - show welcome msg next time

                        break;
                      case 'tasks':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsTasks = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsTasks = false; // revert it - show welcome msg next time

                        break;
                      case 'serviceability-step-1':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsServiceAbilityStep1 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsServiceAbilityStep1 = false; // revert it - show welcome msg next time

                        break;
                      case 'serviceability-step-2-income':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsServiceAbilityStep2 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsServiceAbilityStep2 = false; // revert it - show welcome msg next time
                        break;
                      case 'serviceability-step-2-liability':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsserviceabilityStep3 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsserviceabilityStep3 = false; // revert it - show welcome msg next time

                        break;
                      case 'serviceability-step-3':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsserviceabilityStep3 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsserviceabilityStep3 = false; // revert it - show welcome msg next time

                        break;
                      case 'product-finder-step-1':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsProductFinderStep1 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsProductFinderStep1 = false; // revert it - show welcome msg next time

                        break;
                      case 'product-finder-step-2':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsProductFinderStep2 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsProductFinderStep2 = false; // revert it - show welcome msg next time

                        break;
                      case 'product-finder-step-3':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsWelcomeMessage = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsWelcomeMessage = false; // revert it - show welcome msg next time

                        break;
                      case 'funding-calc-step-1':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsFundingCalcStep1 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsFundingCalcStep1 = false; // revert it - show welcome msg next time

                        break;
                      case 'funding-calc-step-2':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsFundingCalcStep2 = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsFundingCalcStep2 = false; // revert it - show welcome msg next time

                        break;
                      case 'contact':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsContactSummary = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsContactSummary = false; // revert it - show welcome msg next time

                        break;
                      case 'pipelineLeads':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsPipelineLeads = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsPipelineLeads = false; // revert it - show welcome msg next time

                        break;
                      case 'pipelineApplications':
                        if (angular.element('#dm').is(':checked'))
                          scope.wtSettings.IsPipelineApplication = true; // don't show welcome but show the rest
                        else
                          scope.wtSettings.IsPipelineApplication = false; // revert it - show welcome msg next time

                        break;
                      default:
                        break;
                      }

                      generalService.setWalkThrough(
                        scope.wtSettings
                      ).then(() => {
                        angular.element('.walkthru-modal').remove();
                      });

                      angular.element('.felling-stuck-popover').css({ visibility: 'visible' });
                      doc.body.setAttribute('style', '');
                      angular.element('html,body').animate({ scrollTop: 0 }, 1000);
                    });
                  },

                };

                const listeners = {
                  serviceabilityStep2() {
                    angular.element('[ng-click="addIncome()"]').on('click', () => {
                      elementsHolder = new ElementData('serviceability-step-2-income');
                      $timeout(() => {
                        if (_.size(elementsHolder) > 0)
                          walkThru.init(elementsHolder.data);
                      }, 100);
                    });

                    scope.$watch(
                      'isLenderLoaderOverlayDisplay',
                      (nv) => {
                        if (nv === false) {
                          $timeout(() => {
                            elementsHolder = new ElementData('serviceability-step-3');

                            if (_.size(elementsHolder) > 0)
                              walkThru.init(elementsHolder.data);
                          }, 500);
                        }
                      }
                    );
                  },
                  productFinderStep2() {
                    scope.$watch(
                      'isProductSearchOverlayDisplay',
                      (nv) => {
                        if (nv === false) {
                          $timeout(() => {
                            elementsHolder = new ElementData('product-finder-step-2');
                            if (_.size(elementsHolder) > 0)
                              walkThru.init(elementsHolder.data);
                          }, 1000);
                        }
                      }
                    );
                  },

                  fundingCalculatorStep2() {

                  },
                };

                const appDashboardWalkThru = () => {
                  let holder = {};
                  if (scope.wtSettings.IsWelcomeMessage === false) {
                    holder = []; // throw an empty data to disable walkthru
                    walkThru.createWelcome();
                  } else if (scope.wtSettings.IsDashboardTopControls === false) {
                    holder = new ElementData('navigation');
                  }
                  return holder;
                };

                const runWalkThruInit = (holder) => {
                  $timeout(() => {
                    walkThru.loader.hide();
                    if (_.size(holder) > 0)
                      walkThru.init(holder.data);
                  }, 1000);
                };

                $timeout(() => {
                  walkThru.loader.show();

                  $timeout(() => {
                    if ($state.is('app.dashboard')) {
                      elementsHolder = appDashboardWalkThru();
                    }

                    if ($state.is('app.contacts')) {
                      if (scope.wtSettings.IsContacts === false) {
                        elementsHolder = new ElementData('contacts');
                      }
                    }

                    if ($state.is('app.tasks')) {
                      if (scope.wtSettings.IsTasks === false) {
                        elementsHolder = new ElementData('tasks');
                      }
                    }

                    if ($state.is('app.fundingCalculator')) {
                      if (scope.wtSettings.IsServiceAbilityStep1 === false) {
                        elementsHolder = new ElementData('funding-calc-step-1');
                        listeners.fundingCalculatorStep2();
                      }
                    }

                    if ($state.is('app.leads')) {
                      if (scope.wtSettings.IsPipelineLeads === false) {
                        elementsHolder = new ElementData('pipelineLeads');
                      }
                    }

                    if ($state.is('app.application')) {
                      if (scope.wtSettings.IsPipelineApplication === false) {
                        elementsHolder = new ElementData('pipelineApplications');
                      }
                    }

                    if ($state.is('app.loanProfiler')) {
                      if ($stateParams.tab === 'serviceability') {
                        if (scope.wtSettings.IsServiceAbilityStep1 === false) {
                          elementsHolder = new ElementData('serviceability-step-1');

                          listeners.serviceabilityStep2();
                        }
                      }

                      if ($stateParams.tab === 'productFinder') {
                        if (scope.wtSettings.IsProductFinderStep1 === false) {
                          elementsHolder = new ElementData('product-finder-step-1');

                          listeners.productFinderStep2();
                        }
                      }
                    }

                    runWalkThruInit(elementsHolder);
                  }, 5000);
                });

                $rootScope.$on('$stateChangeStart',
                  (event, toState, toParams) => {
                    elementsHolder = {};
                    $timeout(() => {
                      walkThru.loader.show();
                      if (toState.name === 'app.dashboard') {
                        elementsHolder = appDashboardWalkThru();
                      }
                      if (toState.name === 'app.contacts') {
                        if (scope.wtSettings.IsContacts === false) {
                          elementsHolder = new ElementData('contacts');
                        }
                      }
                      if (toState.name === 'app.tasks') {
                        if (scope.wtSettings.IsTasks === false) {
                          elementsHolder = new ElementData('tasks');
                        }
                      }
                      if (toState.name === 'app.fundingCalculator') {
                        if (scope.wtSettings.IsFundingCalcStep1 === false) {
                          elementsHolder = new ElementData('funding-calc-step-1');
                          listeners.fundingCalculatorStep2();
                        }
                      }
                      if (toState.name === 'app.leads') {
                        if (scope.wtSettings.IsPipelineLeads === false) {
                          elementsHolder = new ElementData('pipelineLeads');
                        }
                      }
                      if (toState.name === 'app.application') {
                        if (scope.wtSettings.IsPipelineApplication === false) {
                          elementsHolder = new ElementData('pipelineApplications');
                        }
                      }
                      if (toState.name === 'app.fundingCalculator') {
                        if (scope.wtSettings.IsFundingCalcStep1 === false) {
                          elementsHolder = new ElementData('funding-calc-step-1');
                        }
                      }
                      if (toState.name === 'app.loanProfiler') {
                        if (toParams.tab === 'serviceability') {
                          if (scope.wtSettings.IsServiceAbilityStep1 === false) {
                            elementsHolder = new ElementData('serviceability-step-1');
                            listeners.serviceabilityStep2();
                          }
                        }
                        if (toParams.tab === 'productFinder') {
                          if (scope.wtSettings.IsProductFinderStep1 === false) {
                            elementsHolder = new ElementData('product-finder-step-1');
                            listeners.productFinderStep2();
                          }
                        }
                      }
                      $timeout(() => {
                        walkThru.loader.hide();
                        if (_.size(elementsHolder) > 0)
                          walkThru.init(elementsHolder.data);
                      }, 1000);
                    }, 5000);
                  }
                );
              }
            });
          });
        }
      };

      if ($state.is('app.dashboard')) {
        if ($window.localStorage.getItem('walkthruCount') !== undefined) {
          let count = $window.localStorage.getItem('walkthruCount');

          if (count <= 5) {
            count++;
            $window.localStorage.setItem('walkthruCount', count);
            walkthruInit();
          } else {
            $window.localStorage.setItem('walkthruCount', 1);
            generalService.setWalkThrough(
              {
                IsWelcomeMessage: false,
                IsDashboardTopControls: false,
                IsDashboardWidgets: false,
                IsContacts: false,
                IsTasks: false,
                IsServiceAbilityStep1: false,
                IsServiceAbilityStep2: false,
                IsserviceabilityStep3: false,
                IsProductFinderStep1: false,
                IsProductFinderStep2: false,
                IsProductFinderStep3: false,
                IsFundingCalcStep1: false,
                IsFundingCalcStep2: false,
                IsContactSummary: false,
                IsPipelineLeads: false,
                IsPipelineApplication: false,
              }
            ).then(
              (setResp) => {
                scope.wtSettings = setResp.data;
                walkthruInit();
              }
            );
          }
        } else {
          $window.localStorage.setItem('walkthruCount', 1);
          walkthruInit();
        }
      }
    },
  };
});
