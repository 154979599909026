import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('EmailSignatureModalCtrl',
  function EmailSignatureModalCtrl(
    $scope,
    $uibModalInstance,
    generalService,
    toaster,
    broadcastDataService,
    loginUseInfo,
    email,
    options) {
    $scope.signatureContent = '';
    $scope.loginUseInfo = loginUseInfo;
    $scope.email = email;
    $scope.options = options;
    /* Methods */
    angular.extend($scope, {
      closeSignatureModal() {
        $uibModalInstance.dismiss('cancel');
      },

      removeBackgroundInSignature(signatureToTrimParam) {
        let signatureToTrim = signatureToTrimParam;
        signatureToTrim = _.replace(signatureToTrim, '<div></div>', ' ');
        signatureToTrim = _.replace(signatureToTrim, "style='display:inline-block; vertical-align: top; margin-left: 10px;'", "style='position: relative; bottom: 35px'");

        return signatureToTrim;
      },

      saveEmailSignature() {
        const wrapSignatureContent = `<div style='display:inline-block; vertical-align: top; margin-left: 10px;'>${$scope.signatureContent}</div>`;
        const verticalLine = '<div></div>';
        const signatureAndVerticalLine = verticalLine + wrapSignatureContent;
        const finalSignatureForm = `<div>${signatureAndVerticalLine}</div>`;

        const data = { familyId: $scope.loginUseInfo.BrokerId, emailSignature: finalSignatureForm };
        generalService.setEmailSignature(data).then(() => {
          toaster.pop('success', 'Success', 'Signature successfully set');
          $scope.email.Content = `<p> </br>${finalSignatureForm}</br></p>`;
          broadcastDataService.userSignature = finalSignatureForm;
        });
      },

      getEmailSignature() {
        generalService.getEmailSignature($scope.loginUseInfo.BrokerId).then((response) => {
          if (response.data != null) {
            const signature = response.data;
            $scope.email.Content = `<p> </br>${signature}</br></p>`;
            $scope.signatureContent = `<p>${$scope.removeBackgroundInSignature(signature)}</p>`;
          }
        });
      },
    });
    /* initializing */
    $scope.signatureContent = `<p>${$scope.removeBackgroundInSignature(broadcastDataService.userSignature)}</p>`;
  });
