import angular from 'angular';

angular.module('app').directive('dynamicInputWidth', function dynamicInputWidth() {
  return {
    link(scope, elem) {
      function resize(length) {
        elem.attr('style', `width:${(parseInt(length, 10) + 1) * 7.7}px`);
      }
      scope.$watch(() => { return elem[0].value; }, (nv) => {
        resize(nv.length);
      }, true);
    },
  };
});
