import angular from 'angular';

angular.module('app').controller('ForProductDetailModalCtrl', function ForProductDetailModalCtrl(
  $scope,
  $uibModalInstance,
  product,
  loanStructure,
  loanProfilerService
) {
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.product = product;
  $scope.loanStructure = loanStructure;

  loanProfilerService.getProductFullDetails(product.ProductID).then((response) => {
    $scope.singleProductDetail = response.data;
  });
});
