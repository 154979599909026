import angular from 'angular';

angular.module('app').controller('GetAnnualReviewDetailModalCtrl', function GetAnnualReviewDetailModalCtrl(
  $scope,
  $uibModalInstance,
  annualReview) {
  $scope.annualReview = annualReview;
  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
});
