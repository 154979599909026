import template from './insurancePipelineKanbanBoard.html';
import controller from './insurancePipelineKanbanBoardCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    statuses: '<',
    labelsSelection: '<',
    notProceedingReasons: '<',
    isViewCompact: '<',
    columnsSorting: '<',
    clientFilter: '<',
    onInit: '&?',
    onAddLabel: '&',
    onUpdateLabel: '&',
    onDeleteLabel: '&',
    onColumnSort: '&',
    onApplicationConverted: '&',
  },
};
