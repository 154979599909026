import angular from 'angular';

angular.module('app').directive('newlyClonedLoanAppIndicator', function newlyClonedLoanAppIndicator() {
  return {
    restrict: 'A',
    scope: {
      newlyClonedLoanApp: '=newlyClonedLoanAppIndicator',
    },
    link: ($scope, $elem, $attr) => {
      if ($scope.newlyClonedLoanApp && $scope.newlyClonedLoanApp.loanAppId && Number($attr.loanAppId) === $scope.newlyClonedLoanApp.loanAppId) {
        if (!$elem.hasClass('animate-display')) {
          $elem.addClass('animate-display');
        } else {
          $elem.removeClass('animate-display');
        }

        $scope.newlyClonedLoanApp.loanAppId = null;
      }
    },
  };
});
