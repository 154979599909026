import angular from 'angular';

angular.module('app').directive('appointmentCalendar', function appointmentCalendar() {
  return {
    restrict: 'E',
    templateUrl: 'assets/views/notificationSidebar/appointmentCalendar.html',
    scope: {
      selectedDate: '=',
    },
    controller: /* @ngInject */ function appointmentCalendarController($scope, $filter, $rootScope) {
      $scope.currentDate = new Date();

      $scope.calendarDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      $scope.availableStatement = [];
      $scope.calendarDateNumber = [];
      $scope.isLoading = false;
      $scope.calendarSelectorActive = 'Month';
      $scope.isSelectMonth = false;

      function setDateSelection() {
        $scope.selectedDate = `${$scope.currentMonth}/${$scope.currentDay}/${$scope.currentYear}`;
        $scope.currentDate = new Date($scope.selectedDate);
        $scope.calendarTitle = `${['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][$scope.currentMonth - 1]} ${$scope.currentYear}`;
        $scope.yearSelectionValue = $scope.currentYear;
      }

      function initializeCurrentDates() {
        $scope.currentYear = $scope.currentDate.getFullYear();
        $scope.currentMonth = $scope.currentDate.getMonth() + 1;
        $scope.currentDay = $scope.currentDate.getDate();
        setDateSelection();
      }

      function getFistDay(dateParam) {
        const month = dateParam.getMonth();
        const year = dateParam.getFullYear();
        const temp = new Date(year, month, 1);
        const date = temp.getDay();
        return date;
      }

      function getLastDayOfMonth(dateParam) {
        const month = dateParam.getMonth();
        const year = dateParam.getFullYear();
        const temp = new Date(year, month + 1, 0);
        const date = temp.getDate();

        return date;
      }

      function isToday(dateParam) {
        const temp = new Date();
        const year = temp.getFullYear();
        const month = temp.getMonth();
        const date = temp.getDate();

        const today = `${month + 1}/${date}/${year}`;

        return today === dateParam;
      }

      function isStatement(date) {
        if ($scope.availableStatement) {
          return $scope.availableStatement.indexOf($filter('date')(new Date(date), 'yyyy-MM-dd')) !== -1;
        }
        return false;
      }

      function fillCalendar(pDateParam) {
        $scope.calendarDateNumber = [];
        const dateParam = new Date(pDateParam);
        const year = dateParam.getFullYear();
        const month = dateParam.getMonth();
        const lastDay = getLastDayOfMonth(dateParam);
        const startingDay = getFistDay(dateParam);
        let isFirst = true;
        let day = 1;
        while (day <= lastDay) {
          const weekData = [];
          let data;
          for (let week = 0; week < 7; week++) {
            if ((startingDay !== week && isFirst) || day > lastDay) {
              data = {
                day: '',
                fullDate: '',
                isToday: false,
                isThisMonth: false,
                isStatementAvailable: false,
              };
            } else {
              data = {
                day,
                fullDate: `${month + 1}/${day}/${year}`,
                isToday: isToday(`${month + 1}/${day}/${year}`),
                isThisMonth: true,
                isStatementAvailable: isStatement(`${year}-${month + 1}-${day}`),
              };
              isFirst = false;
              day++;
            }
            weekData.push(data);
          }
          $scope.calendarDateNumber.push(weekData);
        }
      }

      function getStatementForMonth() {
        fillCalendar($scope.selectedDate);
      }

      initializeCurrentDates();

      $scope.nextMonth = function () {
        const currentMonths = $scope.currentDate;
        if (currentMonths.getMonth() === 11) {
          $scope.currentDate = new Date(currentMonths.getFullYear() + 1, 0, 1);
          initializeCurrentDates($scope.currentDate);
          getStatementForMonth();
        } else {
          $scope.currentDate = new Date(currentMonths.getFullYear(), currentMonths.getMonth() + 1, 1);
          initializeCurrentDates($scope.currentDate);
          getStatementForMonth();
        }
      };
      $scope.previousMonth = function () {
        const currentMonth = $scope.currentDate;
        if (currentMonth.getMonth() === 0) {
          $scope.currentDate = new Date(currentMonth.getFullYear() - 1, 11, 1);
          initializeCurrentDates();
          getStatementForMonth();
        } else {
          $scope.currentDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
          initializeCurrentDates();
          getStatementForMonth();
        }
      };
      $scope.selectDate = function (selectedDate) {
        if (selectedDate !== '') {
          $scope.selectedDate = selectedDate;
          $rootScope.$broadcast('appointmentCalendarDateSelected', { calendarDate: selectedDate });
        }
      };
      const calendarDate = $rootScope.$on('calendarDate', (event, args) => {
        const passedDate = new Date(args.calendarDatePkt);

        $scope.selectedDate = `${passedDate.getMonth() + 1}/${passedDate.getDate()}/${passedDate.getFullYear()}`;

        $scope.startDate = passedDate;
        fillCalendar($scope.startDate);
      });

      $scope.$on('$destroy', calendarDate);

      $scope.setCalendarSelector = function (value) {
        $scope.calendarSelectorActive = value;
      };
      $scope.selectMonth = function () {
        $scope.isSelectMonth = true;
      };
      $scope.setMonth = function (mon) {
        $scope.calendarSelectorActive = 'Year';
        $scope.currentMonth = mon + 1;
        setDateSelection();
        getStatementForMonth();
      };
      $scope.setYear = function (y) {
        $scope.isSelectMonth = false;
        $scope.currentYear = y;
        setDateSelection();
        getStatementForMonth();
      };
      $scope.closeDateSelection = function () {
        $scope.isSelectMonth = false;
        $scope.calendarSelectorActive = 'Month';
      };
      $scope.upYear = function () {
        $scope.yearSelectionValue += 4;
      };
      $scope.downYear = function () {
        $scope.yearSelectionValue -= 4;
      };

      getStatementForMonth();
    },
  };
});
