import template from './pipelineKanban.html';
import controller from './pipelineKanbanCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isCompact: '<',
    onClickPipelineItem: '&',
    onDeletePipelineItem: '&',
  },
};
