import angular from 'angular';
import {
  INCOME_TAX,
  INCOME_FREQUENCY,
} from 'Common/constants/incomeTax';
import {
  getParsed,
} from 'Common/utilities/math';
import {
  calculatePay,
} from 'Common/utilities/incomeCalculator';

class IncomeCalculatorCtrl {
  constructor(
    formattingService,
    configService
  ) {
    'ngInject';

    this.calculationObject = {
      taxableIncome: '0',
      taxYear: new Date().getFullYear().toString(),
      superannuationRate: 9.5,
      frequency: 'ANNUALLY',
      hoursPerWeek: 0,
      weeksPerYear: 0,
      hoursPerDay: 0,
      daysPerYear: 0,
      daysPerWeek: 0,
      withholdTaxOffset: 0,
    };

    this.showIncomeCalculator = configService.feature.incomeCalculator;
    this.formatToCurrency = formattingService.calculatorFormatCurrency;
    this.incomeFrequency = Object.keys(INCOME_FREQUENCY);

    // calculation variables may vary per year
    this.taxData = { ...INCOME_TAX[this.calculationObject.taxYear] };
    this.taxYears = ['2019', '2018'];

    this.taxBreakdownRowItems = [{
      key: 'pay',
      label: 'Pay',
    },
    {
      key: 'taxableIncome',
      label: 'Taxable Income',
    },
    {
      key: 'superannuation',
      label: 'Superannuation',
    },
    {
      key: 'totalTaxes',
      label: 'Total Taxes',
    },
    {
      key: 'incomeTax',
      label: 'Income Tax',
    },
    {
      key: 'medicare',
      label: 'Medicare',
    },
    {
      key: 'taxOffset',
      label: 'Tax Offsets',
    },
    ];

    this.frequencyOptions = [{
      value: 'ANNUALLY',
      label: 'A',
    },
    {
      value: 'MONTHLY',
      label: 'M',
    },
    {
      value: 'FORTNIGHTLY',
      label: 'F',
    },
    {
      value: 'WEEKLY',
      label: 'W',
    },
    {
      value: 'DAILY',
      label: 'D',
    },
    {
      value: 'HOURLY/WEEK',
      label: 'HW',
    },
    {
      value: 'HOURLY/DAY',
      label: 'HD',
    },
    ];
  }

  updateTaxYear(prop, value) {
    this.calculationObject[prop] = value;
    this.taxData = { ...INCOME_TAX[this.calculationObject.taxYear] };
  }

  updateTaxableIncome(prop, value) {
    if (value) {
      this.calculationObject[prop] = this.formatToCurrency(value);
    }
  }

  updateProp(prop, value) {
    this.calculationObject[prop] = value;
  }

  getIncomeCalculationTable() {
    if (this.calculationObject.taxableIncome === undefined || Number.isNaN(getParsed(this.calculationObject.taxableIncome))) return;

    this.incomeCalculationTable = {
      weekly: calculatePay(this.calculationObject, 'WEEKLY'),
      fortnightly: calculatePay(this.calculationObject, 'FORTNIGHTLY'),
      monthly: calculatePay(this.calculationObject, 'MONTHLY'),
      annually: calculatePay(this.calculationObject, 'ANNUALLY'),
    };
  }
}

angular.module('app').controller('IncomeCalculatorCtrl', IncomeCalculatorCtrl);
