import angular from 'angular';
import html2canvas from 'html2canvas';

app.factory('loanCalculatorSharedData', function loanCalculatorSharedData(loanToolsService, $window, userService, $rootScope) {
    var factory = this;

    angular.extend(factory, {
        valueSet: [],
        filterLenderList() {
            this.lenderList = this.lenderList.filter((item) => {
                if (item.rates.length > 0) {
                    return item;
                }
            });
        },
        getLenderRates() {
            if (this.lenderList[0].rates) {
                this.filterLenderList();
                this.construct();
            } else {
                var queryStr = '';
                for (let i = 0; i < this.lenderList.length; i++) {
                    queryStr += `providerIDs=${this.lenderList[i].ProviderID}&`;
                }

                loanToolsService.getLenderRates(queryStr)
                    .then((response) => {
                        var dictionary = response.data;
                        for (let i = 0; i < this.lenderList.length; i++) {
                            this.lenderList[i].rates = dictionary[this.lenderList[i].ProviderID];
                        }
                        this.filterLenderList();

                        this.construct();
                    });
            }
        },
        getUserInfo() {
            userService.GetUserInfo().then((response) => {
                if (response.data) {
                    this.clientName = response.data.PreferredFullName;
                } else {
                    this.clientName = '';
                }
            });
        },
        roundFigure(val){
            return $rootScope.crmCurrency + Math.round(val).toLocaleString();
        },
        chartOptions: {
        // Sets the chart to be responsive
        responsive: true,
        scaleLabel(valuePayload){
            return factory.roundFigure(parseInt(valuePayload.value, 10));
        },
        tooltipTemplate(v){
            return factory.roundFigure(v.value);
        },
        multiTooltipTemplate(label){
            return `${label.datasetLabel} : ${factory.roundFigure(label.value)}`;
        },
        // /Boolean - Whether grid lines are shown across the chart
        scaleShowGridLines: true,

        // String - Colour of the grid lines
        scaleGridLineColor: 'rgba(0,0,0,.05)',

        // Number - Width of the grid lines
        scaleGridLineWidth: 1,

        // Boolean - Whether the line is curved between points
        bezierCurve: true,

        // Number - Tension of the bezier curve between points
        bezierCurveTension: 0.4,

        // Boolean - Whether to show a dot for each point
        pointDot: true,

        // Number - Radius of each point dot in pixels
        pointDotRadius: 3,

        // Number - Pixel width of point dot stroke
        pointDotStrokeWidth: 2,

        // Number - amount extra to add to the radius to cater for hit detection outside the drawn point
        pointHitDetectionRadius: 5,

        // Boolean - Whether to show a stroke for datasets
        datasetStroke: true,

        // Number - Pixel width of dataset stroke
        datasetStrokeWidth: 2,

        // Boolean - Whether to fill the dataset with a colour
        datasetFill: true,
    }
    });
    return factory;
});
