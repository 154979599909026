import angular from 'angular';

angular.module('app').controller('OpenSendMailModalCtrl', function openSendMailModalCtrl($scope,
  $uibModalInstance,
  $state,
  $window,
  $controller,
  source,
  sendMailObj,
  listTableData,
  contactService,
  $timeout,
  currentModuleId) {
  const vm = this;
  angular.extend(vm, $controller('CustomerCareSendMailModalBaseCtrl', { $scope }));

  $scope.recipients = {};
  $scope.recipients.email = [];
  $scope.listTableData = listTableData;
  $scope.currentModule = currentModuleId;
  if (source === 'all') {
    $scope.all = true;
    $scope.noOfPeople = $scope.listTableData.length;
  }
  $scope.selectEmailList = [];
  $scope.getRecipientAutoSuggestion = function (userInput) {
    contactService.getRecipientAutoSuggestion(userInput).then((response) => {
      $scope.selectEmailList = response.data;
    });
  };
  $scope.saveEmailContentHtmlToRTF = function (htmlStr) {
    if (!htmlStr || !htmlStr.trim()) return;
    contactService.convertContentHtmlToRTF({ htmlString: htmlStr }).then((response) => {
      if (!response) return;
      const data = response;
      const element = $window.document.createElement('a');
      element.setAttribute('href', `data:${data.contentType};base64,${data.documentContent}`);
      element.setAttribute('download', data.name);

      element.style.display = 'none';
      $window.document.body.appendChild(element);

      const clickDelay = $timeout(() => {
        element.click();
      }, 100);

      clickDelay.then(() => {
        $window.document.body.removeChild(element);
      });

      $scope.$on('$destroy', () => {
        $timeout.cancel(clickDelay);
      });
    });
  };
  $scope.refreshResults = function (select) {
    const search = select.search;

    $scope.getRecipientAutoSuggestion(search);
  };
  function init() {
    $scope.familyId = sendMailObj ? sendMailObj.FamilyID : null;

    // Ckeditor Tollbar Configuration
    $scope.options = {
      language: 'en',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 300,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['BGColor'] }, { items: ['JustifyLeft'] }, { items: ['BulletedList', '-'] }, { items: ['Link'] },
        { items: ['Image'] }, { items: ['Table'] }, { items: ['-'] },
        '/',
        { items: ['Format'] }, { items: ['Font'] }, { items: ['FontSize', '-'] }, { items: ['Bold'] }, { items: ['Italic'] }, { items: ['Underline'] },

      ],

    };
    $scope.subject = 'Happy Birthday!';
    $scope.content = '';
  }

  init();
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
