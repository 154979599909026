import moment from 'moment';

class IdentificationService {
  constructor(toaster) {
    'ngInject';

    this.toaster = toaster;
  }
  isIdentificationValid(data) {
    this.isValid = false;
    if (data && data.IssueDate && !moment(data.IssueDate).isValid()) {
      this.toaster.pop('error', 'Invalid Issue Date', 'Please fill up the form correctly');
    } else {
      this.isValid = true;
    }
    return this.isValid;
  }
}

export default IdentificationService;

