import { ACCESS_TYPE } from 'Common/constants/accessTypes';

function EmailManagementModalCtrl($scope,
  $rootScope,
  $http,
  $filter,
  $timeout,
  corporateService,
  contactService,
  regionalizationService,
  queryService,
  $state,
  $stateParams,
  SweetAlert,
  mapService,
  $uibModal,
  userService) {
  'ngInject';

  const vm = this;
  $scope.isCorporateUser = false;
  userService.GetUserInfo().then((userlevel) => {
    $scope.isCorporateUser = userlevel.data.AccessType === ACCESS_TYPE.CORPORATE;
  });

  $scope.tabs = [
    { name: 'Email Templates', value: 'emailTemplates', disable: false, isCorpOnly: false },
    { name: 'Sharing And Privacy', value: 'sharingAndPrivacy', disable: false, isCorpOnly: true },
  ];

  $scope.closeModal = () => {
    if (!vm.modalInstance) return;
    vm.modalInstance.close($scope.hasChanges);
  };

  $scope.onTemplatesUpdated = () => {
    $scope.hasChanges = true;
  };

  // Ckeditor Tollbar Configuration
  $scope.options = {
    language: 'en',
    allowedContent: true,
    uiColor: '#DFD6E4',
    height: 300,
    resize_enabled: false,
    removePlugins: 'elementspath',
    toolbar: [
      { items: ['BGColor'] }, { items: ['JustifyLeft'] }, { items: ['BulletedList', '-'] }, { items: ['Link'] },
      { items: ['Image'] }, { items: ['Table'] }, { items: ['Paste', '-'] }, { items: ['Smiley'] }, { items: ['-'] },
      '/',
      { items: ['Format'] }, { items: ['Font'] }, { items: ['FontSize', '-'] }, { items: ['Bold'] }, { items: ['Italic'] }, { items: ['Underline'] },
      { items: ['Subscript'] }, { items: ['Superscript'] },
    ],

  };

  // Called when the editor is completely ready.
  $scope.onReady = function () {
  };
}

export default EmailManagementModalCtrl;
