import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';
import { toastError } from 'Common/utilities/alert';

angular.module('app').controller('AddTaskTemplateCtrl',
  function AddTaskTemplateCtrl($scope,
    $stateParams,
    $uibModalInstance,
    $timeout,
    toaster,
    contactService,
    dashboardService,
    taskService,
    taskDetailService,
    generalSearchService
  ) {
    $scope.taskSet = {};
    $scope.mainObject = {};
    $scope.mainObject.isSearchEnable = false;
    $scope.familyId = $stateParams.familyId;
    $scope.format = 'dd MMM yyyy';
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    // Open Date Popup
    $scope.openDate = (templateTask) => {
      templateTask.datePopUp = !templateTask.datePopUp;
    };

    // Change Date
    $scope.dateChanged = (templateTask) => {
      templateTask.DueDate = templateTask.DueDateFormat;
    };

    $scope.closeFamilyList = () => {
      $scope.familyDetailsList = taskDetailService.closeFamilyList($scope.taskInput, $scope.mainObject);
    };

    $scope.selectFamily = (id, contactName) => {
      $scope.familyDetailsList = taskDetailService.selectFamily(id, contactName, $scope.taskInput, $scope.taskSet, $scope.mainObject);
    };
    if ($scope.template_task_id !== 0) {
      taskService.taskDetailsGet($scope.template_task_id).then((respond) => {
        $scope.taskSet = respond.data;
        $scope.taskSet.DueDate = new Date($scope.taskSet.DueDate);
      });
    } else {
      $scope.taskSet.DueDate = new Date();
    }

    $scope.$on('$destroy', () => {
      $timeout.cancel($scope.templatedTimeout);
      $scope.templatedTimeout = null;
    });

    $scope.editTemplateTask = (task) => {
      task.isEditMode = true;
      task.DueDateFormat = new Date(task.DueDate);
      if (!$scope.mainObject.editInfoShown) {
        toaster.pop('info', 'Info', 'Changing the date will update subsequent tasks.');
        $scope.mainObject.editInfoShown = true;
      }
    };

    $scope.cancelTemplateTaskEditMode = (task) => {
      _.map($scope.copiedTemplatedTaskList, (item) => {
        if (item.TaskSettingsID === task.TaskSettingsID) {
          task.TaskName = item.TaskName;
          task.AssignedAdvisorID = item.AssignedAdvisorID;
          task.DueDate = item.DueDate;
          const adviserData = _.find($scope.taskAdviserList, (adviser) => {
            return adviser.BrokerID === task.AssignedAdvisorID;
          });
          if (adviserData) {
            item.FirstName = adviserData.LastName;
            item.LastName = adviserData.LastName;
            task.FirstName = adviserData.FirstName;
            task.LastName = adviserData.LastName;
          }
        }
      });
      task.isEditMode = false;
    };

    $scope.saveTemplateTask = (task) => {
      _.map($scope.copiedTemplatedTaskList, (item) => {
        if (item.TaskSettingsID === task.TaskSettingsID) {
          item.TaskName = task.TaskName;
          item.AssignedAdvisorID = task.AssignedAdvisorID;
          item.DueDate = task.DueDate;
          const adviserData = _.find($scope.taskAdviserList, (adviser) => {
            return adviser.BrokerID === task.AssignedAdvisorID;
          });
          if (adviserData) {
            item.FirstName = adviserData.FirstName;
            item.LastName = adviserData.LastName;
            task.FirstName = adviserData.FirstName;
            task.LastName = adviserData.LastName;
          }
        }
      });
      task.isEditMode = false;
      $scope.formatTaskSettingsDueDate(task.TaskTemplateID, task.TaskSettingsID);
    };

    // Get Task Template List
    taskService.taskTemplateListGet().then((respond) => {
      $scope.taskTemplateList = respond.data;
    });

    // Get Task list for Template
    $scope.copiedTemplatedTaskList = [];
    $scope.selectedTemplateId = 0;
    $scope.getTemplateTask = (templateId) => {
      $scope.selectedTemplateId = templateId;
      taskService.getTaskTemplateSettings(templateId)
        .then((respond) => {
          $scope.templatedTaskList = respond.data;
          $scope.formatTaskSettingsDueDate(templateId);
        });
    };

    $scope.formatTaskSettingsDueDate = (templateId, taskId = 0) => {
      const templateModel = {
        TaskTemplateID: templateId,
        TaskTemplateName: '',
        TaskSettingsList: $scope.templatedTaskList,
      };

      taskService.updateDueDateSubsequently(templateModel, taskId)
        .then((newTaskSettings) => {
          $scope.templatedTaskList = newTaskSettings.data;

          dashboardService.getBrokerBasicInfo()
            .then((getBrokerBasicInfoResponse) => {
              const { data } = getBrokerBasicInfoResponse;
              if (!data) return;

              $scope.mainObject.CreatedByUser = data.BrokerId;

              $scope.templatedTaskList.forEach((item) => {
                item.AssignedAdvisorID = item.AssignedAdvisorID.toString();
                if (item.AssignedAdvisorID === '0') {
                  item.AssignedAdvisorID = data.BrokerId;
                  item.FirstName = data.FirstName;
                  item.AssignedAdvisorID = data.BrokerId;
                }
              });
              $scope.copiedTemplatedTaskList = angular.copy($scope.templatedTaskList);
            });
        });
    };


    // Delete Template task
    $scope.deleteTemplateTask = (task) => {
      const index = $scope.templatedTaskList.indexOf(task);
      if (index > -1)
        $scope.templatedTaskList.splice(index, 1);
    };

    $scope.taskInput = {};
    $scope.searchFamilyInput = () => {
      generalSearchService.searchFamilyInput($scope);
    };

    contactService.getAllByFilter('*').then((respond) => {
      angular.forEach(respond.data.FamilyList, (value) => {
        if (value.FamilyID === $scope.familyId) {
          $scope.taskInput.familyValue = value.FamilyFullName;
          if ($scope.template_task_id === 0) {
            $scope.taskSet.FamilyId = value.FamilyID;
          }
        }
      });
    });

    // Get Adviser List
    contactService.taskAdviserListGet().then((respond) => {
      $scope.taskAdviserList = respond.data;
      $scope.taskAdviserList = _.reject(respond.data, ['BrokerID', 0]);
      angular.forEach(respond.data, (item) => {
        item.BrokerID = item.BrokerID.toString();
      });
    });

    $scope.saveTemplatedTask = () => {
      const postDataList = [];
      angular.forEach($scope.templatedTaskList, (item) => {
        const taskData = {
          FamilyID: $scope.taskSet.FamilyId,
          TaskID: 0,
          Title: item.TaskName,
          assignedClientID: item.AssignedAdvisorID,
          CreatedByUser: $scope.mainObject.CreatedByUser,
          DueDate: moment(item.DueDate).format('YYYY-MM-DD'),
          Notes: '',
          TaskSettingsID: item.TaskSettingsID,
        };
        postDataList.push(taskData);
      });
      const postData = {
        TaskList: postDataList,
        TaskTemplateID: $scope.selectedTemplateId,
      };

      const successHandler = () => {
        if ($scope.template_task_id !== 0) {
          toaster.pop('success', 'Updated', 'Templated task successfully updated');
        } else {
          toaster.pop('success', 'Added', 'Templated task successfully added');
        }
        $scope.triggerFilter();
        $scope.templatedTimeout = $timeout(() => {
          $uibModalInstance.dismiss('cancel');
        }, 100);
      };

      taskService.TaskSetv3Multiple(postData)
        .then(successHandler, toastError);
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
