class LeadAllocationCountCtrl {
  constructor() {
    'ngInject';
  }

  $onChanges(changes) {
    if (!changes) return;
    this.leadProfileObj = changes.leadProfileDetails && changes.leadProfileDetails.currentValue ? changes.leadProfileDetails.currentValue : this.leadProfileDetails;
    this.leadAllocationCount = changes.leadProfileDetails && changes.leadProfileDetails.currentValue ? Number(changes.leadProfileDetails.currentValue.AllocationCountPerMonth) : 0;
  }
}
export default LeadAllocationCountCtrl;
