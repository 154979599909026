import { ADDRESS_TYPE } from 'Common/constants/addressType';

class AddressModalCtrl {
  constructor(
    corporateService
  ) {
    'ngInject';

    this.corporateService = corporateService;
  }
  setCustomAddressFlag() {
    this.addressObject.isCustomAddress = !this.addressObject.isCustomAddress;
  }
  getAddressType() {
    this.addressTypeName = '';
    const typeId = parseInt(this.addressType, 10);
    switch (typeId) {
    case ADDRESS_TYPE.BUSINESS:
      this.addressTypeName = 'BUSINESS ADDRESS';
      break;
    case ADDRESS_TYPE.HOME:
      this.addressTypeName = 'HOME ADDRESS';
      break;
    case ADDRESS_TYPE.MAILING:
      this.addressTypeName = 'MAILING ADDRESS';
      break;
    default:
      break;
    }
  }
  clearManualFields() {
    if (this.addressObject) {
      this.addressObject.street_address = '';
      this.addressObject.locality = '';
      this.addressObject.postal_code = '';
      this.addressObject.stateShort_Name = '';
      this.addressObject.country = '';
      this.addressObject.formatted_address = '';
    }
  }
  $onInit() {
    this.type = ADDRESS_TYPE;
    this.isCustomAddress = false;
    this.getAddressType();
  }
}

export default AddressModalCtrl;
