const MERGE_CONTACT_TYPES = {
  MERGE: 1,
  MARRY: 2,
};

export default class SelectMergeContactTypeModal {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.selectedType = 0;
    this.MERGE_CONTACT_TYPES = MERGE_CONTACT_TYPES;
  }

  selectType(type) {
    this.selectedType = type;
  }

  next() {
    if (!this.selectedType) return;

    this.modalInstance.close();
    if (this.selectedType === MERGE_CONTACT_TYPES.MERGE) {
      this.$uibModal.open({
        templateUrl: '/assets/views/contacts/merge_contacts.html',
        size: 'lg',
        backdrop: 'static',
        controller: 'MergeContactsModalCtrl',
        resolve: {
          clientsToMerge: () => this.clientsToMerge,
          countryCode: () => this.countryCode,
        },
      });
    } else {
      const props = {
        clientsToMerge: this.clientsToMerge,
        countryCode: this.countryCode,
      };

      this.$uibModal.open({
        template: `<marry-contacts-modal
          modal-instance="vm.modalInstance"
          clients-to-merge="vm.props.clientsToMerge"
          country-code="vm.props.countryCode"
        ></marry-contacts-modal>`,
        size: 'lg',
        backdrop: 'static',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        windowClass: 'marry-contacts-modal',
        resolve: {
          props: () => props,
        },
      });
    }
  }
}
