// dependent on magnify.js

app.factory('magnifyService', [function () {
  var factory = this;
  var evt = new MagnifierEvent();
  var id_suffix = 0;

  factory.Magnify = new Magnifier(evt);

  // Used to make sure even if the elements are refresh, there will be fresh ids
  factory.getUniqueId = function () {
    return `-service-suffix-${id_suffix += 1}`;
  };

  return factory;
}]);
