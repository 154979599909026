import angular from 'angular';
import SubscriptionOnlineFactFindModal from './subscriptionOnlineFactFindModal/subscriptionOnlineFactFindModal';
import SubscriptionBrokerPediaModal from './subscriptionBrokerPediaModal/subscriptionBrokerPediaModal';
import SubscriptionPriceFinderModal from './subscriptionPriceFinderModal/subscriptionPriceFinderModal';
import SubscriptionCreditCheckModal from './subscriptionCreditCheckModal/subscriptionCreditCheckModal';


export default angular.module('components.subscriptionModal', [])
  .component('subscriptionOnlineFactFindModal', SubscriptionOnlineFactFindModal)
  .component('subscriptionBrokerPediaModal', SubscriptionBrokerPediaModal)
  .component('subscriptionPriceFinderModal', SubscriptionPriceFinderModal)
  .component('subscriptionCreditCheckModal', SubscriptionCreditCheckModal)
  .name;
