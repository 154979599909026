import angular from 'angular';
import swal from 'sweetalert';
import _ from 'lodash';
import { TRANSACTION_TYPE } from 'Common/constants/securityValues';
import { assetFinanceIgnore, checkIfInvestment } from 'Common/utilities/security';
import { clientTitles } from 'Common/utilities/client';

angular.module('app').controller('ShowEditSecurityModalCtrl',
  function ShowEditSecurityModalCtrl(
    $scope,
    $uibModalInstance,
    optionsService,
    loanScenarioService,
    loanApplicationServices,
    dashboardService,
    $timeout,
    generalService,
    LoanSecurityInfoId,
    loanAppSharedData,
    uiService
  ) {
    $scope.assetFinanceIgnore = assetFinanceIgnore;
    const lenderPlace = 1; /* Lender place for loanapp */
    $scope.uiService = uiService;
    optionsService.getLenderList(lenderPlace).then((response) => {
      $scope.LenderListList = response.data;
      $scope.LenderListList.unshift({ ProviderName: 'Please select', ProviderId: '' });
    });


    $scope.incomeSettingsEditMode = {
      showAddIncome: false,
    };

    $scope.IncomeListEditMode = [];

    $scope.addNewIncomeEditMode = LoanSecurityInfoID => $scope.IncomeListEditMode.push($scope.getNewIncomeModel(LoanSecurityInfoID));

    $scope.checkIfInvestment = () => {
      $scope.isInvestment = checkIfInvestment($scope.security.edit.PropertyDetails.PropertyPrimaryPurposeId);
    };

    $scope.checkTransactionType = () => {
      return parseInt($scope.security.edit.TransactionId, 10) !== TRANSACTION_TYPE.REFINANCE;
    };

    $scope.deleteIncome = (obj, index) => {
      if (obj.Id) {
        swal({
          title: 'Are you sure?',
          text: 'This income will be removed',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            loanScenarioService.IncomeInfoDelete($scope.loanAppId, obj.Id).then(() => {
              $scope.IncomeListEditMode.splice(index, 1);
              swal({
                title: 'Success',
                text: 'Income has been been successfully deleted.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            });
          }
        });
      } else {
        $scope.IncomeListEditMode.splice(index, 1);
      }
    };

    $scope.$watch('IncomeListEditMode', (nv) => {
      $scope.incomeSettingsEditMode.showAddIncome = _.size(nv) > 0;
    }, true);

    _.map($scope.formAreas, (area) => {
      area.error = false;
      return area;
    });

    $scope.OwnershipList = [];
    angular.copy($scope.OwnershipListInit, $scope.OwnershipList);

    const loadSecurity = () => {
      loanApplicationServices.getLoanSecurityInfo($scope.loanAppId, LoanSecurityInfoId).then((response) => {
        const TransactionId = parseInt(response.data.TransactionId, 10);
        const isExistingSecurity = (TransactionId !== TRANSACTION_TYPE.PURCHASING) && (TransactionId !== TRANSACTION_TYPE.CONSTRUCTION);
        if (isExistingSecurity) {
          response.data.TransactionTypeList = [...$scope.TransactionTypeList];

          // remove the first item, purchasing, sold and construction
          _.forEach(response.data.TransactionTypeList, (o, i) => {
            const value = parseInt(o.Value, 10);
            o.disabled = i === 0 || value === TRANSACTION_TYPE.PURCHASING || value === TRANSACTION_TYPE.SOLD || value === TRANSACTION_TYPE.CONSTRUCTION;
          });
        } else {
          response.data.TransactionTypeList = [...$scope.TransactionTypeList];

          // only show the purchasing and construction if there is
          _.forEach(response.data.TransactionTypeList, (o) => {
            const value = parseInt(o.Value, 10);
            o.disabled = value !== TRANSACTION_TYPE.PURCHASING && value !== TRANSACTION_TYPE.CONSTRUCTION;
          });
        }

        if (response.data.PropertyDetails.IdentifierList) {
          response.data.PropertyDetails.IdentifierList = response.data.PropertyDetails.IdentifierList.map((type) => {
            return { ...type, IdentityTypeId: type.IdentityTypeId && parseInt(type.IdentityTypeId, 10) };
          });
        }

        if (response.data.MortgageList) {
          _.map(response.data.MortgageList, (o) => {
            o.CreditorId = o.CreditorId ? o.CreditorId.toString() : '';
            o.FinancialId = o.FinancialId ? o.FinancialId.toString() : '';
            o.InterestRate = Number(o.InterestRate);
            o.InterestRateFrequencyId = o.InterestRateFrequencyId ? o.InterestRateFrequencyId.toString() : '';
            o.MortgagePriorityId = o.MortgagePriorityId ? o.MortgagePriorityId.toString() : '';
            return o;
          });
        }

        if (response.data.IncomeList) {
          _.map(response.data.IncomeList, (obj) => {
            obj.IncomeSubTypeId = obj.IncomeSubTypeId ? String(obj.IncomeSubTypeId) : null;
            return obj;
          });
        }

        $scope.security.edit = response.data;
        if (response.data.IncomeList && _.size(response.data.IncomeList) > 0) {
          $scope.IncomeListEditMode = angular.copy(response.data.IncomeList);
          $scope.incomeSettingsEditMode.showAddIncome = true;
        } else {
          $scope.IncomeListEditMode = [];
        }
        $scope.security = {
          edit: {
            TransactionTypeList: response.data.TransactionTypeList,
            refinanceType: _.find(response.data.TransactionTypeList, o => o.Name === 'Refinance'),
            datePopUp: {
              ContractDate: false,
            },
            dateFormat: 'dd MMM yyyy',
            altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
            dateOptions: {
              formatYear: 'yy',
              showWeeks: false,
            },
            openDate(dateField) {
              $scope.security.edit.datePopUp[dateField] = !$scope.security.edit.datePopUp[dateField];
            },
            LoanApplicationId: $scope.loanAppId,
            IsPrimary: response.data.IsPrimary,
            IsREA: response.data.IsREA,
            SecurityTypeId: parseInt(response.data.SecurityTypeId, 10), // SecurityType
            FundingCalParametersId: response.data.FundingCalParametersId,
            TransactionId: parseInt(response.data.TransactionId, 10), // TransactionType
            LoanSecurityId: LoanSecurityInfoId,
            Address: [],
            PropertyDetails: {
              StatusId: response.data.PropertyDetails.StatusId || '', // StatusType
              PropertyPrimaryPurposeId: response.data.PropertyDetails.PropertyPrimaryPurposeId || '', // PrimaryPurposeType
              HoldingId: response.data.PropertyDetails.HoldingId || '', // HoldingType
              ZoningId: response.data.PropertyDetails.ZoningId || '', // ZoningType
              PropertyTypeId: response.data.PropertyDetails.PropertyTypeId || '', // PropertyType
              PropertyTypeId_original: response.data.PropertyDetails.PropertyTypeId || '', // PropertyType
              CurrentValue: $scope.security.edit.PropertyDetails.IsPreApproved ? '' : response.data.PropertyDetails.CurrentValue,
              LandValue: response.data.PropertyDetails.LandValue,
              EstimatedPrice: response.data.PropertyDetails.EstimatedPrice,
              EstimatedBasesId: parseInt(response.data.PropertyDetails.EstimatedBasesId, 10), // AssetValueEstimatedBasis
              ContratctPrice: response.data.PropertyDetails.ContratctPrice,
              ContractDate: (Object.prototype.hasOwnProperty.call(response.data.PropertyDetails, 'ContractDate') ? new Date(response.data.PropertyDetails.ContractDate) : ''),
              LicensedRealEstateAgentContract: $scope.security.edit.PropertyDetails.LicensedRealEstateAgentContract,
              IsPreApproved: $scope.security.edit.PropertyDetails.IsPreApproved,
              AddressId: $scope.security.edit.PropertyDetails.AddressId || 0,
              TitleTypeId: parseInt(response.data.PropertyDetails.TitleTypeId, 10), // TitleTenureType
              TitleId: parseInt(response.data.PropertyDetails.TitleId, 10), // TitleType
              IdentifierList: response.data.PropertyDetails.IdentifierList,
              SecurityInfoId: response.data.SecurityInfoId, // go back
              Suburb: response.data.PropertyDetails.Suburb,
              PropertyState: response.data.PropertyDetails.PropertyState,
              ContactForAccess: {
                ...response.data.PropertyDetails.ContactForAccess,
              },
            },
            Ownership: response.data.Ownership,
            MortgageList: response.data.MortgageList,
            AssetId: response.data.AssetId,
          },
          titles: clientTitles(),
        };
        $scope.checkIfInvestment();

        // will fallback for invalid date
        if (String($scope.security.edit.PropertyDetails.ContractDate) === 'Invalid Date') {
          $scope.security.edit.PropertyDetails.ContractDate = new Date();
        }

        const collectionBorrowerID = [];

        // This is to present the ownership being selected on Edit
        _.forEach($scope.security.edit.Ownership, (obj) => {
          collectionBorrowerID.push(obj.BorrowerID);
        });

        $scope.scenarioApplicantList = _.map($scope.scenarioApplicantList, (obj) => {
          if (collectionBorrowerID.indexOf(obj.BorrowerID) !== -1) {
            obj.IsTicked = true;
            obj.ticked = true;
            const findOwner = $scope.security.edit.Ownership.find(owner => owner.BorrowerID === obj.BorrowerID);
            obj.Percentage = findOwner ? findOwner.Percentage : obj.Percentage;
          } else {
            obj.Percentage = 0;
            obj.IsTicked = false;
            obj.ticked = false;
          }
          return obj;
        });

        // Get the address details for the first instance
        if ($scope.security.edit.PropertyDetails.AddressId) {
          dashboardService.addressDetailsGet($scope.security.edit.PropertyDetails.AddressId).then((addressDetailsResponse) => {
            $scope.security.edit.Address = addressDetailsResponse.data;
            $scope.security.edit.Address.isEditSecurityInfo = false;
          });
        }

        //
        $scope.$watch(
          'security.edit.PropertyDetails.ZoningId',
          (nv) => {
            if (nv !== '') {
              optionsService.getPropertyType(nv).then((propertyTypeResponse) => {
                $scope.PropertyTypeList = propertyTypeResponse.data;
                $scope.PropertyTypeList.unshift({ AddressPropertyType: 'Please select', AddressPropertyTypeID: '' });

                if (!_.find($scope.PropertyTypeList, (o) => {
                  return parseInt(o.AddressPropertyTypeID, 10) === parseInt($scope.security.edit.PropertyDetails.PropertyTypeId_original, 10);
                })) {
                  $scope.security.edit.PropertyDetails.PropertyTypeId = '';
                } else {
                  $scope.security.edit.PropertyDetails.PropertyTypeId = angular.copy($scope.security.edit.PropertyDetails.PropertyTypeId_original);
                }
              });
            }
          }
        );

        $scope.security.edit.getFormattedAddress = () => {
          $timeout.cancel($scope.searchTimeout);
          $scope.searchTimeout = $timeout(() => {
            generalService.placeSearch($scope.security.edit.Address.formatted_address).then((placeSearchResponse) => {
              $scope.security.edit.addressSuggestions = placeSearchResponse.data;
              $scope.security.edit.showSuggestionModal = true;
            }, () => {
            });
          }, 500);
        };

        $scope.security.edit.closeAutoHomeAddress = () => {
          $scope.security.edit.addressSuggestions = [];
        };

        $scope.security.edit.pickAddressSuggestion = (homeAddress) => {
          $scope.security.edit.Address = homeAddress;

          // Address to post
          $scope.security.edit.ContactAddress = homeAddress;

          $scope.security.edit.closeAutoHomeAddress();
          $scope.security.edit.showSuggestionModal = false;
          $scope.security.saveAddress();
        };

        $scope.security.saveAddress = (cb) => {
          let callback = cb;
          if (typeof cb === 'undefined') {
            callback = () => {};
          }

          if ($scope.security.edit.Address.isCustomAddress) {
            const { street_address: streetAddress, locality, postal_code: postalCode, stateShort_Name: state } = $scope.security.edit.Address;
            const local = locality ? `, ${locality}` : '';
            const zip = postalCode ? `, ${postalCode}` : '';
            const stateShortName = state ? `, ${state}` : '';
            $scope.security.edit.Address.formatted_address = `${streetAddress}${local}${stateShortName}${zip}`;
          }

          dashboardService.addressDetailsSet(
            {
              ...$scope.security.edit.Address,
              countryCode: $scope.security.edit.Address.CountryCode,
              IsMailing: false,
              AddressID: 0,
              TypeId: $scope.security.edit.Address.AddressType,
              AddressValuationExtendedDetailsId: 0,
            }
          ).then((addressResponse) => {
            $scope.security.edit.PropertyDetails.AddressId = addressResponse.data;
            callback();
          });
        };
      });
    };

    $scope.getApplicants().then(() => {
      loadSecurity();
    });

    $scope.securityShowTabContent = (tab) => {
      $scope.showTab = tab;
    };
    $scope.showTab = 'info';

    $scope.getOwners();
    $scope.getEmptySecurityInfo();
    $scope.getSecurityOptions();

    $scope.showSecurityList = () => {
      const objectToPass = {
        LoanApplicationId: $scope.loanAppId,
        LoanSecurityId: 0,
      };

      if ($scope.LoanSecurityList && _.isUndefined($scope.LoanSecurityList.LoanSecurityId)) {
        loanApplicationServices.setLoanSecurity(objectToPass).then(() => {
          $scope.getLoanSecurity();
          $scope.securityList = $scope.LoanSecurityList.SecurityList;
        });
      }
    };

    $scope.showSecurityList();

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.validateSecurityForm = (form) => {
      $scope.hasError = false;
      _.map($scope.formAreas, (area) => {
        area.error = false;
        return area;
      });

      _.each(form, (field, key) => {
        if (field && key[0] !== '$') {
          if (!field.$valid) {
            if (field.$viewValue === '') {
              field.$setValidity('required', false);
              $scope.hasError = true;

              // some names have indexes prepended by "-", lets remove them here to properly check by it's original name
              const nameToSearch = field.$name ? field.$name.split('-')[0] : '';
              _.forEach($scope.formAreas, (area) => {
                if (!area.error && _.findIndex(area.data, (o) => { return o === nameToSearch; }) > -1) {
                  area.error = true;
                }
                return area;
              });
            } else {
              field.$setValidity('required', true);
              $scope.hasError = false;

              field.$valid = true;
              field.$invalid = false;
            }
          } else if (field.$name === 'owners') {
            // validate owners 100%
            let countPercentages = 0;
            let checkPercentage = true;
            $scope.ownersError = false;
            $scope.formAreas.info.error = false;
            $scope.security.edit.Ownership.forEach((obj) => {
              countPercentages += parseFloat(obj.Percentage);
              if (checkPercentage && countPercentages > 100) {
                field.$setValidity('required', true);
                $scope.hasError = true;
                $scope.ownersError = true;
                checkPercentage = false;

                $scope.formAreas.info.error = true;
              }
            });
          } else {
            field.$setValidity('required', true);
          }

          if (field.$pristine) {
            field.$dirty = true;
          }
        }
      });

      if ($scope.hasError) {
        $scope.security.sending = false;
        return false;
      }
      form.$setValidity('required', true);

      return true;
    };

    $scope.$watch('security.edit', () => {
      if ($scope.editSecurityForm.$submitted)
        $scope.validateSecurityForm($scope.editSecurityForm);
    }, true);

    $scope.$watch('IncomeListEditMode', () => {
      if ($scope.editSecurityForm.$submitted)
        $scope.validateSecurityForm($scope.editSecurityForm);
    }, true);

    const proceedEditSecurity = () => {
      if (assetFinanceIgnore($scope.security.edit.SecurityTypeId)) {
        $scope.security.edit.PropertyDetails.StatusId = 0;
        $scope.security.edit.PropertyDetails.PropertyPrimaryPurposeId = 0;
        $scope.security.edit.PropertyDetails.HoldingId = 0;
        $scope.security.edit.PropertyDetails.ZoningId = 0;
        $scope.security.edit.PropertyDetails.PropertyTypeId = 0;
      }
      const { edit: editData } = $scope.security;
      const setData = {
        LoanApplicationId: $scope.loanAppId,
        SecurityInfoId: LoanSecurityInfoId,
        FundingCalParametersId: editData.FundingCalParametersId,
        IsPrimary: editData.IsPrimary,
        SecurityTypeId: parseInt(editData.SecurityTypeId, 10),
        TransactionId: parseInt(editData.TransactionId, 10),
        MortgagePriority: editData.MortgagePriority,
        LoanSecurityId: parseInt($scope.LoanSecurityList.LoanSecurityId, 10),
        IsRefinance: editData.IsRefinance,
        PropertyDetails: {
          LoanSecurityId: LoanSecurityInfoId,
          StatusId: parseInt(editData.PropertyDetails.StatusId, 10),
          PropertyPrimaryPurposeId: parseInt(editData.PropertyDetails.PropertyPrimaryPurposeId, 10),
          HoldingId: parseInt(editData.PropertyDetails.HoldingId, 10),
          ZoningId: parseInt(editData.PropertyDetails.ZoningId, 10),
          PropertyTypeId: parseInt(editData.PropertyDetails.PropertyTypeId, 10),
          Suburb: editData.PropertyDetails.IsPreApproved ? (editData.PropertyDetails.Suburb || '') : '',
          PropertyState: editData.PropertyDetails.IsPreApproved ? (editData.PropertyDetails.PropertyState || '') : '',
          CurrentValue: !editData.PropertyDetails.IsPreApproved ? editData.PropertyDetails.CurrentValue : '',
          LandValue: !editData.PropertyDetails.IsPreApproved ? editData.PropertyDetails.LandValue : '',
          EstimatedPrice: editData.PropertyDetails.IsPreApproved ? editData.PropertyDetails.EstimatedPrice : '',
          EstimatedBasesId: parseInt(editData.PropertyDetails.EstimatedBasesId, 10),
          ContratctPrice: !editData.PropertyDetails.IsPreApproved ? editData.PropertyDetails.ContratctPrice : '',
          ContractDate: editData.PropertyDetails.ContractDate,
          LicensedRealEstateAgentContract: editData.PropertyDetails.LicensedRealEstateAgentContract,
          IsPreApproved: editData.PropertyDetails.IsPreApproved,
          AddressId: !editData.PropertyDetails.IsPreApproved ? editData.PropertyDetails.AddressId : 0,
          TitleTypeId: editData.PropertyDetails.TitleTypeId,
          TitleId: editData.PropertyDetails.TitleId,
          IdentifierList: editData.PropertyDetails.IdentifierList,
          ContactForAccess: { ...editData.PropertyDetails.ContactForAccess,
          },
        },
        MortgageList: editData.MortgageList,
        IncomeList: $scope.IncomeListEditMode,
        Ownership: editData.Ownership,
        AssetId: editData.AssetId,
      };
      loanApplicationServices.setLoanSecurityInfo(setData).then(() => {
        $scope.getLoanSecurity();
        $scope.financialsSharedService.LiabilityInfoGet();
        $scope.financialsSharedService.IncomeInfoGet();
        $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
        $scope.cancel();
      });
    };

    $scope.editSecurity = (formData) => {
      if (!$scope.security || !$scope.security.edit || !$scope.security.edit.PropertyDetails) return;

      $scope.security.sending = true;

      if ($scope.validateSecurityForm(formData) === true) {
        if ($scope.mortgageSettings.showAddMortgage) {
          $scope.saveNewMortgageEditMode();
        }
        const validSecurity = (assetFinanceIgnore($scope.security.edit.SecurityTypeId)) || $scope.security.edit.PropertyDetails.IsPreApproved;
        if (validSecurity) {
          proceedEditSecurity();
        } else {
          $scope.security.saveAddress(proceedEditSecurity);
        }
      }
    };
  });
