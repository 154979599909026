export const ADDRESS_TYPE = {
  CURRENT_ADDRESS: 1,
  OTHER: 5,
  POST_SETTLEMENT_ADDRESS: 3,
  POSTAL_ADDRESS: 2,
  PREVIOUS_ADDRESS: 4,
};

export const ADDRESS_TYPE_NAME = {
  CURRENT_ADDRESS: 'Current Address',
  PREVIOUS_ADDRESS: 'Previous Address',
};
