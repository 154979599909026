import angular from 'angular';

angular.module('app')
  .directive('addTagOnBlur', function tagOnBlur() {
    return {
      require: 'uiSelect',
      link(scope, elm, attrs, ctrl) {
        const onBlurEvnt = () => {
          if ((ctrl.items && ctrl.items.length) || ctrl.tagging.isActivated) {
            ctrl.searchInput.triggerHandler('tagged');
            let newItem = ctrl.search;
            if (ctrl.tagging.fct) {
              newItem = ctrl.tagging.fct(newItem);
            }
            if (newItem) ctrl.select(newItem, true);
          }
        };
        ctrl.searchInput.on('blur', onBlurEvnt);
        scope.$on('$destroy', () => {
          ctrl.searchInput.on('blur', onBlurEvnt);
        });
      },
    };
  });
