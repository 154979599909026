import angular from 'angular';

angular.module('app').controller('AddEmploymentRolesCtrl', function AddEmploymentRolesCtrl(
  $scope, $uibModalInstance, optionsService
) {
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.Employment = {};

  $scope.Employment.getRoles = function (criteria) {
    optionsService.employmentRoleGet(criteria)
      .then((response) => {
        $scope.Employment.empRoleCount = 0;
        $scope.Employment.empRolesWrapper = {
          empRoles: response.data,
        };
        $scope.Employment.empRoleCount = response.data.length;
        $scope.Employment.roleQuery = criteria;
      });
  };

  $scope.Employment.pickedRoleLookup = function (roleObj) {
    $scope.Employment.selectedLookup = roleObj.ProfessionTitle;
    $scope.Employment.criteria = roleObj.ProfessionTitle;
    $scope.Employment.EmploymentRole = $scope.Employment.criteria;
    $scope.Employment.EmploymentRollID = roleObj.ProfessionID;

    $scope.Employment.selectedLookup = roleObj.ProfessionTitle;
    $scope.Employment.criteria = roleObj.ProfessionTitle;
    $scope.Employment.EmploymentRole = $scope.Employment.criteria;
    $scope.Employment.EmploymentRollID = roleObj.ProfessionID;
  };

  $scope.addEmploymentRole = function () {
    $scope.cancel();
  };
});
