import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import {
  isCancelled,
  getModalName,
  videoNotFoundAlert,
} from 'Common/utilities/subscription';


class UsersSubscriptionComponentCtrl {
  constructor(
    contactService,
    $uibModal,
    configService,
    usersSubscriptionService
  ) {
    'ngInject';

    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.subscriptionType = SUBSCRIPTIONTYPE;
    this.configService = configService;
    this.usersSubscriptionService = usersSubscriptionService;
    this.isCancelledWithActiveDate = isCancelled;
    this.videoNotFoundAlert = videoNotFoundAlert;
  }

  $onInit() {
    this.userSubscriptions = [];
  }

  $onChanges(changes) {
    if (changes && changes.adviserInfo) {
      this.adviserInfo = changes.adviserInfo.currentValue;
    }
    if (changes && changes.orderedById) {
      this.orderedById = changes.orderedById.currentValue;
    }
    const checkChange = (this.adviserInfo && this.adviserInfo.FamilyId) && (changes.adviserInfo.currentValue.FamilyId !== changes.adviserInfo.previousValue.FamilyId);
    if (checkChange) {
      this.familyId = this.adviserInfo.FamilyId;
      this.getSubscription();
    }
  }

  checkTypeValid(subscriptionProductId) {
    const { ONLINE_FACT_FIND, BROKERPEDIA, CREDIT_CHECK, PRICE_FINDER, MARKETING_AUTOMATION } = this.subscriptionType;
    const { onlineFactFind: featureOnlineFactFind, brokerPedia: featureBrokerPedia, creditChecks: featureCreditChecks, priceFinder: featurePriceFinder, marketingAutomation: featureMarketingAutomation } = this.configService.feature;
    const onlineFactFind = featureOnlineFactFind && ONLINE_FACT_FIND;
    const brokerPedia = featureBrokerPedia && BROKERPEDIA;
    const creditCheck = featureCreditChecks && CREDIT_CHECK;
    const priceFinder = featurePriceFinder && PRICE_FINDER;
    const marketingAutomation = featureMarketingAutomation && MARKETING_AUTOMATION;
    const subscriptionTypeValid = [onlineFactFind, brokerPedia, creditCheck, priceFinder, marketingAutomation].indexOf(subscriptionProductId) !== -1;
    return subscriptionTypeValid;
  }

  getSubscription() {
    this.userSubscriptions = [];
    if (this.familyId) {
      this.contactService.subscriptionInfo(this.familyId).then((response) => {
        if (!response || !response.length) return;
        this.userSubscriptions = response.reduce((initial, currentObj) => {
          const { subscriptionProductId } = currentObj;
          if (this.checkTypeValid(subscriptionProductId)) {
            initial.push(currentObj);
          }
          return initial;
        }, []);
        const brokerPedia = this.userSubscriptions.find(obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.BROKERPEDIA);
        const priceFinder = this.userSubscriptions.find(obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.PRICE_FINDER);
        this.usersSubscriptionService.setBrokerPediaData(brokerPedia);
        this.usersSubscriptionService.setPriceFinderData(priceFinder);
      });
    }
  }

  openAutoMarketingModal(props) {
    const modalInstance = this.$uibModal.open({
      template: `<subscription-auto-marketing-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId" 
                  subscription-data="vm.props.subscriptionData" 
                  ordered-by-id="vm.props.orderedById">
                </subscription-auto-marketing-modal>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'subscription-modal subscription-auto-marketing-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then((response) => {
      if (response && response.getAllSubscribe) {
        this.getSubscription();
      }
    });
  }

  openSubscribeModal(subs) {
    const { subscriptionProductId } = subs;
    if (!subscriptionProductId) return;
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) return;
    if (subscriptionProductId === this.subscriptionType.MARKETING_AUTOMATION) {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      this.openAutoMarketingModal(props);
    } else {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      const componentName = getModalName(subscriptionProductId);
      if (!componentName) return;
      const modalInstance = this.$uibModal.open({
        template: `<${componentName}
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId" 
                    subscription-data="vm.props.subscriptionData" 
                    ordered-by-id="vm.props.orderedById">
                  </${componentName}>`,
        size: 'lg',
        backdrop: 'static',
        windowClass: 'mc-subscription-modal',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props,
        },
      });
      modalInstance.result.then((response) => {
        if (!response) return;
        this.getSubscription();
      });
    }
  }

  cancelSubscription(subs) {
    const { subscriptionProductId } = subs;
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) return;
    if (subscriptionProductId === this.subscriptionType.MARKETING_AUTOMATION) {
      this.cancelCampaignSubscription(subs); return;
    }
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId" 
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-modal>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'cancel-subscription',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then((response) => {
      if (!response || !response.getSubscription) return;
      this.getSubscription();
    });
  }
  cancelCampaignSubscription(subs) {
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-now-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId" 
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-now-modal>`,
      backdrop: 'static',
      windowClass: 'cancel-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(() => {
      this.getSubscription();
    });
  }
  isInActiveSubscription(subscripton) {
    return this.subscriptionEnabled && !subscripton.isActive && !this.isCancelledWithActiveDate(subscripton);
  }
  isCancelledSubscription(subscripton) {
    return this.subscriptionEnabled && this.isCancelledWithActiveDate(subscripton);
  }
  isActiveSubscription(subscription) {
    return this.subscriptionEnabled && subscription.isActive && !subscription.isTrial;
  }
  isTrialSubscription(subscription) {
    return this.subscriptionEnabled && subscription.isTrial && subscription.isActive;
  }
}

export default UsersSubscriptionComponentCtrl;
