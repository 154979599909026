import angular from 'angular';

class NewLoanApplicationV2Ctrl {
  constructor(
    $uibModalInstance
  ) {
    this.modalInstance = $uibModalInstance;
  }
}

angular.module('app').controller('NewLoanApplicationV2Ctrl', NewLoanApplicationV2Ctrl);
