/*
    @author : Arnaldo Jan Ubas
    Financials Shared Services
    ---
    copied from loan application financialSharedService.js - rolandbarro
*/

import angular from 'angular';

angular.module('app').factory('insuranceFinancialsSharedService', [function () {
  var factory = this;

  /* CODE COMMENTED DUE TO LINTING ERROR CAUSED BY IDENTICAL BLOCKS OF CODE. SHOULD BE REFACTORED BY ROLAND BARRO IN THE FUTURE */

  // // models
  // angular.extend(factory, {
  //   listApplicants: [], // used by financial.js

  //   listAssets: [],
  //   listLiabilities: [],
  //   listIncome: [],
  //   listExpense: [],
  //   selectedFamily: {},
  //   bgColor: [],

  //   assetLength: 0,
  //   liabilityLength: 0,
  //   incomeLength: 0,
  //   expenseLength: 0,

  //   assetTotal: 0,
  //   NonREATotalAmount: 0,
  //   RealEstateTotalAmount: 0,
  //   liabilityTotal: 0,
  //   incomeTotal: 0,
  //   expenseTotal: 0,
  //   test: 'test check',
  // });

  // // methods
  // angular.extend(factory, {

  //   validateFinancials() {
  //     this.isValidFinancials =
  //               this.incomeLength > 0 &&
  //               this.expenseLength > 0;
  //     this.isStartedFinancials =
  //               this.incomeLength > 0 ||
  //               this.expenseLength > 0;
  //   },

  //   updateAsset(FamilyId) {
  //     var family = _.find(this.listAssets, (o) => {
  //       return _.isEqual(String(o.FamilyId), String(FamilyId));
  //     });
  //     if (family) {
  //       this.assetLength = _.size(family.overview.FinancialInfo);
  //       this.assetTotal = family.overview.TotalAmount;
  //       this.NonREATotalAmount = family.overview.NonREATotalAmount;
  //       this.RealEstateTotalAmount = family.overview.RealEstateTotalAmount;

  //       this.validateFinancials();
  //     }
  //   },

  //   updateLiability(FamilyId) {
  //     var family = _.find(this.listLiabilities, (o) => {
  //       return _.isEqual(String(o.FamilyId), String(FamilyId));
  //     });
  //     if (family) {
  //       this.liabilityLength = _.size(family.overview.FinancialInfo);
  //       this.liabilityTotal = family.overview.TotalAmount;

  //       this.validateFinancials();
  //     }
  //   },

  //   updateIncome(FamilyId) {
  //     var family = _.find(this.listIncome, (o) => {
  //       return _.isEqual(String(o.FamilyId), String(FamilyId));
  //     });
  //     if (family) {
  //       this.incomeLength = _.size(family.overview.FinancialInfo);
  //       this.incomeTotal = family.overview.TotalAmountPerMonth;

  //       this.validateFinancials();
  //     }
  //   },

  //   updateExpense(FamilyId) {
  //     var family = _.find(this.listExpense, (o) => {
  //       return _.isEqual(String(o.FamilyId), String(FamilyId));
  //     });
  //     if (family) {
  //       this.expenseLength = _.size(family.overview.FinancialInfo);
  //       this.expenseTotal = family.overview.TotalAmountPerMonth;

  //       this.validateFinancials();

  //       // $rootScope.$broadcast('updateExpense', {
  //       //     length: _.size(family.overview.FinancialInfo),
  //       //     TotalAmount: family.overview.TotalAmountPerMonth
  //       // });
  //     }
  //   },

  //   FinancialApplicantList(data) {
  //     if (!data) return;
  //     this.listApplicants = [];
  //     var tempList = [];

  //     _.forEach(data.InvolvedPartyPerson, (o) => {
  //       tempList.push({
  //         FamilyId: _.first(o).FamilyId,
  //         FamilyName: _.first(o).FamilyFullName,
  //         initials: utilitiesService.filterInitialOneString(_.first(o).FamilyFullName),
  //         background: this.getBGcolor(_.first(o).FamilyId),
  //         listPriority: (_.first(o).IsApplicant ? 0 : 1),
  //       });
  //     });
  //     if (tempList.length > 0) {
  //       var x = _.orderBy(tempList, ['listPriority', 'FamilyName'], ['asc']);
  //       _.forEach(x, (o) => {
  //         this.listApplicants.push(o);
  //       });
  //       tempList = [];
  //     }

  //     _.forEach(data.InvolvedPartyEntity, (o) => {
  //       tempList.push({
  //         FamilyId: o.FamilyId,
  //         FamilyName: o.FamilyFullName,
  //         initials: utilitiesService.filterInitialOneString(o.FamilyFullName),
  //         background: this.getBGcolor(o.FamilyId),
  //         listPriority: (o.IsApplicant ? 0 : 1),
  //       });
  //     });
  //     if (tempList.length > 0) {
  //       var x = _.orderBy(tempList, ['listPriority'], ['asc']);
  //       _.forEach(x, (o) => {
  //         this.listApplicants.push(o);
  //       });
  //       tempList = [];
  //     }

  //     if (_.size(this.listApplicants) > 0) {
  //       angular.extend(this.selectedFamily, {
  //         FamilyId: _.first(this.listApplicants).FamilyId.toString(),
  //         FamilyName: _.first(this.listApplicants).FamilyName,
  //         initials: utilitiesService.filterInitialOneString(_.first(this.listApplicants).FamilyName),
  //         background: this.getBGcolor(_.first(this.listApplicants).FamilyId),
  //         listPriority: (_.first(this.listApplicants).IsApplicant ? 0 : 1),
  //       });
  //     }
  //   },

  //   getBGcolor(Id) {
  //     if (!_.isUndefined(Id)) {
  //       Id = parseInt(Id);

  //       var bg = _.find(this.bgColor, (o) => {
  //         return o.Id == Id;
  //       }
  //       );

  //       if (!angular.isUndefined(bg)) {
  //         return bg.Background;
  //       }

  //       return '999999';
  //     }
  //   },

  //   AssetInfoGet() {
  //     loanScenarioService.AssetInfoGet(this.familyId).then((response) => {
  //       // $scope.AssetTotalAmount = 0;
  //       // $scope.realStateTotal = 0;
  //       this.NoAssets = (!(_.size(response.data) > 0));
  //       _.forEach(response.data, (asset) => {
  //         // NOTE: Let's o out the TotalAmount here.
  //         // REASON: It added ALL assets including Real Estate which is wrong. it should be separated
  //         asset.overview.NonREATotalAmount = 0;
  //         asset.overview.RealEstateTotalAmount = 0; // let's just prepare this total even though it's not used; might be used later

  //         _.forEach(asset.overview.FinancialInfo, (financialInfo) => {
  //           // Let's sum the assets
  //           if (financialInfo.TypeId != 1)
  //             asset.overview.NonREATotalAmount += financialInfo.Value;
  //           else
  //             asset.overview.RealEstateTotalAmount += financialInfo.Value;

  //           _.remove(financialInfo.Borrowers, (borrower) => {
  //             return borrower.BorrowerID == 0;
  //           });

  //           _.map(financialInfo.Borrowers, (borrower) => {
  //             borrower.background = this.getBGcolor(borrower.BorrowerID);
  //             borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
  //             return borrower;
  //           });

  //           // $scope.AssetTotalAmount += asset.overview.TotalAmount;
  //           // $scope.realStateTotal += financialInfo.Value;
  //         });
  //       });

  //       this.listAssets = response.data;

  //       this.updateAsset(this.selectedFamily.FamilyId);
  //     });
  //   },

  //   IncomeInfoGet() {
  //     loanScenarioService.IncomeInfoGet(this.familyId).then((response) => {
  //       // $scope.IncomeTotalAmount = 0;
  //       _.forEach(response.data, (income) => {
  //         _.forEach(income.overview.FinancialInfo, (financialInfo) => {
  //           if (!angular.isUndefined(financialInfo.FrequencyName))
  //             financialInfo.FrequencyInitial = financialInfo.FrequencyName.charAt(0);

  //           _.remove(financialInfo.Borrowers, (borrower) => {
  //             return borrower.BorrowerID == 0;
  //           });

  //           _.map(financialInfo.Borrowers, (borrower) => {
  //             borrower.background = this.getBGcolor(borrower.BorrowerID);
  //             borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
  //             return borrower;
  //           });
  //         });

  //         // $scope.IncomeTotalAmount += income.overview.TotalAmount;
  //       });

  //       this.listIncome = response.data;

  //       this.updateIncome(this.selectedFamily.FamilyId);
  //     });
  //   },

  //   LiabilityInfoGet() {
  //     loanScenarioService.LiabilityInfoGet(this.familyId).then((response) => {
  //       // $scope.LiabilityTotalAmount = 0;
  //       _.forEach(response.data, (liability) => {
  //         _.forEach(liability.overview.FinancialInfo, (financialInfo) => {
  //           if (!_.isUndefined(financialInfo.RepaymentFrequencyName))
  //             financialInfo.FrequencyInitial = financialInfo.RepaymentFrequencyName.charAt(0);

  //           _.remove(financialInfo.Borrowers, (borrower) => {
  //             return borrower.BorrowerID == 0;
  //           });

  //           _.map(financialInfo.Borrowers, (borrower) => {
  //             borrower.background = this.getBGcolor(borrower.BorrowerID);
  //             borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
  //             return borrower;
  //           });
  //         });
  //       });

  //       this.listLiabilities = response.data;

  //       this.updateLiability(this.selectedFamily.FamilyId);
  //     });
  //   },

  //   ExpenseInfoGet() {
  //     this.searchingExpenses = true;
  //     loanScenarioService.ExpenseInfoGet(this.familyId).then((response) => {
  //       this.searchingExpenses = false;
  //       _.forEach(response.data, (expense) => {
  //         _.forEach(expense.overview.FinancialInfo, (financialInfo) => {
  //           if (!angular.isUndefined(financialInfo.FrequencyName))
  //             financialInfo.FrequencyInitial = financialInfo.FrequencyName.charAt(0);

  //           _.remove(financialInfo.Borrowers, (borrower) => {
  //             return borrower.BorrowerID == 0;
  //           });

  //           _.map(financialInfo.Borrowers, (borrower) => {
  //             borrower.background = this.getBGcolor(borrower.BorrowerID);
  //             borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
  //             return borrower;
  //           });
  //         });

  //         // $scope.ExpenseTotalAmount += expense.overview.TotalAmount;
  //       });

  //       this.listExpense = response.data;
  //       this.updateExpense(this.selectedFamily.FamilyId);
  //     });
  //   },
  // });

  return factory;
}]);
