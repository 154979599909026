import moment from 'moment';
import PipelineCtrl from '../pipelineCtrl';

export default class PipelineTableCtrl extends PipelineCtrl {
  constructor(
    opportunityNewModalService,
    loanOpportunityService,
    contactService,
    pipelineService,
    $stateParams,
    $uibModal,
    $state,
    $filter,
    $sce
  ) {
    'ngInject';

    super(
      opportunityNewModalService,
      loanOpportunityService,
      contactService,
      pipelineService,
      $stateParams,
      $uibModal,
      $state,
      $filter,
      $sce
    );
  }
  $onInit() {
    this.freezeColumn = 2;
    this.pageSize = 10;
    this.pageNumber = 1;

    this.showLoader = true;
    this.getPipelineFilterSettings(this.onFirstLoad());
  }

  onFirstLoad() {
    this.getTableHeaders();
    this.refreshTable();
  }

  refreshTable() {
    this.getTableData(this.pageNumber, this.pageSize);
  }

  getTableHeaders() {
    const headerSettings = { isHidden: 0, isSortable: 1 };
    this.tableHeaders = [
      { offlineSortObjKey: 'contactNames', dataSortObjectKey: 'ClientName', label: 'Client Name', ...headerSettings },
      { offlineSortObjKey: 'title', dataSortObjectKey: 'OpportunityName', label: 'Opportunity Name', ...headerSettings },
      { offlineSortObjKey: 'proposedLoanAmount', dataSortObjectKey: 'ProposedLoan', label: 'Proposed Loan', ...headerSettings },
      { offlineSortObjKey: 'pipelineStatus', dataSortObjectKey: 'Status', label: 'Status', ...headerSettings },
      { offlineSortObjKey: 'enquiry', dataSortObjectKey: 'EnquirySource', label: 'Enquiry Source', ...headerSettings },
      { offlineSortObjKey: 'adviserFullName', dataSortObjectKey: 'Adviser', label: 'Adviser', ...headerSettings },
      { offlineSortObjKey: 'referrerName', dataSortObjectKey: 'Referrer', label: 'Referrer', ...headerSettings },
      { offlineSortObjKey: 'statusUpdated', dataSortObjectKey: 'StatusUpdated', label: 'Status Updated', ...headerSettings },
      { label: 'id', isHidden: 1 },
      { label: 'loanId', isHidden: 1 },
      { label: 'loanScenarioId', isHidden: 1 },
      { label: 'pipelineStatusId', isHidden: 1 },
    ];
  }

  getTableData(pageNumber, pageSize) {
    if (!pageNumber || !pageSize) return;
    this.showLoader = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;

    this.pipelineService.getOpportunityTable({ pageNumber, pageSize })
      .then((response) => {
        if (!response || !response.length) {
          this.showLoader = false;
          return;
        }

        const { totalRecords } = response[0];
        this.totalRecords = totalRecords;
        this.tableData = response.reduce((accum, current) => {
          const {
            contactNames,
            title,
            proposedLoanAmount,
            pipelineStatus,
            enquiry,
            adviserFullName,
            referrerName,
            statusUpdated,
            pipelineStatusId,
            loanId,
            loanScenarioId,
          } = current;
          if (!loanScenarioId) return accum;
          return accum.concat(
            {
              contactNames,
              title,
              proposedLoanAmount: this.$filter('currency')(proposedLoanAmount, '$', 2),
              pipelineStatus,
              enquiry,
              adviserFullName: this.$sce.trustAsHtml(`
                <span class="adviser">
                  <h4>${adviserFullName.charAt(0)}</h4>
                  <p>${adviserFullName.split(' ')[0]}</p>
                </span>
              `),
              referrerName,
              statusUpdated: statusUpdated ? moment(new Date(statusUpdated)).format('YYYY MM DD hh:mm:ss') : '',
              id: loanScenarioId,
              loanId,
              loanScenarioId,
              pipelineStatusId,
            }
          );
        }, []);
        this.showLoader = false;
      });
  }
}
