import angular from 'angular';

class AnnouncementModalCtrl {
  constructor($uibModalInstance, featuresList) {
    'ngInject';

    this.modalInstance = $uibModalInstance;
    this.featuresList = featuresList;
  }
}

angular.module('app').controller('AnnouncementModalCtrl', AnnouncementModalCtrl);
