import angular from 'angular';
import swal from 'sweetalert';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';

class CreditChecksCtrl {
  constructor(
    creditCheckSubscriptionService,
    contactService,
    uiService,
    $uibModal,
    toaster
  ) {
    'ngInject';

    this.creditCheckSubscriptionService = creditCheckSubscriptionService;
    this.contactService = contactService;
    this.uiService = uiService;
    this.$uibModal = $uibModal;
    this.toaster = toaster;
  }

  $onInit() {
    this.isLoadingPageContent = true;
    if (this.uiService.loggedUserData) {
      const { FamilyId } = this.uiService.loggedUserData;
      this.checkUserSubscription(FamilyId);
    } else {
      this.contactService.getUserInfo()
        .then((response) => {
          if (response.data) {
            const { FamilyId } = response.data;
            this.checkUserSubscription(FamilyId);
          } else {
            this.onSubscriptionCheckFailed();
          }
        });
    }
  }

  checkUserSubscription(currentUserFamId) {
    this.contactService.subscriptionInfo(currentUserFamId)
      .then((response) => {
        if (!response || !response.length) return;

        this.uiService.creditCheckSub = response.find(sub => sub.subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK);
        const { creditCheckSub } = this.uiService;
        const hasCreditCheckSub = creditCheckSub && creditCheckSub.isActive;

        if (hasCreditCheckSub) {
          this.isLoadingPageContent = false;
        } else {
          this.promptUserSubscription(currentUserFamId, creditCheckSub);
        }
      }, () => {
        this.onSubscriptionCheckFailed();
      });
  }

  promptUserSubscription(currentUserFamId, creditCheckSub) {
    this.creditCheckSubscriptionService.openCreditCheckSubModal(currentUserFamId, creditCheckSub)
      .then((modalResponse) => {
        this.handleSubModalResponse(modalResponse);
      }, () => {
        this.redirectUserToDashboard();
      });
  }

  onSubscriptionCheckFailed() {
    swal('Yikes! Something is wrong', 'Please contact support.', 'error', () => {
      this.redirectUserToDashboard();
    });
  }

  handleSubModalResponse(response) {
    const userSubscribed = response && response.getAllSubscribe;
    this.uiService.creditCheckSub.isActive = userSubscribed;
    if (userSubscribed) {
      this.isLoadingPageContent = false;
    } else {
      this.redirectUserToDashboard();
    }
  }

  redirectUserToDashboard() {
    this.uiService.stateGo('app.dashboard');
  }

  onCreditChecksListInit(api) {
    this.listApi = api;
  }

  openAddCreditChecks() {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'assets/views/creditChecks/set_credit_checks_modal.html',
      controller: 'SetCreditChecksModalCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'credit-checks-modal-window',
      resolve: {
        selectedContact: () => null,
      },
    });

    if (modalInstance && modalInstance.result) {
      modalInstance.result
        .then((result) => {
          if (!result) return;
          this.listApi.refresh();
          this.toaster.pop('success', 'Credit check request submitted.');
        });
    }
  }
}

angular.module('app').controller('CreditChecksCtrl', CreditChecksCtrl);
