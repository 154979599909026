import angular from 'angular';
import _ from 'lodash';
import { SYNC_STATUS } from 'Common/constants/gmailSyncStatus';
import { EMAIL_TYPES, EMAIL_CTYPES } from 'Common/constants/emailTypes';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

angular.module('app').controller('EmailsDetailCtrl',
  function EmailsDetailCtrl($scope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    contactService,
    NgTableParams,
    $filter,
    $q,
    $window,
    $state,
    configService,
    $interval) {
    $scope.SYNC_STATUS = SYNC_STATUS;
    $scope.familyId = $stateParams.familyId;
    $scope.chosenFilter = 'All';
    $scope.isNoEmail = true;
    $scope.gmailObject = [];
    $scope.filterModel = {};
    $scope.syncStatus = 0;
    $scope.isLoadingEmails = true;
    $scope.isActiveInputSearch = false;
    $scope.paginationObject = {
      currentPage: 0,
      maxSize: 5,
      totalItems: 0,
    };
    /* gmail models */
    $scope.gmailObject.models = null;
    $scope.gmailObject.models = {
      emailList: null,
      UserAuthUrl: null,
      isAuthenticated: false,
    };
    /* end of gmail models */
    /* gmail functions and proceedures */
    const stopSyncInterval = () => {
      if ($scope.postInterval) {
        $interval.cancel($scope.postInterval);
        $scope.postInterval = null;
      }
    };
    const stopTimeout = (timeoutToStop) => {
      if (!timeoutToStop) return;

      $timeout.cancel(timeoutToStop);
    };

    const syncStatusInterval = () => {
      if ($scope.postInterval) return;

      $scope.postInterval = $interval(() => {
        if ($scope.syncStatus === SYNC_STATUS.SYNCING) {
          contactService.getGmailSyncStatus($state.params.familyId).then((syncStatusResponse) => {
            if (!syncStatusResponse.data) {
              $scope.syncStatus = SYNC_STATUS.SYNCED;
              $scope.loadEmailList();
              stopSyncInterval();
            }
          });
        } else {
          stopSyncInterval();
        }
      }, 10000);
    };
    const postSyncProcedure = (familyId) => {
      const defer = $q.defer();

      $scope.syncStatus = SYNC_STATUS.SYNCING;

      contactService.postGmailSync({ familyId }).then((postSyncResponse) => {
        if (postSyncResponse.data && Object.keys(postSyncResponse.data).length) {
          /* user hasn't authenticated the gmail yet */
          if (postSyncResponse.data.UserAuthUrl && !postSyncResponse.data.AuthSuccess) {
            $scope.gmailObject.models.UserAuthUrl = postSyncResponse.data.UserAuthUrl;
            $scope.syncStatus = SYNC_STATUS.NOT_SYNCED;
          } else if (typeof postSyncResponse.data.NoOfMailsToSync !== 'undefined')

            if (postSyncResponse.data.NoOfMailsToSync) {
              // NoOfMailsToSync is greater than, 0 it means we are still syncing the gmail emails
              $scope.syncStatus = SYNC_STATUS.SYNCING;
            } else {
              // NoOfMailsToSync is <= 0, it means we are done syncing
              $scope.syncStatus = SYNC_STATUS.SYNCED;
            }
        }


        return defer.resolve();
      });

      return defer.promise;
    };
    /* procedure for syncing when the communication tab is clicked */
    const onInitCommunicationSync = () => {
      const defer = $q.defer();

      /* getGoogleEmail API call is to check whether the user has gmail authenticated */
      const familyId = $state.params.familyId;
      contactService.getGoogleEmail(familyId).then((getGmailResponse) => {
        $scope.gmailObject.models.isAuthenticated = getGmailResponse.data.AuthSuccess;
        contactService.getGmailSync(familyId).then(() => {
          postSyncProcedure(familyId).then(() => {
            return defer.resolve();
          });
        });
      });


      return defer.promise;
    };
    $scope.gmailObject.methods = null;
    $scope.gmailObject.methods = {
      isShowGmailFeatureMethod: () => {
        return (configService.feature && configService.feature.gmailCommunication) || 0;
      },

      setGmailValuesIfUserSynced: () => {
        $scope.isNoEmail = false;
      },

      init: () => {
        onInitCommunicationSync().then(() => {
          if ($scope.gmailObject.models.isAuthenticated) {
            $scope.gmailObject.methods.setGmailValuesIfUserSynced();
            syncStatusInterval();
          } else {
            $scope.showBannerAtStart = true;
          }
        });

        $scope.isShowGmailFeature = $scope.gmailObject.methods.isShowGmailFeatureMethod();
      },

      openNewWindowForAuth: () => {
        $window.open($scope.gmailObject.models.UserAuthUrl);
      },

      addGmailType: (data) => {
        _.forEach(data, (object) => {
          object.CType = EMAIL_TYPES.GMAIL;
        });
        return data;
      },
    };

    const setPaginationTotalItems = (length) => {
      $scope.paginationObject.totalItems = length;
    };
    $scope.manualSync = () => {
      postSyncProcedure($scope.familyId).then(() => {
        $scope.loadEmailList();
      });
    };

    contactService.getUserInfo().then((response) => {
      if (response.data) {
        $scope.userInfo = response.data;
      }
    });
    /* end of gmail functions and proceedures */
    function getSummaryInfo() {
      contactService
        .clientInformGet($scope.familyId)
        .then((response) => {
          $scope.clientsDataObject = response.data;
          // Summary Data;
          if ($scope.getSummaryData) {
            $scope.getSummaryData($scope.clientsDataObject);
          }
        });
    }
    getSummaryInfo();

    $scope.emailDetail = {};
    $scope.emailDetailModel = {};

    $scope.emailDetail.communicationTypes = [
      {
        id: 0,
        type: EMAIL_TYPES.CRM_EMAIL,
        value: 'Email',
      },
      {
        id: 1,
        type: EMAIL_TYPES.SMS,
        value: 'SMS',
      },
      {
        id: 2,
        type: EMAIL_TYPES.GMAIL,
        value: 'Gmail',
      },
    ];
    $scope.emailDetailModel.communicationType = $scope.emailDetail.communicationTypes[0];


    // Any code below was the original code for Emails Tab
    //--------------------------------------------------------------------------------


    // Emails List
    //-------------------------------------------------------------
    $scope.emailsList = [];
    $scope.EmailsListLength = 0;
    $scope.selectedFilterInfo = { isGmailChecked: true, selected: 'multiple' };
    $scope.isShowNoGmailBanner = true;

    const removeGmailsEmails = (emailList) => {
      return emailList.filter((email) => {
        return email.CType !== 'Gmail';
      });
    };
    $scope.hideNogmailBanner = (event) => {
      event.stopPropagation();
      $scope.showBannerAtStart = false;
      $scope.isShowNoGmailBanner = false;
    };
    $scope.loadEmailList = (searchCriteria = '', pageNumber = 1, pageSize = 10) => {
      if (!$scope.filterModel && Object.keys($scope.filterModel).length < 1) return;

      $scope.isLoadingEmails = true;
      const {
        gmail,
        email,
        phone,
      } = $scope.filterModel;

      contactService.getEmailList($scope.familyId, pageNumber, pageSize, gmail, email, phone, searchCriteria)
        .then((result) => {
          $scope.isLoadingEmails = false;

          if (!result.data || result.data.length < 1) {
            $scope.isNoEmail = true;
            $scope.emailsList = [];
            setPaginationTotalItems(0);
          } else {
            const tempEmailList = !$scope.gmailObject.methods.isShowGmailFeatureMethod() ? removeGmailsEmails(result.data) : result.data;

            $scope.emailsList = tempEmailList;

            const paginationLength = ($scope.emailsList.length && $scope.emailsList[0].TotalRecords) || 0;
            setPaginationTotalItems(paginationLength);
            $scope.paginationObject.currentPage = pageNumber;

            $scope.isNoEmail = false;
          }
        });
    };
    $scope.showManualSync = () => {
      return $scope.gmailObject.models.isAuthenticated && $scope.gmailObject.methods.isShowGmailFeatureMethod() && $scope.selectedFilterInfo.isGmailChecked;
    };
    $scope.isShowPaginate = () => {
      return $scope.paginationObject.totalItems > 10;
    };
    $scope.isShowNoGmailBannerConditions = () => {
      return (($scope.isShowNoGmailBanner && $scope.selectedFilterInfo.isGmailChecked) || $scope.showBannerAtStart)
            && !$scope.gmailObject.models.isAuthenticated
            && $scope.selectedFilterInfo.selected !== EMAIL_TYPES.GMAIL
            && !$scope.isFilterGmailFeatureCondition();
    };

    $scope.isContainGmail = () => {
      return !!$scope.emailsList.find(object => object.CType === EMAIL_CTYPES.GMAIL);
    };

    $scope.isShowNoGmailLinked = () => {
      return $scope.selectedFilterInfo.selected === EMAIL_TYPES.GMAIL && !$scope.gmailObject.models.isAuthenticated && !$scope.isLoadingEmails && !$scope.isContainGmail();
    };
    $scope.isShowNoMail = () => {
      const ishowNoMailVar = !$scope.isLoadingEmails && !$scope.emailsList.length && ((!$scope.gmailObject.models.isAuthenticated && $scope.selectedFilterInfo.selected !== EMAIL_TYPES.GMAIL) || $scope.gmailObject.models.isAuthenticated);
      return ishowNoMailVar;
    };
    const selectedFilterInfo = () => {
      let selected = 'none';
      let trueCounter = 0;
      let isGmailChecked = false;

      _.forOwn($scope.filterModel, (value, key) => {
        if (value) {
          selected = key;
          trueCounter++;

          isGmailChecked = key === EMAIL_TYPES.GMAIL;
        }
      });
      return { isMultiSelected: trueCounter > 1, selected: trueCounter > 1 ? 'multiple' : selected, isGmailChecked };
    };
    $scope.isCertainMailExist = (CType) => {
      const toReturnVal = _.find($scope.emailsList, object => object.CType === CType);

      if (toReturnVal) {
        return true;
      }

      return false;
    };

    $scope.updateFilter = () => {
      stopTimeout($scope.updateFilterTimeout);
      $scope.updateFilterTimeout = $timeout(() => {
        $scope.loadEmailList();
        $scope.selectedFilterInfo = selectedFilterInfo();
        $scope.showBannerAtStart = false;
      }, 500);
    };

    $scope.removeEmail = function (emailId, event) {
      if (event) {
        event.stopPropagation();
      }

      swal({
        title: 'Please confirm action',
        text: 'Are you sure you want to delete this email? This action cannot be undone',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FA8E91',
        confirmButtonText: 'Delete Email',
        closeOnConfirm: false,
      },
      (isConfirm) => {
        if (isConfirm) {
          contactService
            .removeEmail(
              $scope.familyId,
              emailId
            )
            .then(() => {
              $scope.loadEmailList();
              swal('Deleted', 'Email has been deleted.', 'success');
            })
            .catch(displayError);
        }
      });
    };

    $scope.viewEmail = function (emailId = null, size = 'lg', gmailEmail = null) {
      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/view_email_modal.html',
        controller: 'ViewEmailCtrl',
        size,
        resolve: {
          familyId() {
            return $scope.familyId;
          },
          emailId() {
            return emailId;
          },
          removeEmail() {
            return $scope.removeEmail;
          },
          loadEmailList() {
            return $scope.loadEmailList;
          },
          gmailEmail() {
            return gmailEmail;
          },
        },
      });
    };
    $scope.isFilterGmailFeatureCondition = () => {
      return ($scope.isShowGmailFeature) ? '' : '!gmail';
    };
    $scope.searchInputChanged = (typedValue = '') => {
      $scope.typedValueInSearch = typedValue;

      stopTimeout($scope.emailSearchTimeout);
      $scope.emailSearchTimeout = $timeout(() => {
        $scope.loadEmailList(typedValue);
      }, 300);
    };

    $scope.isActiveInputStatus = (isActive) => {
      $scope.isActiveInputSearch = isActive;
    };

    const initFilterSection = () => {
      $scope.emailDetail.communicationTypes.forEach((object) => {
        $scope.filterModel[object.type] = true;
      });
    };

    const loadAllEmailsProcedure = () => {
      $scope.loadEmailList();
      $scope.gmailObject.methods.init();
    };
    $scope.$watch('selectedContacts', (newValue) => {
      if (newValue === 'emails') {
        initFilterSection();
        loadAllEmailsProcedure();
      }
    });

    // Load Transactions in Adviser
    if ($stateParams.clientId) {
      initFilterSection();
      loadAllEmailsProcedure();
    }
    $window.authGoogle = function (code) {
      contactService.authGoogle(code).then(() => {
        loadAllEmailsProcedure();
      });
    };

    $scope.$on('$destroy', () => {
      stopSyncInterval();
      stopTimeout($scope.emailSearchTimeout);
      stopTimeout($scope.updateFilterTimeout);
    });
  });
