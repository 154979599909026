import angular from 'angular';

angular.module('app').controller('SavingGoalPdfPreviewModalCtrl', function SavingGoalPdfPreviewModalCtrl(
  $scope,
  $window,
  options,
  labels,
  colors,
  graphData,
  loanAmount,
  years,
  months,
  interestRate,
  frequency,
  initialDeposit,
  savingPerMonth,
  resultPanelFreq,
  installment,
  totalInterest,
  targetYear,
  customThemeService,
  series,
  userService,
  loanCalculatorSharedData) {
  $scope.data = graphData;
  $scope.options = options;
  $scope.labels = labels;
  $scope.colors = colors;
  $scope.series = series;
  $scope.loanAmount = loanAmount;
  $scope.years = years;
  $scope.months = months;
  $scope.interestRate = interestRate;
  $scope.frequency = frequency;
  $scope.initialDeposit = initialDeposit;
  $scope.savingPerMonth = savingPerMonth;
  $scope.resultPanelFreq = resultPanelFreq;
  $scope.installment = installment;
  $scope.totalInterest = totalInterest;
  $scope.targetYear = targetYear;
  $scope.brandingLogo = customThemeService.branding;
  $scope.getUserInfo = loanCalculatorSharedData.getUserInfo;
  $scope.getUserInfo();
  $scope.date = new Date();
});
