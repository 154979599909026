import angular from 'angular';

class LoanAmountForAliCtrl {
  constructor(
    $uibModalInstance,
    familyId,
    clientId
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.familyId = familyId;
    this.clientId = clientId;
  }
}

angular.module('app').controller('LoanAmountForAliCtrl', LoanAmountForAliCtrl);
