import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular.module('app').controller('NewLoanApplicationCtrl',
  function NewLoanApplicationCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $uibModal,
    $uibModalInstance,
    $compile,
    contactService,
    loanScenarioService,
    toaster,
    $state,
    optionsService,
    userService,
    corporateService,
    generalService,
    dashboardService,
    broadcastDataService,
    applicationSharedService,
    loanAppDetailsService
  ) {
    $scope.allocatedAdviser = {};
    $scope.allocatedAdviser.adviserSelected = {};
    $scope.allocatedAdviser.adviserSelected.FullName = {};
    $scope.allocatedAdviser.adviserSelected.FullName.description = {};
    $scope.isDisableAllocateAdviser = false;
    $scope.allocatedAdviserListLoaded = false;
    $scope.isAdviserAccess = false;
    $scope.allocatedAdviser.adviserInitialValue = '';
    $scope.selectedAdviserToEdit = {};
    $scope.submitClicked = false;
    $scope.selectedFamily = undefined;
    if (typeof $scope.brokersInfo === 'undefined') {
      userService.GetUserInfo().then((response) => {
        const access = response.data.AccessType;
        dashboardService.getBrokerBasicInfo().then((res) => {
          $scope.brokersInfo = res.data;
          if (parseInt(access, 10) === ACCESS_TYPE.PRINCIPAL_ADVISER || parseInt(access, 10) === ACCESS_TYPE.ADVISER) {
            $scope.AssignedAdviserId = $scope.brokersInfo.BrokerId;
            $scope.userFamilyId = $scope.brokersInfo.BrokerId;
            $scope.isAdviserAccess = true;
          }
          $scope.getAccounTypeNumber();
        });
      });
    }
    angular.extend($scope, {
      getContactAdviser(contactFamilyId) {
        contactService.getContactAssignedAdviser(contactFamilyId).then((loanAppAdviserResponse) => {
          $scope.AssignedAdviserId = loanAppAdviserResponse.data;
          return $scope.AssignedAdviserId;
        });
      },
      getAdvisersList() {
        generalService.getBrokerAll().then((response) => {
          $scope.advisersList = response.data;
          $scope.allocatedAdviserListLoaded = true;
        });
      },
      sampleChange(id) {
        $scope.AssignedAdviserId = id;
      },
      getAllBroker() {
        generalService.getBrokerAll().then((response) => {
          $scope.brokersList = response.data;
          $scope.allocatedAdviserListLoaded = true;
        });
      },
      getAccounTypeNumber() {
        userService.GetUserInfo().then((response) => {
          $scope.accessType = response.data.AccessType;
          $scope.getAccountType();
        });
        // return accountTypeNumber ? accountTypeNumber : 'Error, no AccessType received.';
      },
      getAccountType() {
        if ($scope.accessType) {
          if (parseInt($scope.accessType, 10) === 2) { /* adviser */
            $scope.getAdvisersList($scope.brokerInfo.BrokerId);
          } else if (parseInt($scope.accessType, 10) === 1) {
            $scope.getAllBroker();
            $scope.getUserInfo();
          } else { /* admin */
            $scope.getUserInfo();
          }
        }
      },
      getUserInfo() {
        contactService.getUserInfo().then((response) => {
          $scope.userInfo = response.data;
          $scope.currentCountryId = response.data.CountryId || 0;
          $scope.currentUserName = response.data.FullName;

          if (parseInt($scope.accessType, 10) !== 1 && parseInt($scope.accessType, 10) !== 2) {
            $scope.getAdvisersDropdown();
          }
        });
      },
      getAdvisersDropdown() {
        corporateService.getAdvisersDropdown($scope.userInfo.FamilyId, 0, 0).then((getAdvisersDropResponse) => {
          if ($scope.accessType === 5) {
            $scope.allocatedAdviserList = _.filter(getAdvisersDropResponse.data, (data) => { return data !== ''; });
          } else {
            $scope.assistantAdvisersList = _.filter(getAdvisersDropResponse.data, (data) => { return data.FullName !== ''; });
            $scope.AssignedAdviserId = $scope.assistantAdvisersList[0].FamilyId;
            $scope.sampleChange($scope.AssignedAdviserId);
          }
          $scope.allocatedAdviserListLoaded = true;
        });
      },
      getAdvisersName(adviserList) {
        $scope.allocatedAdviserListName = adviserList.FullName;
      },
    });
    // for Existing Client functions
    angular.extend($scope, {
      initAdviserScopes() {
        if (_.isNull($scope.selectedAdviser) || _.isEmpty($scope.selectedAdviser) || _.isUndefined($scope.selectedAdviser))
          corporateService.getAdvisersDropdown(0, 0, 0).then((response) => {
            $scope.searchedAdvisersObject = response.data;
            $scope.searchedAdvisersObjectOriginal = response.data;
            $scope.selectedAdviser = $scope.searchedAdvisersObject[0] || null;
            $scope.searchAdviserText = $scope.selectedAdviser.FullName || null;
          });
      },
    });
    $scope.initAdviserScopes();
    $scope.dismissModal = function () {
      $uibModalInstance.dismiss();
    };
    $scope.searchContact = function () {
      $scope.selectedFamily = null;
      $timeout.cancel($scope.searchContactTimeout);
      $scope.searchContactTimeout = $timeout(() => {
        contactService.searchFamily($scope.searchContactText, $scope.selectedAdviser.FamilyId, true).then((response) => {
          $scope.getFamilyList = response.data.FamilyList;
        });
      }, 1000);
    };
    $scope.searchAdviser = function () {
      $scope.selectedAdviser = null;
      $timeout.cancel($scope.searchAdviserTimeout);
      $scope.searchedAdvisersObject = [];
      _.forEach($scope.searchedAdvisersObjectOriginal, (object) => {
        if (_.includes(_.toLower(object.FullName), _.toLower($scope.searchAdviserText))) {
          $scope.searchedAdvisersObject.push(object);
        }
      });
    };
    $scope.selectContact = function (obj) {
      $scope.searchContactText = obj.FamilyFullName;
      $scope.selectedFamily = obj;
      $scope.showContactList = false;
    };
    $scope.selectAdviser = function (object) {
      $scope.searchAdviserText = object.FullName;
      $scope.selectedAdviser = object;
      $scope.showAdviserList = false;
    };
    const createLoanApp = (fromCreateModal, FamilyID) => {
      if (!$scope.selectedFamily && _.isUndefined(fromCreateModal)) {
        toaster.pop('error', 'Error', 'Please select a contact');
        return false;
      } else if (!_.isUndefined(fromCreateModal)) {
        $scope.selectedFamily = {};
        $scope.selectedFamily.FamilyID = FamilyID;
      }
      // else here
      $scope.dismissModal();
      // toaster.pop('info', 'Please wait', 'Creating your Loan Application');
      // $rootScope.$broadcast('InlinePreloader', {preLoaderText:"Creating New Loan Application", preLoaderSize:"", showPreLoader:true})
      broadcastDataService.InlinePreloader = { preLoaderText: 'Creating New Loan Application', preLoaderSize: '', showPreLoader: true };

      $scope.dummyscenario =
          {
            LoanScenarioId: '0',
            AdviserId: $scope.selectedAdviser.FamilyId,
            AdviserName: $scope.selectedAdviser.FullName,
            Title: `Loan Application ${Math.random().toString(36).substring(20)}`,
            StatusName: 'ongoing',
            FamilyId: $scope.selectedFamily.FamilyID,
            AssignedLenderId: '0',
          };
      if (_.isUndefined(fromCreateModal) && _.isUndefined(FamilyID)) {
        loanScenarioService.scenarioSet($scope.dummyscenario).then((response) => {
          if (!$scope.isAdviserAccess) {
            $scope.userFamilyId = $scope.AssignedAdviserId;
          }
          loanAppDetailsService.setInsuranceConcierge(response.data, $scope.selectedFamily.FamilyID, $scope.userFamilyId);
          toaster.pop('success', 'Created', 'Loan application successfully created');
          $state.go('app.loanApplicationDetailsV3', { familyId: $scope.selectedFamily.FamilyID, loanAppId: response.data });
          $rootScope.$on('$stateChangeSuccess', () => {
            broadcastDataService.InlinePreloader = { preLoaderText: '', preLoaderSize: '', showPreLoader: false };
          });
        });
      }
    };
    $scope.CreateDummyLoanScenario = function (fromCreateModal, FamilyID) {
      if ($scope.selectedFamily) {
        generalService.isClientNotCorpLockedOut($scope.selectedFamily.FamilyID).then((res) => {
          if (!res.data) {
            swal('Security Restriction', 'Access to this record is restricted for Corporate users', 'error');
          } else {
            createLoanApp(fromCreateModal, FamilyID);
          }
        });
      } else {
        createLoanApp(fromCreateModal, FamilyID);
      }
    };
    $scope.getNewContactModel = function (role) {
      return {
        FamilyId: '0',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        PreferredName: '',
        Title: '',
        Gender: '',
        DOB: '',
        Role: role,
        Employment: [
          {
            Occupation: '',
            OccupationClass: 0,
            Employer: '',
          },
        ],
        SmokerStatus: '',
        Deceased: false,
        DOBReminder: false,
        BestTimeToCall: '',
        IsResident: true,
        Phone: [
          {
            Type: 'Mobile',
            Number: '',
          },
        ],
        Email: [
          {
            Type: 'Email',
            EmailAddress: '',
          },
        ],
        Notes: '',
        CountryCode: applicationSharedService.countryCodeAndBirthday($rootScope.crmCountry),
        CountryOfBirth: applicationSharedService.countryCodeAndBirthday($rootScope.crmCountry),
        ResidencyId: '',
        MaritalStatusId: '',
        PreferredContactMethodId: '',
        customerConsentOptions: { ConsentMarketing: true },
        isIncluded: true,
        IsCustomerOfLender: false,
        IsEmployeeOfLender: false,
      };
    };
    optionsService.MaritalStatusGet()
      .then((response) => {
        $scope.maritalStatus = response.data;
      }, () => {
        $scope.maritalStatus = [];
      });
    optionsService.ResidencyStatusGet()
      .then((response) => {
        $scope.residencyStatus = response.data;
      }, () => {
        $scope.residencyStatus = [];
      });
    optionsService.countryListGet()
      .then((response) => {
        $scope.countryList = response.data;
      }, () => {
        $scope.countryList = [];
      });
    optionsService.PreferredContactMethodGet()
      .then((response) => {
        $scope.preferredContactMethod = response.data;
      }, () => {
        $scope.preferredContactMethod = [];
      });
    // Add Person Modal
    $scope.personModal = function (type = 1, person) {
      // for applicants as we don't support any other types here

      $uibModal.open({
        templateUrl: '/assets/views/modals/person.add.html',
        controller: 'AddPersonModalCtrl',
        scope: $scope,
        size: 'lg',
        resolve: {
          selectedPartyType() {
            return type.toString();
          },
          person() {
            return person;
          },
          allocatedAdviserList() {
            return $scope.allocatedAdviserList;
          },
          requester() {
            return 0;
          },
          requesterLabel() {
            return 'Applicant';
          },
          assistantAdvisersList() {
            return $scope.assistantAdvisersList;
          },
          AssignedAdviserId() {
            return $scope.AssignedAdviserId;
          },
          accessType() {
            return $scope.accessType;
          },
          userFamilyId() {
            return $scope.userFamilyId;
          },
        },
        backdrop: 'static',
        keyboard: false,
      });
    };
    $scope.addExistingClient = () => {
      $scope.isExistingMode = true;
      return $scope.isExistingMode;
    };
  });
