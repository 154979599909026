import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('LenderRatesModalCtrl', function LenderRatesModalCtrl(
  $scope,
  $stateParams,
  $uibModalInstance,
  lenderList,
  repayObj,
  loanCalculatorSharedData) {
  $scope.selectedRateType = '0';
  $scope.lenderLength = lenderList && lenderList.length ? lenderList.length - 1 : 0;
  $scope.lenderList = lenderList;

  $scope.repayObj = repayObj;
  $scope.setRate = function (rate) {
    let r;
    if (parseInt(rate, 10) < 0 || parseInt(rate, 10) === 0)
      return;
    r = rate;
    r = r.toFixed(2);
    $scope.repayObj.r = r;
    $uibModalInstance.dismiss('cancel');
  };

  function getRateName(obj) {
    return obj.RateTypeName.replace(/\s/g, '');
  }

  function getFieldsList(obj) {
    const scopeNames = [];

    _.forEach(obj, (lenders) => {
      _.forEach(lenders.rates, (rate) => {
        let displayPosition;
        const scopeName = getRateName(rate);

        const result = _.filter(scopeNames, function scopeNamesFn(s) {
          return s.scopeName === scopeName;
        });

        if (result.length === 0) {
          displayPosition = 1;

          if (scopeName === 'StandardVariable' || scopeName === 'BasicVariable' || scopeName === 'Floating') {
            displayPosition = 0;
          } else if (scopeName === 'Fixed10years') {
            displayPosition = 2;
          }

          if (scopeName !== 'Fixed0years') {
            scopeNames.push({
              scopeName,
              RateTypeName: rate.RateTypeName,
              displayPosition,
            });
          }
        }
      });
    });

    return scopeNames;
  }

  function getFixedRate(rateTypeName, obj) {
    let rate;

    _.forEach(obj.rates, (o) => {
      if (!_.isUndefined(rate)) {
        return;
      }
      if (o.RateTypeName === rateTypeName) {
        rate = o.Rate;
      }
    });

    return rate;
  }

  $scope.construct = function () {
    $scope.rateTypeNames = _.orderBy(getFieldsList($scope.lenderList), ['displayPosition', 'scopeName'], ['asc']);

    _.forEach($scope.rateTypeNames, (scopename) => {
      $scope[scopename.scopeName] = [];
    });

    for (let i = 0; i < $scope.lenderList.length; i++) {
      if ($scope.lenderList[i].rates.length === 0) {
        $scope.lenderList[i].hiddenInInterestTable = true;
      } else {
        _.forEach($scope.rateTypeNames, (scopename) => {
          $scope[scopename.scopeName].push({
            providerID: $scope.lenderList[i].ProviderID,
            floatingRate: getFixedRate(scopename.RateTypeName, $scope.lenderList[i]),
          });
        });
      }
    }

    $scope.dataReady = true;
  };

  $scope.getValues = function (obj) {
    return $scope[obj];
  };

  $scope.getLenderRates = loanCalculatorSharedData.getLenderRates;
  $scope.filterLenderList = loanCalculatorSharedData.filterLenderList;

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };


  $scope.done = function () {
    $uibModalInstance.dismiss('cancel');
  };

  function initModal() {
    $scope.getLenderRates();
  }

  initModal();
});
