/* global $ */
import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { assetFinanceIgnore } from 'Common/utilities/security';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { toastError } from 'Common/utilities/alert';

angular.module('app').controller('LoanAppLoanFundingDetailsCtrl',
  function LoanAppLoanFundingDetailsCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    watcherService,
    timeoutService,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    optionsService,
    SweetAlert,
    loanAppSharedData,
    fundingCalculatorService,
    loanProfilerService,
    $document,
    $anchorScroll,
    $location,
    broadcastDataService,
    uiService,
    fundingCalculatorSharedData,
    loanAppDetailsService,
    configService
  ) {
    $scope.clickCounter = 0;
    $scope.uiService = uiService;
    const LOAN_TERM = '30';
    optionsService.countryListGet().then((response) => {
      $scope.countryList = response.data;
    });
    $scope.productDiscountRate = configService.feature.productDiscountRate;

    loanApplicationServices.loanSummaryGet($state.params.loanAppId).then(() => {
      $scope.loanAppSharedData = loanAppSharedData;
      $scope.loanAppSharedData.fundingSummary = loanAppSharedData.loanAppSummary.FundingSummary;

      const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.LOAN_FUNDING_DETAILS);

      $scope.processLoanFundingDetailsSection = () => {
        if ($scope.clickCounter !== 0) return;

        $scope.clickCounter++;

        $scope.assetFinanceIgnore = assetFinanceIgnore;
        /*
            Below are for Security
            @NOTE : We'll use security.js for this
          */

        /*
            Below are for fees
          */
        const lenderPlace = 1;
        angular
          .extend($scope, {
            LoanApplicationCQPFeeStructureGet: [],
            FeesSetting: {
              displayFeesForm: false,
            },
            selectCQPFreq: ['Weekly', 'Fortnightly', 'Monthly'],
            LoanDetailsSet: {},
            selectedLender: '0',
            facility: {
              FavouriteProductData: [
                { SelectedProduct: [] },
              ],
            },
          });

        angular
          .extend($scope, {
            getLoanApplicationCQP: () => {
              loanScenarioService.CQPCommissionInfoGet($scope.loanAppId).then((response) => {
                $scope.CQP.CQPGet = response.data;
                if (!angular.isUndefined($scope.CQP.CQPGet.LenderID)) {
                  $scope.CQP.CQPGet.LenderID = $scope.CQP.CQPGet.LenderID.toString();
                  $scope.LoanDetailsSet.Lender = $scope.CQP.CQPGet.LenderID.toString();
                } else {
                  $scope.CQP.CQPGet.LenderID = '';
                  $scope.LoanDetailsSet.Lender = '';
                }
              });
            },
            getLoanApplicationCQPFeeStructure: () => {
              loanScenarioService.getLoanApplicationCQPFeeStructure($scope.loanAppId).then((response) => {
                $scope.LoanApplicationCQPFeeStructureGet = response.data;
              });
            },
            showAddEditStructureFeesForm: (action, obj, platform) => {
              $scope.FeesSetting.displayFeesForm = !$scope.FeesSetting.displayFeesForm;
              if (platform === 'desktop') {
                if (action === 'edit') {
                  obj.isEditing = !obj.isEditing;
                  $scope.isFeeStructureEditingMode = true;

                  angular.extend($scope.CQP.FeeStructure, obj);
                  $scope.CQP.FeeStructure.FeeTypeID = $scope.CQP.FeeStructure.FeeTypeID.toString();
                } else if (action === 'cancel') {
                  const editingObj = _.find($scope.LoanApplicationCQPFeeStructureGet, 'isEditing');
                  if (editingObj)
                    editingObj.isEditing = false;

                  $scope.isFeeStructureEditingMode = false;
                  $scope.CQP.FeeStructure = $scope.initFeeStructure();
                  $scope.FeesSetting.displayFeesForm = false;
                }
              } else if (platform === 'mobile') {
                obj.isEditing = !obj.isEditing;
              }
            },
            initFeeStructure: () => {
              return {
                LoanApplicationID: $scope.loanAppId,
                LoanApplicationCQPFeeID: 0,
                FeeTypeID: 0,
                Amount: null,
              };
            },
            FeesInit: () => {
              $scope.CQP = {
                FeeStructure: $scope.initFeeStructure(),
              };
              // lender
              contactService.lenderListGet(undefined, lenderPlace).then((response) => {
                $scope.lenderListGet = response.data;
                $scope.CQP.LenderLogo = '';
              });

              $scope.getIsRefinance = () => {
                if ($scope.loanDetailsSet) {
                  $scope.loanDetailsSet.IsRefinance = loanAppSharedData.isRefinance;
                }
                return loanAppSharedData.isRefinance;
              };

              // Loan Application
              $scope.getLoanApplicationCQP();

              $scope.getLoanApplicationCQPFeeStructure();

              $scope.FundingCalculatorDetails = {};
              $scope.hasFundingCalc = false;
              // Funding Calculator
              loanScenarioService.linkedFundingCalculatorGet($scope.loanAppId).then((linked) => {
                const brokerEventId = linked.data;
                if ((brokerEventId)) {
                  loanScenarioService.fundingCalculatorDetailsGet(brokerEventId).then((response) => {
                    $scope.FundingCalculatorDetails = response.data;
                    $scope.hasFundingCalc = true;
                  });
                }
              });
            },
            submitNewStructureFees: (form, advancedOptions, platform, obj) => {
              if (form.$valid) {
                if (platform === 'mobile') {
                  obj.Amount = parseInt(obj.Amount, 10);
                  obj.LoanApplicationID = $scope.loanAppId;
                }

                loanScenarioService.setLoanApplicationCQPFeeStructure(platform === 'desktop' ? $scope.CQP.FeeStructure : obj).then((response) => {
                  if (response.data) {
                    $scope.getLoanApplicationCQPFeeStructure();

                    if (platform === 'desktop')
                      $scope.showAddEditStructureFeesForm('cancel', {}, 'desktop');
                    else
                      obj.isEditing = false;
                  } else
                    toaster.pop('error', 'Error', 'Error Adding Fee Structure.');
                });
              }
            },
            deleteStructure: (type, id) => {
              SweetAlert.swal({
                title: 'Are you sure?',
                text: `This record will be removed from your ${type === 'FeeStructure' ? 'Fee' : 'Referral Fee Structure'}`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: true,
              }, (confirm) => {
                if (confirm) {
                  if (type === 'FeeStructure') {
                    loanScenarioService.deleteLoanApplicationCQPFeeStructure($scope.loanAppId, id).then(() => {
                      $scope.getLoanApplicationCQPFeeStructure();
                    });
                  } else {
                    loanScenarioService.deleteLoanApplicationCQPReferralFeeStructure($scope.loanAppId, id).then(() => {
                      $scope.getLoanApplicationCQPReferralFeeStructure();
                    });
                  }
                }
              });
            },
          });
        // caller
        $scope.FeesInit();


        /**
         * Below are for funds available/deposit
         */
        angular
          .extend($scope, {
            fundsAvailable: [],
          });

        angular
          .extend($scope, {
            getFundingCalculatorFundsSummary: () => {
              if (!$scope.securityBrokerEventId)
                return false;

              fundingCalculatorService.getFundingCalculatorFundsSummary($scope.securityBrokerEventId, $scope.loanAppId).then((summary) => {
                angular.extend($scope.loanAppSharedData.fundingSummary, summary);
              });
            },
            getFundsAvailable: () => {
              // Let's get the funds first for easier object manipulation
              fundingCalculatorService.getFundingCalculatorFunds($scope.securityBrokerEventId).then((fundsResult) => {
                // These funds will be used after parameters, using FUNDING_REQUIRED FundingType
                const fundsData = fundsResult;
                // remove the funds available types
                _.remove(fundsData, (fund) => {
                  return fund.fundingType === 'FUNDING_REQUIRED';
                });

                $scope.fundsAvailable = fundsData;
                $scope.fundingDownpayment = fundsData && fundsData.length ? fundsData[0] : {};

                $rootScope.$broadcast('REFRESH_OBJECTIVES_DEPOSIT_SECTION', { fundsAvailable: $scope.fundsAvailable, isNew: false });
              });
            },
            saveFundsDownpayment: () => {
              $timeout.cancel($scope.paymentDetailsSetTimeout);
              $scope.paymentDetailsSetTimeout = $timeout(() => {
                const postObj = {
                  brokerEventId: $scope.securityBrokerEventId,
                  fundsId: $scope.fundingDownpayment.id || 0,
                  fundingType: 'FUNDS_AVAILABLE',
                  fundingParticulars: 'Deposit Paid',
                  fundingValue: $scope.fundingDownpayment.fundingValue,
                  description: '',
                };
                fundingCalculatorService.setFundingCalculatorFunds(postObj)
                  .then(() => {
                    $scope.getFundsAvailable();
                    $scope.loanDetailsSetMethod();
                    $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                  });
              }, 2000);
            },
            saveFundsAvailable: (fund) => {
              const obj = {
                brokerEventId: $scope.securityBrokerEventId,
                fundsId: fund.fundsId,
                fundingCalParametersId: fund.fundingCalParametersId,
                fundingType: 'FUNDS_AVAILABLE',
                fundingParticulars: fund.name,
                fundingValue: fund.value,
                isOnlyValueEditable: false,
              };

              fundingCalculatorService.setFundingCalculatorFunds(obj).then(() => {
                // Saved
                $scope.getFundsAvailable();
                $scope.loanDetailsSetMethod();
                $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
              });
            },
            fundsAvailableInit: () => {
              loanScenarioService.getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId).then((loanFundingCalcBrokerEventResponse) => {
                $scope.securityBrokerEventId = loanFundingCalcBrokerEventResponse.data;
                $scope.getFundsAvailable();
              });
            },
          });
        $scope.fundsAvailableInit();

        const onRefreshFundsAvailableListener = $rootScope.$on('REFRESH_FUNDS_AVAILABLE_SECTION', (e, args) => {
          if (!args.isNew && args.fundsAvailable)
            $scope.fundsAvailable = args.fundsAvailable;
          else
            $scope.getFundsAvailable();
        });
        $scope.$on('$destroy', () => {
          if (onRefreshFundsAvailableListener) onRefreshFundsAvailableListener();
        });

        $scope.validateLoanDetails = () => {
          if ($scope.loanDetailsSet)
            $scope.isValidLoanDetails = $scope.loanDetailsSet.LoanFacility.length > 0 && $rootScope.loanAppSecurityListCount > 0;
          else
            $scope.isValidLoanDetails = false;

          loanAppSharedData.completionStatusSet(SECTION_ID.LOAN_FUNDING_DETAILS, sectionObject.IsCompleted, $scope.isValidLoanDetails);
        };


        const loanDetailsSecurityWatcher = $scope.$watchGroup(['loanDetailsSet', 'loanAppSecurityListCount'], () => {
          if ($scope.loanDetailsSet) {
            loanAppSharedData.lenderId = $scope.loanDetailsSet.LenderId;
          }
          $scope.validateLoanDetails();
        }, true);
        watcherService.addWatcher(loanDetailsSecurityWatcher, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);

        const loanDetailsSecurityCountWatcher = $scope.$watchGroup(['loanDetailsSet.LenderId', 'loanAppSecurityListCount'], (newVal, oldVal) => {
          $timeout.cancel($scope.generateLoanFacilityTimeout);
          $scope.generateLoanFacilityTimeout = $timeout(() => {
            if ($rootScope.crmCountry === 'New Zealand' &&
                newVal[1] > 0 &&
                ($scope.loanAppSecurityListCount) &&
                newVal[0] &&
                (newVal[0] !== oldVal[0]) &&
                _.isEmpty($scope.loanDetailsSet.LoanFacility) &&
                $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined) {
              const totalLendingRequired = $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined;

              broadcastDataService.addLoanstructureShared($stateParams.loanAppId, $stateParams.familyId, totalLendingRequired).then(() => {
                $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                $scope.loanDetailsGet();
                $scope.updateLoanDetailsField();
                $scope.validateLoanDetails();
              });
            }
          }, 2000);
          timeoutService.addTimeout($scope.generateLoanFacilityTimeout, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
        });
        watcherService.addWatcher(loanDetailsSecurityCountWatcher, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);

        // extend models
        angular
          .extend($scope, {
            loanAppId: $stateParams.loanAppId,
            DepositDetails: {
              LoanApplicationId: $scope.loanAppId,
              LoanApplication_DepositId: 0,
            },
            DepositTypeList: [],
            getTotalSavings: 0,
          });


        // extend methods
        angular
          .extend($scope, {
            /**
                     * Validates deposits section
                     */
            validateDeposits: () => {
              $scope.isValidDeposits = _.size($scope.DepositDetails.DepositBreakDown) > 0;
              $scope.getDepositTotalSavings();
            },
            DepositGet: () => {
              loanApplicationServices.getLoanDeposit($scope.loanAppId)
                .then((loanDepositReponse) => {
                  angular.extend($scope.DepositDetails, loanDepositReponse.data);
                });
            },

            deleteFund: (fundsId) => {
              SweetAlert.swal({
                title: 'Are you sure?',
                text: 'This record will be removed from your fund list',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: false,
              }, (confirm) => {
                if (confirm) {
                  fundingCalculatorService.deleteFundingCalculatorFunds(fundsId).then(() => {
                    const deleteFundTimeout = $timeout(() => {
                      $scope.getFundsAvailable();
                      $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                      SweetAlert.swal({
                        title: 'Success',
                        text: 'Fund has been been successfully deleted.',
                        type: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                      });
                    }, 100);

                    timeoutService.addTimeout(deleteFundTimeout, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
                  });
                }
              });
            },
            DepositInit: () => {
              // todo, uncomment this if api is ready
              loanApplicationServices.getDepositType()
                .then((response) => {
                  $scope.DepositTypeList = response.data;
                });

              $scope.DepositGet();
            },
            getDepositTotalSavings: () => {
              $scope.getTotalSavings = _.sum(_.map($scope.DepositDetails.DepositBreakDown, 'TotalAmount'));
            },

            showAddDepositModal: (type, fundObj) => {
              $uibModal.open({
                templateUrl: '/assets/views/loanApplication/clientAssess/contributions/modal/add.html',
                controller: ($uibModalInstance, modalType, fund) => {
                  $scope.modalType = modalType;
                  $scope.Title = (modalType === 'new' ? 'Add' : 'Edit');
                  $scope.SaveTitle = (modalType === 'new' ? 'Add' : 'Save');

                  optionsService.DepositType().then((response) => {
                    $scope.DepositSourceList = response.data;
                  });

                  $scope.FundingSet = {
                    brokerEventId: $scope.securityBrokerEventId,
                    id: 0,
                    fundingType: 'FUNDS_AVAILABLE',
                    fundingParticulars: '',
                    fundingValue: 0,
                    isOnlyValueEditable: false,
                    description: '',
                  };

                  if (modalType === 'edit') {
                    angular.extend($scope.FundingSet, fund);

                    $scope.FundingSet.fundingParticulars = _.clone($scope.FundingSet.fundingParticulars);

                    $scope.FundingSet = _.omit($scope.FundingSet, 'FundingParticulars');
                  }

                  $scope.modalSaveFunds = () => {
                    let setObj = {};
                    if (modalType === 'edit') {
                      setObj = {
                        brokerEventId: $scope.FundingSet.brokerEventId,
                        fundsId: $scope.FundingSet.id,
                        fundingType: $scope.FundingSet.fundingType,
                        fundingParticulars: $scope.FundingSet.fundingParticulars,
                        fundingValue: $scope.FundingSet.fundingValue,
                        isOnlyValueEditable: $scope.FundingSet.isOnlyValueEditable,
                        description: $scope.FundingSet.description,
                      };
                    } else {
                      setObj = _.clone($scope.FundingSet);
                    }

                    fundingCalculatorService.setFundingCalculatorFunds(setObj).then(() => {
                      // Saved
                      $scope.getFundsAvailable();
                      $scope.loanDetailsSetMethod();
                      $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                      $scope.cancel();
                    });
                  };

                  $scope.cancel = () => {
                    $uibModalInstance.dismiss('cancel');
                  };
                },
                size: 'sm',
                scope: $scope,
                resolve: {
                  modalType: () => {
                    return type;
                  },
                  fund: () => {
                    return (type === 'edit' ? fundObj : {});
                  },
                },
              });
            },
            selectFund: (fund) => {
              $scope.showAddDepositModal('edit', fund);
            },
          });

        // caller
        $scope.DepositInit();

        /**
             *  Validates every update on object
             */
        const depositDetailsWatcher = $scope.$watch('DepositDetails', () => {
          $scope.validateDeposits();
        }, true);
        watcherService.addWatcher(depositDetailsWatcher, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);

        /*
              Below are for Proposed Lending modules
            */
        // extend models
        angular
          .extend($scope, {
            ProposedLendingSet: {},
          });

        // extend methods
        angular
          .extend($scope, {
            loanDetailsGet: () => {
              loanScenarioService.loanDetailsGet($scope.loanAppId).then((response) => {
                $scope.loanDetailsSet = (response && response.data) || {};
                /*
                        Below codes are temporary
                      */
                _.map($scope.loanDetailsSet.LoanFacility, (o) => {
                  o.SelectedProductId = null;
                  return o;
                });

                $scope.loanDetailsSet.LoanScenarioId = $scope.loanAppId;
                if ($scope.loanDetailsSet.LoanAmount < 1) {
                  $scope.loanDetailsSet.LoanAmount = null;
                }

                if ($scope.loanDetailsSet.LenderId && !_.isUndefined($scope.loanDetailsSet.LenderId)) {
                  $scope.loanDetailsSet.LenderId = String($scope.loanDetailsSet.LenderId);
                  $rootScope.loanAppLenderDetailObject = {
                    LenderId: $scope.loanDetailsSet.LenderId,
                    LenderName: $scope.loanDetailsSet.LenderName,
                    OtherLenderName: $scope.loanDetailsSet.OtherLenderName,
                    IsOnlineAvailable: $scope.loanDetailsSet.IsOnlineAvailable,
                  };
                } else {
                  $scope.loanDetailsSet.LenderId = null;
                  $rootScope.loanAppLenderDetailObject = {
                    LenderId: null,
                    LenderName: '',
                    IsOnlineAvailable: null,
                  };
                }
                if ($scope.loanDetailsSet.ABSPurposeId && !_.isUndefined($scope.loanDetailsSet.ABSPurposeId)) {
                  $scope.loanDetailsSet.ABSPurposeId = String($scope.loanDetailsSet.ABSPurposeId);
                } else {
                  $scope.loanDetailsSet.ABSPurposeId = null;
                }

                // Set ReasonForSelectedLender
                if (_.isUndefined($scope.loanDetailsSet.ReasonForSelectedLender)) {
                  $scope.loanDetailsSet.ReasonForSelectedLender = null;
                }
                // Set ReasonForLoanFacility
                if (_.isUndefined($scope.loanDetailsSet.ReasonForLoanFacility)) {
                  $scope.loanDetailsSet.ReasonForLoanFacility = null;
                }

                const forEachCounter = [];

                // Get the favorite products of each facility
                _.forEach($scope.loanDetailsSet.LoanFacility, (facility) => {
                  facility.FavouriteProductData = [];
                  facility.SelectedFavouriteProductData = {};
                  facility.TotalFees = 0;
                  if (!facility.BrokerEventId)
                    return;
                  loanProfilerService.getSelectedProductDetailsGetByLoanStructure(facility.BrokerEventId).then((productResponse) => {
                    facility.FavouriteProductData = productResponse.data;
                    if (typeof facility.FavouriteProductData[0] !== 'undefined') {
                      angular.forEach(facility.FavouriteProductData[0].SelectedProduct, (item) => {
                        if (item.IsRecommended) {
                          facility.SelectedFavouriteProductData = item;
                          if (facility.SelectedFavouriteProductData.NewInitialRate === 0) {
                            facility.SelectedFavouriteProductData.NewInitialRate = '';
                          }
                          if (facility.SelectedFavouriteProductData.NewOngoingRate === 0) {
                            facility.SelectedFavouriteProductData.NewOngoingRate = '';
                          }
                          loanScenarioService.LenderFeesGet(item.ProductID, facility.BrokerEventId, facility.FavouriteProductData[0].LoanStructureID, $scope.loanAppId).then((lenderFeesResponse) => {
                            facility.SelectedFavouriteProductData.FeesList = lenderFeesResponse.data;
                            loanAppSharedData.SAVE_FUNDING_PARAMETER = {
                              fundsId: 0,
                              fundingCalParametersId: 0,
                              name: 'Lenders Fees',
                              value: facility.SelectedFavouriteProductData.FeesList.TotalFee,
                            };
                          });
                        }
                      });
                    }
                    forEachCounter.push(productResponse);

                    if (forEachCounter.length === $scope.loanDetailsSet.LoanFacility.length) {
                      // foreach work done
                      // Save LoanStructure product to be use when loan app change into existing loan
                      // commented out. Please dont delete yet
                      // $scope.saveLoanStructureProduct();  ~ josandi
                    }
                  });
                });

                $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                loanAppSharedData.loanAppSummary.LoanDetails = { ...loanAppSharedData.loanAppSummary.LoanDetails, ...$scope.loanDetailsSet };
              });
            },
            setTBA: (facility) => {
              loanScenarioService.LoanFacilityTBASet($scope.loanAppId, facility.LoanStructureId, facility.IsTBA).then(() => {
              });
            },
            loanDetailsSetMethod: (message, isOverrideTotalProposedLendingUserDefined) => {
              let timeoutValue = 0;
              timeoutValue = isOverrideTotalProposedLendingUserDefined ? 2000 : 0;

              $timeout.cancel($scope.detailsSetTimeout);
              $scope.detailsSetTimeout = $timeout(() => {
                if ($scope.loanDetailsSet && !$scope.loanDetailsSet.ABSPurposeId) {
                  $scope.loanDetailsSet.ABSPurposeId = null;
                }
                const loanDetailsParam = _.omit($scope.loanDetailsSet, 'LoanFacility');
                loanDetailsParam.TotalProposedLendingUserDefined = $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined;
                loanDetailsParam.StopAutoCalc = isOverrideTotalProposedLendingUserDefined ? true : loanDetailsParam.StopAutoCalc;
                loanDetailsParam.LMI = loanDetailsParam.IsLMI ? loanDetailsParam.LMI : 0;
                loanScenarioService.loanDetailsSet(loanDetailsParam).then(() => {
                  $scope.loanDetailsGet();
                });
              }, timeoutValue);

              timeoutService.addTimeout($scope.detailsSetTimeout, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
            },
            lmiSetMethod: () => {
              $scope.loanDetailsSet.LMI = $scope.loanDetailsSet && $scope.loanDetailsSet.IsLMI ? $scope.loanDetailsSet.LMI : 0;
              const loanDetailsParam = _.omit($scope.loanDetailsSet, 'LoanFacility');
              loanScenarioService.loanDetailsSet(loanDetailsParam);
            },
            deleteFacility: (loanFacilityId) => {
              loanScenarioService.loanFacilityDelete($scope.loanAppId, loanFacilityId).then(() => {
                SweetAlert.swal({
                  title: 'Are you sure?',
                  text: 'This record will be removed from your loan facility',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes, remove it!',
                  closeOnConfirm: true,
                }, (confirm) => {
                  if (confirm) {
                    $scope.loanDetailsGet();
                  }
                });
              });
            },
            updateLoanDetailsField: (type, lenderId, otherLenderName) => {
              $scope.getLoanApplicationCQP();
              if (lenderId) {
                $scope.loanDetailsSet.LenderId = lenderId;
                $scope.loanDetailsSet.OtherLenderName = otherLenderName;
                loanAppSharedData.lenderId = lenderId;
                loanAppSharedData.otherLenderName = otherLenderName;
                const hasLoanAppSummaryDetails = loanAppSharedData.loanAppSummary && loanAppSharedData.loanAppSummary.LoanDetails;
                if (hasLoanAppSummaryDetails) {
                  const { LenderName } = $scope.loanDetailsSet;
                  const LoanDetails = {
                    LenderId: lenderId,
                    OtherLenderName: otherLenderName,
                    LenderName,
                  };
                  loanAppSharedData.loanAppSummary.LoanDetails = { ...loanAppSharedData.loanAppSummary.LoanDetails, ...LoanDetails };
                }
              }
              $scope.loanDetailsSetMethod(`Successfully set ${type}`);
            },
            CQPSet: () => {
              contactService.setLoanApplicationCQP($scope.loanAppId, $scope.LoanDetailsSet.Lender).then((response) => {
                if (response) {
                  $scope.getLoanApplicationCQP();
                } else {
                  toaster.pop('error', 'Error', 'Something went wrong');
                }
              });
            },
            loanDetailsInit: () => {
              $scope.listLoanFacilities = [];
              $scope.loanDetailsGet();
              contactService.lenderListGet(true, lenderPlace).then((response) => {
                $scope.lenderList = response.data;
              });

              optionsService.absPurpose().then((response) => {
                $scope.absPurposeList = response.data;
              });
            },
            changeSelectedLenderValue: (lender) => {
              $scope.selectedLender = lender;
            },
            getSearchProduct: (searchString) => {
              if (searchString.length >= 3) {
                return loanProfilerService.getServiceabilityProductSearch(searchString).then((response) => {
                  $scope.SearchProductList = response.data;
                  $scope.productSearchList = [];
                  angular.forEach(response.data, (item) => {
                    item.FullName = `${item.FirstName} ${item.LastName}`;
                    if ($scope.selectedLender !== 0 && typeof $scope.selectedLender !== 'undefined') {
                      if (item.LenderID === $scope.selectedLender)
                        $scope.productSearchList.push(item);
                    } else {
                      $scope.productSearchList.push(item);
                    }
                  });
                  return $scope.productSearchList;
                });
              }

              $scope.productSearchList = [];
              return $scope.productSearchList;
            },
            setFavoriteProductFromSearch: (facility, product) => {
              product.isAddedForComparison = true;
              const postData = {
                brokerEventID: facility.BrokerEventId,
                ProductID: product.ProductID,
                LenderID: product.LenderID,
                loanStructureID: facility.FavouriteProductData[0].LoanStructureID,
                IsFavourite: product.isAddedForComparison,
              };
              loanProfilerService
                .setFavouriteProduct(postData)
                .then((response) => {
                  if (response.data === 1) {
                    $scope.getFacilityFavoriteProductList(facility);
                  } else {
                    toastError();
                  }
                });
            },
            openAddLoanStructureModal: (loanStructureId) => {
              $scope.loanStructureId = loanStructureId;
              $scope.loanFacilityLmiValue = ($scope.loanDetailsSet && $scope.loanDetailsSet.LMI) || 0;
              $uibModal.open({
                templateUrl: '/assets/views/loanApplication/clientAssess/modal/newLoanStructureModal.html',
                controller($uibModalInstance, selectOptionsService) {
                  $scope.loanProductList = [];
                  $scope.cancel = () => {
                    $uibModalInstance.dismiss('cancel');
                  };

                  selectOptionsService.getLoanTerm().then((response) => {
                    $scope.loanTermSelectOptions = response.data;
                  });

                  optionsService.getLoanTerms().then((loanTermResponse) => {
                    $scope.loanTermList = loanTermResponse;
                  });

                  optionsService.FinancialFrequency().then((response) => {
                    $scope.loanRepaymentFreqSelectOptions = response.data;
                  });

                  $scope.LoanStructureSet = {
                    ProductId: 0,
                    InterestOnly: false,
                  };
                  $scope.LoanStructureSet.LoanStructureId = typeof $scope.loanStructureId === 'undefined' ? 0 : $scope.loanStructureId;

                  if ($scope.loanDetailsSet) {
                    $scope.LoanStructureSet.loanScenarioId = $scope.loanDetailsSet.LoanScenarioId;
                  }

                  if ($scope.loanFacilityLmiValue) {
                    $scope.LoanStructureSet.LMIPremium = $scope.loanFacilityLmiValue;
                  }
                  // For Interest Only switch selection Change
                  $scope.ChangeInterestOnlySelection = () => {
                    if (uiService.isCountry('New Zealand')) {
                      return;
                    }

                    if ($scope.loanStructureId !== 0 && typeof $scope.loanStructureId !== 'undefined') {
                      SweetAlert.swal({
                        title: 'Are you sure?',
                        text: 'All saved product will be removed from your list',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#F68F8F',
                        confirmButtonText: 'Yes, remove it!',
                        closeOnConfirm: false,
                      }, (confirm) => {
                        if (confirm) {
                          swal('Deleted', 'Saved product will be deleted after update loan facility.', 'success');
                        } else {
                          $scope.LoanStructureSet.InterestOnly = !$scope.LoanStructureSet.InterestOnly;
                        }
                      });
                    }
                  };

                  $scope.getLoanFacility = () => {
                    loanScenarioService.loanFacilityGet($scope.loanAppId, $scope.loanStructureId).then((response) => {
                      $scope.LoanStructureSet = response.data;
                      $scope.LoanStructureSet.StatementCycleId = $scope.LoanStructureSet.StatementCycleId || 4;

                      $scope.LoanStructureSet.LoanTerm = $scope.LoanStructureSet.LoanTerm;
                      $scope.LoanStructureSet.InterestOnlyTerm = String($scope.LoanStructureSet.InterestOnlyTerm);
                      $scope.LoanStructureSet.loanScenarioId = $scope.loanDetailsSet.LoanScenarioId;
                      if ($scope.loanFacilityLmiValue) {
                        $scope.LoanStructureSet.LMIPremium = $scope.LoanStructureSet.LMIPremium ? $scope.LoanStructureSet.LMIPremium : $scope.loanFacilityLmiValue;
                      }
                      $scope.baseAndLMIChange(false, true);
                      // select the owner
                      _.each($scope.ownersList, (owner) => {
                        owner.ticked = false;
                        const ownerData = _.find($scope.LoanStructureSet.Owner, (obj) => {
                          return owner.BorrowerID === obj.BorrowerID;
                        });
                        if (ownerData) {
                          owner.ticked = true;
                        }
                      });
                    });
                  };

                  $scope.LoanStructureSet.loanScenarioId = $scope.loanDetailsSet.LoanScenarioId;
                  $scope.baseAndLMIChange = (isCapitaliseChanged, onload) => {
                    $scope.saveLmiToFundingParms = false;
                    if (!isCapitaliseChanged && $scope.LoanStructureSet.IsAddLMI && !onload) {
                      $scope.LoanStructureSet.CapitaliseLMI = true;
                    }
                    if ($scope.LoanStructureSet.CapitaliseLMI && $scope.LoanStructureSet.IsAddLMI) {
                      $scope.TotalLoanAmountCalculation = _.parseInt($scope.LoanStructureSet.LMIPremium || 0) + _.parseInt($scope.LoanStructureSet.BaseLoanAmount || 0);
                      if (!onload) {
                        swal({
                          title: 'Are you sure?',
                          text: `Would you like this additional LMI amount of $${$scope.LoanStructureSet.LMIPremium} to be added to the customers funding required?`,
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#F68F8F',
                          confirmButtonText: 'Yes',
                          closeOnConfirm: true,
                        }, (confirm) => {
                          if (confirm) {
                            $scope.saveLmiToFundingParms = true;
                          }
                        }
                        );
                      }
                    } else {
                      $scope.TotalLoanAmountCalculation = _.parseInt($scope.LoanStructureSet.BaseLoanAmount);
                    }
                  };

                  $scope.addLoanStructure = () => {
                    $scope.LoanStructureSet.Owner = _.filter($scope.ownersList, (obj) => {
                      return obj.ticked === true;
                    });
                    $scope.cancel();

                    if (typeof $scope.LoanStructureSet.InterestOnlyTerm === 'undefined') {
                      $scope.LoanStructureSet.InterestOnlyTerm = 0;
                    }

                    if (!$scope.LoanStructureSet.IsAddLMI) {
                      $scope.LoanStructureSet.LMIPremium = 0;
                    }
                    loanScenarioService.loanFacilitySet($scope.LoanStructureSet).then(() => {
                      $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                      if ($scope.saveLmiToFundingParms && $scope.LoanStructureSet.LMIPremium) {
                        $scope.loanDetailsSet.LMI = $scope.LoanStructureSet.LMIPremium;
                        loanAppSharedData.SAVE_FUNDING_PARAMETER = {
                          fundsId: 0,
                          fundingCalParametersId: 0,
                          name: 'Lenders Mortgage Insurance',
                          value: $scope.LoanStructureSet.LMIPremium,
                        };
                      }
                      $scope.loanDetailsGet();
                    });
                  };

                  $scope.modalType = false;
                  //* ************************************/
                  //  owners multi Select                /
                  //* ************************************/
                  $scope.localLang = {
                    selectAll: '<span>Select All</span>',
                    selectNone: '<span>Deselect All </span>',
                    reset: "<i class='fa fa-refresh'></i>",
                    search: 'Search',
                    nothingSelected: 'None Selected', // default-label is deprecated and replaced with this.
                  };
                  $scope.ownersList = [];
                  $scope.ownersListSelected = [];
                  $scope.OwnersGet = () => {
                    loanAppDetailsService.ownersGet($state.params.loanAppId).then((response) => {
                      $scope.ownersListSelected = [];
                      $scope.ownersList = response;
                      if ($scope.loanStructureId) {
                        $scope.getLoanFacility();
                      } else {
                        $scope.LoanStructureSet.LoanTerm = 30;
                        $scope.LoanStructureSet.StatementCycleId = 4;
                        $scope.onSelectAll();
                      }
                    });
                  };

                  $scope.OwnersGet();

                  $scope.ownersListSelectedArray = [];

                  $scope.onSelectAll = () => {
                    for (let i = 0; i < $scope.ownersList.length; i++) {
                      if ($scope.ownersList[i].ClientEntityId > -1) {
                        $scope.ownersList[i].ticked = false;
                      } else {
                        $scope.ownersList[i].ticked = true;
                      }
                    }
                  };


                  $scope.onSelectNone = () => {
                    for (let i = 0; i < $scope.ownersList.length; i++) {
                      $scope.ownersList[i].ticked = false;
                    }
                  };

                  const ownersListSelectedWatcher = $scope.$watch('ownersListSelected', () => {
                    $('.hide-in-mobile .multiSelect').nextAll('div.buttonLabel').remove();
                    if ($scope.ownersListSelected.length >= 3) {
                      $('.multiSelect .buttonLabel:last-child').html(
                        `${$scope.ownersListSelected.length - 2} more ...` +
                                    `<span class="caret"></span>`
                      );
                    }
                    $('.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))').each((e) => {
                      $(e.currentTarget).html(`${$(e.currentTarget).text()}<i class="fa fa-close"></i> <span class="caret"></span>`);
                    });
                  });
                  watcherService.addWatcher(ownersListSelectedWatcher, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
                },
                scope: $scope,
                size: 'sm',
              });
            },
            updateLMIValueOnTxt: (value) => {
              $scope.loanDetailsSet.LMI = value;
              $scope.loanDetailsSetMethod();
              $scope.updateLMIValue();
            },
            updateLMIValue: () => {
              if ($scope.loanDetailsSet.LMI) {
                swal({
                  title: 'Are you sure?',
                  text: `Would you like this additional LMI amount of $${$scope.loanDetailsSet.LMI} to be added to the customers funding required?`,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes',
                  closeOnConfirm: true,
                }, (confirm) => {
                  if (confirm) {
                    loanAppSharedData.SAVE_FUNDING_PARAMETER = {
                      fundsId: 0,
                      fundingCalParametersId: 0,
                      name: 'Lenders Mortgage Insurance',
                      value: $scope.loanDetailsSet.LMI,
                    };
                  }
                });
              }
            },
            openModalForAddLMI: () => {
              $uibModal.open({
                templateUrl: '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/lmi_modal.html',
                scope: $scope,
                controller($uibModalInstance, NewLoanAmount) {
                  $scope.cancel = () => {
                    $uibModalInstance.dismiss('cancel');
                  };

                  fundingCalculatorService.getLenderList(true).then((response) => {
                    $scope.LMILenserList = response.data;
                  });

                  optionsService.getLoanTerms().then((proposedLendingTerm) => {
                    $scope.proposedLendingTermList = proposedLendingTerm;
                  });

                  $scope.getInsurerList = fundingCalculatorSharedData.getInsurerList;


                  // Get Funding Calculator Proposed Lending
                  //----------------------------------------------------------------------------
                  $scope.fundingCalculatorProposedLending = [];
                  $scope.fundingCalculatorLMIDeatils = {};

                  $scope.getFundingCalculatorLMIDeatils = () => {
                    fundingCalculatorService.getFundingCalculatorFundsLMI($scope.securityBrokerEventId).then((response) => {
                      $scope.fundingCalculatorLMIDeatils = response;
                      $scope.fundingCalculatorLMIDeatils.newLoanTerm = $scope.fundingCalculatorLMIDeatils.newLoanTerm.toString();
                      $scope.fundingCalculatorLMIDeatils.newLoanAmount = NewLoanAmount;
                      if ($scope.fundingCalculatorLMIDeatils.newLoanAmount === 0) {
                        $scope.fundingCalculatorLMIDeatils.newLoanAmount = '';
                      }
                      $scope.securityList = $scope.fundingCalculatorLMIDeatils.security;
                      if (typeof $scope.fundingCalculatorLMIDeatils.security === 'undefined') {
                        $scope.securityList = [];
                      }
                      $scope.getLoanOption();
                      if ($scope.fundingCalculatorLMIDeatils.fundsLmiId === 0) {
                        $scope.fundingCalculatorLMIDeatils.isSelfEmployed = false;
                        $scope.fundingCalculatorLMIDeatils.capitalizeLmi = true;
                        $scope.fundingCalculatorLMIDeatils.newLoanTerm = LOAN_TERM;
                        $scope.fundingCalculatorLMIDeatils.lenderId = $scope.loanDetailsSet.LenderId;
                      }

                      if ($scope.fundingCalculatorLMIDeatils.lenderId) {
                        $scope.getInsurerList($scope.fundingCalculatorLMIDeatils.lenderId);
                      }
                    });
                  };
                  $scope.getFundingCalculatorLMIDeatils();

                  // Set Funding Calculator LMI
                  //----------------------------------------------------------------------------
                  $scope.setLMI = () => {
                    broadcastDataService.InlinePreloader = { preLoaderText: 'Calculating LMI', preLoaderSize: '', showPreLoader: true };
                    let loanOptionData = [];
                    const data = _.filter($scope.loanOptions, (itemGet) => {
                      return itemGet.isCheked === true;
                    });
                    if (typeof data !== 'undefined')
                      loanOptionData = _.toArray(data);
                    const postData = {
                      fundsLmiId: $scope.fundingCalculatorLMIDeatils.fundsLmiId,
                      brokerEventId: $scope.securityBrokerEventId,
                      lenderId: Number($scope.fundingCalculatorLMIDeatils.lenderId),
                      insurer: $scope.fundingCalculatorLMIDeatils.insurer,
                      isSelfEmployed: $scope.fundingCalculatorLMIDeatils.isSelfEmployed,
                      newLoanAmount: $scope.fundingCalculatorLMIDeatils.newLoanAmount,
                      newLoanTerm: $scope.fundingCalculatorLMIDeatils.newLoanTerm,
                      predominantLoanPurpose: $scope.fundingCalculatorLMIDeatils.predominantLoanPurpose,
                      loanOption: loanOptionData,
                      security: $scope.securityList,
                      capitalizeLmi: $scope.fundingCalculatorLMIDeatils.capitalizeLmi,
                    };
                    fundingCalculatorService.setFundingCalculatorFundsLMI(postData).then(() => {
                      fundingCalculatorService.getLMICalculatorReport($scope.securityBrokerEventId).then((response) => {
                        broadcastDataService.InlinePreloader = { preLoaderText: 'Calculating LMI', preLoaderSize: '', showPreLoader: false };

                        loanAppSharedData.sectionSelected = 'loandetails';
                        loanAppSharedData.subSectionSelected = 'lmi-section';

                        if (!response.data) {
                          toaster.pop('error', 'Error', 'No Results found, please refine your LMI search criteria.');
                        } else if (response.data.Error) {
                          if (response.data.Error === 'No Results found, please refine your LMI search criteria.') {
                            $scope.updateLMIValueOnTxt(0);
                            toaster.pop('warning', 'Try Again', response.data.Error);
                          } else
                            toaster.pop('error', 'Error', response.data.Error);
                        } else {
                          if (response.data.SymmetryLMISummaryReport) {
                            $scope.updateLMIValueOnTxt(response.data.SymmetryLMISummaryReport.TotalIncStampDuty);
                          }
                          toaster.pop('success', 'Updated', 'LMI has been successfully Updated');
                        }
                      });
                    });
                  };

                  // Get Funding Calculator Proposed Lending Interest Type List
                  //----------------------------------------------------------------------------
                  $scope.proposedLendingInterestTypeList = [];
                  $scope.getProposedLendingInterestType = () => {
                    fundingCalculatorService.getProposedLendingInterestType().then((response) => {
                      $scope.proposedLendingInterestTypeList = response;
                    });
                  };
                  $scope.getProposedLendingInterestType();

                  $scope.loanOptions = [{
                    name: 'Top-Up',
                    value: 'TopUp',
                    isCheked: false,
                  },
                  {
                    name: 'First Home Owner',
                    value: 'FirstHomeOwner',
                    isCheked: false,
                  },
                  {
                    name: 'Unregulated',
                    value: 'Unregulated',
                    isCheked: false,
                  }, {
                    name: 'Low doc',
                    value: 'Lowdoc',
                    isCheked: false,
                  }, {
                    name: 'Non-genuine Savings',
                    value: 'NongenuineSavings',
                    isCheked: false,
                  }, {
                    name: 'Limited Genuine Savings',
                    value: 'LimitedGenuineSavings',
                    isCheked: false,
                  }, {
                    name: 'Interest Only',
                    value: 'InterestOnly',
                    isCheked: false,
                  }];

                  $scope.getLoanOption = () => {
                    const $updatedData = [];
                    angular.forEach($scope.loanOptions, (item) => {
                      item.isCheked = false;
                      angular.forEach($scope.fundingCalculatorLMIDeatils.loanOption, (itemGet) => {
                        if (itemGet.value === item.value) {
                          item.isCheked = true;
                          return item;
                        }
                      });
                      $updatedData.push(item);
                    });
                    $scope.loanOptions = $updatedData;
                  };

                  // Lenders Mortgage Insurance -> Security
                  //----------------------------------------------------------------------------

                  $scope.isSecurityVisible = false;
                  $scope.newSecurityList = [];

                  // Add Security Div
                  $scope.addSecurity = () => {
                    $scope.isSecurityVisible = true;
                    const newSecurity = {
                      id: Math.random(),
                      stateOfSecurity: '',
                      occupancyType: '',
                      value: '',
                      isEditMode: false,
                    };
                    $scope.newSecurityList.push(newSecurity);
                  };

                  // Add Or Update Security
                  $scope.saveSecurity = (security) => {
                    let index = $scope.newSecurityList.indexOf(security);
                    if (index > -1)
                      $scope.newSecurityList.splice(index, 1);
                    const postData = security;
                    if (!security.isEditMode) {
                      postData.id = 0;
                      $scope.securityList.push(postData);
                    } else {
                      index = $scope.securityList.indexOf(security);
                      if (index > -1)
                        $scope.securityList.splice(index, 1);
                      $scope.securityList.push(postData);
                    }
                    security.isEditMode = false;
                  };

                  // Delete Security
                  $scope.deleteSecurity = (security) => {
                    if (Math.floor(security.id) === 0) {
                      const index = $scope.newSecurityList.indexOf(security);
                      if (index > -1)
                        $scope.newSecurityList.splice(index, 1);
                      const indexOne = $scope.securityList.indexOf(security);
                      if (indexOne > -1) {
                        $scope.securityList.splice(indexOne, 1);
                      }
                    } else {
                      const index = $scope.securityList.indexOf(security);
                      if (index > -1) {
                        $scope.securityList.splice(index, 1);
                      }
                    }
                  };
                },
                size: 'md',
                resolve: {
                  NewLoanAmount: () => {
                    return $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined;
                  },
                },
              });
            },
          });

        // caller
        $scope.loanDetailsInit();


        /**
         * Below are for product Finder codes
         */
        // models
        angular
          .extend($scope, {
            isSelectedProductRecommend: false,
            temporaryProducts: [
              {
                ProductID: 3625,
                Description: 'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
              {
                ProductID: 3624,
                Description: 'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
              {
                ProductID: 3626,
                Description: 'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
              {
                ProductID: 3627,
                Description: 'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
            ],
          });


        // methods
        angular
          .extend($scope, {
            searchProductChange: (facility) => {
              facility.ProductsList = [];
              facility.searchInterval = 2;

              if (_.size(facility.searchProduct) > 2) {
                const stop = $interval(() => {
                  //

                  if (facility.searchInterval > 1) {
                    facility.searchInterval -= 1;
                  } else {
                    loanProfilerService.getServiceabilityProductSearch(facility.searchProduct).then((response) => {
                      facility.ProductsList = response.data;
                    });
                    facility.searchInterval = 2;
                    $interval.cancel(stop);
                  }
                }, 1000);
              }
            },
            deleteFavoriteProduct: (facility, product) => {
              const postData = {
                brokerEventID: facility.BrokerEventId,
                ProductID: product.ProductID,
                LenderID: product.LenderID,
                loanStructureID: facility.FavouriteProductData[0].LoanStructureID,
                IsFavourite: false,
              };

              // Remove the other product (Put false to their IsFavourite property)
              loanProfilerService.setFavouriteProduct(postData).then((response) => {
                if (response.data === 1) {
                  $scope.getFacilityFavoriteProductList(facility);
                } else {
                  toastError();
                }
              });
            },
            setFavoriteProduct: (facility, product) => {
              const toBeDeletedProducts = _.clone(facility.selectedProduct);
              const countProductsToBeDeleted = _.size(toBeDeletedProducts);
              let counter = 0;
              _.each(toBeDeletedProducts, (o) => {
                const postData = {
                  brokerEventID: facility.BrokerEventId,
                  ProductID: o.ProductID,
                  LenderID: o.LenderID,
                  loanStructureID: facility.LoanStructureId,
                  IsFavourite: false,
                };

                // Remove the other product (Put false to their IsFavourite property)
                loanProfilerService.setFavouriteProduct(postData).then(() => {
                  _.remove(facility.selectedProduct, (currentProd) => {
                    return _.isEqual(currentProd.LenderID, o.LenderID);
                  });

                  counter++;

                  // Check if every product are now changed
                  if (counter === countProductsToBeDeleted) {
                    product.isAddedForComparison = !product.isAddedForComparison;
                    const productPostData = {
                      brokerEventID: facility.BrokerEventId,
                      ProductID: product.ProductID,
                      LenderID: product.LenderID,
                      loanStructureID: facility.LoanStructureId,
                      IsFavourite: product.isAddedForComparison,
                    };

                    // Add the newly selected product to be favourite
                    loanProfilerService.setFavouriteProduct(productPostData).then((response) => {
                      if (response.data === 1) {
                        // Set loan structure / loan details (facility)
                        angular.extend(facility, {
                          ProductId: product.ProductID,
                          LenderId: product.LenderID,
                          Owner: [],
                          loanScenarioId: $scope.loanAppId,
                        });
                        loanScenarioService.loanFacilitySet(facility).then(() => {
                          $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                          $scope.getFacilityFavoriteProductList(facility);
                        });
                      } else {
                        toastError();
                      }
                    });
                  }
                });
              });
            },

            getFavoriteProduct: (facility) => {
              facility.selectedProduct = [];
              loanProfilerService.getFavouriteProduct(facility.BrokerEventId, facility.LoanStructureId).then((response) => {
                facility.selectedProduct = response.data;
              });
            },
            getFacilityFavoriteProductList: (facility) => {
              facility.FavouriteProductData = [];
              facility.SelectedFavouriteProductData = {};
              facility.TotalFees = 0;
              if (!facility.BrokerEventId)
                return;
              loanProfilerService.getSelectedProductDetailsGetByLoanStructure(facility.BrokerEventId).then((response) => {
                facility.FavouriteProductData = response.data;
                if (typeof facility.FavouriteProductData[0] !== 'undefined') {
                  const filterData = _.find(facility.FavouriteProductData[0].SelectedProduct, (product) => {
                    return product.IsRecommended === true;
                  });
                  $scope.isSelectedProductRecommend = !_.isEmpty(filterData);
                  if (typeof filterData === 'undefined') {
                    if (typeof facility.FavouriteProductData[0].SelectedProduct[0] !== 'undefined') {
                      const postData = {
                        BrokerEventID: Number(facility.BrokerEventId),
                        LoanStructureID: Number(facility.FavouriteProductData[0].LoanStructureID),
                        ProductID: facility.FavouriteProductData[0].SelectedProduct[0].ProductID,
                        IsRecommended: true,
                        IsToolTipShown: false,
                      };

                      loanProfilerService.setFavouriteProductRecommendation(postData).then((productResponse) => {
                        if (productResponse.data === 1) {
                          facility.FavouriteProductData[0].SelectedProduct[0].IsRecommended = true;
                          if (facility.FavouriteProductData[0].SelectedProduct[0].IsRecommended === true) {
                            facility.SelectedFavouriteProductData = facility.FavouriteProductData[0].SelectedProduct[0];
                            if (facility.SelectedFavouriteProductData.NewInitialRate === 0) {
                              facility.SelectedFavouriteProductData.NewInitialRate = '';
                            }
                            if (facility.SelectedFavouriteProductData.NewOngoingRate === 0) {
                              facility.SelectedFavouriteProductData.NewOngoingRate = '';
                            }
                            loanScenarioService.LenderFeesGet(facility.FavouriteProductData[0].SelectedProduct[0].ProductID, facility.BrokerEventId,
                              facility.FavouriteProductData[0].LoanStructureID, $scope.loanAppId).then((feeResponse) => {
                              facility.SelectedFavouriteProductData.FeesList = feeResponse.data;
                              loanAppSharedData.SAVE_FUNDING_PARAMETER = {
                                fundsId: 0,
                                fundingCalParametersId: 0,
                                name: 'Lenders Fees',
                                value: facility.SelectedFavouriteProductData.FeesList.TotalFee,
                              };
                            });
                          }
                        } else {
                          toastError();
                        }
                      });
                    }
                  } else {
                    angular.forEach(facility.FavouriteProductData[0].SelectedProduct, (item) => {
                      if (item.IsRecommended) {
                        facility.SelectedFavouriteProductData = item;
                        if (facility.SelectedFavouriteProductData.NewInitialRate === 0) {
                          facility.SelectedFavouriteProductData.NewInitialRate = '';
                        }
                        if (facility.SelectedFavouriteProductData.NewOngoingRate === 0) {
                          facility.SelectedFavouriteProductData.NewOngoingRate = '';
                        }
                        loanScenarioService.LenderFeesGet(item.ProductID, facility.BrokerEventId, facility.FavouriteProductData[0].LoanStructureID, $scope.loanAppId).then((feeResponse) => {
                          facility.SelectedFavouriteProductData.FeesList = feeResponse.data;
                          loanAppSharedData.SAVE_FUNDING_PARAMETER = {
                            fundsId: 0,
                            fundingCalParametersId: 0,
                            name: 'Lenders Fees',
                            value: facility.SelectedFavouriteProductData.FeesList.TotalFee,
                          };
                        });
                      }
                    });
                  }
                }
              });
            },
            setFavouriteProductRates: (loanStructureId, brokerEventId, productId, rateType, newRate) => {
              const postData = {
                BrokerEventID: brokerEventId,
                LoanStructureID: loanStructureId,
                ProductID: productId,
                RateType: rateType,
                NewRate: newRate,
              };
              loanProfilerService
                .setFavouriteProductRates(postData)
                .then((response) => {
                  if (response.data !== 1) {
                    toastError();
                  }
                });
            },
            setFeesValue: (facility) => {
              loanScenarioService.LenderFeesSet(facility.SelectedFavouriteProductData.FeesList).then((response) => {
                if (response.data === 1) {
                  loanScenarioService.LenderFeesGet(facility.SelectedFavouriteProductData.ProductID, facility.BrokerEventId, facility.FavouriteProductData[0].LoanStructureID,
                    $scope.loanAppId).then((feeResponse) => {
                    facility.SelectedFavouriteProductData.FeesList = feeResponse.data;


                    loanAppSharedData.SAVE_FUNDING_PARAMETER = {
                      fundsId: 0,
                      fundingCalParametersId: 0,
                      name: 'Lenders Fees',
                      value: facility.SelectedFavouriteProductData.FeesList.TotalFee,
                    };
                  });
                }
              });
            },
            setFacilityFavoriteProduct: (facility, product) => {
              if (!facility || !product) return;
              const { FavouriteProductData: favouriteProductData, BrokerEventId: brokerEventId } = facility;
              if (favouriteProductData && favouriteProductData.length && !product.IsRecommended) {
                $scope.isSelectedProductRecommend = !product.IsRecommended;
                const { SelectedProduct: selectedProduct, LoanStructureID: loanStructureId } = favouriteProductData[0];
                selectedProduct && selectedProduct.forEach((item) => {
                  item.IsRecommended = (parseInt(item.ProductID, 10) !== parseInt(product.ProductID, 10) ? false : !product.IsRecommended);
                  const postData = {
                    BrokerEventID: parseInt(brokerEventId, 10),
                    LoanStructureID: parseInt(loanStructureId, 10),
                    ProductID: item.ProductID,
                    IsRecommended: item.IsRecommended,
                    IsToolTipShown: false,
                  };

                  loanProfilerService.setFavouriteProductRecommendation(postData).then((response) => {
                    if (response.data === 1 && item.ProductID === product.ProductID) {
                      $scope.getFacilityFavoriteProductList(facility);
                    } else if (response.data !== 1) {
                      product.IsRecommended = !product.IsRecommended;
                      toastError();
                    }
                  });
                });
              }

              // check if recommend is present
            },
            checkIfFavorite: (facility, product) => {
              if (_.find(facility.selectedProduct, (o) => { return o.ProductID === product.ProductID; }))
                return true;
              return false;
            },
            openProductFinderModal: (facility) => {
              if ($scope.loanAppSharedData.fundingSummary.lvr > 95)
                toaster.pop('warning', 'Important', 'No results will appear in product finder for >95% LVR');
              facility.tempShowProducts = true;
              facility.loanScenarioId = $scope.loanAppId;
              facility.Owner = [];

              if (facility.BrokerEventId) {
                const modalInstance = $uibModal.open({
                  templateUrl: '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/productFinder.html',
                  controller: 'LoanAppProductFinderCtrl',
                  scope: $scope,
                  size: 'wide',
                  backdrop: false,
                  resolve: {
                    facility: () => {
                      return facility;
                    },
                    fundingSummary: () => {
                      const fundingSummary = _.clone($scope.loanAppSharedData.fundingSummary);
                      fundingSummary.totalProposedLendingUserDefined = $scope.loanDetailsSet.TotalProposedLendingUserDefined || 0;

                      return fundingSummary;
                    },
                  },
                });

                modalInstance.result.then(() => {
                  $scope.getFacilityFavoriteProductList(facility);
                }, () => {
                  $scope.getFacilityFavoriteProductList(facility);
                });
              } else {
                toaster.pop('error', 'Error', 'Something did not save due to internet issue , please re-save it again');
              }
            },
          });

        $scope.isCountry = (country) => {
          return uiService.isCountry(country);
        };

        $scope.onOtherLenderSelected = (lender) => {
          if (!lender) return;

          loanAppSharedData.lenderId = lender.ProviderId;
          loanAppSharedData.otherLenderName = lender.ProviderName;
          $scope.updateLoanDetailsField('Lender', lender.ProviderId, lender.ProviderName);
        };

        $scope.onLenderSelected = (lender) => {
          if (!lender) return;

          loanAppSharedData.lenderId = lender.ProviderId;
          loanAppSharedData.otherLenderName = '';
          $scope.loanDetailsSet.LenderName = lender.ProviderName;
          $scope.updateLoanDetailsField('Lender', lender.ProviderId, '');
        };

        $scope.getCountryCode = () => {
          return uiService.getCountryCode();
        };

        // Listens for reseting loandetailsset
        const refreshLoanDetailsWatcher = $scope.$watch('loanAppSharedData.refreshLoanDetailsSet', (nv) => {
          if (nv === 1) {
            $scope.loanDetailsSetMethod();

            $scope.loanAppSharedData.refreshLoanDetailsSet = 0;
          }
        }, true);

        const lenderIdWatcher = $scope.$watch(() => {
          return loanAppSharedData.lenderId;
        }, (nv) => {
          if (!$scope.loanDetailsSet || !nv) return;

          const isNewLender = nv !== $scope.loanDetailsSet.LenderId;
          if (isNewLender) {
            $scope.loanDetailsSet.LenderId = loanAppSharedData.lenderId;
            $scope.loanDetailsSet.LenderName = loanAppSharedData.loanAppSummary &&
              loanAppSharedData.loanAppSummary.LoanDetails &&
              loanAppSharedData.loanAppSummary.LoanDetails.LenderName;
          }
        });

        const otherLenderNameWatcher = $scope.$watch(() => {
          return loanAppSharedData.otherLenderName;
        }, (nv) => {
          if (!$scope.loanDetailsSet) return;

          const isNewOtherLenderName = nv !== $scope.loanDetailsSet.OtherLenderName;
          if (isNewOtherLenderName) {
            $scope.loanDetailsSet.OtherLenderName = loanAppSharedData.otherLenderName;
          }
        });

        watcherService.addWatcher(refreshLoanDetailsWatcher, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
        watcherService.addWatcher(lenderIdWatcher, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
        watcherService.addWatcher(otherLenderNameWatcher, WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
      };

      if (typeof sectionObject.IsCompleted === 'undefined') {
        $scope.processLoanFundingDetailsSection();
      } else {
        const { totalProposedLending: sectionProposedLending, lvr: sectionLVR } = $scope.loanAppSharedData.fundingSummary;
        $scope.isValidLoanDetails = sectionObject.IsCompleted;

        $scope.loanAppSharedData.fundingSummary.totalProposedLending = sectionProposedLending;
        $scope.loanAppSharedData.fundingSummary.lvr = sectionLVR;
      }

      $scope.$on('$destroy', () => {
        watcherService.destroyWatchers(WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
        timeoutService.cancelTimeouts(WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL);
      });
    });
  });
