import { providerDocumentCagory, providerDocument } from 'Common/mappers/providerInformation';

export function mapContactPointForComponent(contactPoint) {
  return contactPoint && {
    ...contactPoint,
    contacts: contactPoint.contacts && contactPoint.contacts.map((contact) => {
      const type = contact.type === 'Website' ? 'web' : contact.type.toLowerCase();
      return { ...contact, type };
    }),
  };
}

export function mapDocumentsForComponent(documents, concatFileNameAndTitle) {
  return documents && documents.map((doc) => {
    const mappedDoc = providerDocument(doc);
    mappedDoc.isFavorite = mappedDoc.isFavorite ? 1 : 0;
    const splitFileName = mappedDoc.fileName.split('.');
    const fileNameNoExtension = splitFileName.length > 1 ? splitFileName.slice(0, splitFileName.length - 1) : splitFileName[0];
    mappedDoc.listId = `${mappedDoc.id}_${fileNameNoExtension}`;
    const doConcat = concatFileNameAndTitle && mappedDoc.fileName && !mappedDoc.isPrivateDocument;
    mappedDoc.title = doConcat ? `${mappedDoc.title} (${mappedDoc.fileName})` : mappedDoc.title;
    return mappedDoc;
  });
}

export function mapProviderDocuments(documents, concatFileNameAndTitle) {
  if (!documents) return {};
  const categories = documents.Categories && documents.Categories.map((category) => {
    const mappedCategory = providerDocumentCagory(category);
    mappedCategory.documents = mapDocumentsForComponent(category.Documents, concatFileNameAndTitle);
    return mappedCategory;
  });
  const uncategorisedDocs = mapDocumentsForComponent(documents.Documents, concatFileNameAndTitle);
  const favorites = mapDocumentsForComponent(documents.Favorites, concatFileNameAndTitle);
  return { categories, documents: uncategorisedDocs, favorites };
}
