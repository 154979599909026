import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('ContactEmploymentCtrl',
  function ContactEmploymentCtrl($scope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanScenarioService,
    utilitiesService,
    contactService,
    colorService,
    $filter,
    $element,
    $window,
    $rootScope,
    toaster,
    SweetAlert,
    optionsService,
    uiService,
    configService,
    loanAppSharedData) {
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.loanAppSharedData.Employment = $scope.loanAppSharedData.Employment;
    $scope.showSpinner = false;
    $scope.documentTypeList = [];
    $scope.documentType = {};
    $scope.countryList = [];
    $scope.country = {};
    $scope.selectedIdentification = {};
    $scope.selectedEmployment = {};
    $scope.docList = [];
    $scope.empCategoryList = [];
    $scope.empCategory = {};
    $scope.loanAppSharedData.EmploymentTypeList = [];
    $scope.loanAppSharedData.EmploymentType = {};
    $scope.loanAppSharedData.EmploymentStatusList = [];
    $scope.loanAppSharedData.EmploymentStatus = {};
    $scope.employerTypeList = [];
    $scope.employerType = {};
    $scope.refreshIdentificationGrid = false;
    $scope.refreshEmploymentGrid = false;
    $scope.emp = {};
    $scope.isPAYG = false;
    $scope.loanAppSharedData.EmploymentList = [];
    $scope.loanAppSharedData.Employment = {
      Income: [],
      StatusList: [],
      Types: [],
      BasisList: [],
      EmployerTypeList: [],
      Info: {
        Status: '',
        Type: '',
        StartDate: new Date(),
        EndDate: new Date(),
        Basis: '',
        isProbation: false,
      },
      Details: {
        EmployerName: '',
        EmployerTypeId: '',
        EmployerAddress: '',
        ContactFName: '',
        ContactLName: '',
      },
      List: {
        Employment: [],
      },
    };
    $scope.uiService = uiService;
    // will check if employment type is PAYG
    $scope.$watch('Employment.EmploymentTypeId', (nv) => {
      $scope.isPAYG = parseInt(nv, 10) === 1;
    });

    $scope.getIncomeVerificationType = function () {
      optionsService.IncomeVerificationType('employment').then((response) => {
        $scope.incomeVerficationTypeList = response.data;
      });
    };

    $scope.deleteIncome = function (income) {
      _.remove($scope.loanAppSharedData.Employment.Income, (o) => { return _.isEqual(o, income); });
    };

    /**
        * Add Empty Instance for newly added income
        * */
    $scope.addNewIncome = function () {
      $scope.changeEmploymentType();
      const { List, EmployerContactFirstName, EmployerContactLastName } = $scope.loanAppSharedData.Employment;
      const { clientId } = List;
      const toPush = {
        IsEvidenceOfTenancy: true,
        Id: 0,
        FamilyId: $scope.familyId,
        FinancialId: 0,
        Type: '',
        TypeId: $scope.isPAYG ? '19' : '',
        Description: '',
        DepositId: 0,
        Amount: 0,
        CurrencyCode: $scope.crmCurrency,
        Category: 'string',
        CurrencyCodeId: $scope.CountryID,
        FrequencyID: '',
        FrequencyName: '',
        Provider: '',
        Borrowers: [
          {
            Percentage: 100,
            BorrowerID: clientId,
            FirstName: EmployerContactFirstName,
            LastName: EmployerContactLastName,
            IsInclude: true,
            IsEntity: false,
          },
        ],
        IncomeVerification: [
          {
            IncomeVerificationTypeId: 0,
          },
        ],
        IsGross: 'grossTemp',
      };
      $scope.loanAppSharedData.Employment.Income.push(toPush);
    };


    /**
         * Run when Employment Type has been changed
         * Assign income type value
         * */
    $scope.changeEmploymentType = function () {
      $scope.checkIfPAYG();
      if (parseInt($scope.loanAppSharedData.Employment.EmploymentTypeId, 10) === parseInt($scope.PAYGid, 10)) {
        $scope.newIncomeType = $scope.PAYGincomeTypeId;
      } else {
        $scope.newIncomeType = '';
      }
    };


    /**
         * Return the id value of "Salary / Wages"
         * */
    $scope.checkIfPAYG = function () {
      /**
             * Get the income type id for "Salary / Wages"
             * */
      $scope.PAYGincomeTypeId = parseInt(_.find(
        $scope.loanAppSharedData.Employment.IncomeTypeList,
        (o) => {
          return o.Name === 'Salary / Wages';
        }
      ).Value, 10);

      /**
             * Get the Employment Type id for PAYG
             * */
      const $resultData = _.find(
        $scope.loanAppSharedData.Employment.Types,
        (o) => {
          return o.Name === 'PAYG';
        });
      if (typeof $resultData !== 'undefined') {
        $scope.PAYGid = parseInt($resultData.Value, 10);
      }
    };

    $scope.changeEmploymentStatus = function () {
      $scope.showEndDate = parseInt($scope.PrevEmpId, 10) === parseInt($scope.loanAppSharedData.Employment.EmploymentStatusId, 10) || !$scope.loanAppSharedData.Employment.IsCurrent;
    };

    // $scope.clientId = 855336;
    const refreshIdentificationGridBroadcastHandler = $rootScope.$on('refreshIdentificationGrid', () => {
      $scope.getDocs($scope.familyId);
    });
    $scope.$on('$destroy', refreshIdentificationGridBroadcastHandler);

    const refreshEmploymentGridBroadcastHandler = $rootScope.$on('refreshEmploymentGrid', () => {
      $scope.getEmployment($scope.familyId);
    });
    $scope.$on('$destroy', refreshEmploymentGridBroadcastHandler);

    $scope.selectedIdentification.documentTypeID = $scope.documentType.Value;
    $scope.selectedEmployment.employmentCategoryID = $scope.empCategory.employmentCategoryID;


    $scope.getDocType = function () {
      optionsService.identificationDocumentsTypeGet()
        .then((response) => {
          $scope.documentTypeList = response.data;
          $scope.documentType = $scope.documentTypeList[0];
        });
    };

    $scope.getCountryList = function () {
      optionsService.countryListGet()
        .then((response) => {
          $scope.countryList = response.data;
          $scope.country = $scope.countryList[0];
        });
    };

    $scope.getDocs = function (familyId) {
      contactService.clientEntityIdentificationGet(familyId)
        .then((response) => {
          $scope.docList = response.data;
        });
    };

    /**
     * * getEmpCategory Not used
     * */
    $scope.getEmpCategory = function () {
      optionsService.employmentCategoryGet()
        .then((response) => {
          $scope.empCategoryList = response.data;

          $scope.emp.employmentCategoryID = $scope.empCategoryList[0].Value;
        });
    };

    /**
         * Get the Employment Status List
         * */
    $scope.getEmploymentStatus = function () {
      optionsService.employmentStatusGet().then((response) => {
        if (!response.data) return;

        $scope.loanAppSharedData.Employment.StatusList = $scope.isCurrentEmploymentStatusShown ? response.data.filter(status => status.Name !== 'Previous Employment') : response.data;
        if (!uiService.isCountry('New Zealand')) {
          $scope.loanAppSharedData.Employment.StatusList.unshift({ Name: 'Please select', Value: '' });
        } else if ($scope.loanAppSharedData.Employment.StatusList && $scope.loanAppSharedData.Employment.StatusList.length) {
          $scope.loanAppSharedData.Employment.EmploymentStatusId = $scope.loanAppSharedData.Employment.StatusList[0].Value;
        }
      });
    };

    /**
         * Get the Employer Type List
         * */
    $scope.getEmployerType = function () {
      optionsService.employerTypeGet()
        .then((response) => {
          $scope.loanAppSharedData.Employment.EmployerTypeList = response.data;
          $scope.loanAppSharedData.Employment.EmployerTypeList.unshift({ Name: 'Please select', Value: '' });
        });
    };

    /**
         * Get the Employment Basis List
         * */
    $scope.getEmploymentBasis = function () {
      optionsService.getEmploymentBasis()
        .then((response) => {
          $scope.loanAppSharedData.Employment.BasisList = response.data;

          /**
                     * Detect CamelCase and add space in between words
                     * */
          angular.forEach($scope.loanAppSharedData.Employment.BasisList, (val) => {
            const name = val.Name;
            val.Name = name.replace(/([a-z](?=[A-Z]))/g, '$1 ');
          });
          if (!uiService.isCountry('New Zealand')) {
            $scope.loanAppSharedData.Employment.BasisList.unshift({ Name: 'Please select', Value: '' });
          } else if ($scope.loanAppSharedData.Employment.BasisList && $scope.loanAppSharedData.Employment.BasisList.length) {
            $scope.loanAppSharedData.Employment.EmploymentBasisId = $scope.loanAppSharedData.Employment.BasisList[0].Value;
          }
        });
    };


    /**
         * Get the Income Type List for Incomes
         * */
    $scope.getIncomeType = function () {
      loanScenarioService.IncomeTypeGet()
        .then((contactEmploymentResponse) => {
          $scope.loanAppSharedData.Employment.IncomeTypeList = [];
          $scope.loanAppSharedData.Employment.IncomeTypeList = contactEmploymentResponse.data;

          _.remove($scope.loanAppSharedData.Employment.IncomeTypeList, (rmItem) => {
            const isItemTobeRemove = (rmItem.GroupId !== '6' && rmItem.GroupId !== '7' && rmItem.GroupId !== '8' && rmItem.GroupId !== '9' && rmItem.GroupId !== '10');
            return isItemTobeRemove;
          });

          $scope.loanAppSharedData.Employment.IncomeTypeList.unshift({ Name: 'Please select', Value: '' });
        });
    };

    /**
        * Get the employment list
        * */
    $scope.getEmployment = function (familyId) {
      contactService.clientEntityEmploymentGet(familyId)
        .then((response) => {
          $scope.loanAppSharedData.Employment.List = response.data;
          $scope.loanAppSharedData.Employment.List = _.orderBy($scope.loanAppSharedData.Employment.List, ['DateStarted'], ['desc']);
          $scope.initialIconBG = colorService.getRandomColor();
          angular.forEach($scope.loanAppSharedData.Employment.List, (val) => {
            val.background = $scope.initialIconBG;
          });
        });
    };

    /**
      * Get the ClientId
      * */
    $scope.clientsGet = function () {
      contactService.clientInformGet($scope.familyId).then((response) => {
        $scope.clientsList = response.data || [];
        if ($scope.clientsList.length >= 1) {
          $scope.clientId = $scope.clientsList[0].PersonId;
        }
      });
    };

    // ownership dropdown
    angular.extend($scope, {
      personList: [],
      contactList: {
        background: colorService.getRandomColor(),
        initials: '',
        SelectedClient: {},
      },
      getPersonList(familyId) {
        contactService.familyInfoGet(familyId).then((info) => {
          const { data } = info;
          let numberOfAdults = 0;
          let personId = 0;
          let personListBackground = '';
          _.forEach(data, (object) => {
            if (object.Role === 'Adult')
              numberOfAdults++;
          });

          $scope.loanAppSharedData.employmentPersonList = _.filter(data, function filter(obj) {
            obj.background = colorService.getRandomColor();
            obj.initials = utilitiesService.filterInitialOneString(obj.FullName);
            return obj.Role === 'Adult';
          });

          if (_.size($scope.loanAppSharedData.employmentPersonList) > 0 && !_.isNil($scope.loanAppSharedData.employmentPersonList[0].PersonId)) {
            personId = $scope.loanAppSharedData.employmentPersonList[0].PersonId;
          }
          if (_.size($scope.loanAppSharedData.employmentPersonList) > 0 && !_.isNil($scope.loanAppSharedData.employmentPersonList[0].background)) {
            personListBackground = $scope.loanAppSharedData.employmentPersonList[0].background;
          }
          if (numberOfAdults === 1) {
            $scope.loanAppSharedData.Employment.SelectedClient = personId;
          }
          $scope.loanAppSharedData.employmentContactList.SelectedClient = personId;

          $scope.loanAppSharedData.changeEmploymentOwnership($scope.loanAppSharedData.employmentContactList.SelectedClient, personListBackground);
        });
      },
      listeners() {
        $scope.$watch('familyIdentification.FamilyId', () => {
          $scope.getPersonList($scope.familyIdentification.FamilyId);
        });

        // Watch for changes in contact tab
        $scope.$watch('selectedContacts', (newValue) => {
          if (newValue === 'employment') {
            $scope.init();
          }
        });
      },
      initialize() {
        $scope.listeners();
      },
    });

    $scope.initialize();

    const checkModalResponse = (response) => {
      if (response && response.isRefreshEmployment) {
        $scope.getEmployment($scope.familyId);
      }
    };

    $scope.openAddEditEmployment = (employmentId = 0, clientId = 0, action = 'Add') => {
      const modalInstance = $uibModal.open({
        backdrop: 'static',
        keyboard: false,
        templateUrl: '/assets/views/modals/employment.html',
        controller: 'EmploymentModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        windowClass: 'modal-larger',
        resolve: {
          newIncomeDetails: () => null,
          isFromIncome: () => false,
          familyId: () => $scope.familyId,
          employmentId: () => employmentId,
          clientId: () => clientId.toString(),
          loanAppId: () => 0,
          action: () => action,
        },
      });

      modalInstance.result.then((response) => {
        checkModalResponse(response);
      });
    };

    /**
         * Start: Open Modal - Add Employment Form
        * */
    $scope.openAddEmployment = function () {
      const modalInstance = $uibModal.open({

        templateUrl: '/assets/views/modals/employment.html',
        controller: 'AddEmploymentCtrl',
        size: 'lg',
        scope: $scope,
        windowClass: 'modal-larger',
        resolve: {
          newIncomeDetails() {
            return null;
          },
          isFromIncome() {
            return false;
          },
        },
      });

      modalInstance.result.then((response) => {
        checkModalResponse(response);
      });
    };

    /**
     * Start: Open Modal - Edit Employment Form
     * */
    $scope.openEditEmployment = function (id) {
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/modals/employment.html',
        controller: 'EditEmploymentCtrl',
        size: 'lg',
        resolve: {
          id() {
            return id;
          },
          isFromIncome() {
            return false;
          },
          newIncomeDetails() {
            return null;
          },
        },
        scope: $scope,
        windowClass: 'modal-larger',
      });

      modalInstance.result.then((response) => {
        checkModalResponse(response);
      });
    };

    /**
     * Start: Open Modal - Select Employment ROLE Form
     * */
    $scope.openAddEmploymentRoles = function () {
      $uibModal.open({

        templateUrl: '/assets/views/contacts/partials/addEmploymentRoleModal.html',

        controller: 'AddEmploymentRolesCtrl',
        size: 'md',
        scope: $scope,
      });
    };

    /**
     * Start: Delete Employment
     * */
    $scope.loanAppSharedData.Employment.delete = function (id, index) {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your employment list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }, (confirm) => {
        if (confirm) {
          $scope.loanAppSharedData.Employment.List.splice(index, 1);
          contactService.clientEntityEmploymentDelete(id).then(() => {
            $scope.getEmployment($scope.familyId);
            $scope.getPersonList($scope.familyId);
          });
        }
      });
    };


    /**
     * Identification Section
     * */

    // model
    angular.extend($scope, {
      getFamilyIdentityStatusModel() {
        return {
          FamilyId: $scope.familyId,
          FamilyIdentityCheckId: 0,
          FaceToFaceIdentityCheck: false,
          IsExistingCustomer: false,
        };
      },
    });

    /**
     * Delete Identification
     * */


    angular.extend($scope, {
      GetFamilyIdentityStatus() {
        loanScenarioService.FamilyIdentityStatusGet($scope.loanAppId, $scope.familyIdentification.FamilyId)
          .then((contactEmploymentResponse) => {
            // zero out FamilyIdentityCheckId first
            $scope.familyIdentityStatusSet = { ...$scope.familyIdentityStatusSet, FamilyIdentityCheckId: 0 };
            angular.extend($scope.familyIdentityStatusSet, contactEmploymentResponse.data);
          });
      },
      SetFamilyIdentityStatus() {
        loanScenarioService.FamilyIdentityStatusSet($scope.familyIdentityStatusSet).then(() => {
          $scope.GetFamilyIdentityStatus();
        });
      },
      SetFamilyIdentification(getFirst, familyId) {
        // Get first?
        let family = {};
        if (getFirst) {
          family = _.first($scope.allIdentifications);
        } else {
          family = _.find($scope.allIdentifications, (o) => { return o.FamilyId === familyId; });
        }

        angular.extend($scope.familyIdentification, family);
        $scope.familyIdentification.FamilyId = ($scope.familyIdentification.FamilyId).toString();
        $scope.familyIdentification.initials = utilitiesService.filterInitialOneString(family.FamilyName);

        _.map($scope.familyIdentification.Identification, (o) => {
          o.DocumentTypeInitials = utilitiesService.filterInitialOneString(o.DocumentTypeName);
          o.background = colorService.getRandomColor();

          return o;
        });
      },
      GetIdentification() {
        contactService.clientEntityIdentificationGet($scope.familyId).then((response) => {
          $scope.allIdentificationFamilies = [];

          $scope.allIdentifications = response.data;
          $scope.familyIdentification.Identification = response.data;
        });
      },
      identificationInit() {
        $scope.familyIdentityStatusSet = $scope.getFamilyIdentityStatusModel();
        $scope.allIdentifications = [];
        $scope.allIdentificationFamilies = [];
        $scope.familyIdentification = {};
        $scope.familyIdentification.FamilyId = ($scope.familyId).toString();
        $scope.familyIdentification.Identification = [];
        // call other apis
        $scope.GetIdentification();
      },
      changeIdentificationFamily(familyId) {
        $scope.SetFamilyIdentification(false, familyId);
      },
      identificationModal(type, identification, familyId) {
        /**
         * @param identification either null or identification object (null if type is 'new')
         */
        $scope.IdentificationSet = {
          IdentificationId: 0,
          ClientEntityId: null,
          IsClient: true,
          NameOnDoc: '',
          DocumentName: '',
          DocumentTypeId: null,
          DocumentTypeName: '',
          DocumentNumber: '',
          IssueDate: null,
          ExpiryDate: null,
          IssueBy: '',
          PlaceOfissue: '',
          CountryCode: 'AU',
          CountryName: '',
          ClientFirstName: '',
          ClientLastName: '',
          IsVerified: false,
          CopyType: '',
        };
        $scope.ClientEntityList = [];


        if (type !== 'new') {
          // edit
          angular.extend($scope.IdentificationSet, identification);
          $scope.IdentificationSet.DocumentTypeId = $scope.IdentificationSet.DocumentTypeId.toString();
          $scope.IdentificationSet.ClientEntityId = $scope.IdentificationSet.ClientEntityId.toString();

          if ($scope.IdentificationSet.IssueDate)
            $scope.IdentificationSet.IssueDate = new Date($scope.IdentificationSet.IssueDate);

          if ($scope.IdentificationSet.ExpiryDate)
            $scope.IdentificationSet.ExpiryDate = new Date($scope.IdentificationSet.ExpiryDate);
        }

        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/modals/identification.html',
          controller: 'IdentificationCtrl',
          scope: $scope,
          size: 'sm',
          resolve: {
            modalType() {
              return type;
            },
            familyId() {
              return familyId;
            },
          },
        });

        modalInstance.result.then((response) => {
          const responseFamilyId = response.modalType === 'new' ? parseInt(response.FamilyId, 10) : response.FamilyId;
          $scope.GetIdentification(responseFamilyId);
        }, () => {
          $scope.GetIdentification();
        });
      },
      deleteIdentification(contactEmploymentIdentificationId) {
        const contactEmploymentAlertTextObj = {
          title: 'Are you sure?',
          text: 'This record will be removed from your identification list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        };
        SweetAlert.swal(contactEmploymentAlertTextObj, (confirm) => {
          if (confirm) {
            loanScenarioService.IdentificationDelete(contactEmploymentIdentificationId).then(() => {
              $timeout(() => {
                $scope.GetIdentification();
                SweetAlert.swal({
                  title: 'Success',
                  text: 'Identification has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }, 100);
            });
          }
        });
      },
      changeIdentificationClient(list) {
        if (!angular.isUndefined($scope.IdentificationSet.ClientEntityId)) {
          const clientEntity = _.find(list, (o) => {
            return parseInt(o.PersonId, 10) === parseInt($scope.IdentificationSet.ClientEntityId, 10);
          }
          );
          $scope.IdentificationSet.NameOnDoc = clientEntity.PersonId ? (clientEntity.FullName) : clientEntity.Name;
        } else {
          $scope.IdentificationSet.NameOnDoc = '';
        }
      },
      isCountry: uiService.isCountry,

    });


    /**
     * Initiate Controller
     * */
    $scope.init = () => {
      $scope.getDocType();
      $scope.getCountryList();
      $scope.getDocs($scope.familyId);
      $scope.getEmploymentBasis();
      $scope.loanAppSharedData.getEmploymentType();
      $scope.loanAppSharedData.getEmploymentStatus();
      $scope.loanAppSharedData.getEmployerType();
      $scope.loanAppSharedData.getIncomeType();
      $scope.loanAppSharedData.getIncomeVerificationType();
      $scope.loanAppSharedData.getFrequency();
      $scope.getEmployment($scope.familyId);
      $scope.selectedLookup = '';
      $scope.clientsGet();
      $scope.identificationInit();
      $scope.isCurrentEmploymentStatusShown = configService.feature && configService.feature.currentEmploymentStatus;
    };

    $scope.init();
  });
