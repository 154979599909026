
export function jasperReportBuilderForUI(report) {
  return {
    reportTypeSettingsId: report.ReportTypeSettingsId,
    reportTypeId: report.ReportTypeId,
    reportTypeDesc: report.ReportTypeDesc,
    isEnabled: report.IsEnabled,
    canCorpAccess: report.CanCorpAccess,
    canAdviserAccess: report.CanAdviserAccess,
    otherSettings: report.OtherSettings,
    corpUserUrl: report.CorpUserURL,
    adviserUserUrl: report.AdviserUserURL,
  };
}

