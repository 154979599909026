import angular from 'angular';

angular.module('app').controller('DocumentPreviewCtrl', function DocumentPreviewCtrl(
  $scope, $uibModalInstance, SweetAlert, documentObject, generalService
) {
  documentObject.Title = documentObject.Name;

  if (angular.isArray(documentObject.Tags))
    documentObject.Tags = documentObject.Tags.join(',');

  documentObject.Title = documentObject.Name;
  documentObject.readOnly = true;
  $scope.documentObject = documentObject;
  $scope.documentThumbnail = {};
  generalService.documentThumbnail(documentObject).then((response) => {
    $scope.documentThumbnail = response && response.data;
  });
  $scope.isDocReadonly = true;
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
