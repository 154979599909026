import angular from 'angular';
import _ from 'lodash';
import {
  INSURANCE_DATA,
  INSURANCE_OPTIONS,
  COVER_TYPES,
} from 'Common/constants/insuranceOptions';
import { COVER_TYPE } from 'Common/constants/coverType';
import { isValidPersonInfo, isValidBenefitInfo } from 'Common/utilities/insuranceValidation';
import { isBoolean } from 'Common/utilities/objectValidation';
import { onWaitingPeriodChanged } from 'Common/utilities/insuranceQuoteEventListener';
import { grammaticallySeparateByComma } from 'Common/utilities/string';
import {
  generateAgeList,
  sortPeopleEntities,
  launchInsuranceQuoteReportModal,
  getBenefitCover,
  getBenefitFieldsConfig,
  getBenefitIdByIndex,
  getBenefitIndexById,
  getBenefitYears,
  canHaveMultipleCovers,
  countBenefitCovers,
  isSecondCover,
  isWaiverOfPremiumDependent,
  getInsuranceBenefitName,
} from 'Common/utilities/insurance';
import { displayError } from 'Common/utilities/alert';

const isPersonEqualTo = (personToCheck, checker) => {
  const isPersonByClientId = personToCheck.ClientId && personToCheck.ClientId === checker.ClientId;
  const isPersonByIndexId = (personToCheck.indexId || personToCheck.indexId === 0) && personToCheck.indexId === checker.indexId;
  return isPersonByClientId || isPersonByIndexId;
};

class NewQouteCtrl {
  constructor(
    $localStorage,
    $stateParams,
    $state,
    $http,
    $uibModal,
    $window,
    $timeout,
    insuranceQuoteService,
    toaster,
    loanProfilerService,
    commonFnService,
    insuranceSharedData,
    timeoutService,
    LimitClients,
    insuranceModalApplicationService
  ) {
    'ngInject';

    this.$localStorage = $localStorage;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.$timeout = $timeout;
    this.insuranceQuoteService = insuranceQuoteService;
    this.insuranceSharedData = insuranceSharedData;
    this.toaster = toaster;
    this.loanProfilerService = loanProfilerService;
    this.commonFnService = commonFnService;
    this.timeoutService = timeoutService;
    this.LimitClients = LimitClients;

    this.isHiddenClientForNz = false;
    this.selectedInsurance = false;
    this.policyTypeList = [];

    // initialize module data
    this.calculateQuoteText = 'Calculate Quote';
    this.queryType = INSURANCE_DATA.QUOTE;
    this.CLIENT_TYPE_OPTIONS = INSURANCE_OPTIONS.CLIENT_TYPE;
    this.coverType = COVER_TYPE;
    this.benefitPeriodList = [];
    this.tempBenefitYear = 1;
    this.isNewClient = true;
    this.isFromProfilerNew = false;
    this.clientType = this.CLIENT_TYPE_OPTIONS.NEW;
    this.YEARLY_RENEWABLE = 1;

    // Insurance quote Right table data
    this.insurersList = [];
    this.qprRatingMax = 5;
    this.isReadonly = true;

    // Add some flag for validtion when calculate button is clicked
    this.validationFlag = true;

    // Add class for the first load to avoid validationFlag to overtake original design
    this.originalStyle = true;
    this.starValue = 0;
    this.indexId = 0;
    this.selectedBenefit = {
      typeId: 0,
    };
    this.formData = this.insuranceSharedData.getNewQuoteEntityInput('Quick Quote', 'quote');
    this.formData.prefix = `quote`;
    this.formData.savingNew = !$stateParams.insQuoteId;
    this.formData.quoteId = parseInt(this.$stateParams.insQuoteId, 10);
    this.formData.FamilyID = this.$stateParams.familyId || 0;
    this.clientNameTemporary = null;
    this.companyCodeTemporary = null;
    this.clientNamePrint = null;
    this.quoteId = parseInt(this.$stateParams.insQuoteId, 10);
    this.insuranceQuoteId = this.quoteId || 0;
    this.insuranceQuoteIdGet = this.quoteId || 0;
    this.profilerId = parseInt(this.$stateParams.profilerId, 10) || 0;
    this.selectedClient = {
      FamilyID: this.$stateParams.familyId || 0,
    };
    this.disableCalculateQuote = true;
    this.showSaveQuoteButton = false;
    this.showSaveToContactButton = false;
    this.isEdits = !!this.$stateParams.insQuoteId;
    this.isFromProfiler = !!this.$stateParams.profilerId;
    this.isMobileDisplayView = false;
    this.showMobileSidebarOptions = true;
    this.selectedColumnList = this.insuranceSharedData.sharedData.selectedColumnList;
    this.selectedColumn = {
      id: 3,
    };
    this.tempThis = '';
    this.fullNameClient = [];
    this.fullNameDependent = [];
    this.adultAgeList = [];
    this.childAgeList = [];
    this.occupationList = [];
    this.acceleratedList = [];
    this.addedBenefitWidget = 0;
    this.openedBenefitWidget = 0;
    this.closedBenefitWidget = 0;
    this.tempEditChildData = [];

    this.isBoolean = isBoolean;
    this.breadcrumbsEnabled = true;

    this.onWaitingPeriodChanged = onWaitingPeriodChanged;
    this.INSURANCE_CLIENTS = {
      MAX: 2,
      MORE: 3,
    };
    this.getInsuranceBenefitName = getInsuranceBenefitName;
    this.insuranceCreateApp = insuranceModalApplicationService;
  }

  $onDestroy() {
    this.$timeout.cancel(this.processTimeOut);
    this.timeoutService.cancelTimeouts('NewInsuranceQuoteCtrl');
  }

  ageType(type) {
    this[`${type}AgeList`] = generateAgeList(type);
  }

  createFullName(people, idx) {
    if (people) {
      const tempName = !people.IsChild ? 'Client' : 'Dependent';
      const clienttype = `fullName${tempName}`;
      if (!this[clienttype][idx]) {
        this[clienttype].push(`${(people.FirstName || `${tempName} ${idx + 1}`)} ${(people.LastName || '')}`);
      } else {
        this[clienttype][idx] = `${(people.FirstName || `${tempName} ${idx + 1}`)} ${(people.LastName || '')}`;
      }
    }
  }

  checkDisplayButtonType() {
    if (this.formData.savingNew || this.isFromProfilerNew || this.isFromProfiler) {
      this.showSaveToContactButton = true;
      this.showSaveQuoteButton = false;
    } else {
      this.showSaveToContactButton = false;
      this.showSaveQuoteButton = true;
    }
  }

  dobAgeUpdate(index) {
    this.formData.PeopleEntity[index].DateOfBirth = '';
    this.formData.PeopleEntity[index].dobChunked = { year: '0' };
  }

  initFunctions() {
    this.commonFnService.hiddenClientForNz().then((isHidden) => {
      this.isHiddenClientForNz = isHidden;
    });

    // Frequency datas goes here
    this.insuranceQuoteService.insuranceQuoteFrequencyGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.frequencyList = response.data;
      }
    });

    this.insuranceQuoteService.insuranceQuotePolicyTypeGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.policyTypeList = response.data;
        this.initIncomeProtectionWidgets();
      }
    });

    // Occupation list
    this.insuranceQuoteService.insuranceQuoteOccupationGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.occList = response.data;
      }
    });

    // Excess list
    this.insuranceQuoteService.insuranceQuoteExcessGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.excessList = response.data;
        this.initHealthCoverWidgets();
      }
    });

    // Loading list
    this.insuranceQuoteService.insuranceQuoteLoadingGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.loadingList = response.data;
        this.initHealthCoverWidgets();
        this.initLifeCoverWidgets();
        this.initTraumaCoverWidgets();
        this.initTPDCoverWidgets();
        this.initFamilyProtectionWidgets();
        this.initIncomeProtectionWidgets();
        this.initMortgageWidgets();
        this.initRedundancyWidgets();
        this.initWaiverOfPremiumWidgets();
      }
    });

    // Calculation Period list
    this.insuranceQuoteService.insuranceQuoteCalculationPeriodGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.calculationPeriodList = response.data;
        this.initLifeCoverWidgets();
        this.initTraumaCoverWidgets();
        this.initTPDCoverWidgets();
      }
    });

    // Week Wait Period list
    this.insuranceQuoteService.insuranceQuoteWeekWaitPeriodGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.weekWaitPeriodList = response.data;
        this.initIncomeProtectionWidgets();
        this.initMortgageWidgets();
        this.initWaiverOfPremiumWidgets();
      }
    });

    // Income Protection Benefit Period list
    this.insuranceQuoteService.insuranceQuoteIPBenefitPeriodGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.incomeProtectionBenefitPeriodList = response.data;
        this.initIncomeProtectionWidgets();
      }
    });

    this.insuranceQuoteService.insuranceQuoteMortgageBenefitPeriodGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.mortgageBenefitPeriodList = response.data;
        this.initMortgageWidgets();
      }
    });

    this.insuranceQuoteService.insuranceQuoteBenefitTypeGet().then((response) => {
      if (response.data && response.data.length > 0) {
        this.benefitPeriodList = response.data;
        this.initFamilyProtectionWidgets();
      }
    });

    this.insuranceQuoteService.insuranceQuoteBenefitGroupGet().then((response) => {
      if (response.data && response.data.length) {
        this.benefitTypeList = [{ ID: 0, Name: 'Add Benefit' }, ...response.data];
        if (!this.wopCheckedOnInit && this.formData.PeopleEntity) {
          this.formData.PeopleEntity.forEach((person) => {
            this.checkIsWOPRequired(person);
          });
          this.wopCheckedOnInit = true;
        }
      }
    });

    this.insuranceQuoteService.insuranceQuoteOwnAnyOccupationGet().then((response) => {
      if (response.data && response.data.length) {
        this.occupationList = response.data;
        this.initTPDCoverWidgets();
      }
    });

    this.insuranceQuoteService.insuranceQuoteAcceleratedGet().then((response) => {
      if (response.data && response.data.length) {
        this.acceleratedList = response.data;
        this.initTPDCoverWidgets();
      }
    });

    this.insuranceQuoteService.insuranceQuoteEmployedStatusGet().then((response) => {
      if (response.data && response.data.length) {
        this.employedStatusList = response.data;
      }
    });
  }

  insurerErrorMessage(value) {
    this.tempThis = '';
    let error = '';
    _.forEach(value.ErrorMessage, (msg) => {
      if (_.size(msg.Name) > 0) {
        error += `${msg.Name}.\n`;
      }
    });
    return error;
  }

  changedValue(item) {
    this.benefitYearsList = [];

    if (parseInt(item, 10) === 1) {
      for (let i = 2; i <= 30; i++) {
        this.benefitYearsList.push({ benefitYearID: i, Description: i, Value: i });
      }
    } else {
      for (let i = 31; i <= 70; i++) {
        this.benefitYearsList.push({ benefitYearID: i, Description: i, Value: i });
      }
    }

    this.initFamilyProtectionWidgets();
  }

  setIndexValue(cover, coverType, index, value) {
    this.formData.PeopleEntity[index].BenefitList[coverType - 1][cover].Indexed = parseInt(value, 10) === this.YEARLY_RENEWABLE;
  }

  calcPeriodChange(coverType, person, value) {
    const index = this.formData.PeopleEntity.indexOf(person);
    switch (coverType) {
    case COVER_TYPE.LIFE_COVER:
      this.setIndexValue('LifeCover', coverType, index, value);
      break;
    case COVER_TYPE.TOTAL_PERMANENT_DISABILITY:
      this.setIndexValue('TotalAndPermanentDisability', coverType, index, value);
      break;
    case COVER_TYPE.TRAUMA_COVER:
      this.setIndexValue('TraumaCover', coverType, index, value);
      break;
    default:
      this.setIndexValue('TraumaCover', coverType, index, value);
      break;
    }
  }

  closeBenefits(value, indexVal) {
    this[value + indexVal] = false;
  }

  starRating(value) {
    this.starValue = `${value}%`;
  }

  /** ***************CLIENT WIDGET or People Entity********************************* */
  updatePeopleEntityChild() {
    if ((this.formData.NumberOfChildren) > (this.formData.TotalClientCount - this.formData.NumberOfClients)) {
      this.formData.PeopleEntity.push(this.insuranceSharedData.getNewPeopleEntity());
      this.indexId += 1;
      if (this.formData.PeopleEntity && this.formData.PeopleEntity.length) {
        this.formData.PeopleEntity[this.formData.PeopleEntity.length - 1].indexId = this.indexId;
        this.formData.lastIndexChild = this.formData.PeopleEntity[this.formData.PeopleEntity.length - 1].indexId;
        this.formData.PeopleEntity[this.formData.PeopleEntity.length - 1].IsChild = true;
        this.formData.TotalClientCount++;
      }
    } else {
      for (let d = 0; d < this.formData.PeopleEntity.length; d++) {
        if (this.formData.PeopleEntity[d] && this.formData.PeopleEntity[d].indexId === this.formData.lastIndexChild) {
          this.addedBenefitWidget -= this.formData.PeopleEntity[d].AddedBenefitCounter;
          this.openedBenefitWidget -= this.formData.PeopleEntity[d].OpenWidgetBenefits;
          this.formData.PeopleEntity.splice(d, 1);
        }
      }
      this.formData.TotalClientCount--;
      for (let i = this.formData.PeopleEntity.length - 1; i > 0; i--) {
        if (this.formData.PeopleEntity[i] && this.formData.PeopleEntity[i].IsChild) {
          this.formData.lastIndexChild = this.formData.PeopleEntity[i].indexId;
        }
      }
    }
    this.disableCalculateQuoteFunction();
  }

  updatePeopleEntityAdult() {
    if ((this.formData.NumberOfClients) > (this.formData.TotalClientCount - this.formData.NumberOfChildren)) {
      this.formData.PeopleEntity.push(this.insuranceSharedData.getNewPeopleEntity());
      this.indexId += 1;
      if (this.formData.PeopleEntity && this.formData.PeopleEntity.length) {
        this.formData.PeopleEntity[this.formData.PeopleEntity.length - 1].indexId = this.indexId;
        this.formData.lastIndexAdult = this.formData.PeopleEntity[this.formData.PeopleEntity.length - 1].indexId;
        this.formData.TotalClientCount++;
      }
    } else {
      for (let d = 0; d < this.formData.PeopleEntity.length; d++) {
        if (this.formData.PeopleEntity[d] && this.formData.PeopleEntity[d].indexId === this.formData.lastIndexAdult) {
          this.addedBenefitWidget -= this.formData.PeopleEntity[d].AddedBenefitCounter;
          this.openedBenefitWidget -= this.formData.PeopleEntity[d].OpenWidgetBenefits;
          this.formData.PeopleEntity.splice(d, 1);
        }
      }
      this.formData.TotalClientCount--;
      for (let i = this.formData.PeopleEntity.length - 1; i > 0; i--) {
        if (this.formData.PeopleEntity[i] && !this.formData.PeopleEntity[i].IsChild) {
          this.formData.lastIndexAdult = this.formData.PeopleEntity[i].indexId;
        }
      }
    }
    this.disableCalculateQuoteFunction();
  }

  updatePeopleEntity(type) {
    this.formData.NumberOfClients = parseInt(this.formData.NumberOfClients, 10);
    this.formData.NumberOfChildren = parseInt(this.formData.NumberOfChildren, 10);
    switch (type) {
    case 'child':
      this.updatePeopleEntityChild();
      break;
    default:

      if (this.LimitClients.onlyTwoClientsQuote(this.formData.NumberOfClients)) {
        this.updatePeopleEntityAdult();
      } else this.formData.NumberOfClients = this.INSURANCE_CLIENTS.MAX;

      break;
    }
  }


  deleteClientWidget(indexId, type) {
    const person = this.formData.PeopleEntity.find(entity => entity.indexId === indexId);
    if (!person) return;

    if (person.ClientId) {
      const clientId = person.ClientId;
      const quoteId = this.insuranceQuoteId;
      this.insuranceQuoteService.insuranceQuoteByClientIDDelete({ clientId, quoteId })
        .then(() => {
          this.onClientDeleted(person, type);
        }, () => {
          this.toaster.pop('error', 'Error', `Unable to delete the ${type}.`);
        });
    } else {
      this.onClientDeleted(person, type);
    }
  }

  onClientDeleted(person, type) {
    this.addedBenefitWidget -= person.AddedBenefitCounter;
    this.openedBenefitWidget -= person.OpenWidgetBenefits;

    this.formData.PeopleEntity = _.filter(this.formData.PeopleEntity, people => people.indexId !== person.indexId);
    this.formData.familyInfo = [...this.formData.PeopleEntity];
    this.formData.TotalClientCount--;

    switch (type) {
    case 'child':
      this.formData.NumberOfChildren--;
      if (this.formData.PeopleEntity && this.formData.PeopleEntity.length) {
        this.formData.lastIndexChild = _.last(this.formData.PeopleEntity, (people) => {
          return people.isChild;
        }).indexId;
      }
      break;

    default:
      this.formData.NumberOfClients--;
      angular.forEach(this.formData.PeopleEntity, (people) => {
        if (!people.isChild) {
          this.formData.lastIndexAdult = people.indexId;
        } else {
          this.formData.lastIndexChild = 0;
        }
      });
      break;
    }
    this.disableCalculateQuoteFunction();
  }

  disableCalculateQuoteFunction() {
    const numOfClientsWithBenefits = this.formData.PeopleEntity.filter((person) => {
      return person.AddedBenefitCounter > 0 && !person.IsChild;
    }).length;

    const isValidQuoteData = this.addedBenefitWidget > 0 && this.openedBenefitWidget === 0 && numOfClientsWithBenefits > 0;
    this.disableCalculateQuote = !isValidQuoteData;
  }

  countOpenBenefitWidget(isExisting, isOpened, person) {
    if (!person) return;

    if (!isExisting) {
      this.addedBenefitWidget += 1;
      person.AddedBenefitCounter += 1;
    }

    if (!isOpened) {
      this.openedBenefitWidget += 1;
      person.OpenWidgetBenefits += 1;
    }

    this.disableCalculateQuoteFunction();
  }

  selectedBenefitChange(benefitType, personIndexValue, type, person) {
    const numberOfCovers = countBenefitCovers(benefitType, person.BenefitList);
    const doGetSecondCover = canHaveMultipleCovers(benefitType) && numberOfCovers > 0;
    const benefitIndex = getBenefitIndexById(benefitType, doGetSecondCover);
    const selectedBenefit = person.BenefitList[benefitIndex];
    const isExistingBenefit = !!selectedBenefit.BenefitId;
    const isNewBenefit = selectedBenefit.isNew;

    selectedBenefit.BenefitId = benefitType;
    selectedBenefit.isNew = true;

    if (isWaiverOfPremiumDependent(benefitType)) {
      this.addWaiverOfPremium(person);
    }

    this.selectedBenefit.typeId = 0;
    this.countOpenBenefitWidget(isExistingBenefit, isNewBenefit, person);
    this.checkIsWOPRequired(person);
  }

  addWaiverOfPremium(person) {
    const wopBenefit = person.BenefitList[getBenefitIndexById(COVER_TYPE.WAIVER_OF_PREMIUM)];
    const isExisting = !!wopBenefit.BenefitId;
    const isOpened = wopBenefit.isNew;
    this.countOpenBenefitWidget(isExisting, isOpened, person);
    wopBenefit.BenefitId = COVER_TYPE.WAIVER_OF_PREMIUM;
    wopBenefit.isNew = true;
  }

  traumaCoverValueParser(options) {
    if (!options) return;
    this.tempThis = '';
    options.Loading = parseInt(options.Loading, 10);
    options.CoverAmount = parseInt(options.CoverAmount, 10);
    if (options.OptionValue === 'Accelerated') {
      options.Accelerated = true;
      options.Standalone = false;
    } else if (options.OptionValue === 'Standalone') {
      options.Accelerated = false;
      options.Standalone = true;
    }

    if (options.OptionValue2 === 'TpdOwnOccupation') {
      options.TpdOwnOccupation = true;
      options.TpdAnyOccupation = false;
    } else if (options.OptionValue2 === 'TpdAnyOccupation') {
      options.TpdOwnOccupation = false;
      options.TpdAnyOccupation = true;
    }
    return options;
  }

  tpdCoverValueParser(options) {
    this.tempThis = '';
    if (options) {
      options.Loading = parseInt(options.Loading, 10);
      options.CoverAmount = parseInt(options.CoverAmount, 10);

      if (options.OptionValue === 'TpdOwnOccupation') {
        options.OwnOccupation = true;
        options.AnyOccupation = false;
      } else if (options.OptionValue === 'TpdAnyOccupation') {
        options.OwnOccupation = false;
        options.AnyOccupation = true;
      }

      if (options.OptionValue2 === 'Accelerated') {
        options.Accelerated = true;
        options.Standalone = false;
      } else if (options.OptionValue2 === 'Standalone') {
        options.Accelerated = false;
        options.Standalone = true;
      }
    }
    return options;
  }

  healthCoverValueParser(options) {
    this.tempThis = '';
    if (options) {
      options.Loading = parseInt(options.Loading, 10);
      options.Excess = parseInt(options.Excess, 10);
    }
    return options;
  }

  lifeCoverValueParser(options) {
    this.tempThis = '';
    if (options) {
      options.Loading = parseInt(options.Loading, 10);
      options.CoverAmount = parseInt(options.CoverAmount, 10);
      options.CalcPeriod = parseInt(options.CalcPeriod, 10);
    }
    return options;
  }

  familyProtectionValueParser(options) {
    this.tempThis = '';
    if (options) {
      options.Loading = parseInt(options.Loading, 10);
      options.CoverAmount = parseInt(options.CoverAmount, 10);
      options.BenefitPeriodTerm = parseInt(options.BenefitPeriodTerm, 10);
      options.BenefitPeriodYear = parseInt(options.BenefitPeriodYear, 10);
    }
    return options;
  }

  incomeProtectionValueParser(options) {
    this.tempThis = '';
    if (options) {
      options.CoverAmount = parseInt(options.CoverAmount, 10);
      options.Loading = parseInt(options.Loading, 10);
      options.WeekWaitPeriod = parseInt(options.WeekWaitPeriod, 10);
      options.BenefitPeriod = parseInt(options.BenefitPeriod, 10);
      options.IsTaxable = parseInt(options.IsTaxable, 10);
    }
    return options;
  }

  mortgageRapaymentValueParser(options) {
    this.tempThis = '';
    if (options) {
      options.Loading = parseInt(options.Loading, 10);
      options.CoverAmount = parseInt(options.CoverAmount, 10);
      options.WeekWaitPeriod = parseInt(options.WeekWaitPeriod, 10);
      options.BenefitPeriod = parseInt(options.BenefitPeriod, 10);
    }
    return options;
  }

  redundacyValueParser(options) {
    this.tempThis = '';
    if (options) {
      options.Loading = parseInt(options.Loading, 10);
      options.CoverAmount = parseInt(options.CoverAmount, 10);
    }
    return options;
  }

  waiverValueParser(options) {
    if (!options) return;
    this.tempThis = '';
    options.Loading = parseInt(options.Loading, 10);
    options.WeekWaitPeriod = parseInt(options.WeekWaitPeriod, 10);
    return options;
  }

  checkProcessType(clientId, isNewClient) {
    if (this.formData.isSubmitted && clientId) {
      return false;
    }
    if (clientId === 0) {
      return true;
    }
    if (this.queryType === INSURANCE_DATA.PROFILER) {
      return true;
    }
    if (this.queryType === INSURANCE_DATA.QUOTE && this.isEdits) {
      return false;
    }
    if (this.queryType === INSURANCE_DATA.QUOTE && !this.isEdits) {
      return true;
    }
    if (this.queryType === INSURANCE_DATA.QUOTE && !isNewClient) {
      return true;
    }
    return false;
  }

  runPostProcess(people) {
    this.formData.isSubmitted = true;
    this.formData.PeopleEntity = [...people];
    this.formData.familyInfo = [...people];
    this.formData.quoteId = this.$stateParams.insQuoteId;
    this.setInsuranceClientBenefit();
  }

  saveFinalData() {
    this.inProgress = true;
    const people = this.formData.PeopleEntity.map((person) => {
      const benefits = person.BenefitList.map((benefit) => {
        return {
          ...benefit,
          BenefitId: parseInt(benefit.BenefitId, 10),
          HealthCover: this.healthCoverValueParser(benefit.HealthCover),
          TraumaCover: this.traumaCoverValueParser(benefit.TraumaCover),
          TotalAndPermanentDisability: this.tpdCoverValueParser(benefit.TotalAndPermanentDisability),
          LifeCover: this.lifeCoverValueParser(benefit.LifeCover),
          FamilyProtection: this.familyProtectionValueParser(benefit.FamilyProtection),
          IncomeProtection: this.incomeProtectionValueParser(benefit.IncomeProtection),
          MortgageRepaymentCover: this.mortgageRapaymentValueParser(benefit.MortgageRepaymentCover),
          RedundancyCover: this.redundacyValueParser(benefit.RedundancyCover),
          WaiverOfPremium: this.waiverValueParser(benefit.WaiverOfPremium),
        };
      });

      return {
        ...person,
        Age: parseInt(person.Age, 10),
        Occupation: parseInt(person.Occupation, 10),
        EmployedStatus: parseInt(person.EmployedStatus, 10),
        benefits,
      };
    });

    this.assignClientIds(people);
  }

  assignClientIds(people) {
    let clientIndex = 0;
    const peopleEntityLength = people.length;
    const isNewClient = this.formData.isNewClient;
    people.forEach((person) => {
      const clientId = parseInt(person.ClientId, 10);
      if (this.checkProcessType(clientId, isNewClient)) {
        this.insuranceQuoteService.insuranceClientIdGet().then((response) => {
          person.ClientId = response.data;
          clientIndex += 1;
          if (peopleEntityLength <= clientIndex) {
            this.runPostProcess(people);
          }
        });
      } else if (clientId > 0) {
        clientIndex += 1;
        if (peopleEntityLength <= clientIndex) {
          this.runPostProcess(people);
        }
      }
    });
  }

  parseData() {
    this.saveFinalData();
    this.showMobileSidebarOptions = false;
  }

  // When calculate button is Clicked
  calculateQuote() {
    if (!this.quoteForm.$valid || this.disableCalculateQuote) {
      this.showValidationMessages();
      this.quoteForm.$setSubmitted();
      return;
    }

    this.quoteForm.$setPristine();
    this.disableCalculateQuote = true;
    this.isCalculating = true;

    const calculateQuoteTimeout = this.$timeout(() => {
      // remove classname originalStyle
      this.originalStyle = false;

      // Catch those open widgets that are empty
      const fieldsChecker = this.$window.document.querySelectorAll('.validateArea .invalidField');

      // Check those open widgets that are empty
      if (fieldsChecker && fieldsChecker.length) {
        this.disableCalculateQuote = false;
        this.isCalculating = false;
        this.toaster.pop('error', 'Error', 'Please fill the required field');
        return;
      }
      this.calculateQuoteText = 'Calculating...';
      this.parseData();
      this.resetUIbehavior = false;
    }, 500);

    this.timeoutService.addTimeout(calculateQuoteTimeout, 'NewInsuranceQuoteCtrl');
  }

  showValidationMessages() {
    const { isInvalidFormData, isInvalidBenefitInfo, invalidPersonInfoNames } = this.getValidation();

    if (isInvalidFormData) {
      this.toaster.pop('error', 'Error', 'Something went wrong with the Quick Quote form.');
      return;
    }

    this.showWarningMessage(invalidPersonInfoNames, 'Please fill up all required fields for {names}');

    if (isInvalidBenefitInfo) {
      this.toaster.pop('warning', 'Warning', 'Please set at least one benefit for at least one adult.');
      return;
    }

    if (this.openedBenefitWidget > 0) {
      this.toaster.pop('warning', 'Warning', 'Please close all benefit widgets to calculate');
    }
  }

  showWarningMessage(names, messageFormat) {
    if (names && names.length) {
      const concatenatedNames = grammaticallySeparateByComma(names);
      this.toaster.pop('warning', 'Warning', messageFormat.replace('{names}', concatenatedNames));
    }
  }

  getValidation() {
    const isValidFormData = this.formData && this.formData.PeopleEntity && this.formData.PeopleEntity.length;
    if (!isValidFormData) return { isInvalidFormData: true };

    let currentClientIndex = -1;
    let currentDependentIndex = -1;
    const validation = this.formData.PeopleEntity.reduce((accum, person) => {
      let personName;
      if (!person.IsChild) {
        currentClientIndex++;
        personName = this.fullNameClient[currentClientIndex];
      } else {
        currentDependentIndex++;
        personName = this.fullNameDependent[currentDependentIndex];
      }
      if (!isValidPersonInfo(person)) {
        accum.invalidPersonInfoNames = [...accum.invalidPersonInfoNames, personName];
      }

      return accum;
    }, {
      invalidPersonInfoNames: [],
    });

    const clientsWithValidBenefits = this.formData.PeopleEntity.filter(person => !person.IsChild && isValidBenefitInfo(person));
    validation.isInvalidBenefitInfo = !clientsWithValidBenefits.length;
    return validation;
  }

  insuranceClientBenefitsSet(obj, quoteId, caller) {
    obj.quoteId = quoteId;
    this.insuranceQuoteService.insuranceClientBenefitsSet(obj, quoteId).then((response) => {
      if (response.data) {
        this.quoteMonsterGet(caller);
      }
    });
  }

  insuranceQuoteSet(caller) {
    const familyId = caller === 'saveToContact' ? this.formData.FamilyID : 0;
    this.insuranceQuoteService.insuranceQuoteSet(
      this.insuranceQuoteId,
      familyId,
      this.formData.NumberOfClients,
      this.formData.NumberOfChildren
    ).then((response) => {
      if (response.data) {
        this.insuranceQuoteId = response.data;
        this.formData.quoteId = this.insuranceQuoteId;

        if (this.selectedClient.FamilyID && this.selectedClient.FamilyID !== 0) {
          this.toaster.pop('success', 'Success', 'Quote was successfully saved to contact.');
        }

        switch (caller) {
        case 'saveToContact':
          this.setInsuranceClientBenefit(caller);
          break;
        case 'setInsuranceClientBenefit':
          this.insuranceClientBenefitsSet(this.formData, this.insuranceQuoteId, caller);
          break;
        default:
          break;
        }
      } else {
        this.toaster.pop('error', 'Error', 'Error saving quote!');
      }
    });
  }

  setInsuranceClientBenefit(caller) {
    if (parseInt(this.insuranceQuoteId, 10) === 0) {
      this.insuranceQuoteSet('setInsuranceClientBenefit');
    } else {
      this.insuranceClientBenefitsSet(this.formData, this.insuranceQuoteId, caller || 'setInsuranceClientBenefit');
    }
  }

  mapRatings(ratingData) {
    const obj = {
      ratingData,
    };
    _.forEach(this.insurersList, (insurer) => {
      const filterObj = _.filter(obj.ratingData, (rating) => {
        return rating.Id === insurer.ProviderId;
      });

      if (filterObj && filterObj.length) {
        insurer.QprRating = filterObj[0].StartRating;
        insurer.FinancialRating = filterObj[0].FinancialRating;
      }
    });
  }

  getRatings() {
    this.insuranceQuoteService.insuranceQuoteStarRatingGet(this.formData).then((response) => {
      const ratingData = response.data;
      if (ratingData && ratingData.length) {
        this.mapRatings(ratingData);
      }
      this.isLoadingRating = false;
    });
  }

  quoteMonsterGet(caller) {
    if (caller !== 'saveToContact') {
      this.insuranceQuoteService.insuranceQuoteMonsterGet(this.formData).then((response) => {
        const data = response.data;

        this.isLoadingRating = true;
        this.getRatings();

        _.forEach(data.ProviderResult, (obj) => {
          obj.IsSelected = false; // set all heart/selected as false
        });


        this.toaster.pop('success', 'Success', 'Quote successfully updated.');
        this.insurersList = data.ProviderResult;

        // can now save quote
        this.quoteCalculated = true;
      })
        .catch((error) => {
          displayError(error, false);
        })
        .finally(() => {
          this.inProgress = false;
          this.disableCalculateQuote = false;
          this.isCalculating = false;
          this.calculateQuoteText = 'Calculate Quote';
        });
    }
  }

  clientRowTitle(companyCode, value) {
    if (this.clientNameTemporary === value) {
      if (this.companyCodeTemporary === companyCode) {
        return 0;
      }
    }
    this.companyCodeTemporary = companyCode;
    this.clientNameTemporary = value;
    this.clientNamePrint = value;
    return 1;
  }

  proceedWithSelection(value) {
    // set heart selected in this.insurersList
    _.forEach(this.insurersList, (obj) => {
      if (obj.ProviderId === value.ProviderId) {
        obj.IsSelected = !obj.IsSelected;
      }
    });
  }

  reportPDF() {
    if (this.formData.isSubmitted) {
      const { insurersList, insuranceQuoteId: quoteId } = this;
      const reportPDF = {
        quoteId,
        insurersList,
        familyId: this.$stateParams.familyId,
        peopleEntity: this.formData.PeopleEntity,
      };
      launchInsuranceQuoteReportModal(this.$uibModal, reportPDF);
    }
  }

  viewBrochures() {
    this.$uibModal.open({
      templateUrl: '/assets/views/insuranceTools/modals/insurerComparison.html',
      controller: 'InsurerComparisonCtrl',
      controllerAs: 'vm',
      windowClass: 'insurer-comparison-modal-window',
      size: 'wide',
      resolve: {
        insurers: () => this.insurersList,
        quoteData: () => this.formData,
      },
    })
      .result.then((response) => {
        if (response) {
          this.initQuotes();
        }
      });
  }

  createNewLoanApp() {
    this.insuranceCreateApp.onCreate({
      typeNew: false,
      familyId: this.selectedClient.FamilyID,
      quoteId: this.insuranceQuoteIdGet,
      fullName: this.formData.FamilyFullName,
    });
  }

  // save quote to contact > select-contact > create new contact
  saveQuoteToContact() {
    if (this.quoteCalculated) {
      const quoteId = parseInt(this.insuranceQuoteIdGet, 10);
      const familyId = parseInt(this.selectedClient.FamilyID, 10);
      this.insuranceQuoteService.insuranceQuoteSet(
        quoteId,
        familyId,
        this.formData.NumberOfClients,
        this.formData.NumberOfChildren
      ).then((response) => {
        if (response.data) {
          this.formData[`${this.prefix}Id`] = response.data;
          this.toaster.pop('success', 'Success', 'Quote was successfully saved to contact.');
          if (this.close) this.close();
        } else {
          this.toaster.pop('error', 'Error', 'Error saving quote!');
        }
      });
    } else {
      this.toaster.pop('warning', 'Warning!', 'You calculate first before you can save this quote to a contact.');
    }
  }

  // Create new contact
  createNewContact() {
    this.$localStorage.loanProfilerCreateNewContact = true;
    this.$localStorage.previousState = 'app.loanProfiler';
    this.$localStorage.previousStateParams = { brokerEventId: this.brokerEventId, loanScenarioId: this.loanScenarioId, tab: this.$stateParams.tab };

    this.$state.go('app.createContact');
  }

  getSearchClient(searchString) {
    if (searchString.length >= 3) {
      return this.loanProfilerService.getSearchClient(searchString, 1).then((response) => {
        this.loanProfilerClientSearchList = [];
        this.SearchClientList = response.data;
        angular.forEach(response.data, (objItem) => {
          objItem.FullName = `${objItem.FirstName} ${objItem.LastName}`;
          this.loanProfilerClientSearchList.push(objItem);
        });
        return this.loanProfilerClientSearchList;
      });
    }
    this.innerResetObject();
    this.loanProfilerClientSearchList = [];
    return this.loanProfilerClientSearchList;
  }

  // select client
  selectClient(client) {
    this.selectedClient = client;
    this.selectedClientName = `${client.FirstName} ${client.LastName}`;
    this.showCreateNewContact = false;
    this.showClientList = false;
  }

  innerResetObject() {
    this.showCreateNewContact = true;
    this.SearchClientList = [];
    this.mainSearchObject = {};
    this.clientScenarioList = [];
    this.selectedClient = {};
  }

  // Save to contact
  saveToContact() {
    this.showSelectClientDropown = false;
    this.insuranceQuoteSet('saveToContact');
  }

  openAccordion(indexVal) {
    this[`accordionRow_${indexVal}`] = !this[`accordionRow_${indexVal}`];
  }
  openAccordionByColumns(length, indexVal) {
    this.error = length;
    if (!this.error) {
      this[`accordionRow_${indexVal}`] = !this[`accordionRow_${indexVal}`];
      this.resetUIbehavior = true;
    }
  }

  validationFunction(insuranceType, personIndex, validationFlag, originalStyle, showOptionVal, benefitIndex, type) {
    let tempClass = '';

    if (this[`insuranceType_${insuranceType}_${personIndex}`] && validationFlag) {
      tempClass += `validateArea `;
    }

    if (originalStyle) {
      tempClass += `originalStyle `;
    }

    if (this[`showOption${showOptionVal}_${personIndex}_${type}`]) {
      tempClass += `cleaned `;
    }

    if (this.formData.PeopleEntity && !this.formData.PeopleEntity[personIndex].IsChild && this.formData.PeopleEntity[personIndex].BenefitList[benefitIndex].BenefitId) {
      tempClass += `opened `;
    }

    return tempClass;
  }

  trashAndRemoveFunct(peopleVal, benefitVal, insuranceType, indexValue, benefitIndex, showOptionVal, type) {
    this.deleteBenefit(peopleVal, benefitVal);
    this.insuranceTypeClose(insuranceType, indexValue);
    peopleVal.BenefitList[benefitIndex].BenefitId = null;
    this[`showOption${showOptionVal}_${indexValue}_${type}`] = undefined;
    this.checkIsWOPRequired(peopleVal);
  }

  trashAction(peopleVal, benefitVal, insuranceType, personIndexValue, benefitIndex, showOptionVal, type) {
    const isWOPBenefit = parseInt(benefitVal.BenefitId, 10) === INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM;
    if (isWOPBenefit && peopleVal.isWOPRequired) {
      this.toaster.pop('warning', 'Warning', 'Waiver of Premium is required.');
      return;
    }

    this.trashAndRemoveFunct(peopleVal, benefitVal, insuranceType, personIndexValue, benefitIndex, showOptionVal, type);
    this.addedBenefitWidget -= 1;
    this.openedBenefitWidget -= 1;
    peopleVal.AddedBenefitCounter -= 1;
    peopleVal.OpenWidgetBenefits -= 1;
    this.disableCalculateQuoteFunction();
  }

  trashRemoveAction(peopleVal, benefitVal, insuranceType, personIndexValue, benefitIndex, showOptionVal, type) {
    this.trashAndRemoveFunct(peopleVal, benefitVal, insuranceType, personIndexValue, benefitIndex, showOptionVal, type);
    this.addedBenefitWidget -= 1;
    peopleVal.AddedBenefitCounter -= 1;
    this.disableCalculateQuoteFunction();
  }

  onDeleteBenefit(benefitDetails) {
    const { benefitId, benefitIndex, person, isEditMode } = benefitDetails;
    if (!person) return;

    const isWOPBenefit = parseInt(benefitId, 10) === INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM;
    if (isWOPBenefit && person.isWOPRequired) {
      this.toaster.pop('warning', 'Warning', 'Waiver of Premium is required.');
      return;
    }

    if (isEditMode) this.onEditModeToggle(person, false);
    const benefitToDelete = person.BenefitList.find(benefit => benefit.BenefitId === benefitId);
    const personType = person.IsChild ? 'child' : 'adult';
    this.trashRemoveAction(person, benefitToDelete, benefitId, person.indexId, benefitIndex, benefitId, personType);
  }

  onSaveBenefit(benefitUpdates) {
    const { benefitId, benefitIndex, personIndex, personId } = benefitUpdates;
    const insuredPerson = this.formData.PeopleEntity.find((entity) => {
      return isPersonEqualTo(entity, { ClientId: personId, indexId: personIndex });
    });
    const savedBenefit = insuredPerson.BenefitList.find((benefit, index) => {
      return index === benefitIndex && benefit.BenefitId === benefitId;
    });
    if (savedBenefit) {
      savedBenefit.isNew = false;
    }
  }

  onEditModeToggle(person, toggled) {
    if (person) {
      person.OpenWidgetBenefits = person.OpenWidgetBenefits || 0;
      this.openedBenefitWidget += toggled ? 1 : -1;
      person.OpenWidgetBenefits += toggled ? 1 : -1;
      this.disableCalculateQuoteFunction();
    } else {
      this.disableCalculateQuote = true;
    }
  }

  showOptionUpdate(value, personIndexValue, type, benefitForm) {
    if (benefitForm) {
      benefitForm.$setSubmitted();
      if (!benefitForm.$valid) return;
    }

    this[`showOption${value}_${personIndexValue}_${type}`] = !this[`showOption${value}_${personIndexValue}_${type}`];

    const filteredPeopleByType = this.formData.PeopleEntity.filter((person) => {
      return type === 'child' ? person.IsChild : !person.IsChild;
    });
    const person = filteredPeopleByType[personIndexValue];

    if (person) {
      if (this[`showOption${value}_${personIndexValue}_${type}`]) {
        this.openedBenefitWidget -= 1;
        person.OpenWidgetBenefits -= 1;
      } else if (!this[`showOption${value}_${personIndexValue}_${type}`]) {
        this.openedBenefitWidget += 1;
        person.OpenWidgetBenefits += 1;
      }
      this.disableCalculateQuoteFunction();
    } else {
      this.disableCalculateQuote = true;
    }
  }

  getTotalCount(people, type) {
    this.totalCount = 0;
    if (people) {
      angular.forEach(people, (member) => {
        switch (type) {
        case 'child':
          this.totalCount += member.IsChild ? 1 : 0;
          break;
        case 'adult':
          this.totalCount += !member.IsChild ? 1 : 0;
          break;
        default:
          break;
        }
      });
      return this.totalCount;
    }
  }

  getChildCount(quoteData) {
    if (quoteData.NoOfDependents) {
      return quoteData.NoOfDependents;
    }
    return this.getTotalCount(quoteData.PeopleEntity, 'child');
  }

  getAdultCount(quoteData) {
    if (quoteData.NoOfClients) {
      return quoteData.NoOfClients;
    }
    return this.getTotalCount(quoteData.PeopleEntity, 'adult');
  }

  initQuotes() {
    this.showSelectClientDropown = false;
    this.quoteCalculated = !!this.$stateParams.insQuoteId;

    let queryId = this.insuranceQuoteId;

    if (this.isFromProfiler) {
      queryId = this.profilerId;
      this.queryType = INSURANCE_DATA.PROFILER;
    }

    // instantiate formdata - insQuoteDetails
    if (this.isEdits || this.isFromProfiler) {
      this.formData.isNewClient = this.isFromProfiler ? !this.$state.params.familyId : false;
      this.formData.isFromProfiler = this.isFromProfiler;
      this.formData.quoteId = this.insuranceQuoteId;
      this.insuranceQuoteService.insuranceQuoteGet(this.queryType, queryId).then((response) => {
        if (response.data && response.data.length) {
          const quoteData = response.data[0];
          quoteData.PeopleEntity = sortPeopleEntities(quoteData.PeopleEntity);
          this.selectedClient.FamilyID = quoteData.FamilyID || 0;
          this.insuranceQuoteIdGet = quoteData.QuoteID;
          this.formData.Frequency = 12;
          this.formData.FamilyID = this.$stateParams.familyId ? this.$stateParams.familyId : this.selectedClient.FamilyID;
          this.isFromProfilerNew = !quoteData.FamilyID;
          this.formData.savingNew = !this.formData.FamilyID;
          this.formData.quoteId = this.$stateParams.insQuoteId;
          this.formData.FamilyFullName = quoteData.FamilyFullName;
          this.formData.NumberOfChildren = this.getChildCount(quoteData);
          this.formData.NumberOfClients = this.getAdultCount(quoteData);
          this.formData.PeopleEntity = [];
          this.processTimeOut = this.$timeout(() => {
            this.formData.PeopleEntity = angular.copy(quoteData.PeopleEntity);
            const isBenefitTypeListFetched = this.benefitTypeList;
            this.wopCheckedOnInit = isBenefitTypeListFetched;

            this.initBenefits('adult');
            this.initBenefits('child');

            const peopleEntity = this.formData.PeopleEntity;
            for (let personIndex = 0; personIndex < peopleEntity.length; personIndex++) {
              peopleEntity[personIndex].Smoker = quoteData.PeopleEntity[personIndex].Smoker;

              if (!peopleEntity[personIndex].IsChild) {
                for (let benefitIndex = 0; benefitIndex < peopleEntity[personIndex].BenefitList.length; benefitIndex++) {
                  const benefitId = peopleEntity[personIndex].BenefitList[benefitIndex].BenefitId;
                  const benefit = peopleEntity[personIndex].BenefitList[benefitIndex];

                  // tweak TC for UI
                  if (benefitId === INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER) {
                    if (benefit.TraumaCover.CoverAmount) {
                      benefit.TraumaCover.CoverAmount = benefit.TraumaCover.CoverAmount.toString();
                    }
                    // OptionValues
                    if (benefit.TraumaCover.Standalone) {
                      benefit.TraumaCover.Accelerated = 'false';
                      benefit.TraumaCover.Standalone = 'true';
                      benefit.TraumaCover.OptionValue = 'Standalone';
                    } else {
                      benefit.TraumaCover.Accelerated = 'true';
                      benefit.TraumaCover.Standalone = 'false';
                      benefit.TraumaCover.OptionValue = 'Accelerated';
                    }
                  }

                  // tweak TPD for UI
                  if (benefitId === INSURANCE_OPTIONS.BENEFIT_TYPE.TPD) {
                    // CoverAmount
                    if (benefit.TotalAndPermanentDisability.CoverAmount) {
                      benefit.TotalAndPermanentDisability.CoverAmount = benefit.TotalAndPermanentDisability.CoverAmount.toString();
                    }

                    // OptionValues
                    if (!benefit.TotalAndPermanentDisability.Standalone) {
                      benefit.TotalAndPermanentDisability.Accelerated = 'true';
                      benefit.TotalAndPermanentDisability.Standalone = 'false';
                      benefit.TotalAndPermanentDisability.OptionValue = 'TpdOwnOccupation';
                      benefit.TotalAndPermanentDisability.OptionValue2 = 'Accelerated';
                    } else {
                      benefit.TotalAndPermanentDisability.Accelerated = 'false';
                      benefit.TotalAndPermanentDisability.Standalone = 'true';
                      benefit.TotalAndPermanentDisability.OptionValue = 'TpdAnyOccupation';
                      benefit.TotalAndPermanentDisability.OptionValue2 = 'Standalone';
                    }
                  }

                  // tweak IsTaxable for UI
                  if (benefitId === INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION) {
                    const taxable = benefit.IncomeProtection.IsTaxable;
                    benefit.IncomeProtection.taxable = (taxable !== 1);
                  }

                  // tweak Trauma Type option value for UI
                  if (benefit && benefit.TraumaCover) {
                    benefit.TraumaCover.OptionValue = '';
                    if (peopleEntity[personIndex].BenefitList[benefitIndex].TraumaCover) {
                      benefit.TraumaCover.OptionValue = peopleEntity[personIndex].BenefitList[benefitIndex].TraumaCover.Standalone ? 'Standalone' : 'Accelerated';
                    } else {
                      benefit.TraumaCover.OptionValue = 'Accelerated';
                    }
                  }

                  peopleEntity[personIndex].BenefitList[benefitIndex] = benefit;
                  if (benefit && benefit.FamilyProtection) {
                    const obj = benefit.FamilyProtection.BenefitPeriodTerm ? benefit.FamilyProtection.BenefitPeriodTerm : 1;
                    this.changedValue(obj);
                  }
                }

                if (isBenefitTypeListFetched) {
                  this.checkIsWOPRequired(peopleEntity[personIndex]);
                }
              }
            }

            this.saveFinalData();
          });
        } else {
          this.toaster.pop('error', 'Error', 'Failed to load Insurance Quote details.');
          this.formData = this.insuranceSharedData.getNewQuoteEntityInput('Quick Quote', 'quote');
        }
      });
    } else {
      this.formData = this.insuranceSharedData.getNewQuoteEntityInput('Quick Quote', 'quote');
      this.formData.savingNew = true;
      this.isFromProfilerNew = false;
    }
  }

  initBenefits(personType) {
    const persons = this.formData.PeopleEntity.filter((person) => {
      return personType === 'adult' ? !person.IsChild : person.IsChild;
    });
    if (!persons || !persons.length) return;

    persons.forEach((person) => {
      const benefitList = this.insuranceSharedData.getBenefitsModel().map((emptyBenefit, index) => {
        const benefitId = getBenefitIdByIndex(index);
        const findSecondBenefit = canHaveMultipleCovers(benefitId) && isSecondCover(index);
        const personBenefits = person.BenefitList.filter(item => item.BenefitId === benefitId);
        const includedBenefit = findSecondBenefit ? personBenefits[1] || {} : personBenefits[0] || {};
        return { ...emptyBenefit, ...includedBenefit };
      });

      person.OpenWidgetBenefits = 0;
      person.AddedBenefitCounter = person.BenefitList.length;
      this.addedBenefitWidget += person.AddedBenefitCounter;
      person.BenefitList = benefitList;
    });
  }

  isTaxable(value, idx) {
    _.forEach(this.formData.PeopleEntity, (data) => {
      if (isPersonEqualTo(data, value)) {
        data.BenefitList[idx].IncomeProtection.IsTaxable = (data.BenefitList[idx].IncomeProtection.taxable ? 2 : 1);
      }
    });
  }

  checkIsWOPRequired(person) {
    if (!person || !this.benefitTypeList) return false;

    const { BenefitList } = person;
    const personHasBenefits = BenefitList && BenefitList.length;
    if (!personHasBenefits) return false;

    const isWOPRequired = _.some(BenefitList, (benefit) => {
      if (!benefit.BenefitId) return false;
      const benefitId = parseInt(benefit.BenefitId, 10);
      const includedBenefitType = this.benefitTypeList.find((benefitType) => {
        const benefitTypeId = parseInt(benefitType.ID, 10);
        return benefitId === benefitTypeId;
      });
      return includedBenefitType && includedBenefitType.WOPRequired;
    });

    person.isWOPRequired = isWOPRequired;
  }

  /** ************************************************************************* */
  // DONT ADD ANY FUNCTIONALITIES IN THE BOTTOM THAT ARE NOT RELATED TO MOBILE!!! ~ josandi thanks!
  // Mobile functionalities starts here, but data still dependends on funtionalities above

  showMobileSidebarOptionsFunction() {
    this.showMobileSidebarOptions = true;
  }

  insuranceTypeClose(value, indexVal) {
    this[`insuranceType_${value}_${indexVal}`] = false;
  }

  deleteBenefit(people, benefit) {
    if (!_.isNil() && this.insuranceQuoteId !== 0) {
      const obj = {
        clientId: people.ClientId,
        benefitId: benefit.BenefitId,
        quoteId: this.insuranceQuoteId,
      };

      this.insuranceQuoteService.insuranceBenefitDelete(obj).then((response) => {
        if (response.data && response.data === 1) {
          this.toaster.pop('success', 'Benefit Removed', 'Successfully removed a benefit.');
        }
      });
    }
  }

  showBenefitListUpdate(value, type) {
    if (this.insuranceType_6 || this.insuranceType_7) {
      this.insuranceType_9 = true;
    }
    this[`showBenefitList_${value}_${type}`] = !this[`showBenefitList_${value}_${type}`];
  }

  showAccordionRow(insurerTempData, insurersListTempData) {
    this.$uibModal.open({
      templateUrl: '/assets/views/insuranceTools/modals/insurerListModal.html',
      windowClass: 'insurersList-modal-window',
      controller: 'insurerListCtrl',
      resolve: {
        insurerTempData() {
          return insurerTempData;
        },
        insurersListTempData() {
          return insurersListTempData;
        },
      },
    });
  }

  settingsModal() {
    this.$uibModal.open({
      templateUrl: '/assets/views/insuranceTools/modals/quoteSettings.html',
      backdrop: false,
      keyboard: false,
      windowClass: 'quote-settings-modal',
      controller: 'QuoteSettingsCtrl',
    });
  }

  onClientTypeChange() {
    this.quoteId = 0;
    this.insuranceQuoteId = 0;
    this.insuranceQuoteIdGet = 0;
  }

  onNewClientSelected() {
    this.quoteId = 0;
    this.insuranceQuoteId = 0;
    this.insuranceQuoteIdGet = 0;
  }

  onBirthdayUpdated(updatedPersonData) {
    const { PeopleEntity: peopleEntity } = this.formData;
    const person = peopleEntity && peopleEntity.find(entity => isPersonEqualTo(entity, updatedPersonData));
    if (person) {
      person.Age = updatedPersonData.Age;
    }
  }

  onWidgetFieldUpdate(fieldUpdate) {
    const { benefitId, benefitIndex, personIndex, personId, field, value } = fieldUpdate;
    const person = this.formData.PeopleEntity.find(insured => isPersonEqualTo(insured, { ClientId: personId, indexId: personIndex }));
    const updatedBenefit = person.BenefitList.find((benefit, index) => {
      return benefit.BenefitId === benefitId && index === benefitIndex;
    });
    const benefitCover = getBenefitCover(updatedBenefit);
    benefitCover[field] = value;

    const doApplyValueToWOP = isWaiverOfPremiumDependent(benefitId) && field === 'WeekWaitPeriod';
    if (doApplyValueToWOP) {
      const wopBenefit = person.BenefitList.find(benefit => benefit.BenefitId === COVER_TYPE.WAIVER_OF_PREMIUM);
      const wopBenefitCover = getBenefitCover(wopBenefit);
      wopBenefit.WaiverOfPremium = { ...wopBenefitCover, WeekWaitPeriod: value };
    }
  }

  setBreadcrumbVisibility() {
    const checkBreadcrumbVisibility = this.$state.current.name === 'app.contactQuickQuote';
    if (checkBreadcrumbVisibility) {
      this.breadcrumbsEnabled = this.$state.params.isFromInsuranceTab === 'true';
    }
  }

  isCalcListLoaded() {
    return !!(this.calculationPeriodList && this.calculationPeriodList.length);
  }

  isLoadingListLoaded() {
    return !!(this.loadingList && this.loadingList.length);
  }

  isWaitPeriodListLoaded() {
    return !!(this.weekWaitPeriodList && this.weekWaitPeriodList.length);
  }

  initWidgets() {
    this.lifeCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.LIFE_COVER);
    this.traumaCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.TRAUMA_COVER);
    this.tpdCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.TOTAL_PERMANENT_DISABILITY);
    this.healthCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.HEALTH_COVER);
    this.familyProtectionFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.FAMILY_PROTECTION);
    this.incomeProtectionFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.INCOME_PROTECTION);
    this.mortgageFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.MORTGAGE_REPAYMENT);
    this.redundancyFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.REDUNDANCY_COVER);
    this.waiverOfPremiumFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.WAIVER_OF_PREMIUM);
  }

  initHealthCoverWidgets() {
    const isExcessListLoaded = !!(this.excessList && this.excessList.length);
    if (isExcessListLoaded && this.isLoadingListLoaded()) {
      this.healthCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.HEALTH_COVER, {
        excesses: this.excessList,
        loadings: this.loadingList,
      });
    }
  }

  initLifeCoverWidgets() {
    if (this.isCalcListLoaded() && this.isLoadingListLoaded()) {
      this.lifeCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.LIFE_COVER, {
        calcPeriods: this.calculationPeriodList,
        loadings: this.loadingList,
      });
    }
  }

  initTraumaCoverWidgets() {
    if (this.isCalcListLoaded() && this.isLoadingListLoaded()) {
      this.traumaCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.TRAUMA_COVER, {
        coverTypes: COVER_TYPES,
        calcPeriods: this.calculationPeriodList,
        loadings: this.loadingList,
      });
    }
  }

  initTPDCoverWidgets() {
    const occupationListLoaded = !!(this.occupationList && this.occupationList.length);
    const acceleratedListLoaded = !!(this.acceleratedList && this.acceleratedList.length);
    const allOptionsListLoaded = this.isCalcListLoaded() && this.isLoadingListLoaded() && occupationListLoaded && acceleratedListLoaded;
    if (allOptionsListLoaded) {
      this.tpdCoverFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.TOTAL_PERMANENT_DISABILITY, {
        coverTypes: this.acceleratedList,
        calcPeriods: this.calculationPeriodList,
        loadings: this.loadingList,
        occupations: this.occupationList,
      });
    }
  }

  initFamilyProtectionWidgets() {
    const benefitPeriodListLoaded = !!(this.benefitPeriodList && this.benefitPeriodList.length);
    const benefitYearsListLoaded = !!(this.benefitYearsList && this.benefitYearsList.length);
    const allOptionsListLoaded = benefitPeriodListLoaded && benefitYearsListLoaded && this.isLoadingListLoaded();
    if (allOptionsListLoaded) {
      this.familyProtectionFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.FAMILY_PROTECTION, {
        benefitPeriods: this.benefitPeriodList,
        benefitYearsFixed: getBenefitYears(true),
        benefitYearsAge: getBenefitYears(),
        loadings: this.loadingList,
      });
    }
  }

  initIncomeProtectionWidgets() {
    const isIncomeProtectionBenefitPeriodsLoaded = !!(this.incomeProtectionBenefitPeriodList && this.incomeProtectionBenefitPeriodList.length);
    const isPolicyTypesLoaded = !!(this.policyTypeList && this.policyTypeList.length);
    const allOptionsLoaded = isIncomeProtectionBenefitPeriodsLoaded && isPolicyTypesLoaded && this.isWaitPeriodListLoaded();
    if (allOptionsLoaded) {
      this.incomeProtectionFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.INCOME_PROTECTION, {
        waitPeriods: this.weekWaitPeriodList,
        benefitPeriods: this.incomeProtectionBenefitPeriodList,
        policyTypes: this.policyTypeList,
        loadings: this.loadingList,
      });
    }
  }

  initMortgageWidgets() {
    const isBenefitPeriodLoaded = !!(this.mortgageBenefitPeriodList && this.mortgageBenefitPeriodList.length);
    const allOptionsLoaded = this.isWaitPeriodListLoaded() && isBenefitPeriodLoaded && this.isLoadingListLoaded();
    if (allOptionsLoaded) {
      this.mortgageFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.MORTGAGE_REPAYMENT, {
        waitPeriods: this.weekWaitPeriodList,
        benefitPeriods: this.mortgageBenefitPeriodList,
        loadings: this.loadingList,
      });
    }
  }

  initRedundancyWidgets() {
    if (this.isLoadingListLoaded()) {
      this.redundancyFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.REDUNDANCY_COVER, this.loadingList);
    }
  }

  initWaiverOfPremiumWidgets() {
    if (this.isWaitPeriodListLoaded() && this.isLoadingListLoaded()) {
      this.waiverOfPremiumFieldsConfig = getBenefitFieldsConfig(COVER_TYPE.WAIVER_OF_PREMIUM, {
        waitPeriods: this.weekWaitPeriodList,
        loadings: this.loadingList,
      });
    }
  }

  $onInit() {
    this.changedValue(1);
    this.initQuotes();
    this.initFunctions();
    this.initWidgets();
    this.ageType('adult');
    this.ageType('child');
    this.checkDisplayButtonType();
    this.setBreadcrumbVisibility();
  }
}

angular.module('app').controller('NewQouteCtrl', NewQouteCtrl);
