import angular from 'angular';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('referralSource',
  function referralSource(
    $timeout,
    overviewDashboardService,
    corporateService,
    queryService,
    overviewDashboardDetailService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl: 'assets/views/overviewDashboard/directiveTemplates/referralSource.html',

      link(scope) {
        scope.tableMode = false;
        scope.chartMode = true;

        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };

        scope.labels = [];
        scope.data = [];
        scope.colors = [];
        scope.timePeriodArrays = [];
        scope.timePeriodArraysSelected = {};
        scope.referralSourcedata = [];

        //  Config
        scope.color_codes = [
          '#7f9fae',
          '#01bfbf',
          '#00bcd5',
          '#8cc052',
          '#b880c9',
          '#604faf',
        ];
        // Chart.js Options

        scope.options = overviewDashboardDetailService.getOptions();

        let currentDate = new Date();
        let getMonth = 0;

        function widgetOptionGet() {
          overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID).then((response) => {
            angular.forEach(response.data, (value) => {
              if (parseInt(value.WidgetOptionId, 10) === 15) {
                scope.timePeriodArraysSelected = scope.timePeriodArrays.filter(item => parseInt(item.OptionDisplayName, 10) === parseInt(value.DisplayValue, 10))[0];
              }
            });
          });
        }

        function referralSourceFn(month, fullYear) {
          scope.showSlick = false;
          const COUNT_PER_PAGE = 5;

          corporateService.loansSummaryReferralSplitGet(1, 0, month, fullYear).then((response) => {
            scope.data = [];
            scope.colors = [];
            scope.labels = [];

            const dataParent = response.data;
            if (dataParent.length > 0) {
              angular.forEach(dataParent, (splitVal, splitKey) => {
                splitVal.colorKey = splitKey;
                scope.data.push(splitVal.ReferralSourceAmountSplitPercent.toFixed(2));
                scope.labels.push('');
                scope.colors.push(scope.color_codes[splitKey % 7]);
              });
            } else {
              scope.data.push(0);
              scope.labels.push('');
              scope.colors.push('');
            }

            scope.referralSourcedata = queryService.queryPerPage(COUNT_PER_PAGE, dataParent);
            scope.showSlick = true;
            if (scope.referralSourcedata.length > 0) {
              $timeout(() => {
                try {
                  scope.slickConfig.method.slickGoTo(0);
                } catch (error) {
                // Continue regardless of error
                }
              }, 1000);
            }
          });
        }

        function saveForTimePeriod(data) {
          overviewDashboardService
            .WidgetOptionSet(data)
            .catch(displayError);
        }

        scope.updateTimePeriod = function (timeperiod) {
          const data = {};
          data.Widget_UserID = scope.myParam.Widget_UserID;
          data.Widget_OptionValue = timeperiod.Option_ValueID;
          data.WidgetOptionId = timeperiod.OptionID;
          data.SelectedUser = timeperiod.OptionDB_value;
          saveForTimePeriod(data);
        };

        function init() {
          corporateService.getWidgetOptionTimePeriodList6()
            .then((response) => {
              scope.timePeriodArrays = response.data;
            }, () => {

            }).then(() => {
              widgetOptionGet();
              scope.$watch('timePeriodArraysSelected', (newValue) => {
                let getFullYear;

                if (!currentDate) {
                  currentDate = new Date();
                }
                if (typeof newValue !== 'undefined') {
                  if (newValue.OptionDisplayName === 'Last Year') {
                    getMonth = 0;
                    getFullYear = currentDate.getFullYear() - 1;
                  } else {
                    getMonth = 0;
                    getFullYear = currentDate.getFullYear();
                  }

                  referralSourceFn(getMonth, getFullYear);
                }
              });

              if (!scope.timePeriodArraysSelected) {
                referralSourceFn(-100);
              }
            });
        }

        // Initialization
        init();
      },
    };
  });
