import angular from 'angular';
import moment from 'moment';

angular.module('app').controller('OpenMobileCalendarModalCtrl',
  function OpenMobileCalendarModalCtrl(
    $scope,
    $uibModalInstance,
    $uibModal,
    cancel,
    filterType,
    referrer,
    getReferrers,
    $filter
  ) {
    $scope.name = 'top';
    $scope.referrers = referrer;
    $scope.filterType = filterType;
    $scope.referrers.isFromDate = true;
    $scope.closeMobileCalendar = function () {
      $uibModalInstance.dismiss('cancel');
    };
    // $scope.getSetDate=getSetDate;
    // Calendar Picker for Mobile
    const getLastDayOfMonth = (dateParam) => {
      const dateData = new Date(dateParam);
      const year = dateData.getFullYear();
      const month = dateData.getMonth();
      const temp = new Date(year, month + 1, 0);
      const date = temp.getDate();

      return date;
    };

    const formatDate = (date, format) => {
      return $filter('date')(new Date(date), format);
    };

    $scope.loadMainCalender = function () {
      if (filterType !== 'Range')
        $scope.calendarSelectedDate = new Date();
      else
      if ($scope.referrers.isFromDate)
        $scope.calendarFromDate = new Date();
      else
        $scope.calendarToDate = new Date();

      const setDate = () => {
        let date;
        if (filterType !== 'Range')
          date = $scope.calendarSelectedDate;
        else
        if ($scope.referrers.isFromDate)
          date = $scope.calendarFromDate;
        else
          date = $scope.calendarToDate;

        $scope.currentYear = new Date(date).getFullYear();
        $scope.currentMonth = new Date(date).getMonth();
        $scope.currentMonthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][$scope.currentMonth];
        $scope.currentDay = new Date(date).getDate();
        $scope.lastDay = getLastDayOfMonth(date);
        // getStatementForWeek();
      };

      const filterDateType = (date, filterTypeCheck) => {
        let returnValue;
        if (filterTypeCheck !== 'Range') {
          returnValue = $scope.calendarSelectedDate;
        }
        if ($scope.referrers.isFromDate) {
          returnValue = $scope.calendarFromDate;
        }
        returnValue = $scope.calendarToDate;
        return returnValue;
      };

      const nextMonth = () => {
        let date;
        const dateFiltered = filterDateType(date, filterType);

        const currentMonth = new Date(dateFiltered);
        if (currentMonth.getMonth() === 11) {
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = new Date(currentMonth.getFullYear() + 1, 0, 1);
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = new Date(currentMonth.getFullYear() + 1, 0, 1);
          else
            $scope.calendarToDate = new Date(currentMonth.getFullYear() + 1, 0, 1);
          setDate();
        } else {
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
          else
            $scope.calendarToDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
          setDate();
        }
      };
      const prevMonth = () => {
        let date;
        const dateFiltered = filterDateType(date, filterType);

        const currentMonth = new Date(dateFiltered);
        if (currentMonth.getMonth() === 0) {
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = new Date(currentMonth.getFullYear() - 1, 11, 1);
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = new Date(currentMonth.getFullYear() - 1, 11, 1);
          else
            $scope.calendarToDate = new Date(currentMonth.getFullYear() - 1, 11, 1);

          setDate();
        } else {
          const lastDay = getLastDayOfMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));

          if (filterType !== 'Range')
            $scope.calendarSelectedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, lastDay);
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, lastDay);
          else
            $scope.calendarToDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, lastDay);

          setDate();
        }
      };
      setDate();
      $scope.increaseDay = function () {
        if ($scope.currentDay < $scope.lastDay) {
          $scope.currentDay++;
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = formatDate(new Date(`${$scope.currentMonth + 1}/${$scope.currentDay}/${$scope.currentYear}`), 'yyyy-MM-dd');
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = formatDate(new Date(`${$scope.currentMonth + 1}/${$scope.currentDay}/${$scope.currentYear}`), 'yyyy-MM-dd');
          else
            $scope.calendarToDate = formatDate(new Date(`${$scope.currentMonth + 1}/${$scope.currentDay}/${$scope.currentYear}`), 'yyyy-MM-dd');
          setDate();
        } else {
          nextMonth();
        }
      };
      $scope.decreaseDay = function () {
        if ($scope.currentDay > 1) {
          $scope.currentDay--;
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = formatDate(new Date(`${$scope.currentMonth + 1}/${$scope.currentDay}/${$scope.currentYear}`), 'yyyy-MM-dd');
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = formatDate(new Date(`${$scope.currentMonth + 1}/${$scope.currentDay}/${$scope.currentYear}`), 'yyyy-MM-dd');
          else
            $scope.calendarToDate = formatDate(new Date(`${$scope.currentMonth + 1}/${$scope.currentDay}/${$scope.currentYear}`), 'yyyy-MM-dd');
          setDate();
        } else {
          prevMonth();
        }
      };
      $scope.nextMonthly = function () {
        let date;
        const dateFiltered = filterDateType(date, filterType);

        const currentMonth = new Date(dateFiltered);
        const lastDay = new Date(currentMonth.getFullYear() + 1, 0, 1);
        if (currentMonth.getMonth() === 11) {
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = formatDate(new Date(currentMonth.getFullYear() + 1, 0, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = formatDate(new Date(currentMonth.getFullYear() + 1, 0, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
            $scope.calendarToDate = formatDate(new Date(currentMonth.getFullYear() + 1, 0, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');

          setDate();
        } else {
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
            $scope.calendarToDate = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');

          setDate();
        }
      };
      $scope.prevMonthly = function () {
        let date;
        const dateFiltered = filterDateType(date, filterType);

        const currentMonth = new Date(dateFiltered);
        const lastDay = getLastDayOfMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
        if (currentMonth.getMonth() === 0) {
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = formatDate(new Date(currentMonth.getFullYear() - 1, 11, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = formatDate(new Date(currentMonth.getFullYear() - 1, 11, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
            $scope.calendarToDate = formatDate(new Date(currentMonth.getFullYear() - 1, 11, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');

          setDate();
        } else {
          if (filterType !== 'Range')
            $scope.calendarSelectedDate = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
          if ($scope.referrers.isFromDate)
            $scope.calendarFromDate = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');
          else
            $scope.calendarToDate = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, (lastDay < $scope.currentDay) ? lastDay : $scope.currentDay), 'yyyy-MM-dd');

          setDate();
        }
      };
    };

    $scope.loadMainCalender();
    $scope.setPickedDate = function () {
      if (filterType === 'On') {
        $scope.referrers.dateCreated = moment($scope.calendarSelectedDate).format('DD MMM YYYY');
        $scope.referrers.createDateFrom = moment($scope.calendarSelectedDate).format('DD MMM YYYY');
        $scope.referrers.createDateTo = moment($scope.calendarSelectedDate).format('DD MMM YYYY');
      }
      if (filterType === 'Before') {
        $scope.referrers.dateCreated = `Before ${moment($scope.calendarSelectedDate).format('DD MMM YYYY')}`;
        const beforeDay = moment($scope.calendarSelectedDate).subtract(1, 'day');
        $scope.referrers.createDateTo = moment(beforeDay).format('DD MMM YYYY');
        $scope.referrers.createDateFrom = moment('1 Jan 2015').format('DD MMM YYYY');
      }
      if (filterType === 'After') {
        $scope.referrers.dateCreated = `After ${moment($scope.calendarSelectedDate).format('DD MMM YYYY')}`;
        const afterDay = moment($scope.calendarSelectedDate).add(1, 'day');
        $scope.referrers.createDateFrom = moment(afterDay).format('DD MMM YYYY');
        $scope.referrers.createDateTo = moment().format('DD MMM YYYY');
      }
      getReferrers();
      $scope.closeMobileCalendar();
      cancel();
    };
    $scope.setRangeDate = function () {
      $scope.referrers.dateCreated = `${moment($scope.calendarFromDate).format('DD MMM YYYY')} - ${moment($scope.calendarToDate).format('DD MMM YYYY')}`;
      $scope.referrers.createDateFrom = moment($scope.calendarFromDate).format('DD MMM YYYY');
      $scope.referrers.createDateTo = moment($scope.calendarToDate).format('DD MMM YYYY');
      getReferrers();
      $scope.closeMobileCalendar();
      cancel();
    };
  });
