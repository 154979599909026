import CONTACT_REQUESTER from 'Common/constants/addNewContactRequesters';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { forEach } from 'lodash';
import { toastSaveSuccess } from 'Common/utilities/alert';
import CreateNewLoanAppCtrlV2 from '../../../createNewLoanApplicationModal/createNewLoanApplicationCtrlV2';

export default class ManagePartiesCtrl extends CreateNewLoanAppCtrlV2 {
  constructor(
    uiService,
    $uibModal,
    userService,
    corporateService,
    generalService,
    loanApplicationServices,
    toaster,
    $scope,
    $state,
    $rootScope,
    broadcastDataService,
    dashboardService,
    $document,
    $timeout,
    contactService,
    utilitiesService,
    loanScenarioService,
    loanOpportunityService

  ) {
    'ngInject';

    super(
      uiService,
      $uibModal,
      userService,
      corporateService,
      generalService,
      loanApplicationServices,
      toaster,
      $scope,
      $state,
      $rootScope,
      broadcastDataService,
      dashboardService,
      $document,
      $timeout,
      contactService,
      utilitiesService,
      loanScenarioService
    );

    this.loanOpportunityService = loanOpportunityService;
  }

  $onInit() {
    this.REQUESTER_TYPE = CONTACT_REQUESTER.REQUESTER_TYPE;
    this.SHOW_EXISTING_PANEL = CONTACT_REQUESTER.SHOW_EXISTING_PANEL;
    this.resetAdviser();
    this.formData = {
      borrower: [],
      guarantor: [],
    };
    this.displayFamily = {
      borrower: [],
      guarantor: [],
    };
    this.displayCompany = {
      borrower: [],
    };
    this.saveDisabled = false;
    this.showThisPanel = 0;
    this.showClientList = false;
    this.searchClientName = '';
    this.clientList = [];
    this.existingFamilies = [];
    this.excludedIds = [];
    this.includedIds = [];
    this.showAdviserLabel = true;
    this.showAdvisers = false;
    this.isAdviserAccess = false;

    this.assistantObj = {
      name: '',
      familyId: 0,
    };

    this.setInvolvedPartiesOnLoad();
    this.setUITitles();
  }

  setUITitles() {
    if (this.showSelectAdviser) {
      this.title = 'Create New Opportunity';
      this.titleSubtext = 'Who will be involved in this Opportunity?';
      this.saveLabel = 'Create Opportunity';
    } else {
      this.title = 'Manage Involved Parties';
      this.titleSubtext = '';
      this.saveLabel = 'Save Changes';
    }
  }

  setInvolvedPartiesOnLoad() {
    if (this.involvedParties) {
      const { InvolvedPartyPerson, InvolvedPartyEntity } = this.involvedParties;
      this.onLoadGetIncludedIds(InvolvedPartyPerson);
      this.onLoadSetPersons(InvolvedPartyPerson);
      this.onLoadSetEntities(InvolvedPartyEntity);
    }

    this.userService.GetUserInfo()
      .then((response) => {
        const { data } = response;
        if (!data) return;
        const { AccessType, FamilyId, FullName } = data;
        const isUserAdviser = AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER || AccessType === ACCESS_TYPE.ADVISER;
        const isUserAssistant = AccessType === ACCESS_TYPE.ADMIN_ASSISTANT || AccessType === ACCESS_TYPE.ASSISTANT;
        this.showAdviserLabel = false;
        this.showAdvisers = false;

        if (isUserAdviser) {
          this.selectedAdviserId = this.selectedAdviserId || FamilyId;
          this.showAdviserLabel = true;
          this.showAdvisers = false;
          this.onLoadGetAdviser();
        } else if (isUserAssistant) {
          this.assistantObj = {
            name: FullName,
            familyId: FamilyId,
          };
          this.onLoadGetAdviser();
        }
      });
  }

  onLoadGetIncludedIds(InvolvedPartyPerson) {
    forEach(InvolvedPartyPerson, (val) => {
      this.includedIds.push(val.PersonId);
    });
  }

  onLoadSetPersons(InvolvedPartyPerson) {
    this.saveDisabled = true;
    forEach(InvolvedPartyPerson, (val) => {
      const clientType = val.IsApplicant ? CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER : CONTACT_REQUESTER.REQUESTER_TYPE.GUARANTOR;
      const isInCurrentList = this.existingFamilies.indexOf(val.FamilyId) !== -1;
      if (!isInCurrentList) {
        this.getClientInformation(val, clientType, true, false, true);
      }
    });
    this.saveDisabled = false;
  }

  getClientInformation(family, requesterType, updateDisplayList = true, isNew = false, isInitialLoad = false) {
    const returnData = [];
    const FamilyId = family.FamilyId || family.FamilyID || family.ResultFamilyID;
    let idx = 0;
    const displayData = [];
    this.addToExistingFamilies(FamilyId);

    this.contactService.clientInformGet(FamilyId)
      .then((clients) => {
        forEach(clients.data, (value) => {
          if (value.Role === 'Adult') {
            if (isNew) {
              family.partyApplicants[idx].PersonId = value.PersonId;
            }
            const isInitiallyExcluded = isInitialLoad && this.includedIds.indexOf(value.PersonId) === -1;
            if (isInitiallyExcluded) return;
            displayData.push(value);
            returnData.push({
              ClientEntityId: value.PersonId,
              IsClient: true,
              AddRelatedChildren: (requesterType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER),
              ClientName: `${value.FirstName} ${value.LastName}`,
              FamilyId,
            });
          }
          idx++;
        });
        if (updateDisplayList) {
          this.storeClientDisplayList(FamilyId, displayData, requesterType);
        } else if (!updateDisplayList && isNew) {
          this.storeClientDisplayList(FamilyId, family.partyApplicants, requesterType);
        }
        this.storeClientList(returnData, requesterType);
      });
  }

  onLoadSetEntities(InvolvedPartyEntity) {
    forEach(InvolvedPartyEntity, (val) => {
      const isInCurrentList = this.existingFamilies.indexOf(val.FamilyId) !== -1;
      if (!isInCurrentList) {
        this.getEntityInformation(val.FamilyId, CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER);
      }
    });
  }

  onLoadGetAdviser() {
    if (!this.selectedAdviserId) return;
    this.contactService.clientInformGet(this.selectedAdviserId)
      .then((response) => {
        const { data } = response;
        if (!data || !data.length) return;

        const adviserObj = data[0];
        this.selectedAdviser = { ...adviserObj, AdviserFamilyId: this.selectedAdviserId };
      });
  }

  saveParties() {
    if (!this.selectedAdviser && this.showSelectAdviser) {
      this.toaster.pop({
        type: 'error',
        body: 'Please set an adviser.',
      });
      return false;
    }

    const { borrower, guarantor } = this.formData;
    const borrowerList = this.convertFormDataToAPIData(borrower);
    if (!borrowerList.length) {
      this.toaster.pop({
        type: 'error',
        body: 'Please fill at least 1 Borrower. ',
      });
      return false;
    }

    const guarantorList = this.convertFormDataToAPIData(guarantor);
    const sendParam = {
      Borrowers: borrowerList,
      Guarantors: guarantorList,
    };
    this.saveDisabled = true;

    if (this.selectedAdviser && this.showSelectAdviser) {
      this.postNewOpportunity({
        ...sendParam,
        AllocatedAdviserID: this.selectedAdviser.AdviserFamilyId,
        AssistantFamilyId: this.assistantObj.familyId,
        isFromManageParties: true,
      });
    } else {
      this.postManageParties(sendParam);
    }
  }

  postManageParties(params) {
    this.loanOpportunityService.setInvolvedParties(this.opportunityId, params)
      .then(() => {
        toastSaveSuccess(this.toaster);
        this.modalInstance.close();
      }, () => {
        this.saveDisabled = false;
      });
  }

  postNewOpportunity(params) {
    this.modalInstance.close({ ...params });
  }

  setAssistant(familyId) {
    this.assistantObj.familyId = familyId;
  }
}
