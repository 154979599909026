import template from './template.html';
import GuarantorsCtrl from './guarantorsCtrl';

export default {
  template,
  bindings: {
    onPersonModal: '&',
    onShowAddExistingPanel: '&',
    onSearchClient: '&',
    onSelectClient: '&',
    onEditFamily: '&',
    onRemoveFamily: '&',
    onCompanyModal: '&',
    showThisPanel: '<',
    searchClientName: '=',
    showClientList: '<',
    clientList: '<',
    displayFamily: '<',
    formData: '<',
    selectedAdviser: '<',
    isAddingDisabled: '<',
  },
  controller: GuarantorsCtrl,
  controllerAs: 'vm',
};
