class ContactInsuranceService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'InsuranceQuote';
    this.apiContactBaseUrl = 'contacts';
    this.apiSelectOptionsBaseUrl = 'SelectOptions';
  }

  insuranceDetailsSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/InsuranceDetailsSet`, data);
  }

  insuranceProductGet(providerId, benefitTypeId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceProductGet`, { providerId, benefitTypeId });
  }

  insuranceProvidersGet(providerId, benefitId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceProvidersGet`, { providerId, benefitId });
  }

  insuranceQuoteProviderGet(providerId, benefitTypeId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteProviderGet`, { providerId, benefitTypeId });
  }

  insuranceExistingProviderGet(providerId, benefitTypeId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceExistingProviderGet`, { providerId, benefitTypeId });
  }

  insuranceDetailsGet(familyId, benefitId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceDetailsGet`, { familyId, benefitId });
  }

  insuranceBenefitTypesGet(familyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceBenefitTypesGet`, { familyId });
  }

  clientInformGet(familyId, clientId) {
    return this.httpClient.get(`${this.apiContactBaseUrl}/ClientInformGet`, { familyId, clientId });
  }

  insuranceBenefitsGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceBenefitsGet`, {}, true);
  }

  insuranceStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceStatusGet`);
  }

  insuranceCalculationPeriodGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteCalculationPeriodGet`);
  }

  insuranceQuoteExcessGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteExcessGet`);
  }

  iPCoverType() {
    return this.httpClient.get(`${this.apiSelectOptionsBaseUrl}/IPCoverType`);
  }

  insuranceQuoteIPCoverTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteIPCoverTypeGet`);
  }

  insuranceDetailsListGet(familyId, status, providerId, policyNumber) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceDetailsListGet`, { familyId, status, providerId, policyNumber });
  }

  insuranceQuoteDelete(quoteId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/InsuranceQuoteDelete`, { quoteId });
  }

  insuranceDetailsDelete(familyId, policyId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/InsuranceDetailsDelete`, { familyId, policyId });
  }

  insuranceFamilyProviderGet(familyId, status) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceFamilyProviderGet`, { familyId, status });
  }

  insuranceFamilyPoliciesGet(familyId, status) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceFamilyPoliciesGet`, { familyId, status });
  }

  insuranceQuoteWeekWaitPeriodGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteWeekWaitPeriodGet`);
  }
  insuranceQuoteIPBenefitPeriodGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteIPBenefitPeriodGet`);
  }
}

export default ContactInsuranceService;
