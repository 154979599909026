import angular from 'angular';

angular.module('app').factory('sendReferralService', function sendReferralService($q, SendReferralModel) {
  const factory = this;

  const sendReferral = new SendReferralModel();

  factory.getReferralAdviserOrgMemberList = function (referrerId, adviserOrgId) {
    const defer = $q.defer();

    sendReferral.getReferralAdviserOrgMemberList(referrerId, adviserOrgId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.referralAdviserOrgList = function (brandId, familyId) {
    const defer = $q.defer();

    sendReferral.referralAdviserOrgList(brandId, familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getStatusList = function () {
    const defer = $q.defer();

    sendReferral.getStatusList((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.getAdvisersList = function (famId, sameAdvOrg) {
    const defer = $q.defer();

    sendReferral.getAdvisersList(famId, sameAdvOrg, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.rayWhiteOrganisationGet = function (size, from) {
    const defer = $q.defer();

    sendReferral.rayWhiteOrganisationGet(size, from, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };

  factory.rayWhiteAdvisersGet = function (orgId, size, from) {
    const defer = $q.defer();

    sendReferral.rayWhiteAdvisersGet(orgId, size, from, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.searchClientGet = function (searchString, partyType) {
    const defer = $q.defer();

    sendReferral.searchClientGet(searchString, partyType, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralReferralTypeGet = function () {
    const defer = $q.defer();

    sendReferral.referralReferralTypeGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralReferralListGet = function (queryFilter) {
    const defer = $q.defer();

    sendReferral.referralReferralListGet(queryFilter, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralSendToListGet = function (referralTypeID) {
    const defer = $q.defer();

    sendReferral.referralSendToListGet(referralTypeID, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralUKPensionSet = function ($postData) {
    const defer = $q.defer();

    sendReferral.referralUKPensionSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralRayWhiteSet = function ($formData) {
    const defer = $q.defer();

    sendReferral.referralRayWhiteSet($formData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralFnGSet = function ($postData) {
    const defer = $q.defer();

    sendReferral.referralFnGSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralOFXSet = function ($postData) {
    const defer = $q.defer();

    sendReferral.referralOFXSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralXESet = function ($postData) {
    const defer = $q.defer();

    sendReferral.referralXESet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.geoCodeSearch = function (search) {
    const defer = $q.defer();

    sendReferral.geoCodeSearch(search, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.addressDetailsSet = function (postData) {
    const defer = $q.defer();
    sendReferral.addressDetailsSet(postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralWealthMarketSet = function ($postData) {
    const defer = $q.defer();

    sendReferral.referralWealthMarketSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralLoanMarketSet = function ($postData) {
    const defer = $q.defer();

    sendReferral.referralLoanMarketSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };
  factory.referralOtherSet = function ($postData) {
    const defer = $q.defer();

    sendReferral.referralOtherSet($postData, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });

    return defer.promise;
  };


  return factory;
});
