import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

/**
 * Action to perform on move failure.
 * remove the item from destination Column.
 * insert the item again in original Column.
 */
export function revertMoveCardToOtherColumn(event, sourceModelProp = 'card') {
  if (event) {
    event.dest.sortableScope.removeItem(event.dest.index);
    event.source.itemScope.sortableScope.insertItem(event.source.index, event.source.itemScope[sourceModelProp]);
  }
}

export function updateColumnTotalRecords(column, increase) {
  if (!column) return;

  let currentRecord = column.TotalRecords;
  if (currentRecord <= 0 && !increase) return;

  if (increase) {
    currentRecord++;
  } else {
    currentRecord--;
  }

  column.TotalRecords = currentRecord;
  return column.cards && column.cards.map((card) => {
    card.TotalRecords = currentRecord;
    return card;
  });
}

export function closeLabelPopover(card, lbl) {
  if (card) {
    card.textOpen = false;
    card.addLabelShown = false;
    card.editLabelShown = false;
  }
  if (lbl) {
    lbl.textOpen = false;
  }
}

export function kanbanHeight(windowObj, currentKanbanBoardHeight = 0) {
  let kanbanBoardHeight = currentKanbanBoardHeight;
  const winHeight = windowObj.document.documentElement.clientHeight;
  const headerHeight = windowObj.document.querySelector('header.navbar.navbar-default.navbar-static-top') ? windowObj.document.querySelector('header.navbar.navbar-default.navbar-static-top').offsetHeight : 55;
  const pipelinePanel = windowObj.document.querySelector('.new-pipeline-container');
  const mobileAddCard = windowObj.document.querySelector('.mobileAddCard');
  const mobileAddCardHeight = mobileAddCard ? 45 : 0;
  const pHeight = `${winHeight - headerHeight - 50}px`;

  // declare height to panel
  if (pipelinePanel) {
    pipelinePanel.style.setProperty('height', pHeight);

    const pipelineFilterWrapperHeight = windowObj.document.querySelector('#pipelineFilterWrapper') ? windowObj.document.querySelector('#pipelineFilterWrapper').offsetHeight : 55;
    kanbanBoardHeight = (winHeight - headerHeight - pipelineFilterWrapperHeight - mobileAddCardHeight - 150);
  }

  return kanbanBoardHeight;
}

export function kanbanItemMoved(
  event,
  pipelineSharedData,
  itemMovedPostCallback,
  itemMovedPostSuccessCallback,
  itemConvertPostCallback,
  itemConvertPostSuccessCallback,
  sourceModelProp = 'card',
  targetIdProp = 'loanId',
  modalRenderService,
  reasonList,
  isShowNurtureCampaignsQuestion
) {
  const isUndefinedElement = event.dest && event.dest.sortableScope && !event.dest.sortableScope.element;
  if (isUndefinedElement) return;

  const itemToHighlight = event.source.itemScope.card;
  const destinationColumn = (event.dest.sortableScope.$parent &&
    event.dest.sortableScope.$parent.$parent &&
    event.dest.sortableScope.$parent.$parent.$parent &&
    event.dest.sortableScope.$parent.$parent.$parent.column) || {};

  // Move among columns
  if (pipelineSharedData.checkItemMove(event) && Object.keys(destinationColumn).length) {
    // itemMovedPostCallback needs to return the promise itself
    typeof itemMovedPostCallback === 'function' && itemMovedPostCallback(itemToHighlight, destinationColumn)
      .then(() => {
        typeof itemMovedPostSuccessCallback === 'function' && itemMovedPostSuccessCallback(itemToHighlight, destinationColumn);
      })
      .catch(() => { revertMoveCardToOtherColumn(event, sourceModelProp); });
  } else {
    const movedToStatus = pipelineSharedData.getMoveSwalTitle(event);
    const isApproveApplication = event.dest.sortableScope.element[0].id === 'approve';
    const modalContent = { content: `This record will be moved to ${movedToStatus}` };
    if (!isApproveApplication) {
      modalContent.reasonList = reasonList;
      modalContent.showNurtureCampaignsQuestion = isShowNurtureCampaignsQuestion && pipelineSharedData.showNurtureCampaignsQuestion(event.source.itemScope.modelValue.enquiry);
    }
    const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(modalContent);
    confirmationPopup.result
      .then((result) => {
        if (result.isOk) {
          const targetId = itemToHighlight[targetIdProp];
          const pipelineStatusId = isApproveApplication ? PIPELINE_STATUS.APPLICATION : PIPELINE_STATUS.NOT_PROCEEDED;
          const { selectedReasonId, shouldSendNurtureCampaigns } = result;

          // itemConvertPostCallback needs to return the promise itself
          typeof itemConvertPostCallback === 'function' && itemConvertPostCallback(targetId, pipelineStatusId, selectedReasonId, shouldSendNurtureCampaigns)
            .then((response) => {
              if (response) {
                typeof itemConvertPostSuccessCallback === 'function' && itemConvertPostSuccessCallback(isApproveApplication, response);
              } else {
                revertMoveCardToOtherColumn(event, sourceModelProp);
              }
            });
        } else {
          revertMoveCardToOtherColumn(event, sourceModelProp);
        }
      });
  }
}

export function getElementYOffset(element, win) {
  if (!element || !element[0]) return;

  const rawElem = element[0];
  const rect = rawElem.getBoundingClientRect();
  if (!rect.width && !rect.height && !rawElem.getClientRects().length) return;

  const doc = rawElem.ownerDocument;
  const docElem = doc.documentElement;
  return (rect.top + win.pageYOffset) - docElem.clientTop;
}
