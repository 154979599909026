
app.factory('fundingCalculatorSharedData', function (optionsService, toaster) {
  var factory = this;

  angular.extend(factory, {
    getInsurerList(lenderId) {
      this.fundingCalculatorLMIDeatils.Insurer = '0';
      optionsService.getLmiInsurers(lenderId).then((response) => {
        this.LMIInsurerList = response.data;

      if (_.size(this.LMIInsurerList) > 0) {
        this.fundingCalculatorLMIDeatils.Insurer = _.first(this.LMIInsurerList).Value;
      }
      });
    },
    lmiSecurity() {
      return {
        FundingCalParametersID: 0,
        OccupancyType: 'PurchaseOwnerOccupied',
        StateOfSercurity: 'QLD',
        Value: 0,
        formInEditMode: true,
      }
    },
    getQuickLmiMortgageObj(mortgageObj, brokerEventId) {
      const {
        IsQuickCalc,
        Lenders,
        LVRValue,
        IsSelfEmployed,
        NewLoanAmount,
        PredominantLoanPurpose,
        SecurityAmount,
        IsFirstHomeBuyer,
        GoalType,
        CapitalizeLMI
      } = mortgageObj;

      return mortgageObj ? {
        BrokerEventID: brokerEventId,
        IsQuickCalc: IsQuickCalc,
        CapitalizeLMI,
        Lenders: Lenders && Lenders.length ? Lenders : ['ALL'],
        LVRValue: LVRValue ? LVRValue : '0',
        IsSelfEmployed: IsSelfEmployed,
        NewLoanAmount: NewLoanAmount ? NewLoanAmount : 0,
        PredominantLoanPurpose: PredominantLoanPurpose ? PredominantLoanPurpose : 'PurchaseOrConstruction',
        IsFirstHomeBuyer: IsFirstHomeBuyer,
        security: [this.lmiSecurity()],
        GoalType: GoalType ? GoalType : 0,
      } : null;
    },
    updateQuickLmiLvrValue(NewLoanAmount, SecurityAmount) {
      const newValue = (NewLoanAmount / SecurityAmount) * 100;
      if (!isNaN(newValue)) {
        if (newValue > 100) {
          toaster.pop('error', 'Invalid', 'It is unlikely you will receive a result for this LVR');
          return `0`;
        } else {
          return `${newValue.toFixed(2)}%`;
        }
      } else {
        return `0`;
      }
    }
});
  return factory;
});
