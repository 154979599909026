import {
  DISPLAY_OPTIONS,
} from 'Common/constants/customerObjectives';

export default class ObjectivesQuestionCtrl {
  constructor(
    loanApplicationServices,
    objectivesQuestionService
  ) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.objectivesQuestionService = objectivesQuestionService;
  }

  $onInit() {
    this.questionInfo = {};
    this.displayOption = DISPLAY_OPTIONS;
    this.isQuestionDone = false;
  }

  $onChanges(changes) {
    const { questionList, familyId, loanId, hideQuestion } = changes;
    if (questionList && questionList.currentValue) {
      this.questionList = questionList.currentValue;
      this.getQuestionInfo();
    }
    if (loanId && loanId.currentValue) {
      this.loanId = loanId.currentValue;
    }
    if (familyId && familyId.currentValue) {
      this.familyId = familyId.currentValue;
    }
    if (hideQuestion && typeof hideQuestion.currentValue !== 'undefined') {
      this.hideQuestion = hideQuestion.currentValue;
    }
  }

  setMainQuestionHide() {
    return !this.hideQuestion;
  }

  checkLoanObjectives(obj) {
    if (obj && obj.length) {
      this.getAnswer(obj);
    } else {
      this.loanApplicationServices.getLoanObjectives(this.loanId)
        .then((response) => {
          if (!response || !response.data) return;
          this.objectivesQuestionService.setObjectives(response.data);
          this.getAnswer(response.data);
        });
    }
  }

  getQuestionInfo() {
    if (!this.questionId || !(this.questionList && this.questionList.length)) return;
    this.questionInfo = this.questionList.find(item => item.QuestionId === this.questionId);
    if (!this.questionInfo) return;
    this.questionInfo.answerType = this.objectivesQuestionService.getQuestionOptionType(this.questionInfo.QuestionDisplayTypeId);
    const { objectivesData } = this.objectivesQuestionService;
    this.checkLoanObjectives(objectivesData);
  }

  getAnswer(objectivesData) {
    const data = objectivesData.find(item => item.QuestionId === this.questionId);
    const answerValue = this.objectivesQuestionService.getAnswer(data);
    if (answerValue !== null) {
      this.questionInfo.answerValue = answerValue;
    }
    this.getSubQuestionsAnswer(objectivesData);
    this.setQuestionStatus();
  }

  getSubQuestionsAnswer(objectivesData) {
    if (!this.questionInfo || !this.questionInfo.SubQuestion || !this.questionInfo.SubQuestion.length) return;
    this.questionInfo.SubQuestion.map((item) => {
      const obj = item;
      obj.optionList = this.objectivesQuestionService.getOptionList(item);
      obj.answerType = this.objectivesQuestionService.getQuestionOptionType(item.QuestionDisplayTypeId);
      obj.placeHolder = this.objectivesQuestionService.getOptionPlaceHolder(item.QuestionId);
      obj.optionalNote = this.objectivesQuestionService.checkOptionalQuestion(item.QuestionId);
      obj.withTextFieldQuestion = this.objectivesQuestionService.withTextboxQuestion(item.QuestionId);
      obj.textareaSize = this.objectivesQuestionService.textareaSize(item.QuestionId);
      obj.hideQuestion = this.objectivesQuestionService.hideSubQuestion(item.QuestionId);
      obj.showOtherField = this.objectivesQuestionService.showSubQuestionOtherFields(item.QuestionId);
      obj.otherFieldValue = this.objectivesQuestionService.getOtherFieldValue(item.QuestionId);
      const data = objectivesData.find(o => o.QuestionId === item.QuestionId);
      obj.answerValue = this.objectivesQuestionService.getAnswer(data);
      obj.answerList = this.objectivesQuestionService.getAnswerList(data);
      return obj;
    });
  }

  addMultipleAnswer(item, subQuestion = false) {
    if (subQuestion) {
      this.questionInfo.SubQuestion.map((data) => {
        if (data.QuestionId === item.QuestionId) {
          if (!data.answerList.includes(item.answerValue)) data.answerList.push(item.answerValue);
          item.answerValue = 1;
        }
        return data;
      });
    }
    this.updateObjectives(item, subQuestion);
  }

  removeMultipleAnswer(question, item, subQuestion = false) {
    if (subQuestion) {
      this.answerIndex = question.answerList.indexOf(item);
      if (question.answerList.includes(item)) question.answerList.splice(this.answerIndex, 1);
    }
    this.updateObjectives(question, subQuestion);
  }

  updateOtherField(item) {
    const sendData = this.objectivesQuestionService.setOtherFieldAnswer({
      LoanScenarioId: this.loanId,
      FamilyId: this.familyId,
      QuestionSectionId: 0,
    }, item);

    if (!sendData.QuestionId) return;
    this.loanApplicationServices.postLoanObjectives(sendData);
  }

  setQuestionStatus() {
    this.isQuestionDone = this.objectivesQuestionService.checkQuestionIsDone(this.questionInfo);
    this.objectivesQuestionService.setFeaturePreferenceStatus(this.questionId, this.isQuestionDone);
    this.updateQuestion({ questionId: this.questionId });
  }

  updateObjectives(item, subQuestion = false) {
    this.isQuestionDone = false;
    if (!item || (item.QuestionDisplayTypeId === DISPLAY_OPTIONS.TEXT_ONLY && !item.answerValue)) return;
    const sendData = this.objectivesQuestionService.setObjectivesAnswer({
      LoanScenarioId: this.loanId,
      FamilyId: this.familyId,
      QuestionSectionId: 0,
      QuestionId: item.QuestionId,
      DisplayOptionId: item.QuestionDisplayTypeId,
    }, item);
    this.loanApplicationServices.postLoanObjectives(sendData)
      .then(({ data }) => {
        if (data && subQuestion) this.questionInfo.SubQuestion = this.objectivesQuestionService.updateSubQuestion(item, this.questionInfo.SubQuestion);
        this.setQuestionStatus();
      });
  }
}
