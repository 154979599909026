import moment from 'moment';
import { isFunction } from 'lodash';
import { formatAddress } from 'Common/utilities/address';
import { INPUT_DATE_FORMAT, DATE_MODEL_OPTIONS } from 'Common/constants/workbench';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';

class CreditChecksListCtrl {
  constructor(
    creditChecksService,
    generalService,
    timeoutService,
    NgTableParams,
    $timeout,
    $window
  ) {
    'ngInject';

    this.creditChecksService = creditChecksService;
    this.generalService = generalService;
    this.timeoutService = timeoutService;
    this.NgTableParams = NgTableParams;
    this.$timeout = $timeout;
    this.$window = $window;
  }

  $onInit() {
    this.dateSettings = {
      timezone: DATE_MODEL_OPTIONS.timezone,
      format: INPUT_DATE_FORMAT,
    };

    this.statusCheckInterval = 25000;

    this.initTableParams();

    if (isFunction(this.onInitialized)) {
      this.api = {};
      this.api.refresh = () => {
        this.shouldShowLoading = false;
        if (this.tableParams.page() === 1) {
          this.tableParams.reload();
        } else {
          this.tableParams.page(1);
        }
      };
      this.onInitialized({ api: this.api });
    }
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts(WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL);
  }

  initTableParams() {
    this.pageSize = 14;
    this.shouldShowLoading = true;
    this.tableParams = new this.NgTableParams({
      page: 1,
      count: this.pageSize,
    },
    {
      total: 0,
      getData: ($defer, params) => {
        const pageNumber = params.page();
        const { pageSize } = this;
        const queryParams = { pageNumber, pageSize };
        this.isLoadingList = this.shouldShowLoading;
        this.timeoutService.cancelTimeouts(WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL);
        this.creditChecksService.getCreditChecks(queryParams).then((response) => {
          const data = response && response.data;
          this.formatCreditChecksData(data);
          this.creditChecks = data;
          this.tableParams.total(this.getTotalRecords());
          this.tableParams.currentPage = this.tableParams.page();
          $defer.resolve(this.creditChecks);
          this.isLoadingList = false;
          this.shouldShowLoading = true;
        }, () => {
          this.tableParams.total(0);
          $defer.resolve([]);
          this.isLoadingList = false;
          this.shouldShowLoading = true;
        });
      },
    });
  }

  formatCreditChecksData(creditChecks) {
    creditChecks && creditChecks.forEach((creditCheck) => {
      if (creditCheck.DateOfBirth) {
        creditCheck.DateOfBirth = moment(creditCheck.DateOfBirth).toDate();
      }
      if (creditCheck.DateSent) {
        creditCheck.DateSent = moment(creditCheck.DateSent).toDate();
      }

      creditCheck.FormattedAddress = formatAddress(creditCheck);

      creditCheck.isGenerating = !creditCheck.EquiFaxEnquiryID && !creditCheck.ErrorReason;
      const isSubmittedToday = moment(creditCheck.DateSent).isSame(moment(), 'day');
      const shouldCheckStatus = creditCheck.isGenerating && isSubmittedToday;
      if (shouldCheckStatus) {
        this.startStatusCheckerFor(creditCheck);
      }
    });
  }

  startStatusCheckerFor(creditCheck) {
    creditCheck.generationStatusChecker = this.$timeout((checkedCreditCheck) => {
      if (checkedCreditCheck.isGenerating) {
        this.checkGenerationStatus(checkedCreditCheck);
      } else {
        this.cancelStatusChecking(checkedCreditCheck);
      }
    }, this.statusCheckInterval, true, creditCheck);

    this.timeoutService.addTimeout(creditCheck.generationStatusChecker, WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL);
  }

  cancelStatusChecking(creditCheck) {
    this.timeoutService.cancelTimeout(WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL, creditCheck.generationStatusChecker);
    creditCheck.generationStatusChecker = null;
  }

  checkGenerationStatus(creditCheck) {
    const { CreditCheckID: creditCheckID } = creditCheck;
    this.creditChecksService.getCreditChecks({ creditCheckID }).then((response) => {
      if (!response) return;

      const { data } = response;
      if (!data || !data.length) return;

      this.cancelStatusChecking(creditCheck);
      this.formatCreditChecksData(data);
      const updatedCreditCheck = data[0];
      Object.assign(creditCheck, updatedCreditCheck);
    });
  }

  getTotalRecords() {
    if (!this.creditChecks || !this.creditChecks.length) return 0;

    const firstItem = this.creditChecks[0];
    return (firstItem && firstItem.TotalRecords) || 0;
  }

  viewCreditCheck(creditCheck) {
    if (!creditCheck) return;

    creditCheck.isDownloading = true;
    this.generalService.documentGet(creditCheck.DocumentId).then((response) => {
      const eventTimeout = this.$timeout(() => {
        creditCheck.isDownloading = false;
        if (!response || !response.data) return;

        const { DocumentContent, ContentType } = response.data;
        const blob = this.convertBase64ToBlob(DocumentContent, ContentType);
        const a = this.$window.document.createElement('a');
        a.href = this.$window.URL.createObjectURL(blob);
        a.target = '_blank';
        a.click();
        this.$timeout.cancel(eventTimeout);
      });
    }, () => {
      creditCheck.isDownloading = false;
    });
  }

  convertBase64ToBlob(base64, contentType) {
    if (!base64 || !contentType) return;

    const byteString = this.$window.atob(base64);
    const buffer = new ArrayBuffer(byteString.length);
    const content = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
      content[i] = byteString.charCodeAt(i);
    }

    const blob = new this.$window.Blob([content], { type: contentType });
    return blob;
  }
}

export default CreditChecksListCtrl;
