import angular from 'angular';
import { POST_SETTLEMENT_FOLLOW_UP_ID } from 'Common/constants/customerCareModules';
import { toastError } from 'Common/utilities/alert';

angular.module('app').controller('OpenPostSettlementSMSModalCtrl', function OpenPostSettlementSMSModalCtrl($scope,
  $uibModalInstance,
  $state,
  $controller,
  toaster,
  source,
  postSettlementObj,
  postSettlementListTable,
  optionsService,
  contactService,
  customerCareService,
  customerCareTemplateService,
  mainObj,
  loadPostSettlementTable) {
  const vm = this;
  angular.extend(vm, $controller('CustomerCareSendMessageModalBaseCtrl', { $scope }));

  $scope.recipients = [];
  $scope.smsList = [];
  $scope.IsComplete = true;
  $scope.currentModule = POST_SETTLEMENT_FOLLOW_UP_ID;
  function getCountryList() {
    optionsService.countryListGet()
      .then((response) => {
        $scope.countryList = response.data;
        const filteredCountry = $scope.countryList.filter((item) => {
          if (postSettlementObj.Country === item.CountryCode) {
            return item;
          }
          return false;
        });
        if (Array.isArray(filteredCountry) && filteredCountry.length > 0) {
          $scope.countryObj = filteredCountry[0];
          $scope.selectedCountryCode = $scope.countryObj.CountryCode;
          $scope.makeObject($scope.selectedCountryCode);
        }
      }, () => {
      });
  }

  $scope.makeObject = function (selectedCountryCode) {
    if (source === 'single') {
      if (postSettlementObj.Mobile !== '') {
        if (typeof selectedCountryCode !== 'undefined') {
          $scope.countryObj = $scope.countryList.filter((item) => {
            if (selectedCountryCode === item.CountryCode) {
              return item;
            }
            return false;
          });

          if (typeof $scope.countryObj !== 'undefined') {
            $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
          }
        }
        const settlementName = mainObj ? mainObj.settlementName : '';
        const notiHeader = `Post Settlement • ${settlementName} Completed via SMS`;
        $scope.smsList.sms = [
          {
            FamilyFullname: postSettlementObj.Customer,
            LoanID: postSettlementObj.LoanID,
            FamilyID: postSettlementObj.FamilyID,
            MobileNumber: $scope.callingCode + postSettlementObj.Mobile,
            NotificationHeader: notiHeader,
          }];
        $scope.recipients.email = [{
          EmailAddress: postSettlementObj.EmailAddress,
          FamilyFullname: postSettlementObj.Customer,
          MobileNumber: postSettlementObj.Mobile,
        }];
      }
    }
    $scope.postSettlementListTable = postSettlementListTable;

    if (source === 'all') {
      $scope.all = true;
      if (typeof selectedCountryCode !== 'undefined') {
        $scope.countryObj = $scope.countryList.filter((item) => {
          if (selectedCountryCode === item.CountryCode) {
            return item;
          }
          return false;
        });

        if (typeof $scope.countryObj !== 'undefined') {
          $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
        }
      }
      if ($scope.postSettlementListTable.length > 0) {
        $scope.selectSMSList = [];
        $scope.emailRecipients = [];

        for (let i = 0; i < $scope.postSettlementListTable.length; i++) {
          $scope.smsAdd = {};
          $scope.emailAdd = {};
          if ($scope.postSettlementListTable[i].Mobile !== '') {
            $scope.smsAdd.FamilyFullname = $scope.postSettlementListTable[i].Customer;
            $scope.smsAdd.LoanID = $scope.postSettlementListTable[i].LoanID;

            $scope.smsAdd.FamilyID = $scope.postSettlementListTable[i].FamilyID;
            $scope.smsAdd.MobileNumber = $scope.callingCode + $scope.postSettlementListTable[i].Mobile;

            $scope.selectSMSList.push($scope.smsAdd);

            $scope.emailAdd.EmailAddress = $scope.postSettlementListTable[i].EmailAddress;
            $scope.emailAdd.FamilyFullname = $scope.postSettlementListTable[i].Customer;
            $scope.emailAdd.MobileNumber = $scope.callingCode + $scope.postSettlementListTable[i].Mobile;
            $scope.emailRecipients.push($scope.emailAdd);
          }
        }
        if ($scope.selectSMSList.length > 0) {
          $scope.smsList.sms = $scope.selectSMSList;
          $scope.noOfPeople = $scope.selectSMSList.length;
        }
        if ($scope.emailRecipients.length > 0) {
          $scope.recipients.email = $scope.emailRecipients;
        }
      }
    }
  };
  $scope.countryChanged = function (selectedCountryCode) {
    $scope.makeObject(selectedCountryCode);
  };
  $scope.selectEmailList = [];
  $scope.getRecipientAutoSuggestion = function (userInputData) {
    contactService.getRecipientAutoSuggestion(userInputData).then((response) => {
      $scope.selectEmailList = response.data;
    });
  };
  $scope.selectedTemplate = [{
    UserTemplateID: 0,
    UserTemplateName: 'Blank',
  }];

  $scope.refreshResults = function (select) {
    const search = select.search;

    $scope.getRecipientAutoSuggestion(search);
  };

  $scope.sendSMS = function (form) {
    if (form.$invalid) {
      let firstError = null;
      form && Object.keys(form).forEach((field) => {
        if (field[0] !== '$') {
          if (!form[field].$valid && firstError === null) {
            firstError = form[field].$name;
          }

          if (form[field].$pristine) {
            form[field].$dirty = true;
          }
        }
      });

      angular.element(`.ng-invalid[name=${firstError}]`).focus();
    } else {
      $scope.recipientsSMSArray = [];
      $scope.smsToSend = {};
      $scope.smsToSend.IsComplete = $scope.IsComplete;

      $scope.smsToSend.Message = $scope.sms.Message;
      $scope.smsToSend.CustomerCareType = 'PRE_APPROVAL_EXPIRY';
      angular.forEach($scope.smsList.sms, (item) => {
        $scope.recipientsSMSArray.push(item);
      });
      $scope.smsToSend.SmsModelList = $scope.recipientsSMSArray;


      customerCareService
        .CustomerCareSMSSend($scope.smsToSend)
        .then((postSettlementRes) => {
          if (postSettlementRes.data && postSettlementRes.data.length) {
            const postSettlementSMSValid = customerCareTemplateService.customerCareResponseMsg(postSettlementRes.data, 'SMS');
            if (postSettlementSMSValid) {
              loadPostSettlementTable();
              $scope.smsToSend.Message = '';
              form.$setPristine(true);
            }
            $scope.cancel();
          }
        })
        .catch(toastError);
    }
  };
  function init() {
    getCountryList();

    $scope.phone = postSettlementObj.MobileNo;
    $scope.content = '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';
    $scope.familyId = postSettlementObj.FamilyID;
  }

  init();
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
