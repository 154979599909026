import angular from 'angular';

angular.module('app').filter('orderObjectBy', function orderObjectBy() {
  return function (input, attribute) {
    if (!angular.isObject(input)) return input;

    const array = [];
    input && Object.keys(input).forEach((x) => {
      array.push(input[x]);
    });

    array.sort((pA, pB) => {
      let a = pA;
      let b = pB;
      a = parseInt(a[attribute], 10);
      b = parseInt(b[attribute], 10);
      return a - b;
    });
    return array;
  };
});
