import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular.module('app').controller('OpenCreateTaskTemplateModalCtrl',
  function OpenCreateTaskTemplateModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    $timeout,
    toaster,
    taskService,
    taskDetailService,
    dashboardService,
    $stateParams,
    init,
    currentUserService,
    contactModelService
  ) {
    $scope.taskSet = {};
    $scope.mainObject = {};
    $scope.mainObject.isSearchEnable = false;
    $scope.format = 'dd MMM yyyy';
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    $scope.init = init;
    $scope.openDate = function (templateTask) {
      templateTask.datePopUp = !templateTask.datePopUp;
    };
    $scope.dateChanged = function (templateTask) {
      templateTask.DueDate = templateTask.DueDateFormat;
    };
    $scope.closeFamilyList = function () {
      $scope.mainObject.isSearchEnable = false;
      $scope.taskInput.familyValue = '';
      $scope.familyDetailsList = [];
    };
    $scope.selectFamily = function (id, fullname) {
      $scope.taskInput.familyValue = fullname;
      $scope.taskSet.FamilyId = id;
      $scope.familyDetailsList = [];
      $scope.mainObject.isSearchEnable = false;
    };
    $scope.$on('$destroy', () => {
      $timeout.cancel($scope.templatedTimeout);
      $scope.templatedTimeout = null;
    });
    $scope.taskSet.DueDate = new Date();
    $scope.editTemplateTask = function (task) {
      taskDetailService.editTemplateTask(task, $scope.mainObject);
    };
    $scope.cancelTemplateTaskEditMode = function (task) {
      _.map($scope.copiedTemplatedTaskList, (item) => {
        if (item.TaskSettingsID === task.TaskSettingsID) {
          task.TaskName = item.TaskName;
          task.AssignedAdvisorID = item.AssignedAdvisorID;
          task.DueDate = item.DueDate;
          const $adviserData = _.find($scope.taskAdviserList, (adviser) => {
            return adviser.BrokerID === task.AssignedAdvisorID;
          });
          if (typeof $adviserData !== 'undefined') {
            item.FirstName = $adviserData.LastName;
            item.LastName = $adviserData.LastName;
            task.FirstName = $adviserData.FirstName;
            task.LastName = $adviserData.LastName;
          }
        }
      });
      task.isEditMode = false;
    };
    $scope.saveTemplateTask = function (task) {
      _.map($scope.copiedTemplatedTaskList, (item) => {
        if (item.TaskSettingsID === task.TaskSettingsID) {
          item.TaskName = task.TaskName;
          item.AssignedAdvisorID = task.AssignedAdvisorID;
          item.DueDate = task.DueDate;
          const $adviserData = _.find($scope.taskAdviserList, (adviser) => {
            return adviser.BrokerID === task.AssignedAdvisorID;
          });
          if (typeof $adviserData !== 'undefined') {
            item.FirstName = $adviserData.FirstName;
            item.LastName = $adviserData.LastName;
            task.FirstName = $adviserData.FirstName;
            task.LastName = $adviserData.LastName;
          }
        }
      });
      task.isEditMode = false;
      $scope.formatTaskSettingsDueDate(task.TaskTemplateID, task.TaskSettingsID);
    };
    taskService.taskTemplateListGet().then((respond) => {
      $scope.taskTemplateList = respond.data;
    });
    $scope.copiedTemplatedTaskList = [];
    $scope.selectedTemplateId = 0;
    $scope.getTemplateTask = function (templateID) {
      if (!templateID) return;
      $scope.selectedTemplateId = templateID;
      taskService.getTaskTemplateSettings(templateID).then((respond) => {
        $scope.templatedTaskList = respond.data;
        $scope.formatTaskSettingsDueDate(templateID);
      });
    };

    $scope.formatTaskSettingsDueDate = (templateId, taskId = 0) => {
      if (!templateId) return;
      const templateModel = {
        TaskTemplateID: templateId,
        TaskTemplateName: '',
        TaskSettingsList: $scope.templatedTaskList,
      };

      taskService.updateDueDateSubsequently(templateModel, taskId)
        .then((newTaskSettings) => {
          $scope.templatedTaskList = newTaskSettings.data;
          dashboardService.getBrokerBasicInfo().then((getBrokerBasicInfoResponse) => {
            const { brokerData } = getBrokerBasicInfoResponse;
            if (!brokerData) return;
            $scope.mainObject.CreatedByUser = brokerData.BrokerId;
            $scope.templatedTaskList.forEach((item) => {
              item.AssignedAdvisorID = item.AssignedAdvisorID && item.AssignedAdvisorID.toString();
              if (!parseInt(item.AssignedAdvisorID, 10)) {
                item.AssignedAdvisorID = brokerData.BrokerId;
                item.FirstName = brokerData.FirstName;
                item.AssignedAdvisorID = brokerData.BrokerId;
              }
            });
            $scope.copiedTemplatedTaskList = [...$scope.templatedTaskList];
          });
        });
    };

    $scope.deleteTemplateTask = function (taskObject) {
      const $indexOf = $scope.templatedTaskList.indexOf(taskObject);
      if ($indexOf > -1)
        $scope.templatedTaskList.splice($indexOf, 1);
    };
    $scope.taskInput = {};
    $scope.searchFamilyInput = function () {
      $scope.mainObject.isSearchEnable = true;
      taskService.searchFamily($scope.taskInput.familyValue, 'TaskClient').then((searchFamilyResponse) => {
        if (typeof searchFamilyResponse.data !== 'undefined') {
          $scope.familyDetailsList = searchFamilyResponse.data;
        }
      });
    };
    contactService.getAllByFilter('*').then((getAllByFilterRespond) => {
      angular.forEach(getAllByFilterRespond.data.FamilyList, (value) => {
        if (value.FamilyID === $scope.familyId) {
          $scope.taskInput.familyValue = value.FamilyFullName;
          $scope.taskSet.FamilyId = value.FamilyID;
        }
      });
    });
    $scope.isCorporateUser = currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    $scope.isNzBased = COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
    const isLoanWriter = $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
    contactModelService.taskAdviserListGet(isLoanWriter).then((taskAdviserListGetRespond) => {
      $scope.taskAdviserList = taskAdviserListGetRespond.data;
      $scope.taskAdviserList = _.reject(taskAdviserListGetRespond.data, ['BrokerID', 0]);
      angular.forEach(taskAdviserListGetRespond.data, (item) => {
        item.BrokerID = item.BrokerID.toString();
      });
    });
    $scope.saveTemplatedTask = function () {
      const $postDataList = [];
      angular.forEach($scope.templatedTaskList, (item) => {
        const $taskData = {
          FamilyID: $scope.taskSet.FamilyId,
          TaskID: 0,
          Title: item.TaskName,
          assignedClientID: item.AssignedAdvisorID,
          CreatedByUser: $scope.mainObject.CreatedByUser,
          DueDate: moment(item.DueDate).format('YYYY-MM-DD'),
          Notes: '',
          TaskSettingsID: item.TaskSettingsID,
        };
        $postDataList.push($taskData);
      });
      const $postData = {
        TaskList: $postDataList,
        TaskTemplateID: $scope.selectedTemplateId,
      };
      taskService.TaskSetv3Multiple($postData).then(() => {
        toaster.pop('success', 'Added', 'Templated task successfully added');
        $scope.init();
        $scope.templatedTimeout = $timeout(() => {
          $uibModalInstance.dismiss('cancel');
        }, 100);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.filterInitial = function (...args) {
      let initialsHolder = '';
      if (!args || !args[0]) {
        return;
      }
      for (let y = 0; y < args.length; y++) {
        if (!_.isNil(args[y]) && _.isString(args[y])) {
          initialsHolder = `${initialsHolder}${args[y].charAt(0).toUpperCase()}`;
        }
      }
      return initialsHolder;
    };
  });
