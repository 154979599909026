import angular from 'angular';
import { isEmploymentIncome } from 'Common/utilities/income';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';

angular.module('app').controller('LoanappIncomeModalCtrl',
  function LoanappIncomeModalCtrl($scope, $uibModalInstance, modalType, toaster, uiService) {
    $scope.uiService = uiService;
    $scope.Title = (modalType === 'new' ? 'Add' : 'Edit');
    $scope.isEmploymentIncome = isEmploymentIncome;
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.changeIncomeModal = () => {
      if (isEmploymentIncome($scope.IncomeSet.TypeId)) {
        $uibModalInstance.close({ isOpenIncomeModal: true, obj: $scope.IncomeSet });
      } else if (parseInt($scope.IncomeSet.TypeId, 10) === DEFAULT_INCOME_TYPES.RENTAL) {
        $scope.IncomeSet.TypeId = $scope.prevValueHolder;
        toaster.pop('error', 'Error', 'Please add rental income to the Existing Real Estate Asset');
      }
    };
    $scope.saveBreakdown = () => {
      $scope.addIncome();
      $scope.cancel();
    };
  });
