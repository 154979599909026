import angular from 'angular';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';

angular.module('app').controller('CustomerCareSendMailModalBaseCtrl', function CustomerCareSendMailModalBaseCtrl(
  $scope,
  $uibModal,
  $window,
  $timeout,
  mailTemplateService,
  templateService,
  contactService
) {
  $scope.openManageTemplatesModal = () => {
    const modalInstance = $uibModal.open({
      templateUrl: 'assets/views/contacts/mailTemplatesManagement/mail_templates_management.html',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      controller: 'MailTemplatesManagementCtrl as mailTemplatesVm',
      resolve: {
        contentToSaveAsTemplate: () => null,
        showDefaultTemplatesSettings: true,
      },
    });
    if (modalInstance && modalInstance.result) {
      modalInstance.result.then((hasChanges) => {
        if (!hasChanges) return;
        $scope.api.refresh();
      });
    }
  };

  $scope.openDefaultTemplateSettings = () => {
    templateService.openDefaultTemplateSettings($scope.selectedTemplate, SEND_METHOD_TYPES.MAIL);
  };

  $scope.hasValidSelectedTemplate = () => {
    return templateService.isValidTemplate($scope.selectedTemplate);
  };

  $scope.setTemplateSelection = (template) => {
    if (template) {
      const { UserTemplateID, UserTemplateName, UserTemplateContent } = template;
      $scope.selectedTemplate = { UserTemplateID, UserTemplateName, UserTemplateContent };
      UserTemplateContent && $scope.translateTemplate(UserTemplateContent);
    }
  };

  $scope.translateTemplate = (templateContent) => {
    if (templateContent && templateContent.trim()) {
      const mergeFieldData = {
        familyId: $scope.familyId,
        templateContent,
      };
      mailTemplateService.translateMailTemplate(mergeFieldData).then((response) => {
        $scope.content = response;
      }, (error) => {
        $scope.content = error.templateContent || templateContent;
      });
    } else {
      $scope.content = null;
    }
  };

  $scope.saveEmailContentHtmlToRTF = (htmlStr) => {
    if (!htmlStr || !htmlStr.trim()) return;

    const htmlStrToPass = encodeURIComponent(htmlStr);
    contactService.convertContentHtmlToRTF({ htmlString: htmlStrToPass }).then((response) => {
      if (!response) return;
      const data = response;
      const element = $window.document.createElement('a');
      element.setAttribute('href', `data:${data.contentType};base64,${data.documentContent}`);
      element.setAttribute('download', data.name);

      element.style.display = 'none';
      $window.document.body.appendChild(element);

      const clickDelay = $timeout(() => {
        element.click();
      }, 100);

      clickDelay.then(() => {
        $window.document.body.removeChild(element);
      });

      $scope.$on('$destroy', () => {
        $timeout.cancel(clickDelay);
      });
    });
  };

  $scope.onTemplatesInit = (api) => {
    $scope.api = api;
  };
});
