export const INSURANCE_OPTIONS = {
  HEALTH: {
    SPECIALIST: 1,
    GPOPTION: 2,
    DENTALOPTICAL: 3,
  },
  OCCUPATION_TYPE: {
    FULLTIME: 1,
    PARTIME: 2,
    NON_EARNER: 3,
  },
  DEVICE_WIDTH: {
    MOBILE: 991,
  },
  CLIENT_TYPE: {
    NEW: 1,
    EXISTING: 2,
  },
  BENEFIT_TYPE: {
    HEALTH_COVER: 1,
    LIFE_COVER: 2,
    FAMILY_PROTECTION: 3,
    TRAUMA_COVER: 4,
    TPD: 5,
    INCOME_PROTECTION: 6,
    MORTAGE_REPAYMENT: 7,
    REDUNDANCY: 8,
    WAIVER_OF_PREMIUM: 9,
    INCOME_PROTECTION_NON_TAXABLE: 10,
  },
};

export const INSURANCE_LOAN_APP = {
  QUESTIONS: {
    DISCUSS_LIFESTYLE: 102,
    DONT_DISCUSS_LIFESTYLE: 103,
    WEALTH_MARKET_FINANCIAL_ADVISER: 104,
    ALI_GROUP: 105,
    EXISTING_ADVISER: 106,
    MORE_DETAILS: 107,
    CONCIERGE: 25,
    COMMENTS: 99,
  },
  FIELD: {
    RADIOBOX_ON_YES: 12,
    RADIOBOX_ON_NO: 13,
  },
  DISPLAY_ID: {
    CONCIERGE: 8,
  },
};

export const INSURANCE_DATA = {
  QUOTE: 1,
  PROFILER: 2,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
};

export const WAIT_PERIOD = {
  TWO_WEEKS: 2,
  FOUR_WEEKS: 4,
  EIGHT_WEEKS: 8,
  TWENTY_SIX_WEEKS: 26,
  FIFTY_TWO_WEEKS: 52,
  ONEHUNDRED_FOUR_WEEKS: 104,
};

export const CALC_PERIOD = {
  NONE: 0,
  YEARLY: 1,
  LEVEL_10: 10,
  LEVEL_65: 65,
  LEVEL_70: 70,
  LEVEL_80: 80,
  LEVEL_85: 85,
  LEVEL_90: 90,
  LEVEL_100: 100,
};

export const BENEFIT_PERIOD = {
  Years_2: 2,
  Years_5: 5,
  TO_AGE_65: 65,
  TO_AGE_70: 70,
};

export const EMPLOYMENT_STATUS = {
  EMPLOYED: 1,
};

export const CLIENT_AGE = {
  TYPE_CHILD: 'child',
  CHILD: 22,
  ADULT: {
    FROM: 18,
    TO: 71,
  },
};

export const INSURANCE_QUOTE_REPORT_SECTION = {
  PREMIUM_COMPARISON_GRAPH: 1,
  INSURER_RECOMMENDATION: 2,
  WOP_BENEFITS: 3,
  HEALTH_INSURANCE_EXCESS: 4,
  ONLINE_APP_LINK: 5,
};

export const INSURANCE_POLICY_FEE_GRAPH_COLOR = '#5B1F23';

export const INSURANCE_TYPES = {
  PROFILER: 1,
  QUICK_QUOTE: 2,
};

export const INCOME_PROTECTION_BENEFITS = [
  INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION,
  INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION_NON_TAXABLE,
];

export const INSURANCE_PIPELINE_STATUS = {
  IN_FORCE: 6,
  NOT_PROCEEDED: 7,
};

export const COVER_TYPES = [
  {
    key: 'Accelerated',
    value: 'Accelerated',
  },
  {
    key: 'Standalone',
    value: 'Standalone',
  },
];

export const BENEFIT_INDICES = {
  HEALTH_COVER: 0,
  LIFE_COVER_1: 1,
  LIFE_COVER_2: 2,
  FAMILY_PROTECTION: 3,
  TRAUMA_COVER_1: 4,
  TRAUMA_COVER_2: 5,
  TPD_1: 6,
  TPD_2: 7,
  INCOME_PROTECTION: 8,
  MORTGAGE_REPAYMENT: 9,
  REDUNDANCY: 10,
  WAIVER_OF_PREMIUM: 11,
};
