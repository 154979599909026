import angular from 'angular';
import _ from 'lodash';

angular.module('app').directive('generalSearch',
  function generalSearch(dashboardService, $document, $sce, $state, loanProfilerService, $timeout, userService, $uibModal, contactService) {
    return {
      templateUrl() {
        return '/assets/views/partials/searchTpl.html';
      },
      link(scope, elem, attrs) {
        const lenderPlace = 0;
        scope.openSearchResult = false;
        scope.isSearching = false;
        scope.timeoutPromise = null;
        scope.cancelSearch = function () {
          scope.openSearchResult = false;
          scope.generalSearchResult = [];
          $timeout.cancel(scope.timeoutPromise);
        };

        scope.isCorporateUserSearch = false;
        function isCorporateUser() {
          contactService.getUserInfo().then((response) => {
            if (response.data) {
              if (response.data.AccessType === 5) {
                scope.isCorporateUserSearch = true;
              }
            }
          });
        }
        isCorporateUser();

        scope.submitSearch = function () {
          $timeout.cancel(scope.timeoutPromise);
          if (scope.generalSearchText && scope.generalSearchText.length > 2) {
            scope.isSearching = true;
            scope.timeoutPromise = $timeout(() => {
              scope.openSearchResult = true;
              scope.generalSearch(scope.generalSearchText);
            }, 300);
          }
        };

        elem.bind('click', (e) => {
          e.stopPropagation();
        });

        $document.bind('click', () => {
          scope.openSearchResult = false;
          $timeout(attrs.generalSearch);
        });

        // General Search
        scope.generalSearch = function (generalSearchText) {
          scope.generalSearchResult = [];
          scope.isSearching = true;
          return dashboardService.getGenralSearchResult(generalSearchText)
            .then(
              (response) => {
                const responseConfig = response.config;
                const searchCriteria = responseConfig.params && responseConfig.params.searchCriteria;
                if (searchCriteria !== scope.generalSearchText) return false;

                scope.defaultListLimit = 3;

                scope.NameLimit = scope.defaultListLimit;
                scope.HomePhoneLimit = scope.defaultListLimit;
                scope.BusinessPhoneLimit = scope.defaultListLimit;
                scope.MobilePhoneLimit = scope.defaultListLimit;
                scope.EntityLimit = scope.defaultListLimit;
                scope.LoanReferenceNumberLimit = scope.defaultListLimit;
                scope.PolicyNumberLimit = scope.defaultListLimit;
                scope.EmailLimit = scope.defaultListLimit;

                scope.limit = {};
                scope.limit.defaultLimit = 3;
                scope.limit.client = scope.defaultListLimit;
                scope.limit.adviser = scope.defaultListLimit;
                scope.limit.adviserOrg = scope.defaultListLimit;
                scope.limit.referrer = scope.defaultListLimit;
                scope.limit.referrerOrg = scope.defaultListLimit;
                scope.limit.assistant = scope.defaultListLimit;


                scope.isSearching = false;
                const data = response.data;
                scope.generalSearchResult = {};
                scope.generalSearchResult.allResults = [];
                if (data && data.length) {
                  data.map((row) => {
                    const result = row.Result;
                    if (!result || !result.length) return [];
                    result.map((searchResult) => {
                      scope.generalSearchResult.allResults.push(searchResult);
                      return searchResult;
                    });
                    return result;
                  });
                }

                $timeout(() => {
                  _.map(scope.generalSearchResult.ClientList = _.filter(scope.generalSearchResult.allResults,
                    (obj) => {
                      return obj.ResultType === 'Client';
                    }
                  )
                  );
                  _.map(scope.generalSearchResult.AdviserList = _.filter(scope.generalSearchResult.allResults,
                    (obj) => {
                      return obj.ResultType === 'Adviser';
                    }
                  )
                  );

                  _.map(scope.generalSearchResult.AssistantList = _.filter(scope.generalSearchResult.allResults,
                    (obj) => {
                      return obj.ResultType === 'Assistant';
                    }
                  )
                  );

                  _.map(scope.generalSearchResult.AdviserOrgList = _.filter(scope.generalSearchResult.allResults,
                    (obj) => {
                      return obj.ResultType === 'Adviser Organisation';
                    }
                  )
                  );
                  _.map(scope.generalSearchResult.ReferrerList = _.filter(scope.generalSearchResult.allResults,
                    (obj) => {
                      return obj.ResultType === 'Referrer';
                    }
                  )
                  );
                  _.map(scope.generalSearchResult.ReferrerOrgList = _.filter(scope.generalSearchResult.allResults,
                    (obj) => {
                      return obj.ResultType === 'Referrer Organisation';
                    }
                  )
                  );
                });

                _.map(scope.generalSearchResult.EntityResult = _.filter(data,
                  function res(obj) {
                    return obj.MatchResultType === 'Entity';
                  }
                ));

                return scope.generalSearchResult;
              },
              () => {
                scope.generalSearchResult = [];
                scope.generalSearchResult.Result = [];
                scope.isSearching = false;
                return scope.generalSearchResult;
              });
        };

        scope.provideDisplay = (result) => {
          return result.ResultName || result.ProviderName || result.ResultContent;
        };

        scope.highlight = function (text, search) {
          if (!search) {
            return $sce.trustAsHtml(text);
          }
          if (typeof text === 'string' && _.isRegExp(text)) {
            return $sce.trustAsHtml(text.replace(new RegExp(search, 'gi'), '<strong>$&</strong>'));
          }
          return $sce.trustAsHtml(text);
        };

        scope.goToCustomer = function (result) {
          scope.openSearchResult = false;
          switch (result.ResultType.toLowerCase()) {
          case 'adviser organisation':
            $state.go('app.contactsAdviserOrg', { adviserOrgId: result.ResultID, adviserComplianceId: result.ClientId });
            break;
          case 'adviser':
          case 'principal adviser':
            $state.go('app.contactsAdviser', { clientId: result.ClientId, familyId: result.ResultID });
            break;
          case 'assistant':
            $state.go('app.contactsAssistant', { clientId: result.ClientId, familyId: result.ResultID });
            break;
          case 'referrer organisation':
          case 'referrer org':
            $state.go('app.contactsReferrerOrg', { referrerComplianceId: result.ClientId, referrerOrgId: result.ResultID });
            break;
          case 'referrer':
            $state.go('app.contactsReferrer', { clientId: result.ClientId, familyId: result.ResultID });
            break;
          default:
            $state.go('app.contactsSingle', { familyId: result.ResultID, activeTab: 'summary' });
            break;
          }
        };

        scope.goToBusiness = function (result) {
          scope.openSearchResult = false;
          $state.go(
            'app.BusinessAccount', { familyId: result.ResultFamilyID }
          );
        };

        scope.toggleListLimit = function (result) {
          switch (result) {
          case 'Name':
            scope.NameLimit = scope.NameLimit === scope.defaultListLimit ? scope.generalSearchResult.NameResult[0].Result.length : scope.defaultListLimit;
            break;

          case 'HomePhone':
            scope.HomePhoneLimit = scope.HomePhoneLimit === scope.defaultListLimit ? scope.generalSearchResult.HomePhoneResult[0].Result.length : scope.defaultListLimit;
            break;

          case 'BusinessPhone':
            scope.BusinessPhoneLimit = scope.BusinessPhoneLimit === scope.defaultListLimit ? scope.generalSearchResult.BusinessPhoneResult[0].Result.length : scope.defaultListLimit;
            break;

          case 'MobilePhone':
            scope.MobilePhoneLimit = scope.MobilePhoneLimit === scope.defaultListLimit ? scope.generalSearchResult.MobilePhoneResult[0].Result.length : scope.defaultListLimit;
            break;

          case 'Entity':
            scope.EntityLimit = scope.EntityLimit === scope.defaultListLimit ? scope.generalSearchResult.EntityResult[0].Result.length : scope.defaultListLimit;
            break;

          case 'LoanReferenceNumber':
            scope.LoanReferenceNumberLimit = scope.LoanReferenceNumberLimit === scope.defaultListLimit ? scope.generalSearchResult.LoanReferenceNumberResult[0].Result.length : scope.defaultListLimit;
            break;

          case 'PolicyNumber':
            scope.PolicyNumberLimit = scope.PolicyNumberLimit === scope.defaultListLimit ? scope.generalSearchResult.PolicyNumberResult[0].Result.length : scope.defaultListLimit;
            break;

          case 'Email':
            scope.EmailLimit = scope.EmailLimit === scope.defaultListLimit ? scope.generalSearchResult.EmailResult[0].Result.length : scope.defaultListLimit;
            break;
          default:
            break;
          }
        };

        scope.toggleListLimitContacts = function (result) {
          switch (result) {
          case 'client':
            scope.limit.client = scope.limit.client === scope.limit.defaultLimit ? scope.generalSearchResult.ClientList.length : scope.limit.defaultLimit;
            break;
          case 'adviser':
            scope.limit.adviser = scope.limit.adviser === scope.limit.defaultLimit ? scope.generalSearchResult.AdviserList.length : scope.limit.defaultLimit;
            break;
          case 'adviserOrg':
            scope.limit.adviserOrg = scope.limit.adviserOrg === scope.limit.defaultLimit ? scope.generalSearchResult.AdviserOrgList.length : scope.limit.defaultLimit;
            break;
          case 'referrer':
            scope.limit.referrer = scope.limit.referrer === scope.limit.defaultLimit ? scope.generalSearchResult.ReferrerList.length : scope.limit.defaultLimit;
            break;
          case 'referrerOrg':
            scope.limit.referrerOrg = scope.limit.referrerOrg === scope.limit.defaultLimit ? scope.generalSearchResult.ReferrerOrgList.length : scope.limit.defaultLimit;
            break;
          case 'assistant':
            scope.limit.assistant = scope.limit.assistant === scope.limit.defaultLimit ? scope.generalSearchResult.AssistantList.length : scope.limit.defaultLimit;
            break;
          default:
            break;
          }
        };

        // advanced filter search

        scope.showAdvancedSearch = function () {
          $uibModal.open({
            templateUrl: 'assets/views/modals/advanced-search.html',
            size: 'lg',
            backdrop: 'true',
            scope,
            controller($uibModalInstance, $filter) {
              // Filter
              scope.showResults = false;
              scope.showLoadingResult = false;
              $filter('lowercase')();
              scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
              };

              scope.lenderListSelected = {};
              const data = { ProviderId: 0, ProviderName: 'Please Select Lender' };
              scope.lenderLists = [];
              scope.resultLists = [];
              contactService.lenderListGet(true, lenderPlace).then((response) => {
                angular.forEach(response.data, (value) => {
                  scope.lenderLists.push(value);
                });

                scope.lenderLists.unshift(data);
                scope.lenderListSelected = scope.lenderLists[0];
              });

              scope.searchResult = function (pclientName, padviserName, plenderListSelected, ploanReferenceNo, pLoanId) {
                scope.resultLists = [];

                scope.showLoadingResult = true;

                const clientName = pclientName || '';
                const adviserName = padviserName || '';
                let providerName = plenderListSelected.ProviderId > 0 ? plenderListSelected.ProviderName : scope.lenderListSelected.ProviderName;
                const loanReferenceNo = ploanReferenceNo || '';
                const loanId = pLoanId || '';


                if (plenderListSelected.ProviderId === 0) {
                  providerName = '';
                }
                dashboardService.generalLoanSearchGet(clientName, adviserName, providerName, loanReferenceNo, loanId).then((response) => {
                  if (response && response.data) {
                    scope.showLoadingResult = false;
                    angular.forEach(response.data, (value) => {
                      if (value.LodgedDate) {
                        value.LodgedDate = scope.formatDate(value.LodgedDate);
                      }

                      if (value.SettledDate) {
                        value.SettledDate = scope.formatDate(value.SettledDate);
                      }

                      if (value.SubmittedDate) {
                        value.SubmittedDate = scope.formatDate(value.SubmittedDate);
                      }
                      scope.resultLists.push(value);
                    });
                  } else {
                    scope.showLoadingResult = false;
                    scope.showResults = true;
                  }
                });
              };

              scope.gotoClient = function (resultList) {
                if (resultList.StatusName === 'In Progress') {
                  if (resultList.EntityName) {
                    $state.go('app.BusinessAccount', { familyId: resultList.FamilyID, activeTab: 'loans', loanId: resultList.LoanId, inprogress: 1 }, { reload: true });
                  } else {
                    $state.go('app.contactsSingle', { familyId: resultList.FamilyID, activeTab: 'lending', loanId: resultList.LoanId, inprogress: 1 }, { reload: true });
                  }
                } else if (resultList.EntityName) {
                  $state.go('app.BusinessAccount', { familyId: resultList.FamilyID, activeTab: 'loans', loanId: resultList.LoanId, inprogress: 0 }, { reload: true });
                } else {
                  $state.go('app.contactsSingle', { familyId: resultList.FamilyID, activeTab: 'lending', loanId: resultList.LoanId, inprogress: 0 }, { reload: true });
                }
                $uibModalInstance.dismiss('cancel');
              };

              scope.formatDate = function (date) {
                return new Date(date);
              };
            },
          });
        };
      },

    };
  });
