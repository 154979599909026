import angular from 'angular';
import createNewLoanApplication from './createNewLoanApplication';
import borrowers from './borrowers';
import guarantors from './guarantors';

export default angular.module('components.newLoanApplicationModal', [])
  .component('createNewLoanApplication', createNewLoanApplication)
  .component('borrowers', borrowers)
  .component('guarantors', guarantors)
  .name;
