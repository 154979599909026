import template from './pipelineKanbanColumn.html';

export default {
  template,
  bindings: {
    column: '<',
    columnIndex: '<',
    isCompact: '<',
    kanbanBoardHeight: '<',
    pipelineType: '<',
    pipelineTypes: '<',
    kanbanBoardOptions: '<',
    cardViewStates: '<',
    onClickPipelineItem: '&',
    onDeletePipelineItem: '&',
  },
};
