import angular from 'angular';
import _ from 'lodash';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';

angular.module('app').controller('AddressesCtrl',
  function AddressesCtrl($scope,
    contactService,
    $uibModal,
    mapService,
    $interval,
    toaster,
    SweetAlert,
    utilitiesService,
    colorService,
    uiService,
    $window
  ) {
    $scope.is_map_loaded = false;
    $scope.$on('IS_MAP_LOADED', () => {
      $scope.is_map_loaded = true;
    });
    $scope.uiService = uiService;

    $scope._init = function () {
      contactService.contactAddressGet($scope.familyId).then((response) => {
        $scope.propertyList = response.data;
        _.forEach($scope.propertyList, (o) => {
          if (o.ContactLivingIn) {
            _.forEach(o.ContactLivingIn, (obj) => {
              obj.background = colorService.getRandomColor();
              if (!_.isUndefined(obj.LastName) || obj.LastName !== '') {
                obj.initials = utilitiesService.filterInitial(obj.FirstName, obj.LastName);
                obj.displayName = `${obj.FirstName} ${obj.LastName}`;
              } else {
                obj.initials = utilitiesService.filterInitialOneString(obj.FirstName);
                obj.displayName = obj.FirstName;
              }
            });
          }
          o.isShowDates = !(parseInt(o.TypeId, 10) === ADDRESS_TYPE.POSTAL_ADDRESS || parseInt(o.TypeId, 10) === ADDRESS_TYPE.POST_SETTLEMENT_ADDRESS || parseInt(o.TypeId, 10) === ADDRESS_TYPE.OTHER);
        });

        const googleInterval = $interval(() => {
          if (!$window.google) return false;
          // proceed
          _.forEach($scope.propertyList, (property) => {
            if (property.latitude && property.longitude) {
              property.hasMap = true;
              mapService.mapDesign(property.latitude, property.longitude, property.formatted_address, undefined, false, `map-${property.AddressValuationExtendedDetailsId}`, '30');
            } else {
              property.hasMap = false;
            }
          });
          $interval.cancel(googleInterval);
        }, 500);
      });
    };

    $scope.contactPropertySet = {
      EstimatedValue: 0,
      // "CategoryId": "",
      Address: {
        AddressID: '0',
      },
      PropertyName: '',
      LocationDesc: '',
      Ownership: [],
      OwnershipTypeId: '',
      FloorNumber: '',
      // "FloorTypeId": "",
      DischargeDate: new Date(),
      PurchaseDate: new Date(),
      StartDate: new Date(),
      EndDate: new Date(),
      // "TitleTypeId": "",
      // "TenureTypeId": "",
      FullAddress: {
        formatted_address: '',
      },
    };

    // Add
    $scope.newAddressesModal = function () {
      const modalType = 'new';
      const familyId = parseInt($scope.familyId, 10);
      const modalInstance = $uibModal.open({
        templateUrl: `/assets/views/contacts/addresses/addressesModal.html?hash=${Math.random().toString(36).substring(20)}`,
        controller: 'AddressesNewModalCtrl',
        resolve: {
          modalType() {
            return modalType;
          },
          familyId() {
            return familyId;
          },
          contactPropertySet() {
            return $scope.contactPropertySet;
          },
        },
        keyboard: false,
        backdrop: 'static',
      });

      modalInstance.result.then(() => {
        $scope._init();
      }, () => {
        $scope._init();
      });
    };

    // Edit
    $scope.editAddressesModal = function (property) {
      const modalType = 'edit';
      const familyId = parseInt($scope.familyId, 10);

      contactService.contactAddressDetailsGet(property.AddressValuationExtendedDetailsId, $scope.familyId).then((response) => {
        if (!response.data || !response.data.Address) return;
        $scope.propertyDetailsList = response.data;
        $scope.propertyDetailsList.FullAddress = $scope.propertyDetailsList.Address;

        const modalInstance = $uibModal.open({
          templateUrl: `/assets/views/contacts/addresses/addressesModal.html?hash=${Math.random().toString(36).substring(20)}`,
          controller: 'AddressesNewModalCtrl',
          resolve: {
            modalType() {
              return modalType;
            },
            familyId() {
              return familyId;
            },
            contactPropertySet() {
              return $scope.propertyDetailsList;
            },
          },
        });

        modalInstance.result.then(() => {
          $scope._init();
        }, () => {
          $scope._init();
        });
      });
    };

    // Delete
    $scope.deleteAddress = function (addressValuationExtendedDetailsId) {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your addresses list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      }, (confirm) => {
        if (confirm) {
          contactService.contactAddressDelete(addressValuationExtendedDetailsId, $scope.familyId).then(() => {
            $scope._init();
            toaster.pop('success', 'Deleted', 'Property has been been successfully deleted.');
          });
        }
      });
    };

    // init
    $scope.$watch('selectedContacts', (newValue) => {
      if (newValue === 'addresses') {
        $scope._init();
      }
    });
  }
);
