
export function filterClientList(FamilyId, clientList) {
  return clientList.length && clientList.reduce((accum, elem) => {
    const isExcluded = parseInt(elem.FamilyId, 10) === parseInt(FamilyId, 10);
    if (isExcluded) return accum;
    return [...accum, elem];
  }, []);
}

export function clientTitles() {
  // TODO: move this to API
  return [
    { Value: 'Mr', Name: 'Mr' },
    { Value: 'Mrs', Name: 'Mrs' },
    { Value: 'Ms', Name: 'Ms' },
    { Value: 'Miss', Name: 'Miss' },
    { Value: 'Master', Name: 'Master' },
    { Value: 'Doctor', Name: 'Doctor' },
  ];
}
