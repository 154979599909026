import angular from 'angular';

class BenefitTypeDescriptionCtrl {
  constructor(
    $uibModalInstance,
    benefitType
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.benefitType = benefitType;
  }
}

angular.module('app').controller('BenefitTypeDescriptionCtrl', BenefitTypeDescriptionCtrl);
