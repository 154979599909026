import { KanbanOpportunity } from 'Common/services/kanban';
import { PIPELINE_TYPES_STR } from 'Common/constants/pipelineType';
import { capitalizeFirstLetter } from 'Common/utilities/string';

export default class PipelineKanbanCtrl {
  constructor(pipelineService, pipelineSharedData, loanOpportunityService, modalRenderService, $stateParams, $timeout, $window, $q) {
    'ngInject';

    this.pipelineService = pipelineService;
    this.pipelineSharedData = pipelineSharedData;
    this.loanOpportunityService = loanOpportunityService;
    this.modalRenderService = modalRenderService;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$q = $q;

    this.removeCard = this.removeCard.bind(this);
  }

  $onInit() {
    this.PIPELINE_TYPES_STR = PIPELINE_TYPES_STR;
    this.pipelineType = this.$stateParams.pipelineType;
    this.statusList = null;

    this.kanbanArgs = {
      name: `${capitalizeFirstLetter(this.pipelineType)} Kanban`,
      pipelineSharedData: this.pipelineSharedData,
      pipelineService: this.pipelineService,
      loanOpportunityService: this.loanOpportunityService,
      modalRenderService: this.modalRenderService,
      $timeout: this.$timeout,
      $window: this.$window,
    };
    this.loadKanban();
  }

  loadKanban() {
    this.kanbanLoading = true;
    const promises = [
      this.getStatuses(),
      this.getCards(),
    ];

    /**
    * please make sure not to interchange the values of @param promises
    * as it is tightly coupled with the code block below
    */
    this.$q.all(promises)
      .then((data) => {
        if (!data || !data.length) return;

        this.kanbanLoading = false;

        this.statusList = data[0];
        const cards = (data[1] && data[1].cards) || [];
        const totalLoanAmounts = (data[1] && data[1].totalLoanAmounts) || [];

        this.kanbanArgs.columns = [];
        this.kanbanArgs.generalCards = cards;
        this.kanbanArgs.totalLoanAmounts = totalLoanAmounts;
        this.kanbanArgs.statusList = this.statusList;

        this.kanbanBoard = new KanbanOpportunity(this.kanbanArgs);
      });
  }

  getStatuses() {
    return this.statusList || this.pipelineService.SettingsStatusGet()
      .then((res) => {
        return res && res.data.filter(status => status.CategoryName === capitalizeFirstLetter(this.pipelineType));
      });
  }

  getCards() {
    switch (this.pipelineType) {
    case PIPELINE_TYPES_STR.OPPORTUNITY:
      return this.pipelineService.getPipelineOpportunityCards();
    default:
      return [];
    }
  }

  removeCard(args = {}) {
    const { columnIndex, cardIndex } = args;

    if (typeof columnIndex !== 'number' || typeof cardIndex !== 'number') {
      /**
      * let's hack this delete to just reload the kanban if this worst-case scenario happens (no columns and cards as args)
      */
      this.loadKanban();
    } else {
      const { columns } = this.kanbanBoard;
      const { cards } = columns[columnIndex];
      cards.splice(cardIndex, 1);

      switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.kanbanBoard.getLoanOpportunityTotalLoanAmounts();
        break;
      default:
        break;
      }
    }
  }
}
