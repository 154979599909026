import _ from 'lodash';
import { parseIntValue } from 'Common/utilities/parse';

const DEFAULT_REA = {
  ZONING: {
    RESIDENTIAL: '2',
  },
  PRIMARY_PURPOSE: {
    OWNER_OCCUPIED: '1',
  },
  PROPERTY_TYPE: {
    STD_RESIDENTIAL: 68,
    FULLY_DETACHED_HOUSE: 13,
  },
};
const DEFAULT_MORTGAGE = {
  PRIORITY: {
    FIRST: '1',
  },
  LOAN_TERM: {
    YEARS_30: 30,
  },
  TERM_TYPE: {
    PRINCIPAL_INTEREST: 2,
  },
};
class FinanceService {
  constructor(financialsSharedService, loanAppSharedData, optionsService, $q, uiService) {
    'ngInject';

    this.scope = {};
    this.q = $q;
    this.optionsService = optionsService;
    this.financialsSharedService = financialsSharedService;
    this.loanAppSharedData = loanAppSharedData;
    this.uiService = uiService;
  }
  getDefaultREAAU() {
    if (!this.uiService.isCountry('Australia')) return {};

    return {
      zoning: DEFAULT_REA.ZONING.RESIDENTIAL,
      primaryPurposeId: DEFAULT_REA.PRIMARY_PURPOSE.OWNER_OCCUPIED,
      propertyTypeId: DEFAULT_REA.PROPERTY_TYPE.FULLY_DETACHED_HOUSE,
    };
  }
  getDefaultMortgageAU() {
    if (!this.uiService.isCountry('Australia')) return {};

    return {
      mortgagePriorityId: DEFAULT_MORTGAGE.PRIORITY.FIRST,
      loanTerm: DEFAULT_MORTGAGE.LOAN_TERM.YEARS_30,
      principalInterest: DEFAULT_MORTGAGE.TERM_TYPE.PRINCIPAL_INTEREST,
    };
  }

  mapAssetType(data) {
    this.assetType = [];
    if (data && data.length) {
      this.assetType = data.map((o) => {
        o.Desc = o.Name;
        o.Value = parseInt(o.Value, 10);
        o.Group = o.GroupName;
        switch (o.Group) {
        case 'Real Estate':
          o.FormGroup = 'Real Estate';
          break;
        case 'Vehicle':
          if (o.Desc === 'Boat')
            o.FormGroup = 'Assets';
          else
            o.FormGroup = 'Vehicle';
          break;
        case 'Investment':
          if (o.Desc === 'Savings')
            o.FormGroup = 'Bank Accounts';
          else
            o.FormGroup = 'Assets';
          break;
        case 'Cash Savings':
          if (o.Desc === 'Cash')
            o.FormGroup = 'Assets';
          else
            o.FormGroup = 'Bank Accounts';
          break;
        case 'Other':
        case 'Contents':
        case 'Business':
        case 'Superannuation':
          o.FormGroup = 'Assets';
          break;
        default:
          break;
        }
        return o;
      });
    }
    return this.assetType;
  }

  SubAssetTypeCall(type) {
    const defer = this.q.defer();
    const list = {};
    if (_.size(type) > 0 && type.Value) {
      this.optionsService.SubAssetTypeGet(type.Value).then((response) => {
        if (response && response.data) {
          defer.resolve(response.data);
        }
      });
    } else {
      defer.resolve(list);
    }
    return defer.promise;
  }

  changeAssetType(scope) {
    this.scope = scope;
    if (_.size(this.scope.AssetsSet.Type) > 0 && this.scope.AssetsSet.Type.FormGroup !== 'Assets') {
      this.scope.AssetsModal('new');
      this.getSubAssetType(this.scope);
    }
  }

  getSubAssetType(scope) {
    if (!scope || !scope.AssetsSet || !scope.AssetsSet.Type) return;
    this.scope = scope;
    this.SubAssetTypeCall(this.scope.AssetsSet.Type)
      .then((res) => {
        this.scope.subAssetTypes = parseIntValue(res);
      });
  }

  changeLiabilityType(scope) {
    this.scope = scope;
    if (_.size(this.financialsSharedService.LiabilitiesSet.Type) > 0 && this.financialsSharedService.LiabilitiesSet.Type.Template !== 'comm') {
      this.scope.LiabilitiesModal('new');
    }
  }

  hasSubType(scope) {
    this.scope = scope;
    return this.scope.AssetsSet && this.scope.AssetsSet.Type && this.scope.AssetsSet.Type.FormGroup && this.scope.AssetsSet.Type.FormGroup !== 'Assets' && this.scope.AssetsSet.Type.Value;
  }
}
export default FinanceService;
