import _ from 'lodash';

class LoanApplicantAssetInfoService {
  constructor(loanScenarioService, $q, contactService) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.$q = $q;
  }

  getAssetInfo(loanAppId, refreshData) {
    const isDifferentLoanApp = parseInt(loanAppId, 10) !== parseInt(this.currentLoanAppId, 10);
    if (isDifferentLoanApp) {
      this.clearAssetInfo();
      this.currentLoanAppId = loanAppId;
    }

    const isFetchingAssetInfo = this.getAssetInfoPromise;
    if (isFetchingAssetInfo) return this.getAssetInfoPromise;

    const isEmptyAssetInfo = !this.assetInfo || !this.assetInfo.length;
    if (isEmptyAssetInfo || refreshData) {
      this.getAssetInfoPromise = this.loanScenarioService.AssetInfoGet(loanAppId).then((response) => {
        this.assetInfo = response && response.data;
        this.getAssetInfoPromise = null;
        return response;
      });
      return this.getAssetInfoPromise;
    }

    return this.fetchedAssetInfo();
  }

  fetchedAssetInfo() {
    const deferred = this.$q.defer();
    deferred.resolve({ data: this.assetInfo });
    return deferred.promise;
  }

  clearAssetInfo() {
    this.assetInfo = null;
    this.getAssetInfoPromise = null;
    this.currentLoanAppId = null;
  }

  updateIds(assetsSet, valueBasisList) {
    this.valueBasisList = valueBasisList;
    const result = _.first(this.valueBasisList);
    if (result) {
      assetsSet.ValueBasisId = result.Value;
    }
    assetsSet.AssetSubtypeId = '1';
  }

  lenderListGet(lenderId, lenderPlace) {
    let listLender = [];
    const defer = this.$q.defer();
    this.contactService.lenderListGet(lenderId, lenderPlace).then((response) => {
      listLender = response.data;
      defer.resolve({ data: listLender });
    });

    return defer.promise;
  }
}

export default LoanApplicantAssetInfoService;
