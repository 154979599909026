class FinancialCalculatorService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'fincalc';
  }

  getRepaymentAmountFloatingType(loanAmount, annualInterestRate, loanTermYears, repaymentFrequency, interestOnly) {
    return this.httpClient.get(`${this.apiBaseUrl}/RepaymentAmount`, {
      loanAmount,
      annualInterestRate,
      loanTermYears,
      repaymentFrequency,
      interestOnly,
    });
  }
}

export default FinancialCalculatorService;
