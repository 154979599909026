import { reject } from 'lodash';
import moment from 'moment-timezone';
import DD_MMM_YYYY_FORMAT from 'Common/constants/dateConfigs';
import { getDatePickerConfig } from 'Common/utilities/date';

class TaskFormModalCtrl {
  constructor(
    toaster,
    contactService,
    dashboardService,
    taskService,
    tasksSharedData,
    clientInfoService,
    taskDetailService
  ) {
    'ngInject';

    this.toaster = toaster;
    this.contactService = contactService;
    this.dashboardService = dashboardService;
    this.taskService = taskService;
    this.taskDetailService = taskDetailService;
    this.clientInfoService = clientInfoService;
    this.tasksSharedData = tasksSharedData;
  }

  $onInit() {
    this.taskSet = {
      FamilyId: 0,
      TaskType: 'Business',
      Notes: '',
      LoanId: this.loanId,
    };

    this.mainObject = {};
    this.mainObject.isSearchEnable = false;
    this.mainObject.datePopUp = false;
    this.format = DD_MMM_YYYY_FORMAT;
    this.dateOptions = getDatePickerConfig();
    this.updateFamilyOnAdviserChange = this.tasksSharedData.updateFamilyOnAdviserChange;
    this.isLoading = false;

    this.taskInput = {
      familyValue: '',
    };
    this.loadData();
  }

  loadData() {
    if (this.taskId) {
      this.taskService.taskDetailsGet(this.taskId)
        .then((respond) => {
          if (!respond || !respond.data) return;
          this.taskSet = respond.data;

          this.selectFamily(this.taskSet.FamilyID, this.taskSet.ClientFamilyName);
          this.taskSet.DueDate = new Date(this.taskSet.DueDate);
          if (this.taskSet && this.taskSet.CreatedByUser) {
            this.taskSet.CreatedByUser = this.taskSet.CreatedByUser.toString();
          }

          this.showNotes = true;
          if (!this.taskSet.Notes || !this.taskSet.CreatedByUser.trim()) {
            this.showNotes = false;
            this.selectFamily(this.taskSet.FamilyID, this.taskSet.ClientFamilyName);
          }
        });
    } else {
      this.taskSet.DueDate = new Date();
    }

    if (this.familyId && !this.loanId) {
      this.contactService.clientInformGet(this.familyId)
        .then((response) => {
          if (!response || !response.data) return;
          const data = response.data;
          this.taskInput.familyValue = this.clientInfoService.getFullName(data);
          this.taskSet.FamilyId = this.familyId;
        });
    }

    this.contactService.taskAdviserListGet()
      .then((respond) => {
        if (!respond || !respond.data) return;
        this.taskAdviserList = respond.data;
        this.taskAdviserList = reject(respond.data, ['BrokerID', 0]);
        respond.data.forEach((item) => {
          if (!item) return;
          item.BrokerID = item.BrokerID && item.BrokerID.toString();
        });
        if (parseInt(this.taskId, 10) === 0) {
          this.dashboardService.getBrokerBasicInfo()
            .then((response) => {
              if (!response || !response.data) return;
              this.taskSet.assignedClientID = response.data.BrokerId;
              this.taskSet.CreatedByUser = response.data.BrokerId;
            });
        }
      });
    this.contactService.getAllByFilter('*')
      .then((respond) => {
        if (!respond || !respond.data) return;
        respond.data.FamilyList.forEach((value) => {
          if (!value) return;
          if (value.FamilyID === this.familyId) {
            this.taskInput.familyValue = value.FamilyFullName;
            if (parseInt(this.taskId, 10) === 0) {
              this.taskSet.FamilyId = value.FamilyID;
            }
          }
        });
      });
  }

  openDate() {
    this.mainObject.datePopUp = !this.mainObject.datePopUp;
  }

  showCalendar() {
    this.mainObject.datePopUp = true;
  }

  closeFamilyList() {
    this.familyDetailsList = this.taskDetailService.closeFamilyList(this.taskInput, this.mainObject);
  }

  selectFamily(id, contactFullName) {
    this.familyDetailsList = this.taskDetailService.selectFamily(id, contactFullName, this.taskInput, this.taskSet, this.mainObject);
  }

  addNotes() {
    if (!this.taskSet.Notes || !this.taskSet.CreatedByUser.trim()) {
      this.showNotes = true;
    }
  }

  taskCompletionSet(task) {
    this.taskService.taskCompletionSet(task.FamilyID, task.TaskID, task.IsCompleted)
      .then((response) => {
        if (response && response.data !== 1) {
          task.IsCompleted = !task.IsCompleted;
        } else {
          this.modalInstance.close('saved');
        }
      });
  }

  toggleOneComplete(task) {
    task.IsCompleted = !task.IsCompleted;

    if (!task.IsCompleted) {
      this.taskCompletionSet(task);
      return;
    }

    this.tasksSharedData.completionConfirmation(() => {
      this.taskCompletionSet(task);
    }, () => {
      task.IsCompleted = false;
    });
  }

  filterFamily(query) {
    const involvedParties = [{
      FamilyId: 0,
      FamilyFullName: 'All Involved Parties',
      IsClient: true,
    }];
    const result = this.familyList.filter((el) => {
      return el.FamilyFullName.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });

    return [...result, ...involvedParties];
  }

  searchFamilyInput(searchString) {
    this.mainObject.isSearchEnable = true;
    this.familyDetailsList = this.filterFamily(searchString);
  }

  saveNewTask() {
    this.taskSet.FamilyId = (this.taskInput.familyValue) ? this.taskSet.FamilyId : 0;

    const { FamilyId, Title, CreatedByUser, Notes, ConnectID, DueDate, assignedClientID } = this.taskSet;
    const taskData = {
      FamilyID: FamilyId || '',
      Title,
      CreatedByUser,
      LoanId: this.loanId,
      assignedClientID,
      Notes,
      ConnectID,
      DueDate: moment(DueDate).format('YYYY-MM-DD'),
    };

    let msg;
    let serviceMethod;
    if (this.taskId) {
      taskData.TaskID = this.taskId;
      msg = 'Updated';
      serviceMethod = 'updateTask';
    } else {
      msg = 'Added';
      serviceMethod = 'storeTask';
    }

    this.isLoading = true;
    this.taskService[serviceMethod](taskData)
      .then(() => {
        this.isLoading = false;
        this.toaster.pop('success', msg, `Task successfully ${msg.toLowerCase()}`);
        this.modalInstance.close('saved');
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

export default TaskFormModalCtrl;
