export const FIXED_RATE_EXPIRES_ID = 1;
export const APPROVAL_EXPIRIES_ID = 2;
export const BIRTHDAYS_ID = 3;
export const ANNUAL_REVIEWS_ID = 4;
export const POST_SETTLEMENT_FOLLOW_UP_ID = 5;

export const CUSTOMER_CARE_TYPES = {
  POST_SETTLEMENT_FOLLOW_UP: 'SETTLEMENT',
  FIXED_RATE_EXPIRES: 'FIXED_RATE_EXPIRY',
  APPROVAL_EXPIRIES: 'PRE_APPROVAL_EXPIRY',
  BIRTHDAYS: 'BIRTH_DAY',
  ANNUAL_REVIEWS: 'CLIENT_ANNUAL_REVIEW',
};

export const SERVICE_TAB_INDEX = {
  FIXED_RATE_EXPIRES: 0,
  APPROVAL_EXPIRIES: 1,
  BIRTHDAYS: 2,
  ANNUAL_REVIEWS: 3,
  POST_SETTLEMENT: 4,
};

export const SERVICE_ID = {
  FIXED_RATE_EXPIRES: 4,
  APPROVAL_EXPIRIES: 5,
  BIRTHDAYS: 3,
  ANNUAL_REVIEWS: 1,
  POST_SETTLEMENT: 2,
};

