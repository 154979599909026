import angular from 'angular';
import _ from 'lodash';
import { INSURANCE_LOAN_APP } from 'Common/constants/insuranceOptions';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { excludedInsuranceQuestions } from 'Common/config/loanAppQuestions';

angular.module('app').controller('LoanAppInsuranceCtrl',
  function LoanAppInsuranceCtrl($rootScope,
    $scope,
    $state,
    $window,
    $timeout,
    $compile,
    $interval,
    $uibModal,
    $document,
    $stateParams,
    $localStorage,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    utilitiesService,
    optionsService,
    watcherService,
    timeoutService,
    loanAppSharedData) {
    // Property
    let clickCounter = 0;

    angular.extend($scope, {
      loanAppId: $stateParams.loanAppId,
      generalInsuranceOption: [],
      lifestyleInsuranceOption: [],
      riskInsuranceOption: [],
      insuranceQuestionList: [],
      insuranceObjSet: {},
      editExecCount: 0,
      modalOpened: 0,
      uiSettings: {
        isTouched: false,
      },
    });

    $scope.excludedQuestions = excludedInsuranceQuestions;

    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    /* end of listeners */
    loanApplicationServices.loanSummaryGet($state.params.loanAppId).then(() => {
      $scope.processInsuranceSection = () => {
        if (clickCounter !== 0) return;

        clickCounter++;

        // Method
        angular.extend($scope, {

          init() {
            /**
             * Calling watchers and listeners :)
             * */

            loanAppSharedData.insurance.section.excludeIds.push(INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE);

            $scope.listeners();

            optionsService.GeneralInsuranceGet().then((response) => {
              $scope.generalInsuranceOption = response.data;
            });
            optionsService.LifestyleInsuranceGet().then((response) => {
              $scope.lifestyleInsuranceOption = response.data;
            });
            optionsService.RiskInsuranceGet().then((response) => {
              $scope.riskInsuranceOption = response.data;
            });
            optionsService.question('insurance').then((response) => {
              $scope.insuranceQuestionList = response.data;
              for (let i = 0; i < $scope.insuranceQuestionList.length; i++) {
                if (parseInt($scope.insuranceQuestionList[i].QuestionId, 10) === INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE) {
                  $scope.insuranceQuestionList[i].forOrdering = 'A';
                } else {
                  $scope.insuranceQuestionList[i].forOrdering = 'Z';
                }
              }
              $scope.orderByInsurance = 'forOrdering';
            });
            $scope.getInsuranceQuestionValue();

            $scope.$on('$destroy', () => {
              watcherService.destroyWatchers(WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL);
              timeoutService.cancelTimeouts(WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL);
            });
          },

          listeners() {
            /**
                     *  Validates every update on object
                     */
            const insuranceObjSetWatcher = $scope.$watch('insuranceObjSet', (nv, ov) => {
              if (!_.isEqual(nv, ov)) {
                $scope.validateInsurance();
              }
            }, true);
            watcherService.addWatcher(insuranceObjSetWatcher, WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL);
          },

          getInsuranceQuestionValue() {
            loanScenarioService.loanInsuranceGet($scope.loanAppId).then((response) => {
              _.forEach(response.data, (obj) => {
                let answerValue = null;
                switch (true) {
                case typeof obj.answerInt !== 'undefined':
                  answerValue = String(_.head(obj.answerInt));
                  break;
                case typeof obj.answerString !== 'undefined':
                  answerValue = _.head(obj.answerString);
                  break;
                case typeof obj.answerBool !== 'undefined':
                  answerValue = _.head(obj.answerBool);
                  break;
                default:
                  break;
                }
                $scope.insuranceObjSet[obj.QuestionId] = answerValue;
              });
            });
          },

          recheckRequiredFields() {
            _.remove(loanAppSharedData.insurance.section.excludeIds, o => o === INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE);
            _.remove(loanAppSharedData.insurance.section.excludeIds, o => o === INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER);
            _.remove(loanAppSharedData.insurance.section.excludeIds, o => o === INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP);
            _.remove(loanAppSharedData.insurance.section.excludeIds, o => o === INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER);
            if ($scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE]) {
              $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE] = false;
              loanAppSharedData.insurance.section.excludeIds.push(INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE);
            } else {
              $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER] = false;
              $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP] = false;
              $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER] = false;
              loanAppSharedData.insurance.section.excludeIds.push(INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER, INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP, INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER);
            }
          },

          resetLifestyleQuestion() {
            const options = [INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE, INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER, INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP, INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER];
            _.each(options, (o) => {
              const insuranceSet = {
                LoanScenarioId: $scope.loanAppId,
                FamilyId: $stateParams.familyId,
                QuestionId: o,
                DisplayOptionId: o !== INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE ? INSURANCE_LOAN_APP.FIELD.RADIOBOX_ON_YES : INSURANCE_LOAN_APP.FIELD.RADIOBOX_ON_NO,
                answerBool: [false],
              };
              loanScenarioService.loanInsuranceSet(insuranceSet);
            });
          },

          updateInsuranceQuestion(questionId, displayType, displayTypeId, otherArgs) {
            if (questionId === INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE) {
              $scope.resetLifestyleQuestion();
              $scope.recheckRequiredFields();
            }

            // if text is empty will return to avoid undefined
            if (typeof $scope.insuranceObjSet[questionId] === 'undefined' && !otherArgs) {
              return false;
            }
            $scope.toggleTouch();
            let insuranceSet = {};
            if (!otherArgs) {
              insuranceSet = {
                LoanScenarioId: $scope.loanAppId,
                FamilyId: $stateParams.familyId,
                QuestionId: questionId,
                DisplayOptionId: displayTypeId,
              };

              switch (displayType) {
              case 'CheckBox':
              case 'RadioButton':
              case 'YesNoOnly':
              case 'RadioBoxOnYes':
              case 'RadioBoxOnNo':
                insuranceSet.answerBool = [$scope.insuranceObjSet[questionId]];
                break;
              case 'TextboxOnNo':
              case 'TextboxOnYes':
              case 'TextOnly':
                insuranceSet.answerString = [String($scope.insuranceObjSet[questionId])];
                break;
              case 'MultiDropDown':
              case 'SingleDropDown':
              case 'SingleDropDownNoFrame':
                insuranceSet.answerInt = [parseInt($scope.insuranceObjSet[questionId], 10)];
                break;
              default:
                break;
              }
              loanScenarioService.loanInsuranceSet(insuranceSet);
            } else if (parseInt(otherArgs.mainObj.QuestionId, 10) === INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE) {
              let subQuestions = angular.copy(otherArgs.mainObj.SubQuestion);
              const filterSubQuestions = o => (o.QuestionDisplayType === 'RadioBoxOnYes' || o.QuestionDisplayType === 'RadioBoxOnNo');
              subQuestions = _.filter(subQuestions, filterSubQuestions);

              _.forEach(subQuestions, (o) => {
                if (o.QuestionDisplayType === 'RadioBoxOnYes' || o.QuestionDisplayType === 'RadioBoxOnNo') {
                  // uncheck the rest
                  $scope.insuranceObjSet[o.QuestionId] = parseInt(questionId, 10) === parseInt(o.QuestionId, 10);

                  insuranceSet = {
                    LoanScenarioId: $scope.loanAppId,
                    FamilyId: $stateParams.familyId,
                    QuestionId: o.QuestionId,
                    DisplayOptionId: o.QuestionDisplayTypeId,
                    answerBool: [(parseInt(questionId, 10) === parseInt(o.QuestionId, 10))],
                  };
                  loanScenarioService.loanInsuranceSet(insuranceSet);
                }
              });
            }
            $scope.validateInsurance();
          },

          validateInsurance() {
            let validDeclarationProp = false;
            let countDeclarationPop = 0;

            _.each($scope.insuranceObjSet, (val, key) => {
              const touchedLifestyle = $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE] || $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE] === false;
              if (touchedLifestyle) {
                validDeclarationProp = $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE] || $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER] || $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP] || $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER];
              }

              if (val) validDeclarationProp = true;

              if (validDeclarationProp && !loanAppSharedData.insurance.section.excludeIds.includes(parseInt(key, 10))) {
                countDeclarationPop++;
              }
            });
            $scope.isStartedInsurance = countDeclarationPop >= 1;
            $scope.isValidInsurance = validDeclarationProp && countDeclarationPop >= 2;

            if ($scope.isValidInsurance && $scope.modalOpened < 1 && $scope.uiSettings.isTouched) {
              const reportsInitTimeout = $timeout(() => {
                $scope.reports.init();
              }, 100);
              timeoutService.addTimeout(reportsInitTimeout, WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL);
              $scope.modalOpened++;
            }

            const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.INSURANCE);
            loanAppSharedData.completionStatusSet(SECTION_ID.INSURANCE, sectionObject.IsCompleted, $scope.isValidInsurance);

            $scope.countValids($scope.isValidInsurance, 'insurance');
          },

          toggleTouch() {
            $scope.uiSettings.isTouched = true;
          },

          incEditCount() {
            const incEditCountTimeout = $timeout(() => {
              if ($scope.isValidInsurance) {
                $scope.editExecCount++;
              }
            }, 200);
            timeoutService.addTimeout(incEditCountTimeout, WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL);
          },

          checkHasAnswer: (hasAnswer, questionId) => {
            if (parseInt(questionId, 10) === INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE) {
              const discussLifestyle = ($scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER] || $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP] || $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER]);
              const isValid = (hasAnswer && discussLifestyle) || (!hasAnswer && $scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE]);
              return (isValid);
            }
            return hasAnswer;
          },

          reports: {

            getLoanAppDetails(callback) {
              loanScenarioService.scenarioDetailsGet(
                $scope.loanAppId
              )
                .then((response) => {
                  const resp = response.data;

                  if (resp.IsShowCPACQPRecommendation === false) {
                    callback();
                  }
                });
            },

            createModal(e) {
              /**
               * Create modal container
               * */

              if (e && e.currentTarget) {
                const that = e.currentTarget;

                that.listeners();
              }


              const modalDiv = $window.document.createElement('div');
              modalDiv.className = 'recommended-reports-modal';
              modalDiv.setAttribute('style', `overflow:hidden;height:${angular.element($window.document).outerHeight()}px`);
              $window.document.body.appendChild(modalDiv);
              angular.element('#loan-application-v3').prepend(modalDiv);

              const hackbg = $window.document.createElement('div');
              hackbg.setAttribute('style', 'background:rgba(27,20,57,0.9)');
              angular.element('.app-aside .sidebar-container').css({ opacity: 0.1 });
              angular.element('.navbar-header').css({ opacity: 0 });
              angular.element('#top-navbar').css({ opacity: 0 });
              angular.element('.facts-widget').css({ opacity: 0.01 });
              angular.element('.calculations-widget').css({ opacity: 0.01 });


              const arrowDiv = $window.document.createElement('div');
              arrowDiv.className = 'wi';
              arrowDiv.setAttribute('data-recommend-reports', 'data-recommend-reports');
              arrowDiv.setAttribute('style', 'top: 600px; right: 500px;');


              const innerWrap = $window.document.createElement('div');
              innerWrap.className = 'inner-wrap';

              const headingWrap = $window.document.createElement('div');
              headingWrap.className = 'heading-wrap';

              if ($rootScope.crmCountry === 'New Zealand')
                headingWrap.appendChild($window.document.createTextNode('Your Loan Profiler report is now ready\n'));
              else
                headingWrap.appendChild($window.document.createTextNode('Your CPA / CQP report is now ready\n'));

              innerWrap.appendChild(headingWrap);

              innerWrap.appendChild($window.document.createTextNode('Click + next to recommendation reports to create'));
              const img = $window.document.createElement('img');
              img.setAttribute('src', '/assets/images/arrow.svg');

              img.setAttribute('style', 'transform:rotate(170deg);right:-10px');
              img.setAttribute('data-top', 0);

              innerWrap.appendChild(img);

              arrowDiv.appendChild(innerWrap);

              modalDiv.appendChild(arrowDiv);


              /**
               * Create container for Next & Finish buttons
               * */
              const modalAction = $window.document.createElement('div');
              modalAction.className = 'modal-action';
              innerWrap.appendChild(modalAction);

              const finBtn = $window.document.createElement('BUTTON');
              finBtn.setAttribute('id', 'fin');
              modalAction.appendChild(finBtn);
              finBtn.appendChild($window.document.createTextNode('Got it'));

              const dontShow = $window.document.createElement('div');
              dontShow.className = 'dontShow';
              modalAction.appendChild(dontShow);

              const checkboxDontShow = $window.document.createElement('input');
              checkboxDontShow.type = 'checkbox';
              checkboxDontShow.id = 'dm';

              const dmLabel = $window.document.createElement('label');
              dmLabel.setAttribute('for', 'dm');
              dmLabel.appendChild($window.document.createTextNode('Don\'t show again'));

              dontShow.appendChild(checkboxDontShow);
              dontShow.appendChild(dmLabel);
            },

            listeners() {
              const documentOnChangeListener = (e) => {
                const target = e.currentTarget;
                if (target.checked) {
                  $scope.reports.updateReportGuideModal(true); // dont show
                  $localStorage.isCpaReadyInfoPopover = true;
                } else {
                  $scope.reports.updateReportGuideModal(false); // show
                  $localStorage.isCpaReadyInfoPopover = false;
                }
              };

              $document.on('change', '#dm', documentOnChangeListener);
              $scope.$on('$destroy', () => {
                if (documentOnChangeListener) {
                  $document.off('change', documentOnChangeListener);
                }
              });
            },

            updateReportGuideModal(show) {
              loanScenarioService.setLoanApplicationShowCPACQPRecommendation($scope.loanAppId, show);
            },

            init() {
              const initModal = () => {
                /**
                 * Call to create the modal element
                 * */
                if (!$localStorage.isCpaReadyInfoPopover) {
                  $scope.reports.listeners();

                  $scope.reports.createModal();

                  angular.element('html,body').animate({ scrollTop: 300 }, 1000);
                  $window.document.body.setAttribute('style', 'overflow:hidden');

                  const modal = $window.document.querySelector('.recommended-reports-modal');
                  const fin = $window.document.getElementById('fin');

                  /**
                   * When user clicks Finished button
                   * */
                  fin.addEventListener('click', () => {
                    modal.remove();
                    $window.document.body.setAttribute('style', '');
                    angular.element('.app-aside .sidebar-container').attr('style', '');
                    angular.element('.navbar-header').attr('style', '');
                    angular.element('#top-navbar').attr('style', '');
                    angular.element('.facts-widget').attr('style', '');
                    angular.element('.calculations-widget').attr('style', '');
                  });
                }
              };

              $scope.reports.getLoanAppDetails(initModal);
            },
          },

        });


        $scope.init();
      };

      const sectionObject = loanAppSharedData.getSectionObjectById(loanAppSharedData.completionListObject, SECTION_ID.INSURANCE);
      if (typeof sectionObject.IsCompleted === 'undefined') {
        $scope.processInsuranceSection();
      } else {
        $scope.isValidInsurance = sectionObject.IsCompleted;
        $scope.isStartedInsurance = sectionObject.IsCompleted;
      }
    });
  });
