import moment from 'moment';
import { remove } from 'lodash';
import { DATE_COUNT } from 'Common/constants/dateRange';
import { EMPLOYMENT } from 'Common/constants/employment';

class EmploymentService {
  constructor(configService, loanApplicationServices, currentUserService) {
    'ngInject';

    this.configService = configService;
    this.loanApplicationServices = loanApplicationServices;
    this.currentUserService = currentUserService;
  }

  isCurrentEmploymentStatusShown() {
    return this.configService.feature && this.configService.feature.currentEmploymentStatus;
  }
  getEmployment(loanAppId, EmpId) {
    return this.loanApplicationServices.getEmployment(loanAppId, EmpId)
      .then((response) => {
        return response && response.data ? response.data : [];
      });
  }
  countEmploymentYears(loanAppId, EmpId) {
    return this.getEmployment(loanAppId, EmpId).then((response) => {
      const monthsInYear = DATE_COUNT.MONTHS_IN_YEAR;
      const daysInYear = DATE_COUNT.DAYS_IN_YEAR;
      const validYearLength = EMPLOYMENT.VALID_YEAR_LENGTH;
      const validityInMonths = monthsInYear * validYearLength;
      const validityInDays = daysInYear * validYearLength;
      const yearsEmployed = [];
      const Employment = {
        AllList: response,
      };

      Employment.AllList && Employment.AllList.forEach((family) => {
        const familyId = family.FamilyId;
        let months = 0;
        let days = 0;

        family.Employment && family.Employment.forEach((person) => {
          if (this.currentUserService.isNZ) {
            const yearsInMonths = (person.NumberOfYears || 0) * monthsInYear;
            months += yearsInMonths + (person.NumberOfMonths || 0);
          } else {
            const dateEnded = typeof person.DateEnded !== 'undefined' ? moment(person.DateEnded, 'YYYY-MM-DD') : moment();
            const start = moment(person.DateStarted, 'YYYY-MM-DD');
            days += dateEnded.diff(start, 'days');
          }
        });
        const frequency = months || days;
        yearsEmployed.push({
          familyId,
          name: family.FamilyName,
          frequency,
        });
      });
      const compareFrequency = this.currentUserService.isNZ ? validityInMonths : validityInDays;
      remove(yearsEmployed, (o) => {
        return o.frequency >= compareFrequency;
      });
      return yearsEmployed;
    });
  }
}

export default EmploymentService;
