import { getWholeNumber, getRemainder } from 'Common/utilities/math';
import { grammaticallySeparateByComma } from 'Common/utilities/string';

export default class SubscriptionAutoMarketingModalCtrl {
  constructor(
    $uibModal,
    contactService,
    optionsService,
    contactModelService,
    usersSubscriptionService
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.getWholeNumber = getWholeNumber;
    this.getRemainder = getRemainder;
    this.contactService = contactService;
    this.optionsService = optionsService;
    this.contactModelService = contactModelService;
    this.usersSubscriptionService = usersSubscriptionService;
    this.grammaticallySeparateByComma = grammaticallySeparateByComma;
  }

  $onInit() {
    this.checkedTerms = false;
    this.buttonText = 'Subscribe Now';
    this.subscriptionLabels = [];
    this.totalPrice = 0;
    this.selectedServices = [];
    this.termsAndCondition = false;
    this.isLoading = true;
    this.isShowSuccess = false;
    this.isSubmitDisabled = false;
    this.loadingMessage = 'Loading Available Plans';
    this.isBeta = true;
    this.subscription = { ...this.subscriptionData };
    this.activeServices = [...this.subscription.subDetailIDs];
    this.unselectedCampaigns = [];
    this.unselectedCampaignsText = '';
    this.campaignUnsubscribe = false;
    this.getMarketingServices();
  }

  getMarketingServices() {
    this.optionsService.getMarketingAutomation()
      .then((response) => {
        this.isLoading = false;
        if (!response) return;
        this.subscriptionLabels = response;
        const totalLabelsPrice = this.subscriptionLabels.reduce((currTotal, label) => currTotal + label.price, 0);
        this.isBeta = totalLabelsPrice === 0;

        if (this.subscription.isActive) {
          this.checkSelectedServices();
        }
      });
  }

  switchButtonText() {
    const isActive = this.subscription && this.subscription.isActive;
    const defaultText = (isActive) ? 'Update Subscription' : 'Subscribe Now';
    this.buttonText = (isActive && !this.subscription.subDetailIDs.length) ? 'Unsubscribe' : defaultText;
  }

  formatUnselectedCampaigns() {
    const unselectedCampaigns = [];
    this.subscriptionLabels.forEach((data) => {
      if (this.unselectedCampaigns.includes(data.value)) {
        unselectedCampaigns.push(data.name);
      }
    });
    this.unselectedCampaignsText = this.grammaticallySeparateByComma(unselectedCampaigns);
  }

  checkCampaignChange() {
    this.switchButtonText();
    this.campaignUnsubscribe = false;
    this.unselectedCampaigns = [];
    this.activeServices.forEach((item) => {
      if (!this.subscription.subDetailIDs.includes(item)) {
        this.unselectedCampaigns.push(item);
        this.campaignUnsubscribe = true;
      }
    });
    this.formatUnselectedCampaigns();
  }

  checkSelectedServices() {
    const hasSelectedServices = this.subscription && this.subscription.subDetailIDs && this.subscription.subDetailIDs.length;
    this.checkedTerms = this.subscription.isActive;

    this.subscriptionLabels.map((data) => {
      if (hasSelectedServices) {
        data.selected = this.subscription.subDetailIDs.includes(data.value);
      }
      return data;
    });
    this.calculateTotal();
  }

  closeSubscriptionModal() {
    this.modalInstance.dismiss('cancel');
  }

  calculateTotal() {
    this.totalPrice = 0;
    this.subscription.subDetailIDs = [];
    this.selectedServices = this.subscriptionLabels.filter(value => value.selected);
    this.selectedServices.forEach((data) => {
      this.subscription.subDetailIDs.push(data.value);
      this.totalPrice += data.price;
    });
    this.checkCampaignChange();
  }

  checkUnsubscribeCampaign() {
    if (!this.subscription.subDetailIDs.length) {
      this.cancelSubscription();
    } else if (!this.subscription.isActive || !this.campaignUnsubscribe) {
      this.subscribeNow();
    } else {
      this.cancelSubscription();
    }
  }

  cancelSubscription() {
    const props = {
      familyId: this.familyId,
      unsubscribeText: this.unselectedCampaignsText,
      selectedServicesLength: this.selectedServices.length,
      subscriptionData: this.subscription,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-now-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId" 
                  unsubscribe-text="vm.props.unsubscribeText" 
                  selected-services-length="vm.props.selectedServicesLength" 
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-now-modal>`,
      backdrop: 'static',
      windowClass: 'cancel-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then((response) => {
      this.subscription = response;
      if (this.selectedServices.length) {
        this.subscribeNow();
      } else {
        this.closeModal();
      }
    });
  }

  subscribeNow() {
    if (!this.checkedTerms) return;
    this.isLoading = true;
    const checkValidToSubscribe = (this.subscription && this.subscription.subscriptionProductId && this.orderedById);
    this.loadingMessage = (this.subscription.isActive) ? 'Updating Subscription' : 'Activating Subscription';
    if (checkValidToSubscribe) {
      const subscibeEndpoint = (this.subscription.isActive) ? 'updateSubscription' : 'subscription';
      this.subscription.monthlyRecurringFee = this.totalPrice;
      this.subscription.orderedById = this.orderedById;
      this.contactModelService[subscibeEndpoint](this.subscription, this.familyId)
        .then((response) => {
          if (!response) return;
          this.isLoading = false;
          this.isShowSuccess = true;
        });
    }
  }
  returnToParent() {
    this.modalInstance.close({ getAllSubscribe: true });
  }
  closeModal() {
    this.returnToParent();
  }
}
