import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import _ from 'lodash';

class SMSTemplatesSelectionCtrl {
  constructor(
    $scope,
    contactService,
    templateService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.contactService = contactService;
    this.templateService = templateService;
    this.templateListing = [];
    this.blankTemplate = { UserTemplateID: 0, UserTemplateName: 'Blank' };

    this.templatesStatus = {
      smsTemplatesLoaded: false,
      defaultTemplateLoaded: false,
      onAllTemplatesLoaded: () => {
        this.onAllTemplatesLoaded();
      },
    };
  }

  $onInit() {
    this.getTemplates();
    this.getDefaultTemplate();
    if (this.onInitialized) {
      this.api = {};
      this.api.refresh = () => {
        const isRefresh = true;
        this.getTemplates(isRefresh);
      };
      this.onInitialized({ api: this.api });
    }
  }

  getTemplates(isRefresh) {
    this.contactService.smsTemplatesGet().then((response) => {
      if (response.data) {
        this.blankCategory = _.remove(response.data, category => this.templateService.isBlankFolderOrTemplate(category))[0];
        this.blankTemplate = this.templateService.getBlankTemplateIn(this.blankCategory);
        this.templateListing = this.templateService.filterTemplatesByUserType(response.data);
      }
      this.templatesStatus.smsTemplatesLoaded = true;

      if (!isRefresh) {
        this.onAllTemplatesLoaded();
      }
    });
  }

  getDefaultTemplate() {
    if (this.module) {
      this.templateService.getModuleDefaultTemplate(this.module, SEND_METHOD_TYPES.SMS).then((response) => {
        this.defaultTemplate = response.data;
        this.templatesStatus.defaultTemplateLoaded = true;
        this.onAllTemplatesLoaded();
      });
    } else {
      this.templatesStatus.defaultTemplateLoaded = true;
      this.onAllTemplatesLoaded();
    }
  }

  onAllTemplatesLoaded() {
    if (this.templatesStatus.defaultTemplateLoaded && this.templatesStatus.smsTemplatesLoaded) {
      const selectedTemplate = this.defaultTemplate ? _.map(this.templateListing, folder => folder.DocRtfSMSTemplateList).flatten()
        .find(template => template.UserTemplateID === this.defaultTemplate.UserTemplateID) : null;

      this.setSelectedTemplate(selectedTemplate || this.blankTemplate);
    }
  }

  setSelectedTemplate(template, index) {
    this.selectedTemplate = template;
    if (this.onTemplateSelected) {
      this.onTemplateSelected({ template, index });
    }
  }
}

export default SMSTemplatesSelectionCtrl;
