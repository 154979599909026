

app.factory('pipelineSharedService', ['pipelineSharedData', '$state', function (pipelineSharedData, $state) {
  var factory = this;
  angular.extend(factory, {
    redirectToApplicationThenFilter(toFilter) {
      pipelineSharedData.toFilter = toFilter;
      /* populating selectedStatusForFilter if toFIlter has a value or not equals to '' */
      if (pipelineSharedData.toFilter != '') {
        pipelineSharedData.toFilter == 'New Application' ? pipelineSharedData.selectedStatusForFilter.PipelineStatusID = 7 : '';
        pipelineSharedData.toFilter == 'Lodged' ? pipelineSharedData.selectedStatusForFilter.PipelineStatusID = 6 : '';
        pipelineSharedData.toFilter == 'Pre-Approved' ? pipelineSharedData.selectedStatusForFilter.PipelineStatusID = 8 : '';
        pipelineSharedData.toFilter == 'Conditionally Approved' ? pipelineSharedData.selectedStatusForFilter.PipelineStatusID = 5 : '';
        pipelineSharedData.toFilter == 'Unconditionally Approved' ? pipelineSharedData.selectedStatusForFilter.PipelineStatusID = 9 : '';
        pipelineSharedData.toFilter == 'Pending Settlement' ? pipelineSharedData.selectedStatusForFilter.PipelineStatusID = 10 : '';
      }
      $state.go('app.application');
    },
  });
  return factory;
}]);
