import angular from 'angular';
import $ from 'jquery';

angular.module('app').directive('summaryTabs', function summaryTabs($window, $timeout) {
  $.fn.reverse = [].reverse;
  const sumTab = (scope, element) => {
    angular.element($window.document).ready(() => {
      const tabsContainer = element.context.firstElementChild;
      let currentParentWidth = 0;
      scope.narrowNav = false;
      const toggleOverflowTabs = () => {
        scope.narrowNav = $('#hiddentablist li').length < 1;
      };
      const checkTabs = () => {
        const screenWidth = $(window).width();
        const parentTab = $('.nav-tabs > li.contact-parent-tab');
        if (screenWidth >= 975) {
          currentParentWidth = tabsContainer.clientWidth;
          let totalWidth = 0;
          parentTab.each(function () {
            totalWidth += parseInt($(this).innerWidth(), 10);
          });
          totalWidth += 5;
          if (currentParentWidth <= totalWidth) {
            let widthDifference = totalWidth - currentParentWidth;
            const reverseParentTab = parentTab.reverse().get();
            reverseParentTab
              .forEach((item) => {
                const lastItem = $(item);
                const currentItemWidth = lastItem.innerWidth();
                if (widthDifference < 1) return;
                widthDifference -= currentItemWidth;
                lastItem.attr('data-width', $(item).outerWidth(true));
                lastItem.prependTo($('.nav-tabs .more ul'));
              });
          } else {
            const elementList = $('.nav-tabs li.more li').get();
            elementList
              .forEach((item) => {
                const validateWidth = totalWidth + $(item).data('width') < currentParentWidth;
                if (!validateWidth) return;
                $(item).insertBefore($('.nav-tabs .more'));
              });
          }
          toggleOverflowTabs();
        }
      };
      const onResize = () => {
        $timeout(() => {
          checkTabs();
        }, 300);
      };
      const cleanUp = () => {
        angular.element($window).off('resize', onResize);
      };
      $timeout(() => {
        checkTabs();
      }, 1000);
      checkTabs();
      angular.element($window).on('resize', onResize);
      scope.$on('$destroy', cleanUp);
    });
  };
  return {
    restrict: 'A',
    link: sumTab,
  };
});
