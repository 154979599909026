import {
  insurancePipelineTableForUI,
  insurancePipelineCardForUI,
  insurancePipelineLabelForUI,
  insurancePipelineCardBenefitForUI,
  insurancePipelineFilterForUI,
  insurancePipelineStatusSortingForUI,
  insurancePipelineFiltersForMyCRM,
  insurancePipelineStatusSortingForMyCRM,
} from 'Common/mappers/insurancePipeline';
import { INSURANCE_PIPELINE_STATUS } from 'Common/constants/insuranceOptions';

export function mapInsurancePipelineTable(pipelineTable) {
  if (!pipelineTable) return;
  const mappedPipelineTable = insurancePipelineTableForUI(pipelineTable);
  mappedPipelineTable.items = pipelineTable.Items && pipelineTable.Items.map((item) => {
    const mappedItem = insurancePipelineCardForUI(item);
    mappedItem.labels = item.CardLabels && item.CardLabels.map(insurancePipelineLabelForUI);
    mappedItem.benefits = item.Benefits && item.Benefits.map(insurancePipelineCardBenefitForUI);
    return mappedItem;
  });
  return mappedPipelineTable;
}

export function mapInsurancePipelineFiltersForUI(filters) {
  if (!filters) return;
  const mappedPipelineFilter = insurancePipelineFilterForUI(filters);
  mappedPipelineFilter.statusSorting = filters.StatusSorting ? filters.StatusSorting.map(insurancePipelineStatusSortingForUI) : [];
  return mappedPipelineFilter;
}

export function mapInsurancePipelineFiltersForMyCRM(filters) {
  if (!filters) return;
  const mappedFilters = insurancePipelineFiltersForMyCRM(filters);
  mappedFilters.StatusSorting = filters.statusSorting ? filters.statusSorting.map(insurancePipelineStatusSortingForMyCRM) : [];
  return mappedFilters;
}

export function checkPipelineColumnHasMore(column, pageSize) {
  return column && (column.totalCards - (column.page * pageSize)) > 0;
}

export function isClientFilterValid(filter) {
  return typeof filter !== 'undefined' && filter !== null && (filter.length >= 3 || filter.length === 0);
}

export function isConversionStatus(status) {
  const { IN_FORCE, NOT_PROCEEDED } = INSURANCE_PIPELINE_STATUS;
  return status && (status.id === IN_FORCE || status.id === NOT_PROCEEDED);
}
