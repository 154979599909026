import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { REQUESTER } from 'Common/constants/addNewContactRequesters';
import PARTY_TYPE from 'Common/constants/partyTypes';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular.module('app').controller('ApplicantModalCtrl', function ApplicantModalCtrl(
  $rootScope,
  $scope,
  $uibModalInstance,
  $stateParams,
  $timeout,
  utilitiesService,
  toaster,
  contactService,
  selectedPartyType,
  person,
  userService,
  corporateService,
  dashboardService,
  generalService,
  loanScenarioService,
  type,
  loanAppSharedData,
  requester,
  listApplicants,
  applicationSharedService,
  optionsService,
  clientInfoService,
  genderService,
  contactSharedDataService,
  assignedAdviserIdFromRequester
) {
  $scope.familyId = $stateParams.familyId || 0;

  // models
  angular.extend($scope, {
    partyApplicants: [],
    Settings: {
      MaritalStatusAdded: false,
    },
    applicant: {},
  });

  $scope.ContactSet = {
    Address: [],
  };
  $scope.BusinessSet = {};
  $scope.allocatedAdviser = {};
  $scope.allocatedAdviser.adviserSelected = {};
  $scope.allocatedAdviser.adviserSelected.FullName = null;
  angular.extend($scope, {
    allocatedAdviser: {
      adviserSelected: {
        FullName: {
          description: {},
        },
      },
    },
  });
  $scope.isDisableAllocateAdviser = false;
  $scope.allocatedAdviser.adviserInitialValue = '';
  $scope.selectedAdviserToEdit = {};
  $scope.allocatedAdviserList = [];

  if (typeof $scope.brokersInfo === 'undefined') {
    userService.GetUserInfo().then((response) => {
      const access = response.data.AccessType;
      dashboardService.getBrokerBasicInfo().then((response2) => {
        $scope.brokersInfo = response2.data;
        if (access === 1 || access === 2)
          $scope.AssignedAdviserId = $scope.brokersInfo.BrokerId;
        $scope.getAccountType();
      });
    });
  }

  angular.extend($scope, {
    showAllocatedAdviser() {
      const vm = this;
      return vm.getAdviserIfEditLoaded || (vm.advisersList && vm.advisersList.length > 0) || (vm.brokersList && vm.brokersList.length > 0) || (vm.assistantAdvisersList && vm.assistantAdvisersList.length > 0) || parseInt(vm.accessType, 10) === ACCESS_TYPE.CORPORATE;
    },
    getAdviserIfEdit() {
      if ($scope.Settings.isEdit === true) {
        if (person.FamilyId) {
          contactService.getContactAssignedAdviser(person.FamilyId).then((response) => {
            const assignedAdviserIdForEdit = response.data;
            if ($scope.accessType !== ACCESS_TYPE.CORPORATE) {
              $scope.AssignedAdviserId = assignedAdviserIdForEdit;
              $scope.getAdviserIfEditLoaded = true;
            } else {
              corporateService.getAdvisersDropdown(person.FamilyId, 0, 0)
                .then((adviserListResponse) => {
                  const { data } = adviserListResponse;
                  const assignedAdvToCorp = _.find(data, obj => parseInt(obj.FamilyId, 10) === parseInt(assignedAdviserIdForEdit, 10));
                  if (assignedAdvToCorp) {
                    $scope.allocatedAdviser.adviserSelected.FullName = {
                      description: {
                        FamilyId: parseInt(assignedAdvToCorp.FamilyId, 10),
                        FullName: assignedAdvToCorp.FullName,
                      },
                    };
                  }
                  $scope.getAdviserIfEditLoaded = true;
                }, () => {
                  $scope.getAdviserIfEditLoaded = true;
                });
            }
          });
        }
      } else {
        $scope.AssignedAdviserId = parseInt($scope.brokerInfo.BrokerId, 10);
        $scope.getAdviserIfEditLoaded = true;
      }
    },
    getAdviser() {
      corporateService.advisersGet($scope.selectedAdviserToEdit.id, 0, 0, 0).then((response) => {
        let responseData = response.data;
        if (responseData.length > 1) {
          responseData = _.remove(responseData, (n) => {
            return parseInt(n.FamilyId, 10) === parseInt($scope.selectedAdviserToEdit.id, 10);
          });
          $scope.selectedAdviserToEdit.FullName = responseData && responseData.length && responseData[0].FullName;
        }
        $scope.allocatedAdviser.adviserInitialValue = $scope.selectedAdviserToEdit.FullName;
        $scope.getAdviserIfEditLoaded = true;
        $scope.AssignedAdviserId = parseInt($scope.selectedAdviserToEdit.id, 10);
        $scope.sampleChange($scope.AssignedAdviserId);
      });
    },
    getContactAdviser(contactFamilyId) {
      if (contactFamilyId && !assignedAdviserIdFromRequester) {
        contactService.getContactAssignedAdviser(contactFamilyId).then((response) => {
          $scope.AssignedAdviserId = parseInt(response.data, 10);
          $scope.sampleChange($scope.AssignedAdviserId);
        });
      } else {
        $scope.AssignedAdviserId = assignedAdviserIdFromRequester;
        $scope.sampleChange($scope.AssignedAdviserId);
      }
    },
    getAdvisersList() {
      generalService.getBrokerAll().then((response) => {
        const { data: brokersList } = response;
        $scope.advisersList = brokersList.map((broker) => {
          broker.BrokerId = parseInt(broker.BrokerId, 10);
          return broker;
        });
      });
    },
    sampleChange(id) {
      const isValidId = ($scope.getAdviserIfEditLoaded && $scope.Settings.isEdit) || !$scope.Settings.isEdit;
      $scope.applicant.AssignedAdviserId = isValidId ? id : null;
    },
    getAllBroker() {
      generalService.getBrokerAll().then((response) => {
        const { data: brokersList } = response;
        $scope.brokersList = brokersList.map((broker) => {
          broker.BrokerId = parseInt(broker.BrokerId, 10);
          return broker;
        });
      });
    },
    getAccountType() {
      clientInfoService.getAccounTypeNumber().then((res) => {
        $scope.accessType = res.data;

        if ($scope.accessType) {
          if (parseInt($scope.accessType, 10) === 1) { /* adviser */
            $scope.getAllBroker();
            $scope.getUserInfo();
          } else if (parseInt($scope.accessType, 10) === 2) {
            $scope.getAdvisersList($scope.brokerInfo.BrokerId);
          } else { /* admin */
            $scope.getUserInfo();
          }
        }
      });
    },
    getUserInfo() {
      contactService.getUserInfo().then((response) => {
        const userInformation = response.data;
        $scope.userInfo = userInformation;
        $scope.currentUserName = response.data.FullName;
        $scope.currentCountryId = response.data.CountryId || 0;
        if (parseInt($scope.accessType, 10) !== PARTY_TYPE.PERSON_PARTY_TYPES.APPLICANT &&
            parseInt($scope.accessType, 10) !== PARTY_TYPE.ENTITY_PARTY_TYPES.COMPANY) {
          $scope.getAdvisersDropdown();
        }
      });
    },
    getAdvisersDropdown() {
      corporateService.getAdvisersDropdown($scope.userInfo.FamilyId, 0, 0).then((response) => {
        if ($scope.accessType === 5) {
          $scope.allocatedAdviserList = _.filter(response.data, (data) => { return data !== ''; });
        } else {
          $scope.assistantAdvisersList = _.filter(response.data, (data) => { return data.FullName !== ''; });
          $scope.getContactAdviser($scope.familyId);
        }
      });
    },
    setIsFirstHomebuyer() {
      if (requester !== REQUESTER.NEW_LOAN_APP_MODAL) {
        loanScenarioService.LoanAppIsFirstHomeBuyerSet(
          $scope.ContactSet.PersonId,
          $scope.loanAppId,
          $scope.ContactSet.IsFirstHomeBuyers
        );
      }
    },
  });
  // Default
  if (parseInt(type, 10) === 1) {
    $scope.saveText = 'Applicant';
    $scope.Settings.SelectedParty = 'Applicants';
  } else if (parseInt(type, 10) === 3) {
    $scope.saveText = 'Guarantor';
    $scope.Settings.SelectedParty = 'Guarantors';
  }

  if (requester === REQUESTER.NEW_LOAN_APP_MODAL && listApplicants) {
    if (parseInt(selectedPartyType, 10) === PARTY_TYPE.PERSON_PARTY_TYPES.APPLICANT) {
      $scope.listApplicants = listApplicants;
    } else {
      $scope.listGuarantors = listApplicants;
    }
  }

  $scope.fixedParams = {
    CountryCode: applicationSharedService.countryCodeAndBirthday($rootScope.crmCountry),
    CountryOfBirth: applicationSharedService.countryCodeAndBirthday($rootScope.crmCountry),
  };

  if (person) {
    $scope.Settings.isEdit = true;
    // We need to know which of the family members are included in the loan app
    // Task 1: We need to know what family object to use
    let loanAppFamily = [];
    if (type === '1') {
      _.forEach($scope.listApplicants, (family) => {
        if (_.isEqual(_.first(family).FamilyId, person.FamilyId)) {
          // take a copy of the family object
          loanAppFamily = angular.copy(family);
        }
      });
    } else if (type === '3') {
      _.forEach($scope.listGuarantors, (family) => {
        if (_.isEqual(_.first(family).FamilyId, person.FamilyId)) {
          // take a copy of the family object
          loanAppFamily = angular.copy(family);
        }
      });
    }
    contactService.clientInformGet(person.FamilyId).then((family) => {
      let applicantNumber = 1;
      _.remove(family.data, (o) => {
        return o.Role === 'Child';
      });
      _.forEach(family.data, (member) => {
        if (member.Role === 'Adult' || member.Role === 'Guarantor') {
          contactService.clientInformGet(person.FamilyId, member.PersonId).then((response) => {
            /* get adviser of the client */
            contactService.getContactAssignedAdviser(person.FamilyId).then((response2) => {
              /* get adviser's details usiong the response2.data we get from getContactAssignedAdviser */
              $scope.selectedAdviserToEdit.id = response2.data;
              $scope.getAdviser();
            });
            const contactSet = contactSharedDataService.getContactDataModel('Adult', $scope.fixedParams.CountryCode, $scope.fixedParams.CountryOfBirth);

            if (contactSet.customerConsentOptions.ConsentMarketing !== true) {
              contactSet.customerConsentOptions.ConsentMarketing = false;
            }
            angular.extend(contactSet, response.data[0]);

            if (_.size(contactSet.Email) > 0) {
              contactSet.EmailAddress = contactSet.Email[0].EmailAddress;
            }

            if (_.isUndefined(contactSet.IsResident)) {
              contactSet.IsResident = false;
            }
            // find country codes
            let citizenshipObj = _.find($scope.countryList, (o) => { return o.CountryID === contactSet.CitizenshipId; });
            let countryOfResidencyObj = _.find($scope.countryList, (o) => { return o.CountryID === contactSet.CountryOfResidencyId; });
            let countryOfBirthObj = _.find($scope.countryList, (o) => { return o.CountryID === contactSet.CountryOfBirthId; });
            if (!citizenshipObj) {
              citizenshipObj = {
                CountryCode: '',
              };
            }
            if (!countryOfResidencyObj) {
              countryOfResidencyObj = {
                CountryCode: '',
              };
            }
            if (!countryOfBirthObj) {
              countryOfBirthObj = {
                CountryCode: '',
              };
            }

            // Task 2: If the member is included in the loanAppFamily put isIncluded property = true, else false
            const familyMember = _.find(loanAppFamily, (mem) => { return mem.PersonId === contactSet.PersonId; });

            angular.extend(contactSet, {
              FamilyId: person.FamilyId,
              CountryCode: citizenshipObj.CountryCode,
              ResidencyCountryCode: countryOfResidencyObj.CountryCode,
              CountryOfBirth: countryOfBirthObj.CountryCode,
              ResidencyId: String(contactSet.ResidencyId),
              MaritalStatusId: String(contactSet.MaritalStatusId),
              DOBGet: moment(contactSet.DOB).format('DD/MM/YYYY'),
              DOB: contactSet.DOB,
              PartyType: selectedPartyType,
              ApplicantNumber: applicantNumber++,
              PreferredContactMethodId: String(contactSet.PreferredContactMethodId),
              isIncluded: (!!familyMember), // to be used on tickbox
              isExisting: (!!familyMember), // to be used on knowing if it's currently existing
            });

            if (requester !== REQUESTER.NEW_LOAN_APP_MODAL) {
              if (response.data && response.data[0]) {
                loanScenarioService.LoanAppIsFirstHomeBuyerGet(response.data[0].PersonId, $scope.loanAppId).then((respond) => {
                  contactSet.IsFirstHomeBuyers = respond.data;
                });
              }
            } else {
              _.forEach(loanAppFamily, (loanFamilyMember) => {
                contactSet.IsFirstHomeBuyers = loanFamilyMember.IsFirstHomeBuyers;
              });
            }

            if (type === '1' && contactSet.MaritalStatusId === '2' && $scope.listApplicants.length === 1) {
              $scope.setMaritalStatus(true);
            }
            // Push it
            $scope.partyApplicants.push(contactSet);
            // set contact set for selected person
            if (member.PersonId === person.PersonId) {
              $scope.ContactSet = contactSet;
            }
          });
        }
      });
    });
  } else {
    $scope.Settings.isEdit = false;
    $scope.ContactSet = contactSharedDataService.getContactDataModel('Adult', $scope.fixedParams.CountryCode, $scope.fixedParams.CountryOfBirth);
    $scope.ContactSet.PartyType = selectedPartyType;
    $scope.ContactSet.ApplicantNumber = 1;
    $scope.ContactSet.customerConsentOptions = { ConsentMarketing: true };

    if (typeof $scope.preferredContactMethod !== 'undefined' && $scope.preferredContactMethod.length) {
      const preferredContact = $scope.preferredContactMethod.filter((item) => {
        let respond = false;
        if (item.Name === 'Mobile') {
          respond = item;
        }
        return respond;
      });
      $scope.ContactSet.PreferredContactMethodId = preferredContact[0].Value;
    }

    $scope.partyApplicants.push($scope.ContactSet);
    $scope.allocatedAdviser.adviserInitialValue = $scope.selectedAdviserToEdit.FullName;
  }

  $scope.titles = [
    { Value: 'Mr', Name: 'Mr' },
    { Value: 'Mrs', Name: 'Mrs' },
    { Value: 'Ms', Name: 'Ms' },
    { Value: 'Miss', Name: 'Miss' },
    { Value: 'Master', Name: 'Master' },
    { Value: 'Dr', Name: 'Dr' },
  ];

  $scope.$watch('ContactSet.Title', (newVal) => {
    genderService.changeGender(newVal, $scope.ContactSet.Gender);
  });

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.datePopUp = {
    DOB: false,
  };
  // Open Date Popup
  $scope.openDate = (dateField) => {
    $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
  };

  $scope.format = 'dd MMM yyyy';
  $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  $scope.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };

  // methods
  angular.extend($scope, {
    setMaritalStatus(bool) {
      $scope.Settings.MaritalStatusAdded = bool;
    },
    showApplicantPopover(bool) {
      $scope.Settings.isShowApplicantPopover = bool;
      if (!bool) {
        $scope.Settings.isShowApplicantDeletePopover = true;
      }
    },
    showApplicantDeletePopover(bool) {
      $scope.Settings.isShowApplicantDeletePopover = bool;
    },
    addPhone() {
      $scope.ContactSet.Phone.push({
        Type: 'Mobile',
        Number: '',
      });
    },
    removeContact() {
      /* Remove Party Applicant if $scope.ContactSet.MaritalStatusId === 2 && partyApplicant > 1 */
      if (_.size($scope.partyApplicants) > 1) {
        $scope.partyApplicants.pop();
      }
    },
    addContact(contactType, isMarried) {
      switch (contactType) {
      case 'Applicant':
        if (_.size($scope.partyApplicants) < 2) {
          if (!isMarried) {
            $scope.ContactSet = contactSharedDataService.getContactDataModel('Adult', $scope.fixedParams.CountryCode, $scope.fixedParams.CountryOfBirth);
            $scope.ContactSet.PartyType = '1';
            $scope.ContactSet.ApplicantNumber = _.size($scope.partyApplicants) + 1;
            $scope.ContactSet.customerConsentOptions = { ConsentMarketing: true };
            if ($scope.Settings.isEdit) {
              $scope.ContactSet.isIncluded = true;
            }

            $scope.partyApplicants.push($scope.ContactSet);
          } else {
            const contactSet = contactSharedDataService.getContactDataModel('Adult', $scope.fixedParams.CountryCode, $scope.fixedParams.CountryOfBirth);
            contactSet.PartyType = '1';
            contactSet.ApplicantNumber = _.size($scope.partyApplicants) + 1;
            contactSet.MaritalStatusId = '2';
            contactSet.customerConsentOptions = { ConsentMarketing: true };

            if ($scope.Settings.isEdit) {
              contactSet.isIncluded = true;
            }

            $scope.partyApplicants.push(contactSet);
            $scope.setMaritalStatus(true);
          }
          $scope.Settings.SelectedParty = 'Applicants';
        }
        break;
      case 'Guarantor':
        if (_.size($scope.partyApplicants) < 2) {
          if (!isMarried) {
            $scope.ContactSet = contactSharedDataService.getContactDataModel('Adult', $scope.fixedParams.CountryCode, $scope.fixedParams.CountryOfBirth);
            $scope.ContactSet.PartyType = '3';
            $scope.ContactSet.ApplicantNumber = _.size($scope.partyApplicants) + 1;
            $scope.ContactSet.customerConsentOptions = { ConsentMarketing: true };
            if ($scope.Settings.isEdit) {
              $scope.ContactSet.isIncluded = true;
            }

            $scope.partyApplicants.push($scope.ContactSet);
          } else {
            const contactSet = contactSharedDataService.getContactDataModel('Adult', $scope.fixedParams.CountryCode, $scope.fixedParams.CountryOfBirth);
            contactSet.PartyType = '3';
            contactSet.ApplicantNumber = _.size($scope.partyApplicants) + 1;
            contactSet.MaritalStatusId = '2';
            contactSet.customerConsentOptions = { ConsentMarketing: true };

            if ($scope.Settings.isEdit) {
              contactSet.isIncluded = true;
            }

            $scope.partyApplicants.push(contactSet);
            $scope.setMaritalStatus(true);
          }
          $scope.Settings.SelectedParty = 'Guarantors';
        }
        break;
      default:
        break;
      }
    },
    selectContact(party) {
      if (party.FirstName === '' || typeof party.FirstName === 'undefined') {
        party.errFirstName = true;
      } else {
        party.errFirstName = false;
      }
      if (party.LastName === '' || typeof party.LastName === 'undefined') {
        party.errLastName = true;
      } else {
        party.errLastName = false;
      }
      if ($scope.accessType === PARTY_TYPE.PERSON_PARTY_TYPES.SOLICITOR) {
        if (_.isEmpty($scope.allocatedAdviser.adviserSelected.FullName.description)) {
          party.errAdviser = true;
        } else
          party.errAdviser = false;
      }
      $scope.ContactSet = party;
      $scope.setMaritalStatus(false);
      switch (parseInt(party.PartyType, 10)) {
      case PARTY_TYPE.PERSON_PARTY_TYPES.APPLICANT:
        $scope.Settings.SelectedParty = 'Applicants';
        break;
      case PARTY_TYPE.PERSON_PARTY_TYPES.GUARANTOR:
        $scope.Settings.SelectedParty = 'Guarantors';
        break;
      default:
        break;
      }
    },
    removeSecondApplicant() {
      $scope.partyApplicants.splice(1, 1);
      $scope.selectContact(_.first($scope.partyApplicants));
    },
    checkMaritalStatus() {
      // NOTE: Married status = 3; make sure the number of applicants is still = 1
      if (parseInt(type, 10) === PARTY_TYPE.PERSON_PARTY_TYPES.APPLICANT) {
        if (parseInt($scope.ContactSet.MaritalStatusId, 10) === 2 && _.size($scope.partyApplicants) === 1) {
          $scope.setMaritalStatus(true);
          $scope.isMarried = true;
        } else {
          $scope.setMaritalStatus(false);
        }
      }
      if (parseInt(type, 10) === PARTY_TYPE.PERSON_PARTY_TYPES.GUARANTOR) {
        if (parseInt($scope.ContactSet.MaritalStatusId, 10) === 2 && _.size($scope.partyApplicants) === 1) {
          $scope.addContact('Guarantors', true);
          $scope.isMarried = true;
        }
      }

      if (!_.isUndefined($scope.isMarried))
        if ($scope.isMarried === true && parseInt($scope.ContactSet.MaritalStatusId, 10) !== 2 && _.size($scope.partyApplicants) > 1) {
          if (parseInt(type, 10) === 1) {
            $scope.removeContact('Applicant');
          }
          if (parseInt(type, 10) === 3) {
            $scope.removeContact('Guarantors');
          }
          $scope.removeSecondApplicant();
        }
    },
    saveClientAccount() { // save applicants
      const obj = [];
      $scope.AssignedAdviserId = $scope.applicant.AssignedAdviserId;
      _.forEach($scope.partyApplicants, (value) => {
        // assign default value to prevent empty names
        value.LastName = (value.LastName === '' ? 'LastName' : value.LastName);
        value.FirstName = (value.FirstName === '' ? 'FirstName' : value.FirstName);
        let adviserId = $scope.AssignedAdviserId;
        if (parseInt($scope.accessType, 10) === ACCESS_TYPE.CORPORATE) {
          const allocatedObj = $scope.allocatedAdviser.adviserSelected.FullName;
          if (allocatedObj && allocatedObj.description) {
            adviserId = allocatedObj.description.FamilyId;
          }
        }

        value.AllocatedAdviserID = adviserId;
        obj.push(value);
      });

      $scope.partyApplicants = angular.copy(obj);
      const partyApplicants = angular.copy($scope.partyApplicants);
      const { excludedApplicants, excludedIds } = applicationSharedService.getExcludedApplicants(partyApplicants);

      // trap ids, we cannot exclude 2 members (this means no adult is selected)
      const isNoMemberTicked = (excludedApplicants.length === partyApplicants.length);
      if (isNoMemberTicked) {
        toaster.pop('error', 'Error Saving', 'Cannot save empty members');
        $scope.submitClicked = false;
        return false;
      }
      if (requester !== REQUESTER.NEW_LOAN_APP_MODAL && excludedApplicants.length > 0) {
        // find if the person is currently existing
        if (excludedApplicants[0].isExisting) {
          $scope.deleteClient({
            IsApplicant: true,
            Role: '1',
            PersonId: excludedApplicants[0].PersonId,
          }, true, partyApplicants);
        }
      }


      // Map the included parties
      _.map(partyApplicants, (party) => {
        party.Email = [{ Type: 'Email', EmailAddress: party.EmailAddress }];
        if (typeof party.DOBGet !== 'undefined' && party.DOBGet !== null && party.DOBGet !== '')
          party.DOB = party.DOBGet.split('/').reverse().join('-');

        let citizenshipObj = _.find($scope.countryList, (o) => { return o.CountryCode === party.CountryCode; });
        let countryOfResidencyObj = _.find($scope.countryList, (o) => { return o.CountryCode === party.ResidencyCountryCode; });
        let countryOfBirthObj = _.find($scope.countryList, (o) => { return o.CountryCode === party.CountryOfBirth; });
        if (!citizenshipObj) {
          citizenshipObj = {
            CountryID: 0,
          };
        }
        if (!countryOfResidencyObj) {
          countryOfResidencyObj = {
            CountryID: 0,
          };
        }
        if (!countryOfBirthObj) {
          countryOfBirthObj = {
            CountryID: 0,
          };
        }
        angular.extend(party, {
          CitizenshipId: parseInt(citizenshipObj.CountryID, 10),
          CountryOfResidencyId: parseInt(countryOfResidencyObj.CountryID, 10),
          CountryOfBirthId: parseInt(countryOfBirthObj.CountryID, 10),
          ResidencyId: parseInt(party.ResidencyId, 10),
        });
      });
      contactService.addContact(partyApplicants)
        .then((response) => {
          const familyId = response.data;

          if ($scope.AssignedAdviserId) {
            const isSolicitorType = parseInt($scope.accessType, 10) === PARTY_TYPE.PERSON_PARTY_TYPES.SOLICITOR;
            const AssignAdviserId = isSolicitorType ? $scope.allocatedAdviser.adviserSelected.FullName.description.FamilyId : $scope.AssignedAdviserId;

            contactService.putAssignAdviser(familyId, AssignAdviserId)
              .then(() => {
                toaster.pop('success', 'Allocated!', 'Contact has been assigned to an adviser successfully.');
              });
          }

          if (requester !== REQUESTER.NEW_LOAN_APP_MODAL) {
            _.each(partyApplicants, (item) => {
              if (!item.SourceFamilyID) {
                loanScenarioService.LoanAppIsFirstHomeBuyerSet(item.PersonId, $scope.loanAppId, item.IsFirstHomeBuyers);
              }
            });
          }

          if (requester === REQUESTER.NEW_LOAN_APP_MODAL) {
            $uibModalInstance.close({ FamilyId: familyId, excludedIds, partyApplicants });
          } else if (parseInt(type, 10) === PARTY_TYPE.PERSON_PARTY_TYPES.APPLICANT) {
            $uibModalInstance.close({ familyId, PartyType: '1', excludedIds });
          } else if (parseInt(type, 10) === PARTY_TYPE.PERSON_PARTY_TYPES.GUARANTOR) {
            $uibModalInstance.close({ familyId, PartyType: '3', excludedIds });
          }
        });
    },
    saveAccount(e, form) {
      form.$setSubmitted(true);
      let errKey = 0;

      _.forEach($scope.partyApplicants, (value, key) => {
        if (value.isIncluded) {
          if (typeof value.FirstName === 'undefined' || value.FirstName === '') {
            form.$invalid = true;
            errKey = key;
          } else if (typeof value.LastName === 'undefined' || value.LastName === '') {
            form.$invalid = true;
            errKey = key;
          }
        }
        if ($scope.accessType === 5 && _.isEmpty($scope.allocatedAdviser.adviserSelected.FullName.description)) {
          form.$invalid = true;
          errKey = key;
        }
      });
      if (form.$invalid) {
        $scope.selectContact($scope.partyApplicants[errKey]);
      } else {
        $scope.$watch('submitClicked', () => {
          $scope.submitClicked = true;
        });
        $scope.saveClientAccount();
        loanAppSharedData.refreshSecurityList = true;
        $scope.submitClicked = false;
      }
    },
  });

  // For the dropdown options
  optionsService.MaritalStatusGet()
    .then((response) => {
      $scope.maritalStatus = response.data;
    }, () => {
      $scope.maritalStatus = [];
    });
  optionsService.ResidencyStatusGet()
    .then((response) => {
      $scope.residencyStatus = response.data;
    }, () => {
      $scope.residencyStatus = [];
    });
  optionsService.countryListGet()
    .then((response) => {
      $scope.countryList = response.data;
    }, () => {
      $scope.countryList = [];
    });
  optionsService.PreferredContactMethodGet()
    .then((response) => {
      $scope.preferredContactMethod = response.data;
    }, () => {
      $scope.preferredContactMethod = [];
    });
});
