import angular from 'angular';
import _ from 'lodash';
import { TRANSACTION_TYPE } from 'Common/constants/securityValues';
import { clientTitles } from 'Common/utilities/client';
import { parseIntValue } from 'Common/utilities/parse';

angular.module('app').controller('LoanAppSecurityCtrl',
  function LoanAppSecurityCtrl(
    $scope,
    $timeout,
    $interval,
    $compile,
    configService,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    optionsService,
    colorService,
    SweetAlert,
    toaster,
    $state,
    $stateParams,
    dashboardService,
    utilitiesService,
    $uibModal,
    fundingCalculatorService,
    $rootScope,
    loanAppSharedData,
    generalService
  ) {
    //  this will be used under loan funding details's Property / Security section under Funding Requied
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.loanAppId = $stateParams.loanAppId;
    $scope.familyId = $stateParams.familyId;

    const ownsId = '2';
    const refinanceId = '4';

    $scope.loanAppSharedData.fundingSummary = {
      totalFundingRequired: 0,
      totalFundingAvailable: 0,
      totalProposedLending: 0,
      totalSecurityValue: 0,
      lvr: 0,
      lmiAmount: 0,
      lmiLvr: 0,
      surplus: 0,
      deficit: 0,
      isSurplus: true,
      lvrPercentage: 0,
    };

    $scope.ownersButtonLabels = [
      { label: 'PreferedName' },
    ];
    $scope.ownersItemLabels = [
      { label: 'PreferedName', isEdit: false, isText: true },
    ];

    $scope.ownersDisableEdit = true;

    if (configService.feature && configService.feature.securityOwnersPercentage) {
      $scope.ownersButtonLabels.push({ label: 'Percentage', isPercentage: true });
      $scope.ownersItemLabels.push(
        { label: 'Percentage', isEdit: true, containerClass: 'input-type-percentage', inputClass: 'text-input', isPercentage: true, inputWidth: '60px' }
      );

      $scope.ownersDisableEdit = false;
    }

    // Create a loan term list
    optionsService.getLoanTerms().then((loanTermResponse) => {
      $scope.loanTermList = loanTermResponse;
    });

    $scope.listRepaymentFrequency = [];
    optionsService.FinancialFrequency().then((response) => {
      _.map(response.data, (o) => {
        o.Desc = o.Name;
        o.Value = parseInt(o.Value, 10);

        return o;
      });

      $scope.listRepaymentFrequency = response.data;
    });

    // Get Summary Data
    $scope.getFundingCalculatorFundsSummary = () => {
      if (!$scope.securityBrokerEventId)
        return false;

      fundingCalculatorService.getFundingCalculatorFundsSummary($scope.securityBrokerEventId, $scope.loanAppId).then((summary) => {
        _.extend($scope.loanAppSharedData.fundingSummary, summary);

        $scope.loanAppSharedData.fundingSummary.unrefinancedExistingLending = 0;
        if ($scope.loanAppSharedData && $scope.loanAppSharedData.fundingSummary && $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined) {
          $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined = $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined.toFixed(2);
        }

        if (!summary.lvrPercentage) {
          if (parseInt($scope.loanAppSharedData.fundingSummary.totalSecurityValue, 10) > 0) {
            $scope.loanAppSharedData.fundingSummary.totalExistingLendingvalue = 0;

            if (_.size($scope.securityList) > 0) {
              _.forEach($scope.securityList, (o) => {
                if (o.MortgageList) {
                  _.forEach(o.MortgageList, (mortgage) => {
                    $scope.loanAppSharedData.fundingSummary.unrefinancedExistingLending += !mortgage.IsRefinance ? mortgage.Amount : 0;
                  });
                }
              });
            }

            $scope.loanAppSharedData.fundingSummary.lvrPercentage = (((parseInt($scope.loanAppSharedData.fundingSummary.totalProposedLending, 10) + parseInt($scope.loanAppSharedData.fundingSummary.unrefinancedExistingLending, 10)) /
                                parseInt($scope.loanAppSharedData.fundingSummary.totalSecurityValue, 10)) * 100) || 0;
          } else {
            $scope.loanAppSharedData.fundingSummary.lvrPercentage = 0;
          }
        }
      });
    };

    // Listens for refreshing summary
    $scope.$watch('loanAppSharedData.refreshSecurityFundsSummary', (nv) => {
      if (nv === 1) {
        $scope.getFundingCalculatorFundsSummary();

        $scope.loanAppSharedData.refreshSecurityFundsSummary = 0;
      }
    }, true);

    $scope.saveFundingParameters = (fund) => {
      fund.isNew = false;
      fund.isEditing = false;
      if (!fund.id && !fund.fundsId && !fund.fundingCalParametersId) {
        const firstSecurity = _.first($scope.securityList);

        if (!firstSecurity || !firstSecurity.fundingParameters)
          return false;

        const checkExist = _.find(firstSecurity.fundingParameters.innerFundingRequired, (o) => { return o.fundingParticulars === fund.name; });
        if (checkExist) {
          fund.fundsId = checkExist.fundsId;
        } else {
          fund.fundsId = 0;
        }

        fund.fundingCalParametersId = firstSecurity.FundingCalParametersId;
      }

      const obj = {
        brokerEventId: $scope.securityBrokerEventId,
        fundsId: fund.fundsId,
        fundingCalParametersId: fund.fundingCalParametersId,
        fundingType: 'FUNDING_REQUIRED',
        fundingParticulars: fund.name,
        fundingValue: fund.value,
        isOnlyValueEditable: true,
      };


      fundingCalculatorService.setFundingCalculatorFunds(obj).then((res) => {
        // Saved
        $scope.getFundingCalculatorFundsSummary();
        if (fund.name !== 'Lenders Fees') {
          $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
        }
        if (fund.name === 'Lenders Mortgage Insurance') {
          if (res) {
            $scope.getParameters();
            toaster.pop('success', 'Updated', 'Funding required has been successfully Updated');
          }
        }
      });
    };

    $scope.$watch('loanAppSharedData.SAVE_FUNDING_PARAMETER', (nv) => {
      if (nv && _.size(nv) > 0) {
        $scope.saveFundingParameters(angular.copy(nv));
      }
    }, true);

    $scope.deleteFund = (item, id) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This fund will be removed',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          fundingCalculatorService.deleteFundingCalculatorFunds(id).then(() => {
            _.remove(item.fundingParameters.innerFundingRequired, (o) => { return _.isEqual(o.fundsId, id); });
            $rootScope.$broadcast('getFundingCalculatorFundsSummary');
            $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
            SweetAlert.swal({
              title: 'Success',
              text: 'Fund has been been successfully deleted.',
              type: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
          });
        }
      });
    };

    // Get parameters
    $scope.getParameters = () => {
      fundingCalculatorService.getFundingCalculatorParameters($scope.securityBrokerEventId).then((param) => {
        const fundingParams = param;

        // Map the params and put isNew and isEditing properties
        _.map(fundingParams, (paramFundings) => {
          _.map(paramFundings.innerFundingRequired, (fund) => {
            fund.isNew = false;
            fund.isEditing = false;
            return fund;
          });
          paramFundings.purchaseInfo.propertyType = parseInt(paramFundings.purchaseInfo.propertyType, 10);
          return paramFundings;
        });

        _.forEach($scope.securityList, (item) => {
          item.fundingParameters = {};
          item.fundingParameters = _.find(fundingParams, (paramFundings) => {
            if (parseInt(paramFundings.fundingCalParametersId, 10) === parseInt(item.FundingCalParametersId, 10)) {
              paramFundings.purchaseInfo.purposeName = item.PropertyDetails.PropertyPrimaryPurpose;
              paramFundings.purchaseInfo.propertyStatusName = item.PropertyDetails.Status;
              paramFundings.purchaseInfo.propertyValue = item.PropertyDetails.CurrentValue;
              return paramFundings;
            }
          });

          item.addFundingParameters = () => {
            item.fundingParameters.innerFundingRequired.push(
              {
                fundsId: 0,
                fundingCalParametersId: item.FundingCalParametersId,
                name: '',
                value: 0,
                isOnlyValueEditable: true,
                isNew: true,
                isEditing: false,
              }
            );
          };

          item.deleteFundingCalculatorFunds = (value) => {
            // This is for new item only
            if (parseInt(value.FundsID, 10) === 0)
              _.remove(item.fundingParameters.innerFundingRequired, (o) => { return _.isEqual(o, value); });
            else {
              SweetAlert.swal({
                title: 'Are you sure?',
                text: 'This record will be removed from your fund list',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: false,
              }, (confirm) => {
                if (confirm) {
                  fundingCalculatorService.deleteFundingCalculatorFunds(value.FundsID).then(() => {
                    $timeout(() => {
                      $rootScope.$broadcast('getFundingCalculatorFundsSummary');
                      _.remove(item.fundingParameters.innerFundingRequired, (o) => { return _.isEqual(o, value); });
                      SweetAlert.swal({
                        title: 'Success',
                        text: 'Fund has been been successfully deleted.',
                        type: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                      });
                    }, 100);
                  });
                }
              });
            }
          };
        });


        $scope.getFundingCalculatorFundsSummary();
      });
    };

    // get linked brokerEventId per loan app
    $scope.getFundingCalculatorInfo = () => {
      $scope.securityBrokerEventId = null;
      $scope.fundingParams = [];
      $scope.fundingSummary = {};
      loanScenarioService.getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId).then((response) => {
        $scope.securityBrokerEventId = response.data;

        // Get parameters
        $scope.getParameters();
      });
    };

    $scope.getEmptySecurityInfo = () => {
      $scope.security = {
        add: {
          LoanApplicationId: $scope.loanAppId,
          SecurityInfoId: '0',
          IsPrimary: true,
          SecurityTypeId: '', // SecurityType
          TransactionId: '', // TransactionType
          Address: [],
          IsRefinance: false,
          PropertyDetails: {
            LoanSecurityId: 0,
            StatusId: '', // StatusType
            PropertyPrimaryPurposeId: ($rootScope.crmCountry === 'New Zealand' ? '1' : ''), // PrimaryPurposeType
            HoldingId: '', // HoldingType
            ZoningId: '', // ZoningType
            PropertyTypeId: '', // PropertyType
            CurrentValue: '',
            LandValue: '',
            EstimatedPrice: '',
            EstimatedBasesId: '', // AssetValueEstimatedBasis
            ContratctPrice: '',
            ContractDate: '',
            LicensedRealEstateAgentContract: true,
            IsPreApproved: true,
            AddressId: 0,
            TitleTypeId: ($rootScope.crmCountry === 'New Zealand' ? '15' : ''), // TitleTenureType
            TitleId: '', // TitleType
            PropertyState: '',
            Suburb: '',
            IdentifierList: [{
              Security_TitleIdentityId: 0, // TitleTenureType
              IdentityTypeId: '', // TitleIdentificationType
              Value: '',
            }],
            ContactForAccess: {
              ContactForAccess: 0,
              CompanyName: '',
              ContactTitle: '',
              ContactFirstName: '',
              ContactLastName: '',
              PhoneAreaCode: '',
              PhoneNumber: '',
              FaxAreaCode: '',
              FaxNumber: '',
              ContactTypeId: '', // ContactAccessType
            },
          },
          Ownership: [],
          MortgageList: [],
        },
        edit: [],
        titles: clientTitles(),

      };
      $scope.mortgageTemp = [];
      $scope.showAddMortgage = false;
    };

    $scope.addNewMortgage = () => {
      $scope.OwnershipList = [];
      angular.copy($scope.OwnershipListInit, $scope.OwnershipList);

      $scope.mortgageTemp =
                {
                  LoanApplicationId: $scope.loanAppId,
                  LoanSecurityInfoId: 0, // reassign
                  SecurityMortgageId: 0,
                  edit: 0,
                  MortgagePriorityId: '',
                  CreditorId: '',
                  Amount: '',
                  /**
                     * TODO: add principalInterest on DB and API
                     * */
                  IsRefinance: false,
                  principalInterest: 2, // 2:Principal and interest; 1: Interest Only
                  InterestTaxDeductible: true,
                };
    };


    $scope.addNewMortgageEditMode = () => {
      $scope.OwnershipList = [];
      angular.copy($scope.OwnershipListInit, $scope.OwnershipList);


      $scope.mortgageTemp =
                {
                  LoanApplicationId: $scope.loanAppId,
                  LoanSecurityInfoId: 0, // reassign
                  SecurityMortgageId: 0,
                  edit: 0,
                  MortgagePriorityId: '',
                  CreditorId: '',
                  Amount: '',
                  /**
                    * TODO: add principalInterest on DB and API
                    * */
                  IsRefinance: false,
                  principalInterest: 2, // 2:Principal and interest; 1: Interest Only
                  InterestTaxDeductible: true,
                };
    };


    $scope.getEmptySecurityInfo();

    angular.copy($scope.security.add, $scope.security.reset);

    $scope.getApplicants = () => {
      return loanScenarioService.scenarioApplicantListGet($scope.loanAppId).then((response) => {
        if (!response || !response.data) return;
        const { InvolvedPartyPerson, InvolvedPartyEntity } = response.data;

        $scope.scenarioApplicantList = [];
        let countApplicants = 0;
        countApplicants += Object.keys(InvolvedPartyEntity).length;
        Object.keys(InvolvedPartyPerson).forEach((familyId) => {
          countApplicants += InvolvedPartyPerson[familyId].length;
        });

        const percentage = (100 / countApplicants).toFixed(2);

        angular.forEach(InvolvedPartyPerson, (family, familyId) => {
          angular.forEach(family, (client) => {
            const data = {
              Percentage: percentage,
              BorrowerID: parseInt(client.PersonId, 10),
              familyId,
              ClientID: parseInt(client.PersonId, 10),
              FirstName: client.FirstName,
              ClientType: client.Role,
              LastName: client.LastName,
              PreferedName: `${client.FirstName} ${client.LastName}`,
              IsInclude: true,
              IsTicked: true,
              OwnershipPercentage: percentage,
              ticked: true,
              IsEntity: false,
              IsClient: true,
            };
            data.initials = utilitiesService.filterInitialOneString(client.FirstName);
            data.background = $scope.getBGcolor(client.PersonId);
            $scope.scenarioApplicantList.push(data);
          });
        });
        angular.forEach(InvolvedPartyEntity, (client) => {
          const data = {
            Percentage: percentage,
            familyId: client.FamilyId,
            BorrowerID: parseInt(client.OrganisationId, 10),
            EntityID: parseInt(client.OrganisationId, 10),
            ClientType: client.EntityTypeName,
            PreferedName: client.FamilyFullName,
            IsInclude: true,
            IsEntity: true,
            IsTicked: true,
            OwnershipPercentage: percentage,
            ticked: true,
          };
          data.initials = utilitiesService.filterInitialOneString(client.FirstName);
          data.background = colorService.getRandomColor();
          $scope.scenarioApplicantList.push(data);
        });

        let isOneFamily = false;
        if ($scope.scenarioApplicantList.length === 1) {
          isOneFamily = true;
        } else if ($scope.scenarioApplicantList.length === 2) {
          isOneFamily = $scope.scenarioApplicantList[0].familyId === $scope.scenarioApplicantList[1].familyId;
        }

        $scope.ownerGroupProperty = isOneFamily ? '' : 'familyId';
      });
    };

    const getEqualPercentage = (list, isAllOwners = true) => {
      let countApplicants = 0;
      if (isAllOwners) {
        countApplicants += list.length;
      } else {
        const listOfAllTicked = list.filter(obj => obj.ticked) || [];
        countApplicants += listOfAllTicked.length;
      }
      return (100 / countApplicants).toFixed(2);
    };

    $scope.ownerChange = (data, list, sourceIsTick) => {
      if (!sourceIsTick && (!data.Percentage || parseInt(data.Percentage, 10) === 0)) {
        data.Percentage = 0;
        list.map((obj) => {
          if (obj.BorrowerID === data.BorrowerID) obj.ticked = false;
          return obj;
        });
      } else if (sourceIsTick) {
        const percentage = getEqualPercentage(list, false);
        list.map((obj) => {
          obj.Percentage = obj.ticked ? percentage : 0;
          return obj;
        });
      }

      $scope.scenarioApplicantList = [...list];
    };

    $scope.selectAll = (isSelectAll = true, list) => {
      let percentage = 0;
      if (isSelectAll) {
        percentage = getEqualPercentage(list);
      }

      $scope.scenarioApplicantList = list.map((obj) => {
        obj.Percentage = percentage;
        return obj;
      });
    };

    $scope.resetOwners = (list) => {
      $scope.selectAll(true, list);
    };

    $scope.getOwners = () => {
      loanScenarioService.scenarioApplicantListGet($scope.loanAppId).then((response) => {
        $scope.OwnershipListInit = [];
        angular.forEach(response.data.InvolvedPartyPerson, (family) => {
          angular.forEach(family, (client) => {
            const data = {
              Percentage: 100,
              BorrowerID: parseInt(client.PersonId, 10),
              ClientID: parseInt(client.PersonId, 10),
              FirstName: client.FirstName,
              ClientType: client.Role,
              LastName: client.LastName,
              PreferedName: client.FamilyFullName,
              IsInclude: true,
              IsTicked: true,
              OwnershipPercentage: 100,
              ticked: true,
            };

            data.initials = utilitiesService.filterInitialOneString(client.FirstName);
            data.background = $scope.getBGcolor(client.PersonId);

            $scope.OwnershipListInit.push(data);
          });
        });

        angular.forEach(response.data.InvolvedPartyEntity, (client) => {
          const data = {
            Percentage: 100,
            BorrowerID: parseInt(client.OrganisationId, 10),
            EntityID: parseInt(client.OrganisationId, 10),
            ClientType: client.EntityTypeName,
            PreferedName: client.FamilyFullName,
            IsInclude: true,
            IsEntity: true,
            IsTicked: true,
            OwnershipPercentage: 100,
            ticked: true,
          };
          data.initials = utilitiesService.filterInitialOneString(client.FirstName);
          data.background = $scope.getBGcolor(client.OrganisationId);

          $scope.OwnershipListInit.push(data);
        });
      });
    };

    $scope.randomColors = [];

    $scope.setOwnerRandomColor = (owner) => {
      _.find($scope.randomColors, (o) => {
        return parseInt(o.BorrowerID, 10) === parseInt(owner.BorrowerID, 10);
      });
    };

    $scope.updateSecurity = (item, isFromDropdown) => {
      if (!isFromDropdown) {
        // check if there are mortgage that is being refinanced
        _.forEach(item.MortgageList, (mortgage) => {
          if (mortgage.IsRefinance === true) {
            item.TransactionId = refinanceId;
          } else {
            item.TransactionId = ownsId;
          }
        });
      }

      const obj = {
        LoanApplicationId: $scope.loanAppId,
        SecurityInfoId: item.SecurityInfoId,
        IsPrimary: item.IsPrimary,
        SecurityTypeId: parseInt(item.SecurityTypeId, 10),
        TransactionId: parseInt(item.TransactionId, 10),
        MortgagePriority: item.MortgagePriority,
        LoanSecurityId: parseInt($scope.LoanSecurityList.LoanSecurityId, 10),
        IsRefinance: item.IsRefinance,
        PropertyDetails: {
          LoanSecurityId: parseInt(item.SecurityInfoId, 10), // go back
          StatusId: parseInt(item.PropertyDetails.StatusId, 10),
          PropertyPrimaryPurposeId: parseInt(item.PropertyDetails.PropertyPrimaryPurposeId, 10),
          HoldingId: parseInt(item.PropertyDetails.HoldingId, 10),
          ZoningId: parseInt(item.PropertyDetails.ZoningId, 10),
          PropertyTypeId: parseInt(item.PropertyDetails.PropertyTypeId, 10),
          Suburb: item.PropertyDetails.Suburb || '',
          PropertyState: item.PropertyDetails.PropertyState || '',
          CurrentValue: item.PropertyDetails.CurrentValue,
          LandValue: item.PropertyDetails.LandValue,
          EstimatedPrice: item.PropertyDetails.EstimatedPrice,
          EstimatedBasesId: parseInt(item.PropertyDetails.EstimatedBasesId, 10),
          ContratctPrice: item.PropertyDetails.ContratctPrice,
          ContractDate: item.PropertyDetails.ContractDate,
          LicensedRealEstateAgentContract: item.PropertyDetails.LicensedRealEstateAgentContract,
          IsPreApproved: item.PropertyDetails.IsPreApproved,
          AddressId: item.PropertyDetails.AddressId,
          TitleTypeId: item.PropertyDetails.TitleTypeId,
          TitleId: item.PropertyDetails.TitleId,
          IdentifierList: item.PropertyDetails.IdentifierList,
          ContactForAccess: {
            ContactForAccess: item.PropertyDetails.ContactForAccess.ContactForAccess,
            CompanyName: item.PropertyDetails.ContactForAccess.CompanyName,
            ContactTitle: item.PropertyDetails.ContactForAccess.ContactTitle,
            ContactFirstName: item.PropertyDetails.ContactForAccess.ContactFirstName,
            ContactLastName: item.PropertyDetails.ContactForAccess.ContactLastName,
            PhoneAreaCode: item.PropertyDetails.ContactForAccess.PhoneAreaCode,
            PhoneNumber: item.PropertyDetails.ContactForAccess.PhoneNumber,
            FaxAreaCode: item.PropertyDetails.ContactForAccess.FaxAreaCode,
            FaxNumber: item.PropertyDetails.ContactForAccess.FaxNumber,
            ContactTypeId: item.PropertyDetails.ContactForAccess.ContactTypeId,
          },
        },
        MortgageList: item.MortgageList,
        Ownership: item.Ownership,
        AssetId: item.AssetId,
      };

      loanApplicationServices.setLoanSecurityInfo(obj).then(() => {
        $scope.getParameters();
        $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
      });
    };

    $scope.updateMortgageRefinance = (mortgage, item) => {
      const mortgageTemp = {
        LoanApplicationId: $scope.loanAppId,
        LoanSecurityInfoId: item.SecurityInfoId, // reassign
        SecurityMortgageId: 0,
        edit: 0,
        MortgagePriorityId: '',
        Ownership: [], // reassign
        CreditorId: '',
        /**
                * TODO: add principalInterest on DB and API
                * */
        IsRefinance: false,
        principalInterest: 2, // 2:Principal and interest; 1: Interest Only
        InterestTaxDeductible: true,
      };

      const setMortgage = angular.extend(mortgageTemp, mortgage);

      loanApplicationServices.setLoanSecurityMortgage(setMortgage).then(() => {
        $rootScope.$broadcast('getFundingCalculatorFundsSummary');
      });
    };

    $scope.TransactionTypeList = [];
    $scope.getTransactionType = (objResponse) => {
      if (_.size($scope.TransactionTypeList) === 0) {
        optionsService.getTransactionType().then((response) => {
          const TransactionTypeList = parseIntValue(response.data);
          $scope.TransactionTypeList = _.map(TransactionTypeList, (o) => {
            o.disabled = false;
            return o;
          });
          $scope.TransactionTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.TransactionTypeList];

          $scope.getTransactionTypeCallback(objResponse);
        });
      } else {
        $scope.getTransactionTypeCallback(objResponse);
      }
    };

    $scope.getTransactionTypeCallback = (response) => {
      _.map($scope.securityList, (o) => {
        if (o.TransactionId) {
          o.TransactionId = parseInt(o.TransactionId, 10);
        }
        return o;
      });

      _.forEach($scope.securityList, (security) => {
        const TransactionId = parseInt(security.TransactionId, 10);
        const isExistingSecurity = (TransactionId !== TRANSACTION_TYPE.PURCHASING) && (TransactionId !== TRANSACTION_TYPE.CONSTRUCTION);
        if (isExistingSecurity) {
          security.TransactionTypeList = [...$scope.TransactionTypeList];

          // remove the first item, purchasing, sold and construction
          _.forEach(security.TransactionTypeList, (o, i) => {
            const value = parseInt(o.Value, 10);
            o.disabled = (!!(i === 0 || value === TRANSACTION_TYPE.PURCHASING || value === TRANSACTION_TYPE.SOLD || value === TRANSACTION_TYPE.CONSTRUCTION));
          });
        } else {
          security.TransactionTypeList = angular.copy($scope.TransactionTypeList);

          // only show the purchasing and constructor if there is
          _.forEach(security.TransactionTypeList, (o) => {
            const value = parseInt(o.Value, 10);
            o.disabled = (value !== TRANSACTION_TYPE.PURCHASING) && (value !== TRANSACTION_TYPE.CONSTRUCTION);
          });
        }
      });

      $scope.LoanSecurityList = response.data;

      $scope.getFundingCalculatorInfo();
      $scope.sumSecurityAmount();

      const filterRefinance = _.filter(response.data.SecurityList, (obj) => {
        const objMortgageRefinance = _.find(obj.MortgageList, (objMort) => {
          return objMort.IsRefinance === true;
        }) || {};
        return !_.isEmpty(objMortgageRefinance);
      }) || [];

      loanAppSharedData.isRefinance = filterRefinance.length >= 1;
    };

    $scope.getLoanSecurity = () => {
      loanScenarioService.getLoanSecurity($scope.loanAppId).then((response) => {
        $scope.securityList = response.data.SecurityList;
        $scope.setSecurityModel($scope.securityList);

        // Used in Product finder product type filter
        if (_.size(response.data.SecurityList) > 0)
          $rootScope.loanAppSecurityProductType = response.data.SecurityList[0].PropertyDetails.PropertyPrimaryPurposeId || '';
        else
          $rootScope.loanAppSecurityProductType = '';

        $rootScope.loanAppSecurityListCount = $scope.securityList.length;

        $scope.getTransactionType(response);
      });
    };

    $scope.$watch(() => { return loanAppSharedData.refreshSecurityList; }, (nv) => {
      if (nv) {
        $scope.getLoanSecurity();
      }
      loanAppSharedData.refreshSecurityList = false;
    });

    $scope.getLoanSecurity();

    $scope.$watch('loanAppSharedData.REFRESH_FUNDING_REQUIRED_SECTION', (nv) => {
      if (nv && _.size(nv) > 0) {
        $scope.getLoanSecurity();
      }
    });

    $scope.getLoanSecurityInfo = () => {
      loanApplicationServices.getLoanSecurityInfo().then(() => {
      });
    };

    $scope.sumSecurityAmount = () => {
      $scope.totalSecurity = 0;
      angular.forEach(
        $scope.securityList,
        (val) => {
          $scope.totalSecurity += val.PropertyDetails.CurrentValue;
        }
      );
    };
    $scope.getSecurityOptions = () => {
      optionsService.getSecurityType().then((response) => {
        const SecurityTypeList = parseIntValue(response.data);
        $scope.SecurityTypeList = [{ Name: 'Please select', Value: '' }, ...SecurityTypeList];
      });

      optionsService.getAssetValueEstimatedBasis().then((response) => {
        const BasisList = parseIntValue(response.data);
        $scope.AssetValueEstimatedBasisList = [{ Name: 'Please select', Value: '' }, ...BasisList];
      });

      optionsService.getHoldingType().then((response) => {
        const HoldingTypeList = parseIntValue(response.data);
        $scope.HoldingTypeList = [{ Name: 'Please select', Value: '' }, ...HoldingTypeList];
      });

      optionsService.getPrimaryPurposeType().then((response) => {
        $scope.PrimaryPurposeTypeList = parseIntValue(response.data);
        $scope.PrimaryPurposeTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.PrimaryPurposeTypeList];
      });

      optionsService.getStatusType().then((response) => {
        $scope.StatusTypeList = parseIntValue(response.data);
        $scope.StatusTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.StatusTypeList];
      });

      optionsService.getZoningType().then((response) => {
        $scope.ZoningTypeList = parseIntValue(response.data);
        $scope.ZoningTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.ZoningTypeList];
      });

      $scope.PropertyTypeList = [{ AddressPropertyType: 'Please select Zoning first', AddressPropertyTypeID: '' }];

      optionsService.getTitleTenureType().then((response) => {
        $scope.TitleTenureTypeList = parseIntValue(response.data);
        $scope.TitleTenureTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.TitleTenureTypeList];
      });

      optionsService.getTitleType().then((response) => {
        $scope.TitleTypeList = parseIntValue(response.data);
        $scope.TitleTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.TitleTypeList];
      });

      optionsService.getTitleIdentificationType().then((response) => {
        $scope.TitleIdentificationTypeList = parseIntValue(response.data);
        $scope.TitleIdentificationTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.TitleIdentificationTypeList];
      });

      optionsService.getContactAccessType().then((response) => {
        $scope.ContactAccessTypeList = parseIntValue(response.data);
        $scope.ContactAccessTypeList = [{ Name: 'Please select', Value: '' }, ...$scope.ContactAccessTypeList];
      });

      optionsService.getMortgagePriority().then((response) => {
        $scope.MortgagePriorityList = parseIntValue(response.data);
        $scope.MortgagePriorityList = [{ Name: 'Please select', Value: '' }, ...$scope.MortgagePriorityList];
      });
    };
    $scope.checkIdentifierNumber = (isAddOrEdit) => {
      if (isAddOrEdit === 'isAdd' && $scope.security.add.PropertyDetails.IdentifierList.length > 1) {
        return true;
      } else if (isAddOrEdit === 'isEdit' && !_.isUndefined($scope.security.edit.PropertyDetails.IdentifierList)) {
        if ($scope.security.edit.PropertyDetails.IdentifierList.length > 1) {
          return true;
        }
      } else
        return false;
    };
    $scope.addNewIdentifier = (isAddOrEdit) => {
      if (isAddOrEdit === 'isAdd') {
        $scope.security.add.PropertyDetails.IdentifierList.push(
          {
            Security_TitleIdentityId: 0, // TitleTenureType
            IdentityTypeId: '', // TitleIdentificationType
            Value: '',
          }
        );
      } else if (isAddOrEdit === 'isEdit') {
        $scope.security.edit.PropertyDetails.IdentifierList.push(
          {
            Security_TitleIdentityId: 0, // TitleTenureType
            IdentityTypeId: '', // TitleIdentificationType
            Value: '',
          }
        );
      }
    };
    $scope.removeIdentifier = (theIndex, addOrEdit) => {
      if (addOrEdit === 'isAdd') {
        _.remove($scope.security.add.PropertyDetails.IdentifierList, (object, key) => {
          return key === theIndex;
        });
      } else if (addOrEdit === 'isEdit') {
        _.remove($scope.security.edit.PropertyDetails.IdentifierList, (object, key) => {
          return key === theIndex;
        });
      }
    };
    $scope.mortgageSettings = {};

    $scope.saveNewMortgage = () => {
      $scope.security.add.MortgageList.push($scope.mortgageTemp); // add to array
      $scope.mortgageTemp = {}; // reset
      $scope.mortgageSettings.showAddMortgage = false;
    };

    $scope.saveNewMortgageEditMode = () => {
      $scope.security.edit.MortgageList.push($scope.mortgageTemp); // add to array
      $scope.mortgageTemp = {}; // reset
      $scope.mortgageSettings.showAddMortgage = false;
    };

    $scope.validateSecurity = () => {
      $scope.isValidSecurity = $scope.securityList.length > 0;
    };

    $scope.editMortgage = () => {
    };

    $scope.deleteMortgage = (index) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This mortgage will be removed from your list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      }, (confirm) => {
        if (confirm) {
          $scope.security.add.MortgageList.splice(index, 1);
        }
      });
    };

    $scope.deleteMortgageEditMode = (index) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This mortgage will be removed from your list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      }, (confirm) => {
        if (confirm) {
          $scope.security.edit.MortgageList.splice(index, 1);
        }
      });
    };

    /*
        * Delete Security Info
        * */
    $scope.deleteSecurity = (item) => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This information will be removed from your list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      }, (confirm) => {
        if (confirm) {
          loanApplicationServices.deleteLoanSecurityInfo($scope.loanAppId, item.SecurityInfoId, item.FundingCalParametersId ? item.FundingCalParametersId : 0).then(() => {
            $scope.getLoanSecurity();
            $scope.financialsSharedService.IncomeInfoGet();
            $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
          });
        }
      });
    };


    $scope.getNewIncomeModel = (LoanSecurityInfoID) => {
      return {
        IsEvidenceOfTenancy: true,
        EmploymentId: 0,
        AssetId: 0,
        IncomeVerification: [
          {
            IncomeVerificationTypeId: 0,
            IncomeVerificationType: '',
          },
        ],
        Id: LoanSecurityInfoID,
        LoanScenarioId: $scope.loanAppId,
        FamilyId: $scope.familyId,
        FinancialId: 0,
        TypeId: 0,
        Description: '',
        Amount: 0,
        CurrencyCode: $scope.crmCurrency,
        CategoryId: 0,
        CurrencyCodeId: $scope.crmCountryID,
        Borrowers: [
        ],
        FrequencyID: 0,
        Provider: '',
        IsAllOwner: true,
        IsLinkedSomewhereElse: true,
        LoanTerm: 0,
        NextGenValue: '',
        IncomeSubTypeId: '1',
        RentalVerificationID: '',
      };
    };

    $scope.formAreas = {
      info: {
        error: false,
        data: [
          'SecurityTypeId',
          'TransactionId',
          'owners',
        ],
      },
      details: {
        error: false,
        data: [
          'StatusId',
          'PropertyPrimaryPurposeId',
          'HoldingId',
          'ZoningId',
          'PropertyTypeId',
        ],
      },
      value: {
        error: false,
        data: [
          'CurrentValue',
          'LandValue',
          'EstimatedPrice',
          'EstimatedBasesId',
          'ContratctPrice',
          'ContractDate',
        ],
      },
      location: {
        error: false,
        data: [
          'Suburb',
          'PropertyState',
          'formatted_address',
          'TitleTypeId',
          'TitleId',
          'IdentityTypeId',
          'IdentityValue',
          'CompanyName',
          'PhoneAreaCode',
          'PhoneNumber',
          'ContactTitle',
          'ContactFirstName',
          'ContactLastName',
        ],
      },
      mortgages: {
        error: false,
        data: [
          'MortgagePriorityId',
          'CreditorId',
          'Limit',
          'MortgageAmount',
          'MortgageRepayments',
          'InterestRate',
          'AccountName',
          'BSB',
          'AccountNumber',
          'LoanTerm',
        ],
      },
      proposedRentalIncome: {
        error: false,
        data: [
          'IncomeGrossAmount',
          'IncomeFrequency',
          'rental',
        ],
      },
    };

    optionsService.RentalVerificationTypeGet().then((response) => {
      $scope.rentalVerificationTypeList = response.data;
    });

    // this is for the security or funding required of Indonesian
    $scope.setSecurityModel = (securityData) => {
      $scope.securityModel = {};
      if (!securityData || !securityData.length) return;
      const { AssetId, FundingCalParametersId, SecurityInfoId, SecurityTypeId, PropertyDetails } = securityData[0];
      $scope.securityModel.AssetId = AssetId;
      $scope.securityModel.FundingCalParametersId = FundingCalParametersId;
      $scope.securityModel.SecurityInfoId = SecurityInfoId;
      $scope.securityModel.SecurityTypeId = SecurityTypeId;
      $scope.securityModel.EstimatedPrice = PropertyDetails.EstimatedPrice;
      $scope.securityModel.formatted_address = PropertyDetails.Suburb;
    };

    $scope.setSecurity = () => {
      const setData = {
        LoanApplicationId: $scope.loanAppId,
        SecurityInfoId: $scope.securityModel.SecurityInfoId || 0,
        FundingCalParametersId: $scope.securityModel.FundingCalParametersId || 0,
        IsPrimary: true,
        SecurityTypeId: $scope.securityModel.SecurityTypeId ? parseInt($scope.securityModel.SecurityTypeId, 10) : '',
        LoanSecurityId: parseInt($scope.LoanSecurityList.LoanSecurityId, 10),
        TransactionId: '',
        MortgagePriority: [],
        IsRefinance: true,
        PropertyDetails: {
          LoanSecurityId: 0,
          StatusId: '',
          PropertyPrimaryPurposeId: '',
          HoldingId: '',
          ZoningId: '',
          PropertyTypeId: '',
          Suburb: $scope.securityModel.formatted_address ? $scope.securityModel.formatted_address : '',
          PropertyState: '',
          CurrentValue: '',
          LandValue: '',
          EstimatedPrice: $scope.securityModel.EstimatedPrice ? $scope.securityModel.EstimatedPrice : '',
          EstimatedBasesId: '',
          ContratctPrice: '',
          ContractDate: '',
          LicensedRealEstateAgentContract: true,
          IsPreApproved: true,
          IdentifierList: [],
          AddressId: 0,
          TitleTypeId: '',
          TitleId: '',
          ContactForAccess: {
            ContactForAccess: 0,
            CompanyName: '',
            ContactTitle: '',
            ContactFirstName: '',
            ContactLastName: '',
            PhoneAreaCode: '',
            PhoneNumber: '',
            FaxAreaCode: '',
            FaxNumber: '',
            ContactTypeId: '',
          },
        },
        MortgageList: [],
        IncomeList: [],
        Ownership: [],
        AssetId: $scope.securityModel.AssetId || 0,
      };
      $timeout.cancel($scope.securityTimeOut);
      $scope.securityTimeOut = $timeout(() => {
        loanApplicationServices.setLoanSecurityInfo(setData);
      }, 2000);
    };

    $scope.selectAddress = (address) => {
      $scope.securityModel.formatted_address = address.formatted_address;
      $scope.showSuggestionMenu = false;
      $scope.setSecurity();
    };

    $scope.getSuggestedAddress = () => {
      $timeout.cancel($scope.searchTimeOut);
      $scope.searchTimeOut = $timeout(() => {
        if ($scope.securityModel.formatted_address.length < 3) return;
        generalService.placeSearch($scope.securityModel.formatted_address)
          .then((response) => {
            if (!response || !response.data) return;
            $scope.showSuggestionMenu = true;
            $scope.addressSuggestions = response.data;
          });
      }, 500);
    };

    $scope.$on('$destroy', () => {
      if ($scope.searchTimeOut) {
        $timeout.cancel($scope.searchTimeOut);
      }
      if ($scope.securityTimeOut) {
        $timeout.cancel($scope.securityTimeOut);
      }
    });
    // Indonesian Funding Required End here.

    $scope.showAddSecurityModal = () => {
      $uibModal.open({
        templateUrl: '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/security/add.html',
        controller: 'ShowAddSecurityModalCtrl',
        size: 'lg',
        scope: $scope,
      });
    };

    $scope.showEditSecurityModal = (LoanSecurityInfoId) => {
      $uibModal.open({
        templateUrl: '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/security/edit.html',
        controller: 'ShowEditSecurityModalCtrl',
        size: 'lg',
        scope: $scope,
        resolve: {
          LoanSecurityInfoId: () => LoanSecurityInfoId,
        },
      });
    };

    /*
            Below code are temporary
        */
    $scope.addOther = (item) => {
      if (_.isUndefined(item.Values)) {
        item.Values = [];
      }

      item.Values.push({ Name: '', Value: '', isEditing: false, isNew: true });
    };

    $scope.saveOther = (other) => {
      other.isEditing = false;
      other.isNew = false;
    };

    $scope.deleteOther = (item, other) => {
      _.remove(item, (o) => { return _.isEqual(o, other); });
    };
  });
