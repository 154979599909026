import angular from 'angular';

class EmailTemplatesSharedData {
  constructor() {}

  getNewTemplateToSave() {
    return { subject: this.newTemplateSubjectToSave, content: this.newTemplateContentToSave };
  }

  setNewTemplateToSave(subject, content) {
    this.newTemplateSubjectToSave = subject;
    this.newTemplateContentToSave = content;
  }

  isShowDefaultTemplateSettings() {
    return this.showDefaultTemplateSettings;
  }

  setShowDefaultTemplateSettings(show) {
    this.showDefaultTemplateSettings = show;
  }
}

angular.module('app').factory('emailTemplatesSharedData', () => new EmailTemplatesSharedData());
