export const ADVISER_STATUS = {
  ONBOARDING: 0,
  ACTIVE: 1,
  TERMINATED: 2,
};

export const LEADS_STATUS = {
  ACTIVE: 1,
  TEMPORARILY_INACTIVE: 2,
  INACTIVE: 3,
  SUSPENDED: 4,
};
