import { pipelineSettingsBuilderForAPI } from 'Common/mappers/pipeline';
import { PipelineOpportunitySettingsService } from 'Common/services/pipelineSettingsService';

export default class KanbanSorting {
  constructor(args = { isOpportunity: true, isLeads: false }, services = {}) {
    const { pipelineService } = services;
    this.pipelineService = pipelineService;

    const { isLeads, isOpportunity } = args;
    this.filterSettings = {};
    this.cardList = [];
    this.getFilterSettings(isLeads, isOpportunity);
  }

  getFilterSettings(isLeads, isOpportunity) {
    this.pipelineService.getPipelineCardFilters(isLeads, isOpportunity)
      .then(({ data: settings }) => {
        const hasSettings = settings && settings.length && settings[0];
        if (!hasSettings) return;
        this.filterSettings = new PipelineOpportunitySettingsService(settings[0], this.pipelineService);
      });
  }

  updateCardList(kanbanColumns) {
    this.cardList = kanbanColumns;
    this.updateSortString();
  }

  updateSortString() {
    const customerString = this.cardList.reduce((finalSortString, column) => {
      const isColumnHasCards = column && column.cards && column.cards.length;
      if (!isColumnHasCards) return finalSortString;

      let totalRecords = column.cards.length;
      const columnSort = column.cards.reduce((columnSortString, card) => {
        totalRecords--;
        return columnSortString.concat(`${column.name}:${card.loanId}:${totalRecords},`);
      }, '');

      return finalSortString.concat(columnSort);
    }, '');
    this.filterSettings.customerSort = customerString.slice(0, -1);
    this.pipelineService.setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.filterSettings));
  }
}
