import angular from 'angular';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';

angular.module('app').controller('CustomerCareSendMessageModalBaseCtrl', function CustomerCareSendMessageModalBaseCtrl(
  $scope,
  $window,
  $uibModal,
  contactService,
  mailTemplateService,
  templateService
) {
  $scope.getRecipientAutoSuggestion = (userInput) => {
    contactService.getRecipientAutoSuggestion(userInput).then((response) => {
      $scope.selectEmailList = response.data;
    });
  };

  $scope.setTemplateSelection = (template, index) => {
    if (template) {
      const { UserTemplateContent } = template;
      $scope.selectedTemplate = template;
      $scope.iconChange = !$scope.iconChange;
      $scope.showTick = true;
      $scope.index = index;
      UserTemplateContent && $scope.translateTemplate(UserTemplateContent);
    }
  };

  $scope.translateTemplate = (templateContent) => {
    $scope.sms = $scope.sms || {};
    if (templateContent && templateContent.trim()) {
      const mergeFieldData = {
        familyId: $scope.familyId,
        templateContent,
      };
      mailTemplateService.translateMailTemplate(mergeFieldData).then((response) => {
        $scope.sms.Message = response;
      }, (error) => {
        $scope.sms.Message = error.templateContent || templateContent;
      });
    } else {
      $scope.sms.Message = null;
    }
  };

  $scope.openManageTemplatesModal = () => {
    const modalInstance = templateService.openSMSTemplatesManagement(true);
    if (modalInstance && modalInstance.result) {
      modalInstance.result.then((hasChanges) => {
        if (!hasChanges) return;
        $scope.templatesSelectionAPI.refresh();
      });
    }
  };

  $scope.openDefaultTemplateSettings = () => {
    templateService.openDefaultTemplateSettings($scope.selectedTemplate, SEND_METHOD_TYPES.SMS);
  };

  $scope.hasValidSelectedTemplate = () => {
    return templateService.isValidTemplate($scope.selectedTemplate);
  };

  $scope.onTemplatesInit = (api) => {
    $scope.templatesSelectionAPI = api;
  };
});
