
app.factory('colorService', ['$q', function ($q) {
  var factory = this;

  factory.getRandomColor = function () {
    var colors = [
      '614a9c',
      '6d4dbc',
      '9e81dd',
      '9278c2',
      '5b458d',

      '305292',
      '4760a0',
      '6d89bb',
      '739cd2',
      '87b8f2',

      '4d4479',
      '6971ba',
      '7486d2',
      'a4a5c4',
      '89a0ee',

      '27467f',
      '1d5dbf',
      '2378ec',
      '619fea',
      '95add3',

      '08b0b9',
      '37c2c7',
      '5fd0d8',
      '68cedc',
      'a3c7a1',

      'f5657c',
      'ef646b',
      'ec84b9',
      'e98c96',
      'fbbd66',
    ];


    return colors[_.random(colors.length - 1)];
  };

  return factory;
}]);
