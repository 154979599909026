
import angular from 'angular';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  SERVICE_TAB_INDEX,
  SERVICE_ID,
} from 'Common/constants/customerCareModules';
import { objectLength } from 'Common/utilities/objectValidation';

angular.module('app').controller('CustomerCareCtrl',
  function CustomerCareCtrl($scope,
    $uibModal,
    SweetAlert,
    NgTableParams,
    $window,
    $location,
    $state,
    $stateParams,
    toaster,
    cfpLoadingBar,
    $timeout,
    $rootScope,
    $filter,
    $localStorage,
    $document,
    customerCareService,
    contactService,
    subscriptionService,
    commonFnService) {
    $rootScope.CustomerCareMainObj = {};
    $rootScope.CustomerCareMainObj.selectedTab = 'fixedRateExpires';

    $scope.customerCareAdviserList = [];

    $scope.serviceId = SERVICE_ID.FIXED_RATE_EXPIRES;
    $scope.getTabBrokers = () => {
      customerCareService.getBrokers({ typeId: $scope.serviceId, loanWritersOnly: true })
        .then((response) => {
          $scope.customerCareAdviserList = response;
        });
    };

    customerCareService.customerCareDateRange()
      .then((response) => {
        if (!response) return;
        $scope.customerCareDateRange = response.data;
      });

    $scope.selectTab = function (selectedTab) {
      $timeout(() => {
        const el = $document.find(`#${selectedTab} .nav-link`);
        angular.element(el).triggerHandler('click');
      }, 0);
    };

    $scope.userInfo = {};
    $scope.isAdviser = true;
    $scope.someBrokersSubscribed = false;
    $scope.autoMarketingSubscription = {};
    $scope.isAssistantUnlocked = false;
    $scope.showLockScreen = false;
    const messageForBroker = 'You have opted in to a subscription where these messages are being sent automatically to your clients. The associated clients have been hidden from this view to prevent accidental duplicate communications.';
    $scope.autoMarketingMessage = messageForBroker;

    const ORG_SUBSCRIPTION = {
      NONE: 0,
      SOME: 1,
      ALL: 2,
    };

    const messageToDisplay = (orgSubscribedType, isSubscribed) => {
      const someSubscribedMessage = 'At least one broker has opted in to a subscription where these messages are being sent automatically. Their associated clients have been hidden from this view to prevent accidental duplicate communications.';
      const allSubscribedMessage = 'All brokers in your business have opted in to a subscription where these messages are being sent automatically. Their associated clients have been hidden from this view to prevent accidental duplicate communications.';

      $scope.autoMarketingMessage = isSubscribed ? messageForBroker : '';
      $scope.isAdviser = $scope.userInfo.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER || $scope.userInfo.AccessType === ACCESS_TYPE.ADVISER;

      if (orgSubscribedType === ORG_SUBSCRIPTION.SOME) {
        $scope.autoMarketingMessage = (!$scope.isAdviser) ? someSubscribedMessage : $scope.autoMarketingMessage;
        $scope.someBrokersSubscribed = true;
      } else if (orgSubscribedType === ORG_SUBSCRIPTION.ALL) {
        $scope.autoMarketingMessage = (!$scope.isAdviser) ? allSubscribedMessage : $scope.autoMarketingMessage;
        $scope.someBrokersSubscribed = false;
      }
      $scope.isAssistantUnlocked = !$scope.autoMarketingMessage || (!$scope.isAdviser && $scope.someBrokersSubscribed);
      $scope.showLockScreen = ($scope.autoMarketingMessage && $scope.isAdviser) || ($scope.autoMarketingMessage && !$scope.isAdviser && !$scope.someBrokersSubscribed);
      if (!$scope.showLockScreen) $scope.getTabBrokers();
    };

    $scope.checkTabView = () => {
      if (!objectLength($scope.autoMarketingSubscription)) return;
      const {
        subscribe_FixedRateExpiry: subscribeFixedRateExpires,
        subscribe_HappyBirthday: subscribeBirthdays,
        subscribe_PreApprovalExpiry: subscribePreApprovalExpiry,
        subscribe_StayInTouchAnnualReview: subscribeAnnualReview,
        subscribe_StayInTouchSettlement: subscribeSettlement,
        organization_FixedRateExpiry: fixedRateExpires,
        organization_HappyBirthday: birthdays,
        organization_PreApprovalExpiry: preApprovalExpiry,
        organization_StayInTouchAnnualReview: annualReview,
        organization_StayInTouchSettlement: settlement,
      } = $scope.autoMarketingSubscription;

      switch ($scope.activeTab) {
      case SERVICE_TAB_INDEX.APPROVAL_EXPIRIES:
        $scope.serviceId = SERVICE_ID.APPROVAL_EXPIRIES;
        messageToDisplay(preApprovalExpiry, subscribePreApprovalExpiry);
        break;
      case SERVICE_TAB_INDEX.BIRTHDAYS:
        $scope.serviceId = SERVICE_ID.BIRTHDAYS;
        messageToDisplay(birthdays, subscribeBirthdays);
        break;
      case SERVICE_TAB_INDEX.ANNUAL_REVIEWS:
        $scope.serviceId = SERVICE_ID.ANNUAL_REVIEWS;
        messageToDisplay(annualReview, subscribeAnnualReview);
        break;
      case SERVICE_TAB_INDEX.POST_SETTLEMENT:
        $scope.serviceId = SERVICE_ID.POST_SETTLEMENT;
        messageToDisplay(settlement, subscribeSettlement);
        break;
      default:
        $scope.serviceId = SERVICE_ID.FIXED_RATE_EXPIRES;
        messageToDisplay(fixedRateExpires, subscribeFixedRateExpires);
        break;
      }
    };

    $scope.getUserSubscription = () => {
      subscriptionService.marketingAutomation()
        .then((response) => {
          if (!response) return;
          $scope.autoMarketingSubscription = response;
          $scope.checkTabView();
        });
    };

    $scope.getUserInfo = () => {
      contactService.getUserInfo()
        .then((response) => {
          if (!response || !response.data) return;
          const { data } = response;
          $scope.userInfo = data;
          $scope.getUserSubscription();
        });
    };
    $scope.getUserInfo();

    $scope.selectTabUpdate = function (tab) {
      $rootScope.CustomerCareMainObj.selectedTab = tab;
      $scope.checkTabView();
    };

    if (typeof $stateParams.activeTab !== 'undefined' && $stateParams.activeTab !== '') {
      $scope.activeTab = parseInt($stateParams.activeTab, 10);
    }

    $scope.gotoContact = (familyId) => {
      commonFnService.hiddenClientForNz().then((isHidden) => {
        if (familyId && !isHidden) {
          $state.go('app.contactsSingle', { familyId });
        }
      });
    };
  }
);
