import { objectLength } from 'Common/utilities/objectValidation';
import { SETTINGS_TYPE_ID } from 'Common/constants/featureManagementSettings';
import {
  toastSaveSuccess,
  toastError,
} from 'Common/utilities/alert';
import toastr from 'toastr';
import swal from 'sweetalert';

export default class FeatureManagementCtrl {
  constructor(
    corporateModelService,
    corporateService,
    contactService,
    uiService,
    $timeout,
    toaster,
    $sce
  ) {
    'ngInject';

    this.corporateModelService = corporateModelService;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.uiService = uiService;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.$sce = $sce;
    this.SETTINGS_TYPE_ID = SETTINGS_TYPE_ID;
  }

  $onInit() {
    this.FEATURES = {
      OPPORTUNITY_ID: 1,
      MARKETING_AUTOMATION_ID: 2,
    };

    this.previousTitle = 'Corporate';
    this.pageTitle = 'Feature Management';
    this.featureSettingList = [
      {
        title: 'Turn Off',
        secondTitle: this.$sce.trustAsHtml('Off <i>(Hidden)</i>'),
        description: 'Hidden from all MyCRM users',
        settingTypeId: SETTINGS_TYPE_ID.OFF,
        color: '#9A86A8',
      },
      {
        title: 'Dependent on Adviser Org',
        description: 'Visible to MyCRM users who are members of the selected Adviser Organisations',
        settingTypeId: SETTINGS_TYPE_ID.DEPENDENT_ON_ADVISER_ORG,
        color: '#F99C5D',
      },
      {
        title: 'Turn On',
        secondTitle: this.$sce.trustAsHtml('On <i>(Visible)</i>'),
        description: 'Visible to all MyCRM users',
        settingTypeId: SETTINGS_TYPE_ID.ON,
        color: '#42B4B4',
      },
    ];

    this.checkAccessValidity();
    this.getFeatures();
  }

  checkAccessValidity() {
    this.contactService.getUserInfo()
      .then((response) => {
        if (!response || !response.data) return;
        const { CorporateTeamId: corporateTeamId } = response.data;
        const isAccessValid = corporateTeamId && this.uiService.checkForFeatureManagement(corporateTeamId);
        if (isAccessValid) return;
        this.uiService.stateGo('app.dashboard');
      });
  }

  getFeatures() {
    this.featureList && this.featureList.map((feature) => {
      feature.organizations = [];
      return feature;
    });

    this.corporateService.getFeatures()
      .then((data) => {
        this.featureList = data;

        this.featureList.map((feature) => {
          const listOfEnabledOrganizations = feature.organizations.reduce((accum, current) => {
            return accum.concat(current.adviserOrganizationId);
          }, []);
          feature.listOfEnabledOrganizations = listOfEnabledOrganizations;

          const setting = this.featureSettingList.find(o => o.settingTypeId === feature.settingTypeId) || {};
          feature.setting = { ...setting };

          return feature;
        });
      });
  }

  setSetting(selectedSetting, feature) {
    const bakSetting = { ...feature.setting };
    feature.setting = { ...selectedSetting };

    const { settingTypeId } = selectedSetting;
    const { featureId: id } = feature;
    const args = { id, settingTypeId };
    this.corporateModelService.putFeaturesSettings(args)
      .then(({ succeeded }) => {
        if (succeeded) {
          toastSaveSuccess(this.toaster);
          this.getFeatures();
        } else {
          toastError();
          feature.setting = { ...bakSetting };
        }
      });
  }

  searchAdviserOrg(feature) {
    this.showAdviserOrgList = true;
    this.selectedAdviserOrg = {};
    feature.adviserOrgList = [];
    this.$timeout.cancel(this.searchAdviserOrgTimeout);
    this.searchAdviserOrgTimeout = this.$timeout(() => {
      this.corporateService.getAdviserOrganizationList({ searchText: feature.searchAdviserOrgText })
        .then(({ data }) => {
          if (!data) return;

          const adviserOrgList = data.filter(org => feature.listOfEnabledOrganizations.indexOf(org.AdviserOrganizationId) === -1);
          feature.adviserOrgList = adviserOrgList;
        });
    }, 1000);
  }

  showSearchAdviser(feature, bool) {
    feature.showSearchAdviserPanel = bool;
    this.showAdviserOrgList = false;
  }

  selectAdviserOrg(feature, adviserOrg) {
    if (!adviserOrg) return;
    this.showAdviserOrgList = false;
    const { AdviserOrganizatioName } = adviserOrg;
    feature.searchAdviserOrgText = AdviserOrganizatioName;
    this.selectedAdviserOrg = adviserOrg;
  }

  grantAccess(featureId) {
    if (!this.selectedAdviserOrg || !objectLength(this.selectedAdviserOrg)) {
      toastr.error('Please select an Adviser Org', 'Ooops');
      return;
    }

    const { AdviserOrganizationId, AdviserOrganizatioName } = this.selectedAdviserOrg;

    const accessModel = {
      FeatureManagerId: 0,
      BrokerBrandingId: AdviserOrganizationId,
      FeatureId: featureId,
      Name: AdviserOrganizatioName,
      IsEnabled: true,
    };
    this.corporateService.postFeatureAccess(accessModel)
      .then(() => {
        toastr.success('Successfully added!', 'Access Added');
        this.getFeatures();
      });
  }

  deleteAccess(featureManagerId) {
    swal({
      title: 'Delete Confirmation',
      text: `Are you sure want to delete this access?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, Delete Access',
      closeOnConfirm: true,
    }, (response) => {
      if (!response) return;
      this.corporateService.deleteFeatureAccess(featureManagerId)
        .then(() => {
          toastr.success('Successfully deleted!', 'Deleted');
          this.getFeatures();
        });
    });
  }

  $onDestroy() {
    this.$timeout.cancel(this.searchAdviserOrgTimeout);
  }
}
