export const ASSET_INSURANCE_DETAILS = {
  INSURER: {
    QUESTION_ID: 160,
    QUESTION_ID_NZ: 156,
  },
  INSURANCE_BROKER: {
    QUESTION_ID: 161,
    QUESTION_ID_NZ: 157,
  },
  POLICY_NUMBER: {
    QUESTION_ID: 162,
    QUESTION_ID_NZ: 158,
  },
  EXPIRY_DATE: {
    QUESTION_ID: 163,
    QUESTION_ID_NZ: 159,
  },
};
