import angular from 'angular';
import _ from 'lodash';
import { mergeFamilyProfileBuilderForUI } from 'Common/mappers/contact';

class MergeContactsService {
  constructor($q, contactService, contactModelService) {
    'ngInject';

    this.$q = $q;
    this.contactService = contactService;
    this.contactModelService = contactModelService;
  }

  checkClientsMergeability(clients, mainFamilyId) {
    const defer = this.$q.defer();
    const mergeability = {};

    if (clients && clients.length === 2) {
      const client1 = clients[0];
      const client2 = clients[1];

      if (!client1 || !client2) {
        defer.resolve(mergeability);
      } else if (client1.Adviser !== client2.Adviser) {
        mergeability.warning = 'Selected Contacts have different Advisers. Merging is not possible.';
        defer.resolve(mergeability);
      } else if (client1.FamilyType === 'Entity' || client2.FamilyType === 'Entity') {
        mergeability.warning = 'One or more of the selected Contacts are entities. Merging is not possible.';
        defer.resolve(mergeability);
      } else {
        this.checkFamilyInfoMergeability(client1, client2, mainFamilyId).then(response => defer.resolve(response.mergeability));
      }
    } else {
      mergeability.mergeable = false;
      return defer.resolve(mergeability);
    }

    return defer.promise;
  }

  checkFamilyInfoMergeability(client1, client2, mainFamilyId) {
    const defer = this.$q.defer();

    if (client1.FamilyInfo && client1.FamilyInfo.length && client2.FamilyInfo && client2.FamilyInfo.length) {
      defer.resolve({ mergeability: this.getFamilyInfoMergeability([client1.FamilyInfo, client2.FamilyInfo]) });
    } else {
      const promises = [
        this.contactService.familyInfoGet(client1.FamilyID),
        this.contactService.familyInfoGet(client2.FamilyID),
      ];
      this.$q.all(promises).then((responses) => {
        if (responses && responses.length === 2) {
          const clientFamilyInfoArr = _.map(responses, response => response.data);
          defer.resolve({ mergeability: this.getFamilyInfoMergeability(clientFamilyInfoArr) });
        }
      });
    }

    return defer.promise;
  }

  getFamilyInfoMergeability(clientFamilyInfoArr) {
    const mergeability = {};
    if (this.hasValidNumberOfAdults(clientFamilyInfoArr)) {
      mergeability.mergeable = true;
    } else {
      mergeability.mergeable = false;
      mergeability.warning = 'Selected contacts don\'t have the same number of adults. Merging is not possible.';
    }
    return mergeability;
  }

  getAdultsCount(familyInfo) {
    let count = 0;
    if (familyInfo && familyInfo.length > 1) {
      _.each(familyInfo, (person) => {
        if (person.Role === 'Adult') count++;
      });
    } else {
      count = 1;
    }
    return count;
  }

  getParamValue(name, url) {
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    return results && results.length === 3 && results[2] ? results[2].replace(/\+/g, ' ') : null;
  }

  hasValidNumberOfAdults(clientFamilyInfoArr) {
    if (clientFamilyInfoArr && clientFamilyInfoArr.length === 2) {
      const familyInfo1 = clientFamilyInfoArr[0];
      const familyInfo2 = clientFamilyInfoArr[1];
      return this.getAdultsCount(familyInfo1) === this.getAdultsCount(familyInfo2);
    }
    return false;
  }

  doAllHaveChildren(clientFamilyInfoArr) {
    return _.findIndex(clientFamilyInfoArr, (info) => {
      return !this.hasChildren(info);
    }) === -1;
  }

  duplicateClientHasChildren(mainFamilyId, clientFamilyInfoResponseArr) {
    const duplicateClientFamilyInfo = _.find(clientFamilyInfoResponseArr, (response) => {
      const familyId = Number(this.getParamValue('familyId', response.config.url));
      return familyId !== Number(mainFamilyId);
    });

    return duplicateClientFamilyInfo && this.hasChildren(duplicateClientFamilyInfo.data);
  }

  hasChildren(familyInfo) {
    return familyInfo && _.findIndex(familyInfo, person => person.Role && person.Role.toLowerCase() === 'child') !== -1;
  }

  initializeClientsToMergeFamilyInfo(clientsToMerge) {
    const defer = this.$q.defer();
    if (!clientsToMerge || clientsToMerge.length !== 2) return defer.promise;

    const promises = [
      this.contactService.familyInfoGet(clientsToMerge[0].FamilyID),
      this.contactService.familyInfoGet(clientsToMerge[1].FamilyID),
    ];
    this.$q.all(promises).then((responses) => {
      _.each(responses, (response) => {
        if (response.data) {
          const requestFamilyId = this.getParamValue('familyId', response.config.url);
          const client = clientsToMerge.find(client => client.FamilyID === requestFamilyId);
          client.FamilyInfo = response.data;
        }
      });
      return defer.resolve(clientsToMerge);
    });

    return defer.promise;
  }

  mergeDuplicateContacts(clientsToMerge) {
    const mainClient = clientsToMerge && clientsToMerge.length === 2 ? clientsToMerge.find(client => client.keepRecords) : null;

    if (mainClient) {
      const duplicateClient = clientsToMerge.find(client => !client.keepRecords);
      return this.getAdultsCount(mainClient.FamilyInfo) === 2 ?
        this.mergeCoupleDuplicateContacts(mainClient, duplicateClient) :
        this.mergeSingleAdultDuplicateContacts(mainClient, duplicateClient);
    }

    const defer = this.$q.defer();
    return defer.promise;
  }

  mergeSingleAdultDuplicateContacts(mainClient, duplicateClient) {
    const defer = this.$q.defer();
    const { FamilyID } = mainClient;
    this.contactService.mergeClients(mainClient.FamilyID, duplicateClient.FamilyID).then(response => defer.resolve({ data: { FamilyID } }), error => defer.reject(error));
    return defer.promise;
  }

  mergeCoupleDuplicateContacts(mainClient, duplicateClient) {
    const defer = this.$q.defer();
    const mainClientAdults = mainClient.FamilyInfo.filter(person => person.Role === 'Adult');
    const duplicateClientAdults = duplicateClient.FamilyInfo.filter(person => person.Role === 'Adult');
    const { FamilyID: mergeToFamilyId, FamilyID: primaryFamilyID } = mainClient;
    const data = {
      mergeToFamilyId,
      primaryFamilyID,
      mergeFromFamilyId: duplicateClient.FamilyID,
      mergedToClientID1: mainClientAdults[0].PersonId,
      victimClientID1: duplicateClientAdults[0].PersonId,
      mergedToClientID2: mainClientAdults[1].PersonId,
      victimClientID2: duplicateClientAdults[1].PersonId,
    };

    const { FamilyID } = mainClient;
    this.contactService.mergeClientsWithSecondary(data).then(response => defer.resolve({ data: { FamilyID } }), error => defer.reject(error));
    return defer.promise;
  }

  mergeFamilyContacts(clientsToMerge) {
    const mainClient = clientsToMerge && clientsToMerge.length === 2 ? clientsToMerge.find(client => client.keepRecords) : null;
    if (!mainClient) return;

    const duplicateClient = clientsToMerge.find(client => !client.keepRecords);
    const { FamilyID: mergeToFamilyId } = mainClient;
    const { FamilyID: mergeFromFamilyId } = duplicateClient;

    const params = { mergeToFamilyId, mergeFromFamilyId };
    return this.contactModelService.postMergeFamilyProfile(params)
      .then(res => mergeFamilyProfileBuilderForUI(res.data, mergeToFamilyId));
  }
}

angular.module('app').factory('mergeContactsService', ($q, contactService, contactModelService) => {
  return new MergeContactsService($q, contactService, contactModelService);
});
