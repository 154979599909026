import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { formatRawNewLine } from 'Common/utilities/string';

angular.module('app').controller('CorporateSendSmsCtrl', function CorporateSendSmsCtrl(
  $scope,
  $uibModal,
  contactService,
  SweetAlert,
  dashboardService,
  corporateService,
  templateService,
  mailTemplateService,
  $rootScope,
  toaster,
  $state,
  $window,
  contactSharedDataService,
  insuranceQuoteService,
  uiService
) {
  $scope.smsModel = {};
  $scope.smsModel.selectedContacts = [];
  $scope.insurerListOfPolicy = [];

  $scope.loginUseInfo = {};
  $scope.templates = {};
  $scope.attachedFile = [];
  $scope.clientLoansList = [];
  $scope.isFromContact = false;
  $scope.templateForInusrancePolicy = '';
  $scope.familyId = $state.params.familyId;
  $scope.contactType = $state.params.contactType;
  $scope.isFromContact = $state.params.isFromContact === 'true';

  const getEmailTemplates = () => {
    corporateService.getEmailTemplates(0).then((response) => {
      $scope.emailTemplateListing = _.filter(templateService.filterTemplatesByUserType(response.data), folder => !templateService.isBlankFolderOrTemplate(folder));
    });
  };
  getEmailTemplates();

  $scope.templates.selectedTemplate = {
    UserTemplateID: 0,
    UserTemplateName: 'Blank',
  };
  $scope.counter = 0;
  let recnoCount = 1;
  const pushContactNumber = (data) => {
    if ((data.FirstName || data.LastName) && data.MobilePhone) {
      const obj = {};
      const familyId = data.FamilyId || data.FamilyID;
      obj.FamilyId = familyId || 0;
      obj.DisplayName = `${data.FirstName} ${data.LastName}`;
      obj.ContactNumber = data.MobilePhone.split(' ').join('');
      obj.recno = recnoCount;
      recnoCount++;
      if (data.CountryCode && data.CountryCode.length)
        obj.CountryCode = data.CountryCode.toLowerCase();
      if (obj.ContactNumber) {
        const contactNum = parseInt(obj.ContactNumber, 10);
        if (!Number.isNaN(contactNum)) {
          obj.ContactNumber = contactNum.toString();
        }
      }
      $scope.smsModel.selectedContacts.push(obj);
    }
  };
  const corporateContactGet = (clientId, familyId) => {
    if ($scope.counter === 0) {
      contactService.contactDetailsSMSGet(clientId, familyId).then((response) => {
        if (response.data && response.data.length) {
          for (let x = 0; x < response.data.length; x++) {
            pushContactNumber(response.data[x]);
          }
        }
        $scope.counter++;
      });
    } else {
      corporateService.corporateContactGet(clientId, familyId).then((response) => {
        response.data.MobilePhone = response.data.Mobile;
        pushContactNumber(response.data);
      });
    }
  };

  // Get adviser information
  $scope.adviserDetails = [];
  corporateService.corporateContactGet($scope.clientId, $scope.familyId).then((response) => {
    $scope.adviserDetails = response.data;
  });


  $scope.setTemplateSelection = function (template) {
    const { UserTemplateContent } = template;
    if (!template) return;

    $scope.templates.selectedTemplate = template;
    UserTemplateContent && $scope.translateTemplate(UserTemplateContent, $scope.selectedLoanPolicyId);
    $scope.templateForInusrancePolicy = UserTemplateContent;
  };

  // Get user information
  contactService.getUserInfo().then((response) => {
    $scope.userInfo = response.data;
  });

  // search Client
  $scope.searchClient = function (searchString) {
    contactService.familyListGet(searchString).then((response) => {
      $scope.searchResult = response.data.FamilyList;
    });
  };

  const resetAddClientSearch = () => {
    $scope.clientSearch = '';
    $scope.searchClient($scope.clientSearch);
  };

  $scope.selectClient = function (client) {
    contactService.clientInformGet(client.FamilyID).then((response) => {
      if (response.data && response.data.length) {
        const data = _.filter(response.data, (value) => {
          if (value.Phone && value.Phone.length)
            return value.Phone.length > 0;
        });

        if (data && data.length) {
          for (let i = 0; i < data.length; i++) {
            if (data[i] && data[i].Phone && data[i].PersonId) {
              const contactsWithMobile = _.filter(data[i].Phone, (phones) => {
                return phones.Type === 'Mobile';
              });
              if (contactsWithMobile && contactsWithMobile.length) {
                corporateContactGet(data[i].PersonId, client.FamilyID);
                $scope.clientSearch = '';
                $scope.searchClient($scope.clientSearch);
                return;
              }
            } else {
              resetAddClientSearch();
              toaster.pop('error', 'Error', 'Client does not have invalid contact information');
            }
          }
        } else {
          resetAddClientSearch();
          toaster.pop('error', 'Error', 'Client selected does not have mobile number');
        }
      } else {
        toaster.pop('error', 'Error', 'There was an error in retrieving contact information.');
      }
    });
  };

  // remove recipient
  $scope.removeRecipient = function (name) {
    if (name.recno !== 1) {
      for (let i = 0; i < $scope.smsModel.selectedContacts.length; i++) {
        if ($scope.smsModel.selectedContacts[i].$$hashKey === name.$$hashKey) {
          $scope.smsModel.selectedContacts.splice(i, 1);
        }
      }
    } else toaster.pop('error', 'Error', 'Cannot remove main recipient');
  };

  corporateContactGet($scope.clientId, $scope.familyId);

  $scope.canSendSms = () => !$scope.isValidNumber($scope.smsModel.selectedContacts) || !$scope.smsModel.selectedContacts[0].ContactNumber || !$scope.smsModel.smsContent;

  $scope.sendSms = () => {
    if ($scope.smsModel.smsContent) {
      angular.forEach($scope.smsModel.selectedContacts, (value) => {
        const data = {
          FamilyId: value.FamilyId,
          MobileNumber: value.ContactNumber,
          Message: $scope.smsModel.smsContent,
        };
        contactService.sendSms(data).then((response) => {
          if (response.data > 0) {
            swal({
              title: 'Sent',
              text: 'Message has been sent.',
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Go to Communication',
              closeOnConfirm: true,
            },
            () => {
              contactSharedDataService.setCurrentTab($scope.contactType, $scope.familyId);
            });
          } else {
            swal('Sending Failed', 'Message sending failed! Please try again.', 'error');
          }
        });
      });
    } else {
      swal('Information', 'Please enter message to send', 'info');
    }
  };

  $scope.manageTemplate = () => {
    const modalInstance = templateService.openSMSTemplatesManagement(false);
    if (!modalInstance || !modalInstance.result) return;

    modalInstance.result.then((hasChanges) => {
      if (!hasChanges) return;
      $scope.smsTemplatesApi.refresh();
    });
  };

  $scope.isValidNumber = (data) => {
    let isValid = false;
    if (data.length > 0) {
      isValid = parseInt(data[0].ContactNumber, 10) > 0;
    }
    return isValid;
  };

  $scope.onTemplatesInit = (smsTemplatesApi) => {
    $scope.smsTemplatesApi = smsTemplatesApi;
  };

  $scope.applyLoanDetailsToTemplate = (loan) => {
    if (loan && $scope.smsModel.smsContent) {
      $scope.selectedLoanInvalid = false;
      $scope.smsModel.smsContent = $scope.translateTemplate($scope.smsModel.smsContent);
    }
  };

  $scope.translateTemplate = (templateContent, policyId) => {
    if (templateContent && templateContent.trim()) {
      const loan = _.find($scope.clientLoansList, item => item.LoanID === $scope.selectedLoan);
      const client = $scope.smsModel.selectedContacts[0];
      const mergeFieldData = {
        familyId: $scope.familyId,
        client,
        loan,
        templateContent,
        policyId,
        isSMS: true,
      };
      mailTemplateService.translateMailTemplate(mergeFieldData).then((response) => {
        if (!response) return;
        $scope.smsModel.smsContent = formatRawNewLine(response);
        $scope.selectedLoanInvalid = false;
      }, (error) => {
        $scope.smsModel.smsContent = formatRawNewLine(error.templateContent || templateContent);
        $scope.selectedLoanInvalid = error.data === 'No loan selected';
      });
    } else {
      $scope.smsModel.smsContent = '';
    }
  };

  const getClientLoans = () => {
    mailTemplateService.getMergeFieldLoansSelection($scope.familyId).then((response) => {
      $scope.clientLoansList = response;
    });
  };

  $scope.getIsurancePolicy = () => {
    insuranceQuoteService
      .getInsurerMergeField($scope.familyId)
      .then((response) => {
        $scope.insurerListOfPolicy = response;
      });
  };

  $scope.validateMergeFieldOption = () => {
    const hasInsurancePolicies = $scope.insurerListOfPolicy && $scope.insurerListOfPolicy.length;
    const hasLoans = $scope.clientLoansList && $scope.clientLoansList.length;
    return hasInsurancePolicies || hasLoans;
  };

  $scope.onlyForNz = () => {
    const hasInsurancePolicies = $scope.insurerListOfPolicy && $scope.insurerListOfPolicy.length;
    return hasInsurancePolicies && uiService.isCountry('New Zealand');
  };


  $scope.changeInsurancPolicy = (policyId) => {
    $scope.translateTemplate($scope.templateForInusrancePolicy, policyId);
  };


  $scope.init = () => {
    getClientLoans();
    $scope.getIsurancePolicy();
  };

  $scope.init();
});
