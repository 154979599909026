

import { jasperReportBuilderForUI } from 'Common/mappers/jasperReport';

class JasperService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Jasper';
  }
  reportTypeSettings() {
    return this.httpClient.get(`${this.apiBaseUrl}/ReportTypeSettings`)
      .then(response => response.data.map(jasperReportBuilderForUI));
  }
}

export default JasperService;

