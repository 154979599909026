export default class OnlineFactFindModal {
  constructor(
    $uibModal
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  open(settings) {
    const { loanId } = settings;

    this.props = {
      loanId,
    };

    return this.$uibModal.open({
      template: `<online-fact-find-modal
                      modal-instance='vm.modalInstance'
                      loan-id='vm.props.loanId'>
                </online-fact-find-modal>`,
      size: 'lg',
      windowClass: 'online-fact-find-form',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => this.props,
      },
    });
  }
}
