import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('InsAppCustObjectivesCtrl',
  function InsAppCustObjectivesCtrl(
    $rootScope,
    $scope,
    insuranceAppService,
    toaster,
    $state
  ) {
    angular.extend($scope, {
      insAppObjQuestionList: [],
      data: {
        insAppId: $state.params.insAppId,
        familyId: $state.params.familyId,
        sectionId: 2, // customer objectives section
      },
    });

    const getQuestions = () => {
      insuranceAppService.applicationQuestionsGet($scope.data.insAppId, $scope.sectionId).then((response) => {
        $scope.insAppObjQuestionList = response.data;
        _.each($scope.insAppObjQuestionList, (value) => {
          if (value.ValueOptions !== '') {
            value.ValueOptions = JSON.parse(value.ValueOptions);
          }
          if (value.ParentQuestionID !== 0) {
            value.showField = false;
          }
        });
      });
    };

    const applicationAnswerSet = (applicationId, questionId, answerString, answerBit, answerInt) => {
      insuranceAppService.applicationAnswerSet(applicationId, questionId, answerString, answerBit, answerInt)
        .then(() => {
        }, (error) => {
          toaster.pop('error', `Error fetching answers. ${error}`);
        });
    };

    /*
        @name: saveApplicationAnswerSet
        @desc: saves responses from user
        @param:  id: is insurance app id, answerObj: the object inject from UI
        @return: none
    */
    $scope.saveApplicationAnswerSet = (id, answerObj) => {
      const a = answerObj;
      if (typeof a.AnswerBit !== 'undefined' && !a.AnswerBit) {
        const opt = a.DisplayOptionID > 0 ? a.DisplayOptionID : 0;
        if (opt !== 2 && opt !== 9) {
          a.AnswerString = '';
        }
      }
      applicationAnswerSet(id, a.QuestionID, a.AnswerString, a.AnswerBit, a.AnswerInt);
    };

    const init = () => {
      getQuestions();
    };

    init();
  });
