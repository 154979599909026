import { sortBy } from 'lodash';

class referralDetailService {
  constructor(contactService) {
    'ngInject';

    this.contactService = contactService;
  }

  toggleOverflowTabs(hiddenTabCount) {
    this.narrowNav = '';
    if (hiddenTabCount > 0) {
      this.narrowNav = false;
    } else {
      this.narrowNav = true;
    }
    return this.narrowNav;
  }
  getReferralCategoryList() {
    return this.contactService.getReferralCategory()
      .then((response) => {
        const categoryList = sortBy(response.data, [o => o.OrderBy]);
        return categoryList
          .filter(value => value && value.CategoryName)
          .map((value, index) => {
            value.orderBy = (index + 1);
            return value;
          });
      });
  }
}

export default referralDetailService;
