import template from './template.html';
import TaskTemplatedCtrl from './taskTemplatedCtrl';
import './style.scss';

export default {
  template,
  controller: TaskTemplatedCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanId: '<',
    familyList: '<',
    familyId: '<',
  },
};
