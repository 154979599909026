import angular from 'angular';

angular.module('app').controller('BankDetailsCtrl',
  function BankDetailsCtrl($scope,
    SweetAlert,
    corporateService,
    onboardingService,
    $stateParams,
    contactService,
    queryService,
    toaster) {
    $scope.familyId = $stateParams.familyId;
    $scope.clientId = $stateParams.clientId;
    $scope.alternateBankDetails = {};
    if ($scope.familyId && $scope.clientId) {
      queryService.queryGet($scope, onboardingService, 'bankAccountDetailsGet', [$scope.familyId], () => {
      });
    }
    const _setBooleantoInt = (value) => {
      return value ? 1 : 0;
    };
    $scope.updateBankDetails = function (data) {
      if (data.BankName || data.AccountName) {
        data.isDebitedForSystemFees = _setBooleantoInt(data.isDebitedForSystemFees);
        queryService.querySet(onboardingService, 'bankAccountDetailsSet', [$scope.familyId, data],
          (response) => {
            if (response) {
              toaster.pop('success', 'Successfully Added!', 'Bank Details');
            }
          }
        );
      }
    };
  });
