import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('IdentificationCtrl', function IdentificationCtrl(
  $scope, $uibModalInstance, toaster, contactService, optionsService, modalType, familyId, passportService, identificationService
) {
  $scope.Title = (modalType === 'new' ? 'Add' : 'Edit');

  $scope.datePopUp = {
    IssueDate: false,
    ExpiryDate: false,
  };
  // Open Date Popup
  $scope.openDate = function (dateField) {
    $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
    $scope.dateModalOpen = true;
  };

  $scope.format = 'dd MMM yyyy';
  $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  $scope.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };

  $scope.cancel = function () {
    $uibModalInstance.close({ modalType, FamilyId: familyId });
  };

  $scope.clientsGet();

  optionsService.identificationDocumentsTypeGet().then((response) => {
    const data = response.data;
    $scope.DocumentsType = passportService.mapPassportValue(data);
    $scope.checkIfDocumentIsPassport = passportService.checkIfDocumentIsPassport;
  });

  angular.extend($scope.ClientEntityList, $scope.clientsList);

  _.map($scope.ClientEntityList, (o) => {
    o.FamilyId = $scope.familyId;
    return o;
  });

  $scope.addIdentification = (form) => {
    $scope.isSavingIdentification = true;
    if (identificationService.isIdentificationValid($scope.IdentificationSet) && form) {
      contactService.clientEntityIdentificationSet($scope.IdentificationSet).then(() => {
        $scope.cancel();
        $scope.GetIdentification();
        $scope.isSavingIdentification = false;
      });
    } else {
      $scope.isSavingIdentification = false;
    }
  };
});
