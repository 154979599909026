import angular from 'angular';

angular.module('app').directive('validNumber', function validNumber() {
  return {
    require: '?ngModel',
    link(scope, element, attrs, ngModelCtrl) {
      if (!ngModelCtrl) {
        return;
      }

      // formatter
      /* ngModelCtrl.$parsers.unshift(function (viewValue) {
        var plainNumber = viewValue.replace(/[^\d|\-+|\.+]/g, '');
        element.val($filter('number')(plainNumber));
        return plainNumber;
      }) */

      ngModelCtrl.$parsers.push((pVal) => {
        let val = pVal;
        if (angular.isUndefined(val)) {
          val = '';
          return undefined;
        }
        let clean = val.replace(/[^0-9.]/g, '');
        const decimalCheck = clean.split('.');

        if (!angular.isUndefined(decimalCheck[1])) {
          decimalCheck[1] = decimalCheck[1].slice(0, 2);
          clean = `${decimalCheck[0]}.${decimalCheck[1]}`;
        }

        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });

      // no zero at first
      ngModelCtrl.$parsers.push((text) => {
        if (text) {
          const transformedInput = text.replace(/^(0*)/g, '');
          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }
          return transformedInput;
        }
        return undefined;
      });

      element.bind('keypress', (event) => {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
      });
    },
  };
});
