
app.factory('wmComplianceService', ['$q', 'wmComplianceModel', function ($q, wmComplianceModel) {
  var factory = this;
  var wmCompliance = new wmComplianceModel();

  factory.appointmentDetailGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.AppointmentDetailGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.appointmentDetailSet = function (familyID, appointedAsId, splitsId, authLevelId, citizenshipStatusId, recruitmentSource, adviserAdviseOSUserName, authorizationTypeId, AFSL) {
    var defer = $q.defer();
    wmCompliance.AppointmentDetailSet(familyID, appointedAsId, splitsId, authLevelId, citizenshipStatusId, recruitmentSource, adviserAdviseOSUserName, authorizationTypeId, AFSL, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.appointedAsGet = function () {
    var defer = $q.defer();
    wmCompliance.AppointedAsGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.authorizationTypeGet = function () {
    var defer = $q.defer();
    wmCompliance.AuthorizationTypeGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.citizenshipStatusGet = function () {
    var defer = $q.defer();
    wmCompliance.CitizenshipStatusGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.employmentDetailGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.EmploymentDetailGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.employmentDetailSet = function (familyID, employmentRoleId, employmentStartDate, employmentEndDate, resignationDate, reasonForLeaving, reHire, educationProvider, highestLevelCompleted) {
    var defer = $q.defer();
    wmCompliance.EmploymentDetailSet(familyID, employmentRoleId, employmentStartDate, employmentEndDate, resignationDate, reasonForLeaving, reHire, educationProvider, highestLevelCompleted, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.employmentRoleGet = function () {
    var defer = $q.defer();
    wmCompliance.EmploymentRoleGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.educationDetailGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.EducationDetailGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.educationDetailSet = function (familyID, HSC_or_Equivalent, relatedDegree, unRelatedDegree, details) {
    var defer = $q.defer();
    wmCompliance.EducationDetailSet(familyID, HSC_or_Equivalent, relatedDegree, unRelatedDegree, details, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.qualificationDetailsGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.QualificationDetailsGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.qualificationDetailsSet = function (familyID, pWMQualificationIDs, pSpecialistCourseID, otherQualifications, pWMAdviceTypeIDs) {
    var defer = $q.defer();
    wmCompliance.QualificationDetailsSet(familyID, pWMQualificationIDs, pSpecialistCourseID, otherQualifications, pWMAdviceTypeIDs, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };


  factory.qualificationsGet = function () {
    var defer = $q.defer();
    wmCompliance.QualificationsGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.specialistCourseGet = function () {
    var defer = $q.defer();
    wmCompliance.SpecialistCourseGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.adviceTypeGet = function () {
    var defer = $q.defer();
    wmCompliance.AdviceTypeGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.recentAuditGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.RecentAuditGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.recentAuditResultSet = function (familyID, auditRating, recommendedForPreVet, noSOAsToBeReviewed, SOAsReviewed, remediationRequired, remediationRequiredDate, recommendedForParaPlanning, paraPlanningStartDate, paraPlanningEndDate, last5YearsAdviserHistory, auditDate) {
    var defer = $q.defer();
    wmCompliance.RecentAuditResultSet(familyID, auditRating, recommendedForPreVet, noSOAsToBeReviewed, SOAsReviewed, remediationRequired, remediationRequiredDate, recommendedForParaPlanning, paraPlanningStartDate, paraPlanningEndDate, last5YearsAdviserHistory, auditDate, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };


  factory.stdPreVetGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.StdPreVetGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.stdPreVetSet = function (familyID, newAdviser, noStdSOAsToBeReviewed, preVetStatus, preVetComments) {
    var defer = $q.defer();
    wmCompliance.StdPreVetSet(familyID, newAdviser, noStdSOAsToBeReviewed, preVetStatus, preVetComments, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };


  factory.specialistAdviceSRGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.SpecialistAdviceSRGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.specialistAdviceSRSet = function (familyID, ETF_SR, directEquity_SR, marginLending_SR, SMSF_SR) {
    var defer = $q.defer();
    wmCompliance.SpecialistAdviceSRSet(familyID, ETF_SR, directEquity_SR, marginLending_SR, SMSF_SR, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.generalGet = function (familyId) {
    var defer = $q.defer();
    wmCompliance.GeneralGet(familyId, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.generalSet = function (familyID, industryMemberships, legacyBlanketApprovals, morningStarSubscription, lonsecResearchSubs) {
    var defer = $q.defer();
    wmCompliance.GeneralSet(familyID, industryMemberships, legacyBlanketApprovals, morningStarSubscription, lonsecResearchSubs, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };


  return factory;
}]);
