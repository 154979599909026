import angular from 'angular';
import _ from 'lodash';
import { LOAN_APP_SUBMISSION_METHODS } from 'Common/constants/loanAppSubmissionMethods';
import moment from 'moment';

class LoanAppSubmissionValidationService {
  constructor($q, uiService, loanSubmissionEmailContentService, loanScenarioService, loanAppSharedData) {
    this.$q = $q;
    this.uiService = uiService;
    this.loanSubmissionEmailContentService = loanSubmissionEmailContentService;
    this.loanScenarioService = loanScenarioService;
    this.loanAppSharedData = loanAppSharedData;
    this.MIN_HISTORY_IN_YRS = 3;
    this.MIN_HISTORY_IN_MONTHS = 0;
  }

  canSubmitViaEmail(lenderSubmissionSet, loanAppLenderDetailObject) {
    if (lenderSubmissionSet && lenderSubmissionSet.SubmissionMethod === LOAN_APP_SUBMISSION_METHODS.EMAIL && !this.uiService.isCountry('Australia')) {
      const lenderEmailAddrValid = lenderSubmissionSet.LenderEmailAddress;
      const clientEmailAddrValid = !lenderSubmissionSet.CopyToClient || lenderSubmissionSet.ClientEmailAddress;
      const adviserEmailAddrValid = !lenderSubmissionSet.CopyToAdviser || lenderSubmissionSet.AdviserEmailAddress;
      const recipientsValid = !this.uiService.isCountry('New Zealand') || (
        lenderEmailAddrValid && clientEmailAddrValid && adviserEmailAddrValid);
      const lenderValid = loanAppLenderDetailObject && loanAppLenderDetailObject.LenderId;
      const lenderEmailValid = lenderSubmissionSet.CustomEmailSubject && lenderSubmissionSet.CustomEmailContent;
      const clientEmailValid = !lenderSubmissionSet.CopyToClient || (
        lenderSubmissionSet.ApplicantEmailSubject && lenderSubmissionSet.ApplicantEmailBody
      );

      return recipientsValid && lenderValid && lenderEmailValid && clientEmailValid;
    }

    return false;
  }

  canSubmitViaPrintedApp(lenderSubmissionSet, loanAppLenderDetailObject) {
    if (lenderSubmissionSet && lenderSubmissionSet.SubmissionMethod === LOAN_APP_SUBMISSION_METHODS.PRINT && !this.uiService.isCountry('Australia')) {
      return !this.uiService.isCountry('New Zealand') || (loanAppLenderDetailObject && loanAppLenderDetailObject.LenderId);
    }
  }

  canSubmitViaSimpology(lenderSubmissionSet, loanAppLenderDetailObject) {
    return lenderSubmissionSet && lenderSubmissionSet.SubmissionMethod === LOAN_APP_SUBMISSION_METHODS.SIMPOLOGY &&
    loanAppLenderDetailObject && loanAppLenderDetailObject.LenderId === this.loanAppSharedData.simpology.bnzId;
  }

  checkClientEmailAddressState(loanAppId, familyId, lenderSubmissionQuestion, lenderSubmissionSet, isRefresh) {
    const deferred = this.$q.defer();
    if (this.isValidLenderSubmissionQuestion(lenderSubmissionQuestion) && lenderSubmissionSet && !lenderSubmissionSet.ClientEmailAddress) {
      this.loanAppSharedData.getLoanAppApplicants(loanAppId, isRefresh).then((response) => {
        if (!response || !response.data || !response.data.InvolvedPartyPerson) {
          deferred.reject({ error: 'No data received' });
          return;
        }

        let defaultClientEmailAddr = '';
        _.forOwn(response.data.InvolvedPartyPerson, (value) => {
          _.each(value, (person) => {
            if (person.IsApplicant && person.Email && person.Email.length && person.Email[0] && person.Email[0].EmailAddress) {
              defaultClientEmailAddr = person.Email[0].EmailAddress;
            }
          });
        });

        if (defaultClientEmailAddr) {
          this.saveLenderSubmissionQuestion(loanAppId, familyId, lenderSubmissionQuestion, defaultClientEmailAddr).then(() => {
            lenderSubmissionSet.ClientEmailAddress = defaultClientEmailAddr;
            deferred.resolve(lenderSubmissionSet);
          });
        }
      });
    }
    return deferred.promise;
  }

  checkLenderEmailState(loanAppId, familyId, emailSubjectQuestion, emailContentQuestion, lenderSubmissionSet) {
    const deferred = this.$q.defer();
    const validSubmissionQuestions = this.isValidLenderSubmissionQuestion(emailSubjectQuestion) && this.isValidLenderSubmissionQuestion(emailContentQuestion);

    if (validSubmissionQuestions && lenderSubmissionSet) {
      this.loanSubmissionEmailContentService.getDefaultEmail(loanAppId, familyId)
        .then((email) => {
          const promises = [];
          if (email.subject) {
            promises.push(this.saveLenderSubmissionQuestion(loanAppId, familyId, emailSubjectQuestion, email.subject));
          }
          if (email.content) {
            promises.push(this.saveLenderSubmissionQuestion(loanAppId, familyId, emailContentQuestion, email.content));
          }
          if (promises.length) {
            this.$q.all(promises).then(() => {
              lenderSubmissionSet.CustomEmailSubject = email.subject || lenderSubmissionSet.CustomEmailSubject;
              lenderSubmissionSet.CustomEmailContent = email.content || lenderSubmissionSet.CustomEmailContent;
              deferred.resolve(lenderSubmissionSet);
            });
          } else {
            deferred.resolve(lenderSubmissionSet);
          }
        });
    } else {
      deferred.reject({ data: 'Invalid arguments' });
    }

    return deferred.promise;
  }

  checkClientEmailState(clientEmail, loanAppId) {
    const deferred = this.$q.defer();
    if (!clientEmail) return deferred.promise;

    clientEmail.onCreated(() => {
      const { Subject: subject, Body: content, SubjectQuestion: emailSubjectQuestion, BodyQuestion: emailContentQuestion } = clientEmail;
      const loanSubmissionEmail = {
        loanAppId,
        familyId: clientEmail.clientFamilyId,
        emailSubjectQuestion,
        emailContentQuestion,
        subject,
        content,
      };
      this.saveSubmissionEmail(deferred, loanSubmissionEmail);
    });
    return deferred.promise;
  }

  saveSubmissionEmail(deferred, loanSubmissionEmail) {
    const promises = [];
    const { loanAppId, familyId, emailSubjectQuestion, emailContentQuestion, subject, content } = loanSubmissionEmail;
    if (subject) {
      promises.push(this.saveLenderSubmissionQuestion(loanAppId, familyId, emailSubjectQuestion, subject));
    }
    if (content) {
      promises.push(this.saveLenderSubmissionQuestion(loanAppId, familyId, emailContentQuestion, content));
    }
    if (promises.length) {
      this.$q.all(promises).then(() => {
        const lenderSubmission = {};
        lenderSubmission.EmailSubject = subject;
        lenderSubmission.EmailContent = content;
        deferred.resolve(lenderSubmission);
      });
    }
  }

  isValidLenderSubmissionQuestion(question) {
    this.isQuestionValid = question && question.QuestionId && question.QuestionDisplayTypeId;
    return this.isQuestionValid;
  }

  isValidLenderSubmissionCustomEmail(lenderSubmission) {
    this.isLenderSubmissionValid = lenderSubmission && lenderSubmission.CustomEmailSubject && lenderSubmission.CustomEmailContent;
    return this.isLenderSubmissionValid;
  }

  isValidAddressHistory(addressHistory) {
    if (this.uiService.isCountry('New Zealand')) {
      return this.getInvalidAddrHistoryForNZ(addressHistory);
    }
    return this.getInvalidAddrHistory(addressHistory);
  }

  getInvalidAddrHistoryForNZ(addressHistory) {
    this.isAddressHistoryEmpty = (!addressHistory || !addressHistory.length);
    return this.isAddressHistoryEmpty ? [{ familyId: 0, name: 'All of the applicants' }] : [];
  }

  getInvalidAddrHistory(addressHistory) {
    if (!addressHistory || !addressHistory.length) {
      return [{ familyId: 0, name: 'All of the applicants' }];
    }

    const invalidHistoryList = [];
    addressHistory.forEach((history) => {
      const totalAddrHistoryInMonths = history.Address && history.Address.length && history.Address
        .reduce((accum, addr) => {
          const start = moment(addr.StartDate);
          const end = addr.EndDate ? moment(addr.EndDate) : moment();
          const diff = end.diff(start, 'months');
          accum += diff;
          return accum;
        }, 0);
      if (totalAddrHistoryInMonths < this.MIN_HISTORY_IN_MONTHS) {
        invalidHistoryList.push({ familyId: history.FamilyId, name: history.FamilyName });
      }
    });
    return invalidHistoryList;
  }

  getMainFamilyFromInvolvedParties(involvedPartyPerson) {
    this.isInvolvedPartyPersonValid = involvedPartyPerson;
    if (this.isInvolvedPartyPersonValid) {
      let mainFamily;
      _.forOwn(this.isInvolvedPartyPersonValid, (value, key) => {
        if (value) {
          mainFamily = value;
          return false;
        }
      });
      return mainFamily;
    }
  }

  saveLenderSubmissionQuestion(loanAppId, familyId, lenderSubmissionQuestion, value) {
    const deferred = this.$q.defer();
    if (lenderSubmissionQuestion) {
      const answer = value ? value.toString() : '';
      const { QuestionId, QuestionDisplayTypeId: DisplayOptionId } = lenderSubmissionQuestion;
      const lenderSubmissionObj = {
        LoanScenarioId: loanAppId,
        FamilyId: familyId,
        QuestionId,
        DisplayOptionId,
        answerString: [answer],
      };
      this.loanScenarioService.lenderSubmissionSet(lenderSubmissionObj).then(response => deferred.resolve(response));
    } else {
      deferred.reject({ data: 'Invalid arguments' });
    }
    return deferred.promise;
  }

  sendEmailHasError(sendEmailResponse) {
    this.isSendEmailResponseValid = sendEmailResponse;
    if (!this.isSendEmailResponseValid || !this.isSendEmailResponseValid.data) return false;
    return !!this.isSendEmailResponseValid.data.find(remarks => _.some(remarks, value => value === 'Error'));
  }
}

angular.module('app').factory('loanAppSubmissionValidationService', ($q, uiService, loanSubmissionEmailContentService, loanScenarioService, loanAppSharedData) => new LoanAppSubmissionValidationService($q, uiService, loanSubmissionEmailContentService, loanScenarioService, loanAppSharedData));
