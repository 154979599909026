import angular from 'angular';
import pipeline from './pipeline';
import pipelineKanban from './pipelineKanban';
import pipelineKanbanColumn from './pipelineKanban/pipelineKanbanColumn';
import opportunityCard from './pipelineKanban/pipelineKanbanColumn/opportunityCard';
import contactNameCell from './pipelineTable/contactNameCell';
import statusDropdown from './pipelineTable/statusDropdown';
import statusDropdownLeads from './pipelineTable/statusDropdownLeads';
import pipelineTable from './pipelineTable';
import pipelineDropboxOpportunity from './pipelineKanban/pipelineDropboxOpportunity';
import pipelineDropboxLead from './pipelineKanban/pipelineDropboxLead';

export default angular.module('components.pipeline', [])
  .component('pipeline', pipeline)
  .component('pipelineKanban', pipelineKanban)
  .component('pipelineKanbanColumn', pipelineKanbanColumn)
  .component('opportunityCard', opportunityCard)
  .component('contactNameCell', contactNameCell)
  .component('statusDropdown', statusDropdown)
  .component('statusDropdownLeads', statusDropdownLeads)
  .component('pipelineDropboxOpportunity', pipelineDropboxOpportunity)
  .component('pipelineDropboxLead', pipelineDropboxLead)
  .component('pipelineTable', pipelineTable)
  .name;
