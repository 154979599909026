import angular from 'angular';

angular.module('app').filter('ordinal', function ordinal() {
  return function (input) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = input % 100;
    return input + (s[(v - 20) % 10] || s[v] || s[0]);
  };
}
);
