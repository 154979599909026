import template from './lenderLogo.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    lenderName: '<',
    lenderId: '<',
    countryCode: '<',
    shownOnError: '<',
    width: '<',
    height: '<',
  },
};
