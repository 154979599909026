import angular from 'angular';
import moment from 'moment';

angular.module('app').controller('EducationModalCtrl', function EducationModalCtrl(
  $uibModalInstance,
  loanToolsService,
  educationId,
  toaster
) {
  const vm = this;
  /* Models */
  vm.dataObject = [];
  vm.isAdd = [];
  // date taken models
  vm.format = 'dd MMM yyyy';
  vm.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  vm.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };
  vm.isDateTakenOpen = false;

  const saveCPD = (isValid) => {
    if (isValid) {
      vm.isSaving = true;
      const objectToSave = {
        EducationID: vm.dataObject.EductionID,
        CourseTitle: vm.dataObject.CourseTitle,
        StructuredHours: vm.dataObject.StructuredHours,
        UnStructuredHours: vm.dataObject.UnStructuredHours,
        Provider: vm.dataObject.Provider,
        DateTaken: vm.dataObject.DateTaken ? moment(vm.dataObject.DateTaken, 'YYYY-MM-DD ').format('YYYY-MM-DD') : '',
        CertificateReceived: vm.dataObject.CertificateReceived,
        Notes: vm.dataObject.Notes,
      };
      loanToolsService.resourceEducationSet(objectToSave)
        .then(() => {
          loanToolsService.resourceEducationGet(0).then((response) => {
            const theResponse = response.data;

            toaster.pop('success', 'Sucess', 'CPD saved');
            $uibModalInstance.close(theResponse);
          });
        });
    }
  };

  const dateTakenClicked = () => {
    vm.isDateTakenOpen = !vm.isDateTakenOpen;
  };

  const initProcessForAdd = () => {
    vm.dataObject = {
      EductionID: 0,
    };
  };

  const initProcessForEdit = (paramEducationId) => {
    loanToolsService.resourceEducationGet(paramEducationId).then((response) => {
      const apiResponse = response.data;

      if (apiResponse && apiResponse.length > 0) {
        vm.dataObject = {
          EductionID: apiResponse[0].EductionID,
          CourseTitle: apiResponse[0].CourseTitle,
          StructuredHours: apiResponse[0].StructuredHours,
          UnStructuredHours: apiResponse[0].UnStructuredHours,
          Provider: apiResponse[0].Provider,
          DateTaken: apiResponse[0].DateTaken ? new Date(apiResponse[0].DateTaken) : '',
          CertificateReceived: apiResponse[0].CertificateReceived,
          Notes: apiResponse[0].Notes,
        };
      }
    });
  };

  const checkIfAdd = () => {
    let toReturnValue;

    if (educationId === null) {
      toReturnValue = true;
    } else {
      toReturnValue = false;
    }
    return toReturnValue;
  };

  const cancel = () => {
    $uibModalInstance.dismiss();
  };

  const init = () => {
    vm.isAdd = checkIfAdd();

    if (vm.isAdd) {
      initProcessForAdd();
    } else {
      initProcessForEdit(educationId);
    }
  };

  vm.saveCPD = saveCPD;
  vm.dateTakenClicked = dateTakenClicked;
  vm.cancel = cancel;
  vm.isSaving = false;

  init();
});
