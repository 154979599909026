import CONTACT_REQUESTER from 'Common/constants/addNewContactRequesters';
import { ENTITY_PARTY_TYPES, PERSON_PARTY_TYPES } from 'Common/constants/partyTypes';
import { filter, forEach, findIndex } from 'lodash';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { filterClientList } from 'Common/utilities/client';

class newLoanApplicationCtrl {
  constructor(
    uiService,
    $uibModal,
    userService,
    corporateService,
    generalService,
    loanApplicationServices,
    toaster,
    $scope,
    $state,
    $rootScope,
    broadcastDataService,
    dashboardService,
    $document,
    $timeout,
    contactService,
    utilitiesService,
    loanScenarioService,
    loanAppDetailsService
  ) {
    'ngInject';

    this.uiService = uiService;
    this.$uibModal = $uibModal;
    this.userService = userService;
    this.corporateService = corporateService;
    this.generalService = generalService;
    this.loanApplicationServices = loanApplicationServices;
    this.toaster = toaster;
    this.$scope = $scope;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.broadcastDataService = broadcastDataService;
    this.dashboardService = dashboardService;
    this.$document = $document;
    this.$timeout = $timeout;
    this.contactService = contactService;
    this.utilitiesService = utilitiesService;
    this.loanScenarioService = loanScenarioService;
    this.loanAppDetailsService = loanAppDetailsService;

    this.REQUESTER_TYPE = CONTACT_REQUESTER.REQUESTER_TYPE;
    this.SHOW_EXISTING_PANEL = CONTACT_REQUESTER.SHOW_EXISTING_PANEL;
  }

  $onInit() {
    this.adviserFamilyId = 0;
    this.resetAdviser();
    this.getAdvisersList();

    this.formData = {
      borrower: [],
      guarantor: [],
    };
    this.displayFamily = {
      borrower: [],
      guarantor: [],
    };
    this.displayCompany = {
      borrower: [],
    };
    this.allocatedAdviserList = [];
    this.assistantAdvisersList = [];
    this.AssignedAdviserId = 0;
    this.userInfo = {};
    this.showAdvisers = false;
    this.selectedAdviser = null;
    this.showAdviserLabel = false;
    this.saveDisabled = false;
    this.showThisPanel = 0;
    this.showClientList = false;
    this.searchClientName = '';
    this.clientList = [];
    this.existingFamilies = [];
    this.excludedIds = [];
    this.isAdviserAccess = false;
    this.isAddingDisabled = false;

    this.checkUserInfo();
  }

  back() {
    this.modalInstance.dismiss('cancel');
  }

  companyModal(partyType, requestType) {
    const modalInstance = this.openCompanyModal(null, partyType);

    modalInstance.result
      .then((response) => {
        const { FamilyId, FamilyID } = response;
        const entityId = FamilyID || FamilyId;
        if (requestType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER) {
          this.getEntityInformation(entityId, CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER);
        } else {
          this.getEntityInformation(entityId, CONTACT_REQUESTER.REQUESTER_TYPE.GUARANTOR);
        }
      });
  }

  editCompany(requestType, entity) {
    const partyType = ENTITY_PARTY_TYPES.COMPANY;
    const modalInstance = this.openCompanyModal(entity, partyType);

    modalInstance.result
      .then((response) => {
        const { FamilyId } = response;
        this.getEntityInformation(FamilyId, requestType, true);
      });
  }

  openCompanyModal(entity, partyType) {
    return this.$uibModal.open({
      templateUrl: '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
      controller: 'AddCompanyRelatedModalCtrl',
      size: 'md',
      resolve: {
        selectedPartyType() {
          return String(partyType);
        },
        person() {
          return entity;
        },
        requester() {
          return CONTACT_REQUESTER.REQUESTER.NEW_LOAN_APP_MODAL;
        },
      },
      backdrop: 'static',
      keyboard: false,
    });
  }

  createLoanApp() {
    if (!this.selectedAdviser) {
      this.toaster.pop({
        type: 'error',
        body: 'Please choose an Adviser',
      });
      return false;
    }
    const { borrower, guarantor } = this.formData;
    const borrowerList = this.convertFormDataToAPIData(borrower);
    if (!borrowerList.length) {
      this.toaster.pop({
        type: 'error',
        body: 'Please fill at least 1 Borrower. ',
      });
      return false;
    }

    const guarantorList = this.convertFormDataToAPIData(guarantor);
    const sendParam = {
      AllocatedAdviserId: this.selectedAdviser.AdviserFamilyId,
      Title: 'Loan Application',
      Borrowers: borrowerList,
      Guarantors: guarantorList,
    };
    this.saveDisabled = true;
    this.postLoanApplication(sendParam);
  }

  removeFromExistingFamilies(FamilyId) {
    this.existingFamilies = this.existingFamilies.filter((val) => {
      return parseInt(val, 10) !== parseInt(FamilyId, 10);
    });
  }

  removeFamily(requestType, family) {
    if (!family || !family[0]) return;
    const familyId = family[0].FamilyId;
    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      const index = findIndex(this.displayFamily.borrower, obj => obj === family);
      this.displayFamily.borrower.splice(index, 1);
    } else {
      const index = findIndex(this.displayFamily.guarantor, obj => obj === family);
      this.displayFamily.guarantor.splice(index, 1);
    }
    this.removeFromExistingFamilies(familyId);
    this.removeFromFamilyFormData(requestType, familyId);
  }

  removeFromFamilyFormData(requestType, FamilyId) {
    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      this.formData.borrower = filterClientList(FamilyId, this.formData.borrower);
    } else {
      this.formData.guarantor = filterClientList(FamilyId, this.formData.guarantor);
    }
  }

  removeCompany(requestType, entity) {
    const { FamilyId } = entity;
    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      this.displayCompany.borrower = filterClientList(FamilyId, this.displayCompany.borrower);
      this.formData.borrower = filterClientList(FamilyId, this.formData.borrower);
    }
    this.removeFromExistingFamilies(FamilyId);
  }

  editFamily(type, family) {
    const listApplicants = (type === PERSON_PARTY_TYPES.APPLICANT ? this.displayFamily.borrower : this.displayFamily.guarantor);
    const modalInstance = this.openApplicantModal(family[0], type, listApplicants);

    modalInstance.result
      .then((response) => {
        const { FamilyId, excludedIds, partyApplicants } = response;

        this.updateFamilyList(partyApplicants, FamilyId, family);

        const requesterType = (type === PERSON_PARTY_TYPES.APPLICANT ? this.REQUESTER_TYPE.BORROWER : this.REQUESTER_TYPE.GUARANTOR);
        this.removeFromFamilyFormData(requesterType, FamilyId);
        this.getClientInformation(response, requesterType, false);
        this.spliceFamilyDisplayList(family, excludedIds);
      });
  }

  spliceFamilyDisplayList(family, excludedIds) {
    if (excludedIds && excludedIds.length) {
      const index = findIndex(family, o => o.PersonId === excludedIds[0]);
      family.splice(index, 1);
      this.excludedIds.push(excludedIds[0]);
    } else {
      forEach(family, (o) => {
        const index = this.excludedIds.indexOf(o.PersonId);
        if (index === -1) return;
        this.excludedIds.splice(index, 1);
      });
    }
  }

  updateFamilyList(partyApplicants, FamilyID, family) {
    let idx = 0;

    forEach(partyApplicants, (member) => {
      const memberData = this.clientDisplayListModel(FamilyID, member);
      family.splice(idx, 1, memberData);
      idx++;
    });
  }


  personModal(type = 1, requestType, assignedAdviserIdFromRequester) {
    this.showAddExistingPanel(0);
    const person = null;
    const modalInstance = this.openApplicantModal(person, type, null, assignedAdviserIdFromRequester);

    modalInstance.result
      .then((response) => {
        this.getClientInformation(response, requestType, false, true);
      });
  }

  openApplicantModal(person, type, listApplicants, assignedAdviserIdFromRequester) {
    return this.$uibModal.open({
      templateUrl: '/assets/views/loanApplication/clientAssess/modal/addApplicants_v2.html',
      controller: 'ApplicantModalCtrl',
      size: 'lg',
      resolve: {
        selectedPartyType() {
          return type.toString();
        },
        type() {
          return type.toString();
        },
        person() {
          return person;
        },
        requester() {
          return CONTACT_REQUESTER.REQUESTER.NEW_LOAN_APP_MODAL;
        },
        listApplicants() {
          return listApplicants;
        },
        assignedAdviserIdFromRequester() {
          return parseInt(assignedAdviserIdFromRequester, 10);
        },
      },
      backdrop: 'static',
      keyboard: false,
    });
  }

  searchClient(searchType) {
    this.clientList = [];
    this.$timeout.cancel(this.searchTimeOut);
    const isSearchParamsValid = this.searchClientName.length >= 3 && this.selectedAdviser && this.selectedAdviser.AdviserFamilyId;
    if (isSearchParamsValid) {
      this.searchTimeOut = this.$timeout(() => {
        this.loanScenarioService.scenarioSearchClientGet(this.searchClientName, searchType)
          .then((response) => {
            if (!response || !response.data) return;
            const { data: familyList } = response;
            this.showClientList = true;
            if (familyList.length) {
              const clientList = familyList.filter((obj) => {
                if (obj.FirstName) obj.FullName = obj.LastName ? `${obj.FirstName} ${obj.LastName}` : obj.FirstName;
                if (searchType === this.SHOW_EXISTING_PANEL.GUARANTOR.PERSON) return obj.IsClient;
                return obj;
              });
              this.clientList = clientList.length ? clientList.filter(obj => this.existingFamilies.indexOf(obj.FamilyID) === -1) : [];
            }
          })
          .catch(() => {
            this.showClientList = false;
          });
      }, 300);
    } else {
      this.showClientList = false;
    }
  }

  selectClient(clientData, requesterType) {
    if (requesterType === this.SHOW_EXISTING_PANEL.BORROWER.COMPANY) {
      this.getEntityInformation(clientData.FamilyID || clientData.FamilyId, this.REQUESTER_TYPE.BORROWER);
    } else if (requesterType === this.SHOW_EXISTING_PANEL.BORROWER.PERSON) {
      this.getClientInformation(clientData, this.REQUESTER_TYPE.BORROWER);
    } else if (requesterType === this.SHOW_EXISTING_PANEL.GUARANTOR.PERSON) {
      this.getClientInformation(clientData, this.REQUESTER_TYPE.GUARANTOR);
    }
    this.showClientList = false;
    this.showThisPanel = false;
  }

  showAddExistingPanel(panelNumber) {
    this.showThisPanel = panelNumber;
    this.searchClientName = '';
  }

  clientDisplayListModel(FamilyId, member) {
    member.FullName = `${member.FirstName} ${member.LastName}`;
    return {
      ...member,
      initials: this.utilitiesService.filterInitial(member.FirstName, member.LastName),
      FamilyId,
    };
  }

  entityDisplayListModel(FamilyId, entity) {
    return {
      ...entity,
      FamilyId,
      ClientName: entity.Name,
      ClientInitials: this.utilitiesService.filterInitialOneString(entity.Name),
    };
  }

  getClientInformation(family, requesterType, updateDisplayList = true, isNew = false) {
    this.saveDisabled = true;
    const returnData = [];
    const FamilyId = family.FamilyId || family.FamilyID || family.ResultFamilyID;
    let idx = 0;
    this.addToExistingFamilies(FamilyId);
    this.contactService.clientInformGet(FamilyId)
      .then((clients) => {
        forEach(clients.data, (value) => {
          if (value.Role === 'Adult') {
            if (isNew) {
              family.partyApplicants[idx].PersonId = value.PersonId;
            }
            returnData.push({
              ClientEntityId: value.PersonId,
              IsClient: true,
              AddRelatedChildren: false,
              ClientName: `${value.FirstName} ${value.LastName}`,
              FamilyId,
              IsFirstHomeBuyer: (!family.partyApplicants || !family.partyApplicants[idx].IsFirstHomeBuyers ? false : family.partyApplicants[idx].IsFirstHomeBuyers),
            });
          }
          idx++;
        });
        if (updateDisplayList) {
          this.storeClientDisplayList(FamilyId, clients.data, requesterType);
        } else if (!updateDisplayList && isNew) {
          this.storeClientDisplayList(FamilyId, family.partyApplicants, requesterType);
        }
        this.storeClientList(returnData, requesterType);
      })
      .finally(() => {
        this.saveDisabled = false;
      });
  }

  storeClientDisplayList(FamilyId, familyData, requestType) {
    const returnData = [];
    forEach(familyData, (member) => {
      if (member.Role === 'Adult') {
        returnData.push(this.clientDisplayListModel(FamilyId, member));
      }
    });

    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      this.displayFamily.borrower.push(returnData);
    }
    if (requestType === this.REQUESTER_TYPE.GUARANTOR) {
      this.displayFamily.guarantor.push(returnData);
    }
  }

  storeClientList(familyData, requestType) {
    const prop = requestType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER ? 'borrower' : 'guarantor';
    const formData = { ...this.formData };

    if (!formData[prop] || (formData[prop] && !formData[prop].length)) formData[prop] = [];
    formData[prop] = [...formData[prop], ...familyData];
    this.formData = { ...formData };
  }

  getEntityInformation(FamilyId, requesterType, isEditData = false) {
    const returnData = [];
    this.saveDisabled = true;
    this.addToExistingFamilies(FamilyId);
    this.contactService.organisationInfoGet(FamilyId)
      .then((response) => {
        const { data } = response;
        returnData.push({
          ClientEntityId: data.OrganisationId,
          IsClient: false,
          AddRelatedChildren: false,
          FamilyId,
          ClientName: data.Name,
        });

        if (!isEditData) {
          this.storeEntityDisplayList(response.data, FamilyId, requesterType);
        } else {
          const index = findIndex(this.displayCompany.borrower, obj => obj.FamilyId === FamilyId);
          this.displayCompany.borrower.splice(index, 1, this.entityDisplayListModel(FamilyId, data));

          const idx = this.formData.findIndex(obj => obj.ClientEntityId === FamilyId);
          this.formData.borrower.splice(idx, 1);
        }
        this.storeClientList(returnData, requesterType);
      })
      .finally(() => {
        this.saveDisabled = false;
      });
  }

  storeEntityDisplayList(entity, FamilyId, requesterType) {
    if (requesterType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER) {
      this.displayCompany.borrower.push(
        this.entityDisplayListModel(FamilyId, entity)
      );
    }
  }

  getAdvisersList() {
    this.userService.GetUserInfo()
      .then((response) => {
        this.allocatedAdviserList = null;
        this.assistantAdvisersList = null;
        this.advisersList = null;
        this.AssignedAdviserId = 0;
        this.userInfo = response.data;

        if (this.userInfo.AccessType === ACCESS_TYPE.ADVISER || this.userInfo.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER) {
          this.generalService.getBrokerAll()
            .then((brokers) => {
              this.advisersList = brokers.data;
              this.AssignedAdviserId = brokers.data[0].FamilyId;
            });
        } else {
          this.corporateService.getAdvisersDropdown(this.userInfo.FamilyId, 0, 0).then((getAdvisersDropResponse) => {
            if (this.userInfo.AccessType === ACCESS_TYPE.CORPORATE) {
              this.allocatedAdviserList = filter(getAdvisersDropResponse.data, (data) => { return data !== ''; });
            } else {
              this.assistantAdvisersList = filter(getAdvisersDropResponse.data, (data) => { return data.FullName !== ''; });
              this.AssignedAdviserId = this.assistantAdvisersList[0].FamilyId;
            }
            this.advisersList = this.allocatedAdviserList || this.assistantAdvisersList;
          });
        }
      });
  }

  resetAdviser() {
    this.showAdvisers = false;
    this.showAdviserLabel = false;

    this.allocatedAdviser = {
      adviserSelected: {},
      adviserInitialValue: '',
    };
    this.selectedAdviser = null;
    this.disableAdding();
  }

  disableAdding() {
    this.isAddingDisabled = true;

    this.formData = {
      borrower: [],
      guarantor: [],
    };
    this.displayFamily = {
      borrower: [],
      guarantor: [],
    };
    this.displayCompany = {
      borrower: [],
    };
    this.clientList = [];
    this.existingFamilies = [];
  }

  showAdvisersField(clearField) {
    if (clearField) {
      this.resetAdviser();
      this.$scope.$broadcast('angucomplete-alt:clearInput');
    }
    this.showAdvisers = true;
    this.showAdviserLabel = false;
  }

  cancelAdviserChanges(adviser) {
    if (adviser) {
      this.selectedAdviser = adviser;
      this.showAdvisers = false;
      this.showAdviserLabel = true;
    } else {
      this.showAdvisers = false;
      this.showAdviserLabel = false;
    }
  }

  setAdviser(adviser = null) {
    if (!adviser) {
      const { originalObject } = this.allocatedAdviser.adviserSelected;
      const adviserName = originalObject.FullName.split(' ', 2);
      this.selectedAdviser = {
        ...originalObject,
        AdviserFamilyId: originalObject.BrokerId || originalObject.FamilyId,
        AdviserInitials: this.utilitiesService.filterInitial(adviserName[0], adviserName[1]),
      };
    } else {
      const adviserName = adviser.FullName.split(' ', 2);
      this.selectedAdviser = {
        ...adviser,
        AdviserFamilyId: adviser.BrokerId || adviser.FamilyId,
        AdviserInitials: this.utilitiesService.filterInitial(adviserName[0], adviserName[1]),
      };
      this.allocatedAdviser.adviserInitialValue = adviser.FullName;
    }
    this.showAdvisers = false;
    this.showAdviserLabel = true;
    this.isAddingDisabled = false;
  }

  convertFormDataToAPIData(listObject) {
    if (!listObject) return false;
    const arrayObject = listObject
      .filter(family => this.excludedIds.indexOf(family.ClientEntityId) === -1)
      .map((obj) => {
        const { ClientEntityId, AddRelatedChildren, IsClient, FamilyId, ClientName: DisplayName } = obj;
        return {
          ClientEntityId,
          AddRelatedChildren,
          IsClient,
          FamilyId,
          DisplayName,
        };
      });
    return arrayObject;
  }

  postLoanApplication(params) {
    this.loanApplicationServices.postMultipleApplicants(params)
      .then((response) => {
        const { data } = response;
        const { LoanScenarioID: loanAppId } = data;
        this.setFirstHomeBuyers(loanAppId);
        const familyId = (typeof data.Borrowers[0] !== 'undefined' && typeof data.Borrowers[0].FamilyID !== 'undefined' ? data.Borrowers[0].FamilyID : data.Guarantors[0].FamilyID);
        this.saveDisabled = false;
        this.toaster.pop('success', 'Created', 'Loan application successfully created');
        this.$state.go('app.loanApplicationDetailsV3', {
          familyId,
          loanAppId,
        });
        this.$rootScope.$on('$stateChangeSuccess', () => {
          this.modalInstance.dismiss('cancel');
          this.broadcastDataService.InlinePreloader = {
            preLoaderText: '',
            preLoaderSize: '',
            showPreLoader: false,
          };
        });
        if (!this.isAdviserAccess) {
          this.adviserFamilyId = params.AllocatedAdviserId || 0;
        }
        this.loanAppDetailsService.setInsuranceConcierge(loanAppId, familyId, this.adviserFamilyId);
      });
  }

  setFirstHomeBuyers(loanAppId) {
    const borrower = this.formData.borrower.filter(obj => obj.IsClient);
    const guarantor = this.formData.guarantor.filter(obj => obj.IsClient);

    const fullList = [
      ...borrower,
      ...guarantor,
    ];
    forEach(fullList, (member) => {
      this.loanScenarioService.LoanAppIsFirstHomeBuyerSet(member.ClientEntityId, loanAppId, member.IsFirstHomeBuyer);
    });
  }

  addToExistingFamilies(FamilyId) {
    this.existingFamilies.push(FamilyId.toString());
  }

  checkUserInfo() {
    this.userService.GetUserInfo()
      .then((response) => {
        const { AccessType, FamilyId } = response.data;
        this.isAdviserAccess = false;
        const isAdviser = AccessType === ACCESS_TYPE.ADVISER || AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER;
        if (isAdviser) {
          this.setAdviser(response.data);
          this.allocatedAdviser.adviserSelected = {
            originalObject: response.data,
          };
          this.adviserFamilyId = FamilyId;
          this.isAdviserAccess = true;
        }
      });
  }
}

export default newLoanApplicationCtrl;
