import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { phoneWorkNumber } from 'Common/utilities/phoneWorkNumber';
import {
  PERSON_PARTY_TYPES,
  ENTITY_PARTY_TYPES,
} from 'Common/constants/partyTypes';


angular
  .module('app')
  .controller('PersonCompanyModalCtrl', function PersonCompanyModalCtrl(
    $scope,
    $uibModalInstance,
    utilitiesService,
    toaster,
    contactService,
    selectedPartyType,
    familyId,
    clientEntityId,
    isClient,
    child,
    optionsService,
    generalService,
    $timeout,
    $rootScope,
    $stateParams,
    partyTypeService,
    genderService,
    corporateService,
    userInfo
  ) {
    $scope.ContactSet = {};
    $scope.modalObj = {
      isCorporate: userInfo.AccessType === ACCESS_TYPE.CORPORATE,
      selectedAdviser: {},
    };
    const parseAllocatedAdviser = (obj) => {
      if (obj && obj.AllocatedAdviserID && $scope.modalObj.isCorporate) {
        $scope.modalObj.selectedAdviser = {
          originalObject: {
            FamilyId: obj.AllocatedAdviserID,
            FullName: obj.AllocatedAdviserName,
          },
        };
      }
    };
    if (_.isUndefined(clientEntityId) && _.isUndefined(familyId)) {
      $scope.ContactSet = $scope.getNewContactModel('Adult');
      $scope.ContactSet.PartyType = String(selectedPartyType);
    } else if (isClient) {
      contactService
        .clientInformGet(familyId, clientEntityId)
        .then((response) => {
          if (response.data[0] != null) {
            $scope.ContactSet = response.data[0];
            if (!_.isUndefined($scope.ContactSet.ResidencyId)) {
              $scope.ContactSet.ResidencyId = String(
                $scope.ContactSet.ResidencyId
              );
            }
            parseAllocatedAdviser($scope.ContactSet);
          }

          if (_.isUndefined($scope.ContactSet.IsResident)) {
            $scope.ContactSet.IsResident = false;
          }

          // find country codes
          let CitizenshipObj = _.find($scope.countryList, (o) => {
            return o.CountryID === $scope.ContactSet.CitizenshipId;
          });
          let CountryOfResidencyObj = _.find($scope.countryList, (o) => {
            return o.CountryID === $scope.ContactSet.CountryOfResidencyId;
          });
          if (!CitizenshipObj) {
            CitizenshipObj = {
              CountryCode: '',
            };
          }
          if (!CountryOfResidencyObj) {
            CountryOfResidencyObj = {
              CountryCode: '',
            };
          }

          // work email
          const personEmailAddress = _.find($scope.ContactSet.Email, (obj) => {
            return obj.Type === 'WorkEmail' || obj.Type === 'Work';
          }) || { EmailAddress: '' };
          $scope.ContactSet.EmailAddress = personEmailAddress.EmailAddress;
          $scope.ContactSet.FamilyId = familyId;

          $scope.ContactSet.MaritalStatusId = String(
            $scope.ContactSet.MaritalStatusId
          );

          // mobile number
          const personMobileNumber = _.find($scope.ContactSet.Phone, (obj) => {
            return obj.Type === 'Mobile';
          }) || { Number: '' };
          $scope.ContactSet.MobileNumber = personMobileNumber.Number;

          if (typeof child !== 'undefined') {
            $scope.ContactSet.PersonId = child.PersonId;
            $scope.ContactSet.Role = child.Role;
            $scope.ContactSet.Title = child.Title;
            $scope.ContactSet.FirstName = child.FirstName;
            $scope.ContactSet.LastName = child.LastName;
            $scope.ContactSet.Gender = child.Gender;
          }
          $scope.ContactSet.PartyType = String(selectedPartyType);
          $scope.ContactSet.CountryCode = CitizenshipObj.CountryCode;
          $scope.ContactSet.CountryOfResidencyCode =
            CountryOfResidencyObj.CountryCode;
          $scope.ContactSet.DOB = moment($scope.ContactSet.DOB).format(
            'DD/MM/YYYY'
          );
        });
    } else {
      contactService.organisationInfoGet(familyId).then((response) => {
        $scope.BusinessSet = response.data;
        parseAllocatedAdviser($scope.BusinessSet);
        angular.extend($scope.BusinessSet, {
          FamilyId: familyId,
        });

        if (_.size($scope.BusinessSet.Phone) > 0) {
          $scope.BusinessSet.Phone = $scope.BusinessSet.Phone[0].Number;
        }

        if (_.size($scope.BusinessSet.Email) > 0) {
          $scope.BusinessSet.EmailAddress =
            $scope.BusinessSet.Email[0].EmailAddress;
        }

        if (_.size($scope.BusinessSet.Address) > 0) {
          $scope.BusinessSet.Address = $scope.BusinessSet.Address[0];
        }

        $scope.ContactSet = {
          PartyType:
            $scope.BusinessSet.OrganisationType === 'Partnership' ? '7' : '2',
        };
        $scope.ContactSet.AllocatedAdviserID =
          $scope.BusinessSet.AllocatedAdviserID;
      });
    }
    $scope.BusinessSet = {};
    $scope.BusinessSet.Address = {};
    optionsService.MaritalStatusGet().then(
      (response) => {
        $scope.maritalStatus = response.data;
      },
      () => {
        $scope.maritalStatus = [];
      }
    );

    optionsService.ResidencyStatusGet().then(
      (response) => {
        $scope.residencyStatus = response.data;
      },
      () => {
        $scope.residencyStatus = [];
      }
    );

    $scope.saveText = 'Applicant';
    $scope.$watch('ContactSet.PartyType', (newVal) => {
      $scope.saveText = partyTypeService.getPartyType(newVal);
    });

    $scope.titles = [
      { Value: 'Mr', Name: 'Mr' },
      { Value: 'Mrs', Name: 'Mrs' },
      { Value: 'Ms', Name: 'Ms' },
      { Value: 'Miss', Name: 'Miss' },
      { Value: 'Master', Name: 'Master' },
      { Value: 'Doctor', Name: 'Doctor' },
    ];

    $scope.$watch('ContactSet.Title', (newVal) => {
      genderService.changeGender(newVal, $scope.ContactSet.Gender);
    });

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.datePopUp = {
      DOB: false,
    };
    // Open Date Popup
    $scope.openDate = function (dateField) {
      $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
    };

    $scope.format = 'dd MMM yyyy';
    $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    // Save Client
    $scope.saveClientAccount = function () {
      $scope.ContactSet.Phone = [
        { Type: 'Mobile', Number: $scope.ContactSet.MobileNumber },
      ];
      $scope.ContactSet.Email = [
        { Type: 'Work', EmailAddress: $scope.ContactSet.EmailAddress },
      ];

      if ($scope.ContactSet.CountryCode === -1)
        $scope.ContactSet.CountryOfBirth =
          $scope.ContactSet.CitizenshipCountryManual;
      else $scope.ContactSet.CountryOfBirth = $scope.ContactSet.CountryCode;

      let CitizenshipObj = _.find($scope.countryList, (o) => {
        return o.CountryCode === $scope.ContactSet.CountryCode;
      });
      let CountryOfResidencyObj = _.find($scope.countryList, (o) => {
        return o.CountryCode === $scope.ContactSet.CountryOfResidencyCode;
      });
      if (!CitizenshipObj) {
        CitizenshipObj = {
          CountryID: 0,
        };
      }
      if (!CountryOfResidencyObj) {
        CountryOfResidencyObj = {
          CountryID: 0,
        };
      }
      angular.extend($scope.ContactSet, {
        CitizenshipId: parseInt(CitizenshipObj.CountryID, 10),
        CountryOfResidencyId: parseInt(CountryOfResidencyObj.CountryID, 10),
        ResidencyId: parseInt($scope.ContactSet.ResidencyId, 10),
        MaritalStatusId: parseInt($scope.ContactSet.MaritalStatusId, 10),
      });

      if (Object.prototype.hasOwnProperty.call($scope.ContactSet, 'Address')) {
        _.forEach($scope.ContactSet.Address, (address) => {
          if (address.Type === 'Current Address') {
            address.Type = 'Home';
          }
        });
      }
      /* FOR DOB SAVING */
      const tempContactSet = angular.copy($scope.ContactSet);
      const isDateValid = moment(
        tempContactSet.DOB,
        'DD/MM/YYYY',
        true
      ).isValid();

      if (isDateValid) {
        tempContactSet.DOB = tempContactSet.DOB.split('/')
          .reverse()
          .join('-');
      }
      /* END OF FOR DOB SAVING */

      contactService.addContact([tempContactSet]).then((response) => {
        const FamilyId = response.data;
        $uibModalInstance.close({
          FamilyId,
          PartyType: $scope.ContactSet.PartyType,
          isEdit: tempContactSet.FamilyId !== '0',
        });
        $scope.getClientByFamilyId();
      });
    };

    $scope.saveBusinessAccount = function (form) {
      if (form.$invalid) {
        toaster.pop(
          'error',
          'Invalid Form',
          'Please fill up the form correctly'
        );
        $scope.canSave = true;
        return false;
      }
      const partyType = $scope.ContactSet.PartyType === '2' ? 'Company' : '';
      const org = {
        SourceFamilyID: $rootScope.isCorporateUser ? $stateParams.familyId : '',
        OrganisationId: $scope.BusinessSet.OrganisationId
          ? $scope.BusinessSet.OrganisationId
          : '0',
        FamilyId: $scope.BusinessSet.FamilyId
          ? $scope.BusinessSet.FamilyId
          : '0',
        PersonId: '0',
        Name: $scope.BusinessSet.Name,
        OrganisationTaxNumber: $scope.BusinessSet.OrganisationTaxNumber,
        OrganisationCompanyNumber: $scope.BusinessSet.OrganisationCompanyNumber,
        Phone: [phoneWorkNumber($scope.BusinessSet.Phone)],
        Address: [$scope.BusinessSet.Address],
        Email: [
          {
            Type: 'Email',
            EmailAddress: $scope.BusinessSet.EmailAddress
              ? $scope.BusinessSet.EmailAddress
              : '',
          },
        ],
        OrganisationType:
          $scope.ContactSet.PartyType === '7' ? 'Partnership' : partyType,
        AllocatedAdviserID: $scope.ContactSet.AllocatedAdviserID,
      };

      contactService.organisationInfoSet(org).then((response) => {
        const FamilyId = response.data;

        if (response.data.Message) {
          toaster.pop('info', 'Information', response.data.Message);
        } else {
          $uibModalInstance.close({
            FamilyId,
            PartyType: $scope.ContactSet.PartyType,
            isEdit: !!$scope.BusinessSet.OrganisationId,
          });
        }
      });
    };

    $scope.saveAccount = function (form) {
      $scope.ContactSet.AllocatedAdviserID = $scope.modalObj.isCorporate
        ? 0
        : $scope.ContactSet.AllocatedAdviserID;
      const isAllocatedFromSearch =
        $scope.modalObj.isCorporate &&
        $scope.modalObj.selectedAdviser &&
        $scope.modalObj.selectedAdviser.originalObject;
      if (isAllocatedFromSearch) {
        $scope.ContactSet.AllocatedAdviserID =
          $scope.modalObj.selectedAdviser.originalObject.FamilyId || 0;
      }
      $scope.validationError = false;
      if (!$scope.ContactSet.AllocatedAdviserID) {
        $scope.validationError = true;
        return;
      }
      const personPartyTypeList = [
        PERSON_PARTY_TYPES.APPLICANT,
        PERSON_PARTY_TYPES.GUARANTOR,
        PERSON_PARTY_TYPES.DEPENDENT,
        PERSON_PARTY_TYPES.SOLICITOR,
        PERSON_PARTY_TYPES.ACCOUNTANT,
      ];
      const entityPartyTypeList = [
        ENTITY_PARTY_TYPES.COMPANY,
        ENTITY_PARTY_TYPES.PARTNERSHIP,
      ];
      if (
        personPartyTypeList.indexOf(
          parseInt($scope.ContactSet.PartyType, 10)
        ) !== -1
      ) {
        $scope.saveClientAccount(form);
      } else if (
        entityPartyTypeList.indexOf(
          parseInt($scope.ContactSet.PartyType, 10)
        ) !== -1
      ) {
        $scope.saveBusinessAccount(form);
      }
    };
    const adviserList = [ACCESS_TYPE.PRINCIPAL_ADVISER, ACCESS_TYPE.ADVISER];
    const getAdviserList = () => {
      corporateService
        .getAdvisersDropdown(userInfo.FamilyId, 0, 0, true)
        .then((response) => {
          $scope.adviserList = response.data;
          if (
            adviserList.indexOf(userInfo.AccessType) !== -1 &&
            !$scope.ContactSet.AllocatedAdviserID
          ) {
            $scope.ContactSet.AllocatedAdviserID = userInfo.FamilyId;
          }
        });
    };
    getAdviserList();
  });
