import angular from 'angular';

angular.module('app').controller('InsurancePreviousCtrl',
  function InsurancePreviousCtrl(
    $scope,
    $timeout,
    $uibModal,
    $state,
    $localStorage,
    imageDataURI,
    $stateParams,
    toaster,
    insuranceSharedData
  ) {
    // EXISTING INSURANCES
    // MODELS
    const tabName = 'Previous';
    $scope.data = {
      familyId: parseInt($stateParams.familyId, 10) || 0,
      previousInsuranceList: [],
      insuranceProviderList: [],
      insurancePolicyList: [],
      filter: {
        policyNumber: 'Show All Policies',
        providerId: 0,
      },
    };

    // Functionalities
    $scope.vm = {
      addPrevious: () => {
        insuranceSharedData.addExistingInsurance('Previous');
      },
      deletePrevious: (familyId, policyId, caller) => {
        insuranceSharedData.deleteInsurance(familyId, policyId, caller);
      },
      editPrevious: (insurance) => {
        insuranceSharedData.editExistingInsurance(insurance, 'Previous');
      },
      changeFilters() {
        const data = $scope.data;
        const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
        insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
      },
      resetFilters() {
        $scope.data.filter = {
          policyNumber: 'Show All Policies',
          providerId: 0,
        };
        const data = $scope.data;
        const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
        insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
      },
    };

    // METHODS
    $scope.$watch(() => { return insuranceSharedData.sharedData.insuranceList; }, (newVal) => {
      if (newVal) {
        $scope.data.previousInsuranceList = newVal;
      }
    });

    $scope.$watch(() => { return insuranceSharedData.sharedData.insuranceProviderList; }, (newVal) => {
      if (newVal) {
        $scope.vm.resetFilters();
        $scope.data.insuranceProviderList = newVal;
      }
    });

    $scope.$watch(() => { return insuranceSharedData.sharedData.insurancePolicyList; }, (newVal) => {
      if (newVal) {
        $scope.vm.resetFilters();
        $scope.data.insurancePolicyList = newVal;
      }
    });

    const init = () => {
      const data = $scope.data;
      const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
      insuranceSharedData.getFiltersList(data.familyId, tabName);
      insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
    };

    init();
  });
