import angular from 'angular';
import _ from 'lodash';

angular.module('app').factory('tasksSharedData', ['SweetAlert', 'contactService', function (SweetAlert, contactService) {
  var factory = this;
  angular.extend(factory, {
    refreshTaskList: false,
    updateFamilyOnAdviserChange() {
      const adviserData = _.find(this.taskAdviserList, { BrokerID: this.taskSet.assignedClientID });
    },
    completionConfirmation(confirmCallback, cancelCallback) {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'Do you really want to complete this task?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, complete it!',
        allowEscapeKey: false,
      }, (confirm) => {
        if (confirm) {
          confirmCallback();
        } else {
          cancelCallback();
        }
      });
    },
    getClientName(id){
      contactService.clientInformGet(id).then((response) => {
        let familyData = _.filter(response.data, (o) => {
          return o.Role === 'Adult' || o.Role === '0';
        });
      if (familyData.length) {
        familyData = _.head(familyData);
        this.taskInput.familyValue = familyData.FullName || '';
        this.taskSet.FamilyId = id;
      }
    });
    },
  });

  return factory;
}]);
