export function generalBuilderForUI(data) {
  return {
    adviserId: data.AdviserId,
    pipelineStatusId: data.PipelineStatusId,
    pipelineStatusIdBak: data.PipelineStatusId,
    summaryNotes: data.SummaryNotes,
    title: data.Title,
    assistantFamilyId: data.AssistantFamilyId,
    assistantName: data.AssistantName,
  };
}

export function notesBuilderForUI(note) {
  return {
    isPrivate: note.IsPrivate,
    isNotification: note.IsNotification,
    notificationSMS: note.NotificationSMS,
    notificationEmail: note.NotificationEmail,
    loanId: note.LoanId,
    adviserId: note.AdviserId,
    adviserName: note.AdviserName,
    isSticky: note.IsSticky,
    isReferrer: note.IsReferrer,
    familyId: note.FamilyID,
    communicationId: note.CommunicationID,
    activityDate: new Date(note.ActivityDate),
    activityTitle: note.ActivityTitle,
    activityDetails: note.ActivityDetails,
    activityOwner: note.ActivityOwner,
    activityType: note.ActivityType,
  };
}

export function notesBuilderForMyCRM(note) {
  return {
    IsPrivate: note.isPrivate,
    IsNotification: note.isNotification,
    NotificationSMS: note.notificationSMS,
    NotificationEmail: note.notificationEmail,
    LoanId: note.loanId,
    AdviserId: note.adviserId,
    IsSticky: note.isSticky,
    IsReferrer: note.isReferrer,
    FamilyID: note.familyId,
    ActivityID: note.activityId,
    CommunicationID: note.communicationId,
    ActivityDate: note.activityDate,
    ActivityTitle: note.activityTitle,
    ActivityDetails: note.activityDetails,
    ActivityOwner: note.activityOwner,
    ActivityType: note.activityType,
  };
}

export function calculationsBuilderForUI(calculation) {
  return {
    brokerEventId: calculation.BrokerEventID,
    dateOfCalculation: calculation.DateOfCalculation,
    typeOfCalculation: calculation.TypeOfCalculation,
  };
}

export function calculationsBuilderForMyCRM(params) {
  return {
    BrokerEventID: params.brokerEventID,
    TypeOfCalculation: params.typeOfCalculation,
  };
}

export function totalLoanAmountsBuilderForUI(totalLoanAmount) {
  return {
    statusId: totalLoanAmount.StatusId,
    statusName: totalLoanAmount.StatusName,
    totalLoanAmount: totalLoanAmount.TotalLoanAmount,
  };
}

export function shareReferrerNotesViaSmsBuilderForMyCrm(smsObj) {
  return {
    MobileNumber: smsObj.mobile,
    Message: smsObj.body,
  };
}

export function shareReferrerNotesViaEmailBuilderForMyCrm(emailObj) {
  return {
    Subject: emailObj.subject,
    Content: emailObj.body,
    ReplyTo: emailObj.replyTo,
    SentDate: emailObj.sentDate,
    Recipients: emailObj.recipients,
  };
}
