import swal from 'sweetalert';
import { toastError, displayError, toastSaveSuccess, toastSaveDelete } from 'Common/utilities/alert';

export default class OpportunityNotesCtrl {
  constructor(
    $uibModal,
    $timeout,
    $q,
    loanOpportunityService,
    referralModelService,
    contactService,
    toaster
  ) {
    'ngInject';

    this.uibModal = $uibModal;
    this.$timeout = $timeout;
    this.$q = $q;
    this.loanOpportunityService = loanOpportunityService;
    this.referralModelService = referralModelService;
    this.contactService = contactService;
    this.toaster = toaster;
  }

  $onInit() {
    this.disableOpenModal = false;
    this.getNotes();
    this.emailObj = {};
    this.getEmailObject();
  }

  getNotes() {
    this.loanOpportunityService.getLoanOpportunityNotes(this.loanId)
      .then((notesList) => {
        this.notesList = notesList;
      });
  }

  consolidateClientNames() {
    if (!this.involvedParties) return;
    const { InvolvedPartyEntity, PersonBorrowers } = this.involvedParties;
    const involvedParties = [...InvolvedPartyEntity, ...PersonBorrowers];
    const clientNames = involvedParties
      .map((obj) => {
        return obj.PersonId ? `${obj.FirstName} ${obj.LastName}` || obj.FamilyFullName : `${obj.Name}`;
      })
      .join(', ');
    return `New Note Created for ${clientNames}`;
  }

  getEmailObject(referrerEmail) {
    this.contactService.getUserInfo()
      .then(({ data }) => {
        if (!data) return;
        this.emailObj = {
          subject: `${this.consolidateClientNames()}`,
          body: '',
          replyTo: { EmailAddress: data.Email },
          sentDate: new Date(),
          recipients: [{ EmailAddress: referrerEmail }],
        };
        this.isSmsEnabled = data.SMSEnabled;
      });
  }

  openAddEditNotesModal(note) {
    if (this.disableOpenModal) return;
    this.disableOpenModal = true;
    const { loanId, familyId, adviserId, referralId, emailObj, isSmsEnabled } = this;
    this.referralModelService.getReferrerContactInfo(loanId)
      .then(({ succeeded, data: referrerInfo }) => {
        if (!succeeded && !referrerInfo) return {};
        const finalSmsObj = {
          mobile: referrerInfo.mobile,
          body: '',
        };
        const finalEmailObj = {
          ...emailObj,
          recipients: [{ EmailAddress: referrerInfo.email }],
          subject: this.consolidateClientNames(),
          referrerEmail: referrerInfo.email,
        };

        return { finalSmsObj, finalEmailObj };
      })
      .then((smsAndEmailObj) => {
        const noteObj = {
          ...note,
          loanId,
          familyId,
          adviserId,
          emailObj: (smsAndEmailObj && smsAndEmailObj.finalEmailObj) || {},
          smsObj: (smsAndEmailObj && smsAndEmailObj.finalSmsObj) || {} };
        const isAdd = !note;
        const props = { noteObj, isAdd, referralId, isSmsEnabled };

        this.callOpenModal(props);
      })
      .catch(() => {
        this.disableOpenModal = false;
      });
  }

  callOpenModal(props) {
    this.uibModal.open({
      template: `<opportunity-notes-modal
                  modal-instance="vm.modalInstance"
                  note-obj="vm.props.noteObj"
                  is-add="vm.props.isAdd"
                  referral-id="vm.props.referralId"
                  is-sms-enabled="vm.props.isSmsEnabled">
                </opportunity-notes-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      windowClass: 'opportunity-notes-modal',
      keyboard: false,
      resolve: {
        props: () => props,
      },
    }).result
      .then(() => {
        this.disableOpenModal = false;
        toastSaveSuccess(this.toaster);
        this.getNotes();
      }, () => {
        this.disableOpenModal = false;
        this.getNotes();
      });
  }

  deleteNote(note) {
    swal({
      title: 'Delete Note Confirmation',
      text: `Are you sure want to delete this note?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, Delete Note',
      closeOnConfirm: true,
    }, (response) => {
      if (!response) return;
      const { communicationId } = note;
      this.loanOpportunityService.deleteLoanOpportunityNote(communicationId)
        .then((res) => {
          const { data: result } = res;
          if (result) {
            const idx = this.notesList.indexOf(note);
            this.notesList.splice(idx, 1);
            toastSaveDelete(this.toaster);
          } else {
            toastError();
          }
        })
        .catch((err) => { displayError(err); });
    });
  }
}
