import _ from 'lodash';

class SmsTemplatesSharingPrivacyCtrl {
  constructor(
    corporateService,
    contactService,
    uiService,
    templateService
  ) {
    'ngInject';

    this.corporateService = corporateService;
    this.contactService = contactService;
    this.uiService = uiService;
    this.templateService = templateService;
    this.templateChanges = [];
    this.templateFolderList = [];
  }

  $onInit() {
    this.getTemplates();
  }

  getTemplates() {
    this.contactService.smsTemplatesGet().then((response) => {
      if (response.data) {
        this.templateFolderList = _.filter(response.data, folder => !this.templateService.isBlankFolderOrTemplate(folder));
      }
    });
  }

  setAsCorporate(obj, isTemplate) {
    if (obj && !obj.IsSystemTemplate) {
      obj.IsExclusiveForCorpUser = !obj.IsExclusiveForCorpUser;
      obj.IsFolder = !isTemplate;
      this.contactService.smsTemplatesSet(obj);
    }
  }

  setAsShared(obj, isTemplate) {
    if (obj) {
      obj.isSharedWithAdviser = !obj.isSharedWithAdviser;
      obj.IsFolder = !isTemplate;
      this.contactService.smsTemplatesSet(obj);
    }
  }
}

export default SmsTemplatesSharingPrivacyCtrl;
