import angular from 'angular';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular.module('app').controller('BrokerSupportNewRequestCtrl', function BrokerSupportNewRequestCtrl(
  $scope,
  userService,
  corporateService,
  contactService,
  brokerSupportService,
  lendingScenarioService,
  loanScenarioService,
  $uibModal,
  referralsService,
  SweetAlert
) {
  $scope.familyId = 0;
  $scope.brokerRequest = {};
  $scope.submission_types = [];
  $scope.loan_purpose_values = [];
  $scope.loan_offer_docs = [];
  $scope.advisers_lists = [];
  $scope.lending_scenarios = [];
  $scope.referrers = [];
  $scope.lenderSplitAdvisers = [];
  $scope.no_loan_splits = [];
  $scope.loan_scenarios = [];
  $scope.help_support_contacts = [];
  $scope.isDisabled = false;
  $scope.brokerRequest.CustomerInMyCrm = false;
  $scope.adminUsers = [];

  const getAdviserContact = (familyId) => {
    corporateService.corporateContactGet(0, familyId).then((response) => {
      const data = response.data;
      if (data) {
        $scope.brokerRequest.AdviserContactEmail = data.Email || '';
        $scope.brokerRequest.AdviserMobileNumber = data.Mobile || '';
      }
    });
  };

  const getAssistantInfo = (familyId) => {
    corporateService.getAssistantList(familyId, 1).then((response) => {
      const assistantList = response.data;
      if (assistantList && assistantList.length) {
        const data = assistantList[0];
        $scope.brokerRequest.AssistantContactEmail = data.Email || '';
        $scope.brokerRequest.AssistantContactNumber = data.MobilePhone || '';
      }
    });
  };

  const getBrokerInfo = (familyId) => {
    $scope.brokerRequest.adviserSupportInitial = '';
    $scope.brokerRequest.adviserSupportSelected = {};
    $scope.brokerRequest.isShow = false;
    brokerSupportService.getReferrersWithActiveAgreement(familyId).then((response) => {
      $scope.referrers = response.data;
    });
    getAdviserContact(familyId);

    corporateService.familyBrokerSupportGet(familyId, 0).then((familyBrokerSupportGetResponse) => {
      $scope.isServiceModelEmpty = false;
      const standardType = [ACCESS_TYPE.CORPORATE, ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT];
      const isStandard = familyId === $scope.familyId && standardType.indexOf(parseInt($scope.accessType, 10)) >= 0;
      if (isStandard) {
        $scope.brokerRequest.BrokerSupportServiceModel = 'standard';
      } else if (familyBrokerSupportGetResponse) {
        if (familyBrokerSupportGetResponse.data) {
          if (familyBrokerSupportGetResponse.data.ServiceModel) {
            $scope.brokerRequest.BrokerSupportServiceModel = familyBrokerSupportGetResponse.data.ServiceModel.toLowerCase() === 'discount' ? 'discounted' : familyBrokerSupportGetResponse.data.ServiceModel.toLowerCase();
          } else {
            swal('No Service Model on this Adviser', 'Broker Support Service Model has not been selected. Please contact brokersupport@loanmarket.com.au', 'error');
            $scope.isServiceModelEmpty = true;
          }
          $scope.brokerRequest.adviserSupportInitial = familyBrokerSupportGetResponse.data.OfficerFullName || '';
          $scope.brokerRequest.adviserSupportSelected = { title: familyBrokerSupportGetResponse.data.OfficerFullName };
        } else {
          SweetAlert.swal('No Service Model on this Adviser', 'Broker Support Service Model has not been selected. Please contact brokersupport@loanmarket.com.au', 'error');
          $scope.isServiceModelEmpty = true;
        }
      } else {
        SweetAlert.swal('No Service Model on this Adviser', 'Broker Support Service Model has not been selected. Please contact brokersupport@loanmarket.com.au', 'error');
        $scope.isServiceModelEmpty = true;
      }
      $scope.brokerRequest.isShow = true;
    });
    getAssistantInfo(familyId);
  };

  const getAdvisersDropdown = (familyId, searchAdviser) => {
    corporateService.getAdvisersDropdown(familyId, searchAdviser).then((response) => {
      $scope.advisersList = response.data;

      const userLogin = {
        FamilyId: $scope.userData.FamilyId,
        FullName: $scope.userData.FullName,
      };

      $scope.brokerRequest.brokerInitialValue = userLogin;
      getBrokerInfo($scope.userData.FamilyId);
    });
  };

  const getClientName = (value) => {
    if (value) {
      return typeof value.title === 'undefined' ? value : value.title;
    }
    return '';
  };

  const init = () => {
    userService.GetUserInfo().then((response) => {
      $scope.accessType = response.data.AccessType;
      $scope.userData = response.data;
      $scope.familyId = response.data.FamilyId;
      if ($scope.accessType === 5) {
        getAdvisersDropdown($scope.familyId, 0);
      } else {
        getAdvisersDropdown($scope.familyId, 1);
      }
    }).then(() => {
      contactService.getAdminUser().then((response) => {
        const tempAdmin = [];
        angular.forEach(response.data, (admin) => {
          tempAdmin.push(admin);
        });
        $scope.adminUsers = tempAdmin;
      });

      lendingScenarioService.scenarioListGet($scope.familyId).then((response) => {
        $scope.lending_scenarios = response.data;
      });

      loanScenarioService.scenarioListGet($scope.familyId, null).then((response) => {
        $scope.loan_scenarios = response.data;
      });

      corporateService.lendersListPerAdviserGet($scope.familyId).then((response) => {
        $scope.lenderSplitAdvisers = response.data;
      });
    });

    $scope.brokerRequest.Priority = 'normal';

    brokerSupportService.submissionTypesGet().then((response) => {
      $scope.submission_types = response.data;
      $scope.brokerRequest.SubmissionType = $scope.submission_types[0].Key;
    });

    brokerSupportService.loanPurposeValuesGet().then((response) => {
      $scope.loan_purpose_values = response.data;
    });

    brokerSupportService.whereToSendLoanOfferDocsGet().then((response) => {
      $scope.loan_offer_docs = response.data;
    });

    brokerSupportService.noOfLoanSplitsGet().then((response) => {
      $scope.no_loan_splits = response.data;
    });

    brokerSupportService.getBrokerSupportServiceModel().then((response) => {
      $scope.serviceModel = response.data;
    });
  };
  const getAdviserFamilyId = () => {
    let familyId = 0;
    const adviser = $scope.brokerRequest.adviserSelected;
    if (adviser.originalObject && adviser.originalObject.FamilyId) {
      const multiAdviserAccess = [ACCESS_TYPE.CORPORATE, ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT];
      const isMultiAdviserAccess = multiAdviserAccess.indexOf($scope.accessType) !== -1;
      if (isMultiAdviserAccess && adviser.originalObject.FamilyId !== $scope.familyId) {
        familyId = adviser.originalObject.FamilyId;
      } else if (!isMultiAdviserAccess) {
        familyId = adviser.originalObject.FamilyId;
      }
    }
    return familyId;
  };
  $scope.searchAPIClient = (userInputString) => {
    const adviserFamilyId = getAdviserFamilyId();
    if (userInputString) {
      return contactService.searchFamilyContactType(userInputString, 'Client', adviserFamilyId);
    }
  };
  $scope.isServiceModelEmpty = true;
  $scope.brokerRequest.adviserSupportInitial = '';
  $scope.brokerRequest.isShow = true;
  $scope.$watch('brokerRequest.adviserSelected', () => {
    $scope.brokerRequest.adviserSupportInitial = '';
    $scope.brokerRequest.adviserSupportSelected = {};
    if ($scope.brokerRequest.adviserSelected && $scope.brokerRequest.adviserSelected.description) {
      $scope.brokerRequest.isShow = false;
      const familyId = $scope.brokerRequest.adviserSelected.description.FamilyId || 0;
      brokerSupportService.getReferrersWithActiveAgreement(familyId).then((response) => {
        $scope.referrers = response.data;
      });
      getAdviserContact(familyId);

      corporateService.familyBrokerSupportGet(familyId, 0).then((response) => {
        if (familyId === $scope.familyId && $scope.accessType === 5) {
          $scope.brokerRequest.BrokerSupportServiceModel = 'standard';
        } else if (response) {
          if (response.data) {
            $scope.isServiceModelEmpty = false;
            if (typeof response.data.ServiceModel === 'undefined' || response.data.ServiceModel === '') {
              SweetAlert.swal('No Service Model on this Adviser', 'Broker Support Service Model has not been selected. Please contact brokersupport@loanmarket.com.au', 'error');
              $scope.isServiceModelEmpty = true;
            } else {
              $scope.brokerRequest.BrokerSupportServiceModel = response.data.ServiceModel.toLowerCase() === 'discount' ? 'discounted' : response.data.ServiceModel.toLowerCase();
            }
            $scope.brokerRequest.adviserSupportInitial = response.data.OfficerFullName || '';
            $scope.brokerRequest.adviserSupportSelected = { title: response.data.OfficerFullName };
          }
        } else {
          SweetAlert.swal('No Service Model on this Adviser', 'Broker Support Service Model has not been selected. Please contact brokersupport@loanmarket.com.au', 'error');
          $scope.isServiceModelEmpty = true;
        }
        $scope.brokerRequest.isShow = true;
      });
      getAssistantInfo(familyId);
      $scope.$broadcast('angucomplete-alt:clearInput', 'clientName');
    }
  });

  $scope.createBrokerRequest = (request) => {
    if ($scope.isServiceModelEmpty) {
      SweetAlert.swal('Service Model Required', 'To get service model please select Adviser.', 'error');
    } else {
      $scope.isDisabled = true;
      request.ClientName = getClientName(request.ClientName);
      if (request.adviserSelected && request.adviserSelected.title) {
        request.Adviser = request.adviserSelected.title;
      } else {
        request.Adviser = $scope.brokerRequest.brokerInitialValue.FullName;
      }
      request.AdviserSupportOfficer = request.adminSelected ? request.adminSelected.title : '';
      request.Referrer = request.refererSelected ? request.refererSelected.title : '';
      request.AgreementId = request.refererSelected ? request.refererSelected.originalObject.AgreementId : 0;
      request.AdviserSupportOfficer = request.adviserSupportSelected ? request.adviserSupportSelected.title : '';
      const lender = $scope.lenderSplitAdvisers && $scope.lenderSplitAdvisers.find(x => parseInt(request.LenderId, 10) === parseInt(x.LenderId, 10));
      request.Lender = (lender && lender.LenderName) || '';
      brokerSupportService.brokerSupportRequestSet(request).then((response) => {
        if (response.status === 200) {
          $scope.isDisabled = false;
          $scope.ticketData = response.data;
          $uibModal.open({
            templateUrl: 'assets/views/helpSupport/modal/request-submitted.html',
            size: 'md',
            backdrop: 'static',
            keyboard: false,
            scope: $scope,
            controller($state) {
              $scope.GoToState = () => {
                $state.go('app.brokerSupportRequest');
              };
            },
          });
        } else {
          $uibModal.open({
            templateUrl: 'assets/views/helpSupport/modal/warning-submitted.html',
            size: 'md',
            backdrop: 'static',
            keyboard: false,
            scope: $scope,
            controller($uibModalInstance, $state) {
              $scope.ComfirmAction = () => {
                $state.go('app.brokerSupportRequest');
              };

              $scope.GoBack = () => {
                $uibModalInstance.dismiss('cancel');
              };
            },
          });
        }
      });
    }
  };
  $scope.showVideoModal = () => {
    $uibModal.open({
      templateUrl: 'assets/views/helpSupport/modal/helpsupport-vid.html',
      size: 'lg',
      backdrop: 'true',
      scope: $scope,
      controller($uibModalInstance) {
        $scope.cancel = () => {
          $uibModalInstance.dismiss('cancel');
        };
      },
    });
  };

  init();
});
