import template from './existingInsurance.html';
import controller from './existingInsuranceCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    onDeleted: '&',
    onUpdated: '&',
  },
};
