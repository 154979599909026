const RELEASE_NOTE_KEYS = {
  TYPE_STR: {
    NEW: 'What\'s new',
    IMPROVEMENTS: 'MyCRM Updates',
    FIXES: 'MyCRM Fixes',
  },
  RELEASE_FILTER: {
    PREVIOUS: 'Previous',
    RECENT: 'Recent',
  },
};

const RELEASE_NOTES_DEFAULTS = {
  PAGE_RECENT: 1,
  PAGE_PREVIOUS: 1,
  PAGE_SIZE: 5,
};
class ReleaseNotesCtrl {
  constructor(
    $state,
    uiService,
    configService,
    releaseNotesService
  ) {
    'ngInject';

    this.$state = $state;
    this.uiService = uiService;
    this.configService = configService;
    this.releaseNotesService = releaseNotesService;
    this.brand = this.configService.isRW ? 'raywhite' : 'loanmarket';
    this.emptyHolder = '';
    this.currentPageRecent = RELEASE_NOTES_DEFAULTS.PAGE_RECENT;
    this.currentPagePrevious = RELEASE_NOTES_DEFAULTS.PAGE_PREVIOUS;
    this.pageSize = RELEASE_NOTES_DEFAULTS.PAGE_SIZE;
  }

  $onInit() {
    this.getReleaseNotes(RELEASE_NOTE_KEYS.RELEASE_FILTER.RECENT);
    this.getReleaseNotes(RELEASE_NOTE_KEYS.RELEASE_FILTER.PREVIOUS);
  }

  getSingularName(length, name) {
    if (length > 1 || name === 'New') return name;
    this.singularDictionary = {
      Improvements: 'Improvement',
      Fixes: 'Fix',
    };
    return this.singularDictionary[name];
  }

  getNoteTypeHeader(note) {
    if (!note || !note.typeName) return this.emptyHolder;
    return RELEASE_NOTE_KEYS.TYPE_STR[note.typeName.toUpperCase()];
  }

  getReleaseNotes(filterType, pageNumber = 1) {
    const data = {
      brandName: this.brand,
      releaseFilterType: filterType,
      pageNumber,
      pageSize: this.pageSize,
    };
    this.releaseNotesService.getReleaseNotes(data)
      .then((response) => {
        if (!response) return;
        if (filterType === RELEASE_NOTE_KEYS.RELEASE_FILTER.RECENT) this.releaseNotesRecent = pageNumber === 1 ? response : [...this.releaseNotesRecent, ...response];
        if (filterType === RELEASE_NOTE_KEYS.RELEASE_FILTER.PREVIOUS) this.releaseNotesPrevious = pageNumber === 1 ? response : [...this.releaseNotesPrevious, ...response];
      });
  }

  isShowPager(releaseNote) {
    return (releaseNote && releaseNote.length && releaseNote[0].totalReleaseCount) > this.pageSize;
  }

  hasMore(releaseNote) {
    this.hasMoreNotes = releaseNote ? (releaseNote.length < releaseNote[0].totalReleaseCount) : false;
    return this.hasMoreNotes;
  }

  getPageNumber(data, currentPage, isRecent) {
    const currentPageNumber = this.hasMore(data) ? currentPage + 1 : 1;
    this.currentPageRecent = isRecent ? currentPageNumber : this.currentPageRecent;
    this.currentPagePrevious = !isRecent ? currentPageNumber : this.currentPagePrevious;

    return currentPageNumber;
  }
}


export default ReleaseNotesCtrl;
