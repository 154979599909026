// ng-storage
// set a prefix to avoid overwriting any local storage variables
function configStorage($localStorageProvider) {
  $localStorageProvider.setKeyPrefix('PacketLtr2');
}

configStorage.$inject = [
  '$localStorageProvider',
];

export default configStorage;
