import { goToOpportunity } from 'Common/utilities/loanOpportunity';

class LendingOpportunityCtrl {
  constructor(opportunityNewModalService, clientInfoService, contactService, $uibModal, $state, uiService) {
    'ngInject';

    this.opportunityNewModalService = opportunityNewModalService;
    this.clientInfoService = clientInfoService;
    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.uiService = uiService;
  }

  $onInit() {
    this.opportunityList = [];
    this.crmCurrency = this.uiService.getCrmCurrency();

    this.familyId && this.contactService.clientInformGet(this.familyId)
      .then((res) => {
        const { data } = res;
        if (!data) return;

        this.clients = data.filter(obj => (obj.Role === 'Adult' || obj.isEntity)) || [];

        const isContactOpener = 1;
        const tabName = 'details';

        const clients = { information: this.clients };
        const { headerName } = this.clientInfoService.getClientHeader(clients);

        const sourceFamilyId = this.familyId;
        const sourceFamilyName = headerName || '';

        const { isBusiness } = this;

        this.redirectParams = { sourceFamilyId, sourceFamilyName, isContactOpener, isBusiness, tabName };
      });
  }

  $onChanges(changes) {
    const { isShowPrevious } = changes;
    if (isShowPrevious) {
      const { currentValue } = isShowPrevious;
      this.opportunityLoansSeeAll = currentValue;
    }
  }

  openNewOpportunityModal() {
    this.opportunityNewModalService.openNewOpportunityModal(this.familyId, this.clients, this.redirectParams);
  }

  redirectToOpportunity(opportunityId, loanId) {
    goToOpportunity(this.$state, { opportunityId, loanId, ...this.redirectParams });
  }

  seeAllOpportunity() {
    this.opportunityLoansSeeAll = true;

    this.onSeeAllOpportunity();
  }

  isShowPreviousLoan() {
    return this.opportunityLoansSeeAll && this.isShowPrevious;
  }

  countPreviousOpportunity() {
    return this.opportunityLoans.filter(loan => loan.IsPrevious).length;
  }
}

export default LendingOpportunityCtrl;
