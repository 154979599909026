import angular from 'angular';
import swal from 'sweetalert';
import _ from 'lodash';
import { WAIT_PERIOD, CALC_PERIOD, BENEFIT_PERIOD, EMPLOYMENT_STATUS, INSURANCE_OPTIONS } from 'Common/constants/insuranceOptions';
import { getInitialBenefitCover } from 'Common/utilities/insurance';

const app = angular.module('app');

app.factory('insuranceSharedData', [
  'contactInsuranceService', 'contactService',
  function (
    contactInsuranceService, contactService
  ) {
    const factory = this;

    factory.sharedData = {
      insurancePolicyList: [],
      insuranceProviderList: [],
      insuranceList: [],
      summaryInsuranceList: [],
      selectedColumnList: [
        { Name: 'Financial Strength', id: 1 },
        { Name: 'Rating', id: 2 },
        { Name: 'Total Premium', id: 3 },
      ],
    };

    // SHARED FUNCTIONALITIES
    factory.getNewQuoteEntityInput = (forModule, prefix) => {
      return {
        isNewClient: true,
        isSubmitted: undefined,
        Frequency: 12,
        NumberOfChildren: 0,
        NumberOfClients: 1,
        PeopleEntity: [factory.getNewPeopleEntity(true)],
        lastIndexChild: 0,
        lastIndexAdult: 0,
        TotalClientCount: 1,
        moduleName: forModule,
        forModule,
        prefix,
      };
    };

    factory.getInsurances = (familyId, status, providerId, policyNumber) => {
      contactInsuranceService.insuranceDetailsListGet(familyId, status, providerId, policyNumber).then((response) => {
        factory.sharedData.insuranceList = [];
        if (response.data && response.data.length > 0) {
          factory.sharedData.insuranceList = response.data;
        }
      });
    };

    factory.getBenefitsModel = () => {
      return [{
        BenefitId: null,
        HealthCover: {
          Loading: 1,
          Excess: 0,
          SpecialistsTest: false,
          GpPrescriptions: false,
          DentalOptical: false,
        },
      },
      getInitialBenefitCover(INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER),
      getInitialBenefitCover(INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER),
      {
        BenefitId: null,
        FamilyProtection: {
          Indexed: true,
          Loading: 1,
          CoverAmount: 0,
          BenefitPeriodTerm: 1,
          BenefitPeriodYear: 2,
        },
      },
      getInitialBenefitCover(INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER),
      getInitialBenefitCover(INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER),
      getInitialBenefitCover(INSURANCE_OPTIONS.BENEFIT_TYPE.TPD),
      getInitialBenefitCover(INSURANCE_OPTIONS.BENEFIT_TYPE.TPD),
      {
        BenefitId: null,
        IncomeProtection: {
          Indexed: true,
          Booster: false,
          CoverAmount: 0,
          Loading: 1,
          WeekWaitPeriod: WAIT_PERIOD.FOUR_WEEKS,
          BenefitPeriod: BENEFIT_PERIOD.TO_AGE_65,
          IsTaxable: 2,
        },
      },
      {
        BenefitId: null,
        MortgageRepaymentCover: {
          Indexed: true,
          Loading: 1,
          CoverAmount: 0,
          WeekWaitPeriod: 4,
          BenefitPeriod: BENEFIT_PERIOD.TO_AGE_65,
        },
      },
      {
        BenefitId: null,
        RedundancyCover: {
          Indexed: true,
          Loading: 1,
          CoverAmount: 0,
        },
      },
      {
        BenefitId: null,
        WaiverOfPremium: {
          Loading: 1,
          WeekWaitPeriod: WAIT_PERIOD.FOUR_WEEKS,
          BenefitPeriod: null,
        },
      },
      ];
    };

    factory.getNewPeopleEntity = (isPrimary = false) => {
      return {
        indexId: 0,
        ClientId: 0,
        FirstName: null,
        LastName: null,
        Gender: null,
        DateOfBirth: '',
        Age: null,
        Smoker: null,
        Occupation: 1,
        EmployedStatus: EMPLOYMENT_STATUS.EMPLOYED,
        IsChild: false,
        IsPrimary: isPrimary,
        AddedBenefitCounter: 0,
        OpenWidgetBenefits : 0,
        BenefitList: factory.getBenefitsModel(),
        isNew: true,
      };
    };

    factory.addPreviousInsurance = () => {};
    factory.editPreviousInsurance = () => {};

    factory.deleteInsurance = (familyId, policyId, caller) => {
      swal({
        title: 'Are you sure?',
        text: 'This insurance will be removed from your list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      },
      (confirm) => {
        if (confirm) {
          contactInsuranceService.insuranceDetailsDelete(familyId, policyId).then((response) => {
            if (response.data && response.data === 1) {
              factory.getFiltersList(familyId, caller);
              factory.getInsurances(familyId, caller, 0, '');
              factory.summaryInsuranceListGet(familyId);
              swal({
                type: 'success',
                title: 'Insurance successfully deleted.',
              });
            } else {
              swal({
                type: 'Error',
                title: 'Insurance was not deleted successfully.',
              });
            }
          });
        }
      });
    };

    // METHODS
    factory.getFiltersList = (familyId, status) => {
      const stat = status || 'Existing';
      contactInsuranceService.insuranceFamilyProviderGet(familyId, stat).then((response) => {
        factory.sharedData.insuranceProviderList = [{ ProviderName: 'Show All Insurers', ProviderID: 0 }];
        const data = response.data;
        if (data && data.length > 0) {
          factory.sharedData.insuranceProviderList = [{ ProviderName: 'Show All Insurers', ProviderID: 0 }, ...data];
        }
      });

      contactInsuranceService.insuranceFamilyPoliciesGet(familyId, stat).then((response) => {
        factory.sharedData.insurancePolicyList = [{ PolicyNumber: 'Show All Policies', PolicyID: '' }];
        const data = response && response.data ? response.data : [];
        if (data && data.length > 0) {
          factory.sharedData.insurancePolicyList = [{ PolicyNumber: 'Show All Policies', PolicyID: '' }, ...data];
        }
      });
    };

    factory.summaryInsuranceListGet = (familyId) => {
      if (familyId) {
        contactService.insuranceList(familyId).then((response) => {
          factory.sharedData.summaryInsuranceList = _.filter(response.data, (data) => {
            return data.InsurancStatus === 'In Force';
          });
        });
      }
    };

    return factory;
  }]);
