import angular from 'angular';
import swal from 'sweetalert';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';

angular.module('app').controller('AdditionalStaffCtrl',
  function AdditionalStaffCtrl(
    $scope,
    corporateService,
    onboardingService,
    onboardingDataService,
    toaster,
    $timeout,
    dataService) {
    $scope.additionalStaff = {};
    $scope.additionalStaffList = [];
    $scope.adviserAssistantList = [];
    $scope.isPreferredEmailValid = false;
    $scope.newAssistant = false;
    let timeOut = null;
    let adviserOrgId = 0;

    const checkEmailFn = (email, data) => {
      if (timeOut) {
        $timeout.cancel(timeOut);
      }
      let FamilyId = 0;
      timeOut = $timeout(() => {
        if (data) {
          FamilyId = data.FamilyID;
        }

        onboardingDataService.checkEmail(email, FamilyId).then((isExist) => {
          if (data) {
            if (data.Email) {
              data.isPreferredEmailValid = !isExist;
            }
          } else {
            $scope.isPreferredEmailValid = !isExist;
          }

          if ($scope.isPreferredEmailValid || (data && data.isPreferredEmailValid)) {
            angular.element(`#email${FamilyId}`).removeClass('is-required');
            angular.element(`#email${FamilyId}`).removeClass('invalid');
          } else {
            angular.element(`#email${FamilyId}`).addClass('invalid');
          }
        });
      }, 1000);
    };


    $scope.populatePreferredEmail = (email) => {
      const preferredEmailSplit = email.split('@');
      if (preferredEmailSplit.length) {
        return preferredEmailSplit[0];
      }
      return email;
    };

    const getOnboardingAdviserOrganisationDetails = () => {
      onboardingDataService.getAdviserOrgDetails().then((response) => {
        if (response) {
          const data = response;
          if (typeof data.AdviserOrganisationId !== 'undefined') {
            adviserOrgId = data.AdviserOrganisationId;
          }
        }
      });
    };

    const checkEmailEmailValidationOnInit = (data) => {
      data.forEach((value) => {
        onboardingDataService.checkEmail(value.Email, value.FamilyID).then((isEmailExist) => {
          value.isPreferredEmailValid = !isEmailExist;
        });
      });
    };

    const getAdviserAssistants = () => {
      onboardingService.getAdviserAssistants(onboardingDataService.getFamilyId()).then((response) => {
        if (!response || !response.data.length)
          return;
        checkEmailEmailValidationOnInit(response.data);
        $scope.adviserAssistantList = response.data;
      }).then(() => {
        getOnboardingAdviserOrganisationDetails();
        const dataTmp = {
          additionalStaff: $scope.adviserAssistantList,
        };
        onboardingDataService.setAllOnboardingDataForPreview(dataTmp);
      });
    };

    const resetDefault = () => {
      $scope.isPreferredEmailValid = false;
      $scope.additionalStaff.givenName = '';
      $scope.additionalStaff.surname = '';
      $scope.additionalStaff.loanMarketEmail = '';
      $scope.additionalStaff.ReqdTeamAcessLevel = 0;
      $scope.newAssistant = false;
    };

    $scope.onChangeSuggestEmail = function (givenName, surname, data) {
      let emailContainer = '';
      if (!$scope.isOwnBrand) {
        if (givenName || surname) {
          if (data) {
            data.Email = onboardingDataService.suggestPreferredEmail(givenName, surname);
            emailContainer = data.Email;
          } else {
            $scope.additionalStaff.loanMarketEmail = onboardingDataService.suggestPreferredEmail(givenName, surname);
            emailContainer = $scope.additionalStaff.loanMarketEmail;
          }
          const email = `${emailContainer}@loanmarket.com.au`;
          checkEmailFn(email, data);
        }
      }
    };

    $scope.saveAssistantPreferredEmail = (predifined, prefferedEmail, data) => {
      let email = '';

      if (prefferedEmail) {
        if (predifined) {
          email = `${prefferedEmail}@loanmarket.com.au`;
        } else {
          email = prefferedEmail;
        }
      }
      checkEmailFn(email, data);
    };

    $scope.saveAssistant = (formName, assistantFamilyId, assistantData) => {
      let data = {};
      if (assistantFamilyId > 0) {
        data = {
          ClientID: 0,
          FamilyId: assistantFamilyId,
          FirstName: assistantData.FirstName || '',
          LastName: assistantData.LastName || '',
          Email: $scope.isOwnBrand ? assistantData.Email : `${assistantData.Email}@loanmarket.com.au`,
          ReqdTeamAcessLevel: assistantData.ReqdTeamAcessLevel,
          AdviserOrganizationId: adviserOrgId,
        };
      } else {
        data = {
          ClientID: 0,
          FamilyId: 0,
          FirstName: $scope.additionalStaff.givenName || '',
          LastName: $scope.additionalStaff.surname || '',
          Email: $scope.isOwnBrand ? $scope.additionalStaff.loanMarketEmail : `${$scope.additionalStaff.loanMarketEmail}@loanmarket.com.au`,
          ContactType: 'Assistant',
          ReqdTeamAcessLevel: $scope.additionalStaff.ReqdTeamAcessLevel,
          AdviserOrganizationId: adviserOrgId,
          IsActive: 0,
        };
      }

      if (formName.$valid && $scope.isPreferredEmailValid) {
        onboardingService.setRecruitmentContact(onboardingDataService.getFamilyId(), data)
          .then((response) => {
            if (response && response.data) {
              if (assistantFamilyId <= 0) {
                resetDefault();
                getAdviserAssistants();
                swal('Success', 'Succefully added new Assistant.', 'success');
              }
            }
          });
      } else {
        swal('Required Field', 'Some field need to be filled out.', 'warning');
      }
    };
    $scope.addNewAssistant = function () {
      $scope.newAssistant = true;
    };

    $scope.cancelAssistant = function () {
      $scope.newAssistant = false;
    };

    const initialize = () => {
      $scope.isOwnBrand = onboardingDataService.isOwnBrand(BRANDING_CATEGORY_TYPE.OWN_BRAND);
      const accessLevel = dataService.getLevelAccessList();
      $scope.level_access = accessLevel.filter(access => access.id !== 3);
      getAdviserAssistants();
    };

    initialize();
  });
