import swal from 'sweetalert';
import toastr from 'toastr';
import toastrConfig from 'Common/config/toastr';

export const displayError = (e, autoClose = true) => {
  const message = (e && e.data && e.data.Message) ? e.data.Message : 'Please try again.';
  swal({
    title: 'Yikes! Something is wrong',
    text: message,
    type: 'error',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const toastError = (e) => {
  toastr.options = toastrConfig;
  const message = (e && e.data && e.data.Message) ? e.data.Message : 'Yikes! Something is wrong';
  toastr.error(message, 'Error');
};

export const toastSaveSuccess = (toasterService) => {
  toastr.options = toastrConfig;
  if (toasterService) toasterService.pop('success', 'Success', 'Successfully Saved!');
  else toastr.success('Successfully Saved!', 'Success');
};

export const toastSaveDelete = (toasterService) => {
  toastr.options = toastrConfig;
  if (toasterService) toasterService.pop('success', 'Success', 'Successfully Deleted!');
  else toastr.success('Successfully Deleted!', 'Success');
};

export const showActionConfirmation = (confirmationTitle, confirmationMessage, onActionConfirmed) => {
  swal({
    title: confirmationTitle,
    text: confirmationMessage,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes',
    closeOnConfirm: true,
  }, onActionConfirmed);
};

export const toastSuccess = (msg) => {
  toastr.options = toastrConfig;
  const message = ((msg) || (msg && msg.data && msg.data.Message)) ? (msg) || (msg && msg.data && msg.data.Message) : 'Operation successful';
  toastr.success(message, 'Success');
};
