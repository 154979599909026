import _ from 'lodash';

class CustomerCareTemplateService {
  constructor(
    contactService,
    toaster
  ) {
    'ngInject';

    this.contactService = contactService;
    this.toaster = toaster;
  }

  getEmailTemplateCategory(familyId) {
    this.contactService.getEmailTemplateCategory(familyId).then((response) => {
      const emailTemplateCategoryList = response.data;
      _.map(emailTemplateCategoryList, (item) => {
        if (!item.HasSubCategory) {
          item.Template.isSelected = false;
        } else {
          _.each(item.ListOfTemplates, (innerItem) => {
            innerItem.isSelected = false;
          });
        }
      });
      return emailTemplateCategoryList;
    });
  }

  customerCareResponseMsg(responseList, type) {
    if (type === 'SMS') {
      let errorMsgSms = '';
      let errorMsgCompletion = '';
      let errorMsg = '';
      let successMsg = '';
      _.each(responseList, (response) => {
        if (response.SmsRemarks && response.CompletionRemarks) {
          successMsg += response.SmsRemarks === 'OK' && response.CompletionRemarks === 'OK' ? response.FamilyName : '';
          errorMsgSms += response.SmsRemarks !== 'OK' ? `${response.FamilyName}, ` : '';
          errorMsgCompletion += response.CompletionRemarks !== 'OK' ? `${response.FamilyName}, ` : '';
        }
      });
      if (errorMsgSms || errorMsgCompletion) {
        if (errorMsgSms) {
          errorMsg = `Failed to send sms for ${errorMsgSms.slice(0, -2)}`;
          this.toaster.pop({
            type: 'error',
            title: 'Error',
            body: errorMsg,
            bodyOutputType: 'trustedHtml',
          });
        }
        if (errorMsgCompletion) {
          errorMsg = `Failed to set mark as complete for ${errorMsgCompletion.slice(0, -2)}`;
          this.toaster.pop({
            type: 'error',
            title: 'Error',
            body: errorMsg,
            bodyOutputType: 'trustedHtml',
          });
        }
        return false;
      }
      if (successMsg) {
        this.toaster.pop('success', 'Sent', 'SMS has been sent successfully.');
        return true;
      }
    } else if (type === 'EMAIL') {
      let errorMsgEmail = '';
      let errorMsgCompletion = '';
      let errorMsg = '';
      let successMsg = '';
      _.each(responseList, (response) => {
        if (response.EmailRemarks && response.CompletionRemarks) {
          successMsg += response.EmailRemarks === 'OK' && response.CompletionRemarks === 'OK' ? response.FamilyName : '';
          errorMsgEmail += response.EmailRemarks !== 'OK' ? `${response.FamilyName}, ` : '';
          errorMsgCompletion += response.CompletionRemarks !== 'OK' ? `${response.FamilyName}, ` : '';
        }
      });
      if (errorMsgEmail || errorMsgCompletion) {
        if (errorMsgEmail) {
          errorMsg = `Failed to send email for ${errorMsgEmail.slice(0, -2)}`;
          this.toaster.pop({
            type: 'error',
            title: 'Error',
            body: errorMsg,
            bodyOutputType: 'trustedHtml',
          });
        }
        if (errorMsgCompletion) {
          errorMsg = `Failed to set mark as complete for ${errorMsgCompletion.slice(0, -2)}`;
          this.toaster.pop({
            type: 'error',
            title: 'Error',
            body: errorMsg,
            bodyOutputType: 'trustedHtml',
          });
        }
        return false;
      }
      if (successMsg) {
        this.toaster.pop('success', 'Sent', 'Email has been sent successfully.');
        return true;
      }
    }
  }
}

export default CustomerCareTemplateService;
