import { PIPELINE_TYPES_STR, PIPELINE_VIEW_TYPES, KANBAN_OPTIONS } from 'Common/constants/pipelineType';
import { displayError } from 'Common/utilities/alert';
import { PipelineOpportunitySettingsService } from 'Common/services/pipelineSettingsService';
import { pipelineSettingsBuilderForUI, pipelineSettingsBuilderForAPI } from 'Common/mappers/pipeline';
import swal from 'sweetalert';
import { goToOpportunity } from 'Common/utilities/loanOpportunity';

export default class PipelineCtrl {
  constructor(
    opportunityNewModalService,
    loanOpportunityService,
    contactService,
    pipelineService,
    $stateParams,
    $uibModal,
    $state,
    $filter,
    $sce
  ) {
    'ngInject';

    this.opportunityNewModalService = opportunityNewModalService;
    this.loanOpportunityService = loanOpportunityService;
    this.contactService = contactService;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.pipelineService = pipelineService;
    this.$filter = $filter;
    this.$sce = $sce;
  }

  $onInit() {
    this.checkRoute();
    this.PIPELINE_TYPES_STR = PIPELINE_TYPES_STR;
    this.PIPELINE_VIEW_TYPES = PIPELINE_VIEW_TYPES;
    this.KANBAN_OPTIONS = KANBAN_OPTIONS;

    this.userInfo = {};
    this.kanbanOption = KANBAN_OPTIONS[0];
    this.getPipelineFilterSettings();
  }

  checkRoute() {
    const { pipelineType } = this.$stateParams;
    const pipelineTypes = Object.keys(PIPELINE_TYPES_STR).map((key) => {
      return String(PIPELINE_TYPES_STR[key]);
    });
    const isPipelineTypeValid = pipelineTypes.indexOf(pipelineType) !== -1;
    if (!isPipelineTypeValid) {
      this.$state.go('app.dashboard');
      return;
    }
    this.pipelineType = pipelineType;
  }

  openAddCard() {
    switch (this.pipelineType) {
    case PIPELINE_TYPES_STR.OPPORTUNITY:
      this.opportunityNewModalService.openNewOpportunityModal();
      break;
    default:
      break;
    }
  }

  switchViewType(viewType) {
    this.settings.isTableView = viewType === PIPELINE_VIEW_TYPES.TABLE;
    this.pipelineService.setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.settings));
  }

  clickPipelineItem(event, loanId, opportunityId) {
    event && event.stopPropagation();
    goToOpportunity(this.$state, { opportunityId, loanId, sourceFamilyId: 0, sourceFamilyName: '', isContactOpener: false, tabName: 'details' });
  }

  deletePipelineItem(event, loanId, successCallback, successCallbackParams) {
    event.stopPropagation();
    swal({
      title: 'Are you sure?',
      text: 'This record will be removed from your pipeline list',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, remove it!',
      closeOnConfirm: false,
    }, (confirm) => {
      if (confirm) {
        /**
        * We need to unify our pipeline delete in a single api
        * that will support all pipeline types (Leads, Opportunity, Applications, etc.)
        */
        this.contactService.loanDelete(loanId)
          .then(() => {
            successCallback && successCallback(successCallbackParams);
          })
          .catch((err) => {
            displayError(err);
          })
          .finally(() => {
            swal({
              title: 'Success',
              text: 'Loan has been been successfully deleted.',
              type: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  }

  getPipelineFilterSettings(callback, callbackParams = {}) {
    // Send a function on callback
    this.pipelineService.getPipelineCardFilters(false, true)
      .then((res) => {
        const { data } = res;
        if (data.length && data[0] && data[0].AdvisorId) {
          this.settings = new PipelineOpportunitySettingsService(pipelineSettingsBuilderForUI(data[0]), { pipelineService: this.pipelineService });
        } else {
          this.createNewSettings();
        }
      }).then(() => {
        if (typeof callback === 'function') {
          callback(callbackParams);
        }
      });
  }

  createNewSettings() {
    this.contactService.getUserInfo()
      .then((res) => {
        const { data } = res;
        if (!data) return;
        const { FamilyId: advisorId } = data;
        this.settings = new PipelineOpportunitySettingsService({ advisorId }, { pipelineService: this.pipelineService });
      });
  }
}
