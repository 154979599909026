import { briSimulateBuilderForUI } from 'Common/mappers/briMapper';

class BriServices {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'bri';
  }

  postAuth() {
    return this.httpClient.post(`${this.apiBaseUrl}/auth`);
  }

  postSimulate() {
    return this.httpClient.post(`${this.apiBaseUrl}/simulate`)
      .then(response => briSimulateBuilderForUI(response.data));
  }

  postScoring() {
    return this.httpClient.post(`${this.apiBaseUrl}/scoring`)
      .then(response => briSimulateBuilderForUI(response.data));
  }
}

export default BriServices;
