import angular from 'angular';

class MatchCommissionCtrl {
  constructor($uibModalInstance, commission, familyIdToMatch) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.commission = commission;
    this.familyIdToMatch = familyIdToMatch;
  }

  onCommissionMatched(commission) {
    this.$uibModalInstance.close({ success: true, matchedCommission: commission });
  }
}

angular.module('app').controller('MatchCommissionCtrl', MatchCommissionCtrl);
