class BreadCrumbCtrl {
  constructor(
    $timeout,
    contactService,
    $state
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.contactService = contactService;
    this.$state = $state;
  }

  openContactSummary() {
    this.$state.go('app.contactsInsurances', { familyId: this.moduleData.FamilyID, activeTab: 'insurance' });
  }
}

export default BreadCrumbCtrl;
