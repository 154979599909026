export function leadsProfileSetBuilderForAPI() {
  return {
    ProfileId: 0,
    FamilyId: 0,
    ClientId: 0,
    ReceiveStatus: 0,
    Priority: 0,
    AllocationWorkingdays: '',
    AllocationCapPerDay: 0,
    AllocationCapPerMonth: 0,
    Distance: 0,
    LoanType: '',
    Comments: '',
    WMReferralAlloc: true,
    SendNotificationClient: true,
    SendNotificationBroker: true,
    CorpLeadCampaignIDs: '',
    IncludeCalendlyLinkInAutoEmails: false,
    CalendlyLink: '',
  };
}
