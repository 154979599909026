export const ENTITY_PARTY_TYPES = {
  COMPANY: 2,
  PARTNERSHIP: 7,
};

export const PERSON_PARTY_TYPES = {
  APPLICANT: 1,
  GUARANTOR: 3,
  DEPENDENT: 4,
  SOLICITOR: 5,
  ACCOUNTANT: 6,
};

export const INVOLVED_PARTIES_TYPE = {
  ENTITY: 1,
  CLIENT_ENTITY: 2,
  CLIENT: 3,
};

export const CLIENT_PARTY_TYPES = {
  COMPANY: 3,
};

export const BORROWER_PARTY_TYPES = {
  PERSON: 3,
};


export const COMPANY_TYPES = {
  COMPANY: 1,
  TRUST: 2,
  COMPANY_STRING: 'Company',
  TRUST_STRING: 'Trust',
};

export const CONTACT_SEARCH_PARTY_TYPES = {
  TRUST_COMPANIES: 1,
  PROFESSIONAL: 3,
};

export default { ENTITY_PARTY_TYPES, PERSON_PARTY_TYPES, CLIENT_PARTY_TYPES, BORROWER_PARTY_TYPES, INVOLVED_PARTIES_TYPE, COMPANY_TYPES, CONTACT_SEARCH_PARTY_TYPES };
