import template from './crmTableViewOpportunity.html';
import controller from './crmTableViewOpportunityCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    tableData: '<',
    tableHeaders: '<',
    freezeColumns: '<',
    filterSettings: '<',
    totalRecords: '<',
    showLoader: '<',
    pageSize: '<',
    pageNumber: '<',
    onPageChange: '&',
    onDeletePipelineItem: '&',
    onRedirectToOpportunity: '&',
    onRefreshTable: '&',
  },
};
