import angular from 'angular';
import _ from 'lodash';
import { FINANCIAL_OBJECTS } from 'Common/constants/financialAssets';
import { parseIntValue } from 'Common/utilities/parse';
import { checkIfInvestment } from 'Common/utilities/security';

angular.module('app').controller('LoanAppFinancialAssetsCtrl',
  function LoanAppFinancialAssetsCtrl($rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    optionsService,
    SweetAlert,
    dashboardService,
    loanAppSharedData,
    financialsSharedService,
    insuranceFinancialsSharedService,
    uiService,
    financeService,
    loanApplicantAssetInfoService,
    currentUserService,
    contactModelService) {
    $scope.uiService = uiService;
    const defaultReaValuesAU = financeService.getDefaultREAAU();
    const defaultMortgageValuesAU = financeService.getDefaultMortgageAU();
    /* NOTICE: this module is also being used by Insurance Application - rolandbarro */
    $scope.callerApp = '';
    $scope.displayAssetsForm = false;

    if ($stateParams.insAppId) {
      $scope.callerApp = 'insurance';
    }
    const setDefaultValuesMethod = (fieldName) => {
      switch (fieldName) {
      case 'all':
        $scope.AssetsSet.ZoningId = parseInt(defaultReaValuesAU.zoning, 10);
        $scope.AssetsSet.PrimaryPurposeId = parseInt(defaultReaValuesAU.primaryPurposeId, 10);
        break;
      case 'zoning':
        $scope.AssetsSet.ZoningId = parseInt(defaultReaValuesAU.zoning, 10);
        break;
      case 'primaryPurposeId':
        $scope.AssetsSet.PrimaryPurposeId = parseInt(defaultReaValuesAU.primaryPurposeId, 10);
        break;
      case 'propertyTypeId':
        $scope.AssetsSet.PropertyTypeId = parseInt(defaultReaValuesAU.propertyTypeId, 10);
        break;
      default:
        break;
      }
    };
    $scope.financialsSharedService = ($scope.callerApp !== 'insurance' ? financialsSharedService : insuranceFinancialsSharedService);
    $scope.loanAppSharedData = loanAppSharedData;
    const lenderPlace = 1;
    $scope.listRepaymentFrequency = [];
    optionsService.FinancialFrequency().then((response) => {
      const list = _.map(response.data, (o) => {
        o.Desc = o.Name;
        o.Value = parseInt(o.Value, 10);

        return o;
      });

      $scope.listRepaymentFrequency = list;
    });

    // Models
    angular.extend($scope, {
      loanAppId: $stateParams.loanAppId,
      familyId: $stateParams.familyId,
      assetLoading: false,
      tooltipDisplay: false,
      hasAsset: false,
      listAssets: [],
      listLender: [],
      subAssetTypes: [],
      assetTypes: [],
      displayAssetsForm: false,
      modalType: false,
      formValidation: false,
      isInvalidAssetDescription: false,
      isInvalidAssetType: false,
      isInvalidAssetAmount: false,
      //* ************************************/
      //  owners multi Select                /
      //* ************************************/
      localLang: {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: '', // default-label is deprecated and replaced with this.
        // nothingSelected: "<div class='buttonLabel'>All Clients</div>"         //default-label is deprecated and replaced with this.
      },
      ownersList: [],
      ownersListSelected: [],
      ownersListSelectedArray: [],
      ListPropertyType: [],
      AssetTotalAmount: 0,
      OFX: {
        ConvertedAmount: null,
      },


    });

    const assetValidation = () => {
      const filterAssets = _.find($scope.financialsSharedService.listAssets, (obj) => {
        return obj.FamilyId === parseInt($scope.financialsSharedService.selectedFamily.FamilyId, 10);
      }) || { overview: { FinancialInfo: [] } };
      if (filterAssets.overview.FinancialInfo.length > 0) {
        const assetFiltered = _.filter(filterAssets.overview.FinancialInfo, (obj) => {
          return obj.Type !== 'Real Estate';
        });
        const estateAssetFiltered = _.filter(filterAssets.overview.FinancialInfo, (obj) => {
          return obj.Type === 'Real Estate';
        });

        $scope.hasEstateAsset = estateAssetFiltered.length > 0;
        $scope.hasAsset = assetFiltered.length > 0;
      } else {
        $scope.hasAsset = false;
        $scope.hasEstateAsset = false;
      }
    };

    $scope.$watch(($scope.callerApp !== 'insurance' ? 'financialsSharedService.listAssets' : 'insuranceFinancialsSharedService.listAssets'), () => {
      assetValidation();
    }, true);

    $scope.$watch(($scope.callerApp !== 'insurance' ? 'financialsSharedService.selectedFamily.FamilyId' : 'insuranceFinancialsSharedService.listAssets'), () => {
      assetValidation();
    });

    $scope.getAssetModelData = () => {
      $scope.resetAssetModel('edit', $scope.financialsSharedService.selectedFamily.FamilyId);
      $scope.AssetClientGet(undefined, $scope.financialsSharedService.selectedFamily.FamilyId);
    };

    const runReqProcess = () => {
      $scope.getAssetModelData();
    };

    // Methods
    angular.extend($scope, {
      getAddressList() {
        $scope.propertyList = _.filter(loanAppSharedData.addressList, (obj) => {
          return obj;
        });
      },
      displayAssetFormCallback(isRealEst) {
        const isRealEstate = typeof isRealEst === 'undefined' ? false : isRealEst;
        $scope.assetLoading = true;
        $timeout(() => {
          $scope.displayAssetsForm = true;
          $scope.assetLoading = false;
          $scope.resetAssetModel();
          $scope.tooltipDisplay = true;
          if (isRealEstate && !$scope.hasEstateAsset) {
            $scope.AssetsModal('new', true);
            $scope.displayAssetsForm = false;
          }
          $timeout(() => {
            $scope.tooltipDisplay = false;
          }, 6500);
        }, 2000);
      },
      hideToolTip() {
        $scope.tooltipDisplay = false;
      },
      displayAssetsFormFunc() {
        $scope.displayAssetsForm = true;
        $scope.resetAssetModel();
      },
      hideAssetForm() {
        $scope.displayAssetsForm = false;
      },
      getNewRentalIncomeModel(index) {
        return {
          IsEvidenceOfTenancy: true,
          Id: 0,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          Type: '',
          Description: '',
          Amount: null,
          CurrencyCode: ($rootScope.crmCurrencyCode), // temp values
          Category: '',
          CurrencyCodeId: ($rootScope.crmCountry === 'Australia' ? '8' : '106'), // temp values
          Borrowers: [],
          FrequencyID: null,
          FrequencyName: '',
          Provider: '',
          IncomeSubTypeId: 1,
          RentalVerificationID: '',
          index,
        };
      },
      getNewMortgageLiabilityModel(index) {
        return {
          IsRefinance: false,
          Ownership: [],
          AccountName: '',
          AccountNumber: '',
          BSB: '',
          Limit: null,
          CreditorId: null,
          InterestTaxDeductible: null,
          RepaymentFrequencyId: 4,
          Repayments: null,
          InterestRate: null,
          IsAddToExpenses: true,
          MortgagePriorityId: defaultMortgageValuesAU.mortgagePriorityId || null,
          Expense: {},
          Id: 0,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          Type: '',
          Description: '',
          Amount: null,
          CurrencyCode: ($rootScope.crmCurrencyCode), // temp values
          Category: '',
          CurrencyCodeId: ($rootScope.crmCountry === 'Australia' ? '8' : '106'), // temp values
          Borrowers: [],
          FrequencyID: 0,
          FrequencyName: '',
          Provider: '',
          LoanTerm: defaultMortgageValuesAU.loanTerm || 30,
          principalInterest: defaultMortgageValuesAU.principalInterest || 2,
          index,
        };
      },
      getNewAssetModel() {
        return {
          AssetSubtypeId: 1,
          ValueBasisId: 1,
          Value: null,
          AccountName: '',
          AccountNumber: '',
          BSB: '',
          Make: '',
          Year: 0,
          PrimaryPurposeId: null,
          PropertyType: '',
          Zoning: '',
          PropertyTypeId: 0,
          ZoningId: null,
          IsRentalIncome: false,
          IsExistingMortgages: false,
          IsAccountDetailsKnown: false,
          Address: {
            IsMailing: true,
            AddressID: '0',
            Type: '',
            TypeId: '',
            formatted_address: '',
            AddressValuationExtendedDetailsId: 0,
          },
          RentalIncome: [],
          MortgageLiability: [],
          Id: 0,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          Type: '',
          TypeId: 0,
          Description: '',
          CurrencyCode: ($rootScope.crmCurrencyCode),
          XECountryCode: ($rootScope.crmCountry === 'Australia' ? 'au' : 'nz'), // temp values
          Category: '',
          CurrencyCodeId: ($rootScope.crmCountry === 'Australia' ? '8' : '106'), // temp values
          Borrowers: [],
          Provider: '',
          ShowCurrencyConversion: false,
        };
      },
      AssetInfoGet(isInitialize) {
        financialsSharedService.AssetInfoGet(!isInitialize);
      },
      changeSelectedFamily() {
        let family = _.find($scope.financialsSharedService.listAssets, (o) => {
          return _.isEqual(o.FamilyId, $scope.financialsSharedService.selectedFamily.FamilyId);
        });
        if (!family) {
          family = {
            FamilyName: '',
          };
        }
        angular.extend($scope.financialsSharedService.selectedFamily, {
          initials: utilitiesService.filterInitialOneString(family.FamilyName),
        });
      },
      AssetInfoDelete(assetId, isFromREA) {
        SweetAlert.swal({
          title: 'Are you sure?',
          text: 'You will also remove all securities for this asset.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            loanScenarioService.AssetInfoDelete($scope.loanAppId, assetId).then(() => {
              $timeout(() => {
                $scope.assetsInit(false);
                if (isFromREA) {
                  loanAppSharedData.REFRESH_FUNDING_REQUIRED_SECTION = { status: true };
                  $scope.financialsSharedService.IncomeInfoGet();
                  $scope.financialsSharedService.LiabilityInfoGet();
                }
                SweetAlert.swal({
                  title: 'Success',
                  text: 'Asset and securities has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }, 100);
            });
          }
        });
      },
      AssetDetailsGet(familyId, assetId, isRealEstate) {
        if (_.isUndefined(assetId))
          return false;

        loanScenarioService.AssetDetailsGet(familyId, $scope.loanAppId, assetId).then((response) => {
          // reset the actual model
          $scope.resetAssetModel('edit', familyId);

          // patch api data to actual model
          angular.extend($scope.AssetsSet, response.data);

          // patch temporary type to actual model
          angular.extend($scope.AssetsSet, {
            Type: _.find($scope.assetTypes, (o) => {
              return o.Desc === $scope.AssetsSet.Type;
            }),
            PrimaryPurposeId: $scope.AssetsSet.PrimaryPurposeId ? parseInt($scope.AssetsSet.PrimaryPurposeId, 10) : null,
            ValueBasisId: $scope.AssetsSet.ValueBasisId ? parseInt($scope.AssetsSet.ValueBasisId, 10) : null,
            Value: $scope.AssetsSet.Value,
            FullAddress: $scope.AssetsSet.Address,
            Year: $scope.AssetsSet.Year ? parseInt($scope.AssetsSet.Year, 10) : null,
            PropertyTypeId: $scope.AssetsSet.PropertyTypeId ? parseInt($scope.AssetsSet.PropertyTypeId, 10) : null,
            PropertyTypeId_original: parseInt($scope.AssetsSet.PropertyTypeId, 10),
            ZoningId: $scope.AssetsSet.ZoningId ? parseInt($scope.AssetsSet.ZoningId, 10) : null,
            AssetSubtypeId: $scope.AssetsSet.AssetSubtypeId ? parseInt($scope.AssetsSet.AssetSubtypeId, 10) : 1,
          });

          _.map($scope.AssetsSet.MortgageLiability, (obj) => {
            obj.MortgagePriorityId = obj.MortgagePriorityId ? parseInt(obj.MortgagePriorityId, 10) : null;
            obj.CreditorId = obj.CreditorId ? String(obj.CreditorId) : null;
            return obj;
          });

          _.map($scope.AssetsSet.RentalIncome, (obj) => {
            obj.IncomeSubTypeId = parseInt(obj.IncomeSubTypeId, 10) || null;
            return obj;
          });

          // country code map
          // find the ofx object first
          const ofx = _.find($scope.OFXSupportCurrency, (ofxObject) => {
            return _.trim(ofxObject.Value) === _.trim($scope.AssetsSet.CurrencyCode);
          });

          if (ofx) {
            // find the country so we could get the country code
            const country = _.find($scope.countryListGet, (c) => {
              return _.trim(c.Name) === _.trim(ofx.Name);
            });
            if (country) {
              $scope.AssetsSet.XECountryCode = country.XECountryCode;
            } else {
              $scope.AssetsSet.XECountryCode = '';
            }
          }


          // Get clients of particular asset
          const filteredBorrowers = $scope.AssetsSet.Borrowers && $scope.AssetsSet.Borrowers.filter(object => object.IsTicked);
          $scope.AssetClientGet($scope.AssetsSet.Id, familyId, [...filteredBorrowers]);
          if (financeService.hasSubType($scope)) {
            $scope.getSubAssetType();
          }
          // Open the edit modal
          $scope.AssetsModal('edit', isRealEstate);
          $scope.checkIfInvestment();
        });
      },
      AssetClientGet(assetId, familyId, borrowers) {
        if (!familyId) return;
        const tmp = [];

        loanScenarioService.AssetClientGet(familyId, $scope.loanAppId, assetId).then((respond) => {
          $scope.ownersListSelected = borrowers || [];
          const temp = {
            Percentage: 0,
            BorrowerID: 0,
            FirstName: 'All',
            LastName: 'Clients',
            PreferedName: 'Joint',
            ticked: true,
            disabled: true,
          };
          if ($scope.ownersListSelected.length < 1 || ($scope.ownersListSelected.length === respond.data.length)) {
            respond.data && Object.keys(respond.data).forEach((x) => {
              const value = respond.data[x];
              value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
              tmp.push(value);
            });
            if (tmp.length === 1) {
              tmp[0].ticked = true;
            } else {
              tmp.unshift(temp);
            }
            $scope.ownersList = tmp;
          } else {
            temp.ticked = false;
            // Set default selected from edit
            // @TODO : Edit should match a borrowers array
            if (_.size(borrowers) <= _.size(respond.data)) {
              respond.data && Object.keys(respond.data).forEach((x) => {
                const value = respond.data[x];
                value.ticked = (!!_.find(borrowers, (o) => {
                  return o.BorrowerID === value.BorrowerID;
                }));
                value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
                tmp.push(value);
              });
            } else {
              respond.data && Object.keys(respond.data).forEach((x) => {
                const value = respond.data[x];
                value.ticked = value.BorrowerID === -1;
                value.PreferedName = value.IsEntity ? value.FirstName : `${value.FirstName} ${value.LastName}`;
                tmp.push(value);
              });
            }
            if (respond.data.length > 1) {
              tmp.unshift(temp);
            }

            $scope.ownersList = tmp;
          }
        });
      },
      isOtherTicked(dataArray) {
        let bool = false;
        dataArray.forEach((val) => {
          if (val.BorrowerID > -1 && val.ticked) {
            bool = true;
          }
        });
        return bool;
      },
      onItemSelect(data) {
        let isJointFamily = true;
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ((typeof $scope.ownersList[i].ticked === 'undefined' || !$scope.ownersList[i].ticked) && $scope.ownersList[i].BorrowerID >= 1) {
            isJointFamily = false;
          }
        }

        // will be true if user clicks on joint
        if (data.BorrowerID === 0) {
          isJointFamily = true;
        }
        if (!isJointFamily) {
          $scope.ownersList = _.map($scope.ownersList, (obj) => {
            if (obj.BorrowerID === 0) {
              obj.ticked = false;
            }
            return obj;
          });
        } else {
          const findJoint = _.find($scope.ownersList, (obj) => {
            return obj.BorrowerID === 0;
          });
          // need to check if single owner
          if (!_.isEmpty(findJoint)) {
            $scope.ownersList = _.map($scope.ownersList, (obj) => {
              obj.ticked = obj.BorrowerID === 0;
              return obj;
            });
          }
        }
      },
      onSelectAll() {
        const findJoint = _.find($scope.ownersList, (obj) => {
          return obj.BorrowerID === 0;
        });
        // need to check if single owner
        if (!_.isEmpty(findJoint)) {
          $scope.ownersList = _.map($scope.ownersList, (obj) => {
            obj.ticked = obj.BorrowerID === 0;
            return obj;
          });
        } else {
          for (let i = 0; i < $scope.ownersList.length; i++) {
            if ($scope.ownersList[i].BorrowerID > -1) {
              $scope.ownersList[i].ticked = false;
            } else {
              $scope.ownersList[i].ticked = true;
            }
          }
        }
      },
      LenderListGet(LoanAppLenderId) {
        loanApplicantAssetInfoService.lenderListGet(LoanAppLenderId, lenderPlace).then((lenderListResponse) => {
          $scope.listLender = lenderListResponse.data;
        });
      },
      resetAssetModel(type, famId) {
        let familyId = famId;
        if (type !== 'edit')
          familyId = $scope.familyId;

        $scope.AssetsSet = $scope.getNewAssetModel(familyId);
      },
      assetsInit(isFull) {
        $scope.financialsSharedService.listAssets = [
          {
            FamilyId: '',
            overview: {
              FinancialInfo: [],
            },
          },
        ];

        $scope.resetAssetModel();
        $scope.AssetInfoGet(isFull);
        $scope.AssetClientGet(undefined, $scope.financialsSharedService.selectedFamily.FamilyId);

        optionsService.AssetValueBasisGet().then((response) => {
          const { data } = response;
          if (data) {
            $scope.ValueBasisList = parseIntValue(data);
            $scope.AssetsSet.ValueBasisId = _.first($scope.ValueBasisList).Value.toString();
          }
        });

        if (isFull) {
          $scope.LenderListGet();
          optionsService.getZoningType().then((response) => {
            const ZoningList = parseIntValue(response.data);
            $scope.ZoningList = [{ Name: 'Please select', Value: null }, ...ZoningList];
            $scope.AssetsSet.ZoningId = null;

            if (uiService.isCountry('Australia')) {
              setDefaultValuesMethod('zoning');
            }
          });

          $scope.ListPropertyType = [{ Name: 'Please select Zoning first', Value: '' }];
          $scope.AssetsSet.PropertyTypeId = '';

          optionsService.getPrimaryPurposeType().then((response) => {
            $scope.LoanPurposeList = parseIntValue(response.data);

            if (uiService.isCountry('Australia')) {
              setDefaultValuesMethod('primaryPurposeId');
            }
          });

          optionsService.MortgagePriorityGet().then((response) => {
            $scope.MortgagePriorityList = parseIntValue(response.data);
          });

          // Get country list first before calling supported currencies
          optionsService.countryListGet().then((response) => {
            $scope.countryListGet = response.data;
          });

          optionsService.AssetTypeGet().then((response) => {
            $scope.assetTypes = financeService.mapAssetType(response.data);
          });

          contactService.lenderListGet(undefined, lenderPlace).then((response) => {
            $scope.CreditorList = response.data;
          });
        }
      },
      changeAsset() {
        financeService.changeAssetType($scope);
      },
      getSubAssetType() {
        financeService.getSubAssetType($scope);
      },
      addAsset(isModal, modalType) {
        const isRemoveIncomeInfo = $scope.AssetsSet.PrimaryPurposeId === FINANCIAL_OBJECTS.PRIMARY_PURPOSE_ID && $scope.AssetsSet.RentalIncome.length && !currentUserService.isNZ;
        if (isRemoveIncomeInfo) {
          angular.forEach($scope.AssetsSet.RentalIncome, (income) => {
            loanScenarioService.IncomeInfoDelete($scope.loanAppId, income.Id);
          });
        }
        const amountTest = parseInt($scope.AssetsSet.Value, 10);
        if (($scope.AssetsSet.Type === '' || Number.isNaN(amountTest)) && typeof modalType === 'undefined') {
          $scope.isInvalidAssetType = $scope.AssetsSet.Type === '';
          $scope.isInvalidAssetAmount = Number.isNaN(amountTest);
          $scope.formValidation = true;
          return false;
        }
        $scope.hideAssetForm();
        const joinFamilyObj = _.find($scope.ownersList, (obj) => {
          return obj.BorrowerID === 0;
        });

        if (joinFamilyObj) {
          if (joinFamilyObj.ticked) {
            $scope.ownersListSelected = _.map($scope.ownersList, (obj) => {
              if (obj.BorrowerID !== 0) {
                obj.ticked = true;
                return obj;
              }
            });
          }
        } else {
          $scope.ownersListSelected = _.filter($scope.ownersList, (obj) => {
            return obj.ticked === true;
          });
        }
        $scope.AssetsSet.Borrowers = [];
        $scope.ownersList.forEach((data) => {
          if (data.ticked && data.BorrowerID !== 0) {
            $scope.AssetsSet.Borrowers.push({
              Percentage: 0,
              BorrowerID: data.BorrowerID,
              IsEntity: data.IsEntity,
            });
          }
        });

        $scope.AssetsSet.TypeId = $scope.AssetsSet.Type.Value;
        $scope.AssetsSet.Type = $scope.AssetsSet.Type.Desc;
        $scope.AssetsSet.Amount = angular.copy($scope.AssetsSet.Value);

        $scope.AssetsSet.Year = parseInt($scope.AssetsSet.Year, 10);

        if (_.size($scope.AssetsSet.RentalIncome) > 0)
          $scope.AssetsSet.IsRentalIncome = true;
        else
          $scope.AssetsSet.IsRentalIncome = false;

        if (_.size($scope.AssetsSet.MortgageLiability) > 0)
          $scope.AssetsSet.IsExistingMortgages = true;
        else
          $scope.AssetsSet.IsExistingMortgages = false;

        // action taken : change the familyIds of the model
        angular.extend($scope.AssetsSet, {
          FamilyId: $scope.financialsSharedService.selectedFamily.FamilyId.toString(),
        });

        angular.extend($scope.AssetsSet.RentalIncome, {
          FamilyId: $scope.financialsSharedService.selectedFamily.FamilyId.toString(),
        });

        angular.extend($scope.AssetsSet.MortgageLiability, {
          FamilyId: $scope.financialsSharedService.selectedFamily.FamilyId.toString(),
        });

        if (isModal && !_.isUndefined($scope.AssetsSet.FullAddress)) {
          const getType = _.find($scope.assetTypes, (o) => {
            return o.Value === parseInt($scope.AssetsSet.TypeId, 10);
          });

          if (!_.isUndefined(getType) && getType.FormGroup === 'Real Estate') {
            angular.extend($scope.AssetsSet, {
              Address: {},
            });

            let copyAssetSetTemp = {};
            copyAssetSetTemp = angular.copy($scope.AssetsSet);

            dashboardService.addressDetailsSet(copyAssetSetTemp.FullAddress).then((response) => {
              const id = response.data;
              copyAssetSetTemp.FullAddress.AddressID = id;
              copyAssetSetTemp.Address = angular.copy(copyAssetSetTemp.FullAddress);
              angular.extend($scope.AssetsSet, copyAssetSetTemp);
              loanScenarioService.AssetDetailsSet($scope.AssetsSet).then(() => {
                $scope.financialsSharedService.IncomeInfoGet();
                $scope.financialsSharedService.LiabilityInfoGet();
                loanAppSharedData.REFRESH_FUNDING_REQUIRED_SECTION = { status: true };
                $scope.assetsInit(false);
              });
            });
          } else {
            loanScenarioService.AssetDetailsSet($scope.AssetsSet).then(() => {
              $scope.assetsInit(false);
            });
          }
        } else {
          loanScenarioService.AssetDetailsSet($scope.AssetsSet).then(() => {
            $scope.assetsInit(false);
          });
        }
      },
      AssetsModal(type, isRealEstate) {
        if (type === 'new') {
          loanApplicantAssetInfoService.updateIds($scope.AssetsSet, $scope.ValueBasisList);
          setDefaultValuesMethod('all');
        }

        if (!$scope.AssetsSet.CurrencyCode) {
          $scope.AssetsSet.CurrencyCode = uiService.getCrmCurrencyCode();
        }

        const templateString = isRealEstate ?
          '/assets/views/loanApplication/clientAssess/modal/real_estate.add.html' :
          '/assets/views/loanApplication/clientAssess/modal/asset.add.html';
        const modalInstance = $uibModal.open({
          templateUrl: templateString,
          controller: 'LoanappAssetModalCtrl',
          size: 'md',
          scope: $scope,
          resolve: {
            modalType() {
              return type;
            },
            isRealEstate() {
              return isRealEstate;
            },
          },
          backdrop: 'static',
          keyboard: false,
          windowClass: 'asset-modal',
        });

        modalInstance.result.then(() => {
          $scope.getAssetModelData();
        }, () => {
          runReqProcess();
        });
      },
      selectAsset(familyId, assetId, isRealEstate) {
        $scope.AssetDetailsGet(familyId, assetId, isRealEstate);
      },
      selectAssetTemp(asset) {
        angular.extend($scope.AssetsSet, asset);
        $scope.AssetsSet.Type = _.filter($scope.assetTypes, (o) => {
          return o.Desc === $scope.AssetsSet.Type;
        })[0];
        $scope.AssetsModal('edit');
      },
      AssetInfoDeleteTemp(asset) {
        _.remove($scope.listAssets[0].overview.FinancialInfo, (obj) => {
          return _.isEqual(obj, asset);
        });
      },
      changeCurrencyCode(code, XECountryCode, CurrencyCodeId) {
        $scope.AssetsSet.CurrencyCode = code;
        $scope.AssetsSet.XECountryCode = XECountryCode;
        $scope.AssetsSet.CurrencyCodeId = CurrencyCodeId.toString();
      },
      checkIfInvestment() {
        $scope.isInvestment = checkIfInvestment($scope.AssetsSet.PrimaryPurposeId);
      },
    });

    $scope.$watch(
      'AssetsSet.ZoningId',
      (nv) => {
        if (nv !== '') {
          optionsService.getPropertyType(nv).then((response) => {
            if (!response || !response.data) return;

            const types = _.map([...response.data], (o) => {
              o.Name = o.AddressPropertyType;
              o.Value = o.AddressPropertyTypeID;
              return o;
            });

            $scope.ListPropertyType = [{ Name: 'Please select', Value: null }, ...types];
            $scope.AssetsSet.PropertyTypeId = null;

            if (_.find($scope.ListPropertyType, (o) => {
              return parseInt(o.AddressPropertyTypeID, 10) === parseInt($scope.AssetsSet.PropertyTypeId_original, 10);
            })) {
              $scope.AssetsSet.PropertyTypeId = angular.copy($scope.AssetsSet.PropertyTypeId_original);
              $scope.AssetsSet.PropertyTypeId = $scope.AssetsSet.PropertyTypeId;
            } else {
              $scope.AssetsSet.PropertyTypeId = '';
            }

            if (nv === defaultReaValuesAU.zoning && uiService.isCountry('Australia') && !$scope.AssetsSet.PropertyTypeId) {
              setDefaultValuesMethod('propertyTypeId');
            }
          });
        }
      }
    );
    // Watch for changes in selected family
    $scope.$watch('financialsSharedService.selectedFamily.FamilyId', (nv) => {
      $scope.AssetClientGet(undefined, nv);
      if ($scope.callerApp !== 'insurance') {
        financialsSharedService.updateAsset(nv);
      } else {
        $scope.financialsSharedService.updateAsset(nv);
      }
    });


    // caller
    $scope.assetsInit(true);

    $scope.$watch('loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS', (nv) => {
      if (nv && _.size(nv) > 0 && nv.RefreshAsset) {
        $scope.assetsInit(true);
        nv.RefreshAsset = false;
      }
    });

    $scope.$on('$destroy', () => {
      financialsSharedService.clearAssetInfo();
    });
    const init = () => {
      contactModelService.getSubIncomeType()
        .then((response) => {
          if (!response) return;

          $scope.incomeSubTypeSample = response;
        });
    };
    init();
  });
