import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { haversineInKM } from 'Common/utilities/math';

angular.module('app').controller('SummarySearchNearbyCtrl', function SummarySearchNearbyCtrl(
  $scope, $uibModalInstance, postingCode, contactService, corporateService, mapService, generalService, $compile, broadcastDataService, $timeout, $window
) {
  contactService.contactFamilyInfoGet($scope.familyId).then((response) => {
    $scope.getContactFamilyInfo = response.data;
  });

  $scope.clientPostCode = postingCode;

  let zoomValue = 8;
  $scope.addressModel = {};
  $scope.addressModel.postalSearch = postingCode;
  $scope.noPostalCode = false;

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.$watch('mapModel.map.isCancelledTriggered', () => {
    if ($scope.mapModel.map.isCancelledTriggered) {
      $uibModalInstance.dismiss('cancel');
      $scope.mapModel.map.isCancelledTriggered = false;
    }
  });

  function initialLoading() {
    const getSummarySearchNearAdviserDetail = 0;
    const summarySearchNearbyWithGeoCode = 1;
    const summarySearchValidateStatusArr = (typeof $scope.statusArrayId !== 'undefined' ? $scope.statusArrayId.join() : '1');
    const summarySearchValidatePriorityIds = (typeof $scope.priorityIds !== 'undefined' ? $scope.priorityIds.join() : '');
    const summarySearchValidateCampaignListId = (typeof $scope.campaignListId !== 'undefined' ? $scope.campaignListId.join() : '', '1,5');
    corporateService.advisersGet(0, 0, 1,
      getSummarySearchNearAdviserDetail,
      summarySearchNearbyWithGeoCode,
      summarySearchValidateStatusArr,
      summarySearchValidatePriorityIds,
      summarySearchValidateCampaignListId)
      .then((adviser) => {
        $scope.allocatedAdviserMap = _.uniqBy(adviser.data, 'FamilyId');

        $scope.addressModel = {};
        let $adviserList = [];

        const cappedAdviser = $scope.allocatedAdviserMap;
        $adviserList = cappedAdviser;

        $scope.clientDetailsLocation = [];

        $scope.adviserSelected = function (data) {
          $scope.selectedAdviser = data;
        };

        $scope.addressModel.postalSearch = $scope.clientPostCode;

        function loadMapDetails(geocoded) {
          $scope.distance = [];
          const latitude = geocoded.latitude || 0;
          const longitude = geocoded.longitude || 0;
          const formattedAddress = geocoded.formatted_address || 0;

          $adviserList && Object.keys($adviserList).forEach((x) => {
            if ($adviserList[x].BusinessAddressLatitude && $adviserList[x].BusinessAddressLongitude) {
              $scope.distance.push({
                distance: haversineInKM(latitude, longitude, $adviserList[x].BusinessAddressLatitude, $adviserList[x].BusinessAddressLongitude),
                details: $adviserList[x],
                address: $adviserList[x].BusinessAddressFormatted,
                lat: $adviserList[x].BusinessAddressLatitude,
                lng: $adviserList[x].BusinessAddressLongitude,
              });
            } else if ($adviserList[x].HomeAddressLatitude && $adviserList[x].HomeAddressLongitude) {
              $scope.distance.push({
                distance: haversineInKM(latitude, longitude, $adviserList[x].HomeAddressLatitude, $adviserList[x].HomeAddressLongitude),
                details: $adviserList[x],
                address: $adviserList[x].HomeAddressFormatted,
                lat: $adviserList[x].HomeAddressLatitude,
                lng: $adviserList[x].HomeAddressLongitude,
              });
            } else if ($adviserList[x].PostalAddressLatitude && $adviserList[x].PostalAddressLatitude) {
              $scope.distance.push({
                distance: haversineInKM(latitude, longitude, $adviserList[x].PostalAddressLatitude, $adviserList[x].PostalAddressLongitude),
                details: $adviserList[x],
                address: $adviserList[x].PostalAddressFormatted,
                lat: $adviserList[x].PostalAddressLatitude,
                lng: $adviserList[x].PostalAddressLongitude,
              });
            }
          });
          $scope.locations = [];
          let toolTipContent;
          let compiled;
          if ($scope.distance.length) {
            const summarySearchloctmp = [];
            const summarySearchObj = {};
            $scope.locations = [];
            $scope.clientDetailsLocation = [];
            $scope.letterIcon = [];
            let summarySearchCounter = 1;

            angular.forEach($scope.distance, (disVal) => {
              let fullName = '';
              let email = '';
              let workNumber = '';
              let mobileNumber = '';
              let familyId = 0;
              if (_.size(disVal.details) > 0) {
                fullName = disVal.details.FullName || '';
                email = disVal.details.Email || '';
                workNumber = disVal.details.WorkNumber || '';
                mobileNumber = disVal.details.MobileNumber || '';
                familyId = disVal.details.FamilyId || 0;
              }

              const tmpLetter = fullName.length > 0 ? fullName.split(' ') : '';

              if (tmpLetter.length > 1) {
                const firstLetter = tmpLetter[0].toUpperCase();
                const secondLetter = tmpLetter[1].toUpperCase();
                $scope.letterIcon = firstLetter.charAt(0) + secondLetter.charAt(0);
              } else if (tmpLetter.length === 1) {
                const firstLetter = tmpLetter[0].toUpperCase();
                $scope.letterIcon = firstLetter.charAt(0);
              } else {
                $scope.letterIcon = '';
              }

              toolTipContent = `${'<div class="col-md-12 map-allocate-adviser">' +
                              '<div class="margin-bottom-5 letter-icon-wrapper ng-isolate-scope size-sm box-round" data="'}${$scope.letterIcon}" char-count="2" color="#7f6AA1"><span class="letter-icon ng-binding">${$scope.letterIcon}</span></div>` +
                              `<h5 class="violet-color margin-left-10 inline-block"><b>${fullName}</b></h5>` +
                              `<h5><a href="#">${email}</a></h5>` +
                              `<h5><span class="violet-color"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;&nbsp;${workNumber}</span></h5>` +
                              `<h5><span class="violet-color"><i class="fa fa-mobile" aria-hidden="true"></i>&nbsp;&nbsp;${mobileNumber}</span></h5>` +
                              `<h5><span class="violet-color">${disVal.address}</span></h5>` +
                              `<button id="allocated" class="btn btn-wide" ng-click="allocateAdviser(${familyId},true,'${fullName}')"><b>Allocate</b></button>` +
                              `</div>`;

              compiled = $compile(toolTipContent)($scope);
              if (disVal.distance <= $scope.searchPreference.radius) {
                const newKey = `${disVal.lat}${disVal.lng}`;
                if (summarySearchObj[newKey]) {
                  summarySearchObj[newKey]++;
                } else {
                  summarySearchObj[newKey] = 1;
                }
                const val = [
                  disVal.address,
                  disVal.lat,
                  disVal.lng,
                  compiled[0],
                ];

                summarySearchloctmp.push(val);
                $scope.clientDetailsLocation.push(disVal);
              }


              if (summarySearchCounter === $scope.distance.length) {
                $scope.locations = summarySearchloctmp;
                if ($scope.mapModel.map.isLoaded) {
                  $scope.mapDetailsSummarySearch = mapService.mapInitialization(
                    'map-address-adviser-modal',
                    latitude,
                    longitude,
                    zoomValue,
                    formattedAddress,
                    true,
                    $scope.locations,
                    true,
                    toolTipContent);

                  $scope.map = $scope.mapDetailsSummarySearch[0];
                  $scope.loadingBroker = false;
                } else {
                  swal('Map Is not Loaded', 'Refresh the browser and try again.', 'error');
                }
              }

              summarySearchCounter++;
            });

            if ($scope.distance.length === 0) {
              if ($scope.mapModel.map.isLoaded) {
                $scope.mapDetailsSummarySearch = mapService.mapInitialization(
                  'map-address-adviser-modal',
                  latitude,
                  longitude,
                  zoomValue,
                  formattedAddress,
                  true,
                  $scope.locations,
                  true,
                  toolTipContent);

                $scope.map = $scope.mapDetailsSummarySearch[0];
                $scope.loadingBroker = false;
              }
            }
          } else if ($scope.mapModel.map.isLoaded) {
            $scope.mapDetailsSummarySearch = mapService.mapInitialization(
              'map-address-adviser-modal',
              latitude,
              longitude,
              5,
              formattedAddress,
              true,
              $scope.locations,
              true,
              toolTipContent);

            $scope.map = $scope.mapDetailsSummarySearch[0];
            $scope.loadingBroker = false;
            $scope.allocation.isAllocationShow = true;
          }
        }

        $scope.searchPostCode = function () {
          $scope.noPostalCode = false;
          $scope.loadingBroker = true;
          $scope.contactHomeAddress = `Postal Code ${$scope.addressModel.postalSearch}`;
          generalService.postCodeSearch($scope.addressModel.postalSearch)
            .then((placeResponse) => {
              const summaryPlaceData = placeResponse.data && placeResponse.data[0];
              if (summaryPlaceData && summaryPlaceData.formatted_address) {
                loadMapDetails(summaryPlaceData);
              } else {
                $scope.initialLoading = false;
                $scope.clientDetailsLocation = [];
                $scope.loadingBroker = false;
              }
            });
        };

        $scope.searchPostCode();
      });
  }

  // Preferences
  $scope.isPreference = false;
  $scope.setPreferences = function () {
    $scope.isPreference = !$scope.isPreference;
  };


  $scope.refreshResult = function () {
    $scope.initialLoading = false;
    zoomValue = 5;
    $scope.noPostalCode = false;
    $scope.isPreference = false;
    $scope.campaignListId = [];
    const statusArray = [];
    $scope.mapModel = broadcastDataService;
    $scope.statusArrayId = [];
    if ($scope.searchPreference.statusActive) {
      statusArray.push('Active');
      $scope.statusArrayId.push(1);
    }
    if ($scope.searchPreference.statusSuspended) {
      statusArray.push('Suspended');
      $scope.statusArrayId.push(4);
    }
    if ($scope.searchPreference.statusInactive) {
      statusArray.push('Inactive');
      $scope.statusArrayId.push(3);
    }
    if ($scope.searchPreference.statusTemporary) {
      statusArray.push('Temporarily Inactive');
      $scope.statusArrayId.push(2);
    }

    $scope.priorityIds = [];

    if ($scope.searchPreference.priority1) {
      $scope.priorityIds.push(1);
    }
    if ($scope.searchPreference.priority2) {
      $scope.priorityIds.push(2);
    }
    if ($scope.searchPreference.priority3) {
      $scope.priorityIds.push(3);
    }
    $scope.lead.campaignList.forEach((data) => {
      if (data.IsChecked) {
        $scope.campaignListId.push(data.CorpLeadCampaignID);
      }
    });

    initialLoading();
  };

  $scope.lead = {};
  $scope.lead.campaignList = [];
  function getLeadCampaign() {
    $scope.lead.campaignList = [];
    corporateService.corpLeadCampaignGet().then((response) => {
      if (response.data.length > 0) {
        _.map(response.data, (data) => {
          if ($scope.getContactFamilyInfo.ReferralItem && $scope.getContactFamilyInfo.ReferralItem === data.CorpLeadCampaignDesc) {
            data.IsChecked = true;
          } else {
            data.IsChecked = false;
          }
        });
        $scope.lead.campaignList = response.data;
      }
    });
  }
  getLeadCampaign();


  $scope.searchPreference = {};
  $scope.searchPreference.priority1 = true;
  $scope.searchPreference.priority2 = true;
  $scope.searchPreference.priority3 = true;

  $scope.searchPreference.typeResidential = true;
  $scope.searchPreference.typeCommercial = true;
  $scope.searchPreference.typeSMSF = true;
  $scope.searchPreference.typePersonal = true;
  $scope.searchPreference.typeInvestment = true;
  $scope.searchPreference.typeHomeBuyer = true;
  $scope.searchPreference.typeBusiness = true;
  $scope.searchPreference.typeReverseMortgage = true;
  $scope.searchPreference.typeCarMortgage = true;
  $scope.searchPreference.typeLowDoc = true;
  $scope.searchPreference.typeRefinance = true;

  $scope.searchPreference.statusActive = true;
  $scope.searchPreference.radius = 100;


  function callTimer() {
    $timeout(() => {
      if (typeof $window.google === 'object' && typeof $window.google.maps === 'object') {
        $scope.mapModel.map.isLoaded = true;
        initialLoading();
      } else {
        mapService.loadAPI().then(() => {
          $scope.mapModel.map.isLoaded = true;
          initialLoading();
        });
        callTimer();
      }
    }, 3000);
  }

  // $rootScope.map.isLoaded = false;
  if ($scope.mapModel.map.isLoaded) {
    initialLoading();
  } else {
    callTimer();
  }
});
