import {
  generalBuilderForUI,
  notesBuilderForUI,
  notesBuilderForMyCRM,
  calculationsBuilderForUI,
  calculationsBuilderForMyCRM,
  totalLoanAmountsBuilderForUI,
  shareReferrerNotesViaSmsBuilderForMyCrm,
  shareReferrerNotesViaEmailBuilderForMyCrm,
} from 'Common/mappers/loanOpportunity';

class LoanOpportunityService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'loan-opportunity';
  }

  setLoanOpportunity(data) {
    return this.httpClient.post(`${this.baseUrl}`, data);
  }

  getLoanOpportunityGeneral(id) {
    return this.httpClient
      .get(`${this.baseUrl}/${id}/general-info`)
      .then(response => response.data && generalBuilderForUI(response.data));
  }

  setLoanOpportunityGeneral(id, data) {
    return this.httpClient
      .put(`${this.baseUrl}/${id}/general-info`, data)
      .then(response => generalBuilderForUI(response.data));
  }

  getLoanOpportunityDetails(id) {
    return this.httpClient.get(`${this.baseUrl}/${id}/details`);
  }

  setInvolvedParties(id, data) {
    return this.httpClient.put(`${this.baseUrl}/${id}/involved-parties`, data);
  }

  setLoanOpportunitySummary(id, data) {
    return this.httpClient.put(`${this.baseUrl}/${id}/summary`, data);
  }

  setLoanOpportunityStatus(loanId, pipelineStatusId) {
    return this.httpClient.put(`${this.baseUrl}/loan/${loanId}/status/${pipelineStatusId}`);
  }

  getLoanOpportunityNotes(id, count = 0) {
    return this.httpClient
      .get(`${this.baseUrl}/loan/${id}/notes`, { count })
      .then(response => response.data.map(notesBuilderForUI));
  }

  setLoanOpportunityNote(data) {
    return this.httpClient
      .post(`${this.baseUrl}/notes`, notesBuilderForMyCRM(data))
      .then(response => notesBuilderForUI(response.data));
  }

  updateLoanOpportunityNote(data, communicationId) {
    return this.httpClient
      .put(`${this.baseUrl}/notes/${communicationId}`, notesBuilderForMyCRM(data))
      .then(response => notesBuilderForUI(response.data));
  }

  deleteLoanOpportunityNote(communicationId) {
    return this.httpClient.delete(`${this.baseUrl}/notes/${communicationId}`);
  }

  setLoanOpportunityCalculation(opportunityId, data) {
    return this.httpClient.put(`${this.baseUrl}/${opportunityId}/calculations`, calculationsBuilderForMyCRM(data));
  }

  getLoanOpportunityCalculation(opportunityId) {
    return this.httpClient
      .get(`${this.baseUrl}/${opportunityId}/calculations`)
      .then(response => response.data.map(calculationsBuilderForUI));
  }

  getLoanOpportunityTotalLoanAmounts() {
    return this.httpClient
      .get(`${this.baseUrl}/status/total-amounts`)
      .then(response => response.data.map(totalLoanAmountsBuilderForUI));
  }

  setConversion(id, conversionStatus) {
    return this.httpClient.put(`${this.baseUrl}/${id}/conversion-status`, conversionStatus);
  }

  getReasonSubstatus() {
    return this.httpClient.get(`${this.baseUrl}/reason-substatus`, {}, true);
  }

  shareReferrerViaEmail(loanId, emailObj) {
    return this.httpClient.post(`${this.baseUrl}/notes/${loanId}/email`, shareReferrerNotesViaEmailBuilderForMyCrm(emailObj))
      .then(response => response.data);
  }

  shareReferrerViaSms(loanId, smsObj) {
    return this.httpClient.post(`${this.baseUrl}/notes/${loanId}/sms`, shareReferrerNotesViaSmsBuilderForMyCrm(smsObj))
      .then(response => response.data);
  }
}

export default LoanOpportunityService;
