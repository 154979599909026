import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular.module('app').directive('outstandingCommissionsSmall',
  function outstandingCommissionsSmall(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl: 'assets/views/overviewDashboard/directiveTemplates/outstandingCommissionsSmall.html',

      link(scope) {
        scope.outstandingCommissionMultiAdviser = [];
        scope.outstandingCommissionMultiAdviserObj = {};
        scope.outstandingCommissionMultiLender = [];
        scope.outstandingCommissionMultiLenderObj = {};
        scope.WidgetOptionGetData = [];
        scope.outp = [];
        scope.outpLender = [];

        function CommissionExpectedSmallGet() {
          overviewDashboardService.CommissionExpectedSmallGet(scope.myParam.Widget_UserID)
            .then((response) => {
              scope.commissionExpectedSmallData = response.data;
            }, () => {
            });
        }
        function WidgetOptionAdviserCommissionList() {
          overviewDashboardService.WidgetOptionAdviserCommissionList()
            .then((response) => {
              scope.WidgetOptionComAdviserList = response.data;
              for (let i = 0; i < scope.WidgetOptionComAdviserList.length; i++) {
                scope.outstandingCommissionMultiAdviserObj = { FamilyName: scope.WidgetOptionComAdviserList[i].FamilyName, FamilyId: scope.WidgetOptionComAdviserList[i].FamilyId, OptionValueId: scope.WidgetOptionComAdviserList[i].OptionValueId, OptionDB_Value: scope.WidgetOptionComAdviserList[i].OptionDB_Value, ticked: false, WidgetOptionId: scope.WidgetOptionComAdviserList[i].OptionId };
                scope.outstandingCommissionMultiAdviser.push(scope.outstandingCommissionMultiAdviserObj);
              }
              overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
                .then((res) => {
                  scope.WidgetOptionGetData = res.data;
                  scope.WidgetOptionGetAdviser = {};
                  scope.Advisers = [];
                  for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                    if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 9) {
                      scope.Advisers = scope.WidgetOptionGetData[j].SelectedUerValues;
                      const AdvisersArray = scope.Advisers.split(',');
                      scope.outstandingCommissionMultiAdviser = scope.outstandingCommissionMultiAdviser.map((obj) => {
                        if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                          obj.ticked = true;
                          return obj;
                        }


                        return obj;
                      });
                    }
                  }
                }, () => {
                });
            }, () => {
            });
        }
        function WidgetOptionLenderListGet() {
          overviewDashboardService.WidgetOptionLenderListGet()
            .then((response) => {
              scope.WidgetOptionLenderList = response.data;
              for (let i = 0; i < scope.WidgetOptionLenderList.length; i++) {
                scope.outstandingCommissionMultiLenderObj = { ProviderName: scope.WidgetOptionLenderList[i].ProviderName, ProviderId: scope.WidgetOptionLenderList[i].ProviderId, OptionValueId: scope.WidgetOptionLenderList[i].OptionValueId, OptionDB_Value: scope.WidgetOptionLenderList[i].OptionDB_Value, ticked: false, WidgetOptionId: scope.WidgetOptionLenderList[i].OptionId };
                scope.outstandingCommissionMultiLender.push(scope.outstandingCommissionMultiLenderObj);
              }
              overviewDashboardService.WidgetOptionGet(scope.myParam.Widget_UserID)
                .then((res) => {
                  scope.WidgetOptionGetData = res.data;
                  scope.WidgetOptionGetLender = {};
                  scope.Lenders = [];
                  for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                    if (parseInt(scope.WidgetOptionGetData[j].WidgetOptionId, 10) === 6) {
                      scope.Lenders = scope.WidgetOptionGetData[j].SelectedUerValues;
                      const LendersArray = scope.Lenders.split(',');
                      scope.outstandingCommissionMultiLender = scope.outstandingCommissionMultiLender.map((obj) => {
                        if (LendersArray.indexOf(obj.ProviderId) > -1) {
                          obj.ticked = true;
                          return obj;
                        }


                        return obj;
                      });
                    }
                  }
                }, () => {
                });
            }, () => {
            });
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(CommissionExpectedSmallGet)
            .catch(displayError);
        }

        scope.updateMultiselectFilterDataLender = function (clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSetLender = {};
          scope.MultiOptionSetForAllLender = {};
          const itemData = _.find(scope.outstandingCommissionMultiLender, ['ticked', true]);
          if (_.isUndefined(itemData)) {
            _.map(scope.outstandingCommissionMultiLender, (item) => {
              if (item.ProviderName === clickedItem.ProviderName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.ProviderName !== 'All') {
            if (_.size(scope.outstandingCommissionMultiLender) !== 0) {
              scope.outstandingCommissionMultiLender[0].ticked = false;
            }

            dataToSend = scope.outstandingCommissionMultiLender.filter(item => item.ProviderName !== 'All' && item.ticked === true).map((obj) => {
              return { PersonId: obj.ProviderId };
            });


            scope.MultiOptionSetLender.SelectedUser = dataToSend;
            scope.MultiOptionSetLender.WidgetOptionId = scope.WidgetOptionLenderList[0].OptionId;
            scope.MultiOptionSetLender.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSetLender.Widget_OptionValue = scope.WidgetOptionLenderList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetLender);
          } else {
            const all = scope.outpLender.filter(item => item.ProviderName === 'All');
            if (all.length > 0 && scope.outpLender.length > 1) {
              scope.outstandingCommissionMultiLender = scope.outstandingCommissionMultiLender.map((obj) => {
                if (obj.ProviderName === 'All')
                  return obj;

                obj.ticked = false;
                return obj;
              });
            }
            dataToSend.push({ PersonId: scope.outstandingCommissionMultiLender[0].ProviderId });

            scope.MultiOptionSetForAllLender.SelectedUser = dataToSend;
            scope.MultiOptionSetForAllLender.WidgetOptionId = scope.WidgetOptionLenderList[0].OptionId;
            scope.MultiOptionSetForAllLender.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAllLender.Widget_OptionValue = scope.WidgetOptionLenderList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAllLender);
          }
        };
        scope.updateMultiselectFilterDataAdviser = function (clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.outstandingCommissionMultiAdviser, ['ticked', true]);
          if (_.isUndefined(itemData)) {
            _.map(scope.outstandingCommissionMultiAdviser, (item) => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.outstandingCommissionMultiAdviser) !== 0) {
              scope.outstandingCommissionMultiAdviser[0].ticked = false;
            }

            dataToSend = scope.outstandingCommissionMultiAdviser.filter(item => item.FamilyName !== 'All' && item.ticked === true).map((obj) => {
              return { PersonId: obj.FamilyId };
            });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId = scope.WidgetOptionComAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue = scope.WidgetOptionComAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && scope.outp.length > 1) {
              scope.outstandingCommissionMultiAdviser = scope.outstandingCommissionMultiAdviser.map((obj) => {
                if (obj.FamilyName === 'All')
                  return obj;

                obj.ticked = false;
                return obj;
              });
            }
            dataToSend.push({ PersonId: scope.outstandingCommissionMultiAdviser[0].FamilyId });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId = scope.WidgetOptionComAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue = scope.WidgetOptionComAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };
        scope.doAction = function (msg) {
          if (msg === 'edit')
            scope.customizeMode = true;
          else
            scope.customizeMode = false;
          if (msg === 'notEdit') {
            scope.customizeMode = false;
          } else
            scope.customizeMode = true;
        };
        function init() {
          CommissionExpectedSmallGet();
          WidgetOptionAdviserCommissionList();
          WidgetOptionLenderListGet();
        }

        init();
      },
    };
  });
