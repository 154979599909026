import angular from 'angular';
import _ from 'lodash';

class MailSharingAndPrivacyCtrl {
  constructor(
    $filter,
    corporateService,
    contactService,
    uiService,
    templateService
  ) {
    'ngInject';

    this.$filter = $filter;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.uiService = uiService;
    this.templateService = templateService;
    this.mailTemplateChanges = [];
    this.mailTemplateFolderList = [];
  }

  setCorporate(obj, index, isTemplate) {
    if (!obj || obj.IsSystemTemplate) return;

    obj.IsExclusiveForCorpUser = !obj.IsExclusiveForCorpUser;
    obj.IsFolder = !isTemplate;
    this.contactService.mailTemplatesSet(obj);
  }

  setShared(obj, index, isTemplate) {
    if (!obj) return;

    obj.isSharedWithAdviser = !obj.isSharedWithAdviser;
    obj.IsFolder = !isTemplate;
    this.contactService.mailTemplatesSet(obj);
  }

  getMailTemplates() {
    this.contactService.mailTemplatesGet().then((response) => {
      if (!response.data) return;
      this.mailTemplateFolderList = _.filter(response.data, folder => !this.templateService.isBlankFolderOrTemplate(folder));
    });
  }

  $onInit() {
    this.getMailTemplates();
  }
}

angular.module('app').controller('MailSharingAndPrivacyCtrl', MailSharingAndPrivacyCtrl);
