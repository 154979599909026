import moment from 'moment';

class PipelineCardsService {
  constructor() {
    this.settlementDateFormat = 'DD-MMM-YYYY';
    this.createDateFormat = 'DD MMMM YYYY';
  }

  buildCardForBoard(card, cardCategory) {
    if (!card || !cardCategory) return;

    const {
      PipelineCardsID: PipelineItemId,
      ClientFamilyName: ClientName,
      Probability: labelType,
      LabelArray: labels,
      IsClient,
      AdvisorName,
      AdviserFirstName,
      AdviserLastName,
      ClientFamilyID: clientFamilyId,
      PipelineStatusID,
      PipelineStatus,
      LastModifiedByFamilyId,
      LastModifiedDate,
      LastModifiedFirstName,
      LastModifiedLastName,
      LastModifiedFullName,
      Lender: LenderName,
      LoanAmount,
      EnquirySource,
      CountryCode,
      CreateDate: CreateDateOriginal,
      TotalRecords,
    } = card;

    const LenderID = card.LenderID && card.LenderID.trim() ? parseInt(card.LenderID, 10) : 0;
    const LoanID = card.LoanID || null;
    const LoanStatusID = card.LoanStatusID || null;
    const LoanScenarioID = card.LoanScenarioID || 0;

    const boardCard = {
      PipelineItemId,
      ClientName,
      labelType,
      labels,
      IsClient,
      AdvisorName,
      AdviserFirstName,
      AdviserLastName,
      clientFamilyId,
      PipelineStatusID,
      PipelineStatus,
      LastModifiedByFamilyId,
      LastModifiedDate,
      LastModifiedFirstName,
      LastModifiedLastName,
      LastModifiedFullName,
      LenderName,
      LenderID,
      LoanAmount,
      EnquirySource,
      CountryCode,
      LoanID,
      LoanStatusID,
      LoanScenarioID,
      CreateDateOriginal,
      TotalRecords,
    };

    if (card.SettlementDate) {
      const settlementDate = moment(card.SettlementDate).format(this.settlementDateFormat);
      const dateSplits = settlementDate.split('-');
      boardCard.settlementDate = dateSplits[0];
      boardCard.settlementMonth = dateSplits[1];
    } else {
      boardCard.settlementDate = '';
    }

    boardCard.subStatuses = this.getSubStatusList(cardCategory);
    const selectedSubStatus = boardCard.subStatuses.find(subStatus => subStatus.pipelineSubStatusID === card.SubStatusID);
    boardCard.selectedSubStatus = selectedSubStatus;
    boardCard.createDate = CreateDateOriginal ? moment(CreateDateOriginal).format(this.createDateFormat) : null;

    return boardCard;
  }

  getSubStatusList(cardCategory) {
    const hasSubstatusList = cardCategory.SubStatus && cardCategory.SubStatus.length;
    if (!hasSubstatusList) return [];

    this.subStatusList = cardCategory.SubStatus.reduce((accum, item) => {
      if (!item) return accum;
      accum.push({ ...item });
      return accum;
    }, []);
    const pipelineSubStatusID = 0;
    const StatusID = cardCategory.PipelineStatusID;
    const SubStatusName = 'Please Select Status';
    this.subStatusList.splice(0, 0, {
      pipelineSubStatusID,
      StatusID,
      SubStatusName,
    });

    return this.subStatusList;
  }

  convertStrLabelsToObj(card) {
    if (!card || !card.Labels) return [];

    const splitLabels = card.Labels.split(',');
    if (!splitLabels.length) return [];

    this.labelsArr = splitLabels.reduce((accum, split) => {
      const splitLabel = split.split(':');
      if (splitLabel.length !== 3) return accum;

      const label = {};
      label.labelColor = splitLabel[0];
      label.labelName = splitLabel[1];
      label.labelID = splitLabel[2];
      accum.push(label);
      return accum;
    }, []);

    return this.labelsArr;
  }

  columnHasMore(column) {
    if (!column) return false;

    const displayedCardsCount = column.cards ? column.cards.length : 0;
    const moreItemsCount = column.TotalRecords - displayedCardsCount;
    this.hasMore = moreItemsCount > 0;
    return this.hasMore;
  }

  getColumnCurrentPage(column, pageSize) {
    if (!column) return 0;

    const displayedCardsCount = column.cards ? column.cards.length : 0;
    this.currentPage = Math.ceil(displayedCardsCount / pageSize);
    return this.currentPage;
  }

  getColumnTotalRecords(column) {
    if (!column) return 0;

    const firstColumnItem = column.cards && column.cards.length ? column.cards[0] : null;
    this.columnTotalRecords = firstColumnItem ? firstColumnItem.TotalRecords : 0;
    return this.columnTotalRecords;
  }
}

export default PipelineCardsService;
