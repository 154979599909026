export const PIPELINE_TYPES_STR = {
  LEADS: 'leads',
  APPLICATION: 'application',
  OPPORTUNITY: 'opportunity',
};

export const PIPELINE_VIEW_TYPES = {
  KANBAN: 1,
  TABLE: 2,
};

export const KANBAN_OPTIONS = [
  { label: 'Comfortable', value: 0 },
  { label: 'Compact', value: 1 },
];

export const PIPELINE_TYPES_ID = {
  LEADS: 1,
  APPLICATION: 2,
  OPPORTUNITY: 3,
};
