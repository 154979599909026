import angular from 'angular';
import { orderBy } from 'lodash';

/* How to use:
* Put data for tableHeaders and tableData.
* Sample Data:
* tableHeaders
* [ { offlineSortObjKey: 'contactNames', dataSortObjectKey: 'ClientName', label: 'Client Name', isHidden: 0, isSortable: 1 } ];
*
* Data for each row should be equal to the number of headers.
*/


export default class CrmTableViewCtrl {
  constructor($interval) {
    'ngInject';

    this.$interval = $interval;
    this.addActiveClass = (rowId) => {
      const scrollable = angular.element(`.scrollable-x .table-data-container .row-${rowId}`);
      const nonScrollable = angular.element(`.non-scrollable-x .table-data-container .row-${rowId}`);

      scrollable.addClass('active');
      nonScrollable.addClass('active');
    };

    this.removeActiveClass = (rowId) => {
      const nonScrollable = angular.element(`.non-scrollable-x .table-data-container .row-${rowId}`);
      const scrollable = angular.element(`.scrollable-x .table-data-container .row-${rowId}`);

      scrollable.removeClass('active');
      nonScrollable.removeClass('active');
    };

    this.removeRow = this.removeRow.bind(this);
  }

  $onInit() {
    this.checkTableDataValidity();
    this.heightWatcher = this.$interval(() => { this.checkTableDataValidity(); }, 500);
  }

  $onDestroy() {
    this.$interval.cancel(this.heightWatcher);
  }

  $onChanges(changes) {
    const { tableData } = changes;
    if (!tableData) return;
    this.checkTableDataValidity();
  }

  checkTableDataValidity() {
    const isTableDataValid = this.tableData && this.tableData.length;
    if (isTableDataValid) {
      this.setRowHeights();
    }
  }

  setRowHeights() {
    this.tableData.forEach((obj) => {
      const { id } = obj;
      if (!id) return;
      const scrollable = angular.element(`.scrollable-x .table-data-container .row-${id}`);
      const nonScrollable = angular.element(`.non-scrollable-x .table-data-container .row-${id}`);
      if (!scrollable || !scrollable[0] || !nonScrollable || !nonScrollable[0]) return;
      scrollable[0].style.height = `auto`;
      nonScrollable[0].style.height = `auto`;

      const maxHeight = scrollable[0].offsetHeight > nonScrollable[0].offsetHeight ? scrollable[0].offsetHeight : nonScrollable[0].offsetHeight;

      scrollable[0].style.height = `${maxHeight}px`;
      nonScrollable[0].style.height = `${maxHeight}px`;
    });
  }

  sortColumn(sortColumn, sortType) {
    if (!sortColumn || !sortType) return;
    this.showLoader = false;
    this.tableData = orderBy(this.tableData, [sortColumn], [sortType.toLowerCase()]);
  }

  removeRow(args = {}) {
    const { rowId } = args;
    if (!rowId) return;
    const idx = this.tableData.findIndex(o => o.id === rowId);

    if (idx === -1) return;
    this.tableData.splice(idx, 1);
  }
}
