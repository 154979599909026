import angular from 'angular';
import _ from 'lodash';
import {
  setCurrrentDate,
  setDateFormat,
} from 'Common/utilities/date';

angular.module('app').controller('AdviserWMComplianceCtrl', function AdviserWMComplianceCtrl(
  broadcastDataService,
  wmComplianceService,
  corporateService,
  commonFnService,
  generalService,
  contactService,
  queryService,
  dataService,
  mapService,
  $uibModal,
  toaster,
  $filter,
  $state,
  $scope
) {
  /*    VARIABLE(s)    */
  /* State Params */
  $scope.clientId = $state.params.clientId || 0;
  $scope.familyId = $state.params.familyId || 0;
  /* Data Type: {Object} */
  $scope.wmEmploymentComplianceDetails = {};
  $scope.wmEducationComplianceDetails = {};
  $scope.familyRecruitmentDetailsGet = {};
  $scope.wmQualificationDetails = {};
  $scope.wmComplianceDetails = {};
  $scope.wmstdPreVetGet = {};
  $scope.wmRecentAudit = {};
  $scope.wmGeneral = {};
  $scope.editMode = {};
  /* Data Type: {Array} */
  $scope.recruitmentNotProceedingReasonLists = [];
  $scope.recruitmentOnboardingStatusLists = [];
  $scope.selectedIndustryMembership = [];
  $scope.prospectiveRatingLists = [];
  $scope.recruitmentStatusLists = [];
  $scope.selectedSpecialCourse = [];
  $scope.perceivedQualityLists = [];
  $scope.selectedQualification = [];
  $scope.appointedAsGetLists = [];
  $scope.citizenshipStatuses = [];
  $scope.industryMemberships = [];
  $scope.authorizationTypes = [];
  $scope.selectedAdviceType = [];
  $scope.inquirySourceLists = [];
  $scope.specialCourseName = [];
  $scope.specialistCourses = [];
  $scope.altInputFormats = [];
  $scope.employmentRoles = [];
  $scope.qualifications = [];
  $scope.preVetStatuses = [];
  $scope.auditRatings = [];
  $scope.adviceTypes = [];
  $scope.eDRSchemes = [];
  $scope.setterType = [];
  $scope.adminUsers = [];
  $scope.authLevels = [];
  $scope.saveType = [];
  $scope.splits = [];
  /* Data Type: {String} */
  $scope.dateStaticValue = '1900-01-01';
  $scope.dateFormat = '';

  let watchAdviserWMComplianceEditMode = null;
  /*  FUNCTION REFERENCE      */
  /*  FUNCTION DEFINITION(S)  */
  /* ------------------------------------------ [ SETTER ] --------------------------------------------------- */
  function setQualificationTypes(qualificationArr, qualificationRespData) {
    let qualificationName = [];
    $scope.selectedQualification = [];

    if (qualificationRespData) {
      qualificationName = qualificationRespData.WMQualificationDescList ? qualificationRespData.WMQualificationDescList.split(',') : [];
      qualificationArr && Object.keys(qualificationArr).forEach((key) => {
        qualificationName && Object.keys(qualificationName).forEach((y) => {
          if (qualificationArr[key].WMQualificationDesc === qualificationName[y]) {
            $scope.selectedQualification.push(qualificationArr[key]);
          }
        });
      });
    }
    return $scope.selectedQualification;
  }

  function setSpecialCourses(specialCourseArr, qualificationRespData) {
    let specialCourse = [];
    $scope.selectedSpecialCourse = [];
    $scope.specialCourseName = [];
    let data = {};
    if (qualificationRespData) {
      specialCourse = qualificationRespData.SpecialistCourseIDs ? qualificationRespData.SpecialistCourseIDs.split(',') : [];
      specialCourseArr && Object.keys(specialCourseArr).forEach((key) => {
        specialCourse && Object.keys(specialCourse).forEach((y) => {
          if (specialCourseArr[key].SpecialistCourseId === specialCourse[y]) {
            $scope.selectedSpecialCourse.push(specialCourseArr[key]);
            $scope.specialCourseName.push(specialCourseArr[key].SpecialistCourseDesc);
          }
        });
      });
      data = {
        specialCourseName: _.size($scope.specialCourseName) > 0 ? $scope.specialCourseName.join(',  ') : '',
        specialCourseArr: $scope.selectedSpecialCourse,
      };
    }
    return data;
  }

  function setAdviceType(adviceTypeArr, qualificationRespData) {
    let adviceTypesName = [];
    if (qualificationRespData) {
      adviceTypesName = qualificationRespData.WMAdviceTypeDescList ? qualificationRespData.WMAdviceTypeDescList.split(',') : [];
      adviceTypeArr && Object.keys(adviceTypeArr).forEach((key) => {
        adviceTypesName && Object.keys(adviceTypesName).forEach((y) => {
          if (adviceTypeArr[key].WMAdviceTypeDesc === adviceTypesName[y]) {
            $scope.selectedAdviceType.push(adviceTypeArr[key]);
          }
        });
      });
    }
    return $scope.selectedAdviceType;
  }

  function setIndustyMembership(qualificationRespData) {
    let industryMembershipsName = [];
    if (qualificationRespData) {
      industryMembershipsName = qualificationRespData.IndustryMemberships ? qualificationRespData.IndustryMemberships.split(',') : [];
      $scope.industryMemberships && Object.keys($scope.industryMemberships).forEach((key) => {
        industryMembershipsName && Object.keys(industryMembershipsName).forEach((y) => {
          if ($scope.industryMemberships[key].name === industryMembershipsName[y]) {
            $scope.selectedIndustryMembership.push($scope.industryMemberships[key]);
          }
        });
      });
    }
    return $scope.selectedIndustryMembership;
  }
  /**
      *   @name querySet
      *   @desc This will save the value from api.
      *   @params {String} setter
      *   @params {String} key
      *   @params {Array} arrays
      *   @params {Boolean} isCorporate
      */
  function querySet(setter, key, field, arrays, isCorporate) {
    $scope.editMode[key] = false;
    commonFnService.setEditMode($scope.editMode, field);
    if (!isCorporate) {
      wmComplianceService[setter].apply(null, arrays)
        .then((response) => {
          if (response && response.status === 200) {
            toaster.pop('success', 'Update', 'successfully updated!');
          }
        });
    }
    toaster.clear();
  }
  /**
      *   @name onRetrieveAppointmentDetailSuccess
      *   @desc This will load data from Api: `appointmentDetailGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveAppointmentDetailSuccess(response) {
    if (response && response.data) {
      queryService.getAllArrayDetails($scope, wmComplianceService, 'appointedAsGet', [],
        'appointedAsGetLists', response.data, 'AppointedAs', 'AppointedAsId',
        'AppointedAsId', 'AppointedAsDesc');

      response.data.Split = queryService.getArrValue(response.data.SplitsId, null, 'id', 'name', $scope.splits);
      response.data.AuthLevel = queryService.getArrValue(response.data.AuthLevelId, null, 'id', 'name', $scope.authLevels);

      queryService.getAllArrayDetails($scope, wmComplianceService, 'authorizationTypeGet', [],
        'authorizationTypes', response.data, 'AuthorizationType', 'AuthorizationTypeId',
        'AuthorizationTypeId', 'AuthorizationTypeDesc');

      queryService.getAllArrayDetails($scope, wmComplianceService, 'citizenshipStatusGet', [],
        'citizenshipStatuses', response.data, 'CitizenshipStatus', 'CitizenshipStatusId',
        'CitizenshipStatusId', 'CitizenshipStatusDesc');

      $scope.wmComplianceDetails = response.data;
    }
  }
  /**
      *   @name onRetrieveEmploymentDetailSuccess
      *   @desc This will load data from Api: `employmentDetailGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveEmploymentDetailSuccess(response) {
    if (response && response.data) {
      dataService.getEmploymentDetail()
        .then((employmentArr) => {
          $scope.employmentRoles = employmentArr;
          response.data.EmploymentRoleId = response.data.EmploymentRoleId ? response.data.EmploymentRoleId : 0;
          response.data.EmploymentRole = queryService.getArrValue(response.data.EmploymentRoleId, null, 'ProfessionId', 'ProfessionTitle', $scope.employmentRoles);
        });

      $scope.wmEmploymentComplianceDetails = response.data;
    }
  }
  /**
      *   @name onRetrieveEducationDetailSuccess
      *   @desc This will load data from Api: `educationDetailGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveEducationDetailSuccess(response) {
    if (response && response.data) {
      $scope.wmEducationComplianceDetails = response.data;
    }
  }
  /**
      *   @name onRetrieveQualificationDetailsSuccess
      *   @desc This will load data from Api: `qualificationDetailsGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveQualificationDetailsSuccess(response) {
    if (response && response.data) {
      dataService.getQualificationDetails()
        .then((qualificationArr) => {
          $scope.qualifications = qualificationArr;
          response.data.WMQualificationArr = setQualificationTypes(qualificationArr, response.data);
        });

      dataService.getSpecialCourses()
        .then((specialCourseArr) => {
          $scope.specialistCourses = specialCourseArr;
          const specialArrTmp = setSpecialCourses(specialCourseArr, response.data);
          response.data.SpecialistCourseDesc = specialArrTmp.specialCourseName;
          response.data.SpecialistCourseArr = specialArrTmp.specialCourseArr;
        });

      dataService.getAdviceTypes()
        .then((adviceTypeArr) => {
          $scope.adviceTypes = adviceTypeArr;
          response.data.WMAdviceTypeArr = setAdviceType(adviceTypeArr, response.data);
        });

      response.data.RelatedDegree = _.isUndefined(response.data.RelatedDegree) ? false : response.data.RelatedDegree;
      response.data.UnRelatedDegree = _.isUndefined(response.data.UnRelatedDegree) ? false : response.data.UnRelatedDegree;
      response.data.HSC_or_Equivalent = _.isUndefined(response.data.HSC_or_Equivalent) ? false : response.data.HSC_or_Equivalent;

      $scope.wmQualificationDetails = response.data;
    }
  }
  /**
      *   @name onRetrieverecentAuditSuccess
      *   @desc This will load data from Api: `recentAuditGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieverecentAuditSuccess(response) {
    if (response && response.data) {
      $scope.wmRecentAudit = response.data;
    }
  }
  /**
      *   @name onRetrieveStdPreVetSuccess
      *   @desc This will load data from Api: `stdPreVetGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveStdPreVetSuccess(response) {
    if (response && response.data) {
      $scope.wmstdPreVetGet = response.data;
    }
  }
  /**
      *   @name onRetrieveSpecialistAdviceSRSuccess
      *   @desc This will load data from Api: `specialistAdviceSRGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveSpecialistAdviceSRSuccess(response) {
    if (response && response.data) {
      $scope.wmSoas = response.data;
    }
  }
  /**
      *   @name onRetrieveGeneralSuccess
      *   @desc This will load data from Api: `generalGet` on success.
      *   @params {response} data from the api result.
      */
  function onRetrieveGeneralSuccess(response) {
    if (response && response.data) {
      response.data.IndustryMembershipArr = setIndustyMembership(response.data);
      $scope.wmGeneral = response.data;
    }
  }

  $scope.setDateFormatInView = (date) => {
    return dataService.setDateFormatInView(date);
  };

  $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
    return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
  };
  /**
      *   This will load/initialize data needed on load.
      *   @name initialize
      */
  function initialize() {
    dataService.getUserInfo()
      .then((response) => {
        $scope.isCorpTeam = response;
      });
    $scope.setterType = dataService.getSetterTypes('Adviser');
    $scope.saveType = dataService.getSaveTypes('Adviser');
    $scope.dateFormat = setDateFormat();
    $scope.altInputFormats = dataService.getDateValidityFormats();
    $scope.currentDate = setCurrrentDate();
    $scope.splits = dataService.getWMSplits();
    $scope.authLevels = dataService.getAuthLevels();
    $scope.industryMemberships = dataService.getIndustryMembership();
    $scope.preVetStatuses = dataService.getPreVetStatuses();
    $scope.auditRatings = dataService.getAuditRatings();

    // Appointment Detail
    wmComplianceService.appointmentDetailGet($scope.familyId)
      .then(onRetrieveAppointmentDetailSuccess, commonFnService.onRetrieveError);
    // Employment Detail
    wmComplianceService.employmentDetailGet($scope.familyId)
      .then(onRetrieveEmploymentDetailSuccess, commonFnService.onRetrieveError);
    // Education
    wmComplianceService.educationDetailGet($scope.familyId)
      .then(onRetrieveEducationDetailSuccess, commonFnService.onRetrieveError);
    // Qualification
    wmComplianceService.qualificationDetailsGet($scope.familyId)
      .then(onRetrieveQualificationDetailsSuccess, commonFnService.onRetrieveError);
    // RecentAudit
    wmComplianceService.recentAuditGet($scope.familyId)
      .then(onRetrieverecentAuditSuccess, commonFnService.onRetrieveError);
    // Prevet
    wmComplianceService.stdPreVetGet($scope.familyId)
      .then(onRetrieveStdPreVetSuccess, commonFnService.onRetrieveError);
    // soas
    wmComplianceService.specialistAdviceSRGet($scope.familyId)
      .then(onRetrieveSpecialistAdviceSRSuccess, commonFnService.onRetrieveError);
    // general
    wmComplianceService.generalGet($scope.familyId)
      .then(onRetrieveGeneralSuccess, commonFnService.onRetrieveError);

    watchAdviserWMComplianceEditMode = $scope.$watch('editModeObj', () => {
      $scope.editMode = $scope.editModeObj;
    });
  }

  $scope.$on('$destroy', () => {
    watchAdviserWMComplianceEditMode();
  });
  /* ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */
  $scope.dateEditMode = function (obj, key) {
    return commonFnService.dateEditMode(obj, key, $scope.editMode);
  };

  $scope.formatDateString = function (date) {
    return commonFnService.formatDateString(date);
  };

  $scope.formatDate = function (date) {
    return commonFnService.formatDate(date);
  };

  $scope.setStatus = function (name, setterType, obj, saveType) {
    let key;
    let field;
    const error = false;

    if (setterType === 'appointmentDetailSet') {
      $scope.wmComplianceDetails.AuthLevel = name.name;
      key = 'AuthLevel';
      field = key;
      $scope.wmComplianceDetails.AuthLevelId = name.id;
      key = 'AuthLevelId';
      field = key;
    } else if (setterType === 'stdPreVetSet') {
      $scope.wmstdPreVetGet.PreVetStatus = name.name;
      key = 'PreVetStatus';
      field = key;
    } else if (setterType === 'recentAuditResultSet') {
      $scope.wmRecentAudit.AuditRating = name.name;
      key = 'AuditRating';
      field = key;
    }

    if (!error) {
      $scope.updateAdviserDetails(setterType, obj, key, field, saveType);
    }
  };

  $scope.updateAdviserDetails = function (setterType, obj, key, field, saveType, formName, inputName) {
    let dataArray = [];
    const qualificationName = [];
    const qualificationId = [];
    const specialCourseName = [];
    const specialCourseId = [];
    const adviceTypeName = [];
    const adviceTypeId = [];
    const industryMemberName = [];
    switch (setterType) {
    case 'appointmentDetailSet':
      switch (key) {
      case 'AppointedAs':
        obj.AppointedAsId = queryService.getArrValue(null, obj.AppointedAs, 'AppointedAsId', 'AppointedAsDesc', $scope.appointedAsGetLists);
        obj.AppointedAs = queryService.getArrValue(obj.AppointedAsId, null, 'AppointedAsId', 'AppointedAsDesc', $scope.appointedAsGetLists);
        break;
      case 'Split':
        obj.SplitsId = queryService.getArrValue(null, obj.Split, 'id', 'name', $scope.splits);
        obj.Split = queryService.getArrValue(obj.SplitsId, null, 'id', 'name', $scope.splits);
        break;
      case 'AuthorizationType':
        obj.AuthorizationTypeId = queryService.getArrValue(null, obj.AuthorizationType, 'AuthorizationTypeId', 'AuthorizationTypeDesc', $scope.authorizationTypes);
        obj.AuthorizationType = queryService.getArrValue(obj.AuthorizationTypeId, null, 'AuthorizationTypeId', 'AuthorizationTypeDesc', $scope.authorizationTypes);
        break;
      case 'CitizenshipStatus':
        obj.CitizenshipStatusId = queryService.getArrValue(null, obj.CitizenshipStatus, 'CitizenshipStatusId', 'CitizenshipStatusDesc', $scope.citizenshipStatuses);
        obj.CitizenshipStatus = queryService.getArrValue(obj.CitizenshipStatusId, null, 'CitizenshipStatusId', 'CitizenshipStatusDesc', $scope.citizenshipStatuses);
        break;
      case 'AuthLevel':
        obj.AuthLevelId = queryService.getArrValue(null, obj.AuthLevel, 'id', 'name', $scope.authLevels);
        obj.AuthLevel = queryService.getArrValue(obj.AuthLevelId, null, 'id', 'name', $scope.authLevels);
        break;
      default:
        break;
      }
      dataArray = [$scope.familyId, obj.AppointedAsId, obj.SplitsId, obj.AuthLevelId, obj.CitizenshipStatusId, obj.RecruitmentSource, obj.AdviserAdviseOSUserName, obj.AuthorizationTypeId, obj.AFSL];
      break;
    case 'employmentDetailSet':
      if (formName && inputName) {
        if (!commonFnService.checkFormDateValidation(formName, inputName, obj, key)) {
          return false;
        }
      } else if (key === 'EmploymentRole') {
        if (obj.EmploymentRole) {
          obj.EmploymentRoleId = queryService.getArrValue(null, obj.EmploymentRole, 'ProfessionId', 'ProfessionTitle', $scope.employmentRoles);
          obj.EmploymentRole = queryService.getArrValue(obj.EmploymentRoleId, null, 'ProfessionId', 'ProfessionTitle', $scope.employmentRoles);
        } else {
          obj.EmploymentRoleId = 0;
        }
      }
      dataArray = [$scope.familyId, obj.EmploymentRoleId, obj.EmploymentStartDate, obj.EmploymentEndDate, obj.ResignationDate, obj.ReasonForLeaving, obj.ReHire, obj.EducationProvider, obj.HighestLevelCompleted];
      break;
    case 'educationDetailSet':
      switch (key) {
      case 'RelatedDegree':
        obj.RelatedDegree = commonFnService.setBooleanToInt(obj.RelatedDegree);
        break;
      case 'UnRelatedDegree':
        obj.UnRelatedDegree = commonFnService.setBooleanToInt(obj.UnRelatedDegree);
        break;
      case 'HSC_or_Equivalent':
        obj.HSC_or_Equivalent = commonFnService.setBooleanToInt(obj.HSC_or_Equivalent);
        break;
      default:
        break;
      }
      dataArray = [$scope.familyId, obj.HSC_or_Equivalent, obj.RelatedDegree, obj.UnRelatedDegree, obj.Details];
      break;
    case 'qualificationDetailsSet':
      switch (key) {
      case 'WMQualificationDescList':
        obj.WMQualificationArr && Object.keys(obj.WMQualificationArr).forEach((wmQualificationKey) => {
          qualificationName.push(obj.WMQualificationArr[wmQualificationKey].WMQualificationDesc);
          qualificationId.push(obj.WMQualificationArr[wmQualificationKey].WMQualificationId);
        });
        obj.WMQualificationDescList = _.size(qualificationName) > 0 ? qualificationName.join(',  ') : '';
        obj.WMQualificationIDs = _.size(qualificationId) > 0 ? qualificationId.join(',') : '';
        break;
      case 'SpecialistCourseDescList':
        obj.SpecialistCourseArr && Object.keys(obj.SpecialistCourseArr).forEach((specialCourseKey) => {
          specialCourseName.push(obj.SpecialistCourseArr[specialCourseKey].SpecialistCourseDesc);
          specialCourseId.push(obj.SpecialistCourseArr[specialCourseKey].SpecialistCourseId);
        });
        obj.SpecialistCourseDesc = _.size(specialCourseName) > 0 ? specialCourseName.join(',  ') : '';
        obj.SpecialistCourseDescList = _.size(specialCourseName) > 0 ? specialCourseName.join(',  ') : '';
        obj.SpecialistCourseIDs = _.size(specialCourseId) > 0 ? specialCourseId.join(',') : '';
        break;
      case 'WMAdviceTypeDescList':
        obj.WMAdviceTypeArr && Object.keys(obj.WMAdviceTypeArr).forEach((WMAdviceTypeKey) => {
          adviceTypeName.push(obj.WMAdviceTypeArr[WMAdviceTypeKey].WMAdviceTypeDesc);
          adviceTypeId.push(obj.WMAdviceTypeArr[WMAdviceTypeKey].WMAdviceTypeId);
        });
        obj.WMAdviceTypeDescList = _.size(adviceTypeName) > 0 ? adviceTypeName.join(',  ') : '';
        obj.WMAdviceTypeIDs = _.size(adviceTypeId) > 0 ? adviceTypeId.join(',') : '';
        break;
      default:
        break;
      }
      dataArray = [$scope.familyId, obj.WMQualificationIDs, obj.SpecialistCourseIDs, obj.OtherQualifications, obj.WMAdviceTypeIDs];
      break;
    case 'recentAuditResultSet':
      if (formName && inputName) {
        if (!commonFnService.checkFormDateValidation(formName, inputName, obj, key)) {
          return false;
        }
      }
      dataArray = [$scope.familyId, obj.AuditRating, obj.RecommendedForPreVet, obj.NoSOAsToBeReviewed, obj.SOAsReviewed, obj.RemediationRequired, obj.RemediationRequiredDate, obj.RecommendedForParaPlanning, obj.ParaPlanningStartDate, obj.ParaPlanningEndDate, obj.Last5YearsAdviserHistory, obj.AuditDate];
      break;
    case 'stdPreVetSet':
      dataArray = [$scope.familyId, obj.NewAdviser, obj.NoStdSOAsToBeReviewed, obj.PreVetStatus, obj.PreVetComments];
      break;
    case 'specialistAdviceSRSet':
      dataArray = [$scope.familyId, obj.ETF_SR, obj.DirectEquity_SR, obj.MarginLending_SR, obj.SMSF_SR];
      break;
    case 'generalSet':
      if (key === 'IndustryMemberships') {
        obj.IndustryMembershipArr && Object.keys(obj.IndustryMembershipArr).forEach((industryKey) => {
          industryMemberName.push(obj.IndustryMembershipArr[industryKey].name);
        });
        obj.IndustryMemberships = _.size(industryMemberName) > 0 ? industryMemberName.join(',  ') : '';
      }
      dataArray = [$scope.familyId, obj.IndustryMemberships, obj.LegacyBlanketApprovals, obj.MorningStarSubscription, obj.LonsecResearchSubs];
      break;
    default:
      break;
    }
    if (dataArray.length > 0) {
      querySet(setterType, key, field, dataArray, false);
    }
  };
  /**
  *   Function Call
  */
  initialize();
});
