import angular from 'angular';

angular.module('app').controller('NotificationSidebarXsCtrl',
  function NotificationSidebarXsCtrl(
    $scope,
    $http,
    loanToolsService,
    dashboardService,
    $filter,
    $element,
    $window,
    toaster,
    SweetAlert,
    configService,
    contactService,
    $rootScope) {
    function init() {
      $scope.currentView = '';
      $scope.showFullSlidedBarXs = false;
      $scope.partialExpanded = true;
      $scope.expandViewInMobile = function (view) {
        $scope.showFullSlidedBarXs = true;
        $scope.partialExpanded = true;
        $scope.currentView = view;
        $rootScope.$broadcast('expandFullSidebarXs', { view, expand: true });
      };
      $scope.logout = function () {
        $rootScope.$broadcast('logoutUserFromSidebar');
      };
      $scope.collapsePartialOrClose = function () {
        if ($scope.showFullSlidedBarXs && $scope.partialExpanded) {
          $scope.showFullSlidedBarXs = false;
          $scope.partialExpanded = true;
          $rootScope.$broadcast('expandFullSidebarXs', { view: $scope.currentView, expand: false });
        } else {
          $scope.currentView = '';
          $rootScope.$broadcast('setNotificationBarOnly', { show: false });
        }
      };
      const viewSwitchHandler = $rootScope.$on('setNotificationView', (event, args) => {
        $scope.currentView = args.view;
      });
      $scope.$on('$destroy', viewSwitchHandler);
    }
    init();
  });
