import angular from 'angular';
import $ from 'jquery';
import { objectLength } from 'Common/utilities/objectValidation';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { COLOR } from 'Common/constants/colors';

angular.module('app').factory('customThemeService', function customThemeService(
  $q, CustomThemeModel, $rootScope, popupBlockerService, contactService, regionalizationService, dashboardService, generalService, $window, toaster, configService, userService, currentUserService
) {
  var factory = this;
  var themeModel = new CustomThemeModel();
  factory.branding = {};
  factory.generalThemeGet = function () {
    var defer = $q.defer();
    themeModel.generalThemeGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.generalThemeSet = function (data) {
    var defer = $q.defer();
    themeModel.generalThemeSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.widgetThemeGet = function () {
    var defer = $q.defer();
    themeModel.widgetThemeGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.widgetThemeSet = function (data) {
    var defer = $q.defer();
    themeModel.widgetThemeSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.buttonLinkThemeGet = function () {
    var defer = $q.defer();
    themeModel.buttonLinkThemeGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.buttonLinkThemeSet = function (data) {
    var defer = $q.defer();
    themeModel.buttonLinkThemeSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.formThemeGet = function () {
    var defer = $q.defer();
    themeModel.formThemeGet((response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.formThemeSet = function (data) {
    var defer = $q.defer();
    themeModel.formThemeSet(data, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.generalThemeLogoSet = function (documentId) {
    var defer = $q.defer();
    themeModel.generalThemeLogoSet({
      documentId,
    }, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };

  factory.customThemeReset = function () {
    var defer = $q.defer();
    themeModel.customThemeReset({}, (response) => {
      factory.data = response.data;
      return defer.resolve(response);
    }, (error) => {
      factory.data = [];
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.theme = {};
  /*
   * Custom CSS
   * */
  function getColor(hex, imp, jq) {
    if (jq) {
      return {
        color: hex + (imp ? ' !important' : ''),
      };
    }
    return `color: ${hex}${imp ? ' !important;' : ';'}`;
  }
  function getFill(hex, imp, jq) {
    if (jq) {
      return {
        fill: hex + (imp ? ' !important' : ''),
      };
    }
    return `fill: ${hex}${imp ? ' !important;' : ';'}`;
  }

  function getBorder(hex, imp, jq) {
    if (jq) {
      return {
        border: `1px solid ${hex}${imp ? ' !important' : ''}`,
      };
    }
    return `border: 1px solid ${hex}${imp ? ' !important;' : ';'}`;
  }

  function getBGColor(hex, imp, jq) {
    if (jq) {
      return {
        background: hex + (imp ? ' !important' : ''),
        'background-color': hex + (imp ? ' !important' : ''),
        'background-image': `linear-gradient(0deg, ${hex} 0%, ${hex} 100%)${imp ? ' !important' : ''}`,
      };
    }
    return [
      `background: ${hex}${imp ? ' !important;' : ';'}`,
      `background-color: ${hex}${imp ? ' !important;' : ';'}`,
      `background-image: linear-gradient(0deg, ${hex} 0%, ${hex} 100%)${imp ? ' !important;' : ';'}`,
    ].join(' ');
  }
  /*
   * Converts color names to hex
   */
  function colourNameToHex(colour) {
    var colours = {
      aliceblue: '#f0f8ff',
      antiquewhite: '#faebd7',
      aqua: '#00ffff',
      aquamarine: '#7fffd4',
      azure: '#f0ffff',
      beige: '#f5f5dc',
      bisque: '#ffe4c4',
      black: '#000000',
      blanchedalmond: '#ffebcd',
      blue: '#0000ff',
      blueviolet: '#8a2be2',
      brown: '#a52a2a',
      burlywood: '#deb887',
      cadetblue: '#5f9ea0',
      chartreuse: '#7fff00',
      chocolate: '#d2691e',
      coral: '#ff7f50',
      cornflowerblue: '#6495ed',
      cornsilk: '#fff8dc',
      crimson: '#dc143c',
      cyan: '#00ffff',
      darkblue: '#00008b',
      darkcyan: '#008b8b',
      darkgoldenrod: '#b8860b',
      darkgray: '#a9a9a9',
      darkgreen: '#006400',
      darkkhaki: '#bdb76b',
      darkmagenta: '#8b008b',
      darkolivegreen: '#556b2f',
      darkorange: '#ff8c00',
      darkorchid: '#9932cc',
      darkred: '#8b0000',
      darksalmon: '#e9967a',
      darkseagreen: '#8fbc8f',
      darkslateblue: '#483d8b',
      darkslategray: '#2f4f4f',
      darkturquoise: '#00ced1',
      darkviolet: '#9400d3',
      deeppink: '#ff1493',
      deepskyblue: '#00bfff',
      dimgray: '#696969',
      dodgerblue: '#1e90ff',
      firebrick: '#b22222',
      floralwhite: '#fffaf0',
      forestgreen: '#228b22',
      fuchsia: '#ff00ff',
      gainsboro: '#dcdcdc',
      ghostwhite: '#f8f8ff',
      gold: '#ffd700',
      goldenrod: '#daa520',
      gray: '#808080',
      green: '#008000',
      greenyellow: '#adff2f',
      honeydew: '#f0fff0',
      hotpink: '#ff69b4',
      'indianred ': '#cd5c5c',
      indigo: '#4b0082',
      ivory: '#fffff0',
      khaki: '#f0e68c',
      lavender: '#e6e6fa',
      lavenderblush: '#fff0f5',
      lawngreen: '#7cfc00',
      lemonchiffon: '#fffacd',
      lightblue: '#add8e6',
      lightcoral: '#f08080',
      lightcyan: '#e0ffff',
      lightgoldenrodyellow: '#fafad2',
      lightgrey: '#d3d3d3',
      lightgreen: '#90ee90',
      lightpink: '#ffb6c1',
      lightsalmon: '#ffa07a',
      lightseagreen: '#20b2aa',
      lightskyblue: '#87cefa',
      lightslategray: '#778899',
      lightsteelblue: '#b0c4de',
      lightyellow: '#ffffe0',
      lime: '#00ff00',
      limegreen: '#32cd32',
      linen: '#faf0e6',
      magenta: '#ff00ff',
      maroon: '#800000',
      mediumaquamarine: '#66cdaa',
      mediumblue: '#0000cd',
      mediumorchid: '#ba55d3',
      mediumpurple: '#9370d8',
      mediumseagreen: '#3cb371',
      mediumslateblue: '#7b68ee',
      mediumspringgreen: '#00fa9a',
      mediumturquoise: '#48d1cc',
      mediumvioletred: '#c71585',
      midnightblue: '#191970',
      mintcream: '#f5fffa',
      mistyrose: '#ffe4e1',
      moccasin: '#ffe4b5',
      navajowhite: '#ffdead',
      navy: '#000080',
      oldlace: '#fdf5e6',
      olive: '#808000',
      olivedrab: '#6b8e23',
      orange: '#ffa500',
      orangered: '#ff4500',
      orchid: '#da70d6',
      palegoldenrod: '#eee8aa',
      palegreen: '#98fb98',
      paleturquoise: '#afeeee',
      palevioletred: '#d87093',
      papayawhip: '#ffefd5',
      peachpuff: '#ffdab9',
      peru: '#cd853f',
      pink: '#ffc0cb',
      plum: '#dda0dd',
      powderblue: '#b0e0e6',
      purple: '#800080',
      red: '#ff0000',
      rosybrown: '#bc8f8f',
      royalblue: '#4169e1',
      saddlebrown: '#8b4513',
      salmon: '#fa8072',
      sandybrown: '#f4a460',
      seagreen: '#2e8b57',
      seashell: '#fff5ee',
      sienna: '#a0522d',
      silver: '#c0c0c0',
      skyblue: '#87ceeb',
      slateblue: '#6a5acd',
      slategray: '#708090',
      snow: '#fffafa',
      springgreen: '#00ff7f',
      steelblue: '#4682b4',
      tan: '#d2b48c',
      teal: '#008080',
      thistle: '#d8bfd8',
      tomato: '#ff6347',
      turquoise: '#40e0d0',
      violet: '#ee82ee',
      wheat: '#f5deb3',
      white: '#ffffff',
      whitesmoke: '#f5f5f5',
      yellow: '#ffff00',
      yellowgreen: '#9acd32',
    };
    if (typeof colours[colour.toLowerCase()] != 'undefined') {
      return colours[colour.toLowerCase()];
    }
    return colour;
  }
  /*
   * Lighten/darken color
   */
  function ColorLuminance(color, percent) {
    var num = parseInt(color.slice(1), 16);
    var amt = Math.round(2.55 * percent);
    var R = (num >> 16) + amt; // eslint-disable-line
    var B = (num >> 8 & 0x00FF) + amt; // eslint-disable-line
    var G = (num & 0x0000FF) + amt; // eslint-disable-line
    return (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)) // eslint-disable-line
      .toString(16)
      .slice(1);
  }
  /*
   * Custom append !important to styling
   */
  var important = [
    'generalThemeFontColour',
    'generalBGColour',
    'formTextColour',
    'buttonLinkSelectionBGColour',
    'buttonLinkSelectionColour',
    'widgetWidgetHeaderColour',
    'generalSideBarBGColour',
    'formBGColour',
    'buttonLinkSelectionColour',
    'buttonLinkSelectionBGColour',
    'formFormLinkBGColour',
    'buttonLinkBGColour',
    'buttonLinkTextColour',
    'buttonLinkLinkColour',
    'buttonLinkSelectionBGColour',
    'buttonLinkSelectionColour',
  ];
  var useJquery = [];
  var cssExtra = {
    generalSideBarBGColour(hex, prefix) {
      var eSel;
      var eStyles;
      var eStyleDarker;
      var eStyleLighter;
      var eCss = [];

      // top nav bar collapse
      eStyleLighter = `#${ColorLuminance(hex, 15)}`;
      eSel = [
        '.app-mobile .navbar .navbar-collapse',
      ];
      eStyles = [
        `background: ${hex} !important;`,
      ];
      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });

      // top nav line
      eStyleLighter = `#${ColorLuminance(hex, 15)}`;
      eSel = [
        '.app-mobile .navbar .navbar-right > li.user-account-line:before',
      ];
      eStyles = [
        `background: ${eStyleLighter} !important;`,
      ];

      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });

      // Search Form background sidebar
      eStyleDarker = `#${ColorLuminance(hex, -15)}`;
      eSel = [
        '.crm-sidebar .search-form #search-input-text',
      ];
      eStyles = [
        `background: ${eStyleDarker} !important;`,
      ];

      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });

      // Search Form border sidebar
      eStyleDarker = `#${ColorLuminance(hex, -20)}`;
      eSel = [
        '.crm-sidebar .search-form #search-input-text',
      ];
      eStyles = [
        `border: 1px solid ${eStyleDarker} !important;`,
      ];

      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });


      // Nav app closed, arrow
      eStyleLighter = `#${ColorLuminance(hex, 15)}`;
      eSel = [
        '.app-sidebar-closed .app-aside > .item-inner:before',
      ];
      eStyles = [
        `border-color: ${eStyleLighter};`,
      ];

      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });

      // Nav separators styling
      eStyleDarker = `#${ColorLuminance(hex, -5)}`;
      eStyleLighter = `#${ColorLuminance(hex, 15)}`;
      eSel = [
        '.navbar-brand:after',
        '.crm-sidebar > div nav > ul > li.item-nav-break',
        '.crm-sidebar .search-form.search-border-block:after',
        '.crm-sidebar .search-form .navbar-form:after',

      ];
      eStyles = [
        `background: ${eStyleDarker} !important;`,
        `border-bottom: 1px solid ${eStyleLighter} !important;`,
      ];

      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });

      // Nav separators mobile styling
      eStyleDarker = `#${ColorLuminance(hex, -5)}`;
      eSel = [
        '.crm-sidebar .search-form .actionsTbl tr td',
      ];
      eStyles = [
        `border-right: 1px solid ${eStyleDarker} !important;`,
      ];

      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });

      // Sub menu styling
      eStyleDarker = `#${ColorLuminance(hex, 7)}`;
      eSel = [
        '.crm-sidebar > div nav > ul > li > ul.sub-menu > li:hover',
        '.crm-sidebar > div nav > ul > li > ul.sub-menu > li.active',
        '.app-sidebar-closed .app-aside ul.sub-menu > li:hover',
        '.app-sidebar-closed .app-aside ul.sub-menu > li.active',
        '.app-sidebar-closed .crm-sidebar > div nav > ul > li.hover',
        '.app-sidebar-closed .crm-sidebar > div nav > ul > li.active',
        '.app-sidebar-closed .app-aside > .item-inner',
        '.app-sidebar-closed .app-aside > .item-inner *',
        '.app-sidebar-closed .app-aside > .item-inner.active',
        '.app-sidebar-closed .app-aside ul.sub-menu',
      ];
      eStyles = [
        `background: ${eStyleDarker} !important;`,
        `background-color: ${eStyleDarker} !important;`,
      ];

      Object.keys(eSel).forEach((e) => {
        eCss.push([prefix + eSel[e], '{ ', eStyles.join(' '), ' }'].join(' '));
      });

      return eCss.join(' ');
    },
    generalSideBarFontColour(hex, prefix) {
    // icon styling
      return [`${prefix}.sidebar-icon path`, '{ ', `${'fill: ' + '#'}${ColorLuminance(hex, 5)} !important`, ' }'].join(' ');
    },
  };
  var cssMap = {
    general: {
      SideBarFontColour: [
        [
          '.crm-sidebar > div nav > ul > li .item-inner',
          '.crm-sidebar > div nav > ul > li .item-inner *',
          '.crm-sidebar > div nav > ul > li > ul > li',
          '.crm-sidebar > div nav > ul > li > ul > li *',
          '.crm-sidebar > div nav > ul > li .item-media',
          '.crm-sidebar > div nav > ul > li .item-media *',
          '.crm-sidebar.sidebar-main > div nav > ul > li .item-inner',
          '.crm-sidebar.sidebar-main > div nav > ul > li .item-media',
          '.crm-sidebar > div nav .navbar-title',
          '.app-sidebar-closed .app-aside ul.sub-menu *',
        ],
        getColor,
        getFill,
      ],
      SideBarBGColour: [
        [
          '.crm-sidebar > .sidebar-container',
          '.crm-sidebar > .sidebar-container .search-form .navbar-form',
          '.crm-sidebar > div nav > ul > li.navbar-item-text.no-hover',
          '.navbar .navbar-header',
          '#loan-tools-page .pdf-preview .pdf-container .pdfPrint .pdfDetails .pdfHeader',
        ],
        getBGColor,
        false,
        [
          '.crm-sidebar > div nav > ul > li.sidebar-item-menu',
          [':hover:before', '.active:before'],
        ],
      ],
      BGColour: [
        '.crm-page',
        getBGColor,
      ],
      ThemeFontColour: [
        [
          '.crm-text',
          '.crm-text > *',
        ],
        getColor,
      ],
    },
    widget: {
      TableHeaderColour: [
        '.crm-widget .widgetBodyHeader div',
        getColor,
      ],
      TableStripeColour: [
        '.crm-widget .widgetAlternateRow',
        function (hex) {
          var tpl = [
          /* For Safari 5.1 to 6.0 */
            'background: -webkit-repeating-linear-gradient(90deg,#ffffff,{hex}) !important;',
            /* For Opera 11.1 to 12.0 */
            'background: -o-repeating-linear-gradient(90deg,#ffffff,{hex}) !important;',
            /* For Firefox 3.6 to 15 */
            'background: -moz-repeating-linear-gradient(90deg,#ffffff,{hex}) !important;',
            /* Standard syntax (must be last) */
            'background: repeating-linear-gradient(90deg,#ffffff,{hex}) !important;',
          ];
          return tpl.join(' ')
            .replace(/\{hex\}/g, hex);
        },
      ],
      TextColour: [
        [
          '.crm-widget .widgetRow div div span',
          '.crm-widget .slick-slide .row div div span',
          '.crm-widget .slick-slide .row div div div',
        ],
        getColor,
      ],
      WidgetBorderColour: [
        '.crm-widget',
        getBorder,
      ],
      WidgetHeaderColour: [
        '.crm-widget .widgetHeading',
        getColor,
      ],
    },
    buttonLink: {
      BGColour: [
        [
          '.crm-btn',
          '.crm-btn.btn-purple',
          '.crm-isteven-multi-select .helperButton:first-child',
          '.crm-isteven-multi-select .multiSelectItem.selected:not(.multiSelectGroup)',
          '.crm-isteven-multi-select .multiSelectItem:hover ',
        ],
        getBGColor,
        getBorder,
        ['.crm-btn', [':hover', ':active']],
      ],
      LinkColour: [
        '.crm-link',
        getColor,
        false,
        ['.crm-link', [':hover', ':active']],
      ],
      SelectionBGColour: [
        [
          '.crm-link::selection',
          '.crm-btn::selection',
          '.crm-tabbable > .nav-tabs > li.active > a',
        /* '.crm-isteven-multi-select .multiSelectItem ',
        '.crm-isteven-multi-select .multiSelectItem:hover ' */
        ],
        getBGColor,
      ],
      SelectionColour: [
        [
          '.crm-link::selection',
          '.crm-btn::selection',
        ],
        getColor,
      ],
      TextColour: [
        '.crm-btn',
        getColor,
      ],
    },
    form: {
      TextColour: [
        [
          '.crm-form label',
        ],
        getColor,
      ],
      BGColour: [
        [
          'input:not(.remove-theme)',
          '.input-group > .input-group-addon',
          'select',
          'textarea',
          '.ui-select-container .btn-default',

          '.input-theme',
          'span select',
          '.input-isteven.crm-isteven-multi-select.default-style span.multiSelect button',
          'form div.form-group.form-control',

        ],
        // getBGColor,
        function (hex) {
          var tpl = [
            /* For Safari 5.1 to 6.0 */
            'background-image: linear-gradient(0deg, {hex} 0%, #FFFFFF 100%) !important;',
          ];
          return tpl.join(' ')
            .replace(/\{hex\}/g, hex);
        },
      ],
      // having defined background colors for links is crazy
      /* FormLinkBGColour: [
      '.crm-form a',
      getBGColor
    ], */
      FormSelectionColour: [
        [
          // '.crm-form label::selection',
          'input::selection',
          'textarea::selection',

          '.input-theme',
          'select',
          'span select',
          '.input-isteven.crm-isteven-multi-select.default-style span.multiSelect button',
          'form div.form-group.form-control',
        ],
        getColor,
      ],
      FormSelectionBGColour: [
        [
          // '.crm-form label::selection',
          'input::selection',
          'textarea::selection',

          '.input-theme',
          'select',
          'span select',
          '.input-isteven.crm-isteven-multi-select.default-style span.multiSelect button',
          'form div.form-group.form-control',
        ],
        getBGColor,
      ],
    },
  };
  const setBrokerCountryInfo = () => {
    factory.brokerCountryInfo = currentUserService.crmCountry;
  }
  setBrokerCountryInfo();
  const isCustomizeThemeEnabled = () => {
    return (configService.feature && configService.feature.customizeTheme);
  }

  const getDefaultColorAccordingToBranding = (brandingCategoryId) => {

    switch(brandingCategoryId) {
      case BRANDING_CATEGORY_TYPE.LOAN_MARKET:
        if(factory.brokerCountryInfo === 'Australia') {
          return COLOR.CERULEAN;
        }
        else {
          return COLOR.HAITI;
        }
        break;
      case BRANDING_CATEGORY_TYPE.WEALTH_MARKET:
        return COLOR.LUCKY_POINT
        break;
      case BRANDING_CATEGORY_TYPE.INSURANCE_MARKET:
        return COLOR.SUSHI;
        break;
      default:
        return COLOR.HAITI;
    }

  }
  const defaultSidebarBG = (subSection, isUseDefaultSidebarBG, brandingCategoryId) => {
    if(!isUseDefaultSidebarBG) return;
    
    if(subSection === 'SideBarFontColour') return COLOR.WHITE;
    if(subSection !== 'SideBarBGColour') return;

    return getDefaultColorAccordingToBranding(brandingCategoryId);
  }
  factory.generateCSS = function (sect, cssId, prefix, custom, isUseDefaultSidebarBG, brandingCategoryId) {
        /*
         * Generate css
         */
        var sel;
        var fn;
        var fn2;
        var pseudo;
        var hex;
        var imp;
        var style;
        var pseudoStyle = [];
        var pseudoRgb;
        var pseudoSel;
        var sStyle;
        var cssStr = custom ? 'customCSS' : 'previewCSS';


        objectLength(cssMap) && Object.keys(cssMap[sect]).forEach((m) => {
          try {
            const subSection = cssMap[sect][m];
            sel = subSection[0];
            fn = subSection[1];
            fn2 = subSection[2];
            pseudo = subSection[3];
            hex = defaultSidebarBG(m, isUseDefaultSidebarBG, brandingCategoryId) || factory.theme[sect][m];

            hex = colourNameToHex(hex.toString()
              .toLowerCase());
            imp = important.indexOf(sect + m) > -1;
            prefix += (prefix.trim()
              .length > 0 ? ' ' : '');

            // pseudo styling
            if (pseudo && pseudo instanceof Array) {
              pseudoRgb = `#${ColorLuminance(hex, 10)}`;
              sStyle = fn(pseudoRgb, imp) + (fn2 ? fn2(pseudoRgb, imp) : '');
              pseudoSel = pseudo[0];
              if (pseudoSel instanceof Array) {
                Object.keys(pseudoSel).forEach((s) => {
                  Object.keys(pseudo[1]).forEach((p) => {
                    pseudoStyle.push([prefix + pseudoSel[s] + pseudo[1][p], '{', sStyle, ' }'].join(' '));
                  });
                });
              } else {
                Object.keys(pseudo[1]).forEach((p) => {
                  pseudoStyle.push([prefix + pseudoSel + pseudo[1][p], '{', sStyle, ' }'].join(' '));
                });
              }
              factory[cssStr] += pseudoStyle.join(' ');
            }

            if (typeof cssExtra[sect + m] == 'function') {
              var fnExtra = cssExtra[sect + m];
              factory[cssStr] += fnExtra(hex, prefix);
            }

            if (sel instanceof Array) {
              sel = prefix + sel.join(`, ${prefix}`);
            } else {
              sel = prefix + sel;
            }

            if (useJquery.indexOf(sect + m) > -1) {
              style = angular.extend(fn(hex, imp, true), (fn2 ? fn2(hex, imp, true) : []));
              $(sel)
                .css(style);
            } else {
              style = [sel, '{', fn(hex, imp) + (fn2 ? fn2(hex, imp) : ''), ' }'].join(' ');
              factory[cssStr] += style;
            }
          } catch (e) {
            // do nothing
          }
        });
        factory.injectCSS(factory[cssStr], cssId);
  };
  factory.injectCSS = function (css, injectId) {
    var style = $window.document.getElementById(injectId);
    var head = $window.document.getElementsByTagName('head')[0];
    if (typeof injectId === 'undefined') return;
    if (style !== null) return style.innerHTML = css;
    style = $window.document.createElement('style');
    style.type = 'text/css';
    style.id = injectId;
    style.innerHTML = css;
    head.appendChild(style);
    // will remove the preloader
    angular.element('.app-preloader').addClass('app-fadeout');
  };
  factory.previewCssId = 'preview-styles';
  factory.previewPrefix = '.preview-content';
  factory.globalCssId = 'global-styles';
  factory.globalPrefix = '';
  factory.updateThemePreview = function (theme) {
    factory.theme = theme;
    factory.previewCSS = '';
    Object.keys(theme).forEach((t) => {
      factory.generateCSS(t, factory.previewCssId, factory.previewPrefix, false);
    });
  };
  factory.initPreviewCSS = function () {
    factory.previewCSS = '';
    factory.initCustomCSS(false, factory.previewCssId, factory.previewPrefix);
  };
  factory.initGlobalCSS = function (isUseDefaultSidebarBG, brandingCategoryId) {
    factory.customCSS = '';
    factory.initCustomCSS(true, factory.globalCssId, factory.globalPrefix, isUseDefaultSidebarBG, brandingCategoryId);
  };
  factory.initCustomCSS = function (custom, cssId, prefix, isUseDefaultSidebarBG, brandingCategoryId) {
    // Form theme info
    function form() {
      factory.formThemeGet()
        .then((response) => {
          if (response.data) {
            factory.theme.form = response.data;
            Object.keys(factory.theme).forEach((t) => {
              factory.generateCSS(t, cssId, prefix, custom, isUseDefaultSidebarBG, brandingCategoryId);
            });
          }
        });
    }
    // ButtonLink theme info
    function buttonLink() {
      factory.buttonLinkThemeGet()
        .then((response) => {
          if (response.data) {
            factory.theme.buttonLink = response.data;
            form();
          }
        });
    }
    // Widget theme info
    function widget() {
      factory.widgetThemeGet()
        .then((response) => {
          if (response.data) {
            factory.theme.widget = response.data;
            buttonLink();
          }
        });
    }
    // Branding logo
    function loadBrandingLogo() {
      if (!factory.theme || !factory.theme.general || Number(factory.theme.general.CRM_LogoDocId) === 0) return;

      userService.GetUserInfo()
        .then((userInfoResponse) => {
          if(!userInfoResponse.data) return;

          const isValidToShowCustomLogo = isCustomizeThemeEnabled() || factory.brokerCountryInfo === 'New Zealand';
          if(!isValidToShowCustomLogo)  return;

          dashboardService.getBrokerBasicInfo().then((response) => {
            const { data } = response;
            if(!data || !data.BrokerId) return;

            regionalizationService.getBrokerRegionalization(data.BrokerId)
              .then((responseBroker) => {
                const { data } = responseBroker;
                if(!data) return;

                const isValidCountryCode = (data.Code === 'NZD' || data.Code === 'AUD')
                if (isValidCountryCode && Number(factory.theme.general.CRM_LogoDocId) > 0) {
                  generalService.documentGet(factory.theme.general.CRM_LogoDocId)
                    .then((responseDoc) => {
                      const isNotAllowedToSetLogo = !responseDoc || !responseDoc.data || !responseDoc.data.ContentType || !responseDoc.data.DocumentContent;

                      if(isNotAllowedToSetLogo) return;

                      const { data } = responseDoc;

                      factory.setBrandingLogo(`data:${data.ContentType};base64,${data.DocumentContent}`);
                      factory.setBrandingLogoPreview(`data:${data.ContentType};base64,${data.DocumentContent}`);
                  },
                  () => {
                    toaster.pop('warning', `Logo can't be found, please upload a new one.`);
                  });
                }
            });
          });
        })
    }
    // General theme info
    function general() {
      factory.generalThemeGet()
        .then((response) => {
          if (response.data) {
            factory.theme.general = response.data;
            loadBrandingLogo();
            widget();
          }
        });
    }
    general();
  };

  factory.customLogoSet = function (docId) {
    var defer = $q.defer();
    themeModel.customLogoSet(docId, (response) => {
      return defer.resolve(response);
    }, (error) => {
      return defer.reject(error);
    });
    return defer.promise;
  };
  factory.setBrandingLogoPreview = function (logo) {
    factory.branding.logoPreview = logo;
  };
  factory.setBrandingLogo = function (logo) {
    factory.branding.logo = logo;
  };

  factory.setDefaultSidebarLogo = (src) => {
    $rootScope.app.layout.logo = src;
  }
  factory.getDefaultLogoDirectories = () => {
    return {
      WEALTH_MARKET: 'assets/images/defaultBrandingLogo/wealth-market-default-logo.svg',
      INSURANCE_MARKET: 'assets/images/defaultBrandingLogo/insurance-market-default-logo.svg',
      LOAN_MARKET: 'assets/images/defaultBrandingLogo/loan-market-default-logo.svg',
    }
  }
  return factory;
});
