import angular from 'angular';
import moment from 'moment';

angular.module('app').controller('MyDocumentsModalCtrl', function MyDocumentsModalCtrl(
  $uibModalInstance,
  successUploadResponse,
  userInfo,
  loanToolsService,
  contactService,
  toaster) {
  const vm = this;

  // models
  vm.fileObject = {};

  const cancel = () => {
    $uibModalInstance.dismiss();
  };

  const uploadMyDocument = (isValid) => {
    if (isValid) {
      loanToolsService.resourceMyDocumentsSet(vm.fileObject)
        .then(() => {
          loanToolsService.resourceMyDocumentsGet(0).then((response) => {
            const theResponse = response;
            toaster.pop('success', 'Sucess', 'Document saved');
            $uibModalInstance.close(theResponse);
          });
        });
    }
  };

  const checkIfAdd = () => {
    let toReturnValue;

    if (typeof successUploadResponse === 'object') {
      toReturnValue = true;
    } else {
      toReturnValue = false;
    }
    return toReturnValue;
  };

  const initProcessForAdd = () => {
    vm.fileObject = {
      documentFileID: 0,
      documentID: successUploadResponse[0].DocumentId,
      title: '',
      description: '',
      contentType: successUploadResponse[0].ContentType,
      writtenBy: userInfo.FullName,
      dateUploaded: moment(moment().format('D MMM, YYYY'), 'D MMM YYYY').format('D MMM YYYY'),
      version: '',
    };
  };

  const initProcessForEdit = () => {
    loanToolsService.resourceMyDocumentsGet(successUploadResponse)
      .then((response) => {
        if (!response || !response.length) return;

        vm.fileObject = response[0];
      });
  };

  const init = () => {
    vm.isAdd = checkIfAdd();

    if (vm.isAdd) {
      initProcessForAdd();
    } else {
      initProcessForEdit();
    }
  };

  vm.uploadMyDocument = uploadMyDocument;
  vm.cancel = cancel;

  init();
});
