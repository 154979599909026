import swal from 'sweetalert';

export function adviserAllocateSwal(title = '', callback) {
  swal({
    title: 'Confirm Change of Adviser',
    text: `By clicking proceed, the allocated adviser of this loan will be changed to ${title}?`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Proceed',
    closeOnConfirm: true,
  }, callback);
}
