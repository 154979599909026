import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

angular.module('app').controller('RatingModalCtrl', function RatingModalCtrl(
  $scope, $uibModalInstance, contactService, SweetAlert, toaster
) {
  $scope.ratingModalType = 'Select';
  $scope.isNewRatingVisible = false;
  $scope.newRatingList = [];
  $scope.existingRatingNameList = [];

  function getRating() {
    contactService.getRating()
      .then((response) => {
        $scope.existingRatingNameList = [];
        const listOfRating = [];
        angular.forEach(response.data, (value) => {
          if (listOfRating.indexOf(value.RatingName) === -1 && value.RatingName !== '') {
            listOfRating.push(value.RatingName);
            $scope.existingRatingNameList.push(value);
          }
        });
      });
  }

  $scope.isRatingPresent = function (rating) {
    return $scope.getContactFamilyInfo.Rating.indexOf(rating) !== -1;
  };

  $scope.ratingCheckedAdd = function (rating, checked) {
    if (checked) {
      $scope.contactInfoSetValue.Rating.push(rating);
    } else {
      const ratingIndex = $scope.contactInfoSetValue.Rating.indexOf(rating);
      $scope.contactInfoSetValue.Rating.splice(ratingIndex, 1);
    }
  };

  $scope.addRating = function () {
    $scope.isNewRatingVisible = true;
    const newRating = { RatingID: Math.random(), RatingName: '', IsChecked: true };
    $scope.newRatingList.push(newRating);
  };

  $scope.deleteRating = function (ratingID) {
    swal({
      title: 'Are you sure?',
      text: 'This record will be removed from your rating list',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, remove it!',
      closeOnConfirm: true,
    }, (confirm) => {
      if (confirm) {
        contactService
          .deleteRating(ratingID)
          .then(() => {
            getRating();
            toaster.pop('success', 'Deleted', 'Rating has been successfully deleted');
          })
          .catch(displayError);
      }
    });
  };
  $scope.saveRating = function (rating) {
    if (rating.RatingName === '') {
      swal('Empty Name', 'Rating Name cannot be empty.', 'error');
      return;
    }

    contactService
      .addRating(rating.RatingName)
      .then(() => {
        const index = $scope.newRatingList.indexOf(rating);
        if (index > -1)
          $scope.newRatingList.splice(index, 1);
        getRating();
        toaster.pop('success', 'Added', 'Rating has been successfully added');
      })
      .catch(displayError);
  };

  $scope.hideEditButton = function (rating) {
    rating.IsEditMode = true;
  };

  $scope.updateRating = function (currentlyEditedRating) {
    if (currentlyEditedRating.RatingName === '') {
      swal('Empty Name', 'Rating Name cannot be empty.', 'error');
      return;
    }
    currentlyEditedRating.IsEditMode = false;

    contactService
      .updateRating(
        currentlyEditedRating.RatingID,
        currentlyEditedRating.RatingName
      )
      .then(() => {
        toaster.pop('success', 'Updated', 'Rating has been successfully updated');
      })
      .catch(displayError);
  };

  function reorder(rating, orderBy) {
    contactService.updateRatingOrder(rating, orderBy)
      .then(() => {
        getRating();
      });
  }

  $scope.reorderUp = function (rating, index) {
    if (index > 0) {
      const itemAbove = $scope.filteredItems[$scope.filteredItems.indexOf(rating) - 1];
      reorder(rating.RatingID, itemAbove.OrderBy);
    }
  };

  $scope.reorderDown = function (rating, index) {
    if (index + 1 < $scope.existingRatingNameList.length) {
      const itemBelow = $scope.filteredItems[$scope.filteredItems.indexOf(rating) + 1];
      reorder(rating.RatingID, itemBelow.OrderBy);
    }
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.done = function () {
    $scope.referralChecker('Rating');
    contactService.contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue)).then(() => {
      $scope.contactFamilyInfoGet();
      toaster.pop('success', 'Updated', 'Rating has been successfully updated');
    });
    $uibModalInstance.dismiss('cancel');
  };

  function init() {
    getRating();
  }

  init();
});
