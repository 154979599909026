

export default class ProviderInformationCtrl {
  constructor(
    crmConfirmation,
    lenderRatesService,
    uiService
  ) {
    'ngInject';

    this.crmConfirmation = crmConfirmation;
    this.lenderRatesService = lenderRatesService;
    this.uiService = uiService;
    this.status = {
      isopen: false,
    };
  }


  $onInit() {
    this.providerLogo = this.getLogo();
    this.providerRatesData = this.providerRatesData.sort((leftRate, rightRate) => {
      if (leftRate.loanTerm < rightRate.loanTerm) return -1;
      else if (leftRate.loanTerm > rightRate.loanTerm) return 1;
      return 0;
    });
  }

  toggleDropdown($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  getLogo() {
    const fileName = this.providerName.replace('/', '-').trim();
    const folder = `lenderLogoLg/${this.providerCountryCode}`;
    return `assets/images/${folder}/${fileName}@2x.png`;
  }

  onprogressMessage() {
    this.crmConfirmation.open({
      type: 'info',
      title: 'Coming Soon',
      description: 'Under development',
    });
  }

  openProductFinderWith(rate) {
    if (!rate) return;

    const loanTermByYear = rate.loanTerm / 12;
    const rateType = rate.loanTerm ? `Fixed|${loanTermByYear}` : 'Variable';
    this.lenderRatesService.setLenderRateInfoForProductFinder({
      CurrentRate: rate.currentRate,
      PrevRate: rate.previousRate,
      LoanTerm: rate.loanTerm.toString(),
      lenderIdArray: [this.providerLenderId],
      lenderName: this.providerName,
      year: rate.loanTermText,
      rateType,
    });
    this.uiService.redirectToLoanProfiler('productFinder');
  }
}
