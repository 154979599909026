import { findIndex } from 'lodash';

class TimeoutService {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
    this.timeouts = {};
  }

  addTimeout(timeout, context) {
    if (!timeout || !context) return;

    if (this.timeouts[context]) {
      timeout.id = this.timeouts[context].length + 1;
      this.timeouts[context] = [...this.timeouts[context], timeout];
    } else {
      timeout.id = 1;
      this.timeouts[context] = [timeout];
    }
  }

  cancelTimeout(context, timeout) {
    if (!context || !timeout) return;

    this.$timeout.cancel(timeout);
    if (this.timeouts[context]) {
      const indexOfTimeout = findIndex(this.timeouts[context], currentTimeout => currentTimeout.id === timeout.id);
      if (indexOfTimeout !== -1) {
        this.timeouts[context].splice(indexOfTimeout, 1);
      }
    }
  }

  cancelTimeouts(context) {
    if (!context) return;

    if (this.timeouts[context] && this.timeouts[context].length) {
      this.timeouts[context].forEach((timeout) => {
        if (timeout) this.$timeout.cancel(timeout);
      });

      this.timeouts[context] = null;
    }
  }
}

export default TimeoutService;
