import angular from 'angular';

angular.module('app').controller('AddressesDetailCtrl',
  function AddressesDetailCtrl($scope,
    $interval,
    mapService,
    $uibModal,
    $stateParams,
    contactService,
    SweetAlert,
    $timeout,
    $state,
    $window,
    uiService) {
    mapService.loadAPI();

    $scope.familyId = $stateParams.familyId;
    $scope.addressId = $stateParams.addressId;
    let clientHeaderName = '';

    contactService.clientInformGet($scope.familyId)
      .then((response) => {
        angular.forEach(response.data, (client) => {
          if (client.Role.toLowerCase() === 'adult') {
            // will only display header name for adults
            clientHeaderName = uiService.generateClientHeaderName(clientHeaderName, client, response.data);
          }
        });
        $scope.clientHeaderName = clientHeaderName;
      });

    $scope.generateContactMap = (formattedAddressParam, latitude, longitude) => {
      let formattedAddress = formattedAddressParam;
      if (!formattedAddress) {
        formattedAddress = 'Your Address';
      }
      const googleInterval = $interval(() => {
        if (!$window.google) return false;
        mapService.mapDesign(latitude, longitude, formattedAddress, undefined, true, 'addressesMapRender', '30');
        $interval.cancel(googleInterval);
      }, 100);
    };

    $scope._init = () => {
      let ownerNames = '';
      contactService.contactAddressDetailsGet($scope.addressId).then((response) => {
        $scope.propertyDetailsList = response.data;
        $scope.propertyDetailsList.FullAddress = $scope.propertyDetailsList.Address;

        $scope.generateContactMap($scope.propertyDetailsList.Address.formatted_address, $scope.propertyDetailsList.Address.latitude, $scope.propertyDetailsList.Address.longitude);

        angular.forEach($scope.propertyDetailsList.Ownership, (owner) => {
          if (ownerNames !== '') {
            ownerNames += ` & ${owner.FirstName} ${owner.LastName}`;
          } else if (response.data && response.data.length > 1) {
            ownerNames += owner.FirstName;
          } else {
            ownerNames += `${owner.FirstName} ${owner.LastName}`;
          }
        });
        $scope.ownerNames = ownerNames;
      });
    };


    // init
    $scope._init();
    $scope.modalType = 'edit';
    $scope.editAddressesModal = () => {
      const familyId = parseInt($scope.familyId, 10);
      $uibModal.open({
        templateUrl: `/assets/views/contacts/addresses/addressesModal.html`,
        controller: 'AddressesNewModalCtrl',
        resolve: {
          modalType() {
            return $scope.modalType;
          },
          familyId() {
            return familyId;
          },
          contactPropertySet() {
            return $scope.propertyDetailsList;
          },
        },
      });
    };

    $scope.deleteAddress = () => {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This record will be removed from your property list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      }, (confirm) => {
        if (confirm) {
          contactService.contactPropertyDelete($scope.addressId, $scope.familyId).then(() => {
            $timeout(() => {
              $state.go('app.contactsSingle', {
                familyId: $scope.familyId,
                activeTab: 'addresses',
              });
              SweetAlert.swal({
                title: 'Success',
                text: 'Property has been been successfully deleted.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            }, 100);
          });
        }
      });
    };
  });
