import swal from 'sweetalert';
import toaster from 'toastr';
import { filter } from 'lodash';

export default class OpportunityCalculationsCtrl {
  constructor(
    loanCalculatorLinking,
    $sce,
    $stateParams,
    loanOpportunityService,
    contactService) {
    'ngInject';

    this.loanCalculatorLinking = loanCalculatorLinking;
    this.$sce = $sce;
    this.$stateParams = $stateParams;
    this.loanOpportunityService = loanOpportunityService;
    this.contactService = contactService;
  }

  $onInit() {
    this.calculationTypes = {
      servicibility: {
        title: `Serviceability Calculator`,
        shortTitle: `Serviceability`,
        id: `serviceability`,
        typeOfCalculation: 'Serviceability',
      },
      funding: {
        title: `Funding Calculator`,
        shortTitle: `Funding Calculator`,
        id: `funding`,
        typeOfCalculation: 'FundingCalculator',
      },
      product: {
        title: `Product Comparision`,
        shortTitle: `Product Comparision`,
        id: `productFinder`,
        typeOfCalculation: 'ProductFinder',
      },
    };

    this.calculationTitles = {
      Serviceability: `Serviceability`,
      FundingCalculator: `Funding Calculator`,
      ProductFinder: `Product Comparision`,
    };

    this.getCalculations();
  }

  getCalculations() {
    const { opportunityId } = this.$stateParams;
    this.loanOpportunityService.getLoanOpportunityCalculation(opportunityId)
      .then((response) => {
        if (!response) return;
        this.calculations = [...response];
      });
  }

  openCalculatorList(bool) {
    this.isCalculatorListOpen = bool;
  }

  openCalculation(calculationObj) {
    this.checkCalculcationsList();
    const tabName = filter(this.calculationTypes, (obj) => {
      return obj.typeOfCalculation === calculationObj.typeOfCalculation;
    });
    const args = {
      tabName: tabName[0].id,
      eventId: calculationObj.brokerEventId,
      id: this.opportunityId,
      linkerId: this.loanCalculatorLinking.LINKERS.OPPORTUNITY,
      title: this.opportunityTitle,
      isEdit: true,
      isOpportunityOrLoanapp: true,
      previousRouteStateParams: { ...this.$stateParams, tabName: 'calculations' },
    };

    this.openCalculationsPage(args);
  }

  removeCalculation($event, calculationObj) {
    $event.stopPropagation();
    const tempCalculations = [...this.calculations];

    swal({
      title: 'Delete Confirmation',
      text: `Are you sure want to delete this calculaltion?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, Delete Calculation',
      closeOnConfirm: true,
    }, (response) => {
      if (!response) return;
      const calculationIdx = this.calculations.findIndex(obj => obj === calculationObj);
      this.calculations.splice(calculationIdx, 1);

      const { typeOfCalculation, brokerEventId } = calculationObj;
      const isFundingCalculatorType = typeOfCalculation === this.calculationTypes.funding.typeOfCalculation;

      const FUNDING_CALCULATOR = 1;
      const SERVICEABILITY_OR_PRODUCTFINDER = 2;

      const calculationTypeParam = (isFundingCalculatorType ? FUNDING_CALCULATOR : SERVICEABILITY_OR_PRODUCTFINDER);
      this.contactService.savedCalculationsDelete(calculationTypeParam, this.$stateParams.opportunityId, brokerEventId)
        .then((result) => {
          if (!result.data) {
            this.calculations = tempCalculations;
            toaster.pop({
              type: 'error',
              body: `There's a problem in deleting this.`,
            });
          }
        });
    });
  }

  checkCalculcationsList() {
    if (!this.calculations || !this.calculations.length) this.calculations = [];
  }

  addCalculation(calculationType) {
    this.checkCalculcationsList();

    const args = {
      tabName: calculationType.id,
      eventId: 0,
      id: this.opportunityId,
      linkerId: this.loanCalculatorLinking.LINKERS.OPPORTUNITY,
      title: this.opportunityTitle,
      isEdit: false,
      isOpportunityOrLoanapp: true,
      previousRouteStateParams: { ...this.$stateParams, tabName: 'calculations' },
    };

    this.openCalculationsPage(args);
  }

  openCalculationsPage(args) {
    const { tabName } = args;
    const isFundingCalculator = (tabName === this.calculationTypes.funding.id || tabName === this.calculationTypes.funding.typeOfCalculation);
    if (isFundingCalculator) {
      args.tabName = this.calculationTypes.funding.id;
      this.loanCalculatorLinking.redirectToFundingCalculator(args);
    } else {
      this.loanCalculatorLinking.redirectToLoanProfiler(args);
    }
  }
}
