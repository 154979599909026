import angular from 'angular';
import _ from 'lodash';
import { isEmploymentIncome } from 'Common/utilities/income';
import { CREDIT_CARDS_ONLY } from 'Common/constants/creditNumberType';

import swal from 'sweetalert';

angular.module('app').factory('financialsSharedService', function financialsSharedService($q, loanScenarioService, utilitiesService, uiService, contactService, $stateParams, SweetAlert, $timeout, loanAppSharedData, optionsService, loanApplicantAssetInfoService, $state, CreditCardService) {
  const factory = this;

  // models
  angular.extend(factory, {
    listApplicants: [], // used by financial.js

    listAssets: [],
    listLiabilities: [],
    listIncome: [],
    listExpense: [],
    selectedFamily: {},
    bgColor: [],

    assetLength: 0,
    liabilityLength: 0,
    incomeLength: 0,
    expenseLength: 0,

    assetTotal: 0,
    NonREATotalAmount: 0,
    RealEstateTotalAmount: 0,
    liabilityTotal: 0,
    incomeTotal: 0,
    expenseTotal: 0,

    liabilityOwnersList: [],
    liabilityOwnersListSelected: [],

    LiabilitiesSet: {},
    creditCardProperty: {},
  });

  // methods
  angular.extend(factory, {

    validateFinancials() {
      this.isValidFinancials =
        this.incomeLength > 0 &&
        this.expenseLength > 0;
      this.isStartedFinancials =
        this.incomeLength > 0 ||
        this.expenseLength > 0;
    },

    updateAsset(FamilyId) {
      const family = _.find(this.listAssets, (o) => {
        return _.isEqual(String(o.FamilyId), String(FamilyId));
      });
      if (family) {
        this.assetLength = _.size(family.overview.FinancialInfo);
        this.assetTotal = family.overview.TotalAmount;
        this.NonREATotalAmount = family.overview.NonREATotalAmount;
        this.RealEstateTotalAmount = family.overview.RealEstateTotalAmount;

        this.validateFinancials();
      }
    },

    updateLiability(FamilyId) {
      const family = _.find(this.listLiabilities, (o) => {
        return _.isEqual(String(o.FamilyId), String(FamilyId));
      });
      if (family) {
        this.liabilityLength = _.size(family.overview.FinancialInfo);
        this.liabilityTotal = family.overview.TotalAmount;

        this.validateFinancials();
      }
    },

    updateIncome(FamilyId) {
      const family = _.find(this.listIncome, (o) => {
        return _.isEqual(String(o.FamilyId), String(FamilyId));
      });
      if (family) {
        this.incomeLength = _.size(family.overview.FinancialInfo);
        this.incomeTotal = family.overview.TotalAmountPerMonth;

        this.validateFinancials();
      }
    },

    updateExpense(FamilyId) {
      const family = _.find(this.listExpense, (o) => {
        return _.isEqual(String(o.FamilyId), String(FamilyId));
      });
      if (family) {
        this.expenseLength = _.size(family.overview.FinancialInfo);
        this.expenseTotal = family.overview.TotalAmountPerMonth;

        this.validateFinancials();
      }
    },

    updateFinancialApplicantList() {
      loanScenarioService.scenarioApplicantListGet($state.params.loanAppId)
        .then((response) => {
          factory.FinancialApplicantList(response.data);
        });
    },

    FinancialApplicantList(data) {
      if (!data) return;
      this.listApplicants = [];
      let tempList = [];

      _.forEach(data.InvolvedPartyPerson, (o) => {
        const person = _.first(o);
        if (person) {
          const { FamilyId, FamilyFullName: FamilyName } = person;
          const initials = utilitiesService.filterInitialOneString(person.FamilyFullName);
          const background = this.getBGcolor(FamilyId);
          const listPriority = (person.IsApplicant ? 0 : 1);
          tempList.push({ FamilyId, FamilyName, initials, background, listPriority });
        }
      });
      if (tempList.length > 0) {
        const x = _.orderBy(tempList, ['listPriority'], ['asc']);
        _.forEach(x, (o) => {
          this.listApplicants.push(o);
        });
        tempList = [];
      }

      _.forEach(data.InvolvedPartyEntity, (o) => {
        tempList.push({
          FamilyId: o.FamilyId,
          FamilyName: o.FamilyFullName,
          initials: utilitiesService.filterInitialOneString(o.FamilyFullName),
          background: this.getBGcolor(o.FamilyId),
          listPriority: (o.IsApplicant ? 0 : 1),
        });
      });
      if (tempList.length > 0) {
        const x = _.orderBy(tempList, ['listPriority', 'FamilyName'], ['asc']);
        _.forEach(x, (o) => {
          this.listApplicants.push(o);
        });
        tempList = [];
      }

      if (_.size(this.listApplicants) > 0) {
        angular.extend(this.selectedFamily, {
          FamilyId: _.first(this.listApplicants).FamilyId.toString(),
          FamilyName: _.first(this.listApplicants).FamilyName,
          initials: utilitiesService.filterInitialOneString(_.first(this.listApplicants).FamilyName),
          background: this.getBGcolor(_.first(this.listApplicants).FamilyId),
          listPriority: (_.first(this.listApplicants).IsApplicant ? 0 : 1),
        });
      }

      this.involvedParties = { ...data.InvolvedPartyPerson, ...data.InvolvedPartyEntity };
    },

    getBGcolor(pId) {
      if (!_.isUndefined(pId)) {
        const Id = parseInt(pId, 10);

        const bg = _.find(this.bgColor, (o) => {
          return o.Id === Id;
        });

        if (!angular.isUndefined(bg)) {
          return bg.Background;
        }

        return '999999';
      }
    },

    AssetInfoGet(isRefresh = true) {
      loanApplicantAssetInfoService.getAssetInfo(this.loanAppId, isRefresh).then((response) => {
        this.NoAssets = (!(_.size(response.data) > 0));
        _.forEach(response.data, (asset) => {
          // NOTE: Let's o out the TotalAmount here.
          // REASON: It added ALL assets including Real Estate which is wrong. it should be separated
          asset.overview.NonREATotalAmount = 0;
          asset.overview.RealEstateTotalAmount = 0; // let's just prepare this total even though it's not used; might be used later

          _.forEach(asset.overview.FinancialInfo, (financialInfo) => {
            // Let's sum the assets
            if (financialInfo.TypeId !== 1)
              asset.overview.NonREATotalAmount += financialInfo.Value;
            else
              asset.overview.RealEstateTotalAmount += financialInfo.Value;

            _.remove(financialInfo.Borrowers, (borrower) => {
              return borrower.BorrowerID === 0;
            });

            _.map(financialInfo.Borrowers, (borrower) => {
              borrower.background = this.getBGcolor(borrower.BorrowerID);
              borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
              return borrower;
            });
          });
        });

        this.listAssets = response.data;

        this.updateAsset(this.selectedFamily.FamilyId);
      });
    },

    clearAssetInfo() {
      loanApplicantAssetInfoService.clearAssetInfo();
    },

    IncomeInfoGet() {
      loanScenarioService.IncomeInfoGet(this.loanAppId).then((response) => {
        // $scope.IncomeTotalAmount = 0;
        if (!response && !response.data) return;
        _.forEach(response.data, (income) => {
          if (!income && !income.overview && !income.overview.FinancialInfo) return;
          let displayableData = [];
          _.forEach(income.overview.FinancialInfo, (financialInfo) => {
            // filter deceased persons
            const aliveBorrowers = financialInfo.Borrowers.filter(borrower => !borrower.Deceased);
            if (aliveBorrowers.length) {
              if (!angular.isUndefined(financialInfo.FrequencyName))
                financialInfo.FrequencyInitial = financialInfo.FrequencyName.charAt(0);

              _.remove(aliveBorrowers, (borrower) => {
                return borrower.BorrowerID === 0;
              });

              _.map(aliveBorrowers, (borrower) => {
                borrower.background = this.getBGcolor(borrower.BorrowerID);
                borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
                return borrower;
              });
              financialInfo.Borrowers = aliveBorrowers;
              displayableData = [...displayableData, financialInfo];
            }
          });
          income.overview.FinancialInfo = displayableData;
        });

        this.listIncome = response.data;

        this.updateIncome(this.selectedFamily.FamilyId);
      });
    },

    LiabilityInfoGet() {
      loanScenarioService.LiabilityInfoGet(this.loanAppId).then((response) => {
        // $scope.LiabilityTotalAmount = 0;
        if (!response && !response.data) return;
        _.forEach(response.data, (liability) => {
          if (!liability && !liability.overview && !liability.overview.FinancialInfo) return;
          let displayableData = [];
          _.forEach(liability.overview.FinancialInfo, (financialInfo) => {
            const aliveBorrowers = financialInfo.Borrowers.filter(borrower => !borrower.Deceased);
            if (aliveBorrowers.length) {
              if (!_.isUndefined(financialInfo.RepaymentFrequencyName))
                financialInfo.FrequencyInitial = financialInfo.RepaymentFrequencyName.charAt(0);

              _.remove(aliveBorrowers, (borrower) => {
                return borrower.BorrowerID === 0;
              });

              _.map(aliveBorrowers, (borrower) => {
                borrower.background = this.getBGcolor(borrower.BorrowerID);
                borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
                return borrower;
              });
              financialInfo.Borrowers = aliveBorrowers;
              displayableData = [...displayableData, financialInfo];
            }
          });
          liability.overview.FinancialInfo = displayableData;
        });

        this.listLiabilities = response.data;

        this.updateLiability(this.selectedFamily.FamilyId);
      });
    },

    ExpenseInfoGet() {
      this.searchingExpenses = true;
      loanScenarioService.ExpenseInfoGet(this.loanAppId).then((response) => {
        this.searchingExpenses = false;
        if (!response && !response.data) return;
        _.forEach(response.data, (expense) => {
          if (!expense && !expense.overview && !expense.overview.FinancialInfo) return;
          let displayableData = [];
          _.forEach(expense.overview.FinancialInfo, (financialInfo) => {
            // filter deceased persons
            const aliveBorrowers = financialInfo.Borrowers.filter(borrower => !borrower.Deceased);
            if (aliveBorrowers.length) {
              if (!angular.isUndefined(financialInfo.FrequencyName))
                financialInfo.FrequencyInitial = financialInfo.FrequencyName.charAt(0);

              _.remove(aliveBorrowers, (borrower) => {
                return borrower.BorrowerID === 0;
              });

              _.map(aliveBorrowers, (borrower) => {
                borrower.background = this.getBGcolor(borrower.BorrowerID);
                borrower.OwnershipInitials = utilitiesService.filterInitial(borrower.FirstName, borrower.LastName ? borrower.LastName : '');
                return borrower;
              });
              financialInfo.Borrowers = aliveBorrowers;
              displayableData = [...displayableData, financialInfo];
            }
          });
        });

        this.listExpense = response.data;
        this.updateExpense(this.selectedFamily.FamilyId);
      });
    },

    liabilityCountOwnersSelected() {
      const selected = _.filter(this.liabilityOwnersList, obj => obj.ticked && obj.BorrowerID !== 0);
      return selected.length || 0;
    },

    creditcardValidator(creditType = 0) {
      const creditCardValue = creditType === CREDIT_CARDS_ONLY.TYPE ? creditType : this.LiabilitiesSet.Type.Value;
      const validAUCreditCardValue = uiService.isCountry('Australia') && parseInt(creditCardValue, 10) === CREDIT_CARDS_ONLY.TYPE;
      if (validAUCreditCardValue) {
        const set_property = CreditCardService.creditCardType(this.LiabilitiesSet.LiabilitySubTypeID);
        this.creditCardProperty = { AU_ONLY: true, _attributes: set_property };
      } else this.creditCardProperty = { AU_ONLY: false, _attributes: [] };
    },
    syncValueCheck() {
      const regex = this.creditCardProperty._attributes.regex;
      if (this.LiabilitiesSet.CreditCardNumber) {
        const cardChecked = CreditCardService.checkCreditCardNumber(this.LiabilitiesSet.CreditCardNumber, regex);
        if (!cardChecked) this.LiabilitiesSet.CreditCardNumber = '';
      }
    },
    checkStudentRepayment() {
      if (uiService.isCountry('New Zealand') && this.LiabilitiesSet && this.LiabilitiesSet.Type && this.LiabilitiesSet.Type.Name === 'Student Loan' && this.LiabilitiesSet.RepaymentFrequencyId > 0) {
        // check if ownership is Joint
        let isJointFamily = true;
        for (let i = 0; i < this.liabilityOwnersList.length; i++) {
          if (this.liabilityOwnersList[i].ticked && this.liabilityOwnersList[i].BorrowerID >= 1) {
            isJointFamily = false;
          }
        }
        this.liabilityOwnersList = _.map(this.liabilityOwnersList, (obj, key) => {
          if (isJointFamily || (this.liabilityOwnersList[0].BorrowerID === 0 && this.liabilityCountOwnersSelected() === 2)) {
            obj.ticked = key === 1; // only tick the first client
          }
          return obj;
        });
        this.liabilityOwnersListSelected = _.filter(this.liabilityOwnersList, (obj) => {
          return obj.ticked;
        });
        const selectedOwner = _.find(this.liabilityOwnersListSelected, obj => obj.ticked);
        if (selectedOwner && selectedOwner.BorrowerID !== 0) {
          const data = {
            familyId: $stateParams.familyId,
            clientId: selectedOwner.BorrowerID,
            frequencyId: this.LiabilitiesSet.RepaymentFrequencyId,
          };

          contactService.studentLoanRepaymentEstimate(data).then((response) => {
            this.LiabilitiesSet.Repayments = parseFloat(response.data).toFixed(2) || null;
          });
        }
      } else if (!this.LiabilitiesSet.RepaymentFrequencyId) {
        this.LiabilitiesSet.Repayments = null;
      }
    },

    liabilityTickOwnersList(data, bool, flip) {
      this.liabilityOwnersList = _.map(this.liabilityOwnersList, (obj) => {
        if (flip && obj.BorrowerID !== data.BorrowerID) {
          obj.ticked = bool;
        } else if (!flip && obj.BorrowerID === data.BorrowerID) {
          obj.ticked = bool;
        }
        return obj;
      });
    },

    liabilityDeleteSuccess() {
      SweetAlert.swal({
        title: 'Success',
        text: 'Liability has been been successfully deleted.',
        type: 'success',
        timer: 2000,
        showConfirmButton: false,
      });
    },

    isSalary(type) {
      return isEmploymentIncome(type);
    },

    deleteIncome(income, loanAppId, familyId) {
      if (income && income.Id) {
        swal({
          title: 'Are you sure?',
          text: 'This record will be removed from your income list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        }, (confirm) => {
          if (confirm) {
            let service;
            let method;
            let param = [];
            if (loanAppId) {
              service = loanScenarioService;
              method = 'IncomeInfoDelete';
              param = [loanAppId, income.Id];
            } else {
              service = contactService;
              method = 'deleteIncome';
              param = [income.Id, familyId];
            }
            service[method](param[0], param[1]).then(() => {
              $timeout(() => {
                if (loanAppId) {
                  factory.IncomeInfoGet();
                }

                _.remove(loanAppSharedData.Employment.Income, (o) => {
                  return _.isEqual(o, income);
                });

                swal({
                  title: 'Success',
                  text: 'Income has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }, 100);
            });
          }
        });
      } else {
        _.remove(loanAppSharedData.Employment.Income, (o) => {
          return _.isEqual(o, income);
        });
      }
    },

  });

  factory.liabilityTypeTemplate = () => {
    const templates = [
      {
        Value: '1',
        Template: 'stud',
      },
      {
        Value: '2',
        Template: 'cont',
      },
      {
        Value: '3',
        Template: 'comm',
      },
      {
        Value: '4',
        Template: 'cont',
      },
      {
        Value: '5',
        Template: 'stud',
      },
      {
        Value: '6',
        Template: 'stud',
      },
      {
        Value: '7',
        Template: 'cont',
      },
      {
        Value: '8',
        Template: 'stud',
      },
      {
        Value: '9',
        Template: 'comm',
      },
      {
        Value: '10',
        Template: 'comm',
      },
      {
        Value: '11',
        Template: 'comm',
      },
      {
        Value: '12',
        Template: 'cont',
      },
      {
        Value: '13',
        Template: 'cont',
      },
      {
        Value: '14',
        Template: 'cont',
      },
      {
        Value: '15',
        Template: 'cont',
      },
      {
        Value: '16',
        Template: 'cont',
      },
      {
        Value: '17',
        Template: 'cont',
      },
      {
        Value: '18',
        Template: 'cont',
      },
      {
        Value: '19',
        Template: 'cont',
      },
      {
        Value: '20',
        Template: 'cont',
      },
      {
        Value: '21',
        Template: 'comm',
      },
      {
        Value: '22',
        Template: 'cont',
      },
      {
        Value: '23',
        Template: 'cont',
      },
      {
        Value: '24',
        Template: 'cont',
      },
    ];

    return templates;
  };

  factory.getLiabilityType = (scope) => {
    optionsService.LiabilityTypeGet().then((response) => {
      _.map(response.data, (o) => {
        const findTemplate = _.find(scope.liabiliTypeTemplates, (temp) => {
          return temp.Value === o.Value;
        });
        if (findTemplate) {
          o.Template = findTemplate.Template;
        }
        return o;
      });
      scope.liabilityTypes = response.data;
    });
  };

  factory.filterExpenseTypes = (expenseTypes, data) => {
    return _.map(expenseTypes, (o) => {
      o.ticked = (o.Value === data.Value);
      return o;
    });
  };


  factory.financialExpeneseFilter = (filteredTypes) => {
    const tmp = [];
    filteredTypes && filteredTypes.forEach((arrObj) => {
      const value = arrObj;
      // Concat GroupName - Name
      value.name = `${value.GroupName} - ${value.Name}`;
      value.ticked = false;
      value.shortName = value.name;
      tmp.push(value);
    });

    return tmp;
  };

  return factory;
});
