import angular from 'angular';
import uiService from 'Assets/js/services/uiService';
import workbench from './Workbench';
import workbenchDetails from './workbenchDetails';
import workbenchTabs from './workbenchTabs';
import workbenchAddNote from './workbenchAddNote';
import sendEmail from '../sendEmail';
import sendMail from '../sendMail';

export default angular.module('components.applicationWorkbench', [
  uiService,
  sendEmail,
  sendMail,
])
  .component('applicationWorkbench', workbench)
  .component('workbenchDetails', workbenchDetails)
  .component('workbenchTabs', workbenchTabs)
  .component('workbenchAddNote', workbenchAddNote)
  .name;
