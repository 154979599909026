import angular from 'angular';

class AddTrustCtrl {
  constructor(
    $uibModalInstance,
    selectedPartyType,
    entity
  ) {
    this.modalInstance = $uibModalInstance;
    this.selectedPartyType = selectedPartyType;
    this.entity = entity;
  }
}

angular.module('app').controller('AddTrustCtrl', AddTrustCtrl);
