import _ from 'lodash';
import angular from 'angular';

const DEFAULT_LENDER = 'ALL';

class lmiLenderMortgageCtrl {
  constructor(
    loanProfilerService,
    fundingCalculatorService,
    fundingCalculatorSharedData,
    toaster
  ) {
    'ngInject';

    this.loanProfilerService = loanProfilerService;
    this.fundingCalculatorService = fundingCalculatorService;
    this.fundingCalculatorSharedData = fundingCalculatorSharedData;
    this.toaster = toaster;
  }

  // Serviceability Filter Dropdown Data
  getFilterData() {
    this.lenderList = [];
    this.setOfLenderList = [];
    this.fundingCalculatorService.getFundingCalculatorQuickLMILenders(this.brokerEventId)
      .then((lendersResponse) => {
        if (!lendersResponse) return;
        const lenders = lendersResponse.data && lendersResponse.data.Lenders;
        if (!lenders || lenders.length === 0) return;
        this.lenderList = lenders.map((obj) => {
          const item = {
            name: obj.Name,
            shortName: obj.Name.length > 12 ? `${obj.Name.substring(0, 12)}...` : obj.Name,
            ticked: false,
            value: obj.Value,
          };
          return item;
        });
        this.selectDefaultAllLender();
      });
  }

  getFundingCalculatorFundsQuickLMIFavorite() {
    if (this.mortgageObj.IsQuickCalc) return;
    this.fundingCalculatorService.getFundingCalculatorFundsQuickLMIFavorite(this.brokerEventId).then((response) => {
      if (response && response.data.length) {
        const favoriteLenderObj = response && response.data && response.data.length && response.data[0];
        this.lenderDefault = `${favoriteLenderObj.LenderId}`;
      } else {
        this.lenderDefault = DEFAULT_LENDER;
      }
    });
  }

  getState() {
    this.fundingCalculatorService.getFundingCalculatorStates().then((response) => {
      this.StateObj = response.data;
    });
  }

  // Select Default All Lender
  selectDefaultAllLender() {
    this.selectedLenderList = this.lenderList.filter(item => item.value === (this.lenderDefault || DEFAULT_LENDER));
    this.lenderList.forEach((item) => {
      item.ticked = !this.mortgageObj.IsQuickCalc ? item.value === this.lenderDefault : item.value === DEFAULT_LENDER;
      if (item.ticked) {
        this.setOfLenderList.push(item);
      }
    });
    this.setDefaultFilterSelection();
  }

  // Set Default Filter Selection
  setDefaultFilterSelection() {
    const lenders = _.map(this.selectedLenderList, 'value');
    if (typeof this.mortgageObj !== 'undefined') {
      this.mortgageObj.Lenders = lenders;
    }
  }

  // Update Default Lenders List
  updateDefaultLendersListSet(LendersListSet) {
    const lendersListData = _.find(LendersListSet, (item) => {
      return item.value === DEFAULT_LENDER;
    });
    const loanProviderLendersListData = _.find(this.selectedLenderList, (item) => {
      return item.value === DEFAULT_LENDER;
    });

    const selectingAllLenders = typeof lendersListData !== 'undefined' && typeof loanProviderLendersListData === 'undefined';
    const selectingEachLenders = (typeof lendersListData === 'undefined' && typeof loanProviderLendersListData !== 'undefined') || (typeof lendersListData !== 'undefined' && typeof loanProviderLendersListData !== 'undefined');

    if (selectingAllLenders) {
      angular.forEach(LendersListSet, (item) => {
        if (item.value !== DEFAULT_LENDER) {
          this.lenderList.forEach((itemGet) => {
            if (itemGet.value === item.value) {
              itemGet.ticked = false;
            }
          });
        }
      });
      this.setOfLenderList = [lendersListData];
      this.selectedLenderList = this.setOfLenderList;
    } else if (selectingEachLenders) {
      this.lenderList.forEach((item) => {
        if (item.value === DEFAULT_LENDER) {
          item.ticked = false;
        }
      });
      this.setOfLenderList = LendersListSet;
      const index = this.setOfLenderList.indexOf(lendersListData);
      if (index !== -1) {
        this.setOfLenderList.splice(index, 1);
      }
      this.selectedLenderList = [...this.setOfLenderList];
    } else {
      this.setOfLenderList = LendersListSet;
      this.selectedLenderList = this.setOfLenderList;
    }
  }

  updateLVR() {
    this.setDefaultFilterSelection();
    const { NewLoanAmount, security } = this.mortgageObj;
    const SecurityAmount = _.sumBy(security, item => parseFloat(item.Value));
    if (NewLoanAmount && SecurityAmount) {
      this.mortgageObj.NewLVRValue = this.fundingCalculatorSharedData.updateQuickLmiLvrValue(NewLoanAmount, SecurityAmount);
      this.mortgageObj.LVRValue = this.mortgageObj.NewLVRValue.replace('%', '');
    }
  }

  $onInit() {
    this.selectedLenderList = [];
    this.getFundingCalculatorFundsQuickLMIFavorite();
    this.getFilterData();
    this.getState();
    if (!this.mortgageObj.TargetLVR) {
      this.mortgageObj.TargetLVR = null;
    }
  }
}

export default lmiLenderMortgageCtrl;
