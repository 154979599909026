import angular from 'angular';

angular.module('app').controller('NewExpenseModalCtrl',
  function NewExpenseModalCtrl($scope, $uibModalInstance, modalType) {
    $scope.Title = (modalType === 'new' ? 'Add' : 'Edit');

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.saveBreakdown = function () {
      $scope.addExpense();
      $scope.cancel();
    };
  });
