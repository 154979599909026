import angular from 'angular';

angular.module('app').controller('InsExistingCtrl',
  function InsExistingCtrl(
    $scope,
    $timeout,
    $uibModal,
    $state,
    $localStorage,
    imageDataURI,
    $stateParams,
    contactInsuranceService,
    toaster,
    insuranceSharedData
  ) {
    /*
    Table of Contents
    1. MODELS
    2. METHODS
    3. Functions
    4. Shared Data
    */

    // 1. MODELS
    angular.extend($scope, {
      insuranceProvider: [],
      insuranceFamilyPoliciesList: [],
      existing: { providerId: 0, policyId: '' },
      existingInsuranceList: [],
      existingIsuranceModalData: [],
    });

    const tabName = 'Existing';
    $scope.data = {
      familyId: parseInt($stateParams.familyId, 10) || 0,
      insuranceProviderList: [],
      insurancePolicyList: [],
      filter: {
        policyNumber: 'Show All Policies',
        providerId: 0,
      },
    };

    // 3. Functions
    $scope.vm = {
      deleteExisting: (familyId, policyId, caller) => {
        insuranceSharedData.deleteInsurance(familyId, policyId, caller);
      },
      changeFilters() {
        const data = $scope.data;
        const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
        insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
      },
      resetFilters() {
        $scope.data.filter = {
          policyNumber: 'Show All Policies',
          providerId: 0,
        };
        const data = $scope.data;
        const policyNumber = (data.filter.policyNumber === 'Show All Policies' ? '' : data.filter.policyNumber.toString());
        insuranceSharedData.getInsurances(data.familyId, tabName, data.filter.providerId, policyNumber);
      },
    };

    function funcInsuranceQuoteProviderGet() {
      contactInsuranceService.insuranceQuoteProviderGet(0, 0).then((response) => {
        if (response.data && response.data.length > 0) {
          $scope.insuranceProvider = [{ ProviderName: 'Show All Insurers', ProviderId: 0 }, ...response.data];
        }
      });
    }

    function funcInsuranceFamilyPoliciesGet() {
      contactInsuranceService.insuranceFamilyPoliciesGet($scope.familyId).then((response) => {
        if (response.data && response.data.length > 0) {
          $scope.insuranceFamilyPoliciesList = [{ PolicyNumber: 'Show All Policies', PolicyID: '' }, ...response.data];
        }
      });
    }

    const funcInsuranceListFirstLoad = () => {
      contactInsuranceService.insuranceDetailsListGet($scope.familyId, 'Existing', 0, '').then((response) => {
        $scope.existingInsuranceList = response.data;
      });
    };

    const viewDataRefresh = (data, caller) => {
      insuranceSharedData.getFiltersList(data.familyId, caller);
      insuranceSharedData.summaryInsuranceListGet(data.familyId);

      if (caller && caller === 'Previous') {
        insuranceSharedData.getInsurances(data.familyId, caller, 0, '');
      } else {
        funcInsuranceListFirstLoad();
      }
    };

    function funcEditExistingIsuranceModal(value, caller) {
      contactInsuranceService.insuranceDetailsGet($scope.familyId, value.BenefitID).then((response) => {
        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
          controller: 'InsuranceExistingModalCtrl',
          windowClass: 'insurance-existing-modal',
          backdrop: false,
          keyboard: false,
          resolve: {
            items() {
              return {
                data: response.data,
                obj: value,
              };
            },
          },
        });

        modalInstance.result.then(() => {
          viewDataRefresh($scope.data, caller);
        });
      });
    }

    function funcAddExistingIsuranceModal(caller) {
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
        controller: 'InsuranceExistingModalCtrl',
        windowClass: 'insurance-existing-modal',
        backdrop: false,
        keyboard: false,
        resolve: {
          items() {
            return {
              data: [],
            };
          },
        },
      });

      modalInstance.result.then(() => {
        viewDataRefresh($scope.data, caller);
      });
    }

    // 4. Shared Data
    $scope.$watch(() => { return insuranceSharedData.sharedData.insuranceList; }, (newVal) => {
      if (newVal) {
        $scope.existingInsuranceList = newVal;
      }
    });

    $scope.$watch(() => { return insuranceSharedData.sharedData.insuranceProviderList; }, (newVal) => {
      if (newVal) {
        $scope.data.insuranceProviderList = newVal;
      }
    });

    $scope.$watch(() => { return insuranceSharedData.sharedData.insurancePolicyList; }, (newVal) => {
      if (newVal) {
        $scope.data.insurancePolicyList = newVal;
      }
    });

    insuranceSharedData.addExistingInsurance = funcAddExistingIsuranceModal;
    insuranceSharedData.editExistingInsurance = funcEditExistingIsuranceModal;

    $scope.existingBenefitOptionChange = () => {
      funcInsuranceListFirstLoad();
    };

    $scope.addExistingIsuranceModal = () => {
      funcAddExistingIsuranceModal();
    };

    $scope.editExistingIsuranceModal = (value) => {
      funcEditExistingIsuranceModal(value);
    };

    const init = () => {
      funcInsuranceQuoteProviderGet();
      funcInsuranceFamilyPoliciesGet();
      funcInsuranceListFirstLoad();

      const data = $scope.data;
      insuranceSharedData.getFiltersList(data.familyId, tabName);
    };

    init();
  });
