import { typeIntBuilderForUI, marketingAutomationBuilderForUI } from 'Common/mappers/sharedMappers';
import { businessTypesBuilderForUI } from 'Common/mappers/options';
import { discountTimePeriodBuilderForUI } from 'Common/mappers/loanTools';
import { objectivesOptionBuilderForUI } from 'Common/mappers/customerObjectives';

class OptionsService {
  constructor(httpClient, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.$q = $q;
    this.apiBaseUrl = 'options';
    this.defaultOptions = [null, true, false];
  }

  IncomeVerificationType(questionType) {
    return this.httpClient.get(`${this.apiBaseUrl}/Financial/IncomeVerificationType`, { questionType });
  }

  ContactStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/ContactStatusGet`, ...this.defaultOptions);
  }

  GeneralInsuranceGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/GeneralInsuranceGet`, ...this.defaultOptions);
  }

  LifestyleInsuranceGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/LifestyleInsuranceGet`, ...this.defaultOptions);
  }

  RiskInsuranceGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/RiskInsuranceGet`, ...this.defaultOptions);
  }

  question(questionType) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/Question`, { questionType });
  }

  frequency() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/Frequency`, ...this.defaultOptions);
  }

  FinancialFrequency() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/FinancialFrequency`, ...this.defaultOptions);
  }

  absPurpose() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/ABSPurpose`, ...this.defaultOptions);
  }

  loanProductList(providerID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/LoanProductList`, { providerID });
  }

  tenureTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/TenureTypeGet`, ...this.defaultOptions);
  }

  EmploymentCategoryGetResult() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/EmploymentCategoryGet`, ...this.defaultOptions);
  }

  getEmploymentBasis() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/EmploymentBasisGet`, ...this.defaultOptions);
  }

  propertyTypeGet(PropertyZoningTypeID) {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/PropertyTypeGet`, { PropertyZoningTypeID });
  }

  ownershipTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/OwnershipTypeGet`, ...this.defaultOptions);
  }

  floorTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/FloorTypeGet`, ...this.defaultOptions);
  }

  addressTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/AddressTypeGet`, ...this.defaultOptions);
  }

  titleTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/TitleTypeGet`, ...this.defaultOptions);
  }

  getSecurityType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Security/SecurityTypeGet`, ...this.defaultOptions);
  }

  getTransactionType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Security/TransactionTypeGet`, ...this.defaultOptions);
  }

  getAssetValueEstimatedBasis() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/AssetValueEstimatedBasisGet`, ...this.defaultOptions);
  }

  getHoldingType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/HoldingTypeGet`, ...this.defaultOptions);
  }

  getPrimaryPurposeType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/PrimaryPurposeTypeGet`, ...this.defaultOptions);
  }

  getStatusType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/StatusTypeGet`, ...this.defaultOptions);
  }

  getZoningType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/ZoningTypeGet`, ...this.defaultOptions);
  }

  getPropertyType(PropertyZoningTypeID, useCache = false) {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/PropertyTypeGet`, { PropertyZoningTypeID }, useCache);
  }

  getTitleTenureType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/TitleTenureTypeGet`, ...this.defaultOptions);
  }

  getTitleType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/TitleTypeGet`, ...this.defaultOptions);
  }

  getTitleIdentificationType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/TitleIdentificationTypeGet`, ...this.defaultOptions);
  }

  getContactAccessType() {
    return this.httpClient.get(`${this.apiBaseUrl}/Security/ContactAccessTypeGet`, ...this.defaultOptions);
  }

  getMortgagePriority() {
    return this.httpClient.get(`${this.apiBaseUrl}/Financial/MortgagePriorityGet`, ...this.defaultOptions);
  }

  getLenderList(lenderPlace) {
    return this.httpClient.get(`${this.apiBaseUrl}/General/LenderListGet`, { lenderPlace });
  }

  identificationDocumentsTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/IdentificationDocumentsTypeGet`, ...this.defaultOptions);
  }

  countryListGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/CountryListGet`, ...this.defaultOptions);
  }

  employmentCategoryGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/EmploymentCategoryGet`, ...this.defaultOptions);
  }

  employmentStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/EmploymentStatusGet`, ...this.defaultOptions);
  }

  employerTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/EmployerTypeGet`, ...this.defaultOptions);
  }

  employmentRoleGet(criteria) {
    return this.httpClient.get(`${this.apiBaseUrl}/General/EmploymentRoleGet`, { criteria });
  }

  loanpurpose() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/Loanpurpose`, ...this.defaultOptions);
  }

  DepositSource() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/DepositSource`, ...this.defaultOptions);
  }

  DepositType() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/DepositType`, ...this.defaultOptions);
  }

  LoanFeatures() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/LoanFeatures`, ...this.defaultOptions);
  }

  bankBranchSearch(search, lenderId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/BankBranchSearch`, { search, lenderId });
  }

  getFrequency() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/Frequency`, ...this.defaultOptions);
  }

  MortgagePriorityGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Financial/MortgagePriorityGet`, ...this.defaultOptions);
  }

  AssetValueBasisGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Property/AssetValueEstimatedBasisGet`, ...this.defaultOptions);
  }

  OFXSupportCurrency() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/OFXSupportCurrency`, ...this.defaultOptions);
  }

  MaritalStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/MaritalStatusGet`, ...this.defaultOptions);
  }

  ResidencyStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/ResidencyStatusGet`, ...this.defaultOptions);
  }

  AssetTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Financial/AssetTypeGet`, ...this.defaultOptions);
  }

  SubAssetTypeGet(assetTypeId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Financial/SubAssetTypeGet`, { assetTypeId });
  }

  LiabilityTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Financial/LiabilityTypeGet`, ...this.defaultOptions);
  }

  LoanFeaturesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/LoanFeaturesGet`, ...this.defaultOptions);
  }

  scenarioSearchClientGet(searchString, partyType) {
    // TODO: move this to generalService
    return this.httpClient.get(`SearchClientGet`, { searchString, partyType });
  }

  PreferredContactMethodGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/General/PreferredContactMethodGet`, ...this.defaultOptions);
  }

  getEnquirySourceList() {
    return this.httpClient.get(`${this.apiBaseUrl}/User/EnquirySourceList`, null, false, false);
  }

  getReferencedReferralList(adviserId = 0, searchString = '') {
    return this.httpClient.get(`${this.apiBaseUrl}/General/ReferencedReferralList`, { adviserId, searchString });
  }

  DocumentTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/DocumentTypeGet`, ...this.defaultOptions);
  }

  getDueDateTypes() {
    return this.httpClient.get(`${this.apiBaseUrl}/tasks/due-date-types`, null, true, false);
  }

  NZDocumentTypeGet(lenderId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/NZDocumentTypeGet`, { lenderId });
  }

  TopUpTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/TopUpTypeGet`, ...this.defaultOptions);
  }

  RentalVerificationTypeGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanApplication/RentalVerificationTypeGet`, ...this.defaultOptions);
  }

  getLoanStatus(includeStatus = '') {
    return this.httpClient.get(`${this.apiBaseUrl}/Loan/LoanStatus`, { includeStatus });
  }

  getLmiInsurers(lenderId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LMI/${lenderId}/Insurers`, ...this.defaultOptions);
  }

  brokerAccessTypes() {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokerAccessTypes`, ...this.defaultOptions);
  }

  adviserAssistantsGet(adviserId) {
    return this.httpClient.get(`${this.apiBaseUrl}/broker/${adviserId}/assistants`, ...this.defaultOptions);
  }


  getASBBranchOptions() { // eslint-disable-line
    // TODO: move this to API implementation
    return [
      {
        Value: '1',
        Name: 'Central',
      },
      {
        Value: '2',
        Name: 'South',
      },
      {
        Value: '3',
        Name: 'Northern',
      },
      {
        Value: '4',
        Name: 'Local',
      },
    ];
  }
  getTitleList() {
    const defer = this.$q.defer();
    const titleList = [
      { id: 1, value: 'Mr.' },
      { id: 2, value: 'Mrs.' },
      { id: 3, value: 'Ms.' },
      { id: 4, value: 'Dr.' },
      { id: 5, value: 'Prof.' },
      { id: 6, value: 'Hon.' },
    ];
    defer.resolve(titleList);
    return defer.promise;
  }

  getActivityType(familyId) {
    return this.httpClient.get(`options/notification/types?familyId=${familyId}`);
  }

  getActivityAdvisers(familyId) {
    return this.httpClient.get(`options/notification/advisers?familyId=${familyId}`);
  }
  getLoanTerms() {
    return this.httpClient.get(`${this.apiBaseUrl}/loan/loanterms`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getAdvisers() {
    return this.httpClient.get(`${this.apiBaseUrl}/corporate/advisers`)
      .then(response => response.data.map(typeIntBuilderForUI));
  }
  getMarketingAutomation() {
    return this.httpClient.get(`${this.apiBaseUrl}/subscription/marketing-automation`)
      .then(response => response.data.map(marketingAutomationBuilderForUI));
  }
  getBusinessTypes() {
    return this.httpClient.get(`${this.apiBaseUrl}/referrer/business-types`)
      .then(response => response.data.map(businessTypesBuilderForUI));
  }
  getDiscountTimePeriod() {
    return this.httpClient.get(`${this.apiBaseUrl}/discount/time-period`)
      .then(response => response.data.map(discountTimePeriodBuilderForUI));
  }
  getObjectivesOption() {
    return this.httpClient.get(`${this.apiBaseUrl}/objective-options`)
      .then(response => response.data.map(objectivesOptionBuilderForUI));
  }
}

export default OptionsService;
