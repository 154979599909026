import { isNil, some } from 'lodash';
import { isBoolean } from 'Common/utilities/objectValidation';
import { INSURANCE_VALIDATION } from 'Common/constants/insuranceValidation';
import { INSURANCE_OPTIONS } from 'Common/constants/insuranceOptions';

export function isValidPersonCommonInfo(person) {
  if (!person) return false;

  const { FirstName, Gender } = person;
  return FirstName && Gender;
}

export function isValidClientInfo(person) {
  const { LastName, Occupation, EmployedStatus, Smoker } = person;
  const isValidSmokerInfo = isBoolean(Smoker);
  const isValidAge = person.Age >= INSURANCE_VALIDATION.MIN_ADULT_AGE;
  return isValidPersonCommonInfo(person) && LastName && Occupation && EmployedStatus && isValidSmokerInfo && isValidAge;
}

export function isValidDependentInfo(person) {
  const isValidAge = !isNil(person.Age);
  return isValidPersonCommonInfo(person) && isValidAge;
}

export function isValidPersonInfo(person) {
  if (!person) return false;

  return person.IsChild ? isValidDependentInfo(person) : isValidClientInfo(person);
}

export function getBenefitCoverAmount(benefit) {
  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  let cover;
  switch (benefit.BenefitId) {
  case BENEFIT_TYPE.LIFE_COVER:
    cover = benefit.LifeCover;
    break;
  case BENEFIT_TYPE.FAMILY_PROTECTION:
    cover = benefit.FamilyProtection;
    break;
  case BENEFIT_TYPE.TRAUMA_COVER:
    cover = benefit.TraumaCover;
    break;
  case BENEFIT_TYPE.TPD:
    cover = benefit.TotalAndPermanentDisability;
    break;
  case BENEFIT_TYPE.INCOME_PROTECTION:
    cover = benefit.IncomeProtection;
    break;
  case BENEFIT_TYPE.MORTAGE_REPAYMENT:
    cover = benefit.MortgageRepaymentCover;
    break;
  case BENEFIT_TYPE.REDUNDANCY:
    cover = benefit.RedundancyCover;
    break;
  default:
    return 0;
  }

  return cover && cover.CoverAmount;
}

export function isValidBenefit(benefit) {
  if (!benefit) return false;

  if (!benefit.BenefitId) return false;

  const { BENEFIT_TYPE } = INSURANCE_OPTIONS;
  switch (benefit.BenefitId) {
  case BENEFIT_TYPE.HEALTH_COVER:
  case BENEFIT_TYPE.WAIVER_OF_PREMIUM:
    return true;
  default:
    return getBenefitCoverAmount(benefit) > 0;
  }
}

export function isValidBenefitInfo(person) {
  if (!person) return false;

  const hasValidBenefits = some(person.BenefitList, benefit => isValidBenefit(benefit));
  return hasValidBenefits;
}
