import _ from 'lodash';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes.js';
import { onAcceptListener } from 'Common/utilities/templatesDragListener';

class MailTemplatesCtrl {
  constructor(
    SweetAlert,
    toaster,
    ckEditorConfigService,
    uiService,
    corporateService,
    contactService,
    mailTemplateService,
    templateService,
    $window
  ) {
    'ngInject';

    this.SweetAlert = SweetAlert;
    this.toaster = toaster;
    this.ckEditorConfigService = ckEditorConfigService;
    this.uiService = uiService;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.mailTemplateService = mailTemplateService;
    this.templateService = templateService;
    this.$window = $window;
    this.SEND_METHOD_TYPES = SEND_METHOD_TYPES;
  }

  $onInit() {
    this.isCorporateUser = this.uiService.isCorporateUser;
    this.options = this.ckEditorConfigService.getCommonEmailEditorConfig();
    this.mailTemplateListing = [];
    this.mailTemplateList = [];
    this.mailTemplateFolderList = [];
    this.mailTemplate = {};
    this.isAddCategory = false;
    this.isTemplateEditor = false;

    this.dragControlListeners = {
      accept: onAcceptListener,
      itemMoved: (event) => {
        const index = event.dest.index;
        const template = event.dest.sortableScope.modelValue[index];
        const destinationFolderId = parseInt(event.dest.sortableScope.$parent.$parent.heading, 10) || 0;
        this.moveTemplateToFolder(template, destinationFolderId);
      },
      orderChanged: (event) => {
        this.saveChangedOrder(event.dest.sortableScope.modelValue);
      },
      dragStart(event) {
        event.source.itemScope.element.addClass('mail-template-dragging');
      },
      dragEnd(event) {
        event.source.itemScope.element.removeClass('mail-template-dragging');
      },
      containerPositioning: 'relative',
      allowDuplicates: true,
    };

    this.dragControlListenersFolder = {
      accept(sourceItemHandleScope, destSortableScope) {
        return sourceItemHandleScope.itemScope.modelValue.IsTemplateFolder && _.isArray(destSortableScope.modelValue) && !destSortableScope.modelValue.IsTemplateFolder;
      },
      dragStart(event) {
        event.source.itemScope.element.addClass('mail-template-folder-dragging');
      },
      dragEnd(event) {
        event.source.itemScope.element.removeClass('mail-template-folder-dragging');
      },
      orderChanged: (event) => {
        this.saveChangedOrder(event.dest.sortableScope.modelValue);
      },
      containerPositioning: 'relative',
      allowDuplicates: false,
    };

    this.getMailTemplates();
  }

  toggleActionButtons(obj) {
    const toggleStatus = obj ? this.mailTemplateList[obj.UserTemplateID] : null;
    if (toggleStatus) {
      toggleStatus.isHovered = !toggleStatus.isHovered;
    } else {
      this.mailTemplateList[obj.UserTemplateID] = {};
      this.mailTemplateList[obj.UserTemplateID].isHovered = true;
    }
  }

  toggleAddCategory() {
    this.isAddCategory = !this.isAddCategory;
  }

  setTemplateEditor(folder, template) {
    if (folder) {
      this.currentTemplateSelection = {
        folderId: folder.UserTemplateID,
        folderName: folder.UserTemplateName,
        templateId: template ? template.UserTemplateID : 0,
        templateName: template ? template.UserTemplateName : this.mailTemplateService.defaultTemplateName,
      };

      if (template) {
        this.mailTemplate = _.clone(template);
      } else {
        this.mailTemplate = this.mailTemplateService.getDefaultTemplateFor(folder, this.newTemplateContent);
      }

      this.toggleTemplateEditor();
    }
  }

  toggleTemplateEditor() {
    this.isTemplateEditor = !this.isTemplateEditor;
  }

  cancelTemplateEditor() {
    this.toggleTemplateEditor();
    this.currentTemplateSelection = {};
  }

  triggerDeleteTemplate(template) {
    if (!template) return;
    this.confirmDelete(false, () => this.deleteTemplate(template));
  }

  triggerDeleteFolder(folder) {
    if (!folder) return;
    this.confirmDelete(true, () => this.deleteFolder(folder));
  }

  editTemplateFolder(folder) {
    if (folder) {
      this.mailTemplateFolderList[folder.UserTemplateID] = this.mailTemplateFolderList[folder.UserTemplateID] || {};
      this.mailTemplateFolderList[folder.UserTemplateID].folderName = folder.UserTemplateName;
      this.mailTemplateFolderList[folder.UserTemplateID].isEdit = true;
      this.currentTemplateId = folder.UserTemplateID;
    }
  }

  updateTemplateFolder(folder) {
    this.contactService.mailTemplateFoldersSet({
      UserTemplateID: folder.UserTemplateID,
      UserTemplateName: this.mailTemplateFolderList[folder.UserTemplateID].folderName,
    }).then(() => {
      this.mailTemplateFolderList[folder.UserTemplateID].isEdit = false;
      folder.UserTemplateName = this.mailTemplateFolderList[folder.UserTemplateID].folderName;
      this.toaster.pop('success', 'Template Folder successfully saved.');
      this.onChangesMade();
    }, () => {
      this.toaster.pop('error', 'Template Folder was not saved.');
    });
  }

  cancelEditFolder(folder) {
    if (folder) {
      this.mailTemplateFolderList[folder.UserTemplateID].isEdit = false;
    }
  }

  noProgation(e) {
    this.uiService.noEventProgation(e);
  }

  confirmDelete(isFolder, onConfirmListener) {
    const title = isFolder ? 'Are you sure you want to delete this Folder?' : 'Are you sure you want to delete this Template?';
    this.SweetAlert.swal({
      title,
      text: 'If you want to recover just contact the administrator.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
      closeOnConfirm: true,
    }, (isConfirmed) => {
      if (isConfirmed && onConfirmListener) {
        onConfirmListener();
      }
    });
  }

  deleteFolder(folder) {
    this.contactService.mailTemplateFoldersDelete(folder.UserTemplateID).then(() => {
      const indexDeleted = _.findIndex(this.mailTemplateListing, templateFolder => templateFolder.UserTemplateID === folder.UserTemplateID);
      this.mailTemplateListing.splice(indexDeleted, 1);
      this.toaster.pop('success', 'Template folder successfully deleted.');
      this.onChangesMade();
    }, () => {
      this.toaster.pop('error', 'Unable to delete the template folder.');
    });
  }

  deleteTemplate(template) {
    template.IsActive = false;
    template.IsFolder = false;
    this.contactService.mailTemplatesDelete(template.UserTemplateID).then(() => {
      const indexFolder = _.findIndex(this.mailTemplateListing, obj => obj.UserTemplateID === template.UserTemplateFolderId);
      if (indexFolder !== -1) {
        const indexDeleted = _.findIndex(this.mailTemplateListing[indexFolder].DocRtfSMSTemplateList, obj => obj.UserTemplateID === template.UserTemplateID);
        this.mailTemplateListing[indexFolder].DocRtfSMSTemplateList.splice(indexDeleted, 1);
      }
      this.toaster.pop('success', 'Template successfully removed.');
      this.onChangesMade();
    }, () => {
      this.toaster.pop('error', 'Unable to delete the template.');
    });
  }

  saveTemplateEnabled() {
    return this.mailTemplateService.isTemplateValid(this.mailTemplate);
  }

  saveMailTemplate() {
    if (this.saveTemplateEnabled()) {
      this.savingTemplate = true;
      this.contactService.mailTemplatesSet(this.mailTemplate).then((response) => {
        if (response.data) {
          this.toaster.pop('success', 'Template successfully saved.');
          this.handleMailTemplateResponse(response.data);
          this.toggleTemplateEditor();
          this.mailTemplate.UserTemplateName = '';
          this.mailTemplate.UserTemplateContent = '';
          this.savingTemplate = false;
          this.onChangesMade();
        }
      }, () => {
        this.toaster.pop('error', 'Unable to save the template.');
        this.savingTemplate = false;
      });
    }
  }

  handleMailTemplateResponse(response) {
    const templateFolder = this.templateService.isBlankFolderOrTemplate(this.mailTemplate) ? this.blankCategory :
      _.find(this.mailTemplateListing, folder => folder.UserTemplateID === response.UserTemplateFolderId);

    if (templateFolder.DocRtfSMSTemplateList) {
      const existingTemplateIndex = _.findIndex(templateFolder.DocRtfSMSTemplateList, temp => temp.UserTemplateID === response.UserTemplateID);
      if (existingTemplateIndex !== -1) {
        templateFolder.DocRtfSMSTemplateList[existingTemplateIndex] = response;
      } else {
        templateFolder.DocRtfSMSTemplateList.push(response);
      }
    } else {
      templateFolder.DocRtfSMSTemplateList = [];
      templateFolder.DocRtfSMSTemplateList.push(response);
    }
  }

  saveChangedOrder(data) {
    this.mailTemplateService.setTemplatesOrder(data);
    this.contactService.mailTemplateSortingSet(data);
    this.onChangesMade();
  }

  moveTemplateToFolder(template, folderId) {
    template.UserTemplateFolderId = folderId;
    this.contactService.mailTemplatesSet(template).then(() => {
      this.toaster.pop('success', 'Template successfully moved to another folder.');
      this.onChangesMade();
    }, () => {
      this.toaster.pop('error', 'Unable to move template to another folder.');
    });
  }

  insertValue(value) {
    this.$window.CKEDITOR.instances.mailContent.insertText(`{${value}}`);
  }

  saveTemplateFolder() {
    if (this.mailTemplateFolderList.newFolder && this.mailTemplateFolderList.newFolder.trim()) {
      const templateFolder = this.mailTemplateService.constructNewTemplateFolder(this.mailTemplateFolderList.newFolder);
      this.contactService.mailTemplateFoldersSet(templateFolder).then((response) => {
        this.toaster.pop('success', 'Template folder successfully saved.');
        templateFolder.UserTemplateID = response.data;
        this.mailTemplateListing.push(templateFolder);
        this.toggleAddCategory();
        this.onChangesMade();
      }, () => {
        this.toaster.pop('error', 'Unable to save the template folder.');
      });
    }
  }

  getMailTemplates() {
    this.contactService.mailTemplatesGet().then((response) => {
      if (response.data) {
        this.blankCategory = _.remove(response.data, folder => this.templateService.isBlankFolderOrTemplate(folder))[0];
        this.blankTemplate = this.templateService.getBlankTemplateIn(this.blankCategory);
        this.mailTemplateListing = this.templateService.filterTemplatesByUserType(response.data);
      }
    });
  }

  onChangesMade() {
    if (!this.onTemplatesUpdated) return;
    this.onTemplatesUpdated();
  }
}

export default MailTemplatesCtrl;
