import {
  sum,
  flatten,
} from 'lodash';

class LoanService {
  constructor(configService, $q) {
    'ngInject';

    this.configService = configService;
    this.$q = $q;
  }
  removeBorrowerApplicant(applicants, valueIndex, partyType) {
    const defer = this.$q.defer();

    if (partyType === 'guarantor') {
      applicants.guarantors.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'solicitor') {
      applicants.solicitors.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'accountant') {
      applicants.accountants.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'dependant') {
      applicants.dependants.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else {
      const borrowers = flatten(applicants.borrowers);
      const applicantCount = sum([applicants.entities.length, borrowers.length]);
      if (applicantCount > 1) {
        if (partyType === 'applicant') {
          applicants.borrowers.splice(valueIndex, 1);
        } else {
          applicants.entities.splice(valueIndex, 1);
        }
        defer.resolve({ data: applicants });
      } else {
        defer.reject();
      }
    }
    return defer.promise;
  }
}

export default LoanService;
