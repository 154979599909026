import angular from 'angular';
import models from './models';
import services from './services';
import icons from './icons';

export default angular.module('app.common', [
  services,
  models,
  icons,
]).name;
