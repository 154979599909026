import template from './insuranceList.html';
import controller from './insuranceListCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {

  },
};
