import {
  responseHasValuesOfObjects,
  confirmAction,
} from 'Common/utilities/insurance';
import { COVER_TYPE } from 'Common/constants/coverType';

class notesTemplateCtrl {
  constructor(
    $uibModal,
    insuranceProfilerService
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.insuranceProfilerService = insuranceProfilerService;
    this.notestemplates = [];
    this.isLimit = true;
    this.loadingState = true;
    this.templateDragListener = {};
    this.isDraggigNotes = false;
  }

  $onInit() {
    const updatOrderByNotes = (data) => {
      this.isDraggigNotes = true;
      this.insuranceProfilerService.profilerNoteUpdateOrderBy(data)
        .then((response) => {
          if (responseHasValuesOfObjects(response)) {
            this.loadInsuranceNotesTemplates();
            this.isDraggigNotes = false;
          }
        });
    };
    this.loadInsuranceNotesTemplates();
    this.templateDragListener = {
      accept(sourceItemHandleScope, destSortableScope) {
        return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
      },
      orderChanged(event) {
        const currentSorting = event.source.sortableScope.modelValue;
        const tempSorting = [];
        currentSorting.forEach((key, index) => {
          tempSorting.push({ NoteID: key.id, OrderBy: index });
        });
        updatOrderByNotes(tempSorting);
      },
      dragStart(event) {
        event.source.itemScope.element.addClass('notes-dragging');
      },
      clone: false,
      allowDuplicates: true,
      additionalPlaceholderClass: 'notes-item' };
  }


  loadInsuranceNotesTemplates() {
    this.insuranceProfilerService
      .profilerNoteTemplatesGet(COVER_TYPE.TEMPLATE_NOTES_MANAGER).then((response) => {
        this.notestemplates = response && response.length && response.reduce((accum, item) => {
          const column = {
            ...item,
            templates: item.templates && item.templates.filter(template => template.id),
          };
          return [...accum, column];
        }, []);
      })
      .catch(error => this.handleLoadTemplatesError(error))
      .finally(() => {
        this.loadingState = false;
      });
  }


  viewMore(state, parentIndex, currentIndex) {
    this.notestemplates[parentIndex].templates[currentIndex].readMore = state;
  }

  actionDeleteNote(note) {
    confirmAction({
      title: 'Delete Confirmation',
      text: `Are you sure you want to delete ${note.name}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Ok',
      closeOnConfirm: true,
    },
    (confirm) => {
      if (confirm) {
        this.deleteNote(note.id);
      }
    });
  }
  deleteNote(id) {
    this.insuranceProfilerService.profilerNoteTemplateDelete(id)
      .then((response) => {
        if (responseHasValuesOfObjects(response)) {
          this.loadInsuranceNotesTemplates();
        }
      });
  }
  actionNoteTemplate(benefitTypeId, index, actionType) {
    const insuranceNoteModal = this.$uibModal.open({
      templateUrl: '/assets/views/profileManagement/Business/modals/addNewInsuranceTemplate.html',
      controller: 'AddInsuranceTemplateNotesModalCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      size: 'md',
      windowClass: '',
      resolve: {
        benefitId: () => benefitTypeId,
        actionType: () => actionType,
      },
    });
    insuranceNoteModal.result.then((benefitsNotes) => {
      if (responseHasValuesOfObjects(benefitsNotes)) {
        if (actionType === 'edit') {
          this.loadInsuranceNotesTemplates();
        } else {
          this.notestemplates[index].templates.push(benefitsNotes);
        }
      }
    });
  }
}

export default notesTemplateCtrl;
