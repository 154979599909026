import angular from 'angular';

angular.module('app').controller('OpenModalForVideoCtrl',
  function OpenModalForVideoCtrl($scope, $uibModalInstance, DEMO_VIDEO) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.videoId = DEMO_VIDEO.SATISFACTION_SERVEY.Url;
  });
